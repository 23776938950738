import { gql } from "@apollo/client";

import { careTeamMember } from "operations/fragments/careProvider";

const getFullMemberDetails = gql`
  query getFullMemberDetails($id: ID!) {
    member(id: $id) {
      id
      created_at
      date_of_birth
      first_name
      last_name
      name
      email
      phone
      country_code

      user {
        id

        calendar {
          id
        }
      }

      cohort {
        id
        dedicated_therapy_available
        name
        customer {
          id
          name
        }
        contract_term {
          end_at
        }
      }

      care_team {
        id
        care_navigator {
          ...careTeamMember
        }
        medication_manager {
          ...careTeamMember
        }
        therapist {
          ...careTeamMember
        }
        coach {
          ...coach
        }
      }

      proposed_grantor_email

      previsit {
        id
        status
      }

      todos {
        id
        kind
        title
      }
    }
  }
  ${careTeamMember}
`;

export default getFullMemberDetails;
