// Base style overrides
import { colors } from "./colors";
import { sizes } from "./sizes";
import { space } from "./spacing";
import { fonts } from "./fonts";
import { textStyles } from "./textStyles";
import { shadows } from "./shadows";
import { components } from "./components";
import { fontWeights } from "./fontWeights";

export const base = {
  colors,
  sizes,
  space,
  fonts,
  fontWeights,
  textStyles,
  shadows,
  components,
};
