import React from "react";
import { Text, Box } from "@chakra-ui/react";
import { getFirstError } from "utils/apollo/errorHandler";

export const MomentsExerciseErrors = ({
  recommendedExerciseErrors,
  memberGoalsError,
  t,
}) => {
  return (
    <>
      {recommendedExerciseErrors.map((error) => {
        return (
          <Box key={error.message}>
            <Text as="span" fontWeight={600} color="negative-base">
              {t("error")}
            </Text>
            <Text as="span" color="negative-base">
              {getFirstError(error)}
            </Text>
          </Box>
        );
      })}
      {memberGoalsError && (
        <Box>
          <Text as="span" fontWeight={600} color="negative-base">
            {t("error")}
          </Text>
          <Text as="span" color="negative-base">
            {getFirstError(memberGoalsError)}
          </Text>
        </Box>
      )}
    </>
  );
};
