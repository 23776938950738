// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const NavItem = (props) => (
	<div
		data-title={typeof props.children === 'string' ? props.children : ''}
		className={classnames(styles.navItem, {
			[styles.active]          : props.active, [styles.active_selected] : props.isRouterPath,
		})}
	>
		{props.children}
	</div>
);

export default NavItem;
