import { gql } from "@apollo/client";

const requestCareTeamChange = gql`
  mutation RequestCareTeamChange($input: RequestCareTeamChangeInput!) {
    requestCareTeamChange(input: $input) {
      success
    }
  }
`;

export default requestCareTeamChange;
