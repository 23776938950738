import { ReactElement } from "react";
import { IconButton } from "@springcare/sh-component-library";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Close } from "design-system/assets";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import experiences from "components/templates/HomePage/components/SUDSignalSection/experiences";
import HiddenContent from "components/templates/HomePage/components/SUDSignalSection/AccordionWithBullets/HiddenContent";

interface SUDSignalModalMobileProps {
  isOpen: boolean;
  onClose: () => void;
  onScheduleCTAClick;
}

const SUDSignalDrawerMobile = ({
  isOpen,
  onClose,
  onScheduleCTAClick,
}: SUDSignalModalMobileProps): ReactElement => {
  const sudSignalExperimentValue: string = useFeatureFlag(
    FLAGS.SUD_SIGNAL_EXPERIMENT,
  );
  const experience = experiences[sudSignalExperimentValue];

  if (!experience) {
    return <></>;
  }

  return (
    <Drawer placement="bottom" size="full" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pt="44px" pb={16}>
          <Flex justifyContent="space-between">
            <Box>
              <Text fontSize={21} fontWeight={600}>
                {experience.title}
              </Text>
            </Box>
            <Box w="44px">
              <IconButton
                aria-label="Check-in on your progress"
                variant="medium-emphasis"
                colorScheme="base"
                size="md"
                icon={Close}
                onClick={onClose}
              />
            </Box>
          </Flex>
        </DrawerHeader>
        <DrawerBody px={24}>
          <HiddenContent
            bullets={experience.bullets}
            endText={experience.endText}
            ctaText={experience.ctaText}
            isFullScreenModalExperience={true}
            onCTAClick={onScheduleCTAClick}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SUDSignalDrawerMobile;
