import { gql } from "@apollo/client";

const getMemberBenefitPreferences = gql(`
  query getMemberBenefitPreferences($member_id: ID!) {
    member_preferences(member_id: $member_id) {
      names
    }
  }
`);

export default getMemberBenefitPreferences;
