import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import Router from "next/router";
import { DateTime } from "luxon";

import { Button, TextButton } from "@springcare/sh-component-library";
import { Box, Text, VStack, Skeleton } from "@chakra-ui/react";
import { setField } from "@spring/smeargle/actions";

import { TRACK_EVENT } from "utils/mixpanel";
import { useMultiSessionBookingAppts } from "./__hooks__/useMultiSessionBookingAppts";
import { routeToConfirmationPage } from "components/molecules/CareProviderScheduleContent/CareProviderScheduleContent.utils";

interface MultiSessionBookingProps {
  onBookAnotherAppointment: (initialStartTime?: string) => void;
  memberId: string;
  multiSessionAppointments: string[];
  routeTeenToInitialAssessment: boolean;
}

type MemberPortalReduxState = {
  global: {
    lang?: string;
    [key: string]: any;
  };
  [key: string]: any;
};

export const formatTime = (time: string, locale) => {
  const dateTime = DateTime.fromISO(time).setLocale(locale);
  return dateTime.toFormat("cccc, LLLL d 'at' h:mm a ZZZZ");
};

export const MultiSessionBooking: React.FC<
  React.PropsWithChildren<MultiSessionBookingProps>
> = ({
  onBookAnotherAppointment,
  memberId,
  multiSessionAppointments,
  routeTeenToInitialAssessment,
}) => {
  const { t } = useTranslation("careVisits");
  const { lastAppointment, appointmentLoading } =
    useMultiSessionBookingAppts(memberId);

  const dispatch = useDispatch();
  const locale = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.lang,
  );

  if (!lastAppointment || appointmentLoading) {
    return <Skeleton height="400px" my="10px" isLoaded={!appointmentLoading} />;
  }
  const {
    provider: {
      first_name: providerFirstName,
      name: providerFullName,
      id: providerId,
    },
    start_at: lastApptStartTime,
    medium,
  } = lastAppointment;

  const updateSelectedTimeSlot = (timeSlot?: string) => {
    const nextStartTime = DateTime.fromISO(timeSlot).setLocale(locale);
    const startTimeMinus1HourToISO = nextStartTime.minus({ hours: 1 }).toISO();
    const formKey = `care-provider-schedule-${providerId}`;
    dispatch(
      setField(
        formKey,
        "startTime",
        {
          selectedDateTime: nextStartTime.toISO() || null,
          selectedMedium: medium,
        },
        true, // sets dirty
      ),
    );
    dispatch(
      setField(
        formKey,
        "initialStartTime",
        {
          selectedDateTime: startTimeMinus1HourToISO,
          selectedMedium: medium,
        },
        true, // sets dirty
      ),
    );
    onBookAnotherAppointment(startTimeMinus1HourToISO);
  };

  return (
    <Box>
      <Box mb={15}>
        <Trans
          t={t}
          i18nKey="multiSessionBooking.appointmentBooked"
          values={{
            providerFullName: providerFullName,
            appointmentTime: formatTime(lastApptStartTime, locale),
          }}
          components={[
            <Text as="span" key="0" />,
            <Text as="span" fontWeight="600" key="1" />,
          ]}
        />
      </Box>
      <Box mb={15}>
        <Text>
          {t("multiSessionBooking.consistencyIsKey", { providerFirstName })}
        </Text>
      </Box>
      <VStack spacing={4} mb={8} pb={16} align="start">
        {multiSessionAppointments.map((apptSlotDateTime, index) => {
          return (
            <TextButton
              key={index}
              colorScheme="base"
              ml={0}
              pl={0}
              onClick={() => {
                TRACK_EVENT.BUTTON_CLICKED(
                  Router.asPath,
                  "Multi Session Booking - TimeSlot",
                  {
                    timeSlot: apptSlotDateTime,
                  },
                );
                updateSelectedTimeSlot(apptSlotDateTime);
              }}
            >
              {formatTime(apptSlotDateTime, locale)}
            </TextButton>
          );
        })}
      </VStack>
      <VStack spacing={8} mt={15}>
        <Button
          variant="high-emphasis"
          size="md"
          colorScheme="base"
          aria-label={t("multiSessionBooking.findADifferentTime", {
            providerFirstName,
          })}
          w="100%"
          onClick={() => {
            TRACK_EVENT.BUTTON_CLICKED(
              Router.asPath,
              "Multi Session Booking - Different Time",
            );
            updateSelectedTimeSlot();
          }}
        >
          {t("multiSessionBooking.findADifferentTime", { providerFirstName })}
        </Button>
        <Button
          variant="medium-emphasis"
          size="md"
          w="100%"
          colorScheme="base"
          aria-label={t("multiSessionBooking.noThanks")}
          onClick={() => {
            TRACK_EVENT.BUTTON_CLICKED(
              Router.asPath,
              "Multi Session Booking - No Thanks",
            );
            routeToConfirmationPage(
              routeTeenToInitialAssessment,
              lastAppointment.id,
            );
          }}
        >
          {t("multiSessionBooking.noThanks")}
        </Button>
      </VStack>
    </Box>
  );
};
