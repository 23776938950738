//

import React from "react";

const Email = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 6V5C2.44772 5 2 5.44772 2 6H3ZM21 6H22C22 5.44772 21.5523 5 21 5V6ZM12 12.8571L11.394 13.6526C11.752 13.9253 12.248 13.9253 12.606 13.6526L12 12.8571ZM3 7H21V5H3V7ZM20 6V17H22V6H20ZM20 17H4V19H20V17ZM4 17V6H2V17H4ZM12.606 13.6526L21.606 6.79543L20.394 5.20457L11.394 12.0617L12.606 13.6526ZM2.39396 6.79543L11.394 13.6526L12.606 12.0617L3.60604 5.20457L2.39396 6.79543ZM4 17H4H2C2 18.1046 2.89543 19 4 19V17ZM20 17V19C21.1046 19 22 18.1046 22 17H20Z"
      fill="#1C6B57"
    />
  </svg>
);

export default Email;
