import { Card } from "@springcare/sh-component-library";
import {
  Text,
  Flex,
  VStack,
  Box,
  HStack,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  VParentingDuotoneSpot,
  VImprovementDuotoneSpot,
  VGrowth4DuotoneSpot,
  VGoalsDuotoneSpot,
} from "@springcare/verdant-icons-react";
import HalfEllipse from "design-system/assets/icons/HalfEllipse";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import routes from "routes/index";
import { TRACK_EVENT } from "utils/mixpanel";
import { SpecializedCoachingCardAnalyticsMapping } from "modules/MemberDashboard/SpecializeCoach/constants/analyticsMapping";

const icons = {
  healthWellness: VGrowth4DuotoneSpot,
  personalDevelopment: VImprovementDuotoneSpot,
  professionalGrowth: VGoalsDuotoneSpot,
  parenting: VParentingDuotoneSpot,
};

const CardContent = ({
  cardTitle,
  Icon,
  t,
  isDesktopView = false,
  isMobile = false,
  isBetweenMobileAndTablet = false,
  isSmallMobile = false,
  dir,
}) => (
  <>
    {isBetweenMobileAndTablet ? (
      <Box ps={16}>
        <Text
          color="var(--chakra-colors-neutral-050)"
          size="body-medium-strong"
        >
          {t(
            `upNextSection.coachingV2Experience.speciality.${cardTitle}.title`,
          )}
        </Text>
      </Box>
    ) : (
      <Box p={16} ps={isSmallMobile && 12} flexGrow={1}>
        <Text
          color="var(--chakra-colors-neutral-050)"
          size="body-medium-strong"
          w={isMobile ? "100%" : 158}
          h={24}
          mb={4}
          whiteSpace={{ md: "nowrap" }}
        >
          {t(
            `upNextSection.coachingV2Experience.speciality.${cardTitle}.title`,
          )}
        </Text>
        {!isMobile && (
          <Text
            overflowY="hidden"
            size="body-small-regular"
            color="var(--chakra-colors-content-secondary)"
          >
            {t(
              `upNextSection.coachingV2Experience.speciality.${cardTitle}.description`,
            )}
          </Text>
        )}
      </Box>
    )}
    {isDesktopView && Icon && (
      <Box alignSelf="flex-end" mt={46}>
        <Box position="relative" width={80} height={80}>
          <Box
            position="absolute"
            right={dir === "ltr" ? "-35px" : "5px"}
            width="100%"
            height="100%"
            style={{ transform: dir === "rtl" ? "scaleX(-1)" : "none" }}
          >
            <HalfEllipse />
          </Box>
          <Box position="absolute" top={0} left={0} width="100%" height="100%">
            <Icon width={80} height={80} aria-hidden="true" asSpan />
          </Box>
        </Box>
      </Box>
    )}
    {isMobile &&
      Icon &&
      (dir === "ltr" ? (
        <Box position="absolute" right={-10} bottom={8}>
          <Box position="absolute" right={0} bottom={0}>
            <HalfEllipse />
          </Box>
          <Box position="absolute" right={10} bottom={0}>
            <Icon width={56} height={56} aria-hidden="true" asSpan />
          </Box>
        </Box>
      ) : (
        <Box position="absolute" bottom={8} left={0}>
          <Box
            position="absolute"
            bottom={0}
            left={0}
            style={{ transform: dir === "rtl" ? "scaleX(-1)" : "none" }}
          >
            <HalfEllipse />
          </Box>
          <Box position="absolute" bottom={0} left={0}>
            <Icon width={56} height={56} aria-hidden="true" asSpan />
          </Box>
        </Box>
      ))}
  </>
);

export const LevelUpCoachingCard = ({
  height,
  width,
  cardTitle,
  isDesktop,
  isBetweenMobileAndTablet,
  isMobile,
}) => {
  const { t } = useTranslation("homepage");
  const [isSmallMobile] = useMediaQuery("(max-width: 330px)");
  const Icon = icons[cardTitle];
  const routerArray = {
    healthWellness: "health-wellness",
    personalDevelopment: "personal-development",
    professionalGrowth: "professional-growth",
    parenting: "parenting",
  };
  const router = useRouter();
  const specializeCoachRoute = () => {
    const specializeRoute = routes.SpecialtyCoach.as.replace(
      ":specialty",
      routerArray[cardTitle],
    );

    const handleClick = () => {
      router.push(specializeRoute);
      TRACK_EVENT.LINK_CLICKED(
        window.location.pathname,
        specializeRoute,
        SpecializedCoachingCardAnalyticsMapping[cardTitle],
      );
    };
    return handleClick;
  };

  const { i18n } = useTranslation();
  const dir = i18n.dir(i18n.language);

  return (
    <Card
      variant="medium-emphasis"
      height={height || "100%"}
      width={width || "100%"}
      p={0}
      cursor="pointer"
      position="relative"
      data-cy="find-a-coach-button"
      onClick={specializeCoachRoute()}
    >
      <VStack width="100%" alignItems={isMobile ? "start" : "center"}>
        {isBetweenMobileAndTablet ? (
          <HStack width="100%" justifyContent="space-between">
            <CardContent
              cardTitle={cardTitle}
              Icon={Icon}
              t={t}
              isBetweenMobileAndTablet={isBetweenMobileAndTablet}
              dir={dir}
            />
            {Icon && (
              <>
                <Box position={"relative"} width={80} height={80}>
                  <Flex
                    position="absolute"
                    top={"2px"}
                    right={dir === "ltr" ? 0 : 35}
                    style={{ transform: dir === "rtl" ? "scaleX(-1)" : "none" }}
                  >
                    <HalfEllipse />
                  </Flex>
                  <Box top={0} left={0}>
                    <Icon width={80} height={80} aria-hidden="true" asSpan />
                  </Box>
                </Box>
              </>
            )}
          </HStack>
        ) : (
          <>
            {isDesktop ? (
              <Flex justifyContent="space-between" direction="column">
                <CardContent
                  cardTitle={cardTitle}
                  Icon={Icon}
                  t={t}
                  isDesktopView={isDesktop}
                  dir={dir}
                />
              </Flex>
            ) : isMobile ? (
              <Flex direction="column" height="100%">
                <CardContent
                  cardTitle={cardTitle}
                  Icon={Icon}
                  t={t}
                  isMobile={isMobile}
                  dir={dir}
                  isSmallMobile={isSmallMobile}
                />
              </Flex>
            ) : (
              <HStack width="100%" justifyContent="space-between">
                <Box>
                  <CardContent
                    cardTitle={cardTitle}
                    Icon={Icon}
                    t={t}
                    dir={dir}
                  />
                </Box>
                {Icon && (
                  <>
                    <Box position={"relative"} width={80} height={80}>
                      <Flex
                        position="absolute"
                        top={"2px"}
                        right={dir === "ltr" ? 0 : 35}
                        style={{
                          transform: dir === "rtl" ? "scaleX(-1)" : "none",
                        }}
                      >
                        <HalfEllipse />
                      </Flex>
                      <Box top={0} left={0}>
                        <Icon
                          width={80}
                          height={80}
                          aria-hidden="true"
                          asSpan
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </HStack>
            )}
          </>
        )}
      </VStack>
    </Card>
  );
};
