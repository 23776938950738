import { Switch } from "@springcare/sh-component-library";
import { MenuItem, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { useParticipantMetadata } from "context/SessionRoomContext";
import { useTranslation } from "hooks/react-i18next";

export const NoiseFilterMenuItem = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "noiseFilterMenuItem",
  });

  const {
    isNoiseCancelingPreferenceEnabled,
    setIsNoiseCancelingPreferenceEnabled,
  } = useParticipantMetadata();

  const toggleNoiseFilter = () => {
    setIsNoiseCancelingPreferenceEnabled((prevState) => !prevState);
  };

  return (
    <MenuItem
      onClick={toggleNoiseFilter}
      _focus={{ outline: "0px solid transparent !important" }}
      _focusVisible={{ outline: "2px solid black !important" }}
      display="flex"
    >
      <FormControl display="flex" justifyContent="space-between">
        <FormLabel>
          <Text>{t("noiseCancellation")}</Text>
        </FormLabel>
        <Switch
          isChecked={isNoiseCancelingPreferenceEnabled}
          onChange={toggleNoiseFilter}
        />
      </FormControl>
    </MenuItem>
  );
};
