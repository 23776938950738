import React from "react";
import PropTypes from "prop-types";
import {
  Content,
  Section,
  Grid,
  Col,
  Checklist,
  Bolded,
  Button,
} from "@spring/smeargle";
import { images, links } from "@spring/constants";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Router from "next/router";

import styles from "./styles.module.scss";

import routes from "routes";
import { Heading } from "design-system/components";

const MomentsBenefit = (props, { isAMinor }) => {
  const { t } = useTranslation("limitedLangBenefits");

  const getImgSrc = () => {
    if (isAMinor) {
      return (
        links.SpringCDN +
        "/assets/images/members/moments-tab-bar-managed-minors.png"
      );
    }
    return links.SpringCDN + "/assets/images/members/moments-tab-bar-zoom.png";
  };

  const momentsPreviewImage = () => {
    return <img alt="" className={styles.tabPreview} src={getImgSrc()}></img>;
  };

  return (
    <>
      <div className={styles.imageWrapper}>
        {props && props.lang === "en" && (
          <>
            <img
              alt=""
              className={styles.mobileBanner}
              src={images.MomentsBannerSmall}
            ></img>
            <img
              alt=""
              className={styles.desktopBanner}
              src={images.MomentsBannerFull}
            ></img>
          </>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.title}>
          <Heading fontSize={20} fontWeight={"bold"} color="#4b4b4b">
            {t("selfGuidedExercises.moments")}
          </Heading>
        </div>

        <Grid gutter="0 30px">
          <Col md={12} lg={6}>
            <div className={styles.contentWrapper}>
              <div className={styles.contentParagraph}>
                <Content>{t("selfGuidedExercises.paragraph.one")}</Content>
              </div>
              <div className={styles.contentParagraph}>
                <Content>{t("selfGuidedExercises.paragraph.two")}</Content>
              </div>
              <div className={styles.contentParagraph}>
                <Content>
                  <Trans
                    ns={"t2Benefits"}
                    i18nKey={"selfGuidedExercises.paragraph.three"}
                  />
                </Content>
              </div>
              {momentsPreviewImage()}
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className={styles.contentWrapper}>
              <Content>{t("selfGuidedExercises.heading")}</Content>
              <Grid gutter="0 20px">
                <Col size={6}>
                  <Checklist
                    listItems={[
                      <Bolded key="anxiety-1">
                        {t("selfGuidedExercises.exercises.anxiety")}
                      </Bolded>,
                      <Bolded key="focus-2">
                        {t("selfGuidedExercises.exercises.focus")}
                      </Bolded>,
                      <Bolded key="personal-growth-3">
                        {t("selfGuidedExercises.exercises.personalGrowth")}
                      </Bolded>,
                      <Bolded key="depression-4">
                        {t("selfGuidedExercises.exercises.depression")}
                      </Bolded>,
                      <Bolded key="sleep-5">
                        {t("selfGuidedExercises.exercises.sleep")}
                      </Bolded>,
                      <Bolded key="emotions-6">
                        {t("selfGuidedExercises.exercises.emotions")}
                      </Bolded>,
                    ]}
                    listStyle="bulleted"
                  />
                </Col>
                <Col size={6}>
                  <Checklist
                    listItems={[
                      <Bolded key="exercise-1">
                        {t("selfGuidedExercises.exercises.exercise")}
                      </Bolded>,
                      <Bolded key="relationships-2">
                        {t("selfGuidedExercises.exercises.relationships")}
                      </Bolded>,
                      <Bolded key="eating-3">
                        {t("selfGuidedExercises.exercises.eating")}
                      </Bolded>,
                      <Bolded key="substance-abuse-4">
                        {t("selfGuidedExercises.exercises.substanceUse")}
                      </Bolded>,
                      <Bolded key="mindfulness-5">
                        {t("selfGuidedExercises.exercises.mindfulness")}
                      </Bolded>,
                    ]}
                    listStyle="bulleted"
                  />
                </Col>
              </Grid>
            </div>

            <div className={styles.downloadContainer}>
              <Section key="try-moments">
                <Button
                  full
                  role="link"
                  text={t("selfGuidedExercises.tryMomentsNow")}
                  onClick={() => {
                    const { to, as } = routes.MemberMoments;
                    Router.push(to, as);
                  }}
                />
              </Section>
            </div>
          </Col>
        </Grid>
      </div>
    </>
  );
};

MomentsBenefit.propTypes = {
  isAMinor: PropTypes.bool.isRequired,
  lang: PropTypes.string,
  openModal: PropTypes.func.isRequired,
};

export { MomentsBenefit };
export default connect((state) => ({
  lang: state.global?.lang,
}))(MomentsBenefit);
