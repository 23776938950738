import React, { useState, useEffect } from "react";
import { Stars } from "design-system/assets";
import Meowth from "@spring/meowth";
import { useQuery } from "@apollo/client";
import { Box, Text, Heading, Stack, Image } from "design-system/components";
import { Spinner } from "@chakra-ui/react";
import { Button, Card, Badge } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import routes from "routes";
import { useRouter } from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";
import getRecommendedTherapistsAvatars from "operations/queries/careProvider/getRecommendedTherapistsAvatar";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useBrecsAlgoVersion } from "hooks/useBrecsAlgoVersion";

const NewActiveCareNoProvidersCard = () => {
  const { t } = useTranslation("homepage");

  const defaultData = [
    {
      therapist: {
        id: 1,
        name: "Provider",
        avatar: "/static/DefaultAvatar.png",
      },
    },
    {
      therapist: {
        id: 2,
        name: "Provider",
        avatar: "/static/DefaultAvatar.png",
      },
    },
    {
      therapist: {
        id: 3,
        name: "Provider",
        avatar: "/static/DefaultAvatar.png",
      },
    },
  ];

  const [trackedOnce, setTrackedOnce] = useState(0);

  const boxShadow =
    "0px 0px 4px 0px rgba(72, 45, 40, 0.04), 0px 8px 16px -6px rgba(72, 45, 40, 0.12)";

  const [recommendedTherapists, setRecommendedTherapists] =
    useState(defaultData);

  const router = useRouter();
  const sagemakerRanking = useFeatureFlag(FLAGS.RECS_SAGEMAKER_RANK);

  const QUERY_VERSION = useBrecsAlgoVersion(sagemakerRanking);
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  apolloOptionsUserId.variables.version = QUERY_VERSION;

  const {
    data: recommendedTherapistData,
    loading: recommendedTherapistsLoading,
  } = useQuery(getRecommendedTherapistsAvatars, {
    ...apolloOptionsUserId,
  });

  const recommendedTherapistsList =
    recommendedTherapistData?.user?.member?.recommended_therapists;

  const recommendedTherapistsCount = recommendedTherapistsList?.length;

  const handleClick = () => {
    router.push(routes.TherapistsBrowse.as);
  };

  useEffect(() => {
    if (trackedOnce === 0 && recommendedTherapistsCount) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Your care recommendations viewed",
        {
          num_recommendations: String(recommendedTherapistsCount || 0),
        },
      );
      setTrackedOnce(1);
    }
  }, [recommendedTherapistsCount]);

  useEffect(() => {
    if (recommendedTherapistsList) {
      const TopMatchTherapists =
        recommendedTherapistsCount > 2
          ? recommendedTherapistsList.slice(0, 3)
          : recommendedTherapistsList;

      setRecommendedTherapists(TopMatchTherapists);
    }
  }, [recommendedTherapistData, recommendedTherapistsLoading]);

  return (
    <Card
      minW="100%"
      mb={["20px", "20px", "40px", "40px"]}
      boxShadow={boxShadow}
      padding="24px"
      variant="high-emphasis"
      _hover={{
        boxShadow: boxShadow,
      }}
      paddingY="24px"
      paddingX={["24px", "24px", "56px", "56px"]}
      data-testid="new-active-care-no-providers-card"
      onClick={() => {
        TRACK_EVENT.CARD_CLICKED(
          window.location.pathname,
          "Your care recommendations Card clicked",
        );
        handleClick();
      }}
      cursor="pointer"
    >
      <Box w="100%" my={["0px", "0px", "40px", "60px"]}>
        <Stack
          direction={["column", "column", "row", "row"]}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            w={["100%", "100%", "50%", "50%"]}
            color="#182023"
            textAlign="left"
            mb={[16, 16, 16, 16]}
          >
            <Heading
              fontSize={["16px", "16px", "18px", "21px"]}
              fontWeight={600}
              mb="4px"
              data-testid="new-active-care-no-providers-card-heading"
            >
              {t("newNoProviderCard.heading")}
            </Heading>
            <Text data-testid="new-active-care-no-providers-card-description">
              {t("newNoProviderCard.description")}
            </Text>
          </Box>

          {recommendedTherapistsLoading ? (
            <Spinner speed="1s" size="xl" />
          ) : (
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
              w={["100%", "100%", "auto", "auto"]}
              top={["0px", "0px", "20px", "20px"]}
            >
              <Box display="flex" position="relative">
                {recommendedTherapists?.map((provider, index) => (
                  <Box
                    key={provider.therapist.id}
                    borderRadius="full"
                    overflow="hidden"
                    boxSize={["80px", "90px", "120px"]}
                    border="3px solid"
                    borderColor="border-subtle"
                    ms={index === 0 ? "0" : "-42px"}
                    zIndex={index === 1 ? 2 : 1}
                    mt={
                      recommendedTherapistsCount < 3
                        ? "0px"
                        : index === 0 || index === 2
                          ? "0"
                          : "-13px"
                    }
                    background="white"
                    mb={10}
                    me={
                      recommendedTherapistsCount === 1 && [0, 0, "50px", "50px"]
                    }
                    data-testid="recommended-therapist-image"
                  >
                    <Image
                      src={provider.therapist.avatar}
                      alt={`${provider.therapist.name} avatar`}
                    />
                  </Box>
                ))}
              </Box>

              <Badge
                position="absolute"
                bottom={["5px", "5px", "8px", "8px"]}
                left={
                  recommendedTherapistsCount === 1
                    ? ["50%", "50%", "35%", "35%"]
                    : "50%"
                }
                transform="translateX(-50%)"
                bg="#E1F0EC"
                color="positive-on-subtle"
                py={4}
                fontSize="14px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex="100"
                borderRadius={8}
                w={120}
                data-testid="recommended-therapist-badge"
                icon={Stars}
                size="md"
              >
                {t("newNoProviderCard.badgeText")}
              </Badge>
            </Box>
          )}
        </Stack>
        <Button
          variant="high"
          background="#068262"
          borderRadius="8px"
          color="#FFFFFF"
          w={["100%", "100%", "auto", "auto"]}
          fontSize={["14px", "14px", "16px", "16px"]}
          _hover={{ textColor: "#FFFFFF" }}
          _focusVisible={{ textColor: "#FFFFFF" }}
          onClick={(e) => {
            e.stopPropagation();
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Your care recommendations clicked",
            );
            handleClick();
          }}
          mt={[16, 16, 0, 0]}
          data-testid="new-active-care-no-providers-card-button"
        >
          {t("newNoProviderCard.button")}
        </Button>
      </Box>
    </Card>
  );
};

export default NewActiveCareNoProvidersCard;
