import { API_DOMAIN } from "constants/api";

export type TemporaryConsentsVariables = {
  appointment_id: string;
  participant_id: string;
  consented: boolean;
};

export const usePostTemporaryConsents = () => {
  const rotomUrl = API_DOMAIN;

  return async (variables: TemporaryConsentsVariables) => {
    const res = await fetch(
      `${rotomUrl}/api/livekit/temporary_session_recording_consents`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(variables),
      },
    );
    return await res.json();
  };
};
export default usePostTemporaryConsents;
