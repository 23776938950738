import { createContext, useRef, useCallback, useState } from "react";
import Router from "next/router";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  useDisclosure,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import Welcome from "./Welcome";
import SeeResults from "./SeeResults";
import Goals from "./ResultsSections/Goals";
import Focus from "./ResultsSections/Focus";
import AdditionalResults from "./ResultsSections/AdditionalResults";
import DownloadCTA from "./DownloadCTA";
import FooterCTA from "./FooterCTA";
import styles from "./styles.module.scss";
import { SpecializedCareNavigatorAvailabilityModal } from "components/modals/CareNavigatorScheduleModal/CareNavigatorAvailabilityModal";
import { SpecializedCareNavigationFlyout } from "components/organisms/CareInfoFlyout/components";
import routes from "routes";
import { Navigation } from "design-system/components";
import RightLinks from "components/templates/MemberDashboard/PageWrapper/RightLinks";
import { useMemberInfo } from "hooks";

const AssessmentResultsContext = createContext();

// used for i18n key-matching and variable style-binding
const CARD_TYPE = {
  GOALS: "goals",
  FOCUS: "focus",
  ADDITIONAL_RESULTS: "additionalResults",
};

// ailments/conditions to exclude from results
const EXCLUDE_AILMENT = {
  CSSRS1: "CSSRS-1",
  CSSRS7: "CSSRS-7",
  FI: "FI",
  SIB: "SIB",
  SDOH: "SDOH",
  SDS: "SDS",
  QIDS_SR_16: "QIDSSR16",
  ISSUES: "Issues",
  ISSUES_V2: "Issues_v2",
  EPDS: "EPDS",
  SPRASS: "SPRASS",
  PCL5: "PCL-5",
  BAM: "BAM",
  MH: "MH",
};

const YourAssessmentResults = ({
  selectedAssessment,
  goals,
  questionnaires,
  isInitialAssessment,
  t,
  inline,
  isYourAssessmentResultsPage = false,
}) => {
  const pageRefs = useRef({});

  const { breakpoints } = useTheme();
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const displayInline = isYourAssessmentResultsPage && !isDesktop;
  const { data: memberData } = useMemberInfo();

  const [isLgScreen] = useMediaQuery(
    `(min-width: 768px) and (max-width: 1250px)`,
  );

  const [isXLgScreen] = useMediaQuery(
    `(min-width: 1250px) and (max-width: 1400px)`,
  );

  const isResultsPageXLgScreen = isYourAssessmentResultsPage && isXLgScreen;
  const isResultsPageLgScreen = isYourAssessmentResultsPage && isLgScreen;
  const isResultsPageDesktop = isYourAssessmentResultsPage && isDesktop;

  // arrays for card rendering - this works but is it kosher?
  let focusCards;
  let additionalCards;
  // context values
  let values;

  function assignRiskScore(risk) {
    // int's returned low-to-high, makes .sort used in setCards() easier to understand
    switch (risk) {
      case "High":
        return 1;
      case "Medium":
        return 2;
      case "Low / Medium":
        return 2.5;
      case "Low":
        return 3;
      default:
        return 3;
    }
  }

  function setCards() {
    return questionnaires
      .map((item) => {
        const results = JSON.parse(item.results_sanitized);
        const info = JSON.parse(item.information);
        const label = info.descriptive_name;
        const positive = results.positive;
        const scoreLabel = `${results.score} / ${info.max_score}`;
        const riskScore = assignRiskScore(results?.risk);
        return {
          results,
          info,
          label,
          positive,
          scoreLabel,
          riskScore,
        };
      })
      .filter(
        (item) =>
          item.info.abbreviation !== EXCLUDE_AILMENT.CSSRS1 &&
          item.info.abbreviation !== EXCLUDE_AILMENT.CSSRS7 &&
          item.info.abbreviation !== EXCLUDE_AILMENT.FI &&
          item.info.abbreviation !== EXCLUDE_AILMENT.SIB &&
          item.info.abbreviation !== EXCLUDE_AILMENT.SDOH &&
          item.info.abbreviation !== EXCLUDE_AILMENT.SDS &&
          item.info.abbreviation !== EXCLUDE_AILMENT.QIDS_SR_16 &&
          item.info.abbreviation !== EXCLUDE_AILMENT.ISSUES &&
          item.info.abbreviation !== EXCLUDE_AILMENT.EPDS &&
          item.info.abbreviation !== EXCLUDE_AILMENT.SPRASS &&
          item.info.abbreviation !== EXCLUDE_AILMENT.ISSUES_V2 &&
          item.info.abbreviation !== EXCLUDE_AILMENT.PCL5 &&
          item.info.abbreviation !== EXCLUDE_AILMENT.BAM &&
          item.info.abbreviation !== EXCLUDE_AILMENT.MH,
      )
      .sort((a, b) => a.riskScore - b.riskScore);
  }

  if (questionnaires) {
    const cards = setCards();
    focusCards = cards.slice(0, 3);
    additionalCards = cards.slice(3, 7);

    values = {
      goals,
      focusCards,
      pageRefs,
      additionalCards,
      styles,
      t,
      routes,
      Router,
    };
  }

  const [isSpecializedCNSupported, setIsSpecializedCNSupported] =
    useState(false);
  const {
    isOpen: isSpecializedCNScheduleModalOpen,
    onOpen: openSpecializedCNScheduleModal,
    onClose: closeSpecializedCNScheduleModal,
  } = useDisclosure();

  const {
    isOpen: isSpecializedCNScheduleFlyoutOpen,
    onOpen: openSpecializedCNScheduleFlyout,
    onClose: closeSpecializedCNScheduleFlyout,
  } = useDisclosure();

  const handleFlyoutOpenSpecializedCNAppointmentModal = useCallback(() => {
    closeSpecializedCNScheduleFlyout();
    openSpecializedCNScheduleModal();
  }, [closeSpecializedCNScheduleFlyout, openSpecializedCNScheduleModal]);

  const getMainContentStyles = (
    isResultsPageXLgScreen,
    isResultsPageLgScreen,
    isResultsPageDesktop,
  ) => {
    let styles = {
      marginLeft: "0px",
      marginRight: isResultsPageDesktop ? "24px" : "0px",
      padding: isResultsPageDesktop ? 0 : undefined,
      marginInline: isResultsPageXLgScreen ? "auto" : undefined,
    };

    if (isResultsPageLgScreen) {
      styles.marginLeft = "24px";
    } else if (isResultsPageDesktop) {
      styles.marginLeft = "107px";
    }

    return styles;
  };

  return (
    <AssessmentResultsContext.Provider value={values}>
      {isYourAssessmentResultsPage && (
        <Navigation
          right={
            <RightLinks
              member={memberData}
              firstName={memberData?.user?.first_name}
              lastName={memberData?.user?.last_name}
              isYourAssessmentResultsPage={true}
              displayInline={displayInline}
            />
          }
          isYourAssessmentResultsPage={true}
        />
      )}
      <VStack
        backgroundColor="background-subtle"
        pt={isYourAssessmentResultsPage && 75}
      >
        {isInitialAssessment && <Welcome t={t} />}
        <Box
          pb={isInitialAssessment ? "120px" : 0}
          backgroundColor="background-subtle"
          w="100%"
        >
          {!isInitialAssessment && (
            <SeeResults selectedAssessment={selectedAssessment} t={t} />
          )}

          <main
            id={!inline && "main-content"}
            tabIndex="-1"
            className={
              isYourAssessmentResultsPage ? styles["results-page"] : ""
            }
            style={getMainContentStyles(
              isResultsPageXLgScreen,
              isResultsPageLgScreen,
              isResultsPageDesktop,
            )}
          >
            {values && (
              <Goals type={CARD_TYPE.GOALS} displayInline={displayInline} />
            )}
            {focusCards && focusCards.length > 0 && (
              <Focus type={CARD_TYPE.FOCUS} displayInline={displayInline} />
            )}
            {additionalCards && additionalCards.length > 0 && (
              <AdditionalResults
                type={CARD_TYPE.ADDITIONAL_RESULTS}
                displayInline={displayInline}
              />
            )}

            <DownloadCTA
              selectedAssessment={selectedAssessment}
              t={t}
              Router={Router}
            />
          </main>
          {isInitialAssessment && (
            <FooterCTA
              styles={styles}
              t={t}
              Router={Router}
              assessmentRiskScore={assignRiskScore(selectedAssessment?.risk)}
              assignRiskScore={assignRiskScore}
              setIsSpecializedCNSupported={setIsSpecializedCNSupported}
              openCNFlyout={openSpecializedCNScheduleFlyout}
            />
          )}
        </Box>
      </VStack>
      <SpecializedCareNavigationFlyout
        callback={handleFlyoutOpenSpecializedCNAppointmentModal}
        isOpen={isSpecializedCNScheduleFlyoutOpen}
        onClose={closeSpecializedCNScheduleFlyout}
      />
      <SpecializedCareNavigatorAvailabilityModal
        isOpen={isSpecializedCNScheduleModalOpen}
        isSpecializedCNSupported={isSpecializedCNSupported}
        onClose={() => {
          closeSpecializedCNScheduleModal();
        }}
        onOpen={openSpecializedCNScheduleModal}
        isFromSUDSignalExperience={false}
      />
    </AssessmentResultsContext.Provider>
  );
};

YourAssessmentResults.propTypes = {
  goals: PropTypes.array,
  isInitialAssessment: PropTypes.bool,
  questionnaires: PropTypes.array,
  selectedAssessment: PropTypes.object,
  t: PropTypes.func,
  inline: PropTypes.bool,
  isYourAssessmentResultsPage: PropTypes.bool,
};

export { AssessmentResultsContext };
export default YourAssessmentResults;
