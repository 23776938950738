import { useState, useEffect } from "react";
import { getWorkplaceBannerPosition } from "components/templates/HomePage/utils/helpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useRouter } from "next/router";
import { updateMemberExperienceState } from "operations/mutations/member";
import { useMutation } from "@apollo/client";
import { isGlobalUser } from "utils/global";
import { useWorkplaceCustomizationData } from "modules/MemberDashboard/ManagerExperience/Workplace/hooks/useWorkplaceCustomizationData";
import { useHelpInfo } from "hooks/useHelpInfo";
import { useChannelPartnerReseller } from "modules/MemberDashboard/ManagerExperience/Workplace/hooks/useChannelPartnerReseller";
import { hasPreAssessedExperience } from "utils/memberHelpers";

export const useWorkplaceManagerBanner = (member) => {
  const router = useRouter();
  const isAGlobalMember = isGlobalUser(member?.postal_address?.country);
  const isADependent = member?.covered_life?.dependent;
  const memberIsPrivatePractice = member?.is_private_practice;
  const customerId = member?.cohort?.customer?.id;
  const globalManagerExperienceFlag = useFeatureFlag(
    FLAGS.MANAGER_EXPERIENCE_FOR_GLOBAL_MEMBERS,
  );

  const { helpInfo } = useHelpInfo();
  const crisisSupportNumber = helpInfo?.region?.crisis_support_number;
  const { isWorkplaceCustomizationLoading, customerHasWorkplaceEnabled } =
    useWorkplaceCustomizationData(customerId, crisisSupportNumber);
  const isWorkplaceEnabled =
    !isWorkplaceCustomizationLoading && customerHasWorkplaceEnabled;

  const { isReseller, channelPartnerLoading } = useChannelPartnerReseller();

  const resellerManagerExperienceFlagEnabled = useFeatureFlag(
    FLAGS.MANAGER_EXPERIENCE_MILESTONE_3,
  );

  const resellerHasAccessToWorkplace =
    isReseller &&
    !channelPartnerLoading &&
    resellerManagerExperienceFlagEnabled;

  const globalMemberHasManagerExperienceAccess =
    isAGlobalMember &&
    globalManagerExperienceFlag &&
    !isADependent &&
    !memberIsPrivatePractice &&
    !resellerHasAccessToWorkplace;
  const domesticMemberHasManagerExperienceAccess =
    !isAGlobalMember &&
    !isADependent &&
    !memberIsPrivatePractice &&
    !resellerHasAccessToWorkplace;

  const isAWorkplaceManager = member?.workplace_manager;
  const memberShouldSeeManagerExperienceBanners =
    (isWorkplaceEnabled &&
      (domesticMemberHasManagerExperienceAccess ||
        globalMemberHasManagerExperienceAccess)) ||
    hasPreAssessedExperience(member?.intent_option);

  const dismissedWorkplaceBanner =
    member && member?.experience_state?.workplace_banner_dismissed;
  const canViewWorkplaceBanner = useFeatureFlag(
    FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
  );
  // flag for experiment #2
  //https://springhealth.atlassian.net/browse/MXE-776
  const showSelfIdAtTopExperiment2 = useFeatureFlag(
    FLAGS.MANGER_X_EXPERIMENT_2,
  );

  const workplacePageViewed =
    member && member?.experience_state?.workplace_page_viewed;

  const { showBannerOnTop, showBannerOnBottom, showExploreCard } =
    getWorkplaceBannerPosition(
      isAWorkplaceManager,
      dismissedWorkplaceBanner,
      canViewWorkplaceBanner,
      workplacePageViewed,
      memberShouldSeeManagerExperienceBanners,
      showSelfIdAtTopExperiment2,
      hasPreAssessedExperience(member?.intent_option),
    );

  const [
    showWorkplaceManagerSelfIdCardOnTop,
    setShowWorkplaceManagerSelfIdCardOnTop,
  ] = useState(showBannerOnTop);

  const [
    showWorkplaceManagerSelfIdCardOnBottom,
    setShowWorkplaceManagerSelfIdCardOnBottom,
  ] = useState(showBannerOnBottom);

  useEffect(() => {
    if (
      showBannerOnTop &&
      canViewWorkplaceBanner &&
      memberShouldSeeManagerExperienceBanners
    ) {
      setShowWorkplaceManagerSelfIdCardOnTop(showBannerOnTop);
    }
    if (
      showBannerOnBottom &&
      canViewWorkplaceBanner &&
      memberShouldSeeManagerExperienceBanners
    ) {
      setShowWorkplaceManagerSelfIdCardOnBottom(showBannerOnBottom);
    }
  }, [showBannerOnTop, showBannerOnBottom]);

  const [updateWorkplaceBannerDismissed] = useMutation(
    updateMemberExperienceState,
    {
      variables: {
        member_id: member?.id,
        workplace_banner_dismissed: false,
      },
    },
  );
  /*
    This will detect route change and trigger mutation to 
    display the banner on the correct position when member returns to homepage
  */
  useEffect(() => {
    if (canViewWorkplaceBanner && memberShouldSeeManagerExperienceBanners) {
      const updateWorkplaceBannerDismissedToFalse = () => {
        updateWorkplaceBannerDismissed();
      };
      router.events.on(
        "routeChangeStart",
        updateWorkplaceBannerDismissedToFalse,
      );
      return () => {
        router.events.off(
          "routeChangeStart",
          updateWorkplaceBannerDismissedToFalse,
        );
      };
    }
  }, [canViewWorkplaceBanner]);

  const removeWorkplaceManagerBanner = () => {
    if (showWorkplaceManagerSelfIdCardOnTop) {
      setShowWorkplaceManagerSelfIdCardOnTop(false);
    }
    if (showWorkplaceManagerSelfIdCardOnBottom) {
      setShowWorkplaceManagerSelfIdCardOnBottom(false);
    }
  };

  return {
    showWorkplaceManagerSelfIdCardOnTop,
    showWorkplaceManagerSelfIdCardOnBottom,
    showExploreCard,
    removeWorkplaceManagerBanner,
    memberShouldSeeManagerExperienceBanners,
    isAWorkplaceManager,
  };
};
