import React from 'react';
import PropTypes from 'prop-types';

const DedicatedCareIcon = ({ ariaHidden }) => (
	<svg aria-hidden={ariaHidden?.toString()} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M6 0C4.89543 0 4 0.895431 4 2V4H2C0.895431 4 0 4.89543 0 6V17C0 17.5523 0.447715 18 1 18H17C17.5523 18 18 17.5523 18 17V10C18 8.89543 17.1046 8 16 8H14V2C14 0.895431 13.1046 0 12 0H6ZM13 10C12.4477 10 12 9.55229 12 9V2H6V5C6 5.55228 5.55228 6 5 6H2V16H8V13C8 12.4477 8.44771 12 9 12C9.55229 12 10 12.4477 10 13V16H16V10H13ZM8 4H10V6H8V4ZM6 10V8H4V10H6ZM6 14V12H4V14H6ZM10 8H8V10H10V8ZM12 12H14V14H12V12Z" fill="#1A5856"/>
	</svg>
);

DedicatedCareIcon.propTypes = {
	ariaHidden: PropTypes.bool,
};

export default DedicatedCareIcon;
