import { gql } from "@apollo/client";

const getZendeskJwt = gql`
  query getZendeskJwt($id: ID!) {
    user(id: $id) {
      id
      zendesk_jwt
      zendesk_sso_help_center_jwt
    }
  }
`;

export default getZendeskJwt;
