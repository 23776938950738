import React from "react";

import { Navigation } from "design-system/components";

/**
 * Overlay component is basically the same as the Modal component,
 * but it cannot be dismissed unless an action has been taken in its children.
 */
export const Overlay = (props) => (
  <div>
    <Navigation />
    <div>{props.children}</div>
  </div>
);
