import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

export interface HeaderProps {
  children: ReactNode;
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  children,
}) => {
  return (
    <Flex
      position="absolute"
      width="100%"
      height="auto"
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="flex-end"
      gap="v-8"
      paddingTop="v-8"
      paddingLeft="v-12"
      paddingBottom="v-8"
      paddingRight="v-12"
      top="0"
    >
      {children}
    </Flex>
  );
};

Header.displayName = "Header";
