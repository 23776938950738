import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";

export const trackFormSubmitted = (type, location, toggle, docId) => {
  TRACK_EVENT.FORM_SUBMITTED(routes.MemberSettings.as, titleCase(type), {
    location: titleCase(location),
    value: toggle,
    spring_doc_id: docId,
  });
};

export const titleCase = (str) =>
  str
    .toLowerCase()
    .split("_")
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
