import { gql } from "@apollo/client";

const createSUDZendeskTicket = gql`
  mutation CreateSUDZendeskTicket(
    $schedule_attempt_type: String
    $care_navigator_id: ID
    $sud_signal_flag: String
  ) {
    createSUDZendeskTicket(
      schedule_attempt_type: $schedule_attempt_type
      care_navigator_id: $care_navigator_id
      sud_signal_flag: $sud_signal_flag
    ) {
      success
    }
  }
`;

export default createSUDZendeskTicket;
