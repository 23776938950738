/**
 * Values for appointment attended statuses
 * - Based off of the ATTENDED variable in Rotom, see rough location of var:
 * https://github.com/SpringCare/rotom/blob/master/app/models/appointment.rb#L77
 */

export const AttendedStatuses = Object.freeze({
  AppointmentKept: "Kept",
  MemberLateCancel: "Member late cancel",
  MemberNoShow: "Member did not attend",
  MemberCancel: "Member cancel",
  CaregiverCancel: "Caregiver cancel",
  CaregiverLateCancel: "Caregiver late cancel",
  CaregiverNoShow: "Caregiver did not attend",
  SpringCancel: "Spring Health cancel",
  SpringLateCancel: "Spring Health late cancel",
});

export type AppointmentAttendedStatusValues =
  (typeof AttendedStatuses)[keyof typeof AttendedStatuses];

export const AttendedRotomValues = Object.freeze({
  AppointmentKept: "KEPT",
  MemberLateCancel: "MEMBER_LATE_CANCEL",
  MemberNoShow: "MEMBER_DID_NOT_ATTEND",
  MemberCancel: "MEMBER_CANCEL",
  CaregiverCancel: "CAREGIVER_CANCEL",
  CaregiverLateCancel: "CAREGIVER_LATE_CANCEL",
  CaregiverNoShow: "CAREGIVER_DID_NOT_ATTEND",
  SpringCancel: "SPRING_HEALTH_CANCEL",
  SpringLateCancel: "SPRING_HEALTH_LATE_CANCEL",
});
