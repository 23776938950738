/* eslint-disable no-multi-spaces */

const STATES_LONG = [
	{ label: 'Alabama',                   value: 'AL', country: 'United States of America' },
	{ label: 'Arizona',                   value: 'AZ', country: 'United States of America'  },
	{ label: 'Alaska',                    value: 'AK', country: 'United States of America'  },
	{ label: 'Arkansas',                  value: 'AR', country: 'United States of America'  },
	{ label: 'California',                value: 'CA', country: 'United States of America'  },
	{ label: 'Colorado',                  value: 'CO', country: 'United States of America'  },
	{ label: 'Connecticut',               value: 'CT', country: 'United States of America'  },
	{ label: 'Delaware',                  value: 'DE', country: 'United States of America'  },
	{ label: 'District Of Columbia',      value: 'DC', country: 'United States of America'  },
	{ label: 'Florida',                   value: 'FL', country: 'United States of America'  },
	{ label: 'Georgia',                   value: 'GA', country: 'United States of America'  },
	{ label: 'Hawaii',                    value: 'HI', country: 'United States of America'  },
	{ label: 'Idaho',                     value: 'ID', country: 'United States of America'  },
	{ label: 'Illinois',                  value: 'IL', country: 'United States of America'  },
	{ label: 'Indiana',                   value: 'IN', country: 'United States of America'  },
	{ label: 'Iowa',                      value: 'IA', country: 'United States of America'  },
	{ label: 'Kansas',                    value: 'KS', country: 'United States of America'  },
	{ label: 'Kentucky',                  value: 'KY', country: 'United States of America'  },
	{ label: 'Louisiana',                 value: 'LA', country: 'United States of America'  },
	{ label: 'Maine',                     value: 'ME', country: 'United States of America'  },
	{ label: 'Maryland',                  value: 'MD', country: 'United States of America'  },
	{ label: 'Massachusetts',             value: 'MA', country: 'United States of America'  },
	{ label: 'Michigan',                  value: 'MI', country: 'United States of America'  },
	{ label: 'Minnesota',                 value: 'MN', country: 'United States of America'  },
	{ label: 'Mississippi',               value: 'MS', country: 'United States of America'  },
	{ label: 'Missouri',                  value: 'MO', country: 'United States of America'  },
	{ label: 'Montana',                   value: 'MT', country: 'United States of America'  },
	{ label: 'Nebraska',                  value: 'NE', country: 'United States of America'  },
	{ label: 'Nevada',                    value: 'NV', country: 'United States of America'  },
	{ label: 'New Hampshire',             value: 'NH', country: 'United States of America'  },
	{ label: 'New Jersey',                value: 'NJ', country: 'United States of America'  },
	{ label: 'New Mexico',                value: 'NM', country: 'United States of America'  },
	{ label: 'New York',                  value: 'NY', country: 'United States of America'  },
	{ label: 'North Carolina',            value: 'NC', country: 'United States of America'  },
	{ label: 'North Dakota',              value: 'ND', country: 'United States of America'  },
	{ label: 'Ohio',                      value: 'OH', country: 'United States of America'  },
	{ label: 'Oklahoma',                  value: 'OK', country: 'United States of America'  },
	{ label: 'Oregon',                    value: 'OR', country: 'United States of America'  },
	{ label: 'Pennsylvania',              value: 'PA', country: 'United States of America'  },
	{ label: 'Puerto Rico',               value: 'PR', country: 'United States of America'  },
	{ label: 'Rhode Island',              value: 'RI', country: 'United States of America'  },
	{ label: 'South Carolina',            value: 'SC', country: 'United States of America'  },
	{ label: 'South Dakota',              value: 'SD', country: 'United States of America'  },
	{ label: 'Tennessee',                 value: 'TN', country: 'United States of America'  },
	{ label: 'Texas',                     value: 'TX', country: 'United States of America'  },
	{ label: 'Utah',                      value: 'UT', country: 'United States of America'  },
	{ label: 'Vermont',                   value: 'VT', country: 'United States of America'  },
	{ label: 'Virginia',                  value: 'VA', country: 'United States of America'  },
	{ label: 'Washington',                value: 'WA', country: 'United States of America'  },
	{ label: 'West Virginia',             value: 'WV', country: 'United States of America'  },
	{ label: 'Wisconsin',                 value: 'WI', country: 'United States of America'  },
	{ label: 'Wyoming',                   value: 'WY', country: 'United States of America'  },
	{ label: 'Alberta',                   value: 'AB', country: 'Canada' }, // Canadian provinces start here
	{ label: 'British Columbia',          value: 'BC', country: 'Canada'  },
	{ label: 'Manitoba',                  value: 'MB', country: 'Canada'  },
	{ label: 'New Brunswick',             value: 'NB', country: 'Canada'  },
	{ label: 'Newfoundland and Labrador', value: 'NL', country: 'Canada'  },
	{ label: 'Nova Scotia',               value: 'NS', country: 'Canada'  },
	{ label: 'Ontario',                   value: 'ON', country: 'Canada'  },//this is for Ontario, Canada and we will change this for global 1/1
	{ label: 'Prince Edward Island',      value: 'PE', country: 'Canada'  },
	{ label: 'Quebec',                    value: 'QC', country: 'Canada'  },
	{ label: 'Saskatchewan',              value: 'SK', country: 'Canada'  },
	{ label: 'Buenos Aires', 			  value: 'AR-B', country: 'Argentina' }, // Argentina provinces start here
	{ label: 'Catamarca Province', 		  value: 'AR-K', country: 'Argentina'  },
	{ label: 'Chaco', 					  value: 'AR-H', country: 'Argentina'  },
	{ label: 'Chubut', 					  value: 'AR-U', country: 'Argentina'  },
	{ label: 'Córdoba', 				  value: 'AR-X', country: 'Argentina'  },
	{ label: 'Corrientes', 				  value: 'AR-W', country: 'Argentina'  },
	{ label: 'Entre Ríos', 				  value: 'AR-E', country: 'Argentina'  },
	{ label: 'Formosa', 				  value: 'AR-P', country: 'Argentina'  },
	{ label: 'Jujuy', 					  value: 'AR-Y', country: 'Argentina'  },
	{ label: 'La Pampa', 				  value: 'AR-L', country: 'Argentina'  },
	{ label: 'La Rioja', 				  value: 'AR-F', country: 'Argentina'  },
	{ label: 'Mendoza', 				  value: 'AR-M', country: 'Argentina'  },
	{ label: 'Misiones', 				  value: 'AR-N', country: 'Argentina'  },
	{ label: 'Neuquén', 				  value: 'AR-Q', country: 'Argentina'  },
	{ label: 'Río Negro', 				  value: 'AR-R', country: 'Argentina'  },
	{ label: 'Salta', 					  value: 'AR-A', country: 'Argentina'  },
	{ label: 'San Juan', 				  value: 'AR-J', country: 'Argentina'  },
	{ label: 'San Luis', 				  value: 'AR-D', country: 'Argentina'  },
	{ label: 'Santa Cruz', 				  value: 'AR-Z', country: 'Argentina'  },
	{ label: 'Santa Fe', 				  value: 'AR-S', country: 'Argentina'  },
	{ label: 'Santiago del Estero', 	  value: 'AR-G', country: 'Argentina'  },
	{ label: 'Tierra del Fuego', 		  value: 'AR-FF', country: 'Argentina'  },
	{ label: 'Tucumán', 				  value: 'AR-T', country: 'Argentina'  },
	{ label: 'Aguascalientes', 			  value: 'MX-AGU', country: 'Mexico' }, // Mexican states start here
	{ label: 'Baja California', 		  value: 'MX-BCN', country: 'Mexico' },
	{ label: 'Baja California Sur', 	  value: 'MX-BCS', country: 'Mexico' },
	{ label: 'Campeche', 				  value: 'MX-CAM', country: 'Mexico' },
	{ label: 'Coahuila', 				  value: 'MX-COA', country: 'Mexico' },
	{ label: 'Colima', 					  value: 'MX-COL', country: 'Mexico' },
	{ label: 'Chiapas', 				  value: 'MX-CHP', country: 'Mexico' },
	{ label: 'Chihuahua', 				  value: 'MX-CHH', country: 'Mexico' },
	{ label: 'Durango', 				  value: 'MX-DUR', country: 'Mexico' },
	{ label: 'Guanajuato', 				  value: 'MX-GUA', country: 'Mexico' },
	{ label: 'Guerrero', 				  value: 'MX-GRO', country: 'Mexico' },
	{ label: 'Hidalgo', 				  value: 'MX-HID', country: 'Mexico' },
	{ label: 'Jalisco', 				  value: 'MX-JAL', country: 'Mexico' },
	{ label: 'México', 					  value: 'MX-MEX', country: 'Mexico' },
	{ label: 'Michoacán', 				  value: 'MX-MIC', country: 'Mexico' },
	{ label: 'Morelos', 				  value: 'MX-MOR', country: 'Mexico' },
	{ label: 'Nayarit', 				  value: 'MX-NAY', country: 'Mexico' },
	{ label: 'Nuevo León', 				  value: 'MX-NLE', country: 'Mexico' },
	{ label: 'Oaxaca', 					  value: 'MX-OAX', country: 'Mexico' },
	{ label: 'Puebla', 					  value: 'MX-PUE', country: 'Mexico' },
	{ label: 'Querétaro', 				  value: 'MX-QUE', country: 'Mexico' },
	{ label: 'Quintana Roo', 			  value: 'MX-ROO', country: 'Mexico' },
	{ label: 'San Luis Potosí', 		  value: 'MX-SLP', country: 'Mexico' },
	{ label: 'Sinaloa', 				  value: 'MX-SIN', country: 'Mexico' },
	{ label: 'Sonora', 					  value: 'MX-SON', country: 'Mexico' },
	{ label: 'Tabasco', 				  value: 'MX-TAB', country: 'Mexico' },
	{ label: 'Tamaulipas', 				  value: 'MX-TLA', country: 'Mexico' },
	{ label: 'Veracruz', 				  value: 'MX-VER', country: 'Mexico' },
	{ label: 'Yucatán', 				  value: 'MX-YUC', country: 'Mexico' },
	{ label: 'Zacatecas', 				  value: 'MX-ZAC', country: 'Mexico' },
	{ label: 'Mexico City',               value: 'MX-MXC', country: 'Mexico' },
	{ label: 'Alava', 				  	  value: 'ES-VI', country: 'Spain' }, // Spanish states start here
	{ label: 'Western Andalusia', 		  value: 'ES-ANW', country: 'Spain' },
	{ label: 'Eastern Andalusia', 		  value: 'ES-AN', country: 'Spain' },
	{ label: 'Aragon', 					  value: 'ES-AR', country: 'Spain' },
	{ label: 'Biscay', 					  value: 'ES-PV', country: 'Spain' },
	{ label: 'Cantabria', 				  value: 'ES-S', country: 'Spain' },
	{ label: 'Castilla la Mancha', 		  value: 'ES-CM', country: 'Spain' },
	{ label: 'Castile and Leon', 		  value: 'ES-CL', country: 'Spain' },
	{ label: 'Catalonia', 				  value: 'ES-CT', country: 'Spain' },
	{ label: 'Ceuta', 					  value: 'ES-CE', country: 'Spain' },
	{ label: 'Valencian Community',		  value: 'ES-VC', country: 'Spain' },
	{ label: 'Extremadura', 			  value: 'ES-EX', country: 'Spain' },
	{ label: 'Galicia', 				  value: 'ES-GA', country: 'Spain' },
	{ label: 'Gipuzkoa', 				  value: 'ES-SS', country: 'Spain' },
	{ label: 'Balearic Islands', 		  value: 'ES-IB', country: 'Spain' },
	{ label: 'Madrid', 					  value: 'ES-MD', country: 'Spain' },
	{ label: 'Melilla', 				  value: 'ES-ML', country: 'Spain' },
	{ label: 'Navarre', 				  value: 'ES-NC', country: 'Spain' },
	{ label: 'The Palms', 				  value: 'ES-GC', country: 'Spain' },
	{ label: 'Principality of Asturias',  value: 'ES-AS', country: 'Spain' },
	{ label: 'Region of Murcia', 		  value: 'ES-MU', country: 'Spain' },
	{ label: 'The Rioja', 				  value: 'ES-RI', country: 'Spain' },
	{ label: 'Santa Cruz of Tenerife', 	  value: 'ES-TF', country: 'Spain' },
	{ label: 'Balearic Islands',          value: 'ES-BAL', country: 'Spain' },
	{ label: 'Estremadura',               value: 'ES-BAL', country: 'Spain' },
];

var STATES_SHORT = [
	{ label: 'AL', value: 'AL' },
	{ label: 'AK', value: 'AK' },
	{ label: 'AZ', value: 'AZ' },
	{ label: 'AR', value: 'AR' },
	{ label: 'CA', value: 'CA' },
	{ label: 'CO', value: 'CO' },
	{ label: 'CT', value: 'CT' },
	{ label: 'DE', value: 'DE' },
	{ label: 'DC', value: 'DC' },
	{ label: 'FL', value: 'FL' },
	{ label: 'GA', value: 'GA' },
	{ label: 'HI', value: 'HI' },
	{ label: 'ID', value: 'ID' },
	{ label: 'IL', value: 'IL' },
	{ label: 'IN', value: 'IN' },
	{ label: 'IA', value: 'IA' },
	{ label: 'KS', value: 'KS' },
	{ label: 'KY', value: 'KY' },
	{ label: 'LA', value: 'LA' },
	{ label: 'ME', value: 'ME' },
	{ label: 'MD', value: 'MD' },
	{ label: 'MA', value: 'MA' },
	{ label: 'MI', value: 'MI' },
	{ label: 'MN', value: 'MN' },
	{ label: 'MS', value: 'MS' },
	{ label: 'MO', value: 'MO' },
	{ label: 'MT', value: 'MT' },
	{ label: 'NE', value: 'NE' },
	{ label: 'NV', value: 'NV' },
	{ label: 'NH', value: 'NH' },
	{ label: 'NJ', value: 'NJ' },
	{ label: 'NM', value: 'NM' },
	{ label: 'NY', value: 'NY' },
	{ label: 'NC', value: 'NC' },
	{ label: 'ND', value: 'ND' },
	{ label: 'OH', value: 'OH' },
	{ label: 'OK', value: 'OK' },
	{ label: 'OR', value: 'OR' },
	{ label: 'PA', value: 'PA' },
	{ label: 'PR', value: 'PR' },
	{ label: 'RI', value: 'RI' },
	{ label: 'SC', value: 'SC' },
	{ label: 'SD', value: 'SD' },
	{ label: 'TN', value: 'TN' },
	{ label: 'TX', value: 'TX' },
	{ label: 'UT', value: 'UT' },
	{ label: 'VT', value: 'VT' },
	{ label: 'VA', value: 'VA' },
	{ label: 'WA', value: 'WA' },
	{ label: 'WV', value: 'WV' },
	{ label: 'WI', value: 'WI' },
	{ label: 'WY', value: 'WY' },
	{ label: 'AB', value: 'AB' }, // Canadian provinces start here
	{ label: 'BC', value: 'BC' },
	{ label: 'MB', value: 'MB' },
	{ label: 'NB', value: 'NB' },
	{ label: 'NL', value: 'NL' },
	{ label: 'NS', value: 'NS' },
	{ label: 'ON', value: 'ON' },//this is for Ontario, Canada and we will change this for global 1/1
	{ label: 'PE', value: 'PE' },
	{ label: 'QC', value: 'QC' },
	{ label: 'SK', value: 'SK' },
	{ label: 'AR-B', value: 'AR-B' }, // Argentina provinces start here
	{ label: 'AR-K', value: 'AR-K' },
	{ label: 'AR-H', value: 'AR-H' },
	{ label: 'AR-U', value: 'AR-U' },
	{ label: 'AR-X', value: 'AR-X' },
	{ label: 'AR-W', value: 'AR-W' },
	{ label: 'AR-E', value: 'AR-E' },
	{ label: 'AR-P', value: 'AR-P' },
	{ label: 'AR-Y', value: 'AR-Y' },
	{ label: 'AR-L', value: 'AR-L' },
	{ label: 'AR-F', value: 'AR-F' },
	{ label: 'AR-FF', value: 'AR-FF' },
	{ label: 'AR-M', value: 'AR-M' },
	{ label: 'AR-N', value: 'AR-N' },
	{ label: 'AR-Q', value: 'AR-Q' },
	{ label: 'AR-R', value: 'AR-R' },
	{ label: 'AR-A', value: 'AR-A' },
	{ label: 'AR-J', value: 'AR-J' },
	{ label: 'AR-D', value: 'AR-D' },
	{ label: 'AR-Z', value: 'AR-Z' },
	{ label: 'AR-S', value: 'AR-S' },
	{ label: 'AR-G', value: 'AR-G' },
	{ label: 'AR-V', value: 'AR-V' },
	{ label: 'AR-T', value: 'AR-T' },
	{ label: 'MX-AGU', value: 'MX-AGU' }, // Mexican states start here
	{ label: 'MX-BCN', value: 'MX-BCN' },
	{ label: 'MX-BCS', value: 'MX-BCS' },
	{ label: 'MX-CAM', value: 'MX-CAM' },
	{ label: 'MX-COA', value: 'MX-COA' },
	{ label: 'MX-COL', value: 'MX-COL' },
	{ label: 'MX-CHP', value: 'MX-CHP' },
	{ label: 'MX-CHH', value: 'MX-CHH' },
	{ label: 'MX-DUR', value: 'MX-DUR' },
	{ label: 'MX-GUA', value: 'MX-GUA' },
	{ label: 'MX-GRO', value: 'MX-GRO' },
	{ label: 'MX-HID', value: 'MX-HID' },
	{ label: 'MX-JAL', value: 'MX-JAL' },
	{ label: 'MX-MEX', value: 'MX-MEX' },
	{ label: 'MX-MIC', value: 'MX-MIC' },
	{ label: 'MX-MOR', value: 'MX-MOR' },
	{ label: 'MX-NAY', value: 'MX-NAY' },
	{ label: 'MX-NLE', value: 'MX-NLE' },
	{ label: 'MX-OAX', value: 'MX-OAX' },
	{ label: 'MX-PUE', value: 'MX-PUE' },
	{ label: 'MX-QUE', value: 'MX-QUE' },
	{ label: 'MX-ROO', value: 'MX-ROO' },
	{ label: 'MX-SLP', value: 'MX-SLP' },
	{ label: 'MX-SIN', value: 'MX-SIN' },
	{ label: 'MX-SON', value: 'MX-SON' },
	{ label: 'MX-TAB', value: 'MX-TAB' },
	{ label: 'MX-TAM', value: 'MX-TAM' },
	{ label: 'MX-TLA', value: 'MX-TLA' },
	{ label: 'MX-TLX', value: 'MX-TLX' },
	{ label: 'MX-VER', value: 'MX-VER' },
	{ label: 'MX-YUC', value: 'MX-YUC' },
	{ label: 'MX-ZAC', value: 'MX-ZAC' },
	{ label: 'MX-MXC', value: 'MX-MXC' },
	{ label: 'ES-VI', value: 'ES-VI' }, // Spanish communities start here
	{ label: 'ES-AN', value: 'ES-AN' }, // Eastern and Western Andalusia have the same code
	{ label: 'ES-AR', value: 'ES-AR' },
	{ label: 'ES-PV', value: 'ES-PV' },
	{ label: 'ES-S', value: 'ES-S' },
	{ label: 'ES-CM', value: 'ES-CM' },
	{ label: 'ES-CL', value: 'ES-CL' },
	{ label: 'ES-CT', value: 'ES-CT' },
	{ label: 'ES-CE', value: 'ES-CE' },
	{ label: 'ES-VC', value: 'ES-VC' },
	{ label: 'ES-EX', value: 'ES-EX' },
	{ label: 'ES-GA', value: 'ES-GA' },
	{ label: 'ES-SS', value: 'ES-SS' },
	{ label: 'ES-IB', value: 'ES-IB' },
	{ label: 'ES-MD', value: 'ES-MD' },
	{ label: 'ES-ML', value: 'ES-ML' },
	{ label: 'ES-NC', value: 'ES-NC' },
	{ label: 'ES-GC', value: 'ES-GC' },
	{ label: 'ES-AS', value: 'ES-AS' },
	{ label: 'ES-MU', value: 'ES-MU' },
	{ label: 'ES-RI', value: 'ES-RI' },
	{ label: 'ES-TF', value: 'ES-TF' },
	{ label: 'ES-BAL', value: 'ES-BAL' },
	{ label: 'ES-EST', value: 'ES-EST' },
];

module.exports = {
	STATES_LONG,
	STATES_SHORT,
};
