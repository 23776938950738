"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
var useEscButton = function useEscButton(callback) {
  var escFunction = function escFunction(e) {
    if (e.key === 'Escape') {
      callback();
    }
  };
  (0, _react.useEffect)(function () {
    document.addEventListener('keydown', escFunction, false);
    return function () {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);
};
var _default = exports["default"] = useEscButton;