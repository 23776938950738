import COLORS from '../../../../../constants/colors';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

const TherapistIcon = ({ svgProps = {}, color, paddingTop, title, iconId }) => {
	const suffix = iconId ? `-${iconId}` : '';
	const titleId = title && `therapist-icon${suffix}`;

	return (
		<svg
			className={classnames({ [styles.addPadding]: paddingTop })}
			aria-labelledby={titleId}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="presentation"
			alt=""
			{ ...svgProps }
		>
			{title && <title id={titleId}>{title}</title>}
			<path fill={color || COLORS.PRIMARY_500} clipRule="evenodd" d="M28.7988 0C29.4106 0 29.9439 0.416396 30.0923 1.00995L30.6881 3.39305C31.9504 8.44222 28.1315 13.3333 22.9269 13.3333H16.7988V30.6667C16.7988 31.403 16.2018 32 15.4654 32C14.7291 32 14.1321 31.403 14.1321 30.6667L14.1321 26.6667H8.00394C2.79937 26.6667 -1.01949 21.7756 0.242801 16.7264L0.838575 14.3433C0.986964 13.7497 1.52028 13.3333 2.1321 13.3333H8.79877C10.8477 13.3333 12.7167 14.1036 14.1321 15.3704V8C14.1321 3.58172 17.7138 0 22.1321 0H28.7988ZM14.1321 21.3296C14.1301 18.3858 11.743 16 8.79877 16H3.17313L2.82985 17.3731C1.98832 20.7393 4.53423 24 8.00394 24H14.1321L14.1321 21.3296ZM16.7988 8V10.6667H22.9269C26.3966 10.6667 28.9425 7.40593 28.101 4.03981L27.7577 2.66667H22.1321C19.1866 2.66667 16.7988 5.05448 16.7988 8Z"/>
		</svg>
	);
};

TherapistIcon.propTypes = {
	color      : PropTypes.string,
	title      : PropTypes.string,
	iconId     : PropTypes.string,
	paddingTop : PropTypes.bool,
	svgProps   : PropTypes.shape({
		width  : PropTypes.string,
		height : PropTypes.string,
		role   : PropTypes.string,
		alt    : PropTypes.string,
	}),
};

export default TherapistIcon;
