const STATES_ALLOWED_FOR_16 = ['CO', 'KY', 'MD', 'MA', 'MN', 'MS', 'MT', 'NH', 'NJ', 'SC', 'SD', 'TN', 'TX'];

const STATES_ALLOWED_FOR_13 = ['CA', 'IL', 'FL', 'WA', 'VI'];

const STATES_ALLOWED_FOR_14 = ['AL', 'DE', 'HI', 'KS', 'MI', 'NM', 'OH', 'OR', 'PA', 'VT', 'VA', 'WV', 'WI']; 

const STATES_ALLOWED_FOR_EMPLOYEE_15 = ['AL', 'CA', 'CO', 'CT', 'DC', 'FL', 'HI', 'IL', 'KS', 'MI', 'NM', 'NC', 'OH', 'OR', 'PA', 'TN', 'TX', 'VT', 'VA', 'WA'];

const STATES_ALLOWED_FOR_EMPLOYEE_16 = ['KY', 'MD', 'MA', 'MN', 'MT', 'NY', 'RI'];
  
module.exports = { STATES_ALLOWED_FOR_16, STATES_ALLOWED_FOR_13, STATES_ALLOWED_FOR_14, STATES_ALLOWED_FOR_EMPLOYEE_15, STATES_ALLOWED_FOR_EMPLOYEE_16 };