import { createIcon } from "@chakra-ui/react";

export const Star = createIcon({
  displayName: "Star",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1246 3.18776C10.7689 1.44653 13.2317 1.44652 13.876 3.18776L15.7496 8.25102L20.8128 10.1246C22.5541 10.7689 22.5541 13.2317 20.8128 13.876L15.7496 15.7496L13.876 20.8128C13.2317 22.5541 10.7689 22.5541 10.1246 20.8128L8.25102 15.7496L3.18776 13.876C1.44653 13.2317 1.44652 10.7689 3.18776 10.1246L8.25102 8.25102L10.1246 3.18776ZM14.0334 9.37633L12.0003 3.88184L9.96715 9.37633C9.86586 9.65005 9.65005 9.86586 9.37633 9.96715L3.88184 12.0003L9.37633 14.0334C9.65005 14.1347 9.86586 14.3505 9.96715 14.6243L12.0003 20.1188L14.0334 14.6243C14.1347 14.3505 14.3505 14.1347 14.6243 14.0334L20.1188 12.0003L14.6243 9.96715C14.3505 9.86586 14.1347 9.65005 14.0334 9.37633ZM18.6986 1.99991L19.4273 3.96932L21.3967 4.69807L19.4273 5.42682L18.6986 7.39623L17.9698 5.42682L16.0004 4.69807L17.9698 3.96932L18.6986 1.99991ZM4.50039 20.9999C5.32882 20.9999 6.00039 20.3283 6.00039 19.4999C6.00039 18.6715 5.32882 17.9999 4.50039 17.9999C3.67197 17.9999 3.00039 18.6715 3.00039 19.4999C3.00039 20.3283 3.67197 20.9999 4.50039 20.9999Z"
      fill="#205785"
    />
  ),
  defaultProps: {
    "aria-label": "Star icon",
    role: "img",
  },
});

export const Envelope = createIcon({
  displayName: "Envelope",
  viewBox: "0 0 24 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1V14C24 15.1046 23.1046 16 22 16H2C0.895432 16 0 15.1046 0 14V1ZM4.07518 2L12 7.7635L19.9248 2H4.07518ZM22 2.96377L12.5882 9.80874C12.2375 10.0638 11.7625 10.0638 11.4118 9.80874L2 2.96377V14H22V2.96377Z"
      fill="#205785"
    />
  ),
  defaultProps: {
    "aria-label": "Envelope icon",
    role: "img",
  },
});
