import { gql } from "@apollo/client";

const updateMemberPaymentPreference = gql`
  mutation UpdateMemberInsurancePolicy(
    $member_id: ID!
    $insurance_type: String
    $trigger_cost_estimate_zendesk_ticket: Boolean
  ) {
    updateMemberInsurancePolicy(
      member_id: $member_id
      insurance_type: $insurance_type
      trigger_cost_estimate_zendesk_ticket: $trigger_cost_estimate_zendesk_ticket
    ) {
      member_insurance_policy {
        id
        carrier_name
        insurance_group_id
        insurance_member_id
        plan_name
        insurance_card_back_id
        insurance_card_front_id
        member {
          id
          previsit {
            id
            can_schedule
          }
        }
      }
      success
    }
  }
`;

export default updateMemberPaymentPreference;
