import { Bolded, Content } from "@spring/smeargle";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const UnderageVerificationInfo = () => {
  const { t } = useTranslation("limitedLangAuth");

  return (
    <div className={styles.header}>
      <Bolded semibold center inheritColor noMargin>
        {t("underageVerification.title")}
      </Bolded>

      <div className={styles.headerSubtext}>
        <Content inheritColor>{t("underageVerification.subtitle")}</Content>
      </div>
    </div>
  );
};

export default UnderageVerificationInfo;
