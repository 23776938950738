import { links } from '@spring/constants';

class EnvironmentUtils {
	constructor(processEnv = {}) {
		this.appEnv = processEnv.appEnv;
		this.careTeamEmailEnv = processEnv.careTeamEmail;
		this.retentlyEnvTag = processEnv.retentlyEnvTag;
	}

	get informedConsentLink() {
		return this.isInternalEnv() ? links.InternalInformedConsent : links.InformedConsent;
	}

	get retentlyEnabled() {
		return !!this.retentlyEnvTag;
	}

	get careTeamEmail() {
		return this.careTeamEmailEnv || 'careteam@springhealth.com';
	}

	get magicEnvToggleEnabled() {
		return this.appEnv ? !this.isProductionEnv() : false;
	}

	get redirectCherrimHomepageToSignIn() {
		return this.isInternalOrDemoEnv();
	}

	isInternalEnv() {
		return this.appEnv === 'internal' || this.appEnv === 'internalStaging';
	}

	isInternalOrDemoEnv() {
		return ['internal', 'demo'].includes(this.appEnv);
	}

	isProductionEnv() {
		return ['production', 'internal', 'demo'].includes(this.appEnv);
	}
}

export default EnvironmentUtils;
