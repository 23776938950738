import { gql } from "@apollo/client";

const acknowledgeLateCancelMissedPolicy = gql`
  mutation AcknowledgeLateCancelMissedPolicy($member_id: ID!) {
    acknowledgeLateCancelMissedPolicy(member_id: $member_id) {
      success
    }
  }
`;
export default acknowledgeLateCancelMissedPolicy;
