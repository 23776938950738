import React from "react";
import PropTypes from "prop-types";
import { FlexRow, HugIcon } from "@spring/smeargle";
import { possessive } from "i18n-possessive";

import styles from "./styles.module.scss";

const MinorAppointmentCallout = (props) => (
  <div className={styles.minorAppointmentCallout}>
    <FlexRow alignment="center" justification="center">
      <span className={styles.hugIcon}>
        <HugIcon />
      </span>
      <span>
        {props.isACareNavigator
          ? `For care calls, only ${possessive(props.firstName, "en")} legal guardian needs to be present.`
          : `For initial sessions, both ${props.firstName} and their legal guardian must be present.`}
      </span>
    </FlexRow>
  </div>
);

MinorAppointmentCallout.propTypes = {
  firstName: PropTypes.string.isRequired,
  isACareNavigator: PropTypes.bool.isRequired,
};

MinorAppointmentCallout.defaultProps = {
  isACareNavigator: false,
  firstName: "the child",
};

export default MinorAppointmentCallout;
