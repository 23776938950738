export const CarePlan = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#058261" fill-opacity="0.12" />
      <path
        d="M11.25 11.25C11.25 10.0074 12.2574 9 13.5 9H15.75V9.75C15.75 10.9926 14.7426 12 13.5 12H12.75V17.25C12.75 17.6642 12.4142 18 12 18C11.5858 18 11.25 17.6642 11.25 17.25V14.25H9.75C8.50736 14.25 7.5 13.2426 7.5 12V11.25H9C10.2426 11.25 11.25 12.2574 11.25 13.5V11.25Z"
        fill="#045642"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25 3C4.83579 3 4.5 3.33579 4.5 3.75V20.25C4.5 20.6642 4.83579 21 5.25 21H18.75C19.1642 21 19.5 20.6642 19.5 20.25V3.75C19.5 3.33579 19.1642 3 18.75 3H5.25ZM7.875 6L6.75 4.5H17.25L16.125 6H7.875ZM17.1 7.2L18 6V19.5H6V6L6.9 7.2C7.04164 7.38885 7.26393 7.5 7.5 7.5H16.5C16.7361 7.5 16.9584 7.38885 17.1 7.2Z"
        fill="#045642"
      />
    </svg>
  );
};
