import React from "react";

export const Postpartum = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_967_8429)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.75 4.5C12.75 5.84346 12.1612 7.04941 11.2277 7.87395C11.5973 7.99524 11.9437 8.1355 12.2342 8.27814C12.7668 7.79464 13.474 7.5 14.25 7.5C15.9068 7.5 17.25 8.84315 17.25 10.5C17.25 12.1569 15.9068 13.5 14.25 13.5C13.6936 13.5 13.1726 13.3486 12.726 13.0846L10.6553 15.1553C10.3624 15.4482 9.88753 15.4482 9.59463 15.1553C9.30174 14.8624 9.30174 14.3876 9.59463 14.0947L11.6653 12.024C11.4014 11.5774 11.25 11.0564 11.25 10.5001C11.25 10.5251 11.2503 10.55 11.2509 10.5749C9.53874 10.922 8.24996 12.4355 8.24996 14.2501V16.5001H10.5C12.3145 16.5001 13.828 15.2113 14.1751 13.4991C14.2 13.4998 14.2249 13.5 14.25 13.5C14.7915 13.5 15.2995 13.3566 15.7381 13.1055C15.5553 15.8393 13.28 18.0001 10.5 18.0001H7.49996C7.08575 18.0001 6.74996 17.6643 6.74996 17.2501V14.2501C6.74996 11.8728 8.33006 9.86452 10.4973 9.21826C10.0408 9.08697 9.57348 9 9.20588 9H7.17899C5.3414 9 3.72136 10.2054 3.19333 11.9655C2.92464 12.8611 2.91785 13.7693 3.16401 14.4926C3.40306 15.1951 3.87649 15.7269 4.63235 15.9709C5.02653 16.0982 5.24291 16.5209 5.11565 16.9151C4.98839 17.3092 4.56568 17.5256 4.1715 17.3984C2.91101 16.9914 2.11653 16.0706 1.74398 14.9758C1.37854 13.902 1.41644 12.6683 1.75659 11.5344C2.28051 9.78805 3.59462 8.4431 5.23745 7.84292C4.32409 7.01931 3.74996 5.8267 3.74996 4.5C3.74996 2.01472 5.76468 0 8.24996 0C10.7352 0 12.75 2.01472 12.75 4.5ZM8.24996 1.5C6.59311 1.5 5.24996 2.84315 5.24996 4.5C5.24996 6.15685 6.59311 7.5 8.24996 7.5C9.90682 7.5 11.25 6.15685 11.25 4.5C11.25 2.84315 9.90682 1.5 8.24996 1.5ZM12.75 10.5C12.75 9.67157 13.4215 9 14.25 9C15.0784 9 15.75 9.67157 15.75 10.5C15.75 11.3284 15.0784 12 14.25 12C13.4215 12 12.75 11.3284 12.75 10.5Z"
          fill="#171E22"
        />
      </g>
      <defs>
        <clipPath id="clip0_967_8429">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
