//
import PropTypes from "prop-types";
import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { get } from "lodash/fp";
import { Query } from "@apollo/client/react/components";
import { FlexCol, InputWrapper, Label } from "@spring/smeargle";
import { setField, setTypeaheadSearchValue } from "@spring/smeargle/actions";

// @TODO: There is a bug in babel where importing `export * from`
//        does not compile to `require()` but stays `import`
//        These import the direct path to fix breaking tests
//        the `/actions` and `/types` should be removed in the future
import sharedStyles from "components/form/sharedStyles.module.scss";

import styles from "./styles.module.scss";

import { formFieldSelector } from "selectors/form";

class WrappedTypeahead extends Component {
  static propTypes = {
    data: PropTypes.any,
    dataPath: PropTypes.any,
    dirty: PropTypes.any,
    disabled: PropTypes.bool,
    displayKey: PropTypes.any,
    fieldKey: PropTypes.any,
    formKey: PropTypes.any,
    full: PropTypes.bool,
    inline: PropTypes.bool,
    label: PropTypes.any,
    max: PropTypes.any,
    maxLength: PropTypes.any,
    min: PropTypes.any,
    minLength: PropTypes.any,
    placeholder: PropTypes.string,
    refetch: PropTypes.func,
    searchField: PropTypes.any,
    searchValue: PropTypes.string,
    setField: PropTypes.func,
    setTypeaheadSearchValue: PropTypes.func,
    theme: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    variables: PropTypes.any,
  };

  static defaultProps = {
    disabled: false,
    inline: false,
    full: false,
    placeholder: "",
    type: "text",
  };

  dropdown;
  state = { open: false };

  componentDidMount() {
    if (document) {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyPress);
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  }

  handleFocus = () => {
    let search = "";

    const { value, displayKey } = this.props;

    if (value) {
      search = value[displayKey];
    }

    this.open();
    this.getResults(search);
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 9) {
      this.handleBlurred();
    }
  };

  handleClickOutside = (e) => {
    if (this.dropdown && !this.dropdown.contains(e.target)) {
      this.handleBlurred();
    }
  };

  handleBlurred = () => {
    this.close();

    const { value, displayKey, searchValue } = this.props;
    const display = value[displayKey];

    if (this.props.searchValue === "") {
      this.props.setField(this.props.formKey, this.props.fieldKey, "", true);
    } else {
      // If the field blurred without setting a new value, reset the
      // search value to indicate there is no value set.
      if (display !== searchValue) {
        this.props.setTypeaheadSearchValue(
          this.props.formKey,
          this.props.fieldKey,
          value ? value[displayKey] : "",
        );
      }
    }
  };

  handleChange = (e) => {
    const target = e.target;

    this.getResults(target.value);

    this.props.setTypeaheadSearchValue(
      this.props.formKey,
      this.props.fieldKey,
      target.value,
    );
  };

  selectOption = (value) => {
    const selected = value[this.props.displayKey];

    this.props.setTypeaheadSearchValue(
      this.props.formKey,
      this.props.fieldKey,
      selected,
    );

    this.props.setField(this.props.formKey, this.props.fieldKey, value, true);

    this.close();
  };

  getResults = (search) => {
    this.props.refetch({
      limit: 10,
      [this.props.searchField]: search,
      ...this.props.variables,
    });
  };

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  get searchValue() {
    // if there is a search, use the content of that
    if (typeof this.props.searchValue === "string") {
      return this.props.searchValue;
    } else if (this.props.value) {
      return this.props.value[this.props.displayKey];
    }

    return "";
  }

  get label() {
    if (this.props.label) {
      return (
        <Label
          disabled={this.props.disabled}
          formKey={this.props.formKey}
          fieldKey={this.props.fieldKey}
          theme={this.props.theme}
        >
          {this.props.label}
        </Label>
      );
    }

    return null;
  }

  get showValidation() {
    // show if the field has an error and is dirty
    return (
      !!get("clientValidation.message", this.props) &&
      (!!this.props.dirty || !!this.props.value)
    );
  }

  // Material theme inputs need a specific placholder
  get placeholder() {
    if (this.props.theme === "material") {
      return " ";
    }

    return this.props.placeholder || "";
  }

  get options() {
    if (
      this.state.open &&
      this.props.data &&
      this.props.data[this.props.dataPath]
    ) {
      let results;
      if (this.props.data[this.props.dataPath].data.length) {
        const options = this.props.data[this.props.dataPath].data;

        results = options.map((opt) => {
          const display = opt[this.props.displayKey];
          const id = opt.id || "";

          return (
            <div
              key={`${display}-${id}`}
              className={styles.option}
              onClick={() => {
                this.selectOption(opt);
              }}
            >
              {display}
            </div>
          );
        });
      } else {
        results = (
          <div
            key="No Results"
            className={classnames(styles.option, styles.empty)}
          >
            No Results
          </div>
        );
      }

      return (
        <div
          ref={(dropdown) => {
            this.dropdown = dropdown;
          }}
          className={classnames(styles.results, styles[this.props.theme])}
        >
          <FlexCol>{results}</FlexCol>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <InputWrapper
        full={this.props.full}
        inline={this.props.inline}
        valid={this.showValidation}
        theme={this.props.theme}
      >
        {this.options}
        <input
          id={`${this.props.formKey}-${this.props.fieldKey}`}
          className={classnames(sharedStyles.inputField, styles.input, {
            [styles.noValue]: !this.props.value,
            [sharedStyles.full]: this.props.full,
            [sharedStyles.invalid]: this.showValidation,
            [sharedStyles[this.props.theme]]: this.props.theme,
          })}
          autoComplete="off"
          type={this.props.type}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          value={this.searchValue}
          placeholder={this.placeholder}
          max={this.props.max}
          min={this.props.min}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
        />
        {this.label}
      </InputWrapper>
    );
  }
}

const QueryTypeahead = (props) => {
  const typeahead = ({ loading, data, refetch, error, fetchMore }) => (
    <WrappedTypeahead
      {...props}
      loading={loading}
      data={data}
      refetch={refetch}
      fetchMore={fetchMore}
      error={error}
    />
  );

  return (
    <Query query={props.query} delay>
      {typeahead}
    </Query>
  );
};

QueryTypeahead.propTypes = {
  query: PropTypes.any,
};

export { QueryTypeahead };
export default connect(
  (state, ownProps) => ({
    ...formFieldSelector(state, ownProps),
    searchValue: get(
      `${ownProps.formKey}.meta.${ownProps.fieldKey}.searchValue`,
      state.form,
    ),
  }),
  { setField, setTypeaheadSearchValue },
)(QueryTypeahead);
