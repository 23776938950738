import React from "react";
import PropTypes from "prop-types";

const RiskLevelArrow = ({ title, kind }) => (
  <svg
    width={10}
    height={35}
    viewBox="0 0 10 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby={`${kind}-${title}`}
    tabIndex={0}
  >
    <title id={`risk-level-indicator-${kind}`}>{title}</title>
    <path
      d="M5.75 8.25L9.33.75H.67l3.58 7.5h1.5zm-1.5 25.5a.75.75 0 001.5 0h-1.5zm0-26.25v26.25h1.5V7.5h-1.5z"
      fill="#182023"
    />
  </svg>
);

export default RiskLevelArrow;

RiskLevelArrow.propTypes = {
  title: PropTypes.string,
  kind: PropTypes.string,
};
