import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const generateAssessment = gql`
  mutation GenerateAssessment(
    $member_id: ID!
    $kind: AssessmentKindEnumType!
    $questionnaires_order: [QuestionnaireName]
  ) {
    generateAssessment(
      member_id: $member_id
      kind: $kind
      questionnaires_order: $questionnaires_order
    ) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default generateAssessment;
