import { gql } from "@apollo/client";

const updateMemberPrivacyPolicyAgreedAt = gql`
  mutation updateMemberPrivacyPolicyAgreedAt(
    $input: MemberPrivacyPolicyAgreedAtInput!
  ) {
    updateMemberPrivacyPolicyAgreedAt(input: $input) {
      success
      member {
        id
        valid_privacy_policy_agreement
      }
    }
  }
`;

export default updateMemberPrivacyPolicyAgreedAt;
