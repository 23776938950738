import NextLink from "next/link";
import { useTranslation } from "react-i18next";
import { VButton } from "design-system/components";
import { VPlusIcon } from "design-system/assets/verdant";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";

export const AddMinorButton = () => {
  const { t } = useTranslation("homepage");
  const trackClick = () =>
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      routes["InviteDependents"].as,
      "Add Child",
      { spring_doc_id: "famhp002", location: "Your Family" },
    );

  return (
    <NextLink href={routes["InviteDependents"].as}>
      <VButton
        role="link"
        variant="medium-emphasis"
        icon={VPlusIcon}
        width="100%"
        onClick={trackClick}
      >
        {t("familySection.yourFamily.addChild")}
      </VButton>
    </NextLink>
  );
};
