import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";
import paging from "operations/fragments/paging";

const getInitialAssessment = gql`
  query getInitialAssessment(
    $id: ID
    $status: [String] = ["Finished"]
    $limit: Int
  ) {
    assessments(
      member_id: $id
      status: $status
      kind: INITIAL_ASSESSMENT
      limit: $limit
    ) {
      data {
        ...assessmentToTake
        ended_at
        metadata
        questionnaires(version: "improved_sud") {
          results_sanitized
        }
      }
      paging {
        ...paging
      }
    }
  }
  ${assessmentToTake}
  ${paging}
`;

export default getInitialAssessment;
