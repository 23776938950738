import { get, compose } from "lodash/fp";
import routes from "routes";
import { images } from "@spring/constants";
import { careProviderKinds } from "components/templates/CareVisitsPage/components/Tabs/constants";

import type { FindNewCareCardsConfigType } from "components/templates/CareVisitsPage/components/Tabs/types";

const getMember = get("user.member");
const getCareItems = compose(get("care_items"), getMember);

export const getAvailableCareItems = compose(
  get("available_care_items"),
  getCareItems,
);

export const getFindNewCareCardConfig = (
  isAMinor: boolean,
): FindNewCareCardsConfigType => {
  return {
    THERAPIST: {
      kind: careProviderKinds.THERAPIST,
      imageUrl: images.TherapyCareCard,
      route: routes.TherapistsBrowse,
      trackingText: "Therapist Care Card",
    },
    COACH: {
      kind: careProviderKinds.COACH,
      imageUrl: images.CoachingCareCard,
      route: routes.CoachesBrowse,
      trackingText: "Coach Care Card",
      showMinorDescription: isAMinor,
    },
    MEDICATION_MANAGER: {
      kind: careProviderKinds.MEDICATION_MANAGER,
      imageUrl: images.MedicationManagerCareCard,
      route: routes.Medication_ManagersBrowse,
      trackingText: "Medication Manager Care Card",
    },
    SUD_CN: {
      kind: careProviderKinds.SUD_CN,
      imageUrl: images.SUDCNCard,
      route: routes.SubstanceUseSupport,
      trackingText: "SUD CN Care Card",
    },
  };
};
