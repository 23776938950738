import { gql } from "@apollo/client";

const getAppointmentSlotsV2 = gql`
  query FetchAppointmentSlotsV2(
    $user_ids: AppointmentUsersInput!
    $kind: AppointmentKindEnum!
    $medium: AppointmentMediumEnum
    $upcoming_slots_only: Boolean
    $upcoming_slots_count: Int = 3
    $range: AppointmentDateRangeInput!
    $filters: CareProviderFiltersInputType
  ) {
    appointment_slots_v2(
      user_ids: $user_ids
      kind: $kind
      medium: $medium
      upcoming_slots_only: $upcoming_slots_only
      upcoming_slots_count: $upcoming_slots_count
      range: $range
      filters: $filters
    ) {
      in_person {
        available
        blocked
        duration
        slot_increment
      }
      video {
        available
        blocked
        duration
        slot_increment
      }
      next_available_appointment {
        medium
        appointment_slot
      }
      __typename
    }
  }
`;

export default getAppointmentSlotsV2;
