const routes = {
  ProvidersBrowse: {
    to: "/apps/Browse",
    as: "/browse",
  },
  ProvidersBrowseDetail: {
    to: "/apps/Browse/providers",
    as: "/browse/providers",
  },
  Medication_ManagersBrowse: {
    to: "/apps/Browse/medication_managers",
    as: "/browse/medication_managers",
  },
  Medication_ManagersBrowseDetail: {
    to: "/apps/Browse/medication_managers/:id",
    as: "/browse/medication_managers/:id",
  },
  TherapistsBrowse: {
    to: "/apps/Browse/therapists",
    as: "/browse/therapists",
  },
  CoachesBrowse: {
    to: "/apps/Browse/coaches",
    as: "/browse/coaches",
  },
  TherapistsBrowseDetail: {
    to: "/apps/Browse/therapists/:id",
    as: "/browse/therapists/:id",
  },
  WorkplaceConsultationsBrowse: {
    to: "/apps/Browse/workplace_consultations",
    as: "/browse/workplace_consultations",
  },
  WorkplaceConsultationsBrowseDetail: {
    to: "/apps/Browse/workplace_consultations/:id",
    as: "/browse/workplace_consultations:/:id",
  },
};

export default routes;
