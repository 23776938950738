//we replaced zendesk chatbot with Ada.  Ada is an AI powered chatbot that does communicate with zendesk
//Details on Ada tech implementation: https://drive.google.com/drive/folders/1yoCT_1KCetOZMfxVSmLPeRg1nODOmA-j
import { openZendeskChat } from "utils/zendesk";
import { createOrUpdateZendeskUser } from "utils/zendeskHelpers";

export const PROD_ADA_HANDLE = "springhealth";

export const LOWER_ENV_ADA_HANDLE = "demo-springhealth";

export const ADA_SCRIPT_ID = "__ada";

export const ADA_SCRIPT_SRC = "https://static.ada.support/embed2.js";

export const openAdaChatbot = async () => {
  const email = localStorage.getItem("uid");

  const dataHandle =
    process.env.APP_ENV === "production" || process.env.APP_ENV === "prod"
      ? PROD_ADA_HANDLE
      : LOWER_ENV_ADA_HANDLE;

  window.adaSettings = {
    metaFields: {
      email: email,
    },
  };
  // Check if the script is already present
  if (document.getElementById(ADA_SCRIPT_ID)) {
    //open chat
    window?.adaEmbed?.toggle();
    return;
  }
  try {
    const adaChatbotScript = document.createElement("script");
    adaChatbotScript.id = ADA_SCRIPT_ID;
    adaChatbotScript.setAttribute("data-handle", dataHandle);
    adaChatbotScript.src = ADA_SCRIPT_SRC;

    adaChatbotScript.async = true;
    document.body.appendChild(adaChatbotScript);
    //open chat after script is loaded
    adaChatbotScript.onload = () => {
      window?.adaEmbed?.toggle();
    };

    await createOrUpdateZendeskUser();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[ADA CHATBOT] Error loading Ada chatbot", e);

    openZendeskChat(); //back-up if issue w/ Ada chatbot
  }
};
