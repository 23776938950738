import { gql } from "@apollo/client";

const updateAppointment = gql`
  mutation UpdateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      success
      appointment {
        id
        status
      }
    }
  }
`;

export default updateAppointment;
