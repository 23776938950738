import { DateTime } from "luxon";
import { AppointmentMedium } from "@spring/constants";

const getInitialStartTime = (locale, available) => {
  if (Array.isArray(available) && available.length) {
    const dateTime = DateTime.fromISO(available[0]).setLocale(locale);
    const localTime = dateTime.toLocal();
    return localTime.toISO();
  }
  return DateTime.local()
    .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    .toISO();
};

const getEndSpan = (startSpan) => {
  return DateTime.fromISO(startSpan)
    .plus({ days: 29 })
    .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
    .toISO();
};

const getStartSpan = (kind) => {
  if (kind) {
    return getBufferedTime();
  }
};

const getBufferedTime = (
  time = DateTime.local(),
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
) => {
  return time
    .plus({ hours: hours })
    .set({ minutes: minutes, seconds: seconds, milliseconds: milliseconds })
    .toISO();
};

const getSupportsInPersonTherapy = (
  provider,
  memberHasInPersonSupport,
  memberCountry,
) =>
  memberHasInPersonSupport &&
  provider.in_person_supported &&
  provider.roles.includes("Therapist") &&
  memberCountry === "US";

const getAppointmentSlotStartTimeAndMedium = (
  upcomingAppointmentSlots,
  provider,
  memberHasInPersonSupport = false,
  memberCountry = "",
) => {
  let initialStartTime;
  let medium;

  const phoneStartTime =
    upcomingAppointmentSlots?.appointment_slots?.available[0];
  const videoStartTime =
    upcomingAppointmentSlots?.firstVirtualSlot?.appointment_slots?.available[0];
  const inPersonStartTime =
    upcomingAppointmentSlots?.firstInPersonSlot?.appointment_slots
      ?.available[0];

  const videoSlotOnly = videoStartTime && !inPersonStartTime;
  const inPersonSlotOnly = !videoStartTime && inPersonStartTime;
  const videoAndInPersonSlots = videoStartTime && inPersonStartTime;

  if (phoneStartTime) {
    initialStartTime = phoneStartTime;
    medium = AppointmentMedium.Phone;
  } else if (videoSlotOnly) {
    initialStartTime = videoStartTime;
    medium = AppointmentMedium.Video;
  } else if (inPersonSlotOnly) {
    initialStartTime = inPersonStartTime;
    medium = AppointmentMedium.InPerson;
  } else if (videoAndInPersonSlots) {
    if (provider.roles.includes("Therapist")) {
      const supportsInPersonTherapy = getSupportsInPersonTherapy(
        provider,
        memberHasInPersonSupport,
        memberCountry,
      );
      initialStartTime =
        videoStartTime > inPersonStartTime && supportsInPersonTherapy
          ? inPersonStartTime
          : videoStartTime;
      medium =
        videoStartTime > inPersonStartTime && supportsInPersonTherapy
          ? AppointmentMedium.InPerson
          : AppointmentMedium.Video;
    } else {
      initialStartTime =
        videoStartTime > inPersonStartTime ? inPersonStartTime : videoStartTime;
      medium =
        videoStartTime > inPersonStartTime
          ? AppointmentMedium.InPerson
          : AppointmentMedium.Video;
    }
  }

  if (!medium) {
    medium = provider.supported_appointment_mediums[0];
  }

  return { initialStartTime, medium };
};

const isPastAppointment = (appt) => {
  const now = DateTime.local().toJSDate().toISOString();
  const appointmentEnd = DateTime.fromISO(appt.start_at)
    .plus({ minutes: appt.duration })
    .toLocal()
    .toJSDate()
    .toISOString();
  return now > appointmentEnd || appt.cancelled_for_member;
};
const isFutureAppointment = (appt) => {
  const now = DateTime.local().toJSDate().toISOString();
  const appointmentEnd = DateTime.fromISO(appt.start_at)
    .plus({ minutes: appt.duration })
    .toLocal()
    .toJSDate()
    .toISOString();
  return now < appointmentEnd && !appt.cancelled_for_member;
};
export {
  getInitialStartTime,
  getEndSpan,
  getStartSpan,
  getBufferedTime,
  getSupportsInPersonTherapy,
  getAppointmentSlotStartTimeAndMedium,
  isPastAppointment,
  isFutureAppointment,
};
