import { gql } from "@apollo/client";

import { userInfo } from "operations/fragments/user";

const updateUser = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      user {
        ...userInfo
      }
    }
  }
  ${userInfo}
`;

export default updateUser;
