import { useTranslation } from "hooks/react-i18next";

import { Box, Text } from "design-system/components";

type Props = {
  scrollToSection: () => void;
};

const TravelDisclaimer = ({ scrollToSection }: Props) => {
  const { t } = useTranslation(["careProvider", "common"]);

  return (
    <Box
      h="fit-content"
      ml="auto"
      p={12}
      bg="#20578529"
      color="info.600"
      borderRadius={8}
    >
      <Text as="i" variant="body_v1">
        {t("browserPageWrapper.travelDisclaimer")}
      </Text>
      <Box as="button" onClick={() => scrollToSection()}>
        <i>
          <Text as="u" ml={8}>
            {t("browserPageWrapper.travelDisclaimerLink")}
          </Text>
        </i>
      </Box>
    </Box>
  );
};

export default TravelDisclaimer;
