import { Flex } from "@chakra-ui/react";

const CarouselItem = ({
  setActiveItem,
  constraint,
  itemWidth,
  positions,
  children,
  index,
}) => {
  const handleKeyUp = (event) => {
    if (event.key === "Tab" && index <= positions.length - 1 - constraint)
      setActiveItem(index);
  };

  return (
    <Flex onKeyUp={handleKeyUp} w={itemWidth} py={2}>
      {children}
    </Flex>
  );
};

export default CarouselItem;
