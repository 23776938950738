/* eslint-disable react/jsx-key */
import { createIcon } from "@chakra-ui/react";

export const Dollar = createIcon({
  displayName: "Provider",
  viewBox: "0 0 24 24",
  path: [
    <path
      fill="currentColor"
      d="M11.5824 9.06219C11.7904 8.76971 12.1962 8.70127 12.4887 8.90934L13.8331 9.86574C14.2832 10.1859 14.9075 10.0806 15.2277 9.63055C15.5478 9.18052 15.4425 8.55617 14.9925 8.23603L13.648 7.27963C13.5946 7.24165 13.5403 7.20592 13.4852 7.17244C13.4949 7.11642 13.5 7.05881 13.5 7V5C13.5 4.44772 13.0523 4 12.5 4C11.9477 4 11.5 4.44772 11.5 5V6.8606C10.8965 7.00358 10.3404 7.3578 9.95267 7.90286C9.10432 9.0954 9.38335 10.7499 10.5759 11.5982L13.2648 13.511C13.5573 13.7191 13.6257 14.1249 13.4176 14.4173C13.2096 14.7098 12.8038 14.7783 12.5113 14.5702L11.1669 13.6138C10.7168 13.2937 10.0925 13.3989 9.77235 13.849C9.4522 14.299 9.5575 14.9234 10.0075 15.2435L11.352 16.1999C11.4006 16.2345 11.4499 16.2672 11.5 16.298V18.5C11.5 19.0523 11.9477 19.5 12.5 19.5C13.0523 19.5 13.5 19.0523 13.5 18.5V16.6189C14.1035 16.476 14.6596 16.1217 15.0473 15.5767C15.8957 14.3841 15.6167 12.7297 14.4241 11.8813L11.7352 9.96851C11.4427 9.76045 11.3743 9.35467 11.5824 9.06219Z"
    />,
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0ZM2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12Z"
    />,
  ],
  defaultProps: {
    color: "platform.900",
  },
});
