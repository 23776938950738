/**
 * Enums used to determine Specialize Coaching Topics
 */

const SpecializeCoachingTopics = Object.freeze({
	HEALTH_WELLNESS: "healthWellness",
    PERSONAL_DEVELOPMENT: "personalDevelopment",
    PROFESSIONAL_GROWTH: "professionalGrowth",
    PARENTING: "parenting",
});

module.exports = SpecializeCoachingTopics;
