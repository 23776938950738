//

import React, { useEffect, useRef } from "react";
import { isEmpty, get, getOr } from "lodash/fp";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { Section, LoadingCircle } from "@spring/smeargle";
import { addNotification, openModal } from "@spring/smeargle/actions";
import {
  modalIds,
  RequestableAppointmentKind,
  AppointmentAction,
} from "@spring/constants";
import Meowth from "@spring/meowth";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  ProviderMatchingModal,
  AvailabilityRequestModal,
  CareProviderScheduleModalV2,
} from "components/modals";

import ProviderResult from "./ProviderResult";
import Pagination from "./Pagination";

import {
  getMemberConsent,
  getMemberAddress,
  getMemberInfo,
} from "operations/queries/member";
import { browseAllMyProviders } from "operations/queries/careProvider";
import { requestCareTeamChange } from "operations/mutations/member";
import { requestAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import { isLegacyMinor, isMinor } from "utils/memberHelpers";
import envUtils from "utils/environment";
import routes from "routes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";
import { useScheduleModalWithProps } from "shared/hooks";

const Listing = (props) => {
  const didComponentMount = useRef();
  const router = useRouter();
  const { visits_covered_coaching } = props.memberInfo?.user?.member || {};
  const { unlimited_coaching_direct_visits } =
    props.memberInfo?.user?.member?.cohort || {};
  const paidCoachingExperience =
    visits_covered_coaching !== null && visits_covered_coaching >= 0;
  const enableDirectSchedulingInCoaching =
    paidCoachingExperience || unlimited_coaching_direct_visits;
  const member = props.memberInfo?.user?.member;

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);
  const coach = props.careTeamData?.user?.member?.care_team?.coach;

  useEffect(() => {
    if (props.memberInfo && !enableDirectSchedulingInCoaching && !coach) {
      const { to, as } = routes.MemberHome;
      router.push(to, as);
    }
  }, [props.memberInfo]);

  useEffect(() => {
    if (!didComponentMount.current) {
      const options = {
        role: props.providerType,
      };

      if (!isEmpty(props.tagData)) {
        const { specialtiesTags, gendersTags, ethnicitiesTags, languagesTags } =
          props.tagData;
        options.care_provider_tag_ids = [
          ...conditionallyInsert(specialtiesTags),
          ...conditionallyInsert(gendersTags),
          ...conditionallyInsert(ethnicitiesTags),
        ];
        options.languages = [...conditionallyInsert(languagesTags)];
      }

      // Load all the providers (mounts without requesting so pagination will work)
      props.refetch(options);
    } else {
      if (!isEmpty(props.tagData)) {
        const { specialtiesTags, gendersTags, ethnicitiesTags, languagesTags } =
          props.tagData;
        props.refetch({
          care_provider_tag_ids: [
            ...conditionallyInsert(specialtiesTags),
            ...conditionallyInsert(gendersTags),
            ...conditionallyInsert(ethnicitiesTags),
          ],
          languages: [...conditionallyInsert(languagesTags)],
        });
      }
    }
  }, [props.tagData]);

  // Will conditionally add tags of different kinds if they exist.
  const conditionallyInsert = (tags = []) =>
    !Array.isArray(tags) || !tags.length ? [] : tags;

  const providerRole = props.providerType;

  const appointmentKind = RequestableAppointmentKind.Coaching;

  const modalId = () => {
    return modalIds.careProviderScheduleModal;
  };

  const isALegacyMinor = isLegacyMinor(
    getOr({}, "memberInfo.user.member", props),
  );

  const isAMinor = isMinor(getOr({}, "memberInfo.user.member", props));

  const modalData = (provider) => {
    let role = "Coach";

    return {
      ...provider,
      providerRole: role,
      kind: appointmentKind,
      medium: appointmentMedium(provider),
      supportsInPerson: false,
    };
  };

  const appointmentMedium = (provider) =>
    provider.supported_appointment_mediums[0]; // TODO: logic will change in the future

  const schedule = (provider, index) => {
    if (shouldShowNewInsuranceModal) {
      onScheduleModalOpen({
        supportsInPerson: false,
        kind: appointmentKind,
        provider,
        buttonText: props.t("listing.confirmAppointment"),
        action: AppointmentAction.Create,
        providerOrder: index,
      });
    } else {
      return props.openModal(modalId(), {
        ...modalData(provider),
        videoTherapy: true,
        buttonText: props.t("listing.confirmAppointment"),
        action: AppointmentAction.Create,
        providerOrder: index,
        isAMinor,
      });
    }
  };
  const requestAvailability = async (provider, medium, kind) => {
    try {
      const payload = {
        care_provider_id: provider.id,
        availability_days_of_week: ["Unknown"],
        availability_time_of_day: ["Unknown"],
        medium,
        kind,
      };

      const {
        data: { requestAppointment },
      } = await props.requestAppointment(payload);

      if (!requestAppointment.success) {
        const { careTeamEmail } = envUtils;
        const message = (
          <Trans
            ns={"careProvider"}
            i18nKey={"listings.errorNotificationMessage"}
          >
            Oh no! We were not able to put this request through. Please reach
            out to {{ careTeamEmail }} with the name of the {{ providerRole }}{" "}
            you would like to schedule with, and a Care Navigator will assist
            you.
          </Trans>
        );
        return props.addNotification(message, "error", 0);
      }

      return props.openModal(modalIds.availabilityRequestModal, {
        ...modalData(provider),
      });
    } catch (err) {
      return props.addNotification(getFirstError(err), "error");
    }
  };

  const providers = (member) => {
    const providersData = getOr([], `data.browse_care_providers.data`, props);
    const pagingData = getOr([], `data.browse_care_providers.paging`, props);

    if (props.loading) {
      return <LoadingCircle />;
    }

    TRACK_EVENT.COMPONENTS_LOADED(window.location.pathname, "Provider Cards", {
      total_providers_available: pagingData.total,
      total_num_pages: pagingData.pages,
      total_providers_displayed:
        pagingData.page === pagingData.pages ? pagingData.total % 10 : 10,
    });

    const renderProviders = providersData.map((p, index) => (
      <Section key={p.id} size="xlg">
        <ProviderResult
          provider={p}
          member={member}
          providerIndex={index}
          pageNumber={pagingData.page}
          appointmentMedium={appointmentMedium(p)}
          appointmentKind={appointmentKind}
          modalData={() => modalData(p)}
          supportsInPerson={false}
          schedule={() => schedule(p, index)}
          requestAvailability={requestAvailability}
          isALegacyMinor={isALegacyMinor}
          isAMinor={isAMinor}
          showGlobalExperience={props.showGlobalExperience}
          supportsVirtual
          showLongBioSnippet
        />
      </Section>
    ));

    return <>{renderProviders}</>;
  };

  const pagination = () => {
    const providersData = getOr([], `data.browse_care_providers.data`, props);

    if (!providersData.length) {
      return null;
    }

    return (
      <Pagination
        refetch={props.refetch}
        loading={props.loading}
        currentPage={get(`data.browse_care_providers.paging.page`, props)}
        totalPages={get(`data.browse_care_providers.paging.pages`, props)}
        limit={get(`data.browse_care_providers.paging.limit`, props)}
      />
    );
  };

  const listings = (member) => {
    return (
      <>
        {providers(member)}
        {pagination()}
      </>
    );
  };

  if (props.loading) {
    return <LoadingCircle />;
  }

  return (
    <div ref={didComponentMount}>
      {listings(member)}
      <ProviderMatchingModal />
      <AvailabilityRequestModal />
      <CareProviderScheduleModalV2
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
        {...scheduleModalProps}
      />
    </div>
  );
};

Listing.propTypes = {
  addNotification: PropTypes.func,
  loading: PropTypes.any,
  openModal: PropTypes.func,
  providerType: PropTypes.any,
  refetch: PropTypes.func,
  requestAppointment: PropTypes.func,
  showGlobalExperience: PropTypes.any,
  t: PropTypes.func,
  tagData: PropTypes.shape({
    conditionsTags: PropTypes.any,
    specialtiesTags: PropTypes.any,
  }),
};

export { Listing };

const mapStateToProps = (state) => ({
  showGlobalExperience: state.global.showGlobalExperience,
});

const WrappedListing = compose(
  graphql(requestCareTeamChange, { name: "requestCareTeamChange" }),
  graphql(getMemberConsent, {
    options: Meowth.apolloOptionsUserId,
    name: "memberConsent",
  }),
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (input) =>
        mutate({
          variables: { input },
        }),
    }),
  }),
  connect(mapStateToProps, { addNotification, openModal }),
)(withTranslation(["coachesProvider", "careProvider"])(Listing));

const FinalListing = (props) => {
  const { data: memberAddressData, loading: memberAddressLoading } = useQuery(
    getMemberAddress,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );
  const { data: memberInfoData, loading: memberInfoLoading } = useQuery(
    getMemberInfo,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  const { loading, data, refetch, error } = useQuery(browseAllMyProviders);

  return (
    <WrappedListing
      {...props}
      tagData={props.tagData}
      loading={loading || memberAddressLoading || memberInfoLoading}
      data={data}
      refetch={refetch}
      error={error}
      memberAddress={memberAddressData}
      memberInfo={memberInfoData}
    />
  );
};

FinalListing.propTypes = {
  tagData: PropTypes.any,
};

export default FinalListing;
