import { gql } from "@apollo/client";

import { userInfo } from "operations/fragments/user";
import paging from "operations/fragments/paging";

const getUsers = gql`
  query getUsers(
    $active: Boolean
    $roles: [String]
    $name_or_email: String
    $offset: Int
    $created_after: Date
    $created_before: Date
  ) {
    users(
      active: $active
      roles: $roles
      name_or_email: $name_or_email
      offset: $offset
      created_after: $created_after
      created_before: $created_before
    ) {
      data {
        ...userInfo
      }
      paging {
        ...paging
      }
    }
  }
  ${userInfo}
  ${paging}
`;

export default getUsers;
