import { gql } from "@apollo/client";

import { assessment } from "operations/fragments/assessment";

const getAssessment = gql`
  query getAssessment($id: ID!) {
    assessment(id: $id) {
      ...assessment
    }
  }
  ${assessment}
`;

export default getAssessment;
