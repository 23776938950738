import { gql } from "@apollo/client";

import { browseCareProviderData } from "operations/fragments/careProvider";
import paging from "operations/fragments/paging";

const browseCareProviders = gql`
  query browseCareProviders(
    $states: [String]
    $country: String
    $ethnicities: [String]
    $licenses: [String]
    $role: CareProviderRoleForMembers
    $languages: [String]
    $name_or_email: String
    $limit: Int = 10
    $offset: Int = 0
    $exclude_recommended: Boolean = true
    $distance_from: DistanceFromSearchInput
    $care_provider_tag_ids: [ID]
    $care_provider_tag_ids_exact_match: Boolean = false
    $in_person_supported: Boolean
    $virtual_supported: Boolean
    $licensed_countries: [String]
    $radius_search: RadiusSearchInput
    $dedicated_only: Boolean = false
  ) {
    browse_care_providers(
      states: $states
      country: $country
      ethnicities: $ethnicities
      licenses: $licenses
      role: $role
      languages: $languages
      name_or_email: $name_or_email
      limit: $limit
      offset: $offset
      exclude_recommended: $exclude_recommended
      distance_from: $distance_from
      care_provider_tag_ids: $care_provider_tag_ids
      in_person_supported: $in_person_supported
      virtual_supported: $virtual_supported
      licensed_countries: $licensed_countries
      radius_search: $radius_search
      care_provider_tag_ids_exact_match: $care_provider_tag_ids_exact_match
      dedicated_only: $dedicated_only
    ) {
      data {
        ...browseCareProviderData
      }

      paging {
        ...paging
      }
    }
  }
  ${browseCareProviderData}
  ${paging}
`;

export default browseCareProviders;
