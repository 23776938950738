//

import { flow } from "lodash/fp";
import { setIn } from "@spring/immutability";

import {
  AUTH_VERIFIED_TOKEN,
  AUTH_USER_IDENTIFIERS_LOADED,
  AUTH_SSO_RELAY_STATE,
} from "actions/auth/types";

export const initialState = {
  isTokenVerified: false,
  isLoggedIn: false,
  initialized: false,
  userIdentifiers: {},
  ssoRelayState: {},
};

export const logoutState = {
  isTokenVerified: true,
  isLoggedIn: false,
  initialized: true,
  userIdentifiers: {},
  ssoRelayState: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_VERIFIED_TOKEN:
      return flow(
        setIn("isTokenVerified", action.verified),
        setIn("isLoggedIn", action.valid),
        setIn("isMomentsOnly", action.isMomentsOnly),
      )(state);

    case AUTH_USER_IDENTIFIERS_LOADED:
      return {
        ...state,
        userIdentifiers: action.userIdentifiers || {},
      };

    case AUTH_SSO_RELAY_STATE:
      return {
        ...state,
        ssoRelayState: action.ssoRelayState,
      };

    default:
      return state;
  }
};

export default auth;
