import { gql } from "@apollo/client";

const updateMemberPrevisit = gql`
  mutation UpdateMemberPrevisit($input: UpdateMemberPrevisitInput!) {
    updateMemberPrevisit(input: $input) {
      success
      member_previsit {
        id
        status
        data
      }
    }
  }
`;

export default updateMemberPrevisit;
