/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-indent-props */
import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { startCase } from "lodash";

import DetailFlyout from "components/templates/YourAssessmentResults/DetailFlyout/DetailFlyout";
import { AssessmentResultsContext } from "components/templates/YourAssessmentResults/YourAssessmentResults";
import { TRACK_EVENT } from "utils/mixpanel";
import { clickableDivProps } from "lib/accessibility_helpers";
import { Text, VStack } from "@chakra-ui/react";

// const's for card variation (css) + i18n key matching
const GOALS = "goals";
const FOCUS = "focus";
const ADDITIONAL_RESULTS = "additionalResults";

function loadIconImg(name) {
  if (typeof name !== "undefined") {
    const Icon = lazy(() => import(`../icons/CardIcons/${name}`));

    return (
      <Suspense fallback={<span />}>
        <Icon />
      </Suspense>
    );
  }
  const Icon = lazy(() => import("../icons/CardIcons/therapy"));
  return (
    <Suspense fallback={<span />}>
      <Icon />
    </Suspense>
  );
}

const GoalCard = ({ data, openDetail, type, index, styles, t }) => {
  const { goal } = data;
  const onClick = () => openDetail(type);
  return (
    <div
      className={classnames(styles.cardContainer, {
        [styles[type]]: type,
      })}
      onClick={onClick}
      {...clickableDivProps({ onClick: onClick })}
    >
      <div
        className={styles.cardHeader}
        aria-label={t(`goals.${goal.goal_type}.header`)}
      >
        <span>{loadIconImg(goal.goal_type)}</span>
        <Text mx={10} casing="capitalize">
          {t(`goals.${goal.goal_type}.header`)}
        </Text>
      </div>

      <VStack alignItems="flex-start" justifyContent="space-evenly">
        <Text>{t("goals.goalIndex", { index: index })}</Text>
        <Text mt={0}>{t(`goals.${goal.goal_type}.description`)}</Text>
      </VStack>
    </div>
  );
};

const FocusCard = ({ data, openDetail, type, styles, t }) => {
  let risk = data.results.risk;
  if (risk === "Low") risk = "Normal";
  const ailment = startCase(
    t(`detailFlyout.ailments.${data?.info?.kind}.header`),
  );

  return (
    <div
      className={classnames(styles.cardContainer, {
        [styles[type]]: type,
      })}
      onClick={() => openDetail(type)}
      {...clickableDivProps({ onClick: () => openDetail(type) })}
      data-cy={`${ailment.replace(/ /g, "-").toLowerCase()}-focus-card`}
    >
      <div
        className={styles.cardHeader}
        aria-label={t(`detailFlyout.ailments.${data.info.kind}.header`)}
      >
        <span>{loadIconImg("therapy")}</span>
        <Text mx={10} casing="capitalize">
          {t(`detailFlyout.ailments.${data.info.kind}.header`)}
        </Text>
      </div>

      <VStack alignItems="flex-start" justifyContent="space-evenly">
        <Text>{t("focus.cardSubheader", { risk })}</Text>
        <Text mt={0}>
          {t(`detailFlyout.blurb.${data.results.risk}`, {
            risk: data.results.risk,
            ailment,
          })}
        </Text>
      </VStack>
    </div>
  );
};

const AdditionalCard = ({ data, openDetail, type, styles, t }) => {
  let risk = data.results.risk;
  if (risk === "Low") risk = "Normal";
  return (
    <div
      className={classnames(styles.cardContainer, {
        [styles[type]]: type,
      })}
      onClick={() => openDetail(type)}
      {...clickableDivProps({ onClick: () => openDetail(type) })}
    >
      <div
        className={styles.cardHeader}
        aria-label={t(`detailFlyout.ailments.${data.info.kind}.header`)}
      >
        <span>{loadIconImg("therapy")}</span>
        <Text mx={10} casing="capitalize">
          {t(`detailFlyout.ailments.${data.info.kind}.header`)}
        </Text>
      </div>
      <Text>{t("additionalResults.cardSubheader", { risk })}</Text>
    </div>
  );
};

const Card = ({ data, type, index }) => {
  const [detailOpen, setDetailOpen] = useState(false);
  // deconstruct needed data from context
  const values = useContext(AssessmentResultsContext);
  const { styles, t, Router } = values;

  useEffect(() => {
    if (detailOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [detailOpen]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        clearAllBodyScrollLocks();
        closeDetail(type);
      }
    };
    window.addEventListener("keydown", handleEsc);
    //

    return () => {
      window.removeEventListener("keydown", handleEsc);
      clearAllBodyScrollLocks();
    };
  }, []);

  function isDesktop() {
    return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  }

  function openDetail(type) {
    if (type === GOALS) {
      TRACK_EVENT.CARD_CLICKED(
        Router.router.asPath,
        "Goal Detail Modal",
        "Goal",
      );
    } else {
      TRACK_EVENT.CARD_CLICKED(
        Router.router.asPath,
        "Assessment Result Detail Modal",
        "Assessment Result",
      );
    }
    const targetElement = document.querySelector(
      `.${styles.detailFlyoutContainer}`,
    );

    if (isDesktop()) {
      disableBodyScroll(targetElement);
    }
    setDetailOpen(true);
    if (type === GOALS) {
      TRACK_EVENT.MODAL_OPENED(Router.router.asPath, "Goal Detail");
    } else {
      TRACK_EVENT.MODAL_OPENED(
        Router.router.asPath,
        "Assessment Result Detail",
      );
    }
  }

  function closeDetail(type) {
    if (isDesktop()) {
      clearAllBodyScrollLocks();
    }

    setDetailOpen(false);

    if (type === GOALS) {
      TRACK_EVENT.MODAL_CLOSED(Router.router.asPath, "Goal Detail");
    } else {
      TRACK_EVENT.MODAL_CLOSED(
        Router.router.asPath,
        "Assessment Result Detail",
      );
    }
  }

  return (
    <>
      {type === GOALS && (
        <GoalCard
          data={data}
          openDetail={openDetail}
          type={type}
          index={index}
          styles={styles}
          t={t}
        />
      )}

      {type === FOCUS && (
        <FocusCard
          data={data}
          openDetail={openDetail}
          type={type}
          styles={styles}
          t={t}
        />
      )}

      {type === ADDITIONAL_RESULTS && (
        <AdditionalCard
          data={data}
          openDetail={openDetail}
          type={type}
          styles={styles}
          t={t}
        />
      )}

      <DetailFlyout
        data={data}
        id="detailFlyout"
        show={detailOpen}
        closeDetail={closeDetail}
        type={type}
        loadIconImg={loadIconImg}
        styles={styles}
        t={t}
      />
    </>
  );
};

GoalCard.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  openDetail: PropTypes.func,
  styles: PropTypes.object,
  t: PropTypes.func,
  type: PropTypes.string,
};

FocusCard.propTypes = {
  data: PropTypes.object,
  openDetail: PropTypes.func,
  styles: PropTypes.object,
  t: PropTypes.func,
  type: PropTypes.string,
};

AdditionalCard.propTypes = {
  data: PropTypes.object,
  openDetail: PropTypes.func,
  styles: PropTypes.object,
  t: PropTypes.func,
  type: PropTypes.string,
};

Card.propTypes = {
  data: PropTypes.object,
  index: PropTypes.any,
  styles: PropTypes.object,
  type: PropTypes.string,
};

export default Card;
