//

import React, { PureComponent } from "react";
import {
  Modal,
  Headline,
  Section,
  HorizontalRule,
  Icon,
  Floaty,
  Content,
} from "@spring/smeargle";
import { modalIds } from "@spring/constants";

import styles from "./styles.module.scss";

class ProviderMatchingModal extends PureComponent {
  render() {
    return (
      <Modal size="xlg" id={modalIds.providerMatchingModal}>
        <Headline noMargin center>
          Provider Matching
        </Headline>
        <Section size="xlg">
          <HorizontalRule />
        </Section>
        <div className={styles.stepWrapper}>
          <div className={styles.step}>
            <div className={styles.iconWrapper}>
              <Floaty radius="50%" float={4}>
                <Icon type="map-marker" className={styles.icon} />
              </Floaty>
            </div>

            <div className={styles.title}>
              <Content inheritColor bold>
                Match Your Location
              </Content>
            </div>

            <Content>
              Locate vetted, world class providers in your home state that can
              meet you when you want, where you want.
            </Content>
          </div>

          <div className={styles.step}>
            <div className={styles.iconWrapper}>
              <Floaty radius="50%" float={4}>
                <Icon type="format-list-checks" className={styles.icon} />
              </Floaty>
            </div>

            <div className={styles.title}>
              <Content inheritColor bold>
                Match your goals and assessment
              </Content>
            </div>

            <Content>
              Use our proprietary algorithm to analzye your results and choose
              the best provider for you.
            </Content>
          </div>

          <div className={styles.step}>
            <div className={styles.iconWrapper}>
              <Floaty radius="50%" float={4}>
                <Icon type="check" className={styles.icon} />
              </Floaty>
            </div>

            <div className={styles.title}>
              <Content inheritColor bold>
                Make a recommendation
              </Content>
            </div>

            <Content>
              Recommend the provider that best meets your needs, in terms of
              specialization and practically.
            </Content>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ProviderMatchingModal;
