import yup from "schemas/yup";

const getSchema = (t) => {
  return yup.object().shape({
    dateOfBirth: yup.string(),
    gender: yup
      .string()
      .oneOf(["Woman", "Man", "Non-Binary", "Unknown"])
      .required(),

    raceOrEthnicity: yup.string().required(),
    previousDiagnoses: yup.string().required(),
    previousTreatments: yup.string().required(),
    currentMentalHealthMeds: yup.string().required(),
    otherCurrentMeds: yup.string().required(),
    allergies: yup.string().required(),

    emergencyContact: yup.object().shape({
      name: yup.string().required(),
      phoneNumber: yup
        .object()
        .shape({
          code: yup.string(),
          country: yup.string(),
          number: yup
            .string()
            .when("code", {
              is: "us",
              then: yup
                .string()
                .min(
                  10,
                  t(
                    "manualVerificationForm.phoneNumberVerify.exactly10Digits",
                    { ns: "limitedLangAuth" },
                  ),
                )
                .max(
                  10,
                  t(
                    "manualVerificationForm.phoneNumberVerify.exactly10Digits",
                    { ns: "limitedLangAuth" },
                  ),
                )
                .matches(
                  /^[0-9]+$/,
                  t("manualVerificationForm.phoneNumberVerify.numericOnly", {
                    ns: "limitedLangAuth",
                  }),
                ),
            })
            .matches(
              /^[0-9]+$/,
              t("manualVerificationForm.phoneNumberVerify.numericOnly", {
                ns: "limitedLangAuth",
              }),
            )
            .min(
              4,
              t("manualVerificationForm.phoneNumberVerify.atLeast4Digits", {
                ns: "limitedLangAuth",
              }),
            )
            .required(),
        })
        .required(),
    }),

    preferredPharmacy: yup.object().shape({
      name: yup.string().required().min(3),
      street: yup.string().required().min(3),
      city: yup.string().required().min(3),
      state: yup.string().required().min(2),
      zipCode: yup.string().required().min(2),
    }),

    consentAgreements: yup.object().shape({
      informedConsent: yup.boolean().equals([true]).required(),
      privacy: yup.boolean().equals([true]).required(),
    }),
  });
};

export default getSchema;
