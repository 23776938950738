import { gql } from "@apollo/client";

const getMemberPreferences = gql`
  query getMemberPreferences($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        care_preference {
          genders
          specialties
          ethnicities
          mediums
          preference_type
          status
          therapy_styles
          # start_at
          # end_at
        }
      }
    }
  }
`;

export default getMemberPreferences;
