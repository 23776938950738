import { gql } from "@apollo/client";

const updateOffboardingPreferences = gql`
  mutation UpdateOffboardingPreferences(
    $member_id: ID
    $offboarding_preferences: OffboardingPreferencesInput!
  ) {
    updateOffboardingPreferences(
      member_id: $member_id
      offboarding_preferences: $offboarding_preferences
    ) {
      success
      member {
        id
        offboarding_preferences {
          continue_with_care
          continue_with_spring
          find_provider_help
          prefer_out_of_pocket_payments
        }
      }
    }
  }
`;

export default updateOffboardingPreferences;
