// 

import React from 'react';

import { Content } from '../../typography';
import { FlexCol, FlexRow, Grid, Col, Section } from '../../layout';

import styles from './styles.module.scss';



const InfoSection = (props) => {
	
	function columns() {
		return props.details.map((column) => (
			<Col md={4} key={column.title}>
				<FlexCol>
					<Section>
						<div className={styles.wrapper}>
							<div className={styles.icon}>
								{column.icon}
							</div>
							<h2 style={{ color: '#007e5e', fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>{column.title}</h2>
							<Section>
								<Content center>{column.content}</Content>
							</Section>
						</div>
					</Section>
				</FlexCol>
			</Col>
		));
	}
	
	return (
		<Section size="xlg">
			<FlexRow justification="space-between">
				<Grid>
					{columns()}
				</Grid>
			</FlexRow>
		</Section>
	);
};

export default InfoSection;
