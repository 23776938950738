import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, getOr } from 'lodash/fp';
import classnames from 'classnames';

import { Icon } from '../../atoms';
import { FlexRow } from '../../layout';

import styles from './styles.module.scss';

import { initializeForm } from '../../../actions/form';
import { closeFlyout } from '../../../actions/flyout';
import FocusTrap from 'focus-trap-react';




const Flyout = (props) => {

	const containerRef = useRef();

	useEffect(() => {
		if (props.open){
			document.body.style.overflow = 'hidden';
			if(containerRef.current) containerRef.current.focus();
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [props.open]);

	const exitActions = () => {
		containerRef.current.scrollTop = 0;
		props.closeFlyout(props.id);

		if (props.addExitAction) {
			props.addExitAction();
		}
	};

	const closeButton = () => {
		return (
			<div
				className={classnames({
					[styles.open]              : props.open,
					[styles.transparentHeader] : props.transparentHeader,
				})}
			>
				<FlexRow justification="flex-end" alignment="center">
					<div className={styles.close}>
						<Icon
							type="close"
							onClick={() => {
								exitActions();
							}}
						/>
					</div>
				</FlexRow>
			</div>
		);
	};

	const onClickOnWrapper = (e) => {
		// ignore clicks inside the wrapper div
		if (e.target !== e.currentTarget) return;
		if (!props.open) return;
		exitActions();
	};

	return (
		<FocusTrap active={!!props.open}>
			<div
				onClick={onClickOnWrapper}
				className={classnames(styles.overlay, {
					[styles.open]: props.open,
				})}
				aria-label={props.ariaLabel}
				role="dialog"
				aria-modal="true"
			>
				<div
					className={classnames(styles.container, {
						[styles.open]: props.open,
					})}
					ref={containerRef}
					tabIndex="0"
				>
					{closeButton()}
					<div className={styles.content}>
						{props.children}
					</div>
				</div>
			</div>
		</FocusTrap>
	);
};

Flyout.propTypes = {
	id                : PropTypes.string,
	ariaLabel         : PropTypes.string,
	open              : PropTypes.bool,
	children          : PropTypes.node,
	closeFlyout       : PropTypes.func,
	initializeForm    : PropTypes.func,
	addExitAction     : PropTypes.func,
	transparentHeader : PropTypes.bool,
	initialData       : PropTypes.shape({
		conditionsTags  : PropTypes.array,
		specialtiesTags : PropTypes.array,
		languagesTags   : PropTypes.array,
		gendersTags     : PropTypes.array,
		ethnicitiesTags : PropTypes.array,
		fixedTag        : PropTypes.string,
	}),
};

export { Flyout };

export default connect(
	(state, ownProps) => ({
		open        : getOr(ownProps.open, `${ownProps.id}.open`, state.flyout),
		data        : get(`${ownProps.id}.data`, state.flyout),
		initialData : getOr({}, 'form.browseProviderTags.data', state),
	}),
	{ closeFlyout, initializeForm }
)(Flyout);
