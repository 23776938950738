import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { Button, Avatar, Badge } from "@springcare/sh-component-library";
import { Box, Heading, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Stars } from "design-system/assets";
import { useTranslation } from "hooks/react-i18next";
import { useRouter } from "next/router";
import { getRecommendedTherapists } from "operations/queries/careProvider";
import { useEffect, useState } from "react";
import routes from "routes/BrowseRoutes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";
import getRecommendedTherapistsAvatar from "operations/queries/careProvider/getRecommendedTherapistsAvatar";
import { useBrecsAlgoVersion } from "hooks/useBrecsAlgoVersion";

const UpNextTherapyCard = ({
  cardTitle,
  overline,
  subtitle,
  secondPadding,
  secondaryCTACallback,
  primaryCTAText,
  cardTrackingName,
  isVisible = true,
}) => {
  const { t } = useTranslation(["homepage"]);
  const router = useRouter();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [providers, setProviders] = useState([]);
  const flagVariant = useFeatureFlag(FLAGS.HOMEPAGE_TODO_COLLAPSE);
  const showBlurredImages = flagVariant === "blurred_images";

  const navigateToBrowse = () => {
    router.push(routes.TherapistsBrowse.as);
  };

  const handleAvatarClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Up next therapy card avatar clicked",
    );
    navigateToBrowse();
  };

  const handleButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Up next therapy card CTA clicked",
    );
    navigateToBrowse();
  };

  const sagemakerRanking = useFeatureFlag(FLAGS.RECS_SAGEMAKER_RANK);
  const QUERY_VERSION = useBrecsAlgoVersion(sagemakerRanking);

  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  apolloOptionsUserId.variables.version = QUERY_VERSION;

  const { data: recommendedProviderData, loading: recommendedProviderLoading } =
    useQuery(getRecommendedTherapistsAvatar, {
      ...apolloOptionsUserId,
      skip: !apolloOptionsUserId,
    });

  useEffect(() => {
    if (recommendedProviderData != null && !recommendedProviderLoading) {
      const limitedProviderList =
        recommendedProviderData.user?.member?.recommended_therapists?.slice(
          0,
          3,
        );

      const formattedProviders = limitedProviderList.map(({ therapist }) => ({
        avatar: therapist.avatar,
        id: therapist.id,
        name: therapist.name,
      }));
      setProviders(formattedProviders);
    }
  }, [recommendedProviderData, recommendedProviderLoading]);

  useEffect(() => {
    if (isVisible) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Up next therapy card with images viewed",
        {
          variant: flagVariant,
        },
      );
    }
  }, [flagVariant, isVisible]);

  const RecAvatar = ({ avatar, name }) => {
    return (
      <Flex pos="relative" flexDir="column">
        <Box
          p="0"
          m="0 4px"
          borderRadius="50%"
          w={isMobile ? "56px" : "104px"}
          h={isMobile ? "56px" : "104px"}
          position="relative"
          overflow="hidden"
        >
          <Avatar
            aria-label={`${name} avatar`}
            data-testid={`${name} avatar`}
            src={avatar}
            cursor="pointer"
            onClick={handleAvatarClick}
            w={"100%"}
            h={"100%"}
            filter={showBlurredImages && `blur(${isMobile ? "3px" : "5px"})`}
          />
        </Box>
        {!isMobile && (
          <Box
            backgroundColor="white"
            pos="absolute"
            w="86px"
            h="24px"
            left="50%"
            bottom="-15%"
            borderRadius="6px"
            transform="translate(-50%, -50%)"
          >
            <Badge pos="absolute" icon={Stars} colorScheme="positive">
              Top match
            </Badge>
          </Box>
        )}
      </Flex>
    );
  };

  const AvatarSection = () => {
    return (
      <Flex
        mr="16px"
        alignItems={"center"}
        flexDir="row"
        h={isMobile ? "56px" : "auto"}
        mt={isMobile && "16px"}
      >
        {providers?.map((provider) => (
          <RecAvatar
            avatar={provider.avatar}
            name={provider.name}
            key={`upNextProvider_${provider.id}`}
          />
        ))}
      </Flex>
    );
  };

  return (
    <Box
      w="100%"
      borderWidth="3px"
      borderRadius="12px"
      borderColor="tertiary.light"
      backgroundColor="platform.on"
    >
      <Flex>
        <Flex
          w="100%"
          h="100%"
          p={"24px"}
          flexDir="column"
          data-cy={`${overline.toLowerCase()}-up-next-card`}
        >
          <Text textColor="rgba(24, 32, 35, 1)" ml="2px" variant="bodyBold_v1">
            {overline}
            <Button
              variant="no-emphasis"
              mb="6px"
              p="0"
              textColor="content-secondary"
              data-testid="info-icon"
              _hover={{
                textColor: "content-secondary",
                backgroundColor: "transparent",
              }}
              _focus={{ textColor: "content-secondary" }}
              _active={{ textColor: "content-secondary" }}
              cursor="pointer"
              onClick={() => {
                TRACK_EVENT.LINK_CLICKED(
                  window.location.pathname,
                  `Informational fly-out - ${overline}`,
                  `Therapy`,
                  {
                    spring_doc_id: "HPR_004",
                    location: `${cardTrackingName} card`,
                  },
                );
                secondaryCTACallback();
              }}
            >
              <InfoOutlineIcon />
            </Button>
          </Text>

          <Heading
            size={isMobile ? "heading-small" : "heading-medium"}
            color="platform.900"
          >
            {cardTitle}
          </Heading>

          {isMobile && <AvatarSection />}

          <Box pt={"16px"}>
            <Button
              size="lg"
              colorScheme="primary"
              p="0"
              onClick={handleButtonClick}
              h="56px"
              w={isMobile ? "100%" : "224px"}
              whiteSpace="normal"
              _focus={{ textColor: "rgba(247, 251, 250, 1)" }}
              _focusVisible={{ boxShadow: "0 0 0 3px black" }}
              data-cy={`${primaryCTAText.replace(/ /g, "-").toLowerCase()}-button`}
            >
              {primaryCTAText}
            </Button>
          </Box>
        </Flex>
        {!isMobile && <AvatarSection />}
      </Flex>
    </Box>
  );
};

export default UpNextTherapyCard;
