//

import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useOpenClose } from '@spring/hooks';

import styles from './styles.module.scss';


const Tooltip = (props) => {
	const [isOpen, { open, close }] = useOpenClose(false);
	const { noBaseIcon, placement, text, children } = props;

	useEffect(() => {
		const closeListener = (event) => {
			if (event?.key === 'Escape') {
				close();
			}
		};
		document.addEventListener('keydown', closeListener);
		return () => document.removeEventListener('keydown', closeListener);
	}, []);

	return (
		<div
			className={classnames(styles.tooltip, {
				'mdi mdi-information-outline' : !noBaseIcon,
				[styles.open]                 : isOpen,
			})}
			onMouseEnter={open}
			onMouseLeave={close}
			onClick={() => {
				isOpen && close();
				!isOpen && open();
			}}
			onFocus={open}
			onBlur={close}
			tabIndex={props.isTabIndex ? 0 : -1}
			role={props.role || 'tooltip'}
			aria-labelledby={'tooltip-text'}
			onKeyDown={(e) => {
				!isOpen && 	e.code === 'Enter' && open();
				isOpen && close();
			}}
		>
			<span
			id='tooltip-text'
				className={classnames(styles.tooltipText, {
					[styles[placement]]: placement,
				})}
				aria-live={ props.role ? "off" : 'polite'}
				role={props.role && 'tooltip'}
			>
				{text}
			</span>
			{children}
		</div>
	);
};

export default Tooltip;
