//
import PropTypes from "prop-types";
import React from "react";
import { Fab, FlexRow, Content } from "@spring/smeargle";

import {
  EVENT_TYPE,
  makeEventString,
  track,
  TRACK_EVENT,
} from "utils/mixpanel";

import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const Pagination = (props) => {
  const { t } = useTranslation("a11y");
  const currentPage = props.currentPage;
  const totalPages = props.totalPages;

  const nextOnClick = () => {
    window.scrollTo(0, 0);
    track("Schedule Therapy Visit -- Next (More Results)", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: "Appointment Selected",
      }),
    });
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Pagination Next", {
      spring_doc_id: "directsched004",
      page_number_from: props.currentPage,
      page_number_to: props.currentPage + 1,
      total_pages: props.totalPages,
    });
    props.refetch({
      offset: props.currentPage * props.limit,
    });
  };

  const previousOnClick = () => {
    window.scrollTo(0, 0);
    track("Schedule Therapy Visit -- Back (More Results)", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: "Appointment Selected",
      }),
    });
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Pagination Back", {
      spring_doc_id: "directsched004",
      page_number_from: props.currentPage,
      page_number_to: props.currentPage + 1,
      total_pages: props.totalPages,
    });
    props.refetch({
      offset: (props.currentPage - 2) * props.limit,
    });
  };

  return (
    <div className={styles.pagination}>
      <FlexRow justification="space-evenly">
        <Fab
          ariaLabel={t("previousPage")}
          icon="arrow-left"
          disabled={props.loading || props.currentPage === 1}
          onClick={debounce(previousOnClick, 500, {
            maxWait: 500,
            leading: true,
            trailing: false,
          })}
        />

        <Content>
          {t("medicationManagers.pagination", {
            ns: "common",
            currentPage: currentPage,
            totalPages: totalPages,
          })}
        </Content>
        <Fab
          ariaLabel={t("nextPage")}
          icon="arrow-right"
          dataCy="next-page"
          disabled={props.loading || props.currentPage === props.totalPages}
          onClick={debounce(nextOnClick, 500, {
            maxWait: 500,
            leading: true,
            trailing: false,
          })}
        />
      </FlexRow>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  limit: PropTypes.number,
  loading: PropTypes.any,
  refetch: PropTypes.func,
  totalPages: PropTypes.any,
  totalProviderCount: PropTypes.number,
};

export default Pagination;
