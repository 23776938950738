import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  VParentingDuotoneSpot,
  VParentingFlatSpot,
} from "@springcare/verdant-icons-react";
import { InformationFlyout } from "components";
import { useTranslation } from "react-i18next";

type ParentCoachingFlyoutType = {
  callback?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

const ParentCoachingTopicsExamples = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.parentCoaching",
  });

  const topics = [];

  for (let i = 1; i <= 7; i++) {
    topics.push(
      <ListItem key={`parent_coaching_topics_${i}`}>
        <Text>{t(`commonParentingTopicsExamplesList.item${i}`)}</Text>
      </ListItem>,
    );
  }

  return (
    <>
      <Text mb={18}>{t("commonParentingTopicsBodyExamples")}</Text>
      <UnorderedList>{topics}</UnorderedList>
    </>
  );
};

const ParentCoachingFlyoutBody = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.parentCoaching",
  });

  return (
    <>
      <Heading size="heading-medium" mb="v-8">
        {t("benefitsOfParentCoachingHeader")}
      </Heading>
      <Text mb={24}>{t("benefitsOfParentCoachingBody")}</Text>
      <Heading size="heading-small" mb="v-8">
        {" "}
        {t("commonParentingTopicsHeader")}
      </Heading>
      <Text mb={18}>{t("commonParentingTopicsBody")}</Text>
      <ParentCoachingTopicsExamples />
    </>
  );
};

export const ParentCoachingFlyout = ({
  callback,
  isOpen,
  onClose,
}: ParentCoachingFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.parentCoaching",
  });
  const getParentingIllustration = () => {
    return (
      <VParentingDuotoneSpot viewBox="0 0 80 80" width={154} height={154} />
    );
  };

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VParentingFlatSpot />}
      heroIllustration={getParentingIllustration}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={callback ? t("ctaScheduleText") : t("ctaBrowseCoachesText")}
      ctaLink={callback ? "" : "/browse/coaches/?Parenting=true"}
      ctaLinkAs={callback ? "" : "/browse/coaches"}
      ctaType={callback && "button"}
      ctaCallback={callback}
    >
      <ParentCoachingFlyoutBody />
    </InformationFlyout>
  );
};
