//

import React from "react";
import { Modal, Headline, Subtitle, Banner } from "@spring/smeargle";
import { modalIds } from "@spring/constants";
import { useTranslation } from "react-i18next";

import { InPersonAppointmentDetailsForm } from "components/organisms";

const InPersonAppointmentDetailsModal = () => {
  const { t } = useTranslation("careProvider");
  return (
    <Modal size="xlg" id={modalIds.inPersonAppointmentDetails}>
      <Headline>{t("inPersonAppointmentDetailsModal.headline")}</Headline>
      <Subtitle>{t("inPersonAppointmentDetailsModal.subtitle")}</Subtitle>
      <Banner
        text={t("inPersonAppointmentDetailsModal.bannerText")}
        icon="information"
        color="accent"
      />
      <InPersonAppointmentDetailsForm />
    </Modal>
  );
};

export default InPersonAppointmentDetailsModal;
