import PropTypes from "prop-types";
import React, { Component } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { getOr } from "lodash/fp";
import Router from "next/router";
import { connect } from "react-redux";
import { Grid, Col, Form, Input } from "@spring/smeargle";
import StateSelect from "components/form/StateSelect";
import { addNotification, closeModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import { Trans, withTranslation } from "react-i18next";

import { withMemberAddress } from "components/hocs";

import routes from "routes";
import memberAddress from "schemas/memberAddress";
import { updateMember } from "operations/mutations/member";
import { memberAddressSelector } from "selectors/form";
import { getFirstError } from "utils/apollo/errorHandler";
import { normalizeToName, shouldShowStateField } from "utils/global";

import { getFullUserInfo, setUserIdentifiers } from "actions/auth";

import styles from "./styles.module.scss";
import { Text } from "design-system";

class MemberAddressForm extends Component {
  static propTypes = {
    addNotification: PropTypes.func,
    closeModal: PropTypes.func,
    fullSubmit: PropTypes.any,
    memberAddressData: PropTypes.shape({
      member: PropTypes.shape({
        id: PropTypes.any,
      }),
    }),
    mpTracking: PropTypes.func,
    redirect: PropTypes.shape({
      asPath: PropTypes.any,
      to: PropTypes.any,
    }),
    showGlobalExperience: PropTypes.any,
    country: PropTypes.string,
    t: PropTypes.func,
    updateMember: PropTypes.func,
    formHeader: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const address = getOr(
      {},
      "props.memberAddressData.member.postal_address",
      this,
    );
    this.state = {
      zip: address?.zip_code ? address.zip_code : "",
      country_short_code:
        this.props.country ||
        this.props.memberAddressData.member.postal_address.country ||
        "US",
    };
  }

  handleSubmit = async ({ postal_address }) => {
    // Make a copy so that we don't mutate the form data here
    const postalAddress = {
      ...postal_address,
      country_display_name: undefined,
    };
    const payload = {
      id: this.props.memberAddress.user.member.id,
      patch: {
        ...postalAddress,
        country: this.state.country_short_code?.toUpperCase(),
      },
    };

    try {
      await this.props.updateMember(payload);
      const res = await getFullUserInfo();
      this.props.setUserIdentifiers(res);
      this.props.addNotification(
        this.props.t("notifications.addressUpdated"),
        "success",
      );

      if (this.props.mpTracking) {
        this.props.mpTracking();
      }

      if (this.props.redirect) {
        // Wait to make sure the server is ready with the new address

        window.setTimeout(() => {
          const { to, asPath } = this.props.redirect;
          if (to && asPath) {
            return Router.push(to, asPath);
          }

          return Router.push(
            routes.TherapistsBrowse.to,
            routes.TherapistsBrowse.as,
          );
        }, 500);
      }

      return this.props.closeModal(modalIds.addressModal);
    } catch (error) {
      return this.props.addNotification(getFirstError(error), "error");
    }
  };

  get initialData() {
    const address = getOr(
      {},
      "props.memberAddress.user.member.postal_address",
      this,
    );
    let { city = "", state = "", country = "" } = address;
    const ogCountry = normalizeToName(country);

    return {
      postal_address: {
        street_address_1: address.street_address_1,
        street_address_2: address.street_address_2,
        zip_code: address.zip_code ? address.zip_code : null,
        city: city,
        state: state,
        country: ogCountry,
        country_display_name: address.country_display_name,
      },
    };
  }

  scrollToView() {
    const element = document.getElementById("change_country");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.focus();
    }
  }

  formContent = (t, showState, countryChangeRequest) => (
    <Grid gutter="0 16px">
      <Col sm={9}>
        <Input
          dataCy="homeAddress"
          fieldKey="postal_address.street_address_1"
          placeholder="Home Address"
          label={t("address.homeAddress")}
          autoComplete="address-line1"
          renderLabelFirst={true}
        />
      </Col>

      <Col sm={3}>
        <Input
          dataCy="street_address_2"
          fieldKey="postal_address.street_address_2"
          label={t("address.aptSuite")}
          autoComplete="address-line2"
          renderLabelFirst={true}
        />
      </Col>

      <Col sm={showState ? 5 : 8}>
        <Input
          dataCy="city"
          fieldKey="postal_address.city"
          placeholder={t("address.city")}
          label={t("address.city")}
          autoComplete="address-level2"
          renderLabelFirst={true}
        />
      </Col>

      {showState && (
        <Col sm={3}>
          <StateSelect
            dataCy="state"
            fieldKey="postal_address.state"
            label={t("address.state")}
            forceRequired
          />
        </Col>
      )}

      <Col sm={4}>
        <Input
          dataCy="zip"
          fieldKey="postal_address.zip_code"
          placeholder={t("address.zip")}
          label={t("address.zip")}
          autoComplete="postal-code"
          renderLabelFirst={true}
        />
      </Col>

      <Col sm={12}>
        <Input
          fieldKey="postal_address.country_display_name"
          placeholder={t("address.country")}
          label={t("address.country")}
          autoComplete="country-name"
          disabled
          renderLabelFirst={true}
        />
        <Text color="#6E7072" fontWeight="600">
          {countryChangeRequest ? (
            t("limitedLangSettings:address.countryChangeRequested")
          ) : (
            <Trans
              ns={"limitedLangSettings"}
              i18nKey={"address.supportMessage"}
              components={[
                <button
                  key={"0"}
                  type="button"
                  className={styles.inlineTextLink}
                  onClick={this.scrollToView}
                />,
              ]}
            />
          )}
        </Text>
      </Col>
    </Grid>
  );

  render() {
    const { t } = this.props;
    const showState = shouldShowStateField(this.state.country_short_code);
    const countryChangeRequest =
      window.sessionStorage.getItem("countryChangeRequested") === "true";

    return (
      <Form
        dataCy="address"
        formKey="member-address"
        schema={memberAddress}
        onSubmit={this.handleSubmit}
        initialData={this.initialData}
        submitIcon="arrow-right"
        submitText={t("common:form.submitText")}
        submitAriaLabel={t("a11y:submit.addressDetails")}
        fullSubmit={this.props.fullSubmit}
      >
        {this.props.formHeader ? (
          <>
            <h2 className={styles.formHeader}>{this.props.formHeader} </h2>
            <fieldset>
              <legend hidden> {this.props.formHeader}</legend>
              {this.formContent(t, showState, countryChangeRequest)}
            </fieldset>
          </>
        ) : (
          <>{this.formContent(t, showState, countryChangeRequest)}</>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  const { showGlobalExperience, lang, country } = state.global;
  return {
    memberAddressData: memberAddressSelector(state),
    showGlobalExperience,
    lang,
    country,
  };
};

const mapDispatchToProps = { addNotification, closeModal, setUserIdentifiers };

export { MemberAddressForm };
export default compose(
  withMemberAddress,
  graphql(updateMember, {
    props: ({ mutate }) => ({
      updateMember: (input) =>
        mutate({
          variables: { input },
        }),
    }),
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(withTranslation("limitedLangSettings")(MemberAddressForm));
