import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import Router from "next/router";
import { useTranslation } from "react-i18next";
import routes from "routes";
import { links } from "@spring/constants";
import { updateMemberExperienceState } from "operations/mutations/member";
import { TextButton, Button } from "@springcare/sh-component-library";
import { Heading, Text, Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import { VCelebrationDuotoneSpot } from "@springcare/verdant-icons-react";
import { UpdateSettingsProps } from "./types";
import { TRACK_EVENT } from "utils/mixpanel";

export const UpdateSettings: React.FC<
  React.PropsWithChildren<UpdateSettingsProps>
> = ({ memberId, updateSettingsShownCount = 0 }) => {
  const { t } = useTranslation("accountTransition");
  const headingSize = useBreakpointValue([
    "heading-small",
    "heading-small",
    "heading-medium",
  ]);

  const [updateSettingsShownCountMutation] = useMutation(
    updateMemberExperienceState,
    {
      variables: {
        member_id: memberId,
        update_settings_shown_count: updateSettingsShownCount + 1,
      },
    },
  );

  const [updateShouldUpdateSettingsMutation] = useMutation(
    updateMemberExperienceState,
    {
      variables: {
        member_id: memberId,
        should_update_settings: false,
      },
    },
  );

  useEffect(() => {
    TRACK_EVENT.COMPONENT_VIEWED(Router.asPath, "Aged Out Update Settings", {
      spring_doc_id: "agingOut007",
    });

    if (sessionStorage.getItem("ageOutUpdateSettingsShown") !== "true") {
      updateSettingsShownCountMutation();
      sessionStorage.setItem("ageOutUpdateSettingsShown", "true");
    }
  }, []);

  const handleUpdateSettingsClicked = () => {
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Aged Out Update Consent", {
      spring_doc_id: "agingOut008",
      location: "Update Information",
    });
    updateShouldUpdateSettingsMutation();
    Router.push(routes.MemberSettings.to, routes.MemberSettings.as);
  };

  const handleLearnMoreClicked = () => {
    TRACK_EVENT.LINK_CLICKED(Router.asPath, "Aged Out Update Consent", {
      spring_doc_id: "agingOut009",
      location: "Learn More",
      to: links.AccountTransitions,
    });
    window.open(links.AccountTransitions, "_blank");
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      bg="background-base"
      p="v-24"
      gap={24}
      borderRadius={"v-xl"}
    >
      <Box>
        <VCelebrationDuotoneSpot aria-hidden="true" />
      </Box>

      <Flex
        direction="column"
        gap={8}
        alignItems="center"
        maxW={"810px"}
        textAlign="center"
      >
        <Heading size={headingSize}>{t("updateSettings.heading")}</Heading>
        <Text textStyle="body-medium-strong">
          {t("updateSettings.bodyText")}
        </Text>
      </Flex>

      <Flex direction="column" gap={4} alignItems="center">
        <Button size="md" onClick={handleUpdateSettingsClicked}>
          {t("updateSettings.buttonText")}
        </Button>
        <TextButton onClick={handleLearnMoreClicked}>
          {t("updateSettings.textButton")}
        </TextButton>
      </Flex>
    </Flex>
  );
};
