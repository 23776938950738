//

class Node {
  value;
  next;
  prev;

  constructor(value, prev, next) {
    this.value = value;
    this.next = next;
    this.prev = prev;
  }
}

export default class DoublyLinkedList {
  head;

  constructor(head) {
    this.head = head || null;
  }

  get tail() {
    if (this.isEmpty) {
      return null;
    }

    let tmp = this.head;

    if (tmp) {
      while (tmp.next) {
        tmp = tmp.next;
      }

      return tmp;
    }

    return null;
  }

  /**
   * A function to see if the list is empty or not;
   */
  get isEmpty() {
    return this.head === null;
  }

  get length() {
    let tmp = this.head,
      length = 0;

    while (tmp) {
      length += 1;
      tmp = tmp.next;
    }

    return length;
  }

  /**
   * Loops through the list and creates an Array from all of the Nodes in the list.
   */
  toArray() {
    let array = [];

    let tmp = this.head;
    while (tmp && tmp.next) {
      array.push(tmp.value);
      tmp = tmp.next;
    }

    if (tmp && tmp.value) {
      // Make sure we push the last value!
      array.push(tmp.value);
    }

    return array;
  }

  /**
   * Checks to see if a value is in the linked list.
   * Only checks exact values, not deep values.
   */
  includes(val) {
    let current = this.head;

    while (current) {
      if (current.value === val) {
        return true;
      }

      current = current.next;
    }

    return false;
  }

  /**
   * Empties the list by setting `this.head` to a null value.
   *
   * @return {null}
   */
  clear() {
    this.head = null;
  }

  /**
   *
   * Returns the Node at the given index
   *
   */
  getNode(index) {
    let i = 0,
      current = this.head;

    while (i !== index && current) {
      current = current.next;
      i++;
    }

    if (current) {
      return current;
    }

    return undefined;
  }

  // /**
  //  * Returns the value of the first node in the list and removes it from the list.
  //  * If the Node has a `next` value, sets that Node to `this.head`.
  //  * Otherwise sets head to `null`.
  //  */
  // shift(): ?NodeData {
  //  if (this.head) {
  //    let tmp = this.head;
  //    this.head = this.head.next;

  //    return tmp.value;
  //  } else {
  //    this._throwEmpty();
  //  }
  // }

  // /**
  //  * Returns the value of the last node in the list and removes it from the list.
  //  * Also, takes the next-to-last node, and sets it's `next` pointer to `null`.
  //  *
  //  * @return {*} The value of the last node in the list.
  //  */
  // pop(): ?NodeData {
  //  let tmp = this.head;

  //  if (tmp) {

  //    while(tmp.next  && tmp.next.next) {
  //      tmp = tmp.next;
  //    }

  //    let tail = tmp.next;
  //    tmp.next = null;

  //    return tail ? tail.value : null;
  //  } else {
  //    this._throwEmpty();
  //  }
  // }

  /**
   * Adds a new Node to the front of the list.
   *
   * @return {null}
   */
  prepend(value) {
    let tmp = this.head || null;
    const newHead = new Node(value, null, tmp);

    if (tmp) {
      tmp.prev = newHead;
    }

    this.head = newHead;
  }

  /**
   * Adds a new Node to the end of the list.
   *
   * @return {null}
   */
  append(value) {
    if (this.head === null) {
      this.prepend(value);
    } else {
      let tmp = this.tail;

      if (tmp) {
        tmp.next = new Node(value, tmp, null);
      }
    }
  }

  /**
   * Appends an array to the end of the list.
   */
  appendArray(array) {
    let tail = this.tail || this.head; // If there's no tail, use the head, since it's either 0 or 1 entry long

    if (tail) {
      array.forEach((val) => {
        if (tail) {
          const next = new Node(val, tail, null);
          tail.next = next;
          tail = next;
        }
      });
    } else {
      // List is empty so we just make a new list from the array
      this.head = DoublyLinkedList.fromArray(array).head;
    }
  }

  /**
   *
   * Inserts a new node into the DLL at a given index.
   *
   */
  insert(value, index) {
    let i = 0,
      current = this.head;

    while (i !== index - 1 && current) {
      current = current.next;
      i++;
    }

    if (current) {
      const newNode = new Node(value, current, current.next);
      current.next = newNode;
      if (newNode.next) {
        // current.next could be undefined
        newNode.next.prev = newNode;
      }
    }
  }

  /**
   * Takes an array and inserts all entries as nodes at an index.
   */
  insertFromArray(arr, index) {
    if (!arr.length) {
      return;
    }

    let i = 0,
      current = this.head;

    if (current) {
      while (i !== index - 1 && current) {
        current = current.next;
        i++;
      }

      let insertNode;
      const final = current ? current.next : null;

      // Create a new node from each entry in the array and add it to the LL.
      arr.forEach((val) => {
        insertNode = new Node(val, current);

        // $FlowFixMe this can't ever be null since we're assigning a specific node to `current`
        current.next = insertNode;
        current = insertNode;
      });

      // Finally, the last node added needs to refer to the previous next node,
      // and vice versa.
      if (final && insertNode) {
        insertNode.next = final;
        final.prev = insertNode;
      }
    } else {
      // If the list is empty, just make a new list from the array
      this.head = DoublyLinkedList.fromArray(arr).head;
    }
  }

  /**
   * Replaces the next node and sets it as the tail
   */
  insertAsTail(value, index) {
    let i = 0,
      current = this.head;

    while (i !== index && current) {
      current = current.next;
      i++;
    }

    if (current) {
      const newNode = new Node(value, current, null);
      current.next = newNode;
    }
  }

  /**
   * Replaces the next node with an array of prompts and sets the last one as the tail
   */
  insertArrayAsTail(array, index) {
    if (!array.length) {
      return;
    }

    let i = 0,
      current = this.head;

    if (current) {
      while (i !== index && current) {
        current = current.next;
        i++;
      }

      let insertNode;
      // Create a new node from each entry in the array and add it to the LL.
      array.forEach((val) => {
        insertNode = new Node(val, current);

        // $FlowFixMe this can't ever be null since we're assigning a specific node to `current`
        current.next = insertNode;
        current = insertNode;
      });
    } else {
      // If the list is empty, just make a new list from the array
      this.head = DoublyLinkedList.fromArray(array).head;
    }
  }

  // Static Methods

  /**
   * Clears the list and fills it with new Nodes from the values of an array.
   *
   * @return {null}
   */
  static fromArray(array) {
    let dll = new DoublyLinkedList();

    if (array) {
      for (let i = array.length - 1; i > -1; i--) {
        let tmp = dll.head;
        const newNode = new Node(array[i], null, tmp);

        dll.head = newNode;

        if (tmp) {
          tmp.prev = newNode;
        }
      }
    }

    return dll;
  }
}
