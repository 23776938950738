import {
  BookSession,
  CareNavigator,
  VAiIcon,
  VSelfGuidedExcercisesIcon,
  VVisitsIcon,
  WateringPlant,
  WelcomeHand,
} from "design-system/assets";
import {
  Box,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  VStack,
  useMediaQuery,
} from "design-system/components";
import { TRACK_EVENT, register } from "utils/mixpanel";
import { useCallback, useEffect } from "react";

import { BoeingCustomerID } from "constants/customers";
import { BoeingSDOHZendeskForm } from "constants/links";
import routes from "routes";
import { Trans, useTranslation } from "react-i18next";
import { useRegisterContext } from "context/RegisterContext";
import { toString } from "lodash/fp";
import { useMember } from "context/MemberContext";
import type { FindCoveredLifeOutput } from "modules/shared/graphql-codegen/graphql";
import { getPromoCardContentKey, pickOrNullCamelCase } from "./helpers";
import { Link } from "@springcare/sh-component-library";
import PromoExperiment from "./PromoExperiment";
import { TermsAndPolicyFooter } from "components";

const PROMO_CARD_CONFIGS = {
  [BoeingCustomerID]: [
    { transKey: "Two" },
    { transKey: "Three" },
    { transKey: "FourBoeing", subtitleLink: BoeingSDOHZendeskForm },
  ],
  chip: [
    { transKey: "TwoChip" },
    { transKey: "ThreeChip" },
    { transKey: "FourChip" },
  ],
  default: [{ transKey: "Two" }, { transKey: "Three" }, { transKey: "Four" }],
};

const PromoDesktop = ({
  isWarmSignup = false,
  isWarmLoading = false,
  personImagery = null,
}) => {
  const { t, ready } = useTranslation("limitedLangRegister");
  const { isChip } = useMember();
  const [isOver900] = useMediaQuery("(min-height: 900px)");

  const coveredLife: FindCoveredLifeOutput = useRegisterContext().coveredLife;

  const isExperimentVisible =
    !isWarmSignup &&
    !isChip &&
    (personImagery === "variant_b" || personImagery === "variant_c");

  const {
    firstName: coveredLifeName,
    customerName,
    customerId,
    totalVisitsCovered,
  } = pickOrNullCamelCase(coveredLife, [
    "first_name",
    "customer_name",
    "customer_id",
    "total_visits_covered",
  ]);

  const remainingCovered = toString(totalVisitsCovered);

  const titleStr = t("promo.itemTwo.titleWarm", { remainingCovered });
  const sessionsCopy =
    totalVisitsCovered > 0
      ? t("promo.variantItemOne.withSessions", { remainingCovered })
      : t("promo.variantItemOne.noSessions");

  const TEST_PROMO_CARDS = [
    sessionsCopy,
    t("promo.variantItemTwo.copy"),
    t("promo.variantItemThree.copy"),
  ];

  const heading = coveredLifeName
    ? t("promo.itemOne.titleWarm", { coveredLifeName })
    : t("promo.itemOne.title");
  const subheading = t(`promo.itemOne${isChip ? "Chip" : ""}.subtitle`);

  useEffect(() => {
    if (customerName) {
      register({ customer_name: customerName });
    }
  }, [customerName]);

  const contentKey = getPromoCardContentKey(customerId, isChip);
  const PROMO_CARDS = PROMO_CARD_CONFIGS[contentKey];

  const handleClick = useCallback((href) => {
    TRACK_EVENT.LINK_CLICKED(routes.Register.as, href, "Promo Desktop", {});
  }, []);

  const Icon = ({ index }) => {
    if (index === 0) {
      return <WateringPlant width="40px" height="40px" />;
    }
    if (index === 1) {
      return <BookSession width="40px" height="40px" />;
    }
    if (index === 2) {
      return <CareNavigator width="40px" height="40px" />;
    }
    return null;
  };

  const IconVariant = ({ index }) => {
    if (index === 0) {
      return <VAiIcon width="24px" height="24px" aria-label="ai-icon" />;
    }
    if (index === 1) {
      return (
        <VVisitsIcon
          width="24px"
          height="24px"
          aria-label="flexible-appointments"
        />
      );
    }
    if (index === 2) {
      return (
        <VSelfGuidedExcercisesIcon
          width="24px"
          height="24px"
          aria-label="self-guided-exercises"
        />
      );
    }
    return null;
  };

  const VariantCopySection = ({ card, index }) => (
    <Flex pb={8} alignItems="flex-start" w="90%">
      <IconVariant index={index} />
      <Box ps={16}>
        <Text
          variant="body1"
          data-cy={
            index === 0 && totalVisitsCovered > 0
              ? "cl-remaining-sessions"
              : "cl-promo-copy"
          }
        >
          {card}
        </Text>
      </Box>
    </Flex>
  );

  const CopySection = ({ card, index }) => (
    <Flex pb={24} alignItems="flex-start" w="80%">
      <Icon index={index} />
      <Box ps={20}>
        {remainingCovered && (
          <Heading
            variant="md"
            fontSize={22}
            pb={8}
            data-cy="cl-remaining-sessions"
          >
            {card.transKey === "Two"
              ? titleStr
              : t(`promo.item${card.transKey}.title`)}
          </Heading>
        )}
        {!remainingCovered && (
          <Heading variant="md" fontSize={22} pb={8}>
            {t(`promo.item${card.transKey}.title`)}
          </Heading>
        )}
        <Text variant="body1">
          {card.subtitleLink ? (
            <Trans
              ns="limitedLangRegister"
              t={t}
              i18nKey={`promo.item${card.transKey}.subtitle`}
              components={[
                <Link
                  key="0"
                  href={card.subtitleLink}
                  onClick={() => handleClick(card.subtitleLink)}
                  target="_blank"
                />,
              ]}
            />
          ) : (
            t(`promo.item${card.transKey}.subtitle`)
          )}
        </Text>
      </Box>
    </Flex>
  );

  if (isWarmSignup && (isWarmLoading || !ready)) {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={9999}
        bg="rgba(255, 255, 255)"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary.base"
          size="xl"
        />
      </Box>
    );
  }

  // NOTE: friendly reminder that that the background color behind the promo also changes based on the variant. See RegisterPage.js
  if (isExperimentVisible) {
    return <PromoExperiment variant={personImagery} />;
  }

  if (isWarmSignup && !isChip) {
    return (
      <Box bg="tertiary.25" mt={32}>
        <Image
          src="/static/register_variant_b.png"
          alt="Desktop and mobile preview of therapy recommendations and scheduling dashboard"
          aria-hidden="true"
          display="inline-block"
          pl={54}
          maxWidth={isOver900 ? 780 : 640}
        />
        <VStack height="calc(100vh - 95px)" pt={"5%"}>
          <Flex
            align="left"
            direction="column"
            justifyContent={"center"}
            mb={16}
            width="80%"
          >
            {coveredLifeName && (
              <Heading
                as="h1"
                pb={8}
                variant="display"
                letterSpacing={[0, 0, 1, 1.5]}
                data-cy="welcome-to-spring-health"
              >
                {heading}
              </Heading>
            )}
            {!coveredLifeName && (
              <Heading
                as="h1"
                pb={8}
                variant="display"
                letterSpacing={[0, 0, 1, 1.5]}
                data-cy="welcome-to-spring-health"
              >
                {t("promo.itemOne.title")}
              </Heading>
            )}
            <Text variant="bodyBold_v1">{t("promo.itemOne.subtitleTwo")}</Text>
          </Flex>
          <Flex
            align="left"
            justify="center"
            direction="column"
            pb="95px"
            width="80%"
          >
            {TEST_PROMO_CARDS.map((card, index) => (
              <VariantCopySection card={card} index={index} key={index} />
            ))}
          </Flex>
          <TermsAndPolicyFooter noBackground={true} />
        </VStack>
      </Box>
    );
  }

  return (
    <Box bg="tertiary.25">
      <VStack height="calc(100vh - 95px)" pt={"5%"}>
        <Flex
          align="left"
          direction="column"
          justifyContent={"center"}
          mb={48}
          width="80%"
        >
          <Box pb={"11px"}>
            <WelcomeHand width="72px" height="72px" />
          </Box>
          <Heading
            as="h1"
            pb={8}
            variant="display"
            letterSpacing={[0, 0, 1, 1.5]}
            data-cy="welcome-to-spring-health"
          >
            {heading}
          </Heading>
          <Text variant="body1">{subheading}</Text>
        </Flex>
        <Flex
          align="left"
          justify="center"
          direction="column"
          pb="95px"
          width="80%"
        >
          {PROMO_CARDS.map((card, index) => (
            <CopySection card={card} index={index} key={index} />
          ))}
        </Flex>
        <TermsAndPolicyFooter noBackground={true} />
      </VStack>
    </Box>
  );
};

export default PromoDesktop;
