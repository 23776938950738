import { useEffect } from "react";
import {
  useParticipantMetadata,
  useSessionRecording,
  useSessionVerification,
} from "context/SessionRoomContext";
import usePostStartRecording from "components/templates/SHSessionRoom/hooks/usePostStartRecording";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import { useSessionTracks } from "components/templates/SHSessionRoom/hooks/useSessionTracks";

export const useStartAudioRecording = () => {
  const { participantType, appointmentId } = useParticipantMetadata();
  const { sessionConsents, setRecording } = useSessionRecording();
  const { localTrack, remoteTracks } = useSessionTracks();
  const { sessionVerified } = useSessionVerification();
  const startRecording = usePostStartRecording();

  const localTrackId = localTrack?.participant?.audioTrackPublications.size
    ? localTrack.participant.audioTrackPublications.keys().next().value
    : null;
  const remoteTrackId =
    remoteTracks?.length === 1 &&
    remoteTracks[0].participant?.audioTrackPublications.size
      ? remoteTracks[0].participant.audioTrackPublications.keys().next().value
      : null;

  useEffect(() => {
    const hasTwoParticipants = localTrack && remoteTracks?.length === 1;
    if (
      participantType === ParticipantType.Provider &&
      sessionVerified &&
      sessionConsents &&
      sessionConsents.member_consent &&
      sessionConsents.care_provider_consent &&
      hasTwoParticipants &&
      remoteTrackId &&
      localTrackId
    ) {
      startRecording({
        appointment_id: appointmentId,
        member_livekit_audio_track_id: remoteTrackId,
        care_provider_livekit_audio_track_id: localTrackId,
      }).then(() => setRecording(true));
    }
  }, [
    sessionVerified,
    sessionConsents,
    participantType,
    localTrackId,
    remoteTrackId,
  ]);
};
