import { Button } from "@springcare/sh-component-library";
import { COST_ESTIMATE_DISPLAY_STATES } from "components/templates/CostEstimation/constants";
import { TRACK_EVENT } from "utils/mixpanel";

export const ConfirmButton = ({
  handleSubmit,
  handleShiftTab,
  appointmentCreationPending,
  buttonText,
  hasConsent,
  showCancelApptPolicy,
  setDisplayState,
}) => {
  return (
    <Button
      variant="high-emphasis"
      w="100%"
      h="58px"
      data-cy={buttonText ? "schedule-provider-modal-submit-button" : ""}
      disabled={!hasConsent() || appointmentCreationPending}
      onClick={(event) => {
        if (setDisplayState && showCancelApptPolicy) {
          event.preventDefault();
          setDisplayState(COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY);
          TRACK_EVENT.MODAL_OPENED(
            window.location.pathname,
            "Acknowledge Cancellation Policy Modal",
          );
        } else {
          handleSubmit();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Tab" && event.shiftKey) {
          handleShiftTab(event);
        }
      }}
    >
      {buttonText}
    </Button>
  );
};
