/* eslint-disable react/jsx-key */
import { createIcon } from "@chakra-ui/react";

export const ChangeCaregiver = createIcon({
  displayName: "ChangeCaregiver",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M7.70711 3.29289C7.31658 2.90237 6.68342 2.90237 6.29289 3.29289C5.90237 3.68342 5.90237 4.31658 6.29289 4.70711L7.58579 6H1C0.447716 6 2.38419e-07 6.44772 2.38419e-07 7C2.38419e-07 7.55228 0.447716 8 1 8H7.58579L6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L10.7071 7.70711C11.0976 7.31658 11.0976 6.68342 10.7071 6.29289L7.70711 3.29289Z"
      fill="#171E22"
    />,
    <path
      d="M3.29289 12.2929C3.68342 11.9024 4.31658 11.9024 4.70711 12.2929C5.09763 12.6834 5.09763 13.3166 4.70711 13.7071L3.41421 15H9C9.55229 15 10 15.4477 10 16C10 16.5523 9.55229 17 9 17H3.41421L4.70711 18.2929C5.09763 18.6834 5.09763 19.3166 4.70711 19.7071C4.31658 20.0976 3.68342 20.0976 3.29289 19.7071L0.292894 16.7071C-0.0976312 16.3166 -0.0976312 15.6834 0.292894 15.2929L3.29289 12.2929Z"
      fill="#171E22"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5321 10.8252C21.4341 10.0022 22 8.81719 22 7.5C22 5.01472 19.9853 3 17.5 3C15.0147 3 13 5.01472 13 7.5C13 8.81719 13.5659 10.0022 14.4679 10.8252C12.9616 11.7121 11.8677 13.2445 11.5876 15.0777L11.0115 18.849C10.9281 19.3949 11.303 19.9051 11.849 19.9885C12.3949 20.0719 12.9051 19.697 12.9885 19.151L13.5647 15.3797C13.8617 13.4358 15.5335 12 17.5 12C19.4665 12 21.1383 13.4358 21.4353 15.3797L22.0115 19.151C22.0949 19.697 22.6051 20.0719 23.151 19.9885C23.697 19.9051 24.0719 19.3949 23.9885 18.849L23.4124 15.0777C23.1323 13.2445 22.0384 11.7121 20.5321 10.8252ZM17.5 5C16.1193 5 15 6.11929 15 7.5C15 8.88071 16.1193 10 17.5 10C18.8807 10 20 8.88071 20 7.5C20 6.11929 18.8807 5 17.5 5Z"
      fill="#171E22"
    />,
  ],
  defaultProps: {
    color: "platform.900",
  },
});
