import { gql } from "@apollo/client";

const loginlessConfirmAppointment = gql`
  mutation LoginlessConfirmAppointment($input: ConfirmAppointmentInput!) {
    loginlessConfirmAppointment(input: $input) {
      success
      appointment {
        derived_status
        id
        provider {
          avatar_cdn_url
          first_name
          last_name
        }
        start_at
        type
      }
    }
  }
`;

export default loginlessConfirmAppointment;
