import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { useMemberInfo } from "hooks/useMemberInfo";
import { getCareTeam } from "operations/queries/careProvider";
import { formatMemberAddress } from "utils/displayHelpers";

export const useTeenYourCareSection = () => {
  const { data: memberData } = useMemberInfo();

  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  const address = formatMemberAddress(memberData);
  const getCareTeamVars = address
    ? { id: memberData.user.id, distance_from: { address: address } }
    : { id: apolloOptionsUserId.variables.id };

  const { data: careTeamData, loading: careTeamDataLoading } = useQuery(
    getCareTeam,
    {
      variables: getCareTeamVars,
      skip: !memberData,
      fetchPolicy: "cache-and-network",
    },
  );

  const member = memberData?.user?.member;
  const memberHasInPersonSupport = member?.cohort?.in_person_supported;
  const memberCountry = member?.postal_address?.country;

  return {
    careTeamData,
    careTeamDataLoading,
    member,
    memberHasInPersonSupport,
    memberCountry,
  };
};
