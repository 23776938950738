import React from 'react';
import PropTypes from 'prop-types';

const PhoneIcon = ({ color, width, height }) => (
	<svg width={ width || '24' } height={ height || '25' } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M4.25953 0.517691C5.48196 -0.297263 7.10968 -0.13609 8.14854 0.902778L9.83724 2.59148C11.0409 3.79511 11.0409 5.74659 9.83724 6.95023L9.18312 7.60436C9.87428 9.10636 10.8675 10.5541 12.1567 11.8433C13.446 13.1325 14.8936 14.1257 16.3956 14.8169L17.0498 14.1628C18.2534 12.9591 20.2049 12.9591 21.4085 14.1628L23.0972 15.8515C24.1361 16.8903 24.2973 18.518 23.4823 19.7405L20.9505 23.5382C20.7688 23.8108 20.4679 23.9803 20.1406 23.9947C18.4027 24.0708 16.2658 23.3268 14.0862 22.0943C11.8742 20.8434 9.49393 19.0187 7.23761 16.7624C4.98129 14.5061 3.15658 12.1258 1.90571 9.91383C0.673165 7.73425 -0.0707571 5.59735 0.00532832 3.85938C0.0196573 3.53207 0.189234 3.23122 0.461832 3.04949L4.25953 0.517691ZM6.69563 2.3557C6.34934 2.00941 5.80677 1.95568 5.39929 2.22734L2.0687 4.44773C2.15877 5.58313 2.68539 7.11834 3.69427 8.90241C4.84116 10.9305 6.54616 13.1651 8.69053 15.3095C10.8349 17.4538 13.0695 19.1588 15.0976 20.3057C16.8817 21.3146 18.4169 21.8412 19.5523 21.9313L21.7727 18.6007C22.0443 18.1932 21.9906 17.6507 21.6443 17.3044L19.9556 15.6157C19.5544 15.2145 18.9039 15.2145 18.5027 15.6157L17.356 16.7624C17.065 17.0534 16.6283 17.1427 16.2465 16.9892C14.2646 16.1929 12.3622 14.9547 10.7038 13.2962C9.04533 11.6378 7.80713 9.73535 7.01078 7.75354C6.85733 7.37166 6.94659 6.93505 7.23761 6.64403L8.38432 5.49731C8.78554 5.0961 8.78554 4.44561 8.38432 4.0444L6.69563 2.3557Z" fill={color || '#205785'} />
	</svg>

);

PhoneIcon.propTypes = {
	color  : PropTypes.string,
	width  : PropTypes.string,
	height : PropTypes.string,
};

export default PhoneIcon;