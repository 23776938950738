import { useMemberInfo } from "hooks/useMemberInfo";

import { setUserIdentifiers, verifyToken } from "actions/auth/actions";
import { reduxStore } from "constants/reduxStore";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import Router from "next/router";

export const PARENT_KEY = "parent_local_storage";
export const DEPENDENT_KEY = "dependent_local_storage";

function isParentOrDependentKey(k) {
  return k.startsWith(PARENT_KEY) || k.startsWith(DEPENDENT_KEY);
}

function getCachedUUID(key) {
  const json = localStorage.getItem(key);
  if (!json) return false;
  const data = JSON.parse(json);
  return data["uuid"] ?? false;
}

function localStorageKeys() {
  return Object.keys(localStorage).filter(
    (key) => !isParentOrDependentKey(key),
  );
}

function readFullLocalStorage() {
  return Object.fromEntries(
    localStorageKeys().map((key) => [key, localStorage.getItem(key)]),
  );
}

function foundCachedLocalStorageSet(key) {
  const json = localStorage.getItem(key);
  if (!json) return false;
  return true;
}

function restoreLocalStorage(key) {
  const json = localStorage.getItem(key);
  if (!json) return false;
  const data = JSON.parse(json);
  Object.entries(data).map(([k, v]) => localStorage.setItem(k, v));

  return true;
}

function saveLocalStorage(key) {
  localStorage.setItem(key, JSON.stringify(readFullLocalStorage()));
}

export const getCachedLocalStoreData = (localStoreCacheId, key) => {
  const json = localStorage.getItem(localStoreCacheId);
  if (!json) return false;
  const data = JSON.parse(json);
  return data[key];
};

export function isParentLogged() {
  return !!localStorage.getItem(PARENT_KEY);
}

export function useCanChangeUser() {
  const { loading, data } = useMemberInfo();

  if (isParentLogged()) return true;

  if (loading) return false;

  const hasManager = data?.user?.member?.manager !== null;
  const allowsDependents = data?.user?.member?.cohort?.dependents_allowed;
  const allowsChildDependents =
    data?.user?.member?.cohort?.child_dependents_allowed;
  const isMinor = data?.user?.member?.minor;

  return !hasManager && !isMinor && (allowsDependents || allowsChildDependents);
}

export function switchToParent() {
  restoreLocalStorage(PARENT_KEY);
}

export function purgeRememberUser() {
  Object.keys(localStorage)
    .filter((key) => isParentOrDependentKey(key))
    .map((key) => localStorage.removeItem(key));
}

export function rememberParentLocalStorage() {
  if (!localStorage.getItem(PARENT_KEY)) saveLocalStorage(PARENT_KEY);
}

export function rememberDependentLocalStorage(id) {
  saveLocalStorage(`${DEPENDENT_KEY}-${id}`);
}

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

async function waitForLocalStoreUpdatOnAutoSignIn() {
  if (getCachedUUID(PARENT_KEY) === localStorage.getItem("uuid")) {
    sleep(1000);
    waitForLocalStoreUpdatOnAutoSignIn();
  }
  return;
}

export async function autoSignInAsDependent(id, memberDateOfBirth) {
  if (!foundCachedLocalStorageSet(`${DEPENDENT_KEY}-${id}`)) return false;

  if (restoreLocalStorage(`${DEPENDENT_KEY}-${id}`)) {
    await waitForLocalStoreUpdatOnAutoSignIn();
    try {
      const userIdentifiers = await verifyToken();
      if (userIdentifiers?.user?.id && memberDateOfBirth) {
        await reduxStore.dispatch(setUserIdentifiers(userIdentifiers));
        const memberHomeRoute = getHomeRoute(memberDateOfBirth);
        Router.push(memberHomeRoute.as);
        return true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  }

  return false;
}
