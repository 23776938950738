const HowManyDays = [
	{ value: 0, label: 'Not at all' },
	{ value: 1, label: 'Several days' },
	{ value: 2, label: 'More than half the days' },
	{ value: 3, label: 'Nearly every day' },
];

const HowDifficult = [
	{ value: 0, label: 'Not difficult at all' },
	{ value: 1, label: 'Somewhat difficult' },
	{ value: 2, label: 'Very difficult' },
	{ value: 3, label: 'Extremely difficult' },
];

const YesNoOptions = [
	{ value: 1, label: 'Yes' },
	{ value: 0, label: 'No' },
];

const HowManyTimes = [
	{ label: 'Never', value: 0 },
	{ label: 'Monthly or less', value: 1 },
	{ label: 'Two to four times a month', value: 2 },
	{ label: 'Two to three times a week', value: 3 },
	{ label: 'Four or more times a week', value: 4 },
];

const YesNoThisYear = [
	{ value: 0, label: 'No' },
	{ value: 2, label: 'Yes, but not in the last year' },
	{ value: 4, label: 'Yes, during the last year' },
];

const HowOften = [
	{ value: 0, label: 'Never' },
	{ value: 1, label: 'Rarely' },
	{ value: 2, label: 'Sometimes' },
	{ value: 3, label: 'Often' },
	{ value: 4, label: 'Very Often' },
];

module.exports = {
	HowManyDays,
	HowDifficult,
	YesNoOptions,
	HowManyTimes,
	YesNoThisYear,
	HowOften,
};