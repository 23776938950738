import { gql } from "@apollo/client";

const createProviderRequestAvailabilityZendeskTicket = gql`
  mutation createProviderRequestAvailabilityZendeskTicket(
    $input: ProviderRequestAvailabilityZendeskTicketInput!
  ) {
    createProviderRequestAvailabilityZendeskTicket(input: $input) {
      success
    }
  }
`;

export default createProviderRequestAvailabilityZendeskTicket;
