import React from 'react';
import PropTypes from 'prop-types';

const Duration = (props) => {
	let format = (seconds) => {
		const date = new Date(seconds * 1000);
		const hh = date.getUTCHours();
		const mm = date.getUTCMinutes();
		const ss = pad(date.getUTCSeconds());
		if (hh) {
			return `${hh}:${pad(mm)}:${ss}`;
		}
		return `${mm}:${ss}`;
	};

	let pad = (string) => {
		return ('0' + string).slice(-2);
	};

	return (
		<span>
			{format(props.duration)}
		</span>
	);
};

Duration.propTypes = {
	duration: PropTypes.number.isRequired,
};

export default Duration;
