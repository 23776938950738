// 

import React from 'react';

const SpringHeartShield = () => (
	<svg width="34" height="41" viewBox="0 0 34 41" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M16.2648 0.125843C16.5668 -0.0419476 16.9341 -0.0419476 17.2361 0.125843L32.9866 8.8761C33.304 9.05247 33.5009 9.38709 33.5009 9.75026C33.5009 26.2959 22.7261 37.0213 17.2719 40.3544C16.9518 40.55 16.5491 40.55 16.229 40.3544C10.7748 37.0213 0 26.2959 0 9.75026C0 9.38709 0.196892 9.05247 0.514357 8.8761L16.2648 0.125843ZM2.00478 10.336C2.24692 25.1416 11.6226 34.9251 16.7505 38.3159C21.8783 34.9251 31.254 25.1416 31.4961 10.336L16.7505 2.14396L2.00478 10.336Z" fill="#1C6B57"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M11.0834 15.8998C12.2794 14.6989 14.2186 14.6989 15.4146 15.8998L16.7491 17.2397L18.0836 15.8998C19.2797 14.6989 21.2188 14.6989 22.4148 15.8998C23.6108 17.1006 23.6108 19.0476 22.4148 20.2484L16.7491 25.937L11.0834 20.2484C9.88741 19.0476 9.88741 17.1006 11.0834 15.8998Z" fill="#068464"/>
	</svg>
);

export default SpringHeartShield;
