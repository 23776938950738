interface ExperienceType {
  title: string;
  subtitle: string;
  bullets: string[];
  endText: string;
  ctaText: string;
}

const experiences: Record<string, ExperienceType> = {
  family_support: {
    title: "Looking for substance use support for a loved one?",
    subtitle:
      "4 ways to take care of yourself while you support someone struggling with addiction",
    bullets: [
      "Educate yourself: Learn about substance use disorder to better understand and support your loved one.",
      "Express concern: Approach your loved one with empathy and non-confrontational concern about their alcohol use.",
      "Offer resources: Provide information on treatment options like therapy, support groups, or rehab, and help them access these resources.",
      "Encourage boundaries: Set boundaries to protect yourself and others, while still offering compassion and support.",
    ],
    endText:
      "We can get your loved one connected the right care. Schedule a free call with a specialized care navigator to learn more.",
    ctaText: "Schedule",
  },
  drinking_quiz: {
    title: "Curious about your drinking habits?",
    subtitle:
      "These 4 questions can help determine if alcohol may be causing problems in your life",
    bullets: [
      "Have you ever thought you should drink less?",
      "Are you bothered by criticism of your drinking?",
      "Do you feel guilty about drinking?",
      "Do you ever have a morning drink to start the day or cure a hangover?",
    ],
    endText:
      "If you answered yes to any of these consider scheduling a free call with a specialized care navigator.",
    ctaText: "Schedule",
  },
  coping: {
    title: "Understand your coping mechanisms",
    subtitle:
      "These 4 questions can help you determine if your coping strategies are allies or adversaries in your journey to well-being",
    bullets: [
      "Do you confront your problems or try to escape them?",
      "Do you often turn to things for relief that might not be healthy?",
      "Are you progressing in the long term, or leaning on certain habits?",
      "Do your habits help you reach your goals, or are they pulling you away from them?",
    ],
    endText:
      "If your answers made you feel like your coping skills are working against you, schedule a free call with our specialized care navigators.",
    ctaText: "Schedule",
  },
};

export default experiences;
