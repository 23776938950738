import React from "react";
import PropTypes from "prop-types";

function NoProviderEmail({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 13h22M9 13v13a1 1 0 001 1h20a1 1 0 001-1V13M9 13l11 8 11-8"
      ></path>
    </svg>
  );
}

NoProviderEmail.propTypes = {
  color: PropTypes.string,
};

NoProviderEmail.defaultProps = { color: "#1C6B57" };

export default NoProviderEmail;
