import { gql } from "@apollo/client";

const uploadDocument = gql`
  mutation UploadDocument($input: UploadDocumentInputType!) {
    upload_document(input: $input) {
      document_id
    }
  }
`;

export default uploadDocument;
