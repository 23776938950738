import { Avatar, Button, Link } from "@springcare/sh-component-library";
import { Checkbox, Flex, Heading, Text } from "@chakra-ui/react";
import {
  VCelebrationConfettiDuotoneSpot,
  VCheckIcon,
  VPrivateSpringIcon,
} from "@springcare/verdant-icons-react";
import { SHStickyFooter } from "design-system/components";
import { links } from "@spring/constants";
import { useState } from "react";

export const DesktopContent = ({ onClick, t }) => {
  return (
    <Flex
      alignItems="flex-start"
      flexDirection="column"
      width={528}
      gap={24}
      mx="auto"
    >
      <VCelebrationConfettiDuotoneSpot aria-hidden />
      <HeadingAndSubtitle t={t} />
      <BulletPoints t={t} />
      <PrivacySection onClick={onClick} t={t} />
    </Flex>
  );
};

export const MobileContent = ({ onClick, t }) => {
  return (
    <Flex
      flexDirection="column"
      px={24}
      gap={24}
      maxWidth={620}
      mx={"auto"}
      width={"95%"}
    >
      <VCelebrationConfettiDuotoneSpot aria-hidden />
      <HeadingAndSubtitle t={t} />
      <BulletPoints t={t} />
      <SHStickyFooter hasShadow={true} left="0" testId="sticky-footer">
        <Flex
          flexDirection="column"
          justifyContent="center"
          px={24}
          gap={24}
          mb={34}
          borderTop={`1px solid border-subtle`}
        >
          <Button onClick={onClick} mt={8}>
            {t("upselledExpectations.buttonText")}
          </Button>
          <Flex
            flexDirection="row"
            gap={8}
            alignItems="center"
            justifyContent="center"
          >
            <IconWrapper>
              <VPrivateSpringIcon />
            </IconWrapper>
            <Text fontSize={12} color="content-secondary">
              {t("upselledExpectations.confidentiality")}
            </Text>
          </Flex>
        </Flex>
      </SHStickyFooter>
    </Flex>
  );
};

const HeadingAndSubtitle = ({ t }) => {
  return (
    <Flex flexDirection="column">
      <Heading
        data-testid="member-intent-question-heading"
        variant="lg_v1"
        as="h1"
        fontSize={29}
        letterSpacing={-0.5}
      >
        {t("upselledExpectations.title")}
      </Heading>
      <Text color="content-secondary">
        {t("upselledExpectations.subtitle")}
      </Text>
    </Flex>
  );
};

const BulletPoints = ({ t }) => {
  return (
    <Flex flexDirection="column" gap={8}>
      {["bulletPointOne", "bulletPointTwo", "bulletPointThree"].map((point) => (
        <Flex flexDirection="row" gap={16} alignItems="center" key={point}>
          <Avatar icon={VCheckIcon} />
          {t(`upselledExpectations.previewSpringOfferings.${point}`)}
        </Flex>
      ))}
    </Flex>
  );
};

const PrivacySection = ({ onClick, t }) => {
  const [disableButton, setDisableButton] = useState(true);

  return (
    <Flex flexDirection="column" gap={4}>
      <Checkbox
        onChange={() => {
          setDisableButton((i) => !i);
        }}
        isChecked={!disableButton}
        my={12}
        sx={{
          "& .chakra-checkbox__control": {
            border: "1px solid #00131738",
            width: "18px",
            height: "18px",
            background: "white",
          },
        }}
      >
        <Text fontSize={12} fontWeight={400}>
          {t("upselledExpectations.privacyPolicy")}{" "}
          <Link href={links.PrivacyPolicy} target="_blank" fontSize={12}>
            {t("upselledExpectations.privacyPolicyLinkText")}
          </Link>
        </Text>
      </Checkbox>
      <Button onClick={onClick} w={79} h={48} disabled={disableButton}>
        {t("upselledExpectations.buttonText")}
      </Button>
      <Flex flexDirection="row" gap={8} alignItems="center" mt={34}>
        <IconWrapper>
          <VPrivateSpringIcon />
        </IconWrapper>
        <Text fontSize={12} color="content-secondary">
          {t("upselledExpectations.confidentiality")}
        </Text>
      </Flex>
    </Flex>
  );
};

const IconWrapper = ({ children }: { children: React.ReactNode }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    bg="neutral-subtle"
    borderRadius="50%"
    boxSize={24}
    minWidth={24}
  >
    {children}
  </Flex>
);
