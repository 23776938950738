import { useQuery } from "@apollo/client";
import { getCompletedTeenInitialAssessment } from "operations/queries/assessment";

export const useTeenAssessmentStatus = (skip = false) => {
  const { data, loading } = useQuery(getCompletedTeenInitialAssessment, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip,
  });

  const assessmentStatus = data?.assessments?.data[0]?.status;
  return { assessmentStatus, loading };
};
