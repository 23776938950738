// @flow

import React from "react";
import PropTypes from "prop-types";
import {
  Underlined,
  Accordion,
  Backdrop,
  Grid,
  Col,
  Floaty,
  Section,
} from "@spring/smeargle";

import styles from "./styles.module.scss";
import { Heading } from "design-system/components";

const Billboard = (props) => {
  Billboard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.any,
    link: PropTypes.any,
    open: PropTypes.any,
    color: PropTypes.string,
  };

  return (
    <Floaty radius={3} float={0}>
      <Accordion open={props.open}>
        <Backdrop color={props.color}>
          <div className={styles.wrapper}>
            <Section size="sm">
              <Grid>
                <Col md={8}>
                  <Heading fontSize={20} fontWeight={600} color="#007e5e">
                    {props.title}
                  </Heading>
                  <Section size="sm">
                    <Underlined grey>
                      <div className={styles.subtitle}>{props.subtitle}</div>
                    </Underlined>
                  </Section>
                </Col>

                <Col md={4} alignment="center">
                  <div className={styles.button}>{props.link}</div>
                </Col>
              </Grid>
            </Section>
          </div>
        </Backdrop>
      </Accordion>
    </Floaty>
  );
};

export default Billboard;
