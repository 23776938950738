import { Box, Flex, Heading, Link, Text } from "design-system/components";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import CareTeamCardAvatar from "components/molecules/CareTeamCard/CareTeamCardAvatar";
import styles from "components/templates/Browse/CoachesBrowsePageWrapper/styles.module.scss";
import { NoProviderEmail } from "components/atoms";
import { connect } from "react-redux";
import { openModal } from "@spring/smeargle/actions";
import { modalIds, ProviderRole } from "@spring/constants";
import { EmptyCoachIcon } from "@spring/smeargle";
import routes from "routes/index";
import { CareProviderDetailModal } from "components/modals";
import BrowseHeader from "components/templates/Browse/ProviderBrowsePage/components/HeaderSection/BrowseHeader";
import { useCareTeam } from "hooks";
import {
  returnCoachingCoveredAndRemaining,
  returnTherapyCoveredAndRemaining,
} from "modules/shared/utils";

type Props = {
  openModal: (id: string, data: any) => void;
  memberInfoData: any;
  showHeader?: boolean;
  providerType: string;
};

const NoSponsoredSessions = ({
  openModal,
  memberInfoData,
  showHeader,
  providerType,
}: Props) => {
  const { data: careTeamData } = useCareTeam();
  const { t } = useTranslation(["coachesProvider", "careProvider"]);
  const coachesInfo = careTeamData?.user?.member?.care_team?.coach;
  const therapistInfo = careTeamData?.user?.member?.care_team?.therapist;
  const providerInfo =
    providerType === ProviderRole.Therapist ? therapistInfo : coachesInfo;
  const { usedTherapy: usedTherapyCount, coveredTherapy: coveredTherapyCount } =
    returnTherapyCoveredAndRemaining(memberInfoData);
  const {
    coveredCoaching: coveredCoachingCount,
    usedCoaching: usedCoachingCount,
  } = returnCoachingCoveredAndRemaining(memberInfoData);

  const getHeading = () => {
    if (providerType === ProviderRole.Therapist) {
      return (
        <>
          {t("careProvider:noSponsoredSessions.heading", {
            usedTherapyCount: usedTherapyCount,
            coveredTherapyCount: coveredTherapyCount,
          })}
        </>
      );
    }
    return (
      <>
        {t("noSponsoredSessions.heading", {
          usedCoachingCount: usedCoachingCount,
          coveredCoachingCount: coveredCoachingCount,
        })}
      </>
    );
  };

  const getSubText = () => {
    if (providerType === ProviderRole.Therapist) {
      return t("careProvider:noSponsoredSessions.subText");
    }
    return t("noSponsoredSessions.subText");
  };

  const getDetailsAction = () => {
    return (providerData) => {
      openModal(modalIds.careProviderDetailModal, {
        ...providerData,
      });
    };
  };

  const careNavigatorID =
    careTeamData?.user?.member?.care_team?.care_navigator?.id;

  const careNavigatorStandalonePage = routes[
    "ScheduleAppointmentProviderDetail"
  ].as.replace(":id", careNavigatorID);

  const ProviderInfoCard = () => {
    return (
      <Flex flexDirection="row" py="20">
        <Flex pe="24">
          <CareTeamCardAvatar
            providerData={providerInfo}
            styles={styles}
            providerAttributes={{ emptyIcon: <EmptyCoachIcon /> }}
            infoAction={getDetailsAction()}
          />
        </Flex>
        <Flex flexDirection="column" my="24">
          <Text fontWeight="bold">{providerInfo?.name}</Text>
          <Text fontSize="sm" fontWeight="semibold" color="#7f8182">
            {providerInfo?.roles},{providerInfo?.licenses.join(", ")}
          </Text>
          <Flex ms="-8">
            <NoProviderEmail color="#000" />
            <Text fontSize="sm" py="8">
              {providerInfo?.email}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction={"column"}>
      {showHeader && <BrowseHeader providerType={providerType} />}
      <Box bg="#faf9f6">
        <Box px="40" py="20">
          <Heading fontSize="24px">{getHeading()}</Heading>
          <Text fontSize="xs" py={8}>
            {getSubText()}
          </Text>
          <ProviderInfoCard />
          <Text fontSize="sm" py={8}>
            <Trans
              ns="careProvider"
              i18nKey="noSponsoredSessions.CTA"
              components={[
                <Link key={"0"} href={careNavigatorStandalonePage} />,
              ]}
            />
          </Text>
        </Box>
      </Box>
      <CareProviderDetailModal />
    </Flex>
  );
};

const mapDispatchToProps = { openModal };
export default connect(null, mapDispatchToProps)(NoSponsoredSessions);
