import PropTypes from "prop-types";
import {
  assessments as assessmentConstants,
  getAssessmentKindForTracking,
  AssessmentKind,
} from "@spring/constants";
import { Button } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { VTempFooter } from "design-system/components";
import { get, equals, truncate } from "lodash/fp";
import classnames from "classnames";
import { Section, FlexCol } from "@spring/smeargle";
import { withTranslation } from "react-i18next";
import { ConfidentialityBanner } from "components/assessments/Elements";

import ElementCurator from "components/assessments/ElementCurator";

import styles from "./styles.module.scss";

import { setPromptValidation } from "actions/assessment/actions";
import { track, TRACK_EVENT } from "utils/mixpanel";
import { promptIdMapping } from "constants/promptIdMapping";
import { redirectToMemberHome } from "lib/redirects";
import AssessmentFooter from "components/assessments/AssessmentFooter";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const { elementKinds } = assessmentConstants;

class Prompt extends Component {
  static propTypes = {
    active: PropTypes.any,
    activePromptLoading: PropTypes.any,
    advance: PropTypes.func,
    assessmentVersion: PropTypes.number,
    assessmentKind: PropTypes.any,
    autoAdvance: PropTypes.bool,
    currentQuestionnaireKind: PropTypes.any,
    direction: PropTypes.any,
    formKey: PropTypes.any,
    handleError: PropTypes.func,
    hasNext: PropTypes.any,
    id: PropTypes.any,
    newSubtitle: PropTypes.any,
    newTitle: PropTypes.any,
    nextText: PropTypes.any,
    questions: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
      reduce: PropTypes.func,
    }),
    schema: PropTypes.shape({
      isValidSync: PropTypes.func,
    }),
    setPromptValidation: PropTypes.func,
    showNext: PropTypes.any,
    stackPosition: PropTypes.any,
    subtitle: PropTypes.any,
    t: PropTypes.func,
    tReady: PropTypes.any,
    title: PropTypes.any,
    values: PropTypes.any,
    handleQuestionnaireSkip: PropTypes.func,
  };

  static defaultProps = {
    autoAdvance: true,
  };

  state = {
    nextQuestionLoading: false,
    allowSkip: false,
    isHeaderPrompt: false,
  };

  trackingData = {};

  handleAdvance = async (isAnswering, skipQuestionnaire = false) => {
    this.setState({ nextQuestionLoading: true });
    if (
      (this.props.id === promptIdMapping.PCL5_HEADER ||
        this.props.id === promptIdMapping.BAM_HEADER) &&
      skipQuestionnaire
    ) {
      if (this.props.assessmentKind === AssessmentKind.ProgressUpdate) {
        return redirectToMemberHome();
      }
      return this.props.handleQuestionnaireSkip();
    }
    const response = await this.props.advance(isAnswering);
    if (response && response.error) {
      this.props.handleError(response.error);
    }
    this.setState({ nextQuestionLoading: false });
  };

  get shouldAutoAdvance() {
    const { questions } = this.props;
    if (
      questions &&
      questions.length === 1 &&
      questions[0].element &&
      questions[0].element.kind === elementKinds.RADIO
    ) {
      return true;
    }
    return false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.stackPosition !== this.props.stackPosition ||
      nextProps.direction !== this.props.direction
    ) {
      return true;
    }

    return (
      !equals(this.props.values, nextProps.values) ||
      !equals(this.state, nextState)
    );
  }

  componentDidMount() {
    document.getElementById("assessmentPageContent")?.scrollIntoView();
    this.props.setPromptValidation(this.props.id, this.isValid);
    this.ref = React.createRef();
    this.forceUpdate();
    document.getElementById("questionTitle")?.focus();
    this.setState({ allowSkip: this.isValid });
    this.setState({
      isHeaderPrompt:
        this.props.id === promptIdMapping.PCL5_HEADER ||
        this.props.id === promptIdMapping.BAM_HEADER,
    });
    this.trackingData = {
      prompt_id: this.props.id,
      prompt_title: this.props.title,
      prompt_subtitle: this.props.subtitle,
      questionnaireId: this.props.questionnaire_id,
      questionnaireKind: this.props.questionnaireKind,
      questions: this.props.questionsString,
      assessmentKind: getAssessmentKindForTracking(this.props.assessmentKind),
    };
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      "Member viewed prompt",
      this.trackingData,
    );
  }

  componentDidUpdate(nextProps) {
    this.props.setPromptValidation(this.props.id, this.isValid);

    if (
      this.props.active &&
      this.isValid &&
      this.shouldAutoAdvance &&
      !equals(this.props.values, nextProps.values)
    ) {
      this.handleAdvance(true);
    }
  }

  // This is used to switch <legend> in the Prompt card in an edge-case
  // A hard-coded string is used because translations don't always show up
  // This is used in an operation on get title() and get subtitle()
  HERE_TO_HELP_CARD_TEXT = [
    this.props.t("reviewResults.ctaSection.title"),
    "We're here to help.",
  ];

  get isOptional() {
    return !this.isMandatory;
  }

  get isMandatory() {
    const { questions } = this.props;
    return questions.reduce((acc, question) => acc || question.required, false);
  }

  get isValid() {
    // @TODO: save all the validation and pass to inputs
    if (this.props.schema) {
      return (
        this.props.schema.isValidSync(this.props.values) || this.isOptional
      );
    }
    return true;
  }

  get title() {
    let title;

    const parsedTitle = this.props.newTitle?.replaceAll("**", "");

    if (parsedTitle && this.props.newSubtitle == undefined) {
      title = (
        <h1 id="questionTitle" className={styles.goalQuestionHeader}>
          <span>{parsedTitle}</span>
        </h1>
      );
    }

    if (parsedTitle && this.props.newSubtitle != undefined) {
      title = (
        <p id="questionTitle" className={styles.goalSubtitle}>
          <span>{parsedTitle}</span>
        </p>
      );
    }

    return title;
  }

  get subtitle() {
    const subtitle = this.props.newSubtitle?.replaceAll("**", "");

    // Take care of BAM prompt edge case
    if (this.props.id === "12f38bc7-36e4-4427-af36-6cd348d6d8b2") {
      const [bamSubtitle, bamSubSubtitle] = subtitle.split("\n");
      return (
        <Box>
          <Heading
            style={{ textAlign: "left" }}
            fontSize="21px"
            lineHeight="28px"
            fontWeight="600"
          >
            {bamSubtitle}
          </Heading>
          <Text className={styles.goalSubtitle}>{bamSubSubtitle}</Text>
        </Box>
      );
    }

    if (subtitle && this.HERE_TO_HELP_CARD_TEXT.includes(this.props?.title)) {
      return (
        <h1 className={styles.goalQuestionHeader} style={{ textAlign: "left" }}>
          <span>{subtitle}</span>
        </h1>
      );
    }

    if (subtitle) {
      return (
        <h1 className={styles.goalQuestionHeader} style={{ textAlign: "left" }}>
          <span>{subtitle}</span>
        </h1>
      );
    }
    return null;
  }

  getHeaderPromptContent = () => {
    const { newTitle, newSubtitle, showNewFooter } = this.props;
    if (newTitle && newSubtitle) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Flex direction="column">
            <Heading id="questionTitle" fontWeight="700" fontSize="29px">
              {newTitle}
            </Heading>
            <Text mt="8px" whiteSpace="pre-wrap">
              {newSubtitle}
            </Text>
            <Box mt="24px" mb={showNewFooter ? 50 : 0}>
              <ConfidentialityBanner />
            </Box>
            <Flex gap="8px" mt="-24px">
              <Button
                onClick={() => {
                  this.handleAdvance(true);
                  track("Assessment -- Prompt Next Navigation", {
                    assessment_version: this.props.assessmentVersion,
                  });
                }}
                dataCy="assessment-question-next-button"
              >
                {this.props.t("continue")}
              </Button>
              <Button
                variant="medium-emphasis"
                onClick={this.handleSkip}
                dataCy="assessment-question-skip-button"
              >
                {this.props.t("skipAll")}
              </Button>
            </Flex>
          </Flex>
        </Box>
      );
    }
    return null;
  };

  getElement = (question) => {
    const { key, element, id, newTitle, newSubtitle, fieldKey } = question;

    return (
      <ElementCurator
        id={id}
        key={`question_${key}`}
        fieldKey={fieldKey}
        element={element}
        title={newTitle}
        subtitle={newSubtitle}
        disabled={this.state.nextQuestionLoading}
        dataCy={this.shouldAddDataCy ? "assessment" : ""}
        isSpinnerOn={this.props.activePromptLoading}
        toolTipRef={this.ref}
        advance={() => this.handleAdvance(false)}
      />
    );
  };

  get questions() {
    if (this.props.questions) {
      if (Array.isArray(this.props.questions)) {
        return this.props.questions.map((q) => {
          return React.cloneElement(this.getElement(q), {
            formKey: this.props.formKey,
            assessmentNext: this.props.advance,
          });
        });
      }
      return this.props.questions;
    }

    return null;
  }

  get nextText() {
    const { nextText, t, tReady } = this.props;
    if (nextText) {
      return nextText;
    }
    return tReady ? t("teenAssessments.beforeWeStart.continue") : "Continue";
  }

  get skipText() {
    const { t, tReady } = this.props;

    return tReady ? t("goals:memberGoalForm.skip") : "Skip";
  }

  handleSkip = () => {
    this.handleAdvance(true, truncate);
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Member skipped prompt",
      this.trackingData,
    );
  };

  isUndefined = (value) => {
    return value === undefined;
  };

  get nextButton() {
    const footerActions = [
      {
        label: this.nextText,
        onClick: () => {
          this.handleAdvance(true);
          TRACK_EVENT.BUTTON_CLICKED(
            window.location.pathname,
            "Member advanced prompt",
            this.trackingData,
          );
        },
        isDisabled:
          !this.isValid ||
          this.state.nextQuestionLoading ||
          (this.props.values &&
            Object.values(this.props.values).every(this.isUndefined)),
        dataCy: "assessment-question-next-button",
      },
    ];

    const legacyFooterActions = {
      primary: footerActions[0],
    };

    if (
      this.state.allowSkip &&
      this.props.currentQuestionnaireKind !== "PCL5"
    ) {
      const secondaryAction = {
        label: this.skipText,
        onClick: this.handleSkip,
        dataCy: "assessment-question-skip-button",
        isDisabled: false,
        variant: "medium-emphasis",
      };
      footerActions.push(secondaryAction);
      legacyFooterActions.secondary = secondaryAction;
    }

    const shouldShowActions =
      !this.shouldAutoAdvance || this.props.showNext || this.props.nextText;

    if (this.props.showNewFooter) {
      return <AssessmentFooter actions={shouldShowActions && footerActions} />;
    }

    if (shouldShowActions) {
      return <VTempFooter actions={legacyFooterActions} />;
    }

    return null;
  }

  get shouldAddDataCy() {
    return this.props.active && !this.props.activePromptLoading;
  }

  render() {
    return (
      <main
        ref={this.ref}
        className={classnames(
          styles[this.props.stackPosition],
          styles[this.props.direction],
          [styles.prompt],
        )}
      >
        <div>
          <FormControl className={styles.questionTextWrapper} as="fieldset">
            <FormLabel as="legend">
              <Section size="lg">
                <FlexCol justification="center" alignment={"flex-start"}>
                  {this.state.isHeaderPrompt && this.getHeaderPromptContent()}
                  {!this.state.isHeaderPrompt && this.title}
                  {!this.state.isHeaderPrompt && this.subtitle}
                </FlexCol>
              </Section>
            </FormLabel>

            <div className={styles.questions}>
              {this.questions}
              {!this.state.isHeaderPrompt && !this.props.showNewFooter && (
                <ConfidentialityBanner />
              )}

              {!this.state.isHeaderPrompt && (
                <div className={styles.nextButton}>{this.nextButton}</div>
              )}
            </div>
          </FormControl>
        </div>
      </main>
    );
  }
}

export { Prompt };

const PromptContainer = (props) => {
  const showNewFooter = useFeatureFlag(FLAGS.FOOTER_REPOSITION);
  return <Prompt showNewFooter={showNewFooter} {...props} />;
};

export default connect(
  (state, ownProps) => {
    let values = {};
    if (ownProps.questions) {
      ownProps.questions.forEach((question) => {
        const value = get(
          `${ownProps.formKey}.data.${question.fieldKey}`,
          state.form,
        );
        values[question.fieldKey] = value;
      });
    }

    return { values };
  },
  { setPromptValidation },
)(withTranslation(["assessments", "goals"])(PromptContainer));
