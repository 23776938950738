import React from "react";
import { Text, Box, Flex } from "design-system/components";
import { useTranslation, Trans } from "react-i18next";
import { Dollar, Link, useDisclosure } from "design-system/index";
import { CancellationDetailsModal } from "components/modals";
import { useNoShowPolicyConfig } from "./hooks";

const CustomerPayForNoShowCopy = ({
  customerName,
  costOfMissedAppointment,
}) => (
  <Trans
    ns="careVisits"
    i18nKey="appointmentDetails.cancellationPolicy.customerPayForNoShow"
    values={{
      customerName,
      costOfMissedAppointment,
    }}
  />
);

const MemberPayForNoShowCopy = ({ hasGracePeriod, costOfMissedAppointment }) =>
  costOfMissedAppointment ? (
    <Trans
      ns="careVisits"
      i18nKey={
        hasGracePeriod
          ? "appointmentDetails.cancellationPolicy.memberMayBeCharged"
          : "appointmentDetails.cancellationPolicy.memberWillBeCharged"
      }
      values={{
        costOfMissedAppointment,
      }}
    />
  ) : (
    <Trans
      ns="careVisits"
      i18nKey={
        hasGracePeriod
          ? "appointmentDetails.cancellationPolicy.memberMayBeChargedNoCost"
          : "appointmentDetails.cancellationPolicy.memberWillBeChargedNoCost"
      }
    />
  );

const ConfirmOrCancelPrefixCopy = () => (
  <Trans
    ns="careVisits"
    i18nKey="appointmentDetails.cancellationPolicy.confirmOrCancel"
  />
);

export const CancellationPolicy = ({
  defaultCopy = "",
  showDollarIcon = true,
  showViewPolicyCTA = false,
  showConfirmOrCancelPrefix = false,
  showAutoCancelCopy = false,
  showAutoCancelAcknowledgeCopy = false,
}) => {
  const {
    config: {
      customerName,
      customerCostForNoShow,
      customerPayForNoShow,
      requireConfirmOrCancel,
      memberPayForNoShow,
      memberCostForNoShow,
      hasGracePeriodMissedAppointments,
      showDefaultCopy,
      cohortEligibleForAutoCancel,
    },
  } = useNoShowPolicyConfig();
  const { t } = useTranslation("careVisits");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const NoShowPolicyCopy = () => {
    if (showAutoCancelAcknowledgeCopy) {
      return (
        <Trans
          ns="careVisits"
          i18nKey="appointmentDetails.cancellationPolicy.autoCancelAcknowledgeCopy"
        />
      );
    }

    if (showDefaultCopy) {
      return <Text as="span">{defaultCopy}</Text>;
    }

    if (memberPayForNoShow) {
      return (
        <MemberPayForNoShowCopy
          hasGracePeriod={hasGracePeriodMissedAppointments}
          costOfMissedAppointment={memberCostForNoShow}
        />
      );
    }

    if (customerPayForNoShow) {
      return (
        <CustomerPayForNoShowCopy
          customerName={customerName}
          costOfMissedAppointment={customerCostForNoShow}
        />
      );
    }

    return <></>;
  };

  const showCancelPrefix =
    requireConfirmOrCancel &&
    showConfirmOrCancelPrefix &&
    !cohortEligibleForAutoCancel;

  return (
    <Box>
      {/* @ts-ignore */}
      <Flex gap={8}>
        {!showDefaultCopy && showDollarIcon && (
          <Box>
            <Dollar width={25} height={24} data-testid="dollar-icon" />
          </Box>
        )}
        <Box color={"black"}>
          {!showDefaultCopy && showDollarIcon && (
            // @ts-ignore
            <Text fontWeight={"bold"} display={"inline"}>
              {t("cancelAppointment.title")}{" "}
            </Text>
          )}
          {showCancelPrefix && (
            <Text as="span">
              <ConfirmOrCancelPrefixCopy />{" "}
            </Text>
          )}
          <Text as="span">
            <NoShowPolicyCopy />
          </Text>
          {showAutoCancelCopy && cohortEligibleForAutoCancel && (
            <>
              {" "}
              <Trans
                ns="careVisits"
                i18nKey={`appointmentDetails.cancellationPolicy.cohortEligibleForAutoCancel`}
              />
            </>
          )}
          {showViewPolicyCTA && (
            <>
              {" "}
              <Link
                display={"inline"}
                color={"primary-base"}
                cursor={"pointer"}
                onClick={onOpen}
              >
                {t("cancelAppointment.viewPolicy")}
              </Link>
              <CancellationDetailsModal isOpen={isOpen} onClose={onClose} />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
