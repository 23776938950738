import { useTheme } from "../../index";

// StackedStones spot icon version 1.1
export const StackedStones = ({
  width,
  height,
  isGrayScale = false,
  colorScheme = "primary",
}) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 154 154"
      fill="none"
      aria-labelledby="stacked-stones"
      aria-hidden="true"
    >
      <title id="stacked-stones">Stacked stones</title>
      <path
        fill={isGrayScale ? colors[colorScheme][900] : colors[colorScheme].base}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.025 11.5501C102.025 23.2447 87.8043 32.7251 70.2623 32.7251C61.0873 32.7251 52.8209 30.1316 47.0225 25.9843C50.2893 35.3621 62.9017 42.3501 77.9623 42.3501C95.5042 42.3501 109.725 32.8698 109.725 21.1751C109.725 15.5972 106.49 10.523 101.202 6.74097C101.74 8.286 102.025 9.8959 102.025 11.5501ZM64.6911 91.742C92.7913 95.2772 117.204 84.217 119.218 67.0385C119.842 61.7147 118.242 56.4281 114.899 51.5617C124.456 58.5371 129.912 67.5458 128.843 76.6635C126.829 93.842 102.416 104.902 74.3162 101.367C54.9246 98.9275 38.8442 90.1748 31.4017 79.3386C39.8508 85.5055 51.5055 90.0832 64.6911 91.742ZM132.797 110.69C131.796 125.56 103.875 137.927 69.1951 138.574C45.7347 139.011 25.0339 133.976 13.5029 126.085C13.476 126.485 13.4685 126.887 13.4809 127.29C13.9545 142.691 43.2079 154.638 78.8201 153.974C114.432 153.31 142.918 140.286 142.444 124.885C142.283 119.63 138.77 114.777 132.797 110.69Z"
      />
      <path
        fill={colors[colorScheme].dark}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.875 21.175C105.875 25.3345 103.346 29.5715 98.2865 32.9446C93.2538 36.2997 86.0795 38.5 77.9626 38.5C69.8457 38.5 62.6714 36.2997 57.6387 32.9446C52.5791 29.5715 50.0501 25.3345 50.0501 21.175C50.0501 17.0155 52.5791 12.7785 57.6387 9.40541C62.6714 6.0503 69.8457 3.85 77.9626 3.85C86.0795 3.85 93.2538 6.0503 98.2865 9.40541C103.346 12.7785 105.875 17.0155 105.875 21.175ZM90.2563 40.7055C101.693 37.5021 109.725 29.9638 109.725 21.175C109.725 9.48037 95.5045 0 77.9626 0C60.4207 0 46.2001 9.48037 46.2001 21.175C46.2001 28.7616 52.1848 35.4164 61.1785 39.1555C42.5334 41.6494 28.5843 51.0554 27.0828 63.8616C25.3506 78.6361 40.7506 93.1245 62.9716 99.1392C34.2538 102.466 13.072 113.992 13.4809 127.29C13.9545 142.691 43.2079 154.638 78.8201 153.974C114.432 153.31 142.918 140.286 142.444 124.885C142.089 113.336 125.548 103.728 102.278 99.9259C117.052 96.1762 127.551 87.6774 128.842 76.6634C130.644 61.2982 113.916 46.2425 90.2563 40.7055ZM109.773 93.2759C118.957 89.1148 124.228 82.959 125.019 76.2151C125.811 69.4553 122.101 62.1429 114.125 55.8096C106.213 49.527 94.5931 44.6718 81.1288 42.978C67.6634 41.284 55.2698 43.1186 46.1526 47.2491C36.9682 51.4102 31.6973 57.566 30.9066 64.3099C30.1141 71.0697 33.8246 78.3821 41.8006 84.7154C49.7126 90.998 61.3321 95.8532 74.7964 97.547C88.2618 99.241 100.655 97.4064 109.773 93.2759ZM134.892 133.212C137.579 130.323 138.675 127.561 138.596 125.004C138.517 122.437 137.243 119.692 134.358 116.884C131.458 114.062 127.12 111.38 121.496 109.083C110.26 104.495 94.5847 101.726 77.1768 102.051C59.7673 102.375 44.2882 105.725 33.3616 110.721C27.8919 113.221 23.74 116.054 21.0332 118.963C18.346 121.852 17.2505 124.614 17.3291 127.171C17.408 129.738 18.6818 132.483 21.5672 135.291C24.4671 138.113 28.8049 140.795 34.4291 143.092C45.665 147.68 61.3404 150.449 78.7484 150.124C96.1579 149.8 111.637 146.45 122.564 141.454C128.033 138.954 132.185 136.121 134.892 133.212Z"
      />
    </svg>
  );
};
