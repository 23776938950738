import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Center, FormLabel, Input, Button } from "design-system/components";
import { addNotification } from "@spring/smeargle/actions";
import { Trans, useTranslation } from "react-i18next";
import { forgotPassword } from "actions/auth";
import forgotPasswordSchema from "schemas/forgotPassword";

type FormData = {
  email: string;
};

const ForgotPasswordForm = ({ addNotification }) => {
  const { t } = useTranslation("limitedLangAuth");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(forgotPasswordSchema),
  });

  const submitForm: SubmitHandler<FormData> = async (data) => {
    try {
      await forgotPassword(
        data.email,
        window.location.protocol +
          "//" +
          window.location.host +
          "/reset_password",
      );
      const { email } = data;

      const notificationMessage = (
        <Trans ns="limitedLangAuth" i18nKey="forgotPassword.notification">
          If {{ email }} is registered with us, you will receive instructions on
          how to reset your password.
        </Trans>
      );

      addNotification(notificationMessage, "success");
    } catch (err) {
      if (err.errors && err.errors.length) {
        addNotification(err.errors[0], "error");
      }
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await submitForm(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="reset password form">
      <FormLabel htmlFor="email">Email</FormLabel>
      <Input
        id="email"
        type="email"
        height={48}
        // NOTE: ignoring here but this is a standard Chakra variant
        // @ts-ignore
        variant="outline"
        placeholder="Email"
        colorScheme={
          !!errors.email && touchedFields.email ? "error" : "platform"
        }
        mb={24}
        defaultValue=""
        {...register("email")}
        aria-label="email input"
      />
      <Center>
        <Button
          type="submit"
          variant="solid"
          colorScheme="primary"
          isDisabled={!isValid || !isDirty}
        >
          {t("forgotPassword.submitText")}
        </Button>
      </Center>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  addNotification: PropTypes.func,
};

export default connect(null, { addNotification })(ForgotPasswordForm);
