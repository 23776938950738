import { Button } from "@springcare/sh-component-library";
import { Box, Flex, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { VFinancialHelpIcon } from "@springcare/verdant-icons-react";
import { TRACK_EVENT } from "utils/mixpanel";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { acknowledgeLateCancelMissedPolicy } from "operations/mutations/appointment";
import { useState } from "react";
import { CancellationPolicy } from "components/templates/CareVisitDetails/components";
import { useNoShowPolicyConfig } from "components/templates/CareVisitDetails/components/ConfirmationCopy/hooks";

export const AcknowledgeLateCancelContent = ({
  memberId,
  trackingProps,
  trackingType,
  onComplete,
  isModal = false,
}) => {
  const { t } = useTranslation(["careVisits"]);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const iconSize = isMobile ? 16 : 24;

  const {
    config: {
      customerName,
      customerCostForNoShow,
      memberEligibleForAutoCancel,
    },
  } = useNoShowPolicyConfig();
  const [updateAcknowledgeLateCancelTimestamp] = useMutation(
    acknowledgeLateCancelMissedPolicy,
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);

  return (
    <>
      <Text pb={16}>
        <Trans
          ns={"careVisits"}
          i18nKey={`noShowlandingPage.${memberEligibleForAutoCancel ? "autoCancelBody" : "reminder"}`}
        />
      </Text>

      <Box bg="background-subtle" borderRadius={16} p={{ base: 16, md: 24 }}>
        <Box pb={16}>
          <Heading size="heading-small" pb={8}>
            {t("noShowlandingPage.cancellationPolicy")}
          </Heading>
          <CancellationPolicy
            showDollarIcon={false}
            showViewPolicyCTA={false}
            showConfirmOrCancelPrefix={true}
            defaultCopy={t("noShowlandingPage.avoidCharge")}
            showAutoCancelAcknowledgeCopy={memberEligibleForAutoCancel}
          />
        </Box>
        <Box>
          {!memberEligibleForAutoCancel && (
            <Text display="inline" size="body-medium-strong">
              {t("noShowlandingPage.changesWithin24hours")}
            </Text>
          )}
          <Flex direction={"column"} pt={12} gap={8}>
            <Flex direction={"row"} gap={8}>
              <Box mt={isMobile ? 1 : 0} mr={4} color={"primary-base"}>
                <VFinancialHelpIcon height={iconSize} width={iconSize} />
              </Box>
              <Text>{t("noShowlandingPage.losingNoCost")}</Text>
            </Flex>
            <Flex direction={"row"} gap={8}>
              <Box mt={isMobile ? 1 : 0} mr={4} color={"primary-base"}>
                <VFinancialHelpIcon height={iconSize} width={iconSize} />
              </Box>
              <Trans
                ns={"careVisits"}
                i18nKey={
                  memberEligibleForAutoCancel && customerCostForNoShow
                    ? "noShowlandingPage.customerCost"
                    : "noShowlandingPage.costOfFullAppt"
                }
                values={{
                  customerName: customerName,
                  customerCostForNoShow: customerCostForNoShow,
                }}
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box pt={32} pb={!isModal && { base: 26, md: 16 }}>
        <Button
          minW="100%"
          disabled={submitDisabled}
          onClick={() => {
            if (memberId) {
              setSubmitDisabled(true);
              TRACK_EVENT.BUTTON_CLICKED(
                window.location.pathname,
                trackingType,
                trackingProps,
              );
              updateAcknowledgeLateCancelTimestamp({
                variables: {
                  member_id: memberId,
                },
                onCompleted: () => {
                  onComplete();
                },
              });
            }
          }}
        >
          {t("noShowlandingPage.acknowledge")}
        </Button>
      </Box>
    </>
  );
};
