export const isCoachWithParentingSpecialty = (coach) =>
  coach?.care_provider_tags?.find(
    (tag) => tag.kind === "SPECIALTY" && tag.name === "Parenting",
  );

export const shouldShowJourneySection = (member) => {
  return (
    member?.first_upcoming_appointments?.length > 0 ||
    member?.visits_eligibility_limit_date
  );
};

export const getWorkplaceBannerPosition = (
  isAWorkplaceManager,
  dismissedWorkplaceBanner,
  canViewWorkplaceBanner,
  workplacePageViewed,
  memberShouldSeeManagerExperienceBanners = false,
  showSelfIdAtTopExperiment2 = false,
  showUnderPreAssessedExperience = false,
) => {
  let showCardByPosition = {
    showBannerOnTop: false,
    showBannerOnBottom: false,
    showExploreCard: false,
  };

  if (!canViewWorkplaceBanner || !memberShouldSeeManagerExperienceBanners)
    return showCardByPosition;

  if (workplacePageViewed) return showCardByPosition;

  // Will render <WorkplaceManagerSelfIdentificationCard/> on top of the page
  if (isAWorkplaceManager === null && dismissedWorkplaceBanner === null) {
    showCardByPosition = {
      showBannerOnTop: true,
      showBannerOnBottom: false,
      showExploreCard: false,
    };
  }

  /*
      if member changes route <WorkplaceManagerSelfIdentificationCard/> 
      will render under your care section
    */
  if (isAWorkplaceManager === null && dismissedWorkplaceBanner === false) {
    showCardByPosition = {
      showBannerOnTop: showSelfIdAtTopExperiment2,
      showBannerOnBottom: !showSelfIdAtTopExperiment2,
      showExploreCard: false,
    };
  }

  if (isAWorkplaceManager === null && showUnderPreAssessedExperience) {
    showCardByPosition = {
      showBannerOnTop: true,
      showBannerOnBottom: false,
      showExploreCard: false,
    };
  }

  /* 
      if member selects workplace manager and changes route, 
      will render <WorkplaceManagerExploreCardProps> under your care section
    */
  if (isAWorkplaceManager === true && dismissedWorkplaceBanner === false) {
    showCardByPosition = {
      showBannerOnTop: false,
      showBannerOnBottom: true,
      showExploreCard: true,
    };
  }
  /* 
      if member selects workplace manager and immediately refreshes the page, 
      will render <WorkplaceManagerExploreCardProps> under your care section
    */
  if (isAWorkplaceManager === true && dismissedWorkplaceBanner === null) {
    showCardByPosition = {
      showBannerOnTop: true,
      showBannerOnBottom: false,
      showExploreCard: true,
    };
  }

  return showCardByPosition;
};
