import { ReactElement } from "react";
import { Button } from "@springcare/sh-component-library";
import { Box, Flex, ListItem, OrderedList, Text } from "@chakra-ui/react";

interface HiddenContentProps {
  bullets: string[];
  endText: string;
  ctaText: string;
  onCTAClick: () => void;
  isFullScreenModalExperience?: boolean;
}

const HiddenContent = ({
  bullets,
  endText,
  ctaText,
  onCTAClick,
  isFullScreenModalExperience = false,
}: HiddenContentProps): ReactElement => {
  return (
    <Flex
      textAlign="left"
      pt={16}
      p={1}
      h="100%"
      direction="column"
      justifyContent={
        isFullScreenModalExperience ? "space-between" : "flex-start"
      }
    >
      <Box>
        <OrderedList>
          {bullets.map((bullet, index) => {
            // This is a temporary logic, will be removed once translations are in
            const [beforeColon, afterColon] = bullet.split(":");
            return (
              <ListItem key={index}>
                {afterColon ? (
                  <>
                    <strong>{beforeColon}</strong>:{afterColon}
                  </>
                ) : (
                  bullet
                )}
              </ListItem>
            );
          })}
        </OrderedList>
        <Text fontWeight={700} mt={20}>
          {endText}
        </Text>
      </Box>
      <Box>
        <Button
          mt={16}
          size="md"
          variant={
            isFullScreenModalExperience ? "high-emphasis" : "medium-emphasis"
          }
          onClick={onCTAClick}
          float={isFullScreenModalExperience ? "right" : "none"}
        >
          {ctaText}
        </Button>
      </Box>
    </Flex>
  );
};

export default HiddenContent;
