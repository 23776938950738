import { useState, useEffect } from "react";
import { useRemoteParticipants } from "@livekit/components-react";
import { Badge } from "@springcare/sh-component-library";
import { Box, keyframes } from "@chakra-ui/react";

import { useTranslation } from "hooks/react-i18next";

const ProviderStatusBadge = ({ sessionData }) => {
  const remoteParticipants = useRemoteParticipants();
  const [isProviderInRoom, setIsProviderInRoom] = useState(false);

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "providerStatusBadge",
  });

  const { provider_name } = sessionData;

  const COPY_STRINGS = [t("first"), t("second")];

  const [currentText, setCurrentText] = useState(COPY_STRINGS[0]);
  const [nextText, setNextText] = useState(COPY_STRINGS[1]);
  const [showNext, setShowNext] = useState(false);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    const isProviderPresent = remoteParticipants.some(
      (participant) => participant.attributes?.participant_type === "provider",
    );
    setIsProviderInRoom(isProviderPresent);
  }, [remoteParticipants]);

  useEffect(() => {
    if (isProviderInRoom) {
      const message = t("providerIsInRoom", {
        providerName: provider_name,
      } as any);
      setCurrentText(message);
      // Reset animation
      setShowNext(false);
    } else {
      // Immediate reset of copy when provider leaves
      setCurrentText(COPY_STRINGS[0]);
      setNextText(COPY_STRINGS[1]);
      // Force re-render to display updated copy
      setRenderKey((key) => key + 1);
    }
  }, [isProviderInRoom]);

  useEffect(() => {
    // Don't run animation if provider is in the room
    if (isProviderInRoom) return;

    const interval = setInterval(() => {
      setShowNext(true);
      setTimeout(() => {
        setCurrentText(nextText);
        setNextText((prev) => {
          const nextIndex =
            (COPY_STRINGS.indexOf(prev) + 1) % COPY_STRINGS.length;
          return COPY_STRINGS[nextIndex];
        });
        // Reset for next cycle
        setShowNext(false);
        // Reset for next cycle (otherwise 'key' bugs in console)
        setRenderKey((key) => key + 1);
        // Animation duration
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [isProviderInRoom, nextText, renderKey]);

  const fadeInDown = keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `;

  const fadeOutDown = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  `;

  return (
    <Badge
      variant="high-emphasis"
      w="100%"
      bg={isProviderInRoom ? "primary-base" : "info-base"}
      mb="v-8"
      position="relative"
      overflow="hidden"
      sx={{
        display: "inline-block",
        textAlign: "center",
        overflowX: "visible",
      }}
    >
      <Box
        key={`current-${renderKey}`}
        sx={{
          animation: showNext ? `${fadeOutDown} 0.5s ease forwards` : undefined,
          position: showNext ? "absolute" : "relative",
        }}
      >
        {currentText}
      </Box>

      <Box
        key={`next-${renderKey}`}
        w="100%"
        sx={{
          animation: showNext ? `${fadeInDown} 0.5s ease forwards` : undefined,
          position: showNext ? "relative" : "absolute",
          visibility: showNext ? "visible" : "hidden",
        }}
      >
        {nextText}
      </Box>
    </Badge>
  );
};

export default ProviderStatusBadge;
