//

const adhd = {
  title: "Attention-deficit/hyperactivity disorder (ADHD)",
  overview:
    "Attention-Deficit/Hyperactivity Disorder is a condition that affects people's ability to focus, think carefully about things before acting and not feel restless, bored or understimulated easily.  These problems often first show up in childhood and involve problems with brain reward and inhibition.  There are three main problem areas in ADHD: inattention, impulsivity and hyperactivity.  Not everyone with ADHD has problems with all three of these, however. Inattention refers to problems with focus, starting tasks (particularly those that are boring, tedious or mentally challenging), sustaining attention, procrastination and follow through.  Impulsivity is when you do behaviours that aren't thought through fully.  This can mean doing things on a whim, not thinking about the consequences of actions, or doing things which are risky or put you or others at danger.  Hyperactivity is things like being restless, being constantly active or fidgeting a lot.  Adults with hyperactivity may also feel like they need to constantly be on the go and may have trouble relaxing or doing one thing at a time. These problems with attention and behaviour can cause people with ADHD to have a lot of problems at home, work, and in relationships.",
  sections: [
    {
      title: "How might this affect me?",
      content:
        "Common symptoms of inattention include finding it hard to keep focus on a task or activity, being easily distracted or forgetful, making careless mistakes, starting things but not finishing them, and struggling to keep life organised. Inattention often results in your avoiding or struggling to begin tasks which require sustained periods of concentration – procrastination, in other words. Problems with impulsivity and hyperactivity, on the other hand, may look like fidgetting frequently or talking quickly or excessively. You may even exhibit erratic behaviours at times, like ending relationships seemingly without reason or quitting jobs more frequently than most of your peers. Some people with ADHD have problems with regulating their emotions, too. If you have ADHD, you may find yourself having easily changing moods, being easily irritated, or struggling to motivate yourself; or you may experience episodes of anxiety or low mood. Depending upon the type of ADHD you have, you may present with mostly one type of symptom, or a combination of all three.",
    },
    {
      title: "What help is there for me?",
      content:
        "If you think you may have adult ADHD, you should speak to your physician who may diagnose you if you fit the necessary criteria. Irrespective of the medical treatments recommended to you by your physician, there are plenty of lifestyle changes you can make to help you cope with the symptoms you have and reduce their impact on your life. Some people find that their ADHD is made worse by specific foods or drinks, and you may find it helpful to keep a food and symptom diary to see if certain things make your symptoms worse. Many people find that practicing mindfulness can help calm them down, and regain their focus in short periods of time, and so breathing exercises or other forms of mindfulness may help you. Regular exercise can be helpful in releasing energy and stabilising your moods. Keeping a regular bedtime routine, and other good sleep habits, can help to regulate your mood and attention. It's also important to keep a structure to your days and weeks as this can help improve the effects of your difficulty organizing tasks. Keeping calendars and tasks lists at home and at work can help to keep you productive and improve relationships in both settings. You may also find that making small, realistic, and time-dependent goals improves your productivity and reduces the impact of your attention difficulties.",
    },
    {
      title: "What happens next?",
      content:
        "Most physicians recommend medications for ADHD, sometimes alongside psychotherapies. If you decide to take medication you would take daily tablets or capsules, which should help with your symptoms. These medications may be stimulants or non-stimulants, which can change the side effects you might experience. With stimulants, you may find that although you see a change in your ability to focus and in your ability to control certain behaviours quickly, you might experience difficulty sleeping, appetite suppression, or headaches. You may find it more suitable to have short-acting stimulants to help you when you need it, or longer acting to help you get through the day. With non-stimulants, which take longer to have an effect on your focus and behaviour, you may find that you feel sick, have a change in bowel habits, or feel drowsy more often. As well as medication, you may be recommended psychotherapies such as Cognitive Behavioural Therapy (CBT). Many people find that medication improves many of their symptoms, but doesn't help with their ability to motivate themselves, prioritize tasks, plan their life, or keep track of things which need doing. CBT can help you develop the skills and habits needed to overcome these difficulties, alongside the medication. CBT usually lasts between three to four months, and therapists give homework to do between sessions to help integrate new skills and techniques into your life.",
    },
  ],
  books: [
    {
      title:
        "Driven to Distraction (Revised): Recognizing and Coping with Attention Deficit Disorder",
      byline: "Edward M. Hallowell M.D., John J. Ratey M.D.",
      link: "https://www.amazon.com/Delivered-Distraction-Getting-Attention-Disorder/dp/0345442318/",
    },
    {
      title: "Taking Charge of Adult ADHD 1st Edition",
      byline: "Russell A. Barkley, PhD",
      link: "https://www.amazon.com/Taking-Charge-Adult-Russell-Barkley/dp/1606233386/",
    },
    {
      title:
        "The Mindfulness Prescription for Adult ADHD: An 8-Step Program for Strengthening Attention, Managing Emotions, and Achieving Your Goals",
      byline: "Lidia Zylowska, MD, and Daniel Siegel, MD",
      link: "https://www.amazon.com/Mindfulness-Prescription-Adult-ADHD-Strengthening/dp/1590308476",
    },
    {
      title: "Organizing Solutions for People with ADHD",
      byline: "Susan C. Pinsky",
      link: "https://www.amazon.com/Organizing-Solutions-People-Revised-Updated/dp/1592335128",
    },
    {
      title:
        "Mastering Your Adult ADHD: A Cognitive-Behavioral Treatment Program, Client Workbook (Treatments That Work) 2nd Edition",
      byline:
        "Steven A. Safren, Susan E. Sprich, Carol A. Perlman, Michael W. Otto",
      link: "https://www.amazon.com/Mastering-Your-Adult-ADHD-Cognitive-Behavioral/dp/019023556X",
    },
  ],
  resources: [
    {
      text: "The facts on ADHD by  the Attention Deficit Disorder Association",
      link: "https://add.org/adhd-facts/",
    },
    {
      text: "Information about ADHD provided by CHADD, The National Resource on ADHD",
      link: "https://www.chadd.org/understanding-adhd/about-adhd.aspx",
    },
    {
      text: "Suggestions for coping with ADHD in the workplace (from CHADD)",
      link: "https://www.chadd.org/Understanding-ADHD/For-Adults/Workplace-Issues.aspx",
    },
    {
      text: "The basics of ADHD by the National Institute of Mental Health",
      link: "https://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder-adhd-the-basics/index.shtml",
    },
  ],
};

const alcoholism = {
  title: "Alcohol Use Disorder/Alcoholism",
  overview:
    'Alcohol Use Disorder is the official diagnosis given to people who have difficulty controlling their drinking.  This includes a range of people: some who struggle to control how much they drink, some who can\'t stop drinking once they\'ve started, and others who are dependent upon alcohol and struggle to function without it.  You may have heard of examples of this condition before, including "binge drinkers" who drink excessively in short amounts of times, or "alcoholism" which refers to people with an addiction or dependence on alcohol.',
  sections: [
    {
      title: "How might this affect me?",
      content:
        "You may find yourself drinking more, or for longer amounts of time than you mean to, and struggle to stop once you start. You may also develop a tolerance to alcohol, and need to drink more to get the same effect. This need to drink could take up a lot of your thoughts and cause problems at home or work. In severe cases, people find that they get the \"shakes\", feel hung-over, or have mood changes when they haven't drank. People with severe alcohol use disorders often find themselves in trouble as a result of drinking, for example with the police because they're caught driving under the influence of alcohol.",
    },
    {
      title: "What help is there for me?",
      content:
        "Alcohol Use Disorders are quite common, and there are many resources available for you to use to try to improve your condition, with the ultimate goal of stopping drinking completely. There are support groups where you can meet other people in similar situations and get friendly, judgement-free advice on how to stop drinking. Sometimes, when people spend less time drinking, they struggle stay sober because they don't know what else to do with their time. Alcoholics Anonymous is probably the most popular self-help program, and it can also help you to fill your time with activities that do not involve drinking. Either way, it is important to find other hobbies or activities to do to fill up the free time in a positive way to ensure you are able to quit effectively. It is also important to recognise and avoid things which trigger your desire and urge to drink.",
    },
    {
      title: "What happens next?",
      content:
        "If self-help methods and support groups aren't enough to help you quit drinking, we recommend that you speak to your physician. They may refer you to a therapist for Cognitive Behavioural Therapy, in which your therapist will help you develop methods which help you overcome the specific issues preventing you from recovery. There are other therapies which may focus on your relationships with other people, or your environment, to help you overcome your addiction. These treatments can last for months, and require you to do \"homework\" between sessions. This could include keeping diaries on your daily habits, feelings related to alcohol, or amount of time spent drinking. If these don't work, they may recommend you begin treatment with medication, which would require you to take tablets or get injections. These don't always reduce your cravings, but can make it severely uncomfortable to drink and so help deter you from it. If these steps don't work for you, you could also consider a residential treatment program (\"rehab\"), where people go to stay at a treatment facility for 30-, 60-, or 90-day stays to stop drinking.",
    },
  ],
  books: [
    {
      title: "Alcoholics Anonymous: The Big Book",
      byline: "Anonymous",
      link: "https://www.amazon.com/Alcoholics-Big-Book/dp/1893007170/",
    },
    {
      title: "Twelve Steps and Twelve Traditions",
      byline: "AA World Services Inc",
      link: "https://www.amazon.com/Twelve-Steps-Traditions-World-Services-ebook/dp/B00HGJL79Q/",
    },
    {
      title: "Quitting Alcohol Workbook: Your Personal Recovery Plan",
      byline: "Cardwell C. Nuckols, PhD",
      link: "https://www.amazon.com/Quitting-Alcohol-Workbook-Personal-Recovery/dp/1592853943",
    },
    {
      title:
        "This Naked Mind: Control Alcohol: Find Freedom, Rediscover Happiness & Change Your Life",
      byline: "Annie Grace",
      link: "https://www.amazon.com/This-Naked-Mind-Rediscover-Happiness/dp/0996715002/",
    },
  ],
  resources: [
    {
      text: "An overview of substance use and mental health by the National Institute of Mental Health",
      link: "https://www.nimh.nih.gov/health/topics/substance-use-and-mental-health/index.shtml",
    },
    {
      text: "Mayo Clinic's overview of alcohol use disorder",
      link: "https://www.mayoclinic.org/diseases-conditions/alcohol-use-disorder/basics/definition/con-20020866",
    },
    {
      text: "An introduction to Alcoholics Anonymous",
      link: "https://www.aa.org/pages/en_US/index",
    },
    {
      text: "Find a self-help recovery group",
      link: "https://www.ncadd.org/people-in-recovery/hope-help-and-healing/self-help-recovery-support-groups",
    },
    {
      text: "How to stop drinking and start recovery",
      link: "https://www.helpguide.org/articles/addictions/overcoming-alcohol-addiction.htm",
    },
  ],
};

const anxiety = {
  title: "Anxiety",
  overview:
    "Anxiety disorders are characterized by varying degrees of excessive fear, worry, ruminations and physical symptoms of anxiety. Sometimes anxiety begins without any clear cause. However, many people live stressful lives, and anxiety typically emerges or worsens during stressful periods. Occasionally, the symptoms go away on their own once the stress has subsided. However, if the anxiety persists, or regularly comes back, or has a big impact on daily life then an anxiety disorder is likely, and it is time to intervene.",
  sections: [
    {
      title: "How might this affect me?",
      content:
        "When you get anxious, you may feel like something terrible is about to happen. You may also sweat, feel your heart racing, feel sick or light headed, and some people feel like they are struggling to breathe. People can also feel tense, restless, tired, and feel a pit in their stomach. This may cause you to avoid doing activities that make you anxious, or leave you unable to do everyday tasks. In anxiety disorders like social anxiety or panic disorder, these symptoms often feel like they last for a long period of time, but often come on suddenly and last a few minutes. For other anxiety disorders, like generalized anxiety disorder, post-traumatic stress disorder, or obsessive compulsive disorder, the symptoms might be less extreme but more consistent or more frequent. In extreme cases of anxiety, you may experience what people call a panic attack. In these you may feel changes in your body happen quickly. You may suddenly feel light headed or dizzy, and become aware of your heartbeat. Some people feel hot or cold, and like they are sweating and shaking. Sometimes people feel like they have tightness or pain in their chest or stomach and like they are having trouble breathing, and may even be about to faint. Many of the techniques which you may read about to help with anxiety will also help with panic attacks.",
    },
    {
      title: "What help is there for me?",
      content:
        "If you experience any of these symptoms, you should seek professional help from a physician. Depending upon how much your anxiety is affecting you they may recommend therapy, with or without medication. The therapy and medications offered to you will be based upon the type of anxiety disorder you have and what is most likely to help you. There are many online resources and books available to you, which can help you to understand your feelings and behaviours, and change them to improve your symptoms. There are things that you can do in your day-to-day life to try to improve your symptoms. Aerobic exercise has been shown to help, along with keeping a routine, so going for regular walks or doing sports can help with symptoms. Mindfulness, meditation, and relaxation methods such as breathing exercises, have been shown to help people with different types of anxiety as well.",
    },
    {
      title: "What happens next?",
      content:
        'You will most likely be referred to a therapist, who will see you regularly to help you understand and cope with your feelings and symptoms. They will help you identify and change unhelpful thoughts and behaviours affecting your illness. Therapists usually give "homework" to do between sessions; this can range from tracking your feelings or symptoms, to actively doing things which make you anxious, a technique called exposure. If you decide to take medication to help improve your symptoms, you would take a medication that is likely to help treat your type of anxiety disorder. This could mean taking tablets once or twice a day. Soon after beginning your medication, you may notice some side effects including: tiredness, feeling sick, changing bowel habits, or sweating. Most often, these subside after the first week or two. It is important to keep taking your tablets, as it can take up to six weeks for the treatment to start working, depending upon which type you are prescribed. If the side effects are impacting your life, contact your physician. They may recommend an alternative medication or help you manage the side effects.',
    },
  ],
  books: [
    {
      title: "The Anxiety & Phobia Workbook",
      byline: "Edmund Bourne PhD",
      link: "https://www.amazon.com/Feeling-Good-New-Mood-Therapy/dp/0380810336/ref=sr_1_1?s=books&ie=UTF8&qid=1503463430&sr=1-1&keywords=Feeling+Good",
    },
    {
      title: "Mastery of Your Anxiety and Worry: Workbook",
      byline: "Michelle G. Craske",
      link: "https://www.amazon.com/Mind-Over-Mood-Second-Changing/dp/1462520421/ref=sr_1_1?ie=UTF8&qid=1503463410&sr=8-1&keywords=mind+over+mood",
    },
    {
      title: "Overcoming Anxiety",
      byline: "Helen Kennerley, PhD",
      link: "https://www.amazon.com/Overcoming-Anxiety-Books-Prescription-Title/dp/1849018782",
    },
    {
      title:
        "When Panic Attacks: The New, Drug-Free Anxiety Therapy That Can Change Your Life",
      byline: "David D. Burns",
      link: "https://www.amazon.com/When-Panic-Attacks-Drug-Free-Anxiety-ebook/dp/B000GCFVV0/",
    },
  ],
  resources: [
    {
      text: "An overview of Anxiety Disorders, from the National Institute of Mental Health",
      link: "https://www.nimh.nih.gov/health/topics/anxiety-disorders/index.shtml",
    },
    {
      text: "Tips to help manage anxiety, from the Anxiety and Depression Association of America",
      link: "https://adaa.org/living-with-anxiety/managing-anxiety",
    },
    {
      text: "Simple exercises to help you relax, from the charity MIND",
      link: "https://www.mind.org.uk/information-support/tips-for-everyday-living/relaxation/#.WdvO9RNSzUJ",
    },
    {
      text: "More techniques to help relax and reduce stress levels",
      link: "https://www.helpguide.org/articles/stress/relaxation-techniques-for-stress-relief.htm",
    },
  ],
};

const bipolar = {
  title: "Bipolar Disorder",
  overview:
    'Bipolar Disorders are characterised by periods of elevated mood states and depression.  The elevated mood states of bipolar disorders include hypomania and mania where you feel very excitable, have a lot of energy, and do a lot of things in a way that is very uncharacteristic of you when you are in a normal "good mood".  Hypomania and mania can feel either pleasurable (e.g. feeling on top of the world, elated, high, and very confident) or unpleasurable (e.g. feeling irritable, aggressive, and on edge. The severity of these episodes can vary: sometimes people feel energised, excitable, and productive; while sometimes they have erratic and impulsive behaviour, or even hallucinations.  During periods of depression, people with bipolar disorders may feel low in mood and energy, struggle to do daily activities, or in severe cases, think of hurting themselves or other people.',
  sections: [
    {
      title: "How might this affect me?",
      content:
        'The extent to which your manic or depressed episodes affect you will be dependent upon how severe they are. You could experience mild mania, or "hypomania" where you feel full of energy, euphoric, and productive. You may also feel easily distracted or unable to concentrate, stopping you from doing day-to-day activities. This may make you more active than usual, sleep less, or act out of character. In severe episodes, people sometimes experience delusional thoughts, or hallucinations, which result in uncharacteristic or risky behaviours. During depressed episodes, you may feel low in mood, or even emotionally "empty". You may feel tired and lacking in energy, under-confident, or even hopeless. In severe cases, some people have suicidal thoughts. These feelings may result in you struggling with sleep, avoiding people or normal activities, and being less active. The contrast between episodes of mania and depression may make you feel less capable of coping with both. Sometimes people experience mixed episodes of mania and depression, where features of mania and depression coexist. This can be a particularly uncomfortable mood state that is confusing to understand and manage.',
    },
    {
      title: "What help is there for me?",
      content:
        "If you notice that your behaviour has become more risk-taking or you are having suicidal thoughts, it is important that you seek professional help immediately. You can also call the National Suicide Prevention Lifeline at 1-800-273-TALK (8255) if you are in crisis and feel unable to cope. While medical help is important in your long-term care, there are many resources available to you which can help you understand your condition and more easily manage your feelings and life. Many people find that monitoring their mood, sleep, medication, and daily events can help them keep on top of their feelings and make it easier to notice early signs of mania or depression. You may find that talking to friends and family about the condition or joining support groups helps you cope with your changing mood. Exercise can be helpful in establishing a daily routine and managing medication side effects, however it is important to speak to your physician to ensure it is ok to exercise while taking your medication. There are also books available which tell the stories of other people with bipolar disorders, which can help you understand the experience more and reduce feelings of isolation.",
    },
    {
      title: "What happens next?",
      content:
        "When speaking to your physician, they may recommend that you begin taking medication, or undertaking psychotherapy. If you choose to undertake therapy, you would see your therapist weekly or every other week. Your therapist will help you understand your thoughts and behaviors in your manic and depressed periods, to help change them and reduce their impact on your life. Therapists usually give homework activities, which are things you should do between sessions to help your progress, such as keeping a mood diary or tracking your behaviors. If you decide to take medication, you would start taking tablets once or twice a day. You may need regular blood tests to monitor the levels of the medication in your blood. It is important to keep taking the tablets, as you may not see an effect for several weeks. You may notice some side effects before any behaviour improvements, such as shaky hands, increased thirst, increased urination, changes in bowel habits, or nausea. These often improve as your body adjusts to the medication, but it is important to tell your physician if you notice any changes, as they may be able to help.",
    },
  ],
  books: [
    {
      title:
        "Manic-Depressive Illness: Bipolar Disorders and Recurrent Depression",
      byline: " Frederick K. Goodwin & Kay Redfield Jamison",
      link: "https://www.amazon.com/Manic-Depressive-Illness-Disorders-Recurrent-Depression/dp/0195331532/",
    },
    {
      title:
        "The Bipolar Disorder Survival Guide: What You and Your Family Need to Know",
      byline: "David J. Miklowitz",
      link: "https://www.amazon.com/The-Bipolar-Disorder-Survival-Guide-Second-Edition-What-You-and-Your-Family-Need-to-Know/dp/1606235427/",
    },
    {
      title: "Madness: A Bipolar Life",
      byline: "Marya Hornbacher",
      link: "https://www.amazon.com/Madness-Bipolar-Life-Marya-Hornbacher/dp/0618754458/",
    },
  ],
  resources: [
    {
      text: "An overview of bipolar disorder, from the National Institute of Mental Health",
      link: "https://www.nimh.nih.gov/health/topics/bipolar-disorder/index.shtml",
    },
    {
      text: "Mayo Clinic's overview of bipolar disorder",
      link: "https://www.mayoclinic.org/diseases-conditions/bipolar-disorder/home/ovc-20307967",
    },
    {
      text: "Find an in-person support group near you",
      link: "https://www.dbsalliance.org/site/PageServer?pagename=peer_support_group_locator",
    },
    {
      text: "Learn strategies for coping with bipolar disorder online",
      link: "https://www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=38",
    },
    {
      text: "Lessons that will teach you how to treat and manage bipolar disorder",
      link: "https://psychologytools.com/bipolar.html",
    },
  ],
};

const depression = {
  title: "Depression",
  overview:
    "Depression is a common condition that is characterized by low mood, trouble having adequate pleasure in life, persistent negative thoughts, a lack of interest in doing normal activities, and a lack of energy. It can also affect your sleep and appetite, cause feelings of physical pain, and make people think about hurting themselves.",
  sections: [
    {
      title: "How might this affect me?",
      content:
        'You may find yourself feeling "down" or "numb" or sad for no reason, and less interested in doing the things you would normally do, such as shopping, studying, working or socializing. You may feel tired more frequently, or that you tire easily when doing things. In more severe cases, you may feel like you cause problems for those around you, that things are too difficult to manage, and that you want to commit suicide. These feelings may begin, or continue, without any clear cause or they may be triggered by psychological stress such as financial problems and emotional, physical or sexual abuse. They may also be triggered by emotional setbacks in your life, such as the end of a relationship, the end of a marriage, loss of job or identity or death of someone important to you.',
    },
    {
      title: "What help is there for me?",
      content:
        "Most importantly, anyone thinking of suicide should immediately contact their physician or, if you are unable to reach them, the National Suicide Prevention Lifeline at 1-800-273-TALK (8255). There are also plenty of resources available that can help you find ways of coping with your illness. There are changes you can make in your day-to-day life that may improve your symptoms. Good sleep habits can help you sleep better at night, and feel more energized during the day. A routine in the morning and evening is important to sleeping well, as is going outside during the daytime. It is important to try to keep active, even when you just want to stay in bed all day. Going out for a walk, or doing exercise three to four times per week can improve your mood and feelings of tiredness. If you speak to a physician, they may advise you to start medication, therapy, or both. If any of this is confusing to you, there are plenty of resources online which can give you more information on which therapy is right for you.",
    },
    {
      title: "What happens next?",
      content:
        "If you decide to take medication to help with your symptoms, you would take tablets or capsules once or twice a day. Some people begin to feel better after four weeks, but it can take up to twelve weeks for treatment to take full effect so it's important to stick with the treatment even if you think it's not helping. Within a few days of starting a medication, you may begin to feel some side effects; these often improve as you take the tablet for longer. They could make you feel sick, increase your appetite, cause headaches, make you sleepy or make it difficult for you to fall asleep, or even make you anxious. It's important to mention any side effects to your physician, who may be able to help. If you decide to undertake a course of therapy, you would see a therapist weekly or every other week. Your therapist will help you cope with and better understand your feelings. They will also help you change unhealthy thought patterns and behaviors that may be contributing to your depression. Therapists usually give \"homework\" between sessions - things for you to do or think about between sessions - to help you understand your feelings and begin to modify maladaptive thought patterns behaviors. Homework in therapy for depression can range from keeping a mood diary, doing a thought record, challenging cognitive distortions or getting you to do activities that you used to do when you were feeling well, such as exercising and socializing.",
    },
  ],
  books: [
    {
      title: "Feeling Good: The New Mood Therapy",
      byline: "David Burns",
      link: "https://www.amazon.com/Feeling-Good-New-Mood-Therapy/dp/0380810336/",
    },
    {
      title:
        "Mind Over Mood: Change How You Feel by Changing the Way You Think",
      byline: "Dennis Greenberger PhD & Christine A. Padesky PhD",
      link: "https://www.amazon.com/Mind-Over-Mood-Second-Changing/dp/1462520421/",
    },
  ],
  resources: [
    {
      text: "The basics of depression by the National Institute of Mental Health.",
      link: "https://www.nimh.nih.gov/health/publications/depression/index.shtml",
    },
    {
      text: "An overview of depression, from the Mayo Clinic",
      link: "https://www.mayoclinic.org/diseases-conditions/depression/home/ovc-20321449",
    },
    {
      text: "Tips for every day living with depression, by the MIND charity",
      link: "https://www.mind.org.uk/information-support/tips-for-everyday-living/",
    },
    {
      text: "A video overview on depression, by NAMI",
      link: "https://www.nami.org/Learn-More/Mental-Health-Conditions/Depression",
    },
    {
      text: "Information about medications that help treat depression",
      link: "https://www.webmd.com/depression/guide/depression-medications-antidepressants",
    },
  ],
};

const eatingDisorder = {
  title: "Eating Disorders",
  overview:
    "Eating Disorders are a group of conditions that all affect the way that a person relates to food, their body weight, or body shape.  They can affect men and  women, run in families, and be related to childhood events - or have no obvious cause.  Common eating disorders include Binge Eating Disorder, Bulimia Nervosa and Anorexia Nervosa.",
  sections: [
    {
      title: "How might this affect me?",
      content:
        "Different eating disorders can affect you in different ways, and often in different ways to how other people with them are affected. Some people think obsessively about their weight, particularly that they are overweight; they may be terrified of gaining more weight. This leads to them eating small amounts of food for long periods of time (caloric restriction) and becoming extremely thin. Other people find that they go through periods where they are unable to stop themselves from eating unusually large amounts of food. These are called binge episodes. Some people who binge compensate for their large caloric intake by resorting to methods that the food out of their system, such as vomiting or using laxatives, or to over-exercising.. An eating disorder can be diagnosed in cases where someone has more subtle changes to how they see themselves or more mild cases of disordered eating.",
    },
    {
      title: "What help is there for me?",
      content:
        "If you think you might have an eating disorder and need help, you should see your physician. They may be able to provide you with medical care. There are also things that you can do day-to-day to try to improve your symptoms and relationship to your body and food. You may find it useful to keep a food diary, with the aim to ensure that you are getting the right amounts of nutrients and calories everyday to keep yourself healthy. You may find it easy to plan your weekly meals and exercise in advance to ensure you are getting enough food, and not over-exercising. Some people find that their moods and daily events can affect their eating, and so keeping a diary centered on things that happened each day and how they made you feel may help you. You may also find it helpful to speak to other people about your eating disorder, whether they are your peers or a support group of people with similar problems.",
    },
    {
      title: "What happens next?",
      content:
        "There are many options for treatment that you can access through your physician. Treatments can be tailored to your needs and what you're most comfortable with. You may decide to undertake a course of psychotherapy. This could be eating disorder-focused cognitive behavioural therapy, which is centred around negative patterns of thinking and the beliefs that cause them, and helps you to recognize and overcome them. This therapy consists of weekly sessions for several months in which your therapist gives you activities to perform between sessions, ranging from keeping a food diary to eating specific foods to limiting exercise. There are some medications that you could take to help you with your condition. Some eating disorders respond better to medication than others, so your physician may recommend a certain medication to you. These have a variety of side effects, however it is sometimes possible to take different medications, or change doses to reduce their impact on your life whilst improving your health. It is important to know that although you may not feel benefits from some medications for up to six weeks, you may feel side effects within days of starting treatment.",
    },
  ],
  books: [
    {
      title:
        "Overcoming Binge Eating: The Proven Program to Learn Why You Binge and How You Can Stop",
      byline: "Dr. Christopher G. Fairburn",
      link: "https://www.amazon.com/Overcoming-Binge-Eating-Second-Program/dp/1572305614/",
    },
    {
      title:
        "The Anorexia Workbook: How to Accept Yourself, Heal Your Suffering, and Reclaim Your Life",
      byline: "Michelle Heffner MA and Georg H. Eifert PhD",
      link: "https://www.amazon.com/Anorexia-Workbook-Suffering-Harbinger-Self-Help/dp/1572243627/",
    },
    {
      title:
        "Reclaiming Yourself from Binge Eating: A Step-By-Step Guide to Healing",
      byline: "Leora Fulvio MFT",
      link: "https://www.amazon.com/gp/product/1780996802/",
    },
    {
      title:
        "8 Keys to Recovery from an Eating Disorder: Effective Strategies from Therapeutic Practice and Personal Experience",
      byline: "Carolyn Costin and Gwen S. Grabb",
      link: "https://www.amazon.com/Keys-Recovery-Eating-Disorder-Therapeutic/dp/0393706958/",
    },
    {
      title:
        "Overcoming Your Eating Disorder, Workbook: A Cognitive-Behavioral Therapy Approach for Bulimia Nervosa and Binge-Eating Disorder",
      byline: "Robin F Apple and W. Stewart Agras",
      link: "https://www.amazon.com/Overcoming-Eating-Disorder-Workbook-Cognitive-Behavioral/dp/019531168X/",
    },
  ],
  resources: [
    {
      text: "An overview of anorexia nervosa, bulimia nervosa and binge eating disorder, by the National Institute for Mental Health",
      link: "https://www.nimh.nih.gov/health/topics/eating-disorders/index.shtml",
    },
    {
      text: "Signs, symptoms, and stages of an eating disorder",
      link: "https://www.eatingdisorders.org.au/eating-disorders/what-is-an-eating-disorder",
    },
    {
      text: "Read more about common types of eating disorders and symptoms",
      link: "https://www.nationaleatingdisorders.org/types-symptoms-eating-disorders",
    },
    {
      text: "An example of a food diary you could try",
      link: "https://www.get.gg/docs/FoodDiary.pdf",
    },
    {
      text: "Learn about types of therapy that help treat eating disorders",
      link: "https://www.eatingdisorderhope.com/treatment-for-eating-disorders/types-of-treatments",
    },
  ],
};

const ptsd = {
  title: "Post Traumatic Stress Disorder (PTSD)",
  overview:
    "Some people develop PTSD after exposure to a traumatic event.  This can be by directly experiencing the traumatic event (e.g. physical assault, rape, or natural disaster), seeing it (e.g. crime scenes and accidents), or learning that someone close to you went through it.  People with PTSD experience the trauma again and again in some way, shape or form, which makes them behave differently, in turn, to avoid things which remind them of, or are related to, the traumatic experience.  While these are not unusual responses to traumatic events, in PTSD they go on for a longer period of time and cause more distress and impairments in the person's functioning.",
  sections: [
    {
      title: "How might this affect me?",
      content:
        "After exposure to a traumatic event, you may re-experience the events as intrusive thoughts, memories or images during the day (flashbacks) or have nightmares. In these, you might feel as if you are reliving the events or watching them happen again as an observer. You may find yourself feeling isolated or less interested in normal activities, or struggling to feel positive. Often, people with PTSD avoid situations that trigger their symptoms – such as driving if they were involved in motor vehicle accident. Symptoms of PTSD may leave you struggling to sleep or concentrate; they may also make you startle easily, be hypervigilant, or become easily irritated. Over a period of months, these changes in your feelings and behaviours may begin to have a significant effect on your life, whether at home, socially, or at work.",
    },
    {
      title: "What help is there for me?",
      content:
        "If you are suffering from symptoms of PTSD, it is important to contact your physician. Your physician may recommend specific psychological or medical treatments, but there are also changes you can make on your own which may improve the quality of your life. Performing regular exercise, such as walking, swimming, or jogging, is a good way of relieving tension, and taking a break from difficult feelings and thoughts. You may find mindfulness and stress-relief techniques helpful when experiencing periods of anxiety related to your trauma. You may also find it helpful and less isolating to join a support group where you can speak to other people experiencing similar symptoms.",
    },
    {
      title: "What happens next?",
      content:
        "As a first treatment, your physician will likely recommend you participate in trauma-based psychological therapies such as cognitive behavioural therapy. In this type of therapy, your therapist will help you understand your feelings and thoughts related to the trauma, and help adapt your reactions to improve your symptoms. If they recommend cognitive processing therapy, your therapist would work with you to think about how you remember and think about the causes of your trauma and how you responded to it. They would then help you find ways to change these feelings and beliefs. By helping you to change your feelings about the trauma, your therapist would help you to reclaim the aspects of your life which have been affected by it. If therapies aren't suitable, it is possible to help improve your symptoms with medications, but these don't always have immediate effects. Some of the medications a physician may prescribe are also used to treat depression and anxiety disorders.,You may find that after beginning to take these antidepressant tablets, you experience side effects but no improvements in your symptoms for up to six weeks. Other medications for PTSD can help with insomnia and nightmares. If you experience side effects, contact your physician who may be able to help improve them. It is important to bear in mind that improvements in your experience with PTSD may not happen quickly, and setting small and realistic goals over longer periods of time will help you adjust.",
    },
  ],
  books: [
    {
      title:
        "The PTSD Workbook: Simple, Effective Techniques for Overcoming Traumatic Stress Symptoms",
      byline: "Mary Beth Williams PhD LCSW CTS & Soili Poijula PhD",
      link: "https://www.amazon.com/PTSD-Workbook-Effective-Techniques-Overcoming/dp/1626253706/",
    },
    {
      title:
        "The Body Keeps the Score: Brain, Mind, and Body in the Healing of Trauma",
      byline: "Bessel van der Kolk MD",
      link: "https://www.amazon.com/Body-Keeps-Score-Healing-Trauma-ebook/dp/B00G3L1C2K/",
    },
    {
      title:
        "Getting Past Your Past: Take Control of Your Life with Self-Help Techniques from EMDR Therapy",
      byline: "Francine Shapiro PhD",
      link: "https://www.amazon.com/Getting-Past-Your-Self-Help-Techniques/dp/1609619951/",
    },
  ],
  resources: [
    {
      text: "An overview of PTSD, from the National Institute of Mental Health",
      link: "https://www.nimh.nih.gov/health/topics/post-traumatic-stress-disorder-ptsd/index.shtml",
    },
    {
      text: "Tips for every day living with PTSD, from the MIND charity",
      link: "https://www.mind.org.uk/information-support/tips-for-everyday-living/",
    },
    {
      text: "The basics of PTSD, from the US Department of Veteran Affairs",
      link: "https://www.ptsd.va.gov/public/PTSD-overview/basics/index.asp",
    },
    {
      text: "An introduction to Trauma-Focused Cognitive Behavioral Therapy",
      link: "https://www.goodtherapy.org/learn-about-therapy/types/trauma-focused-cognitive-behavioral-therapy",
    },
    {
      text: "Relaxation techniques to help deal with stress",
      link: "https://www.helpguide.org/articles/stress/relaxation-techniques-for-stress-relief.htm",
    },
  ],
};

const postpartumDepression = {
  title: "Postpartum Depression",
  overview:
    "Postpartum depression is a mental health disorder that can affect women after childbirth. Mothers with postpartum depression often experience feelings of sadness and anxiety. Other commons symptoms are anger, increased irritability, trouble sleeping or waking up, rage, changes in eating behavior, and lack of bond between you and your baby.",
  sections: [],
  books: [
    {
      title: "This Isn't What I Expected: Overcoming Postpartum Depression",
      byline: "Karen R. Kleiman MSW & Valerie D. Raskin MD",
      link: "https://www.amazon.com/This-Isnt-What-Expected-Overcoming/dp/0553370758/",
    },
    {
      title:
        "A Deeper Shade of Blue: A Woman's Guide to Recognizing and Treating Depression in Her Childbearing Years",
      byline: "Ruta Nonacs MD PhD",
      link: "https://www.amazon.com/Deeper-Shade-Blue-Recognizing-Childbearing/dp/0743254759",
    },
    {
      title:
        "Beyond the Blues: A Guide to Understanding And Treating Prenatal And Postpartum Depression",
      byline: "Shoshana S. Bennett PhD & Pec Indman EdD MFT",
      link: "https://www.amazon.com/Beyond-Blues-Understanding-Postpartum-Depression/dp/0971712433/",
    },
  ],
  resources: [
    {
      text: "Postpartum depression facts by the National Institute of Mental Health.",
      link: "https://www.nimh.nih.gov/health/publications/postpartum-depression-facts/index.shtml",
    },
    {
      text: "Postpartum Progress on the symptoms of postpartum depression. It also offers a peer-to-peer support community.",
      link: "https://www.postpartumprogress.com/the-symptoms-of-postpartum-depression-anxiety-in-plain-mama-english",
    },
    {
      text: "Mayo Clinic's definition of postpartum depression",
      link: "https://www.mayoclinic.org/diseases-conditions/postpartum-depression/basics/definition/con-20029130",
    },
  ],
};

export default {
  adhd,
  alcoholism,
  anxiety,
  bipolar,
  depression,
  eatingDisorder,
  ptsd,
  postpartumDepression,
};
