import { gql } from "@apollo/client";

const updateQuestionnaire = gql`
  mutation UpdateQuestionnaire($input: UpdateQuestionnaireInput!) {
    updateQuestionnaire(input: $input) {
      success
      questionnaire {
        id
      }
    }
  }
`;

export default updateQuestionnaire;
