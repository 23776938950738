//
import { createSelector } from "reselect";
import { get, getOr } from "lodash/fp";

const dirtySelector = (state, props) => {
  return getOr(
    false,
    `${props.formKey}.meta.${props.fieldKey}.dirty`,
    state.form,
  );
};

const valueSelector = (state, props) => {
  return getOr("", `${props.formKey}.data.${props.fieldKey}`, state.form) || "";
};

const nullValueSelector = (state, props) => {
  return (
    getOr(null, `${props.formKey}.data.${props.fieldKey}`, state.form) || null
  );
};

const themeSelector = (state, props) => {
  return get(`${props.formKey}.theme`, state.form);
};

const clientValidationSelector = (state, props) => {
  return getOr(
    {},
    `${props.formKey}.validation.client.${props.fieldKey}`,
    state.form,
  );
};

export const memberAddressSelector = (state) =>
  getOr({}, "memberSettings.data", state.form);

export const formFieldSelector = createSelector(
  [dirtySelector, valueSelector, themeSelector, clientValidationSelector],
  (dirty, value, theme, clientValidation) => ({
    dirty,
    value,
    theme,
    clientValidation,
  }),
);

export const nullFormFieldSelector = createSelector(
  [dirtySelector, nullValueSelector, themeSelector, clientValidationSelector],
  (dirty, value, theme, clientValidation) => ({
    dirty,
    value,
    theme,
    clientValidation,
  }),
);

export const memberConsentSelector = (state) =>
  getOr({}, "member-consent", state.form);
