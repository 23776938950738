//

import { useQuery } from "@apollo/client";
import { modalIds, ProviderRole } from "@spring/constants";
import Meowth from "@spring/meowth";
import {
  Col,
  ContentPage,
  FlexRow,
  Grid,
  HugIcon,
  LoadingCircle,
} from "@spring/smeargle";
import {
  addOverlay,
  initializeForm,
  openModal,
  setField,
} from "@spring/smeargle/actions";
import { ProviderFilterFlyout } from "components/flyouts";
import {
  AddressModal,
  BeforeRequestMedicationModal,
  BeforeYouScheduleModal,
  CareProviderDetailModal,
  CareProviderScheduleModal,
  ConfirmInPersonAppointmentModal,
  InPersonAppointmentDetailsModal,
  RequestInPersonModal,
} from "components/modals";
import CoachesHeader from "components/templates/Browse/Listing/CoachesHeader";
import CoachesListing from "components/templates/Browse/Listing/CoachesListing";
import MultiSelectParent from "components/templates/Browse/Listing/MultiSelectParent";
import MemberDashboardPageWrapper from "components/templates/MemberDashboard/PageWrapper";
import { get, getOr } from "lodash/fp";
import { DateTime } from "luxon";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styles from "./styles.module.scss";

import FindProviderCard from "components/templates/Browse/Listing/FindProviderCard";
import NoSponsoredSessions from "components/templates/Browse/ProviderBrowsePage/components/HeaderSection/NoSponsoredSessions";
import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "design-system";
import { VCoachingDuotoneSpot } from "design-system/assets/verdant";
import Router from "next/router";
import {
  getCareProviderTags,
  getCareTeam,
} from "operations/queries/careProvider";
import { getMemberAddress, getMemberInfo } from "operations/queries/member";
import routes from "routes";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import {
  filterTagsByProviderType,
  formatMemberExactAge,
} from "utils/displayHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isMinor } from "utils/memberHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import Snorlax from "utils/snorlax";
import NoProvidersFound from "components/templates/Browse/ProviderBrowsePage/components/BodySection/NoProvidersFound";

const useMemberData = () => {
  const {
    data: memberInfoData,
    loading: memberInfoLoading,
    refetch: memberInfoRefetch,
  } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const { data: memberAddressData, loading: memberAddressLoading } = useQuery(
    getMemberAddress,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  // Pipe the data to avoid repetition
  const memberInfo = memberInfoData && memberInfoData.user;
  const memberAddress = memberAddressData && memberAddressData.user;

  const getCareTeamVars = {
    id: memberInfo && memberInfo?.id,
  };

  const { data: careTeamData } = useQuery(getCareTeam, {
    variables: getCareTeamVars,
    skip: !memberInfo,
  });

  return {
    data: { memberInfo, memberAddress, careTeamData },
    loading: memberInfoLoading || memberAddressLoading,
    refetch: memberInfoRefetch,
  };
};

const TimeZoneSection = (props) => {
  const { t } = useTranslation("coachesProvider");
  const luxonDateTime = DateTime.local().setLocale(props.locale);
  const timeZoneOffsetName = getOr(
    "your local time zone",
    "offsetNameLong",
    luxonDateTime,
  );

  if (props.isAMinor) {
    const memberAge = get("memberInfo.member", props)
      ? formatMemberExactAge(get("memberInfo.member.date_of_birth", props))
      : 6;
    let filterText = t("childTexts.adolescentsBetween");

    if (memberAge < 13) {
      filterText = t("childTexts.childrenBetween");
    }

    return (
      <Grid gap="0 16px" alignment="end" justification="space-between">
        <Col md={6}>
          <div className={styles.childInitialVisitSection}>
            <FlexRow alignment="center" justification="start">
              <span className={styles.hugIcon}>
                <HugIcon />
              </span>
              <span>{t("childTexts.autoFilteringText", { filterText })}</span>
            </FlexRow>
          </div>
        </Col>
        <Col md={6}>
          <div className={styles.withBanner}>
            <span className={styles.timeZoneOffsetName}>
              {t("timeZoneTexts.allTimesIn")} <b>{timeZoneOffsetName}</b>.
            </span>
          </div>
        </Col>
      </Grid>
    );
  }

  return (
    <div>
      <span className={styles.timeZoneOffsetName}>
        {t("timeZoneTexts.allTimesIn")} <b>{timeZoneOffsetName}</b>.
      </span>
    </div>
  );
};

TimeZoneSection.propTypes = {
  isAMinor: PropTypes.bool,
};

const CoachesProviderBrowsePageWrapper = (props) => {
  const { t } = useTranslation("coachesProvider");
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const { data, loading: memberDataLoading } = useMemberData();

  const { data: careProviderTagData, loading: careProviderTagLoading } =
    useQuery(getCareProviderTags, { skip: Meowth.apolloSkipUserId() });

  const filteredProviderTags = filterTagsByProviderType(
    careProviderTagData,
    props.providerType,
  );
  const validTier =
    !props.qualifiesForCoachingViaCN && props.shouldShowCoaching;
  const showLobBanner = useFeatureFlag(FLAGS.LOB_COACHING_PATHWAYS_CTA);
  const loading = memberDataLoading || careProviderTagLoading;
  const isAMinor = isMinor(getOr({}, "memberInfo.member", data)) || props.minor;
  const {
    visits_covered_coaching: coveredCoachingCount,
    visits_remaining_coaching: remainingCoachingCount,
  } = data?.memberInfo?.member ?? {};
  const isGlobalWithNoVisits =
    props.shouldShowCoaching &&
    props.showGlobalExperience &&
    remainingCoachingCount === 0 &&
    coveredCoachingCount >= 0;

  // currently minors should not see coaching
  useEffect(() => {
    if (isAMinor && data?.memberInfo?.member?.date_of_birth) {
      const memberHomeRoute = getHomeRoute(
        data.memberInfo.member.date_of_birth,
      );
      Router.replace(memberHomeRoute.to, memberHomeRoute.as);
    }
  }, [isAMinor, Router.asPath]);

  useEffect(() => {
    if (data.memberInfo) {
      if (!data.memberInfo.member.full_address_available) {
        props.openModal(modalIds.addressModal),
          {
            noAddress: true,
          };
      }
    }
  }, [data.memberInfo]);

  const router = useRouter();
  const shouldFilterByParenting = Object.keys(router.query).includes(
    "Parenting",
  );

  useEffect(() => {
    if (
      props.isFormInitialized &&
      shouldFilterByParenting &&
      careProviderTagData
    ) {
      const careProviderParentingTag =
        careProviderTagData?.care_provider_tags?.data.find(
          (tag) => tag.name === "Parenting",
        ).id || [];
      props.setField(
        "browseProviderTagsTemp",
        "specialtiesTags",
        [careProviderParentingTag],
        true,
      );
      props.initializeForm("browseProviderTags", null, {
        specialtiesTags: [careProviderParentingTag],
      });
    }
  }, [props.isFormInitialized, shouldFilterByParenting, careProviderTagData]);

  useEffect(() => {
    if (showLobBanner) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Coaching Banner viewed",
      );
    }
  }, [showLobBanner]);

  const coachingBanner = (
    <Box
      width="100%"
      border="2px"
      borderRadius="16px"
      borderColor="gray.200"
      background="#E5F2F0"
      mb={[16, 28, 32]}
      padding={24}
    >
      <Stack direction={["column", "column", "row"]}>
        <HStack>
          <Box width={{ base: "80px", sm: "80px", md: "50px" }}>
            <VCoachingDuotoneSpot width={isMobile ? "80px" : "50px"} />
          </Box>
          <VStack align="start" justify="start" spacing={0}>
            <Text
              paddingLeft={0}
              fontSize="16px"
              fontWeight={700}
              color="#1A5856"
              lineHeight="24px"
            >
              {t("banner.title")}
            </Text>
            <Text
              color="#1A5856"
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
              letterSpacing={0}
            >
              {t("banner.subTitle")}
              <Link
                href="https://benefits.springhealth.com/coaching-pathways"
                isExternal
              >
                {t("banner.learnMore")}
              </Link>
            </Text>
          </VStack>
        </HStack>
        <Spacer />
        <Flex alignItems="center" justifyContent={isMobile ? "center" : ""}>
          <Link
            href="https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=18582323684756"
            isExternal
          >
            <Button
              borderRadius="8px"
              borderWidth="2px"
              colorScheme="primary"
              size="md"
              variant="solid"
              onClick={() => {
                TRACK_EVENT.BUTTON_CLICKED(
                  window.location.pathname,
                  "Submit request to enroll",
                );
              }}
            >
              {t("banner.submitButton")}
            </Button>
          </Link>
        </Flex>
      </Stack>
    </Box>
  );

  return (
    <MemberDashboardPageWrapper
      wide
      allowed={props.allowed}
      float={false}
      darkBg={false}
      bordered={false}
      headerBackground="transparent"
      showCheckIn={false}
      shadedBackground
    >
      <div role="main">
        <ContentPage>
          {loading && <LoadingCircle />}
          {!loading && (
            <>
              <div
                role="heading"
                aria-level="1"
                aria-label="Browse Coaches Header"
              >
                <CoachesHeader providerStatus={!validTier} />
              </div>
              {isGlobalWithNoVisits ? (
                <NoSponsoredSessions
                  memberInfoData={data.memberInfo.member}
                  showHeader={false}
                  providerType={props.providerType}
                />
              ) : (
                <>
                  {showLobBanner && coachingBanner}
                  {validTier && (
                    <>
                      <div className={styles.filterContainer}>
                        <MultiSelectParent
                          tagData={filteredProviderTags}
                          memberInfo={data.memberInfo}
                          providerType={props.providerType}
                        />
                      </div>
                      <TimeZoneSection
                        openModal={props.openModal}
                        memberInfo={data.memberInfo}
                        isAMinor={isAMinor}
                        locale={props.locale}
                      />
                    </>
                  )}

                  {validTier && (
                    <CoachesListing
                      providerType={props.providerType}
                      tagData={props.tagData}
                      openModal={openModal}
                      selectedProviderTags={props.tagData}
                      careTeamData={data.careTeamData}
                    />
                  )}

                  {!validTier && (
                    <NoProvidersFound
                      isCoach={props.providerType === ProviderRole.Coach}
                    />
                  )}

                  {validTier && (
                    <FindProviderCard
                      // @ts-ignore
                      providerType="coach"
                      filteredProviderTags={filteredProviderTags}
                      isMinor={isAMinor}
                    />
                  )}
                </>
              )}
            </>
          )}
        </ContentPage>
        <CareProviderDetailModal />
        <CareProviderScheduleModal />
        <BeforeYouScheduleModal />
        <BeforeRequestMedicationModal />
        <AddressModal showSubmit />
        <InPersonAppointmentDetailsModal />
        <ConfirmInPersonAppointmentModal />
        <RequestInPersonModal />
        <ProviderFilterFlyout providerType={props.providerType} />
      </div>
    </MemberDashboardPageWrapper>
  );
};

CoachesProviderBrowsePageWrapper.propTypes = {
  addOverlay: PropTypes.func,
  allowed: PropTypes.bool,
  openModal: PropTypes.func,
  overlays: PropTypes.shape({
    includes: PropTypes.func,
  }),
  providerType: PropTypes.any,
  tagData: PropTypes.any,
  shouldShowCoaching: PropTypes.bool,
  qualifiesForCoachingViaCN: PropTypes.bool,
};

CoachesProviderBrowsePageWrapper.defaultProps = {
  allowed: true,
  shouldShowCoaching: false,
  qualifiesForCoachingViaCN: false,
};

const mapStateToProps = (state) => ({
  // TODO: change this to a formal selector
  tagData:
    state.form.browseProviderTagsTemp && state.form.browseProviderTagsTemp.data,
  showGlobalExperience: state.global.showGlobalExperience,
  locale: state.global.lang,
  isFormInitialized: Object.keys(state.form).length > 0,
  overlays: getOr([], "modal.overlays", state),
  allowed: () =>
    Snorlax(state)
      .loggedIn.to(routes.SignIn)
      .isMember(routes["Logout"])
      .hasCompletedInitialAssessment(routes.MemberExpectations),
});

const mapDispatchToProps = { addOverlay, openModal, setField, initializeForm };

export { CoachesProviderBrowsePageWrapper };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoachesProviderBrowsePageWrapper);
