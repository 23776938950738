import React from "react";
import { Text, Spinner, Flex } from "design-system/components";

export const SessionCountText = ({ sessionSnapshot, t }) => {
  // NOTE: probably should have a loader here
  if (!sessionSnapshot) {
    return (
      <Flex h={48} mb={40} justifyContent="center" alignItems="center">
        <Spinner label="Loading your therapy/ medication management session counts." />
      </Flex>
    );
  }

  const {
    remainingCovered,
    totalVisitsCovered,
    expirationDate,
    specialtyVistsRemaining,
  } = sessionSnapshot;

  const textKey =
    specialtyVistsRemaining > 0
      ? "sessionsCountText"
      : "sessionsCountTextNoMedicationManagementSessions";

  return (
    <Text mb={40} data-testid="session-count">
      {t(textKey, {
        remainingCovered,
        totalVisitsCovered,
        expirationDate,
        specialtyVistsRemaining,
      })}
    </Text>
  );
};
