//

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { Modal, Form, Textarea, Content } from "@spring/smeargle";
import { addNotification, closeModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import Meowth from "@spring/meowth";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { getMemberInfo } from "operations/queries/member";
import { requestCareTeamChange } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";

const ChangeCareProviderModal = (props) => {
  const { t } = useTranslation("careProvider");
  const { data } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const [requestCareTeamChangeMutation, { loading: mutationLoading }] =
    useMutation(requestCareTeamChange, {
      onCompleted: () => {
        props.closeModal(modalIds.changeCareProviderModal);
        props.addNotification(
          t("changeCareProviderModal.notificationMessage.success"),
          "success",
        );
      },
      onError: (err) => props.addNotification(getFirstError(err), "error"),
    });

  return (
    <Modal size="md" id={modalIds.changeCareProviderModal}>
      <Content>{t("changeCareProviderModal.content")}</Content>
      <div className={styles.form}>
        <Form
          formKey="care-provider-request-change"
          theme="simple"
          submitText={t("common:form.submitText")}
          loading={mutationLoading}
          onSubmit={(formData) =>
            requestCareTeamChangeMutation({
              variables: {
                input: {
                  member_id: data.user.member.id,
                  role: props.role,
                  care_provider_id: props.careProviderId,
                  reason: formData.reason,
                },
              },
            })
          }
        >
          <Textarea
            fieldKey="reason"
            label={t("changeCareProviderModal.reason.placeholder")}
            placeholder={t("changeCareProviderModal.reason.placeholder")}
          />
        </Form>
      </div>
    </Modal>
  );
};

ChangeCareProviderModal.propTypes = {
  addNotification: PropTypes.func,
  careProviderId: PropTypes.any,
  closeModal: PropTypes.func,
  role: PropTypes.any,
};

const careProviderSelector = (state) =>
  state.modal[modalIds.changeCareProviderModal] &&
  state.modal[modalIds.changeCareProviderModal].data;

const mapStateToProps = (state) => ({ ...careProviderSelector(state) });

const mapDispatchToProps = { addNotification, closeModal };

export { ChangeCareProviderModal };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeCareProviderModal);
