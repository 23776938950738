import { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "hooks/react-i18next";
import { UpdateAddressForm } from "./UpdateAddressForm";

export const UpdateAddressModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation(["careProvider", "common"]);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const closeModalFocusRef = useRef(null);
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      finalFocusRef={closeModalFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="heading-large" pt={isMobile ? "v-16" : "v-64"}>
            {t("updateAddress.title")}
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <UpdateAddressForm onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
