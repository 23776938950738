import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MomentsCurveSVG from '../Assets/svgs/MomentsCurveSVG';
import CareVisitsCurveSVG from '../Assets/svgs/CareVisitsCurveSVG';
import BrowseProvidersCurveSVG from '../Assets/svgs/BrowseProvidersCurveSVG';
import YourPlanCurveSVG from '../Assets/svgs/YourPlanCurveSVG';
import SubstanceUseSupportSVG from '../Assets/svgs/SubstanceUseSupportSVG';

import styles from './styles.module.scss';


const getBackgroundCurveWrapperClassname = (pageRoute) => {
	switch(pageRoute) {
		case 'SubstanceUseSupport': return styles.backgroundCurveWrapperSUD;
		case 'CareVisits': return styles.careVisitBackgroundCurveWrapper;
		default: return styles.backgroundCurveWrapper;
	}
};

const BackgroundCurve = (props) => (
	<>
		<div
			className={classnames(styles.underlined, {
				[styles.backgroundWrapper]      : props.pageRoute !== 'YourPlan',
				[styles.yPlanBackgroundWrapper] : props.pageRoute === 'YourPlan',
			})}
			style={{ marginTop: props.marginTop }}
		/>
		{props.pageRoute !== 'YourPlan' ?
			(
				<div className={getBackgroundCurveWrapperClassname(props.pageRoute)}>
					{props.pageRoute === 'Moments' && (
						<MomentsCurveSVG className={styles.backgroundCurveWrapper} />
					)}

					{props.pageRoute === 'CareVisits' && (
						<CareVisitsCurveSVG className={styles.backgroundCurveWrapper} />
					)}

					{props.pageRoute === 'BrowseProviders' && (
						<BrowseProvidersCurveSVG className={styles.backgroundCurveWrapper} />
					)}

					{props.pageRoute === 'SubstanceUseSupport' && (
						<SubstanceUseSupportSVG className={styles.backgroundCurveWrapper} />
					)}
				</div>
			) : (
				<div className={styles.yPlanbackgroundCurveWrapper} style={{ marginTop: props.marginTop ? props.marginTop : '20px' }}>
					<YourPlanCurveSVG className={styles.backgroundCurveWrapper} />
				</div>
			)
		}

	</>
);

BackgroundCurve.propTypes = {
	marginTop: PropTypes.string,
};

BackgroundCurve.defaultProps = {
	marginTop: '0px',
};

export default BackgroundCurve;
