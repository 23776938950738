//

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import styles from "components/templates/MemberDashboard/Icons/styles.module.scss";

const Calendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
  >
    <path
      className={classnames(styles.icon, {
        [styles.active]: props.active,
      })}
      fillRule="nonzero"
      d="M5.5 0C4.674 0 4 .674 4 1.5V3H1.5C.65 3 0 3.695 0 4.506v20.973c0 .81.65 1.505 1.5 1.505h24c.85 0 1.5-.695 1.5-1.505V4.506C27 3.696 26.35 3 25.5 3H23V1.5c0-.826-.674-1.5-1.5-1.5S20 .674 20 1.5V3h-5V1.5c0-.826-.674-1.5-1.5-1.5S12 .674 12 1.5V3H7V1.5C7 .674 6.326 0 5.5 0zm0 1c.275 0 .5.225.5.5v4a.501.501 0 0 1-1 0v-4c0-.275.225-.5.5-.5zm8 0c.275 0 .5.225.5.5v4a.501.501 0 0 1-1 0v-4c0-.275.225-.5.5-.5zm8 0c.275 0 .5.225.5.5v4a.501.501 0 0 1-1 0v-4c0-.275.225-.5.5-.5zm-20 2.984H4V5.5C4 6.326 4.674 7 5.5 7S7 6.326 7 5.5V3.984h5V5.5c0 .826.674 1.5 1.5 1.5S15 6.326 15 5.5V3.984h5V5.5c0 .826.674 1.5 1.5 1.5S23 6.326 23 5.5V3.984h2.5c.3 0 .5.23.5.522V8c-.3 0-.5.223-.5.5v1c0 .277.2.5.5.5v15.479c0 .289-.2.521-.5.521h-24c-.3 0-.5-.232-.5-.521V10c.3 0 .5-.223.5-.5v-1c0-.277-.2-.5-.5-.5V4.506c0-.291.2-.522.5-.522zM3.5 8c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zM6 8c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zm2.5 0c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zM11 8c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zm2.5 0c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zM16 8c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zm2.5 0c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zM21 8c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zm2.5 0c-.3 0-.5.223-.5.5v1c0 .275.2.5.5.5s.5-.223.5-.5v-1c0-.277-.2-.5-.5-.5zM13 12c-.826 0-1.5.674-1.5 1.5V15c0 .268.076.518.201.734A1.5 1.5 0 0 0 11 17v2.5c0 .826.674 1.5 1.5 1.5h2c.826 0 1.5-.674 1.5-1.5V17a1.5 1.5 0 0 0-.701-1.266c.125-.216.201-.466.201-.734v-1.5c0-.826-.674-1.5-1.5-1.5h-1zm0 1h1c.275 0 .5.225.5.5V15c0 .275-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5v-1.5c0-.275.225-.5.5-.5zm-.5 3.5h2c.275 0 .5.225.5.5v2.5c0 .275-.225.5-.5.5h-2a.501.501 0 0 1-.5-.5V17c0-.275.225-.5.5-.5zM2.5 23a.501.501 0 0 0 0 1h22a.501.501 0 0 0 0-1h-22z"
    />
  </svg>
);

Calendar.propTypes = {
  active: PropTypes.any,
  replaceMe: PropTypes.string,
};

Calendar.defaultProps = {
  replaceMe: "sailor",
};

export default Calendar;
