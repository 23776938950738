import { ModalSheet } from "@springcare/sh-component-library";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "hooks/react-i18next";
import { useEffect, useState } from "react";
import { TRACK_EVENT } from "utils/mixpanel";

type CouplesTherapyFAQModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
};

export const CouplesTherapyFAQModal = ({
  isModalOpen,
  onModalClose,
}: CouplesTherapyFAQModalProps) => {
  const { t } = useTranslation("careVisits");
  const [trackOnce, setTrackedOnce] = useState(false);

  useEffect(() => {
    if (!trackOnce && isModalOpen) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Couples Sessions Learn More modal",
        {
          location: "Appointment details page",
          spring_doc_id: "couples_therapy_008",
        },
      );
      setTrackedOnce(true);
    }
  }, [trackOnce, isModalOpen]);

  return (
    <ModalSheet
      placement="center"
      isOpen={isModalOpen}
      onClose={onModalClose}
      title={t("appointmentDetails.couplesTherapy.faqModal.title")}
      size="lg"
      footer="off"
    >
      <VStack gap={40}>
        <Box>
          <Text size="body-medium-strong" mb="v-8">
            {t(
              "appointmentDetails.couplesTherapy.faqModal.content.question1.title",
            )}
          </Text>
          <Text>
            {t(
              "appointmentDetails.couplesTherapy.faqModal.content.question1.answer",
            )}
          </Text>
        </Box>

        <Box>
          <Text size="body-medium-strong" mb="v-8">
            {t(
              "appointmentDetails.couplesTherapy.faqModal.content.question2.title",
            )}
          </Text>
          <Text>
            {t(
              "appointmentDetails.couplesTherapy.faqModal.content.question2.answer",
            )}
          </Text>
        </Box>
      </VStack>
    </ModalSheet>
  );
};
