import { gql } from "@apollo/client";

const getCareProvider = gql`
  query getCareProvider($id: ID!) {
    care_provider(id: $id) {
      id
      roles
      name
      first_name
      avatar
      user_id
      top_tags
      associate_status
      dedicated_customer {
        name
      }
    }
  }
`;

export default getCareProvider;
