const HalfEllipse = () => {
  return (
    <>
      <svg
        width="45"
        height="72"
        viewBox="0 0 45 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="36" cy="36" r="36" fill="#5F4B19" fill-opacity="0.1" />
      </svg>
    </>
  );
};

export default HalfEllipse;
