/* eslint-disable react/prop-types */
// @flow
import { Link } from "components/atoms";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { Badge } from "@spring/smeargle";
import routes from "routes";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const LinkItemNav = ({ data, open }) => {
  const { t } = useTranslation("common");
  const isActive = (alias) => {
    const location = window.location.pathname;
    return routes[alias].as === location;
  };

  const MobileLink = (props) => (
    <>
      <span
        className={classnames(styles.icon, {
          [styles.active]: isActive(props.alias),
        })}
      >
        {props.icon}
      </span>
      <span
        className={classnames(styles.link, {
          [styles.active]: isActive(props.alias),
        })}
      >
        {t(props.text)}
      </span>
      <div className={styles.badge}>
        <Badge count={props.count} color="secondary" hideEmpty />
      </div>
    </>
  );

  return (
    <Link
      alias={data.alias}
      ariaLabel={t(data.text)}
      aria-current={isActive(data.alias)}
      mpTracking={{
        pageName: "Top Navigation",
        page: window.location.pathname,
        type: data.trackingText,
        linkName: data.linkName,
        location: open ? "Left Nav Bar Expanded" : "Left Nav Bar",
      }}
      className={classnames(styles.sidebarLink, {
        [styles.active]: isActive(data.alias),
      })}
    >
      <MobileLink key={data.text} {...data} />
    </Link>
  );
};

export default connect((state) => ({
  open: state.memberDashboard?.sidebarOpen,
}))(LinkItemNav);
