//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { get, getOr } from "lodash/fp";
import { connect } from "react-redux";
import { Section, Content } from "@spring/smeargle";
import Meowth from "@spring/meowth";
import { getFirstUpperCase } from "@spring/smeargle/utils";

import PrevisitDisplay from "components/templates/PrevisitDisplay";
import PrevisitForm from "components/templates/PrevisitForm";

import { Link } from "components";
import { getMemberPrevisit, getMemberInfo } from "operations/queries/member";
import envUtils from "utils/environment";
import { normalizeToName } from "utils/global";
import { Heading } from "design-system/components";
import { Trans, withTranslation } from "react-i18next";

class PrevisitPage extends Component {
  static propTypes = {
    countryCode: PropTypes.any,
    data: PropTypes.shape({
      loading: PropTypes.any,
    }),
    t: PropTypes.func,
  };

  state = {
    editing: null,
  };

  componentDidMount() {
    const status = get("data.member.previsit.status", this.props);

    this.setState({
      editing: status !== "Submitted",
    });
  }

  toggleForm = () => {
    this.setState({
      editing: !this.state.editing,
    });
  };

  get displayOrEdit() {
    const response = get("data.member.previsit.data", this.props);
    const id = get("data.member.previsit.id", this.props);
    const date_of_birth = getOr("", "data.member.date_of_birth", this.props);
    const { countryCode } = this.props;

    let data = (response && JSON.parse(response)) || { emergencyContact: {} };
    let country = normalizeToName(countryCode);
    country = countryCode
      ? getFirstUpperCase(country.toLowerCase())
      : "United States";

    // we need to pass the country for the new country selector phone input
    if (
      !data.emergencyContact.phoneNumber ||
      typeof data.emergencyContact.phoneNumber === "string"
    ) {
      data.emergencyContact.phoneNumber = {
        number: data.emergencyContact.phoneNumber || "",
      };
    }
    data.emergencyContact.phoneNumber.country = country;
    data.emergencyContact.phoneNumber.code = countryCode;
    data.dateOfBirth = date_of_birth;

    if (this.state.editing) {
      return (
        <PrevisitForm
          userId={Meowth.getUserId()}
          formData={data}
          toggle={this.toggleForm}
          id={id}
        />
      );
    }

    return <PrevisitDisplay formData={data} id={id} />;
  }

  render() {
    if (this.props.data.loading) {
      return null;
    }

    const { careTeamEmail } = envUtils;

    return (
      <div role="main">
        <Heading my={24} as="h1" fontSize={48} fontWeight={50}>
          {this.props.t("title")}
        </Heading>

        <Section>
          <Content>
            <Trans
              ns={"previsitPage"}
              i18nKey={"subtitle"}
              values={{
                email: careTeamEmail,
              }}
              components={[
                <Link
                  key={0}
                  to={`mailto:${careTeamEmail}`}
                  inlineTextLink={true}
                />,
              ]}
            />
          </Content>
        </Section>

        <Section>
          <Content bold>{this.props.t("securityAssurance")}</Content>
        </Section>

        <Section>{this.displayOrEdit}</Section>
      </div>
    );
  }
}

// ensure that `data` is available to decide which view to render
const Wrapper = ({ data, countryCode, t }) => {
  if (data.loading) {
    return null;
  }

  return <PrevisitPage data={data} countryCode={countryCode} t={t} />;
};

Wrapper.propTypes = {
  countryCode: PropTypes.any,
  data: PropTypes.shape({
    loading: PropTypes.any,
  }),
  t: PropTypes.func,
};

const mapStateToProps = ({ global: { country } }) => ({
  countryCode: country,
});

export default compose(
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(getMemberPrevisit, {
    options: ({ data }) => {
      const id = get("user.member.id", data);
      return { variables: { id } };
    },
    skip: (ownProps) => get("data.user.member.id", ownProps) === undefined,
  }),
  connect(mapStateToProps, null),
  withTranslation("previsitPage"),
)(Wrapper);
