//
import Router from "next/router";
import { get, getOr, union } from "lodash/fp";
import { DateTime } from "luxon";
import Meowth from "@spring/meowth";
import routes from "routes";
import { initApollo } from "utils/apollo/init";
import { isMinor, isT2Member } from "utils/memberHelpers";
import { getCompletedInitialAssessment } from "operations/queries/assessment";
import { getMemberSignupInfo } from "operations/queries/customer";
import { getUserInfo } from "operations/queries/user";
import { datadogAddAction } from "lib/datadog-setup";
import { isParentLogged } from "lib/rememberUser";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import { getMemberInfo } from "operations/queries/member";
import { getCoveredLifeInfo } from "modules/shared/graphql/queries/getCoveredLifeInfo";
import { isEmpty } from "lodash";
class Allow {
  isAllowed;
  pending;
  state;
  queries;
  apollo;
  redirecting;

  constructor(state) {
    this.state = state;
    this.isAllowed = true;
    this.queries = [];
    this.apollo = initApollo();
    this.momentsOnly = false;
  }

  /**
   *
   * Sets the allowed state.  If the route is allowed, we override `isAllowed` with the new
   * state.  If it's NOT allowed, we maintain that state, since any conditional that doesn't
   * allow a user to get to a route is considered final.
   *
   */
  _setState = (allowed) => {
    this.isAllowed = !this.isAllowed ? this.isAllowed : allowed;
  };

  get allowed() {
    return Promise.all(this.queries).then((results) => {
      if (this.pending) {
        return Promise.resolve("pending");
      }

      if (!this.isAllowed) {
        const route = results.reduce((acc, val) => {
          // if the value is a route, and a route hasn't been set
          if (typeof val === "object" && !acc) {
            return val;
          }

          return acc;
        }, null);

        if (route) {
          this.to(route);
        }
        // @TODO: Should there be a default redirect? Perhaps a not allowed page?
      }

      return Promise.resolve(this.isAllowed);
    });
  }

  get loggedIn() {
    const loggedIn = get("auth.isLoggedIn", this?.state);
    this._setState(loggedIn);

    return this;
  }

  get loggedOut() {
    const loggedOut = !get("auth.isLoggedIn", this.state);
    this._setState(loggedOut);

    return this;
  }

  /**
   * Reroutes to the deactivated page if today's date is past the member's last allowed access date.
   */
  isDeactivated(data) {
    const terminateAt = data.user?.member?.access_limit_date;
    const terminateAtDateTime = terminateAt
      ? DateTime.fromISO(terminateAt)
      : null;
    const today = DateTime.local();
    // If today is past member termination date, show deactivated page
    if (
      terminateAtDateTime &&
      terminateAtDateTime.setZone(today.zoneName) < today
    ) {
      this._setState(false);

      datadogAddAction("visit_deactivated_page", {
        customerName: data?.user?.customer?.name || "Unspecified",
      });

      return Promise.resolve(routes.Deactivated);
    }
  }

  /**
   * Reroutes to the deactivated page if the member is logged in, and isDeactivated.
   *
   * The reason for this separate method is to be able to put it into PageBase, which
   * doesn't require that the user is signed in.
   *
   * NOTE: This should be called for Every page the member can access while signed in.
   *   That's why it currently lives in PageBase, to reliably catch all current and future pages.
   */
  isLoggedInAndDeactivated() {
    const loggedIn = get("auth.isLoggedIn", this.state);
    if (loggedIn) {
      this.queries.push(
        this.apollo
          .query({
            query: getUserInfo,
            variables: { id: Meowth.getUserId() },
          })
          .then(({ data }) => {
            const deactivatedPromise = this.isDeactivated(data);
            if (deactivatedPromise) {
              return deactivatedPromise;
            }

            return Promise.resolve(true);
          }),
      );
    }

    return this;
  }

  isMember(redirect) {
    if (!Meowth.getUserId()) {
      this._setState(false);
      return this;
    }
    this.queries.push(
      this.apollo
        .query({
          query: getUserInfo,
          variables: { id: Meowth.getUserId() },
        })
        .then(({ data }) => {
          const isMember = get("user.member.id", data);
          const isMomentsOnlyUser = get("user.roles", data).includes(
            "MOMENTS_ONLY",
          );

          if (!isMember && !isMomentsOnlyUser) {
            this._setState(false);

            if (redirect) {
              return Promise.resolve(redirect);
            }
          }

          return Promise.resolve(true);
        }),
    );

    return this;
  }

  isNestedMinor() {
    if (!Meowth.getUserId()) {
      this._setState(false);
      return this;
    }
    this.queries.push(
      this.apollo
        .query({
          query: getUserInfo,
          variables: { id: Meowth.getUserId() },
        })
        .then(({ data }) => {
          const isAMinor = isMinor(getOr({}, "user.member", data));
          const isMinorLoggedInViaParent = isParentLogged();

          if (
            isAMinor &&
            (Router.asPath === "/members/home" ||
              Router.asPath === "/members/journey" ||
              Router.asPath === "/members/results" ||
              Router.asPath === "/members/assessments/:id" ||
              Router.asPath === "/members/set_goals" ||
              Router.asPath === "/members/invite_dependents" ||
              Router.asPath === "/browse/medication_managers" ||
              Router.asPath === routes.SubstanceUseSupport.as ||
              (Router.asPath === "/members/choose_user" &&
                !isMinorLoggedInViaParent))
          ) {
            this._setState(false);

            if (data?.user?.member?.date_of_birth) {
              const memberHomeRoute = getHomeRoute(
                data.user.member.date_of_birth,
              );
              return Promise.resolve(memberHomeRoute);
            }
          }

          return Promise.resolve(true);
        }),
    );

    return this;
  }

  hasRole(roles, redirect) {
    if (!Meowth.getUserId()) {
      this._setState(false);
      return this;
    }
    this.queries.push(
      this.apollo
        .query({
          query: getUserInfo,
          variables: { id: Meowth.getUserId() },
        })
        .then(({ data }) => {
          const userRoles = get("user.roles", data);

          // User doesn't have ANY roles OR
          // The intersection of the user roles and the allowed roles
          // isn't less than the length of both combined.
          //
          // If they have ONE of the roles, the length of the union
          // would be less than the 2 arrays combined.
          //
          // [1,2] union [2,3] === [1,2,3]
          // It's not the case that [1,2,3].length >= [1,2].length + [2,3].length
          if (
            !userRoles ||
            union(userRoles, roles).length >= roles.length + userRoles.length
          ) {
            this._setState(false);

            if (redirect) {
              return Promise.resolve(redirect);
            }
          }

          return Promise.resolve(true);
        }),
    );

    return this;
  }

  hasCompletedAddress(redirect, options = {}) {
    const user_id = Meowth.getUserId();
    // If no user_id is present, set isAllowed to false, early exit before sending getUserInfo request.
    if (!user_id) {
      this._setState(false);
      return this;
    }

    if (options.skip) {
      return this;
    }

    this.queries.push(
      this.apollo
        .query({
          query: getUserInfo,
          variables: { id: user_id },
        })
        .then(({ data }) => {
          const memberAddress =
            data?.user?.member?.postal_address?.street_address_1;
          // NOTE: country added as there are some members signed up with no country
          const memberCountry = data?.user?.member?.postal_address?.country;
          if (!memberAddress || !memberCountry) {
            this._setState(false);

            if (redirect) {
              return Promise.resolve(redirect);
            }
          }
          return Promise.resolve(true);
        }),
    );
    return this;
  }

  isChipGuardian(redirect, options = {}) {
    if (!Meowth.getUserId()) {
      this._setState(false);
      return this;
    }

    if (options.skip) {
      return this;
    }

    this.queries.push(
      this.apollo
        .query({
          query: getMemberInfo,
          variables: { id: Meowth.getUserId() },
        })
        .then(({ data }) => {
          const coveredLifeId = data?.user?.member?.covered_life?.id;

          if (!coveredLifeId) {
            return Promise.resolve(true);
          }

          return this.apollo.query({
            query: getCoveredLifeInfo,
            variables: { id: coveredLifeId },
          });
        })
        .then(({ data }) => {
          if (isEmpty(data)) {
            return Promise.resolve(true);
          }

          const isChip = data?.get_covered_life_info?.is_chip;
          const isCareEnabled = data?.get_covered_life_info?.is_care_enabled;

          if (isChip && isCareEnabled === false) {
            return Promise.resolve(redirect);
          }
        }),
    );

    return this;
  }

  hasCompletedInitialAssessment(redirect, options = {}) {
    // if  minor, we're good.
    // if pre-assessed experience, we're good.
    // if has completed, we're good.
    // if has not completed...
    //    if in progress -> TakeAssessment(id of in progress)
    //    else           -> MemberExpectations
    if (!Meowth.getUserId()) {
      this._setState(false);
      return this;
    }
    if (options.skip) {
      return this;
    }
    let minor = false;
    let isMomentsOnlyUser = false;
    let isAT2Member = false;
    let hasPreAssessedExperienceEnabled = false;
    this.queries.push(
      this.apollo
        .query({
          query: getUserInfo,
          variables: { id: Meowth.getUserId() },
        })
        .then(({ data }) => {
          minor = isMinor(getOr({}, "user.member", data));
          isMomentsOnlyUser = get("user.roles", data).includes("MOMENTS_ONLY");
          isAT2Member = isT2Member(getOr({}, "user.member", data));
          hasPreAssessedExperienceEnabled = get(
            "user.member.intent_option",
            data,
          );

          return Promise.resolve(true);
        }),
    );
    if (isAT2Member || isMomentsOnlyUser || hasPreAssessedExperienceEnabled) {
      // Bypass init assessment call
      return this;
    }

    this.queries.push(
      this.apollo
        .query({
          query: getCompletedInitialAssessment,
          variables: { skip: !Meowth.getUserId() },
        })
        .then(({ data }) => {
          if (
            !data.assessments.data.length &&
            !isMomentsOnlyUser &&
            !isAT2Member &&
            !hasPreAssessedExperienceEnabled
          ) {
            if (!minor) {
              this._setState(false);
              if (redirect) {
                return Promise.resolve(redirect);
              }
            }
          }
          return Promise.resolve(true);
        }),
    );

    return this;
  }

  hasNotCompletedInitialAssessment(redirect) {
    // if has *not* completed, we're good.
    // if has completed or managed_minor, -> MemberDashboard.
    if (!Meowth.getUserId()) {
      this._setState(false);
      return this;
    }
    let minor = false;

    this.queries.push(
      this.apollo
        .query({
          query: getUserInfo,
          variables: { id: Meowth.getUserId() },
        })
        .then(({ data }) => {
          const isAMinor = isMinor(getOr({}, "user.member", data));
          if (isAMinor) {
            minor = isAMinor;
          }

          return Promise.resolve(true);
        }),
    );
    this.queries.push(
      this.apollo
        .query({
          query: getCompletedInitialAssessment,
          variables: { skip: !Meowth.getUserId() },
        })
        .then(({ data }) => {
          if (data.assessments.data.length || minor) {
            this._setState(false);

            if (redirect) {
              return Promise.resolve(redirect);
            }
          }

          return Promise.resolve(true);
        }),
    );

    return this;
  }

  customerIsLaunched(id, redirect, options = {}) {
    if (options.skip) {
      return this;
    }

    if (!id || id === "null" || id === "undefined") {
      this._setState(false);
      return this;
    }

    this.queries.push(
      this.apollo
        .query({
          query: getMemberSignupInfo,
          variables: { id },
        })
        .then(({ data }) => {
          const customerId = get("customer.id", data);
          const active = get("customer.active", data);
          const active_at = get("customer.active_at", data);
          const inactive_at = get("customer.inactive_at", data);
          const now = Date.now();

          if (!customerId) {
            // eslint-disable-next-line no-console
            console.error("Null customer request with id: ", id);
            return Promise.resolve(routes.SignUpDenied);
          }

          if (!active) {
            this._setState(false);

            // customers who are not renewing and are offboarded
            if (inactive_at && now > Date.parse(inactive_at)) {
              return Promise.resolve(routes.SignUpDenied);
            }

            // customer has not launched yet or nonexisting customer domain
            if (!active_at || (active_at && Date.parse(active_at) > now)) {
              return Promise.resolve(routes.ComingSoon);
            }

            if (redirect) {
              return Promise.resolve(redirect);
            }

            // Just to be safe
            return Promise.resolve(routes.ComingSoon);
          }

          return Promise.resolve(true);
        }),
    );

    return this;
  }

  to(route) {
    if (
      // !this.pending &&              // we're not waiting on the app to initialize
      !this.isAllowed && // the user is not allowed to see that page
      !this.redirecting && // we're not currently redirecting
      // !this.state.app.routing &&    // the app isn't in the middle of routing already
      typeof window !== "undefined" // we have access to the window object (only client can route)
    ) {
      let params = "";

      if (typeof window !== "undefined") {
        params = window.location.search; // maintain the route params when redirecting due to permissions
      }

      this.redirecting = true;
      Router.replace(route.to, `${route.as}${params}`);
    }

    return this;
  }
}

const Snorlax = (state) => {
  return new Allow(state);
};

export default Snorlax;
