import { Box } from "design-system";

export const PersonalizedCare = ({ width, height }) => (
  <Box>
    <svg
      width={width}
      height={height}
      role="img"
      aria-labelledby="personalized-care-illustration"
      aria-hidden="true"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="personalized-care-illustration">Personalized Care</title>
      <path
        d="M40 25L40 45"
        stroke="#11383B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 79L53 79"
        stroke="#068464"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.0711 10.9289C46.1425 10.0003 45.0401 9.26375 43.8268 8.7612C42.6136 8.25866 41.3132 8 40 8C38.6868 8 37.3864 8.25866 36.1732 8.76121C34.9599 9.26375 33.8575 10.0003 32.9289 10.9289L40 18L47.0711 10.9289Z"
        fill="#068464"
      />
      <circle cx="40" cy="2" r="2" fill="#068464" />
      <circle cx="33" cy="5" r="2" fill="#068464" />
      <circle cx="47" cy="5" r="2" fill="#068464" />
      <path
        d="M40 2V15M40 15L33 5M40 15L47 5"
        stroke="#068464"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5346 43.802C31.3061 43.4592 31.5518 43 31.9638 43H48.0362C48.4482 43 48.6939 43.4592 48.4654 43.802L46.039 47.4415C45.3615 48.4577 45 49.6518 45 50.8731C45 53.2388 46.4457 55.3345 48.0745 57.0502C48.6659 57.6731 49.2161 58.377 49.7064 59.1484L44.5345 61.0621C43.6639 61.3843 43.6639 62.6157 44.5345 62.9379L50.7589 65.2411L51.9305 68.4073C51.2829 74.9251 46.1885 80 40 80C33.3726 80 28 74.1797 28 67C28 64.3422 28.7362 61.8707 29.8963 59.8118L30.8393 60.1607L32.0621 63.4655C32.3843 64.3361 33.6157 64.3361 33.9379 63.4655L35.1607 60.1607L38.4655 58.9379C39.3361 58.6157 39.3361 57.3843 38.4655 57.0621L35.1607 55.8393L34.3517 53.6529C34.7544 52.7773 35 51.8481 35 50.8731C35 49.6518 34.6385 48.4577 33.961 47.4415L31.5346 43.802ZM38.0804 69.9196L37 67L35.9196 69.9196L33 71L35.9196 72.0804L37 75L38.0804 72.0804L41 71L38.0804 69.9196Z"
        fill="#068464"
      />
      <path
        d="M63 38L64.0804 40.9196L67 42L64.0804 43.0804L63 46L61.9196 43.0804L59 42L61.9196 40.9196L63 38Z"
        fill="#11383B"
      />
      <path
        d="M21 69L22.3505 72.6495L26 74L22.3505 75.3505L21 79L19.6495 75.3505L16 74L19.6495 72.6495L21 69Z"
        fill="#11383B"
      />
      <circle cx="17" cy="63" r="2" fill="#11383B" />
      <circle cx="62" cy="75" r="2" fill="#11383B" />
      <circle cx="26" cy="44" r="2" fill="#11383B" />
      <circle cx="58.5" cy="47.5" r="1.5" fill="#11383B" />
      <path
        d="M40 25C46.495 25 51 17.9498 51 12.9657C51 12.2964 50.9438 11.6398 50.8357 11C45.6827 11.4755 41.4377 14.952 40 19.5969C38.5624 14.952 34.3173 11.4755 29.1643 11C29.0562 11.6398 29 12.2964 29 12.9657C29 17.9498 33.505 25 40 25Z"
        stroke="#11383B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M63.8491 11L40 40C40 40 49.9868 36.1842 60.1227 30.0789C70.2585 23.9737 67.0786 15.5789 63.8491 11Z"
        stroke="#11383B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1509 11L40 40C40 40 30.0132 36.1842 19.8773 30.0789C9.74149 23.9737 12.9214 15.5789 16.1509 11Z"
        stroke="#11383B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.9379 66.653C37.7926 66.2605 37.4184 66 37 66C36.5816 66 36.2074 66.2605 36.0621 66.653L35.1414 69.1414L32.653 70.0621C32.2605 70.2074 32 70.5816 32 71C32 71.4184 32.2605 71.7926 32.653 71.9379L35.1414 72.8586L36.0621 75.347C36.2074 75.7395 36.5816 76 37 76C37.4184 76 37.7926 75.7395 37.9379 75.347L38.8586 72.8586L41.347 71.9379C41.7395 71.7926 42 71.4184 42 71C42 70.5816 41.7395 70.2074 41.347 70.0621L38.8586 69.1414L37.9379 66.653Z"
        stroke="#11383B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0621 52.5345C32.3843 51.6639 33.6157 51.6639 33.9379 52.5345L35.1607 55.8393L38.4655 57.0621C39.3361 57.3843 39.3361 58.6157 38.4655 58.9379L35.1607 60.1607L33.9379 63.4655C33.6157 64.3361 32.3843 64.3361 32.0621 63.4655L30.8393 60.1607L27.5345 58.9379C26.6639 58.6157 26.6639 57.3843 27.5345 57.0621L30.8393 55.8393L32.0621 52.5345Z"
        stroke="#11383B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.0621 52.5345C53.3843 51.6639 54.6157 51.6639 54.9379 52.5345L57.2411 58.7589L63.4655 61.0621C64.3361 61.3843 64.3361 62.6157 63.4655 62.9379L57.2411 65.2411L54.9379 71.4655C54.6157 72.3361 53.3843 72.3361 53.0621 71.4655L50.7589 65.2411L44.5345 62.9379C43.6639 62.6157 43.6639 61.3843 44.5345 61.0621L50.7589 58.7589L53.0621 52.5345Z"
        stroke="#11383B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);
