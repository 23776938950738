import { gql } from "@apollo/client";

import { goal } from "operations/fragments/goal";

const getMemberGoalProgress = gql`
  query getMemberGoalProgress($id: ID!) {
    member_goal_progress(id: $id) {
      goal {
        ...goal
      }
      data {
        total
        series {
          value
          date
        }
      }
      trend {
        description
        direction
        percentage_change
      }
    }
  }
  ${goal}
`;

export default getMemberGoalProgress;
