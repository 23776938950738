import { gql } from "@apollo/client";

const assessment = gql`
  fragment assessment on Assessment {
    id
    kind
    status
    questionnaires_order
    started_at
    ended_at
    metadata
    triaged
    questionnaires(version: "improved_sud") {
      id
      kind
      information
      responses
      status
    }
  }
`;

export default assessment;
