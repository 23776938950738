//

import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import { find, get } from "lodash/fp";
import { LinkButton } from "@spring/smeargle";
import Billboard from "components/layout/Billboard/Billboard";
import { openModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import Meowth from "@spring/meowth";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { Link } from "components";
import { getMemberTodos } from "operations/queries/member";

const CheckinTodo = (props) => {
  const todos = get("data.user.member.todos", props);
  const todo = find({ kind: "Member/Check-in/Complete" }, todos);
  const { t } = useTranslation("common");

  if (!props.data.loading && todo) {
    const data = JSON.parse(todo.data);
    const assessmentLink = (
      <Link
        ariaLabel={t("checkinTodo.btnTitle")}
        alias="TakeMemberAssessment"
        queries={{ id: data.id }}
        white
      >
        {t("checkinTodo.btnTitle")}
      </Link>
    );

    return (
      <div
        className={styles.checkInContainer}
        id={"checkintodo"}
        data-testid="check-in-todo"
      >
        <Billboard
          open
          color="white"
          title={t("checkinTodo.title")}
          subtitle={
            <LinkButton
              bold={false}
              small
              inheritColor
              text={t("checkinTodo.subTitle")}
              onClick={() => {
                props.openModal(modalIds.checkInDetailsModal, {
                  link: assessmentLink,
                });
              }}
              tabIndex="0"
              role="button"
              onKeyDown={(e) => {
                e.code === "Enter" &&
                  props.openModal(modalIds.checkInDetailsModal, {
                    link: assessmentLink,
                  });
              }}
            ></LinkButton>
          }
          link={assessmentLink}
        />
      </div>
    );
  }

  return null;
};

CheckinTodo.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.any,
  }),
  openModal: PropTypes.func,
};

export { CheckinTodo };

export default compose(
  connect(null, { openModal }),
  graphql(getMemberTodos, {
    options: Meowth.apolloOptionsUserId,
    fetchPolicy: "network-only",
  }),
)(CheckinTodo);
