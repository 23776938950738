import { useDispatch } from "react-redux";
import { openModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";

export const useOpenModal = () => {
  const dispatch = useDispatch();

  const openCareProviderDetailModal = (provider) => {
    dispatch(openModal(modalIds.careProviderDetailModal, { ...provider }));
  };

  const openCareProviderScheduleModal = ({
    provider,
    kind,
    medium,
    providerRole,
    buttonText,
    action,
    initialStartTime,
  }) => {
    dispatch(
      openModal(modalIds.careProviderScheduleModal, {
        ...provider,
        kind,
        medium,
        providerRole,
        buttonText,
        action,
        initialStartTime,
      }),
    );
  };

  return {
    openCareProviderDetailModal,
    openCareProviderScheduleModal,
  };
};
