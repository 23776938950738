import { gql } from "@apollo/client";

const completeStep = gql`
  mutation completeStep($exercise_id: ID!, $current_step_id: ID!) {
    completeStep(exercise_id: $exercise_id, current_step_id: $current_step_id) {
      success
      next_step {
        id
        exercise_id
        title
        position
        final_step
        content_format
        elements {
          type
          content
          media_uri
        }
      }
    }
  }
`;
export default completeStep;
