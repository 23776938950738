//

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import styles from "components/templates/MemberDashboard/Icons/styles.module.scss";

const HeartHand = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path
      className={classnames(styles.icon, {
        [styles.active]: props.active,
      })}
      fillRule="nonzero"
      d="M13.586.004c-1.43 0-2.86.543-3.95 1.633a5.593 5.593 0 0 0 0 7.9l7.552 7.549c.218.219.507.338.814.338.309 0 .6-.12.818-.338l7.543-7.549a5.593 5.593 0 0 0 0-7.9 5.59 5.59 0 0 0-7.9 0L18 2.1l-.463-.463A5.567 5.567 0 0 0 13.586.004zm8.828.998c1.174 0 2.35.447 3.242 1.342a4.588 4.588 0 0 1 0 6.484l-7.545 7.553c-.072.072-.142.072-.216 0l-7.551-7.553a4.588 4.588 0 0 1 0-6.484 4.569 4.569 0 0 1 3.242-1.34c1.176 0 2.35.445 3.242 1.34l.467.465-.11.107a.496.496 0 0 0 0 .707.493.493 0 0 0 .706 0l.109-.11 1.172-1.17a4.568 4.568 0 0 1 3.242-1.341zm-6.049 2.94a.5.5 0 0 0-.353.146l-.828.828a.5.5 0 1 0 .707.707l.828-.828a.5.5 0 0 0-.354-.854zm-8.217 12.72a6.426 6.426 0 0 0-.908.041l-5.916.695C.57 17.488 0 18.128 0 18.891v6.328c0 .467.21.896.578 1.183.365.285.834.385 1.285.272l3.282-.819a3.506 3.506 0 0 1 2.48.3l6.293 3.314a4.49 4.49 0 0 0 2.096.517c.71 0 1.422-.166 2.072-.504l10.65-5.523A2.334 2.334 0 0 0 30 21.877c0-.729-.332-1.402-.908-1.85a2.32 2.32 0 0 0-2.02-.414l-6.705 1.739a2.493 2.493 0 0 0-2.547-1.696l-1.095.078a2.49 2.49 0 0 1-1.246-.232l-4.706-2.22a6.498 6.498 0 0 0-2.625-.62zm-.023 1a5.453 5.453 0 0 1 2.22.524l4.708 2.22c.543.256 1.144.37 1.744.326l1.096-.078c.42-.029.822.112 1.13.399.307.285.477.676.477 1.095v.204a.5.5 0 0 1-.295.457l-1.476.662a4.52 4.52 0 0 1-3.516.074l-2.522-1.01a.5.5 0 1 0-.37.928l2.52 1.012a5.528 5.528 0 0 0 4.3-.092l1.474-.662c.537-.242.885-.78.885-1.37l6.822-1.77a1.334 1.334 0 0 1 1.157.237c.332.256.521.643.521 1.065 0 .502-.277.957-.723 1.187l-10.652 5.524a3.503 3.503 0 0 1-3.242-.01L8.09 25.27a4.492 4.492 0 0 0-3.186-.385l-3.283.82A.499.499 0 0 1 1 25.22V18.89a.5.5 0 0 1 .441-.498l5.916-.696a5.61 5.61 0 0 1 .768-.035zM2.5 19a.501.501 0 0 0 0 1h1a.501.501 0 0 0 0-1h-1zm0 2.5a.501.501 0 0 0 0 1h1a.501.501 0 0 0 0-1h-1zm0 2.5a.501.501 0 0 0 0 1h1a.501.501 0 0 0 0-1h-1z"
    />
  </svg>
);

HeartHand.propTypes = {
  active: PropTypes.any,
};

export default HeartHand;
