// 

import React from 'react';

const MomentsIcon = ({ height, width }) => (
	<svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M18.0668 9.11712C19.2824 8.151 20.0351 6.81248 20.0351 5.33333C20.0351 2.38781 17.0503 0 13.3684 0C9.68655 0 6.70178 2.38781 6.70178 5.33333C6.70178 6.52456 7.18995 7.62456 8.01498 8.5123C5.06783 9.33257 2.99164 11.0792 2.72953 13.3232C2.45214 15.6981 4.2759 18.0378 7.23231 19.5704C2.87961 20.7514 -0.0684006 23.0612 0.00120671 25.655C0.0993856 29.3135 6.16369 32.1515 13.5462 31.9937C20.9287 31.836 26.8339 28.7424 26.7357 25.0839C26.6767 22.8855 24.4635 20.9834 21.1045 19.8496C22.7475 18.913 23.8235 17.5839 24.0074 16.0102C24.3273 13.2707 21.8517 10.5781 18.0668 9.11712ZM17.3684 5.33333C17.3684 6.28156 16.1636 8 13.3684 8C10.5733 8 9.36845 6.28156 9.36845 5.33333C9.36845 4.3851 10.5733 2.66667 13.3684 2.66667C16.1636 2.66667 17.3684 4.3851 17.3684 5.33333ZM19.2482 17.8056C20.8354 17.0838 21.2969 16.2296 21.3587 15.7008C21.4219 15.1599 21.1588 14.1938 19.7684 13.0856C18.4212 12.0118 16.3272 11.1033 13.7969 10.7838C11.2657 10.4641 9.03109 10.8263 7.48872 11.5277C5.90151 12.2496 5.43996 13.1037 5.37819 13.6325C5.31502 14.1734 5.57811 15.1395 6.96851 16.2477C8.31567 17.3215 10.4097 18.23 12.94 18.5496C15.4712 18.8692 17.7058 18.507 19.2482 17.8056ZM21.7087 27.4895C23.8213 26.3827 24.0769 25.413 24.07 25.1554C24.0628 24.8865 23.7408 23.9172 21.5645 22.8987C19.5573 21.9594 16.6177 21.3393 13.2476 21.4113C9.87702 21.4833 6.97732 22.2283 5.02818 23.2495C2.91564 24.3562 2.66 25.3259 2.66691 25.5835C2.67413 25.8525 2.99609 26.8218 5.17237 27.8402C7.17963 28.7795 10.1192 29.3997 13.4893 29.3277C16.8599 29.2557 19.7596 28.5106 21.7087 27.4895Z" fill="#1C6B57"/>
	</svg>
);

MomentsIcon.defaultProps = {
	height : 18,
	width  : 21,
};

export default MomentsIcon;
