import { AppointmentKind } from "@spring/constants";
import { useDispatch } from "react-redux";

import { useAllUpcomingAppointmentSlots } from "hooks";
import { useTranslation } from "hooks/react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { openUpNextSchedulingModal } from "modules/MemberDashboard/Teen/Home/utils/teenHomePage.utils";
import { useTeenHomePage } from "./useTeenHomePage";

export const useTeenUpNextSection = (onScheduleModalOpen) => {
  const { therapist, memberData, coach } = useTeenHomePage();

  const { data: upcomingTherapyAppointmentSlots } =
    useAllUpcomingAppointmentSlots(therapist, AppointmentKind.MinorTherapy, 1);
  const { data: upcomingCoachingAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      coach,
      AppointmentKind.Coaching,
      1,
      false,
      true,
    );

  const dispatch = useDispatch();
  const { t } = useTranslation(["carePlan"]);
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const memberHasInPersonSupport = memberData?.cohort?.in_person_supported;
  const memberCountry = memberData?.postal_address?.country;

  const buttonText = t("carePlanSteps.confirmAppointment");
  const springDocId = "Teen_UpNext_001";

  const openTherapistModal = () =>
    openUpNextSchedulingModal(
      therapist,
      AppointmentKind.MinorTherapy,
      "Therapist",
      shouldShowNewInsuranceModal,
      onScheduleModalOpen,
      buttonText,
      "Up Next - Therapy",
      springDocId,
      upcomingTherapyAppointmentSlots,
      memberHasInPersonSupport,
      memberCountry,
      dispatch,
    );

  const openCoachingModal = () =>
    openUpNextSchedulingModal(
      coach,
      AppointmentKind.Coaching,
      "Coach",
      shouldShowNewInsuranceModal,
      onScheduleModalOpen,
      buttonText,
      "Up Next - Coaching",
      springDocId,
      upcomingCoachingAppointmentSlots,
      memberHasInPersonSupport,
      memberCountry,
      dispatch,
    );

  return { openTherapistModal, openCoachingModal, therapist, coach };
};
