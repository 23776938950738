import { gql } from "@apollo/client";

const getSupportedLanguages = gql`
  query getSupportedLanguages($role: String) {
    supported_languages(role: $role) {
      languages
      __typename
    }
  }
`;

export default getSupportedLanguages;
