"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _fp = require("lodash/fp");
/**
 * Removes the first element from an array and returns the new updated array.
 * The old array is not mutated.
 *
 * @name shift
 * @param {Array} array
 * @returns {Array} Returns a new array without removed value.
 *
 * @example
 * const arr = [1, 2, 3];
 * const newArr =  shift(arr);
 *
 * console.log(arr, newArr); // [1, 2, 3], [2, 3]
 */

function curried(array) {
  return (0, _toConsumableArray2["default"])(array).slice(1);
}
var shift = (0, _fp.curry)(curried);
var _default = exports["default"] = shift;