import { createIcon } from "@chakra-ui/react";

export const Stars = createIcon({
  displayName: "Stars",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97558 1.40947C10.671 -0.469819 13.329 -0.469827 14.0244 1.40947L16.338 7.66195L22.5905 9.97558C24.4698 10.671 24.4698 13.329 22.5905 14.0244L16.338 16.338L14.0244 22.5905C13.329 24.4698 10.671 24.4698 9.97558 22.5905L7.66195 16.338L1.40947 14.0244C-0.469819 13.329 -0.469827 10.671 1.40947 9.97558L7.66195 7.66195L9.97558 1.40947ZM14.4859 8.87649L12 2.15857L9.51415 8.87649C9.40483 9.17191 9.17191 9.40483 8.87649 9.51415L2.15857 12L8.87649 14.4859C9.17191 14.5952 9.40483 14.8281 9.51415 15.1235L12 21.8414L14.4859 15.1235C14.5952 14.8281 14.8281 14.5952 15.1235 14.4859L21.8414 12L15.1235 9.51415C14.8281 9.40483 14.5952 9.17191 14.4859 8.87649Z"
      />
      <path
        fill="currentColor"
        d="M20.6343 0.127849L21.5088 2.49119L23.8721 3.36571L21.5088 4.24022L20.6343 6.60357L19.7598 4.24022L17.3964 3.36571L19.7598 2.49119L20.6343 0.127849Z"
      />
      <path
        fill="currentColor"
        d="M2.82607 22.7929C3.72018 22.7929 4.44499 22.068 4.44499 21.1739C4.44499 20.2798 3.72018 19.555 2.82607 19.555C1.93196 19.555 1.20714 20.2798 1.20714 21.1739C1.20714 22.068 1.93196 22.7929 2.82607 22.7929Z"
      />
    </>
  ),
  defaultProps: {
    "aria-label": "Stars icon",
    role: "img",
  },
});

export default Stars;
