// Returns props that can be added to clickable divs to make them accessible
// <div onClick={clickHandler} {...clickableDivProps({ onClick: clickHandler })}
// Note: This file is also copied in smeargle so any changes made here probably want
// to be replicated over there
export const clickableDivProps = ({ onClick, disabled, pressed, expanded }) => {
  const props = {};

  if (!onClick) return props;

  props.role = "button";
  props.tabIndex = -1;

  if (!disabled) {
    props.tabIndex = 0;
    props.onKeyDown = (e) =>
      (e.code === "Enter" || e.code === "Space") && onClick && onClick(e);
  } else {
    props["aria-disabled"] = true;
  }

  if (expanded === true) {
    props["aria-expanded"] = true;
  }

  if (expanded === false) {
    props["aria-expanded"] = false;
  }

  if (pressed) {
    props["aria-pressed"] = true;
  } else if (pressed === false) {
    props["aria-pressed"] = false;
  }

  return props;
};
