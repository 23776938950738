export const Sapling = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 24C37.1609 24 44.0284 26.1071 49.0919 29.8579C50.0007 30.531 50.838 31.2472 51.6002 32H8.3999C9.16211 31.2472 9.9994 30.531 10.9082 29.8579C15.9716 26.1071 22.8392 24 30 24Z"
      fill="#068464"
    />
    <path
      d="M1 33H59"
      stroke="#17453A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 24L30 10"
      stroke="#17453A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 1L21 1C25.9706 1 30 5.02944 30 10V14H23C18.0294 14 14 9.97056 14 5V1Z"
      stroke="#17453A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 1L39 1C34.0294 1 30 5.02944 30 10V14H37C41.9706 14 46 9.97056 46 5V1Z"
      stroke="#17453A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
