import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  VTherapyDuotoneSpot,
  VTherapyFlatSpot,
} from "@springcare/verdant-icons-react";
import { InformationFlyout, SessionCountText } from "components";
import { useTranslation } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";

type TherapyFlyoutType = {
  isAlsoAvailableTile?: boolean;
  isOpen: boolean;
  onClose: () => void;
  sessionSnapshot: any;
  therapyRecsEnabled?: boolean;
  showPreAssessedExperience?: boolean;
};

const TherapyFlyoutBody = ({
  isAlsoAvailableTile,
}: {
  isAlsoAvailableTile: boolean;
}) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.therapy",
  });

  return (
    <>
      {isAlsoAvailableTile ? (
        ""
      ) : (
        <>
          <Heading size="heading-medium" mb="v-8">
            {t("whyIsThisRecommendedHeader")}
          </Heading>
          <Text mb={24}>{t("whyIsThisRecommendedBody")}</Text>
        </>
      )}
      <Heading size="heading-small" mb="v-8">
        {t("benefits.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>{t("benefits.item1")} </ListItem>
        <ListItem>{t("benefits.item2")} </ListItem>
      </UnorderedList>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpect.header")}
      </Heading>
      <Text mb={24}>{t("whatToExpect.body")}</Text>
      <Heading size="heading-small" mb="v-8">
        {t("commonConcerns.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item1Q")}
          </Text>{" "}
          {t("commonConcerns.item1A")}
        </ListItem>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item2Q")}
          </Text>{" "}
          {t("commonConcerns.item2A")}
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const TherapyFlyout = ({
  isAlsoAvailableTile = false,
  isOpen,
  onClose,
  sessionSnapshot,
  therapyRecsEnabled = false,
  showPreAssessedExperience = false,
}: TherapyFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.therapy",
  });
  const shouldUseBrecsRoute = isBrecsVariant(
    useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT),
  );
  const ctaLink = showPreAssessedExperience
    ? "/members/initial_assessment"
    : shouldUseBrecsRoute
      ? "/browse/therapists"
      : therapyRecsEnabled
        ? "/members/recommendations"
        : "/browse/therapists";
  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VTherapyFlatSpot />}
      heroIllustration={VTherapyDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaLink={ctaLink}
    >
      <>
        <SessionCountText sessionSnapshot={sessionSnapshot} t={t} />
        <TherapyFlyoutBody isAlsoAvailableTile={isAlsoAvailableTile} />
      </>
    </InformationFlyout>
  );
};
