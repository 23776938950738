// 

import React from 'react';

import { Tag } from '../../atoms';


const TagsByKind = ({ careProviderTags, kind, color }) => (
	careProviderTags
		.filter((tag) => tag.kind === kind)
		.map((tag) => <Tag key={tag.id} text={tag.name} color={color} />)
);

export default TagsByKind;
