import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";
import { SELF_ID_ANSWER_CHOICES } from "../constants/selfIDAnswerChoices";
import routes from "routes";

export const getMixpanelEventMetadataForWorkplaceSelfIDQuestion = (
  showWorkplaceManagerSelfIdCardOnBottom,
  answer,
): {
  type: string;
  spring_doc_id: string;
  location: string;
} | null => {
  if (
    !showWorkplaceManagerSelfIdCardOnBottom &&
    answer === SELF_ID_ANSWER_CHOICES.isAManager
  ) {
    return {
      type: "Positive Manager Identification",
      spring_doc_id: "Manager_Experience_002",
      location: "Top of homepage",
    };
  } else if (
    !showWorkplaceManagerSelfIdCardOnBottom &&
    answer === SELF_ID_ANSWER_CHOICES.notAManager
  ) {
    return {
      type: "Negative Manager Identification",
      spring_doc_id: "Manager_Experience_003",
      location: "Top of homepage",
    };
  } else if (
    showWorkplaceManagerSelfIdCardOnBottom &&
    answer === SELF_ID_ANSWER_CHOICES.isAManager
  ) {
    return {
      type: "Positive Manager Identification",
      spring_doc_id: "Manager_Experience_008",
      location: "Below the fold",
    };
  } else if (
    showWorkplaceManagerSelfIdCardOnBottom &&
    answer === SELF_ID_ANSWER_CHOICES.notAManager
  ) {
    return {
      type: "Negative Manager Identification",
      spring_doc_id: "Manager_Experience_009",
      location: "Below the fold",
    };
  } else {
    console.error(
      "Unsupported combination. showWorkplaceManagerSelfIdCardOnBottom:",
      showWorkplaceManagerSelfIdCardOnBottom,
      "answer:",
      answer,
    );
    return null;
  }
};

export const trackMemberViewedWorkplaceManagerSelfIdentificationCard = (
  showWorkplaceManagerSelfIdCardOnBottom,
) => {
  TRACK_EVENT.COMPONENT_VIEWED(
    Router.asPath,
    "Manager Identification Question",
    showWorkplaceManagerSelfIdCardOnBottom
      ? {
          spring_doc_id: "Manager_Experience_007",
          location: "Below the fold",
        }
      : {
          spring_doc_id: "Manager_Experience_001",
          location: "Top of homepage",
        },
  );
};

export const trackMemberAnsweredManagerSelfIdentificationQuestion = (
  showWorkplaceManagerSelfIdCardOnBottom,
  answer,
) => {
  const mixpanelEventMetadata =
    getMixpanelEventMetadataForWorkplaceSelfIDQuestion(
      showWorkplaceManagerSelfIdCardOnBottom,
      answer,
    );

  if (!mixpanelEventMetadata) return;

  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, mixpanelEventMetadata.type, {
    spring_doc_id: mixpanelEventMetadata.spring_doc_id,
    location: mixpanelEventMetadata.location,
  });
};

export const trackMemberViewedWorkplaceManagerExploreCard = (
  showWorkplaceManagerSelfIdCardOnBottom,
) => {
  TRACK_EVENT.COMPONENT_VIEWED(
    Router.asPath,
    "Manager Identification Welcome",
    showWorkplaceManagerSelfIdCardOnBottom
      ? {
          spring_doc_id: "Manager_Experience_010",
          location: "Below the fold",
        }
      : {
          spring_doc_id: "Manager_Experience_005",
          location: "Top of homepage",
        },
  );
};

export const trackMemberClickedExploreButtonOnWorkplaceManagerExploreCard = (
  showWorkplaceManagerSelfIdCardOnBottom,
) => {
  TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    routes.WorkplaceManager.as,
    "Manager Identification Welcome",
    showWorkplaceManagerSelfIdCardOnBottom
      ? {
          spring_doc_id: "Manager_Experience_011",
          location: "Below the fold",
        }
      : {
          spring_doc_id: "Manager_Experience_006",
          location: "Top of homepage",
        },
  );
};

export const trackMemberClickedCloseButtonOnWorkplaceManagerSelfIdentificationCard =
  (showWorkplaceManagerSelfIdCardOnBottom) => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Manager Identification Closed",
      showWorkplaceManagerSelfIdCardOnBottom
        ? {
            spring_doc_id: "Manager_Experience_015",
            location: "Below the fold",
          }
        : {
            spring_doc_id: "Manager_Experience_004",
            location: "Top of homepage",
          },
    );
  };

export const trackMemberClickedCloseButtonOnWorkplaceManagerExploreCard = (
  showWorkplaceManagerSelfIdCardOnBottom,
) => {
  TRACK_EVENT.BUTTON_CLICKED(
    Router.asPath,
    "Manager Identification Closed",
    showWorkplaceManagerSelfIdCardOnBottom
      ? {
          spring_doc_id: "Manager_Experience_012",
          location: "Below the fold",
        }
      : {
          spring_doc_id: "Manager_Experience_016",
          location: "Top of homepage",
        },
  );
};
