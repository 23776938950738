//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons, Textarea } from "@spring/smeargle";
import { formOptions } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "SIB.ideation": numberField(),
};
const prompt2Schema = {
  "SIB.plan": numberField(),
};
const prompt3Schema = {
  "SIB.behavior": numberField(),
};
const prompt4Schema = {
  "SIB.intent": numberField(),
};
const prompt5Schema = {
  "SIB.home": numberField(),
};
const prompt6Schema = {
  "SIB.address": yup.string(),
};

export const SIBSchema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
};

const { YesNoOptions } = formOptions;

const SIB = [
  {
    id: "SIB--1",
    title:
      "Have you thought about suicide or harming yourself (or others) in the past week?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="SIB.ideation"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SIB--2",
    title:
      "In the past week, have you thought about a plan for killing yourself or harming yourself (or others)?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="SIB.plan"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SIB--3",
    title:
      "In the past week, have you taken any steps toward killing yourself or harming yourself (or others)?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="SIB.behavior"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SIB--4",
    title: "Do you intend to commit suicide or harm yourself (or others)?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="SIB.intent"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SIB--5",
    title: "What is your location at this moment?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="SIB.home"
        options={[
          { value: 1, label: "Home" },
          { value: 0, label: "Elsewhere" },
        ]}
      />,
    ],
  },
  {
    id: "SIB--6",
    title: "What is the address of where you are?",
    autoAdvance: false,
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <Textarea key={6} fieldKey="SIB.address" placeholder="Address" />,
    ],
  },
];

export default SIB;
