import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { DateTime } from "luxon";
import {
  Form,
  FlexRow,
  Section,
  Bolded,
  Stout,
  Breakpoint,
  Icon,
  Tag,
} from "@spring/smeargle";
import { setField, openFlyout, initializeForm } from "@spring/smeargle/actions";
import { without, toTagLanguageOptions } from "@spring/immutability";
import {
  flyoutIds,
  ProviderTags,
  CareProviderTagKind,
  languagesExtended,
  browseProviders,
} from "@spring/constants";
import { useTranslation } from "react-i18next";
import routes from "routes";
import { mxTheme } from "design-system";

import styles from "./styles.module.scss";

import { isMinor } from "utils/memberHelpers";
import { FiltersIcon } from "components/atoms";
import { formatMemberExactAge } from "utils/displayHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import { clickableDivProps } from "lib/accessibility_helpers";
import { NONE_SELECTED } from "./constants";

import {
  SESSION_TYPE_TAG_DATA,
  DAY_OF_WEEK_TAG_DATA,
} from "components/flyouts/ProviderFilterFlyout/providerFilterFlyout.constants";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const MultiSelectParent = ({
  fixedTag,
  tagData,
  memberInfo,
  setField,
  openFlyout,
  initializeForm,
  conditionsTags,
  specialtiesTags,
  gendersTags,
  ethnicitiesTags,
  languagesTags,
  providerType,
  sessionTypeTags,
  daysOfWeekTags,
  timeOfDayTags,
  shouldDisableFilters,
}) => {
  const { t } = useTranslation("careProvider");

  const showInPersonMedMan = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );
  const [fixedTabState, setFixedTabState] = useState({
    tagId: "",
    tagLabel: "",
  });
  const shouldShowSessionFilters =
    (providerType === "therapist" &&
      memberInfo?.member?.cohort?.in_person_supported) ||
    (providerType === "medication_manager" &&
      memberInfo?.user?.member?.cohort?.in_person_med_management_supported &&
      showInPersonMedMan);
  const isAMinor = isMinor(getOr({}, "member", memberInfo));
  const browseProviderFormKeys = [
    browseProviders.formKey,
    browseProviders.formKeyTemp,
  ];

  // replaced || 0 shorthand because it can return undefined, and adding undefines together results in NaN
  const selectedSessionTypes =
    sessionTypeTags && sessionTypeTags.length ? sessionTypeTags.length : 0;
  const selectedConditions =
    conditionsTags && conditionsTags.length ? conditionsTags.length : 0;
  const selectedSpecialties =
    specialtiesTags && specialtiesTags.length ? specialtiesTags.length : 0;
  const selectedLanguages =
    languagesTags && languagesTags.length ? languagesTags.length : 0;
  const selectedGenders =
    gendersTags && gendersTags.length ? gendersTags.length : 0;
  const selectedEthnicities =
    ethnicitiesTags && ethnicitiesTags.length ? ethnicitiesTags.length : 0;
  const selectedDaysOfWeek =
    daysOfWeekTags && daysOfWeekTags.length ? daysOfWeekTags.length : 0;
  const selectedTimeOfDay =
    timeOfDayTags && timeOfDayTags.length ? timeOfDayTags.length : 0;

  const selectedOthers =
    selectedLanguages +
    selectedGenders +
    selectedEthnicities +
    selectedSessionTypes +
    selectedDaysOfWeek +
    selectedTimeOfDay;
  const selectedSecondaryTotal = selectedSpecialties + selectedOthers;
  const selectedTotal = selectedConditions + selectedSecondaryTotal;

  // coach filters
  const selectedTotalCoaches =
    selectedSpecialties +
    selectedGenders +
    selectedEthnicities +
    selectedLanguages;
  const selectedSecondaryTotalCoaches =
    selectedSpecialties + selectedEthnicities + selectedLanguages;

  const getCurrentRoute = () =>
    providerType === "coach"
      ? routes.CoachesBrowse.as
      : window.location.pathname;

  useEffect(() => {
    if (isAMinor) {
      const membersAge = formatMemberExactAge(memberInfo.member.date_of_birth);

      if (membersAge >= 13) {
        setTagByLabel(t("minorFixedTag.adolescentsTag"));
      } else if (membersAge < 13) {
        setTagByLabel(t("minorFixedTag.childrenTag"));
      }
    }
  }, [memberInfo.member.date_of_birth, tagData.length]);

  useEffect(() => {
    if (fixedTabState.tagId) {
      browseProviderFormKeys.map((formKey) => {
        setField(
          formKey,
          browseProviders.specialtiesFieldKey,
          [fixedTabState.tagId],
          true, // sets dirty
        );

        setField(
          formKey,
          browseProviders.fixedFieldKey,
          fixedTabState.tagId,
          true, // sets dirty
        );
      });
    }
  }, [fixedTabState.tagId, memberInfo.member.date_of_birth, tagData.length]);

  useEffect(() => {
    if (shouldDisableFilters) {
      // reset filter form in the provider filter flyout
      browseProviderFormKeys.map((formKey) => {
        initializeForm(formKey, null, {
          conditionTags: [],
          specialtiesTags: fixedTag ? [fixedTag] : [],
          languagesTags: [],
          fixedTag: fixedTag,
          gendersTags: [],
          ethnicitiesTags: [],
          daysOfWeekTags: [],
        });
      });
    }
  }, [shouldDisableFilters]);

  const setTagByLabel = (tagLabel) => {
    const tag = tagData.filter((x) => x.name === tagLabel);

    if (Array.isArray(tag) && tag.length) {
      setFixedTabState((prevState) => ({
        ...prevState,
        tagId: tag && tag[0].id,
        tagLabel: tag && tag[0].label,
      }));
    }
  };

  const setMetaData = (kind) => {
    let metadata = {};

    if (kind === CareProviderTagKind.Condition) {
      metadata = {
        fieldKey: browseProviders.conditionsFieldKey,
        tagArray: conditionsTags,
      };
    } else if (kind === CareProviderTagKind.Specialty) {
      metadata = {
        fieldKey: browseProviders.specialtiesFieldKey,
        tagArray: specialtiesTags,
      };
    }
    if (kind === CareProviderTagKind.Gender) {
      metadata = {
        fieldKey: browseProviders.gendersFieldKey,
        tagArray: gendersTags,
      };
    }
    if (kind === CareProviderTagKind.Ethnicity) {
      metadata = {
        fieldKey: browseProviders.ethnicitiesFieldKey,
        tagArray: ethnicitiesTags,
      };
    }
    if (kind === CareProviderTagKind.SessionType) {
      metadata = {
        fieldKey: browseProviders.sessionTypesFieldKey,
        tagArray: sessionTypeTags,
      };
    }
    if (kind === CareProviderTagKind.DaysOfWeek) {
      metadata = {
        fieldKey: browseProviders.daysOfWeekFieldKey,
        tagArray: daysOfWeekTags,
      };
    }
    if (kind === CareProviderTagKind.timeOfDay) {
      metadata = {
        fieldKey: browseProviders.timeOfDayFieldKey,
        tagArray: timeOfDayTags,
      };
    }

    return metadata;
  };

  const displaySelectedTags = () => {
    const tagDataArray = shouldShowSessionFilters
      ? [...tagData, ...SESSION_TYPE_TAG_DATA, ...DAY_OF_WEEK_TAG_DATA]
      : [...tagData, ...DAY_OF_WEEK_TAG_DATA];
    // Combines all tag data (Conditions, Specialties, Languages, Genders, Ethnicities)
    const tags = tagDataArray.map((tag) => ({
      name: tag.name,
      id: tag.id,
      kind: tag.kind,
      metadata: setMetaData(tag.kind),
      isFixed: tag.id === fixedTabState.tagId,
    }));

    const languages = toTagLanguageOptions(languagesExtended, languagesTags);
    const tagDataWithLanguages = [...tags, ...languages];

    // Combines 'selected' tags stored in Redux
    const combinedTags = [
      ...sessionTypeTags,
      ...conditionsTags,
      ...specialtiesTags,
      ...languagesTags,
      ...gendersTags,
      ...ethnicitiesTags,
      ...daysOfWeekTags,
    ]; // ...sessionTypesTags

    // Filters and sorts tags alphabetically
    const displayTags = tagDataWithLanguages
      .filter((item) => combinedTags.includes(item.id))
      .sort((a, b) => a.name.localeCompare(b.name));

    const capitalizeAndLowerCase = (string) => {
      return string.charAt(0) + string.slice(1).toLowerCase();
    };

    return displayTags.map((tag) => {
      // Returns tag without dismiss functionality
      if (tag.isFixed) {
        return <Tag key={tag.id} text={tag.name} />;
      }

      // Returns tag with dismiss functionality
      return (
        <Tag
          key={tag.id}
          text={tag.name}
          icon={
            <Icon
              iconProps={{
                "aria-label": t("multiSelectParent.removeFilter", {
                  name: tag.name,
                }),
              }}
              type="close"
            />
          }
          dataCy={tag.name}
          closeClick={() => {
            TRACK_EVENT.FILTER_CHANGE_APPLIED(
              getCurrentRoute(),
              capitalizeAndLowerCase(tag.kind),
              {
                change: "Removed",
                value: tag.name,
              },
            );

            browseProviderFormKeys.map((formKey) => {
              setField(
                formKey,
                tag.metadata.fieldKey,
                without(tag.id, tag.metadata.tagArray),
                true, // sets dirty
              );
            });
          }}
        />
      );
    });
  };

  const filterButton = (
    label,
    selectedNumber,
    filterType = "",
    isSmallScreen = false,
    buttonLabel = "",
  ) => {
    const disableCursor = shouldDisableFilters ? styles.disabled : "";
    const disableButtonStyles = shouldDisableFilters
      ? mxTheme.semanticTokens.colors["content-disabled"].default
      : "";
    const smallScreenLabel = () => {
      if (selectedNumber > 0) {
        return `${selectedNumber} selected`;
      }
      return label;
    };
    const buttonContent = (isSmallScreen) => {
      if (isSmallScreen) {
        return (
          <Bolded>
            <FlexRow justification="space-between" alignment="center">
              {smallScreenLabel()}
              <span className={styles.filterIcon}>
                <FiltersIcon />
              </span>
            </FlexRow>
          </Bolded>
        );
      }

      return (
        <FlexRow alignment="center">
          <Bolded>{label}</Bolded>
          {selectedNumber > 0 && (
            <div className={styles.selectedNumber}>{selectedNumber}</div>
          )}
          {/* adjust filter to only apply + to more */}
          {label === "More" && (
            <div
              className={styles.filterPlusIcon}
              style={{ color: disableButtonStyles }}
            >
              <Icon type="plus" />
            </div>
          )}
        </FlexRow>
      );
    };

    const onClick = () => {
      if (shouldDisableFilters) return;

      TRACK_EVENT.BUTTON_CLICKED(getCurrentRoute(), label, {
        spring_doc_id: "directsched08",
        to: "Filter Flyout",
      });

      openFlyout(flyoutIds.providerFilterFlyout, {
        filterType: filterType,
        tagData: tagData,
        selectedConditionsTags: conditionsTags,
        selectedSpecialtiesTags: specialtiesTags,
        timestamp: DateTime.local(),
      });
    };

    return (
      <div
        className={`${styles.filterButton} ${disableCursor}`}
        {...clickableDivProps({ onClick: onClick })}
        onClick={onClick}
        aria-label={
          label !== NONE_SELECTED ? label : t("multiSelectParent.filterBtn")
        }
        data-cy={buttonLabel}
        style={{ color: disableButtonStyles }}
      >
        {buttonContent(isSmallScreen)}
      </div>
    );
  };

  const filterButtonSection = (providerType) => {
    const buttonLabelMoreFilters = "moreFilters";
    if (providerType === "coach") {
      return (
        <>
          <Breakpoint xs>
            {filterButton(
              t("multiSelectParent.noneSelected.label"),
              selectedTotalCoaches,
              null,
              true,
            )}
          </Breakpoint>

          <Breakpoint sm>
            {filterButton(
              t("multiSelectParent.noneSelected.label"),
              selectedTotalCoaches,
              null,
              true,
            )}
          </Breakpoint>

          <Breakpoint md>
            <FlexRow>
              {filterButton(
                t("multiSelectParent.specialties.label"),
                selectedSpecialties,
                ProviderTags.Specialties,
              )}
              {filterButton(
                t("multiSelectParent.genders.label"),
                selectedGenders,
                ProviderTags.Genders,
              )}
              {filterButton(
                t("multiSelectParent.moreFilters.label"),
                selectedSecondaryTotalCoaches,
              )}
            </FlexRow>
          </Breakpoint>

          <Breakpoint lg andUp>
            <FlexRow>
              {filterButton(
                t("multiSelectParent.specialties.label"),
                selectedSpecialties,
                ProviderTags.Specialties,
              )}
              {filterButton(
                t("multiSelectParent.genders.label"),
                selectedGenders,
                ProviderTags.Genders,
              )}
              {filterButton(
                t("multiSelectParent.ethnicities.label"),
                selectedEthnicities,
                ProviderTags.Ethnicities,
              )}
              {filterButton(
                t("multiSelectParent.languages.label"),
                selectedLanguages,
                ProviderTags.Languages,
              )}
            </FlexRow>
          </Breakpoint>
        </>
      );
    }

    return (
      <>
        <Breakpoint xs>
          {filterButton(
            t("multiSelectParent.noneSelected.label"),
            selectedTotal,
            null,
            true,
          )}
        </Breakpoint>

        <Breakpoint sm>
          {filterButton(
            t("multiSelectParent.noneSelected.label"),
            selectedTotal,
            null,
            true,
          )}
        </Breakpoint>

        <Breakpoint md>
          <FlexRow>
            {filterButton(
              t("multiSelectParent.conditions.label"),
              selectedConditions,
              ProviderTags.Conditions,
            )}
            {filterButton(
              t("multiSelectParent.moreFilters.label"),
              selectedSecondaryTotal,
            )}
          </FlexRow>
        </Breakpoint>

        <Breakpoint lg andUp>
          <FlexRow>
            {shouldShowSessionFilters &&
              filterButton(
                t("multiSelectParent.mediums.label"),
                selectedSessionTypes,
                ProviderTags.SessionTypes,
              )}
            {filterButton(
              t("multiSelectParent.daysOfWeek.label"),
              selectedDaysOfWeek,
              ProviderTags.DaysOfWeek,
            )}
            {filterButton(
              t("multiSelectParent.conditions.label"),
              selectedConditions,
              ProviderTags.Conditions,
            )}
            {filterButton(
              t("multiSelectParent.specialties.label"),
              selectedSpecialties,
              ProviderTags.Specialties,
            )}
            {filterButton(
              t("multiSelectParent.moreFilters.label"),
              selectedOthers,
              "",
              false,
              buttonLabelMoreFilters,
            )}
          </FlexRow>
        </Breakpoint>
      </>
    );
  };

  return (
    <div className={styles.multiSelectContainer}>
      <Section size="sm">
        <Stout>{t("multiSelectParent.title")}</Stout>
      </Section>

      <Form formKey={browseProviders.formKeyTemp}>
        {filterButtonSection(providerType)}
      </Form>

      <div className={styles.displaySelectedTags}>{displaySelectedTags()}</div>
    </div>
  );
};

MultiSelectParent.propTypes = {
  tagData: PropTypes.array,
  memberInfo: PropTypes.shape({
    member: PropTypes.any,
  }),
  setField: PropTypes.func,
  openFlyout: PropTypes.func,
  conditionsTags: PropTypes.array,
  specialtiesTags: PropTypes.array,
  languagesTags: PropTypes.array,
  gendersTags: PropTypes.array,
  ethnicitiesTags: PropTypes.array,
  daysOfWeekTags: PropTypes.array,
  fixedTag: PropTypes.string,
  providerType: PropTypes.string,
  shouldDisableFilters: PropTypes.bool,
};

export { MultiSelectParent };

const mapStateToProps = (state) => ({
  sessionTypeTags: getOr(
    [],
    "browseProviderTagsTemp.data.sessionTypeTags",
    state.form,
  ),
  daysOfWeekTags: getOr(
    [],
    "browseProviderTagsTemp.data.daysOfWeekTags",
    state.form,
  ),
  conditionsTags: getOr(
    [],
    "browseProviderTagsTemp.data.conditionsTags",
    state.form,
  ),
  specialtiesTags: getOr(
    [],
    "browseProviderTagsTemp.data.specialtiesTags",
    state.form,
  ),
  languagesTags: getOr(
    [],
    "browseProviderTagsTemp.data.languagesTags",
    state.form,
  ),
  gendersTags: getOr([], "browseProviderTagsTemp.data.gendersTags", state.form),
  ethnicitiesTags: getOr(
    [],
    "browseProviderTagsTemp.data.ethnicitiesTags",
    state.form,
  ),
  fixedTag: getOr("", "browseProviderTagsTemp.data.fixedTag", state.form),
});

export default connect(mapStateToProps, {
  setField,
  openFlyout,
  initializeForm,
})(MultiSelectParent);
