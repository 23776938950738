import React from "react";

const LeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clip-path="url(#clip0_508_1568)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 3C0.5 1.34315 1.84315 0 3.5 0H5.5C6.05228 0 6.5 0.447715 6.5 1C6.5 1.55228 6.05228 2 5.5 2H3.5C2.94772 2 2.5 2.44772 2.5 3V12V17.5V21C2.5 21.5523 2.94772 22 3.5 22H7.5H15.5H21.5C22.0523 22 22.5 21.5523 22.5 21V19C22.5 18.4477 22.9477 18 23.5 18C24.0523 18 24.5 18.4477 24.5 19V21C24.5 22.6569 23.1569 24 21.5 24H15.5H7.5H3.5C1.84315 24 0.5 22.6569 0.5 21V17.5V12V3ZM10.5 1C10.5 0.447715 10.9477 0 11.5 0H23.25C23.9404 0 24.5 0.559645 24.5 1.25V13C24.5 13.5523 24.0523 14 23.5 14C22.9477 14 22.5 13.5523 22.5 13V3.41421L12.2071 13.7071C11.8166 14.0976 11.1834 14.0976 10.7929 13.7071C10.4024 13.3166 10.4024 12.6834 10.7929 12.2929L21.0858 2H11.5C10.9477 2 10.5 1.55228 10.5 1Z"
        fill="#F0FFFB"
      />
    </g>
    <defs>
      <clipPath id="clip0_508_1568">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default LeftIcon;
