import { useContext } from "react";
import PropTypes from "prop-types";

import { AssessmentResultsContext } from "components/templates/YourAssessmentResults/YourAssessmentResults";

import Card from "./Card";
import CardWrapper from "./CardWrapper";

const AdditionalResults = ({ type, displayInline = false }) => {
  // deconstruct needed data from context
  const values = useContext(AssessmentResultsContext);
  const additionalCards = values.additionalCards;
  const { styles, t } = values;

  return (
    <CardWrapper
      header={t(`${type}.header`)}
      subheader={t(`${type}.subHeader`)}
      styles={styles}
      displayInline={displayInline}
      height={105}
    >
      {additionalCards.map((card) => (
        <Card data={card} type={type} key={card.label} />
      ))}
    </CardWrapper>
  );
};

AdditionalResults.propTypes = {
  type: PropTypes.string,
};

export default AdditionalResults;
