import { gql } from "@apollo/client";

const getMemberSUDSupported = gql`
  query getMemberSUDSupported($id: ID!) {
    user(id: $id) {
      member {
        cohort {
          contract_term {
            id
            sud_supported
          }
        }
      }
    }
  }
`;

export default getMemberSUDSupported;
