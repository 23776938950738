// 

import React from 'react';

import styles from './styles.module.scss';


const FullWidth = (props) => (
	<div className={styles.fullWidth}>
		{props.children}
	</div>
);

export default FullWidth;
