/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import { TransitionStack } from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";

import { WelcomeForm } from "./Forms";
import Success from "./Success";

import { createPotentialCoveredLife } from "operations/mutations/potentialCoveredLife";
import { getFirstError } from "utils/apollo/errorHandler";

const DependentSignupForm = ({ addNotification }) => {
  const router = useRouter();
  const [page, setPage] = useState(0);
  const [stack, setStack] = useState([]);
  const [create, { loading }] = useMutation(createPotentialCoveredLife, {
    onError: (err) => addNotification(getFirstError(err), "error"),
  });

  const getCustomer = () => {
    if (router && router.query.customer) {
      return router.query.customer;
    }

    let host = window.location.host;
    let parts = host.split(".");
    let subdomain = parts[0];

    if (subdomain !== "springhealth") {
      return subdomain;
    }

    return "Unknown";
  };

  // Adds delay to ensure the next slide is mounted
  const setPageSafely = (page) => {
    return new Promise((resolve) => {
      window.setTimeout(() => {
        setPage(page);

        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        resolve;
      }, 1);
    });
  };

  const back = async (currentStack) => {
    // minus 2 because length isn't 0 indexed, and then we want to go back one
    await setPageSafely(currentStack.length - 2);

    // Wait for animation to resolve then remove the page we left
    window.setTimeout(() => {
      const copy = [...currentStack];
      copy.pop();

      setStack(copy);
    }, 500);
  };

  const createCL = async (
    formData,
    is_minor = false,
    currentStack = [],
    who = "myself",
  ) => {
    const {
      first_name,
      last_name,
      city,
      state,
      country,
      email,
      phone,
      ...metadata
    } = formData;

    const res = await create({
      variables: {
        input: {
          first_name,
          last_name,
          email,
          city,
          state,
          country,
          is_minor,
          customer: getCustomer(),
          metadata: JSON.stringify({
            phone: phone.number,
            ...metadata,
          }),
        },
      },
    });

    if (res) {
      setStack([...currentStack, <Success key="complete" who={who} />]);
      setPageSafely(currentStack.length);
    }
  };

  if (!stack.length) {
    window.setTimeout(() => {
      setStack([
        <WelcomeForm
          key="welcome"
          setPage={setPageSafely}
          setStack={setStack}
          create={createCL}
          loading={loading}
          back={back}
        />,
      ]);
    }, 100);
  }

  return <TransitionStack currentIndex={page} stack={stack} />;
};

DependentSignupForm.propTypes = {
  addNotification: PropTypes.func,
};

export { DependentSignupForm };
export default connect(null, { addNotification })(DependentSignupForm);
