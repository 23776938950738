// 

import React from 'react';
import classnames from 'classnames';


import styles from './styles.module.scss';


const Small = (props) => (
	<span
		className={classnames(styles.small, {
			[styles[props.status]] : props.status,
			[styles.bold]          : props.bold,
			[styles.italic]        : props.italic,
			[styles.underline]     : props.underline,
			[styles.center]        : props.center,
			[styles.noMargin]      : props.noMargin,
			[styles.inheritColor]  : props.inheritColor,
			[styles.inline]        : props.inline,
		})}
	>
		{props.children}
	</span>
);

export default Small;
