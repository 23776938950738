import { createIcon } from "@chakra-ui/react";

export const VideoChat = createIcon({
  displayName: "VideoChat",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.7959 5C5.92991 5 2.7959 8.13401 2.7959 12V15.2829C2.7959 16.9397 4.13904 18.2829 5.7959 18.2829H10.6906C13.4377 18.2829 15.8151 16.7005 16.9612 14.3976L19.8937 15.3751C21.1888 15.8068 22.5262 14.8428 22.5262 13.4777V9.80519C22.5262 8.44007 21.1888 7.47613 19.8937 7.90782L17.6906 8.64218V8C17.6906 6.34315 16.3475 5 14.6906 5H9.7959ZM17.6906 10.7504L17.6906 11.2829C17.6906 11.2841 17.6906 11.2854 17.6906 11.2866V12.5325L20.5262 13.4777V9.80519L17.6906 10.7504ZM15.6906 10.0282V8C15.6906 7.44771 15.2429 7 14.6906 7H9.7959C7.03447 7 4.7959 9.23858 4.7959 12V15.2829C4.7959 15.8352 5.24361 16.2829 5.7959 16.2829H10.6906C13.451 16.2829 15.6889 14.046 15.6906 11.286C15.6906 11.285 15.6906 11.2839 15.6906 11.2829L15.6906 10.0296C15.6906 10.0291 15.6906 10.0287 15.6906 10.0282Z"
    />
  ),
  defaultProps: {
    "aria-label": "Video chat icon",
    role: "img",
  },
});
