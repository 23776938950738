import { gql } from "@apollo/client";

const experienceState = gql`
  fragment experienceState on ExperienceState {
    visited_your_journey
    modal_mobile_app_count
    banner_moments_count
    family_todo_count
    update_settings_shown_count
    should_update_settings
    workplace_page_viewed
    workplace_banner_dismissed
    should_onboard_global_scheduling
  }
`;

export default experienceState;
