import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Bell = ({ componentFrom, count }) => {
  const { t } = useTranslation("a11y");
  const suffix = componentFrom ? `-${componentFrom}` : "";
  const titleId = `bell-icon${suffix}`;

  return (
    <svg
      role="img"
      aria-label={t("icons.notificationCount", { count: count })}
      aria-labelledby={titleId}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <title id={titleId}>{t("icons.bellAlt")}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.01792 4.16566C6.80184 5.2097 5.21133 7.38345 5.01399 9.97553L4.72104 13.8234L3.73067 14.4905C3.11372 14.906 2.67316 15.536 2.49449 16.2581L2.05854 18.02C1.7465 19.2811 2.70084 20.5004 3.99999 20.5004H9.17039C9.58236 21.6653 10.6935 22.4999 11.9995 22.4999C13.3055 22.4999 14.4167 21.6653 14.8286 20.5004H20.0024C21.3016 20.5004 22.2559 19.2811 21.9439 18.02L21.5079 16.2581C21.3293 15.536 20.8887 14.906 20.2717 14.4905L19.281 13.8231L18.988 9.97553C18.7906 7.38222 17.1987 5.20763 14.9809 4.16417C14.814 2.66533 13.5429 1.49989 11.9995 1.49989C10.4556 1.49989 9.18414 2.6661 9.01792 4.16566ZM7.00821 10.1274C7.20695 7.51693 9.38303 5.50023 12.001 5.50023C14.619 5.50023 16.7951 7.51693 16.9938 10.1274L17.2867 13.9749C17.3332 14.5849 17.6562 15.1401 18.1636 15.4819L19.1544 16.1493C19.3601 16.2878 19.5069 16.4978 19.5665 16.7385L20.0024 18.5004H3.99999L4.43595 16.7385C4.4955 16.4978 4.64235 16.2878 4.848 16.1493L5.83838 15.4821C6.34577 15.1404 6.66883 14.5852 6.71527 13.9752L7.00821 10.1274Z"
        fill="#182023"
      />
    </svg>
  );
};

Bell.propTypes = {
  componentFrom: PropTypes.string,
  count: PropTypes.string,
};

export default Bell;
