import { datadogRum } from "@datadog/browser-rum";
import { isT2Member } from "utils/memberHelpers";

function hasAllDatadogEnvs() {
  return (
    process.env.DATADOG_ID &&
    process.env.DATADOG_TOKEN &&
    process.env.DATADOG_ENV
  );
}

const emailRegex = /email=[^&]*/;
const phoneRegex = /phone_number=[^&]*/;
const countryRegex = /country=[^&]*/;

export const isValidJSON = (str) => {
  let result;
  try {
    result = JSON.parse(str);
  } catch (_error) {
    return false;
  }
  return typeof result === "object";
};

export const addOperationNameToEventContext = (event, context) => {
  //graphql RUM resource
  if (
    event?.type === "resource" &&
    event?.resource?.type === "fetch" &&
    event?.resource?.url?.endsWith("/api/graphql")
  ) {
    const requestInit = { ...context?.requestInit };
    const requestBody = requestInit?.body;
    if (isValidJSON(requestBody)) {
      const body = JSON.parse(requestBody);
      // update the event context to include graphql operation name from request body
      if (body?.operationName) {
        event.context = {
          ...event.context,
          operation_name: body.operationName,
        };
      }
    }
  }
};

const sanitizeQuery = (url) =>
  url
    .replace(new RegExp(emailRegex, "g"), "email=REDACTED")
    .replace(new RegExp(phoneRegex, "g"), "phone_number=REDACTED")
    .replace(new RegExp(countryRegex, "g"), "country=REDACTED");

if (hasAllDatadogEnvs()) {
  const DEMO_ENV = "demo-2";
  const isDemoEnv = process.env.DATADOG_ENV === DEMO_ENV;

  datadogRum.init({
    applicationId: process.env.DATADOG_ID,
    clientToken: process.env.DATADOG_TOKEN,
    env: process.env.DATADOG_ENV,
    site: "datadoghq.com",
    service: "member-portal",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.DATADOG_RUM_VERSION,
    sampleRate: 100,
    // we want to enable tracking and session replay ONLY for the demo environment
    trackInteractions: isDemoEnv,
    sessionReplayRecorder: isDemoEnv,
    // Needed to stop session replay (except in demo) for RUM browser SDK < 5.0
    replaySampleRate: isDemoEnv ? 100 : 0,
    allowedTracingOrigins: [
      /https:\/\/.*\.springhealth\.com/,
      /https:\/\/.*\.springhealth\.cloud/,
      /https:\/\/.*\.springtest\.us/,
      /https:\/\/.*\.springhealth\.us/,
      /https:\/\/.*\.on-aptible.\.com/,
    ],
    tracingSampleRate: 100,
    beforeSend: (event, context) => {
      event?.view?.url && (event.view.url = sanitizeQuery(event.view.url));
      event?.view?.referrer &&
        (event.view.referrer = sanitizeQuery(event.view.referrer));
      event?.resource?.url &&
        (event.resource.url = sanitizeQuery(event.resource.url));
      addOperationNameToEventContext(event, context);
    },
  });

  // TODO: Remove after upgrading RUM browser SDK to 5.0+
  if (isDemoEnv) {
    datadogRum.startSessionReplayRecording();
  }
}

export function datadogIdentify(userIdentifiers) {
  const id = userIdentifiers?.user?.id;
  const country = userIdentifiers?.user?.member?.postal_address?.country;
  const preferred_language = userIdentifiers?.user?.preferred_language;
  const is_member_t2 = isT2Member(userIdentifiers?.user?.member);
  if (id) {
    datadogRum.setUser({ id, country, preferred_language, is_member_t2 });
  }
}

export function datadogAddAction(action, context = {}) {
  if (action) {
    datadogRum.addAction(action, context);
  }
}

export function datadogAddError(error) {
  datadogRum.addError(error);
}
