import { gql } from "@apollo/client";

const getCompletedTeenInitialAssessment = gql`
  query getCompletedTeenInitialAssessment {
    assessments(status: ["Finished"], kind: TEEN_INITIAL_ASSESSMENT, limit: 1) {
      data {
        id
        kind
        status
        questionnaires_order
        started_at
        ended_at
        metadata
        triaged
        questionnaires(version: "improved_sud") {
          id
          kind
          information
          responses
          status
          results_sanitized
        }
      }
    }
  }
`;

export default getCompletedTeenInitialAssessment;
