import { useContext } from "react";
import PropTypes from "prop-types";

import { AssessmentResultsContext } from "components/templates/YourAssessmentResults/YourAssessmentResults";

import Card from "./Card";
import CardWrapper from "./CardWrapper";

const Goals = ({ type, displayInline = false }) => {
  const values = useContext(AssessmentResultsContext);
  const goals = values.goals;
  const { pageRefs, styles, t } = values;

  return (
    <>
      {goals && goals.length > 0 && (
        <CardWrapper
          header={t(`${type}.header`)}
          subheader={t(`${type}.subHeader`)}
          styles={styles}
          ref={(el) => (pageRefs.current = { ...pageRefs.current, goals: el })}
          displayInline={displayInline}
        >
          {goals.map((goal, index) => (
            <Card
              data={goal}
              type={type}
              key={goal.id}
              t={t}
              index={index + 1}
            />
          ))}
        </CardWrapper>
      )}
    </>
  );
};

Goals.propTypes = {
  type: PropTypes.string,
};

export default Goals;
