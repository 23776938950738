import { AppointmentAction, modalIds } from "@spring/constants";
import Meowth from "@spring/meowth";
import { getStartSpan, getEndSpan } from "utils/schedulingHelpers";

export const handleBookAgainClick = async (
  data,
  openModal,
  getLazyNextAppointmentSlot,
  getLazyNextSlotVars,
) => {
  const providerRole = data.bookings.find(
    (booking) => booking.role !== "Member",
  ).role;
  const startSpan = getStartSpan(data.kind);
  const endSpan = getEndSpan(startSpan);
  const { data: nextAppointmentSlotData } = await getLazyNextAppointmentSlot({
    variables: {
      user_ids: [Meowth.getUserId(), data.provider?.user_id],
      kind: data.kind,
      medium: data.medium,
      start_span: startSpan,
      end_span: endSpan,
      ...getLazyNextSlotVars,
    },
    skip: !data.provider || !data.kind,
  });
  if (providerRole) {
    const modalData = {
      ...data.provider,
      // NOTE: assumes we're clicking this from care_visits and the
      // Past Appointments section
      mpLocation: "Your Past Appointments",
      kind: data.kind,
      medium: data.medium,
      providerRole: providerRole,
      buttonText: "Confirm Appointment",
      action: AppointmentAction.Create,
      initialStartTime: nextAppointmentSlotData.appointment_slots.available[0],
      dataCy: "schedule-provider-modal",
    };

    openModal(modalIds.careProviderScheduleModal, { ...modalData });
  }
};
