//
import COLORS from '../../../constants/colors';

import React from 'react';
import classnames from 'classnames';
import { InlineIcon } from '@iconify/react';

import { clickableDivProps } from '../../../lib/accessibility_helpers';
import styles from './styles.module.scss';


const Icon = (props) => {
	const iconColor = COLORS[props.color];
	const bgColor = COLORS[props.background];

	let style = { ...(props.style || {}) };

	const iconProps = {
		...(props.iconProps || {}),
		'aria-hidden': props.ariaHidden || false,
	};
	if (props.color && !iconColor) {
		console.error(`${props.color} for "color" is not a valid color.`);
		console.error('Valid colors', Object.keys(COLORS));
	} else {
		style.color = iconColor;
	}

	if (props.background && !bgColor) {
		console.error(`${props.background} for "background" is not a valid color.`);
		console.error('Valid colors', Object.keys(COLORS));
	} else {
		style.backgroundColor = bgColor;
	}

	return (
		<span
			style={{ alignItems: 'center' }}
		>
			<InlineIcon
				data-icon={props.type}
				style={style}
				onClick={props.onClick}
				{...clickableDivProps({ onClick: props.onClick })}
				aria-label={props.type}
				aria-expanded={props.expanded}
				className={classnames(
					styles.default,
					props.className,
					{
						[styles.clickable]  : props.onClick,
						[styles.background] : Boolean(bgColor),
					},
				)}
				icon={`mdi-${props.type}`}
				{...iconProps}
			/>
		</span>
	);
};

Icon.defaultProps = {
	className : '',
	type      : 'cake',
};

export default Icon;
