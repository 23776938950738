// 

import React from 'react';
import classnames from 'classnames';

import Base from '../Base';

import styles from './styles.module.scss';


const SelectButton = (props) => (
	<Base
		className={classnames(styles.default, {
			[styles.selected]: props.selected,
		})}
		onClick={props.onClick}
		text={props.text}
	/>
);

SelectButton.defaultProps = {
	selected: false,
};

export default SelectButton;

