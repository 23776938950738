import { useQuery } from "@apollo/client";
import { getMemberInfo } from "operations/queries/member";
import Meowth from "@spring/meowth";
import { isMinor, isT2Member, isTeen } from "utils/memberHelpers";
import { isGlobalUser } from "utils/global";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useChannelPartnerReseller } from "modules/MemberDashboard/ManagerExperience/Workplace/hooks/useChannelPartnerReseller";

export const useMemberInfoForNavLinks = () => {
  const {
    data: memberData,
    loading: memberLoading,
    error: memberError,
  } = useQuery(getMemberInfo, {
    variables: {
      id: Meowth.getUserId(),
    },
  });

  const memberInfo = memberData?.user?.member;
  const isAMinorMember = isMinor(memberInfo);
  const isATeenMember = isTeen(memberInfo?.date_of_birth);
  const isAnAdultMember = !isAMinorMember;
  const isAGlobalMember = isGlobalUser(memberInfo?.postal_address?.country);
  const isAT2Member = isT2Member(memberInfo);
  const isAT1Member = isAGlobalMember && !isAT2Member;
  const isAWorkplaceManager = memberInfo?.workplace_manager;
  const isADependent = memberInfo?.covered_life?.dependent;
  /** Indicates a Bamboo member with Spring Lite functionality */
  const isPrivatePractice = memberInfo?.is_private_practice;
  const hasViewedWorkplacePage =
    memberInfo?.experience_state?.workplace_page_viewed;
  const memberId = memberInfo?.id;
  const intent = memberInfo?.intent_option;
  const assessment = memberInfo?.most_recent_assessment; // Need this to update the nav links based on the any assessment taken

  // Workplace Manager Experience for pre-assessed experience
  const hasWorkplaceManagerExperience = Boolean(memberInfo?.workplace_manager);

  const { isReseller, channelPartnerLoading } = useChannelPartnerReseller();

  const resellerManagerExperienceFlagEnabled = useFeatureFlag(
    FLAGS.MANAGER_EXPERIENCE_MILESTONE_3,
  );

  const resellerCanViewWorkplaceNavLink =
    isReseller &&
    !channelPartnerLoading &&
    !resellerManagerExperienceFlagEnabled;

  const canSeeWorkplaceLink = useFeatureFlag(
    FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
  );
  const viewWorkplaceLinkFeatureFlag = useFeatureFlag(
    FLAGS.MANAGER_EXPERIENCE_FOR_GLOBAL_MEMBERS,
  );

  return {
    memberInfo,
    memberId,
    isAMinorMember,
    isATeenMember,
    isAnAdultMember,
    isAGlobalMember,
    isAT2Member,
    isAT1Member,
    isAWorkplaceManager,
    isADependent,
    isPrivatePractice,
    hasViewedWorkplacePage,
    canSeeWorkplaceLink,
    viewWorkplaceLinkFeatureFlag,
    memberLoading,
    memberError,
    resellerCanViewWorkplaceNavLink,
    intent,
    assessment,
    hasWorkplaceManagerExperience,
  };
};
