import { createIcon } from "@chakra-ui/react";

export const Warning = createIcon({
  displayName: "Warning",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        d="M13.144 15.5349C13.144 16.1514 12.6318 16.6512 12 16.6512C11.3682 16.6512 10.856 16.1514 10.856 15.5349C10.856 14.9184 11.3682 14.4186 12 14.4186C12.6318 14.4186 13.144 14.9184 13.144 15.5349Z"
        fill="currentColor"
      />
      <path
        d="M12.7627 9.95349C12.7627 9.54249 12.4212 9.2093 12 9.2093C11.5788 9.2093 11.2373 9.54249 11.2373 9.95349V12.9302C11.2373 13.3412 11.5788 13.6744 12 13.6744C12.4212 13.6744 12.7627 13.3412 12.7627 12.9302V9.95349Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35793 5.48837C10.5322 3.50387 13.4678 3.50388 14.6421 5.48837L20.5867 15.5349C21.761 17.5194 20.2932 20 17.9447 20H6.05535C3.70684 20 2.23903 17.5194 3.41328 15.5349L9.35793 5.48837ZM13.321 6.23256C12.7339 5.24031 11.2661 5.24031 10.679 6.23256L4.73431 16.2791C4.14719 17.2713 4.8811 18.5116 6.05535 18.5116H17.9447C19.1189 18.5116 19.8528 17.2713 19.2657 16.2791L13.321 6.23256Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    "aria-label": "Warning icon",
    role: "img",
  },
});
