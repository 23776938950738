import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextTransition, { presets } from 'react-text-transition';

class Slider extends Component {

	state = {
		index: 0,
	}

	intervalIds = [];

	componentDidMount() {
		const intervalId = setInterval(() => {
			this.setState({
				index: (this.state.index + 1) % this.props.texts.length,
			});
		},5000);

		this.intervalIds.push(intervalId);
	}

	componentWillUnmount() {
		this.intervalIds.map(clearInterval);
		this.intervalIds = [];
	}

	render() {
		return (
			<TextTransition
				text={ this.props.texts[this.state.index] }
				inline={ this.props.inline }
				direction="down"
				springConfig={presets.wobbly}
				noOverflow
			/>
		);
	}
}

Slider.propTypes = {
	texts  : PropTypes.arrayOf(PropTypes.string),
	inline : PropTypes.bool,
};

Slider.defaultProps = {
	texts  : [''],
	inline : false,
};

export default Slider;
