import { useQuery } from "@apollo/client";
import getMemberGoalProgress from "operations/queries/member/getMemberGoalProgress";
import { datadogAddAction } from "lib/datadog-setup";

export const useMemberGoalProgress = (trackedGoalID) => {
  const { data, loading, error } = useQuery(getMemberGoalProgress, {
    variables: { id: trackedGoalID },
    skip: !trackedGoalID,
    onError: (err) => {
      datadogAddAction("getMemberGoalProgress_error", {
        error: err,
      });
    },
  });

  return {
    goalProgress: data,
    goalProgressLoading: loading,
    goalProgressError: error,
  };
};
