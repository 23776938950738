// 

import { flow } from 'lodash/fp';
import { setIn } from '@spring/immutability';

import { OPEN_FLYOUT, CLOSE_FLYOUT } from '../../actions/flyout/types';


const flyout = (state = {}, action) => {
	
	switch (action.type) {
		case OPEN_FLYOUT:
			return flow(
				setIn(`${action.id}.open`, true),
				setIn(`${action.id}.data`, action.data),
			)(state);

		case CLOSE_FLYOUT:
			return setIn(`${action.id}.open`, false, state);

		default:
			return state;
	}
};

export default flyout;
