import React from "react";
import { Button, ButtonProps } from "@springcare/sh-component-library";
import { Box, chakra, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "hooks/react-i18next";
import { useRouter } from "next/router";
import routes from "routes/MemberDashboardRoutes";

export const Footer = ({
  shouldRenderFooter = false,
}: {
  shouldRenderFooter?: boolean;
}) => {
  const [isSmallScreen] = useMediaQuery("(max-width: 540px)");
  const router = useRouter();
  const { t } = useTranslation("assessmentResults");

  if (!shouldRenderFooter) return null;

  const onClickGoToRoute = (route: { to: string; as: string }) => {
    router.push(route.to, route.as);
  };

  return (
    <StyledBox bottom={isSmallScreen ? "3rem" : 0}>
      <StyledButton onClick={() => onClickGoToRoute(routes.MemberMoments)}>
        {t("detailFlyout.footer.viewMoments")}
      </StyledButton>
      <StyledButton
        variant="low-emphasis"
        onClick={() => onClickGoToRoute(routes.MemberHome)}
      >
        {t("detailFlyout.footer.seeCarePlan")}
      </StyledButton>
    </StyledBox>
  );
};

export const FOOTER_HEIGHT = "152px";

const StyledBox = chakra(Box, {
  baseStyle: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    height: FOOTER_HEIGHT,
    width: "100%",
    boxShadow: "high",
    padding: "v-24",
    gap: "v-8",
    backgroundColor: "media-base",
  },
});

const StyledButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <Button
    width="100%"
    sx={{
      ["span"]: {
        background: "none !important",
      },
    }}
    {...props}
  >
    {children}
  </Button>
);
