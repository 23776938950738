import { useState, useEffect } from "react";

// Only fires off one useState update when the component is in the viewport
export const useInViewportOnce = (elementRef, rootMargin) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let observerRefValue = null;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(entry.isIntersecting);
        }
      },
      { rootMargin },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
      observerRefValue = elementRef.current;
    }

    elementRef.current && observer.observe(elementRef.current);

    return () => observerRefValue && observer.unobserve(observerRefValue);
  }, [elementRef, rootMargin]);

  return isVisible;
};

export const useInViewport = (elementRef, rootMargin) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let observerRefValue = null;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
      observerRefValue = elementRef.current;
    }

    elementRef.current && observer.observe(elementRef.current);

    return () => observerRefValue && observer.unobserve(observerRefValue);
  }, [elementRef, rootMargin]);

  return isVisible;
};
