import React from "react";
import { Box, Flex, Text, IconButton } from "design-system/components";
import { LeftArrow, RightArrow } from "design-system/assets";
import { EVENT_TYPE, makeEventString, track } from "utils/mixpanel";

import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import {
  trackPaginationNextButton,
  trackPaginationBackButton,
} from "components/templates/Browse/ProviderBrowsePage/analytics";

type Props = {
  currentPage: number;
  limit: number;
  loading: boolean;
  refetch: (object) => void;
  totalPages: number;
  totalProviderCount: number;
  payload: any;
};

const Pagination = (props: Props) => {
  const { t } = useTranslation("a11y");
  const { isMobile } = useProviderBrowseContext();
  const currentPage = props.currentPage;
  const totalPages = props.totalPages;
  const payload = props.payload;

  const nextOnClick = () => {
    window.scrollTo(0, 0);
    track("Schedule Therapy Visit -- Next (More Results)", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: "Appointment Selected",
      }),
    });

    // Mixpanel Event
    trackPaginationNextButton({
      currentPage: props.currentPage,
      destinationPage: props.currentPage + 1,
      totalPages: props.totalPages,
    });

    props.refetch({
      variables: {
        ...payload,
        offset: props.currentPage * props.limit,
      },
    });
  };

  const previousOnClick = () => {
    window.scrollTo(0, 0);
    track("Schedule Therapy Visit -- Back (More Results)", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: "Appointment Selected",
      }),
    });

    // Mixpanel Event
    trackPaginationBackButton({
      currentPage: props.currentPage,
      destinationPage: props.currentPage - 1,
      totalPages: props.totalPages,
    });

    props.refetch({
      variables: {
        ...payload,
        offset: (props.currentPage - 2) * props.limit,
      },
    });
  };

  const leftArrowDisabled = props.loading || props.currentPage === 1;
  const rightArrowDisabled =
    props.loading || props.currentPage === props.totalPages;

  return (
    <Box mt={isMobile ? 35 : 75} mb={isMobile ? 40 : 100}>
      <Flex direction="row" justifyContent="space-evenly">
        <IconButton
          m="0"
          p="0"
          h="30px"
          w="30px"
          border={0}
          borderRadius="50%"
          variant="outline"
          bg="primary.base"
          _focus={{
            border: "2px solid",
            borderColor: "primary.base",
          }}
          _hover={{ color: leftArrowDisabled ? "white" : "platform.base" }}
          _disabled={{
            background: "rgba(1, 20, 25, 0.37)",
            cursor: "not-allowed",
          }}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          color="white"
          aria-label={t("previousPage")}
          // @ts-ignore
          icon={<LeftArrow boxSize={24} />}
          data-cy="prev-page-button"
          disabled={leftArrowDisabled}
          onClick={debounce(previousOnClick, 500, {
            maxWait: 500,
            leading: true,
            trailing: false,
          })}
        />

        <Text mb={8}>
          {t("medicationManagers.pagination", {
            ns: "common",
            currentPage: currentPage,
            totalPages: totalPages,
          })}
        </Text>

        <IconButton
          m="0"
          p="0"
          h="30px"
          w="30px"
          border={0}
          borderRadius="50%"
          variant="outline"
          bg="primary.base"
          _focus={{
            border: "2px solid",
            borderColor: "primary.base",
          }}
          _hover={{ color: rightArrowDisabled ? "white" : "platform.base" }}
          _disabled={{
            background: "rgba(1, 20, 25, 0.37)",
            cursor: "not-allowed",
          }}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          color="white"
          aria-label={t("nextPage")}
          // @ts-ignore
          icon={<RightArrow boxSize={24} />}
          data-cy="next-page-button"
          disabled={rightArrowDisabled}
          onClick={debounce(nextOnClick, 500, {
            maxWait: 500,
            leading: true,
            trailing: false,
          })}
        />
      </Flex>
    </Box>
  );
};

export default Pagination;
