import { gql } from "@apollo/client";

import paging from "operations/fragments/paging";

const getAppointments = gql`
  query getAppointments(
    $status: String
    $kind: [String]
    $paid: String
    $booking_name: String
    $booking_user_id: ID
    $care_provider_id: ID
    $member_id: ID
    $exclude_attended: [AppointmentAttendedEnumType!]
    $exclude_status: String
    $starting_after_timestamp: DateTime
    $starting_after: Date
    $starting_before_timestamp: DateTime
    $starting_before: Date
    $limit: Int = 30
    $offset: Int
    $sort_by: [SortType] = [
      { column: "appointments.start_at", direction: "desc" }
    ]
    $member_has_customer: ID
    $attended: AppointmentAttendedEnumType
  ) {
    appointments(
      status: $status
      kind: $kind
      paid: $paid
      booking_name: $booking_name
      booking_user_id: $booking_user_id
      care_provider_id: $care_provider_id
      member_id: $member_id
      exclude_attended: $exclude_attended
      exclude_status: $exclude_status
      starting_after_timestamp: $starting_after_timestamp
      starting_after: $starting_after
      starting_before_timestamp: $starting_before_timestamp
      starting_before: $starting_before
      limit: $limit
      offset: $offset
      sort_by: $sort_by
      member_has_customer: $member_has_customer
      attended: $attended
    ) {
      data {
        id
        duration
        start_at
        end_at
        kind
        paid
        medium
        status
        location
        cancelled_for_member
        cancelled_at
        confirmed_at
        attended
        cohort_name
        provider_id
        bookings {
          id
          name
          role
          avatar
        }
        provider {
          id
          name
          avatar
        }
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default getAppointments;
