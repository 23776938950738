/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React from "react";
import { Headline } from "@spring/smeargle";
import { useTranslation } from "react-i18next";

import { SpringLogoIcon } from "design-system/index";
import { AuthWrapper } from "components/layout";

import styles from "./styles.module.scss";

const Slide = ({ children, empty = false }) => {
  const { t } = useTranslation("limitedLangAuth");
  return (
    <div className={styles.slide}>
      <AuthWrapper>
        {!empty && (
          <>
            <div
              style={{ width: 36, marginBlock: "0 16px", marginInline: "auto" }}
            >
              <SpringLogoIcon />
            </div>
            <Headline center>
              {t("forms.dependentSignupForm.headline")}
            </Headline>
          </>
        )}
        {children}
      </AuthWrapper>
    </div>
  );
};

Slide.propTypes = {
  children: PropTypes.any,
  empty: PropTypes.bool,
  showEmergency: PropTypes.bool,
};

export default Slide;
