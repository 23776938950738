const IssuesData = {
  anger: 0,
  drugs: 0,
  focus: 0,
  grief: 0,
  panic: 0,
  sleep: 0,
  family: 0,
  people: 0,
  stress: 0,
  breakup: 0,
  sadness: 0,
  worrying: 0,
  body_image: 0,
  distraction: 0,
  self_esteem: 0,
  concentration: 0,
  postpartum: 0,
  relationships: 0,
  domestic_violence: 0,
};

const assessmentKinds = {
  PROGRESS_UPDATE: "PROGRESS_UPDATE",
  INITIAL_ASSESSMENT: "INITIAL_ASSESSMENT",
  SPRASS: "SPRASS",
  TEEN_INITIAL_ASSESSMENT: "TEEN_INITIAL_ASSESSMENT",
};

const builtInPromptIds = {
  CONFIDENCE: "confidence_prompt",
  COMPLETE: "complete",
  GOALS: "goals",
  SPRASSCOMPLETE: "SPRASS--complete",
};

const elementKinds = {
  RADIO: "RADIO",
  NUMBER: "NUMBER",
  CHECKBOX: "CHECKBOX",
  APPETITE: "APPETITE",
  COMPLETE: "COMPLETE",
  CONFIDENCE: "CONFIDENCE",
  GOALS: "GOALS",
  TEXTAREA: "TEXTAREA",
  RANGE: "RANGE",
  SELECT: "SELECT",
  TEXT: "TEXT",
};

const assessmentStatuses = {
  STARTED: "Started",
  FINISHED: "Finished",
  REQUESTED: "Requested",
};

module.exports = {
  IssuesData,
  assessmentKinds,
  builtInPromptIds,
  elementKinds,
  assessmentStatuses,
};
