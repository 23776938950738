const SpringCDN =
  process.env.APP_ENV === "production"
    ? "https://spring-cdn.springhealth.com"
    : "https://spring-cdn.springtest.us";

const InformedConsent =
  SpringCDN +
  "/pdfs/accessible/Spring_Health_Informed_Consent_for_Services_USA_7_7_31.pdf";
const InformedConsentGlobal =
  SpringCDN +
  "/pdfs/accessible/Spring_Health_Informed_Consent_for_Services_INTL_2_7_23.pdf";
const MinorInformedConsent = "https://springhealth.com/informed-minor-consent/";
const Coppa = "https://springhealth.com/coppa-notice/";
const InternalInformedConsent =
  SpringCDN + "/pdfs/Internal+Informed+Consent+Spring+Medical-22MAR2021.pdf";
const PrivacyNotice = "https://springhealth.com/hipaa-joint-privacy-notice/";
const AppStoreUrl =
  "https://apps.apple.com/us/app/spring-health-mobile/id1464703978?ls=1";
const GooglePlayUrl =
  "https://play.google.com/store/apps/details?id=com.springhealth.cherubi";
const shlabsUrl =
  "https://springhealth.atlassian.net/wiki/spaces/PROD/blog/2020/10/15/19857763/Announcing+Spring+Heath+Labs";
const caregiverTypeDetailsInfoUrl =
  "https://springhealth.atlassian.net/wiki/spaces/CN/pages/289177605/Caregiver+Type+-+Explained";
const TermsOfService = "https://springhealth.com/terms-of-service/";
const PaymentPolicyPDF =
  "https://spring-cdn.s3.amazonaws.com/pdfs/sh-payment-policy.pdf";
const PrivacyPolicy = "https://springhealth.com/privacy-policy/";
const ElectronicCommunicationAgreement =
  SpringCDN +
  "/pdfs/accessible/Spring_Health_Electronic_Communication_Agreement.pdf";
const HealthInformationConsent =
  SpringCDN +
  "/pdfs/accessible/Spring_Health_Health_Information_Collection_Consent.pdf";
const SMSTerms =
  SpringCDN + "/pdfs/accessible/Spring_Health_SMS_Terms_September2024.pdf";
const GoogleAccount = "https://www.google.com/gmail/";
const iCloudAccount = "https://www.icloud.com/mail";
const OutlookAccount = "https://outlook.live.com/owa/";
const Datadog = "https://app.datadoghq.com/";
const Iterable = "https://app.iterable.com/";
const Support = "https://springhealth.com/support";
const CareSupportCenterProductionLink =
  "https://careteam.springhealth.com/hc/en-us";
const CareSupportCenterLowerEnvLink =
  "https://spring1678223177.zendesk.com/hc/en-us";
const ZendeskAvatarUrl =
  "https://res.cloudinary.com/spring-health/image/upload/v1686852668/Spring_Health-symbol-winter-green_k8yldp.png";
const AccountTransitionFAQlink17To18 =
  "https://careteam.springhealth.com/hc/en-us/articles/20959442825620-How-do-Spring-Health-accounts-work-when-children-turn-18";
const AccountTransitionFAQlink5To6 =
  "https://careteam.springhealth.com/hc/en-us/articles/25337038130196-Navigating-Account-Upgrades-From-Ages-5-to-6";
const AccountTransitionFAQlink12To13 =
  "https://careteam.springhealth.com/hc/en-us/articles/25337500098964-Navigating-Account-Upgrades-From-Ages-12-to-13";
const TargetSageContentURL =
  "https://targetonline.sharepoint.com/sites/Learn/SitePages/Wellbeing.aspx";
const CancellationPolicy =
  "https://careteam.springhealth.com/hc/en-us/articles/15588030378516-How-can-I-reschedule-or-cancel-an-appointment";
const WorkplaceManagementConsult =
  "https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=15495106468116&tf_16369359392532=eap_management_consult";
const WorkplaceCriticalIncidentResponse =
  "https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=15495106468116&tf_16369359392532=eap_critical_incident_response_request";
const RecommendationsSurveyURL =
  "https://cx.retently.com/al/634480e3c00a903ead2dafa2";
const WorkplaceSurveyURL =
  "https://cx.retently.com/al/667f213978638d44551e3dd7";
const WebinarsURL = "https://webinars.springhealth.com/";
const UpcomingWebinarsURL = "https://webinars.springhealth.com/upcoming";
const GlobalWellspringsURL = "https://globalevents.springhealth.com/";
const WellspringsURL = "https://wellsprings.springhealth.com/";
const UpcomingWellspringsURL = "https://wellsprings.springhealth.com/upcoming-sessions";
const CostEstimateSupportLink =
  "https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=14448838263956&tf_subject=Cost Estimate Request&tf_14922672270484=mef_billing&tf_14922700433812=mef_billing_cost_estimate";
const CompassURL = "https://compass.springhealth.com/";
const CompassStagingURL = "https://compass.staging.springtest.us/"
const SpeakerTestURL = SpringCDN + "/assets/media/speaker_test.mp3"
const JoinSessionSoundURL = SpringCDN + "/assets/media/sound_join_session.mp3";
const LeaveSessionSoundURL =
  SpringCDN + "/assets/media/sound_leave_session.mp3";
const WaitingRoomSoundURL =
  SpringCDN + "/assets/media/session_join_waiting_room.mp3";
const CareSupportEmailURL =
  "https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=14448838263956";
const CareSupportAppointmentSchedulingURL =
  "https://careteam.springhealth.com/hc/en-us/categories/15544968907668-Scheduling-Appointments";
const CareSupportMedicalRecordsURL =
  "https://careteam.springhealth.com/hc/en-us/categories/15545039511060-Medical-Records";
// support links for Livekit implementation
const GeneralPermissionsSupportPage =
  "https://careteam.springhealth.com/hc/en-us/categories/30701589319956-Virtual-Therapy-Session-Setup-and-Support";
// support links for browser permissions
  const BrowserPermissionsSupportPageChrome =
  "https://careteam.springhealth.com/hc/en-us/articles/31045195833492-Learn-How-to-Reset-Microphone-and-Camera-Settings-on-Chrome";
const BrowserPermissionsSupportPageSafari =
  "https://careteam.springhealth.com/hc/en-us/articles/31045287464468-Learn-How-to-Reset-Microphone-and-Camera-Settings-on-Safari";
const BrowserPermissionsSupportPageEdge =
  "https://careteam.springhealth.com/hc/en-us/articles/31045249955732-Learn-How-to-Reset-Microphone-and-Camera-Settings-on-Microsoft-Edge";
const BrowserPermissionsSupportPageFirefox =
  "https://careteam.springhealth.com/hc/en-us/articles/31045220390548-Learn-How-to-Reset-Microphone-and-Camera-Settings-on-Firefox";
// support links for system permissions
const SystemPermissionsSupportPageMacOS =
  "https://careteam.springhealth.com/hc/en-us/articles/31744092505748-Learn-How-to-Give-Your-Browser-Permission-to-Access-the-Camera-and-Microphone-on-Mac";
const SystemPermissionsSupportPageWindows =
  "https://careteam.springhealth.com/hc/en-us/articles/31744016966420-Learn-How-to-Give-Your-Browser-Permission-to-Access-the-Camera-and-Microphone-on-Windows";
const SystemPermissionsSupportPageIOS =
  "https://careteam.springhealth.com/hc/en-us/articles/31744131205268-Learn-How-to-Give-Your-Browser-Permission-to-Access-the-Camera-and-Microphone-on-iOS";
const SystemPermissionsSupportPageAndroid =
  "https://careteam.springhealth.com/hc/en-us/articles/31744254968596-Learn-How-to-Give-Your-Browser-Permission-to-Access-the-Camera-and-Microphone-on-Android";
const FirewallSupportPage =
  "https://careteam.springhealth.com/hc/en-us/articles/32343919286164-Network-or-Firewall-Blocking-Connection-to-Care-Session"

module.exports = {
  InformedConsent,
  InformedConsentGlobal,
  MinorInformedConsent,
  Coppa,
  InternalInformedConsent,
  PrivacyNotice,
  AppStoreUrl,
  GooglePlayUrl,
  shlabsUrl,
  caregiverTypeDetailsInfoUrl,
  ElectronicCommunicationAgreement,
  HealthInformationConsent,
  TermsOfService,
  PaymentPolicyPDF,
  PrivacyPolicy,
  SMSTerms,
  GoogleAccount,
  iCloudAccount,
  OutlookAccount,
  Datadog,
  Iterable,
  Support,
  CareSupportCenterProductionLink,
  CareSupportCenterLowerEnvLink,
  ZendeskAvatarUrl,
  AccountTransitionFAQlink17To18,
  AccountTransitionFAQlink12To13,
  AccountTransitionFAQlink5To6,
  SpringCDN,
  TargetSageContentURL,
  CancellationPolicy,
  WorkplaceManagementConsult,
  WorkplaceCriticalIncidentResponse,
  RecommendationsSurveyURL,
  WorkplaceSurveyURL,
  WebinarsURL,
  UpcomingWebinarsURL,
  GlobalWellspringsURL,
  WellspringsURL,
  UpcomingWellspringsURL,
  CostEstimateSupportLink,
  CompassURL,
  CompassStagingURL,
  SpeakerTestURL,
  JoinSessionSoundURL,
  LeaveSessionSoundURL,
  WaitingRoomSoundURL,
  CareSupportEmailURL,
  CareSupportAppointmentSchedulingURL,
  CareSupportMedicalRecordsURL,
  GeneralPermissionsSupportPage,
  BrowserPermissionsSupportPageChrome,
  BrowserPermissionsSupportPageSafari,
  BrowserPermissionsSupportPageEdge,
  BrowserPermissionsSupportPageFirefox,
  SystemPermissionsSupportPageMacOS,
  SystemPermissionsSupportPageWindows,
  SystemPermissionsSupportPageIOS,
  SystemPermissionsSupportPageAndroid,
  FirewallSupportPage,
};
