import { gql } from "@apollo/client";

import { teenCareTeamMember } from "operations/fragments/careProvider";

const getCareProviderForTeen = gql`
  query getCareProviderForTeen($id: ID!) {
    care_provider(id: $id) {
      ...teenCareTeamMember
    }
  }
  ${teenCareTeamMember}
`;

export default getCareProviderForTeen;
