import React, { useEffect, useState } from "react";
import { BackgroundCurve } from "@spring/smeargle";
import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
  Button,
  Divider,
  Accordion,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  useMediaQuery,
  Spinner,
} from "design-system/components";
import {
  Provider,
  Confidential,
  Dollar,
  Convenience,
  ChevronDown,
  ChevronUp,
} from "design-system/assets";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import routes from "routes/";
import Router, { useRouter } from "next/router";
import { useMutation, useQuery } from "@apollo/client";
import { createSUDZendeskTicket } from "operations/mutations/member";
import { connect } from "react-redux";
import { addNotification } from "@spring/smeargle/src/actions";
import { TRACK_EVENT } from "utils/mixpanel";

import { useFeatureFlag, FLAGS } from "utils/launchdarkly/flags";
import getSUDCareNavigators from "operations/queries/careProvider/getSUDCareNavigators";

const CareProviderCard = ({
  id,
  name,
  avatar,
  licenses,
  t,
  sudZendeskMutation,
}) => {
  const router = useRouter();
  const [isSmallScreen] = useMediaQuery("(max-width: 992px)");

  const scheduleSessionHandler = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      routes["ScheduleAppointmentProviderDetail"].as.replace(":id", id),
      "Tried to Schedule a call",
      {
        location: "Schedule a call button CTA",
        provider_id: id,
      },
    );

    sudZendeskMutation({
      variables: {
        schedule_attempt_type: "Member Tried",
        care_navigator_id: id,
      },
    });
    router.push(
      routes["ScheduleAppointmentProviderDetail"].as.replace(":id", id),
    );
  };

  return (
    <Box
      mt="16px"
      border="2px"
      borderRadius="16px"
      borderColor="tertiary.light"
    >
      <HStack>
        <Image
          src={avatar}
          width={{ base: 78, md: 140, lg: 160 }}
          height={{ base: 104, lg: "100%" }}
          maxH={170}
          objectFit="cover"
          borderTopStartRadius="16px"
          borderBottomStartRadius={{ base: "0", md: "16px" }}
          alt={name}
        />
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          ms="0 !important"
          px={24}
        >
          <VStack pe={"8px"}>
            <HStack>
              <Provider color="platform.900" boxSize={24} />
              <Text fontWeight={{ base: 400, md: 700 }} fontSize={16}>
                {t("firstStep.careProviderCard.careNavigator")} &nbsp;•&nbsp;{" "}
                {licenses.join(", ")}
              </Text>
            </HStack>
            <Text
              w={{ base: "100%", md: "" }}
              fontWeight={{ base: 700, md: 600 }}
              fontSize={{ base: 16, md: 21 }}
            >
              {name}
            </Text>
          </VStack>
          <Box display={{ base: "none", md: "block" }}>
            <Button
              data-cy="schedule-call-button"
              onClick={scheduleSessionHandler}
              size={isSmallScreen ? "md" : "lg"}
              w={{ base: 172, lg: 291 }}
              variant="outline"
              colorScheme="primary"
              whiteSpace="normal"
              h={"auto"}
              maxH={80}
            >
              {t("firstStep.careProviderCard.scheduleSessionButton")}
            </Button>
          </Box>
        </Flex>
      </HStack>
      <Divider />
      <Box display={{ base: "flex", md: "none" }}>
        <Button
          onClick={scheduleSessionHandler}
          size="lg"
          w="100%"
          variant="ghost"
          colorScheme="primary"
        >
          {t("firstStep.careProviderCard.scheduleSessionButton")}
        </Button>
      </Box>
    </Box>
  );
};

const AboutProgramCard = ({ logo, title, subtitle }) => (
  <Box
    borderColor="#EFEDE8"
    borderRadius="8px"
    w={{ base: "100%", sm: 327, md: 310, lg: 295 }}
    borderWidth={1}
    px={{ base: 22, lg: 40 }}
    pt={{ base: 24, lg: 40 }}
    pb={16}
  >
    {logo}
    <Heading
      as="h3"
      mt={18}
      fontSize={{ base: 16, lg: 21 }}
      fontWeight={{ base: 700, lg: 600 }}
    >
      {title}
    </Heading>
    <Text mt={16} maxH="332px" overflowY="auto">
      {subtitle}
    </Text>
  </Box>
);

const FAQAccordionItem = ({ question, answer }) => (
  <AccordionItem
    bg="tertiary.25"
    borderRadius="16px"
    border="none"
    mt={16}
    p={24}
    color="platform.900"
  >
    {({ isExpanded }) => (
      <>
        <AccordionButton
          _focus={{}}
          _focusVisible={{
            boxShadow: "var(--chakra-shadows-outline)",
          }}
        >
          <Box flex="1" display="flex" gap="8px" textAlign="start">
            <Heading
              as="h3"
              fontSize={{ base: 16, md: 21 }}
              w={{ base: "95%", md: "100%" }}
              fontWeight={600}
            >
              {question}
            </Heading>
          </Box>
          {isExpanded ? (
            <ChevronUp boxSize={26} />
          ) : (
            <ChevronDown boxSize={26} />
          )}
        </AccordionButton>
        <AccordionPanel maxH={400} overflowY="auto" p={0}>
          <Text fontSize={16} fontWeight={400} mt={{ base: 24, md: 28 }}>
            {answer}
          </Text>
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
);

const Header = ({ t }) => (
  <Box mt="4rem">
    <Flex>
      <Box w={{ base: "100%", lg: "50%" }}>
        <Heading as="h1" fontWeight={700} fontSize={{ base: 29, lg: 38 }}>
          {t("header.title")}
        </Heading>
        <Text
          mt="1rem"
          maxW={{ base: 460, lg: 604 }}
          fontWeight={700}
          fontSize={16}
        >
          {t("header.body")}
        </Text>
      </Box>
      <Box
        w="42%"
        display={{ base: "none", lg: "flex" }}
        className={styles.setbackContainer}
      >
        <img
          src="/static/setback.svg"
          alt="Setback"
          className={styles.setback}
        />
      </Box>
    </Flex>
    <BackgroundCurve pageRoute="SubstanceUseSupport" />
  </Box>
);

const FirstStep = ({ t, addNotification }) => {
  const [requestedSUD, setRequestSUD] = useState(
    localStorage.getItem("availability_requested_sud_cn"),
  );

  const [sudZendeskMutation] = useMutation(createSUDZendeskTicket);

  const handleSudZendeskMutation = async () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Request availability",
      {
        location: "Submit request for availabity CTA",
      },
    );

    try {
      await sudZendeskMutation();
      localStorage.setItem("availability_requested_sud_cn", true);
      setRequestSUD(true);
      addNotification(t("firstStep.successNotification"), "success");
    } catch (_e) {
      addNotification(t("firstStep.errorNotification"), "error");
    }
  };

  const {
    data: { sud_care_navigators: sudCareNavigators = [] } = {},
    loading,
  } = useQuery(getSUDCareNavigators);

  return (
    <Box w={{ base: "100%", lg: 814 }}>
      <Flex justify="space-between">
        <Box>
          <Heading as="h2" fontWeight={700} fontSize={{ base: 21, lg: 29 }}>
            {t("firstStep.title")}
          </Heading>
          <Text
            mt="1rem"
            maxW={810}
            fontWeight={400}
            fontSize={16}
            color="platform.900"
            lineHeight="24px"
          >
            {t("firstStep.body")}
          </Text>
        </Box>
      </Flex>

      <Box mt={{ base: 24, lg: 40 }}>
        {loading && <Spinner speed="1s" size="xl" />}
        {!loading &&
          sudCareNavigators.map((sudCN) => {
            return (
              <CareProviderCard
                t={t}
                key={sudCN.id}
                id={sudCN.id}
                name={sudCN.name}
                avatar={sudCN.avatar_cdn_url}
                licenses={sudCN.licenses}
                sudZendeskMutation={sudZendeskMutation}
              />
            );
          })}
      </Box>

      <Box my={{ base: 24, lg: 40 }}>
        {requestedSUD ? (
          <Text display="inline" me={1} fontWeight={700} fontSize={16}>
            {t("firstStep.postSUDRequest")}
          </Text>
        ) : (
          <>
            <Text display="inline" me={1} fontWeight={700} fontSize={16}>
              {t("firstStep.timeUnavailableText")}
            </Text>
            <Button
              onClick={handleSudZendeskMutation}
              w="fit-content"
              variant="link"
              colorScheme="primary"
            >
              {t("firstStep.requestAvailability")}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

const AboutProgram = ({ t }) => (
  <Box mt={{ base: 40, lg: 64 }}>
    <Heading
      as="h2"
      fontWeight={{ base: 600, lg: 700 }}
      fontSize={{ base: 21, lg: 29 }}
    >
      {t("aboutProgram.title")}
    </Heading>
    <SimpleGrid
      spacingY={16}
      mt={24}
      minChildWidth={{ base: "100%", sm: 331, md: 314, lg: 299 }}
      justifyItems={{ base: "center", md: "left" }}
    >
      <AboutProgramCard
        logo={<Confidential boxSize={22} />}
        title={t("aboutProgram.cards.confidential.title")}
        subtitle={t("aboutProgram.cards.confidential.body")}
      />
      <AboutProgramCard
        logo={<Dollar boxSize={22} />}
        title={t("aboutProgram.cards.affordable.title")}
        subtitle={t("aboutProgram.cards.affordable.body")}
      />
      <AboutProgramCard
        logo={<Provider boxSize={22} />}
        title={t("aboutProgram.cards.dedicatedSupport.title")}
        subtitle={t("aboutProgram.cards.dedicatedSupport.body")}
      />
      <AboutProgramCard
        logo={<Convenience boxSize={22} />}
        title={t("aboutProgram.cards.convenientPrivateOptions.title")}
        subtitle={t("aboutProgram.cards.convenientPrivateOptions.body")}
      />
    </SimpleGrid>
  </Box>
);

const FAQ = ({ t }) => (
  <Box mt={64} mb={100}>
    <Heading as="h2" fontWeight={700} fontSize={{ base: 21, md: 29 }}>
      {t("faq.title")}
    </Heading>
    <Box mt={24}>
      <Accordion mt={14} allowToggle>
        <FAQAccordionItem
          question={t("faq.items.participationConfidential.question")}
          answer={t("faq.items.participationConfidential.answer")}
        />
        <FAQAccordionItem
          question={t("faq.items.costEnquiry.question")}
          answer={t("faq.items.costEnquiry.answer")}
        />
        <FAQAccordionItem
          question={t("faq.items.availabilityEnquiry.question")}
          answer={t("faq.items.availabilityEnquiry.answer")}
        />
        <FAQAccordionItem
          question={t("faq.items.areDependentsAllowed.question")}
          answer={t("faq.items.areDependentsAllowed.answer")}
        />
        <FAQAccordionItem
          question={t("faq.items.alreadyCNAssigned.question")}
          answer={t("faq.items.alreadyCNAssigned.answer")}
        />
        <FAQAccordionItem
          question={t("faq.items.alreadySeeingTherapist.question")}
          answer={t("faq.items.alreadySeeingTherapist.answer")}
        />
      </Accordion>
    </Box>
  </Box>
);

const SubstanceUseSupport = ({ addNotification }) => {
  const { t } = useTranslation("substanceUseSupport");
  const showSudProgramPage = useFeatureFlag(FLAGS.SHOW_SUD_PROGRAM_PAGE);

  useEffect(() => {
    if (!showSudProgramPage) {
      Router.replace(routes.MemberHome.to, routes.MemberHome.as);
    }
  }, [showSudProgramPage]);

  return (
    <Container className={styles.substance_use_container} maxW={1274}>
      <Header t={t} />
      <FirstStep t={t} addNotification={addNotification} />
      <Divider borderColor="platform.light" borderWidth={2} />
      <AboutProgram t={t} />
      <FAQ t={t} />
    </Container>
  );
};

export default connect(null, { addNotification })(SubstanceUseSupport);
