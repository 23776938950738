import type { CareProviderKindsMapType } from "components/templates/CareVisitsPage/components/Tabs/types";

export const BENEFIT_PREFERENCES = [
  {
    enum: "PHYSICAL_HEALTH",
    label: "Physical health",
  },
  {
    enum: "EMOTIONAL_WELL_BEING",
    label: "Emotional well being",
  },
  {
    enum: "PARENTING_SKILLS",
    label: "Parenting skills",
  },
  {
    enum: "FINANCES",
    label: "Finances",
  },
  {
    enum: "RELATIONSHIPS",
    label: "Relationships",
  },
  {
    enum: "ALCOHOL_OR_SUBSTANCE_USE",
    label: "Alcohol or substance use",
  },
  {
    enum: "FERTILITY_CHALLENGES",
    label: "Fertility challenges",
  },
];

export const careProviderKinds: CareProviderKindsMapType = {
  THERAPIST: "therapist",
  COACH: "coaches",
  MEDICATION_MANAGER: "medicationManager",
  SUD_CN: "sudCn",
};
