import { gql } from "@apollo/client";

import { assessment } from "operations/fragments/assessment";

const finishAssessment = gql`
  mutation FinishAssessment($input: FinishAssessmentInput!) {
    finishAssessment(input: $input) {
      success
      assessment {
        ...assessment
        member {
          id
          todos {
            id
            data
            detail
            kind
            title
          }
          initial_sud_risk
          latest_sud_risk
        }
      }
    }
  }
  ${assessment}
`;

export default finishAssessment;
