import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import routes from "routes";

import { Bolded, Content } from "@spring/smeargle";
import { Button, Link } from "@springcare/sh-component-library";
import { Box, useDisclosure } from "@chakra-ui/react";
import Router from "next/router";

import { HelpFlyout } from "components/flyouts";
import { CareProvider } from "shared/contexts";
import { ScheduleModalOpenOptions } from "shared/hooks";
import envUtils from "utils/environment";
import { TRACK_EVENT } from "utils/mixpanel";

import styles from "./styles.module.scss";

import useChatBotHandler from "utils/chatbot/useChatBotHandler";

interface ConnectTextProps {
  careNavigator: CareProvider;
  isGlobalTherapy: boolean;
  needHelpText: string;
  isCoach: boolean;
  hideCostText?: boolean;
  modalControls: { isOpen: boolean; onClose: () => void; onOpen: () => void };
  onScheduleModalOpen: (props: ScheduleModalOpenOptions) => void;
  providerType?: boolean;
}

const ConnectText: FC<React.PropsWithChildren<ConnectTextProps>> = ({
  careNavigator,
  isGlobalTherapy,
  needHelpText,
  isCoach,
  hideCostText = false,
  providerType,
}) => {
  const careTeamEmail = envUtils.careTeamEmail;
  const { t } = useTranslation(["benefits"]);
  const {
    isOpen: isHelpFlyoutOpen,
    onOpen: onOpenHelpFlyout,
    onClose: closeHelpFlyout,
  } = useDisclosure();

  const { handleOpenChat } = useChatBotHandler();

  const spanStyle = {
    "& > span": {
      padding: 0,
    },
    ":focus-visible": { boxShadow: "0 0 0 3px rgba(43, 160, 181,1)" },
  };

  const openHelpFlyout = () => {
    onOpenHelpFlyout();
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Help Button Clicked", {
      location: "Care Navigation",
    });
  };

  const onClickChatButton = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Connect with your Care Navigator",
      {
        location: providerType,
      },
    );

    handleOpenChat();
  };

  const onClickCareNavigatorButton = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Connect with your Care Navigator",
      {
        location: providerType,
      },
    );

    Router.push(routes.CareNavigation.as);
  };

  const ConnectWithCareNavigation = ({ text }) => {
    return (
      <Button
        size="sm_v1"
        variant="link"
        colorScheme="primary"
        fontWeight={100}
        textDecoration="underline"
        verticalAlign="baseline"
        css={spanStyle}
        onClick={onClickCareNavigatorButton}
      >
        {text}
      </Button>
    );
  };

  const RealTimeChatButton = ({ text }) => {
    return (
      <Button
        size="sm_v1"
        variant="link"
        colorScheme="primary"
        fontWeight={100}
        textDecoration="underline"
        verticalAlign="baseline"
        css={spanStyle}
        onClick={onClickChatButton}
      >
        {text}
      </Button>
    );
  };

  const FindATimeButton = () => {
    const onClick = () => {
      Router.push(routes.CareNavigation.as);
    };

    return (
      <Button
        size="sm_v1"
        variant="link"
        colorScheme="primary"
        fontWeight={100}
        css={spanStyle}
        textDecoration="underline"
        verticalAlign="baseline"
        onClick={onClick}
      >
        {t("coveredCareContent.findATime")}
      </Button>
    );
  };

  function getTranslationValue() {
    if (isGlobalTherapy) {
      return needHelpText;
    }
    return "coveredCareContent.careNavigatorHelpNew";
  }

  if (careNavigator && !isCoach) {
    const i18nKey = getTranslationValue();
    return (
      <>
        <Box pb="30">
          <Content>
            <Trans
              ns="benefits"
              i18nKey={i18nKey}
              values={{ careTeamEmail }}
              components={[
                <Link
                  as="button"
                  key="0"
                  fontWeight={100}
                  onClick={openHelpFlyout}
                >{`${careTeamEmail}`}</Link>,
                <RealTimeChatButton
                  key="1"
                  text={t("coveredCareContent.realTimeChat")}
                />,
                <FindATimeButton key="2" />,
              ]}
            />
          </Content>
        </Box>
        {!isGlobalTherapy && !hideCostText && (
          <div className={styles.connectTextWrapper}>
            <Content>
              <Trans
                ns="benefits"
                i18nKey="coveredCareContent.careNavigatorNoCost"
                components={[<Bolded key={0} />]}
              />
            </Content>
          </div>
        )}
        <HelpFlyout
          isOpen={isHelpFlyoutOpen}
          onOpen={openHelpFlyout}
          onClose={closeHelpFlyout}
        />
      </>
    );
  }

  return (
    <Box className={styles.connectTextWrapper} pb={20}>
      <Content>
        <Trans
          ns="benefits"
          i18nKey={
            needHelpText ? needHelpText : "coveredCareContent.needHelpText"
          }
          components={[
            <ConnectWithCareNavigation
              key="0"
              text={t("coveredCareContent.connectWithCareNavigator")}
            />,
          ]}
        />
      </Content>
    </Box>
  );
};

export default ConnectText;
