import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ContentPage = (props) => (
	<div className={styles.content}>
		{props.children}
	</div>
);

ContentPage.propTypes = {
	children: PropTypes.any,
};

export default ContentPage;
