import {
  Box,
  HStack,
  Text,
  VStack,
  useBreakpointValue,
} from "design-system/components";

import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { VErrorIcon } from "@springcare/verdant-icons-react";

export const AccountLockedBanner = () => {
  const { t } = useTranslation("limitedLangRegister");
  const bannerWidth = useBreakpointValue([300, 400, 575]);

  return (
    <Box role="alert" aria-live="assertive">
      <Alert
        colorScheme="negative"
        paddingY={16}
        marginY={24}
        paddingLeft={16}
        paddingRight={16}
        borderRadius="16px"
        width={bannerWidth}
      >
        <VStack alignItems="start">
          <HStack>
            <AlertIcon as={VErrorIcon} height={24} width={24} />
            <AlertTitle>
              {t("accountExists.login.accountLocked", "Account locked")}
            </AlertTitle>
          </HStack>
          <Text textAlign="left" paddingLeft={42}>
            {t("accountExists.login.overMaxiLoginAttemptsMsg")}
          </Text>
        </VStack>
      </Alert>
    </Box>
  );
};
