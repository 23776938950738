// @flow
import { track } from ".";

export const CARD_TYPE = {
  PRIMARY: "Primary",
  DEPENDENT: "Dependent",
  GUARDIAN: "Guardian",
  CHILD: "Child",
  ADD_CHILD: "Add Child",
};

export const LINK_TYPE = {
  TOS: "Terms of Service",
  PP: "Privacy Policy",
  HPN: "HIPAA Privacy Notice",
  COPPA: "COPPA",
  MIC: "Minor Informed Consent",
  IC: "Informed Consent",
};

// What should be tracked?
//    Anything the user DOES should have ONE event triggered for it. On most websites, that primarily involves tracking clicks.
//    Anything the user SEES could have ONE event triggered for it. Eg: When a provider comes into view on a window.
//    Do NOT track errors, that is for logging to handle.
//    Do NOT track results of code work. Eg: 'Sign In Success', 'Received Server Response', etc

// Adding Tracking:
//    1) Pick your TRACK_EVENT type and trigger it on user's action or view load.
//    2) Document the new event.

// Creating A New TRACK_EVENT Type.
//    1) Does your event fall into an event category already specified? Try to use that event. Refer to Additionl Params bellow if you would like to add parameters to an event.
//    1) Try to think broadly about the website interactions our users make and the categories of action those fall into. Those categories are the event types we should be tracking.
//    2) Try to keep these events generic and reusable, but specific enough someone unfamiliar with the code would be able to look at the site and guess what event type would be triggered on a user's action.

// Adding a additional parameters to an existing TRACK_EVENT
//    1) When adding additional parameters, try to use generic naming that has been, or can be, used by other event types. The fewer parameter types we maintain, the easier it is to find and view the parameter you are looking for when consuming the data and generating reports.
//    2) Eg TRACK_EVENT.CARD_CLICKED currently tracks page, type and to parameters as seen in the TRACK_EVENT object bellow.
//    3) The Link component behaves slightly different, refer to the Links section below.
//    4) Work with DS team to ensure new params are being mapped in stitch so all traking data makes it to snowflake.

// Replacing Existing Events:
//    1) Add { deprecated: true, replaced_with: <new event string> } to the existing event parameters.
//    2) Add your new event.
//    3) Release your changes.
//    4) Update event tracking documentation.
//    5) Communicate widely that an event has been deprecated and what the event replacing it is. Data Science, Product and event consumers should be notified.
//    6) Give time for all reporting to be updated and for sufficient history to be built with the new event. This may be 1-6 months.
//    7) Delete the replaced event.

// Documenting Events:
//    Document what user actions trigger which events and the parameters added.
//    This enables teams outside of engineering to easily consume this data and build reports with out relying on an eng to see in code what is triggered when.
//    Member Singup documentation: https://docs.google.com/spreadsheets/d/1Uv06uUUsZdNaq6siZqmvMvyvu9CHy7V1sKApiCUXoj0/edit?usp=sharing
//    Member Portal documentation: WIP - Andrea Hallier can provide access as requested
//    Care Navigator Scheduling Event tracking documentation: https://docs.google.com/spreadsheets/d/13r3RFbIyk9XOEn5tJZ3_rMFNHYs0EXYANaBp8bW8FK8

// Links:
//    We have all <Link> type components triggering mixpanel events. To add parameters to the Link Clicked event being triggered, add 'mpTracking' params to the <Link> component.
//    eg: <Link alias="TermsOfService" mpTracking={{ page: routes.FamilySignup.as, to: links.TermsOfService, type: LINK_TYPE.TOS, location: 'Child Sign Up' }}>Terms of Service</Link>
//    Not all interactions that trigger a Link Clicked event, are in a <Link> component. You can still trigger the Link Clicked event for those interactions using TRACK_EVENT.LINK_CLICKED function.

// When to use Link Clicked vs Button Clicked.
//    Generally:
//    1) If it looks like a button, use Button Clicked. Check it is not a Link Component first, those have auto tracking setup if you pass up params.
//    2) If it looks like a link, but does not reroute, use a Button Clicked.
//    3) There are strange edge cases, use your best judgment.

export const EVENT_TYPE = {
  PAGE_VERSION_VIEWED: "Page Version Viewed",
  CARD_CLICKED: "Card Clicked",
  LINK_CLICKED: "Link Clicked",
  BUTTON_CLICKED: "Button Clicked",
  FORM_SUBMITTED: "Form Submitted",
  SURVEY_SUBMITTED: "Survey Submitted",
  PAGE_VIEWED: "Page Viewed",
  ROUTE_CHANGED: "Route Changed",
  MODAL_OPENED: "Modal Opened",
  MODAL_CLOSED: "Modal Closed",
  COMPONENT_VIEWED: "Component Viewed",
  COMPONENTS_LOADED: "Components Loaded",
  ERROR_MESSAGE_VIEWED: "Error Message Viewed",
  FILTER_CHANGE_APPLIED: "Filter Change Applied",
  NOTIFICATION_VIEWED: "Notification Viewed",
  WARNING_VIEWED: "Warning Viewed",
  QUERY_RESULT: "Query Result",
  ERROR: "Error",
  SUCCESS: "Success",
  ASSESSMENT_PROMPT_ANSWERED: "Assessment Prompt Answered",
  VIDEO_ENDED: "Video Ended",
  VIDEO_LOADED: "Video Loaded",
  APPOINTMENT_DIRECTLY_SCHEDULED: "Appointment Directly Scheduled",
  CL_FOUND: "Covered Life Found",
  EXERCISE_COMPLETED: "Exercise Completed",
};

export const TRACK_EVENT = {
  PAGE_VERSION_VIEWED: (page, type, props) =>
    track(EVENT_TYPE.PAGE_VERSION_VIEWED, { page, type, ...props }),
  CARD_CLICKED: (page, to, type, props) =>
    track(EVENT_TYPE.CARD_CLICKED, { page, to, type, ...props }),
  LINK_CLICKED: (page, to, type, props) =>
    track(EVENT_TYPE.LINK_CLICKED, { page, to, type, ...props }),
  BUTTON_CLICKED: (page, type, props) =>
    track(EVENT_TYPE.BUTTON_CLICKED, { page, type, ...props }),
  FORM_SUBMITTED: (page, type, props) =>
    track(EVENT_TYPE.FORM_SUBMITTED, { page, type, ...props }),
  SURVEY_SUBMITTED: (page, type, props) =>
    track(EVENT_TYPE.SURVEY_SUBMITTED, { page, type, ...props }),
  MODAL_OPENED: (page, type, props) =>
    track(EVENT_TYPE.MODAL_OPENED, { page, type, ...props }),
  MODAL_CLOSED: (page, type, props) =>
    track(EVENT_TYPE.MODAL_CLOSED, { page, type, ...props }),
  COMPONENT_VIEWED: (page, type, props) =>
    track(EVENT_TYPE.COMPONENT_VIEWED, { page, type, ...props }),
  COMPONENTS_LOADED: (page, type, props) =>
    track(EVENT_TYPE.COMPONENTS_LOADED, { page, type, ...props }),
  ERROR_MESSAGE_VIEWED: (page, type, props) =>
    track(EVENT_TYPE.ERROR_MESSAGE_VIEWED, { page, type, ...props }),
  WARNING_VIEWED: (page, type, props) =>
    track(EVENT_TYPE.WARNING_VIEWED, { page, type, ...props }),
  NOTIFICATION_VIEWED: (page, type, props) =>
    track(EVENT_TYPE.NOTIFICATION_VIEWED, { page, type, ...props }),
  FILTER_CHANGE_APPLIED: (page, type, props) =>
    track(EVENT_TYPE.FILTER_CHANGE_APPLIED, { page, type, ...props }),
  QUERY_RESULT: (page, type, props) =>
    track(EVENT_TYPE.QUERY_RESULT, { page, type, ...props }),
  ERROR: (page, type, props) =>
    track(EVENT_TYPE.ERROR, { page, type, ...props }),
  SUCCESS: (page, type, props) =>
    track(EVENT_TYPE.SUCCESS, { page, type, ...props }),
  ASSESSMENT_PROMPT_ANSWERED: (page, type, props) =>
    track(EVENT_TYPE.ASSESSMENT_PROMPT_ANSWERED, { page, type, ...props }),
  VIDEO_ENDED: (page, type, props) =>
    track(EVENT_TYPE.VIDEO_ENDED, { page, type, ...props }),
  VIDEO_LOADED: (page, type, props) =>
    track(EVENT_TYPE.VIDEO_LOADED, { page, type, ...props }),
  APPOINTMENT_DIRECTLY_SCHEDULED: (page, type, props) =>
    track(EVENT_TYPE.APPOINTMENT_DIRECTLY_SCHEDULED, { page, type, ...props }),
  CL_FOUND: (page, type, props) =>
    track(EVENT_TYPE.CL_FOUND, { page, type, ...props }),
  EXERCISE_COMPLETED: (page, type, props) =>
    track(EVENT_TYPE.EXERCISE_COMPLETED, { page, type, ...props }),
};

// export type AssessmentPromptEventProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. cnsched000
//    */
//   spring_doc_id: string,
//   question_id: string, //{question id},
//   assessment_id: string, //{specific user assessment id},
//   questionnaire_kind_id: string, //8,
//   questionnaire_kind:string, // PHQ9,
//   assessment_kind: string, //Initial,
//   questionnaires_id: string, //{specific user questionnaire id}, // (may not be able to get this?)
//   prompt_id: string, //(if we can), // (may not be able to get this?)
//   assessment_version: integer, //2,
// }

// export type LinkEventProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. cnsched000
//    */
//   spring_doc_id: string,
//   location: string,
//   provider_PMI: string,
// }

// export type CardClickedProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. HPR_013
//    */
//   spring_doc_id: string,
//   location: string,
// }

// export type ModalEventProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. cnsched000
//    */
//   spring_doc_id: string,
//   location: string,
//   provider_id: string,
//   appointment_type: string,
//   appointment_medium: string,
//   provider_role: string,
//   modal_id: string, // use uuidv4
// }

// export type ButtonEventProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. cnsched000
//    */
//   spring_doc_id: string,
//   to: string,
//   location: string,
//   provider_id: string,
//   appointment_type: string,
//   appointment_medium: string,
//   page_number_from: Number,
//   page_number_to: Number,
//   total_pages: Number,
//   /**
//    * utc date string ISO-8601
//    */
//   appointment_time_utc: string,
//   time_zone: string,
//   offset: Number,
//   on_load: Boolean,
//   order: Number,
//   provider_PMI: string,
// }
// export type FormEventProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. cnsched000
//    */
//   spring_doc_id: string,
//   info: Number,
//   location: string,
//   provider_id: string,
//   appointment_type: string,
//   appointment_medium: string,
//   value: number || string,
//   /**
//    * utc date string ISO-8601
//    */
//   appointment_time_utc: string,
//   time_zone: string,
//   offset: number,
// }

// export type PageVersionViewedProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. HPR_013
//    */
//   spring_doc_id: string,
//   user_flow_type: string,
//   customer_id: string,
//   cohort_id: string,
//   invite_token: string,
//   covered_life_country: string,
//   dependent: string
// }

// export type ComponentViewedProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. cnsched000
//    */
//   spring_doc_id: string,
//   location: string,
//   provider_id: string,
//   appointment_type: string,
//   appointment_medium: string,
//   browse_page_number: number,
//   /**
//    * utc date string ISO-8601
//    */
//   appointment_time_utc: string,
//   /**
//    * utc date string ISO-8601
//    */
//   first_appointment_time_utc: string,
//   /**
//    * utc date string ISO-8601
//    */
//   second_appointment_time_utc: string,
//   /**
//    * utc date string ISO-8601
//    */
//   third_appointment_time_utc: string,
//   available_appointments_shown: Number,
//   time_zone: string,
//   offset: Number,
//   provider_PMI: string,
//   total: number,
// }

// export type ComponentLoadedProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. cnsched000
//    */
//    total_providers_displayed : Number
//    total_providers_available : Number
//    total_num_pages : Number
//    condition_tags : [String] || string
//    language_tags : [String] || string
//    specialtiesTags: [String] || string
//    genderTags: [String] || string
//    ethnicitiesTags: [String] || string
// }

// export type ErrorMessageViewedProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. MVerror000
//    */
//   spring_doc_id: string,
// }

// export type ErrorProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. MVerror000
//    */
//   spring_doc_id : string,
//   page_version  : titleCase(viewName),
//   error         : errorMessage,
// }

// export type SuccessProperties = {
//   /**
//    * a searchable linkage to event documentation, (see comments above)
//    * this value ties together a recorded mixpanel event, the documentation for it,
//    * and the place in the code that creates it.
//    * ex. MVerror000
//    */
//   spring_doc_id: string,
// }

// Page Views:
//    We have the 'Page Viewed' event tapped into our router, no additional page view events should be tracked.
//      If you have additional information to track on a page to specify what 'version' of a page a user is looking at (eg: /family_signup guardian vs child pages)
//      Use the 'Page Version Viewed' Event.

export const SYSTEM_TRACKED_EVENTS = {
  // Do not use, all page views are being captured via the router.
  // See 'Page Version Viewed' to track additional page information.
  PAGE_VIEWED: (page) => track(EVENT_TYPE.PAGE_VIEWED, { page }),
};

export const makeEventString = (event, params) => {
  const QUOTES_AND_BRACES = /"|{|}/g;

  return (
    event +
    " - " +
    JSON.stringify(params)
      .replace(QUOTES_AND_BRACES, "")
      .replace(/:/g, ": ")
      .replace(/,/g, ", ")
  );
};

const underscoreOrSpace = /_|\s/;
export const toTitleCase = (string) =>
  string
    .toLowerCase()
    .split(underscoreOrSpace)
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
