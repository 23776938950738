"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 * Takes a path, an index, and the object to unset.
 * Unsets the property at the path for the object. If an index is provided, unsets the
 * value at that index given the path is an array.
 *
 * @name unset
 * @param {(string | Array<String | Number>)} path
 * @param {Number | null} index
 * @param {Object} obj
 * @returns {Object} Returns a new object with updated value.
 *
 * @example
 * const x = {a: { b: 'remove me!'} };
 * const y = unset('a.b', null, x);
 *
 * console.log(y); // {a: {} }
 *
 * @example
 * const x = {a: { b: ['keep', 'remove'] } }
 * const y = unset('a.b', 1, x);
 *
 * console.log(y); // {a: { b: ['keep'] } }
 */

function curried(path, index, obj) {
  if (index !== null) {
    var arr = (0, _fp.get)(path, obj);
    if (Array.isArray(arr)) {
      arr.splice(index, 1);
      (0, _fp.unset)(path, obj);
      return (0, _fp.set)(path, arr, obj);
    }
  }
  return (0, _fp.unset)(path, obj);
}
var unset = (0, _fp.curry)(curried);
var _default = exports["default"] = unset;