/**
 * These loading statuses are to be used for async calls.
 */

const LoadingStatus = Object.freeze({
	Initial : 'Initial',
	Loading : 'Loading',
	Success : 'Success',
	Failed  : 'Failed',
});

module.exports = LoadingStatus;