import { useMutation } from "@apollo/client";
import updateAgedOutRecord from "modules/MemberDashboard/Home/components/AgingOut/graphql/mutations/updateAgedOutRecord";

export const useUpdateAgedOutConsents = (memberId, setNeedsUpdatedConsent) => {
  return useMutation(updateAgedOutRecord, {
    variables: { member_id: memberId },
    onCompleted: () => {
      setNeedsUpdatedConsent(false);
    },
  });
};
