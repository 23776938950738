import { useMutation } from "@apollo/client";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addNotification } from "@spring/smeargle/actions";
import {
  Breakpoint,
  Button,
  Card,
  Content,
  FlexCol,
  Form,
  Subtitle,
  Textarea,
  Subhead,
} from "@spring/smeargle";
import {
  AppointmentMedium,
  RequestableAppointmentKind,
} from "@spring/constants";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { requestAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import { track } from "utils/mixpanel";
import { useHelpInfo } from "hooks/useHelpInfo";
import useChatBotHandler from "utils/chatbot/useChatBotHandler";

const FindProviderCard = (props) => {
  const { t } = useTranslation(["careProvider", "memberHelpCard"]);
  const { handleOpenChat } = useChatBotHandler();

  const { helpInfo } = useHelpInfo();
  const [requestAppointmentMutation, { loading: mutationLoading }] =
    useMutation(requestAppointment, {
      onCompleted: () =>
        props.addNotification(
          t("findProvider.notificationMessage.success"),
          "success",
        ),
      onError: (err) => props.addNotification(getFirstError(err), "error"),
    });

  const handleSubmit = (formData) => {
    track("Browse Provider Page - Submit request for provider clicked");

    let appointmentKind =
      props.appointmentKind() || RequestableAppointmentKind.Therapy;
    if (
      [
        "FOLLOW_UP_MEDICATION_MANAGEMENT",
        "INITIAL_MEDICATION_MANAGEMENT",
      ].includes(appointmentKind)
    ) {
      appointmentKind = RequestableAppointmentKind.MedicationManagement;
    }

    return requestAppointmentMutation({
      variables: {
        input: {
          availability_days_of_week: ["See notes"],
          availability_time_of_day: ["See notes"],
          kind: appointmentKind,
          medium: AppointmentMedium.Video, // `medium` is required on the BE so we pass video as a default
          notes: formData.notes,
        },
      },
    });
  };

  const content = ({ isSmallerScreen, size, handleOpenChat }) => {
    const availabilityText = t(helpInfo?.region?.general_support_availability, {
      ns: "memberHelpCard",
    });

    return (
      <>
        <Subhead semibold noMargin>
          <div className={styles.findProviderCardRequestTitle}>
            <h2>{t("findProvider.subhead")}</h2>
          </div>
        </Subhead>
        <div className={styles.findProviderCardLiveHelpSubtitle}>
          <Subtitle bold noMargin>
            {t("findProvider.subtitle")}
          </Subtitle>
        </div>

        <Content>
          {t("findProvider.availabilityContent", {
            availability: availabilityText,
          })}
        </Content>

        <div className={styles.findProviderCardLiveHelpButtons}>
          <span className={styles.findProviderCardChatButton}>
            {window && (
              <Button
                text={t("findProvider.liveChatButtonText")}
                icon="message"
                onClick={() => {
                  track(
                    "Browse Provider Page - Need help finding a provider? - Live chat clicked",
                  );
                  handleOpenChat();
                }}
                full={isSmallerScreen ? true : false}
              />
            )}
          </span>

          <span className={styles.findProviderCardPhoneButton}>
            {window && (
              <Button
                text={helpInfo?.region?.general_support_number}
                icon="phone"
                onClick={() => {
                  track(
                    "Browse Provider Page - Need help finding a provider? - Phone number clicked",
                  );

                  if (window) {
                    window.open(
                      `tel:${helpInfo?.region?.general_support_number}`,
                      "_parent",
                    );
                  }
                }}
                full={isSmallerScreen ? true : false}
              />
            )}
          </span>
        </div>

        <div className={styles.findProviderCardRequestSubtitle}>
          <Subtitle bold noMargin>
            {t("findProvider.submitRequest.subtitle")}
          </Subtitle>
        </div>

        <Form
          formKey="findProviderCard"
          theme="simple"
          loading={mutationLoading}
          onSubmit={(formData) => handleSubmit(formData)}
          submitText={t("findProvider.submitRequest.submitText")}
          fullSubmit={isSmallerScreen ? true : false}
          miniSubmit={isSmallerScreen ? true : false}
          centerSubmit
        >
          <div className={styles.findProviderCardRequestTextarea}>
            <Textarea
              label={t("findProvider.submitRequest.askPreference")}
              fieldKey={`notes-${size}`}
              placeholder={t("findProvider.submitRequest.placeholder")}
              maxLength="900"
              ariaLabel={t("findProvider.submitRequest.askPreference")}
            />
          </div>

          <div className={styles.findProviderCardRequestContent}>
            <FlexCol center>
              <Content>{t("findProvider.submitRequest.content")}</Content>
            </FlexCol>
          </div>
        </Form>
      </>
    );
  };

  const breakpointWrapper = () => (
    <>
      <Breakpoint xs>
        <Card padding="find-provider-sm" color="GREY_150">
          {content({ isSmallerScreen: true, size: "xs", handleOpenChat })}
        </Card>
      </Breakpoint>

      <Breakpoint sm>
        <Card padding="find-provider-sm" color="GREY_150">
          {content({ isSmallerScreen: true, size: "sm", handleOpenChat })}
        </Card>
      </Breakpoint>

      <Breakpoint md andUp>
        <Card padding="find-provider-lg" color="GREY_150">
          {content({
            isSmallerScreen: false,
            size: "md",
            handleOpenChat,
          })}
        </Card>
      </Breakpoint>
    </>
  );

  return breakpointWrapper();
};

FindProviderCard.propTypes = {
  addNotification: PropTypes.func,
  appointmentKind: PropTypes.func,
};

export { FindProviderCard };

export default connect(null, { addNotification })(FindProviderCard);
