import { useTranslation } from "react-i18next";
import styles from "components/molecules/BenefitsTracker/styles.module.scss";
import React from "react";
import { InNetworkStatusDisclaimer } from "components/molecules";
import { Box } from "design-system/components";

const TherapyBenefitsTable = ({
  therapyVisitsRemaining,
  therapyVisitsCovered,
  therapyVisitsUsed,
  specialistVisitsCovered,
  customerName,
  isInNetwork,
  internationalCohort,
  virtualSessionCostTerm,
  isBoeing,
  isAMinor,
}) => {
  const { t } = useTranslation("benefits");
  const totalCovered = therapyVisitsCovered + specialistVisitsCovered;

  const sessionsTable = () => {
    return (
      <>
        <div className={styles.sessionsInfo}>
          <span className={styles.sessionsInfoHeader}>
            {t("sessions.noCostVisits")}
          </span>
          <div className={styles.sessionsInfoContainer}>
            <span className={styles.sessionsInfoText}>
              {t(
                isBoeing
                  ? "sessions.therapyVisitsCoveredBoeing"
                  : "sessions.therapyVisitsCovered",
                { therapyVisitsCovered },
              )}
            </span>
            {therapyVisitsUsed > 0 && (
              <>
                <span className={styles.sessionsInfoText}>
                  {t("sessions.therapyVisitsUsed", { therapyVisitsUsed })}
                </span>
                <span className={styles.sessionsInfoText}>
                  {t("sessions.therapyVisitsRemaining", {
                    therapyVisitsRemaining,
                  })}
                </span>
              </>
            )}
          </div>
        </div>
        <span className={styles.sessionsInfoDesc}>
          {`${t(
            isBoeing
              ? "sessions.noCostDescriptionTherapistBoeing"
              : "sessions.noCostDescriptionTherapist",
            {
              therapyVisitsCovered,
            },
          )}
          ${
            specialistVisitsCovered != 0 && !isAMinor
              ? " " +
                t("sessions.noCostDescriptionMedManager", {
                  specialistVisitsCovered,
                })
              : ""
          }`}
        </span>
      </>
    );
  };

  return (
    <>
      {/* Render the sessions table only if there are covered sessions */}
      {totalCovered > 0 && sessionsTable()}
      <Box mt={10}>
        <InNetworkStatusDisclaimer
          inNetwork={isInNetwork}
          hasFreeSessions={!!therapyVisitsCovered}
          internationalCohort={internationalCohort}
          customerName={customerName}
          telemetryProps={{ location: "Therapy" }}
          virtualSessionCostTerm={virtualSessionCostTerm}
          inNetworkDisclaimerConfig={{
            inNetworkFreeSessions: t("sessions.afterRemainingSessions", {
              customerName: customerName,
            }),
            inNetworkNoFreeSessions: t("sessions.inNetworkSessions", {
              customerName: customerName,
            }),
            notInNetworkFreeSessions: t("sessions.additionalSessionsAtCost"),
            notInNetworkNoFreeSessions: t("sessions.sessionTherapy"),
            default: null,
          }}
        />
      </Box>
    </>
  );
};

export default TherapyBenefitsTable;
