import React from "react";
import { useTranslation } from "react-i18next";
import styles from "components/molecules/BenefitsTracker/styles.module.scss";
import { money_format } from "modules/MemberDashboard/Billing/utils";

const CoachingBenefitsTable = (props) => {
  const { t } = useTranslation("benefits");
  const {
    coachingVisitsRemaining,
    coachingVisitsCovered,
    coachingVisitsUsed,
    memberCostOfCoaching,
    isBoeing,
  } = props;
  const coachingSessionCost = money_format(memberCostOfCoaching);
  const SessionTable = () => {
    return (
      <>
        <div className={styles.sessionsInfoCoaching}>
          <span className={styles.sessionsInfoHeader}>
            {t("coachesVisit.noCostVisits")}
          </span>
          <div className={styles.sessionsInfoContainer}>
            {/*  VisitsCovered, VisitsUsed, and VisitsRemaining are rendered on the same line. */}
            <span className={styles.sessionsInfoText}>
              {t(
                isBoeing
                  ? "coachesVisit.coachingVisitsCoveredBoeing"
                  : "coachesVisit.coachingVisitsCovered",
                { coachingVisitsCovered },
              )}
            </span>
            {coachingVisitsUsed ? (
              <>
                <span className={styles.sessionsInfoText}>
                  {t("coachesVisit.coachingVisitsUsed", { coachingVisitsUsed })}
                </span>
                <span className={styles.sessionsInfoText}>
                  {t("coachesVisit.coachingVisitsRemaining", {
                    coachingVisitsRemaining,
                  })}
                </span>
              </>
            ) : null}
          </div>
        </div>

        {isBoeing && (
          <span className={styles.sessionsInfoDesc}>
            {t("coachesVisit.coachingVisitsCoveredDescriptionBoeing", {
              coachingVisitsCovered,
            })}
          </span>
        )}
      </>
    );
  };

  const CostTable = () => {
    return (
      <div className={styles.sessionsInfo} data-cy="coaching-session-cost">
        <span className={styles.sessionsInfoHeader}>
          {coachingVisitsCovered > 0
            ? t("coachesVisit.additionalVisits")
            : t("coachesVisit.noCovered")}
        </span>
        {memberCostOfCoaching > 0 ? (
          <>
            <span className={styles.sessionsInfoText}>
              {t("coachesVisit.additionalVisitsCost", {
                coachingSessionCost: coachingSessionCost,
              })}
            </span>
          </>
        ) : (
          <>
            {" "}
            <span className={styles.sessionsInfoText}>
              {t("coachesVisit.noCostVisits")}
            </span>
          </>
        )}
      </div>
    );
  };
  const AdditionalVisitsNoCost = () => {
    return (
      <div className={styles.sessionsInfo} data-cy="coaching-session-cost">
        <span className={styles.sessionsInfoText}>
          {t("coachesVisit.coachingNoCost")}
        </span>
      </div>
    );
  };
  return (
    <div className={styles.sessionsWrapper}>
      {/* Coaching session transparency table */}
      {coachingVisitsCovered > 0 && <SessionTable />}
      {memberCostOfCoaching == 0 && <AdditionalVisitsNoCost />}
      {/* Coaching Session cost table. SessionCost is a constant and this should NOT use the understandCostTable */}
      {memberCostOfCoaching > 0 && <CostTable />}
    </div>
  );
};

export default CoachingBenefitsTable;
