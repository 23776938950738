import { gql } from "@apollo/client";

const getMemberInsurance = gql`
  query getMemberInsurance($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        payment_preference
        visits_remaining_total
        insurance_policy {
          id
          carrier_name
          insurance_group_id
          insurance_member_id
          plan_name
          insurance_card_front_id
          insurance_card_back_id
          cost_estimate_requested_date
        }
        validated_insurance_policy {
          id
          carrier_name
          insurance_member_id
          insurance_group_id
          plan_name
          insurance_payer_id
        }
        eligible_for_insurance_validation
        cohort {
          in_network
          customer {
            is_health_plan
          }
        }
      }
    }
  }
`;

export default getMemberInsurance;
