import i18n from "i18n";
import { API_BASE } from "constants/api";
import { getIdentifiers, getZendeskJwtToken } from "actions/auth";
import { links } from "@spring/constants";
import Meowth from "@spring/meowth";

const MessengerWidget = "messenger";
const ClassicWidget = "classic";

export const loadZendesk = () => {
  return new Promise((resolve, reject) => {
    try {
      if (process.env.ZENDESK_KEY) {
        // Check if the script already exists
        if (!document.getElementById("ze-snippet")) {
          const zeSnippetScript = document.createElement("script");
          zeSnippetScript.id = "ze-snippet";
          zeSnippetScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_KEY}`;
          document.body.appendChild(zeSnippetScript);

          zeSnippetScript.onload = () => {
            waitForZendeskWidget(resolve, reject);
          };

          zeSnippetScript.onerror = () => {
            reject(new Error("Failed to load Zendesk script"));
          };
        } else if (
          !(
            window.zE &&
            (window.zE.setLocale || window.zE.widget === "messenger")
          )
        ) {
          // The script exists but widget might not be ready, this can also happen when the chat window is opened
          // and closed.  When the user goes to open it a subsequent time, the widget is not ready and the chat window
          // doesn't show.  So we need to wait for the widget to be ready again.
          waitForZendeskWidget(resolve, reject);
        } else {
          // The widget is already loaded and ready
          resolve();
        }
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const waitForZendeskWidget = (resolve, reject) => {
  let attempts = 0;
  const checkZendeskWidget = setInterval(() => {
    attempts++;

    if (
      window.zE &&
      (window.zE.setLocale || window.zE.widget === "messenger")
    ) {
      clearInterval(checkZendeskWidget);
      resolve();
    } else if (attempts >= 5) {
      clearInterval(checkZendeskWidget);
      openSupport();
      reject(
        new Error("[ZENDESK] - Timeout waiting for Zendesk widget to load"),
      );
    }
  }, 500);
};

//loads the default behavior once zendesk snippet has been loaded
export const zendeskBehavior = async () => {
  const appendScript = (id, content) => {
    if (!document.getElementById(id)) {
      const script = document.createElement("script");
      script.id = id;
      script.type = "text/javascript";
      script.innerHTML = content;
      document.body.appendChild(script);
    }
  };
  const localeScript = `
  window.zE('webWidget', 'setLocale', '${i18n.resolvedLanguage || "en"}');
  window.zE('webWidget:on', 'close', function() {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: { suppress: false },
        chat: { suppress: false }
      }
    });
  });
  window.zE(function() {
    zE.hide();
  });`;

  const settingsScript = `
  window.zESettings = {
    webWidget: {
      contactForm: {},
      contactOptions: {
        enabled: true
      },
      connectOnPageLoad: false,
      launcher: {
        mobile: {
          labelVisible: true
        }
      }
    }
  };`;

  const messengerScript = `
  zE('messenger', 'hide');
  window.zE('messenger:on', 'close', function() {
    window.zESpringIsOpen = false;
    zE('messenger', 'hide');
  })`;

  const executeBehaviorBasedOnWidget = () => {
    try {
      if (window.zE?.setLocale) {
        appendScript("zdClassicLocale", localeScript);
        appendScript("zdClassicSettings", settingsScript);
      } else if (window.zE?.widget === "messenger") {
        appendScript("zdMessengerBehaviorScript", messengerScript);
      } else {
        // eslint-disable-next-line no-console
        console.info(
          "[ZENDESK] - In zendeskBehavior did not hit either zd widget condition",
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("[ZENDESK] - error in executeBehaviorBasedOnWidget", e);
    }
  };

  executeBehaviorBasedOnWidget();
};

export const determineWidgetType = () => {
  if (window.zE) {
    if (window.zE.widget === MessengerWidget) {
      return MessengerWidget;
    } else if (window.zE.setLocale) {
      return ClassicWidget;
    }
    throw new Error("[ZENDESK]- Unable to determine widget type");
  }
  throw new Error("window.zE is undefined");
};

export const openChat = async () => {
  try {
    if (typeof window !== "undefined") {
      const widgetType = determineWidgetType();

      if (widgetType === MessengerWidget) {
        window.zESpringIsOpen = true;
        window.zE("messenger", "show");
        window.zE("messenger", "open");
      } else if (widgetType === ClassicWidget) {
        allowAllComms();

        const avatarUrl = links.ZendeskAvatarUrl;

        window.zE("webWidget", "updateSettings", {
          webWidget: {
            answerBot: {
              avatar: {
                url: avatarUrl,
                name: { "*": "Spring Health Support" },
              },
              title: { "en-US": "Spring Health Support" },
            },
            chat: {
              concierge: {
                avatarPath: avatarUrl,
                name: "Spring Health Support",
              },
              title: { "en-US": "Spring Health Support" },
            },
          },
        });

        window.zE("webWidget", "open");
        window.zE.activate({ hideOnClose: true });
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[ZENDESK] - error in openChat", e);
  }
};

export async function zendeskIdentify() {
  try {
    if (typeof window !== "undefined" && window.zE) {
      const widgetType = determineWidgetType();
      let userData;
      const userId = Meowth.getUserId();
      try {
        if (!userId) return;
        userData = await getIdentifiers();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("[Zendesk] - Error fetching user data: ", error);
        return;
      }

      if (!userData || !userData.user) {
        // eslint-disable-next-line no-console
        console.warn("[Zendesk] - User data is not available");
        return;
      }

      const { first_name, last_name, email, phone, member, zendesk_jwt } =
        userData.user;
      const fullName = first_name + " " + last_name;

      if (widgetType === MessengerWidget) {
        if (zendesk_jwt) {
          window.zE("messenger", "loginUser", (callback) =>
            callback(zendesk_jwt),
          );
        }
        //ensures CS support has email and other info for member
        await createOrUpdateZendeskUser();
      } else if (widgetType === ClassicWidget) {
        const organization = member?.cohort?.customer?.name || "";
        window.zE("webWidget", "prefill", {
          name: { value: fullName },
          email: { value: email },
          phone: { value: phone || "" },
        });
        window.zE("webWidget", "identify", {
          name: fullName,
          email: email,
          organization: organization,
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.info("window.zE is not available");
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[ZENDESK] - error in zendeskIdentify", e);
  }
}

export async function createOrUpdateZendeskUser() {
  try {
    const userId = Meowth.getUserId();
    const accessToken = localStorage.getItem("access_token");

    if (!userId || !accessToken) return;

    const bodyData = JSON.stringify({ id: userId });

    const response = await fetch(`${API_BASE}/zendesk/update_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: bodyData,
    });

    if (!response.ok) {
      throw new Error("Network error trying to update user for Zendesk");
    }

    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in createOrUpdateZendeskUser:", error);
  }
}

i18n.on("languageChanged", (lng) => {
  try {
    if (typeof window !== "undefined") {
      if (!window.zE) return;
      const widgetType = determineWidgetType();
      if (widgetType === MessengerWidget) {
        window.zE("messenger:set", "locale", lng);
      } else if (widgetType === ClassicWidget) {
        window.zE.setLocale(lng);
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[ZENDESK] - error in languageChanged", e);
  }
});

function allowAllComms() {
  try {
    if (typeof window !== "undefined") {
      if (!window.zE || window.zE.widget === MessengerWidget) return;

      window.zE("webWidget", "updateSettings", {
        webWidget: {
          contactForm: {
            suppress: false,
          },
          chat: {
            suppress: false,
          },
        },
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[ZENDESK] - error in allowAllComms", e);
  }
}

export async function openSupport(shouldAuthHelpCenter) {
  try {
    const userId = localStorage?.getItem("uuid");
    await createOrUpdateZendeskUser();
    const isProduction = process.env.APP_ENV === "production";
    const domain = isProduction ? "spring" : "spring1678223177";
    const returnUrl = isProduction
      ? links.CareSupportCenterProductionLink
      : `${links.CareSupportCenterLowerEnvLink}`;

    if (userId && shouldAuthHelpCenter) {
      const zendeskData = await getZendeskJwtToken(userId);
      const zendeskSsoJwt = zendeskData?.user?.zendesk_sso_help_center_jwt;

      //send to help center home authenticated to allow for article segmentation
      const url = `https://${domain}.zendesk.com/access/jwt?jwt=${encodeURIComponent(zendeskSsoJwt)}&return_to=${encodeURIComponent(`${returnUrl}`)}`;
      window.open(url, "_blank");
    } else {
      //send to help center home unauthenticated
      window.open(returnUrl, "_blank");
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[ZENDESK] - error in openSupport", e);
  }
}
