//

import {
  START_LOADING_PROGRESS,
  SET_LOADING_PROGRESS,
  RESET_LOADING_PROGRESS,
  END_LOADING_PROGRESS,
  START_ROUTING,
  END_ROUTING,
} from "./types";

/**
 *
 * Actions.
 *
 */

export function startLoadingProgress() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_LOADING_PROGRESS,
    });
    dispatch({
      type: START_LOADING_PROGRESS,
    });

    setLoadingProgress(50)(dispatch, getState);
  };
}

export function endLoadingProgress() {
  return (dispatch) => {
    dispatch({
      type: END_LOADING_PROGRESS,
    });

    window.setTimeout(() => {
      dispatch({
        type: RESET_LOADING_PROGRESS,
      });
    }, 500);
  };
}

export function setLoadingProgress(progress) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LOADING_PROGRESS,
      progress,
    });

    window.setTimeout(() => {
      let { progress, active } = getState().app.loading;

      if (progress < 90 && active) {
        setLoadingProgress(progress + 20)(dispatch, getState);
      }
    }, 1500);
  };
}

export function resetLoadingProgress() {
  return {
    type: RESET_LOADING_PROGRESS,
  };
}

export function startRouting() {
  return {
    type: START_ROUTING,
  };
}

export function endRouting() {
  return {
    type: END_ROUTING,
  };
}
