/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { get } from "lodash/fp";
import { useQuery, useMutation } from "@apollo/client";
import { FadeIn } from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import Meowth from "@spring/meowth";
import { countries } from "@spring/constants";

import { WelcomeForm } from "./Forms";

import { createCoveredLife } from "operations/mutations/user";
import { createPotentialCoveredLife } from "operations/mutations/potentialCoveredLife";
import { getMemberCohort } from "operations/queries/member";
import { getFirstError } from "utils/apollo/errorHandler";
import { track, time_event, EVENT_TYPE, makeEventString } from "utils/mixpanel";
import routes from "routes";
import { getIterableCampaignInfo } from "utils/localStorage";

const mpPrefix = "Invite Dependents";

const DependentInviteForm = ({ addNotification, redirect }) => {
  const router = useRouter();

  const { data: userData, loading: userLoading } = useQuery(getMemberCohort, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const [createAdult, { adultLoading }] = useMutation(createCoveredLife, {
    onError: (err) => addNotification(getFirstError(err), "error"),
  });

  const [createChild, { childLoading }] = useMutation(
    createPotentialCoveredLife,
    {
      onError: (err) => addNotification(getFirstError(err), "error"),
    },
  );

  // component did mount
  useEffect(() => {
    track(`${mpPrefix} -- Start`, {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
        page: routes.InviteDependents.as,
      }),
    });
    //Time event does not trigger an event and since these events are not actually triggered later, we do not need these time_event calls.
    time_event(`${mpPrefix} -- Done Clicked`);
    time_event(`${mpPrefix} -- Skip Clicked`);
    time_event(`${mpPrefix} -- Return to Dashboard Clicked`);
  }, []);

  useEffect(() => {
    if (userData && !get("user.member.cohort.dependents_allowed", userData)) {
      router.push(routes.MemberHome.to, routes.MemberHome.as);
    }
  }, [userData]);

  const createCL = async (formData, is_minor = false) => {
    const {
      first_name,
      last_name,
      city,
      state,
      email,
      phone,
      date_of_birth,
      relationship,
      additional,
      country,
    } = formData;
    const countryEntry =
      countries.find((entry) => entry.iso2 === phone.code) || {};
    const dialCode = countryEntry.dialCode;

    if (is_minor) {
      return await createChild({
        variables: {
          input: {
            first_name,
            last_name,
            is_minor: true,
            city,
            state,
            country,
            metadata: JSON.stringify({ relationship, additional }),
          },
        },
      });
    }

    return await createAdult({
      variables: {
        input: {
          first_name,
          last_name,
          dependent: true, // required
          date_of_birth: date_of_birth.actualDate,
          city,
          state,
          country,
          corporate_email: email,
          phone_number: `+${dialCode + phone.number}`,
          campaign: getIterableCampaignInfo(),
        },
      },
    });
  };

  if (userLoading || !get("user.member.cohort.dependents_allowed", userData)) {
    return null;
  }

  return (
    <FadeIn duration=".7s">
      <WelcomeForm
        key="welcome"
        create={createCL}
        loading={adultLoading || childLoading}
        email={get("user.member.email", userData)}
        redirect={redirect}
      />
    </FadeIn>
  );
};

DependentInviteForm.propTypes = {
  addNotification: PropTypes.func,
  redirect: PropTypes.any,
};

export { DependentInviteForm };
export default connect(null, { addNotification })(DependentInviteForm);
