import React from 'react';

const PersonalizedSuggestionIcon = () => (
	<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
		<path
			d="M11.062 2.535c.322-.871 1.554-.871 1.876 0l2.303 6.224 6.224 2.303c.871.322.871 1.554 0 1.876l-6.224 2.303-2.303 6.224c-.322.871-1.554.871-1.876 0L8.76 15.241l-6.224-2.303c-.871-.322-.871-1.554 0-1.876L8.759 8.76l2.303-6.224z"
			stroke="#F2B145"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M20 1l.81 2.19L23 4l-2.19.81L20 7l-.81-2.19L17 4l2.19-.81L20 1z" fill="#F2B145"/>
		<circle cx="3.5" cy="20.5" r="1.5" fill="#F2B145"/>
	</svg>
);

export default PersonalizedSuggestionIcon;
