import { gql } from "@apollo/client";

const question = gql`
  fragment question on Question {
    id
    position
    key
    title
    subtitle
    newTitle
    newSubtitle
    required
    element {
      id
      kind
      label
      options {
        label
        value
      }
      min
      max
      step
      markers
      placeholder
      left_label
      right_label
      appetite_options {
        left {
          label
          value
        }
        right {
          label
          value
        }
        top {
          label
          value
        }
      }
      show_conditions {
        dependent_element_id
        score
        operator
        field_key
      }
    }
  }
`;

export default question;
