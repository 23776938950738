import { CloseButton, useMediaQuery } from "@chakra-ui/react";
import { mobileAndTabletCheck } from "utils/displayHelpers";
import { useVideoPlayer } from "modules/shared/components/VideoPlayer/VideoPlayer/context/videoPlayerContext";

export const VideoMomentModalOverlayCloseButton = ({
  handleCloseButtonOnClick,
}) => {
  const { isPlaying, setIsPlaying } = useVideoPlayer();
  const [isLandscape] = useMediaQuery("(orientation: landscape)");
  const [isMobile] = useMediaQuery("(max-width:600px)");

  const isMobileOrTabletDevice = mobileAndTabletCheck();

  const handleClick = () => {
    handleCloseButtonOnClick();
    if (isPlaying) {
      setIsPlaying(false);
    }
  };

  return isMobile || (isMobileOrTabletDevice && isLandscape) ? (
    <CloseButton
      position="absolute"
      top={isMobileOrTabletDevice && isLandscape ? "v-64" : "v-32"}
      left="8px"
      size="lg"
      color="white"
      onClick={handleClick}
      zIndex="overlay"
    />
  ) : (
    <CloseButton
      size="lg"
      onClick={handleClick}
      alignSelf="end"
      color="#068262"
    />
  );
};
