//
import { gql } from "@apollo/client";

const requestAppointment = gql`
  mutation RequestAppointment($input: RequestInput!) {
    requestAppointment(input: $input) {
      success
    }
  }
`;

export default requestAppointment;
