import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Floaty } from "@spring/smeargle";
import { Call, Mobile, SMS } from "design-system/assets";
import { Box, Flex, Heading, Text } from "design-system/components";
import { useTranslation } from "react-i18next";
import { EnableMfaHeading, MfaContext } from "components";

const mfaOptions = {
  sms: {
    ariaLabel: "Setup via SMS Text Message",
    linkAlias: "MemberSetupMfaSMS",
    icon: <SMS color="black" w={18} h={18} />,
    title: "optionSmsTitle",
    subtitle: "optionSmsDescription",
  },
  voice: {
    ariaLabel: "Setup via Voice Over Phone",
    linkAlias: "MemberSetupMfaVoice",
    icon: <Call color="black" w={18} h={18} />,
    title: "optionVoiceTitle",
    subtitle: "optionVoiceDescription",
  },
  app: {
    ariaLabel: "Setup via Authenticator App",
    linkAlias: "MemberSetupMfaApp",
    icon: <Mobile color="black" w={18} h={18} />,
    title: "optionAppTitle",
    subtitle: "optionAppDescription",
  },
};

const MfaOptionCard = ({ mfaOption, onClick }) => {
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });

  const { ariaLabel, icon, title, subtitle } = mfaOptions[mfaOption];

  return (
    <section>
      <Box
        data-testid={`mfaOptionCard-${mfaOption}`}
        role="button"
        aria-label={ariaLabel}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            onClick();
          }
        }}
      >
        <Floaty float={0} hover={1} radius={3}>
          <Box
            px={24}
            py={32}
            boxShadow="md"
            borderWidth="1px"
            borderRadius="base"
          >
            {icon}
            <Heading variant="lg" color="primary.700">
              {t(title)}
            </Heading>
            <Text variant="body1" color="platform.base">
              {t(subtitle)}
            </Text>
          </Box>
        </Floaty>
      </Box>
    </section>
  );
};

MfaOptionCard.propTypes = {
  mfaOption: PropTypes.oneOf(["sms", "voice", "app"]).isRequired,
  onClick: PropTypes.func.isRequired,
};

const MfaOptionsPage = () => {
  const { setPage } = useContext(MfaContext);

  return (
    <>
      <EnableMfaHeading backButtonAlias="MemberSettings" mfaOption="default" />
      <Flex flexDirection="column" gap={16}>
        {Object.keys(mfaOptions).map((mfaOption) => (
          <MfaOptionCard
            key={mfaOption}
            mfaOption={mfaOption}
            onClick={() => setPage(mfaOption)}
          />
        ))}
      </Flex>
    </>
  );
};

export default MfaOptionsPage;
