import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Router from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";

import { ActionChip } from "@springcare/sh-component-library";
import { Text } from "@chakra-ui/react";
import { VHelpIcon } from "@springcare/verdant-icons-react";

const HelpButton = ({ setOnOpen, isAT2Member = false }) => {
  const { t } = useTranslation("limitedLangMemberHelpCard");

  const openHelpFlyout = () => {
    setOnOpen();
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Help Button Clicked", {});
  };

  const at2MemberProps = {
    _active: { textColor: "content-primary" },
    _focus: { textColor: "content-primary" },
    _focusVisible: {
      textColor: "primary-base",
      bg: "transparent",
      boxShadow: "var(--chakra-shadows-focus-visible)",
    },
    borderRadius: "30px",
    color: "content-primary",
  };

  return (
    <ActionChip
      variant="low-emphasis"
      colorScheme="base"
      icon={VHelpIcon}
      onClick={openHelpFlyout}
      data-cy={"help-button"}
      {...(isAT2Member ? at2MemberProps : {})}
    >
      <Text size={isAT2Member ? "body-medium-regular" : "body-medium-strong"}>
        {t("helpButtonText")}
      </Text>
    </ActionChip>
  );
};

HelpButton.propTypes = {
  setOnOpen: PropTypes.func,
};

export default HelpButton;
