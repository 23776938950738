import { gql } from "@apollo/client";

const money = gql`
  fragment money on Money {
    amount
    currency
  }
`;

export default money;
