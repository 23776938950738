import { useTranslation } from "react-i18next";
import { Box, Text, Heading } from "@chakra-ui/react";
import { Map } from "components/templates/CareVisitDetails/components/AppointmentLocation";
import { CareProvider, Member } from "modules/shared/graphql-codegen/graphql";

interface InPersonMapProps {
  careProvider: CareProvider;
  member: Member;
  borderStyle?: string;
}

interface AddressInput {
  street_address_1?: string;
  street_address_2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  country?: string;
}

const InPersonMap = ({
  careProvider,
  member,
  borderStyle,
}: InPersonMapProps) => {
  const { t } = useTranslation("careProvider");

  const formatAddress = (address: AddressInput, omitLine2 = false) => {
    const addressComponents = [
      address.street_address_1,
      address.street_address_2,
      address.city,
      address.state,
      address.zip_code,
      address.country,
    ];

    if (!omitLine2) {
      addressComponents.splice(1, 1);
    }

    return addressComponents.filter((item) => item != null).join(", ");
  };

  const formatProviderAddress = (omitLine2 = false) => {
    return formatAddress(careProvider, omitLine2);
  };

  const formatMemberAddress = (omitLine2 = false) => {
    const memberAddress = member?.postal_address;
    return formatAddress(memberAddress, omitLine2);
  };

  return (
    <Box borderTop={borderStyle} width="100%" flex="100%" padding="16px 0 24px">
      <Heading size="heading-small" color="content-primary" pb={16}>
        {t("forInPersonSessions")}
      </Heading>
      <Map
        style={{
          height: "152px",
          borderRadius: "8px",
          marginBottom: "16px",
        }}
        locations={[formatProviderAddress(true)]}
      />
      <Box position={"relative"}>
        <Text size="body-medium-regular" color="content-primary">
          {formatProviderAddress()}
        </Text>
        <Text
          size="body-medium-regular"
          color="content-secondary"
          as="span"
          pr={8}
        >
          {t("distanceAway", {
            milesAway: careProvider.distance_miles,
          })}
        </Text>
        <Text
          size="body-medium-strong"
          color="primary-base"
          as="span"
          cursor="pointer"
          onClick={() => {
            const googleMapURL = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(formatMemberAddress(true))}&destination=${encodeURIComponent(formatProviderAddress(true))}`;

            window.open(googleMapURL, "_blank", "noopener noreferrer");
          }}
        >
          {t("getDirections")}
        </Text>
      </Box>
    </Box>
  );
};

export default InPersonMap;
