import { SHOutlinedCircleCheckBox } from "design-system/components";
import { setField } from "actions/assessment/actions";
import { SpringFormState } from "components/assessments/types";
import { useDispatch, useSelector } from "react-redux";

type OptionProps = {
  label: string;
  value: string;
  _typeName: string;
};

type SingleSelectProps = {
  dataCy: string;
  fieldKey: string;
  formKey: string;
  options: OptionProps[];
  advance: Function;
};

const SingleSelect = ({
  fieldKey,
  formKey,
  options,
  dataCy,
  advance,
}: SingleSelectProps) => {
  const answer = useSelector((state: SpringFormState) => {
    return state.form[formKey]?.data[fieldKey];
  });

  const dispatch = useDispatch();

  const handleChange = (val) => {
    if (answer === val) {
      advance();
    } else {
      dispatch(setField(formKey, fieldKey, val, "string"));
    }
  };

  return (
    <>
      {options.map((option, index) => (
        <SHOutlinedCircleCheckBox
          label={option.label}
          key={option.value}
          fieldKey={option.value}
          onChange={() => handleChange(option.value)}
          checked={answer === option.value}
          dataCy={dataCy ? `${dataCy}-option-${index}-radio-button` : ""}
        />
      ))}
    </>
  );
};

export { SingleSelect };
