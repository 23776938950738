import { Box, Heading, Text } from "@chakra-ui/react";

export const ConsentFaqModalContent = () => {
  return (
    <Box>
      <Heading size={"sm"} fontWeight={"bold"} pb={16}>
        Why do I need to request consent for recording therapy sessions?
      </Heading>
      <Text>
        It’s essential to obtain explicit consent from your clients before
        recording any therapy session to respect their privacy and ensure
        compliance with legal and ethical guidelines. Consent ensures clients
        understand how the recording will be used and allows them to make an
        informed decision about their participation.
      </Text>
      <Heading size={"sm"} fontWeight={"bold"} pb={16} pt={16}>
        What is the purpose of recording therapy sessions?
      </Heading>
      <Text>
        Recordings are primarily used to assist with documentation, improve
        accuracy in note-taking, and enhance the overall quality of care. In
        some cases, recordings are also used for quality assurance purposes,
        such as ensuring adherence to professional standards, improving the
        therapist’s practice, or for clinical supervision.
      </Text>
      <Heading size={"sm"} fontWeight={"bold"} pb={16} pt={16}>
        How do I explain the purpose of the recording to my client?
      </Heading>
      <Text pb={16}>
        When requesting consent, it’s important to clearly explain why the
        session is being recorded. You can say something like:
      </Text>
      <Text>
        "I would like to record our session today to help with accurate
        note-taking and for quality assurance purposes. This allows me to review
        the session to ensure I’m providing you with the best possible care. The
        recording will be kept confidential and used only for professional
        purposes."
      </Text>
      <Heading size={"sm"} fontWeight={"bold"} pb={16} pt={16}>
        What are quality assurance purposes?
      </Heading>
      <Text>
        Quality assurance purposes involve using session recordings to monitor
        and improve the quality of therapeutic services. This may include
        reviewing sessions for supervision, training, or performance evaluation
        to ensure that therapists are providing high-quality, ethical care. It’s
        also a way to ensure that client progress is tracked effectively and
        that any issues in service delivery are identified and addressed.
      </Text>
      <Heading size={"sm"} fontWeight={"bold"} pb={16} pt={16}>
        Will the recordings be shared with anyone else?
      </Heading>
      <Text>
        Recordings may only be shared with authorized professionals such as
        supervisors or clinical team members involved in quality assurance or
        supervision, and only if necessary for those purposes. In such cases,
        all individuals with access to the recording will be bound by
        confidentiality agreements. It’s crucial to reassure clients that their
        privacy will be maintained at all times.
      </Text>
      <Heading size={"sm"} fontWeight={"bold"} pb={16} pt={16}>
        How do I ensure the recording remains confidential?
      </Heading>
      <Text>
        Recordings are stored securely and encrypted to protect client
        confidentiality. Only authorized personnel, such as the therapist or a
        supervisor (if applicable), will have access to them. Additionally,
        recordings are typically destroyed after they’ve served their purpose,
        in accordance with HIPAA regulations or other relevant laws.
      </Text>
      <Heading size={"sm"} fontWeight={"bold"} pb={16} pt={16}>
        Can a client decline to have their session recorded?
      </Heading>
      <Text>
        Yes. Clients have the right to decline recording at any time, and their
        decision will not affect the care they receive. If a client chooses not
        to be recorded, it’s important to respect their choice and proceed with
        the session without recording.
      </Text>
      <Heading size={"sm"} fontWeight={"bold"} pb={16} pt={16}>
        Can a client decline to have their session recorded?
      </Heading>
      <Text>
        If a client is uncertain, take the time to address their concerns. You
        can explain how the recording will be used, emphasize the
        confidentiality and security measures in place, and reiterate that they
        can withdraw consent at any time. It’s important that they feel
        comfortable and informed before agreeing.
      </Text>
    </Box>
  );
};
