import React, { useState } from "react";
import { IconButton, Tooltip } from "@springcare/sh-component-library";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
} from "@chakra-ui/react";
import {
  VLogOutIcon,
  VArrowRightIcon,
  VXIcon,
} from "@springcare/verdant-icons-react";
import { useLocalParticipant } from "@livekit/components-react";
import { useLazyQuery } from "@apollo/client";
import { logSessionEndForAllError } from "components/templates/SHSessionRoom/telemetry/datadog";
import { sessionEndForAll } from "operations/queries/appointment";
import {
  useSessionViewStatus,
  useSessionUserActive,
  useSessionConnectStatus,
  useParticipantMetadata,
} from "context/SessionRoomContext";

import {
  ParticipantType,
  SessionViewStatus,
} from "components/templates/SHSessionRoom/types";

import {
  trackExitMenuOpened,
  trackExitSessionClick,
} from "components/templates/SHSessionRoom/telemetry/analytics";

import { releaseKeepActive } from "components/templates/SHSessionRoom/utils/keepMemberLoggedIn";

import { useTranslation } from "hooks/react-i18next";

export const ExitButton = () => {
  const { setSessionViewStatus } = useSessionViewStatus();
  const { wakeLock, setWakeLock, isLoggedIn } = useSessionUserActive();
  const { handleDisconnect } = useSessionConnectStatus();
  const { participantType, appointmentId, participantId } =
    useParticipantMetadata();
  const { localParticipant } = useLocalParticipant();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.exitButton",
  });
  const leaveLabel = t("leave");
  const leaveSessionLabel = t("leaveSession");
  const endSessionForAllLabel = t("endSessionForAll");
  const handleOpenExitMenu = () => {
    setIsOpen(!isOpen);
    trackExitMenuOpened(!isOpen);
  };

  const handleExitSession = (type: string) => {
    trackExitSessionClick(type);
    if (isLoggedIn) {
      releaseKeepActive(wakeLock, setWakeLock);
    }
    handleDisconnect();
    setSessionViewStatus(SessionViewStatus.Ended);
  };

  const [loadSessionEndForAll, { error }] = useLazyQuery(sessionEndForAll);
  if (error) {
    logSessionEndForAllError(error, appointmentId, participantId);
  }

  const endSessionForAll = async (participantId) => {
    setIsLoading(true);
    try {
      await loadSessionEndForAll({
        variables: {
          appointmentId,
          participantId,
        },
      });
      if (isLoggedIn) {
        releaseKeepActive(wakeLock, setWakeLock);
      }
      setSessionViewStatus(SessionViewStatus.Ended);
    } catch (error) {
      logSessionEndForAllError(error, appointmentId, participantId);
    } finally {
      setIsLoading(false);
    }
    trackExitSessionClick("end_session");
  };

  return (
    <Menu>
      <Tooltip label={leaveLabel} aria-label={leaveLabel}>
        <MenuButton
          as={IconButton}
          icon={VLogOutIcon}
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label={
            participantType === ParticipantType.Provider
              ? t("optionsToLeaveOrEnd")
              : t("optionsToLeaveOrStay")
          }
          onClick={handleOpenExitMenu}
          variant="high-emphasis"
          colorScheme="negative"
          _focus={{}} // handle styling issues that probably need to be fixed in the component-library
        >
          {t("exit")}
        </MenuButton>
      </Tooltip>
      <MenuList zIndex={10}>
        <MenuItem
          icon={<VArrowRightIcon />}
          onClick={() => handleExitSession("leave")}
          aria-label={leaveSessionLabel}
          _focus={{ outline: "0px solid transparent !important" }}
          _focusVisible={{ outline: "2px solid black !important" }}
          _hover={{ bg: "hover-base-low-emphasis" }}
        >
          {leaveSessionLabel}
        </MenuItem>
        {participantType === ParticipantType.Provider && (
          <MenuItem
            icon={isLoading ? <Spinner speed="1s" size="xs" /> : <VXIcon />}
            onClick={() => endSessionForAll(localParticipant?.identity)}
            aria-label={endSessionForAllLabel}
            _focus={{ outline: "0px solid transparent !important" }}
            _focusVisible={{ outline: "2px solid black !important" }}
            _hover={{ bg: "hover-base-low-emphasis" }}
          >
            {endSessionForAllLabel}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default ExitButton;

ExitButton.displayName = "ExitButton";
