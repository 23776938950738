import { useTranslation } from "react-i18next";
import { ModalSheet } from "@springcare/sh-component-library";
import { Heading, Text } from "@chakra-ui/react";
import { Box } from "design-system/components";
import { PersonalizedCare } from "design-system/assets";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const GenAIDisclosureModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation(["careProvider"]);

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      placement="center"
      footer="on"
      footerVariant="high-emphasis"
      footerActions={[
        {
          label: t(
            "recommendedProviders.providerCardGenAI.disclosureModal.closeBtn",
          ),
          onClick: () => onClose(),
        },
      ]}
    >
      <Box pt={32} pb={32}>
        <PersonalizedCare width={80} height={80} />
        <Heading size="heading-medium" my={16}>
          Your personalized recommendations
        </Heading>

        <Text size="body-medium" mb={16}>
          {t(
            "recommendedProviders.providerCardGenAI.disclosureModal.sentence1",
          )}
        </Text>
        <Text size="body-medium" mb={16}>
          {t(
            "recommendedProviders.providerCardGenAI.disclosureModal.sentence2",
          )}
        </Text>
        <Text size="body-medium">
          {t(
            "recommendedProviders.providerCardGenAI.disclosureModal.sentence3",
          )}
        </Text>
      </Box>
    </ModalSheet>
  );
};

export default GenAIDisclosureModal;
