import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { logSessionEndForAllError } from "components/templates/SHSessionRoom/telemetry/datadog";
import { sessionEndForAll } from "operations/queries/appointment";
import {
  useSessionViewStatus,
  useSessionUserActive,
  useParticipantMetadata,
} from "context/SessionRoomContext";

import { SessionViewStatus } from "components/templates/SHSessionRoom/types";

import { trackExitSessionVerificationClick } from "components/templates/SHSessionRoom/telemetry/analytics";

import { releaseKeepActive } from "components/templates/SHSessionRoom/utils/keepMemberLoggedIn";

export const useEndSessionForAll = () => {
  const { setSessionViewStatus } = useSessionViewStatus();
  const { wakeLock, setWakeLock, isLoggedIn } = useSessionUserActive();
  const { appointmentId, participantId } = useParticipantMetadata();
  const [isLoading, setIsLoading] = useState(false);

  const [loadSessionEndForAll, { error }] = useLazyQuery(sessionEndForAll);
  if (error) {
    logSessionEndForAllError(error, appointmentId, participantId);
  }

  const endSessionForAll = async (participantId) => {
    setIsLoading(true);
    try {
      await loadSessionEndForAll({
        variables: {
          appointmentId,
          participantId,
        },
      });
      if (isLoggedIn) {
        releaseKeepActive(wakeLock, setWakeLock);
      }
      setSessionViewStatus(SessionViewStatus.Ended);
    } catch (error) {
      logSessionEndForAllError(error, appointmentId, participantId);
    } finally {
      setIsLoading(false);
    }
    trackExitSessionVerificationClick();
  };

  return { endSessionForAll, isLoading };
};
