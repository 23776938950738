import React, { useEffect } from "react";
import { Box, useMediaQuery, Text, Heading } from "@chakra-ui/react";
import { LottieAnimation } from "design-system/assets";
import { useTranslation } from "react-i18next";

type PersonalizedResultsScreenProps = {
  handleSubmit: () => void;
};

export const PersonalizedResultsScreen = ({
  handleSubmit,
}: PersonalizedResultsScreenProps) => {
  const { t } = useTranslation("assessments");
  const [isMobile] = useMediaQuery("(max-width: 450px)");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSubmit();
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Box
      mt={174}
      mx={[24, "auto"]}
      w={isMobile ? "90%" : ["auto", "405px"]}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Box>
        <LottieAnimation
          height={80}
          width={80}
          animationName="heart-puzzle-loading"
        />
      </Box>

      <Box mt={24} ms={8}>
        <Text data-testid="subtitle">
          {" "}
          {t("personalizedResultsScreen.subtitle")}{" "}
        </Text>
      </Box>

      <Heading
        size="heading-medium"
        tabIndex={0}
        mt={8}
        ms={8}
        maxW={!isMobile && 300}
        data-testid="title"
      >
        {t("personalizedResultsScreen.title")}
      </Heading>
    </Box>
  );
};
