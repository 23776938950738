import { isCareNavigation, isCoaching } from "@spring/constants";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import { getAppointment } from "operations/queries/appointment";
import { getCareProvider } from "operations/queries/careProvider";

const getCancelModalText = (kind) => {
  // Default text
  let cancelModalText = {
    careful: "cancelAppointment.careful",
    cancel: "cancelAppointment.cancel",
    keepButtonText: "cancelAppointment.buttonText",
    cancelButtonText: "cancelAppointment.cancelButtonText",
    cancellationFee: "cancelAppointment.cancellationFee",
    cancelNotification: "cancelAppointment.notificationMessage.success",
  };

  if (isCareNavigation(kind)) {
    cancelModalText.careful = "cancelAppointment.careNavigation.careful";
    cancelModalText.cancel = "cancelAppointment.careNavigation.cancel";
    cancelModalText.keepButtonText =
      "cancelAppointment.careNavigation.keepButtonText";
    cancelModalText.cancelButtonText =
      "cancelAppointment.careNavigation.cancelButtonText";

    return cancelModalText;
  }

  if (isCoaching(kind)) {
    cancelModalText.cancel = "cancelAppointment.coachingCancel";
    cancelModalText.keepButtonText = "cancelAppointment.coachingButtonText";
    cancelModalText.cancelButtonText =
      "cancelAppointment.cancelCoachingButtonText";
    cancelModalText.cancelNotification =
      "cancelAppointment.notificationMessage.coachingSuccess";

    return cancelModalText;
  }

  return cancelModalText;
};

const useCancelAppointment = () => {
  const router = useRouter();
  const { data: appointmentData, loading: appointmentLoading } = useQuery(
    getAppointment,
    {
      variables: { id: router && router.query.id },
      skip: !router,
    },
  );
  const { data: providerData, loading: providerLoading } = useQuery(
    getCareProvider,
    {
      variables: {
        id: appointmentData && appointmentData.appointment.provider_id,
      },
      skip: !appointmentData,
    },
  );

  return {
    data: { ...appointmentData, ...providerData },
    loading: appointmentLoading || providerLoading,
  };
};

export { getCancelModalText, useCancelAppointment };
