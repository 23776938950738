import { gql } from "@apollo/client";

const updatePotentialCoveredLife = gql`
  mutation updatePotentialCoveredLife(
    $id: ID!
    $input: PotentialCoveredLifeInput!
    $send_notification: Boolean
  ) {
    updatePotentialCoveredLife(
      id: $id
      input: $input
      send_notification: $send_notification
    ) {
      success
      potential_covered_life_id
    }
  }
`;

export default updatePotentialCoveredLife;
