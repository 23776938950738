import { gql } from "@apollo/client";

const getMemberPreferenceQuestions = gql`
  query getMemberPreferenceQuestions($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        care_preference_questionnaire {
          key
          label
          question_text
          component
          question_order
          options
          type_options
          slider_options {
            key
            title
            subtitle
            constraints
            left_label
            mid_label
            right_label
            component
          }
        }
      }
    }
  }
`;

export default getMemberPreferenceQuestions;
