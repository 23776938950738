import { useTeenAssessmentStatus } from "modules/MemberDashboard/Teen/Home/hooks";
import { isTeen } from "utils/memberHelpers";
import {
  isCoaching,
  isTherapy,
  assessments as ASSESSMENT_CONSTANTS,
} from "@spring/constants";

export const useRouteTeenToInitialAssessment = (member, appointmentKind) => {
  const { assessmentStatus: teenAssessmentStatus } =
    useTeenAssessmentStatus(!member);

  const isATeen = isTeen(member?.date_of_birth);

  const { assessmentStatuses } = ASSESSMENT_CONSTANTS;
  const teenAssessmentIsNotFinished =
    teenAssessmentStatus !== assessmentStatuses.FINISHED;
  const isATherapyAppointment = isTherapy(appointmentKind);
  const isCoachingAppointment = isCoaching(appointmentKind);
  const routeTeenToInitialAssessment =
    isATeen &&
    teenAssessmentIsNotFinished &&
    (isATherapyAppointment || isCoachingAppointment);

  return { routeTeenToInitialAssessment };
};
