import React from "react";

const GoogleSvgIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M10.4105 9.1V11.8333H17.1154C16.9101 13.3667 16.3859 14.4892 15.5871 15.2775C14.6062 16.2333 13.0788 17.2775 10.4105 17.2775C6.28239 17.2775 3.05567 14.0333 3.05567 10.0108C3.05567 5.98833 6.28239 2.74417 10.4105 2.74417C12.6341 2.74417 14.2649 3.6 15.4622 4.7L17.4352 2.7775C15.7701 1.2 13.5346 0 10.4105 0C4.75498 0 0 4.48917 0 10C0 15.5108 4.75498 20 10.4105 20C13.4662 20 15.7701 19.0225 17.5712 17.2C19.4185 15.4 20 12.8558 20 10.8108C20 10.1775 19.9547 9.58833 19.852 9.1H10.4105Z"
      fill="#110A09"
    />
  </svg>
);

export default GoogleSvgIcon;
