import React from "react";
import { Text, Link } from "design-system/components";
import { Trans } from "react-i18next";

export const DownloadMessage = ({ appointmentId, API_DOMAIN }) => {
  return (
    <Trans
      ns={"careVisits"}
      i18nKey="appointmentDetails.appointReminders.dowloadIcs"
    >
      <Text
        sx={{
          "&, &:hover, &:active, &:focus": {
            color: "primary-on-base",
          },
        }}
        fontSize="20"
        as={Link}
        fontWeight="700"
        href={`${API_DOMAIN}/event/members/${appointmentId}`}
      ></Text>
    </Trans>
  );
};
