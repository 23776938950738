import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Link } from "@springcare/sh-component-library";

export function SkipToContent() {
  const { t } = useTranslation("a11y");

  return (
    <nav aria-labelledby="skip-to-content-link">
      <Button
        as={Link}
        id="skip-to-content-link"
        // @ts-ignore
        href="#main-content"
        sx={{
          top: 0,
          position: "absolute",
          transform: "translateY(-100%)",
          transition: "transform 0.3s",
          clip: "rect(0 0 0 0)",
          clipPath: "inset(50%)",
          zIndex: 9999,

          "&:focus": {
            transform: "translateY(0%)",
            clip: "unset",
            clipPath: "unset",
          },
        }}
      >
        {t("skipToContent")}
      </Button>
    </nav>
  );
}
