import { useState, useMemo, useEffect } from "react";
import {
  useIsRecording,
  useRemoteParticipants,
} from "@livekit/components-react";
import { useLazyQuery } from "@apollo/client";
import { Button } from "@springcare/sh-component-library";
import {
  Box,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Text,
} from "@chakra-ui/react";
import { logSessionAdmitParticipantError } from "components/templates/SHSessionRoom/telemetry/datadog";
import { sessionAdmitParticipant } from "operations/queries/appointment";
import { trackAdmitParticipantClick } from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";
import {
  useParticipantMetadata,
  useSessionRecording,
} from "context/SessionRoomContext";
import { usePostStopRecording } from "components/templates/SHSessionRoom/hooks/usePostStopRecording";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const AdmitParticipantPopover = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.admitParticipantPopover",
  });
  const admitParticipantAriaLabel = t("admitParticipantAriaLabel");

  const isRecording = useIsRecording();

  const stopRecordingOnExceedingParticipantsEnabled = useFeatureFlag(
    FLAGS.ENABLE_STOP_RECORDINGS_ON_EXCEEDING_PARTICIPANTS,
  );

  const [isLoading, setIsLoading] = useState(false);
  const { remoteParticipantName, appointmentId, participantId } =
    useParticipantMetadata();
  const { setRecording } = useSessionRecording();

  const remoteParticipants = useRemoteParticipants();

  const waitingRoomParticipants = useMemo(
    () =>
      remoteParticipants.filter(
        (participant) => !participant.permissions?.canPublish,
      ),
    [remoteParticipants],
  );

  const popoverText = useMemo(() => {
    setIsLoading(false);
    if (waitingRoomParticipants.length === 1) {
      return `${remoteParticipantName ?? t("aParticipant")} ${t("isRequestingToJoin")}`;
    } else if (waitingRoomParticipants.length > 1) {
      return `${waitingRoomParticipants.length} ${t("participantsAreRequestingToJoin")}`;
    }
    return "";
  }, [waitingRoomParticipants]);

  const [loadSessionAdmitParticipant, { error }] = useLazyQuery(
    sessionAdmitParticipant,
  );

  if (error) {
    logSessionAdmitParticipantError(error, appointmentId, participantId);
  }

  // TODO: Q4-2024/Q1-2025 -- admit multiple participants with a single call
  const admitParticipant = async (identity) => {
    setIsLoading(true);
    try {
      await loadSessionAdmitParticipant({
        variables: {
          appointmentId,
          participantId: identity,
        },
      });
    } catch (error) {
      logSessionAdmitParticipantError(error, appointmentId, participantId);
    } finally {
      setIsLoading(false);
    }
    trackAdmitParticipantClick();
  };

  const stopRecording = usePostStopRecording();

  const hasOver2AdmittedParticipants = useMemo(
    () =>
      remoteParticipants.filter((p) => p.permissions?.canPublish).length > 1,
    [remoteParticipants],
  );

  useEffect(() => {
    if (
      stopRecordingOnExceedingParticipantsEnabled &&
      isRecording &&
      hasOver2AdmittedParticipants
    ) {
      stopRecording({
        appointment_id: appointmentId,
      });
      setRecording(false);
    }
  }, [hasOver2AdmittedParticipants]);

  return (
    <Popover
      //TODO: Ensure only provider can see and admit participants
      isOpen={waitingRoomParticipants.length > 0}
      placement="top"
    >
      <PopoverAnchor>
        <Box />
      </PopoverAnchor>
      <PopoverContent
        w="v-4xl"
        p="v-8"
        borderRadius="v-xl"
        marginBottom="v-8"
        aria-label={admitParticipantAriaLabel}
      >
        <Text textStyle="body-medium-regular" p="v-8" aria-live="polite">
          {popoverText}
        </Text>
        <Button
          w="100%"
          onClick={() => admitParticipant(waitingRoomParticipants[0]?.identity)}
          aria-label={admitParticipantAriaLabel}
          isLoading={isLoading}
        >
          {t("admit")}
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default AdmitParticipantPopover;

AdmitParticipantPopover.displayName = "AdmitParticipantPopover";
