//
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { get, getOr } from 'lodash/fp';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';

import { Icon } from '../../atoms';
import { FlexRow } from '../../layout';

import styles from './styles.module.scss';

import { closeModal } from '../../../actions/modal';


const Modal = (props) => {

	// We are aware of the useOutsideClick hook however for some reason it does not appear to function properly when used in this component therefore we have unhookified the function and left the hook for convenience.
	const handleClickEvent = (ref, callback, modalOpen) => {
		function handleOutsideClick(event) {
			if (ref?.current && !ref.current.contains(event.target) && modalOpen) {
				callback();
			}
		}
		document.addEventListener('mousedown', handleOutsideClick);
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	};

	const containerRef = useRef();
	const modalId = `modal-${props.id}`;

	const isChakraModalOpen = props.isChakraModalOpen || false;

	useEffect(() => {
		const close = (e) => {
			if (e.key === 'Escape') {
				props.closeModal(props.id);
				if (props.closeRedirect) {
					props.closeRedirect();
				}
			}
		};
		window.addEventListener('keydown', close);
		return () => window.removeEventListener('keydown', close);
	}, []);

	useEffect(() => {
		const root = document.querySelector('[data-reactroot]');

		if (props.open) {
			document.body.style.overflow = 'hidden';
			root && (root.ariaHidden = 'true');
		} else {
			document.body.style.overflow = 'unset';
			root && (root.ariaHidden = 'false');
		}
	}, [props.open]);

	const closeButton = () => {
		if (!props.dismissable) {
			return null;
		}

		return (
			<div className={styles.closeWrapper}>
				<FlexRow justification="flex-end" alignment="center">
					<div
						className={styles.close}
						onKeyDown={(e) => {
							if (e.code === 'Enter' && typeof props.closeModal === 'function') {
								props.closeModal(props.id);
								if (props.closeRedirect) {
									props.closeRedirect();
								}
							}
						}}
					>

						{props.hideModalCloseButton ? (
							null
						) : (
							<Icon
								type="close"
								onClick={() => {
									props.closeModal(props.id);
									if (props.closeRedirect) {
										props.closeRedirect();
									}
								}}
							/>
						)}
					</div>
				</FlexRow>
			</div>
		);
	};

	return (
		props.open ?
			createPortal(
				isChakraModalOpen ? (
					// Render without FocusTrap if Chakra modal is open
					<div
						className={classnames(styles.overlay, { [styles.open]: props.open })}
						onClick={() => {
							if (props.dismissable) {
								handleClickEvent(containerRef, () => {
									props.closeModal(props.id);
									if (props.closeRedirect) {
										props.closeRedirect();
									}
								}, props.open);
							}
						}}
						role="dialog"
						aria-label={!props.ariaLabeledBy ? props.title : undefined}
						aria-labelledby={props.ariaLabeledBy ? props.ariaLabeledBy : undefined}
						aria-modal="true"
					>
						<div id={modalId} tabIndex="-1" className={styles.wrapper}>
							<div className={classnames(styles.modal, styles[props.size])}>
								<div
									ref={containerRef}
									className={classnames(styles.body, {
										[styles.gradient]        : props.gradient,
										[styles.noScrollNeeded]  : props.noScrollNeeded,
										[styles.noBottomPadding] : props.noBottomPadding,
									})}
									onClick={(e) => {e.stopPropagation();}}
								>
									{closeButton()}

									<div className={styles.content}>
										{props.children}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					// Render with FocusTrap otherwise
					<FocusTrap
						focusTrapOptions={{
							fallbackFocus: () => document.getElementById(modalId),
							allowOutsideClick: true,
              // NOTE: THIS USES THE ID ELEMENT SELECTOR SO ALL
						  // VALUES SHOULD HAVE THE FOLLOWING STRUCTURE: "#<MY ELEMENT ID HERE>"
							...(props.initialFocusElementId && { initialFocus: props.initialFocusElementId }),
						  ...(props.data?.setReturnFocus && { setReturnFocus: props.data?.setReturnFocus }),
						}}
					>
						<div
							className={classnames(styles.overlay, { [styles.open]: props.open })}
							onClick={() => {
								if (props.dismissable) {
									handleClickEvent(containerRef, () => {
										props.closeModal(props.id);
										if (props.closeRedirect) {
											props.closeRedirect();
										}
									}, props.open);
								}
							}}
							role="dialog"
							aria-label={!props.ariaLabeledBy ? props.title : undefined}
							aria-labelledby={props.ariaLabeledBy ? props.ariaLabeledBy : undefined}
							aria-modal="true"
						>
							<div id={modalId} tabIndex="-1" className={styles.wrapper}>
								<div className={classnames(styles.modal, styles[props.size])}>
									<div
										ref={containerRef}
										className={classnames(styles.body, {
											[styles.gradient]        : props.gradient,
											[styles.noScrollNeeded]  : props.noScrollNeeded,
											[styles.noBottomPadding] : props.noBottomPadding,
										})}
										onClick={(e) => {e.stopPropagation();}}
									>
										{closeButton()}

										<div className={styles.content}>
											{props.children}
										</div>
									</div>
								</div>
							</div>
						</div>
					</FocusTrap>
				),
			document.body
		) : null
	);
};

Modal.defaultProps = {
	open          : false,
	size          : 'sm',
	closeRedirect : false,
	gradient      : false,
	dismissable   : true,
	title         : '',
	ariaLabeledBy : false,
	hideModalCloseButton : false,
};

export { Modal };

export default connect(
	(state, ownProps) => ({
		open : getOr(ownProps.open, `${ownProps.id}.open`, state.modal),
		data : get(`${ownProps.id}.data`, state.modal),
	}),
	{ closeModal }
)(Modal);
