import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { getChooseUserMemberInfo } from "components/templates/ChooseUserPage/graphql/queries";

export function useChooseUserMemberInfo() {
  return useQuery(getChooseUserMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    fetchPolicy: "network-only",
    skip: Meowth.apolloSkipUserId(),
  });
}
