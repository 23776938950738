import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import {
  VCalendarIcon,
  VCallIcon,
  VFinancialHelpIcon,
  VTimeIcon,
} from "@springcare/verdant-icons-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns/format";
import type { TimeSlotType } from "modules/MemberDashboard/Scheduling/types";
import { getUsersTimezone } from "modules/MemberDashboard/Scheduling/utils";

type AppointmentDetailsPropType = {
  selectedTimeSlot: TimeSlotType;
};
export const AppointmentDetails = ({
  selectedTimeSlot,
}: AppointmentDetailsPropType) => {
  const { t } = useTranslation("scheduling", { keyPrefix: "bookingReview" });
  const timezone = getUsersTimezone(false);

  return (
    <Box
      width="100%"
      borderColor={"border-subtle"}
      borderWidth="v-width-thin"
      borderRadius={12}
      p={24}
    >
      <Heading size="heading-small" mb={24}>
        {t("appointmentDetails")}
      </Heading>
      <VStack alignItems="flex-start" gap={16}>
        <HStack spacing={8}>
          <VCalendarIcon width={24} height={24} />
          <Text size="body-medium">
            {format(selectedTimeSlot.timestamp, "PPP")}
          </Text>
        </HStack>
        <HStack spacing={8}>
          <VTimeIcon width={24} height={24} />
          <Text size="body-medium">
            {selectedTimeSlot.formattedTime} {timezone}
          </Text>
        </HStack>
        <HStack spacing={8}>
          <VCallIcon width={24} height={24} />
          <Text size="body-medium">{t("phone")}</Text>
        </HStack>
        <HStack spacing={8}>
          <VFinancialHelpIcon width={24} height={24} />
          <Text size="body-medium">{t("benefit")}</Text>
        </HStack>
      </VStack>
    </Box>
  );
};
