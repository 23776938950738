// TODO: Add provider query if unselectFilter is clicked
import { useLazyQuery } from "@apollo/client";
import { HStack, Flex, Box, Text, IconButton } from "design-system/components";
import { Close } from "design-system/assets";
import { cloneDeep } from "lodash/fp";
import { useTranslation } from "hooks/react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import {
  searchCareProviders,
  getRecommendedTherapists,
} from "operations/queries/careProvider";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import {
  prepareQueryFilters,
  minorFilterTag,
  mapCategoryToSingular,
} from "components/templates/Browse/ProviderBrowsePage/utils";
import { trackFilterChangeApplied } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { isMinor } from "utils/memberHelpers";
import {
  getQueriedFilters,
  isBrecsVariant,
  dedupeProviders,
  BrecsVariants,
} from "components/templates/Browse/ProviderBrowsePage/utils";
import { ProviderRole } from "@spring/constants";
import Meowth from "@spring/meowth";
import { useBrecsAlgoVersion } from "hooks/useBrecsAlgoVersion";

const SelectedFilterChips = () => {
  const {
    queriedFilters,
    setQueriedFilters,
    setSelectedFilters,
    setProviderList,
    setProviderListLoading,
    setPossibleFilters,
    setPaging,
    memberInfo,
    providerType,
    setQueryRequestId,
    setBrecsProviderList,
    providerList,
    setBrecsProviderListLoading,
  } = useProviderBrowseContext();
  const brecsVariantFlag = useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT);
  const isCombinedList = brecsVariantFlag === BrecsVariants.CombinedList;
  const isBrecsFlag = isBrecsVariant(brecsVariantFlag);
  const isAMinor = isMinor(memberInfo?.user?.member);
  const showBrecs =
    isBrecsFlag && providerType === ProviderRole.Therapist && !isAMinor;
  const brecsOldFilters =
    useFeatureFlag(FLAGS.BRECS_FILTERS_UPDATE) && showBrecs;

  const [doSearchCareProviders] = useLazyQuery(searchCareProviders, {
    fetchPolicy: "no-cache",
  });
  const [doGetRecommendedTherapists] = useLazyQuery(getRecommendedTherapists, {
    fetchPolicy: "no-cache",
  });

  const { t } = useTranslation(["careProvider", "common"]);

  function updateFilteredProviders() {
    const preparedFilters = prepareQueryFilters(queriedFilters, providerType);
    const conditionsInFilters = queriedFilters?.filters?.conditions?.length;

    if (preparedFilters) {
      preparedFilters["version"] = "v2";
      setProviderListLoading(true);
      doSearchCareProviders({
        variables: preparedFilters,
      })
        .then(async (data) => {
          const response = data?.data;
          if (response) {
            setProviderList(response?.care_providers_search?.providers);
            setPaging(response?.care_providers_search?.paging);
            setPossibleFilters(
              response?.care_providers_search?.possible_filters,
            );

            if (brecsOldFilters && !conditionsInFilters) {
              await appendBrecsProviders(
                response?.care_providers_search,
                preparedFilters,
              );
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }
        })
        .finally(() => {
          setProviderListLoading(false);
        });
    }
  }

  function unselectFilter(filterObj: { type: string; filterLabel: string }) {
    const { type, filterLabel } = filterObj;
    const queriedFiltersCopy = { ...queriedFilters };

    // Removes deselected filter
    queriedFiltersCopy.filters[type] = queriedFiltersCopy.filters[type].filter(
      (item) => {
        return item !== filterLabel;
      },
    );

    // Track tag removed for Mixpanel
    trackFilterChangeApplied(
      filterLabel,
      mapCategoryToSingular(type, t),
      false,
      "Browse Filter Section",
    );

    // Updates state of `searchProvider` payload with removed filters
    setQueriedFilters(cloneDeep(queriedFiltersCopy));

    // Updates flyout "selected" filters to match updated "queried" filters
    setSelectedFilters(cloneDeep(queriedFiltersCopy));

    // Triggers + updates `searchProvider` query with new results
    updateFilteredProviders();
  }

  async function appendBrecsProviders(
    filteredSearchProvidersObj,
    preparedFilters,
  ) {
    const recommendedData = await getFilteredBrecsProviders(preparedFilters);
    const filteredBrecsProviders =
      recommendedData && recommendedData?.recommendedTherapists;
    const filteredSearchProviders = filteredSearchProvidersObj?.providers;

    if (!filteredBrecsProviders) {
      setBrecsProviderList([]);
      return;
    }

    setBrecsProviderList(filteredBrecsProviders);
    const duplicatesRemoved = dedupeProviders(
      filteredSearchProviders,
      recommendedData?.data,
      providerList,
      setProviderList,
      isCombinedList,
    );

    const totalProviderCount =
      filteredBrecsProviders?.length +
      filteredSearchProvidersObj?.paging?.total -
      duplicatesRemoved;

    setProviderListLoading(false);
    setPaging({
      limit: filteredSearchProvidersObj?.paging?.limit,
      page: filteredSearchProvidersObj?.paging?.page,
      pages: filteredSearchProvidersObj?.paging?.pages,
      total: totalProviderCount,
    });

    setQueryRequestId(filteredSearchProvidersObj?.query_request_id);
  }

  async function getFilteredBrecsProviders(preparedFilters) {
    const sagemakerRanking = useFeatureFlag(FLAGS.RECS_SAGEMAKER_RANK);
    const apolloOptionsUserId = Meowth.apolloOptionsUserId();
    apolloOptionsUserId.variables.version =
      useBrecsAlgoVersion(sagemakerRanking);
    apolloOptionsUserId.variables.filters = preparedFilters.filters;
    setBrecsProviderListLoading(true);

    const { data } = await doGetRecommendedTherapists({
      ...apolloOptionsUserId,
    });
    if (data?.user?.member?.recommended_therapists) {
      setBrecsProviderListLoading(false);
      // Return the therapist key with group_name added within the therapist object
      const recommendedTherapists = data.user.member.recommended_therapists.map(
        (item) => ({
          ...item.therapist,
          group_name: item.group_name,
          isBrecsRecommended: true,
        }),
      );
      return {
        data: data.user.member.recommended_therapists,
        recommendedTherapists: recommendedTherapists,
      };
    } else {
      return false;
    }
  }

  const queriedFilterObjs = getQueriedFilters(queriedFilters.filters);
  const filterTag = minorFilterTag(memberInfo, t);

  return (
    <>
      {queriedFilterObjs.length > 0 && (
        <Flex wrap="wrap">
          {queriedFilterObjs.map((queriedFilter) => {
            return (
              <Box
                key={queriedFilter.filterLabel}
                w="fit-content"
                px={12}
                py={8}
                mr={8}
                mb={8}
                bg="platform.light"
                borderRadius={4}
                data-cy={`${queriedFilter.filterLabel}-tag`}
              >
                <HStack spacing={4} whiteSpace="nowrap">
                  <Text variant="captionBold_v1">
                    {queriedFilter.filterLabel}
                  </Text>
                  {filterTag !== queriedFilter.filterLabel && (
                    <IconButton
                      onClick={() => unselectFilter(queriedFilter)}
                      aria-label="Close"
                      icon={<Close boxSize={8} />}
                      color="platform.base"
                      _hover={{ color: "platform.base" }}
                    />
                  )}
                </HStack>
              </Box>
            );
          })}
        </Flex>
      )}
    </>
  );
};

export default SelectedFilterChips;
