/**
 * Enums for passing in App Task Parent Types
 * where necessary.
 */

const AppTaskParent = Object.freeze({
	Cohort   : 'COHORT',
	Customer : 'CUSTOMER',
});

module.exports = AppTaskParent;
