import { useRouter } from "next/router";
import { mobileAndTabletCheck } from "utils/global";
import { useCreateCheckInMutation } from "hooks/useCreateCheckInMutation";
import { useMemberInfoForNavLinks } from "components/templates/MemberDashboard/PageWrapper/hooks/useMemberInfoForNavLinks";
import { WebMenu } from "components/templates/MemberDashboard/PageWrapper/WebMenu";
import { MobileMenu } from "components/templates/MemberDashboard/PageWrapper/MobileMenu";
import { MenuProps } from "components/templates/MemberDashboard/PageWrapper/types/navLinkTypes";

export const Menu = ({ showCheckInButton }: MenuProps) => {
  const { memberId } = useMemberInfoForNavLinks();
  const router = useRouter();
  const [createCheckInMutation] = useCreateCheckInMutation(memberId, router);

  if (!router) {
    return null;
  }

  return (
    <>
      {mobileAndTabletCheck() ? (
        <MobileMenu
          showCheckInButton={showCheckInButton}
          createCheckInMutation={createCheckInMutation}
        />
      ) : (
        <WebMenu />
      )}
    </>
  );
};

export default Menu;
