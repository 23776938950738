import { useTranslation } from "react-i18next";
import { VStack, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import { VEmptyStateDuotoneSpot } from "@springcare/verdant-icons-react";

export const EmptyState = () => {
  const { t } = useTranslation("scheduling", { keyPrefix: "timePicker" });
  const { breakpoints } = useTheme();
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  return (
    <VStack mx={8} w="100%" maxW={{ lg: 254 }} mt="v-40">
      <VEmptyStateDuotoneSpot width={isDesktop ? "50px" : "80px"} />
      <Text mt={16}>{t("empty")}</Text>
    </VStack>
  );
};
