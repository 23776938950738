import { useHelpInfo } from "hooks/useHelpInfo";
import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
} from "design-system/components";
import { Card, Badge, useCustomToast } from "@springcare/sh-component-library";
import { Text } from "@chakra-ui/react";
import {
  VCalendarIcon,
  VCallIcon,
  VChevronRightSmallIcon,
  VEmailIcon,
  VExternalLinkIcon,
  VNotesIcon,
  VSafetyIcon,
  VSmsIcon,
} from "@springcare/verdant-icons-react";
import { Link } from "components/atoms";
import { useTranslation } from "react-i18next";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";
import { formatPhoneForDisplay } from "utils/global";
import { footerLinks } from "components/organisms/MemberHelpCard/footerLinks";
import styles from "components/organisms/MemberHelpCard/styles.module.scss";
import NewSHFlyoutHeader from "design-system/components/custom/overlay/NewSHFlyoutHeader";
import { useState, useEffect } from "react";
import {
  track,
  TRACK_EVENT,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";
import { openSupport } from "utils/zendeskHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { links } from "@spring/constants";
import useChatBotHandler from "utils/chatbot/useChatBotHandler";

const trackHelpClick = (channel) => {
  track(`Help And Support -- ${channel} Clicked`);
};

enum SupportType {
  GENERAL = "general",
  CRISIS = "crisis",
}

const trackSupportNumberClicked = (supportType, phoneNumber) => {
  const trackingType =
    supportType === SupportType.GENERAL
      ? "Care and Technical Issues"
      : "Crisis Support";
  const to = `tel:${phoneNumber}`;
  const location = "New Request Support Fly-out";

  track(`Link Clicked - ${trackingType} Phone Number`, {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
      page: window.location.pathname,
      to: to,
      type: trackingType,
      location: location,
    }),
  });
  TRACK_EVENT.LINK_CLICKED(window.location.pathname, to, trackingType, {
    location: location,
    spring_doc_id: "directsched039",
  });
};

const HelpCardFooter = ({ children }: HelpCardFooterProps) => (
  <footer
    className={styles.newFooterContentContainer}
    style={{ overflowY: "auto" }}
  >
    <div className={styles.newFooterContent} data-cy="footer-data">
      {children}
    </div>
  </footer>
);

const NewCrisisContactDetails = ({
  phoneNumber,
}: NewCrisisContactDetailsProps) => {
  const { t: translatedString } = useTranslation("memberHelpCard");
  return (
    <Box>
      <Card
        variant="medium-emphasis"
        size="md"
        width="full"
        data-cy="help-crisis"
        cursor={"pointer"}
        onClick={() => {
          trackSupportNumberClicked(SupportType.CRISIS, phoneNumber);
          window.location.href = `tel:${phoneNumber}`;
        }}
      >
        <Flex align="center" gap={"16px"}>
          <Box width={"18px"}>
            <VSafetyIcon
              color="var(--chakra-colors-negative-base)"
              width={"18px"}
              height={"18px"}
            />
          </Box>
          <Flex direction="column" gap={"8px"}>
            <Text fontWeight={600} color="negative-base">
              {translatedString("newCrisisContactDetailsHeading")}
            </Text>
            <Text style={getPhoneStyleForRTL()} color={"content-primary"}>
              {translatedString("newCrisisSupportText", {
                number: formatPhoneForDisplay(phoneNumber),
              })}
            </Text>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};

const NewSupportDetails = ({ closeFlyout }: NewSupportDetailsProps) => {
  const { t: translatedString } = useTranslation("memberHelpCard");
  const shouldAuthHelpCenter = useFeatureFlag(FLAGS.HELP_CENTER_REDIRECT);

  const handleSupportCenterRequestsClick = () => {
    trackHelpClick("Support Center");
    openSupport(shouldAuthHelpCenter);
    closeFlyout();
  };
  const handleMedicalRecordsSupportRequestsClick = () => {
    trackHelpClick("Medical Records Support Center");
    window.open(links.CareSupportMedicalRecordsURL, "_blank");
    closeFlyout();
  };
  const handleAppointmentSchedulingSupportRequestsClick = () => {
    trackHelpClick("Appointment Scheduling Support Center");
    window.open(links.CareSupportAppointmentSchedulingURL, "_blank");
    closeFlyout();
  };

  return (
    <Flex direction={"column"} gap={"16px"}>
      <Heading
        fontSize={"21px"}
        fontWeight={600}
        size="heading-small"
        color={"content-primary"}
      >
        {translatedString("newGetSupportHeading")}
      </Heading>

      <Flex direction={"column"} gap={"8px"}>
        <Card
          variant="low-emphasis"
          size="md"
          width="full"
          cursor={"pointer"}
          onClick={handleMedicalRecordsSupportRequestsClick}
        >
          <Flex align="center" gap={"16px"}>
            <VNotesIcon width="18px" height="18px" />
            <Text color="content-primary">
              {translatedString("newDocumentSupport")}
            </Text>
          </Flex>
        </Card>

        <Card
          variant="low-emphasis"
          size="md"
          width="full"
          cursor={"pointer"}
          onClick={handleAppointmentSchedulingSupportRequestsClick}
        >
          <Flex align="center" gap={"16px"}>
            <VCalendarIcon width="18px" height="18px" />
            <Text color="content-primary">
              {translatedString("newAppointmentSupport")}
            </Text>
          </Flex>
        </Card>

        <Card
          variant="low-emphasis"
          size="md"
          width="full"
          cursor={"pointer"}
          onClick={handleSupportCenterRequestsClick}
        >
          <Flex align="center" gap={"16px"}>
            <VExternalLinkIcon width="18px" height="18px" />
            <Text color="content-primary">
              {translatedString("newSupportCenter")}
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

const NewGeneralContactDetails = ({
  closeFlyout,
  chatAvailability,
  handleCTAButtonClick,
}: NewGeneralContactDetailsProps) => {
  const { t: translatedString } = useTranslation("memberHelpCard");
  const { handleOpenChat } = useChatBotHandler();

  const handleChatCTAClick = () => {
    closeFlyout();
    handleOpenChat();
    trackHelpClick("Zendesk Contact");
  };
  const handleEmailCTAClick = () => {
    trackHelpClick("Email Support Center");
    window.open(links.CareSupportEmailURL, "_blank");
    closeFlyout();
  };

  return (
    <Flex direction={"column"} gap={"8px"}>
      <Heading
        fontSize={"21px"}
        fontWeight={600}
        size="heading-small"
        color={"content-primary"}
      >
        {translatedString("newGeneralContactDetailsHeading")}
      </Heading>

      <Card
        variant="low-emphasis"
        size="md"
        width="full"
        cursor={"pointer"}
        onClick={handleChatCTAClick}
      >
        <Flex align="center" gap={"16px"}>
          <VSmsIcon width="18px" height="18px" />
          <Flex direction="column" gap={"8px"}>
            <Flex gap={"8px"}>
              <Text color="content-primary">
                {translatedString("newLiveChat")}
              </Text>
              <Badge>{translatedString("newRecommendedBadgeText")}</Badge>
            </Flex>
            <Text color={"content-secondary"}>
              {`${translatedString("chatReachOutText")} ${translatedString(chatAvailability, { ns: "memberHelpCard" })}`}
            </Text>
          </Flex>
        </Flex>
      </Card>

      <Card
        variant="low-emphasis"
        size="md"
        width="full"
        cursor={"pointer"}
        onClick={handleEmailCTAClick}
      >
        <Flex align="center" gap={"16px"}>
          <VEmailIcon width="18px" height="18px" />
          <Flex direction="column" gap={"8px"}>
            <Text fontWeight={400} fontSize="16px" color="content-primary">
              {translatedString("newEmailSupportHeading")}
            </Text>
            <Text fontWeight={400} fontSize="16px" color={"content-secondary"}>
              {translatedString("newEmailSupportSubText")}
            </Text>
          </Flex>
        </Flex>
      </Card>

      <Card
        variant="low-emphasis"
        size="md"
        width="full"
        cursor={"pointer"}
        onClick={handleCTAButtonClick}
      >
        <Flex
          align="center"
          justifyContent="space-between"
          gap={"16px"}
          width="full"
        >
          <Box display={"flex"} alignItems="center" gap={"16px"}>
            <VCallIcon width="18px" height="18px" />
            <Text color="content-primary">
              {translatedString("giveUsACallText")}
            </Text>
          </Box>
          <VChevronRightSmallIcon width="18px" height="18px" />
        </Flex>
      </Card>
    </Flex>
  );
};

const NewFooterLinks = () => {
  const { t: translatedString } = useTranslation("limitedLangRegister");
  return (
    <div>
      <div>
        <Link
          inlineTextLink
          to={footerLinks.privacyPolicy}
          className={`${styles.newContactCardHeading}`}
        >
          {translatedString("createAccount.form.consents.privacyPolicy")}
        </Link>
        <span className={`${styles.interpunct}`}></span>
        <Link
          inlineTextLink
          to={footerLinks.tos}
          className={`${styles.newContactCardHeading}`}
        >
          {translatedString("createAccount.form.consents.tos")}
        </Link>
      </div>
    </div>
  );
};

const NewMemberHelpCardSkeleton = ({
  showBackButton,
}: NewMemberHelpCardSkeletonProps) => {
  return (
    <Box p={20}>
      <SimpleGrid overflowX="clip" h="100%">
        <Stack gap={8} mb={16}>
          {showBackButton ? (
            <Skeleton h="56px" />
          ) : (
            <>
              <Skeleton h="56px" />
              <SkeletonText
                noOfLines={1}
                spacing="4"
                skeletonHeight="8"
                mb="8px"
              />
              <Skeleton h="56px" />
              <Skeleton h="56px" />
              <SkeletonText
                noOfLines={1}
                spacing="4"
                skeletonHeight="8"
                mb="8px"
              />
              <Skeleton h="56px" />
              <Skeleton h="56px" />
            </>
          )}
        </Stack>
      </SimpleGrid>
    </Box>
  );
};

const GiveUsACallCta = ({
  phoneNumber,
  phoneAvailability,
}: GiveUsACallCtaProps) => {
  const { t: translatedString } = useTranslation("memberHelpCard");
  return (
    <Flex direction={"column"} gap={"8px"}>
      <Card
        variant="low-emphasis"
        size="md"
        width="full"
        cursor={"pointer"}
        onClick={() => {
          trackSupportNumberClicked(SupportType.GENERAL, phoneNumber);
          window.location.href = `tel:${phoneNumber}`;
        }}
      >
        <Flex align="center" gap={"16px"}>
          <VCallIcon width="18px" height="18px" />
          <Flex direction="column" gap={"8px"}>
            <Flex gap={"8px"}>
              <Text style={getPhoneStyleForRTL()} color="content-primary">
                {translatedString("generalSupportText", {
                  number: formatPhoneForDisplay(phoneNumber),
                })}
              </Text>
            </Flex>
            <Text color={"content-secondary"}>
              {translatedString(phoneAvailability)}
            </Text>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

const NewMemberHelpCard = ({
  closeFlyout = () => {},
}: NewMemberHelpCardProps) => {
  const [showBackButton, setShowBackButton] = useState(false);
  const {
    helpInfo,
    loading,
    setSelectedCountry,
    error: helpInfoError,
  } = useHelpInfo();
  const { t: translatedString } = useTranslation("memberHelpCard");

  const showHelpInfoError = useCustomToast({
    type: "error",
    message: translatedString("helpInfoErrorMessage"),
    duration: 5000,
    layout: "fit-content",
    action: "off",
  });

  useEffect(() => {
    if (helpInfoError) {
      showHelpInfoError();
      closeFlyout();
    }
  }, [helpInfoError]);

  const getSupportedLanguages = (availableLanguages, translatedString) => {
    if (availableLanguages?.length === 1) {
      return availableLanguages[0];
    }
    const lastLang = availableLanguages?.pop();
    return `${availableLanguages?.join(", ")} ${translatedString("common:grammar.and")} ${lastLang}`;
  };

  const handleCTAButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Give us a call", {
      location: "New Request Support Fly-out",
    });
    setShowBackButton(!showBackButton);
  };

  const handleSetCountry = (item) => {
    setSelectedCountry(item.iso2);
  };

  const getNewHelpFlyoutHeader = () => {
    return showBackButton
      ? translatedString("giveUsACallText")
      : translatedString("newHelpFlyoutHeaderText");
  };

  if (!helpInfo || loading) {
    return <NewMemberHelpCardSkeleton showBackButton={showBackButton} />;
  }
  return (
    <>
      <NewSHFlyoutHeader
        caption={translatedString("supportedLanguages", {
          languages: getSupportedLanguages(
            helpInfo?.region?.languages,
            translatedString,
          ),
        })}
        closeButton="on"
        layout="full"
        title={getNewHelpFlyoutHeader()}
        variant="base"
        onClose={closeFlyout}
        backButton={showBackButton ? "on" : "off"}
        onBack={handleCTAButtonClick}
        helpInfo={helpInfo}
        handleSetCountry={handleSetCountry}
      />
      <HelpCardFooter>
        {loading ? (
          <NewMemberHelpCardSkeleton showBackButton={showBackButton} />
        ) : (
          <>
            {showBackButton ? (
              <GiveUsACallCta
                phoneNumber={helpInfo?.region?.general_support_number}
                phoneAvailability={
                  helpInfo?.region?.general_support_availability
                }
              />
            ) : (
              <>
                <NewCrisisContactDetails
                  phoneNumber={helpInfo?.region?.crisis_support_number}
                />
                <NewSupportDetails closeFlyout={closeFlyout} />
                <NewGeneralContactDetails
                  closeFlyout={closeFlyout}
                  chatAvailability={helpInfo?.region?.chat_availability}
                  handleCTAButtonClick={handleCTAButtonClick}
                />
                <NewFooterLinks />
              </>
            )}
          </>
        )}
      </HelpCardFooter>
    </>
  );
};

interface HelpCardFooterProps {
  children: React.ReactNode;
}

interface NewCrisisContactDetailsProps {
  phoneNumber: string;
}

interface NewSupportDetailsProps {
  closeFlyout: () => void;
}

interface NewGeneralContactDetailsProps {
  closeFlyout: () => void;
  chatAvailability: string;
  handleCTAButtonClick: () => void;
}

interface NewMemberHelpCardSkeletonProps {
  showBackButton: boolean;
}

interface GiveUsACallCtaProps {
  phoneNumber: string;
  phoneAvailability: string;
}

interface NewMemberHelpCardProps {
  closeFlyout?: () => void;
}

export default NewMemberHelpCard;
