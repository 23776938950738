import { API_DOMAIN } from "constants/api";

export type TemporaryConsentsVariables = {
  appointment_id: string;
};

export const useVerifySessions = () => {
  const rotomUrl = API_DOMAIN;

  return async (variables: TemporaryConsentsVariables) => {
    const res = await fetch(`${rotomUrl}/api/ehr/session_verifications`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("compass_redirect_access_token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(variables),
    });
    return await res.json();
  };
};
export default useVerifySessions;
