import React from "react";
import {
  GlobalOneSupportedLanguages,
  SupportedLanguages,
} from "@spring/constants";
import { Section, Form, Grid, Col, Select } from "@spring/smeargle";
import { getOr } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { addNotification } from "@spring/smeargle/actions";

import MemberSettingsLanguageSchema from "schemas/memberLanguageForm";
import { lookupLanguage } from "utils/language";
import { Heading } from "design-system/components";
import { useDispatch } from "react-redux";

interface Props {
  userInfo: {
    user: {
      id: string;
      preferred_language: string;
    };
  };
  doUpdateUser: Function;
  isMemberT2: boolean;
}

const MemberLanguageForm = ({ doUpdateUser, userInfo, isMemberT2 }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("limitedLangSettings");

  const submitLanguage = (formData) => {
    const { preferred_language } = formData;

    const payload = {
      id: userInfo.user.id,
      patch: {
        preferred_language,
      },
    };

    doUpdateUser({
      variables: { input: payload },
      onCompleted: (response) => {
        if (!response?.updateUser?.success) {
          dispatch(addNotification(t("notifications.genericError"), "error"));
        } else {
          dispatch(addNotification(t("notifications.changesSaved"), "success"));
        }
      },
    });
  };

  const getInitialLanguageData = () => {
    const languageFromData = getOr("", "user.preferred_language", userInfo);
    const language = lookupLanguage(languageFromData);
    return language?.englishName || "English";
  };

  const languageList = isMemberT2
    ? GlobalOneSupportedLanguages.concat(SupportedLanguages)
    : SupportedLanguages;

  const languages = languageList.map((language) => {
    return {
      label: language?.nativeName,
      value: language?.englishName,
      code: language?.code,
    };
  });

  languages.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    } else if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <Section>
      <Form
        dataCy="language"
        formKey="memberPreferredLanguage"
        theme="simple"
        onSubmit={submitLanguage}
        initialData={{ preferred_language: getInitialLanguageData() }}
        submitIcon="arrow-right"
        submitText={t("common:form.submitText")}
        submitAriaLabel={t("a11y:submit.languagePref")}
        schema={MemberSettingsLanguageSchema}
      >
        <Heading fontSize={20} fontWeight={"bold"} color="#4b4b4b">
          {t("language.title")}
        </Heading>
        <fieldset>
          <legend hidden>{t("language.title")}</legend>
          <Grid gutter="0 8px">
            <Col size={12}>
              <Select
                ariaLabel={t("language.title")}
                dataCy="preferred_language"
                fieldKey="preferred_language"
                label={t("language.subtitle")}
                options={languages}
                isLanguage={true}
              />
            </Col>
          </Grid>
        </fieldset>
      </Form>
    </Section>
  );
};

export default MemberLanguageForm;
