/**
 * Enums for passing in Request Covered Life Kinds
 * where necessary.
 */

const RequestCoveredLifeKind = Object.freeze({
	Self    : 'SELF',
	Primary : 'PRIMARY',
});

module.exports = RequestCoveredLifeKind;