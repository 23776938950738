/* eslint-disable react/prop-types */
/* eslint-disable no-async-promise-executor */

import PropTypes from "prop-types";
import React, { Component, useEffect } from "react";
import Router from "next/router";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Button,
  LinkButton,
  Icon,
  FlexRow,
  Section,
  HorizontalRule,
  Subtitle,
  Form,
  DateSelect,
  Input,
  PhoneInput,
  RadioButtons,
  Textarea,
} from "@spring/smeargle";
import StateSelect from "components/form/StateSelect";
import { addNotification } from "@spring/smeargle/actions";
import { get } from "lodash/fp";
import yup from "schemas/yup";
import { withTranslation } from "react-i18next";
import styles from "./styles.module.scss";

// import { AddressTypeahead } from '../../form';

import Slide from "./Slide";

import {
  TRACK_EVENT,
  track,
  time_event,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";
import routes from "routes";
import {
  normalizeToName,
  shouldShowStateField,
  isStateMailingEnabled,
} from "utils/global";
const mpPrefix = "Invite Dependents";

function isDateComplete(date) {
  return !!(date.month && date.day && date.year);
}

const allowedAge = 18;

function isMaxYear(year) {
  const maxYear = new Date().getFullYear() - allowedAge;
  return year === maxYear;
}

function isMaxMonth(month) {
  return month === new Date().getMonth() + 1;
}

const specialCharacters = new Set(["^", "$", "+", "*", "?", "|"]);

const adultSignupRequirements = (email) => {
  if (email) {
    email = email.split("").reduce((acc, val) => {
      if (specialCharacters.has(val)) {
        acc += "\\";
      }
      acc += val;
      return acc;
    }, "");
  }

  const adultSignupSchema = {
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: email
      ? yup
          .string()
          .email()
          .matches(new RegExp(`^(?!${email}$).*$`, "i"))
          .required()
      : yup.string().email().required(),
    date_of_birth: yup.object().shape({
      month: yup.lazy((_value, { parent }) => {
        if (isDateComplete(parent) && isMaxYear(parent.year)) {
          return yup
            .number()
            .min(1)
            .max(new Date().getMonth() + 1)
            .required();
        }
        return yup.number().min(1).max(12).required();
      }),
      day: yup.lazy((_value, { parent }) => {
        if (
          isDateComplete(parent) &&
          isMaxYear(parent.year) &&
          isMaxMonth(parent.month)
        ) {
          return yup.number().min(1).max(new Date().getDate()).required();
        }
        return yup.number().min(1).max(31).required();
      }),
      year: yup
        .number()
        .min(1900)
        .max(new Date().getFullYear() - allowedAge)
        .required(),
    }),
    city: yup.string().required(),
    phone: yup
      .object()
      .shape({
        code: yup.string(),
        number: yup
          .string()
          .min(5, "Phone number must be exactly 10 digits")
          .required(),
      })
      .required(),
  };
  return adultSignupSchema;
};

const childSignupRequirements = {
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  relationship: yup.string().required(),
  city: yup.string().required(),
  country: yup.string().required(),
};

const buildSchema = (forms, child = false, email, global) => {
  let schema = {};

  const requirements = child
    ? childSignupRequirements
    : adultSignupRequirements(email, global);

  forms.forEach((form) => {
    schema[form] = yup.object().shape({ ...requirements });
  });

  return yup.object().shape({ ...schema });
};

const WelcomeForm = ({ secondVisit, ...rest }) => {
  // component did mount
  useEffect(() => {
    time_event(`${mpPrefix} -- SubmitDependentForm(s)`);
  });

  return <AddDependents signingUp secondVisit={secondVisit} {...rest} />;
};

WelcomeForm.propTypes = {
  secondVisit: PropTypes.any,
};

// Props:
// Child: bool
// signingUp: bool
class AddDependentsForm extends Component {
  static propTypes = {
    addNotification: PropTypes.func,
    child: PropTypes.any,
    country: PropTypes.any,
    create: PropTypes.func,
    email: PropTypes.any,
    formData: PropTypes.any,
    global: PropTypes.any,
    isValid: PropTypes.any,
    loading: PropTypes.any,
    signingUp: PropTypes.any,
    t: PropTypes.func,
  };

  state = {
    forms: [Date.now()],
    schema: {},
    child: false,
  };

  componentDidMount() {
    const { router } = Router;
    const { child, email, global } = this.props;
    if (router.query.child) {
      this.setState({
        schema: buildSchema(
          this.state.forms,
          router.query.child,
          email,
          global,
        ),
        child: router.query.child,
      });
    } else {
      this.setState({
        schema: buildSchema(this.state.forms, child, email, global),
        child: this.props.child,
      });
    }
  }

  addForm = () => {
    track(`${mpPrefix} -- Add Dependent Form`, {
      signup: this.props.signingUp,
      child: this.state.child,
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: routes.InviteDependents.as,
        type: "Add Dependent Form",
      }),
    });
    TRACK_EVENT.BUTTON_CLICKED(
      routes.InviteDependents.as,
      "Add Dependent Form",
    );
    const forms = [...this.state.forms, Date.now()];

    this.setState({
      forms,
      schema: buildSchema(forms, this.state.child, this.props.email),
    });
  };

  removeForm = (id, userRemovedForm) => {
    track(`${mpPrefix} -- Remove Dependent Form`, {
      signup: this.props.signingUp,
      child: this.state.child,
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: routes.InviteDependents.as,
        type: "Remove Dependent",
      }),
    });
    if (userRemovedForm) {
      TRACK_EVENT.BUTTON_CLICKED(
        routes.InviteDependents.as,
        "Remove Dependent",
      );
    }

    const arr = [...this.state.forms];
    const i = arr.indexOf(id);
    arr.splice(i, 1);

    this.setState({
      forms: arr,
      schema: buildSchema(arr, this.state.child, this.props.email),
    });
  };

  get subtitle() {
    const { t, global } = this.props;

    if (this.state.child) {
      return t("forms.dependentInviteForm.addDependentsForm.subtitle1");
    }

    return global
      ? t("forms.dependentInviteForm.addDependentsForm.intlSubtitle2")
      : t("forms.dependentInviteForm.addDependentsForm.subtitle2");
  }

  getAdultInputs = (id, i) => {
    const { t, global, country } = this.props;
    const normalizedCountry = normalizeToName(country);
    const showState = shouldShowStateField(normalizedCountry);
    const stateMailingEnabled = isStateMailingEnabled(normalizedCountry);
    return (
      <>
        <Input
          autoFocus={i !== 0}
          dataCy="dependent-invite-adult-firstname"
          fieldKey={`${id}.first_name`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.adultInputs.firstName",
          )}
          inherit={true}
          forceRequired={true}
        />
        <Input
          dataCy="dependent-invite-adult-lastname"
          fieldKey={`${id}.last_name`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.adultInputs.lastName",
          )}
          inherit={true}
          forceRequired={true}
        />
        <DateSelect
          dataCy="dependent-invite-adult-dob"
          fieldKey={`${id}.date_of_birth`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.adultInputs.dateOfBirth",
          )}
          inherit={true}
          forceRequired={true}
        />
        <Input
          dataCy="dependent-invite-adult-city"
          fieldKey={`${id}.city`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.adultInputs.city",
          )}
          forceRequired={true}
        />
        {showState && stateMailingEnabled && (
          <StateSelect
            dataCy="dependent-invite-adult"
            fieldKey={`${id}.state`}
            label={t(
              "forms.dependentInviteForm.addDependentsForm.adultInputs.state",
            )}
            country={normalizedCountry}
            forceRequired={true}
          />
        )}
        <Input
          dataCy="dependent-invite-adult-email"
          fieldKey={`${id}.email`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.adultInputs.email",
          )}
          inherit={true}
          forceRequired={true}
        />
        {global && (
          <Input
            fieldKey={`${id}.country`}
            label={t(
              "forms.dependentInviteForm.addDependentsForm.adultInputs.country",
            )}
            disabled={true}
            value={`${normalizedCountry}`}
            placeholder={`${normalizedCountry}`}
            inherit={true}
            forceRequired={true}
          />
        )}
        <PhoneInput
          dataCy="dependent-invite-adult-phone"
          fieldKey={`${id}.phone`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.adultInputs.phoneNumber",
          )}
          inherit={true}
          forceRequired={true}
        />
      </>
    );
  };

  getChildInputs = (id, i) => {
    const { t, global, country } = this.props;
    const normalizedCountry = normalizeToName(country);
    const showState = shouldShowStateField(normalizedCountry);
    const stateMailingEnabled = isStateMailingEnabled(normalizedCountry);
    return (
      <>
        <Input
          autoFocus={i !== 0}
          fieldKey={`${id}.first_name`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.childInputs.firstName",
          )}
        />
        <Input
          fieldKey={`${id}.last_name`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.childInputs.lastName",
          )}
        />
        <Input
          fieldKey={`${id}.city`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.adultInputs.city",
          )}
        />
        {showState && stateMailingEnabled && (
          <StateSelect
            fieldKey={`${id}.state`}
            label={t(
              "forms.dependentInviteForm.addDependentsForm.adultInputs.state",
            )}
            country={normalizedCountry}
          />
        )}
        {global && (
          <Input
            fieldKey={`${id}.country`}
            label={t(
              "forms.dependentInviteForm.addDependentsForm.adultInputs.country",
            )}
            disabled
            value={`${normalizedCountry}`}
            placeholder={`${normalizedCountry}`}
          />
        )}
        <RadioButtons
          fieldKey={`${id}.relationship`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.childInputs.radiobutton.labels.default",
          )}
          options={[
            {
              label: t(
                "forms.dependentInviteForm.addDependentsForm.childInputs.radiobutton.labels.mother",
              ),
              value: "mother",
            },
            {
              label: t(
                "forms.dependentInviteForm.addDependentsForm.childInputs.radiobutton.labels.father",
              ),
              value: "father",
            },
            {
              label: t(
                "forms.dependentInviteForm.addDependentsForm.childInputs.radiobutton.labels.other",
              ),
              value: "other",
            },
          ]}
        />
        <Textarea
          fieldKey={`${id}.additional`}
          label={t(
            "forms.dependentInviteForm.addDependentsForm.childInputs.textareaLabel",
          )}
        />
      </>
    );
  };

  get forms() {
    const { t } = this.props;
    return (
      <>
        <Form
          preserve
          formKey="addDependents"
          schema={this.state.schema}
          initialData={{ phone: { code: this.props.country } }}
          autocomplete={false}
        >
          {this.state.forms.map((form, i) => (
            <>
              <div className={styles.hidden} role={i === 0 ? "none" : "alert"}>
                {t("dependentFormAddButton", { ns: "a11y", formCount: i + 1 })}
              </div>
              <div key={form}>
                <Section>
                  <FlexRow reverse justification="space-between">
                    {this.state.forms.length > 1 && (
                      <Icon
                        type="close"
                        onClick={() => {
                          this.removeForm(form, true);
                        }}
                      />
                    )}

                    {this.state.forms.length > 1 && (
                      <Subtitle bold noMargin>
                        {t(
                          "forms.dependentInviteForm.addDependentsForm.dependent",
                        )}{" "}
                        {i + 1}
                      </Subtitle>
                    )}
                  </FlexRow>
                </Section>

                {this.state.child && this.getChildInputs(form, i)}
                {!this.state.child && this.getAdultInputs(form, i)}
                <Section>
                  <HorizontalRule />
                </Section>
              </div>
            </>
          ))}
        </Form>
      </>
    );
  }

  get submit() {
    const { t } = this.props;
    let text = t(
      "forms.dependentInviteForm.addDependentsForm.submit.sendInvite",
    );
    if (this.state.forms.length > 1) {
      const invites = this.state.forms.length;
      text = t(
        "forms.dependentInviteForm.addDependentsForm.submit.sendMultipleInvites",
        { invites: invites },
      );
    }

    return (
      <Button
        full
        text={text}
        dataCy="dependent-invite-form-submit"
        disabled={!this.props.isValid || this.props.loading}
        onClick={async () => {
          track(`${mpPrefix} -- Submit Dependent Form(s)`, {
            signup: this.props.signingUp,
            child: this.state.child,
            count: this.state.forms.length,
            deprecated: true,
            replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
              page: routes.InviteDependents.as,
              type: "Invite Dependents",
              info: this.state.forms.length,
            }),
          });
          TRACK_EVENT.FORM_SUBMITTED(
            routes.InviteDependents.as,
            "Invite Dependents",
            {
              info: this.state.forms.length,
            },
          );
          const data = this.props.formData;
          const promises = [];

          // Send an invite to everyone in a form on this screen
          this.state.forms.forEach(async (form) => {
            const invitee = data[form];
            invitee.country = normalizeToName(this.props.country);
            promises.push(
              new Promise(async (resolve, reject) => {
                if (this.state.child) {
                  // TODO: handle duplicate emails
                  const res = await this.props.create(invitee, true);

                  const okay = get(
                    "data.createPotentialCoveredLife.success",
                    res,
                  );
                  const { first_name } = invitee;
                  const { last_name } = invitee;
                  if (okay) {
                    this.props.addNotification(
                      t(
                        "forms.dependentInviteForm.addDependentsForm.submit.okay.success",
                        { first_name: first_name, last_name: last_name },
                      ),
                      "success",
                    );
                    this.removeForm(form, false);
                    resolve("success");
                  } else {
                    this.props.addNotification(
                      t(
                        "forms.dependentInviteForm.addDependentsForm.submit.okay.error",
                        { first_name: first_name, last_name: last_name },
                      ),
                      "error",
                    );
                    reject("failure");
                  }
                } else {
                  // TODO: handle duplicate emails
                  const res = await this.props.create(invitee, false);

                  const okay = get("data.createCoveredLife.success", res);
                  const invitee_email = invitee.email;
                  if (okay) {
                    track(`${mpPrefix} -- Success Message Viewed`, {
                      deprecated: true,
                      replaced_with: makeEventString(
                        EVENT_TYPE.FORM_SUBMITTED,
                        {
                          page: routes.InviteDependents.as,
                          type: "Invite Dependents",
                          info: "number_of_dependents_invited",
                          deprecated: true,
                          replaced_with: "N/A",
                        },
                      ),
                    });
                    this.props.addNotification(
                      t(
                        "forms.dependentInviteForm.addDependentsForm.submit.sentInvite.success",
                        { invitee_email: invitee_email },
                      ),
                      "success",
                    );
                    this.removeForm(form, false);
                    resolve("success");
                  } else {
                    this.props.addNotification(
                      t(
                        "forms.dependentInviteForm.addDependentsForm.submit.sentInvite.error",
                        { invitee_email: invitee_email },
                      ),
                      "error",
                    );
                    reject("failure");
                  }
                }
              }),
            );
          });

          await Promise.all(promises);
          this.addForm();
        }}
      />
    );
  }

  render() {
    const { t } = this.props;
    const child = t("forms.dependentInviteForm.title.child");
    const dependents = t("forms.dependentInviteForm.title.dependents");
    return (
      <Slide
        title={`${this.state.child ? child : dependents}`}
        subtitle={this.subtitle}
        back={() => {
          TRACK_EVENT.LINK_CLICKED(
            routes.InviteDependents.as,
            routes.AddDependentsOptions.as,
            "Back",
          );
          const { to, as } = routes.AddDependentsOptions;
          const { router } = Router;
          if (router.query.referrer) {
            router.push(
              {
                pathname: to,
                query: { referrer: Router.query.referrer },
              },
              as,
            );
          } else {
            router.push(
              {
                pathname: to,
              },
              as,
            );
          }
        }}
      >
        {this.forms}

        <Section size="lg">
          <Subtitle>
            <LinkButton
              full
              reverse
              icon="plus"
              text={t("forms.dependentInviteForm.submitText")}
              disabled={!this.props.isValid || this.props.loading}
              onClick={this.addForm}
            />
          </Subtitle>
        </Section>

        {this.submit}
      </Slide>
    );
  }
}

const AddDependents = compose(
  connect(
    (state) => ({
      isValid: get("form.addDependents.validation.isValid", state),
      formData: get("form.addDependents.data", state),
      global: get("global.showGlobalExperience", state),
      country: get("global.country", state),
    }),
    { addNotification },
  ),
)(withTranslation(["limitedLangAuth", "a11y"])(AddDependentsForm));

export { WelcomeForm, AddDependents };
