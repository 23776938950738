import { useQuery } from "@apollo/client";
import { getCountryStates } from "operations/queries/setting";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { shouldShowStateField } from "utils/global";

type CountryType = {
  global: {
    country: string;
  };
};

type StateInfo = {
  code: string;
  name: string;
};

export function useCountryStates() {
  const { i18n } = useTranslation();
  const { country: country } = useSelector(
    (state: CountryType) => state.global,
  );
  const showState = shouldShowStateField(country || "US");

  const { data: countryStatesData, loading: countryStatesLoading } = useQuery(
    getCountryStates,
    {
      variables: {
        country: country,
        locale: i18n.language,
      },
      skip: !showState,
    },
  );
  const states = countryStatesData?.country_states?.map((state: StateInfo) => ({
    value: state.code,
    label: state.name,
  }));
  return {
    data: { states },
    loading: countryStatesLoading,
  };
}
