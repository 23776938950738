import { Flex, Text } from "@chakra-ui/react";
import { VIndividualIcon } from "@springcare/verdant-icons-react";

const CameraPlaceholder = ({ borderRadius = "8px", initials = null }) => {
  return (
    <Flex
      w="full"
      h="full"
      position="relative"
      align="center"
      justify="center"
      bg="black"
      borderRadius={borderRadius}
      role="img"
      aria-label={
        initials ? `Participant initials: ${initials}` : "Participant icon"
      }
    >
      <Flex
        align="center"
        justify="center"
        borderRadius="full"
        bg="content-media-disabled"
        w="56px"
        h="56px"
      >
        {initials ? (
          <Text color="white">{initials}</Text>
        ) : (
          <VIndividualIcon color="white" aria-hidden={true} />
        )}
      </Flex>
    </Flex>
  );
};

export default CameraPlaceholder;
