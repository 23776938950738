import {
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  Flex,
  IconButton,
} from "design-system/components";
import { CompactPageHeader } from "@springcare/sh-component-library";
import { VXIcon } from "@springcare/verdant-icons-react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick?: () => void;
  bodyDisplay?: string;
  children?: React.ReactNode;
  headerItems?: React.ReactNode[];
  mobileHeaderItems?: Array<{}>;
  styles?: {};
};

export const SpecialtyCareModal = ({
  isOpen,
  onClose,
  onBackClick,
  bodyDisplay = "block",
  children,
  headerItems,
  mobileHeaderItems,
  styles,
}: Props) => {
  function headerActions() {
    return [
      <Flex alignItems="center" width="100%" gap={8}>
        {headerItems?.map((item) => item)}
        <IconButton
          onClick={onClose}
          borderRadius={8}
          bg="accent-subtle"
          color="content-primary"
          aria-label="Close Modal"
          icon={<VXIcon fontSize={"18px"} />}
          size={"sm"}
          h={40}
          w={40}
        />
      </Flex>,
    ];
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />
      <ModalContent css={{ ...styles }}>
        <CompactPageHeader
          actions={headerActions()}
          actionsMobile={mobileHeaderItems}
          backstack={!!onBackClick}
          onBackClick={onBackClick}
        />
        <ModalBody box-shadow="none" display={bodyDisplay}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
