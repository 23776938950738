import { gql } from "@apollo/client";

const updateAgedOutRecord = gql`
  mutation updateAgedOutRecord($member_id: ID!) {
    updateAgedOutRecord(member_id: $member_id) {
      aged_out_record {
        hipaa_agreed_at
        terms_of_service_agreed_at
        privacy_policy_agreed_at
        informed_consent_agreed_at
        electronic_comm_consent_agreed_at
        health_info_consent_agreed_at
        updated_at
      }
    }
  }
`;

export default updateAgedOutRecord;
