import { useContext } from "react";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import { getFindNewCareCardConfig } from "components/templates/CareVisitsPage/components/Tabs/helpers";

import type { FindNewCareCardsType } from "components/templates/CareVisitsPage/components/Tabs/types";

export const useGetFindNewCareCards = (): FindNewCareCardsType => {
  const {
    showGlobalExperience,
    isAMinor,
    isMemberUnderSix,
    showSUDCopy: showSUDCNCard,
    isCoachingEnabled: showCoachingCard,
  } = useContext(CareContextValues);
  const showTherapyCard = !isMemberUnderSix;
  const showMMCard = !showGlobalExperience && !isAMinor;
  const cardConfig = getFindNewCareCardConfig(isAMinor);
  const cards = [];

  showTherapyCard && cards.push(cardConfig.THERAPIST);
  showCoachingCard && cards.push(cardConfig.COACH);
  showMMCard && cards.push(cardConfig.MEDICATION_MANAGER);
  showSUDCNCard && cards.push(cardConfig.SUD_CN);

  return cards;
};
