// 

import React from 'react';
import classnames from 'classnames';

import { clickableDivProps } from '../../../lib/accessibility_helpers';

import styles from './styles.module.scss';



const Tag = (props) => {
	if (props.checkbox) {
		return (
			<label
				className={classnames(styles.tag, styles[props.color], {
					[styles.clickable]    : props.onClick,
					[styles.checkboxTag]  : props.checkbox,
					[styles.selected]     : props.checked,
					[styles.large]        : props.large,
					[styles.rounded]      : props.rounded,
					[styles.normalWeight] : props.normalWeight,
					[styles.noBorder]     : props.noBorder,
					[styles.selected]     : props.selected,
				})}
				htmlFor={props.labelFor}
				onClick={props.onClick}
			>
				{props.checkbox}
				{props.text}
			</label>
		);
	}

	return (
		<div
			className={classnames(styles.tag, styles[props.color], {
				[styles.clickable]    : props.onClick,
				[styles.reverse]      : props.reverse,
				[styles.italic]       : props.italic,
				[styles.withIcon]     : props.icon,
				[styles.large]        : props.large,
				[styles.rounded]      : props.rounded,
				[styles.normalWeight] : props.normalWeight,
				[styles.noBorder]     : props.noBorder,
				[styles.selected]     : props.selected,
			})}
			onClick={props.onClick}
			{...clickableDivProps({ onClick: props.onClick })}
			data-cy={props.dataCy ? `${props.dataCy}-tag` : ''}
		>
			{props.text}
			{props.icon && <span className={styles.icon} {...clickableDivProps({ onClick: props.closeClick })} onClick={props.closeClick}>{props.icon}</span>}
		</div>
	);
};

export default Tag;
