import { gql } from "@apollo/client";

const getUserSubscriptions = gql`
  query getUserSubscriptions($id: ID!) {
    user(id: $id) {
      subscriptions {
        appointment_reminders_email {
          name
          id
          subscribed
          title
          description
        }
        progress_checkins_email {
          name
          id
          subscribed
          title
          description
        }
        news_and_announcements_email {
          name
          id
          subscribed
          title
          description
        }
      }
    }
  }
`;

export default getUserSubscriptions;
