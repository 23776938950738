// 

import React from 'react';
import classnames from 'classnames';


import styles from './styles.module.scss';


const Stout = (props) => (
	<div
		className={classnames(styles.stout, {
			[styles[props.status]] : props.status,
			[styles.semibold]      : props.semibold,
			[styles.lower]         : !props.uppercase,
			[styles.italic]        : props.italic,
			[styles.underline]     : props.underline,
			[styles.center]        : props.center,
			[styles.noMargin]      : props.noMargin,
			[styles.inheritColor]  : props.inheritColor,
			[styles.inline]        : props.inline,
			[styles.dark]          : props.dark,
			[styles.medium]        : props.medium,
			[styles.regular]       : props.regular,
			[styles.noFontColor]   : props.inherit,
		})}
	>
		{props.children}
	</div>
);

Stout.defaultProps = {
	uppercase: true,
};

export default Stout;
