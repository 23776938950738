/* eslint-disable react/prop-types */
// @flow
import { Link } from "components/atoms";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { Stout, Badge } from "@spring/smeargle";
import { useRouter } from "next/router";
import routes from "routes";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { mobileAndTabletCheck } from "utils/global";
import { Badge as VBadge } from "@springcare/sh-component-library";
import { HStack } from "@chakra-ui/react";

const LinkItem = ({ data, PAGE_NAME, open, isWorkplacePageViewed }) => {
  const { t } = useTranslation(["common", "a11y"]);
  const isActive = (alias) => {
    const location = window.location.pathname;
    return routes[alias].as === location;
  };
  const router = useRouter();

  const WebLink = ({ data, isWorkplacePageViewed }) => {
    const isNewWorkplaceManagerNavLink = (data) => {
      let showNewBadge = false;
      if (data.alias === "WorkplaceManager" && !isWorkplacePageViewed) {
        showNewBadge = true;
      }
      return showNewBadge;
    };

    const showNewBadge = isNewWorkplaceManagerNavLink(data);
    return (
      <Stout>
        <div
          className={classnames({
            [styles.active_selected]: router.asPath === data.path,
            [styles.navText]: router.asPath !== data.path,
          })}
        >
          {data.navText === "Moments" && (
            <span
              className={classnames({
                [styles.active]: router.asPath === data.path,
              })}
            ></span>
          )}
          {showNewBadge ? (
            <HStack>
              <div>{t(data.translatedText)}</div>
              <VBadge colorScheme="positive">{t("grammar.new")}</VBadge>
            </HStack>
          ) : (
            t(data.translatedText)
          )}
        </div>
      </Stout>
    );
  };

  const MobileLink = (props) => (
    <>
      <span
        className={classnames(styles.icon, {
          [styles.active]: isActive(props.alias),
        })}
      >
        {props.icon}
      </span>
      <span
        className={classnames(styles.link, {
          [styles.active]: isActive(props.alias),
        })}
      >
        {t(props.text)}
      </span>
      <div className={styles.badge}>
        <Badge count={props.count} color="secondary" hideEmpty />
      </div>
    </>
  );

  return (
    <Link
      alias={data.alias}
      ariaLabel={
        mobileAndTabletCheck()
          ? t(data.text)
          : `${t("action.linkTo", { ns: "a11y", linkName: `${t(data.translatedText)}` })}`
      }
      aria-current={
        mobileAndTabletCheck()
          ? isActive(data.alias)
          : router.asPath === data.path
      }
      mpTracking={{
        pageName: mobileAndTabletCheck() ? "Top Navigation" : PAGE_NAME,
        page: window.location.pathname,
        type: data.trackingText,
        linkName: data.linkName,
        location: mobileAndTabletCheck()
          ? open
            ? "Left Nav Bar Expanded"
            : "Left Nav Bar"
          : "Header",
        spring_doc_id: data.spring_doc_id,
      }}
      className={classnames({
        [styles.active]: mobileAndTabletCheck() && isActive(data.alias),
        [styles.sidebarLink]: mobileAndTabletCheck(),
      })}
      dataCy={data.alias}
    >
      {mobileAndTabletCheck() ? (
        <MobileLink key={data.text} {...data} />
      ) : (
        <WebLink
          key={data.translatedText}
          data={data}
          PAGE_NAME={PAGE_NAME}
          isWorkplacePageViewed={isWorkplacePageViewed}
        />
      )}
    </Link>
  );
};

export default connect((state) => ({
  open: state.memberDashboard?.sidebarOpen,
}))(LinkItem);
