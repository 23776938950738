//

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import styles from "components/templates/MemberDashboard/Icons/styles.module.scss";

const MomentsSidebar = (props) => (
  <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className={classnames(styles.icon, {
        [styles.active]: props.active,
      })}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.026 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm.733 2.92a4 4 0 1 0-6.152-.844c-3.03.349-5.319 1.852-5.56 3.916-.208 1.782 1.16 3.536 3.377 4.686-3.264.886-5.475 2.618-5.423 4.563.074 2.744 4.622 4.873 10.159 4.754 5.537-.118 9.965-2.438 9.892-5.182-.044-1.649-1.704-3.075-4.224-3.926 1.233-.702 2.04-1.699 2.178-2.88.234-2.003-1.526-3.973-4.247-5.086Zm.677 6.434c1.19-.541 1.537-1.182 1.583-1.578.048-.406-.15-1.13-1.193-1.962-1.01-.805-2.58-1.486-4.478-1.726-1.899-.24-3.575.032-4.731.558-1.19.541-1.537 1.182-1.583 1.578-.048.406.15 1.13 1.193 1.962 1.01.805 2.58 1.486 4.478 1.726 1.899.24 3.574-.032 4.731-.558Zm1.846 7.263c1.584-.83 1.776-1.557 1.77-1.75-.005-.202-.246-.93-1.879-1.693-1.505-.704-3.71-1.17-6.237-1.116-2.528.055-4.703.613-6.165 1.38-1.584.83-1.776 1.556-1.77 1.75.005.201.246.928 1.878 1.692 1.506.705 3.71 1.17 6.238 1.116 2.528-.054 4.703-.613 6.165-1.379Z"
      fill="#000"
    />
  </svg>
);

MomentsSidebar.propTypes = {
  active: PropTypes.any,
};

export default MomentsSidebar;
