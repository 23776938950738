import { Box, Flex, Text } from "@chakra-ui/react";
import { getOr } from "lodash/fp";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useInViewportOnce } from "hooks/useInViewport";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";

import { CareProviderList } from "./components/CareProviderList";
import SDOHCards from "./components/SDOHCards";
import {
  getIsUniqueWPMCHCopy,
  getIsUniqueCCACopy,
} from "utils/employerServicesHelpers";

import { NeurodiversitySection } from "modules/MemberDashboard/Neurodiversity/components/NeurodiversitySection";
import { useNeurodiversityPagesAccess } from "modules/MemberDashboard/Neurodiversity/hooks/useNeurodiversityPagesAccess";

export const FindYourCareTab = () => {
  const { memberInfo, setIsFindNewCareTab } = useContext(CareContextValues);
  const { t } = useTranslation("careVisits");
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");

  const member = getOr(memberInfo, "user.member", memberInfo); // check if nested member, else use top level object
  const {
    care_advocate_supported: careAdvocateSupported,
    care_consultant_supported: careConsultantSupported,
    workplace_consultation_supported: workplaceConsultationsSupported,
  } = member || {};
  const shouldShowSDOHCards =
    careAdvocateSupported ||
    careConsultantSupported ||
    workplaceConsultationsSupported;
  const customerId = member.cohort?.customer?.id;
  const isUniqueWPMHCCopy = getIsUniqueWPMCHCopy(customerId);

  const isUniqueCCACopy = getIsUniqueCCACopy(customerId);

  const { canAccessNeurodiversityPage } = useNeurodiversityPagesAccess();

  useEffect(() => {
    if (inViewport) {
      setIsFindNewCareTab(true);
    } else {
      setIsFindNewCareTab(false);
    }
  }, [inViewport]);

  return (
    <Flex flexDirection="column" ref={ref}>
      {(shouldShowSDOHCards || canAccessNeurodiversityPage) && (
        <Box>
          <Text size="body-medium-strong" mb={10}>
            {t("findNewCareTab.careProviderList.title")}
          </Text>
        </Box>
      )}
      <CareProviderList />
      {shouldShowSDOHCards && (
        <Box mt={40}>
          <Text size="body-medium-strong" mb={10}>
            {isUniqueCCACopy || isUniqueWPMHCCopy
              ? t(`findNewCareTab.sdohCards.cards.${customerId}.title`)
              : t("findNewCareTab.sdohCards.cards.default.title")}
          </Text>
          <SDOHCards
            careAdvocateSupported={careAdvocateSupported}
            careConsultantSupported={careConsultantSupported}
            workplaceConsultationsSupported={workplaceConsultationsSupported}
            customerId={customerId}
          />
        </Box>
      )}
      {/*
        IMPORTANT: The neurodiversity pages should NOT be accessible in the prod env.
        They are vaporware and are only intended for our sales team to use in the demo env.
        See https://springhealth.atlassian.net/browse/MXE-520 for more detail.
      */}
      {canAccessNeurodiversityPage && <NeurodiversitySection />}
    </Flex>
  );
};
