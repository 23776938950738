import { links } from "@spring/constants";

import { ConsentLinkType } from "./types";

// Helper methods for doing i18n namespace lookups
const getErrorKey = (key: string) => getTransKey(key);
const getTransKey = (key: string) =>
  `limitedLangRegister:createAccount.form.consents.${key}`;

export const electronicCommConsent = {
  errorKey: getErrorKey("electronicCommConsent"),
  formKey: "electronic_comm_consent",
  link: {
    docId: "register038",
    name: "Electronic Communication Consent",
    url: links.ElectronicCommunicationAgreement,
  },
  transKey: getTransKey("electronicCommConsent"),
} as const;

export const healthInfoConsent = {
  errorKey: getErrorKey("healthInfoConsent"),
  formKey: "health_info_consent",
  link: {
    docId: "register039",
    name: "Health Information Consent",
    url: links.HealthInformationConsent,
  },
  transKey: getTransKey("healthInfoConsent"),
} as const;

export const hipaaConsent = {
  errorKey: getErrorKey("hipaaConsent"),
  formKey: "hipaa_privacy_policy",
  link: {
    docId: "register040",
    name: "HIPAA",
    url: links.PrivacyNotice,
  },
  transKey: getTransKey("hipaaConsent"),
} as const;

export const informedConsent = {
  errorKey: getErrorKey("informedConsent"),
  formKey: "informed_consent",
  link: {
    docId: "register037",
    name: "Informed Consent",
    url: null,
  },
  transKey: getTransKey("informedConsent"),
} as const;

export const privacyPolicy = {
  errorKey: getErrorKey("privacyConsent"),
  formKey: "privacy_policies",
  link: {
    docId: "register036",
    name: "Privacy Policy",
    url: links.PrivacyPolicy,
  },
  transKey: getTransKey("privacyPolicy"), // this is a different name than the error key for some reason
} as const;

// no form or error keys since ToS and Privacy are combined in the same checkbox
export const termsOfService = {
  errorKey: null,
  formKey: null,
  link: {
    docId: "register035",
    name: "Terms of Service",
    url: links.TermsOfService,
  },
  transKey: getTransKey("tos"),
} as const;

/*
 * Fields:
 *   electronic_comm_consent - Global
 *   health_info_consent - Global
 *   hipaa_privacy_policy - US
 *   informed_consent - US & Global
 *   privacy_policies - US & Global
 */
export const BOTH: ConsentLinkType[] = [
  termsOfService,
  privacyPolicy,
  informedConsent,
];
export const GLOBAL_ONLY: ConsentLinkType[] = [
  electronicCommConsent,
  healthInfoConsent,
];
export const US_ONLY: ConsentLinkType[] = [hipaaConsent];

export const GLOBAL: ConsentLinkType[] = [...BOTH, ...GLOBAL_ONLY];
export const US: ConsentLinkType[] = [...BOTH, ...US_ONLY];
