export const Time = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#058261" fill-opacity="0.12" />
      <g clip-path="url(#clip0_654_12618)">
        <path
          d="M12.75 7.5C12.75 7.08579 12.4142 6.75 12 6.75C11.5858 6.75 11.25 7.08579 11.25 7.5V11.6893L9.21967 13.7197C8.92678 14.0126 8.92678 14.4874 9.21967 14.7803C9.51256 15.0732 9.98744 15.0732 10.2803 14.7803L12.5303 12.5303C12.671 12.3897 12.75 12.1989 12.75 12V7.5Z"
          fill="#045642"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM7.05055 6.36489C8.19498 5.35892 9.64838 4.69605 11.25 4.53703V5.25C11.25 5.66421 11.5858 6 12 6C12.4142 6 12.75 5.66421 12.75 5.25V4.53703C14.3516 4.69605 15.805 5.35893 16.9495 6.36491L16.4545 6.85986C16.1616 7.15276 16.1616 7.62763 16.4545 7.92052C16.7474 8.21342 17.2223 8.21342 17.5152 7.92052L17.9726 7.46307C18.7888 8.53581 19.3225 9.83506 19.463 11.25H18.75C18.3358 11.25 18 11.5858 18 12C18 12.4142 18.3358 12.75 18.75 12.75H19.463C19.3225 14.1649 18.7888 15.4642 17.9726 16.537L17.5152 16.0795C17.2223 15.7866 16.7474 15.7866 16.4545 16.0795C16.1616 16.3724 16.1616 16.8473 16.4545 17.1402L16.9494 17.6351C15.805 18.6411 14.3516 19.3039 12.75 19.463V18.75C12.75 18.3358 12.4142 18 12 18C11.5858 18 11.25 18.3358 11.25 18.75V19.463C9.64839 19.304 8.195 18.6411 7.05058 17.6351L7.54551 17.1402C7.83841 16.8473 7.83841 16.3724 7.54551 16.0795C7.25262 15.7866 6.77775 15.7866 6.48485 16.0795L6.02741 16.537C5.21126 15.4642 4.67752 14.165 4.53703 12.75H5.25C5.66421 12.75 6 12.4142 6 12C6 11.5858 5.66421 11.25 5.25 11.25H4.53703C4.67752 9.83505 5.21125 8.53579 6.02739 7.46305L6.48486 7.92052C6.77776 8.21342 7.25263 8.21342 7.54552 7.92052C7.83842 7.62763 7.83842 7.15276 7.54552 6.85986L7.05055 6.36489Z"
          fill="#045642"
        />
      </g>
      <defs>
        <clipPath id="clip0_654_12618">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
