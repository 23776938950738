import React, { FC, useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";

type EnvDetailsProps = {
  environment: string;
  apiUrl: string;
  authApiUrl: string;
};

// Display localStorage items from Magic Env Toggle first, then default to .env values if provided
export const EnvDetails: FC<React.PropsWithChildren<EnvDetailsProps>> = ({
  environment,
  apiUrl,
  authApiUrl,
}) => {
  const [rotom, setRotom] = useState("");
  const [diglett, setDiglett] = useState("");
  const [pikachu, setPikachu] = useState("");

  if (environment !== "dev") {
    return null;
  }

  const isWindowDefined = typeof window !== "undefined";

  useEffect(() => {
    if (isWindowDefined) {
      setRotom(localStorage.getItem("rotom"));
      setDiglett(localStorage.getItem("diglett"));
      setPikachu(localStorage.getItem("pikachu"));
    }
  }, [isWindowDefined]);

  const rotomURL = rotom || apiUrl;
  const authURL = diglett || pikachu || authApiUrl;

  return (
    <Box
      role="complementary"
      position="absolute"
      top="50px"
      zIndex={1000}
      ps="v-12"
    >
      <Box backgroundColor="background-base">
        <Text color="chart-diverging-positive-negative-n50">
          Rotom: {rotomURL}
        </Text>
        <Text color="chart-diverging-positive-negative-n50">
          Auth: {authURL}
        </Text>
      </Box>
    </Box>
  );
};
