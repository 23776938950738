//

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';

import Icon from '../../atoms/Icon';
import Floaty from '../../layout/Floaty';
import FlexRow from '../../layout/FlexRow';

import styles from './styles.module.scss';


class ButtonBase extends PureComponent {
	static defaultProps = {
		radius     : 3,
		color      : 'success',
		debounce   : 0,
		iconOnLeft : false,
		onClick    : () => { },
	}

	constructor(props) {
		super(props);

		if (props.debounce) {
			this.onClick = debounce(
				props.onClick,
				props.debounce,
				{
					maxWait  : props.debounce,
					leading  : true,
					trailing : false,
				}
			);
		}
	}

	render() {
		const {
			active,
			ariaLabel,
			className,
			wrapperClassName,
			color,
			customIcon,
			debounce,
			disabled,
			flat,
			full,
			icon,
			iconOnLeft,
			mini,
			onClick,
			pagination,
			radius,
			reverse,
			secondary,
			text,
			transparent,
			dataCy,
			...props
		} = this.props;
		return (
			<div
				className={classnames(styles.wrapper, wrapperClassName, {
					[styles.full]: full,
				})}
			>
				<Floaty
					radius={radius}
					float={(disabled || flat) ? 0 : 1}
					hover={(disabled || flat) ? 0 : 2}
				>
					<button
						{...props}
						className={classnames(styles.base, className, {
							[styles[color]]                                     : color,
							[styles.disabled]                                   : disabled,
							[styles.secondary]                                  : secondary,
							[styles.pagination]                                 : pagination,
							[styles.reverse]                                    : reverse,
							[styles.full]                                       : full,
							[styles.mini]                                       : mini,
							[styles.transparent]                                : transparent,
							[styles.active]                                     : active,
							[styles.iconOnLeft]                                 : iconOnLeft,
							[styles[`padding-${this.props.padding}`]]           : this.props.padding,
							[styles[`borderRadius-${this.props.borderradius}`]] : this.props.borderradius,
						})}
						disabled={disabled}
						data-cy={dataCy ? dataCy + '-button' : ''}
						aria-label={this.props.ariaLabel}
						onClick={debounce ? this.onClick : onClick}
					>

						<FlexRow alignment="center" justification="center">
							{icon && iconOnLeft && (
								<span className={styles.icon}>
									<Icon type={icon} />
								</span>
							)}


							{customIcon && iconOnLeft && (
								<span className={styles.customIcon}>
									{customIcon}
								</span>
							)}

							{text && (icon === 'phone' ? (
								<span className={styles.text}>
									&lrm;{text}
								</span>) : (
								<span className={styles.text}>
									{text}
								</span>
							))}

							{icon && !iconOnLeft && (
								<span className={styles.icon}>
									<Icon type={icon} />
								</span>
							)}


							{customIcon && !iconOnLeft && (
								<span className={styles.customIcon}>
									{customIcon}
								</span>
							)}
						</FlexRow>
					</button>
				</Floaty>
			</div>
		);
	}
}

export default ButtonBase;

