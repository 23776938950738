import { register } from "utils/mixpanel";

export const getIterableCampaignInfo = () => {
  let iterableCampaignId: string | number = localStorage.getItem(
    "iterable_campaign_id",
  );
  let iterableTemplateId: string | number = localStorage.getItem(
    "iterable_template_id",
  );

  iterableCampaignId = iterableCampaignId ? Number(iterableCampaignId) : null;
  iterableTemplateId = iterableTemplateId ? Number(iterableTemplateId) : null;

  return {
    campaign_id: iterableCampaignId,
    template_id: iterableTemplateId,
  };
};

export const updateIterableCampaignInfo = (router) => {
  const iterable_campaign_id = router?.query?.campaign_id ?? null;
  const iterable_template_id = router?.query?.template_id ?? null;
  const has_any_other_utm =
    router?.query?.utm_campaign ||
    router?.query?.utm_source ||
    router?.query?.utm_medium ||
    router?.query?.utm_term ||
    router?.query?.utm_content
      ? true
      : false;

  //unset iterable tracking if under a campaign with no iterable ids
  if (has_any_other_utm && !iterable_campaign_id && !iterable_template_id) {
    deleteIterableCampaignInfo();
    register({
      itcid: null,
      ittid: null,
    });
  }
  //if different - update local storage and mixpanel cookie with new values
  else if (iterable_campaign_id || iterable_template_id) {
    if (localStorage.getItem("iterable_campaign_id") !== iterable_campaign_id) {
      localStorage.setItem("iterable_campaign_id", iterable_campaign_id);
      register({ itcid: iterable_campaign_id });
    }
    if (localStorage.getItem("iterable_template_id") !== iterable_template_id) {
      localStorage.setItem("iterable_template_id", iterable_template_id);
      register({ ittid: iterable_template_id });
    }
  }
};

export const deleteIterableCampaignInfo = () => {
  localStorage.removeItem("iterable_campaign_id");
  localStorage.removeItem("iterable_template_id");
};

// Should check to make sure this is removed once the user logs out. Clarify if that's part of our the intended functionality as well.
