import routes from "routes/index";
import { isMinorNonTeen, isTeen } from "utils/memberHelpers";

export const getHomeRoute = (
  memberDateOfBirth: string,
): { to: string; as: string } => {
  const isATeen = isTeen(memberDateOfBirth);
  if (isATeen) return routes.TeenHome;

  const isAMinorNonTeen = isMinorNonTeen(memberDateOfBirth);
  if (isAMinorNonTeen) return routes.MemberCareVisits;

  return routes.MemberHome;
};
