import { LazyQueryHookOptions, useLazyQuery } from "@apollo/client";
import { useMember } from "context/MemberContext";
import { findCoveredLife } from "operations/queries/user";

export const useLazyFindCoveredLife = (options?: LazyQueryHookOptions) => {
  // sets coveredLifeId in MemberContext to allow for fetching getCoveredLifeInfo
  const { setCoveredLifeId } = useMember();

  const [getFindCoveredLife, { loading, data, error }] = useLazyQuery(
    findCoveredLife,
    {
      onCompleted: (data) =>
        setCoveredLifeId(data?.find_covered_life?.covered_life_id),
      ...options,
    },
  );

  return { getFindCoveredLife, loading, data, error };
};
