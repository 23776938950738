import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Col,
  Grid,
  Content,
  Section,
  FlexRow,
  Icon,
  MomentsIcon,
  SubstanceUseSmallIcon,
} from "@spring/smeargle";
import { useTranslation, Trans } from "react-i18next";

import styles from "./styles.module.scss";
import { Heading } from "design-system/components";

const BenefitsSnapshot = (props) => {
  const { t } = useTranslation("benefitsSnapshot");
  const sudSupported = props?.sudSupported;

  return (
    <Grid>
      <Col md={6}>
        <Section size="sm">
          <div className={styles.titleWrapper}>
            <Heading as="h3" fontSize={16}>
              {t("whatsAvailableTitle")}
            </Heading>
          </div>
        </Section>
        {props.benefits.map(({ values, ...benefit }) => {
          const copy = (
            <Content>
              <div className={styles.iconWrapper}>{benefit.icon}</div>
              <div>
                <Trans
                  ns={benefit.translation.ns}
                  i18nKey={benefit.translation.key}
                  values={values}
                  components={[
                    <Heading
                      key={`${benefit.translation.key}`}
                      fontSize={16}
                      color="#4b4b4b"
                      fontWeight={"bold"}
                      display={"inline"}
                    ></Heading>,
                  ]}
                />
              </div>
            </Content>
          );

          return (
            <FlexRow key={benefit.text} className={styles.benefitContainer}>
              <div className={styles.benefitContainer}>
                <div className={styles.iconWrapper}>
                  <Icon type={benefit.icon} color="PRIMARY_500" />
                </div>
                {copy}
              </div>
            </FlexRow>
          );
        })}
        <FlexRow>
          <div className={styles.benefitContainer}>
            <div className={styles.iconWrapper}>
              <Icon type="heart" color="PRIMARY_500" />
            </div>
            <Content>{t("bulletPoints.talkToCareNavigator")}</Content>
          </div>
        </FlexRow>
        <FlexRow>
          <div className={styles.benefitContainer}>
            <div className={styles.iconWrapper}>
              <Icon type="trending-up" color="PRIMARY_500" />
            </div>
            <Content>{t("bulletPoints.checkins")}</Content>
          </div>
        </FlexRow>
        <FlexRow>
          <div className={styles.benefitContainer}>
            <div className={classnames(styles.iconWrapper, styles.customIcon)}>
              <MomentsIcon />
            </div>
            <Content>{t("bulletPoints.selfguidedExercises")}</Content>
          </div>
        </FlexRow>
        {sudSupported && (
          <FlexRow>
            <div className={styles.benefitContainer}>
              <div className={styles.iconWrapper}>
                <SubstanceUseSmallIcon />
              </div>
              <Content>{t("bulletPoints.substanceUse")}</Content>
            </div>
          </FlexRow>
        )}
      </Col>
      <Col md={6}>
        <Section size="sm">
          <div className={styles.titleWrapper}>
            <Heading as="h3" fontSize={16}>
              {t("additionalThings.title")}
            </Heading>
          </div>
        </Section>
        <Content>{t("additionalThings.content")}</Content>
      </Col>
    </Grid>
  );
};

BenefitsSnapshot.defaultProps = {
  benefits: [],
  sudSupported: false,
};

BenefitsSnapshot.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.node,
      icon: PropTypes.string,
    }),
  ),
  sudSupported: PropTypes.bool,
};

export default BenefitsSnapshot;
