import { useQuery } from "@apollo/client";
import { getAppointmentSlotsV2 } from "operations/queries/appointment";
import { DateTime } from "luxon";

export const useGetAppointmentSlot = (
  providerInfo,
  memberInfo,
  appointmentTime,
  appointmentKind,
  appointmentMedium,
) => {
  const end_span = DateTime.fromISO(appointmentTime)
    .plus({ minutes: 59 })
    .toISO();

  const payload = {
    upcoming_slots_count: 1,
    user_ids: {
      member_id: memberInfo?.user?.id,
      care_provider_id: providerInfo?.care_provider?.user_id,
    },
    kind: appointmentKind,
    medium: appointmentMedium,
    upcoming_slots_only: true,
    range: {
      start_span: appointmentTime,
      end_span: end_span,
    },
  };

  return useQuery(getAppointmentSlotsV2, {
    variables: payload,
    fetchPolicy: "network-only",
    skip:
      !memberInfo ||
      !providerInfo ||
      !appointmentTime ||
      !appointmentKind ||
      !appointmentMedium,
  });
};
