//

import React from "react";
import { connect } from "react-redux";
import { getOr, get } from "lodash/fp";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { Modal, Button, Subtitle, Headline } from "@spring/smeargle";
import { addNotification, closeModal } from "@spring/smeargle/actions";
import {
  modalIds,
  RequestableAppointmentKind,
  AppointmentMedium,
} from "@spring/constants";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { MemberConsent } from "components/molecules";

import styles from "./styles.module.scss";

import { requestAppointment } from "operations/mutations/appointment";
import { updateMember } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";
import envUtils from "utils/environment";
import { memberConsentSelector } from "selectors/form";

class BeforeRequestMedicationModal extends React.Component {
  submit = async () => {
    try {
      const requestAppointmentPayload = {
        care_provider_id: this.props.provider.data.id,
        availability_days_of_week: ["Unknown"],
        availability_time_of_day: ["Unknown"],
        medium: AppointmentMedium.Video,
        kind: RequestableAppointmentKind.MedicationManagement,
      };

      const updateMemberPayload = {
        id: this.props.formData.data.id,
        patch: {
          hipaa_privacy_policy: this.props.formData.data.hipaa_privacy_policy,
          informed_consent: this.props.formData.data.informed_consent,
        },
      };

      const [updateMemberResponse, requestAppointmentResponse] =
        await Promise.all([
          this.props.updateMember(updateMemberPayload),
          this.props.requestAppointment(requestAppointmentPayload),
        ]);

      const {
        data: { updateMember },
      } = await updateMemberResponse;
      const {
        data: { requestAppointment },
      } = await requestAppointmentResponse;

      const { t } = this.props;

      if (!updateMember.success || !requestAppointment.success) {
        const { careTeamEmail } = envUtils;
        const message = (
          <Trans
            ns={"careProvider"}
            i18nKey={"BeforeRequestMedicationModal.notificationMessage.error"}
          >
            Oh no! We were not able to put this request through. Please reach
            out to {{ careTeamEmail }} with the name of the Medication Manager
            you would like to schedule with, and a Care Navigator will assist
            you.
          </Trans>
        );
        return this.props.addNotification(message, "error", 0);
      }

      this.props.addNotification(
        t("BeforeRequestMedicationModal.notificationMessage.success"),
        "success",
      );
      return this.props.closeModal(modalIds.beforeRequestMedicationModal);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return this.props.addNotification(getFirstError(err), "error");
    }
  };

  render() {
    return (
      <Modal size="md" id={modalIds.beforeRequestMedicationModal}>
        <Headline>
          {this.props.t("BeforeRequestMedicationModal.headline")}
        </Headline>
        <Subtitle>
          {this.props.t("BeforeRequestMedicationModal.subtitle")}
        </Subtitle>
        <MemberConsent />
        <div className={styles.buttonContainer}>
          <Button
            text={this.props.t("BeforeRequestMedicationModal.submitText")}
            onClick={this.submit}
            disabled={!get("validation.isValid", this.props.formData)}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  formData: memberConsentSelector(state),
  provider: getOr({}, `${modalIds.beforeRequestMedicationModal}`, state.modal),
});

const mapDispatchToProps = {
  addNotification,
  closeModal,
};

BeforeRequestMedicationModal.propTypes = {
  addNotification: PropTypes.func,
  closeModal: PropTypes.func,
  formData: PropTypes.shape({
    data: PropTypes.shape({
      hipaa_privacy_policy: PropTypes.any,
      id: PropTypes.any,
      informed_consent: PropTypes.any,
    }),
  }),
  provider: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  requestAppointment: PropTypes.func,
  t: PropTypes.func,
  updateMember: PropTypes.func,
};

export default compose(
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (data) =>
        mutate({ variables: { input: { ...data } } }),
    }),
  }),
  graphql(updateMember, {
    props: ({ mutate }) => ({
      updateMember: (data) => mutate({ variables: { input: { ...data } } }),
    }),
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(withTranslation("careProvider")(BeforeRequestMedicationModal));
