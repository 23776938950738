import { createIcon } from "@chakra-ui/react";

export const ClosedEye = createIcon({
  displayName: "ClosedEye",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9404 9.34077C22.1283 8.82142 21.8595 8.24811 21.3402 8.06026C20.8208 7.87241 20.2475 8.14115 20.0597 8.66051C18.9503 11.7277 15.791 14.0006 12 14.0006C8.2091 14.0006 5.0498 11.7277 3.9404 8.66052C3.75255 8.14116 3.17925 7.87243 2.65989 8.06028C2.14054 8.24813 1.8718 8.82143 2.05965 9.34079C2.9266 11.7376 4.7466 13.6998 7.08142 14.8588L6.13389 16.5C5.85774 16.9783 6.02162 17.5899 6.49991 17.866C6.97821 18.1421 7.5898 17.9783 7.86594 17.5L8.97123 15.5856C9.62387 15.7691 10.3025 15.8948 10.9998 15.9566V18C10.9998 18.5523 11.4476 19 11.9998 19C12.5521 19 12.9998 18.5523 12.9998 18V15.9566C13.7047 15.8942 14.3904 15.7665 15.0495 15.5797C15.0707 15.6439 15.0987 15.7069 15.1338 15.7678L16.1338 17.4998C16.41 17.9781 17.0215 18.142 17.4998 17.8658C17.9781 17.5897 18.142 16.9781 17.8659 16.4998L16.9185 14.8589C19.2534 13.6999 21.0734 11.7377 21.9404 9.34077Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    "aria-label": "Closed eye icon",
    role: "img",
  },
});
