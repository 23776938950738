//

import PropTypes from "prop-types";
import React from "react";
import Router from "next/router";
import { useMutation } from "@apollo/client";
import {
  Icon,
  Grid,
  Col,
  GooglePlay,
  AppStore,
  Content,
  Bolded,
  Section,
  LinkButton,
} from "@spring/smeargle";
import { links } from "@spring/constants";
import classnames from "classnames";
import { useTranslation, Trans } from "react-i18next";

import styles from "./styles.module.scss";

import routes from "routes";
import { updateMemberExperienceState } from "operations/mutations/member";
import { track } from "utils/mixpanel";

const MomentsBanner = (props) => {
  const [useUpdateMemberExperienceState] = useMutation(
    updateMemberExperienceState,
    {
      variables: {
        member_id: props.memberId,
        banner_moments_count: 1,
      },
    },
  );
  const { t } = useTranslation("moments");

  return (
    <div className={classnames(styles.wrapper)}>
      <div className={styles.background}>
        <Grid gutter="0 28px">
          <Col lg={5}>
            <img
              className={styles.mobileBanner}
              src={
                links.SpringCDN +
                "/assets/images/members/moments-preview-banner-small_no-fade.png"
              }
            ></img>
          </Col>
          <Col lg={7}>
            <div className={styles.container}>
              <Section size="sm">
                <div className={styles.header}>{t("momentsBanner.title")}</div>
              </Section>
              <div className={styles.momentsText}>
                <Content inheritColor>
                  <Trans
                    i18nKey="momentsBanner.subtitle"
                    components={{ bold: <Bolded>Moments</Bolded> }}
                  />
                </Content>
              </div>
              <Section>
                <div className={styles.mobileLinksContainer}>
                  <span className={styles.mobileLinks}>
                    <GooglePlay
                      link={links.GooglePlayUrl}
                      onClick={() => {
                        track("Moments Pathway - Banner Click - Google Play");
                      }}
                    />
                  </span>
                  <span className={styles.mobileLinks}>
                    <AppStore
                      link={links.AppStoreUrl}
                      onClick={() => {
                        track("Moments Pathway - Banner Click - App Store");
                      }}
                    />
                  </span>
                </div>
                <div className={styles.mobileText}>
                  {t("momentsBanner.mobileText")}
                </div>
              </Section>
            </div>
          </Col>
        </Grid>

        <div className={styles.closeButton}>
          <Icon type="close" onClick={useUpdateMemberExperienceState} />
        </div>

        <div className={styles.ctaLink}>
          <LinkButton
            text={t("momentsBanner.linkButtonText")}
            onClick={() => {
              track("Moments Pathway - Banner - Learn More");
              const tabId = 1;
              const { to, as } = routes.MemberBenefits;

              Router.replace(
                {
                  pathname: to,
                  query: { tabId },
                },
                as,
              );
            }}
            icon="arrow-right"
          />
        </div>
      </div>
    </div>
  );
};

MomentsBanner.propTypes = {
  memberId: PropTypes.any,
};

export default MomentsBanner;
