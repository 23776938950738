import { HStack, Image, Text } from "@chakra-ui/react";
import { Card } from "@springcare/sh-component-library";
import { VVideoChatIcon } from "@springcare/verdant-icons-react";
import { FC } from "react";
import { useTranslation } from "hooks/react-i18next";
import { formatDuration } from "modules/MemberDashboard/ManagerExperience/Workplace/utils";

type ContentCardProps = {
  imageSrc: string;
  imageAltText: string;
  contentType: "textAndAudio" | "video" | "external" | "text" | "audio";
  eyebrowText: string;
  title: string;
  subtitle?: string;
  time?: string | number;
  ariaLabel?: string;
  handleClick: () => void;
  cardWidthSize?: string[];
};

// This is a temporary component while workplace content is available on homepage and moments for
// Manager Experience experiment 6: https://springhealth.atlassian.net/browse/MXE-830
export const ContentCard: FC<ContentCardProps> = ({
  imageSrc,
  imageAltText,
  eyebrowText,
  title,
  subtitle,
  time,
  ariaLabel = "manager-experience-exercises",
  handleClick,
  cardWidthSize = ["100%"],
}) => {
  const { t } = useTranslation("managerExperience");
  return (
    <Card
      variant="no-emphasis"
      padding="none"
      borderRadius="v-lg"
      minW={cardWidthSize}
      maxW={cardWidthSize}
      onClick={handleClick}
      tabIndex={0}
      aria-label={ariaLabel}
      cursor="pointer"
      role="link"
      as="button"
      textAlign="left"
    >
      <>
        <Image
          borderRadius="v-lg"
          src={imageSrc}
          alt={imageAltText}
          w="100%"
          objectFit="cover"
          height={["172px", "172px", "200px"]}
        />
        <Text size="body-small-strong" pt="v-8" color="content-secondary">
          {eyebrowText}
        </Text>

        <Text size="body-medium-strong" color="content-primary" as="h3">
          {title}
        </Text>
        {subtitle && <Text color="content-secondary">{subtitle}</Text>}
        <HStack spacing="v-8" pt="v-4" color="content-secondary">
          <VVideoChatIcon role="graphics-symbol" aria-label="Video content" />
          {time && <Text>{formatDuration(time, t)}</Text>}
        </HStack>
      </>
    </Card>
  );
};
