import { createIcon } from "@chakra-ui/react";

export const SmallStones = createIcon({
  displayName: "SmallStonesV1.1",
  viewBox: "0 0 20 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5145 6.83784C14.4238 6.11325 14.9869 5.10936 14.9869 4C14.9869 1.79086 12.7542 0 10 0C7.24583 0 5.01313 1.79086 5.01313 4C5.01313 4.89342 5.3783 5.71842 5.99545 6.38423C3.79089 6.99943 2.23783 8.30937 2.04177 9.99238C1.83427 11.7736 3.1985 13.5284 5.40998 14.6778C2.15403 15.5635 -0.0511657 17.2959 0.000902654 19.2413C0.0743434 21.9851 4.61063 24.1136 10.133 23.9953C15.6553 23.877 20.0725 21.5568 19.9991 18.8129C19.955 17.1641 18.2994 15.7376 15.7868 14.8872C17.0158 14.1848 17.8207 13.1879 17.9582 12.0076C18.1976 9.95302 16.3457 7.93358 13.5145 6.83784ZM12.9921 4C12.9921 4.71117 12.0909 6 10 6C7.90912 6 7.00788 4.71117 7.00788 4C7.00788 3.28883 7.90912 2 10 2C12.0909 2 12.9921 3.28883 12.9921 4ZM14.3982 13.3542C15.5855 12.8128 15.9307 12.1722 15.9769 11.7756C16.0242 11.3699 15.8274 10.6454 14.7873 9.81419C13.7796 9.00887 12.2133 8.32749 10.3205 8.08784C8.42707 7.84811 6.75553 8.11973 5.60179 8.6458C4.41451 9.18717 4.06925 9.82777 4.02305 10.2244C3.97579 10.6301 4.17259 11.3546 5.21265 12.1858C6.22037 12.9911 7.78674 13.6725 9.67951 13.9122C11.5729 14.1519 13.2445 13.8803 14.3982 13.3542ZM16.2388 20.6171C17.819 19.787 18.0102 19.0597 18.0051 18.8666C17.9997 18.6649 17.7588 17.9379 16.1309 17.1741C14.6294 16.4696 12.4306 16.0044 9.90963 16.0584C7.3883 16.1125 5.21925 16.6713 3.76123 17.4371C2.18098 18.2672 1.98976 18.9945 1.99493 19.1876C2.00033 19.3893 2.24117 20.1163 3.86908 20.8801C5.37058 21.5846 7.56945 22.0498 10.0904 21.9958C12.6117 21.9417 14.7808 21.3829 16.2388 20.6171Z"
    />
  ),
  defaultProps: {
    "aria-label": "Small stones icon",
    role: "img",
  },
});
