import { createIcon } from "@chakra-ui/react";

export const Share = createIcon({
  displayName: "Document",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 4C16.25 1.79086 18.0409 0 20.25 0C22.4591 0 24.25 1.79086 24.25 4C24.25 6.20914 22.4591 8 20.25 8C19.0461 8 17.9664 7.46811 17.2331 6.6265L8.16208 11.162C8.21969 11.4322 8.25 11.7126 8.25 12C8.25 12.2874 8.21969 12.5678 8.16208 12.838L17.2331 17.3735C17.9664 16.5319 19.0461 16 20.25 16C22.4591 16 24.25 17.7909 24.25 20C24.25 22.2091 22.4591 24 20.25 24C18.0409 24 16.25 22.2091 16.25 20C16.25 19.7126 16.2803 19.4322 16.3379 19.162L7.26694 14.6265C6.53363 15.4681 5.45393 16 4.25 16C2.04086 16 0.25 14.2091 0.25 12C0.25 9.79086 2.04086 8 4.25 8C5.45393 8 6.53363 8.53189 7.26694 9.3735L16.3379 4.83801C16.2803 4.56777 16.25 4.28742 16.25 4ZM20.25 2C19.1454 2 18.25 2.89543 18.25 4C18.25 5.10457 19.1454 6 20.25 6C21.3546 6 22.25 5.10457 22.25 4C22.25 2.89543 21.3546 2 20.25 2ZM4.25 10C3.14543 10 2.25 10.8954 2.25 12C2.25 13.1046 3.14543 14 4.25 14C5.35457 14 6.25 13.1046 6.25 12C6.25 10.8954 5.35457 10 4.25 10ZM18.25 20C18.25 18.8954 19.1454 18 20.25 18C21.3546 18 22.25 18.8954 22.25 20C22.25 21.1046 21.3546 22 20.25 22C19.1454 22 18.25 21.1046 18.25 20Z"
    />
  ),
  defaultProps: {
    "aria-label": "Document icon",
    role: "img",
  },
});
