export const momentsAudioSubtitlesMap = {
  "06911d54-b57d-4127-ae6a-770b3db7ae41": {
    en: "/static/subtitles/en/ExploringEmotionsedited.vtt",
    es: "/static/subtitles/es/ExploringEmotionsedited.vtt",
  },
  "128777de-5d30-40c2-a66d-c82d1b3a3315": {
    en: "/static/subtitles/en/AutomaticThoughtsEdited.vtt",
    es: "/static/subtitles/es/AutomaticThoughtsEdited.vtt",
  },
  "13ad12b9-116d-4f84-a8ee-6d26b834b19c": {
    en: "/static/subtitles/en/MeditatingwithRAINedited.vtt",
    es: "/static/subtitles/es/MeditatingwithRAINedited.vtt",
  },
  "17c633d2-f75f-438d-a8ad-315a5d74391b": {
    en: "/static/subtitles/en/Grounding5-4-3-2-1.vtt",
    es: "/static/subtitles/es/Grounding5-4-3-2-1.vtt",
  },
  "2c40e6bb-f4ed-4510-b235-7c64b150ce0f": {
    en: "/static/subtitles/en/MindfulWalking.vtt",
    es: "/static/subtitles/es/MindfulWalking.vtt",
  },
  "2ce4171c-f2d6-4466-8c7f-6355d5a31cef": {
    en: "/static/subtitles/en/10MinsofMindfulness.vtt",
    es: "/static/subtitles/es/10MinsofMindfulness.vtt",
  },
  "3f6145af-1c77-4432-ac8b-e159d64585da": {
    en: "/static/subtitles/en/PracticingMindfulness_7-min.vtt",
    es: "/static/subtitles/es/PracticingMindfulness_7-min.vtt",
  },
  "45b624bf-32f5-4a8b-a401-7ea4a30ffc51": {
    en: "/static/subtitles/en/RelaxingYourMuscles.vtt",
    es: "/static/subtitles/es/RelaxingYourMuscles.vtt",
  },
  "48c0abd8-0c30-45f8-82cf-029add60fbdf": {
    en: "/static/subtitles/en/ScanningYourBody.vtt",
    es: "/static/subtitles/es/ScanningYourBody.vtt",
  },
  "5ea13903-6da6-4016-bd71-90227122973e": {
    en: "/static/subtitles/en/CreatingCalm_observe.vtt",
    es: "/static/subtitles/es/CreatingCalm_observe.vtt",
  },
  "5efe17b2-ff8f-4320-83ad-8e7481810b68": {
    en: "/static/subtitles/en/PracticingCompassion_CompassionPractice.vtt",
    es: "/static/subtitles/es/PracticingCompassion_CompassionPractice.vtt",
  },
  "64e7e70f-cde6-41fd-bf17-115dd2921f7d": {
    en: "/static/subtitles/en/DeepBreathing.vtt",
    es: "/static/subtitles/es/DeepBreathing.vtt",
  },
  "811b8e1d-1b05-4051-a1a3-65d07b87c9aa": {
    en: "/static/subtitles/en/PracticingCompassion_SelfCompassionBreak.vtt",
    es: "/static/subtitles/es/PracticingCompassion_SelfCompassionBreak.vtt",
  },
  "98b7bebd-952c-4b21-bd0f-16f1c943bc6b": {
    en: "/static/subtitles/en/AnxietyCues_becomingawareedited.vtt",
    es: "/static/subtitles/es/AnxietyCues_becomingawareedited.vtt",
  },
  "a086ee88-373b-451f-87c4-48a5a23e1ed7": {
    en: "/static/subtitles/en/DailyMindfulnessEdited.vtt",
    es: "/static/subtitles/es/DailyMindfulnessEdited.vtt",
  },
  "03305174-2998-488c-b0f3-0afff106962b": {
    en: "/static/subtitles/en/Preventing+Burnout+Podcast.vtt",
    es: "/static/subtitles/es/Preventing+Burnout+Podcast.vtt",
  },
  "a3845a88-0064-467c-b896-9cfcbec42ea6": {
    en: "/static/subtitles/en/CreatingCalm_sit.vtt",
    es: "/static/subtitles/es/CreatingCalm_sit.vtt",
  },
  "d83d2971-b658-4446-bc81-999737503acf": {
    en: "/static/subtitles/en/CreatingCalm_grounding.vtt",
    es: "/static/subtitles/es/CreatingCalm_grounding.vtt",
  },
  "e5633c3f-5392-44a7-b417-e32757b4297c": {
    en: "/static/subtitles/en/CopingDuringaPanicAttack.vtt",
    es: "/static/subtitles/es/CopingDuringaPanicAttack.vtt",
  },
  "1952ddf1-f21a-4e25-9e40-97dab430d4d0": {
    en: "/static/subtitles/en/Reframing+Your+Thoughts.vtt",
    es: "/static/subtitles/es/Reframing+Your+Thoughts.vtt",
  },
  "f60e3e55-61dc-4d4b-bdcd-6319f2f0ce64": {
    en: "/static/subtitles/en/MindfulEating.vtt",
    es: "/static/subtitles/es/MindfulEating.vtt",
  },
  "ed66cea8-c96a-4892-aa99-a882339682d5": {
    en: "/static/subtitles/en/Anxiety+Exposure.vtt",
    es: "/static/subtitles/es/Anxiety+Exposure.vtt",
  },
  "a6b255fa-0e6a-4959-a380-4482d12bae53": {
    en: "/static/subtitles/en/Half+Smile+Technique.vtt",
    es: "/static/subtitles/es/Half+Smile+Technique.vtt",
  },
  "067381c4-01aa-4025-aa3b-b8a3e626a057": {
    en: "/static/subtitles/en/Understanding+Your+Self-Esteem+Conclusion.vtt",
    es: "/static/subtitles/es/Understanding+Your+Self-Esteem+Conclusion.vtt",
  },
  "8fc1bddc-88f3-4b20-be0c-3f566306fc18": {
    en: "/static/subtitles/en/Meditation_3A+Think+Like+a+Beginner.vtt",
    es: "/static/subtitles/es/Meditation_3A+Think+Like+a+Beginner.vtt",
  },
  "68662362-ff30-4a4f-b3d2-04d24bee77b5": {
    en: "/static/subtitles/en/Muscle+Relaxation.vtt",
    es: "/static/subtitles/es/Muscle+Relaxation.vtt",
  },
  "a6d598f4-902b-4266-aeb7-de7d42cdb303": {
    en: "/static/subtitles/en/Practicing+Mindful+Acceptance.vtt",
    es: "/static/subtitles/es/Practicing+Mindful+Acceptance.vtt",
  },
  "295b3437-7acc-4f2e-86b3-ee895bda88a5": {
    en: "/static/subtitles/en/Mindfulness_3A+10-Minute+Breath+Counting.vtt",
    es: "/static/subtitles/es/Mindfulness_3A+10-Minute+Breath+Counting.vtt",
  },
  "36f91b3e-1a6e-4a8d-a118-83edf656a046": {
    en: "/static/subtitles/en/Guided+Productivity+Meditation.vtt",
    es: "/static/subtitles/es/Guided+Productivity+Meditation.vtt",
  },
  "f89c21fc-fa25-48a5-87a7-6121cabe81c3": {
    en: "/static/subtitles/en/Falling+Asleep_3A+Waves.vtt",
    es: "/static/subtitles/es/Falling+Asleep_3A+Waves.vtt",
  },
  "b3635bf0-9f30-43b6-a8e0-397adbf1fb7b": {
    en: "/static/subtitles/en/Falling+Asleep_3A+Rain.vtt",
    es: "/static/subtitles/es/Falling+Asleep_3A+Rain.vtt",
  },
  "c86b6018-9387-4cc6-a071-47c9f2907bfb": {
    en: "/static/subtitles/en/Difficult+Emotions+Practice.vtt",
    es: "/static/subtitles/es/Difficult+Emotions+Practice.vtt",
  },
  "361a6fc3-f9e2-4d64-9169-09a01580a9f1": {
    en: "/static/subtitles/en/Having+More+Fun+Meditation.vtt",
    es: "/static/subtitles/es/Having+More+Fun+Meditation.vtt",
  },
  "f77e5699-b73b-4f47-adc3-5f3d1436fb75": {
    en: "/static/subtitles/en/Falling+Asleep_+Music.vtt",
    es: "/static/subtitles/es/Falling+Asleep_+Music.vtt",
  },
  "f91b3e91-9efb-4324-a6d2-0f7ac656f1ee": {
    en: "/static/subtitles/en/ThreeGoodThings_rev.465eb710.vtt",
    es: "/static/subtitles/es/ThreeGoodThings_rev.465eb710.vtt",
  },
  "08df183c-db99-4520-861c-6b6e2b0b33bb": {
    en: "/static/subtitles/en/Mindfulness_3A+10-Minute+Mantra.vtt",
    es: "/static/subtitles/es/Mindfulness_3A+10-Minute+Mantra.vtt",
  },
  "d61b978d-70ba-45ea-ab17-82b7010994b6": {
    en: "/static/subtitles/en/Understanding+Your+Self-Esteem+Position+2.vtt",
    es: "/static/subtitles/es/Understanding+Your+Self-Esteem+Position+2.vtt",
  },
  "dc12f30f-1eec-41fb-b7a8-b4e772970bb7": {
    en: "/static/subtitles/en/Introduction+to+Self-Esteem.vtt",
    es: "/static/subtitles/es/Introduction+to+Self-Esteem.vtt",
  },
  "bc4efad4-4d9a-4c80-b73f-47f39ae709ba": {
    en: "/static/subtitles/en/Understanding+Your+Self-Esteem+Position+1.vtt",
    es: "/static/subtitles/es/Understanding+Your+Self-Esteem+Position+1.vtt",
  },
  "a44bcf01-1c4c-4b6c-89cd-c63fe1892a6a": {
    en: "/static/subtitles/en/Understanding+Your+Self-Esteem+Position+3.vtt",
    es: "/static/subtitles/es/Understanding+Your+Self-Esteem+Position+3.vtt",
  },
  "90333531-65c9-4585-af41-3e966c7d3c82": {
    en: "/static/subtitles/en/Diaphragm+Breathing+-+Counting.vtt",
    es: "/static/subtitles/es/Diaphragm+Breathing+-+Counting.vtt",
  },
  "be3a3d02-597c-4598-9bd7-85aa09efdeb0": {
    en: "/static/subtitles/en/Culture_s+Influence.vtt",
    es: "/static/subtitles/es/Culture_s+Influence.vtt",
  },
  "472312ed-76c1-44f5-aaf1-090ed9f90b10": {
    en: "/static/subtitles/en/Tired-Calm+Meditation.vtt",
    es: "/static/subtitles/es/Tired-Calm+Meditation.vtt",
  },
  "fdb2590d-19e6-487c-8e2a-27079095366c": {
    en: "/static/subtitles/en/Energized-Calm+Meditation.vtt",
    es: "/static/subtitles/es/Energized-Calm+Meditation.vtt",
  },
  "9cf89bf2-7420-45ff-9b46-dd487b645493": {
    en: "/static/subtitles/en/Fat+Talk_3A+Podcast.vtt",
    es: "/static/subtitles/es/Fat+Talk_3A+Podcast.vtt",
  },
  "3078c0d5-6308-4815-91ad-a68371c14bd3": {
    en: "/static/subtitles/en/Your+Relationship+with+Fat+Talk.vtt",
    es: "/static/subtitles/es/Your+Relationship+with+Fat+Talk.vtt",
  },
  "6011ca04-5497-451b-83bc-d0969c039533": {
    en: "/static/subtitles/en/Multi-Tasking.vtt",
    es: "/static/subtitles/es/Multi-Tasking.vtt",
  },
  "a3cf9192-da37-4f6d-8865-c544e8a9cf81": {
    en: "/static/subtitles/en/Improving+Your+Relationship+to+Your+Body.vtt",
    es: "/static/subtitles/es/Improving+Your+Relationship+to+Your+Body.vtt",
  },
  "c04fd574-7128-428e-810a-0f6b2cac40cc": {
    en: "/static/subtitles/en/Mindfulness_2C+Take+Two.vtt",
    es: "/static/subtitles/es/Mindfulness_2C+Take+Two.vtt",
  },
};
