import { TRACK_EVENT } from "utils/mixpanel";
import { memberAgeRange } from "utils/memberHelpers";
import routes from "routes";

const setDependentType = (is_dependent) => {
  if (is_dependent === "true") {
    return "Dependent";
  }
  if (is_dependent === "false") {
    return "Not Dependent";
  }
  return undefined;
};

export const trackFormSubmitted = (
  viewName,
  docId,
  customer_id = undefined,
  cohort_id = undefined,
  invite_token = undefined,
  covered_life_country = undefined,
  cl_id = undefined,
  age_range = undefined,
  is_dependent = undefined,
) => {
  TRACK_EVENT.FORM_SUBMITTED(routes.Register.as, titleCase(viewName), {
    page_version: titleCase(viewName),
    spring_doc_id: docId,
    customer_id,
    cohort_id,
    invite_token,
    covered_life_country,
    cl_id,
    age_range: memberAgeRange(age_range),
    dependent: setDependentType(is_dependent),
  });
};

export const trackButtonClicked = (buttonName, viewName, docId, location) => {
  TRACK_EVENT.BUTTON_CLICKED(routes.Register.as, buttonName, {
    page_version: titleCase(viewName),
    location: location,
    spring_doc_id: docId,
  });
};

export const trackLinkClicked = (link, linkType, viewName, docId, location) => {
  TRACK_EVENT.LINK_CLICKED(routes.Register.as, link, linkType, {
    location: location,
    page_version: titleCase(viewName),
    spring_doc_id: docId,
  });
};

export const trackPageVersionViewed = (
  viewName,
  docId,
  customer_id = undefined,
  cohort_id = undefined,
  invite_token = undefined,
  covered_life_country = undefined,
  cl_id = undefined,
  age_range = undefined,
  is_dependent = undefined,
  traffic_type = undefined,
) => {
  TRACK_EVENT.PAGE_VERSION_VIEWED(routes.Register.as, titleCase(viewName), {
    page_version: titleCase(viewName),
    spring_doc_id: docId,
    customer_id,
    cohort_id,
    invite_token,
    covered_life_country,
    cl_id,
    age_range: memberAgeRange(age_range),
    dependent: setDependentType(is_dependent),
    traffic_type,
  });
};

export const trackErrorMsgViewed = (viewName, docId) => {
  TRACK_EVENT.ERROR_MESSAGE_VIEWED(routes.Register.as, titleCase(viewName), {
    page_version: titleCase(viewName),
    spring_doc_id: docId,
  });
};

export const trackError = (viewName, type, docId) => {
  TRACK_EVENT.ERROR(routes.Register.as, type, {
    page_version: titleCase(viewName),
    spring_doc_id: docId,
  });
};

export const trackNotificationViewed = (viewName, type, docId) => {
  TRACK_EVENT.NOTIFICATION_VIEWED(routes.Register.as, type, {
    page_version: titleCase(viewName),
    spring_doc_id: docId,
  });
};

export const titleCase = (str) =>
  str
    ? str
        .toLowerCase()
        .split("_")
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ")
    : "";
