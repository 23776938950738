"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useOpenClose;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function useOpenClose() {
  var initial = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _useState = (0, _react.useState)(initial),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    isOpen = _useState2[0],
    setOpen = _useState2[1];
  var open = function open() {
    return setOpen(true);
  };
  var close = function close() {
    return setOpen(false);
  };
  return [isOpen, {
    open: open,
    close: close
  }];
}