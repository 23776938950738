import React from "react";
import { useOnRampToCareExperiment } from "../hooks";
import { Heading, Text, Flex, useMediaQuery } from "@chakra-ui/react";
import {
  VCelebrationConfettiDuotoneSpot,
  VTherapyDuotoneSpot,
  VCoachingDuotoneSpot,
} from "@springcare/verdant-icons-react";
import { useTranslation } from "hooks/react-i18next";
import { EfficacySurveyFeeling } from "./MomentsCompletionModal";

export const MomentsCompletionModalHeader = ({
  exerciseTitle,
  feeling,
}: {
  exerciseTitle?: string;
  feeling: string | null;
}) => {
  const surveyCompleted = !!feeling;
  const { isTherapyTreatmentUser, isCoachingTreatmentUser, remainingSessions } =
    useOnRampToCareExperiment();

  if (isCoachingTreatmentUser || isTherapyTreatmentUser) {
    if (!surveyCompleted) {
      return <DefaultHeader exerciseTitle={exerciseTitle} />;
    }

    return (
      <TreatmentHeader
        isCoachingTreatmentUser={isCoachingTreatmentUser}
        feeling={feeling}
        remainingSessions={remainingSessions}
      />
    );
  }

  // * if the user is assigned treatment by the FF but does not meet additional criteria, let's make sure we render DefaultHeader
  return <DefaultHeader exerciseTitle={exerciseTitle} />;
};

const TreatmentHeader = ({
  isCoachingTreatmentUser,
  feeling,
  remainingSessions,
}: {
  feeling: string | null;
  isCoachingTreatmentUser: boolean;
  remainingSessions: string | number;
}) => {
  const [isMobile] = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation("moments");

  const sessionTypei18nKey = isCoachingTreatmentUser
    ? "completeExerciseModal.remainingCoachingSessionsSubtitle"
    : "completeExerciseModal.remainingTherapySessionsSubtitle";
  const providerTypei18nKey = isCoachingTreatmentUser
    ? "completeExerciseModal.coach"
    : "completeExerciseModal.therapist";
  const i18nTitleKey =
    feeling === EfficacySurveyFeeling.Better
      ? "completeExerciseModal.levelUpTitle"
      : "completeExerciseModal.boostHealthTitle";
  const remainingSessionsUXCopy =
    remainingSessions === "unlimited"
      ? t("completeExerciseModal.unlimited")
      : remainingSessions;

  return (
    <Flex
      flexDirection="column"
      gap="v-16"
      width="100%"
      maxWidth={isMobile ? "296px" : "none"}
      px={["v-none", "v-none", "v-none", "v-52"]}
      mt="v-40"
    >
      {isCoachingTreatmentUser ? (
        <VCoachingDuotoneSpot aria-hidden />
      ) : (
        <VTherapyDuotoneSpot aria-hidden />
      )}
      <Heading size="heading-medium">
        {t(i18nTitleKey)}
        {t(providerTypei18nKey)}
      </Heading>
      {!!remainingSessions && (
        <Text>
          {t("completeExerciseModal.remainingSessionsSubtitle")}
          {remainingSessionsUXCopy}
          {t(sessionTypei18nKey)}
          <strong>
            {t("completeExerciseModal.remainingSessionsSubtitleNoCost")}
          </strong>
        </Text>
      )}
    </Flex>
  );
};

const DefaultHeader = ({ exerciseTitle }: { exerciseTitle?: string }) => {
  const { t } = useTranslation("moments");

  return (
    <Flex direction="column" alignItems="center" gap="v-24">
      <VCelebrationConfettiDuotoneSpot aria-hidden />
      <Flex
        direction="column"
        alignItems="center"
        maxWidth={["295px", "none", "none", "none"]}
      >
        <Text
          textAlign="center"
          size="body-medium-regular"
          data-cy="complete-moment-modal-title"
        >
          {t("completeExerciseModal.title")}
        </Text>
        <Heading size="heading-medium" textAlign="center">
          {exerciseTitle}
        </Heading>
      </Flex>
    </Flex>
  );
};
