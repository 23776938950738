import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { Content, Bolded, OpenInNewTab } from "@spring/smeargle";
import { useTranslation, withTranslation } from "react-i18next";

import { Link } from "components/atoms";
import styles from "./styles.module.scss";
import routes from "routes";
import { formatMiles, formatKilometers } from "utils/displayHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const ProviderOfficeLocation = (props) => {
  const { t } = useTranslation(["careVisits", "memberDashboard"]);
  const { street_address_1, street_address_2, city, state, zip_code } =
    props.provider;
  const address = [street_address_1, street_address_2, city, state]
    .filter(Boolean)
    .join(", ");
  const { latitude, longitude } = getOr("", "modalData.coordinates", props);
  const full_street_address = street_address_2
    ? `${street_address_1} ${street_address_2}`
    : street_address_1;
  const enableInPersonGlobal = useFeatureFlag(FLAGS.IN_PERSON_GLOBAL);
  const distanceResult =
    enableInPersonGlobal && props.showGlobalExperience
      ? formatKilometers(props.provider?.distance_kilometers, false)
      : formatMiles(props.provider?.distance_miles, false);
  const formatAddress = () => {
    if (latitude && longitude) {
      return (
        <div onClick={(event) => event.stopPropagation()}>
          <Link
            to={"https://www.google.com/maps/search/"}
            mpTracking={{
              page: routes.TherapistsBrowse.as,
              type: "Provider Address",
              location: "Provider Profile",
              spring_doc_id: "directsched002",
            }}
            queries={{
              api: 1,
              query: `${full_street_address},${state},${city},${zip_code}`,
            }}
            ariaLabel={t("a11y:providerAddress", {
              providerAddress: address.toString(),
              zip: zip_code.toString(),
            })}
          >
            <Content dark underline>
              {address} {zip_code}
            </Content>
          </Link>
          &nbsp;
          <OpenInNewTab />
        </div>
      );
    }

    return (
      <Content dark>
        {address} {zip_code}
      </Content>
    );
  };

  return (
    <div
      className={
        props.smallSlotStyle
          ? styles.smallInPersonLocation
          : styles.inPersonLocation
      }
    >
      <Bolded>
        {distanceResult.distance +
          " " +
          t(`displayHelper:${distanceResult.distanceUnit}`) +
          " " +
          t("away")}
      </Bolded>
      {formatAddress()}
    </div>
  );
};

ProviderOfficeLocation.propTypes = {
  modalData: PropTypes.shape({
    id: PropTypes.string,
    coordinates: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string,
    }),
  }),
  provider: PropTypes.shape({
    city: PropTypes.any,
    distance_kilometers: PropTypes.any,
    distance_miles: PropTypes.any,
    state: PropTypes.any,
    street_address_1: PropTypes.any,
    street_address_2: PropTypes.any,
    zip_code: PropTypes.any,
  }),
  showGlobalExperience: PropTypes.bool,
};

export { ProviderOfficeLocation };
export default withTranslation("careProvider")(ProviderOfficeLocation);
