//

import React from "react";
import yup from "schemas/yup";
import { Checkbox } from "@spring/smeargle";

const issuesSchema = {};

export const IssuesSchema = {
  ...issuesSchema,
};

const Issues = [
  {
    id: "Issues--1",
    title: "Have any of these been bothering you?",
    subtitle: "Let us know what we can help you with.",
    autoAdvance: false,
    schema: yup.object().shape(issuesSchema),
    questions: [
      <Checkbox
        key={1}
        fieldKey="Issues.sadness"
        label="Sad, down, or depressed"
        border
      />,
      <Checkbox
        key={2}
        fieldKey="Issues.drugs"
        label="Using drugs or alcohol"
        border
      />,
      <Checkbox
        key={3}
        fieldKey="Issues.anger"
        label="Anger or impulsivity"
        border
      />,
      <Checkbox
        key={4}
        fieldKey="Issues.sleep"
        label="Trouble sleeping"
        border
      />,
      <Checkbox
        key={5}
        fieldKey="Issues.body_image"
        label="Eating or body image"
        border
      />,
      <Checkbox
        key={6}
        fieldKey="Issues.stress"
        label="Feeling stressed out"
        border
      />,
    ],
  },
  {
    id: "Issues--2",
    title: "Have any of these been bothering you?",
    subtitle: "Let us know what we can help you with.",
    autoAdvance: false,
    schema: yup.object().shape(issuesSchema),
    questions: [
      <Checkbox
        key={7}
        fieldKey="Issues.worrying"
        label="Excessive worrying"
        border
      />,
      <Checkbox
        key={8}
        fieldKey="Issues.concentration"
        label="Poor concentration"
        border
      />,
      <Checkbox
        key={9}
        fieldKey="Issues.family"
        label="Family or relationships"
        border
      />,
      <Checkbox
        key={10}
        fieldKey="Issues.breakup"
        label="Going through a breakup"
        border
      />,
      <Checkbox
        key={11}
        fieldKey="Issues.postpartum"
        label="My partner or I just had a baby"
        border
      />,
      <Checkbox
        key={12}
        fieldKey="Issues.relationships"
        label="Not happy in relationship"
        border
      />,
    ],
  },
  {
    id: "Issues--3",
    title: "Have any of these been bothering you?",
    subtitle: "Let us know what we can help you with.",
    autoAdvance: false,
    schema: yup.object().shape(issuesSchema),
    onAdvance: (data, assessment) => {
      // Everyone must take the SDS and PHQ9 for diagnostic purposes
      // As of 2/28/19, the API will ensure that the SDS and PHQ9 are included.
      // I am leaving this here for now to ensure that EXISTING assessments have these added.
      const questionnaires = ["SDS", "PHQ9"];
      if (data.postpartum) {
        questionnaires.push("EPDS");
      }
      if (data.anger || data.concentration || data.focus || data.distraction) {
        questionnaires.push("ASRS");
      }

      if (data.anger || data.drugs) {
        questionnaires.push("AUDIT");
      }

      if (data.anger) {
        questionnaires.push("MDQ");
      }

      if (data.panic || data.domestic_violence) {
        questionnaires.push("PCPTSD");
      }

      if (data.body_image) {
        questionnaires.push("SCOFF");
      }

      if (data.sleep || data.stress || data.worrying || data.panic) {
        questionnaires.push("GAD7");
      }

      // append to the end of the questionnaire
      // The initial assessment has 2 ending propmts that must come at the end.
      // All others only have one (the submit prompt)
      const endingPadding =
        assessment.meta.kind === "INITIAL_ASSESSMENT" ? 2 : 1;
      assessment.injectQuestionnaires(
        questionnaires,
        assessment.length - endingPadding,
      );
    },
    questions: [
      <Checkbox
        key={13}
        fieldKey="Issues.panic"
        label="Intense panic"
        border
      />,
      <Checkbox
        key={14}
        fieldKey="Issues.people"
        label="Trouble getting on with people"
        border
      />,
      <Checkbox
        key={15}
        fieldKey="Issues.grief"
        label="Grief or bereavement"
        border
      />,
      <Checkbox
        key={16}
        fieldKey="Issues.domestic_violence"
        label="Domestic violence"
        border
      />,
      <Checkbox
        key={17}
        fieldKey="Issues.self_esteem"
        label="Low self-esteem"
        border
      />,
      <Checkbox
        key={18}
        fieldKey="Issues.distraction"
        label="Easily distracted"
        border
      />,
      <Checkbox key={19} fieldKey="Issues.focus" label="Can't focus" border />,
    ],
  },
];

export default Issues;
