import {
  modalIds,
  AppointmentKind,
  AppointmentMedium,
  isCoaching,
} from "@spring/constants";
import { DateTime } from "luxon";
import { getOrdinal } from "@spring/immutability";
import {
  getIsUniqueCCACopy,
  getIsUniqueWPMCHCopy,
} from "utils/employerServicesHelpers";
/**
 * @param {function} t: the translation callback returned by useTranslation
 */
export const curriedGetKind = (t, customerId) => (kind) => {
  const isUniqueCCACopy = getIsUniqueCCACopy(customerId);

  switch (kind) {
    case AppointmentKind.Therapy:
      return t("appointmentDetails.kind.therapy");
    case AppointmentKind.MinorTherapy:
      return t("appointmentDetails.kind.minorTherapy");
    case AppointmentKind.CouplesTherapy:
      return t("appointmentDetails.kind.couplesTherapy");
    case AppointmentKind.InitialMedicationManagement:
      return t("appointmentDetails.kind.initialMedication");
    case AppointmentKind.FollowUpMedicationManagement:
      return t("appointmentDetails.kind.followupMedication");
    case AppointmentKind.InitialCareNavigation:
      return t("appointmentDetails.kind.initialCN");
    case AppointmentKind.InitialSpecialtyCareNavigation:
      return t("appointmentDetails.kind.initialSCN");
    case AppointmentKind.FollowUpCareNavigation:
      return t("appointmentDetails.kind.followupCN");
    case AppointmentKind.FollowUpSpecialtyCareNavigation:
      return t("appointmentDetails.kind.followupSCN");
    case AppointmentKind.InitialCoaching:
      return t("appointmentDetails.kind.initialCoaching");
    case AppointmentKind.FollowUpCoaching:
      return t("appointmentDetails.kind.followUpCoaching");
    case AppointmentKind.Coaching:
      return t("appointmentDetails.kind.coaching");
    case AppointmentKind.WellnessAndSocialSupportSession:
      if (isUniqueCCACopy) {
        return t(
          `appointmentDetails.kind.wellnessAndSocialSupport.${customerId}`,
        );
      } else {
        return t("appointmentDetails.kind.wellnessAndSocialSupport.default");
      }
    case AppointmentKind.ManagerConsultationAndSupport:
      return t("appointmentDetails.kind.managerConsultationAndSupport");
    case AppointmentKind.IndividualSolutionsFocusedConsultation:
      return t(
        "appointmentDetails.kind.individualSolutionsFocusedConsultation",
      );
    case AppointmentKind.TrainingAndAwarenessEventConsultation:
      return t("appointmentDetails.kind.trainingAndAwarenessEventConsultation");
    case AppointmentKind.FollowUpPeerRecoverySpecialist:
      return t("appointmentDetails.kind.followupPeerRecoverySpecialist");
    case AppointmentKind.InitialPeerRecoverySpecialist:
      return t("appointmentDetails.kind.initialPeerRecoverySpecialist");
    default:
      return t("appointmentDetails.kind.CN");
  }
};

/**
 * @param {function} t: the translation callback returned by useTranslation
 */
export const curriedGetAppointmentKind =
  (t, customerId = null) =>
  (kind) => {
    const isUniqueWPMCHCopy = getIsUniqueWPMCHCopy(customerId);
    const isUniqueCCACopy = getIsUniqueCCACopy(customerId);

    switch (kind) {
      case AppointmentKind.Therapy:
      case AppointmentKind.MinorTherapy:
        return t("appointmentDetails.appointmentKind.therapy");
      case AppointmentKind.CouplesTherapy:
        return t("appointmentDetails.appointmentKind.couplesTherapy");
      case AppointmentKind.InitialMedicationManagement:
      case AppointmentKind.FollowUpMedicationManagement:
        return t("appointmentDetails.appointmentKind.medication");
      case AppointmentKind.InitialCoaching:
      case AppointmentKind.FollowUpCoaching:
      case AppointmentKind.Coaching:
        return t("appointmentDetails.appointmentKind.coaching");
      case AppointmentKind.WellnessAndSocialSupportSession:
        return isUniqueCCACopy
          ? t(`appointmentDetails.appointmentKind.careAdvocate.${customerId}`)
          : t("appointmentDetails.appointmentKind.careAdvocate.default");
      case AppointmentKind.ManagerConsultationAndSupport:
      case AppointmentKind.IndividualSolutionsFocusedConsultation:
      case AppointmentKind.TrainingAndAwarenessEventConsultation:
        return isUniqueWPMCHCopy
          ? t(
              `appointmentDetails.appointmentKind.careConsultant.${customerId}.title`,
            )
          : t("appointmentDetails.appointmentKind.careConsultant.default");
      case AppointmentKind.FollowUpPeerRecoverySpecialist:
      case AppointmentKind.InitialPeerRecoverySpecialist:
        return t("appointmentDetails.appointmentKind.peerRecoverySpecialist");
      case AppointmentKind.InitialSpecialtyCareNavigation:
      case AppointmentKind.FollowUpSpecialtyCareNavigation:
        return t("appointmentDetails.appointmentKind.specialtyCareNavigator");
      case AppointmentKind.InitialCareNavigation:
      case AppointmentKind.FollowUpCareNavigation:
      default:
        return t("appointmentDetails.appointmentKind.careNavigator");
    }
  };

/**
 * @param {function} t: the translation callback returned by useTranslation
 */
export const curriedGetAppointmentType = (t) => (medium) => {
  switch (medium) {
    case AppointmentMedium.Video:
      return t("appointmentDetails.appointmentType.virtual");
    case AppointmentMedium.InPerson:
      return t("appointmentDetails.appointmentType.inPerson");
    case AppointmentMedium.Phone:
      return t("appointmentDetails.appointmentType.phone");
  }
};

/**
 * @param {function} t: the translation callback returned by useTranslation
 */
export const curriedGetHeadline =
  (t) =>
  (
    medium,
    isInHouseVideo,
    isAMinor,
    memberFirstName,
    kind,
    appointmentKindText,
    appointmentTypeText,
  ) => {
    switch (medium) {
      case AppointmentMedium.Video:
        if (kind === "GOOGLE_CALENDAR")
          return "appointmentDetails.appointReminders.appointmentDescription.virtual";
        if (isAMinor)
          return t(
            "appointmentDetails.headline.confirmation.minor.appointment",
            { memberFirstName, appointmentTypeText, appointmentKindText },
          );

        return t("appointmentDetails.headline.confirmation.your.appointment", {
          appointmentTypeText,
          appointmentKindText,
        });
      case AppointmentMedium.InPerson:
        if (kind === "GOOGLE_CALENDAR")
          return "appointmentDetails.appointReminders.appointmentDescription.inPerson";
        if (isAMinor)
          return t(
            "appointmentDetails.headline.confirmation.minor.appointment",
            { memberFirstName, appointmentTypeText, appointmentKindText },
          );

        return t("appointmentDetails.headline.confirmation.your.appointment", {
          appointmentTypeText,
          appointmentKindText,
        });
      case AppointmentMedium.Phone:
        if (kind === "GOOGLE_CALENDAR")
          return "appointmentDetails.appointReminders.appointmentDescription.onPhoneAppointment";
        if (isAMinor)
          return t(
            "appointmentDetails.headline.confirmation.minor.onPhoneAppointment",
            { memberFirstName, appointmentKindText, appointmentTypeText },
          );

        return t(
          "appointmentDetails.headline.confirmation.your.onPhoneAppointment",
          { appointmentKindText, appointmentTypeText },
        );
      default:
        if (kind === "GOOGLE_CALENDAR")
          return t(
            "appointmentDetails.appointReminders.appointmentDescription.appointment",
          );
        if (isAMinor)
          return t("appointmentDetails.headline.confirmation.minor.default", {
            memberFirstName,
          });

        return t("appointmentDetails.headline.confirmation.your.default");
    }
  };

/**
 * @param {function} t: the translation callback returned by useTranslation
 */
export const curriedGetCancellationHeadline =
  (t) =>
  (
    medium,
    isAMinor,
    memberFirstName,
    appointmentKindText,
    appointmentTypeText,
  ) => {
    switch (medium) {
      case AppointmentMedium.Video:
      case AppointmentMedium.InPerson:
        return isAMinor
          ? t("appointmentDetails.headline.cancellation.minor.appointment", {
              memberFirstName,
              appointmentTypeText,
              appointmentKindText,
            })
          : t("appointmentDetails.headline.cancellation.your.appointment", {
              appointmentTypeText,
              appointmentKindText,
            });
      case AppointmentMedium.Phone:
        return isAMinor
          ? t(
              "appointmentDetails.headline.cancellation.minor.onPhoneAppointment",
              { memberFirstName, appointmentKindText, appointmentTypeText },
            )
          : t(
              "appointmentDetails.headline.cancellation.your.onPhoneAppointment",
              { appointmentKindText, appointmentTypeText },
            );
      default:
        return isAMinor
          ? t("appointmentDetails.headline.cancellation.minor.default", {
              memberFirstName,
            })
          : t("appointmentDetails.headline.cancellation.your.default");
    }
  };

//TODO: Remove below function and refactor the 'checkIfWithin24Hours' uses to use 'isCurrentTimeWithin24HoursOfAppointment'.
// Below function currently has coupled logic that should be handled within the component and a separate more direct utility function.
export const checkIfWithin24Hours = (time, secondModal) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const luxonDate: number = DateTime.fromISO(time).toUTC();
  const todaysDate: number = new Date().valueOf();
  const timeDifference = (luxonDate - todaysDate) / millisecondsPerDay;
  return timeDifference < 1 ? modalIds.cancelAppointmentModal : secondModal;
};

export const formatDate = (time) => {
  const d = DateTime.fromISO(time).day;
  const suffix = getOrdinal(d);

  return `${DateTime.fromISO(time).toFormat("EEE, LLL d")}${suffix}`;
};

export const formatAppointmentTime = (time) => {
  const appointmentTime = DateTime.fromISO(time);
  const date = appointmentTime.toFormat("D");
  const formattedTime = appointmentTime.toFormat("t");

  return `${date} at ${formattedTime}`;
};

export const getShouldShowPaymentCopy = (kind) => {
  if (
    kind === AppointmentKind.InitialCareNavigation ||
    kind === AppointmentKind.FollowUpCareNavigation
  ) {
    return false;
  }
  return true;
};

export const getInitialCopy = (
  kind,
  defaultCopy,
  coachingCopy,
  managedMinorCopy,
  isAManagedMinor,
) => {
  if (isAManagedMinor) {
    return managedMinorCopy;
  } else if (isCoaching(kind)) {
    return coachingCopy;
  } else {
    return defaultCopy;
  }
};
