import { createIcon } from "@chakra-ui/react";

export const Benefits = createIcon({
  displayName: "Benefits List",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7C4 6.44772 4.44772 6 5 6H13.5351C13.1948 6.58835 13 7.27143 13 8C13 9.09196 13.4376 10.0817 14.1469 10.8035C12.7918 11.6338 11.8183 13.0418 11.586 14.7144L11.2685 17H5C4.44772 17 4 16.5523 4 16V7ZM11.0002 19H5C3.34315 19 2 17.6569 2 16V7C2 5.34315 3.34315 4 5 4H17C19.2091 4 21 5.79086 21 8C21 9.09182 20.5626 10.0815 19.8534 10.8033C21.2087 11.6335 22.1824 13.0416 22.4148 14.7144L22.9909 18.8624C23.0669 19.4095 22.685 19.9145 22.138 19.9905C21.5909 20.0665 21.0859 19.6846 21.0099 19.1376L20.4338 14.9895C20.1958 13.2758 18.7305 12 17.0004 12C15.2702 12 13.805 13.2758 13.567 14.9895L12.9909 19.1376C12.9149 19.6846 12.4098 20.0665 11.8628 19.9905C11.362 19.9209 10.9996 19.4918 11.0002 19ZM17 6C15.8954 6 15 6.89543 15 8C15 9.10457 15.8954 10 17 10C18.1046 10 19 9.10457 19 8C19 6.89543 18.1046 6 17 6ZM6 9C6 8.44772 6.44772 8 7 8H10C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10 10H7C6.44772 10 6 9.55228 6 9ZM7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14H8C8.55228 14 9 13.5523 9 13C9 12.4477 8.55228 12 8 12H7Z"
    />
  ),
  defaultProps: {
    "aria-label": "Benefits icon",
    role: "img",
  },
});
