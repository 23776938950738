import { gql } from "@apollo/client";

import { assessment } from "operations/fragments/assessment";
import paging from "operations/fragments/paging";

const getAssessments = gql`
  query getAssessments($member_id: ID, $status: [String], $limit: Int) {
    assessments(member_id: $member_id, status: $status, limit: $limit) {
      data {
        ...assessment
        ended_at
        metadata
        questionnaires(version: "improved_sud") {
          results_sanitized
        }
      }
      paging {
        ...paging
      }
    }
  }
  ${assessment}
  ${paging}
`;

export default getAssessments;
