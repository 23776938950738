import { Box, Flex, Button } from "design-system/components";
import { Col } from "@spring/smeargle";
import { TRACK_EVENT } from "utils/mixpanel";

const ShowSchedulingLinks = ({
  providerRole,
  providerData,
  providerAttributes,
  scheduleAction,
  showChange,
  changeAction,
}) => {
  return (
    <>
      <Col alignment="center" justification="center" md={12} lg={4}>
        <Flex
          justifyContent="center"
          alignItems="center"
          w={"100%"}
          flexDirection="column"
        >
          <Button
            size="lg"
            w={275}
            variant="outline"
            colorScheme="primary"
            data-testid={`change-${providerRole}-button`}
            onClick={() => {
              scheduleAction.onClick(providerData),
                TRACK_EVENT.BUTTON_CLICKED(
                  window.location.pathname,
                  `Schedule ${providerAttributes.role}`,
                  {
                    spring_doc_id: "therapy019",
                    location: "Therapy",
                    to: "Care Provider Schedule Modal",
                    provider_id: providerData.id,
                    appointment_type: providerRole,
                  },
                );
            }}
          >
            {scheduleAction?.text
              ? scheduleAction.text
              : providerAttributes.scheduleText}
          </Button>
          {showChange && (
            <Box flexDirection="column" padding={12}>
              <Button
                variant="link"
                colorScheme="primary"
                onClick={() => changeAction(providerData.id, providerRole)}
              >
                {providerAttributes.changeText}
              </Button>
            </Box>
          )}
        </Flex>
      </Col>
    </>
  );
};

export default ShowSchedulingLinks;
