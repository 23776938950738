const CRISIS_CENTRES = 'https://www.iasp.info/resources/Crisis_Centres/';
const GLOBAL_EMERGENCY_LINK = 'https://travel.state.gov/content/dam/students-abroad/pdfs/911_ABROAD.pdf';
const SUICIDE_PREVENTION_LIVE_CHAT = 'https://suicidepreventionlifeline.org/chat';
const SUPPORTED_ISO2_COUNTRY_CODES = ['AR', 'AT', 'AU', 'BE', 'BR', 'CA', 'CL', 'CO', 'CR', 'CY', 'CZ', 'DK', 'DO', 'EC', 'EG', 'SV', 'FI', 'FR', 'GE', 'DE', 'GR', 'GT', 'HU', 'IN', 'ID', 'IE', 'IL', 'IT', 'JP', 'JO', 'MY', 'MX', 'NL', 'NZ', 'NO', 'PA', 'PE', 'PH', 'PL', 'PT', 'RO', 'RS', 'SG', 'ZA', 'KR', 'ES', 'SE', 'CH', 'TR', 'AE', 'GB', 'US'];

/**
 * Takes an ISO2 or ISO3 country,
 * and returns true if the US or Canada is included in it.
 *
 * @param {string} country ISO2 or ISO3 country
 * @returns {boolean}
 */
const isUnitedStatesOrCanada = country => {
	if (!country) {
		return false;
	}
	const options = ['US', 'USA', 'UNITED STATES', 'CA', 'CAN', 'CANADA'];
	return options.includes(country.toUpperCase());
};

/**
 *
 * @param {string} countryCode 2 letter ISO2 country code eg, US, CA,
 * @returns {boolean}
 */
const isCountryWhereWeConsiderStateLicensure = (countryCode) => isUnitedStatesOrCanada(countryCode);

const isSupported = (countryCode) => SUPPORTED_ISO2_COUNTRY_CODES.includes(countryCode);

module.exports = {
	CRISIS_CENTRES,
	GLOBAL_EMERGENCY_LINK,
	isCountryWhereWeConsiderStateLicensure,
	isSupported,
	isUnitedStatesOrCanada,
	SUICIDE_PREVENTION_LIVE_CHAT,
	SUPPORTED_ISO2_COUNTRY_CODES,
};
