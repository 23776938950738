import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import Meowth from "@spring/meowth";
import {
  Modal,
  FlexCol,
  Subhead,
  Subtitle,
  Form,
  Checkbox,
  SpringHeartShield,
} from "@spring/smeargle";
import { modalIds, links } from "@spring/constants";
import { addNotification, closeModal } from "@spring/smeargle/actions";
import { useTranslation, Trans } from "react-i18next";

import informedConsentSchema from "schemas/informedConsent";

import styles from "./styles.module.scss";

import { Link } from "components";
import { updateMember } from "operations/mutations/member";
import { getMemberConsent } from "operations/queries/member";
import { hasICModalClosed, closeICModal } from "utils/displayHelpers";

const InformedConsentModal = (props) => {
  const { t } = useTranslation("memberDashboard");

  const submitInformedConsent = async () => {
    const result = await props.updateMember({
      variables: {
        input: {
          id: props.data.user.member.id,
          patch: {
            informed_consent: true,
          },
        },
      },
    });

    if (result.data.updateMember.success) {
      props.closeModal(modalIds.informedConsentModal);
      closeICModal();
      return props.addNotification(
        t("informedConsent.notificationSuccess"),
        "success",
      );
    }
    props.closeModal(modalIds.informedConsentModal);
    closeICModal();
    return props.addNotification(
      t("informedConsent.notificationFailure"),
      "error",
    );
  };

  const shouldShow =
    props.data.user &&
    !props.data.user.member.informed_consent &&
    !hasICModalClosed();

  if (props.data.user && props.data.user.member.informed_consent) {
    props.closeModal(modalIds.informedConsentModal);
  }

  return (
    <Modal
      size="md"
      id={modalIds.informedConsentModal}
      open={shouldShow}
      dismissable
      closeRedirect={() => closeICModal()}
    >
      <div className={styles.informedConsentModal}>
        <SpringHeartShield />
        <Subhead center>{t("informedConsent.title")}</Subhead>
        <Subtitle center>{t("informedConsent.subTitle")}</Subtitle>
        <Form
          theme="simple"
          formKey="informed_consent"
          schema={informedConsentSchema}
          onSubmit={() => submitInformedConsent()}
          submitText={t("informedConsent.submitText")}
          fullSubmit
          mdSection
        >
          <FlexCol alignment="center" justification="space-between">
            <Checkbox
              fieldKey="informed_consent"
              required={false}
              label={
                <Trans
                  ns="memberDashboard"
                  i18nKey="informedConsent.declaration"
                  components={[
                    <Link key="1" to={links.InformedConsent}>
                      informed consent
                    </Link>,
                  ]}
                />
              }
            />
          </FlexCol>
        </Form>
      </div>
    </Modal>
  );
};

InformedConsentModal.propTypes = {
  addNotification: PropTypes.func,
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    open: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string,
      member: PropTypes.shape({
        id: PropTypes.string,
        informed_consent: PropTypes.bool,
      }),
    }),
  }),
  loading: PropTypes.bool,
  updateMember: PropTypes.func,
};

const mapDispatchToProps = { addNotification, closeModal };
export default compose(
  graphql(getMemberConsent, { options: Meowth.apolloOptionsUserId }),
  graphql(updateMember, { name: "updateMember" }),
  connect(null, mapDispatchToProps),
)(InformedConsentModal);
