import { useCustomToast } from "@springcare/sh-component-library";
import { VCalendarIcon } from "@springcare/verdant-icons-react";
import Router from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTranslation } from "react-i18next";
import { TRACKING_CONSTANTS } from "modules/MemberDashboard/CareNavigation/constants";
import routes from "routes";

type UseCNApptToastProps = {
  upcomingCNAppointmentID: string;
};

export const useCNApptToast = ({
  upcomingCNAppointmentID,
}: UseCNApptToastProps) => {
  const { t } = useTranslation("careNavigation", {
    keyPrefix: "careNavigation",
  });

  const upcomingCNApptRoute = routes.MemberAppointmentDetail.as.replace(
    ":id",
    upcomingCNAppointmentID,
  );
  const previousAppointmentToastDescription = t(
    "previousAppointmentToast.description",
  );
  const previousAppointmentToastViewSession = t(
    "previousAppointmentToast.viewSessionLink",
  );

  return useCustomToast({
    icon: VCalendarIcon,
    message: previousAppointmentToastDescription,
    buttonText: previousAppointmentToastViewSession,
    buttonAction: () => {
      TRACK_EVENT.LINK_CLICKED(
        Router.asPath,
        upcomingCNApptRoute,
        TRACKING_CONSTANTS.viewAppointmentDetailsClick,
      );
      Router.push(upcomingCNApptRoute);
    },
    layout: "fit-content",
    action: "on",
  });
};
