//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";
import { formOptions } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "AUDIT.q1": numberField(),
};
const prompt2Schema = {
  "AUDIT.q2": numberField(),
};
const prompt3Schema = {
  "AUDIT.q3": numberField(),
};
const prompt4Schema = {
  "AUDIT.q4": numberField(),
};
const prompt5Schema = {
  "AUDIT.q5": numberField(),
};
const prompt6Schema = {
  "AUDIT.q6": numberField(),
};
const prompt7Schema = {
  "AUDIT.q7": numberField(),
};
const prompt8Schema = {
  "AUDIT.q8": numberField(),
};
const prompt9Schema = {
  "AUDIT.q9": numberField(),
};
const prompt10Schema = {
  "AUDIT.q10": numberField(),
};

export const AUDITSchema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...prompt7Schema,
  ...prompt8Schema,
  ...prompt9Schema,
  ...prompt10Schema,
};

const { HowManyTimes, YesNoThisYear } = formOptions;

const AUDIT = [
  {
    id: "AUDIT--1",
    title:
      "During the past year, how often have you had **drinks containing alcohol**?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="AUDIT.q1"
        options={HowManyTimes}
      />,
    ],
  },
  {
    id: "AUDIT--2",
    title:
      "During the past year, how many drinks containing alcohol have you had on **a typical day** when you are drinking?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="AUDIT.q2"
        options={[
          { label: "0 to 2", value: 0 },
          { label: "3 or 4", value: 1 },
          { label: "5 or 6", value: 2 },
          { label: "7 to 9", value: 3 },
          { label: "10 or more", value: 4 },
        ]}
      />,
    ],
  },
  {
    id: "AUDIT--3",
    title:
      "During the past year, how often have you had **six or more drinks** on one occasion?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="AUDIT.q3"
        options={HowManyTimes}
      />,
    ],
  },
  {
    id: "AUDIT--4",
    title:
      "During the past year, how often have you found that you were **not able to stop drinking** once you had started?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="AUDIT.q4"
        options={HowManyTimes}
      />,
    ],
  },
  {
    id: "AUDIT--5",
    title:
      "During the past year, how often have you **failed to do what was normally expected** from you because of drinking?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="AUDIT.q5"
        options={HowManyTimes}
      />,
    ],
  },
  {
    id: "AUDIT--6",
    title:
      "During the past year, how often have you **needed a first drink in the morning** to get yourself going after a heavy drinking session?",
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <RadioButtons
        key={6}
        border={true}
        fieldKey="AUDIT.q6"
        options={HowManyTimes}
      />,
    ],
  },
  {
    id: "AUDIT--7",
    title:
      "During the past year, how often have you had a **feeling of guilt or remorse** after drinking?",
    schema: yup.object().shape(prompt7Schema),
    questions: [
      <RadioButtons
        key={7}
        border={true}
        fieldKey="AUDIT.q7"
        options={HowManyTimes}
      />,
    ],
  },
  {
    id: "AUDIT--8",
    title:
      "During the past year, how often have you been **unable to remember what happened** the night before because you had been drinking?",
    schema: yup.object().shape(prompt8Schema),
    questions: [
      <RadioButtons
        key={8}
        border={true}
        fieldKey="AUDIT.q8"
        options={HowManyTimes}
      />,
    ],
  },
  {
    id: "AUDIT--9",
    title:
      "Have you or someone else been **injured** as a result of your drinking?",
    schema: yup.object().shape(prompt9Schema),
    questions: [
      <RadioButtons
        key={9}
        border={true}
        fieldKey="AUDIT.q9"
        options={YesNoThisYear}
      />,
    ],
  },
  {
    id: "AUDIT--10",
    title:
      "Has a relative or friend, doctor or other health worker been **concerned about your drinking** or suggested you cut down?",
    schema: yup.object().shape(prompt10Schema),
    questions: [
      <RadioButtons
        key={10}
        border={true}
        fieldKey="AUDIT.q10"
        options={YesNoThisYear}
      />,
    ],
  },
];

export default AUDIT;
