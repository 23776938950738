import { gql } from "@apollo/client";

const getDocument = gql`
  query getDocument($id: ID!) {
    document(id: $id) {
      url
      file_name
    }
  }
`;

export default getDocument;
