import { AssessmentKind } from "@spring/constants";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import Meowth from "@spring/meowth";
import { isMinor } from "utils/memberHelpers";

export const useJourneySection = (member) => {
  const hasTrackedGoals = !!member?.tracked_goals.length;
  const hasTakenOneCheckin = member?.assessments?.some(
    (assessment) => assessment.kind === AssessmentKind.GoalCheckIn,
  );
  const hasCheckInTodo = member?.todos?.some(
    (todo) => todo.kind === "Member/Check-in/Complete",
  );
  const isJourneyFlagEnabled = useFeatureFlag(
    FLAGS.MEMBER_JOURNEY_HOMEPAGE_RELEASE,
  );
  const isMomentsOnlyUser =
    Meowth.getUserScopes().includes("group:moments_only");
  const journeySectionEligible =
    !isMinor(member) &&
    !isMomentsOnlyUser &&
    hasCheckInTodo &&
    hasTakenOneCheckin &&
    hasTrackedGoals;

  const shouldShowJourneySection =
    isJourneyFlagEnabled && journeySectionEligible;

  const hasUpcomingAppointments = Boolean(
    member?.first_upcoming_appointments?.therapy ||
      member?.first_upcoming_appointments?.coaching ||
      member?.first_upcoming_appointments?.medication_management,
  );

  return {
    journeySectionEligible,
    shouldShowJourneySection,
    hasUpcomingAppointments,
  };
};
