import NextLink from "next/link";
import { Box, HStack } from "design-system/components";
import { VChevronRightSmallIcon } from "design-system/assets/verdant";
import { TRACK_EVENT } from "utils/mixpanel";

export const WrapperMobile = ({ children, link, accountLinkAriaLabel }) => {
  const trackClick = () =>
    TRACK_EVENT.LINK_CLICKED(window.location.pathname, link, "View Account", {
      spring_doc_id: "famhp001",
      location: "Your Family",
    });

  return (
    <NextLink href={link} passHref>
      <HStack
        h={"10 0%"}
        mb={40}
        justifyContent="space-between"
        cursor="pointer"
        _active={{
          transform: "scale(1.025)",
        }}
        tabIndex={0}
        aria-label={accountLinkAriaLabel}
        role="link"
        onClick={trackClick}
      >
        {children}
        <Box pr={16}>
          <VChevronRightSmallIcon height="1.5em" width="1.5em" />
        </Box>
      </HStack>
    </NextLink>
  );
};
