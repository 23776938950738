import { useMemo } from "react";

// If the deviceId is "default", attempt to find and return the device ID that matches the given device label.
// If no match is found, return undefined to prevent any unintended effects from using a default device.
const getSpecificDeviceId = (
  devices: MediaDeviceInfo[],
  deviceId: string,
  deviceLabel: string,
): string | undefined => {
  if (deviceId === "default" || deviceId === "communications") {
    const foundDevice = devices.find(
      (device) =>
        deviceLabel?.includes(device.label) &&
        device.deviceId !== "default" &&
        device.deviceId !== "communications",
    );
    return foundDevice ? foundDevice.deviceId : undefined;
  }
  return deviceId;
};

// Retrieve the specific device ID and return a ConstrainDOMString with the exact attribute
export const useSpecificAudioCapture = (
  audioSessionDevices: MediaDeviceInfo[],
  selectedAudioInputDeviceID: string,
  selectedAudioInputDeviceLabel: string,
): ConstrainDOMString => {
  const specificAudioDeviceID = useMemo(() => {
    return getSpecificDeviceId(
      audioSessionDevices,
      selectedAudioInputDeviceID,
      selectedAudioInputDeviceLabel,
    );
  }, [selectedAudioInputDeviceID]);
  return specificAudioDeviceID ? { exact: specificAudioDeviceID } : undefined;
};

// Uses the specific Device ID function, passing an empty string to convert
// "default" to undefined, and returns the ConstrainDOMString if a specific ID is selected.
export const useSpecificVideoCapture = (
  videoSessionDevices: MediaDeviceInfo[],
  selectedVideoInputDeviceID: string,
): ConstrainDOMString => {
  const specificVideoDeviceID = useMemo(() => {
    return getSpecificDeviceId(
      videoSessionDevices,
      selectedVideoInputDeviceID,
      null,
    );
  }, [selectedVideoInputDeviceID]);

  return specificVideoDeviceID ? { exact: specificVideoDeviceID } : undefined;
};
