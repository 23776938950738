import { COMPASS_DOMAIN } from "constants/api";
import { useEffect, useState } from "react";
import pichu from "constants/pichu";
import Cookies from "universal-cookie";

const buildCookies = () => {
  const compassOriginAccessToken = `${COMPASS_DOMAIN.replaceAll(".", "_")}_access_token`;
  const cookieExpiration = new Date();
  cookieExpiration.setFullYear(cookieExpiration.getFullYear() + 1);

  const cookies = new Cookies(null, {
    path: "/",
    domain: window.location.host.includes("localhost")
      ? undefined
      : `.${window.location.host.split(".").slice(-2).join(".")}`,
    expires: cookieExpiration,
  });

  return { compassOriginAccessToken, cookies };
};

export const syncCompassAuth = () => {
  const { cookies, compassOriginAccessToken } = buildCookies();
  const compassCookieValue = cookies.get(compassOriginAccessToken);

  try {
    if (compassCookieValue && pichu.validateAccessToken(compassCookieValue)) {
      localStorage.setItem("compass_redirect_access_token", compassCookieValue);
      return true;
    }

    if (
      localStorage.getItem("compass_redirect_access_token") &&
      pichu.validateAccessToken(
        localStorage.getItem("compass_redirect_access_token"),
      )
    ) {
      return true;
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.log(error);
  }
  return false;
};

export const useCompassAuthSync = (enabled: boolean) => {
  const [authenticatedViaCompass, setAuthenticatedViaCompass] = useState(false);
  useEffect(() => {
    if (enabled && !authenticatedViaCompass) {
      setAuthenticatedViaCompass(syncCompassAuth());
    }
  }, [enabled]);

  return authenticatedViaCompass;
};
