import { EfficacySurvey } from "./EfficacySurvey";
import { ThanksForLettingUsKnow } from "./ThanksForLettingUsKnow";

export const DefaultCompletionSteps = ({
  exerciseTitle,
  exerciseId,
  exerciseMediaType,
  exerciseCategory,
  feeling,
  setFeeling,
  handleOnClick,
}: {
  feeling?: string;
  exerciseId?: string;
  exerciseTitle?: string;
  exerciseCategory?: unknown;
  exerciseMediaType?: unknown;
  setFeeling: (value: string) => void;
  handleOnClick: () => void;
}) => {
  const surveyCompleted = !!feeling;

  if (!surveyCompleted) {
    return (
      <EfficacySurvey
        exerciseId={exerciseId}
        exerciseTitle={exerciseTitle}
        exerciseCategory={exerciseCategory}
        exerciseMediaType={exerciseMediaType}
        setFeeling={setFeeling}
      />
    );
  }

  if (surveyCompleted) {
    return (
      <ThanksForLettingUsKnow
        exerciseId={exerciseId}
        exerciseTitle={exerciseTitle}
        exerciseCategory={exerciseCategory}
        exerciseMediaType={exerciseMediaType}
        handleOnClick={handleOnClick}
      />
    );
  }

  return null;
};
