// 

import React from 'react';
import classnames from 'classnames';


import styles from './styles.module.scss';


const Subhead = (props) => (
	<div
		className={classnames(styles.subhead, {
			[styles[props.status]] : props.status,
			[styles.bold]          : props.bold,
			[styles.semibold]      : props.semibold,
			[styles.italic]        : props.italic,
			[styles.underline]     : props.underline,
			[styles.center]        : props.center,
			[styles.noMargin]      : props.noMargin,
			[styles.smMargin]      : props.smMargin,
			[styles.inheritColor]  : props.inheritColor,
			[styles.inline]        : props.inline,
			[styles.dark]          : props.dark,
			[styles.mdHeader]      : props.mdHeader,
		})}
		data-cy={props.dataCy}
	>
		{props.children}
	</div>
);

export default Subhead;
