import { gql } from "@apollo/client";

const getMemberSignupInfo = gql`
  query getMemberSignupInfo($id: ID!) {
    customer(id: $id) {
      id
      active
      active_at
      inactive_at

      domain

      default_cohort {
        id
        name
        dependents_allowed
      }
    }
  }
`;

export default getMemberSignupInfo;
