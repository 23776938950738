import React, { useState, useEffect } from "react";
import NextLink from "next/link";
import {
  Box,
  Button,
  Heading,
  Link,
  useBreakpointValue,
} from "design-system/components";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTranslation } from "react-i18next";

const UpNextCompleted = () => {
  const { t } = useTranslation("homepage");
  const headingVariant = useBreakpointValue([
    "sm_v1",
    "sm_v1",
    "lg_v1",
    "lg_v1",
  ]);

  let [trackedOnce, setTrackedOnce] = useState(0);
  useEffect(() => {
    if (trackedOnce === 0) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Up next completed",
        {
          spring_doc_id: "HPR_020",
          location: "Up Next section",
        },
      );
      setTrackedOnce(1);
    }
  }, []);
  return (
    <>
      <Box maxW={[360, 360, 360, 535, 614]}>
        <Heading variant={headingVariant}>
          {t("upNextSection.completedState.heading")}
        </Heading>
      </Box>
      <Box pt={24}>
        <NextLink href={routes.MemberCareVisits.as} passHref>
          <Button
            as={Link}
            colorScheme="primary"
            size="md"
            onClick={() => {
              TRACK_EVENT.BUTTON_CLICKED(
                window.location.pathname,
                "Manage sessions",
                {
                  spring_doc_id: "HPR_026",
                  location: "Recommended care items completed",
                },
              );
            }}
          >
            Manage sessions
          </Button>
        </NextLink>
      </Box>
    </>
  );
};

export default UpNextCompleted;
