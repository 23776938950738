import { useState } from "react";

import { useDispatch } from "react-redux";

import { SHCheckboxInput } from "design-system/components";
import PropTypes from "prop-types";
import { setField } from "actions/assessment/actions";

const TextInput = (props) => {
  const [isChecked, setChecked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useDispatch();

  const handleBlur = (e) => {
    const value = e.target.value.trim();

    if (!value || value === props.label) {
      setChecked(false);
    } else {
      setChecked(true);
    }

    setIsFocused(false);
  };

  const handleChange = (e) => {
    dispatch(setField(props.formKey, props.fieldKey, e.target.value, "string"));
  };

  return (
    <SHCheckboxInput
      checked={isChecked}
      onBlur={handleBlur}
      onChange={handleChange}
      key={props.fieldKey}
      fieldKey={props.fieldKey}
      label={props.label}
      dataCy={props.dataCy}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      placeholder={props.placeholder}
      fontWeight={400}
    />
  );
};

TextInput.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  setIsFocused: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
};

export { TextInput };
