import { gql } from "@apollo/client";

const getInsuranceEligibilityCheck = gql`
  query InsuranceEligibilityCheck(
    $member_id: ID!
    $insurance_member_id: ID!
    $insurance_payer_id: ID!
    $on_success_create_coverage: Boolean
  ) {
    insurance_eligibility_check(
      member_id: $member_id
      insurance_member_id: $insurance_member_id
      insurance_payer_id: $insurance_payer_id
      on_success_create_coverage: $on_success_create_coverage
    ) {
      success
      failure_reason
      eligibility {
        insurance_group_id
        plan_name
      }
    }
  }
`;

export default getInsuranceEligibilityCheck;
