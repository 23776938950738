import { createIcon } from "@chakra-ui/react";

export const Sparkle = createIcon({
  displayName: "Sparkle",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.124 3.188c.644-1.741 3.107-1.741 3.752 0l1.873 5.063 5.063 1.874c1.742.644 1.742 3.107 0 3.751L15.75 15.75l-1.873 5.063c-.645 1.741-3.108 1.741-3.752 0L8.251 15.75l-5.064-1.874c-1.741-.644-1.741-3.107 0-3.751L8.251 8.25l1.873-5.063Zm3.909 6.188L12 3.882 9.967 9.376a1 1 0 0 1-.591.591L3.88 12l5.495 2.034a1 1 0 0 1 .59.59L12 20.12l2.033-5.495a1 1 0 0 1 .59-.59L20.119 12l-5.494-2.033a1 1 0 0 1-.591-.59ZM18.698 2l.729 1.97 1.97.728-1.97.729-.729 1.97-.729-1.97L16 4.698l1.97-.729L18.7 2ZM4.5 21a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      clipRule="evenodd"
    />
  ),
  defaultProps: {
    "aria-label": "Sparkle icon",
    role: "img",
    color: "white",
  },
});
