import React from "react";
import { colors } from "design-system/theme/base/colors";

interface SHProgressBarProps {
  min: number;
  max: number;
  barColor?: string;
  height?: number;
  current: number;
  ariaLabel: string;
}

export const SHProgressBar = ({
  barColor = colors.secondary.base,
  height = 8,
  min,
  max,
  current,
  ariaLabel,
}: SHProgressBarProps) => {
  const value = ((current - min) / (max - min)) * 100;

  return (
    <div
      style={{ backgroundColor: colors.tertiary.light, height: height }}
      role="progressbar"
      aria-label={ariaLabel}
      aria-valuenow={value}
    >
      <div
        style={{
          backgroundColor: barColor,
          height: "100%",
          width: `${value}%`,
          transition: "all .6s ease-out",
        }}
      />
    </div>
  );
};
