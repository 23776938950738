/**
 * Constants used to determine Assessment optimism levels
 */

// Setting this as its own enum so we can extend it easily
const optimismLevel = Object.freeze({
	Min  : 0,
	High : 8,
	Max  : 10,
});

const isOptimistic = val => {
	return val >= optimismLevel.High;
};

module.exports = {
	optimismLevel,
	isOptimistic,
};
