import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import pichu from "constants/pichu";
import { Box, Button, Flex, Heading, Text } from "design-system/components";
import routes from "routes";
import { getFirstError } from "utils/apollo/errorHandler";
import { addNotification } from "@spring/smeargle/actions";
import { useDispatch } from "react-redux";

const ConfigureMfa = () => {
  const dispatch = useDispatch();
  const [isMfaActive, setIsMfaActive] = useState<boolean>(false);

  const router = useRouter();
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });

  useEffect(() => {
    pichu
      .getMe()
      .then((res) => {
        setIsMfaActive(res.data.mfa_enabled);
      })
      .catch((err) => {
        dispatch(addNotification(getFirstError(err), "error"));
      });
  }, []);

  const setupMfa = () => {
    router.push(routes.MemberSetupMfa.to, routes.MemberSetupMfa.as);
  };

  const removeMfa = () => {
    pichu
      .removeMfa()
      .then(() => {
        dispatch(addNotification(t("successfullyRemovedMfa"), "success"));
        setIsMfaActive(false);
      })
      .catch((res) => {
        dispatch(addNotification(getFirstError(res), "error"));
      });
  };

  return (
    <section>
      <Box data-cy="configureMfa" my={16}>
        <Heading fontSize={20} fontWeight={"bold"} color="platform.900">
          {t("settingsTitle")}
        </Heading>
        <Text variant="body1" color="platform.base" my={16}>
          {t("settingsSubtitle")}
        </Text>
        <Box
          px={24}
          py={12}
          mb={16}
          borderWidth="1px"
          borderColor="platform.base"
          borderRadius="base"
        >
          <Flex justify="space-between">
            <Text variant="body1" color="platform.700" fontWeight={700}>
              {t("settingsStatusMessage")}
            </Text>
            {isMfaActive ? (
              <Text
                data-testid="mfaStatusActive"
                variant="body1"
                color="platform.700"
                fontWeight={700}
              >
                {t("settingsStatusEnabled")}
              </Text>
            ) : (
              <Text
                data-testid="mfaStatusInactive"
                variant="body1"
                color="platform.700"
                fontWeight={700}
              >
                {t("settingsStatusDisabled")}
              </Text>
            )}
          </Flex>
        </Box>
        <Flex justify="flex-end">
          {isMfaActive ? (
            <Button
              data-testid="removeMfaButton"
              variant="solid"
              colorScheme="error"
              h="58"
              w="150px"
              onClick={removeMfa}
            >
              {t("settingsDisableMfa")}
            </Button>
          ) : (
            <Button
              data-testid="setupMfaButton"
              variant="solid"
              colorScheme="primary"
              h="58"
              w="150px"
              onClick={setupMfa}
            >
              {t("settingsEnableMfa")}
            </Button>
          )}
        </Flex>
      </Box>
    </section>
  );
};

export default ConfigureMfa;
