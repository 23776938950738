import { AUTH_API_DOMAIN } from "constants/api";

import Pichu from "@spring/pichu";

import { reduxStore } from "./reduxStore";

export const AUTH_MESSAGE_CL_LOOKUP_FAILED = "CL_LOOKUP_FAILED";
export const AUTH_MESSAGE_UNCLAIMED_CL_FOUND = "UNCLAIMED_CL_FOUND";
export const AUTH_MESSAGE_CL_AWAITING_SSO = "CL_CLAIMED_AWAITING_SSO";

export default new Pichu(AUTH_API_DOMAIN, reduxStore);
