//

import { flow } from "lodash/fp";
import { setIn } from "@spring/immutability";

import {
  UPDATE_FIND_MY_MEMBERSHIP_MODEL,
  RESET_FIND_MY_MEMBERSHIP_MODEL,
  UPDATE_FAMILY_SIGNUP_MODEL,
  RESET_FAMILY_SIGNUP_MODEL,
  UPDATE_VERIFICATION_MODEL,
  RESET_VERIFICATION_MODEL,
} from "actions/signUp/types";

export const initialState = {
  findMyMembershipModel: {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    email_address: "",
    potential_member_type: "",
    country: "",
  },
  familySignupModel: {
    is_family_signup: null,
    family_signup_step: null,
    guardian_phone_number: "",
  },
  verificationModel: {
    cohort_id: "",
    invite_token: "",
  },
};

const signUp = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIND_MY_MEMBERSHIP_MODEL:
      return flow(
        setIn(
          `findMyMembershipModel.${action.payload.fieldKey}`,
          action.payload.value,
        ),
      )(state);
    case RESET_FIND_MY_MEMBERSHIP_MODEL:
      return flow(
        setIn("findMyMembershipModel", initialState.findMyMembershipModel),
      )(state);
    case UPDATE_FAMILY_SIGNUP_MODEL:
      return flow(
        setIn(
          `familySignupModel.${action.payload.fieldKey}`,
          action.payload.value,
        ),
      )(state);
    case RESET_FAMILY_SIGNUP_MODEL:
      return flow(setIn("familySignupModel", initialState.familySignupModel))(
        state,
      );
    case UPDATE_VERIFICATION_MODEL:
      return flow(
        setIn(
          `verificationModel.${action.payload.fieldKey}`,
          action.payload.value,
        ),
      )(state);
    case RESET_VERIFICATION_MODEL:
      return flow(setIn("verificationModel", initialState.verificationModel))(
        state,
      );
    default:
      return state;
  }
};

export default signUp;
