import { Button } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import routes from "routes/index";
import Router from "next/router";
import { links } from "@spring/constants";

export const MomentsCareTabT2 = () => {
  const [isMobile] = useMediaQuery(["(max-width: 680px)"]);
  const { t } = useTranslation(["t2Benefits", "limitedLangBenefits"]);
  const exercises = [
    "anxiety",
    "focus",
    "personalGrowth",
    "depression",
    "sleep",
    "emotions",
    "exercise",
    "relationships",
    "eating",
    "substanceUse",
    "mindfulness",
  ];

  return (
    <Box my="32px">
      <Heading variant="sm_v1" mb="16px">
        {t("limitedLangBenefits:selfGuidedExercises.moments")}
      </Heading>
      <Flex gap="0 100px" direction={isMobile ? "column" : "row"}>
        <Flex
          flexDirection="column"
          width={isMobile ? "100%" : "50%"}
          maxWidth="475px"
        >
          <Flex flexDirection="column">
            <Text pb="15px">
              {t("limitedLangBenefits:selfGuidedExercises.paragraph.one")}
            </Text>
            <Text pb="15px">
              {t("limitedLangBenefits:selfGuidedExercises.paragraph.two")}
            </Text>
            <Text pb="15px">
              <Trans
                ns={"t2Benefits"}
                i18nKey={"selfGuidedExercises.paragraph.three"}
                components={[<strong key={0} />]}
              />
            </Text>
            <Image
              src={
                links.SpringCDN +
                "/assets/images/members/moments-tab-bar-zoom.png"
              }
              width="285px"
              pt="8px"
            ></Image>
          </Flex>
        </Flex>
        <Flex direction="column" marginTop={isMobile && "3rem"} flexGrow={1}>
          <Flex>
            <Text>{t("limitedLangBenefits:selfGuidedExercises.heading")}</Text>
          </Flex>
          <Flex gap="64px">
            <UnorderedList>
              {exercises.slice(0, 6).map((exercise) => (
                <ListItem
                  key={exercise}
                  fontWeight="600"
                  p="5px 0"
                  color="#6a6a6a"
                >
                  {t(
                    `limitedLangBenefits:selfGuidedExercises.exercises.${exercise}`,
                  )}
                </ListItem>
              ))}
            </UnorderedList>
            <UnorderedList>
              {exercises.slice(6).map((exercise) => (
                <ListItem
                  key={exercise}
                  fontWeight="600"
                  p="5px 0"
                  color="#6a6a6a"
                >
                  {t(
                    `limitedLangBenefits:selfGuidedExercises.exercises.${exercise}`,
                  )}
                </ListItem>
              ))}
            </UnorderedList>
          </Flex>
          <Flex mt="32px">
            <Button
              p="16px 32px"
              width="100%"
              onClick={() => {
                const { to, as } = routes.MemberMoments;
                Router.push(to, as);
              }}
            >
              {t("limitedLangBenefits:selfGuidedExercises.tryMomentsNow")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
