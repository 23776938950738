import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Input } from "@springcare/sh-component-library";
import { VCircleCheckIcon, VErrorIcon } from "@springcare/verdant-icons-react";

const onSubtleStyles = {
  border: "1px solid var(--Info-On-Subtle, #244F73)",
  color: "info-on-subtle",
  backgroundColor: "info-subtle",
  _focus: {
    borderColor: "info-on-subtle",
    boxShadow: "0px 0px 0px 4px rgba(36, 79, 115, 0.24);",
    color: "info-on-subtle",
    backgroundColor: "info-subtle",
  },
  _hover: {
    borderColor: "info-on-subtle",
  },
};

export const InsuranceMemberIdInput = ({
  label,
  placeholder,
  name,
  register,
  errors,
  isCheckingEligibility,
  isIndeterminate,
  isInsuranceValidated,
  handleMemberIdChange,
}) => {
  const { t } = useTranslation("insurance");
  return (
    <FormControl isRequired={true} isInvalid={!!errors[name]}>
      <FormLabel fontWeight="normal" htmlFor={name}>
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          ps={"v-16"} // component lib Input bug not rendering the padding
          {...register(name, {
            required: t("form.errors.requiredField"),
          })}
          name={name}
          type={"text"}
          sx={isIndeterminate && onSubtleStyles}
          variant={"medium-emphasis"}
          placeholder={placeholder}
          _placeholder={{ color: "content-placeholder" }}
          onChange={handleMemberIdChange}
          aria-label={name}
        />
        {isCheckingEligibility && (
          <InputRightElement height={48} width={48}>
            <Flex
              p={1}
              width={32}
              height={32}
              borderRadius={"v-md"}
              alignItems={"center"}
              justifyContent={"center"}
              backgroundColor={"accent-subtle"}
            >
              <Icon
                color={"accent-on-subtle"}
                height={19}
                width={19}
                aria-hidden={true}
                as={Spinner}
              />
            </Flex>
          </InputRightElement>
        )}
        {isIndeterminate && (
          <InputRightElement height={48}>
            <Icon
              data-testid={"indeterminate-icon"}
              aria-label={"indeterminate-icon"}
              aria-hidden={true}
              boxSize={"v-md"}
              as={VErrorIcon}
              color="info-on-subtle"
            />
          </InputRightElement>
        )}
        {isInsuranceValidated && !isCheckingEligibility && (
          <InputRightElement height={48} width={48}>
            <Flex
              p={1}
              width={32}
              height={32}
              borderRadius={"v-md"}
              backgroundColor={"positive-subtle"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon
                aria-hidden={true}
                data-testid={"circle-check-icon"}
                as={VCircleCheckIcon}
                color={"positive-on-subtle"}
                height={22}
                width={22}
              />
            </Flex>
          </InputRightElement>
        )}
      </InputGroup>
      {/* Render custom error message - cant use the FormErrorMessage component
        since the indeterminate state is not a react-hook-form validation error. */}
      {isIndeterminate && (
        <Text
          color={"hover-info-high-emphasis"}
          mt={"v-8"}
          size={"body-medium-regular"}
        >
          {t("form.indeterminateInsuranceMemberId")}
        </Text>
      )}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  );
};
