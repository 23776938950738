/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React from "react";
import {
  Headline,
  Subhead,
  Subtitle,
  Content,
  Icon,
  FlexRow,
} from "@spring/smeargle";
import { useTranslation } from "react-i18next";

import { AuthWrapper } from "components/layout";

import styles from "./styles.module.scss";

const Slide = ({ title, subtitle, children, back }) => {
  const { t } = useTranslation("limitedLangAuth");
  return (
    <div className={styles.slide}>
      <div role="main">
        <AuthWrapper>
          {back && (
            <div
              tabIndex="0"
              role="button"
              aria-label={t("forms.dependentInviteForm.back")}
              className={styles.back}
              onClick={back}
            >
              <FlexRow alignment="center">
                <Subhead noMargin status="dark">
                  <Icon
                    iconProps={{
                      "aria-label": t("forms.dependentInviteForm.back"),
                    }}
                    type="arrow-left"
                  />
                </Subhead>
                <span className={styles.spacer} />
                <Content bold status="dark">
                  {t("forms.dependentInviteForm.back")}
                </Content>
              </FlexRow>
            </div>
          )}

          <div className={styles.content}>
            <div role="heading" aria-level="1" className={styles.header}>
              <div className={styles.title}>
                <Headline center noMargin status="primary">
                  {title}
                </Headline>
              </div>
              <div className={styles.subtitle}>
                <Subtitle center noMargin>
                  {subtitle}
                </Subtitle>
              </div>
            </div>
            {children}
          </div>
        </AuthWrapper>
      </div>
    </div>
  );
};

Slide.propTypes = {
  back: PropTypes.any,
  children: PropTypes.any,
  showEmergency: PropTypes.bool,
  subtitle: PropTypes.any,
  title: PropTypes.any,
};

export default Slide;
