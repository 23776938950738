import COLORS from '../../../../../constants/colors';

import React from 'react';
import PropTypes from 'prop-types';

const MobileIcon = ({ color }) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill={color || COLORS.PRIMARY_500} d="M6.66667 25.3333C5.93029 25.3333 5.33333 25.9303 5.33333 26.6667C5.33333 27.403 5.93029 28 6.66667 28H13.3333C14.0697 28 14.6667 27.403 14.6667 26.6667C14.6667 25.9303 14.0697 25.3333 13.3333 25.3333H6.66667Z" />
		<path fill={color || COLORS.PRIMARY_500} clipRule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V28C20 30.2091 18.2091 32 16 32H4C1.79086 32 0 30.2091 0 28V4ZM4 2.66667C3.26362 2.66667 2.66667 3.26362 2.66667 4V28C2.66667 28.7364 3.26362 29.3333 4 29.3333H16C16.7364 29.3333 17.3333 28.7364 17.3333 28V4C17.3333 3.26362 16.7364 2.66667 16 2.66667H13.3333C13.3333 3.40305 12.7364 4 12 4H8C7.26362 4 6.66667 3.40305 6.66667 2.66667H4Z"/>
	</svg>	
);

MobileIcon.propTypes = {
	color: PropTypes.string,
};

export default MobileIcon;
