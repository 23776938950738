import { useTranslation } from "react-i18next";
import { Button } from "@springcare/sh-component-library";
import { Box, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect, useState, useRef } from "react";
import routes from "routes/MemberDashboardRoutes";
import { JourneySectionContent } from "./JourneySectionContent";
import { GoalsList } from "./GoalsList";
import { useMemberGoalProgress } from "./useMemberGoalProgress";
import { Skeleton } from "design-system/components";
import { useInViewportOnce } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";

export const JourneySection = ({
  hasUpcomingAppointments,
  memberId,
  trackedGoals,
}) => {
  const ref = useRef();
  const isInView = useInViewportOnce(ref, "0px");
  const [selectedGoal, setSelectedGoal] = useState(0);
  const trackedGoalID = trackedGoals?.[selectedGoal]?.id;
  const { goalProgress, goalProgressLoading, goalProgressError } =
    useMemberGoalProgress(trackedGoalID);
  const JourneySectionHeadingVariant = useBreakpointValue([
    "md_v1",
    "lg_v1",
    "lg_v1",
    "lg_v1",
  ]);
  const { t } = useTranslation("homepage");
  const router = useRouter();

  const redirectToJourney = () => {
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "View All Goals", {
      location: "Your Progress",
      to: "Journey Page",
    });
    router.push(routes.MemberProgressDashboard.as);
  };

  if (goalProgressError) {
    return null;
  }
  useEffect(() => {
    if (isInView) {
      TRACK_EVENT.COMPONENT_VIEWED(router.asPath, "Your Progress", {
        primary_cta: hasUpcomingAppointments
          ? "Take Check-In"
          : "Schedule Session",
      });
    }
  }, [isInView, hasUpcomingAppointments]);

  return (
    <Box ref={ref} pt={[90, 90, 92, 100]}>
      <Heading pb={8} variant={JourneySectionHeadingVariant}>
        {t("journeySection.heading")}
      </Heading>
      <Text pt={[0, 8]} pb={24} size="body-medium-regular">
        {t("journeySection.description")}
      </Text>
      <Box mb="8">
        <GoalsList
          trackedGoals={trackedGoals}
          selectedGoal={selectedGoal}
          setSelectedGoal={setSelectedGoal}
        />
      </Box>
      {goalProgressLoading && (
        <Skeleton
          data-testid="skeleton"
          height={[600, 600, 500, 400]}
          borderRadius={16}
        />
      )}
      {!goalProgressLoading && (
        <JourneySectionContent
          hasUpcomingAppointments={hasUpcomingAppointments}
          memberId={memberId}
          goalProgress={goalProgress}
        />
      )}
      <Button
        colorScheme="base"
        variant="low-emphasis"
        size="sm"
        onClick={redirectToJourney}
        mt="24px"
      >
        <Text size="body-medium-strong">
          {t("journeySection.viewAllGoals")}
        </Text>
      </Button>
    </Box>
  );
};
