import { createIcon } from "@chakra-ui/react";

export const QuestionMark = createIcon({
  displayName: "QuestionMark",
  viewBox: "0 0 20 20 ",
  path: (
    <path
      d="M9 15C9 15.5523 9.44771 16 10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14C9.44771 14 9 14.4477 9 15ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.959 4 6.27622 5.52671 6.03079 7.50099C5.99672 7.77502 6.22386 8 6.5 8H7.5C7.77614 8 7.99363 7.77262 8.06235 7.50517C8.28402 6.64245 9.07063 6 10 6C11.1 6 12 6.9 12 8C12 9.89107 9.3179 9.77056 9.02573 12.4992C8.99633 12.7737 9.22386 13 9.5 13H10.5C10.7761 13 10.9931 12.7724 11.053 12.5029C11.4545 10.6966 14 10.3028 14 8C14 5.79 12.21 4 10 4Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    "aria-label": "Question mark icon",
    role: "img",
  },
});
