import COLORS from '../styles/_variables.scss';

const googleMapColor = [
	{
		'elementType' : 'labels.text',
		'stylers'     : [
			{
				'lightness': 5,
			},
			{
				'weight': 1,
			},
		],
	},
	{
		'featureType' : 'landscape.natural',
		'elementType' : 'geometry.fill',
		'stylers'     : [
			{
				'visibility': 'off',
			},
		],
	},
	{
		'featureType' : 'landscape.natural',
		'elementType' : 'labels.text',
		'stylers'     : [
			{
				'visibility': 'on',
			},
		],
	},
	{
		'featureType' : 'poi',
		'elementType' : 'geometry.fill',
		'stylers'     : [
			{
				'color': '#bdecc4',
			},
			{
				'visibility': 'on',
			},
		],
	},
	{
		'featureType' : 'road',
		'elementType' : 'geometry',
		'stylers'     : [
			{
				'lightness': 100,
			},
			{
				'visibility': 'simplified',
			},
		],
	},
	{
		'featureType' : 'road',
		'elementType' : 'labels',
		'stylers'     : [
			{
				'visibility': 'off',
			},
		],
	},
	{
		'featureType' : 'transit.line',
		'elementType' : 'geometry',
		'stylers'     : [
			{
				'color': '#f7f8fa',
			},
			{
				'lightness': 700,
			},
			{
				'visibility': 'on',
			},
		],
	},
	{
		'featureType' : 'water',
		'stylers'     : [
			{
				'color': '#a7cdf2',
			},
		],
	},
];

COLORS.GOOGLE_MAPS = googleMapColor;

export default COLORS;