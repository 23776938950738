import React from "react";

const FrownSvg = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5727 42.2582C22.3256 42.8506 20.8343 42.3199 20.2418 41.0727C19.6494 39.8256 20.1801 38.3343 21.4273 37.7418C24.0285 36.5061 26.9371 35.8159 30 35.8159C33.0629 35.8159 35.9715 36.5061 38.5727 37.7418C39.8199 38.3343 40.3506 39.8256 39.7582 41.0727C39.1657 42.3199 37.6744 42.8506 36.4273 42.2582C34.4822 41.3342 32.3051 40.8159 30 40.8159C27.6949 40.8159 25.5178 41.3342 23.5727 42.2582Z"
      fill="#6C7071"
    />
    <path
      d="M27.5 28.75C27.5 30.8211 25.8211 32.5 23.75 32.5C21.6789 32.5 20 30.8211 20 28.75C20 26.6789 21.6789 25 23.75 25C25.8211 25 27.5 26.6789 27.5 28.75Z"
      fill="#6C7071"
    />
    <path
      d="M36.25 32.5C38.3211 32.5 40 30.8211 40 28.75C40 26.6789 38.3211 25 36.25 25C34.1789 25 32.5 26.6789 32.5 28.75C32.5 30.8211 34.1789 32.5 36.25 32.5Z"
      fill="#6C7071"
    />
    <path
      d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM5 30C5 16.1929 16.1929 5 30 5C43.8071 5 55 16.1929 55 30C55 43.8071 43.8071 55 30 55C16.1929 55 5 43.8071 5 30Z"
      fill="#6C7071"
    />
  </svg>
);

export default FrownSvg;
