const TRACKING = {
  backToLocalResources: "Back to local resources",
  careVisitsBack: "Back to care visits",
  error: "Generic error screen",
  keywordClear: "Keyword clear",
  keywordSearch: "Keyword search submit",
  localResourcesLocation: "Local resources page",
  map: "Programs list map",
  nextPage: "Next page button",
  noResults: "No results found error screen",
  peerRecoverySpecialistFollowUp: "Peer Recovery Specialist Follow Up",
  personalAttributes: "Personal filters button",
  personalAttributesApply: "Personal filters flyout apply button",
  personalAttributesClear: "Personal filters flyout clear button",
  personalAttributesFlyout: "Personal filters flyout",
  preSearch: "Pre-search content",
  previousPage: "Previous page button",
  programDetailsBrowse: "Browse additional resources button",
  programDetailsContact: "Contact me with more info button",
  programDetailsEmailMoreInfo: "Email more info button",
  programDetailsError: "Program details error screen",
  programDetailsLink: "Program details link",
  programDetailsLocation: "Local resources program details page",
  programDetailsReferralClick: "Referral button",
  programDetailsSeeMoreLocations: "See more locations button",
  programDetailsView: "Program details view",
  programsListCard: "Programs list card",
  programsListCardPhone: "Programs list card phone number",
  programsListCardSeeMore: "Programs list card see more",
  programsListCardWebsite: "Programs list card website",
  programsMapCardSeeMore: "Programs map card see more",
  queryResult: "Local resources query result",
  sdohCard: "sdoh local resources card",
  serviceTag: "Service tag option select",
  serviceTagChip: "Pre-search service tag chip",
  sortBy: "Sort by toggle",
  zipCode: "Postal code button",
  zipcodeError: "Postal code error screen",
  zipCodeFlyout: "Postal code flyout",
  zipCodeFlyoutSubmit: "Postal code flyout submit",
};

export default TRACKING;
