import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Section,
  Breakpoint,
  Card,
  FlexCol,
  Grid,
  Col,
  Stout,
  Content,
  Avatar,
  Button,
} from "@spring/smeargle";
import { withTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import { Heading } from "design-system/components";

class CarePlanStep extends Component {
  static defaultProps = {
    kind: "",
    footer: "",
  };

  get avatar() {
    if (this.props.provider.name) {
      return (
        <Section size="lg">
          <div className={classnames(styles.flexCol, styles.imageWrapper)}>
            <Avatar
              round
              provider={this.props.provider.name}
              imgProps={{ "aria-hidden": "true" }}
              height="75px"
              width="75px"
              src={this.props.provider.avatar}
            />
            <div className={classnames(styles.flexCol, styles.textWrapper)}>
              <Section size="sm">
                <Content center bold>
                  {this.props.provider.name}
                </Content>
              </Section>
              <Stout center>
                {(this.props.provider.roles || []).join("")},{" "}
                {(this.props.provider.licenses || []).join(", ")}
              </Stout>
            </div>
          </div>
        </Section>
      );
    }

    if (!this.props.kind.includes("Onward")) {
      return (
        <Section size="lg">
          <div className={classnames(styles.flexCol, styles.imageWrapper)}>
            {this.props.provider.genericImage && (
              <div className={styles.genericImage}>
                <Avatar square src={this.props.provider.genericImage} />
              </div>
            )}
            {!this.props.kind.includes("Onward") &&
              this.props.provider.genericDescription && (
                <div className={classnames(styles.flexCol, styles.textWrapper)}>
                  <Section size="sm">
                    <Content center>
                      {this.props.provider.genericDescription}
                    </Content>
                  </Section>
                </div>
              )}
          </div>
        </Section>
      );
    }

    return null;
  }

  get hasProvider() {
    if (this.props.provider.name) {
      return (
        <Breakpoint md andDown>
          {this.avatar}
        </Breakpoint>
      );
    }

    return null;
  }

  get kind() {
    const { t } = this.props;
    if (this.props.kind.includes("Onward")) {
      return null;
    }

    if (this.props.index === this.props.firstActiveStep) {
      return (
        <div className={styles.activeKind}>
          <Stout inheritColor>{t("carePlanStep.yourNextStep")}</Stout>
        </div>
      );
    }

    return <Stout>{t("carePlanStep.comingUp")}</Stout>;
  }

  get actions() {
    return this.props.actions.map((action) => {
      return (
        <Section key={action.text}>
          <Button
            className="journey-next-step"
            full
            text={action.text}
            icon="arrow-right"
            onClick={() => {
              action.onClick();
            }}
          />
        </Section>
      );
    });
  }

  get pricingInfo() {
    if (this.props.footer) {
      return (
        <Section>
          <div className={styles.footerWrapper}>
            <Content inheritColor>{this.props.footer}</Content>
          </div>
        </Section>
      );
    }

    return null;
  }

  get signature() {
    if (this.props.kind.includes("Onward")) {
      return (
        <Section size="lg">
          <FlexCol>
            {this.props.provider.genericImage && (
              <Avatar
                square
                src={this.props.provider.genericImage}
                width="150px"
                height="auto"
              />
            )}
            {this.props.provider.genericDescription && (
              <Section size="sm">
                <Content center>
                  {this.props.provider.genericDescription}
                </Content>
              </Section>
            )}
          </FlexCol>
        </Section>
      );
    }

    return null;
  }

  render() {
    return (
      <Card>
        <Grid>
          <Col md={8}>
            <Section size="sm">{this.kind}</Section>
            <Section>
              <Heading fontSize={20} fontWeight={600} color="#4b4b4b">
                {this.props.title}
              </Heading>
            </Section>
            <Content>{this.props.description}</Content>
            {this.hasProvider}
            {this.actions}
            {this.pricingInfo}
            {this.signature}
          </Col>
          <Col md={4} alignment="center" justification="center">
            <Breakpoint md andUp>
              {this.avatar}
            </Breakpoint>
          </Col>
        </Grid>
      </Card>
    );
  }
}

CarePlanStep.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  description: PropTypes.string,
  firstActiveStep: PropTypes.number,
  footer: PropTypes.string,
  index: PropTypes.number,
  kind: PropTypes.string,
  provider: PropTypes.shape({
    avatar: PropTypes.string,
    genericDescription: PropTypes.string,
    genericImage: PropTypes.string,
    licenses: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  t: PropTypes.func,
  title: PropTypes.string,
};

export default withTranslation("memberDashboard")(CarePlanStep);
