import React from "react";
import { VideoMomentCard } from "./VideoMomentCard";
import { Badge } from "@springcare/sh-component-library";
import {
  Box,
  VStack,
  HStack,
  Flex,
  Text,
  Heading,
  Skeleton,
} from "@chakra-ui/react";
import {
  VAllCareIcon,
  VFocusIcon,
  VSleepIcon,
  VSolitudeIcon,
} from "@springcare/verdant-icons-react";
import getExercises from "operations/queries/exercise/getExercises";
import { useQuery } from "@apollo/client";
import { useTranslation } from "hooks/react-i18next";

type Props = {
  shouldShowWorkplaceContentExperiment?: boolean;
};
export const DailyMoments = ({
  shouldShowWorkplaceContentExperiment = false,
}: Props) => {
  const { t } = useTranslation("moments");
  const DAILY_MOMENTS = "Daily Moments";
  const { data, loading, error } = useQuery(getExercises, {
    variables: { daily_moments: true },
  });

  if (loading) {
    return <Skeleton height="360px" />;
  }

  if (error) {
    console.error("Daily moments:", error);
    return null;
  }

  return (
    <VStack spacing={4} align="start" w="100%">
      <HStack spacing="v-8" w="100%">
        <VAllCareIcon
          role="img"
          aria-hidden="true"
          data-testid="all-care-icon"
        />
        <Heading size="heading-small">
          {t("dailyMomentsSection.dailyMoments")}
        </Heading>
        {/* Conditionally hiding the "featured" badge is a temporary change for Manager Experience Experiment 6. MXE-830 */}
        {!shouldShowWorkplaceContentExperiment && (
          <Badge>{t("dailyMomentsSection.featured")}</Badge>
        )}
      </HStack>
      <Box>
        <Text mb="v-20" size="body-medium-regular">
          {t("dailyMomentsSection.findSupport")}
        </Text>
      </Box>
      <Flex gap="v-24" w="100%" direction={["column", "column", "row"]}>
        <VStack justifyContent="start" alignItems="start" w="100%">
          <HStack mb="v-16">
            <VFocusIcon
              role="img"
              aria-hidden="true"
              data-testid="focus-icon"
            />
            <Text size="body-medium-strong">
              {t("dailyMomentsSection.focus")}
            </Text>
          </HStack>
          <VideoMomentCard
            exercise={data?.exercises.data[0]}
            locationForTracking={DAILY_MOMENTS}
          />
        </VStack>
        <VStack alignItems="start" w="100%">
          <HStack mb="v-16">
            <VSleepIcon
              role="img"
              aria-hidden="true"
              data-testid="sleep-icon"
            />
            <Text size="body-medium-strong">
              {t("dailyMomentsSection.feelBetter")}
            </Text>
          </HStack>
          <VideoMomentCard
            exercise={data?.exercises.data[1]}
            locationForTracking={DAILY_MOMENTS}
          />
        </VStack>
        <VStack alignItems="start" w="100%">
          <HStack mb="v-16">
            <VSolitudeIcon
              role="img"
              aria-hidden="true"
              data-testid="solitude-icon"
            />
            <Text size="body-medium-strong">
              {t("dailyMomentsSection.relax")}
            </Text>
          </HStack>
          <VideoMomentCard
            exercise={data?.exercises.data[2]}
            locationForTracking={DAILY_MOMENTS}
          />
        </VStack>
      </Flex>
    </VStack>
  );
};
