//
import { gql } from "@apollo/client";

const createAppointment = gql`
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      success
      appointment {
        id
        covered
        end_at
        created_at
        kind
        medium
        start_at
        reason_for_scheduling
        updated_at
      }
    }
  }
`;

export default createAppointment;
