import { useState } from "react";
import { WorkplaceManagerSelfIdentificationCard } from "./WorkplaceManagerSelfIdentificationCard";
import { WorkplaceManagerExploreCard } from "./WorkplaceManagerExploreCard";
import { WorkplaceManagerBannersProps } from "./types";
import { hasPreAssessedExperience } from "utils/memberHelpers";

export const WorkplaceManagerBanners: React.FC<
  React.PropsWithChildren<WorkplaceManagerBannersProps>
> = ({
  removeWorkplaceManagerBanner,
  memberId,
  showExploreCard,
  showWorkplaceManagerSelfIdCardOnBottom,
  selectedOption,
}) => {
  const [
    showWorkplaceManagerExploreBanner,
    setShowWorkplaceManagerExploreBanner,
  ] = useState(showExploreCard);

  return (
    <>
      {showWorkplaceManagerExploreBanner ? (
        <WorkplaceManagerExploreCard
          removeWorkplaceManagerBanner={removeWorkplaceManagerBanner}
          memberId={memberId}
          setShowWorkplaceManagerExploreBanner={
            setShowWorkplaceManagerExploreBanner
          }
          showWorkplaceManagerSelfIdCardOnBottom={
            showWorkplaceManagerSelfIdCardOnBottom
          }
        />
      ) : (
        <WorkplaceManagerSelfIdentificationCard
          removeWorkplaceManagerBanner={removeWorkplaceManagerBanner}
          memberId={memberId}
          setShowWorkplaceManagerExploreBanner={
            setShowWorkplaceManagerExploreBanner
          }
          showWorkplaceManagerSelfIdCardOnBottom={
            showWorkplaceManagerSelfIdCardOnBottom
          }
          showPreAssessedExperience={hasPreAssessedExperience(selectedOption)}
        />
      )}
    </>
  );
};
