import { links } from "@spring/constants";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "hooks/react-i18next";
import { compact, isEmpty, pick } from "lodash";
import { useCallback } from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import CombinedConsentLinks from "./ConsentCheckbox/CombinedConsentLinks";
import { GLOBAL, US } from "./ConsentCheckbox/constants";
import { ConsentLinkType } from "./ConsentCheckbox/types";

interface ConsentCheckboxPropsType {
  currentView: string;
  errors: FieldErrors<FieldValues>;
  isCountryUS: boolean;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const ConsentCheckbox = ({
  currentView,
  errors,
  isCountryUS,
  register,
  setValue,
}: ConsentCheckboxPropsType) => {
  // No idea why we need all of these i18n namespaces?
  const { t } = useTranslation([
    "common",
    "inviteDependents",
    "limitedLangRegister",
  ]);

  // Based on isCountryUS, find which formKeys we need to submit
  const consentLinks: ConsentLinkType[] = isCountryUS ? US : GLOBAL;
  const formKeys = compact(consentLinks.map((consent) => consent.formKey));
  const informedConsentLink = isCountryUS
    ? links.InformedConsent
    : links.InformedConsentGlobal;

  // Check if any of the required formKeys exist in the useForm errors
  const hasErrors = !isEmpty(pick(errors, formKeys));

  // Use setValue to set all required consents to the same value based on the checkbox value
  const handleCheckboxChange = useCallback(
    (event) => {
      const checked = event?.target?.checked;
      for (const formKey of formKeys) setValue(formKey, checked);
    },
    [formKeys],
  );

  return (
    <FormControl isRequired isInvalid={hasErrors}>
      {/* Use hidden inputs to keep track of the required formKeys back up to useForm */}
      {formKeys.map((formKey) => (
        <input
          {...register(formKey, { required: true })}
          key={formKey}
          type="hidden"
        />
      ))}

      <Box bg="background-subtle" borderRadius={8} px={14} py={20}>
        <HStack alignItems="start">
          <Checkbox
            data-cy="consent-checkbox"
            data-testid="consent-checkbox"
            onChange={handleCheckboxChange}
            pt="2px"
            size="lg"
            variant="primary"
          />
          <Flex flexDirection="column">
            <FormLabel
              fontWeight={400}
              requiredIndicator={
                <span style={{ marginLeft: "4px" }} title="required field">
                  *
                </span>
              }
            >
              {/* I agree to Spring Health's... */}
              {t(
                "limitedLangRegister:createAccount.form.consents.userPrivacyAgree",
              )}

              {/* Use the list of required consents to create a CSV of consent links */}
              <CombinedConsentLinks
                consentLinks={consentLinks}
                currentView={currentView}
                informedConsentLink={informedConsentLink}
                t={t}
              />
            </FormLabel>
            {hasErrors && (
              <FormErrorMessage color="error.base" fontWeight="bold" pr={32}>
                {t("limitedLangRegister:createAccount.form.consents.error")}
              </FormErrorMessage>
            )}
          </Flex>
        </HStack>
      </Box>
    </FormControl>
  );
};

export default ConsentCheckbox;
