/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Section,
  Card,
  Subtitle,
  Content,
  Form,
  Input,
  PhoneInput,
  RadioButtons,
  Textarea,
  Checkbox,
} from "@spring/smeargle";
import StateSelect from "components/form/StateSelect";
import { useRouter } from "next/router";
import yup from "schemas/yup";
import { links } from "@spring/constants";
import { useTranslation } from "react-i18next";

import { Link } from "components/atoms";
import { AuthWrapper } from "components/layout";
// import { AddressTypeahead } from '../../form';

import Slide from "./Slide";

import { track } from "utils/mixpanel";
// import { isUnitedStatesOrCanada } from 'utils/global';

const mpPrefix = "Dependent Sign-Up";

const signupRequirements = {
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  city: yup.string().required(),
  phone: yup
    .object()
    .shape({
      code: yup.string(),
      number: yup.string().min(5).required(),
    })
    .required(),
};

const legalRequirements = {
  tos: yup.boolean().equals([true]).required(),
  hipaa: yup.boolean().equals([true]).required(),
  eca: yup.boolean().equals([true]).required(),
};

const ConsentFields = ({ formKey }) => {
  const { t } = useTranslation("limitedLangAuth");
  return (
    <Section size="lg">
      <Card>
        <Subtitle>{t("forms.dependentSignupForm.subtitle")}</Subtitle>
        <Checkbox
          formKey={formKey}
          fieldKey="tos"
          label={
            <Content>
              <Link to={links.TermsOfService}>
                {t("forms.dependentSignupForm.tos")}
              </Link>{" "}
              {t("forms.dependentSignupForm.and")}{" "}
              <Link to={links.PrivacyPolicy}>
                {t("forms.dependentSignupForm.privacyPolicy")}
              </Link>
              .
            </Content>
          }
        />
        <Checkbox
          formKey={formKey}
          fieldKey="hipaa"
          label={
            <Content>
              <Link to={links.PrivacyNotice}>
                {t("forms.dependentSignupForm.hippaPolicy")}
              </Link>
              .
            </Content>
          }
        />
        <Checkbox
          formKey={formKey}
          fieldKey="eca"
          label={
            <Content>
              <Link to={links.ElectronicCommunicationAgreement}>
                {t("forms.dependentSignupForm.eca")}
              </Link>
              .
            </Content>
          }
        />
      </Card>
    </Section>
  );
};

ConsentFields.propTypes = {
  formKey: PropTypes.any,
};

const WelcomeForm = ({ setStack, setPage, create, loading, back }) => {
  const router = useRouter();
  const allowsChildren = router && router.query.allowsChildren;

  const { t } = useTranslation("limitedLangAuth");

  return (
    <Slide>
      <Form
        preserve
        schema={yup.object().shape({
          who: yup.string().required(),
        })}
        formKey="who-for"
        onSubmit={({ who }) => {
          if (who === "myself") {
            setStack([
              <WelcomeForm
                key="welcome"
                setStack={setStack}
                setPage={setPage}
                create={create}
                loading={loading}
                back={back}
              />,
              <MyselfForm
                key="myself"
                setStack={setStack}
                setPage={setPage}
                create={create}
                loading={loading}
                back={back}
              />,
            ]);
          }

          if (who === "someone") {
            if (allowsChildren) {
              setStack([
                <WelcomeForm
                  key="welcome"
                  setStack={setStack}
                  setPage={setPage}
                  create={create}
                  loading={loading}
                  back={back}
                />,
                <AreYouAChildForm
                  key="child"
                  setStack={setStack}
                  setPage={setPage}
                  create={create}
                  loading={loading}
                  back={back}
                />,
              ]);
            } else {
              setStack([
                <WelcomeForm
                  key="welcome"
                  setStack={setStack}
                  setPage={setPage}
                  create={create}
                  loading={loading}
                  back={back}
                />,
                <DependentForm
                  key="dependent"
                  setStack={setStack}
                  setPage={setPage}
                  create={create}
                  loading={loading}
                  allowsChildren={allowsChildren}
                  back={back}
                />,
              ]);
            }
          }

          setPage(1);
        }}
        submitText={t("forms.dependentSignupForm.submitText")}
      >
        <RadioButtons
          fieldKey="who"
          label={t(
            "forms.dependentSignupForm.welcomeForm.radiobutton.labels.default",
          )}
          options={[
            {
              label: t(
                "forms.dependentSignupForm.welcomeForm.radiobutton.labels.1",
              ),
              value: "myself",
            },
            {
              label: t(
                "forms.dependentSignupForm.welcomeForm.radiobutton.labels.2",
              ),
              value: "someone",
            },
          ]}
        />
      </Form>
    </Slide>
  );
};

WelcomeForm.propTypes = {
  back: PropTypes.any,
  create: PropTypes.any,
  loading: PropTypes.any,
  setPage: PropTypes.func,
  setStack: PropTypes.func,
};

const MyselfForm = ({ create, loading, stack, back }) => {
  const { t } = useTranslation("limitedLangAuth");
  useEffect(() => {
    track(`${mpPrefix} -- Adult Form Viewed`);
  }, []);

  return (
    <>
      <Slide>
        <Form
          preserve
          formKey="myself-form"
          schema={yup.object().shape({
            ...signupRequirements,
            ...legalRequirements,
          })}
          actions={[
            {
              text: t("forms.dependentSignupForm.submit"),
              color: "success",
              disabled: loading,
              requireValid: true,
              onClick: (formData) => {
                track(`${mpPrefix} -- Adult Form Submitted`);
                create(formData, false, stack, "myself");
              },
            },
            {
              text: t("forms.dependentSignupForm.back"),
              color: "success",
              reverse: true,
              onClick: () => {
                track(`${mpPrefix} -- Adult Form Back Clicked`);
                back(stack);
              },
            },
          ]}
        >
          <Input
            fieldKey="first_name"
            label={t("forms.dependentSignupForm.welcomeForm.firstName")}
          />
          <Input
            fieldKey="last_name"
            label={t("forms.dependentSignupForm.welcomeForm.lastName")}
          />
          <Input
            fieldKey="email"
            label={t(
              "forms.dependentSignupForm.welcomeForm.personalEmailAddress",
            )}
          />
          <Input
            fieldKey="city"
            label={t("forms.dependentSignupForm.welcomeForm.city")}
          />
          <PhoneInput
            fieldKey="phone"
            label={t(
              "forms.dependentSignupForm.welcomeForm.personalPhoneNumber",
            )}
          />
          <ConsentFields formKey="myself-form" />
        </Form>
      </Slide>

      <Section size="sm">
        <AuthWrapper compact>
          <Subtitle noMargin status="primary">
            {t("forms.dependentSignupForm.welcomeForm.yourInformation")}
          </Subtitle>
          <Section size="sm">
            <Content>
              {t("forms.dependentSignupForm.welcomeForm.confirmContent")}
            </Content>
          </Section>
        </AuthWrapper>
      </Section>
    </>
  );
};

MyselfForm.propTypes = {
  back: PropTypes.func,
  create: PropTypes.func,
  loading: PropTypes.any,
  stack: PropTypes.any,
};

const AreYouAChildForm = ({
  setStack,
  setPage,
  stack,
  create,
  loading,
  back,
}) => {
  const { t } = useTranslation("limitedLangAuth");
  return (
    <>
      <Slide showDisclaimer>
        <Form
          preserve
          formKey="are-you-child-form"
          schema={yup.object().shape({
            younger: yup.string().required(),
          })}
          actions={[
            {
              text: t("forms.dependentSignupForm.submitText"),
              color: "success",
              onClick: ({ younger }) => {
                if (younger === "yes") {
                  setStack([
                    ...stack,
                    <ChildDependentForm
                      key="child-dependent"
                      setStack={setStack}
                      setPage={setPage}
                      create={create}
                      loading={loading}
                      back={back}
                    />,
                  ]);
                }

                if (younger === "no") {
                  setStack([
                    ...stack,
                    <DependentForm
                      key="dependent"
                      setStack={setStack}
                      setPage={setPage}
                      create={create}
                      loading={loading}
                      back={back}
                    />,
                  ]);
                }

                setPage(2);
              },
            },
            {
              text: t("forms.dependentSignupForm.back"),
              color: "success",
              reverse: true,
              onClick: () => {
                back(stack);
              },
            },
          ]}
        >
          <RadioButtons
            fieldKey="younger"
            label={t(
              "forms.dependentSignupForm.areYouAChildForm.radiobutton.labels.default",
            )}
            options={[
              {
                label: t(
                  "forms.dependentSignupForm.areYouAChildForm.radiobutton.labels.1",
                ),
                value: "yes",
              },
              {
                label: t(
                  "forms.dependentSignupForm.areYouAChildForm.radiobutton.labels.2",
                ),
                value: "no",
              },
            ]}
          />
        </Form>
      </Slide>

      <Section size="sm">
        <AuthWrapper compact>
          <Subtitle noMargin status="primary">
            {t("forms.dependentSignupForm.areYouAChildForm.registerForOther")}
          </Subtitle>
          <Section size="sm">
            <Content>
              {t(
                "forms.dependentSignupForm.areYouAChildForm.confidentialContent",
              )}
            </Content>
          </Section>
        </AuthWrapper>
      </Section>
    </>
  );
};

AreYouAChildForm.propTypes = {
  back: PropTypes.func,
  create: PropTypes.any,
  loading: PropTypes.any,
  setPage: PropTypes.func,
  setStack: PropTypes.func,
  stack: PropTypes.any,
};

const ChildDependentForm = ({ create, loading, stack, back }) => {
  const { t } = useTranslation("limitedLangAuth");
  useEffect(() => {
    track(`${mpPrefix} -- Child Form Viewed`);
  }, []);

  return (
    <>
      <Slide showDisclaimer>
        <Form
          preserve
          formKey="child-dependent-form"
          schema={yup.object().shape({
            relationship: yup.string().required(),
            grantor_name: yup.string().required(),
            additional: yup.string().required(),
            ...legalRequirements,
            ...signupRequirements,
          })}
          actions={[
            {
              text: t("forms.dependentSignupForm.submit"),
              color: "success",
              disabled: loading,
              requireValid: true,
              onClick: (formData) => {
                track(`${mpPrefix} -- Child Form Submitted`);
                create(formData, true, stack, "dependent_child");
              },
            },
            {
              text: t("forms.dependentSignupForm.back"),
              color: "success",
              reverse: true,
              onClick: () => {
                track(`${mpPrefix} -- Child Form Back Clicked`);
                back(stack);
              },
            },
          ]}
        >
          <Input
            fieldKey="first_name"
            label={t("forms.dependentSignupForm.childDependentForm.firstName")}
          />
          <Input
            fieldKey="last_name"
            label={t("forms.dependentSignupForm.childDependentForm.lastName")}
          />
          <RadioButtons
            fieldKey="relationship"
            label={t(
              "forms.dependentSignupForm.childDependentForm.radiobutton.labels.default",
            )}
            options={[
              {
                label: t(
                  "forms.dependentSignupForm.childDependentForm.radiobutton.labels.mother",
                ),
                value: "mother",
              },
              {
                label: t(
                  "forms.dependentSignupForm.childDependentForm.radiobutton.labels.father",
                ),
                value: "father",
              },
              {
                label: t(
                  "forms.dependentSignupForm.childDependentForm.radiobutton.labels.other",
                ),
                value: "other",
              },
            ]}
          />
          <Input
            fieldKey="relationship_other"
            label={t(
              "forms.dependentSignupForm.childDependentForm.relationship",
            )}
          />
          <Input
            fieldKey="grantor_name"
            label={t("forms.dependentSignupForm.childDependentForm.fullName")}
          />
          <Input
            fieldKey="email"
            label={t(
              "forms.dependentSignupForm.childDependentForm.emailAddress",
            )}
          />
          <PhoneInput
            fieldKey="phone"
            label={t(
              "forms.dependentSignupForm.childDependentForm.phoneNumber",
            )}
          />
          <Input
            fieldKey="city"
            label={t("forms.dependentSignupForm.childDependentForm.city")}
          />
          <StateSelect
            fieldKey="state"
            label={t("forms.dependentSignupForm.childDependentForm.state")}
          />
          <Textarea
            fieldKey="additional"
            label={t(
              "forms.dependentSignupForm.childDependentForm.describeIssues",
            )}
          />
          <ConsentFields formKey="child-dependent-form" />
        </Form>
      </Slide>

      <Section size="sm">
        <AuthWrapper compact>
          <Subtitle noMargin status="primary">
            {t("forms.dependentSignupForm.childDependentForm.subtitle")}
          </Subtitle>
          <Section size="sm">
            <Content>
              {t("forms.dependentSignupForm.childDependentForm.content")}
            </Content>
          </Section>
        </AuthWrapper>
      </Section>
    </>
  );
};

ChildDependentForm.propTypes = {
  back: PropTypes.func,
  create: PropTypes.func,
  loading: PropTypes.any,
  stack: PropTypes.any,
};

const DependentForm = ({ create, loading, stack, back }) => {
  const { t } = useTranslation("limitedLangAuth");
  return (
    <>
      <Slide showDisclaimer>
        <Form
          preserve
          formKey="dependent-form"
          schema={yup.object().shape({
            grantor_name: yup.string().required(),
            grantor_email: yup.string().email().required(),
            additional: yup.string().required(),
            ...signupRequirements,
            ...legalRequirements,
          })}
          actions={[
            {
              text: t("forms.dependentSignupForm.submit"),
              color: "success",
              disabled: loading,
              requireValid: true,
              onClick: (formData) => {
                create(formData, false, stack, "dependent_adult");
              },
            },
            {
              text: t("forms.dependentSignupForm.back"),
              color: "success",
              reverse: true,
              onClick: () => {
                back(stack);
              },
            },
          ]}
        >
          <Input
            fieldKey="first_name"
            label={t("forms.dependentSignupForm.dependentForm.firstName")}
          />
          <Input
            fieldKey="last_name"
            label={t("forms.dependentSignupForm.dependentForm.lastName")}
          />
          <Input
            fieldKey="email"
            label={t("forms.dependentSignupForm.dependentForm.input.label")}
            description={t(
              "forms.dependentSignupForm.dependentForm.input.description",
            )}
          />
          <Input
            fieldKey="city"
            label={t("forms.dependentSignupForm.dependentForm.city")}
          />
          <PhoneInput
            fieldKey="phone"
            label={t("forms.dependentSignupForm.dependentForm.phoneNumber")}
          />
          <Input
            fieldKey="grantor_name"
            label={t("forms.dependentSignupForm.dependentForm.fullName")}
          />
          <Input
            fieldKey="grantor_email"
            label={t("forms.dependentSignupForm.dependentForm.emailAddress")}
          />
          <Textarea
            fieldKey="additional"
            label={t(
              "forms.dependentSignupForm.dependentForm.additionInformation",
            )}
          />
          <ConsentFields formKey="dependent-form" />
        </Form>
      </Slide>

      <Section size="sm">
        <AuthWrapper compact>
          <Subtitle noMargin status="primary">
            {t("forms.dependentSignupForm.dependentForm.subtitle")}
          </Subtitle>
          <Section size="sm">
            <Content>
              {t("forms.dependentSignupForm.dependentForm.content")}
            </Content>
          </Section>
        </AuthWrapper>
      </Section>
    </>
  );
};

DependentForm.propTypes = {
  back: PropTypes.func,
  create: PropTypes.func,
  loading: PropTypes.any,
  stack: PropTypes.any,
};

export {
  WelcomeForm,
  MyselfForm,
  AreYouAChildForm,
  ChildDependentForm,
  DependentForm,
};
