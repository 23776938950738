const ROLES = [
	{ label: 'Therapist', value: 'Therapist' },
	{ label: 'Internist', value: 'Internist' },
	{ label: 'Psychiatrist', value: 'Psychiatrist' },
	{ label: 'Care Navigator', value: 'Care Navigator' },
	{ label: 'Family Practitioner', value: 'Family Practitioner' },
	{ label: 'Coach', value: 'Coach' },
	{ label: 'Psychiatric Nurse Practitioner', value: 'Psychiatric Nurse Practitioner' },
];

module.exports = ROLES;
