import { useQuery } from "@apollo/client";
import { getCareProviderForTeen } from "components/templates/TeenAssessment/queries";

const useGetCareProviderForTeen = (provider_id) => {
  const { data, loading, error } = useQuery(getCareProviderForTeen, {
    variables: {
      id: provider_id,
    },
    skip: !provider_id,
  });

  return {
    careProvider: data,
    careProviderLoading: loading,
    careProviderError: error,
  };
};

export { useGetCareProviderForTeen };
