import { React, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { PageBase } from "components/layout/Page";

import styles from "./styles.module.scss";

import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { getMemberInfo } from "operations/queries/member";
import { isMinor, isTeen } from "utils/memberHelpers";
import { useRouter } from "next/router";
import routes from "routes";

const AssessmentPage = (props) => {
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  const { data: memberData } = useQuery(getMemberInfo, {
    ...apolloOptionsUserId,
    skip: Meowth.apolloSkipUserId(),
  });

  //minors are not allowed to access asessments
  const isAMinor = isMinor(memberData?.user?.member) || props.isAMinor;
  const isATeen =
    isTeen(memberData?.user?.member?.date_of_birth) || props.isATeen;
  const router = useRouter();

  useEffect(() => {
    if (isATeen) {
      router.push(routes.TeenHome.to, routes.TeenHome.as);
    }
    if (isAMinor) {
      router.push(routes.MemberCareVisits.to, routes.MemberCareVisits.as);
    }
  }, [isAMinor, isATeen]);

  return (
    <PageBase
      allowed={props.allowed}
      darkBg={false}
      removeBasePagePadding={true}
    >
      <div
        id="assessmentPageContent"
        className={classnames(styles.newPageContent)}
      >
        {props.children}
      </div>
    </PageBase>
  );
};

AssessmentPage.defaultProps = {
  allowed: true,
};

AssessmentPage.propTypes = {
  allowed: PropTypes.bool,
  children: PropTypes.any,
};

export default AssessmentPage;
