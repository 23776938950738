//

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { openModal, addNotification } from "@spring/smeargle/actions";
import { Button } from "@spring/smeargle";
import { modalIds, RequestableAppointmentKind } from "@spring/constants";

import styles from "./styles.module.scss";

import { requestAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";

const RequestAvailabilityButton = (props) => {
  const [requestAppointmentMutation, { loading: requestAppointmentLoading }] =
    useMutation(requestAppointment, {
      variables: {
        input: {
          care_provider_id: props.modalData.id,
          availability_days_of_week: ["Unknown"],
          availability_time_of_day: ["Unknown"],
          medium:
            props.modalData.supported_appointment_mediums &&
            props.modalData.supported_appointment_mediums[0],
          kind: props.kind || RequestableAppointmentKind.MedicationManagement,
        },
      },
      onCompleted: () =>
        props.addNotification(
          "Great! Our Care Navigation Team will send you an email to schedule an appointment.",
          "success",
        ),
      onError: (err) => props.addNotification(getFirstError(err), "error"),
    });

  const requestAvailability = () => {
    const {
      isMinor,
      openModal,
      modalData,
      consent: { hipaa_privacy_policy, informed_consent },
    } = props;

    if ((!hipaa_privacy_policy || !informed_consent) && !isMinor) {
      return openModal(modalIds.beforeRequestMedicationModal, modalData);
    }

    return requestAppointmentMutation();
  };

  return (
    <div className={styles.requestAppointmentButton}>
      <Button
        full
        flat
        transparent
        disabled={requestAppointmentLoading}
        color="success"
        text="Help me schedule"
        onClick={requestAvailability}
      />
    </div>
  );
};

RequestAvailabilityButton.propTypes = {
  addNotification: PropTypes.func,
  isMinor: PropTypes.any,
  kind: PropTypes.any,
  consent: PropTypes.any,
  modalData: PropTypes.shape({
    id: PropTypes.any,
    supported_appointment_mediums: PropTypes.any,
  }),
  openModal: PropTypes.func,
};

export { RequestAvailabilityButton };

export default connect(null, { openModal, addNotification })(
  RequestAvailabilityButton,
);
