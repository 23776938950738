import { gql } from "@apollo/client";

const resendSignupInvitation = gql`
  mutation ResendSignupInvitation(
    $corporate_email: String
    $personal_phone_number: String
  ) {
    resendSignupInvitation(
      corporate_email: $corporate_email
      personal_phone_number: $personal_phone_number
    ) {
      success
    }
  }
`;

export default resendSignupInvitation;
