export const downloadAndOpenBase64File = (contentBase64: string): void => {
  const blob = base64toBlob(contentBase64);
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
};

function base64toBlob(base64Data: string): Blob {
  // 1024 bytes = 1 kilobyte
  const sliceSize = 1024;
  // NOTE: we specifically call window.atob because the Node version of atob is deprecated/ left in as legacy code
  // see: https://nodejs.org/api/buffer.html#bufferatobdata
  // window.atob returns an ASCII string
  const byteCharacters = window.atob(base64Data);
  const bytesLength = byteCharacters.length;
  // ceiling ensures we don't lose any data
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array<Uint8Array>(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes: Array<number> = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      // slicing chunks of byteCharacters and pushing the Unicode char codes of the chunk
      // for conversion to Uint8
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: "application/pdf" });
}
