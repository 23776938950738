import { createIcon } from "@chakra-ui/react";

export const SMS = createIcon({
  displayName: "SMS",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 17.5646V13C4 9.13405 7.13401 6.00005 11 6.00005H18C19.1046 6.00005 20 6.89548 20 8.00005V10.5295C20 14.3955 16.866 17.5295 13 17.5295H7.55556L6 18.6103L4 20V17.5646ZM8.1822 19.5295H13C17.9706 19.5295 22 15.5 22 10.5295V8.00005C22 5.79091 20.2091 4.00005 18 4.00005H11C6.02944 4.00005 2 8.02948 2 13V20C2 20.7446 2.41354 21.4274 3.07331 21.7724C3.73307 22.1174 4.52985 22.0673 5.14124 21.6425L8.1822 19.5295ZM8 10C8 9.44776 8.44772 9.00005 9 9.00005H16C16.5523 9.00005 17 9.44776 17 10C17 10.5523 16.5523 11 16 11H9C8.44772 11 8 10.5523 8 10ZM9 12C8.44772 12 8 12.4478 8 13C8 13.5523 8.44772 14 9 14H13C13.5523 14 14 13.5523 14 13C14 12.4478 13.5523 12 13 12H9Z"
    />
  ),
  defaultProps: {
    "aria-label": "Sms icon",
    role: "img",
  },
});
