export const RelationshipSpring = ({ color, height = "24", width = "24" }) => {
  const fill = color || "#045642";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {color ?? (
        <rect
          width="24"
          height="24"
          rx="12"
          fill="#058261"
          fill-opacity="0.12"
        />
      )}
      <g clip-path="url(#clip0_654_12624)">
        <path
          d="M11.7938 3.02881C12.1921 2.91501 12.6072 3.14563 12.721 3.54391L13.1718 5.12164C13.1759 5.13586 13.1798 5.1501 13.1837 5.16435C13.9425 4.8191 15.1389 4.73606 16.6172 5.40799C16.8564 5.51674 17.0213 5.74277 17.0518 6.00379C17.0823 6.26481 16.9739 6.52275 16.7661 6.68368C15.463 7.69313 14.0685 7.41958 13.2159 7.01276C13.1627 7.23858 13.0882 7.46028 12.9929 7.67486L12.4767 8.83614C12.2182 9.41791 12.2182 10.082 12.4767 10.6638L13.0596 11.9752C13.3655 12.6635 13.4571 13.428 13.3223 14.169L12.7862 17.118C12.762 17.2508 12.7499 17.3855 12.7499 17.5205V20.2499C12.7499 20.6642 12.4141 20.9999 11.9999 20.9999C11.5857 20.9999 11.2499 20.6642 11.2499 20.2499V17.5205C11.2499 17.2955 11.2701 17.071 11.3104 16.8496L11.8465 13.9007C11.9274 13.4561 11.8724 12.9974 11.6889 12.5844L11.106 11.273C10.873 10.7486 10.766 10.1841 10.785 9.62293C8.93025 8.85864 8.24987 7.58415 8.24987 6.74995C8.24987 6.53282 8.34397 6.32634 8.50785 6.1839C8.67172 6.04145 8.88929 5.97702 9.10431 6.00726C9.69435 6.09023 10.7822 6.48289 11.451 7.45063L11.6221 7.06565C11.8365 6.5834 11.8745 6.04115 11.7295 5.53372L11.2787 3.95599C11.1649 3.55771 11.3955 3.1426 11.7938 3.02881Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.74987 12.7499C9.74987 13.6218 9.37797 14.4068 8.78416 14.9549C9.41525 15.4521 9.86297 16.1783 9.99692 17.0223L10.4906 20.1324C10.5555 20.5415 10.2765 20.9257 9.86744 20.9907C9.45835 21.0556 9.07408 20.7766 9.00914 20.3675L8.51547 17.2574C8.37765 16.3891 7.629 15.7499 6.74987 15.7499C5.87073 15.7499 5.12208 16.3891 4.98426 17.2574L4.49059 20.3675C4.42566 20.7766 4.04138 21.0556 3.63229 20.9907C3.2232 20.9257 2.9442 20.5415 3.00914 20.1324L3.50281 17.0223C3.63676 16.1783 4.08448 15.4521 4.71557 14.9549C4.12176 14.4068 3.74987 13.6218 3.74987 12.7499C3.74987 11.0931 5.09301 9.74995 6.74987 9.74995C8.40672 9.74995 9.74987 11.0931 9.74987 12.7499ZM6.74987 11.2499C5.92144 11.2499 5.24987 11.9215 5.24987 12.7499C5.24987 13.5784 5.92144 14.2499 6.74987 14.2499C7.57829 14.2499 8.24987 13.5784 8.24987 12.7499C8.24987 11.9215 7.57829 11.2499 6.74987 11.2499Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.2842 14.9549C19.878 14.4068 20.2499 13.6218 20.2499 12.7499C20.2499 11.0931 18.9067 9.74995 17.2499 9.74995C15.593 9.74995 14.2499 11.0931 14.2499 12.7499C14.2499 13.6218 14.6218 14.4068 15.2156 14.9549C14.5845 15.4521 14.1368 16.1783 14.0028 17.0223L13.5091 20.1324C13.4442 20.5415 13.7232 20.9257 14.1323 20.9907C14.5414 21.0556 14.9257 20.7766 14.9906 20.3675L15.4843 17.2574C15.6221 16.3891 16.3707 15.7499 17.2499 15.7499C18.129 15.7499 18.8777 16.3891 19.0155 17.2574L19.5091 20.3675C19.5741 20.7766 19.9584 21.0556 20.3674 20.9907C20.7765 20.9257 21.0555 20.5415 20.9906 20.1324L20.4969 17.0223C20.363 16.1783 19.9152 15.4521 19.2842 14.9549ZM15.7499 12.7499C15.7499 11.9215 16.4214 11.2499 17.2499 11.2499C18.0783 11.2499 18.7499 11.9215 18.7499 12.7499C18.7499 13.5784 18.0783 14.2499 17.2499 14.2499C16.4214 14.2499 15.7499 13.5784 15.7499 12.7499Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_654_12624">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
