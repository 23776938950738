// TODO: Add props
import { useEffect, useState, useRef } from "react";
import { Text, Box, Button, useDisclosure } from "design-system/components";
import { useTranslation } from "react-i18next";

import { RequestSupportFlyout } from "components/templates/Browse/ProviderBrowsePage/components/Modals/RequestSupportFlyout";
import {
  trackRequestSupportedClicked,
  trackRequestSupportComponentType,
} from "components/templates/Browse/ProviderBrowsePage/analytics";
import { useInViewportOnce } from "hooks";

type Props = {
  provider: any;
  providerIndex: number;
  pageNumber: number;
  medium: string;
  kind: string;
  queryRequestId: string;
};

export const RequestSupport = ({
  provider,
  providerIndex,
  pageNumber,
  medium,
  kind,
  queryRequestId,
}: Props) => {
  const { t } = useTranslation(["careProvider"]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const appointmentMedium = medium;
  const appointmentKind = kind;

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible
  const [trackedOnce, setTrackedOnce] = useState(false);

  useEffect(() => {
    if (!trackedOnce && inViewport) {
      setTrackedOnce(true);
      trackRequestSupportComponentType({
        appointmentMedium,
        appointmentKind,
        provider,
        providerIndex,
        pageNumber,
        queryRequestId,
        category: provider?.isBrecsRecommended ? "recommended" : "browse",
      });
    }
  }, [inViewport]);

  return (
    <Box
      pt={0}
      pb={10}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        trackRequestSupportedClicked({
          appointmentMedium,
          appointmentKind,
          provider,
          queryRequestId,
        });
      }}
    >
      <Text variant="bodyBold_v1">
        {t("scheduleModal.appointmentSlots.contactToBook")} {provider?.name}.
      </Text>
      <Text color="platform.base" mb={24}>
        {t("scheduleModal.appointmentSlots.scheduleHelp")}
      </Text>
      <Box>
        <Button
          ref={ref}
          p={25}
          py={16}
          fontSize={15}
          h={50}
          borderColor="platform.400"
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          variant="outline"
          size="md"
          onClick={() => onOpen()}
        >
          {t("scheduleModal.appointmentSlots.requestSupportButtonText")}
        </Button>
      </Box>

      <RequestSupportFlyout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Box>
  );
};

export default RequestSupport;
