import {
  useEffect,
  useState,
  useContext,
  createContext,
  RefObject,
  FC,
  ReactNode,
} from "react";
import Player from "video.js/dist/types/player";

const noop = () => {
  // No-op
};
const defaultVolume = 0.5;

type VideoPlayerContextType = {
  player: Player | null;
  setPlayer: (player: Player) => void;
  showControls: boolean;
  setShowControls: (show: boolean) => void;
  isPlaying: boolean;
  setIsPlaying: (playing: boolean) => void;
  isCaptionOn: boolean;
  duration: number;
  currentTime: number;
  volume: number;
  setVolume: (volume: number) => void;
  isMuted: boolean;
  setIsMuted: (muted: boolean) => void;
  captionButtonTapped: () => void;
  fullScreenButtonTapped: () => void;
  forward10: () => void;
  backward10: () => void;
  updateTime: (newTime: number) => void;
  isMobile: boolean;
  isFullScreen: boolean;
  containerRef: RefObject<HTMLDivElement> | null;
  setContainerRef: (ref: RefObject<HTMLDivElement>) => void;
};

const videoPlayerContext: VideoPlayerContextType = {
  player: null,
  setPlayer: noop,
  showControls: false,
  setShowControls: noop,
  isPlaying: false,
  setIsPlaying: noop,
  isCaptionOn: false,
  duration: 0,
  currentTime: 0,
  volume: defaultVolume,
  setVolume: noop,
  isMuted: false,
  setIsMuted: noop,
  captionButtonTapped: noop,
  fullScreenButtonTapped: noop,
  forward10: noop,
  backward10: noop,
  updateTime: noop,
  isMobile: false,
  isFullScreen: false,
  containerRef: null,
  setContainerRef: noop,
};

const VideoPlayerContext =
  createContext<VideoPlayerContextType>(videoPlayerContext);

type VideoPlayerProviderProps = {
  children: ReactNode;
  mobile: boolean;
  listenerCallbacks: {
    onPlaying: () => void;
    onPlay: () => void;
    onPause: () => void;
    onEnd: () => void;
    onTimeUpdate: (currentTime: number) => void;
    onForward10: () => void;
    onBackward10: () => void;
    onSeeking: (updateTime: number) => void;
    onCaptionChange: (caption: boolean) => void;
    onFullScreenChange: (fullScreen: boolean) => void;
  };
};

const VideoPlayerProvider: FC<
  React.PropsWithChildren<VideoPlayerProviderProps>
> = ({ children, mobile, listenerCallbacks }) => {
  const [player, setPlayer] = useState<Player | null>(null);
  const [showControls, setShowControls] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isCaptionOn, setIsCaptionOn] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(defaultVolume);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [containerRef, setContainerRef] =
    useState<RefObject<HTMLDivElement> | null>(null);

  const [isMobile] = useState(mobile);

  useEffect(() => {
    if (player) {
      if (isPlaying) {
        player?.play();
      } else {
        player?.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (player) {
      player.muted(isMuted);
      if (!isMuted) {
        player.volume(volume);
      }
    }
  }, [volume, isMuted]);

  useEffect(() => {
    const element = containerRef?.current;
    if (!element) return;

    if (isFullScreen) {
      if (document.fullscreenElement !== element) {
        element.requestFullscreen();
      }
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [isFullScreen, containerRef]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullscreenNow = document.fullscreenElement !== null;
      setIsFullScreen(isFullscreenNow);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const captionButtonTapped = () => {
    setIsCaptionOn(!isCaptionOn);
    listenerCallbacks.onCaptionChange(isCaptionOn);
  };

  const fullScreenButtonTapped = () => {
    setIsFullScreen(!isFullScreen);
    listenerCallbacks.onFullScreenChange(isFullScreen);
  };

  const forward10 = () => {
    if (player) {
      player?.currentTime((player?.currentTime() ?? 0) + 10);
      listenerCallbacks.onForward10();
    }
  };

  const backward10 = () => {
    if (player) {
      player?.currentTime((player?.currentTime() ?? 0) - 10);
      listenerCallbacks.onBackward10();
    }
  };

  const updateTime = (newTime: number) => {
    if (player) {
      player?.currentTime(newTime);
      listenerCallbacks.onSeeking(newTime);
    }
  };

  useEffect(() => {
    if (!player) return;

    const handlePlaying = () => {
      setDuration(player.duration() ?? 0);
      player.volume(defaultVolume);
      player.muted(isMuted);
      listenerCallbacks.onPlaying();
    };
    const handlePlay = () => {
      setIsPlaying(true);
      listenerCallbacks.onPlay();
    };
    const handlePause = () => {
      setIsPlaying(false);
      listenerCallbacks.onPause();
    };
    const handleEnd = () => {
      setIsPlaying(false);
      listenerCallbacks.onEnd();
    };
    const handleTimeUpdate = () => {
      setCurrentTime(player.currentTime() ?? 0);
      listenerCallbacks.onTimeUpdate(player.currentTime() ?? 0);
    };

    const handleVolumeChange = () =>
      setVolume(player.volume() ?? defaultVolume);

    player?.on("playing", handlePlaying);
    player?.on("play", handlePlay);
    player?.on("pause", handlePause);
    player?.on("ended", handleEnd);
    player?.on("timeupdate", handleTimeUpdate);
    player?.on("volumechange", handleVolumeChange);

    return () => {
      player?.off("playing", handlePlaying);
      player?.off("play", handlePlay);
      player?.off("pause", handlePause);
      player?.off("ended", handleEnd);
      player?.off("timeupdate", handleTimeUpdate);
      player?.off("volumechange", handleVolumeChange);
    };
  }, [player]);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timeout | undefined;
    if (showControls && (isMobile || isFullScreen)) {
      timerId = setTimeout(() => {
        setShowControls(false);
      }, 3000);
    }
    return () => clearTimeout(timerId);
  }, [showControls]);

  const value: VideoPlayerContextType = {
    player,
    setPlayer,
    showControls,
    setShowControls,
    isPlaying,
    setIsPlaying,
    isCaptionOn,
    duration,
    currentTime,
    volume,
    setVolume,
    isMuted,
    setIsMuted,
    captionButtonTapped,
    fullScreenButtonTapped,
    forward10,
    backward10,
    updateTime,
    isMobile,
    isFullScreen,
    containerRef,
    setContainerRef,
  };

  return (
    <VideoPlayerContext.Provider value={value}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

const useVideoPlayer = () => {
  const context = useContext(VideoPlayerContext);
  if (context === undefined) {
    throw new Error("useVideoPlayer must be used within a VideoPlayerProvider");
  }
  return context;
};

export { VideoPlayerProvider, useVideoPlayer, VideoPlayerContext };
