import {
  Box,
  Button,
  IconButton,
  HStack,
  Text,
} from "design-system/components/index";
import { TextButton } from "@springcare/sh-component-library";
import { HelpingHand, LeftArrow } from "design-system/index";
import { useRouter } from "next/router";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { titleCase } from "components/templates/RegisterPage/analytics";
import { useTranslation } from "hooks/react-i18next";
import { Close } from "design-system/assets/icons";
import { useCallback } from "react";
// general icon btn to reuse in this flow, pass the icon you want and arialabel
// should probably be lifted up to design systems at some point
const HeaderIconButton = ({
  onClick,
  icon,
  ariaLabel,
  hideBackground = false,
  width = "48px",
  height = "48px",
}) => (
  <IconButton
    onClick={onClick}
    h={height}
    w={width}
    bg={hideBackground ? "transparent" : "tertiary.50"}
    border={0}
    borderRadius="50%"
    variant="outline"
    colorScheme="platform.900"
    _focus={{
      border: "2px solid",
      borderColor: "primary.base",
    }}
    _focusVisible={{ outline: "2px solid black", outlineOffset: "4px" }}
    _active={{
      bg: "tertiary.100",
      border: "2px solid",
      borderColor: "primary.base",
    }}
    aria-label={ariaLabel}
    icon={icon}
  />
);

// help btn (desktop & mobile)
export const NavHelpBtn = ({ onClick }) => {
  const { t } = useTranslation("common");

  return (
    <Box
      data-cy="help-button"
      data-testid="help-button"
      as="button"
      onClick={onClick}
      w="fit-content"
      h="40px"
      px={16}
      bg={"tertiary.50"}
      borderRadius={24}
      _focus={{
        border: "2px solid",
        borderColor: "primary.base",
      }}
      _focusVisible={{ outline: "2px solid black", outlineOffset: "4px" }}
      _active={{
        bg: "tertiary.100",
        border: "2px solid",
        borderColor: "primary.base",
      }}
      _hover={{ bg: "tertiary.100" }}
    >
      <HStack spacing={2}>
        <HelpingHand boxSize={20} aria-hidden={true} />
        <Text fontWeight="bold">{t("navigation.helpButton")}</Text>
      </HStack>
    </Box>
  );
};

// back btn & behavior logic
export const BackBtn = ({
  displayBackBtn,
  doGoBack,
  currentView,
  setCurrentView,
  isDependentObj,
  hideBackground = true,
}) => {
  return (
    <>
      {displayBackBtn(currentView) ? (
        <HeaderIconButton
          onClick={() => doGoBack(currentView, setCurrentView, isDependentObj)}
          icon={<LeftArrow boxSize={20} />}
          ariaLabel="Back"
          hideBackground={hideBackground}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
        />
      ) : null}
    </>
  );
};

// close button
export const CloseBtn = ({ closeTo, hideBackground = true }) => {
  const router = useRouter();
  function doCloseBtnClick() {
    if (routes[closeTo]) {
      TRACK_EVENT.LINK_CLICKED(
        window.location.pathname,
        routes[closeTo].as,
        "Close",
        {
          location: "Header",
        },
      );
      router.push(routes[closeTo].to, routes[closeTo].as);
    }
  }

  return (
    <HeaderIconButton
      onClick={() => doCloseBtnClick()}
      icon={<Close />}
      ariaLabel="Close"
      hideBackground={hideBackground}
      _focusVisible={{ boxShadow: "0 0 0 3px black" }}
      width={"40px"}
      height={"40px"}
    />
  );
};

// login btn ui + logic - logic local to /register for the moment
export const LoginBtn = ({ currentView, setCurrentView }) => {
  const router = useRouter();
  const { t } = useTranslation("common");

  const trackButtonClick = (button, docId) => {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, button, {
      location: "Header",
      page_version:
        window.location.pathname === routes.Register.as
          ? titleCase(currentView)
          : undefined,
      spring_doc_id: docId,
    });
  };

  function doLoginBtnClick() {
    //Login page and not Register Pages (this will need to be updated if we add more non logged in pages)
    //Always shows create account button and directs to register
    switch (window.location.pathname) {
      case routes.SignIn.as:
        trackButtonClick("Create Account", "register004a");
        setCurrentView("EMAIL_VERIFICATION");
        router.push(routes.Register.to, routes.Register.as);
        // The SelectSSOProvider component is used on both the register and sign_in routes. If a
        // user is on the /register route, we want to make sure they are redirected to the root
        // login view, rather than SelectSSOProvider on /sign_in.
        if (
          window.location.pathname === routes.Register.children.RegisterSSO.as
        ) {
          setCurrentView(null);
        }
        break;
      case routes.Register.as:
        trackButtonClick("Login", "register004b");
        setCurrentView("LOGIN");
        router.push(routes.SignIn.to, routes.SignIn.as);
        break;
      case routes.Register.children.RegisterDependent.as:
        trackButtonClick("Login", "register004b");
        setCurrentView("LOGIN");
        router.push(routes.SignIn.to, routes.SignIn.as);
        break;
      case routes.Register.children.RegisterNoEmail.as:
        trackButtonClick("Login", "register004b");
        setCurrentView("LOGIN");
        router.push(routes.SignIn.to, routes.SignIn.as);
        break;
      case routes.Register.children.RegisterSSO.as:
        trackButtonClick("Login", "register004b");
        setCurrentView("LOGIN");
        router.push(routes.SignIn.to, routes.SignIn.as);
        break;
      case routes.Register.children.RegisterReferral.as:
        trackButtonClick("Login", "register004b");
        setCurrentView("LOGIN");
        router.push(routes.SignIn.to, routes.SignIn.as);
        break;
      case routes.Referral.as:
        trackButtonClick("Login", "register004b");
        setCurrentView("LOGIN");
        router.push(routes.SignIn.to, routes.SignIn.as);
        break;
      default:
        trackButtonClick("Create Account", "register004c");
        setCurrentView("EMAIL_VERIFICATION");
        break;
    }
  }

  return (
    <Button
      data-cy="login-button"
      as="button"
      onClick={() => doLoginBtnClick()}
      variant="outline"
      w="fit-content"
      h="40px"
      px={16}
      py={[0, 0, 0]}
      color="platform.900"
      borderRadius={24}
      _hover={{ bg: "platform.100" }}
      _focus={{
        border: "2px solid",
        borderColor: "primary.base",
      }}
      _focusVisible={{ outline: "2px solid black", outlineOffset: "4px" }}
      _active={{ bg: "platform.100", color: "platform.900" }}
    >
      {router.pathname !== routes.SignIn.to ? (
        <>{t("navigation.loginButton")}</>
      ) : (
        <>{t("navigation.createAccountButton")}</>
      )}
    </Button>
  );
};

// login btn ui + logic - logic local to /register for the moment
export const LogoutBtn = ({ currentView }) => {
  const { t } = useTranslation(["common"]);
  const router = useRouter();
  function doLogoutBtnClick() {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Logout", {
      location: "Header",
      page_version:
        window.location.pathname === routes.Register.as
          ? titleCase(currentView)
          : undefined,
    });
    router.push(routes.Logout.to, routes.Logout.as);
  }

  return (
    <Button
      as="button"
      onClick={() => doLogoutBtnClick()}
      variant="outline"
      w="fit-content"
      h="40px"
      px={16}
      py={[0, 0, 0]}
      color="platform.900"
      borderRadius={24}
      _hover={{ bg: "platform.100" }}
      _focus={{
        border: "2px solid",
        borderColor: "primary.base",
      }}
      _focusVisible={{ outline: "2px solid black", outlineOffset: "4px" }}
      _active={{ bg: "platform.100", color: "platform.900" }}
    >
      {t("navigation.links.logout")}
    </Button>
  );
};

export const LogoutBtnNew = () => {
  const { t } = useTranslation(["common"]);
  const router = useRouter();

  const handleLogoutClick = useCallback(() => {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Logout", {
      location: "Header",
    });
    router.push(routes.Logout.to, routes.Logout.as);
  }, []);

  return (
    <TextButton onClick={handleLogoutClick}>
      {t("navigation.links.logout")}
    </TextButton>
  );
};
