import { useTranslation } from "react-i18next";
import { Heading } from "design-system/components/base/Typography";

const Passed = ({ appointment }) => {
  const { t } = useTranslation("loginless", { keyPrefix: "confirmation" });
  const providerName = appointment?.provider.first_name || "your provider";

  return (
    <Heading as="h1" variant="md_v1" data-testid="passed">
      {t("passed", { providerName })}
    </Heading>
  );
};

export default Passed;
