import { gql } from "@apollo/client";

import { postalAddress } from "operations/fragments/member";

const getUserIdentifiers = gql`
  query getUserIdentifiers($id: ID!) {
    user(id: $id) {
      id
      created_at
      confirmed
      first_name
      last_name
      email
      phone
      preferred_language
      zendesk_jwt
      member {
        id
        date_of_birth
        verified
        coaching_tier
        risk
        initial_sud_risk
        latest_sud_risk
        is_private_practice
        assessments(status: "Finished") {
          status
          kind
          risk
          ended_at
        }
        postal_address {
          ...postalAddress
        }
        cohort {
          id
          name
          in_network
          international
          contract_term {
            id
            sud_supported
          }
          customer {
            id
            name
            channel_partner {
              id
            }
            is_health_plan
            dependent_eligibility_tier
          }
        }
        tracked_goals {
          id
          tracked
          goal {
            id
            description
          }
        }
        managed_dependents {
          id
          first_name
          last_name
          email
        }
        covered_life {
          id
        }
      }
    }
  }
  ${postalAddress}
`;

export default getUserIdentifiers;
