import { gql } from "@apollo/client";

const getTherapeuticAllianceSurvey = gql`
  query getTherapeuticAllianceSurvey($appointment_id: ID!) {
    get_survey_status(appointment_id: $appointment_id) {
      msg
      success
    }
  }
`;

export default getTherapeuticAllianceSurvey;
