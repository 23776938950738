// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const FlexRow = (props) => (
	<span
		className={classnames(styles.flexRow, {
			[styles.center]  : props.center,
			[styles.reverse] : props.reverse,
		})}
		style={{
			alignItems     : props.alignment,
			justifyContent : props.justification,
			flexWrap       : props.wrap,
			flexShrink     : props.shrink,
		}}
	>
		{props.children}
	</span>
);

export default FlexRow;
