//
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  Avatar,
  FlexCol,
  Grid,
  Col,
  Section,
  Modal,
  Content,
  TagsByKind,
} from "@spring/smeargle";
import { shouldShowTags } from "@spring/smeargle/src/utils";
import { modalIds, CareProviderTagKind } from "@spring/constants";
import { useTranslation } from "react-i18next";
import { isDemoEnvironment } from "utils/memberHelpers";
import { getOr } from "lodash/fp";

import { CareProviderSubhead } from "components/molecules";

import styles from "./styles.module.scss";
import { Heading } from "design-system/components";

const CareProviderDetailModal = (props) => {
  const { t } = useTranslation("careProvider");
  if (!props.modalData) {
    return null;
  }

  const hasTag = (kind) =>
    props.modalData.care_provider_tags?.some((tag) => tag.kind === kind);

  const memberAddressState = getOr(
    null,
    "memberAddress.user.member.postal_address.state",
    props,
  );
  const licensedStatesToShow =
    isDemoEnvironment() && memberAddressState
      ? [memberAddressState]
      : props.modalData.states;

  return (
    <Modal size="md" id={modalIds.careProviderDetailModal}>
      <div className={styles.body}>
        <FlexCol justification="space-between">
          <Grid>
            <Col md={4}>
              <Avatar
                rounded
                src={props.modalData.avatar}
                imgProps={{ alt: props.modalData.name }}
                height="160px"
                width="160px"
              />
            </Col>

            <Col md={8}>
              <FlexCol>
                <Heading as="h1" fontSize={48} fontWeight={300} color="#182023">
                  {props.modalData.name}
                </Heading>
                <Heading
                  as="h2"
                  fontSize={20}
                  fontWeight={"normal"}
                  color="#4b4b4b"
                >
                  <CareProviderSubhead
                    role={props.modalData.careTeamRole}
                    roles={props.modalData.roles}
                    licenses={props.modalData.licenses}
                    useAbsolutePosition
                  />
                </Heading>
              </FlexCol>
            </Col>
          </Grid>

          <div className={styles.content}>
            {props.modalData.bio && (
              <Section>
                <Heading
                  as={"h3"}
                  fontSize={16}
                  color="#6A6A6A"
                  fontWeight={700}
                >
                  {t("detailModal.introduction")}
                </Heading>
                <Content>{props.modalData.bio}</Content>
              </Section>
            )}

            {licensedStatesToShow?.length > 0 && (
              <Section>
                <Heading
                  as={"h3"}
                  fontSize={16}
                  color="#6A6A6A"
                  fontWeight={700}
                >
                  {t("detailModal.licensedIn")}
                </Heading>
                <Content>{licensedStatesToShow.join(", ")}</Content>
              </Section>
            )}

            {hasTag(CareProviderTagKind.Condition) &&
              shouldShowTags(props.modalData) && (
                <Section>
                  <Heading
                    as={"h3"}
                    fontSize={16}
                    color="#6A6A6A"
                    fontWeight={700}
                  >
                    {t("detailModal.conditions")}
                  </Heading>
                  <TagsByKind
                    careProviderTags={props.modalData.care_provider_tags}
                    kind={CareProviderTagKind.Condition}
                  />
                </Section>
              )}

            {hasTag(CareProviderTagKind.Specialty) &&
              shouldShowTags(props.modalData) && (
                <Section>
                  <Heading
                    as={"h3"}
                    fontSize={16}
                    color="#6A6A6A"
                    fontWeight={700}
                  >
                    {t("detailModal.specialites")}
                  </Heading>
                  <TagsByKind
                    careProviderTags={props.modalData.care_provider_tags}
                    kind={CareProviderTagKind.Specialty}
                    color="pear"
                  />
                </Section>
              )}
          </div>
        </FlexCol>
      </div>
    </Modal>
  );
};

CareProviderDetailModal.propTypes = {
  modalData: PropTypes.shape({
    avatar: PropTypes.any,
    bio: PropTypes.any,
    careTeamRole: PropTypes.any,
    care_provider_tags: PropTypes.shape({
      some: PropTypes.func,
    }),
    licenses: PropTypes.any,
    name: PropTypes.any,
    roles: PropTypes.any,
    states: PropTypes.any,
  }),
};

export { CareProviderDetailModal };
export default connect((state) => ({
  modalData:
    state.modal[modalIds.careProviderDetailModal] &&
    state.modal[modalIds.careProviderDetailModal].data,
}))(CareProviderDetailModal);
