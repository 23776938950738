// 

import React from 'react';

import styles from './styles.module.scss';


const Reader = (props) => (
	<div className={styles.reader}>
		{props.children}
	</div>
);

export default Reader;
