import React, { FC } from "react";
import { Box, Heading, VStack } from "@chakra-ui/react";

import { useTranslation } from "hooks/react-i18next";
import { useTeenUpNextSection } from "modules/MemberDashboard/Teen/Home/hooks";
import { UpNextCard } from "shared/components";

import type { TeenUpNextSectionProps } from "./types";

/**
 * This component is shown on the Teen Homepage (/members/teen/home)
 * It shows the member's current therapist, along with a button to schedule,
 * and a link to browse other therapists
 *
 * @param props
 */

export const TeenUpNextSection: FC<
  React.PropsWithChildren<TeenUpNextSectionProps>
> = ({ onScheduleModalOpen }) => {
  const { t } = useTranslation(["teenHomepage", "homepage", "carePlan"]);
  const { openTherapistModal, openCoachingModal, therapist, coach } =
    useTeenUpNextSection(onScheduleModalOpen);

  const cardConfigs = [];

  if (therapist) {
    cardConfigs.push({
      overline: t("homepage:upNextSection.therapistFollowUp.overline"),
      cardTitle: `${t("homepage:upNextSection.therapistFollowUp.cardTitle")} ${
        therapist?.first_name
      }`,
      imageUrl: therapist?.avatar,
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.primaryCTAText"),
      primaryCTACallback: openTherapistModal,
      secondaryCTAType: "link",
      secondaryCTAText: t(
        "homepage:upNextSection.therapistFollowUp.secondaryCTAText",
      ),
      secondaryCTAURL: "/browse/therapists",
      cardTrackingName: "Schedule Follow-Up Therapy",
    });
  }

  if (coach) {
    cardConfigs.push({
      overline: t("homepage:upNextSection.coachFollowUp.overline"),
      cardTitle: `${t("homepage:upNextSection.coachFollowUp.cardTitle")} ${
        coach?.first_name
      }`,
      imageUrl: coach?.avatar,
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.primaryCTAText"),
      primaryCTACallback: openCoachingModal,
      secondaryCTAType: "link",
      secondaryCTAText: t(
        "homepage:upNextSection.coachFollowUp.secondaryCTAText",
      ),
      secondaryCTAURL: "/browse/coaches",
      cardTrackingName: "Schedule Follow-Up Coaching",
    });
  }

  return (
    <>
      <Heading as="h2" size="heading-medium" pb={24}>
        {t("upNext.heading")}
      </Heading>
      <VStack gap={24}>
        {cardConfigs.map((config, index) => (
          <Box
            key={`teen-up-next-card-${index}`}
            data-testid="teen-up-next-card"
            w="100%"
          >
            <UpNextCard
              {...config}
              buttonVariant="solid"
              cardOrder={index}
              headerText={t("upNext.heading")}
            />
          </Box>
        ))}
      </VStack>
    </>
  );
};
