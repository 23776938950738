//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getOr, get } from "lodash/fp";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import {
  openModal,
  closeModal,
  addNotification,
} from "@spring/smeargle/actions";
import {
  Form,
  CheckboxGroup,
  Textarea,
  Button,
  Section,
  Content,
  Bolded,
} from "@spring/smeargle";
import { days, inPersonTimes, modalIds } from "@spring/constants";
import Meowth from "@spring/meowth";

import { MemberConsent } from "components/molecules";
import { withMemberInfo } from "components/hocs";

import { updateMember } from "operations/mutations/member";
import schema from "schemas/inPersonAppointmentDetails";
import { memberConsentSelector } from "selectors/form";
import { getMemberConsent } from "operations/queries/member";
import { getFirstError } from "utils/apollo/errorHandler";

const formKey = "inPersonAppointmentDetail";

class InPersonAppointmentDetailsForm extends Component {
  static propTypes = {
    addNotification: PropTypes.func,
    closeModal: PropTypes.func,
    formData: PropTypes.any,
    memberConsentData: PropTypes.any,
    memberConsent: PropTypes.shape({
      data: PropTypes.shape({
        hipaa_privacy_policy: PropTypes.any,
        informed_consent: PropTypes.any,
      }),
    }),
    memberInfo: PropTypes.shape({
      user: PropTypes.shape({
        member: PropTypes.shape({
          id: PropTypes.any,
        }),
      }),
    }),
    openModal: PropTypes.func,
    providerData: PropTypes.shape({
      requestInPerson: PropTypes.any,
    }),
    updateMember: PropTypes.func,
  };

  get isValid() {
    const {
      formData,
      memberConsent,
      memberConsentData: {
        user: {
          member: { hipaa_privacy_policy, informed_consent },
        },
      },
    } = this.props;

    if (hipaa_privacy_policy && informed_consent) {
      return get("validation.isValid", formData);
    }

    return (
      get("validation.isValid", formData) &&
      get("validation.isValid", memberConsent)
    );
  }

  handleClick = async () => {
    const {
      memberInfo,
      memberConsent,
      memberConsentData: {
        user: {
          member: { hipaa_privacy_policy, informed_consent },
        },
      },
      updateMember,
    } = this.props;

    if (!hipaa_privacy_policy || !informed_consent) {
      try {
        const payload = {
          variables: {
            input: {
              id: memberInfo.user.member.id,
              patch: {
                hipaa_privacy_policy: memberConsent.data.hipaa_privacy_policy,
                informed_consent: memberConsent.data.informed_consent,
              },
            },
          },
        };

        await updateMember(payload);
      } catch (error) {
        this.props.addNotification(getFirstError(error), "error");
      }
    }

    this.props.closeModal(modalIds.inPersonAppointmentDetails);

    if (this.props.providerData.requestInPerson) {
      return this.props.openModal(modalIds.requestInPersonModal);
    }

    return this.props.openModal(
      modalIds.confirmInPersonAppointment,
      this.props.providerData,
    );
  };

  render() {
    return (
      <Form formKey={formKey} schema={schema} preserve>
        <Section size="xlg">
          <Bolded>
            <Content>
              When are you available to travel to this provider's office for a
              visit?
            </Content>
          </Bolded>
          <Section size="sm">
            <Content>Select all convenient days</Content>
          </Section>
          <CheckboxGroup
            fieldKey="days"
            options={days}
            border
            inline
            hideCheckbox
            centeredOption
          />
        </Section>

        <Section size="xlg">
          <Bolded>
            <Content>
              What time of day are you available to travel to this provider's
              office for a visit?
            </Content>
          </Bolded>
          <Section size="sm">
            <Content>Select all convenient times</Content>
          </Section>
          <CheckboxGroup
            fieldKey="times"
            options={inPersonTimes}
            border
            inline
            hideCheckbox
          />
        </Section>

        <Section size="xlg">
          <Bolded>
            <Content>
              Is there any other information that you would like us to know?
            </Content>
          </Bolded>
          <Section size="md">
            <Textarea fieldKey="other" />
          </Section>
        </Section>

        <MemberConsent />

        <Section size="xlg">
          <Button
            text="Request Availability"
            disabled={!this.isValid}
            onClick={this.handleClick}
            full
          />
        </Section>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  memberConsent: memberConsentSelector(state),
  formData: getOr({}, `${formKey}`, state.form),
  providerData: getOr({}, modalIds.inPersonAppointmentDetails, state.modal)
    .data,
});

const mapDispatchToProps = { openModal, closeModal, addNotification };

export { InPersonAppointmentDetailsForm };
export default compose(
  withMemberInfo,
  graphql(updateMember, { name: "updateMember" }),
  graphql(getMemberConsent, {
    options: Meowth.apolloOptionsUserId,
    name: "memberConsentData",
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(InPersonAppointmentDetailsForm);
