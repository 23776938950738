import { useEffect, useState, useMemo } from "react";
import { VRecordIcon } from "@springcare/verdant-icons-react";
import { Flex, Icon, Text, Box } from "@chakra-ui/react";
import {
  useParticipantMetadata,
  useSessionRecording,
} from "context/SessionRoomContext";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import {
  useIsRecording,
  useRemoteParticipants,
} from "@livekit/components-react";
import { usePostStopRecording } from "components/templates/SHSessionRoom/hooks/usePostStopRecording";
import { TemporaryStopIcon } from "components/templates/SHSessionRoom/components/temporaryStopIcon";

const RecordingIcon = ({
  isRecording,
  isProvider,
  appointmentId,
}: {
  isRecording: boolean;
  isProvider: boolean;
  appointmentId: string;
}) => {
  const stopRecording = usePostStopRecording();
  const showsStopRecording = isProvider && isRecording;

  return (
    <Flex>
      <Flex
        opacity={isRecording ? "100%" : "50%"}
        bg={"white"}
        h={"48px"}
        w={showsStopRecording ? "88px" : "48px"}
        align="center"
        justify="space-around"
        borderRadius={"lg"}
      >
        <Icon
          as={VRecordIcon}
          color={isRecording ? "red.500" : "black"}
          height={18}
          width={18}
        />
        {showsStopRecording && (
          <Box
            as="button"
            w={18}
            h={18}
            padding={0}
            _focus={{ outline: "none !important" }}
            _active={{ outline: "none !important" }}
            onClick={() => {
              stopRecording({
                appointment_id: appointmentId,
              });
            }}
          >
            <Icon as={TemporaryStopIcon} color="black" height={18} width={18} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export const RecordingBadge = () => {
  const { sessionConsents } = useSessionRecording();
  const { participantType, appointmentId } = useParticipantMetadata();
  const recording = useIsRecording();
  const [recordingCopy, setRecordingCopy] = useState("");

  const remoteParticipants = useRemoteParticipants();

  const hasOver2AdmittedParticipants = useMemo(
    () =>
      remoteParticipants.filter((p) => p.permissions?.canPublish).length > 1,
    [remoteParticipants],
  );

  useEffect(() => {
    // Member view
    if (participantType == ParticipantType.Member) {
      if (recording) {
        setRecordingCopy(
          "Audio is being recorded. You can request to stop the recording at any time.",
        );
      } else {
        setRecordingCopy("Audio is not being recorded.");
      }
    }
    // Provider view
    if (participantType == ParticipantType.Provider) {
      if (recording) {
        setRecordingCopy("Audio is being recorded.");
      } else {
        if (hasOver2AdmittedParticipants) {
          setRecordingCopy(
            "Audio recording has stopped. Recording is disabled with multiple attendees.",
          );
        } else if (sessionConsents && !sessionConsents.member_consent) {
          setRecordingCopy("Client has declined consent.");
        } else {
          setRecordingCopy("Audio is not being recorded.");
        }
      }
    }
  }, [
    recording,
    sessionConsents,
    participantType,
    hasOver2AdmittedParticipants,
  ]);

  return (
    <Flex alignContent="center" alignItems="center">
      <RecordingIcon
        isRecording={recording}
        isProvider={participantType === ParticipantType.Provider}
        appointmentId={appointmentId}
      />
      <Text pl={8} color={"white"}>
        {recordingCopy}
      </Text>
    </Flex>
  );
};
