import { useChooseUserMemberInfo } from "./hooks";

import { ChooseUserPageLayout } from "./Layout";
import { ChooseUserDisplay } from "./ChooseUserDisplay";
import { useTranslation } from "react-i18next";
import { isT2Member } from "utils/memberHelpers";
import Router from "next/router";
import routes from "routes/index";
import { useEffect } from "react";

export const ChooseUserPage = () => {
  const { t } = useTranslation("chooseUser");

  const { data, loading } = useChooseUserMemberInfo();

  useEffect(() => {
    if (!loading && data?.user) {
      const isAT2Member = isT2Member(data?.user?.member);
      if (isAT2Member) {
        Router.replace(routes.MemberHome.to, routes.MemberHome.as);
      }
    }
  }, [loading]);

  if (loading || !data?.user) return null;

  return (
    <ChooseUserPageLayout header={t("header")} subtitle={t("subtitle")}>
      <ChooseUserDisplay currentUser={data.user} />
    </ChooseUserPageLayout>
  );
};
