import React, { useRef, useEffect, useState } from "react";
import { Button } from "@springcare/sh-component-library";
import { Text, Flex, Box, Heading } from "@chakra-ui/react";
import { Introduce } from "../../../index";
import PropTypes from "prop-types";
import { useInViewportOnce } from "../../../../hooks/useInViewport";

export const SHSurveyCard = ({
  marginTop,
  marginBottom,
  onClick,
  title,
  subTitle,
  submitBtnText,
  role,
  dataCy,
  trackingText,
  TRACK_EVENT,
}) => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible

  let [trackedOnce, setTrackedOnce] = useState(0);

  useEffect(() => {
    if (inViewport && trackedOnce === 0) {
      TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, trackingText);
      setTrackedOnce(1);
    }
  }, [inViewport]);

  return (
    <Flex
      ref={ref}
      p={[16, 16, 24, 24]}
      mt={marginTop}
      mb={marginBottom}
      border="1px solid"
      borderColor="platform.400"
      borderRadius="8px"
      width="100%"
      flexDirection={["column", "column", "row"]}
      justifyContent={["space-between"]}
      alignItems="center"
    >
      <Box mb={[8, 8, 0, 0]}>
        <Flex w="100%">
          <Introduce boxSize={24} color="platform.900" />
          <Heading pl={[0, 0, 4]} variant="sm_v1" color="platform.900">
            {title}
          </Heading>
        </Flex>

        <Text variant="body_v1" color="platform.500">
          {subTitle}
        </Text>
      </Box>

      <Box width={["100%", "100%", "fit-content", "fit-content"]}>
        <Button
          onClick={onClick}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          role={role}
          data-cy={dataCy}
          color="primary.base"
          colorScheme="white"
          variant="outline"
          width="100%"
          padding={["1rem 6.5rem", "1rem 6.5rem", "1rem 4rem", "1rem 6.5rem"]}
          border="2px solid"
          _hover={{ boxShadow: "0 3px 12px lightgrey" }}
          _active={{ color: "primary.base" }}
          _focus={{ color: "primary.base" }}
        >
          <Text as="span" variant="bodyBold_v1">
            {submitBtnText}
          </Text>
        </Button>
      </Box>
    </Flex>
  );
};

SHSurveyCard.defaultProps = {
  marginTop: 64,
  marginBottom: 64,
};

SHSurveyCard.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  submitBtnText: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  trackingText: PropTypes.string.isRequired,
  TRACK_EVENT: PropTypes.object,
};
