import { Divider, Link } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { BenefitsNumberedSteps } from "modules/MemberDashboard/CareVisits/AppointmentDetails/components";
import {
  benefitsPageAnalyticsMapping,
  tabTypes,
} from "modules/MemberDashboard/Benefits/constants/coveredCareTabTypes";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes/MemberDashboardRoutes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const LeftPanelT2 = ({
  title,
  description,
  tabType,
  memberInfo,
  isMobile,
}) => {
  const { t } = useTranslation("t2Benefits");
  const customerName = memberInfo?.user?.member?.cohort?.customer?.name;
  const visitsCoveredTherapy = memberInfo?.user?.member?.visits_covered_total;
  const visitsCoveredCoaching =
    memberInfo?.user?.member?.visits_covered_coaching;
  const hideCoachingSessionsCount = useFeatureFlag(
    FLAGS.HIDE_COACHING_SESSIONS_COUNT,
  );

  if (tabType === tabTypes.WORK_LIFE_SUPPORT) {
    const browseArticlesCTA = memberInfo?.user?.member?.cohort?.cohort_eap_link;
    description = (
      <Trans
        ns={"t2Benefits"}
        i18nKey={"EAPBenefit.descriptionT2"}
        components={[
          <Link
            key="0"
            target={"_blank"}
            href={browseArticlesCTA}
            isExternal
            onClick={() => {
              TRACK_EVENT.LINK_CLICKED(
                routes.MemberBenefits.as,
                `Benefits Page EAP Benefits Link Clicked - ${benefitsPageAnalyticsMapping[tabType]}`,
              );
            }}
          />,
        ]}
      />
    );
  }

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between">
        <Heading variant="sm_v1">{title}</Heading>
      </Flex>
      <Flex mt="16px">
        <Text>{description}</Text>
      </Flex>
      {tabType === tabTypes.CRISIS && (
        <>
          <Flex
            mt="16px"
            mb="8px"
            flexDirection="column"
            alignItems="start"
            gap="8px"
            alignSelf="stretch"
          >
            <Text fontWeight={600} color="#171E22">
              {t("crisisSupport.whatToExpectTitle")}
            </Text>
            <UnorderedList ms="32px">
              <ListItem>
                <Text>{t("crisisSupport.whatToExpectContentOne")}</Text>
              </ListItem>
              <ListItem>
                <Text>{t("crisisSupport.whatToExpectContentTwo")}</Text>
              </ListItem>
            </UnorderedList>
          </Flex>
          <Flex
            mt="16px"
            mb="8px"
            flexDirection="column"
            alignItems="start"
            gap="8px"
            alignSelf="stretch"
          >
            <Text fontWeight={600} color="#171E22">
              {t("crisisSupport.commonConcernsTitle")}
            </Text>
            <Text>
              <Trans
                ns={"t2Benefits"}
                i18nKey={"crisisSupport.commonConcernsContent"}
                components={[<strong key={0} />]}
              ></Trans>
            </Text>
          </Flex>
        </>
      )}
      {tabType !== tabTypes.CRISIS && (
        <Box mt="32px">
          <BenefitsNumberedSteps
            steps={[t(`${tabType}.t2Stepper`, { returnObjects: true })]}
          />
        </Box>
      )}
      {(tabType === tabTypes.THERAPY ||
        tabType === tabTypes.DEPENDENT_ACCESS) && (
        <>
          <Divider width="100%" my="16px" borderColor="#001317" opacity="0.2" />
          <Text>
            <Trans
              ns={"t2Benefits"}
              i18nKey={"customerTherapyCoveredInfo"}
              values={{
                customerName: customerName,
                sessionCount: visitsCoveredTherapy,
              }}
              components={[<strong key={0} />]}
            />
          </Text>
          {isMobile && (
            <Divider
              width="100%"
              my="16px"
              borderColor="#001317"
              opacity="0.2"
            />
          )}
        </>
      )}
      {tabType === tabTypes.COACH && !hideCoachingSessionsCount && (
        <>
          <Divider width="100%" my="16px" borderColor="#001317" opacity="0.2" />
          <Text>
            <Trans
              ns={"t2Benefits"}
              i18nKey={"customerCoachingCoveredInfo"}
              values={{
                customerName: customerName,
                sessionCount: visitsCoveredCoaching,
              }}
              components={[<strong key={0} />]}
            />
          </Text>
          {isMobile && (
            <Divider
              width="100%"
              my="16px"
              borderColor="#001317"
              opacity="0.2"
            />
          )}
        </>
      )}
    </Flex>
  );
};
