import React, { useState, useEffect } from "react";
import { useOnRampToCareExperiment } from "../../hooks";
import { EfficacySurveyFeeling } from "../MomentsCompletionModal";
import { CoachingPrompt } from "./CoachingPrompt";
import { EfficacySurvey } from "./EfficacySurvey";
import { TherapyPrompt } from "./TherapyPrompt";

export const TreatmentUserCompletionSteps = ({
  exerciseTitle,
  exerciseId,
  exerciseMediaType,
  exerciseCategory,
  feeling,
  setFeeling,
  handleOnClick,
}: {
  feeling?: string;
  exerciseId?: string;
  exerciseTitle?: string;
  exerciseCategory?: unknown;
  exerciseMediaType?: unknown;
  setFeeling: (value: EfficacySurveyFeeling) => void;
  handleOnClick: () => void;
}) => {
  const surveyCompleted = !!feeling;
  const { isTherapyTreatmentUser, isCoachingTreatmentUser } =
    useOnRampToCareExperiment();

  // * if efficacy survey is not completed, render step 1 (efficacy survey), else render step 2
  const initialStepTrackerValue = !surveyCompleted
    ? StepTrackerConfig.EFFICACY_SURVEY
    : StepTrackerConfig.THERAPY_OR_COACHING_STEP;
  const [stepTracker, setStepTracker] = useState<StepTrackerConfig>(
    initialStepTrackerValue,
  );

  useEffect(() => {
    if (surveyCompleted) {
      setStepTracker(StepTrackerConfig.THERAPY_OR_COACHING_STEP);
    }
  }, [surveyCompleted]);

  if (stepTracker === StepTrackerConfig.EFFICACY_SURVEY && !surveyCompleted) {
    return (
      <EfficacySurvey
        exerciseId={exerciseId}
        exerciseTitle={exerciseTitle}
        exerciseCategory={exerciseCategory}
        exerciseMediaType={exerciseMediaType}
        setFeeling={setFeeling}
      />
    );
  }

  if (
    stepTracker === StepTrackerConfig.THERAPY_OR_COACHING_STEP &&
    surveyCompleted
  ) {
    if (isTherapyTreatmentUser) {
      return (
        <TherapyPrompt
          closeModal={handleOnClick}
          exerciseId={exerciseId}
          exerciseTitle={exerciseTitle}
          exerciseCategory={exerciseCategory}
          exerciseMediaType={exerciseMediaType}
          feeling={feeling}
        />
      );
    }
    if (isCoachingTreatmentUser) {
      return (
        <CoachingPrompt
          closeModal={handleOnClick}
          exerciseId={exerciseId}
          exerciseTitle={exerciseTitle}
          exerciseCategory={exerciseCategory}
          exerciseMediaType={exerciseMediaType}
          feeling={feeling}
        />
      );
    }
  }

  return null;
};

enum StepTrackerConfig {
  EFFICACY_SURVEY = 1,
  THERAPY_OR_COACHING_STEP = 2,
  THANK_YOU_STEP = 3,
}
