"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
var useOutsideClick = function useOutsideClick(ref, callback, modalOpen) {
  (0, _react.useEffect)(function () {
    function handleOutsideClick(event) {
      if (ref !== null && ref !== void 0 && ref.current && !ref.current.contains(event.target) && modalOpen) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return function () {
      return document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref, modalOpen]);
};
var _default = exports["default"] = useOutsideClick;