//

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Anchor from "next/link";
import classnames from "classnames";
import routes from "routes";
import {
  track,
  TRACK_EVENT,
  makeEventString,
  EVENT_TYPE,
} from "utils/mixpanel";
import _ from "lodash";

import styles from "./styles.module.scss";

function constructPath(as = "", queries) {
  let path = as;
  //
  // Find all ':' in the path and replace with a query value.
  for (let query in queries) {
    path = path.replace(`:${query}`, `${queries[query]}`);
  }

  if (path.includes(":")) {
    // eslint-disable-next-line no-console
    console.error(
      "Link path still includes queries.  Please ensure you provide a replacement for all parameters.",
      path,
      queries,
    );
  }

  return path;
}

function getAriaLabel(props) {
  let mobileArrayResult;

  if (React.Children.toArray(props.children).length) {
    mobileArrayResult = React.Children.toArray(props.children).find(
      (value) => value !== " ",
    );
  }

  return (
    props.ariaLabel ||
    props["aria-label"] ||
    (props.alias && _.startCase(props.alias)) ||
    (React.Children.toArray(props.children).length && mobileArrayResult) ||
    (typeof props.children === "string" && props.children)
  );
}

const Link = (props) => {
  const classes = props.className || styles.default;
  const to = props.alias ? routes[props.alias].to : props.to;

  if (props.alias || props.as) {
    let as = props.alias ? routes[props.alias].as : props.as;

    if (!as) {
      as = "";

      if (props.alias !== "PublicIndex") {
        // eslint-disable-next-line no-console
        console.error(
          'Link "as" is empty.  Expected a value, recieved:',
          as,
          "to",
          props.to,
          "alias",
          props.alias,
        );
      }
    }
    // If there are parameters in the URL, replace them
    // with query values before rendering the link.
    const asPath = as.includes(":") ? constructPath(as, props.queries) : as;
    // const href = to.includes(':') ? constructPath(to, props.queries) : to;

    let href;

    if (props.queries) {
      href = { pathname: to, query: { ...props.queries } };
    } else {
      href = to;
    }

    useEffect(() => {
      if (props.mpTracking?.type === "View Appointment Details Link") {
        TRACK_EVENT.COMPONENT_VIEWED(
          props.mpTracking?.page,
          props.mpTracking?.type,
          {
            ...props.mpTracking?.props,
          },
        );
      }
    }, []);

    return (
      <Anchor href={href} as={asPath}>
        <a
          {...props.anchorProps}
          target={props.target}
          className={classnames(classes, {
            [styles.white]: props.white,
            [styles.bold]: props.bold,
            [styles.inlineTextLink]: props.inlineTextLink,
            [styles.inlineTextLinkDark]: props.inlineTextLinkDark,
          })}
          data-cy={props.dataCy ? props.dataCy + "-link" : ""}
          aria-current={props["aria-current"]}
          aria-label={getAriaLabel(props)}
          onClick={() => {
            if (!props.skipTracking) {
              track("Link -- Click", {
                to: asPath,
                ...props.mpTracking,
                deprecated: true,
                replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
                  page: props.mpTracking?.page,
                  to: asPath,
                  type: props.mpTracking?.type,
                  location: props.mpTracking?.location,
                }),
              });
              TRACK_EVENT.LINK_CLICKED(
                props.mpTracking?.page,
                asPath,
                props.mpTracking?.type,
                {
                  location: props.mpTracking?.location,
                  spring_doc_id: props.mpTracking?.spring_doc_id,
                },
              );
            }
          }}
        >
          {props.children}
        </a>
      </Anchor>
    );
  }

  let target, rel;

  if (props.to && props.to.includes("http")) {
    target = "_blank";
    rel = "noopener noreferrer";
  }
  return (
    <Anchor href={{ pathname: props.to, query: { ...props.queries } }}>
      <a
        {...props.anchorProps}
        target={target || props.target}
        rel={rel || props.rel}
        className={classnames(classes, {
          [styles.white]: props.white,
          [styles.inlineTextLink]: props.inlineTextLink,
        })}
        aria-current={props["aria-current"]}
        aria-label={getAriaLabel(props)}
        onClick={() => {
          if (!props?.skipTracking) {
            track("Link -- Click", {
              to: props.to,
              ...props.mpTracking,
              deprecated: true,
              replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
                page: props.mpTracking?.page,
                to: props.to,
                type: props.mpTracking?.type,
                location: props.mpTracking?.location,
              }),
            });
            TRACK_EVENT.LINK_CLICKED(
              props.mpTracking?.page,
              props.to,
              props.mpTracking?.type,
              {
                location: props.mpTracking?.location,
                spring_doc_id: props.mpTracking?.spring_doc_id,
              },
            );
          }
        }}
      >
        {props.children}
      </a>
    </Anchor>
  );
};

Link.propTypes = {
  alias: PropTypes.string,
  name: PropTypes.string,
  as: PropTypes.any,
  bold: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
  dataCy: PropTypes.string,
  ariaLabel: PropTypes.string,
  "aria-label": PropTypes.string,
  "aria-current": PropTypes.bool,
  mpTracking: PropTypes.shape({
    location: PropTypes.any,
    page: PropTypes.any,
    type: PropTypes.any,
    spring_doc_id: PropTypes.any,
    props: PropTypes.object,
  }),
  queries: PropTypes.any,
  rel: PropTypes.any,
  target: PropTypes.any,
  to: PropTypes.string,
  white: PropTypes.any,
  anchorProps: PropTypes.object,
  inlineTextLink: PropTypes.any,
  skipTracking: PropTypes.bool,
};

export default Link;
