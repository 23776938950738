import { Text, Box } from "design-system/components";

type TitleProps = {
  title?: string;
  subtitle?: string;
};

const Title = ({ title, subtitle }: TitleProps) => {
  if (!title) return null;

  return (
    <Box w={"100%"}>
      <Text as="h2" fontWeight={700} data-testid="slider-title">
        {title}
      </Text>
      {subtitle && <Text data-testid="slider-subtitle">{subtitle}</Text>}
    </Box>
  );
};

export default Title;
