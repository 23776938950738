import { gql } from "@apollo/client";

const getMemberVisits = gql`
  query getMemberVisits($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        # New endpoints - Use these
        visits_covered_total # Includes Therapy (basic), Specialist (MM), and sponsored covered Sessions
        visits_covered_coaching # Only coaching covered
        visits_covered_specialist # Only Specialist covered
        visits_used_total # Includes Therapy, Specialist (MM), and sponsored used sessions
        visits_used_basic # Only Therapy (basic) used sessions
        visits_used_specialist # Only Specialist (MM) used sessions
        visits_used_coaching # Only Coaching used sessions
        visits_remaining_coaching # Only Coaching remaining sessions
        visits_remaining_total # Includes Therapy (basic), Specialist (MM), and sponsored remaining sessions
        visits_remaining_specialist # Only Specialist (MM) remaining sessions
        visits_eligibility_limit_date # The contract term expiration date
        # -----------------------------------
        cohort {
          id
          # Old endpoints, leaving them in so if they are being used nothing breaks
          # TODO remove these calls when the corresponding endpoints are removed from ROTOM
          # Deprecated - DO NOT USE
          basic_visits_covered
          specialist_visits_covered
          all_basic_visits_covered
          all_specialist_visits_covered
          # ----------------------------------------
          contract_term {
            id
            visit_type
            end_at
          }
        }
        visits_eligibility_limit_date
      }
    }
  }
`;

export default getMemberVisits;
