import { Heading, useBreakpointValue } from "design-system/components";
import { useTranslation } from "react-i18next";

export const CarouselHeading = () => {
  const momentsHeadingVariant = useBreakpointValue([
    "sm_v1",
    "sm_v1",
    "md_v1",
    "md_v1",
  ]);
  const { t } = useTranslation("homepage");

  return (
    <Heading variant={momentsHeadingVariant}>
      {t("familySection.moments.heading")}
    </Heading>
  );
};
