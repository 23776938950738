//
/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import yup from "schemas/yup";
import {
  Bolded,
  Button,
  Card,
  Content,
  Form,
  Input,
  PhoneInput,
  Subhead,
  Grid,
  Col,
} from "@spring/smeargle";
import StateSelect from "components/form/StateSelect";
import { addNotification } from "@spring/smeargle/actions";
import { PotentialMemberType } from "@spring/constants";
import { useTranslation } from "react-i18next";

// import { AddressTypeahead } from '../../form';

import { updateFindMyMembershipModel } from "actions/signUp";
import { createPotentialCoveredLife } from "operations/mutations/potentialCoveredLife";
import { getFirstError } from "utils/apollo/errorHandler";
import routes from "routes";
import {
  normalizeToISO2,
  shouldShowStateField,
  isStateMailingEnabled,
} from "utils/global";
import {
  TRACK_EVENT,
  track,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";

import styles from "./styles.module.scss";
import { ResponsiveCardPadding } from "components";

// eslint-disable-next-line quotes
const mpPrefix =
  "We're having trouble finding your benefit (/manual_verification)";

const ViewState = Object.freeze({
  Initial: "INITIAL",
  Success: "SUCCESS",
});

const ManualVerificationForm = (props) => {
  const router = useRouter();
  const [view, setView] = useState(ViewState.Initial);
  const [memberCountry, setMemberCountry] = useState("");
  const potentialMemberType = props.findMyMembershipModel.potential_member_type;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (view === ViewState.Initial) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(
        routes.ManualVerification.as,
        "Manual Verification Form",
      );
    } else if (view === ViewState.Success) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(routes.ManualVerification.as, "Success");
    }
  }, [view]);

  useEffect(() => {
    if (potentialMemberType === PotentialMemberType.Employee) {
      track(`${mpPrefix} -- Primary Viewed`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
          page: routes.ManualVerification.as,
        }),
      });
    }

    if (potentialMemberType === PotentialMemberType.Dependent) {
      track(`${mpPrefix} -- Dependent Viewed`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
          page: routes.ManualVerification.as,
        }),
      });
    }

    setMemberCountry(props.findMyMembershipModel.country || "United States");
  }, []);

  const [create, { loading }] = useMutation(createPotentialCoveredLife, {
    onError: (err) => props.addNotification(getFirstError(err), "error"),
  });

  const createCL = async (formData) => {
    TRACK_EVENT.FORM_SUBMITTED(
      routes.ManualVerification.as,
      "Manual Verification",
    );

    if (potentialMemberType === PotentialMemberType.Employee) {
      track(`${mpPrefix} -- Primary Submitted`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
          page: routes.ManualVerification.as,
          type: "Manual Verification",
        }),
      });
    }

    if (potentialMemberType === PotentialMemberType.Dependent) {
      track(`${mpPrefix} -- Dependent Submitted`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
          page: routes.ManualVerification.as,
          type: "Manual Verification",
        }),
      });
    }

    const {
      first_name,
      last_name,
      email,
      employer,
      city,
      state,
      country,
      phone,
      ...metadata
    } = formData;

    const res = await create({
      variables: {
        input: {
          first_name,
          last_name,
          email,
          customer: employer,
          city,
          state,
          country: normalizeToISO2(country),
          primary: potentialMemberType === PotentialMemberType.Employee,
          metadata: JSON.stringify({
            phone: phone.code + phone.number,
            ...metadata,
          }),
        },
      },
    });

    if (res) {
      setView(ViewState.Success);
    }
  };

  const { t } = useTranslation("limitedLangAuth");

  const header = () => (
    <div className={styles.header}>
      <Subhead semibold center inheritColor noMargin>
        {t("manualVerificationForm.subhead")}
      </Subhead>

      <div className={styles.headerSubtext}>
        <Content inheritColor>{t("manualVerificationForm.content")}</Content>
      </div>
    </div>
  );

  const initialData = () => {
    const first_name = props.findMyMembershipModel.first_name || "";
    const last_name = props.findMyMembershipModel.last_name || "";
    const email = props.findMyMembershipModel.email_address || "";
    const country = props.findMyMembershipModel.country || "United States";

    return {
      first_name,
      last_name,
      email,
      country,
    };
  };

  const manualVerificationFormSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    employer: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    phone: yup
      .object()
      .shape({
        code: yup.string(),
        country: yup.string(),
        number: yup
          .string()
          .when("country", {
            is: "United States",
            then: yup
              .string()
              .min(
                10,
                t("manualVerificationForm.phoneNumberVerify.exactly10Digits"),
              )
              .max(
                10,
                t("manualVerificationForm.phoneNumberVerify.exactly10Digits"),
              )
              .matches(
                /^[0-9]+$/,
                t("manualVerificationForm.phoneNumberVerify.numericOnly"),
              ),
          })
          .matches(
            /^[0-9]+$/,
            t("manualVerificationForm.phoneNumberVerify.numericOnly"),
          )
          .min(4, t("manualVerificationForm.phoneNumberVerify.atLeast4Digits"))
          .required(),
      })
      .required(),
  });

  const form = () => (
    <Form
      schema={manualVerificationFormSchema}
      formKey="manual_verification_form"
      preserve
      fullSubmit
      flatSubmit
      submitText={t("manualVerificationForm.submitText")}
      disabled={loading}
      onSubmit={(formData) => createCL(formData)}
      initialData={initialData()}
      dataCy="manual-verification-form"
    >
      <Grid gutter="0 16px">
        <Col sm={6}>
          <Input
            fieldKey="first_name"
            label={t("manualVerificationForm.firstName.label")}
            placeholder={t("manualVerificationForm.firstName.placeholder")}
            dataCy="manual-verification-firstname"
            autoComplete="given-name"
            onInput={(e) => {
              props.updateFindMyMembershipModel({
                fieldKey: "first_name",
                value: e.target.value,
              });
            }}
            bolded
            autoFocus
          />
        </Col>

        <Col sm={6}>
          <Input
            fieldKey="last_name"
            label={t("manualVerificationForm.lastName.label")}
            placeholder={t("manualVerificationForm.lastName.placeholder")}
            dataCy="manual-verification-lastname"
            autoComplete="family-name"
            onInput={(e) => {
              props.updateFindMyMembershipModel({
                fieldKey: "last_name",
                value: e.target.value,
              });
            }}
            bolded
          />
        </Col>

        <Col>
          <Input
            fieldKey="email"
            label={t("manualVerificationForm.email.label")}
            placeholder={t("manualVerificationForm.email.placeholder")}
            dataCy="manual-verification-email"
            autoComplete="email"
            bolded
          />
        </Col>

        <Col>
          <Input
            fieldKey="employer"
            label={t("manualVerificationForm.employer.label")}
            placeholder={t("manualVerificationForm.employer.placeholder")}
            dataCy="manual-verification-employer"
            bolded
          />

          <div className={styles.employerInfo}>
            <Content inheritColor>
              <Bolded>{t("manualVerificationForm.formContent.part1")}</Bolded>{" "}
              {t("manualVerificationForm.formContent.part2")}
            </Content>
          </div>
        </Col>

        <Col sm={shouldShowStateField(memberCountry) ? 8 : 12}>
          <Input
            fieldKey="city"
            label={t("manualVerificationForm.city.label")}
            placeholder={t("manualVerificationForm.city.placeholder")}
            dataCy="manual-verification-city"
            bolded
          />
        </Col>

        {shouldShowStateField(memberCountry) &&
          isStateMailingEnabled(memberCountry) && (
            <Col sm={4}>
              <StateSelect
                fieldKey="state"
                label={t("manualVerificationForm.state.label")}
                placeholder={t("manualVerificationForm.state.placeholder")}
                dataCy="manual-verification"
                bolded
                country={memberCountry}
              />
            </Col>
          )}

        <Col>
          <Input
            fieldKey="country"
            label={t("manualVerificationForm.country.label")}
            placeholder={t("manualVerificationForm.country.placeholder")}
            dataCy="manual-verification-country"
            bolded
            disabled
          />
          <p>{t("manualVerificationForm.country.wrongCountry")}</p>
        </Col>

        <Col>
          <PhoneInput
            fieldKey="phone"
            label={t("manualVerificationForm.phone.label")}
            placeholder={t("manualVerificationForm.phone.placeholder")}
            dataCy="manual-verification-phone"
            bolded
          />
        </Col>
      </Grid>
    </Form>
  );

  const success = () => (
    <>
      <div className={styles.header}>
        <Subhead
          semibold
          center
          inheritColor
          noMargin
          dataCy="manual-verification-success-header"
        >
          {t("manualVerificationForm.title")}
        </Subhead>

        <div className={styles.headerSubtext}>
          <Content inheritColor>
            {t("manualVerificationForm.success.message")}
          </Content>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          full
          text={t("manualVerificationForm.success.buttonText")}
          dataCy="manual-verification-back-to-home"
          onClick={() => {
            TRACK_EVENT.BUTTON_CLICKED(
              routes.ManualVerification.as,
              "Back to Home",
              {
                to: routes.PublicIndex.as,
              },
            );
            goToHomepage();
          }}
        />
      </div>
    </>
  );

  const goToHomepage = () => {
    return router.push(routes.PublicIndex.to, routes.PublicIndex.as);
  };

  const headerForm = () => (
    <>
      {header()}
      {form()}
    </>
  );

  return (
    <Card color="GREY_100" padding="none" radius="md">
      <ResponsiveCardPadding>
        {view === ViewState.Success ? success() : headerForm()}
      </ResponsiveCardPadding>
    </Card>
  );
};

ManualVerificationForm.propTypes = {
  addNotification: PropTypes.func,
  findMyMembershipModel: PropTypes.shape({
    country: PropTypes.string,
    email_address: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    potential_member_type: PropTypes.any,
  }),
  updateFindMyMembershipModel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  findMyMembershipModel: state.signUp.findMyMembershipModel,
});

const mapDispatchToProps = { addNotification, updateFindMyMembershipModel };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManualVerificationForm);
