export const InsuranceIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M17.6705 12.0455C18.1098 11.6062 18.1098 10.8938 17.6705 10.4545C17.2312 10.0152 16.5188 10.0152 16.0795 10.4545L12.375 14.159L10.9205 12.7045C10.4812 12.2652 9.76884 12.2652 9.3295 12.7045C8.89017 13.1438 8.89017 13.8562 9.3295 14.2955L11.5795 16.5455C12.0188 16.9848 12.7312 16.9848 13.1705 16.5455L17.6705 12.0455Z"
          fill="#171E22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0463 0.141573C13.7066 -0.047191 13.2934 -0.047191 12.9537 0.141573L2.82865 5.76657C2.4715 5.96499 2.25 6.34144 2.25 6.75C2.25 17.6008 9.3088 24.6322 12.9134 26.8349C13.2735 27.055 13.7265 27.055 14.0866 26.8349C17.6912 24.6322 24.75 17.6008 24.75 6.75C24.75 6.34144 24.5285 5.96499 24.1713 5.76657L14.0463 0.141573ZM13.5 24.5301C10.2849 22.2843 4.77023 16.3137 4.50959 7.40663L13.5 2.41195L22.4904 7.40663C22.2298 16.3137 16.7151 22.2843 13.5 24.5301Z"
          fill="#171E22"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
