"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _fp = require("lodash/fp");
/**
 * Takes a value and appends it to a new array.
 * The old array is not updated.
 *
 * @name push
 * @param {*} value
 * @param {Array} array
 * @returns {Array} Returns a new array with added value.
 *
 * @example
 * const arr = [1, 2, 3];
 * const newArr =  push(4, arr);
 *
 * console.log(arr, newArr); // [1, 2, 3], [1, 2, 3, 4]
 */

function curried(value, array) {
  return [].concat((0, _toConsumableArray2["default"])(array), [value]);
}
var push = (0, _fp.curry)(curried);
var _default = exports["default"] = push;