import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const createInitialEvaluation = gql`
  mutation CreateInitialEvaluation(
    $member_id: ID!
    $version_number: Int
    $campaign: CampaignInput
  ) {
    createInitialEvaluation(
      member_id: $member_id
      version_number: $version_number
      campaign: $campaign
    ) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default createInitialEvaluation;
