import { gql } from "@apollo/client";

import { cohortDetails } from "operations/fragments/cohort";

const getCustomer = gql`
  query getCustomer($id: ID!) {
    customer(id: $id) {
      id
      active
      active_at
      inactive_at
      launched
      domain
      name
      slug
      number_of_employees
      cohorts {
        ...cohortDetails
      }
    }
  }
  ${cohortDetails}
`;

export default getCustomer;
