// 

import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


class Accordion extends Component {
	accordion

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);

		// Force the component to update when `ref` is available.
		// Required for `open` on load
		setTimeout(() => {
			this.forceUpdate();
		}, 500);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	// Handle window resizes to make sure the height of the component is updated.
	handleResize = () => {
		this.forceUpdate();
	}

	get height() {
		const maxHeight = this.accordion ? this.accordion.offsetHeight : 0;

		return this.props.open ? { maxHeight } : { maxHeight: 0 };
	}

	render() {
		return (
			<div
				style={this.height}
				className={classnames(styles.wrapper, {
					[styles.allowOverflow]: this.props.open && this.props.allowOverflow,
				})}
			>
				<div
					ref={(accordion) => { this.accordion = accordion; }}
					className={styles.inner}
				>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default Accordion;
