import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { DateTime } from "luxon";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";

import Router from "next/router";
import { get, getOr } from "lodash/fp";
import {
  Section,
  Stout,
  Icon,
  FlexRow,
  HorizontalRule,
  TryMomentsModal,
} from "@spring/smeargle";
import Meowth from "@spring/meowth";
import { addNotification, openModal } from "@spring/smeargle/actions";
import {
  links,
  modalIds,
  RequestableAppointmentKind,
  AppointmentMedium,
  AppointmentKind,
  AppointmentAction,
} from "@spring/constants";

import styles from "./styles.module.scss";

import routes from "routes";
import {
  CareProviderScheduleModal,
  Link,
  OffboardingStep,
  CarePlanStep,
  AvailabilityRequestModal,
} from "components";
import {
  getMemberCarePlanProgress,
  getMemberInfo,
} from "operations/queries/member";
import { getCareTeam } from "operations/queries/careProvider";
import { sendDownloadAppEmail } from "operations/mutations/general";
import { requestAppointment } from "operations/mutations/appointment";
import { generateAssessment } from "operations/mutations/assessment";
import { getFirstError } from "utils/apollo/errorHandler";
import { track } from "utils/mixpanel";
import { getIterableCampaignInfo } from "utils/localStorage";

const DashboardNextStep = (props) => {
  const { t } = useTranslation(["memberDashboard", "moments"]);
  const provider = (path) => {
    const careTeam = getOr({}, "data.user.member.care_team", props);
    const { care_navigator, medication_manager, therapist } = careTeam;
    const { t } = props;

    let provider = {};

    if (path.includes("CareNavigation")) {
      // all members should have a CN
      if (care_navigator) {
        provider = {
          ...care_navigator,
        };
      }
    } else if (path.includes("Therapy")) {
      if (therapist) {
        provider = {
          ...therapist,
        };
      } else {
        provider = {
          genericDescription: t("carePlanStep.therapyDesc"),
          genericImage:
            links.SpringCDN + "/assets/images/members/Therapy+Visit.png",
        };
      }
    } else if (path.includes("Medication")) {
      if (medication_manager) {
        provider = {
          ...medication_manager,
        };
      } else {
        provider = {
          genericDescription: t("carePlanStep.medicationDesc"),
          genericImage:
            links.SpringCDN + "/assets/images/members/Medication+Managers.png",
        };
      }
    } else if (path.includes("Onward")) {
      provider = {
        genericDescription: t("carePlanStep.onwardDesc"),
        genericImage: links.SpringCDN + "/assets/images/members/signature.png",
      };
    } else if (path.includes("SPRASS")) {
      provider = {
        genericDescription: t("carePlanStep.sprassDesc"),
        genericImage:
          links.SpringCDN + "/assets/images/members/Therapy+Visit.png",
      };
    }

    return provider;
  };

  const handleClickCTA = async (path) => {
    const careTeam = getOr({}, "data.user.member.care_team", props);
    const { care_navigator, medication_manager, therapist } = careTeam;
    const memberId = get("data.user.member.id", props);

    if (path.includes("CareNavigation")) {
      if (care_navigator) {
        props.openModal(modalIds.careProviderScheduleModal, {
          ...care_navigator,
          kind: AppointmentKind.InitialCareNavigation,
          medium: AppointmentMedium.Phone,
          providerRole: "Care Navigator",
          buttonText: t("carePlanStep.scheduleCareCallButtonText"),
          action: AppointmentAction.Create,
          initialStartTime: DateTime.local()
            .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            .toISO(),
        });
      } else {
        const { to, as } = routes.TherapistsBrowse;
        Router.replace(to, as);
      }
    } else if (path.includes("SPRASS")) {
      props
        .generateAssessment({
          variables: {
            member_id: memberId,
            kind: "SPRASS",
            campaign: getIterableCampaignInfo(),
          },
        })
        .then((res) => {
          const id = get("data.generateAssessment.assessment.id", res);
          let { to, as } = routes.TakeMemberAssessment;

          if (res.data.generateAssessment.success) {
            as = as.replace(":id", id);
            Router.replace(
              {
                pathname: to,
                query: { id },
              },
              as,
            );
          }
        });
    } else if (path.includes("Therapy")) {
      if (therapist) {
        /** FIXME: INITIAL START TIME PASSED IN IS A MONKEY PATCH
                ... should unify with implementation in CarePlan
                 Without it, the modal shows up with no appointments
                See MXENG-2279 for context
                 */
        props.openModal(modalIds.careProviderScheduleModal, {
          ...therapist,
          kind: AppointmentKind.Therapy,
          medium: AppointmentMedium.Video,
          providerRole: "Therapist",
          buttonText: t("carePlanStep.confirmAppointment"),
          action: AppointmentAction.Create,
          initialStartTime: DateTime.local()
            .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            .toISO(),
        });
      } else {
        const { to, as } = routes.TherapistsBrowse;
        Router.replace(to, as);
      }
    } else if (path.includes("Medication")) {
      if (medication_manager) {
        try {
          const payload = {
            care_provider_id: medication_manager.id,
            availability_days_of_week: ["Unknown"],
            availability_time_of_day: ["Unknown"],
            medium: AppointmentMedium.Video,
            kind: RequestableAppointmentKind.MedicationManagement,
          };

          const {
            errors,
            data: { requestAppointment },
          } = await props.requestAppointment(payload);

          if (!requestAppointment.success) {
            return props.addNotification(getFirstError(errors), "error");
          }

          return props.addNotification(
            t("carePlanStep.medicationSuccessNotif"),
            "success",
          );
        } catch (err) {
          return props.addNotification(getFirstError(err), "error");
        }
      } else {
        const { to, as } = routes.Medication_ManagersBrowse;
        Router.push(to, as);
      }
    } else if (path.includes("Previsit")) {
      const { to, as } = routes.PrevisitForm;
      Router.push(to, as);
    } else if (path.includes("MomentsCarePlanStep")) {
      const { to, as } = routes.MemberMoments;
      Router.push(to, as).then(() => window && window.scrollTo(0, 0));
    }
  };

  const actions = (step) => {
    if (!step.cta_path.includes("Onward")) {
      return [
        {
          text: step.call_to_action,
          onClick: () => {
            track("Dashboard Next Step", {
              "Care Plan Kind": step.cta_path,
              "Care Plan External Name": step.external_name,
              "Care Plan Internal Name": step.internal_name,
            });

            handleClickCTA(step.cta_path);
          },
        },
      ];
    }

    return [];
  };

  const carePlanStep = () => {
    let steps = getOr([], "carePlan.care_plan_progress.progress_steps", props);
    const memberId = get("data.user.member.id", props);
    const { showGlobalExperience } = props;

    if (steps) {
      // for global we do not show the medication management
      if (showGlobalExperience) {
        steps = steps.filter(
          (step) =>
            !step.cta_path.includes("SPRASS") &&
            !step.cta_path.includes("Medication"),
        );
      }
      let activeStep = steps.length - 1; // safety check and auto-assign activeStep to last step for cases when all steps are completed

      for (let i = 0; i < steps.length; i++) {
        if (!steps[i].completed) {
          activeStep = i; // if step is incomplete, override activeStep
          break;
        }
      }

      const nextStep = steps[activeStep];

      if (nextStep.cta_path.includes("Offboarding")) {
        return <OffboardingStep memberId={memberId} />;
      }

      if (nextStep.cta_path.includes("Therapy")) {
        const copy = nextStep.alt_copy;

        return (
          <CarePlanStep
            kind={nextStep.cta_path}
            title={nextStep.external_name}
            description={nextStep.description}
            actions={actions(nextStep)}
            provider={provider(nextStep.cta_path)}
            footer={copy}
          />
        );
      }
      return (
        <CarePlanStep
          kind={nextStep.cta_path}
          title={nextStep.external_name}
          description={nextStep.description}
          actions={actions(nextStep)}
          provider={provider(nextStep.cta_path)}
          footer={nextStep.alt_copy}
        />
      );
    }

    return null;
  };

  const nextCarePlanStep = () => {
    const steps = getOr(
      [],
      "carePlan.care_plan_progress.progress_steps",
      props,
    );

    if (steps.length > 1) {
      return (
        <Section size="xlg">
          <div className={styles.carePlan}>
            <div className={styles.contentWrapper}>
              {carePlanStep()}
              <div className={styles.linkWrapper}>
                <Link
                  inlineTextLink
                  alias="MemberResults"
                  ariaLabel={t("carePlanStep.viewMyPlan")}
                  mpTracking={{
                    pageName: "Member Dashboard",
                    linkName: "View My Plan",
                  }}
                >
                  <FlexRow justification="flex-end">
                    <div className={styles.stoutWrapper}>
                      <Stout inheritColor>
                        {t("carePlanStep.viewMyPlan")}
                        <Icon type="arrow-right" />
                      </Stout>
                    </div>
                  </FlexRow>
                </Link>
              </div>
            </div>
          </div>
        </Section>
      );
    }

    return (
      <Section size="xlg">
        <HorizontalRule />
      </Section>
    );
  };

  const sendDownloadAppEmail = () => {
    props.addNotification("Sending email", "info");
    props.sendDownloadAppEmail().then((success) => {
      if (success) {
        props.addNotification(t("carePlanStep.emailSuccessNotif"), "success");
      } else {
        props.addNotification(t("carePlanStep.emailErrorNotif"), "error");
      }
    });
  };

  if (
    !get("data.loading", props) &&
    !get("carePlan.loading", props) &&
    !get("memberInfo.loading", props)
  ) {
    const userEmail = get("memberInfo.user.member.email", props);
    const { t } = props;

    return (
      <div>
        {nextCarePlanStep()}

        <CareProviderScheduleModal />
        <AvailabilityRequestModal />
        <TryMomentsModal
          title={t("tryMomentsModal.title", { ns: "limitedLangMoments" })}
          subtitle={t("tryMomentsModal.subtitle", { ns: "limitedLangMoments" })}
          linkText={t("tryMomentsModal.linkText", { ns: "limitedLangMoments" })}
          userEmail={userEmail}
          url={
            (window &&
              window.location &&
              window.location.protocol +
                "//" +
                window.location.host +
                "/download_spring_app") ||
            "https://care.springhealth.com/download_spring_app"
          }
          sendEmail={sendDownloadAppEmail}
        />
      </div>
    );
  }

  return null;
};

const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});

DashboardNextStep.propTypes = {
  addNotification: PropTypes.func,
  generateAssessment: PropTypes.func,
  openModal: PropTypes.func,
  requestAppointment: PropTypes.func,
  sendDownloadAppEmail: PropTypes.func,
  showGlobalExperience: PropTypes.bool,
  t: PropTypes.func,
};

export { DashboardNextStep };

export default compose(
  graphql(getMemberInfo, {
    options: Meowth.apolloOptionsUserId,
    name: "memberInfo",
  }),
  graphql(getCareTeam, { options: Meowth.apolloOptionsUserId }),
  graphql(getMemberCarePlanProgress, {
    options: (ownProps) => ({
      fetchPolicy: "network-only",
      variables: {
        member_id: get("data.user.member.id", ownProps),
      },
    }),
    name: "carePlan",
    refetchQueries: ["getMemberCarePlanProgress"],
  }),
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (data) =>
        mutate({ variables: { input: { ...data } } }),
    }),
  }),
  graphql(sendDownloadAppEmail, {
    props: ({ mutate }) => ({
      sendDownloadAppEmail: () => mutate(),
    }),
  }),
  graphql(generateAssessment, { name: "generateAssessment" }),
  connect(mapStateToProps, { openModal, addNotification }),
)(withTranslation(["memberDashboard", "moments"])(DashboardNextStep));
