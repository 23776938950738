import { Box } from "design-system";

export const ChildBird = ({ width, height, fill = "#3E302E" }) => {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
        aria-labelledby="child-bird"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <title id="child-bird">{"Child Bird"}</title>
        <path
          d="M17.5 5.00004C17.5 5.55232 17.0523 6.00004 16.5 6.00004C15.9477 6.00004 15.5 5.55232 15.5 5.00004C15.5 4.44775 15.9477 4.00004 16.5 4.00004C17.0523 4.00004 17.5 4.44775 17.5 5.00004Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8986 1.67299C18.2273 -0.00923645 15.6366 -0.395256 13.5408 0.747865C11.6663 1.77033 10.5 3.73504 10.5 5.87027V6.18084C9.07772 6.49213 7.7369 6.91986 6.53564 7.37938C5.77878 7.49942 5.07286 7.65279 4.43274 7.8461C3.36127 8.16966 2.39224 8.62852 1.67859 9.30107C0.935606 10.0013 0.5 10.9079 0.5 12C0.5 17.7099 5.6197 22 12.5 22C19.3803 22 24.5 17.7099 24.5 12C24.5 10.9079 24.0644 10.0013 23.3214 9.30107C22.6078 8.62852 21.6387 8.16966 20.5673 7.8461L20.5 7.82601V7.41655L24.1244 3.81936C24.3963 3.54944 24.4901 3.14786 24.3659 2.78539C24.2417 2.42292 23.9213 2.1633 23.5409 2.11694L19.8986 1.67299ZM22.4288 11.4944C22.3449 11.2192 22.186 10.9793 21.9497 10.7566C21.6288 10.4541 21.1516 10.1742 20.5 9.93189V10.1213C20.5 11.0633 20.3158 11.9555 19.9843 12.7676C20.8161 12.4057 21.6327 11.9813 22.4288 11.4944ZM16.8688 13.8162C17.8652 12.909 18.5 11.6044 18.5 10.1213V7.00014C18.5 6.73362 18.6064 6.47812 18.7956 6.29038L21.2516 3.85272L19.2974 3.61452C19.0619 3.58582 18.8442 3.4743 18.6834 3.29989L18.5668 3.17351C17.5247 2.04381 15.8478 1.7677 14.4986 2.50365C13.2666 3.17565 12.5 4.46692 12.5 5.87027L12.5 7.00011C12.5 7.4834 12.1544 7.89753 11.6789 7.98398C8.17931 8.62027 5.07743 10.1091 3.37661 11.0481C3.13438 11.1818 2.97661 11.4353 2.96095 11.7269C5.62845 13.2774 8.51296 14.1379 11.4213 14.3084C11.4999 14.2262 11.594 14.1562 11.7019 14.103C12.729 13.5969 13.5283 12.659 13.8208 11.4671L13.994 10.7617C14.1256 10.2253 14.6671 9.89722 15.2035 10.0289C15.7399 10.1605 16.068 10.702 15.9363 11.2384L15.7632 11.9438C15.5539 12.7965 15.1711 13.5626 14.6615 14.2128C15.4014 14.1256 16.1383 13.9933 16.8688 13.8162ZM12.5 20C7.30924 20 3.72962 17.331 2.76249 13.9027C8.93241 17.1524 16.0676 17.1524 22.2375 13.9027C21.2704 17.331 17.6908 20 12.5 20Z"
          fill={fill}
        />
      </svg>
    </Box>
  );
};
