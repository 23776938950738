import { gql } from "@apollo/client";

const getChooseUserMemberInfo = gql`
  query getChooseUserMemberInfo($id: ID!) {
    user(id: $id) {
      id
      first_name
      date_of_birth
      member {
        id
        choose_user_cta_tag
        minor
        is_global_t2
        managed_dependents {
          id
          first_name
          email
          date_of_birth
          choose_user_cta_tag
          within_aging_out
        }
        manager {
          id
          first_name
          last_name
          email
        }
        cohort {
          id
          dependents_allowed
          child_dependents_allowed
          contract_term {
            scheduling_access {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export default getChooseUserMemberInfo;
