import React from "react";

const EditIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 4L2 16V22H8L20 10M14 4L15.5858 2.41421C16.3668 1.63317 17.6332 1.63316 18.4142 2.41421L21.5858 5.58579C22.3668 6.36683 22.3668 7.63316 21.5858 8.41421L20 10M14 4L20 10"
      stroke="black"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
