import { addNotification } from "@spring/smeargle/actions";
import { getFirstError } from "utils/apollo/errorHandler";
import { useMutation } from "@apollo/client";
import { createCheckIn } from "operations/mutations/assessment";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { getIterableCampaignInfo } from "utils/localStorage";

export const useCreateCheckInMutation = (memberId, router) => {
  return useMutation(createCheckIn, {
    variables: {
      member_id: memberId,
      campaign: getIterableCampaignInfo(),
    },
    onError: (err) => addNotification(getFirstError(err), "error"),
    onCompleted: ({ createCheckIn }) => {
      const { id } = createCheckIn.assessment;
      const { to } = routes.TakeMemberAssessment;
      let { as } = routes.TakeMemberAssessment;

      TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Progress Check-in", {
        spring_doc_id: "checkin001",
      });
      as = as.replace(":id", id);
      router.replace(
        {
          pathname: to,
          query: { id },
        },
        as,
      );
    },
  });
};
