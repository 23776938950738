import React from "react";

const WarningIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5359 3C10.0755 0.33333 13.9245 0.333337 15.4641 3L23.2583 16.5C24.7979 19.1667 22.8734 22.5 19.7942 22.5H4.20576C1.12656 22.5 -0.797935 19.1667 0.741665 16.5L8.5359 3ZM13.732 4C12.9622 2.66667 11.0377 2.66667 10.2679 4L2.47372 17.5C1.70391 18.8333 2.66616 20.5 4.20576 20.5H19.7942C21.3338 20.5 22.2961 18.8333 21.5263 17.5L13.732 4ZM13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5ZM13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V9Z"
      fill="black"
    />
  </svg>
);

export default WarningIcon;
