"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 *
 * @param {number} day
 * @returns {string} An ordinalize version of the day
 *
 */

var getOrdinal = function getOrdinal(day) {
  if (!day || typeof day !== 'number') {
    return '';
  }
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
var _default = exports["default"] = (0, _fp.curry)(getOrdinal);