import { setLogExtension, LogLevel } from "livekit-client";
import { logLiveKitLog } from "components/templates/SHSessionRoom/telemetry/datadog";

let isDebugModeEnabledConfig = false;

// This log extension is responsible for forwarding logs from LiveKit to Datadog.
// It automatically forwards logs of levels 'warn', 'error', and 'info'.
// Additionally, it can be configured to forward 'debug' level logs as well.
// This is controlled by a feature flag, which can be enabled for specific users
// or scenarios where more detailed logging is required. This allows for
// targeted debugging without overwhelming the log system with unnecessary data.

const liveKitLogExtension = (
  level: LogLevel,
  msg: string,
  context?: object,
) => {
  const shouldLog =
    level === LogLevel.warn ||
    level === LogLevel.error ||
    (level === LogLevel.info && isDebugModeEnabledConfig) ||
    (level === LogLevel.debug && isDebugModeEnabledConfig);

  if (shouldLog) {
    logLiveKitLog(LogLevel[level], msg, context);
  }
};

export function setLiveKitLogExtension(isDebugModeEnabled: boolean) {
  isDebugModeEnabledConfig = isDebugModeEnabled;
  setLogExtension(liveKitLogExtension);
}
