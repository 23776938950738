import { gql } from "@apollo/client";

const getChannelPartner = gql`
  query getChannelPartner($id: ID!) {
    user(id: $id) {
      customer {
        channel_partner {
          id
          name
          channel_partner_type
          channel_partner_sub_type
          launched
        }
      }
    }
  }
`;

export default getChannelPartner;
