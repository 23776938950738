//

export function equalTo(
  ref,
  message = "${path} must be the same as ${reference}",
) {
  return this.test({
    message,

    name: "equalTo",
    exclusive: false,

    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}

export const PHONE_NUMBER_REGEX =
  /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/; //eslint-disable-line
