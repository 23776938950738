import { openZendeskChat } from "utils/zendesk";
import { openAdaChatbot } from "utils/chatbot/adaChatbot";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const useChatBotHandler = () => {
  const shouldOpenAdaChatbot = useFeatureFlag(FLAGS.ADA_CHATBOT);

  const handleOpenChat = () => {
    if (shouldOpenAdaChatbot) {
      openAdaChatbot();
    } else {
      openZendeskChat();
    }
  };

  return { handleOpenChat };
};

export default useChatBotHandler;
