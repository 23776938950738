import { gql } from "@apollo/client";

// import { browseCareProviderData } from '../../fragments/careProvider';
import paging from "operations/fragments/paging";

const searchCareProviders = gql`
  query SearchCareProviders(
    $roles: [CareProviderRoleEnum!]!
    $limit: Int = 10
    $offset: Int = 0
    $version: String
    $filters: CareProviderFiltersInputType
  ) {
    care_providers_search(
      roles: $roles
      limit: $limit
      offset: $offset
      version: $version
      filters: $filters
    ) {
      query_request_id
      providers {
        id
        created_at
        roles
        associate_status
        status
        license_number
        name
        first_name
        last_name
        avatar
        bio
        gender
        licenses
        languages
        on_site
        cohorts
        dedicated
        states
        email
        phone
        user_id
        acuity_calendar_id
        in_house_availability_source_of_truth_at
        scheduling_url
        zoom_url
        in_person_supported
        virtual_supported
        dedicated_customer {
          id
          name
        }
        care_provider_tags {
          id
          kind
          name
          created_at
          updated_at
        }
        top_tags
        coordinates {
          latitude
          longitude
        }
        distance_kilometers
        distance_miles
        street_address_1
        street_address_2
        city
        state
        zip_code
        supported_appointment_kinds
        supported_appointment_mediums
        licensed_countries
        categories

        allows_email_consults
        allows_phone_consults
      }
      possible_filters {
        conditions
        specialties
        genders
        languages
        ethnicities
        mediums
        licenses {
          countries
          states
        }
        days_of_week
        time_of_day
        allow_company_exclusive
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default searchCareProviders;
