import React from "react";
import { Grid, Col, Section, Label, RadioButtons } from "@spring/smeargle";
import PropTypes from "prop-types";

const AppetiteElement = (props) => {
  const { left, right, options, fieldKey, formKey } = props;

  return (
    <Grid gap="0 16px">
      <Col md={6}>
        <Section>
          <Label>{left}</Label>
        </Section>
      </Col>
      <Col md={6}>
        <Section>
          <Label>{right}</Label>
        </Section>
      </Col>

      <Col size={12}>
        <RadioButtons
          border={true}
          formKey={formKey}
          fieldKey={fieldKey}
          options={options.top}
        />
      </Col>

      <Col md={6}>
        <RadioButtons
          border={true}
          formKey={formKey}
          fieldKey={fieldKey}
          options={options.left}
        />
      </Col>

      <Col md={6}>
        <RadioButtons
          border={true}
          formKey={formKey}
          fieldKey={fieldKey}
          options={options.right}
        />
      </Col>
    </Grid>
  );
};

AppetiteElement.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  left: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  right: PropTypes.string.isRequired,
};

export default AppetiteElement;
