//

import React from "react";
import yup from "schemas/yup";
import { SeverityRange, Range } from "@spring/smeargle";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "SDS.work_impact": numberField(),
  "SDS.social_life_impact": numberField(),
  "SDS.home_life_impact": numberField(),
};

const prompt2Schema = {
  "SDS.days_missed": numberField(),
};

const prompt3Schema = {
  "SDS.days_unproductive": numberField(),
};

const dayImpact = [
  "0 Days",
  "1 Days",
  "2 Days",
  "3 Days",
  "4 Days",
  "5 Days",
  "6 Days",
  "7 Days",
];

export const SDSSchema = {
  ...prompt1Schema,
};

const SDS = [
  {
    id: "SDS--1",
    title: "We're here to help.",
    subtitle: "Help us understand how these problems have disrupted your life.",
    schema: yup.object().shape(prompt1Schema),
    autoAdvance: false,
    questions: [
      <SeverityRange
        key={1}
        fieldKey="SDS.work_impact"
        min={0}
        max={10}
        step={1}
        label="Work"
        subtext="How much have these problems disrupted your work? (drag the circle)"
      />,
      <SeverityRange
        key={2}
        fieldKey="SDS.social_life_impact"
        min={0}
        max={10}
        step={1}
        label="Social Life"
        subtext="How much have these problems disrupted your social life/leisure activities? (drag the circle)"
      />,
      <SeverityRange
        key={3}
        fieldKey="SDS.home_life_impact"
        min={0}
        max={10}
        step={1}
        label="Family Life/Home Responsibilities"
        subtext="How much have these problems disrupted your family life/home responsibilities? (drag the circle)"
      />,
    ],
  },
  {
    id: "SDS--2",
    title:
      "In the last week, how often did your symptoms cause you to leave work or not carry out daily responsibilities?",
    schema: yup.object().shape(prompt2Schema),
    autoAdvance: false,
    questions: [
      <Range
        key={1}
        fieldKey="SDS.days_missed"
        min={0}
        max={7}
        step={1}
        markers={dayImpact}
        showMarkers
        showActiveIndicator
        showExact
      />,
    ],
  },
  {
    id: "SDS--3",
    title:
      "In the last week, how often did your symptoms cause you to be less productive at work?",
    schema: yup.object().shape(prompt3Schema),
    autoAdvance: false,
    questions: [
      <Range
        key={1}
        fieldKey="SDS.days_unproductive"
        min={0}
        max={7}
        step={1}
        markers={dayImpact}
        showMarkers
        showActiveIndicator
        showExact
      />,
    ],
  },
];

export default SDS;
