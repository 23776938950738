import { Box, Text, Heading, Image } from "design-system/components";
import { Button, Card } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import routes from "routes";
import { useRouter } from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";

const PreAssessedNoProvidersCard = () => {
  const { t } = useTranslation("homepage");

  const therapistsData = [
    {
      therapist: {
        id: 1,
        name: "Provider",
        avatar: "/static/DefaultAvatar3.png",
      },
    },
    {
      therapist: {
        id: 2,
        name: "Provider",
        avatar: "/static/DefaultAvatar2.png",
      },
    },
    {
      therapist: {
        id: 3,
        name: "Provider",
        avatar: "/static/DefaultAvatar1.png",
      },
    },
  ];

  const router = useRouter();

  const handleClick = () => {
    router.push(routes.TakeInitialAssessment.as);
  };

  return (
    <Card
      minW="100%"
      mb={["20px", "20px", "40px", "40px"]}
      padding="24px"
      variant="medium-emphasis"
      _hover={{
        borderColor: "border-subtle",
        cursor: "default",
      }}
      data-testid="new-active-care-no-providers-card"
      bg="tertiary.25"
    >
      <Box
        w="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w={["100%", "100%", "auto", "auto"]}
        >
          <Box
            display="flex"
            position="relative"
            alignItems={"center"}
            justifyContent="center"
          >
            {therapistsData?.map((provider, index) => (
              <Box
                key={provider.therapist.id}
                borderRadius="full"
                overflow="hidden"
                boxSize={
                  index === 1
                    ? ["47px", "52px", "56px"]
                    : ["40px", "45px", "48px"]
                }
                borderColor="border-subtle"
                ms={index === 0 ? "0" : ["-73px", "-87px"]}
                zIndex={therapistsData.length - index}
                background="white"
                mb={16}
                data-testid="recommended-therapist-image"
              >
                <Image
                  src={provider.therapist.avatar}
                  alt={`${provider.therapist.name} avatar`}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          w={["100%", "100%", "50%", "50%"]}
          color="#182023"
          mb={[16, 16, 16, 16]}
          textAlign="center"
        >
          <Heading
            display={"inline-block"}
            fontSize={["16px", "16px", "18px", "21px"]}
            fontWeight={600}
            mb="4px"
            data-testid="new-active-care-no-providers-card-heading"
          >
            {t("preAssessment.banner.noProviderCard.heading")}
          </Heading>
          <Text
            display={"inline-block"}
            color="content-secondary"
            data-testid="new-active-care-no-providers-card-description"
          >
            {t("preAssessment.banner.noProviderCard.description")}
          </Text>
        </Box>

        <Button
          background="#068262"
          borderRadius="8px"
          color="#FFFFFF"
          w={["100%", "100%", "auto", "auto"]}
          fontSize={["14px", "14px", "16px", "16px"]}
          onClick={(e) => {
            e.stopPropagation();
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Begin Assessment clicked",
            );
            TRACK_EVENT.CARD_CLICKED(
              window.location.pathname,
              "Pre Assessed Care Recommendations",
            );
            handleClick();
          }}
          mt={[16, 16, 0, 0]}
          data-testid="pre-assessed-no-providers-card-button"
        >
          {t("preAssessment.banner.noProviderCard.button")}
        </Button>
      </Box>
    </Card>
  );
};

export default PreAssessedNoProvidersCard;
