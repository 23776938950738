//

import React from "react";
import { Box } from "@chakra-ui/react";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";

const SpringPhone = () => (
  <Box style={getPhoneStyleForRTL()}>
    <a href="tel:+12405585796">+1 240 558 5796</a>
  </Box>
);

export default SpringPhone;
