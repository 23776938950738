import React from "react";
import { Box } from "@chakra-ui/react";
import {
  VHeadphonesIcon,
  VReadingBookIcon,
} from "@springcare/verdant-icons-react";
import { MomentContentFormatIconsProps } from "./types";

export const MomentContentFormatIcons = ({
  formatType,
}: MomentContentFormatIconsProps): React.ReactElement => {
  return (
    <Box marginRight="4px">
      {formatType === "TEXT" ? (
        <VReadingBookIcon width={18} height={18} aria-label={"text content"} />
      ) : (
        <VHeadphonesIcon width={18} height={18} aria-label="audio content" />
      )}
    </Box>
  );
};
