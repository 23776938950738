const DEV_CUSTOMER = '00000000000000000000000000000000';

const customers = {
	'preview': '00000000-0000-0000-0000-000000000000',
};

module.exports = {
	DEV_CUSTOMER,
	customers,
};
