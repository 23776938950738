//

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import styles from "components/templates/MemberDashboard/Icons/styles.module.scss";

const Checklist = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="24"
    viewBox="0 0 28 24"
  >
    <path
      className={classnames(styles.icon, {
        [styles.active]: props.active,
      })}
      fillRule="nonzero"
      d="M1.5 0C.674 0 0 .674 0 1.5v21c0 .826.674 1.5 1.5 1.5h25c.826 0 1.5-.674 1.5-1.5v-21c0-.826-.674-1.5-1.5-1.5h-25zm0 1h25c.275 0 .5.225.5.5v21c0 .275-.225.5-.5.5h-25a.501.501 0 0 1-.5-.5v-21c0-.275.225-.5.5-.5zm6 5a.5.5 0 0 0-.354.146L5.5 7.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.497.497 0 0 0 .708 0l2-2A.5.5 0 0 0 7.5 6zm3 1a.5.5 0 1 0 0 1h2a.5.5 0 1 0 0-1h-2zm4 0a.5.5 0 1 0 0 1h9a.5.5 0 1 0 0-1h-9zm-7 3a.5.5 0 0 0-.354.146L5.5 11.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.497.497 0 0 0 .708 0l2-2A.5.5 0 0 0 7.5 10zm3 1a.5.5 0 1 0 0 1h13a.5.5 0 1 0 0-1h-13zm-3 3a.5.5 0 0 0-.354.146L5.5 15.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.497.497 0 0 0 .708 0l2-2A.5.5 0 0 0 7.5 14zm3 1a.5.5 0 1 0 0 1h7a.5.5 0 1 0 0-1h-7zm9 0a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1h-4zM3 20a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1A.5.5 0 0 0 3 20zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zM8 20a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1A.5.5 0 0 0 8 20zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5z"
    />
  </svg>
);

Checklist.propTypes = {
  active: PropTypes.any,
};

export default Checklist;
