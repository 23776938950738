import React, { useState, useEffect, useContext } from "react";
import routes from "routes";
import NextLink from "next/link";
import {
  Box,
  Text,
  Heading,
  Stack,
  Hide,
  Show,
} from "design-system/components";
import { Button, Link } from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTranslation } from "react-i18next";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import { useFeatureFlag, FLAGS } from "utils/launchdarkly";
import NewActiveCareNoProvidersCard from "./NewActiveCareNoProvidersCard";
import { useRouter } from "next/router";
import PreAssessedNoProvidersCard from "./PreAssessedNoProvidersCard";

const ActiveCareNoProvidersCard = ({ showNewPreAssessedExperience }) => {
  const [trackedOnce, setTrackedOnce] = useState(0);
  const { t } = useTranslation("homepage");
  const router = useRouter();
  const currentRoute = router?.asPath;

  const isMemberHomePage = routes.MemberHome.as === currentRoute;

  const enableNewNoProviderInterface = useFeatureFlag(
    FLAGS.ENABLE_NEW_NO_PROVIDER_INTERFACE,
  );
  const showNewNoProviderInterface =
    isMemberHomePage && enableNewNoProviderInterface;

  const { setIsFindNewCareTab, setCurrentTab, isPrivatePractice } =
    useContext(CareContextValues);

  const goToFindNewCareTab = () => {
    setCurrentTab(1);
    setIsFindNewCareTab(true);
  };

  useEffect(() => {
    if (trackedOnce === 0 && !showNewNoProviderInterface) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Your care empty state",
        {
          spring_doc_id: "HPR_018",
          location: "Active care",
        },
      );
      setTrackedOnce(1);
    }
  }, []);

  if (showNewPreAssessedExperience) {
    return <PreAssessedNoProvidersCard />;
  }

  if (showNewNoProviderInterface) {
    return <NewActiveCareNoProvidersCard />;
  }

  return (
    <>
      <Box
        minW={["100%", "100%", "100%", "650px"]}
        mb="64px"
        borderWidth="2px"
        borderRadius="12px"
        borderColor="tertiary.300"
        borderStyle="dashed"
      >
        <Box w="100%">
          <Stack
            align="stretch"
            alignItems="center"
            direction={["column", "column", "row", "row"]}
          >
            <Box
              h={"fit-content"}
              w={["100%", "100%", "50%", "50%"]}
              pt={["24px", "24px", "0px", "0px"]}
              ps="56px"
            >
              <Box maxW="400px" margin="auto">
                <Hide below="480px">
                  <Heading variant="md_v1" color="platform.900" mb="16px">
                    {t("noProvidersCard.heading")}
                  </Heading>
                </Hide>
                <Show below="480px">
                  <Text variant="bodyBold_v1" color="platform.900" mb="16px">
                    {t("noProvidersCard.heading")}
                  </Text>
                </Show>
                <Text variant="md_v1">{t("noProvidersCard.description")}</Text>
              </Box>
              <Box mt="40px">
                {isPrivatePractice ? (
                  <Button
                    colorScheme="base"
                    variant="medium-emphasis"
                    as={Link}
                    size="sm"
                    _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                    onClick={() => {
                      goToFindNewCareTab();
                      TRACK_EVENT.BUTTON_CLICKED(
                        window.location.pathname,
                        "Active Care No Therapy card",
                        {
                          type: "Active Care No Therapy card",
                          to: "Care Visits",
                          spring_doc_id: "HPR_028",
                          location: "Active Care",
                        },
                      );
                    }}
                  >
                    <Text size="body-medium-strong">
                      {t("noProvidersCard.button")}
                    </Text>
                  </Button>
                ) : (
                  <NextLink href={routes.MemberCareVisits.as} passHref>
                    <Button
                      colorScheme="base"
                      variant="medium-emphasis"
                      as={Link}
                      size="sm"
                      _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                      onClick={() => {
                        TRACK_EVENT.BUTTON_CLICKED(
                          window.location.pathname,
                          "Active Care No Therapy card",
                          {
                            type: "Active Care No Therapy card",
                            to: "Care Visits",
                            spring_doc_id: "HPR_028",
                            location: "Active Care",
                          },
                        );
                      }}
                    >
                      <Text size="body-medium-strong">
                        {t("noProvidersCard.button")}
                      </Text>
                    </Button>
                  </NextLink>
                )}
              </Box>
            </Box>
            <Box
              h="100%"
              w={["100%", "100%", "50%", "50%"]}
              pe={["0px", "0px", "0px", "24px"]}
              py="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box w="321px" h="321px" borderRadius="50%" pe={"5px"}>
                <img src="/static/safety.svg" alt="Safety" />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ActiveCareNoProvidersCard;
