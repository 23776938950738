import { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from "../../index";
import { Subtitle } from "../../../index";
import { SupportedLanguages } from "@spring/constants";
import { useTranslation } from "react-i18next";

export const SHSubtitleLanguageSelect = ({
  subtitles,
  trackLang,
  setTrackLang,
}) => {
  const subtitleLanguages = useMemo(() => {
    let languages = SupportedLanguages.filter((lang) =>
      Object.keys(subtitles).includes(lang.code),
    );
    languages.unshift({ nativeName: "Off", englishName: "Off", code: null });
    return languages;
  }, [subtitles]);

  const { t } = useTranslation("moments");

  function getNativeNameFromLanguageCode(langCode) {
    const language = subtitleLanguages.find(
      (language) => language.code === langCode,
    );
    return language.nativeName;
  }

  return (
    <Menu isLazy offset={[0, -40]}>
      <MenuButton
        as={Button}
        variant="link"
        color="platform.700"
        _focusVisible={{ boxShadow: "0 0 0 3px black" }}
        leftIcon={<Subtitle boxSize={24} />}
        lang={trackLang}
      >
        {trackLang === null
          ? t("exerciseModal.subtitlesBtn")
          : getNativeNameFromLanguageCode(trackLang)}
      </MenuButton>
      <MenuList
        id="choose-subtitle-language"
        minW={130}
        minH={130}
        justifyContent="start"
        zIndex="10"
      >
        <MenuOptionGroup defaultValue="Off" type="radio">
          {subtitleLanguages.map((language) => (
            <MenuItemOption
              p={8}
              fontWeight="bold"
              value={() => setTrackLang(language.code)}
              lang={language.code}
              key={language.nativeName}
            >
              {language.nativeName}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

SHSubtitleLanguageSelect.propTypes = {
  subtitles: PropTypes.array,
  trackLang: PropTypes.string,
  setTrackLang: PropTypes.func,
};
