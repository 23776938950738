import {
  compose,
  defaults,
  pick,
  mapKeys,
  camelCase,
  zipObject,
} from "lodash/fp";
import { BoeingCustomerID } from "constants/customers";

export type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>;

// returns object of camelCase keys with inferred types or null fallback values
export const pickOrNullCamelCase = <T, K extends Array<keyof T>>(
  target: T, // object
  keys: K, // array of strings that are keys of target object
): {
  [key in K[number] as CamelCase<string & key>]: T[key] | null;
} =>
  compose(
    mapKeys(camelCase),
    pick(keys),
    defaults(
      zipObject(
        keys,
        keys.map((_) => null),
      ),
    ),
  )(target);

export const getPromoCardContentKey = (
  customerId: string,
  isChip: boolean,
): string => {
  if (isChip) {
    return "chip";
  }

  if (customerId === BoeingCustomerID) {
    return BoeingCustomerID;
  }

  return "default";
};
