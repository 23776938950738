import {
  Box,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { images } from "@spring/constants";
import { CoveredCareContentT2 } from "./CoveredCareContentT2";
import { tabTypes } from "modules/MemberDashboard/Benefits/constants/coveredCareTabTypes";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes/MemberDashboardRoutes";

export const CoveredCareTabsT2 = ({ memberInfo }) => {
  const { t } = useTranslation(["t2Benefits", "limitedLangBenefits"]);
  const [isMobile] = useMediaQuery(["(max-width: 680px)"]);
  const [isMd] = useMediaQuery("(min-width: 540px) and (max-width: 960px)");
  const isWorkLifeSupported = memberInfo?.user?.member?.is_work_life_supported;

  const tabStyles = (isMobile: boolean, isMd: boolean) => {
    return {
      color: "platform.base",
      borderBottom: "4px hover-base-low-emphasis",
      border: isMobile && "1px solid #00131738",
      borderColor: "content-secondary",
      whiteSpace: !isMd && "nowrap",
      fontWeight: 600,
      _hover: { cursor: "pointer" },
      _active: {
        color: isMobile ? "primary-on-base" : "content-primary",
        background: isMobile ? "primary-base" : "transparent",
      },
      _selected: {
        color: isMobile ? "primary-on-base" : "content-primary",
        borderBottom: "4px solid",
        borderColor: "primary-base",
        background: isMobile ? "primary-base" : "transparent",
      },
      _focus: {
        focusBorderColor: "transparent",
        borderColor: "none",
      },
    };
  };

  const TherapyTab = {
    label: t("t2Benefits:therapyVisits.label"),
    body: (
      <CoveredCareContentT2
        title={t("limitedLangBenefits:therapyVisits.title")}
        tabType={tabTypes.THERAPY}
        description={t("t2Benefits:therapyVisits.descriptionT2")}
        memberInfo={memberInfo}
      />
    ),
  };

  const CoachTab = {
    label: t("limitedLangBenefits:coachesVisit.label"),
    body: (
      <CoveredCareContentT2
        title={t("limitedLangBenefits:coachesVisit.title")}
        tabType={tabTypes.COACH}
        description={t("t2Benefits:coachesVisit.descriptionT2")}
        memberInfo={memberInfo}
      />
    ),
  };

  const MomentsTabData = {
    label: t("t2Benefits:selfGuidedExercises.label"),
    body: (
      <>
        <Image
          src={images.MomentsBannerFull}
          alt={t("t2Benefits:selfGuidedExercises.bannerAltText")}
          marginTop={isMobile && "32px"}
        ></Image>
        <CoveredCareContentT2
          title={t("limitedLangBenefits:selfGuidedExercises.moments")}
          tabType={tabTypes.MOMENTS}
          description={""}
          memberInfo={memberInfo}
        />
      </>
    ),
  };

  const CrisisTabData = {
    label: t("t2Benefits:crisisSupport.label"),
    body: (
      <CoveredCareContentT2
        title={t("t2Benefits:crisisSupport.title")}
        tabType={tabTypes.CRISIS}
        description={t("t2Benefits:crisisSupport.descriptionT2")}
        memberInfo={memberInfo}
      />
    ),
  };

  const WorkLifeSupportTabData = {
    label: t("limitedLangBenefits:EAPBenefit.label"),
    body: (
      <CoveredCareContentT2
        title={t("limitedLangBenefits:EAPBenefit.label")}
        tabType={tabTypes.WORK_LIFE_SUPPORT}
        description={t("t2Benefits:EAPBenefit.descriptionT2")}
        memberInfo={memberInfo}
      />
    ),
  };

  const DependentAccessTabData = {
    label: t("t2Benefits:dependentAccess.labelT2"),
    body: (
      <CoveredCareContentT2
        title={t("t2Benefits:dependentAccess.callToActionText")}
        tabType={tabTypes.DEPENDENT_ACCESS}
        description={t("t2Benefits:dependentAccess.descriptionT2")}
        memberInfo={memberInfo}
      />
    ),
  };

  const getTabsToRender = () => {
    return [
      TherapyTab,
      CoachTab,
      MomentsTabData,
      CrisisTabData,
      ...(isWorkLifeSupported ? [WorkLifeSupportTabData] : []),
      DependentAccessTabData,
    ];
  };
  const tabsToRender = getTabsToRender();

  return (
    <Box>
      <Tabs isFitted size="md" my="32px">
        <TabList flexDirection={isMobile ? "column" : "row"}>
          {tabsToRender.map((tab, index) => (
            // @ts-ignore
            <Tab
              key={index}
              {...tabStyles(isMobile, isMd)}
              type="button"
              _focusVisible={{
                borderTop: "1px solid #4b4b4b",
                borderLeft: "1px solid #4b4b4b",
                borderRight: "1px solid #4b4b4b",
              }}
              onClick={() => {
                TRACK_EVENT.CARD_CLICKED(
                  routes.MemberBenefits.as,
                  `Benefits Page Tab Change - ${tab.label}`,
                );
              }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabsToRender.map((tab, index) => (
            <TabPanel key={index} tabIndex={-1}>
              {tab.body}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
