import { useRef, useEffect } from "react";

// A custom hook for holding state value between a transition (e.g., a render). Useful if you need
// to compare an old version of a state value against a new version of that value in order to
// perform some logic.
const useSyncPreviousValue = (value) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

// Similar to the above hook, but skips the update if the value is
// undefined or null(not necessarily falsy because we may want to track if false or 0)
export const useSyncPreviousTruthyValue = (value) => {
  const ref = useRef(value);

  useEffect(() => {
    if (value === undefined || value === null) return;
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default useSyncPreviousValue;
