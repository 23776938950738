import { useEffect } from "react";
import { graphql } from "@apollo/client/react/hoc";
import Meowth from "@spring/meowth";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { compose } from "redux";
import { getOr } from "lodash/fp";
import { CountryLanguage } from "@spring/constants";
import { useTranslation } from "react-i18next";

import { localeCountry } from "actions/global/actions";
import { getUserInfo } from "operations/queries/user";
import { lookupLanguage } from "utils/language";
import { normalizeToISO2 } from "utils/global";
import { isT2MemberPreSignup } from "utils/memberHelpers";

const switchCountry = (currentLang, currentCountry, userLang, userCountry) => {
  let lang = "en";
  let country = "US";

  if (userLang && userCountry) {
    lang = userLang;
    country = userCountry;
  } else if (userCountry) {
    lang = CountryLanguage[userCountry]?.primary || currentLang;
    country = userCountry;
  } else {
    lang = userLang || currentLang || lang;
    country = userCountry || currentCountry || country;
  }

  return {
    lang,
    country,
  };
};

const LocaleWrapper = ({
  isLoggedIn,
  currentLang,
  currentCountry,
  data,
  localeCountry,
  children,
}) => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const userData = data?.user || null;

  const changeLangCountry = (lang, country) => {
    i18n.changeLanguage(lang);
    localeCountry({ lang, country });
  };

  useEffect(() => {
    const isT2 = isT2MemberPreSignup();
    if ((!isLoggedIn || router.route === "/_error") && !isT2) {
      let routerQueryCountry = normalizeToISO2(router.query.country);
      const urlCountry = CountryLanguage[routerQueryCountry]
        ? routerQueryCountry
        : "US";
      let urlLang = router.query.lang
        ? lookupLanguage(router.query.lang).code
        : CountryLanguage[urlCountry].primary || "en";

      if (currentLang !== urlLang || currentCountry !== urlCountry) {
        changeLangCountry(urlLang, urlCountry);
      }
      return;
    }

    let userLang = getOr(null, "user.preferred_language", data);

    if (userLang) {
      userLang = lookupLanguage(userLang)?.code;
    }

    let userCountry = getOr(null, "user.member.postal_address.country", data);

    if (userCountry) {
      const countryCode = normalizeToISO2(userCountry);
      userCountry = countryCode === "Invalid Input" ? userCountry : countryCode;
    }

    const { lang: newLang, country: newCountry } = switchCountry(
      currentLang,
      currentCountry,
      userLang,
      userCountry,
    );

    if (newLang !== currentLang || newCountry !== currentCountry) {
      changeLangCountry(newLang, newCountry);
    }
  }, [userData, isLoggedIn]);

  return <>{children}</>;
};

export default compose(
  graphql(getUserInfo, {
    options: Meowth.apolloOptionsUserId,
    skip: Meowth.apolloSkipUserId,
  }),
  connect(
    (state) => ({
      isLoggedIn: state.auth?.isLoggedIn,
      currentLang: state.global?.lang,
      currentCountry: state.global?.country,
    }),
    { localeCountry },
  ),
)(LocaleWrapper);

export { switchCountry };
