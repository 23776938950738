export const colors = {
  primary: {
    base: "#068464",
    dark: "#014333",
    light: "#F0FFFB",
    on: "#F0FFFB",
    25: "#F0FFFB",
    50: "#BBF5E6",
    100: "#8AEBD2",
    200: "#35D6AE",
    300: "#06C292",
    400: "#06AD83",
    500: "#068464",
    600: "#046E53",
    700: "#025943",
    800: "#014333",
    900: "#002E22",
  },
  secondary: {
    base: "#11383B",
    dark: "#11383B",
    light: "#E7F2F0",
    25: "#F4FAFA",
    50: "#E7F2F0",
    100: "#C7E7E2",
    200: "#80C7BC",
    300: "#53B2A4",
    400: "#2B958D",
    500: "#1B7E85",
    600: "#19676C",
    700: "#1C6361",
    800: "#11383B",
    900: "#001819",
  },
  tertiary: {
    base: "#3E302E",
    dark: "#110A09",
    light: "#F1EFEA",
    on: "#F1EFEA",
    25: "#FAF9F6",
    50: "#F1EFEA",
    100: "#DCD9D6",
    200: "#CEC7C0",
    300: "#B8ADA1",
    400: "#958A83",
    500: "#837267",
    600: "#6A544D",
    700: "#452A25",
    800: "#3E302E",
    900: "#110A09",
  },
  purple: {
    base: "#414379",
    dark: "#24264C",
    light: "#F3F2F8",
    25: "#F3F2F8",
    50: "#DEDBEA",
    100: "#D4D2E5",
    200: "#C5C6E2",
    300: "#B1B3D8",
    400: "#9597C6",
    500: "#7A7CB8",
    600: "#595CA6",
    700: "#414379",
    800: "#24264C",
    900: "#0C0D2C",
  },
  orange: {
    base: "#CC612E",
    dark: "#A24D25",
    light: "#F8EBE2",
    25: "#F8F2EF",
    50: "#F8EBE2",
    100: "#F9E6DA",
    200: "#FAD1B7",
    300: "#FFBA97",
    400: "#F5A580",
    500: "#EE8C5D",
    600: "#DB7342",
    700: "#CC612E",
    800: "#A24D25",
    900: "#4F2612",
  },
  info: {
    base: "#316A9A",
    dark: "#1F4769",
    light: "#ECF4FB",
    on: "#ECF4FB",
    25: "#ECF4FB",
    50: "#D9EAF7",
    100: "#BBD7EE",
    200: "#8BB6D9",
    300: "#6599C4",
    400: "#4780AF",
    500: "#316A9A",
    600: "#205785",
    700: "#1F4769",
    800: "#1C374D",
    900: "#152430",
  },
  platform: {
    base: "#6C7071",
    dark: "#555859",
    light: "#EFF1F1",
    on: "#FFFFFF",
    25: "#F7F8F8",
    50: "#EFF1F1",
    100: "#E4E7E7",
    200: "#DCE0E0",
    300: "#C6CDCD",
    400: "#B4B9BB",
    500: "#6C7071",
    600: "#555859",
    700: "#424A4C",
    800: "#2C3A40",
    900: "#182023",
  },
  success: {
    base: "#068464",
    dark: "#145A49",
    light: "#DFF8F2",
    on: "#DFF8F2",
    25: "#DFF8F2",
    50: "#C1F1E5",
    100: "#99E5D2",
    200: "#64CCB2",
    300: "#3AB495",
    400: "#1B9C7B",
    500: "#068464",
    600: "#0E6F57",
    700: "#145A49",
    800: "#17453A",
    900: "#15302A",
  },
  warning: {
    base: "#FECC79",
    dark: "#916010",
    light: "#FFF8DC",
    on: "#916010",
    25: "#FFFCEE",
    50: "#FFF8DC",
    100: "#FFEBB8",
    200: "#FECC79",
    300: "#F2B145",
    400: "#D2922A",
    500: "#B17819",
    600: "#916010",
    700: "#714A0B",
    800: "#513508",
    900: "#302006",
  },
  error: {
    base: "#C04848",
    dark: "#8F2B2B",
    light: "#FDDCDC",
    on: "#F7EAEA",
    25: "#F7EAEA",
    50: "#FDDCDC",
    100: "#F7BEBE",
    200: "#E48E8E",
    300: "#D26767",
    400: "#C04848",
    500: "#AE3131",
    600: "#8F2B2B",
    700: "#6F2626",
    800: "#501F1F",
    900: "#301515",
  },
};
