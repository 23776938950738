// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const VerticalRule = (props) => (
	<div
		className={classnames(styles.verticalRuleWrapper, {
			[styles.pad]    : props.pad,
			[styles.margin] : props.margin,
		})}
	>
		<div className={classnames(styles.verticalRule)} />
	</div>
);

export default VerticalRule;
