import { useQuery } from "@apollo/client";
import { getAllowDirectScheduling } from "../queries/getAllowDirectScheduling";

export const useGetAllowDirectScheduling = ({
  memberId,
}: {
  memberId: string;
}) => {
  return useQuery(getAllowDirectScheduling, {
    variables: {
      id: memberId,
    },
    skip: !memberId,
  });
};
