import { createIcon } from "@chakra-ui/react";

const LeftArrowIcon = createIcon({
  displayName: "LeftArrow",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7071 4.70711C13.0976 4.31658 13.0976 3.68342 12.7071 3.29289C12.3166 2.90237 11.6834 2.90237 11.2929 3.29289L3.29289 11.2929C3.10536 11.4804 3 11.7348 3 12C3 12.2652 3.10536 12.5196 3.29289 12.7071L11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071C13.0976 20.3166 13.0976 19.6834 12.7071 19.2929L6.41422 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41421 11L12.7071 4.70711Z"
    />
  ),
  defaultProps: {
    "aria-label": "Left arrow icon",
    role: "img",
    "aria-hidden": "true",
  },
});

export const LeftArrow = () => (
  <span data-icon={"left"}>
    <LeftArrowIcon />
  </span>
);
