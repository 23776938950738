// 

import React from 'react';
import classnames from 'classnames';


import styles from './styles.module.scss';


const Content = (props) => (
	<span
		onClick={props.onClick || null}
		className={classnames(styles.content, {
			[styles[props.status]] : props.status,
			[styles.subheader]     : props.subheader,
			[styles.dark]          : props.dark,
			[styles.bold]          : props.bold,
			[styles.italic]        : props.italic,
			[styles.underline]     : props.underline,
			[styles.center]        : props.center,
			[styles.noMargin]      : props.noMargin,
			[styles.inheritColor]  : props.inheritColor,
			[styles.inline]        : props.inline,
			[styles.block]         : props.block,
		})}
		lang={props.languageCode ? props.languageCode : null}
		style={props.width ? { width: props.width } : {}}
	>
		{!props.splitFirstWord ? <>{props.children}</> : <> <b> {props.children.split(' ')[0]} </b> {props.children.substring(props.children.split(' ')[0].length).trim()} </>}
	</span>
);

export default Content;
