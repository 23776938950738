import { gql } from "@apollo/client";

const cancelAppointment = gql`
  mutation CancelAppointment($input: CancelAppointmentInput!) {
    cancelAppointment(input: $input) {
      success
      appointment {
        id
        status
      }
    }
  }
`;

export default cancelAppointment;
