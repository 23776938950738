import { InsuranceIcon } from "./InsuranceIcon";
import { Link } from "@springcare/sh-component-library";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import routes from "routes";
import NextLink from "next/link";
import {
  InNetworkStatusDisclaimerProps,
  InNetworkDisclaimerConfig,
} from "./dataContract";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InsuranceVerificationModal } from "components/modals";
import { TRACK_EVENT } from "utils/mixpanel";
import { useInViewportOnce } from "hooks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useSelector } from "react-redux";

type RootState = {
  auth?: {
    userIdentifiers?: {
      user?: {
        member?: {
          has_access_to_cost_estimation?: boolean;
        };
      };
    };
  };
};

const getVirtualSessionCostTermDisclaimer = (
  t: Function,
  costTransparencyPercentageCovered: number,
  customerName: string,
): string => {
  return customerName
    ? t("benefitsSummary.costTransparencyForPercentageCustomerCoveredMessage", {
        customerName: customerName,
        percentage: costTransparencyPercentageCovered,
      })
    : t("benefitsSummary.costTransparencyForPercentageCoveredMessage", {
        percentage: costTransparencyPercentageCovered,
      });
};

const getCsnStatusDisclaimer = (
  disclaimers: InNetworkDisclaimerConfig,
  inNetwork: boolean,
  hasFreeSessions: boolean,
): ReactElement | string => {
  if (inNetwork && hasFreeSessions) return disclaimers.inNetworkFreeSessions;
  if (inNetwork && !hasFreeSessions) return disclaimers.inNetworkNoFreeSessions;
  if (!inNetwork && hasFreeSessions)
    return disclaimers.notInNetworkFreeSessions;
  if (!inNetwork && !hasFreeSessions)
    return disclaimers.notInNetworkNoFreeSessions;

  return disclaimers.default;
};

const InNetworkStatusDisclaimer = ({
  inNetwork,
  hasFreeSessions,
  internationalCohort,
  showCTA = true,
  showIcon = true,
  telemetryProps = null,
  inNetworkDisclaimerConfig = null,
  virtualSessionCostTerm = null,
  coaching = false,
  medicationManagement = false,
  hasMedManagementFreeSessions = false,
}: InNetworkStatusDisclaimerProps): ReactElement => {
  const { t } = useTranslation("careProvider");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const trackingProperties = {
    in_network: String(inNetwork),
    ...telemetryProps,
  };

  const { has_access_to_cost_estimation: hasAccessToCostEstimation } =
    useSelector(
      (state: RootState) => state?.auth?.userIdentifiers?.user?.member || {},
    );

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const showHighmarkCopy = useFeatureFlag(
    FLAGS.HIGHMARK_COST_TRANSPARENCY_COPY,
  );
  const isUsingHighmarkBanners = useFeatureFlag(FLAGS.HIGHMARK_COST_BANNERS);
  const shouldShowCoverageTab = useFeatureFlag(FLAGS.ENABLE_COST_TRANSPARENCY);

  useEffect(() => {
    if (inViewport && showCTA && telemetryProps && !trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Request cost estimate",
        trackingProperties,
      );
      setTrackedOnce(true);
    }
  }, [inViewport, showCTA]);

  const getInNetworkDisclaimer = () => {
    if (coaching) {
      return null;
    }

    if (
      showHighmarkCopy &&
      !inNetworkDisclaimerConfig?.dontShowHighmarkCopy &&
      !isUsingHighmarkBanners
    ) {
      let providerType = "therapy";
      if (medicationManagement) {
        providerType = "medication management";
      }
      return t("benefitsSummary.costTransparencyForHighMarkMessage", {
        providerType: providerType,
      });
    }

    if (
      virtualSessionCostTerm?.enable_show &&
      virtualSessionCostTerm?.percentage_covered
    ) {
      return getVirtualSessionCostTermDisclaimer(
        t,
        virtualSessionCostTerm.percentage_covered,
        virtualSessionCostTerm.customer_display_name,
      );
    }

    if (!internationalCohort) {
      const freeSessions = medicationManagement
        ? hasMedManagementFreeSessions
        : hasFreeSessions;

      return getCsnStatusDisclaimer(
        inNetworkDisclaimerConfig,
        inNetwork,
        freeSessions,
      );
    }

    return null;
  };

  if (!getInNetworkDisclaimer()) {
    return <>{inNetworkDisclaimerConfig.default}</>;
  }

  function getLink(showModal, CTA, link) {
    if (!showModal) {
      return (
        <NextLink href={link} passHref>
          <Link
            display="inline-block"
            color="primary.base"
            onClick={() => {
              TRACK_EVENT.LINK_CLICKED(
                window.location.pathname,
                shouldShowCoverageTab && hasAccessToCostEstimation
                  ? routes["Billing--Coverage"].as
                  : routes.MembersInsurance.as,
                "Request cost estimate hyperlink",
                trackingProperties,
              );
            }}
          >
            {CTA}
          </Link>
        </NextLink>
      );
    }
    return (
      <Link
        display="inline-block"
        color="primary.base"
        onClick={() => {
          if (showModal) {
            TRACK_EVENT.MODAL_OPENED(
              window.location.pathname,
              "Verify Insurance Modal",
              trackingProperties,
            );
            onOpen();
          }
        }}
      >
        {CTA}
      </Link>
    );
  }

  return (
    <Flex flexWrap="nowrap" ref={ref}>
      {showIcon && inNetwork && (
        <Flex pr={2} alignItems="center">
          <InsuranceIcon />
        </Flex>
      )}
      <Box data-testid="in-network-disclaimer">
        {getInNetworkDisclaimer()}
        {showCTA && (
          <>
            {" "}
            {getLink(
              false,
              t("benefitsSummary.requestCostEstimate"),
              shouldShowCoverageTab && hasAccessToCostEstimation
                ? routes["Billing--Coverage"].as
                : routes.MembersInsurance.as,
            )}
          </>
        )}
      </Box>
      <InsuranceVerificationModal
        trackingProperties={trackingProperties}
        isOpen={isOpen}
        onClose={() => {
          TRACK_EVENT.MODAL_CLOSED(
            window.location.pathname,
            "Verify Insurance Modal",
            trackingProperties,
          );
          return onClose();
        }}
      />
    </Flex>
  );
};
export default InNetworkStatusDisclaimer;
