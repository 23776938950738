import { gql } from "@apollo/client";

const sendDownloadAppEmail = gql`
  mutation SendDownloadAppEmail {
    sendDownloadAppEmail {
      success
    }
  }
`;

export default sendDownloadAppEmail;
