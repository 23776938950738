import { useRouter } from "next/router";
import routes from "routes";
import { PageBase } from "components/layout";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { SHHeader } from "design-system/components";
import styles from "components/organisms/MemberExpectations/styles.module.scss";
import { DesktopContent, MobileContent } from "./UpselledComponents";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { TRACK_EVENT } from "utils/mixpanel";

interface UpselledExpectationsProps {
  allowed: boolean;
}

const UpselledExpectations = ({ allowed }: UpselledExpectationsProps) => {
  const [isDesktop] = useMediaQuery("(min-width: 780px)");
  const { t } = useTranslation("membership");
  const router = useRouter();

  const handleButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      routes.MemberExpectations.as,
      "Upselled Member Expectations Begin",
    );
    router.push(
      routes.TakeInitialAssessment.to,
      routes.TakeInitialAssessment.as,
    );
  };

  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      routes.MemberExpectations.as,
      "Upselled Member Expectations",
      {
        page_version: "Upselled Member Expectations",
      },
    );
  }, []);

  return (
    <PageBase
      // @ts-ignore
      className={styles.pageBase}
      darkBg={false}
      allowed={allowed}
      redirect={routes.SignIn}
    >
      <SHHeader
        showSplitHeaderDesktop={true}
        sliderDismissed
        isLoggedIn
        country="US"
        global={false}
        setGlobalCtx={() => {}}
        currentView="upselling_expectations"
        isDependentObj={false}
        setCurrentView={() => {}}
        displayBackBtn={() => false}
        doGoBack={() => {}}
        desktopBg="white"
        page="upselling_expectations"
        mobileBg="white"
        currLang={undefined}
      />

      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        mb={40}
        mt={isDesktop ? 62 : 32}
      >
        {isDesktop ? (
          <DesktopContent onClick={handleButtonClick} t={t} />
        ) : (
          <MobileContent onClick={handleButtonClick} t={t} />
        )}
      </Box>
    </PageBase>
  );
};

export default UpselledExpectations;
