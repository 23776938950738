export const VanillaSimple = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="vanilla-simple-icon"
    aria-hidden="true"
  >
    <g clipPath="url(#a)">
      <path
        stroke="#1A5856"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25 15.625v12.5M16.875 49.375h16.25"
      />
      <path
        fill="#1A5856"
        d="M29.42 6.83a6.249 6.249 0 0 0-8.84 0L25 11.25l4.42-4.42Z"
      />
      <circle cx="25" cy="1.25" r="1.25" fill="#1A5856" />
      <circle cx="20.625" cy="3.125" r="1.25" fill="#1A5856" />
      <circle cx="29.375" cy="3.125" r="1.25" fill="#1A5856" />
      <path
        stroke="#1A5856"
        strokeLinecap="round"
        strokeWidth="2"
        d="M25 1.25v8.125m0 0-4.375-6.25M25 9.375l4.375-6.25"
      />
      <path
        fill="#1A5856"
        fillRule="evenodd"
        d="M19.71 27.376a.322.322 0 0 1 .267-.501h10.046c.257 0 .41.287.268.501l-1.517 2.275a3.867 3.867 0 0 0-.649 2.145c0 1.478.904 2.788 1.922 3.86.37.39.713.83 1.02 1.312l-2.282.844c-.871.322-.871 1.554 0 1.876l2.94 1.088.732 1.978C32.052 46.828 28.867 50 25 50c-4.142 0-7.5-3.638-7.5-8.125 0-1.661.46-3.206 1.185-4.493l.59.218.412 1.115c.322.871 1.554.871 1.876 0l.413-1.115 1.114-.412c.871-.322.871-1.554 0-1.876l-1.114-.412-.413-1.115a1.046 1.046 0 0 0-.11-.217c.262-.557.422-1.15.422-1.772 0-.764-.226-1.51-.65-2.145l-1.516-2.275ZM23.8 43.7l-.675-1.825-.675 1.825-1.825.675 1.825.675.675 1.825.675-1.825 1.825-.675L23.8 43.7Z"
        clipRule="evenodd"
      />
      <path
        fill="#1A5856"
        d="m39.375 23.75.675 1.825 1.825.675-1.825.675-.675 1.825-.675-1.825-1.825-.675 1.825-.675.675-1.825ZM13.125 43.125l.844 2.281 2.281.844-2.281.844-.844 2.281-.844-2.281L10 46.25l2.281-.844.844-2.281Z"
      />
      <circle cx="10.625" cy="39.375" r="1.25" fill="#1A5856" />
      <circle cx="38.75" cy="46.875" r="1.25" fill="#1A5856" />
      <circle cx="16.25" cy="27.5" r="1.25" fill="#1A5856" />
      <circle cx="36.563" cy="29.688" r=".938" fill="#1A5856" />
      <path
        stroke="#1A5856"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25 15.625c4.06 0 6.875-4.406 6.875-7.521 0-.419-.035-.83-.103-1.229-3.22.297-5.873 2.47-6.772 5.373-.898-2.903-3.552-5.076-6.772-5.373-.068.4-.103.81-.103 1.229 0 3.115 2.816 7.521 6.875 7.521Z"
      />
      <path
        stroke="#1A5856"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M39.906 6.875 25 25s6.242-2.385 12.577-6.2c6.335-3.816 4.347-9.063 2.329-11.925ZM10.094 6.875 25 25s-6.242-2.385-12.577-6.2c-6.335-3.816-4.347-9.063-2.329-11.925ZM24.063 41.528a1 1 0 0 0-1.876 0l-.515 1.394-1.394.515a1 1 0 0 0 0 1.876l1.394.515.515 1.394a1 1 0 0 0 1.876 0l.515-1.394 1.394-.515a1 1 0 0 0 0-1.876l-1.394-.515-.515-1.394ZM19.687 33.785c.322-.871 1.554-.871 1.876 0l.413 1.115 1.114.412c.871.322.871 1.554 0 1.876l-1.114.412-.413 1.115c-.322.871-1.554.871-1.876 0l-.413-1.115-1.114-.412c-.871-.322-.871-1.554 0-1.876l1.114-.412.413-1.115ZM32.812 33.785c.322-.871 1.554-.871 1.876 0l1.088 2.94 2.94 1.087c.87.322.87 1.554 0 1.876l-2.94 1.088-1.088 2.94c-.322.87-1.554.87-1.876 0l-1.088-2.94-2.94-1.088c-.87-.322-.87-1.554 0-1.876l2.94-1.088 1.088-2.94Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h50v50H0z" />
      </clipPath>
    </defs>
  </svg>
);
