// 

import React from 'react';

import { Icon } from '../../atoms';
import { Content } from '../../typography';
import { FlexRow } from '../../layout';

import styles from './styles.module.scss';



const ListItemStyle = (props) => {
	if (props.listStyle === 'bulleted') {
		return <div className={styles.bullet} />;
	}

	return (
		<div className={styles.iconWrapper}>
			<Icon style={{ marginBlock: '2px 0', marginInline: '0 2px' }} type="check" />
		</div>	
	);
};

const Checklist = (props) => {
	const items = Array.isArray(props.listItems) ? props.listItems : [];
	return (
		<ul className={styles.checkList}>
			{
				items.map((item, idx) => (
					<li key={idx} className={styles.checkListItem}>
						<FlexRow alignment="center">
							<ListItemStyle listStyle={props.listStyle} />
							<Content>{item}</Content>
						</FlexRow>
					</li>
				))
			}
		</ul>
	);
};

Checklist.defaultProps = {
	listItems : [],
	style     : 'checked',
};

export default Checklist;
