export const RightRays = () => (
  <svg
    width="9"
    height="22"
    viewBox="0 0 9 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="right-rays"
    aria-hidden="true"
    role="img"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.06279 1.18333L0.158031 2.75043C-0.118112 3.22872 0.045764 3.84031 0.524057 4.11645C1.00235 4.39259 1.61394 4.22872 1.89008 3.75043L2.79484 2.18333C3.07099 1.70504 2.90711 1.09345 2.42882 0.817308C1.95053 0.541166 1.33894 0.705041 1.06279 1.18333ZM7.09999 5.48827C6.82385 5.00997 6.21226 4.8461 5.73396 5.12224L4.16687 6.027C3.68858 6.30314 3.5247 6.91473 3.80084 7.39303C4.07699 7.87132 4.68858 8.03519 5.16687 7.75905L6.73396 6.85429C7.21226 6.57815 7.37613 5.96656 7.09999 5.48827ZM7.04785 12.1074C7.60014 12.1074 8.04785 11.6597 8.04785 11.1074C8.04785 10.5551 7.60014 10.1074 7.04785 10.1074H5.23833C4.68604 10.1074 4.23833 10.5551 4.23833 11.1074C4.23833 11.6597 4.68604 12.1074 5.23833 12.1074H7.04785ZM2.42882 21.1827C2.90711 20.9065 3.07099 20.2949 2.79484 19.8166L1.89008 18.2495C1.61394 17.7712 1.00235 17.6074 0.524057 17.8835C0.045764 18.1597 -0.118112 18.7712 0.158031 19.2495L1.06279 20.8166C1.33894 21.2949 1.95053 21.4588 2.42882 21.1827ZM5.73396 16.8777C6.21226 17.1539 6.82385 16.99 7.09999 16.5117C7.37613 16.0334 7.21226 15.4218 6.73396 15.1457L5.16687 14.2409C4.68858 13.9648 4.07699 14.1286 3.80084 14.6069C3.5247 15.0852 3.68858 15.6968 4.16687 15.973L5.73396 16.8777Z"
      fill="#068262"
    />
  </svg>
);
