/**
 * Enums for provider statuses.
 */

const ProviderStatus = Object.freeze({
	Pending       : 'Pending',
	Submitted     : 'Submitted',
	Approved      : 'Approved',
	Disabled      : 'Disabled',
	Rejected      : 'Rejected',
	NoNewPatients : 'No New Patients',
});

module.exports = ProviderStatus;
