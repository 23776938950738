export const shadows = {
  topShadow: "0px -6px 4px rgba(222, 222, 222, 0.25)",
  cardShadow: "0px 3px 12px rgba(0, 0, 0, 0.15)",
  boxShadow:
    "0px 12px 40px -8px rgba(72, 45, 40, 0.2), 0px 0px 8px rgba(72, 45, 40, 0.08);",
  elevation: {
    low: {
      left: "-8px 4px 4px rgba(180, 185, 187, 0.25)",
      top: "0px -6px 4px rgba(222, 222, 222, 0.25)",
      bottom: "0px 6px 4px rgba(222, 222, 222, 0.25)",
    },
  },
};
