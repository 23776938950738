import { gql } from "@apollo/client";

const getAppointmentSlots = gql`
  query getAppointmentSlots(
    $user_ids: [ID]!
    $start_span: DateTime!
    $end_span: DateTime!
    $slot_increment: Int = 30
    $kind: AppointmentKindEnum
    $medium: AppointmentMediumEnum!
    $upcoming_slots_only: Boolean
    $upcoming_slots_count: Int = 3
    $provider_index: Int
    $page_number: Int
    $should_infer_kind: Boolean = false
  ) {
    appointment_slots(
      user_ids: $user_ids
      start_span: $start_span
      end_span: $end_span
      slot_increment: $slot_increment
      kind: $kind
      medium: $medium
      upcoming_slots_only: $upcoming_slots_only
      upcoming_slots_count: $upcoming_slots_count
      provider_index: $provider_index
      page_number: $page_number
      should_infer_kind: $should_infer_kind
    ) {
      available
      blocked
      kind
    }
  }
`;

export default getAppointmentSlots;
