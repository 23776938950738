import { useEffect } from "react";
import { ModalSheet } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import { AcknowledgeLateCancelContent } from "components/templates/NoShowReschedulePage";
import { useBreakpointValue } from "@chakra-ui/react";

type AcknowledgeLateCancelModalProps = {
  memberId: string;
  autocancelPolicyAckRequired: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const AcknowledgeLateCancelModal: React.FC<
  React.PropsWithChildren<AcknowledgeLateCancelModalProps>
> = ({ memberId, autocancelPolicyAckRequired, isOpen, onOpen, onClose }) => {
  const { t } = useTranslation(["careVisits"]);
  const size = useBreakpointValue({ base: "sm", md: "md" });

  useEffect(() => {
    if (
      !sessionStorage.getItem("autocancelOpened") &&
      autocancelPolicyAckRequired &&
      memberId
    ) {
      onOpen();
    }
  }, [memberId]);

  const closeModalWithoutAck = () => {
    onClose();
    sessionStorage.setItem("autocancelOpened", "true");
  };

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={closeModalWithoutAck}
      data-testid="acknowledge-late-cancel-modal"
      title={t("noShowlandingPage.autoCancelTitle")}
      size={size}
    >
      <AcknowledgeLateCancelContent
        memberId={memberId}
        trackingType="Acknowledge policy button"
        trackingProps={{
          location: "Home page",
          to: "Home page",
        }}
        onComplete={onClose}
        isModal={true}
      />
    </ModalSheet>
  );
};
