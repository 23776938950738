import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  VMedicationDuotoneSpot,
  VMedicationFlatSpot,
} from "@springcare/verdant-icons-react";
import { InformationFlyout, SessionCountText } from "components";
import { useTranslation } from "react-i18next";

type MedicationManagementFlyoutType = {
  isAlsoAvailableTile?: boolean;
  isOpen: boolean;
  onClose: () => void;
  sessionSnapshot: {
    expirationDate: string;
    totalVisitsCovered: number;
    remainingCovered: number;
    specialtyVistsRemaining: number;
    visits_covered_coaching: number;
  };
  isHealthPlan?: boolean;
  showPreAssessedExperience?: boolean;
};

const MedicationManagementFlyoutBody = ({
  isAlsoAvailableTile,
}: {
  isAlsoAvailableTile: boolean;
}) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.medicationManager",
  });
  return (
    <>
      {isAlsoAvailableTile ? (
        ""
      ) : (
        <>
          <Heading size="heading-medium" mb="v-8">
            {t("whyIsThisRecommendedHeader")}
          </Heading>
          <Text mb={24}>{t("whyIsThisRecommendedBody")}</Text>
        </>
      )}
      <Heading size="heading-small" mb="v-8">
        {t("benefitsHeader")}
      </Heading>
      <Text mb={24}>{t("benefitsBody")}</Text>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpect.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>{t("whatToExpect.item1")}</ListItem>
        <ListItem>{t("whatToExpect.item2")}</ListItem>
      </UnorderedList>
      <Heading size="heading-small" mb="v-8">
        {t("commonConcerns.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item1Q")}
          </Text>{" "}
          {t("commonConcerns.item1A")}{" "}
        </ListItem>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item2Q")}
          </Text>{" "}
          {t("commonConcerns.item2A")}
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const MedicationManagementFlyout = ({
  isAlsoAvailableTile = false,
  isOpen,
  onClose,
  sessionSnapshot,
  isHealthPlan,
  showPreAssessedExperience = false,
}: MedicationManagementFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.medicationManager",
  });

  const ctaLink = showPreAssessedExperience
    ? "/members/initial_assessment"
    : "/browse/medication_managers";

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VMedicationFlatSpot />}
      heroIllustration={VMedicationDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaLink={ctaLink}
    >
      <>
        {!isHealthPlan && (
          <SessionCountText sessionSnapshot={sessionSnapshot} t={t} />
        )}
        <MedicationManagementFlyoutBody
          isAlsoAvailableTile={isAlsoAvailableTile}
        />
      </>
    </InformationFlyout>
  );
};
