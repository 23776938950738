//

import PropTypes from "prop-types";
import React from "react";
import { TransitionStack, FloatyPage, Reader } from "@spring/smeargle";

import JourneyIntroCard from "components/molecules/JourneyStack/JourneyIntroCard";
import JourneyChartIntro from "components/molecules/JourneyStack/JourneyChartIntro";
import JourneyChartForecast from "components/molecules/JourneyStack/JourneyChartForecast";

const Stack = (props) => (
  <TransitionStack
    animation="slideshow"
    currentIndex={props.currentIndex}
    stack={[
      <Reader key="JourneyIntroCard">
        <FloatyPage>
          <JourneyIntroCard onNext={props.onNext} />
        </FloatyPage>
      </Reader>,
      <Reader key="JourneyChartIntro">
        <FloatyPage>
          <JourneyChartIntro onNext={props.onNext} />
        </FloatyPage>
      </Reader>,
      <Reader key="JourneyChartForecast">
        <FloatyPage>
          <JourneyChartForecast onNext={props.onNext} />
        </FloatyPage>
      </Reader>,
    ]}
  />
);

Stack.propTypes = {
  currentIndex: PropTypes.any,
  onNext: PropTypes.any,
};

export default Stack;
