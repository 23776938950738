/* eslint react/prop-types: 0 */

import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { reasonForCancellationSchema } from "schemas/reasonForCancellationSchema";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import { Bolded, Modal } from "@spring/smeargle";
import { Text } from "design-system/components";
import { getIterableCampaignInfo } from "utils/localStorage";
import { Button } from "@springcare/sh-component-library";
import {
  Box,
  Checkbox,
  Flex,
  Select,
  SkeletonText,
  Skeleton,
  Stack,
  Center,
} from "@chakra-ui/react";
import {
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  getModalNameForTracking,
  isCareNavigation,
  modalIds,
} from "@spring/constants";
import { addNotification, closeModal } from "@spring/smeargle/actions";
import { useTranslation, Trans } from "react-i18next";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";

import { cancelAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import { TRACK_EVENT } from "utils/mixpanel";
import { useCancelAppointment } from "./CancelAppointmentModal.util";
import { useRouter } from "next/router";
import { getAppointment } from "operations/queries/appointment";
import { CancellationPolicy } from "components/templates/CareVisitDetails/components";
import { useNoShowPolicyConfig } from "components/templates/CareVisitDetails/components/ConfirmationCopy/hooks";

const computeModalTypeForTracking = (isLateCancel, hasCancelled) => {
  if (isLateCancel && !hasCancelled) {
    return "Late Cancelation/Reschedule Modal";
  } else if (isLateCancel && hasCancelled) {
    return "Late Cancelation Reason Modal";
  }
  return getModalNameForTracking(modalIds.cancelAppointmentModal);
};

const useModalStateTracking = (
  modalOpen,
  eventProps,
  isLateCancel,
  hasCancelled,
) => {
  const [hasTrackedOpenOnce, setHasTrackedOpenOnce] = useState(false);

  useEffect(() => {
    const modalType = computeModalTypeForTracking(isLateCancel, hasCancelled);
    if (modalOpen === true && !hasTrackedOpenOnce) {
      TRACK_EVENT.MODAL_OPENED(window.location.pathname, modalType, {
        spring_doc_id: "cnsched019",
        ...eventProps,
      });
      setHasTrackedOpenOnce(true);
    } else if (!modalOpen) {
      TRACK_EVENT.MODAL_CLOSED(window.location.pathname, modalType, {
        spring_doc_id: "cnsched020",
        ...eventProps,
      });
    }
  }, [modalOpen, isLateCancel, eventProps, hasCancelled, hasTrackedOpenOnce]);
};

const CancelAppointmentModal = ({
  isWithin24Hours,
  addNotification,
  closeModal,
  timeToAppointment,
  modalOpen,
  setShouldShowCancelledSection,
}) => {
  const router = useRouter();
  const { t } = useTranslation("careVisits");
  const { data, loading } = useCancelAppointment();
  const [isCancelled, setIsCancelled] = useState(false);
  const [isReasonSelected, setIsReasonSelected] = useState("");
  const [isPolicyAgreementChecked, setIsPolicyAgreementChecked] =
    useState(false);

  const appointmentKind = data?.appointment?.kind;
  const isCareNavigatorAppt = isCareNavigation(appointmentKind);
  const {
    config: {
      memberPayForNoShow,
      memberCostForNoShow,
      hasGracePeriodMissedAppointments,
      isPayer,
    },
  } = useNoShowPolicyConfig();

  const closeCancelAppointmentModal = () => {
    closeModal(modalIds.cancelAppointmentModal);
  };

  const [cancelAppointmentMutation, { loading: cancelAppointmentLoading }] =
    useMutation(cancelAppointment, {
      refetchQueries: [getAppointment],
      onCompleted: () => {
        setIsCancelled(true);
        // hide the cancellation details while the modal is open
        setShouldShowCancelledSection(false);
        if (!isWithin24Hours || isCareNavigatorAppt) {
          closeCancelAppointmentModal();
        }
      },
      onError: (err) => addNotification(getFirstError(err), "error"),
    });

  const { register, handleSubmit } = useForm({
    mode: "onChange",
    resolver: yupResolver(reasonForCancellationSchema),
  });

  useEffect(() => {
    if (!modalOpen) {
      // show the cancellation details when the modal is closed
      setShouldShowCancelledSection(true);
    }
  }, [modalOpen]);

  const trackingProperties = {
    provider_id: getOr(null, "care_provider.id", data),
    appointment_id: data?.appointment?.id,
    appointment_type: getAppointmentKindForTracking(
      getOr(null, "appointment.kind", data),
    ),
    appointment_medium: getAppointmentMediumForTracking(
      getOr(null, "appointment.medium", data),
    ),
    appointment_time_utc: DateTime.fromISO(
      getOr(null, "appointment.start_at", data),
    )
      .toUTC()
      .toISO(),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    time_to_appointment: timeToAppointment,
    offset: new Date().getTimezoneOffset(),
  };

  useModalStateTracking(
    modalOpen,
    trackingProperties,
    isWithin24Hours,
    isCancelled,
  );

  const onSubmit = async (data) => {
    const { cancellationReason } = data;

    try {
      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        isWithin24Hours ? "Confirm Late Cancelation" : "Confirm Cancel",
        {
          spring_doc_id: "cnsched017",
          location: isWithin24Hours
            ? modalIds.cancelAppointmentModal
            : getModalNameForTracking(modalIds.cancelAppointmentModal),
          ...trackingProperties,
        },
      );

      await cancelAppointmentMutation({
        variables: {
          input: {
            id: router?.query?.id,
            cancellation_reason: cancellationReason,
            campaign: getIterableCampaignInfo(),
          },
        },
      });

      closeCancelAppointmentModal();
    } catch (err) {
      if (err.errors && err.errors.length) {
        // eslint-disable-next-line no-console
        console.error(err.errors[0].message);
      }
    }
  };

  const primaryCtaStyling =
    cancelAppointmentLoading ||
    ((!isPolicyAgreementChecked || !isReasonSelected) &&
      !isCareNavigatorAppt &&
      isWithin24Hours)
      ? {
          _hover: {
            borderColor: "platform.base",
            color: "platform.base",
            opacity: "30%",
          },
          _active: {
            borderColor: "platform.base",
            color: "platform.base",
            opacity: "30%",
          },
          _disabled: {
            borderColor: "platform.base",
            color: "platform.base",
            opacity: "30%",
            cursor: "not-allowed",
          },
        }
      : {
          _hover: {
            borderColor: "transparent",
            color: "white",
            background: "#981C14",
          },
          _focus: {
            borderColor: "transparent",
            color: "white",
            background: "#981C14",
          },
          _active: {
            borderColor: "transparent",
            color: "white",
            background: "#981C14",
          },
        };

  return (
    <Modal
      size="md"
      title="Cancellation Dialog"
      id={modalIds.cancelAppointmentModal}
    >
      {loading ? (
        <Stack gap={12} mb={16} mt={16}>
          <SkeletonText
            noOfLines={2}
            spacing="10"
            skeletonHeight="48"
            mb="8px"
          />
          <SkeletonText
            noOfLines={4}
            spacing="10"
            skeletonHeight="20"
            mb="8px"
          />
          <Center>
            <Skeleton h="48px" w="225px" />
          </Center>
          <Center>
            <Skeleton h="20px" w="175px" />
          </Center>
        </Stack>
      ) : (
        <Box height={{ base: "max-content", md: "auto" }}>
          {!isCancelled && (
            <Box display="flex" flexDirection="column" height="inherit">
              <Text as="h2" marginBottom="32px" fontSize="30px">
                <Bolded>
                  {!isCareNavigatorAppt && isWithin24Hours
                    ? t("cancelAppointment.lateCancelThisAppointmentHeader")
                    : t("cancelAppointment.cancelThisAppointment")}
                </Bolded>
              </Text>
              <Box>
                {!isCareNavigatorAppt && isWithin24Hours ? (
                  <CancellationPolicy
                    defaultCopy={t("cancelAppointment.changesMayResultInLoss")}
                    showDollarIcon={false}
                  />
                ) : (
                  <Box>
                    {!isCareNavigatorAppt && (
                      <Text>
                        {t("cancelAppointment.cancelingAndRescheduling")}
                      </Text>
                    )}
                    <Text>
                      {t("cancelAppointment.reschedulingCancellationFeeText")}
                    </Text>
                  </Box>
                )}
              </Box>

              <form
                id="cancellationReasonForm"
                aria-label="Reason for cancelling form"
              >
                {!isCareNavigatorAppt && isWithin24Hours && (
                  <Box pt={15}>
                    <Text
                      as="label"
                      htmlFor="reason"
                      fontSize="16px"
                      fontWeight="600"
                    >
                      {t("cancelAppointment.cancellationReason.optionTitle")}
                    </Text>
                    <Select
                      data-cy="late-cancel-reason"
                      data-testid="late-cancel-reason"
                      id="selectReason"
                      name="reason"
                      borderColor="background-disabled"
                      colorScheme="platform"
                      marginBottom="25px"
                      {...register("cancellationReason")}
                      onChange={(e) => setIsReasonSelected(e.target.value)}
                    >
                      <option disabled={false} value="">
                        {t("cancelAppointment.cancellationReason.optionTitle")}
                      </option>
                      <option value="I_can_no_longer_make_this_schedule_time">
                        {t(
                          "cancelAppointment.cancellationReason.options.cannotMakeScheduledTime",
                        )}
                      </option>
                      <option value="I_do_not_want_to_see_this_provider_anymore">
                        {t(
                          "cancelAppointment.cancellationReason.options.doNotWantToSeeThisProvider",
                        )}
                      </option>
                      <option value="tech_related">
                        {t(
                          "cancelAppointment.cancellationReason.options.techRelated",
                        )}
                      </option>
                      <option value="work_conflict">
                        {t(
                          "cancelAppointment.cancellationReason.options.workConflict",
                        )}
                      </option>
                      <option value="family_emergency">
                        {t(
                          "cancelAppointment.cancellationReason.options.familyEmergency",
                        )}
                      </option>
                      <option value="sickness">
                        {t(
                          "cancelAppointment.cancellationReason.options.sickness",
                        )}
                      </option>
                      <option value="other">
                        {t(
                          "cancelAppointment.cancellationReason.options.other",
                        )}
                      </option>
                    </Select>
                    <Box
                      display="flex"
                      alignItems="start"
                      bg="#F1EFEA"
                      gap="10px"
                      padding="16px 24px"
                      my="16px"
                      borderRadius="5px"
                    >
                      <Checkbox
                        data-cy="late-cancel-checkbox"
                        isChecked={isPolicyAgreementChecked}
                        onChange={(e) =>
                          setIsPolicyAgreementChecked(e.target.checked)
                        }
                        id="lateCancellationFee"
                        __css={{
                          ".chakra-checkbox__control": {
                            _focusVisible: {
                              borderWidth: "2px",
                            },
                            _focus: {
                              borderWidth: "2px",
                            },
                          },
                        }}
                      />

                      <label htmlFor="lateCancellationFee">
                        {memberPayForNoShow ? (
                          <Trans
                            ns="careVisits"
                            i18nKey="cancelAppointment.memberPayForNoShowCancellationAcknowledgment"
                            values={{
                              mayOrWill: hasGracePeriodMissedAppointments
                                ? t("may")
                                : t("will"),
                              costOfMissedAppointment: memberCostForNoShow,
                            }}
                          />
                        ) : (
                          <Trans
                            ns="careVisits"
                            i18nKey="cancelAppointment.reschedulingCancellationFeeAcknowledgementText"
                            values={{
                              customerType: isPayer
                                ? t("payer")
                                : t("employer"),
                            }}
                          />
                        )}
                      </label>
                    </Box>
                  </Box>
                )}

                <Flex
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  marginTop={
                    !isCareNavigatorAppt && isWithin24Hours ? "" : "32px"
                  }
                  pt={20}
                >
                  <Button
                    type="submit"
                    form="cancellationReasonForm"
                    data-cy="cancel-appointment"
                    isLoading={cancelAppointmentLoading}
                    disabled={
                      cancelAppointmentLoading ||
                      ((!isPolicyAgreementChecked || !isReasonSelected) &&
                        !isCareNavigatorAppt &&
                        isWithin24Hours)
                    }
                    loadingText={t("appointmentDetails.cancelationInProgress")}
                    colorScheme="negative"
                    size="lg"
                    paddingX="80px"
                    variant="low-emphasis"
                    border="2px solid #c44848"
                    {...primaryCtaStyling}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("appointmentDetails.cancelAppointmentLong")}
                  </Button>

                  <Button
                    size="lg"
                    marginTop="20px"
                    variant="no-emphasis"
                    colorScheme="neutral"
                    id="cancel-modal-nevermind-button"
                    onClick={() => {
                      TRACK_EVENT.BUTTON_CLICKED(
                        window.location.pathname,
                        isWithin24Hours ? "Deny Late Cancel" : "Deny Cancel",
                        {
                          spring_doc_id: "cnsched018",
                          location: isWithin24Hours
                            ? modalIds.cancelAppointmentModal
                            : getModalNameForTracking(
                                modalIds.cancelAppointmentModal,
                              ),
                          ...trackingProperties,
                        },
                      );
                      closeCancelAppointmentModal();
                    }}
                  >
                    {t("appointmentDetails.neverMindKeepMyAppointment")}
                  </Button>
                </Flex>
              </form>
            </Box>
          )}
        </Box>
      )}
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  modalOpen: getOr(
    null,
    `modal.${modalIds.cancelAppointmentModal}.open`,
    state,
  ),
});

CancelAppointmentModal.propTypes = {
  addNotification: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  providerId: PropTypes.any,
  time: PropTypes.any,
  timeToAppointment: PropTypes.any,
  name: PropTypes.string,
  appointmentId: PropTypes.string,
  medium: PropTypes.string,
  kind: PropTypes.string,
  isWithin24Hours: PropTypes.bool,
};

const mapDispatchToProps = { addNotification, closeModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelAppointmentModal);
