import React, { FC, useEffect, useRef } from "react";

import { useRecommendedTeenMoments } from "modules/MemberDashboard/Teen/Home/hooks/useRecommendedTeenMoments";
import { useRouter } from "next/router";
import { connect } from "react-redux";

import { modalIds } from "@spring/constants";
import { openModal } from "@spring/smeargle/actions";
import { Button } from "@springcare/sh-component-library";
import {
  Box,
  HStack,
  Heading,
  Skeleton,
  Text,
  useBreakpointValue,
  SimpleGrid,
} from "@chakra-ui/react";
import { VArrowRightIcon } from "@springcare/verdant-icons-react";

import { useTranslation } from "hooks/react-i18next";
import { useInViewportOnce } from "hooks";
import routes from "routes";

import { TeenMomentsCard } from "./TeenMomentsCard";
import type { MomentsSectionProps } from "./types";
import { TRACK_EVENT } from "utils/mixpanel";
import { trackTeenMomentsOnHomePageViewed } from "modules/MemberDashboard/Moments/components/analytics";

export const MomentsSection: FC<
  React.PropsWithChildren<MomentsSectionProps>
> = ({ openModal }) => {
  const router = useRouter();
  const { t } = useTranslation("teenHomepage");
  const exploreMomentsButtonSize = useBreakpointValue(["sm", "lg"]);

  const {
    data: recommendedExercisesData,
    loading: recommendedExercisesLoading,
  } = useRecommendedTeenMoments();

  const handleExploreMomentsClicked = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Explore Moments on Teen home page",
      {
        location: "Teen Carousel Explore Moments Button on Home Page",
        to: routes.MemberMoments.as,
        spring_doc_id: "teenhp005",
      },
    );
    router.push(routes.MemberMoments.as);
  };

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");

  useEffect(() => {
    if (inViewport) {
      trackTeenMomentsOnHomePageViewed();
    }
  }, [inViewport]);

  return (
    <Box mt={[40, 56]} ref={ref}>
      <Box gap={8}>
        <Heading size="heading-medium" mb={16}>
          <Skeleton isLoaded={!recommendedExercisesLoading}>
            {t("momentsSection.heading")}
          </Skeleton>
        </Heading>
        <Skeleton isLoaded={!recommendedExercisesLoading} mb={16}>
          <Text size="body-medium-regular">
            {t("momentsSection.subHeader")}
          </Text>
        </Skeleton>
      </Box>
      <Box
        as="section"
        overflowX="scroll"
        minH="100%"
        _active={{ cursor: "grabbing" }}
      >
        <HStack gap={[24, 24, 8]} mb={16}>
          {recommendedExercisesLoading ? (
            <SimpleGrid overflowX="clip" h="100%">
              <HStack gap={12} mb={16}>
                <Skeleton w="300px" h="108px" />
                <Skeleton w="300px" h="108px" />
                <Skeleton w="300px" h="108px" />
                <Skeleton w="300px" h="108px" />
              </HStack>
            </SimpleGrid>
          ) : (
            recommendedExercisesData &&
            recommendedExercisesData.length &&
            recommendedExercisesData.map((exerciseObj) => (
              <Box h={106} w={290} key={exerciseObj.exercise.id}>
                <TeenMomentsCard
                  title={exerciseObj.exercise.title}
                  contentFormatTypes={exerciseObj.exercise.content_formats}
                  exerciseCategory={exerciseObj.exercise.categories}
                  duration={exerciseObj.exercise.duration}
                  exerciseId={exerciseObj.exercise.id}
                  openModal={() =>
                    openModal(
                      modalIds.momentsStartExerciseModal,
                      exerciseObj.exercise,
                    )
                  }
                />
              </Box>
            ))
          )}
        </HStack>
      </Box>
      <Skeleton
        isLoaded={!recommendedExercisesLoading}
        h={56}
        w={203}
        borderRadius={8}
      >
        <Button
          variant="low-emphasis"
          size={exploreMomentsButtonSize}
          colorScheme="base"
          icon={VArrowRightIcon}
          onClick={handleExploreMomentsClicked}
          width={["327px", "203px"]}
          height={[40, 56]}
          mt={16}
        >
          <Text size="body-medium-strong">
            {t("momentsSection.buttonLabel")}
          </Text>
        </Button>
      </Skeleton>
    </Box>
  );
};

export default connect(null, { openModal })(MomentsSection);
