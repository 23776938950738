//

import { gql } from "@apollo/client";

const getMemberConsent = gql`
  query getMemberConsent($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        hipaa_privacy_policy
        informed_consent
      }
    }
  }
`;

export default getMemberConsent;
