// 





export const ADD_NOTIFICATION = 'notifications/ADD_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'notifications/DISMISS_NOTIFICATION';
export const MOUNT_NOTIFICATION = 'notifications/MOUNT_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'notifications/REMOVE_NOTIFICATION';
