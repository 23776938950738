import { useQuery } from "@apollo/client";
import getExercise from "operations/queries/exercise/getExercise";

// These three exercises are the 2nd, 3rd and 4th most used Moments exercises, based on mobile usage data.
// The top exercise, "Deep Breathing," is opened by the "Start a Moment" button, which is rendered
// immediately above MomentsForYou.

export const useFilteredRecommendedExerciseData = () => {
  const {
    data: goodNightsSleepData,
    loading: goodNightsSleepLoading,
    error: goodNightsSleepError,
  } = useQuery(getExercise, {
    variables: {
      id: "629f99c8-5368-43ac-9e29-04d99b21f255", // "A Good Night's Sleep"
    },
  });

  const {
    data: automaticThoughtsData,
    loading: automaticThoughtsLoading,
    error: automaticThoughtsError,
  } = useQuery(getExercise, {
    variables: {
      id: "0dd272be-f2dd-4083-8975-244e9f24f746", // "Automatic Thoughts"
    },
  });

  const {
    data: askingData,
    loading: askingLoading,
    error: askingError,
  } = useQuery(getExercise, {
    variables: {
      id: "fb5392b7-5620-4a49-93a8-213ebdac7cb1", // "Asking for What You Need"
    },
  });
  const filteredExercisesData = [
    goodNightsSleepData,
    automaticThoughtsData,
    askingData,
  ].filter((exercise) => exercise !== undefined);
  const isLoading =
    goodNightsSleepLoading || automaticThoughtsLoading || askingLoading;
  const filteredExercisesErrors = [
    goodNightsSleepError,
    automaticThoughtsError,
    askingError,
  ].filter((error) => error !== undefined);
  return {
    data: filteredExercisesData,
    loading: isLoading,
    error: filteredExercisesErrors,
  };
};
