//

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import styles from "components/templates/MemberDashboard/Icons/styles.module.scss";

const BarChart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="24"
    viewBox="0 0 28 24"
  >
    <path
      className={classnames(styles.icon, {
        [styles.active]: props.active,
      })}
      fillRule="nonzero"
      d="M1.5 0C.674 0 0 .674 0 1.5v21c0 .826.674 1.5 1.5 1.5h25c.826 0 1.5-.674 1.5-1.5v-21c0-.826-.674-1.5-1.5-1.5h-25zm0 1h25c.275 0 .5.225.5.5v21c0 .275-.225.5-.5.5h-25a.501.501 0 0 1-.5-.5V6h20.5a.5.5 0 1 0 0-1H1V1.5c0-.275.225-.5.5-.5zM3 2a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1A.5.5 0 0 0 3 2zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zM8 2a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1A.5.5 0 0 0 8 2zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zM13 2a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1A.5.5 0 0 0 13 2zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zM18 2a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1A.5.5 0 0 0 18 2zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zM23 2a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1A.5.5 0 0 0 23 2zm2.5 0a.5.5 0 0 0-.5.5v1a.5.5 0 1 0 1 0v-1a.5.5 0 0 0-.5-.5zm-2 3a.5.5 0 1 0 0 1h2a.5.5 0 1 0 0-1h-2zm-10 3a.5.5 0 0 0-.5.5V16h-2v-1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V20H4.5a.5.5 0 1 0 0 1h18a.5.5 0 1 0 0-1H20v-8.5a.5.5 0 0 0-.5-.5H17V8.5a.5.5 0 0 0-.5-.5h-3zm.5 1h2v11h-2V9zm3 3h2v8h-2v-8zm-9 3h2v5H8v-5zm3 2h2v3h-2v-3z"
    />
  </svg>
);

BarChart.propTypes = {
  active: PropTypes.any,
};

export default BarChart;
