//

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Section,
  Modal,
  Subhead,
  InfoSection,
  SpringPeople,
  SpringTherapy,
  SpringDigitalCbt,
} from "@spring/smeargle";
import { modalIds } from "@spring/constants";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const CheckInDetailsModal = () => {
  const { t } = useTranslation(["memberDashboard", "common"]);
  const { link } = useSelector((state) => ({
    link: state?.modal?.[modalIds.checkInDetailsModal]?.data?.link,
  }));
  const details = [
    {
      title: t("checkinDetails.usefulTitle"),
      content: t("checkinDetails.usefulContent"),
      icon: <SpringTherapy />,
    },
    {
      title: t("checkinDetails.smartTitle"),
      content: t("checkinDetails.smartContent"),
      icon: <SpringPeople />,
    },
    {
      title: t("checkinDetails.shortTitle"),
      content: t("checkinDetails.shortContent"),
      icon: <SpringDigitalCbt />,
    },
  ];
  const focusElementId = "initial-focus-target";
  const focusElementSelector = "#" + focusElementId;
  return (
    <Modal
      size="xlg"
      id={modalIds.checkInDetailsModal}
      initialFocusElementId={focusElementSelector}
    >
      <div className={styles.default} id={focusElementId}>
        <Subhead status="primary" center noMargin bold>
          <h1>{t("checkinTodo.subTitle", { ns: "common" })}</h1>
        </Subhead>
        <InfoSection details={details} />
        <Section>
          <div className={styles.button}>{link}</div>
        </Section>
      </div>
    </Modal>
  );
};

CheckInDetailsModal.propTypes = {
  link: PropTypes.any,
};

export default CheckInDetailsModal;
