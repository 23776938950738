import React from "react";
import { formatMemberExactAge } from "utils/displayHelpers";

import {
  VDependentAvatar1DuotoneSpot,
  VDependentAvatar2DuotoneSpot,
  VDependentAvatar3DuotoneSpot,
  VDependentAvatar4DuotoneSpot,
} from "@springcare/verdant-icons-react";

export const RenderChildAvatar = ({ dependent, avatarWidth, avatarHeight }) => {
  const age = formatMemberExactAge(dependent?.date_of_birth);
  if (age < 6)
    return (
      <VDependentAvatar1DuotoneSpot
        data-testid="under-six-avatar"
        aria-hidden={true}
        width={avatarWidth}
        height={avatarHeight}
      />
    );
  if (age >= 6 && age < 13)
    return (
      <VDependentAvatar2DuotoneSpot
        data-testid="under-thirteen-avatar"
        aria-hidden={true}
        width={avatarWidth}
        height={avatarHeight}
      />
    );
  if (age >= 13 && age < 18)
    return (
      <VDependentAvatar4DuotoneSpot
        data-testid="under-eighteen-avatar"
        aria-hidden={true}
        width={avatarWidth}
        height={avatarHeight}
      />
    );
  return (
    <VDependentAvatar3DuotoneSpot
      data-testid="over-eighteen-avatar"
      aria-hidden={true}
      width={avatarWidth}
      height={avatarHeight}
    />
  );
};
