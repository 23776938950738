// 

import React from 'react';
import classnames from 'classnames';

import { Icon } from '../../atoms';

import styles from './styles.module.scss';
import { clickableDivProps } from '../../../lib/accessibility_helpers';



const LinkButton = ({ uppercase, bold, small, letterSpacing, warning, inheritColor, text, icon, dataCy, ariaLabel, inlineTextLink, ...props }) => (
	<span
		{...clickableDivProps({ onClick: props.onClick })}
		aria-label={ariaLabel || text}		
		{...props}
		className={classnames(styles.default, {
			[styles.uppercase]      : uppercase,
			[styles.bold]           : bold,
			[styles.small]          : small,
			[styles.letterSpacing]  : letterSpacing,
			[styles.warning]        : warning,
			[styles.inheritColor]   : inheritColor,
			[styles.inlineTextLink] : inlineTextLink,
		})}
		data-cy={dataCy}
		onClick={props.onClick}
	>
		{text}
		{icon ? <>{'  '}<Icon type={icon}/></> : null}
	</span>
);

LinkButton.defaultProps = {
	uppercase     : false,
	bold          : true,
	small         : false,
	letterSpacing : false,
};

export default LinkButton;
