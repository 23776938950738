import React from "react";

const T2HomePageBackgroundSvg = ({ height }) => (
  <svg
    width="4000"
    height={height}
    viewBox="0 0 4000 602"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={true}
  >
    <rect width="100%" height={height} fill="#FBFAF9" />
  </svg>
);

export default T2HomePageBackgroundSvg;
