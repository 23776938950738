import { gql } from "@apollo/client";

import { goal } from "operations/fragments/goal";
import { experienceState } from "operations/fragments/member";

const getMemberGoals = gql`
  query getMemberGoals($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        experience_state {
          ...experienceState
        }
        tracked_goals {
          id
          goal {
            ...goal
          }
          status
          tracked
        }
      }
    }
  }
  ${goal}
  ${experienceState}
`;

export default getMemberGoals;
