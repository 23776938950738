import { useQuery } from "@apollo/client";

import getExercises from "operations/queries/exercise/getExercises";

export const useParentingMoments = () => {
  const { data, loading, error } = useQuery(getExercises, {
    variables: {
      category: "PARENTING",
      limit: 3,
    },
  });

  return {
    parentingMomentsData: data,
    parentingMomentsLoading: loading,
    parentingMomentsError: error,
  };
};
