// 

import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import FocusTrap from 'focus-trap-react';

import Icon from '../../atoms/Icon';

import styles from './styles.module.scss';

import { dismissNotification, mountNotification, removeNotification } from '../../../actions/notifications/actions';


class Notification extends Component {
	componentDidMount() {
		setTimeout(() => { // to ensure the proper height is calculated
			this.forceUpdate();
			this.props.mount(this.props.timestamp);
		}, 50);

		// Auto-dismiss toast after 10 seconds
		setTimeout(() => {
			this.props.dismiss(this.props.timestamp);
			this.props.remove(this.props.timestamp);
		}, 10000);
	}

	componentWillUnmount() {
		if (!this.props.out) {
			this.clearNotification();
		}
	}

	clearNotification = () => {
		this.props.dismiss(this.props.timestamp);
		window.setTimeout(() => {
			this.props.remove(this.props.timestamp);
		}, 1500);
	}

	notification = React.createRef();

	get heightStyles() {
		if (!this.props.mounted) {
			return { };
		}

		const maxHeight = this.notification ? this.notification.offsetHeight : 0;

		return this.props.out ? { maxHeight: 0 } : { maxHeight };
	}

	get icon() {
		switch (this.props.color) {
			case 'error':
				return 	(
					<div className={styles.leftIcon}>
						<Icon type="alert-outline" />
					</div>
				);

			case 'success':
			case 'info':
			case 'warn':
			default:
				return null;
		}
	}

	render() {
		return (
			<FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
				<div
					style={this.heightStyles}
					className={classnames(styles.notification, styles[this.props.color], {
						[styles.mounted] : this.props.mounted,
						[styles.out]     : this.props.out,
					})}
					>
					<div
						role="alert"
						ref={(notification) => (this.notification = notification)}
						className={styles.content}
					>
						<div className={styles.textContainer}>
							{this.icon}
							<div
								className={styles.text}
								data-cy="notification"
							>
								{this.props.text}
							</div>
						</div>
						<div
							data-cy="close"
							className={styles.closeIcon}
							tabIndex="0"
						>
							<Icon type="close" onClick={this.clearNotification} />
						</div>
					</div>
				</div>
			</FocusTrap>
		);
	}
}


class Notifications extends Component {
	get notifications() {
		if (!this.props.notifications[0]) {
			return null;
		}

		return this.props.notifications.map((n) => (
			<Notification
				key={n.timestamp}
				color={n.color}
				mounted={n.mounted}
				out={n.out}
				text={n.text}
				timestamp={n.timestamp}
				dismiss={this.props.dismissNotification}
				mount={this.props.mountNotification}
				remove={this.props.removeNotification}
			/>
		));
	}

	get open() {
		return this.props.notifications.filter((n) => (
			!n.out && n.mounted
		)).length;
	}

	render() {
		return (
			<section
				id="smeargle-notification-container"
				className={classnames(styles.notificationWrapper, {
					[styles.empty]: this.open === 0,
				})}
			>
				{this.notifications}
			</section>
		);
	}
}

export default connect(
	(state) => ({
		notifications: state.notifications,
	}),
	{ dismissNotification, mountNotification, removeNotification }
)(Notifications);
