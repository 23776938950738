import React from "react";

const LanguageIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99997 9C3.99997 6.23858 6.23855 4 8.99997 4H17C17.5523 4 18 3.55228 18 3C18 2.44772 17.5523 2 17 2H8.99997C5.13398 2 1.99997 5.13401 1.99997 9V15.5654C1.99997 17.353 4.16606 18.2427 5.42253 16.9713L6.3586 16.024C7.79526 18.1584 10.2336 19.5625 13 19.5625H16.0833L18.8 21.6C19.406 22.0545 20.2168 22.1276 20.8944 21.7889C21.572 21.4501 22 20.7575 22 20V13C22 8.58172 18.4182 5 14 5H8.99997C6.79083 5 4.99997 6.79086 4.99997 9V11.5625C4.99997 12.4599 5.14774 13.3228 5.42027 14.1282L3.99997 15.5654V9ZM20 13V17.5V20L18 18.5L16.75 17.5625H13C9.68626 17.5625 6.99997 14.8762 6.99997 11.5625V9C6.99997 7.89543 7.8954 7 8.99997 7H14C17.3137 7 20 9.68629 20 13ZM10 9.00003C9.44775 9.00003 9.00003 9.44775 9.00003 10C9.00003 10.5523 9.44775 11 10 11H10.1584C10.4209 12.0805 10.9481 13.0507 11.6649 13.8426C11.2849 13.9454 10.8859 14 10.4751 14H10C9.44775 14 9.00003 14.4477 9.00003 15C9.00003 15.5523 9.44775 16 10 16H10.4751C11.5623 16 12.5923 15.734 13.5 15.2609C14.4078 15.734 15.4378 16 16.525 16H17C17.5523 16 18 15.5523 18 15C18 14.4477 17.5523 14 17 14H16.525C16.1142 14 15.7152 13.9454 15.3352 13.8426C16.0519 13.0507 16.5792 12.0805 16.8416 11H17C17.5523 11 18 10.5523 18 10C18 9.44775 17.5523 9.00003 17 9.00003H14.5C14.5 8.44775 14.0523 8.00003 13.5 8.00003C12.9477 8.00003 12.5 8.44775 12.5 9.00003H10ZM13.5 12.8497C12.9419 12.3535 12.5056 11.7208 12.2446 11H13.5H14.7555C14.4945 11.7208 14.0581 12.3535 13.5 12.8497Z"
      fill="#1A202C"
    />
  </svg>
);

export default LanguageIcon;
