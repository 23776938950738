import React from "react";

const Testimonial = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#058261" fill-opacity="0.12" />
    <path
      d="M35.933 17.688C34.324 18.8827 33.0726 20.0987 32.1788 21.336C31.3296 22.5307 30.905 23.6613 30.905 24.728C31.0838 24.6427 31.3073 24.6 31.5754 24.6C31.8883 24.5573 32.1564 24.536 32.3799 24.536C33.4078 24.536 34.257 24.9413 34.9274 25.752C35.6425 26.52 36 27.48 36 28.632C36 30.04 35.5084 31.2133 34.5251 32.152C33.5419 33.0907 32.3128 33.56 30.838 33.56C29.2737 33.56 28.0447 33.0267 27.1508 31.96C26.257 30.8507 25.8101 29.3787 25.8101 27.544C25.8101 25.368 26.5028 23.2133 27.8883 21.08C29.2737 18.9467 31.3073 16.92 33.9888 15L35.933 17.688ZM22.1229 17.688C20.514 18.8827 19.2626 20.0987 18.3687 21.336C17.5196 22.5307 17.095 23.6613 17.095 24.728C17.2737 24.6427 17.4972 24.6 17.7654 24.6C18.0782 24.5573 18.3464 24.536 18.5698 24.536C19.5978 24.536 20.4469 24.9413 21.1173 25.752C21.8324 26.52 22.1899 27.48 22.1899 28.632C22.1899 30.04 21.6983 31.2133 20.7151 32.152C19.7318 33.0907 18.5028 33.56 17.0279 33.56C15.4637 33.56 14.2346 33.0267 13.3408 31.96C12.4469 30.8507 12 29.3787 12 27.544C12 25.368 12.6927 23.2133 14.0782 21.08C15.4637 18.9467 17.4972 16.92 20.1788 15L22.1229 17.688Z"
      fill="#045642"
    />
  </svg>
);

export default Testimonial;
