// 

import React from 'react';


const BackgroundImage = (props) => (
	<div
		style={{
			height             : props.height,
			width              : props.width,
			backgroundImage    : props.backgroundImage,
			backgroundColor    : props.backgroundColor,
			backgroundRepeat   : props.backgroundRepeat,
			backgroundPosition : props.backgroundPosition,
			backgroundSize     : props.backgroundSize,
			position           : props.absolute ? 'absolute' : 'relative',
		}}
	/>
);

BackgroundImage.defaultProps = {
	height           : '100%',
	width            : '100%',
	backgroundRepeat : 'no-repeat',
};

export default BackgroundImage;

