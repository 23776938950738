import React, { useEffect } from "react";
import PropTypes from "prop-types";
import envUtils from "utils/environment/";

// HACK: This is to prevent retently from loading for consumer reports.
const CONSUMER_REPORTS_ID = "34b26005-e4fa-4cf3-ba08-f88ada3e38e0";

const Retently = (props) => {
  // Retently disabled for Consumer Reports and Trial Customers
  if (
    props.user.customer?.id === CONSUMER_REPORTS_ID ||
    props.user.customer?.trial
  ) {
    return null;
  }

  useEffect(() => {
    if (
      !document.getElementById("retently-jssdk") &&
      envUtils.retentlyEnabled
    ) {
      const script = document.createElement("script");
      script.id = "retently-jssdk";
      script.src =
        "https://cdn.retently.com/public/components/embed/sdk.min.js";
      document.body.appendChild(script);
    }
  });

  return !envUtils.retentlyEnabled ? null : (
    <div
      id="retently-survey-embed"
      data-href="https://app.retently.com/api/remote/tracking/5e7e2ee492910a433a3a35b7"
      data-rel="dialog"
      data-campaign="regular"
      data-email={props.user.email}
      data-firstname={props.user.first_name}
      data-lastname={props.user.last_name}
      data-company={props.user.customer?.name}
      data-tags={process.env.RETENTLY_ENV_TAG}
    />
  );
};

Retently.propTypes = {
  user: PropTypes.object,
};

export default Retently;
