import routes from "routes/MemberDashboardRoutes";

export const formatDuration = (durationInMinutes, t) => {
  if (durationInMinutes >= 90) {
    return t("contentDurationInMinutes.ninetyMinutes");
  } else if (durationInMinutes >= 55) {
    return t("contentDurationInMinutes.oneHour");
  } else if (durationInMinutes == 1) {
    return t("contentDurationInMinutes.oneMinute");
  } else {
    return `${durationInMinutes} ${t("contentDurationInMinutes.minutes")}`;
  }
};

export const EMPLOYER_SERVICES_ROUTES = [
  routes.WorkplaceGeneralConsultations.as,
  routes.WorkplaceCriticalIncidents.as,
  routes.WorkplaceCrisisSupport.as,
];
