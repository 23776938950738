import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useRouter } from "next/router";
import { getOr } from "lodash/fp";
import { DateTime } from "luxon";
import { LoadingCircle, Subtitle, Bolded, Content } from "@spring/smeargle";
import { Box } from "design-system/components";
import { CancelAppointmentModal } from "components/modals";
import { isCurrentTimeWithin24HoursOfAppointment } from "utils/time/time";
import { isPastAppointment } from "utils/schedulingHelpers";
import Meowth from "@spring/meowth";

import { AppointmentMedium } from "@spring/constants";

import {
  CancellationDetails,
  AppointmentTips,
  AppointmentDetails,
  AppointmentLocation,
} from "./components";

import { getFirstError } from "utils/apollo/errorHandler";
import { isMinor } from "utils/memberHelpers";
import { getAppointment } from "operations/queries/appointment";
import { getCareProvider } from "operations/queries/careProvider";
import { getMemberInfo } from "operations/queries/member";
import Router from "next/router";
import routes from "routes/MemberDashboardRoutes";
import { isGlobalUser } from "utils/global";
import { returnAllSessionCounts } from "modules/shared/utils";
export const getTimeToAppointment = (appointmentStartTime) => {
  const appointmentTime = DateTime.fromISO(appointmentStartTime);
  const currentTime = DateTime.fromISO(new Date().toISOString());
  const appointmetTimeDifference = appointmentTime.diff(currentTime, "hours");
  return parseFloat(appointmetTimeDifference.hours).toFixed(2);
};

export const CareVisitDetails = ({ showCancelledSection = true }) => {
  const router = useRouter();
  const {
    data: appointmentData,
    loading: appointmentLoading,
    error: appointmentError,
  } = useQuery(getAppointment, {
    variables: {
      id: router && router.query.id,
    },
    skip: !router,
  });

  const {
    data: memberData,
    loading: memberLoading,
    error: memberError,
  } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });
  // This link replaces Zoom links for virtual meetings *only* -- very important
  const BASE_URL = `${window.location.origin}/session`;
  const appointmentLocation =
    appointmentData?.appointment?.medium === AppointmentMedium.Video
      ? `${BASE_URL}/${appointmentData?.appointment?.id}/${memberData?.user?.id}`
      : appointmentData?.appointment?.location;

  const isGlobal = isGlobalUser(
    getOr("", "user.member.postal_address.country", memberData),
  );
  const customerId = getOr("", "user.member.cohort.customer.id", memberData);

  const isAMinor = () => {
    return isMinor(getOr({}, "user.member", memberData));
  };

  const {
    data: providerData,
    loading: providerLoading,
    error: providerError,
  } = useQuery(getCareProvider, {
    variables: {
      id: appointmentData?.appointment.provider_id,
    },
    skip: !appointmentData,
  });

  const loading = appointmentLoading || memberLoading || providerLoading;
  const error = appointmentError || memberError || providerError;
  const providerEmail = getOr("", "care_provider.email", providerData);

  const {
    coveredTherapy: therapyCoveredCount,
    usedTherapy: therapyUsedCount,
    remainingTherapy: therapyRemainingCount,
    coveredCoaching: coachingCoveredCount,
    usedCoaching: coachingUsedCount,
    remainingCoaching: coachingRemainingCount,
  } = returnAllSessionCounts(memberData?.user?.member);
  const memberTherapyDetails = {
    therapyCoveredCount,
    therapyUsedCount,
    therapyRemainingCount,
    therapistEmail: providerEmail,
    isGlobalMemberWithNoVisitsRemaining:
      isGlobal && therapyRemainingCount === 0 && therapyCoveredCount >= 0,
  };

  const memberCoachingDetails = {
    coachingCoveredCount,
    coachingUsedCount,
    coachingRemainingCount,
    coachEmail: providerEmail,
    isGlobalMemberWithNoVisitsRemaining:
      isGlobal && coachingUsedCount === 0 && coachingCoveredCount >= 0,
  };

  const isAppointmentCancelled =
    appointmentData?.appointment?.cancelled_for_member ||
    appointmentData?.appointment.status === "Cancelled";
  const [shouldShowCancelledSection, setShouldShowCancelledSection] =
    useState(showCancelledSection);

  const currentTime = DateTime.now();
  const appointmentTime = DateTime.fromISO(
    appointmentData?.appointment?.start_at,
  );
  const isWithin24Hours = isCurrentTimeWithin24HoursOfAppointment(
    currentTime,
    appointmentTime,
  );

  if (
    !error &&
    !appointmentLoading &&
    isPastAppointment(appointmentData?.appointment) &&
    !isAppointmentCancelled
  ) {
    Router.replace(routes.MemberCareVisits.to, routes.MemberCareVisits.as);
  }

  const appointmentId = appointmentData?.appointment?.id;

  return (
    <>
      {loading && (
        <Box role="alert" aria-busy={loading}>
          <LoadingCircle />
        </Box>
      )}
      {!loading && (
        <>
          {error && (
            <>
              <Subtitle>Sorry! This appointment cannot be found.</Subtitle>
              <Bolded>Error: </Bolded>
              <Content>{getFirstError(error)}</Content>
            </>
          )}
          {!error && (
            <div role="alert" aria-busy={false}>
              {(!isAppointmentCancelled || !shouldShowCancelledSection) && (
                <>
                  <AppointmentDetails
                    appointmentId={appointmentData.appointment.id}
                    isAppointmentConfirmed={
                      !!appointmentData.appointment.confirmed_at
                    }
                    provider={providerData.care_provider}
                    time={appointmentData.appointment.start_at}
                    duration={appointmentData.appointment.duration}
                    medium={appointmentData.appointment.medium}
                    kind={appointmentData.appointment.kind}
                    appointmentLocation={appointmentLocation}
                    sessionType={appointmentData.appointment.session_type}
                    cancelled={
                      isAppointmentCancelled && shouldShowCancelledSection
                    }
                    isAMinor={isAMinor()}
                    isCoveredForMember={
                      appointmentData.appointment.is_covered_for_member
                    }
                    memberFirstName={memberData.user.first_name}
                    memberId={memberData.user.member.id}
                    isGlobalMember={isGlobalUser(
                      memberData?.user?.member?.postal_address?.country,
                    )}
                    reasonForScheduling={
                      appointmentData.appointment.reason_for_scheduling
                    }
                    isInHouseVideo={
                      appointmentData.appointment.is_in_house_video
                    }
                  />
                  <AppointmentLocation
                    medium={appointmentData.appointment.medium}
                    appointmentLocation={appointmentLocation}
                    onSite={providerData.care_provider.on_site}
                    appointmentId={appointmentId}
                  />
                  <AppointmentTips
                    medium={appointmentData.appointment.medium}
                    instructions={providerData.care_provider.instructions}
                    isAMinor={isAMinor()}
                    kind={appointmentData.appointment.kind}
                    isInHouseVideo={
                      appointmentData.appointment.is_in_house_video
                    }
                  />
                </>
              )}
              {isAppointmentCancelled && shouldShowCancelledSection && (
                <CancellationDetails
                  customerId={customerId}
                  provider={providerData.care_provider}
                  time={appointmentData.appointment.start_at}
                  medium={appointmentData.appointment.medium}
                  kind={appointmentData.appointment.kind}
                  isCancelled={
                    appointmentData.appointment.status === "Cancelled"
                  }
                  isAMinor={isAMinor()}
                  memberFirstName={memberData.user.first_name}
                  memberHasInPersonSupport={
                    memberData.cohort?.in_person_supported
                  }
                  memberCountry={memberData?.postal_address?.country}
                  isWithin24Hours={isWithin24Hours}
                  appointmentLocation={appointmentLocation}
                  memberTherapyDetails={memberTherapyDetails}
                  memberCoachingDetails={memberCoachingDetails}
                />
              )}
            </div>
          )}
        </>
      )}
      <CancelAppointmentModal
        isWithin24Hours={isWithin24Hours}
        providerId={appointmentData?.appointment?.provider_id}
        time={appointmentTime}
        appointmentId={appointmentData?.appointment?.id}
        medium={appointmentData?.appointment?.medium}
        kind={appointmentData?.appointment?.kind}
        name={providerData?.care_provider?.name}
        timeToAppointment={getTimeToAppointment(appointmentTime)}
        setShouldShowCancelledSection={setShouldShowCancelledSection}
      />
    </>
  );
};
