import { useQuery } from "@apollo/client";
import {
  getChannelPartner,
  ChannelPartnerData,
} from "operations/queries/customer";
import Meowth from "@spring/meowth";
import ChannelPartnerSubType from "@spring/constants/src/enums/channelPartnerSubType";

export function useChannelPartnerReseller() {
  const {
    data: channelPartnerData,
    loading: channelPartnerLoading,
  }: { data: ChannelPartnerData; loading: boolean } = useQuery(
    getChannelPartner,
    { ...Meowth.apolloOptionsUserId() },
  );

  const isReseller =
    channelPartnerData?.user?.customer?.channel_partner
      ?.channel_partner_sub_type == ChannelPartnerSubType.Reseller;

  return {
    isReseller,
    channelPartnerData,
    channelPartnerLoading,
  };
}
