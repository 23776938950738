import { Box, Flex, Heading } from "@chakra-ui/react";
import AssessmentPrompt from "./AssessmentPrompt";

export const TeenInitialAssessment = ({
  memberInfo,
  handleAnswerSelection,
  formData,
  currentPrompt,
  questionnaireKind,
  teenAssessmentData,
}) => {
  const { first_name } = memberInfo;
  const questionTitle = `${first_name}, ${currentPrompt.newTitle.toLowerCase()}`; // should be the same for all prompts

  return (
    <Flex
      direction="column"
      align="center"
      data-testid="teen-initial-assessment"
    >
      <Box pt="40px" w={["100vw", 450, 605]} paddingX={[24, 0]}>
        <Heading size="heading-medium" pb="10px" aria-live="assertive">
          {questionTitle}
        </Heading>
        <AssessmentPrompt
          prompt={currentPrompt}
          handleAnswerSelection={handleAnswerSelection}
          answers={formData}
          questionnaireKind={questionnaireKind}
          teenAssessmentData={teenAssessmentData}
        />
      </Box>
    </Flex>
  );
};

export default TeenInitialAssessment;
