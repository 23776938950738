import { DateTime } from "luxon";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isGlobalUser } from "utils/global";

type MemberPortalReduxState = {
  global: {
    country?: string;
  };
};

export const useSeasonalityWarningConfig = (appointmentTime) => {
  const country = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.country,
  );
  const isAGlobalMember = isGlobalUser(country);

  const selectedDateTime = DateTime.fromISO(appointmentTime);
  const isWeekend = selectedDateTime.weekday > 5;

  // Between the hours of 10pm and 6am.
  const isOutsideOfNormalHours =
    selectedDateTime.hour < 6 || selectedDateTime.hour >= 22;

  const nextWeekDay = (weekday, date) => {
    return date.plus({ days: (7 - date.weekday + weekday) % 7 });
  };

  const getHolidayDates = (year: number) => {
    return [
      DateTime.fromObject({ year, month: 1, day: 1 }), // New Year’s Day
      nextWeekDay(
        1,
        DateTime.fromObject({ year, month: 1, day: 1 }).plus({ weeks: 2 }),
      ), // Birthday of Martin Luther King, Jr.
      nextWeekDay(
        1,
        DateTime.fromObject({ year, month: 2, day: 1 }).plus({ weeks: 2 }),
      ), // Washington’s Birthday
      DateTime.fromObject({ year, month: 5, day: 31 }).set({ weekday: 1 }), // Memorial Day (last Monday in May)
      DateTime.fromObject({ year, month: 6, day: 19 }), // Juneteenth National Independence Day
      DateTime.fromObject({ year, month: 7, day: 4 }), // Independence Day
      nextWeekDay(1, DateTime.fromObject({ year, month: 9, day: 1 })), // Labor Day (first Monday in September)
      nextWeekDay(
        1,
        DateTime.fromObject({ year, month: 10, day: 1 }).plus({ weeks: 1 }),
      ), // Columbus Day (second Monday in October)
      DateTime.fromObject({ year, month: 11, day: 11 }), // Veterans Day
      nextWeekDay(
        4,
        DateTime.fromObject({ year, month: 11, day: 1 }).plus({ weeks: 3 }),
      ), // Thanksgiving Day (fourth Thursday in November)
      DateTime.fromObject({ year, month: 12, day: 25 }), // Christmas Day
    ];
  };

  const isDateHoliday = (date) => {
    const currentYear = selectedDateTime.year;
    if (!currentYear) return false;
    const holidays = getHolidayDates(currentYear);
    return holidays.some((holiday) =>
      DateTime.fromISO(holiday).hasSame(date, "day"),
    );
  };

  const isHoliday = useMemo(() => {
    return !isAGlobalMember && isDateHoliday(selectedDateTime);
  }, [country, selectedDateTime]);

  return {
    isWeekend,
    isOutsideOfNormalHours,
    isHoliday,
  };
};
