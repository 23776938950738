// 

import React from 'react';
import classnames from 'classnames';


import styles from './styles.module.scss';


const Bolded = (props) => (
	<strong
		className={classnames(styles.bolded)}
	>
		{props.children}
	</strong>
);

export default Bolded;
