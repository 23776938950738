import COLORS from '../../../../../constants/colors';

import React from 'react';
import PropTypes from 'prop-types';

const MedicationManagerIcon = ({ color, width, height }) => (
	<svg width={ width || '24' } height={ height || '24' } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill={color || COLORS.PRIMARY_500} fillRule="evenodd" clipRule="evenodd" d="M5 0C3.89543 0 3 0.895431 3 2V4C3 5.10457 3.89543 6 5 6V10V14V18V23C5 23.5523 5.44772 24 6 24H18C18.5523 24 19 23.5523 19 23V6C20.1046 6 21 5.10457 21 4V2C21 0.895431 20.1046 0 19 0H16H12H8H5ZM7 19H11C11.5523 19 12 18.5523 12 18C12 17.4477 11.5523 17 11 17H7V15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13H7V11H11C11.5523 11 12 10.5523 12 10C12 9.44771 11.5523 9 11 9H7V6H8H12H16H17V22H7V19ZM7 4H6H5V2H7V4ZM11 4H9V2H11V4ZM15 4H13V2H15V4ZM18 4H17V2H19V4H18Z" />
	</svg>
);

MedicationManagerIcon.propTypes = {
	color: PropTypes.string,

	width  : PropTypes.string,
	height : PropTypes.string,
};

export default MedicationManagerIcon;
