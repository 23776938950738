/**
 * Enums for Tag colors.
 */

const TagColors = Object.freeze({
	Pear      : 'pear',
	Primary   : 'primary',
	Accent    : 'accent',
	Secondary : 'secondary',
	Tertiary  : 'tertiary',
	Alternate : 'alternate',
	Gray      : 'gray',
});

module.exports = TagColors;
