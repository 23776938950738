// 







export const OPEN_MODAL = 'modal/OPEN_MODAL';
export const CLOSE_MODAL = 'modal/CLOSE_MODAL';

export const ADD_OVERLAY = 'modal/ADD_OVERLAY';
export const REMOVE_OVERLAY_BY_ID = 'modal/REMOVE_OVERLAY_BY_ID';
export const REMOVE_FIRST_OVERLAY = 'modal/REMOVE_FIRST_OVERLAY';

