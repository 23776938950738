//

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get, getOr, flow } from 'lodash/fp';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { setIn } from '@spring/immutability';

import { Button } from '../../buttons';
import * as Buttons from '../../buttons';
import FlexRow from '../../layout/FlexRow';
import FlexCol from '../../layout/FlexCol';
import Section from '../../layout/Section';

import styles from './styles.module.scss';

import { initializeForm, clearForm, setFormValidation } from '../../../actions/form';





class Form extends PureComponent {
	static defaultProps = {
		actions        : [],
		data           : {},
		isValid        : false,
		theme          : 'simple',
		schema         : {},
		submitText     : 'Submit',
		enctype        : 'application/x-www-form-urlencoded',
		submitDebounce : 2500,
		autocomplete   : true,
	};

	getFieldsMeta(fields) {
		let meta = {};
		for (let field in fields) {
			let children = {};

			if (fields[field].fields) {
				children = this.getFieldsMeta(fields[field].fields);
			}

			const required = get(`${field}.exclusiveTests.required`, fields) || false;
			const type = get(`${field}._type`, fields);

			meta[field] = {
				dirty      : false,
				__required : required,
				__type     : type,
				...children,
			};
		}

		return meta;
	}

	componentDidMount() {
		if (!this.props.initialized) {
			const { fields } = this.props.schema;

			const meta = this.getFieldsMeta(fields);

			this.props.initializeForm(
				this.props.formKey,
				this.props.schema,
				this.props.initialData,
				meta,
				this.props.theme
			);

			this.validate(this.props.initialData);
		}

		if (
			this.props.initialized &&
			get('props.form.data', this)
		) {
			if (this.props.onMount) { // Do something to preserved forms when they re-mount
				const data = Object.assign({}, this.props.form.data); // clone the data
				this.props.onMount(data);
			}
		}
	}

	componentWillUnmount() {
		if (!this.props.preserve) {
			this.props.clearForm(this.props.formKey);
		}
	}

	componentDidUpdate(prevProps) {
		if (
			(
				this.props.initialized &&
				prevProps.form.meta.updatedAt &&
				this.props.form.meta.updatedAt &&
				prevProps.form.meta.updatedAt !== this.props.form.meta.updatedAt
			) || (
				prevProps.schema !== this.props.schema
			)
		) {
			this.validate(this.props.form.data);
			// OnChange should be used for things like live-updates
			// or submitting data on every change.
			if (this.props.onChange) {
				const data = Object.assign({}, this.props.form.data); // clone the data
				this.props.onChange(data);
			}
		}
	}

	validate = (data) => {
		// If there's no schema, set validation to always true
		if (!this.props.schema.fields) {
			this.props.setFormValidation(this.props.formKey, {}, true);
		}

		if (this.props.schema && this.props.schema.validate) {
			const toValidate = data || this.props.form.data;

			this.props.schema.validate(toValidate, { abortEarly: false, context: { formData: data } })
				.then(() => {
					this.props.setFormValidation(this.props.formKey, {}, true);
				})
				.catch((errors) => {
					let validation = {};
					// Go through each error and save it to the field.
					// If the field has multiple errors, we only keep one at a time
					errors.inner.forEach(err => {
						validation = flow(
							setIn(`${err.path}.message`, err.message),
							setIn(`${err.path}.type`, err.type),
							setIn(`${err.path}.valid`, false),
						)(validation);
					});

					this.props.setFormValidation(this.props.formKey, validation, false);
				});
		}
	}

	formFields = (children) => {
		return React.Children.map(children, (child) => {
			// If there's a `fieldKey` prop, then this is a formField
			const fieldKey = get(['props', 'fieldKey'], child);
			if (fieldKey) {
				return React.cloneElement(child, {
					formKey: this.props.formKey,
				});
			}

			// forms can contain more than just form fields.  So, we need to deeply traverse all children
			if (get(['props', 'children'], child)) {
				return React.cloneElement(child, {
					children: this.formFields(child.props.children),
				});
			}

			// Otherwise, this is just a display component, and should be rendered without alterations.
			return child;
		});
	};

	// NOTE: Since this function is created on initialization, any props or state
	// used in the onSubmit from the parent component are bound at this point,
	// making that data stale when this debounced onSubmit is called.
	// unboundSubmit was created as a temporary workaround until onSubmit is fixed. See CGX-423
	onSubmit = debounce(
		this.props.onSubmit || function () { },
		this.props.submitDebounce,
		{
			maxWait  : this.props.submitDebounce,
			leading  : true,
			trailing : false,
		}
	);

	submit = (e) => {
		e.preventDefault();
		if (this.props.onSubmit) {
			const data = Object.assign({}, this.props.form.data);
			this.onSubmit(data);
		} else if (this.props.unboundSubmit) {
			const data = Object.assign({}, this.props.form.data);
			debounce(
				this.props.unboundSubmit || function () { },
				this.props.submitDebounce,
				{
					maxWait  : this.props.submitDebounce,
					leading  : true,
					trailing : false,
				}
			)(data);
		}
	};

	get submitButton() {
		if (this.props.onSubmit || this.props.unboundSubmit) {
			return (
				<div
					key='submit'
					className={classnames({
						[styles.full]: this.props.fullSubmit,
					})}
				>
					<Button
						type='submit'
						text={this.props.submitText}
						icon={this.props.submitIcon}
						disabled={!this.props.isValid || this.props.disabled || this.props.loading}
						onClick={this.submit}
						full={this.props.fullSubmit}
						flat={this.props.flatSubmit}
						mini={this.props.miniSubmit}
						dataCy={this.props.dataCy ? this.props.dataCy + '-submit' : ''}
						ariaLabel={this.props.submitAriaLabel}
					/>
				</div>
			);
		}

		return null;
	}

	get actions() {
		let actions = this.props.actions.map((a) => {
			const onClick = (e) => {
				e.preventDefault();

				const data = Object.assign({}, this.props.form.data);

				if (a.onClick) {
					a.onClick(data);
				}
			};

			const button = React.createElement(Buttons[a.buttonType || 'Button'], {
				onClick,
				text      : a.text,
				color     : a.color || 'info',
				debounce  : a.debounce || 1000,
				reverse   : a.reverse,
				type      : 'Button',
				icon      : a.icon,
				disabled  : a.requireValid ? a.disabled || !this.props.isValid : a.disabled,
				tabIndex  : 0,
				role      : 'button',
				onKeyDown : (e) => {e.code === 'Enter' && onClick(e);},
			});

			return (
				<div
					className={styles.buttonWrapper}
					key={a.text}
				>
					{button}
				</div>
			);
		});

		if (this.submitButton) {
			actions.push(this.submitButton);
		}

		if (!actions.length) {
			return null;
		}

		if (this.props.centerSubmit) {
			return (
				<FlexCol center>
					{actions}
				</FlexCol>
			);
		}

		return (
			<Section size={this.props.mdSection ? 'md' : 'xlg'}>
				<FlexRow justification="flex-end">
					{actions}
				</FlexRow>
			</Section>
		);
	}

	render() {
		if (!this.props.initialized) {
			return null;
		}

		return (
			<form
				data-cy={this.props.dataCy ? `${this.props.dataCy}-form` : ''}
				id={this.props.formId}
				onSubmit={this.submit}
				encType={this.props.enctype}
				autoComplete={this.props.autocomplete ? 'on' : 'off'}
			>
				{this.formFields(this.props.children)}
				{this.actions}
			</form>
		);
	}
}

export { Form };
export default connect(
	(state, ownProps) => ({
		form        : getOr({ meta: {} }, ownProps.formKey, state.form),
		initialized : getOr(false, `${ownProps.formKey}.initialized`, state.form),
		isValid     : getOr(false, `${ownProps.formKey}.validation.isValid`, state.form),
	}),
	{ initializeForm, clearForm, setFormValidation }
)(Form);
