import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CloseIcon from "components/templates/YourAssessmentResults/icons/CardIcons/close_icon";
import { Footer, FOOTER_HEIGHT } from "./components/Footer";
import RiskLevelBar from "./RiskLevelBar";
import {
  Box,
  Button,
  Center,
  Heading,
  ListItem,
  UnorderedList,
  useMediaQuery,
} from "design-system/components";
import FocusTrap from "focus-trap-react";
import { Trans, useTranslation } from "react-i18next";
import { startCase } from "lodash";
import Router from "next/router";
import routes from "routes/index";
import { useQuery } from "@apollo/client";
import { getMemberSUDSupported } from "operations/queries/member";
import Meowth from "@spring/meowth";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";
import { Link } from "components/atoms";

const BenefitsContent = ({ t, goalType, hasSUDGoals }) => (
  <>
    <Heading mb={10} as="h3" fontSize={16}>
      {t(`detailFlyout.goals.${goalType}.benefitsHeader`)}
    </Heading>
    <p>{t(`detailFlyout.goals.${goalType}.benefitsBody`)}</p>

    <Heading mb={10} as="h3" fontSize={16}>
      {t(`detailFlyout.goals.${goalType}.tipsHeader`)}
    </Heading>
    <UnorderedList ms={0}>
      <ListItem role="listitem">
        {t(`detailFlyout.goals.${goalType}.tipOne`)}
      </ListItem>
      <ListItem role="listitem">
        {t(`detailFlyout.goals.${goalType}.tipTwo`)}
      </ListItem>
      <ListItem role="listitem">
        {t(`detailFlyout.goals.${goalType}.tipThree`)}
      </ListItem>
      <ListItem role="listitem">
        {t(`detailFlyout.goals.${goalType}.tipFour`)}
      </ListItem>
      {!hasSUDGoals && (
        <ListItem role="listitem">
          {t(`detailFlyout.goals.${goalType}.tipFive`)}
        </ListItem>
      )}
    </UnorderedList>
  </>
);

const SUDBenefitsContent = ({ t, goalType }) => {
  const [isSmallScreen] = useMediaQuery("(max-width: 540px)");

  return (
    <>
      <Box mb={isSmallScreen ? 0 : 110}>
        <Heading mt={9} as="h3" fontSize={16} fontWeight={600}>
          {t(`detailFlyout.goals.${goalType}.tipsHeader`)}
        </Heading>
        <Box mt={22} ms={22}>
          <UnorderedList ms={0}>
            <ListItem role="listitem">
              {t(`detailFlyout.goals.${goalType}.tipOne`)}
            </ListItem>
            <ListItem role="listitem">
              {t(`detailFlyout.goals.${goalType}.tipTwo`)}
            </ListItem>
            <ListItem role="listitem">
              {t(`detailFlyout.goals.${goalType}.tipThree`)}
            </ListItem>
            <ListItem role="listitem">
              {t(`detailFlyout.goals.${goalType}.tipFour`)}
            </ListItem>
          </UnorderedList>
        </Box>
        <Heading mt={36} as="h3" fontSize={16} fontWeight={600}>
          {t(`detailFlyout.goals.${goalType}.helpOptionsHeader`)}
        </Heading>
        <Box mt={22} ms={22}>
          <UnorderedList ms={0}>
            <ListItem role="listitem">
              {t(`detailFlyout.goals.${goalType}.helpOption1`)}
            </ListItem>
            <ListItem role="listitem">
              <Trans
                ns={"assessmentResults"}
                i18nKey={`detailFlyout.goals.${goalType}.helpOption2`}
                components={[
                  <Link
                    key={0}
                    to={routes.SubstanceUseSupport.as}
                    mpTracking={{
                      page: window.location.pathname,
                      to: routes.SubstanceUseSupport.as,
                      type: "Schedule a call",
                      location:
                        goalType === "alcohol_use"
                          ? "Alcohol use flyout for enhanced sud member"
                          : "Drug use flyout for enhanced sud member",
                    }}
                  />,
                ]}
              />
            </ListItem>
            <ListItem role="listitem">
              {t(`detailFlyout.goals.${goalType}.helpOption3`)}
            </ListItem>
            <ListItem role="listitem">
              {t(`detailFlyout.goals.${goalType}.helpOption4`)}
            </ListItem>
          </UnorderedList>
        </Box>

        <Box
          position="absolute"
          bottom={isSmallScreen ? "3rem" : 0}
          left={0}
          w="100%"
          p={16}
          bg="white"
          boxShadow={"0px -4px 4px rgba(188, 188, 188, 0.25)"}
        >
          <Box w="100%" height={48}>
            <Center>
              <Button
                w={"fit-content"}
                colorScheme="primary"
                variant="solid"
                borderColor="interactive"
                size="lg"
                borderWidth={2}
                borderRadius="8px"
                onClick={() => {
                  TRACK_EVENT.BUTTON_CLICKED(
                    window.location.pathname,
                    "Learn More",
                    {
                      location:
                        goalType === "alcohol_use"
                          ? "Alcohol use flyout for enhanced sud member"
                          : "Drug use flyout for enhanced sud member",
                      to: routes.SubstanceUseSupport.as,
                    },
                  );
                  Router.push(routes.SubstanceUseSupport.as);
                }}
              >
                {t("detailFlyout.learnMore")}
              </Button>
            </Center>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const DetailFlyout = ({
  data,
  show,
  type,
  loadIconImg,
  closeDetail,
  styles,
  t,
}) => {
  const [isSmallScreen] = useMediaQuery("(max-width: 540px)");
  const { i18n } = useTranslation();
  const dir = i18n.dir(i18n.language);
  const goalType = data?.goal?.goal_type;

  const sudMxV2Flag = useFeatureFlag(FLAGS.SUD_MX_V2);
  const { data: memberInfo } = useQuery(getMemberSUDSupported, {
    ...Meowth.apolloOptionsUserId(),
    skip: !sudMxV2Flag,
  });
  const sudSupported =
    memberInfo?.user?.member?.cohort?.contract_term?.sud_supported || false;
  const hasSUDGoals = goalType === "alcohol_use" || goalType === "drugs";
  const shouldShowEnhancedSUDFlyout = hasSUDGoals && sudSupported;

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        closeDetail(type);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  function isScoreEligible(scoreLabel) {
    return scoreLabel !== "undefined / 1";
  }

  const onClickWrapper = (e) => {
    // ignore clicks inside the wrapper div
    if (e.target !== e.currentTarget) return;
    if (!show) return;
    closeDetail(type);
  };

  const ailment = startCase(
    t(`detailFlyout.ailments.${data?.info?.kind}.header`),
  );

  const whatToDoAnsweri18nKey = `detailFlyout.ailments.${data?.info?.kind}.whatToDoAnswer.${data?.results?.risk}`;
  const whatToDoAnsweri18nUXCopy = t(whatToDoAnsweri18nKey);
  const shouldRenderFooter = whatToDoAnsweri18nUXCopy?.includes("Moments");

  const detailFlyoutBodyStyle = {
    maxHeight: shouldRenderFooter
      ? `calc(100% - ${FOOTER_HEIGHT} - 7rem)`
      : "100%",
    paddingBlock:
      isSmallScreen && shouldRenderFooter ? undefined : "1rem 120px",
  };

  const detailFlyoutContent = (
    <div
      className={classnames(
        dir === "rtl"
          ? styles.flyoutDirectionRight
          : styles.flyoutDirectionLeft,
        styles.detailFlyoutContainer,
        {
          [styles.open]: show,
          [styles[type]]: type,
        },
      )}
      id={
        "detailFlyoutContainer-" +
        (data.label
          ? `${data.label.replaceAll(" ", "-")}-${data.results.risk}`
          : goalType)
      }
      style={{ paddingBottom: shouldRenderFooter ? null : "150px" }}
    >
      {type !== "goals" ? (
        <>
          <div className={styles.detailFlyoutHeader}>
            <span>{loadIconImg("therapy")}</span>
            <h2>{ailment}</h2>
            <button
              onClick={() => closeDetail(type)}
              type="button"
              tabIndex={show ? "0" : "-1"}
            >
              <CloseIcon />
            </button>
          </div>
          <div
            className={styles.detailFlyoutBody}
            style={detailFlyoutBodyStyle}
          >
            {data.riskScore <= 2 && (
              <Heading mb={10} as="h3" fontSize={16}>
                {t(`detailFlyout.blurb.${data.results.risk}`, {
                  risk: data.results.risk,
                  ailment: ailment,
                })}
              </Heading>
            )}
            {isScoreEligible(data.scoreLabel) && (
              <RiskLevelBar
                styles={styles}
                scoreLabel={data.scoreLabel}
                kindLabel={data.label}
                riskLevelDesc={t(`detailFlyout.blurb.${data.results.risk}`, {
                  risk: data.results.risk,
                  ailment: ailment,
                })}
                t={t}
              />
            )}
            <Heading mb={10} as="h3" fontSize={16}>
              {t(`detailFlyout.ailments.${data.info.kind}.ailmentQuestion`)}
            </Heading>
            <p>
              {t(`detailFlyout.ailments.${data?.info?.kind}.ailmentAnswer`)}
            </p>
            <Heading mb={10} as="h3" fontSize={16}>
              {t("detailFlyout.whatToDoQuestion")}
            </Heading>
            <p>{whatToDoAnsweri18nUXCopy}</p>
          </div>
          <Footer shouldRenderFooter={shouldRenderFooter} />
        </>
      ) : (
        <>
          <div className={styles.detailFlyoutHeader}>
            <span>{loadIconImg(goalType)}</span>
            <h2>{t(`detailFlyout.goals.${goalType}.header`)}</h2>
            <button
              onClick={() => closeDetail(type)}
              type="button"
              tabIndex={show ? "0" : "-1"}
            >
              <CloseIcon />
            </button>
          </div>

          <div
            className={styles.detailFlyoutBody}
            style={detailFlyoutBodyStyle}
          >
            <b
              id={styles.benefitsHeader + "bold"}
              className={styles.benefitsHeader}
            >
              <p
                id={styles.benefitsHeader + "paragraph"}
                className={styles.benefitsHeader}
              >
                {!shouldShowEnhancedSUDFlyout &&
                  t(`detailFlyout.goals.${goalType}.subHeader`)}
                {shouldShowEnhancedSUDFlyout &&
                  t(`detailFlyout.goals.${goalType}.subHeaderEnhanced`)}
              </p>
            </b>

            {!shouldShowEnhancedSUDFlyout && (
              <BenefitsContent
                t={t}
                goalType={goalType}
                hasSUDGoals={hasSUDGoals}
              />
            )}
            {shouldShowEnhancedSUDFlyout && (
              <SUDBenefitsContent t={t} goalType={goalType} />
            )}
          </div>
        </>
      )}
    </div>
  );

  return (
    <FocusTrap active={!!show}>
      <div
        onClick={onClickWrapper}
        className={classnames(styles.overlay, {
          [styles.open]: show,
        })}
        role="dialog"
        aria-modal="true"
        aria-hidden={!show}
        aria-label={
          data.label ? `Flyout for ${data.label}` : `Flyout for ${goalType}`
        }
      >
        {detailFlyoutContent}
      </div>
    </FocusTrap>
  );
};

DetailFlyout.propTypes = {
  closeDetail: PropTypes.func,
  data: PropTypes.object,
  loadIconImg: PropTypes.func,
  show: PropTypes.bool,
  styles: PropTypes.object,
  t: PropTypes.func,
  type: PropTypes.string,
};

export default DetailFlyout;
