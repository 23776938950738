// TODO: deprecate this in favor of hooks

import React from "react";
import { Query } from "@apollo/client/react/components";
import Meowth from "@spring/meowth";
import { LoadingOverlay } from "@spring/smeargle";

import { getMemberAddress } from "operations/queries/member";

function withMemberAddress(Component) {
  return class MemberComponent extends React.Component {
    get memberId() {
      return Meowth.getUserId();
    }

    render() {
      return (
        <Query query={getMemberAddress} variables={{ id: this.memberId }}>
          {({ loading, data }) => (
            <>
              {loading ? (
                <LoadingOverlay in={loading} out={loading} />
              ) : (
                <Component memberAddress={data} {...this.props} />
              )}
            </>
          )}
        </Query>
      );
    }
  };
}

export default withMemberAddress;
