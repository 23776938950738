// 
import React, { Component } from 'react';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { getOr, get } from 'lodash/fp';
import { timeFormats } from '@spring/constants';


import Select from '../Select';
import InputWrapper from '../InputWrapper';
import Label from '../Label';
import Input from '../Input';
import Stout from '../../typography/Stout';
import Bolded from '../../typography/Bolded';
import Section from '../../layout/Section';

import { Months } from './static';
import styles from './styles.module.scss';

import { formFieldSelector } from '../../../selectors/form';
import { setField } from '../../../actions/form';

class DateSelect extends Component {
	static defaultProps = {
		minYear: 0,
	}

	componentDidMount() {
		this.props.setField &&
			this.props.setField(
				this.props.formKey,
				`${this.props.fieldKey}.actualDate`,
				this.constructActualDate(),
				true
			);
	}

	shouldComponentUpdate(prevProps) {
		const { formData: prevFormData } = prevProps;
		const { formData: currentFormData, fieldKey } = this.props;

		return (
			prevProps.label !== this.props.label ||
			get(`${fieldKey}.month`, prevFormData) !== get(`${fieldKey}.month`, currentFormData) ||
			get(`${fieldKey}.day`, prevFormData) !== get(`${fieldKey}.day`, currentFormData) ||
			get(`${fieldKey}.year`, prevFormData) !== get(`${fieldKey}.year`, currentFormData)
		);
	}

	componentDidUpdate() {
		this.props.setField(
			this.props.formKey,
			`${this.props.fieldKey}.actualDate`,
			this.constructActualDate(),
			true
		);

	}

	constructActualDate = () => {
		const { fieldKey, formData } = this.props;
		const month = get(`${fieldKey}.month`, formData);
		const day = get(`${fieldKey}.day`, formData);
		const year = get(`${fieldKey}.year`, formData);
		return DateTime.fromObject({ year, month, day }).toFormat(timeFormats.datePickerFormat);
	}

	get label() {
		if (this.props.label) {
			return (
				<Section size="sm">
					<Label
						disabled={this.props.disabled}
						formKey={this.props.formKey}
						fieldKey={this.props.fieldKey}
						theme={this.props.theme}
						bolded={this.props.bolded}
					>
						{this.props.bolded ? (
							<span className={styles.bolded}>
								<Bolded>{this.props.label}</Bolded>
							</span>
						) :
							<Stout inline inherit={this.props.inherit}>{this.props.label}</Stout>
						}
					</Label>
				</Section>
			);
		}
		return null;
	}
	render() {
		return (
			<div className={styles.container}>
				<InputWrapper>
					<fieldset>
						<legend>{this.label}</legend>
						<div className={styles.dateSelectContainer}>
							<div className={styles.monthContainer}>
								<Select
									label={!this.props.hideSubLabels && this.props.inputLabel?.month?.label || 'Month'}
									formKey={this.props.formKey}
									fieldKey={`${this.props.fieldKey}.month`}
									options={Months}
									placeholder={this.props.inputLabel?.month?.placeholder || 'MM'}
									dataCy={this.props.dataCy ? this.props.dataCy + '-month' : ''}
									ariaLabel='choose month'
									forceRequired={this.props.forceRequired}
								/>
							</div>
							<div className={styles.dayInput}>
								<Input
									label={!this.props.hideSubLabels && this.props.inputLabel?.day?.label || 'Day'}
									type="number"
									autoComplete="bday-day"
									formKey={this.props.formKey}
									fieldKey={`${this.props.fieldKey}.day`}
									placeholder={this.props.inputLabel?.day?.placeholder || 'DD'}
									min={1}
									max={31}
									dataCy={this.props.dataCy ? this.props.dataCy + '-day' : ''}
									inputProps={{ 'aria-label': 'choose day' }}
									forceRequired={this.props.forceRequired}
								/>
							</div>
							<div className={styles.yearContainer}>
								<Input
									label={!this.props.hideSubLabels && this.props.inputLabel?.year?.label || 'Year'}
									type="number"
									autoComplete="bday-year"
									formKey={this.props.formKey}
									fieldKey={`${this.props.fieldKey}.year`}
									placeholder={this.props.inputLabel?.year?.placeholder || 'YYYY'}
									min={this.props.minYear}
									max={this.props.maxYear}
									dataCy={this.props.dataCy ? this.props.dataCy + '-year' : ''}
									inputProps={{ 'aria-label': 'choose year' }}
									forceRequired={this.props.forceRequired}
								/>
							</div>
						</div>
					</fieldset>
				</InputWrapper>
			</div>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		formData: getOr({}, `${ownProps.formKey}.data`, state.form),
		...formFieldSelector(state, ownProps),
	};
};

const mapDispatchToProps = { setField };

export { DateSelect };
export default connect(mapStateToProps, mapDispatchToProps)(DateSelect);