//

import { createContext, useMemo, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { connect } from "react-redux";
import { SHNotification, useToast } from "design-system/components";

import { getUserInfo } from "operations/queries/user";
import { EnhancedMemberExpectations, UpselledExpectations } from "components";
import { UpdateConsent } from "modules/MemberDashboard/Home/components/AgingOut/components";
import { useUpdateAgedOutConsents } from "modules/MemberDashboard/Home/components/AgingOut/hooks/useUpdateAgedOutConsents";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import Router from "next/router";
import routes from "routes";
import Snorlax from "utils/snorlax";
import Meowth from "@spring/meowth";
import { Spinner } from "@chakra-ui/react";

const ExpectationsContext = createContext({
  global: null,
  setGlobal: () => {},
  coveredLife: null,
  setCoveredLife: () => {},
  potentialCL: null,
  setPotentialCL: () => {},
  isDependentObj: false,
  setIsDependentObj: () => {},
  notification: null,
  setNotification: () => {},
});

const Expectations = ({
  isLoggedIn,
  userId,
  agedOutConsentRecord,
  memberId,
  isT2,
  allowed,
}) => {
  const toast = useToast();
  const hasAgedOutRecord = !!agedOutConsentRecord?.created_at;
  const isAgeOutEnabled = useFeatureFlag(FLAGS.ENABLE_AGING_OUT_P1);
  const isUpsellingT2T1 = useFeatureFlag(FLAGS.UPSELL_T2_T1);

  // context state setup
  const [global, setGlobal] = useState({
    country: "US",
    lang: "en",
    showGlobalExperience: "false",
    country_code: "1",
  });
  const [coveredLife, setCoveredLife] = useState(null);
  const [potentialCL, setPotentialCL] = useState(null);
  const [isDependentObj, setIsDependentObj] = useState(null);
  const [notification, setNotification] = useState(null);
  const [needsUpdatedConsent, setNeedsUpdatedConsent] = useState(
    hasAgedOutRecord && !agedOutConsentRecord.terms_of_service_agreed_at,
  );
  const [userInfo, setUserInfo] = useState(null);
  // userInfo query
  const [doGetUserInfo] = useLazyQuery(getUserInfo);
  const [updateAgedOutConsentMutation] = useUpdateAgedOutConsents(
    memberId,
    setNeedsUpdatedConsent,
  );

  // context updates uses useMemo, which cuts down redundant re-renders
  const globalValue = useMemo(() => ({ global, setGlobal }), [global]);
  const clValue = useMemo(
    () => ({ coveredLife, setCoveredLife }),
    [coveredLife],
  );
  const potentialCLValue = useMemo(
    () => ({ potentialCL, setPotentialCL }),
    [potentialCL],
  );

  const isDependentValue = useMemo(
    () => ({ isDependentObj, setIsDependentObj }),
    [isDependentObj],
  );

  const notificationValue = useMemo(
    () => ({ notification, setNotification }),
    [notification],
  );

  async function fetchUserInfo() {
    const { data } = await doGetUserInfo({ variables: { id: userId } });
    setUserInfo(data);
  }

  useEffect(() => {
    if (isLoggedIn && userId) {
      fetchUserInfo();
    }
  }, [isLoggedIn, userId]);

  useEffect(() => {
    if (notification?.text && notification?.status) {
      toast({
        duration: null,
        containerStyle: {
          maxWidth: "100%",
          width: "80%",
        },
        // eslint-disable-next-line react/display-name
        render: () => (
          <SHNotification
            notification={notification}
            setNotification={setNotification}
          />
        ),
      });
    }
  }, [notification]);

  const handleUpdateConsent = () => {
    updateAgedOutConsentMutation();
  };

  if (isT2) {
    Router.replace(routes.MemberHome.to, routes.MemberHome.as);
  }

  if (isAgeOutEnabled && needsUpdatedConsent) {
    return <UpdateConsent handleUpdateConsent={handleUpdateConsent} />;
  }

  if (
    isUpsellingT2T1 &&
    userInfo?.user?.member?.experience_state?.should_onboard_global_scheduling
  ) {
    return <UpselledExpectations allowed={allowed} />;
  }

  if (!userInfo) {
    return <Spinner />;
  }

  return (
    <ExpectationsContext.Provider
      data-cy="begin-assessment-heading"
      value={{
        ...globalValue,
        ...clValue,
        ...potentialCLValue,
        ...isDependentValue,
        ...notificationValue,
      }}
    >
      <EnhancedMemberExpectations />
    </ExpectationsContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth?.isLoggedIn || false,
  userId: state.auth?.userIdentifiers?.user?.id || false,
  memberId: state.auth?.userIdentifiers?.user?.member?.id,
  agedOutConsentRecord:
    state?.auth?.userIdentifiers?.user?.member?.aged_out_consent_fields,
  isT2: state?.auth?.userIdentifiers?.user?.member?.is_global_t2,
  allowed: () =>
    Snorlax(state)
      .loggedIn.to(routes.SignIn)
      .isMember(routes["Logout"])
      .customerIsLaunched(Meowth.getCustomerId(), routes.ComingSoon)
      .hasNotCompletedInitialAssessment(routes.MemberHome),
});

export { ExpectationsContext };
export default connect(mapStateToProps)(Expectations);
