// 

import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const FadeIn = (props) => {
	const [animated, setAnimated] = useState(false);


	useEffect(() => {
		setAnimated(true);
	});

	return (
		<div 
			className={classnames(styles.fadeIn, { [styles.in]: animated })}
			style={{ transitionDuration: props.duration }}
		>
			{props.children}
		</div>
	);
};

FadeIn.defaultProps = {
	duration: '.5s',
};

export default FadeIn;
