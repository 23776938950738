import { gql } from "@apollo/client";

import { assessment } from "operations/fragments/assessment";
import paging from "operations/fragments/paging";

const getDetailedAssessments = gql`
  query getDetailedAssessments(
    $member_id: ID
    $status: [String]
    $kind: AssessmentKindEnumType
    $triaged: Boolean
    $risk: [String]
    $limit: Int
    $offset: Int
  ) {
    assessments(
      member_id: $member_id
      status: $status
      kind: $kind
      triaged: $triaged
      risk: $risk
      limit: $limit
      offset: $offset
    ) {
      data {
        ...assessment
        risk
        member {
          id
          name
        }
        questionnaires(version: "improved_sud") {
          results_sanitized
        }
      }
      paging {
        ...paging
      }
    }
  }
  ${assessment}
  ${paging}
`;

export default getDetailedAssessments;
