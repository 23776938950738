import { FC } from "react";
import { Badge, Tooltip } from "@springcare/sh-component-library";
import type { UpcomingChangesBadgeProps } from "modules/shared/components/Notifications/types";
import { useTranslation } from "react-i18next";
import { getAccountTransitionDate } from "modules/shared/components/Notifications/utils";
import { WITHIN_AGING_OUT_VALUES } from "modules/shared/components/Notifications/constants";

export const UpcomingChangesBadge: FC<
  React.PropsWithChildren<UpcomingChangesBadgeProps>
> = (props) => {
  const { t } = useTranslation("accountTransition");
  if (!props.agingOutCategory) return null;
  const { dateOfBirth, agingOutCategory, minorId } = props;
  const isAgingOut = agingOutCategory in WITHIN_AGING_OUT_VALUES;

  // TODO we will enable this badge for other aging out ranges in P2 https://springhealth.atlassian.net/browse/FAM-567
  if (!isAgingOut) return null;

  const formattedDate = getAccountTransitionDate(dateOfBirth);
  const translatedText = t(`badge.${agingOutCategory}`, {
    transitionDate: formattedDate,
  });

  return (
    <Tooltip
      id={`${minorId}UpcomingChangesTooltip`}
      role="tooltip"
      label={translatedText}
      placement="top"
      borderRadius={"v-lg"}
      isDisabled={agingOutCategory !== "seventeen_to_eighteen"}
    >
      <Badge
        variant="medium-emphasis"
        colorScheme="neutral"
        tabIndex={0}
        aria-labelledby={`${minorId}UpcomingChangesTooltip`}
        whiteSpace={["nowrap", "normal", "nowrap"]}
      >
        {t("upcomingChanges")}
      </Badge>
    </Tooltip>
  );
};
