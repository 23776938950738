import { gql } from "@apollo/client";

const getAllCountries = gql`
  query getAllCountries($locale: String) {
    all_countries(locale: $locale) {
      iso2
      name
      dial_code
      flag
    }
  }
`;

export default getAllCountries;
