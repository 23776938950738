import React from "react";
import PropTypes from "prop-types";

const CelebrateIcon = ({ ariaHidden, fillColor }) => (
  <svg
    aria-hidden={ariaHidden?.toString()}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7071 4.70711L14.0503 10.364C13.6597 10.7545 13.0266 10.7545 12.636 10.364C12.2455 9.97344 12.2455 9.34027 12.636 8.94975L18.2929 3.29289C18.6834 2.90237 19.3166 2.90237 19.7071 3.29289C20.0976 3.68342 20.0976 4.31658 19.7071 4.70711ZM19.7071 8.29289L19 7L18.2929 8.29289L17 9L18.2929 9.70711L19 11L19.7071 9.70711L21 9L19.7071 8.29289ZM15 4C15 4.55228 14.5523 5 14 5C13.4477 5 13 4.55228 13 4C13 3.44772 13.4477 3 14 3C14.5523 3 15 3.44772 15 4ZM6.27904 12.0415C6.53133 11.3567 7.40845 11.164 7.92449 11.6801L11.7144 15.47C12.2305 15.9861 12.0378 16.8632 11.353 17.1155L5.35228 19.3263C4.55177 19.6212 3.77331 18.8428 4.06824 18.0422L6.27904 12.0415ZM9.3387 10.2659C7.79059 8.71776 5.15923 9.29569 4.40236 11.3501L2.19155 17.3508C1.30678 19.7524 3.64216 22.0878 6.04369 21.203L12.0445 18.9922C14.0988 18.2353 14.6768 15.604 13.1287 14.0558L9.3387 10.2659ZM14.2929 12.2929C13.9024 12.6834 13.9024 13.3166 14.2929 13.7071C14.6834 14.0976 15.3166 14.0976 15.7071 13.7071C16.4882 12.9261 17.7545 12.9261 18.5355 13.7071C18.9261 14.0976 19.5592 14.0976 19.9497 13.7071C20.3403 13.3166 20.3403 12.6834 19.9497 12.2929C18.3876 10.7308 15.855 10.7308 14.2929 12.2929ZM9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289C12.2692 4.85499 12.2692 7.38765 10.7071 8.94975C10.3166 9.34027 9.68342 9.34027 9.29289 8.94975C8.90237 8.55922 8.90237 7.92606 9.29289 7.53553C10.0739 6.75449 10.0739 5.48816 9.29289 4.70711C8.90237 4.31658 8.90237 3.68342 9.29289 3.29289ZM18.5 19.9956C19.3284 19.9956 20 19.324 20 18.4956C20 17.6672 19.3284 16.9956 18.5 16.9956C17.6716 16.9956 17 17.6672 17 18.4956C17 19.324 17.6716 19.9956 18.5 19.9956ZM4 9C4 9.55228 3.55228 10 3 10C2.44772 10 2 9.55228 2 9C2 8.44772 2.44772 8 3 8C3.55228 8 4 8.44772 4 9ZM13 22C13.5523 22 14 21.5523 14 21C14 20.4477 13.5523 20 13 20C12.4477 20 12 20.4477 12 21C12 21.5523 12.4477 22 13 22ZM5.70711 4.29289L5 3L4.29289 4.29289L3 5L4.29289 5.70711L5 7L5.70711 5.70711L7 5L5.70711 4.29289Z"
      fill={fillColor}
    />
  </svg>
);

CelebrateIcon.propTypes = {
  ariaHidden: PropTypes.bool,
  fillColor: PropTypes.string,
};

export default CelebrateIcon;
