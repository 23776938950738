import { gql } from "@apollo/client";

const getMemberCheckInFrequency = gql`
  query getMemberCheckInFrequency($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        check_in_frequency_days
      }
    }
  }
`;

export default getMemberCheckInFrequency;
