import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { getMemberInfo } from "operations/queries/member";

export function useMemberInfo(options) {
  return useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    ...options,
  });
}
