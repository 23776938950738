import { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Badge,
  IconButton,
} from "@springcare/sh-component-library";
import {
  Text,
  Flex,
  Box,
  Heading,
  useBreakpointValue,
  HStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { VXIcon, VWorkersDuotoneSpot } from "@springcare/verdant-icons-react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import routes from "routes";
import { WorkplaceManagerExploreCardProps } from "./types";
import { useMutation } from "@apollo/client";
import { debounce } from "lodash";
import { updateMemberExperienceState } from "operations/mutations/member";
import {
  trackMemberClickedCloseButtonOnWorkplaceManagerExploreCard,
  trackMemberClickedExploreButtonOnWorkplaceManagerExploreCard,
  trackMemberViewedWorkplaceManagerExploreCard,
} from "modules/MemberDashboard/Home/components/ManagerExperience/Workplace/utils/WorkplaceBannersMixpanelAnalytics";
import { useInViewportOnce } from "hooks";

export const WorkplaceManagerExploreCard: React.FC<
  React.PropsWithChildren<WorkplaceManagerExploreCardProps>
> = ({
  removeWorkplaceManagerBanner,
  memberId,
  showWorkplaceManagerSelfIdCardOnBottom,
}) => {
  const router = useRouter();
  const { t } = useTranslation("homepage");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const headingSize = useBreakpointValue([
    "heading-small",
    "heading-small",
    "heading-medium",
  ]);

  const cardVariant = showWorkplaceManagerSelfIdCardOnBottom
    ? "medium-emphasis"
    : "low-emphasis";

  const ref = useRef();
  const inViewport = useInViewportOnce(ref);
  const [viewedOnce, setViewedOnce] = useState(false);

  useEffect(() => {
    if (inViewport && !viewedOnce) {
      trackMemberViewedWorkplaceManagerExploreCard(
        showWorkplaceManagerSelfIdCardOnBottom,
      );
      setViewedOnce(true);
    }
  }, [inViewport, viewedOnce, showWorkplaceManagerSelfIdCardOnBottom]);

  const [updateWorkplaceBannerDismissed] = useMutation(
    updateMemberExperienceState,
    {
      variables: {
        member_id: memberId,
        workplace_banner_dismissed: true,
      },
    },
  );

  const handleCloseButtonClick = () => {
    trackMemberClickedCloseButtonOnWorkplaceManagerExploreCard(
      showWorkplaceManagerSelfIdCardOnBottom,
    );

    updateWorkplaceBannerDismissed();
    removeWorkplaceManagerBanner();
  };

  const handleExploreButtonClick = () => {
    trackMemberClickedExploreButtonOnWorkplaceManagerExploreCard(
      showWorkplaceManagerSelfIdCardOnBottom,
    );

    updateWorkplaceBannerDismissed();
    router.push(routes.WorkplaceManager.as);
  };

  return (
    <Card
      variant={cardVariant}
      size={isMobile ? "md" : "lg"}
      width="100%"
      mb="v-16"
      mt={["v-8", "v-8", "v-none"]}
      ref={ref}
    >
      <>
        <HStack
          justifyContent="space-between"
          width="100%"
          mb={["v-none", "v-none", "v-8"]}
        >
          <Badge colorScheme="positive" variant="medium-emphasis">
            {t("workplaceSelfIdentification.newBadge")}
          </Badge>
          <IconButton
            icon={VXIcon}
            variant="no-emphasis"
            colorScheme="neutral"
            aria-label="close-banner-button"
            onClick={debounce(handleCloseButtonClick, 100)}
          ></IconButton>
        </HStack>
        {isMobile && (
          <Flex alignSelf="center" marginBottom={["v-16", "v-16", "v-24"]}>
            <VWorkersDuotoneSpot
              width={100}
              height={100}
              aria-hidden="true"
              data-testid="workplace-icon"
            />
          </Flex>
        )}
        <Flex flexDir="row" justifyContent="space-between" width="95%">
          <Box>
            <Heading size={headingSize} mb="v-8">
              {t("workplaceSelfIdentification.newResourceHeading")}
            </Heading>
            <Text size="body-medium-regular" mb={["v-8", "v-8", "v-16"]}>
              {t("workplaceSelfIdentification.newResourceSubHeading")}
            </Text>
            <Flex
              flexDir="row"
              justifyContent={["flex-end", "flex-end", "flex-start"]}
            >
              <Button
                variant="high-emphasis"
                size="md"
                role="link"
                onClick={handleExploreButtonClick}
              >
                {t("workplaceSelfIdentification.exploreButtonLabel")}
              </Button>
            </Flex>
          </Box>
          {!isMobile && (
            <Flex alignSelf="flex-end">
              <VWorkersDuotoneSpot
                width={120}
                height={120}
                aria-hidden="true"
                data-testid="workplace-icon"
              />
            </Flex>
          )}
        </Flex>
      </>
    </Card>
  );
};
