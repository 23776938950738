import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { useMediaQuery } from "design-system/components";

import { getMemberInfo } from "operations/queries/member";
import {
  ScheduleModalOpenOptions,
  useScheduleModalWithProps,
} from "shared/hooks";
import { autoFilterProviderListByFilterTag } from "components/templates/Browse/ProviderBrowsePage/utils";

interface MemberInfo {
  user: {
    member: {
      id: string;
      date_of_birth: string;
      postal_address: {
        country: string;
        state: string;
        city: string;
        street_address_1: string;
        street_address_2: string | null;
        zip_code: string;
      };
      minor: boolean;
      previsit: {
        can_schedule: boolean;
      };
      visits_remaining_total: number;
      cohort: {
        in_person_supported: boolean;
        in_person_med_management_supported: boolean;
        has_dedicated_providers: boolean;
        contract_term: {
          scheduling_access: {
            name: string;
          };
        };
        customer: {
          name: string;
        };
      };
      customer_site_id: string;
    };
  };
}

interface Global {
  country: string;
  lang: string;
  showGlobalExperience: string;
  country_code: string;
}

interface Notification {
  text: string;
  status: boolean;
}

interface SelectedFilters {
  filters: {
    mediums: string[];
    conditions: string[];
    specialties: string[];
    genders: string[];
    ethnicities: string[];
    languages: string[];
    days_of_week: string[];
    time_of_day: string[];
  };
}

interface PossibleFilters {
  allow_company_exclusive: boolean;
  mediums: string[];
  conditions: string[];
  specialties: string[];
  genders: string[];
  ethnicities: string[];
  languages: string[];
  days_of_week: string[];
  time_of_day: [];
  licenses: {
    countries: string[];
    states: string[];
  };
}

interface Paging {
  page: number | null;
  pages: number | null;
  total: number | null;
  limit: number | null;
}

interface scheduleModalWithPropsV2 {
  isOpen: boolean;
  onOpen: (newModalProps: ScheduleModalOpenOptions) => void;
  onClose: () => void;
  modalProps: ScheduleModalOpenOptions;
}

interface IProviderContext {
  activeAccordionIndex: number;
  setActiveAccordionIndex: Dispatch<SetStateAction<number>>;
  queriedFilters: SelectedFilters;
  setQueriedFilters: Dispatch<SetStateAction<object>>;
  selectedFilters: SelectedFilters;
  setSelectedFilters: Dispatch<SetStateAction<object>>;
  providerList: Array<object> | null;
  setProviderList: Dispatch<SetStateAction<Array<object> | null>>;
  providerListLoading: boolean;
  setProviderListLoading: Dispatch<SetStateAction<boolean>>;
  paging?: Paging | null;
  setPaging?: Dispatch<SetStateAction<object>>;
  // BRECS-specific ProviderList
  brecsProviderList: Array<object> | null;
  setBrecsProviderList: Dispatch<SetStateAction<Array<object> | null>>;
  brecsProviderListLoading: boolean;
  setBrecsProviderListLoading: Dispatch<SetStateAction<boolean>>;
  memberInfo?: MemberInfo | null;
  setMemberInfo?: Dispatch<SetStateAction<object>>;
  memberAddressCopy?: string;
  setMemberAddressCopy?: Dispatch<SetStateAction<string>>;
  memberCanSchedule?: boolean;
  setMemberCanSchedule?: Dispatch<SetStateAction<boolean>>;
  possibleFilters?: PossibleFilters | null;
  setPossibleFilters?: Dispatch<SetStateAction<object>>;
  providerCardClick?: any | null;
  setProviderCardClick?: Dispatch<SetStateAction<any>>;
  global: Global;
  setGlobal: Dispatch<SetStateAction<object>>;
  notification: Notification;
  setNotification: Dispatch<SetStateAction<object>>;
  isMobile: boolean | null;
  addressUpdated: boolean | null;
  setAddressUpdated: Dispatch<SetStateAction<boolean>>;
  isCompanyExclusive: boolean;
  setIsCompanyExclusive: Dispatch<SetStateAction<boolean>>;
  queryRequestId?: string;
  setQueryRequestId: Dispatch<SetStateAction<string>>;
  scheduleModalWithPropsV2: scheduleModalWithPropsV2;
  providerType?: string | string[];
  setProviderType?: Dispatch<SetStateAction<string | string[]>>;
  customRoleName?: string | null;
  setCustomRoleName?: Dispatch<SetStateAction<string | null>>;
  minorTag?: string;
  setMinorTag: Dispatch<SetStateAction<string>>;
}

const ProviderBrowseContextValues = createContext<IProviderContext>({
  activeAccordionIndex: null,
  setActiveAccordionIndex: () => {},
  queriedFilters: null,
  setQueriedFilters: () => {},
  selectedFilters: null,
  setSelectedFilters: () => {},
  providerList: null,
  setProviderList: () => {},
  providerListLoading: false,
  setProviderListLoading: () => {},
  paging: null,
  setPaging: () => {},
  brecsProviderList: null,
  setBrecsProviderList: () => {},
  brecsProviderListLoading: false,
  setBrecsProviderListLoading: () => {},
  memberInfo: null,
  setMemberInfo: () => {},
  possibleFilters: null,
  setPossibleFilters: () => {},
  memberAddressCopy: null,
  setMemberAddressCopy: () => {},
  memberCanSchedule: null,
  setMemberCanSchedule: () => {},
  providerCardClick: null,
  setProviderCardClick: () => {},
  global: null,
  setGlobal: () => {},
  notification: null,
  setNotification: () => {},
  isMobile: null,
  addressUpdated: null,
  setAddressUpdated: () => {},
  isCompanyExclusive: false,
  setIsCompanyExclusive: () => {},
  queryRequestId: null,
  setQueryRequestId: () => {},
  scheduleModalWithPropsV2: null,
  providerType: null,
  setProviderType: () => {},
  customRoleName: null,
  setCustomRoleName: () => {},
  minorTag: null,
  setMinorTag: () => {},
});

export function ProviderBrowseContext({ children }: { children: ReactNode }) {
  // context state setup
  const [queriedFilters, setQueriedFilters] = useState<SelectedFilters>({
    filters: {
      mediums: [],
      conditions: [],
      specialties: [],
      genders: [],
      ethnicities: [],
      languages: [],
      days_of_week: [],
      time_of_day: [],
    },
  });
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    filters: {
      mediums: [],
      conditions: [],
      specialties: [],
      genders: [],
      ethnicities: [],
      languages: [],
      days_of_week: [],
      time_of_day: [],
    },
  });
  const [providerList, setProviderList] = useState<Array<object> | null>(null);
  const [providerListLoading, setProviderListLoading] =
    useState<boolean>(false);
  const [paging, setPaging] = useState<object | null>(null);
  const [brecsProviderList, setBrecsProviderList] =
    useState<Array<object> | null>(null);
  const [brecsProviderListLoading, setBrecsProviderListLoading] =
    useState<boolean>(false);
  const [memberInfo, setMemberInfo] = useState<MemberInfo | null>(null);
  const [possibleFilters, setPossibleFilters] =
    useState<PossibleFilters | null>(null);
  const [notification, setNotification] = useState<Notification>({
    text: "",
    status: false,
  });
  const [memberAddressCopy, setMemberAddressCopy] = useState<string>("");
  const [memberCanSchedule, setMemberCanSchedule] = useState<boolean>(false);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState<number>(0);
  const [providerCardClick, setProviderCardClick] = useState<any>(null);
  const [addressUpdated, setAddressUpdated] = useState<boolean>(false);
  const [isCompanyExclusive, setIsCompanyExclusive] = useState<boolean>(false);
  const [queryRequestId, setQueryRequestId] = useState<string>("");
  const [providerType, setProviderType] = useState<string | string[]>("");
  const [customRoleName, setCustomRoleName] = useState<string | null>(null);
  const [minorTag, setMinorTag] = useState<string>("");

  const [global, setGlobal] = useState<Global>({
    country: "US",
    lang: "en",
    showGlobalExperience: "false",
    country_code: "1",
  });
  const [isMobile] = useMediaQuery(
    "(max-width: 767px) and (orientation: portrait)",
  );
  // context updates uses useMemo, for (expensive) queries
  const providerListValue: object = useMemo(
    () => ({ providerList, setProviderList }),
    [providerList],
  );
  const pagingValue: object = useMemo(() => ({ paging, setPaging }), [paging]);

  const brecsProviderListValue: object = useMemo(
    () => ({ brecsProviderList, setBrecsProviderList }),
    [brecsProviderList],
  );

  const memberValue: object = useMemo(
    () => ({ memberInfo, setMemberInfo }),
    [memberInfo],
  );
  // @ts-ignore
  const filteredProviderTagsValue: [] = useMemo(
    () => ({ possibleFilters, setPossibleFilters }),
    [possibleFilters],
  );

  const scheduleModalWithPropsV2 = useScheduleModalWithProps();

  // @ts-ignore
  const providerBrowseContext: IProviderContext = {
    ...providerListValue,
    ...pagingValue,
    ...brecsProviderListValue,
    ...memberValue,
    ...filteredProviderTagsValue,
    providerListLoading,
    setProviderListLoading,
    brecsProviderListLoading,
    setBrecsProviderListLoading,
    memberAddressCopy,
    setMemberAddressCopy,
    memberCanSchedule,
    setMemberCanSchedule,
    activeAccordionIndex,
    setActiveAccordionIndex,
    queriedFilters, // Object that copies / passes "selectedFilters" to the `searchProviders` query
    setQueriedFilters,
    selectedFilters, // Placeholder object that stores selected filters in the ProviderFilterFlyout
    setSelectedFilters,
    providerCardClick,
    setProviderCardClick,
    global,
    setGlobal,
    notification,
    setNotification,
    isMobile,
    addressUpdated,
    setAddressUpdated,
    isCompanyExclusive,
    setIsCompanyExclusive,
    queryRequestId,
    setQueryRequestId,
    scheduleModalWithPropsV2,
    providerType,
    setProviderType,
    customRoleName,
    setCustomRoleName,
    minorTag,
    setMinorTag,
  };

  // getMemberInfo query is used directly in context because it's critical
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  const { loading, error, data } = useQuery(getMemberInfo, {
    ...apolloOptionsUserId,
  });
  useEffect(() => {
    if (!loading && !error && data) {
      setGlobal({
        country: data?.user?.member?.postal_address.country,
        lang: data?.user?.member?.preferred_language,
        showGlobalExperience:
          data?.user?.member?.postal_address?.country === "US"
            ? "false"
            : "true",
        country_code: data?.user?.member?.country_code,
      });
      setMemberInfo(data);
      if (data?.user?.member?.previsit?.can_schedule) {
        setMemberCanSchedule(data?.user?.member?.previsit?.can_schedule);
      }
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (minorTag && !queriedFilters.filters.specialties.includes(minorTag)) {
      autoFilterProviderListByFilterTag(
        minorTag,
        queriedFilters,
        setQueriedFilters,
        setSelectedFilters,
      );
    }
  }, [minorTag, queriedFilters]);

  return (
    <ProviderBrowseContextValues.Provider value={providerBrowseContext}>
      {children}
    </ProviderBrowseContextValues.Provider>
  );
}

export function useProviderBrowseContext() {
  return useContext(ProviderBrowseContextValues);
}
