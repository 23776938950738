import { useTranslation } from "react-i18next";
import { Heading } from "design-system/components/base/Typography";

const Error = () => {
  const { t } = useTranslation("loginless", { keyPrefix: "confirmation" });

  return (
    <Heading as="h1" variant="md_v1" data-testid="error">
      {t("error")}
    </Heading>
  );
};

export default Error;
