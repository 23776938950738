import { Badge } from "@springcare/sh-component-library";
import { ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { VOutpatientCareFlatSpot } from "@springcare/verdant-icons-react";
import { InformationFlyout } from "components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getFormLinkWPMHC } from "utils/employerServicesHelpers";
import {
  shouldShowHeaderIcontus,
  getHeroIllustration,
  getIsUniqueWPMCHCopy,
  getParaFiveExists,
  getAvailabilityExists,
  getBadgeExists,
} from "utils/employerServicesHelpers";

type CareConsultantFlyoutType = {
  isOpen: boolean;
  onClose: () => void;
  customerId?: string;
};

const CareConsultantBenefits = ({ customerId, isUniqueWPMCHCopy }) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: isUniqueWPMCHCopy
      ? `careInfoFlyout.careConsultant.${customerId}`
      : "careInfoFlyout.careConsultant.default",
  });

  const benefits = [];

  for (let i = 1; i <= 6; i++) {
    benefits.push(
      <ListItem key={`care_consultant_benefits_${i}`}>
        <Text display="inline">{t(`benefits.item${i}`)}</Text>
      </ListItem>,
    );
  }
  return (
    <>
      <Text mb={24}>{t("benefits.body")}</Text>
      <UnorderedList mb={24}>{benefits}</UnorderedList>
    </>
  );
};

const CareConsultantFlyoutBody = ({ customerId, isUniqueWPMCHCopy }) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: isUniqueWPMCHCopy
      ? `careInfoFlyout.careConsultant.${customerId}`
      : "careInfoFlyout.careConsultant.default",
  });

  const availabilityExists = getAvailabilityExists(customerId);

  const paraFiveExists = getParaFiveExists(customerId);

  return (
    <>
      <Text mb={24}>{t("subheading")}</Text>

      <Text size="body-medium-strong" mb="v-8">
        {t("benefits.header")}
      </Text>
      <CareConsultantBenefits
        customerId={customerId}
        isUniqueWPMCHCopy={isUniqueWPMCHCopy}
      />
      <Text size="body-medium-strong" mb="v-8">
        {t("whatToExpect.header")}
      </Text>
      {availabilityExists ? (
        <Text size="body-small-strong">
          {t("whatToExpect.body.availability")}
        </Text>
      ) : null}
      <Text mb={24}>{t("whatToExpect.body.p1")}</Text>
      <Text mb={24}>{t("whatToExpect.body.p2")}</Text>
      <Text mb={24}>{t("whatToExpect.body.p3")}</Text>
      <Text mb={24}>{t("whatToExpect.body.p4")}</Text>
      {(paraFiveExists || !isUniqueWPMCHCopy) && (
        <Text mb={24}>{t("whatToExpect.body.p5")}</Text>
      )}
    </>
  );
};

const CareConsultantHeaderBadge = ({ customerId, isUniqueWPMCHCopy }) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: isUniqueWPMCHCopy
      ? `careInfoFlyout.careConsultant.${customerId}`
      : "careInfoFlyout.careConsultant.default",
  });

  return <Badge colorScheme="positive">{t("badge")}</Badge>;
};

export const CareConsultantFlyout = ({
  isOpen,
  onClose,
  customerId,
}: CareConsultantFlyoutType) => {
  const isUniqueWPMCHCopy = getIsUniqueWPMCHCopy(customerId);

  const { t } = useTranslation("homepage", {
    keyPrefix: isUniqueWPMCHCopy
      ? `careInfoFlyout.careConsultant.${customerId}`
      : "careInfoFlyout.careConsultant.default",
  });

  const formLinkSDOH = getFormLinkWPMHC(customerId);

  const callback = useCallback(() => {
    return window.open(formLinkSDOH, "_blank", "noopener noreferrer");
  }, []);

  const badgeExists = getBadgeExists(customerId);

  const heroIllustration = getHeroIllustration(customerId);

  return (
    <InformationFlyout
      headerBadge={
        (badgeExists || !isUniqueWPMCHCopy) && (
          <CareConsultantHeaderBadge
            customerId={customerId}
            isUniqueWPMCHCopy={isUniqueWPMCHCopy}
          />
        )
      }
      headerText={t("heading")}
      headerIcon={
        shouldShowHeaderIcontus(customerId) ? <VOutpatientCareFlatSpot /> : null
      }
      heroIllustration={heroIllustration}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaType="button"
      ctaCallback={callback}
      reEnableButton
    >
      <CareConsultantFlyoutBody
        customerId={customerId}
        isUniqueWPMCHCopy={isUniqueWPMCHCopy}
      />
    </InformationFlyout>
  );
};
