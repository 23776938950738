import React from "react";
import { Close } from "../../../assets";
import { Button, Center, VisuallyHidden } from "../../base";

// NOTE: By default this won't render any children
export const SHCloseButton = ({
  /*
    NOTE: if the close button is anything but black and a ghost variant,
    it'll conflict and look wonky.
  */
  iconColor = "black",
  iconWidth = 16,
  iconHeight = 16,
  variant = "ghost",
  width = 24,
  height = 24,
  colorScheme = "primary",
  ...props
}) => (
  <Button
    maxWidth={width}
    maxHeight={height}
    padding={[20, 20, 20]}
    width={width}
    height={height}
    variant={variant}
    color={iconColor}
    boxSizing="border-box"
    colorScheme={colorScheme}
    // manually setting focus outline since that gets cleared by background
    _hover={{
      background: "rgba(0,0,0,0)",
      color: iconColor,
      outline: "black solid medium",
    }}
    _focus={{
      background: "rgba(0,0,0,0)",
      color: iconColor,
      outline: "black solid medium",
    }}
    _active={{
      background: "rgba(0,0,0,0)",
      color: iconColor,
      outline: "black solid medium",
    }}
    _focusVisible={{ boxShadow: "0 0 0 3px black" }}
    {...props}
  >
    <Center width={iconWidth} height={iconHeight}>
      <Close w={iconWidth} h={iconHeight} />
    </Center>
    <VisuallyHidden>Close</VisuallyHidden>
  </Button>
);
