import { gql } from "@apollo/client";

const updateMemberBenefitPreferences = gql(`
  mutation updateMemberPreference($member_id: ID!, $preferences: [PreferenceEnumType!]!) {
    updateMemberPreference(member_id: $member_id, preferences: $preferences) {
      success
      member_preferences {
        id
        name
      }
    }
  }
`);

export default updateMemberBenefitPreferences;
