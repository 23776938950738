// 

import React from 'react';

import Range from '../Range';



const markers = ['Not at all', 'Mildly', 'Moderately', 'Markedly', 'Extremely'];

const SeverityRange = (props) => (
	<Range
		{...props}
		markers={markers}
		required={true}
		showMarkers
		showActiveIndicator
	/>
);

SeverityRange.defaultProps = {
	min      : 1,
	max      : 10,
	step     : 1,
	disabled : false,
};

export default SeverityRange;
