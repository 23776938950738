//

import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR,
  UPDATE_SEEN_MOBILE_MODAL,
} from "./types";

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR,
  };
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}

export function updateSeenMobileModal() {
  return {
    type: UPDATE_SEEN_MOBILE_MODAL,
  };
}
