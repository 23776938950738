//

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Section,
  Modal,
  Content,
  Headline,
  FlexRow,
} from "@spring/smeargle";
import { closeModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";

const Close = connect(null, { closeModal })((props) => (
  <Button
    text="I Understand"
    icon="arrow-right"
    onClick={() => {
      props.closeModal(modalIds.previewDemoDisclaimerModal);
    }}
  />
));

Close.propTypes = {
  closeModal: PropTypes.func,
};

const PreviewDemoDisclaimerModal = () => (
  <Modal id={modalIds.previewDemoDisclaimerModal} size="md" open>
    <Headline>This is a Demo Environment</Headline>

    <Section size="lg">
      <Content>
        Please be advised, this version of the Spring Health web environment is
        for demonstrative purposes only. Spring Health will not provide health
        care through this environment.
      </Content>
    </Section>

    <Section size="lg">
      <Content>
        In using this environment, please take care not to share personal
        healthcare information. While Spring Health takes heavy measures to
        handle user data securely, we do not maintain this environment to the
        same audit and compliance standards as the production environment that
        we support for Spring Health customers.
      </Content>
    </Section>

    <FlexRow justification="flex-end">
      <Close />
    </FlexRow>
  </Modal>
);

export default PreviewDemoDisclaimerModal;
