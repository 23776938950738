import { gql } from "@apollo/client";

export const getWorkplaceCustomization = gql`
  query getCustomerWorkplaceCustomization($customer_id: ID!) {
    customer_workplace_customization(customer_id: $customer_id) {
      id
      critical_incident_email
      critical_incident_form_link
      critical_incident_phone_extension
      critical_incident_phone_number
      critical_incident_response_enabled
      employer_services_enabled
      fitness_for_duty_referral_type_enabled
      formal_referral_type_enabled
      informal_referral_type_enabled
      leader_course_description
      leader_course_title
      leader_course_url
      manager_consult_email
      manager_consult_enabled
      manager_consult_form_link
      manager_consult_phone_extension
      manager_consult_phone_number
      manager_consult_term
      mandatory_referral_type_enabled
      substance_abuse_referral_type_enabled
      term_employee_known_as
      term_manager_known_as
      workplace_enabled
    }
  }
`;
