//

import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Subhead,
  FlexCol,
  FlexRow,
  Button,
  Subtitle,
  Section,
} from "@spring/smeargle";
import { withTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { track } from "utils/mixpanel";

class JourneyChartForecast extends Component {
  static propTypes = {
    onNext: PropTypes.any,
    t: PropTypes.func,
  };

  componentDidMount() {
    track("Your Journey -- Tutorial #2 Card (Forecast Card) Shown");
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Section size="lg">
          <FlexCol alignment="center">
            <Subhead center bold noMargin>
              {t("stack.title")}
            </Subhead>
            <Section size="md">
              <Subtitle noMargin>{t("stack.chartForecastText")}</Subtitle>
            </Section>
            <div className={styles.chartWrapper}>
              <svg
                width="503px"
                height="368px"
                viewBox="0 0 503 368"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <path
                    d="M45,85 L159,85 C160.656854,85 162,86.3431458 162,88 L162,125 C162,126.656854 160.656854,128 159,128 L45,128 C43.3431458,128 42,126.656854 42,125 L42,88 C42,86.3431458 43.3431458,85 45,85 Z"
                    id="path-forecast-1"
                  ></path>
                  <filter
                    x="-17.5%"
                    y="-27.9%"
                    width="135.0%"
                    height="209.3%"
                    filterUnits="objectBoundingBox"
                    id="filter-forecast-2"
                  >
                    <feMorphology
                      radius="0.5"
                      operator="dilate"
                      in="SourceAlpha"
                      result="shadowSpreadOuter1"
                    ></feMorphology>
                    <feOffset
                      dx="0"
                      dy="14"
                      in="shadowSpreadOuter1"
                      result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                      stdDeviation="4.5"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    ></feGaussianBlur>
                    <feComposite
                      in="shadowBlurOuter1"
                      in2="SourceAlpha"
                      operator="out"
                      result="shadowBlurOuter1"
                    ></feComposite>
                    <feColorMatrix
                      values="0 0 0 0 0.206499787   0 0 0 0 0.206499787   0 0 0 0 0.206499787  0 0 0 0.04 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                    ></feColorMatrix>
                  </filter>
                  <filter
                    x="-10.0%"
                    y="-7.0%"
                    width="120.0%"
                    height="167.4%"
                    filterUnits="objectBoundingBox"
                    id="filter-forecast-3"
                  >
                    <feGaussianBlur
                      stdDeviation="2"
                      in="SourceAlpha"
                      result="shadowBlurInner1"
                    ></feGaussianBlur>
                    <feOffset
                      dx="0"
                      dy="-1"
                      in="shadowBlurInner1"
                      result="shadowOffsetInner1"
                    ></feOffset>
                    <feComposite
                      in="shadowOffsetInner1"
                      in2="SourceAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                      result="shadowInnerInner1"
                    ></feComposite>
                    <feColorMatrix
                      values="0 0 0 0 0.9603   0 0 0 0 0.96418   0 0 0 0 0.97  0 0 0 1 0"
                      type="matrix"
                      in="shadowInnerInner1"
                    ></feColorMatrix>
                  </filter>
                  <path
                    d="M26,124 C61.0814452,188.258806 106.801282,212.615989 163.159509,197.071548 C193.697647,188.648673 239.628488,220.9638 278.02454,223.704798 C320.182764,226.714367 346.576933,205.780174 386.588957,203.21751 C407.91411,201.851691 411.269927,218.99625 447.656442,219.208976 C454.98967,219.251848 472.437523,218.246454 500,216.192792"
                    id="path-forecast-4"
                  ></path>
                  <filter
                    x="-4.9%"
                    y="-14.2%"
                    width="109.7%"
                    height="150.8%"
                    filterUnits="objectBoundingBox"
                    id="filter-forecast-5"
                  >
                    <feMorphology
                      radius="2"
                      operator="dilate"
                      in="SourceAlpha"
                      result="shadowSpreadOuter1"
                    ></feMorphology>
                    <feOffset
                      dx="0"
                      dy="14"
                      in="shadowSpreadOuter1"
                      result="shadowOffsetOuter1"
                    ></feOffset>
                    <feMorphology
                      radius="2"
                      operator="erode"
                      in="SourceAlpha"
                      result="shadowInner"
                    ></feMorphology>
                    <feOffset
                      dx="0"
                      dy="14"
                      in="shadowInner"
                      result="shadowInner"
                    ></feOffset>
                    <feComposite
                      in="shadowOffsetOuter1"
                      in2="shadowInner"
                      operator="out"
                      result="shadowOffsetOuter1"
                    ></feComposite>
                    <feGaussianBlur
                      stdDeviation="4.5"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    ></feGaussianBlur>
                    <feColorMatrix
                      values="0 0 0 0 0.850980392   0 0 0 0 0.82745098   0 0 0 0 0.82745098  0 0 0 0.5 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                    ></feColorMatrix>
                  </filter>
                  <circle id="path-forecast-6" cx="24" cy="120" r="9"></circle>
                  <filter
                    x="-163.9%"
                    y="-86.1%"
                    width="427.8%"
                    height="427.8%"
                    filterUnits="objectBoundingBox"
                    id="filter-forecast-7"
                  >
                    <feOffset
                      dx="0"
                      dy="14"
                      in="SourceAlpha"
                      result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                      stdDeviation="7.5"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    ></feGaussianBlur>
                    <feColorMatrix
                      values="0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.5 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                    ></feColorMatrix>
                  </filter>
                </defs>
                <g
                  id="Artboard-Copy"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <rect
                    id="Rectangle"
                    stroke="#D8D8D8"
                    fill="#FFFFFF"
                    x="14.5"
                    y="52.5"
                    width="488"
                    height="286"
                    rx="5"
                  ></rect>
                  <path
                    d="M15.3294993,244.416667 L501.670501,244.416667"
                    id="Line"
                    stroke="#E8E9EB"
                    strokeLinecap="square"
                  ></path>
                  <path
                    d="M15.3294993,292.416667 L501.670501,292.416667"
                    id="Line"
                    stroke="#E8E9EB"
                    strokeLinecap="square"
                  ></path>
                  <path
                    d="M15.3294993,196.416667 L501.670501,196.416667"
                    id="Line"
                    stroke="#E8E9EB"
                    strokeLinecap="square"
                  ></path>
                  <path
                    d="M15.3294993,244.416667 L501.670501,244.416667"
                    id="Line"
                    stroke="#E8E9EB"
                    strokeLinecap="square"
                  ></path>
                  <path
                    d="M15.3294993,196.416667 L501.670501,196.416667"
                    id="Line"
                    stroke="#E8E9EB"
                    strokeLinecap="square"
                  ></path>
                  <path
                    d="M15.3294993,100.416667 L501.670501,100.416667"
                    id="Line"
                    stroke="#E8E9EB"
                    strokeLinecap="square"
                  ></path>
                  <path
                    d="M15.3294993,148.416667 L501.670501,148.416667"
                    id="Line"
                    stroke="#E8E9EB"
                    strokeLinecap="square"
                  ></path>
                  <g id="bg">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-forecast-2)"
                      xlinkHref="#path-forecast-1"
                    ></use>
                    <use
                      fill="#FFFFFF"
                      fillRule="evenodd"
                      xlinkHref="#path-forecast-1"
                    ></use>
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-forecast-3)"
                      xlinkHref="#path-forecast-1"
                    ></use>
                    <use
                      stroke="#DBDDE1"
                      strokeWidth="1"
                      xlinkHref="#path-forecast-1"
                    ></use>
                  </g>
                  <circle
                    id="Oval"
                    fill="#1E8E85"
                    cx="67"
                    cy="107"
                    r="6"
                  ></circle>
                  <g id="Line-2" strokeLinecap="square" strokeDasharray="11,17">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-forecast-5)"
                      xlinkHref="#path-forecast-4"
                    ></use>
                    <use
                      stroke="#C3C7CD"
                      strokeWidth="4"
                      xlinkHref="#path-forecast-4"
                    ></use>
                  </g>
                  <text
                    id="Your-Score"
                    fontFamily="HKGroteskBold, HKGroteskRegular"
                    fontSize="12"
                    fontWeight="bold"
                    line-spacing="21"
                    fill="#292929"
                  >
                    <tspan x="85" y="108">
                      {t("progressTracking.yourScore")}
                    </tspan>
                  </text>
                  <g id="Oval">
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-forecast-7)"
                      xlinkHref="#path-forecast-6"
                    ></use>
                    <use
                      fill="#1E8E85"
                      fillRule="evenodd"
                      xlinkHref="#path-forecast-6"
                    ></use>
                  </g>
                  <circle
                    id="Oval-2"
                    fill="#FFFFFF"
                    cx="24"
                    cy="120"
                    r="4"
                  ></circle>
                  <text
                    id="Your-Journey"
                    fontFamily="HKGroteskSemibold, HKGroteskRegular"
                    fontSize="16"
                    fontWeight="600"
                    fill="#6a6a6a"
                  >
                    <tspan x="202" y="14">
                      {t("progressTracking.graphTitle")}
                    </tspan>
                  </text>
                  <text
                    id="1"
                    fontFamily="HKGroteskRegular"
                    fontSize="14"
                    fontWeight="normal"
                    fill="#292929"
                  >
                    <tspan x="16" y="368">
                      1
                    </tspan>
                  </text>
                  <text
                    id="2"
                    fontFamily="HKGroteskRegular"
                    fontSize="14"
                    fontWeight="normal"
                    fill="#292929"
                  >
                    <tspan x="126" y="366">
                      2
                    </tspan>
                  </text>
                  <text
                    id="3"
                    fontFamily="HKGroteskRegular"
                    fontSize="14"
                    fontWeight="normal"
                    fill="#292929"
                  >
                    <tspan x="250" y="366">
                      3
                    </tspan>
                  </text>
                  <text
                    id="4"
                    fontFamily="HKGroteskRegular"
                    fontSize="14"
                    fontWeight="normal"
                    fill="#292929"
                  >
                    <tspan x="381" y="367">
                      4
                    </tspan>
                  </text>
                  <text
                    id="5"
                    fontFamily="HKGroteskRegular"
                    fontSize="14"
                    fontWeight="normal"
                    fill="#292929"
                  >
                    <tspan x="495" y="367">
                      5
                    </tspan>
                  </text>
                </g>
              </svg>
            </div>
          </FlexCol>
        </Section>
        <FlexRow justification="flex-end">
          <Button
            text={t("stack.btnTitle")}
            icon="arrow-right"
            onClick={this.props.onNext}
          />
        </FlexRow>
      </div>
    );
  }
}

export default withTranslation("journey")(JourneyChartForecast);
