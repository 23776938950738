//

import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { get } from "lodash/fp";
import Meowth from "@spring/meowth";

import getAssessments from "operations/queries/assessment/getAssessments";
import getInitialAssessment from "operations/queries/assessment/getInitialAssessment";
import getMemberInfo from "operations/queries/member/getMemberInfo";

const latestInitialAssessment = (status = ["Finished"]) => {
  return compose(
    graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
    graphql(getAssessments, {
      name: "migratedAssessment",
      options: { variables: { kind: "MIGRATED", limit: 1, status } },
      skip: Meowth.apolloSkipUserId,
    }),
    graphql(getInitialAssessment, {
      options: { variables: { limit: 1, status } },
      name: "initialAssessment",
      skip: Meowth.apolloSkipUserId,
      props: ({ initialAssessment }) => {
        const assessment = get("assessments.data[0]", initialAssessment);

        return {
          initialAssessment: {
            ...assessment,
            loading: initialAssessment.loading,
          },
        };
      },
    }),
  );
};

export default latestInitialAssessment;
