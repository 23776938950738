import { TRACK_EVENT } from "utils/mixpanel";
import { capitalize } from "lodash/fp";

export const trackRecProviderCardsLoaded = (selectedPreferences, providers) => {
  TRACK_EVENT.COMPONENTS_LOADED(
    window.location.pathname,
    "Recommended Provider Cards",
    {
      spring_doc_id: "recs01",
      total_providers_displayed: providers?.length ? providers?.length : 0,
      preferences: selectedPreferences?.length
        ? selectedPreferences.join(", ")
        : "None Selected",
      query_request_id: providers[0]?.query_request_id,
    },
  );
};

export const trackAddPreferencesClicked = (to = "Preferences Modal") => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Add Preferences", {
    spring_doc_id: "recs02",
    to: to,
  });
  TRACK_EVENT.MODAL_OPENED(window.location.pathname, "Preferences Modal", {
    spring_doc_id: "recs03",
  });
};

export const trackModalWarning = () => {
  TRACK_EVENT.WARNING_VIEWED(
    window.location.pathname,
    "Too many preferences warning",
    {
      spring_doc_id: "recs04",
      location: "Preferences Modal",
    },
  );
};

export const trackFiltersUpdate = (count, filters) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Update Preferences", {
    spring_doc_id: "recs05",
    location: "Preferences Modal",
    count: count,
    preferences: filters,
  });
};

// Not enough providers meeting preferences warning at top of page
export const trackNotEnoughProvidersWarning = () => {
  TRACK_EVENT.WARNING_VIEWED(
    window.location.pathname,
    "Not enough providers meeting preferences",
    {
      spring_doc_id: "recs06",
      location: "Recommendations Filter Section",
    },
  );
};

export const trackFilterChangeApplied = (
  filterTag,
  filterCategory,
  isAddedFilter,
  eventLocation,
) => {
  TRACK_EVENT.FILTER_CHANGE_APPLIED(
    window.location.pathname,
    capitalize(filterCategory),
    {
      spring_doc_id: "recs07",
      location: eventLocation,
      change: isAddedFilter ? "Added" : "Removed",
      value: filterTag,
    },
  );
};

export const trackAddPreferencesViewed = () => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Recommended Providers Add Preferences Button",
    {
      spring_doc_id: "recs08",
    },
  );
};

export const trackIntroPreferenceModalOpened = () => {
  TRACK_EVENT.MODAL_OPENED(
    window.location.pathname,
    "Intro Member Preference Modal",
    {
      spring_doc_id: "recs09",
    },
  );
};

export const trackIntroPreferenceModalClosed = () => {
  TRACK_EVENT.MODAL_CLOSED(
    window.location.pathname,
    "Intro Member Preference Modal",
    {
      spring_doc_id: "recs10",
    },
  );
};

export const trackIntroModalSubmitButtonClicked = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Intro Member Preference Modal",
    {
      spring_doc_id: "recs11",
      location: "Personalized Recommendations Button",
    },
  );
};

export const trackIntroModalSkipButtonClicked = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Intro Member Preference Modal",
    {
      spring_doc_id: "recs12",
      location: "Skip for now Button",
    },
  );
};

export const trackQuestionnaireOpened = () => {
  TRACK_EVENT.MODAL_OPENED(
    window.location.pathname,
    "Member Preference Modal",
    {
      spring_doc_id: "recs13",
    },
  );
};

export const trackQuestionnaireClosed = () => {
  TRACK_EVENT.MODAL_CLOSED(
    window.location.pathname,
    "Member Preference Modal",
    {
      spring_doc_id: "recs14",
    },
  );
};

export const trackQuestionnaireBackButton = ({
  questionNumber,
  questionType,
  questionValue,
}) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Member Preference Modal",
    {
      spring_doc_id: "recs15",
      location: "Back Button",
      question_number: questionNumber,
      question_type: questionType,
      question_value: questionValue,
    },
  );
};

export const trackQuestionnaireSkipButton = ({
  questionNumber,
  questionType,
  questionValue,
}) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Member Preference Modal",
    {
      spring_doc_id: "recs16",
      location: "Skip to Recommendations Button",
      question_number: questionNumber,
      question_type: questionType,
      question_value: questionValue,
    },
  );
};

export const trackQuestionnaireContinueButton = ({
  questionNumber,
  questionType,
  questionValue,
}) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Member Preference Modal",
    {
      spring_doc_id: "recs17",
      location: "Continue Arrow Button",
      question_number: questionNumber,
      question_type: questionType,
      question_value: questionValue,
    },
  );
};

export const trackQuestionnaireSubmitted = () => {
  TRACK_EVENT.FORM_SUBMITTED(
    window.location.pathname,
    "Member Preference Modal",
    {
      spring_doc_id: "recs18",
    },
  );
};

export const trackMemberPreferencesBannerViewed = () => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Member Preferences Banner",
    {
      spring_doc_id: "recs019",
    },
  );
};

export const trackMemberPreferencesBannerClicked = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Member Preferences Banner",
    {
      spring_doc_id: "recs020",
    },
  );
};

export const trackIntroDependentModalGoBackButtonClicked = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Dependent Therapy Modal",
    {
      spring_doc_id: "recs21",
      location: "Back Button",
    },
  );
};

export const trackIntroDependentModalSubmitButtonClicked = (url) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Dependent Therapy Modal",
    {
      spring_doc_id: "recs22",
      location: "Yes Button",
      url,
    },
  );
};

export const trackIntroDependentModalOpened = () => {
  TRACK_EVENT.MODAL_OPENED(
    window.location.pathname,
    "Dependent Therapy Modal",
    {
      spring_doc_id: "recs23",
    },
  );
};
