export enum SessionViewStatus {
  BrowserPermissionsDenied = "browser_permissions_denied",
  SystemPermissionsDenied = "system_permissions_denied",
  CameraInUsePermissionsDenied = "camera_in_use_permissions_denied",
  AmbiguousPermissionsDenied = "ambiguous_permissions_denied",
  LikelyFirewallError = "likely_firewall_error",
  NotStarted = "not_started",
  Connecting = "connecting",
  MemberIsWaiting = "member_is_waiting",
  ProviderNoShow = "provider_no_show",
  InProgress = "in_progress",
  Ended = "ended",
}

// used for defining the type of media
export enum MediaType {
  Microphone = "microphone",
  Camera = "camera",
}

export enum ParticipantType {
  Member = "member",
  Provider = "provider",
  Unknown = "unknown",
}

// used for defining the type of LiveKit's VideoTrack
export interface TrackType {
  participant: {
    isLocal: boolean;
    sid: string;
  };
}

export enum DeviceTypes {
  AudioOutput = "audiooutput",
  AudioInput = "audioinput",
  VideoInput = "videoinput",
}

export enum ToggleButtonTypes {
  Camera = "camera",
  Chat = "chat",
  Microphone = "microphone",
  Participants = "participants",
  Screensharing = "screensharing",
  Faq = "faq",
}

export enum DrawerTabTypes {
  Participants = "participants",
  Chat = "chat",
}

// Safari doesn't support audio output devices, this is copy for a fake device
export enum SafariAudioDevice {
  SystemDefaultSpeaker = "System Default Speaker",
  SystemDefaultMicrophone = "System Default Microphone",
  DeviceId = "virtual",
}

export type SessionRecordingConsent = {
  id: string;
  appointment_id: string;
  care_provider_consent: boolean;
  member_consent: boolean;
  created_at: string;
};
