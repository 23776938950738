"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 * @example
 * const string = 'hello world';
 * const newString =  truncateString(string, 5);
 *
 * console.log(string, newString); // 'hello world', 'hello...'
 */

function truncateString(string, num) {
  if (!string) {
    return '';
  } else if (string.length <= num) {
    return string;
  }
  // Return string truncated with '...' concatenated to the end of string.
  return string.slice(0, num) + '...';
}
var _default = exports["default"] = (0, _fp.curry)(truncateString);