import { createIcon } from "@chakra-ui/react";

export const Document = createIcon({
  displayName: "Document",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C5 2.44772 5.44772 2 6 2H10H18C18.5523 2 19 2.44772 19 3V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V7V3ZM6 0C4.34315 0 3 1.34315 3 3V7V21C3 22.6569 4.34315 24 6 24H18C19.6569 24 21 22.6569 21 21V3C21 1.34315 19.6569 0 18 0H10H6ZM8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8H16C16.5523 8 17 7.55228 17 7C17 6.44772 16.5523 6 16 6H8ZM7 11C7 10.4477 7.44772 10 8 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H8C7.44772 12 7 11.5523 7 11ZM8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16H13C13.5523 16 14 15.5523 14 15C14 14.4477 13.5523 14 13 14H8Z"
    />
  ),
  defaultProps: {
    "aria-label": "Document icon",
    role: "img",
  },
});
