import { createIcon } from "@chakra-ui/react";

export const Language = createIcon({
  displayName: "Language",
  viewBox: "0 0 24 24 ",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 4.23858 4.23858 2 7 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H7C3.13401 0 0 3.13401 0 7V13.5654C0 15.353 2.16609 16.2427 3.42256 14.9713L4.35863 14.024C5.79529 16.1584 8.23368 17.5625 11 17.5625H14.0833L16.8 19.6C17.406 20.0545 18.2169 20.1276 18.8944 19.7889C19.572 19.4501 20 18.7575 20 18V11C20 6.58172 16.4183 3 12 3H7C4.79086 3 3 4.79086 3 7V9.5625C3 10.4599 3.14777 11.3228 3.4203 12.1282L2 13.5654V7ZM18 11V15.5V18L16 16.5L14.75 15.5625H11C7.68629 15.5625 5 12.8762 5 9.5625V7C5 5.89543 5.89543 5 7 5H12C15.3137 5 18 7.68629 18 11ZM8.00006 7.00003C7.44778 7.00003 7.00006 7.44775 7.00006 8.00003C7.00006 8.55232 7.44778 9.00003 8.00006 9.00003H8.15846C8.42091 10.0805 8.94818 11.0507 9.66493 11.8426C9.28492 11.9454 8.88589 12 8.47513 12H8.00006C7.44778 12 7.00006 12.4477 7.00006 13C7.00006 13.5523 7.44778 14 8.00006 14H8.47513C9.5623 14 10.5924 13.734 11.5001 13.2609C12.4078 13.734 13.4378 14 14.525 14H15.0001C15.5524 14 16.0001 13.5523 16.0001 13C16.0001 12.4477 15.5524 12 15.0001 12H14.525C14.1142 12 13.7152 11.9454 13.3352 11.8426C14.052 11.0507 14.5792 10.0805 14.8417 9.00003H15.0001C15.5524 9.00003 16.0001 8.55232 16.0001 8.00003C16.0001 7.44775 15.5524 7.00003 15.0001 7.00003H12.5001C12.5001 6.44775 12.0524 6.00003 11.5001 6.00003C10.9478 6.00003 10.5001 6.44775 10.5001 7.00003H8.00006ZM11.5001 10.8497C10.942 10.3535 10.5056 9.72078 10.2446 9.00003H11.5001H12.7555C12.4945 9.72078 12.0582 10.3535 11.5001 10.8497Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    "aria-label": "Language icon",
    role: "img",
  },
});
