import { gql } from "@apollo/client";

const teenCareTeamMember = gql`
  fragment teenCareTeamMember on CareProvider {
    id
    created_at
    name
    avatar
  }
`;

export default teenCareTeamMember;
