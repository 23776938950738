import { AppointmentAction, AppointmentKind } from "@spring/constants";
import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { images, ProviderRole } from "@spring/constants";
import { CareProviderScheduleModalV2 } from "components/modals";
import { SHCareCard } from "design-system/components/custom/cards/SHCareCard";
import { useScheduleModalWithProps } from "shared/hooks";
import { getIsUniqueCCACopy } from "utils/employerServicesHelpers";
import { getOr } from "lodash/fp";
import { getCustomerSite } from "operations/queries/customerSite";
import { useQuery } from "@apollo/client";
import { getMemberInfo } from "operations/queries/member";
import Meowth from "@spring/meowth";
interface CareAdvocateCardPropsType {
  customerId?: string;
  title?: string;
  subtitle?: string;
  width?: string[];
  wrapperStyles?: Record<string, any>;
}

const CareAdvocateCard = ({
  customerId,
  subtitle,
  title,
  width,
  wrapperStyles = { mr: [0, 0, 8], mb: [11, 11, 0] },
}: CareAdvocateCardPropsType): ReactElement => {
  const { isOpen, onOpen, onClose, modalProps } = useScheduleModalWithProps();
  const { t } = useTranslation("careVisits");
  const isUniqueCCACopy = customerId && getIsUniqueCCACopy(customerId);
  const stringKey = isUniqueCCACopy ? customerId : "default";

  const { data: memberInfo, loading: memberLoading } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );

  const member = getOr(memberInfo, "user.member", memberInfo); // check if nested member, else use top level object

  const customerSiteId = member?.customer_site_id;
  const shouldLoadCustomerSite = customerSiteId && !memberLoading;
  const { data: { customer_site: customerSite } = {} } = useQuery(
    getCustomerSite,
    {
      variables: {
        id: customerSiteId,
      },
      skip: !shouldLoadCustomerSite, // Don't load if we don't need it
    },
  );

  const ccaProvider = customerSite?.care_providers[0];

  const openModal = () => {
    onOpen({
      action: AppointmentAction.Create,
      buttonText: "Confirm Appointment",
      kind: AppointmentKind.WellnessAndSocialSupportSession,
      mpLocation: "SDOH Cards",
      provider: ccaProvider,
      providerRole: ProviderRole.CareAdvocate, // Passing in an enum, though it'll be converted to a string
      // TODO: Fix telemetry props
      telemetryProps: {
        location: "CCA Card",
        springDocId: "123",
      },
    });
  };

  return (
    <>
      <Box {...wrapperStyles}>
        <SHCareCard
          buttonTrackingText="CCA Care Card"
          cardSubtitle={
            subtitle ||
            t(`findNewCareTab.sdohCards.cards.${stringKey}.cca.description`)
          }
          cardTitle={
            title || t(`findNewCareTab.sdohCards.cards.${stringKey}.cca.title`)
          }
          componentTrackingText="CCA Care Card"
          imageAlt={t(
            `findNewCareTab.sdohCards.cards.${stringKey}.cca.imageAlt`,
          )}
          imageUrl={images.CareAdvocateCard}
          onClick={openModal}
          onClickTrackingText="CCA Modal Opened"
          testId="cca-card"
          width={width}
        />
      </Box>
      <CareProviderScheduleModalV2
        isOpen={isOpen}
        onClose={onClose}
        {...modalProps}
      />
    </>
  );
};

export default CareAdvocateCard;
