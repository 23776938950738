//

import PropTypes from "prop-types";
import React from "react";
import { Backdrop, Section, Icon, Stout } from "@spring/smeargle";

import Link from "components/atoms/Link";

import styles from "./styles.module.scss";

const VerifyPhone = (props) => (
  <Section>
    <Stout>Verify your phone number</Stout>
    <Section>
      <Backdrop color="primary">
        <div className={styles.verifyPhone}>
          <Icon type="phone" />

          <div className={styles.number}>&lrm;{props.phone}</div>

          <div className={styles.link}>
            <Link
              alias={props.editLinkAlias}
              ariaLabel="Change Member Settings"
            >
              change
            </Link>
          </div>
        </div>
      </Backdrop>
    </Section>
  </Section>
);

VerifyPhone.propTypes = {
  editLinkAlias: PropTypes.any,
  phone: PropTypes.any,
  replaceMe: PropTypes.string,
};

VerifyPhone.defaultProps = {
  replaceMe: "sailor",
};

export default VerifyPhone;
