import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SHSliderInput, Box, Text, Heading } from "design-system/components";
import { setField } from "actions/assessment/actions";
import { SpringFormState } from "components/assessments/types";
import { TRACK_EVENT } from "utils/mixpanel";

import { useRef } from "react";

type ConfidenceProps = {
  fieldKey: string;
  formKey: string;
};

const Confidence = ({ fieldKey, formKey }: ConfidenceProps) => {
  const { t } = useTranslation(["assessments", "a11y"]);
  const ref = useRef(null);

  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      "Confidence Screener",
    );
  }, []);

  const answer = useSelector((state: SpringFormState) => {
    return state.form[formKey]?.data[fieldKey];
  });

  const dispatch = useDispatch();

  const handleChange = (val) => {
    dispatch(setField(formKey, fieldKey, val, "string"));
  };

  useEffect(() => {
    ref?.current?.focus();
  }, []);

  return (
    <div>
      <Box mb={"10px"}>
        <Heading
          tabIndex={0}
          as="h1"
          fontSize={"29px"}
          fontWeight={700}
          ref={ref}
        >
          {t("confidenceForm.title")}
        </Heading>
        <Text tabIndex={0}>{t("confidenceForm.subtitle")}</Text>
      </Box>
      <SHSliderInput
        min={0}
        max={10}
        step={1}
        value={answer}
        onChange={handleChange}
        leftLabel={t("confidenceForm.leftLabel")}
        rightLabel={t("confidenceForm.rightLabel")}
        dataCy="confidence-slider"
        toolTipRef={ref}
        labelModifier={(label) => t("confidenceForm.label", { number: label })}
      />
    </div>
  );
};

export { Confidence };
