import React from 'react';
import { components } from 'react-select';

import styles from './styles.module.scss';

export const MultiValue = (props) => (
	<components.MultiValueContainer {...props}>
		<components.Placeholder {...props}>
			{props.selectProps.placeholder}
		</components.Placeholder>
	</components.MultiValueContainer>
);

export const Option = (props) => {
	return (
		<div>
			<components.Option {...props}>
				<span>
					<input
						type="checkbox"
						className={styles.checkboxField}
						checked={props.isSelected}
						onChange={() => null}
					/>
				</span>{' '}
				<label>{props.label || 'Actions'} </label>
			</components.Option>
		</div>
	);
};