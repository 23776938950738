import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  VCareNavigatorDuotoneSpot,
  VCareNavigatorFlatSpot,
} from "@springcare/verdant-icons-react";
import { InformationFlyout } from "components";
import { useTranslation } from "react-i18next";

type CareNavigationFlyoutType = {
  callback: () => void;
  hasManagedDependents: boolean;
  isAlsoAvailableTile?: boolean;
  isOpen: boolean;
  onClose: () => void;
  showGlobalExperience: boolean;
};

const CareNavigationFlyoutBody = ({
  isAlsoAvailableTile,
  showGlobalExperience,
}: {
  isAlsoAvailableTile: boolean;
  showGlobalExperience: boolean;
}) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.careNavigation",
  });

  return (
    <>
      <Text mb={40}>{t("callAtNoCost")}</Text>
      {isAlsoAvailableTile ? (
        ""
      ) : (
        <>
          <Heading size="heading-medium" mb="v-8">
            {t("whyIsThisRecommendedHeader")}
          </Heading>
          <Text mb={24}>{t("whyIsThisRecommendedBody")}</Text>
        </>
      )}
      <Heading size="heading-small" mb="v-8">
        {t("benefitsHeader")}
      </Heading>
      <Text mb={24}>
        {showGlobalExperience ? t("benefitsBodyGlobal") : t("benefitsBody")}
      </Text>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpect.header")}
      </Heading>
      <Text mb={24}>
        {showGlobalExperience
          ? t("whatToExpect.bodyGlobal")
          : t("whatToExpect.body")}
      </Text>
      <Heading size="heading-small" mb="v-8">
        {t("commonConcerns.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item1Q")}
          </Text>{" "}
          {t("commonConcerns.item1A")}{" "}
        </ListItem>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item2Q")}
          </Text>{" "}
          {t("commonConcerns.item2A")}
        </ListItem>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item3Q")}
          </Text>{" "}
          {t("commonConcerns.item3A")}
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const CareNavigationFlyout = ({
  callback,
  hasManagedDependents,
  isAlsoAvailableTile = false,
  isOpen,
  onClose,
  showGlobalExperience,
}: CareNavigationFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.careNavigation",
  });

  return (
    <InformationFlyout
      headerText={hasManagedDependents ? t("familyHeading") : t("heading")}
      headerIcon={<VCareNavigatorFlatSpot />}
      heroIllustration={VCareNavigatorDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaType="button"
      ctaCallback={callback}
    >
      <CareNavigationFlyoutBody
        isAlsoAvailableTile={isAlsoAvailableTile}
        showGlobalExperience={showGlobalExperience}
      />
    </InformationFlyout>
  );
};
