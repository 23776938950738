import { fontWeights } from "./fontWeights";

// Define the new text styles
// Version numbers in this file align with our Figma typography version numbers.
export const textStyles = {
  /***************** VERSION 0 *****************/
  /* FOR USE WITH <Heading> components */

  //************DO NOT USE ANY VERSION 0 STYLES***************

  display: {
    fontSize: 40,
    fontWeight: fontWeights.bold,
  },
  lg: {
    fontSize: 32,
    fontWeight: fontWeights.medium,
  },
  md: {
    fontSize: 24,
    fontWeight: fontWeights.bold,
  },
  sm: {
    fontSize: 24,
    fontWeight: fontWeights.medium,
  },
  // TODO: Delete exsm once all uses of it are removed. exsm was temporary and never officially part of the design system.
  exsm: {
    fontSize: 22,
    fontWeight: fontWeights.bold,
  },
  /* FOR USE WITH <Text> components */
  // TODO: Outdated & Deprecated as official design-system - replace after Design QA
  subtitle1: {
    fontSize: 18,
    fontWeight: fontWeights.bold,
  },
  // TODO: Outdated & Deprecated as official design-system - replace after Design QA
  subtitle2: {
    fontSize: 18,
    fontWeight: fontWeights.medium,
  },
  // TODO: Delete subtitle3 once all uses of it are removed. subtitle3 was temporary and never officially part of the design system.
  subtitle3: {
    fontSize: 12,
    fontWeight: fontWeights.medium,
  },
  body1: {
    fontSize: 16,
    fontWeight: fontWeights.medium,
  },
  body2: {
    fontSize: 14,
    fontWeight: fontWeights.medium,
  },

  /***************** VERSION 1 *****************/
  /* FOR USE WITH <Heading> components */
  display_v1: {
    fontSize: 51,
    fontFamily: "HKGroteskBold, sans serif",
    fontWeight: fontWeights.bold,
  },
  lg_v1: {
    fontSize: 38,
    fontFamily: "HKGroteskBold, sans serif",
    fontWeight: fontWeights.bold,
  },
  md_v1: {
    fontSize: 29,
    fontFamily: "HKGroteskBold, sans serif",
    fontWeight: fontWeights.bold,
  },
  sm_v1: {
    fontSize: 21,
    fontFamily: "HKGroteskSemiBold, sans serif",
    fontWeight: fontWeights.semiBold,
  },
  /* FOR USE WITH <Text> components */
  body_v1: {
    fontSize: 16,
    fontWeight: fontWeights.regular,
  },
  bodyBold_v1: {
    fontSize: 16,
    fontFamily: "HKGroteskBold, sans serif",
    fontWeight: fontWeights.bold,
  },
  caption_v1: {
    fontSize: 12,
    fontWeight: fontWeights.regular,
  },
  captionBold_v1: {
    fontSize: 12,
    fontFamily: "HKGroteskBold, sans serif",
    fontWeight: fontWeights.bold,
  },
  data1_v1: {
    fontSize: 9,
    fontFamily: "HKGroteskSemiBold, sans serif",
    fontWeight: fontWeights.semiBold,
  },
  data2_v1: {
    fontSize: 7,
    fontFamily: "HKGroteskSemiBold, sans serif",
    fontWeight: fontWeights.semiBold,
  },
};
