//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "EPDS.q1": numberField(),
};
const prompt2Schema = {
  "EPDS.q2": numberField(),
};
const prompt3Schema = {
  "EPDS.q3": numberField(),
};
const prompt4Schema = {
  "EPDS.q4": numberField(),
};
const prompt5Schema = {
  "EPDS.q5": numberField(),
};
const prompt6Schema = {
  "EPDS.q6": numberField(),
};
const prompt7Schema = {
  "EPDS.q7": numberField(),
};
const prompt8Schema = {
  "EPDS.q8": numberField(),
};
const prompt9Schema = {
  "EPDS.q9": numberField(),
};
const prompt10Schema = {
  "EPDS.q10": numberField(),
};

export const EPDSSchema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...prompt7Schema,
  ...prompt8Schema,
  ...prompt9Schema,
  ...prompt10Schema,
};

const EPDS = [
  {
    id: "EPDS--1",
    title:
      "In the past seven days, have you been able to **laugh and see the funny side of things**?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="EPDS.q1"
        options={[
          { value: 0, label: "As much as I always could" },
          { value: 1, label: "Not quite so much now" },
          { value: 2, label: "Definitely not so much now" },
          { value: 3, label: "Not at all" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--2",
    title:
      "In the past seven days, have you **looked forward with enjoyment to things**?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="EPDS.q2"
        options={[
          { value: 0, label: "As much as I ever did" },
          { value: 1, label: "Rather less than I used to" },
          { value: 2, label: "Definitely less than I used to" },
          { value: 3, label: "Hardly at all" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--3",
    title:
      "In the past seven days, have you **blamed yourself unnecessarily when things went wrong**?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="EPDS.q3"
        options={[
          { value: 3, label: "Yes, most of the time" },
          { value: 2, label: "Yes, some of the time" },
          { value: 1, label: "Not very often" },
          { value: 0, label: "No, never" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--4",
    title:
      "In the past seven days, have you been **anxious or worried for no good reason**?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="EPDS.q4"
        options={[
          { value: 0, label: "No, not at all" },
          { value: 1, label: "Hardly ever" },
          { value: 2, label: "Yes, sometimes" },
          { value: 3, label: "Yes, very often" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--5",
    title:
      "In the past seven days, have you **felt scared or panicky for no very good reason**?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="EPDS.q5"
        options={[
          { value: 3, label: "Yes, quite a lot " },
          { value: 2, label: "Yes, sometimes" },
          { value: 1, label: "No, not much" },
          { value: 0, label: "No, not at all" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--6",
    title:
      "In the past seven days, have **things been getting on top of you**?",
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <RadioButtons
        key={6}
        border={true}
        fieldKey="EPDS.q6"
        options={[
          {
            value: 3,
            label: "Yes, most of the time I haven't been able to cope at all",
          },
          {
            value: 2,
            label: "Yes, sometimes I haven't been coping as well a usual",
          },
          { value: 1, label: "No, most of the time I have coped quite well" },
          { value: 0, label: "Not at all" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--7",
    title:
      "In the past seven days, have you **been so unhappy that you have had difficulty sleeping**?",
    schema: yup.object().shape(prompt7Schema),
    questions: [
      <RadioButtons
        key={7}
        border={true}
        fieldKey="EPDS.q7"
        options={[
          { value: 3, label: "Yes, most of the time " },
          { value: 2, label: "Yes, quite often" },
          { value: 1, label: "Not very often" },
          { value: 0, label: "No, not at all" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--8",
    title: "In the past seven days, have you **felt sad or miserable**?",
    schema: yup.object().shape(prompt8Schema),
    questions: [
      <RadioButtons
        key={8}
        border={true}
        fieldKey="EPDS.q8"
        options={[
          { value: 3, label: "Yes, most of the time" },
          { value: 2, label: "Yes, quite often" },
          { value: 1, label: "Not very often" },
          { value: 0, label: "No, not at all" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--9",
    title:
      "In the past seven days, have you been **so unhappy that you cried**?",
    schema: yup.object().shape(prompt9Schema),
    questions: [
      <RadioButtons
        key={9}
        border={true}
        fieldKey="EPDS.q9"
        options={[
          { value: 3, label: "Yes, most of the time" },
          { value: 2, label: "Yes, quite often" },
          { value: 1, label: "Only occassionally" },
          { value: 0, label: "No, never" },
        ]}
      />,
    ],
  },
  {
    id: "EPDS--10",
    title:
      "In the past seven days, has the **thought of harming yourself occurred to you**?",
    schema: yup.object().shape(prompt10Schema),
    questions: [
      <RadioButtons
        key={10}
        border={true}
        fieldKey="EPDS.q10"
        options={[
          { value: 3, label: "Yes, quite often" },
          { value: 2, label: "Sometimes" },
          { value: 1, label: "Hardly ever" },
          { value: 0, label: "Never" },
        ]}
      />,
    ],
  },
];

export default EPDS;
