import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { getCareTeam } from "operations/queries/careProvider";

export function useCareTeam() {
  return useQuery(getCareTeam, {
    ...Meowth.apolloOptionsUserId(),
    fetchPolicy: "network-only",
    name: "careTeam",
  });
}
