"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useWindowSize;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function useWindowSize() {
  var _useState = (0, _react.useState)(window.innerWidth),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    windowWidth = _useState2[0],
    setWindowWidth = _useState2[1];
  var handleResize = function handleResize() {
    setWindowWidth(window.innerWidth);
  };
  (0, _react.useEffect)(function () {
    window.addEventListener("resize", handleResize);
    return function () {
      return window.removeEventListener("resize", handleResize);
    };
  }, []);
  return windowWidth;
}