import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { timeFormats } from "@spring/constants";
import { getAppointments } from "operations/queries/appointment";
import { AppointmentKind, AppointmentAttendedValues } from "@spring/constants";
import { ALSO_AVAILABLE_TILE_TYPES } from "modules/MemberDashboard/Teen/Home/components/AlsoAvailableToYou/constants";
import { useTeenAssessmentStatus } from "./useTeenAssessmentStatus";
import { assessments as assessmentConstants } from "@spring/constants";
import Meowth from "@spring/meowth";

export const useTeenAvailableItems = (hasATherapistAssigned = false) => {
  const { data, loading } = useQuery(getAppointments, {
    variables: {
      attended: AppointmentAttendedValues.Kept,
      starting_before: DateTime.local()
        .toUTC()
        .toFormat(timeFormats.datePickerFormat),
      sort_by: [
        {
          column: "appointments.start_at",
          direction: "desc",
        },
      ],
    },
  });

  const {
    assessmentStatus: teenAssessmentStatus,
    loading: teenAssessmentStatusLoading,
  } = useTeenAssessmentStatus(!Meowth.apolloOptionsUserId());
  const { assessmentStatuses } = assessmentConstants;

  const teenCompletedInitialAssessment =
    teenAssessmentStatus === assessmentStatuses.FINISHED;

  const hasTakenInitialCN =
    data?.appointments?.data?.filter((appt) => {
      return appt.kind === AppointmentKind.InitialCareNavigation;
    }).length > 0;

  const hasTakenTherapy =
    data?.appointments?.data?.filter((appt) => {
      return (
        appt.kind === AppointmentKind.MinorTherapy ||
        appt.kind === AppointmentKind.Therapy
      );
    }).length > 0;

  const showTherapyJourneyCard = !hasTakenTherapy && !hasATherapistAssigned;
  const startTherapyJourney =
    showTherapyJourneyCard && ALSO_AVAILABLE_TILE_TYPES.THERAPY;
  const teenCheckIn =
    hasTakenTherapy &&
    teenCompletedInitialAssessment &&
    ALSO_AVAILABLE_TILE_TYPES.TEEN_CHECK_IN;
  const downloadMobileApp = ALSO_AVAILABLE_TILE_TYPES.DOWNLOAD_APP;
  const careNavigation =
    !hasTakenInitialCN && ALSO_AVAILABLE_TILE_TYPES.CARE_NAVIGATION;

  const availableItems = [
    startTherapyJourney,
    teenCheckIn,
    downloadMobileApp,
    careNavigation,
  ].filter((item) => item);

  const teenAvailableItemsLoading = loading || teenAssessmentStatusLoading;

  return { availableItems, hasTakenTherapy, teenAvailableItemsLoading };
};
