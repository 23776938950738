import { Box, Center, Flex, Text } from "design-system/components";
import PropTypes from "prop-types";
import React from "react";

export const SHFormNotification = ({ text, icon, children, ...otherProps }) => {
  return (
    <Box mt={16} p={16} bg="warning.light" {...otherProps}>
      <Flex alignItems="center">
        {icon && (
          <Box width="40px">
            {React.cloneElement(icon, {
              width: "100%",
              fill: "#916010",
            })}
          </Box>
        )}
        <Text ml={4} color="warning.on" fontWeight="bold">
          {text}
        </Text>
      </Flex>
      {children && <Center>{children}</Center>}
    </Box>
  );
};

SHFormNotification.propTypes = {
  children: PropTypes.component,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
};
