/* eslint-disable no-mixed-spaces-and-tabs */
// linting is exploding on the isInternational logic below, no formatting works -- temp disabled.

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import {
  Bolded,
  Breakpoint,
  Content,
  Card,
  FlexRow,
  Icon,
  Subhead,
} from "@spring/smeargle";
import { PotentialMemberType } from "@spring/constants";
import { useTranslation } from "react-i18next";
import { updateFindMyMembershipModel } from "actions/signUp";
import routes from "routes";
import {
  track,
  TRACK_EVENT,
  CARD_TYPE,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";

import styles from "./styles.module.scss";

const mpPrefix = "Who is signing up? (/sign_up)";

const MemberVerificationMenu = (props) => {
  const router = useRouter();

  useEffect(() => {
    track(`${mpPrefix} -- Viewed`, {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
        page: routes.SignUp.as,
      }),
    });
  }, []);

  const { t } = useTranslation("limitedLangAuth");
  const isInternational = props.currentCountry !== "US";

  const initialContent = () => {
    return (
      <>
        <div role="main">
          <div className={styles.header}>
            <Subhead semibold center inheritColor noMargin>
              {t("memberVerificationMenu.subhead")}
            </Subhead>

            <div className={styles.headerSubtext}>
              <Content inheritColor>
                {t("memberVerificationMenu.content")}
              </Content>
            </div>
          </div>

          <div className={styles.initialContentEmpCard}>
            <Card
              dataCy="sign-up-me"
              onClick={() => {
                track(`${mpPrefix} -- Primary Card Clicked`, {
                  deprecated: true,
                  replaced_with: makeEventString(EVENT_TYPE.CARD_CLICKED, {
                    page: routes.SignUp.as,
                    to: routes.Verification.as,
                    type: CARD_TYPE.PRIMARY,
                  }),
                });
                TRACK_EVENT.CARD_CLICKED(
                  routes.SignUp.as,
                  routes.Verification.as,
                  CARD_TYPE.PRIMARY,
                );
                props.updateFindMyMembershipModel({
                  fieldKey: "potential_member_type",
                  value: PotentialMemberType.Employee,
                });
                let { to, as } = routes.Verification;
                router.push(to, as);
              }}
              radius="md"
            >
              <FlexRow alignment="center" justification="space-between">
                <div>
                  <Bolded>{t("memberVerificationMenu.me.head")}</Bolded>

                  <div className={styles.initialContentEmpDepSubtext}>
                    <Content inheritColor>
                      {t("memberVerificationMenu.me.content")}
                    </Content>
                  </div>
                </div>

                <Subhead>
                  <Icon type="arrow-right" />
                </Subhead>
              </FlexRow>
            </Card>
          </div>

          <div className={styles.initialContentDepCard}>
            <Card
              dataCy="sign-up-dependent"
              onClick={() => {
                track(`${mpPrefix} -- Dependent Card Clicked`, {
                  deprecated: true,
                  replaced_with: makeEventString(EVENT_TYPE.CARD_CLICKED, {
                    page: routes.SignUp.as,
                    to: routes.Verification.as,
                    type: CARD_TYPE.DEPENDENT,
                  }),
                });
                TRACK_EVENT.CARD_CLICKED(
                  routes.SignUp.as,
                  routes.Verification.as,
                  CARD_TYPE.DEPENDENT,
                );
                props.updateFindMyMembershipModel({
                  fieldKey: "potential_member_type",
                  value: PotentialMemberType.Dependent,
                });
                let { to, as } = routes.Verification;
                router.push(to, as);
              }}
              radius="md"
            >
              <FlexRow alignment="center" justification="space-between">
                <div>
                  <Bolded>{t("memberVerificationMenu.dependent.head")}</Bolded>

                  <div className={styles.initialContentEmpDepSubtext}>
                    <Content inheritColor>
                      {!isInternational && (
                        <>{t("memberVerificationMenu.dependent.content")}</>
                      )}
                      {isInternational && (
                        <>{t("memberVerificationMenu.dependent.intlContent")}</>
                      )}
                    </Content>
                  </div>
                </div>

                <Subhead>
                  <Icon type="arrow-right" />
                </Subhead>
              </FlexRow>
            </Card>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Breakpoint xs>
        <Card color="GREY_100" padding="verification-menu-sm" radius="md">
          {initialContent()}
        </Card>
      </Breakpoint>

      <Breakpoint sm>
        <Card color="GREY_100" padding="verification-menu-sm" radius="md">
          {initialContent()}
        </Card>
      </Breakpoint>

      <Breakpoint md andUp>
        <Card color="GREY_100" padding="verification-menu-lg" radius="md">
          {initialContent()}
        </Card>
      </Breakpoint>
    </>
  );
};

MemberVerificationMenu.propTypes = {
  currentCountry: PropTypes.string,
  updateFindMyMembershipModel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentCountry: state.global?.country, // from LocaleWrapper
});

export default connect(mapStateToProps, { updateFindMyMembershipModel })(
  MemberVerificationMenu,
);
