// 
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';


import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

import { formFieldSelector } from '../../../selectors/form';
import { setField } from '../../../actions/form';




class RadioButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabbedFormKey     : null,
			tabbedFieldKey    : null,
			tabbedTargetValue : null,
		};
	}

	handleChange = (e) => {
		const nativeEvent = e?.nativeEvent;

		if (nativeEvent?.screenX === 0 && nativeEvent?.screenY === 0) { // this is a tab 
			this.setState({
				tabbedFormKey     : this.props.formKey,
				tabbedFieldKey    : this.props.fieldKey,
				tabbedTargetValue : e.target.value,
			});
		} else { // this is a click
			this.props.setField(
				this.props.formKey,
				this.props.fieldKey,
				e.target.value,
				true
			);
		}
	};

	handleKeyDown = (e) => {
		if (
			e.key === 'Enter' &&
			this.state.tabbedFormKey &&
			this.state.tabbedFieldKey &&
			this.state.tabbedTargetValue
		) {
			this.props.setField(
				this.state.tabbedFormKey,
				this.state.tabbedFieldKey,
				this.state.tabbedTargetValue,
				true
			);
		}
	}

	get label() {
		if (this.props.label) {
			return (
				<Label
					disabled={this.props.disabled}
					formKey={this.props.formKey}
					fieldKey={this.props.fieldKey}
					theme={this.props.theme}
					className={styles.radioLabel}
				>
					{this.props.label}
				</Label>
			);
		}

		return null;
	}

	sanitizeLabel = (label) => {
		return label ? label.replace(/ /g, '_').replace(/[^\w\d]/g, '') : '';
	}

	fieldLabel = (label) => {
		if (label) {
			return (
				<Label
					disabled={this.props.disabled}
					formKey={this.props.formKey}
					fieldKey={this.props.fieldKey}
					theme={this.props.theme}
					for={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel(label)}`}
					required={false}
				>
					{ /* REQUIRED=FALSE is used to turn of the required star manually */ }
					{label}
				</Label>
			);
		}

		return null;
	}

	get showValidation() {
		// show if the field has an error and is dirty
		return !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);
	}

	get options() {
		return this.props.options.map((opt, index) => {
			// loose equality for different value types
			const val = this.props.value ? this.props.value.toString() : undefined;
			const checked = (val === opt.value.toString() || this.state.tabbedTargetValue === opt.value.toString());

			return (
				<label
					className={classnames(styles.option, {
						[sharedStyles.border]       : this.props.border,
						[sharedStyles.activeBorder] : checked,
					})}
					key={opt.label}
				>
					<input
						id={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel(opt.label)}`}
						value={opt.value}
						className={classnames(styles.radioField, sharedStyles.radioField, {
							[styles.invalid]           : this.showValidation,
							[styles[this.props.theme]] : this.props.theme,
							[sharedStyles.invalid]     : this.showValidation,
							[styles.checkBoxRadio]     : this.props.type === 'checkbox',
						})}
						type={ this.props.type || 'radio' }
						disabled={this.props.disabled}
						onChange={this.handleChange}
						checked={checked}
						placeholder={this.props.placeholder}
						data-cy={this.props.dataCy ? `${this.props.dataCy}-option-${index}-radio-button` : ''}
						onKeyDown={this.handleKeyDown}
					/>
					{this.fieldLabel(opt.label)}
				</label>
			);
		});
	}

	render() {
		return (
			<InputWrapper
				inline={this.props.inline}
				valid={this.showValidation}
				theme={this.props.theme}
				for="radiobutton"
			>
				<div className={classnames(styles.radioGroup, { [sharedStyles.full]: this.props.border })}>
					{this.label}
					<div className={styles.radioOptions}>
						{this.options}
					</div>
				</div>
			</InputWrapper>
		);
	}
}

export { RadioButtons };
export default connect(
	(state, ownProps) => formFieldSelector(state, ownProps),
	{ setField }
)(RadioButtons);
