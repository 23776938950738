const LotusSvg = () => (
  <svg width="163" height="87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M155.58 72.015c-.637-.0559-1.276-.0775-1.915-.0647-.648.0246-1.188.148-.848.2436.565.081 1.138.1038 1.708.0679l2.054-.0525c.329.0067.658-.0222.981-.0863.176-.0679-.219-.1481-.66-.1604-.437-.0076-.875.0068-1.311.0432M145.378 74.0538c-.37-.0559-.745-.0776-1.12-.0648-.379.0247-.693.148-.493.2436.326.0805.663.1035.996.0679l1.2-.0524c.195.0063.389-.023.574-.0864.101-.0679-.127-.148-.386-.1604-.256-.0075-.511.007-.765.0432M134.257 70.9232c-.37-.0559-.745-.0775-1.12-.0648-.379.0247-.694.148-.493.2437.325.0805.663.1034.996.0678l1.2-.0524c.195.0063.389-.023.574-.0864.101-.0678-.127-.148-.386-.1604-.257-.0109-.515.0005-.771.034"
      fill="#3A4A21"
    />
    <path
      d="M57.3042 30.8763C54.0934 18.4804 44.8312 7.83332 33.0799 2.80895c-.3072-.13165-.6416-.18751-.9749-.16289-.3333.02463-.6558.12904-.9403.30441-.2846.17537-.5228.41657-.6946.70328-.1718.2867-.2721.61051-.2925.94411-.4226 6.47704-.3393 13.02824.8697 19.40964 1.3849 7.3099 4.3181 14.4347 9.2098 20.0482 4.6265 5.305 10.8199 9.0555 17.3401 11.7204 6.5203 2.6649 13.4045 4.2687 20.2579 5.8756M85.9045 76.9746c3.1522-28.0674 11.8408-57.5597 40.0535-74.19654 1.619-.95614 3.79-.04935 4.163 1.73648C136.13 33.353 111.924 63.783 85.812 80.1823"
      fill="#F4E5E3"
    />
    <path
      d="M87.2849 60.8282c-1.4127-16.2699-7.4703-32.1942-17.4265-45.7961-3.1213-4.2657-6.7855-8.43566-11.6248-10.99874-1.0209-.54284-2.3225.0401-2.4675 1.10419-1.6902 12.02885-3.1553 24.25205-.66 36.14215 2.6402 12.5871 10.5114 24.9645 23.3298 30.3745"
      fill="#F4E5E3"
    />
    <path
      d="M82.1826 63.6103c-6.0521-9.6124-8.7738-20.9488-7.7454-32.2611C75.4656 20.037 80.187 9.37721 87.8732 1.01381c.2946-.319632.6523-.574742 1.0504-.749233C89.3218.0900862 89.7517 0 90.1864 0s.8647.0900862 1.2629.264577c.3981.174491.7558.429601 1.0504.749233 7.0477 7.71082 8.8613 19.52379 6.7084 29.91799-2.378 11.4799-8.7718 21.6643-15.0515 31.565M130.801 47.726c4.12-3.6646 7.237-8.3196 9.056-13.5247 1.563-4.5525 1.918-9.253 1.021-13.2133-.201-.8852-.978-1.2954-1.87-1.0086-6.289 2.0295-12.584 6.7855-17.164 13.0498-4.935 6.7485-7.809 15.1286-7.711 22.5372"
      fill="#F4E5E3"
    />
    <path
      opacity=".54"
      d="M37.5601 17.006c1.0357 3.4429 2.2884 6.8167 3.7505 10.1012 1.4694 3.2847 3.136 6.4777 4.9905 9.5614 1.8505 3.0844 3.8368 6.0792 5.8879 9.0402 2.0511 2.9609 4.1546 5.8849 6.2242 8.8397-2.2701-2.8037-4.5031-5.6351-6.6344-8.5498-2.1313-2.9147-4.1669-5.9065-6.0422-9.0216-1.866-3.1011-3.504-6.3338-4.901-9.6725-1.3848-3.3327-2.4807-6.7782-3.2755-10.2986zM119.909 18.9059c-1.052 2.6098-2.289 5.1413-3.701 7.5751-1.431 2.4316-3.07 4.7349-4.898 6.8842-1.826 2.1591-3.753 4.1855-5.653 6.2458-1.9 2.0603-3.785 4.1515-5.636 6.2427 1.664-2.2619 3.419-4.4538 5.265-6.5758 1.829-2.1313 3.736-4.1885 5.552-6.3229 1.811-2.1249 3.469-4.3748 4.963-6.7331 1.474-2.3626 2.816-4.8208 4.108-7.316zM135.874 27.4156c-1.378 2.9548-3.355 5.6073-5.505 8.0563-2.176 2.4318-4.496 4.7316-6.946 6.8873-2.437 2.159-4.95 4.2348-7.495 6.2612-2.544 2.0264-5.114 4.0096-7.643 6.0638 2.366-2.2362 4.846-4.3489 7.325-6.4494 2.48-2.1004 4.966-4.1916 7.387-6.3537 2.422-2.1621 4.781-4.3766 7.002-6.7547 1.104-1.1843 2.159-2.4057 3.161-3.7012.127-.1573.259-.3084.38-.4749l.351-.4966.703-.9932c.247-.3084.429-.6878.645-1.0301l.635-1.0148zM90.5659 12.4412L88.959 26.7988c-.2838 2.3903-.5583 4.7869-.8976 7.1741-.1665 1.1937-.3546 2.3873-.5767 3.5748-.1172.5922-.2406 1.1843-.3948 1.7704-.1183.5965-.343 1.1669-.6631 1.684.1459-.2618.25-.5449.3084-.8389.074-.29.1326-.583.1851-.8791.1048-.5921.1789-1.1874.2498-1.7827.148-1.1936.2652-2.3904.3917-3.5871.2375-2.3965.4873-4.793.7587-7.1865.2715-2.3934.583-4.7868.95-7.171.3671-2.3842.7896-4.7561 1.2954-7.1156zM61.2988 15.4021c.5938 3.046 1.4186 6.0424 2.4675 8.9631 1.068 2.9061 2.3463 5.7306 3.8245 8.4511 1.4558 2.7358 3.0504 5.4006 4.6265 8.0901 1.5761 2.6896 3.0844 5.4007 4.497 8.1797-1.6162-2.6587-3.2817-5.2804-4.9349-7.9237-1.6532-2.6432-3.2725-5.2957-4.7499-8.047-1.4901-2.7484-2.7378-5.6216-3.7289-8.5867-.9773-2.9658-1.648-6.024-2.0018-9.1266z"
      fill="#F6BEB6"
    />
    <path
      d="M99.252 74.5102c12.2 1.9617 24.698-.5639 35.178-7.1092 10.481-6.5452 18.235-16.6667 21.826-28.4901l-15.033 9.9994-41.971 25.5999z"
      fill="#C97F75"
    />
    <path
      d="M105.931 60.4179c11.58-9.9919 25.122-17.4512 39.757-21.8987 2.945-.8944 6.091-1.6594 9.092-1.2337.239.0327.468.1188.67.2521.201.1333.37.3102.493.5176.124.2075.199.4401.22.6806.022.2405-.012.4828-.097.7087-2.557 6.7855-7.276 12.7013-12.926 17.2938-6.123 4.9781-13.306 8.5066-20.702 11.2547-9.006 3.3472-18.384 5.5945-27.9287 6.693"
      fill="#F6BEB6"
    />
    <path
      d="M162.674 64.1191c-.426 4.8363-3.374 9.1975-7.193 12.1955-3.818 2.9979-8.448 4.7776-13.114 6.1131-19.391 5.5518-42.5916 2.4335-60.8816-6.0669 26.5526-.3392 81.1886-12.2417 81.1886-12.2417z"
      fill="#C97F75"
    />
    <path
      d="M117.791 67.9807c11.735-2.6961 23.677-4.3945 35.698-5.0768 2.776-.1573 5.805-.1727 8.236.9932.205.1023.378.2598.498.4549.121.195.185.4199.185.6492 0 .2294-.064.4543-.185.6493-.12.1951-.293.3526-.498.4549-24.487 12.7136-53.486 16.5351-80.4242 10.5515"
      fill="#F6BEB6"
    />
    <path
      d="M73.4426 73.77c-13.5899 3.7866-28.0915 2.5206-40.8196-3.5636C19.8949 64.1222 9.80259 53.632 4.21484 40.6783l18.33326 9.4226L73.4426 73.77z"
      fill="#C97F75"
    />
    <path
      d="M64.0594 58.6291c-14.4153-9.8586-30.7333-16.593-47.9058-19.7706-3.4544-.6353-7.12171-1.1042-10.48671-.2529-.26466.0704-.51039.1987-.71948.3755-.20909.1769-.3763.398-.48961.6473-.1133.2493-.16986.5207-.16558.7945.00427.2738.06927.5433.1903.789 3.76905 7.3869 9.86988 13.4785 16.87738 17.9693 7.5813 4.864 16.1835 7.9483 24.9276 10.1227 10.6456 2.6415 21.5712 3.9878 32.5396 4.0097"
      fill="#F6BEB6"
    />
    <path
      d="M0 65.396c.425637 4.8363 3.37426 9.2098 7.18033 12.1954 3.80607 2.9857 8.44797 4.7777 13.11457 6.1132 19.3912 5.5518 42.5915 2.4335 60.8815-6.0669C54.645 77.3046 0 65.396 0 65.396z"
      fill="#C97F75"
    />
    <path
      d="M31.333 55.4244c-4.1181-3.6612-7.2347-8.3118-9.0556-13.5124-1.5638-4.5525-1.9185-9.253-1.0209-13.2133.2004-.8852.9777-1.2954 1.8691-1.0085 6.2889 2.0294 12.584 6.7855 17.1642 13.0497 4.935 6.7486 7.8096 15.1287 7.7109 22.5372M96.2307 57.2164c6.4773-14.8387 18.4253-26.5252 32.1143-31.4602 1.076-.3886 2.211.2437 2.535 1.4589.978 3.6611.956 7.7108.154 11.4984-.959 4.5401-2.985 8.7564-5.511 12.4668-7.261 10.6595-18.947 17.1798-30.5044 17.0163M97.551 53.435c1.3497-2.479 2.1145-5.2338 2.2358-8.0538.1214-2.8199-.4039-5.6303-1.5357-8.216-2.0387-4.679-6.0823-8.8305-11.3318-11.6989-.6-.3115-1.2707-.4614-1.9462-.4349-.6755.0265-1.3324.2285-1.9062.586-7.6738 5.0861-12.6858 12.4854-13.6111 20.2209-.9962 8.3431 2.7759 16.9638 10.1505 23.1602M44.8817 69.2638c-11.7348-2.6961-23.6768-4.3945-35.69801-5.0768-2.77589-.1573-5.80471-.1727-8.235159.9932-.205302.1023-.377997.2598-.498719.4549-.120721.195-.184675.4199-.184675.6493 0 .2293.063954.4542.184675.6492.120722.1951.293417.3526.498719.455C25.435 80.1022 54.4339 83.9236 81.3724 77.94"
      fill="#F6BEB6"
    />
    <path
      d="M73.057 69.452c-2.159-16.4333-12.0289-32.1233-26.4728-42.2553-1.135-.7958-2.7234-.6631-3.5655.3393-2.4966 3.0491-4.0545 6.7575-4.4846 10.6748-.5552 4.6265.2807 9.364 1.903 13.8363C45.0976 64.8902 56.5743 75.67 70.4106 80.1577"
      fill="#F6BEB6"
    />
    <path
      opacity=".6"
      d="M6.81201 40.2866c3.56659 3.0736 7.34269 5.8954 11.30099 8.4449 1.0024.6168 2.0048 1.2337 2.9979 1.8506l3.0628 1.7241 3.1213 1.6162 3.1831 1.4866 3.2292 1.3664 3.2879 1.2337 3.3311 1.1289c1.1268.3249 2.2506.659 3.3712 1.0024 4.5413 1.2441 9.1635 2.1716 13.8332 2.7759-4.7439-.1022-9.4636-.707-14.08-1.8043-1.1514-.292-2.3009-.5902-3.4482-.8945l-3.3928-1.0579-3.3496-1.2337-3.294-1.3726-3.2201-1.5421-3.1491-1.6748-3.0596-1.829c-.9963-.6477-1.9833-1.3078-2.9702-1.9709-3.901-2.6956-7.5061-5.7962-10.75509-9.2499zM153.133 39.3242c-3.244 3.4619-6.846 6.5707-10.745 9.2746-.987.66-1.974 1.3201-2.971 1.9709l-3.062 1.829-3.149 1.6748-3.22 1.5421-3.295 1.3726-3.349 1.2337-3.393 1.0579c-1.147.3064-2.297.6045-3.448.8945-4.62 1.0917-9.343 1.6903-14.089 1.7858 4.669-.6043 9.291-1.5319 13.833-2.7759 1.123-.3413 2.246-.6755 3.371-1.0024l3.331-1.1289 3.288-1.2337 3.232-1.3756 3.183-1.4867 3.122-1.6161 3.062-1.7242c.994-.6168 1.996-1.2337 2.998-1.8506 3.959-2.5485 7.735-5.3693 11.301-8.4418z"
      fill="#C97F75"
    />
    <path
      d="M90.7852 64.1191C103.215 51.131 118.375 44.5984 131.551 46.449c1.036.1449 1.61 1.4558 1.261 2.9794-1.086 4.5778-2.978 8.9256-5.589 12.8401-2.821 4.2247-6.379 7.9074-10.505 10.8723-10.977 7.9267-23.3635 8.889-32.3327 2.5044"
      fill="#C97F75"
    />
    <path
      d="M83.0094 77.3201c4.0096.3547 8.1334-.3578 12.0474-1.5823 3.9356-1.2784 7.7232-2.9741 11.2982-5.0583 3.611-2.0681 7.06-4.4076 10.317-6.9983 1.628-1.2924 3.214-2.6464 4.731-4.0713.753-.7187 1.502-1.4435 2.212-2.2053.721-.7529 1.396-1.5491 2.02-2.3842-.598.8551-1.248 1.673-1.946 2.4489-.685.7865-1.413 1.5422-2.159 2.2824-1.478 1.4774-3.023 2.8839-4.627 4.2379-3.191 2.7125-6.583 5.1788-10.147 7.3777-3.569 2.2352-7.3671 4.0819-11.3291 5.5086-3.9788 1.3849-8.2228 2.2793-12.547 2.0419l.1295-1.5977z"
      fill="#BB5F53"
    />
    <path
      opacity=".44"
      d="M44.7158 30.6049c.5938 3.046 1.4186 6.0424 2.4675 8.9631 1.068 2.9061 2.3462 5.7306 3.8245 8.451 1.4558 2.7358 3.0504 5.4007 4.6265 8.0902 1.5761 2.6896 3.0844 5.4007 4.497 8.1797-1.6162-2.6587-3.2817-5.2804-4.9349-7.9237s-3.2941-5.2927-4.7561-8.047c-1.4901-2.7485-2.7378-5.6216-3.7289-8.5868-.9752-2.966-1.6438-6.0241-1.9956-9.1265zM24.2139 35.4688c1.062 2.9165 2.3447 5.7479 3.8369 8.4695 1.5092 2.7029 3.2131 5.2924 5.0984 7.7478 1.8506 2.4675 3.8585 4.8579 5.8201 7.2698 1.9616 2.412 3.9017 4.8547 5.7183 7.3777-2.014-2.3749-4.0682-4.7036-6.1038-7.0538-2.0357-2.3503-4.059-4.7191-5.9497-7.205-1.9022-2.4819-3.5844-5.1249-5.0275-7.899-1.4254-2.7788-2.5622-5.6964-3.3927-8.707zM86.0612 29.9633c-.746 3.012-1.2615 6.0764-1.5422 9.1666-.2579 3.0851-.2909 6.1848-.0987 9.2746.1635 3.0843.4874 6.1687.765 9.2808.2776 3.112.5274 6.2025.6168 9.3146-.3423-3.0843-.7464-6.1686-1.1196-9.253-.3732-3.0843-.731-6.1686-.9068-9.2992-.191-3.1215-.1094-6.2536.2437-9.3609.367-3.103 1.0512-6.1601 2.0418-9.1235zM124.466 35.6693c-1.675 2.6132-3.546 5.095-5.598 7.4239-2.065 2.3068-4.294 4.4602-6.672 6.4432-2.362 2.0048-4.827 3.8924-7.269 5.8201-2.443 1.9277-4.87 3.877-7.196 5.9466 2.483-1.8753 5-3.7012 7.501-5.5518 2.501-1.8506 4.997-3.7012 7.381-5.7276 2.399-2.0052 4.619-4.2158 6.634-6.6066 2-2.3976 3.749-4.9938 5.219-7.7478zM150.911 66.8087c-2.914 1.0573-5.904 1.8915-8.945 2.4952-3.041.5778-6.12.9334-9.213 1.0641-3.084.1604-6.199.1604-9.311.2035-3.112.0432-6.224.1203-9.327.3486 3.112.0185 6.218.0987 9.327.1511 3.109.0524 6.224.0833 9.342-.0648 3.124-.1345 6.231-.5411 9.284-1.2152 3.042-.6899 6.005-1.6893 8.843-2.9825zM11.7593 67.9468c2.8082 1.3073 5.7117 2.3992 8.6854 3.2663 2.9782.8457 6.014 1.4735 9.0834 1.8783 3.0689.4318 6.1686.7094 9.253 1.0302 3.0843.3208 6.1902.6724 9.2529 1.1782-3.1028-.256-6.2025-.4534-9.3023-.6785-3.0997-.2252-6.2087-.4689-9.3023-.8945-3.0997-.4117-6.1575-1.0928-9.1389-2.0357-2.9638-.9543-5.8224-2.2089-8.5312-3.7443z"
      fill="#C97F75"
    />
    <path
      d="M70.3704 68.6407C57.9405 55.6526 42.7811 49.12 29.6048 50.9706c-1.0363.145-1.61 1.4558-1.2615 2.9795 1.0863 4.5777 2.9787 8.9255 5.5888 12.84 2.8218 4.2236 6.3802 7.9053 10.5053 10.8692 10.9771 7.9267 23.3637 8.889 32.333 2.5044"
      fill="#C97F75"
    />
    <path
      d="M74.9947 80.8455c-4.318.2405-8.5652-.657-12.547-2.0419-3.962-1.4267-7.7599-3.2734-11.3288-5.5086-3.5643-2.1989-6.9565-4.6652-10.1474-7.3777-1.5946-1.3509-3.1399-2.7759-4.6265-4.2379-.731-.7464-1.4589-1.4959-2.159-2.2824-.698-.7759-1.3479-1.5938-1.9463-2.4489.6243.8351 1.2989 1.6313 2.0203 2.3842.7094.7618 1.4589 1.4866 2.2115 2.2053 1.5174 1.4249 3.1028 2.7759 4.7313 4.0713 3.2519 2.5901 6.6956 4.9296 10.3017 6.9983 3.5748 2.0842 7.3623 3.7799 11.2979 5.0583 3.9171 1.2337 8.0192 1.937 12.0474 1.5823l.1449 1.5977z"
      fill="#BB5F53"
    />
    <path
      d="M76.7907 81.7707c-4.793-.5366-9.6909-2.4983-13.0806-6.8749-3.3897-4.3767-4.8455-11.5354-2.5199-16.933 2.3934-5.5518 15.2366-17.4912 25.4303-17.5375 3.001.6601 6.036 1.3325 8.8304 2.887 3.0628 1.6638 5.4271 4.3714 6.6621 7.6306 1.357 3.7012 1.184 8.0439.309 11.961-1.691 7.4857-5.9656 13.9504-11.4526 17.3216-2.6377 1.6538-5.6661 2.5802-8.7776 2.6853-3.1115.1051-6.1955-.6149-8.9388-2.0869"
      fill="#C97F75"
    />
    <path
      d="M82.0483 83.0076c1.9802-11.7838 2.5374-23.7628 1.6594-35.6795 1.414 11.931 1.3892 23.9888-.074 35.9139l-1.5854-.2344z"
      fill="#BB5F53"
    />
  </svg>
);

export default LotusSvg;
