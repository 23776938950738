//

import PropTypes from "prop-types";
import resources from "constants/selfHelpResources";

import React, { Component } from "react";
import {
  Standard,
  Section,
  Headline,
  Subtitle,
  Content,
} from "@spring/smeargle";

import { LandingPageWrapper, Link, BackButton } from "components";

class SelfHelpPage extends Component {
  static propTypes = {
    type: PropTypes.any,
  };

  get sections() {
    return resources[this.props.type].sections.map((section) => (
      <Section key={section.title}>
        <Subtitle bold>{section.title}</Subtitle>
        <Content>{section.content}</Content>
      </Section>
    ));
  }

  get books() {
    return resources[this.props.type].books.map((book) => (
      <li key={book.title}>
        <Link target="_blank" to={book.link}>
          {book.title}
        </Link>{" "}
        {book.byline}
      </li>
    ));
  }

  get onlineResources() {
    return resources[this.props.type].resources.map((resource) => (
      <li key={resource.link}>
        <Link target="_blank" to={resource.link}>
          {resource.text}
        </Link>
      </li>
    ));
  }

  render() {
    return (
      <LandingPageWrapper>
        <Standard>
          <Section size="lg">
            <BackButton alias="MemberResults" />
          </Section>
          <Subtitle>Learn More About:</Subtitle>
          <Headline>{resources[this.props.type].title}</Headline>

          <Section>
            <Content>{resources[this.props.type].overview}</Content>
          </Section>

          {this.sections}

          <Section>
            <Subtitle bold>Books</Subtitle>
            <Content>
              <ul>{this.books}</ul>
            </Content>
          </Section>

          <Section>
            <Subtitle bold>Online Resources</Subtitle>
            <Content>
              <ul>{this.onlineResources}</ul>
            </Content>
          </Section>
        </Standard>
      </LandingPageWrapper>
    );
  }
}

export default SelfHelpPage;
