import { useTranslation } from "hooks/react-i18next";
import { colors } from "design-system/theme/base/colors";

import { Flex, Text, Box } from "design-system/components";
import { Hug } from "design-system/assets";
import { minorFilterTag } from "components/templates/Browse/ProviderBrowsePage/utils";

const MinorFilterBanner = ({ memberInfo }) => {
  const { t } = useTranslation(["careProvider", "common"]);
  const minorFilterText = minorFilterTag(memberInfo, t);

  return (
    <Box
      bg={colors.purple["light"]}
      color={colors.purple["dark"]}
      p={"14px 14px 12px"}
      mt={"40px"}
      borderRadius={"5px"}
      fontWeight={400}
      maxW={"525px"}
    >
      <Flex justify="flex-start">
        <Flex justify="inline-flex" alignItems="center">
          <Box>
            <Hug pl={4} w={32} h={"auto"} />
          </Box>
          <Text pl={12}>
            {t("minorFixedTag.title", {
              ns: "careProvider",
              // @ts-ignore
              filterText: minorFilterText,
            })}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MinorFilterBanner;
