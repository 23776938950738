import { useMediaQuery, useTheme } from "@chakra-ui/react";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import CarouselItem from "./CarouselItem";
import CarouselSlider from "./CarouselSlider";
import CarouselTrack from "./CarouselTrack";

interface CarouselProps {
  children: ReactNode[];
  gap: number;
  CarouselHeading?: ReactNode;
  CarouselSubHeading?: ReactNode;
  sliderVariant?: string;
  itemVariant?: string;
  itemWidth?: string;
  sliderFullWidth?: boolean;
  isArrowSquare?: boolean;
  hideSliderButtons?: boolean;
  mpSliderBtnEvent?: (direction: string) => void;
  isT2Member?: boolean;
  delayItemWidthUpdate?: boolean;
}

const Carousel = ({
  children,
  gap,
  CarouselHeading,
  CarouselSubHeading,
  sliderVariant = "default",
  itemVariant = "default",
  itemWidth: customItemWidth,
  sliderFullWidth = false,
  isArrowSquare = false,
  hideSliderButtons = false,
  mpSliderBtnEvent,
  isT2Member = false,
  delayItemWidthUpdate = false,
}: CarouselProps) => {
  const [sliderWidth, setSliderWidth] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const [constraint, setConstraint] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const T2baseAndMdWidth = 265;
  const T2MdAndXlWidth = 525;

  const initSliderWidth = useCallback((width) => setSliderWidth(width), []);

  const positions = useMemo(
    () => children.map((_, index) => -Math.abs((itemWidth + gap) * index)),
    [children, itemWidth, gap],
  );

  const { breakpoints } = useTheme();

  const [isBetweenBaseAndMd] = useMediaQuery(
    `(min-width: ${breakpoints.base}) and (max-width: ${breakpoints.md})`,
  );

  const [isBetweenMdAndXl] = useMediaQuery(
    `(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl})`,
  );

  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);

  useEffect(() => {
    setTimeout(() => setActiveItem(0), 1000);
    if (isBetweenBaseAndMd) {
      if (isT2Member) {
        setSliderWidth(T2baseAndMdWidth);
      }
      setItemWidth(sliderWidth - gap);
      setConstraint(1);
    }
    if (isBetweenMdAndXl) {
      if (isT2Member) {
        setSliderWidth(T2MdAndXlWidth);
      }
      setItemWidth(sliderWidth / 2 - gap);
      setConstraint(2);
    }
    if (isGreaterThanXL) {
      let itemsInSlider;
      if (itemVariant === "wide") {
        itemsInSlider = 2;
      } else {
        itemsInSlider = 3;
      }

      setItemWidth(sliderWidth / itemsInSlider - gap);
      // NOTE: constraint is set to 2 for wide variant to show 2 full items at a time
      setConstraint(itemsInSlider);
    }
  }, [
    isBetweenBaseAndMd,
    isBetweenMdAndXl,
    isGreaterThanXL,
    sliderWidth,
    gap,
    setActiveItem,
  ]);

  const sliderProps = {
    initSliderWidth,
    setActiveItem,
    activeItem,
    constraint,
    positions,
    gap,
    CarouselHeading,
    CarouselSubHeading,
    sliderVariant,
    sliderFullWidth,
    isArrowSquare,
    hideSliderButtons,
    mpSliderBtnEvent,
    isT2Member,
  };

  const trackProps = {
    setActiveItem,
    activeItem,
    setItemWidth,
    itemWidth,
    positions,
    constraint,
    delayItemWidthUpdate,
  };

  const itemProps = {
    setActiveItem,
    constraint,
    itemWidth,
    positions,
  };

  return (
    <CarouselSlider {...sliderProps}>
      <CarouselTrack {...trackProps}>
        {children.map((child, index) => (
          <CarouselItem
            {...itemProps}
            index={index}
            key={index}
            itemWidth={customItemWidth || itemWidth}
          >
            {child}
          </CarouselItem>
        ))}
      </CarouselTrack>
    </CarouselSlider>
  );
};

export default Carousel;
