// this is a temp file, it will be removed when we solve the ld async issue https://springhealth.atlassian.net/browse/LOB-335?focusedCommentId=1015666

export const t2CustomersJulyRelease = [
  "725d1e89-5828-49e3-840d-535fd8f1297d",
  "fd45fbcf-f54a-49ae-a175-7c68c0ecca61",
  "9550fe2f-0dbf-41a4-9b34-8a049538d26b",
  "c66c38f1-dcd0-4f4a-ba80-fb1947e030da",
  "0fcfe0c4-41a3-4ad4-a3ea-57a5ba1c7b67",
  "29b1ce81-441d-4753-91ff-6e5990b33ebd",
  "f6263bb7-ed96-4177-8761-91281e665596",
  "640aa1a2-419b-4b71-8035-94b1bf16bce9",
  "87e5a902-adbb-4174-b4f3-0886d47f9cf6",
  "87c161db-7a08-48ef-be16-a2df9e8be39d",
  "cac67dfd-9fb7-4cd4-b9a3-9a98e8d8b07d",
  "05dca1bf-4651-4453-a2e7-54a0d2ea0615",
  "8a722ddf-d60f-4635-9c76-88264c3f6d40",
];

export const msftCustomerId = "87e5a902-adbb-4174-b4f3-0886d47f9cf6";
export const testCustomerId = "fd45fbcf-f54a-49ae-a175-7c68c0ecca61"; // for testing on prod

export const msftAllowedCountries = [
  "AZ",
  "CN",
  "HK",
  "QA",
  "SA",
  "TW",
  "TH",
  "VN",
];
