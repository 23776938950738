import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

// providerId: videoUrl
const STAGING_PROVIDER_IDS = {
  // staging profile
  "3759db8e-cf87-47e6-8c5a-2d0b23990c7b":
    "https://provider-profile-videos-cdn-demo.springhealth.com/ProviderVideo_KateMurphy_demo.mov",
  // staging profile
  "de8508a3-e237-4ec4-89bd-1e6dd85d5837":
    "https://provider-profile-videos-cdn-demo.springhealth.com/ProviderVideo_KateMurphy_demo.mov",
};

// demo profile DO NOT CHANGE
// https://admin-new.demo.springhealth.com/admin/providers/3e5b190d-5b93-4a04-92e9-5e568de68ebf
const DEMO_PROVIDER_IDS = {
  "3e5b190d-5b93-4a04-92e9-5e568de68ebf":
    "https://provider-profile-videos-cdn-demo.springhealth.com/ProviderVideo_KateMurphy_demo.mov",
};

const VIDEO_PROVIDER_IDS = { ...STAGING_PROVIDER_IDS, ...DEMO_PROVIDER_IDS };

type UseProfileVideosReturn = {
  showVideoProfile: boolean;
  videoUrl?: string;
};

const useProfileVideos = (providerId: string): UseProfileVideosReturn => {
  const videoProfileEnabled = useFeatureFlag(FLAGS.PROVIDER_PROFILE_VIDEOS);
  const videoUrl = VIDEO_PROVIDER_IDS[providerId];

  return {
    showVideoProfile: videoProfileEnabled && !!videoUrl,
    videoUrl: videoUrl,
  };
};

export default useProfileVideos;
