import { useQuery } from "@apollo/client";
import getExercises from "operations/queries/exercise/getExercises";

// /**
//  * This hook queries for 4 specific spotlighted moments for teens.
//  * These moments were chosen by product and design
export const useRecommendedTeenMoments = () => {
  const { data, loading, error } = useQuery(getExercises, {
    variables: { teen_spotlighted_only: true },
  });

  const parsedExercisesData = data?.exercises.data.map((exercise) => ({
    exercise,
  }));

  return {
    data: parsedExercisesData,
    loading,
    error,
  };
};
