/**
 * Enums for passing in Appointment Actions
 * where necessary.
 */

const AppointmentAction = Object.freeze({
	Create     : 'CreateAppointment',
	Reschedule : 'RescheduleAppointment',
	Cancel     : 'CancelAppointment',
});

module.exports = AppointmentAction;