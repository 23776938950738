import { Heading } from "@chakra-ui/react";
import React, { FC } from "react";

import { ActiveCareSection, BenefitsSummarySection } from "components";
import { Box, SimpleGrid, GridItem } from "design-system/components";
import { useTranslation } from "hooks/react-i18next";
import { useTeenYourCareSection } from "modules/MemberDashboard/Teen/Home/hooks";

import type { TeenYourCareSectionProps } from "./types";

/**
 * This component renders information about the teen's care team and
 * upcoming appointments, as well as a summary of their benefits.
 * It is used on the Teen Homepage.
 *
 * @param props
 */

export const TeenYourCareSection: FC<
  React.PropsWithChildren<TeenYourCareSectionProps>
> = () => {
  const { t } = useTranslation("teenHomepage");
  const { careTeamData, member, memberHasInPersonSupport, memberCountry } =
    useTeenYourCareSection();
  const memberCanBookTherapyAppointments = member?.visits_remaining_total > 0;
  const memberCanBookCoachingAppointments =
    member?.visits_remaining_coaching > 0;

  return (
    <section>
      <Box pt={[56, 68, 72, 78]}>
        <SimpleGrid columns={[4, 4, 8, 12]} spacing={24}>
          <GridItem colSpan={[4, 4, 8, 8]}>
            <Heading as="h2" size={"heading-medium"} pb={24}>
              {t("yourCare.yourCareHeading")}
            </Heading>
            <ActiveCareSection
              memberHasInPersonSupport={memberHasInPersonSupport}
              memberCountry={memberCountry}
              memberNextUpcomingAppointments={
                member?.first_upcoming_appointments
              }
              careTeam={careTeamData}
              includeMoments={false}
              isDirectScheduling
              isOnTeenHomepage
              memberCanBookCoachingAppointments={
                memberCanBookCoachingAppointments
              }
              memberCanBookTherapyAppointments={
                memberCanBookTherapyAppointments
              }
            />
          </GridItem>
          <GridItem colSpan={[4, 4, 8, 4]} w="100%">
            <Box>
              <BenefitsSummarySection member={member}>
                <Heading as="h2" size={"heading-medium"} pb={24}>
                  {t("yourCare.yourBenefitsHeading")}
                </Heading>
              </BenefitsSummarySection>
            </Box>
          </GridItem>
        </SimpleGrid>
      </Box>
    </section>
  );
};
