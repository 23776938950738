import { useMutation } from "@apollo/client";
import { createAppointment } from "operations/mutations/appointment";

type BookAppointmentDataInput = {
  input: {
    put: {
      bookings: Array<Object>;
      kind: string;
      medium: string;
      reason_for_scheduling: string;
      start_at: string;
      time_zone: string;
    };
  };
};

type BookAppointmentData = {
  data: {
    createAppointment: {
      success: boolean;
      appointment: {
        id: string;
        covered: boolean;
        end_at: string;
        created_at: string;
        kind: string;
        medium: string;
        start_at: string;
        reason_for_scheduling: string;
        updated_at: string;
      };
      campaign: {
        campaign_id: number;
        template_id: number;
      };
    };
  };
};

export const useBookAppointment = (payload) => {
  return useMutation<BookAppointmentData, BookAppointmentDataInput>(
    createAppointment,
    { ...payload },
  );
};
