// 

import React from 'react';

import styles from './styles.module.scss';

const SpringMedication = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		id="Layer_1"
		data-name="Layer 1"
		viewBox="0 0 500 500"
		className={styles.svg}
	>
		<g id="Medication">
			<path className={styles.cls1} d="M224.84 172.38l84.67-22.84a39.38 39.38 0 0 1 48.26 28 39.69 39.69 0 0 1-27.86 48.58L245.23 249" />
			<path className={styles.cls2} d="M245.23 249l-84.67 22.84a39.38 39.38 0 0 1-48.26-28 39.68 39.68 0 0 1 27.86-48.57l84.68-22.84" />
			<path className={styles.cls3} d="M330.67 229l-169.35 45.7a42.61 42.61 0 0 1-21.92-82.36l169.35-45.67A42.61 42.61 0 0 1 330.67 229zm-189.74-30.91A36.67 36.67 0 0 0 159.79 269l169.35-45.68a36.66 36.66 0 0 0-18.86-70.86z" />
			<path className={styles.cls4} d="M136.86 214.46a26.58 26.58 0 0 0-3.61 32.24 1.93 1.93 0 1 1-3.3 2c-7.19-11.86-5.42-27.42 4.2-37a1.23 1.23 0 0 1 .35-.28 1.94 1.94 0 0 1 2.36 3z" />
			<path className={styles.cls1} d="M272.56 254.37L187 235.3a39.4 39.4 0 0 0-47 30.15 39.65 39.65 0 0 0 29.95 47.29l85.6 19.07" />
			<path className={styles.cls2} d="M255.54 331.81l85.59 19.06a39.4 39.4 0 0 0 47-30.15 39.66 39.66 0 0 0-30-47.29l-85.59-19.06" />
			<path className={styles.cls3} d="M137.1 264.81a42.4 42.4 0 0 1 50.51-32.41l171.18 38.13a42.62 42.62 0 0 1-18.3 83.25L169.3 315.65a42.69 42.69 0 0 1-32.2-50.84zm49.23-26.6a36.67 36.67 0 0 0-15.75 71.63L341.77 348a36.68 36.68 0 0 0 15.74-71.64z" />
			<path className={styles.cls4} d="M362.29 292.51c8.7 8 10.82 21.46 5 32.05a1.93 1.93 0 1 0 3.38 1.87c6.67-12.17 4.22-27.64-5.81-36.8a1.38 1.38 0 0 0-.36-.26 1.94 1.94 0 0 0-2.37.38 2 2 0 0 0 .16 2.76z" />
		</g>
	</svg>
);

export default SpringMedication;
