/**
 * Enums for passing in App Task Statues
 * where necessary.
 */

const AppTaskStatus = Object.freeze({
	Queued   : 'QUEUED',
	Started  : 'STARTED',
	Finished : 'FINISHED',
	Errored  : 'ERRORED',
	Queueing : 'QUEUEING',
});

module.exports = AppTaskStatus;
