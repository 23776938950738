import React from "react";
import {
  Breakpoint,
  Col,
  Content,
  FlexCol,
  FlexRow,
  Grid,
  Section,
} from "@spring/smeargle";
import { Trans, useTranslation } from "react-i18next";

import { ReflectSvg, TargetSvg } from "components/atoms/Assets/svgs";
import { Center, Heading } from "design-system/components";
import { any } from "prop-types";

// Hack: The Trans component (used below) does not support nested components
// so had to make a new component which contained Center and Heading tags
const CenterHeading = ({ children }) => {
  return (
    <>
      <Center>
        <Heading as="h2" fontSize={20}>
          {children}
        </Heading>
      </Center>
    </>
  );
};

CenterHeading.propTypes = {
  children: any,
};

const ExpectationsInstructions = () => {
  const { t } = useTranslation("membership");
  return (
    <>
      <Breakpoint sm andUp>
        <Grid>
          <Col size="6">
            <FlexCol center>
              <TargetSvg size="xlg" />
              <Section size="md">
                <Trans
                  t={t}
                  i18nKey="expectations.instructions.chooseGoals"
                  components={{
                    title: <CenterHeading>Choose your goals</CenterHeading>,
                    subtitle: (
                      <Content center inline={false}>
                        and where you need support.
                      </Content>
                    ),
                  }}
                />
              </Section>
            </FlexCol>
          </Col>
          <Col size="6">
            <FlexCol center>
              <ReflectSvg size="xlg" />
              <Section size="md">
                <Trans
                  t={t}
                  i18nKey="expectations.instructions.checkin"
                  components={{
                    title: (
                      <CenterHeading>Check in with yourself</CenterHeading>
                    ),
                    subtitle: (
                      <Content>with questions customized for you.</Content>
                    ),
                  }}
                />
              </Section>
            </FlexCol>
          </Col>
        </Grid>
      </Breakpoint>

      <Breakpoint xs andUp={false}>
        <FlexCol center>
          <FlexRow>
            <FlexCol>
              <Section>
                <FlexRow>
                  <FlexCol>
                    <Section padding="sm" size="none">
                      <TargetSvg size="lg" />
                    </Section>
                  </FlexCol>
                  <FlexCol>
                    <Section padding="sm" size="none">
                      <Trans
                        t={t}
                        i18nKey="expectations.instructions.chooseGoals"
                        components={{
                          title: (
                            <CenterHeading>Choose your goals</CenterHeading>
                          ),
                          subtitle: (
                            <Content center inline={false}>
                              and where you need support.
                            </Content>
                          ),
                        }}
                      />
                    </Section>
                  </FlexCol>
                </FlexRow>
              </Section>

              <Section>
                <FlexRow>
                  <FlexCol>
                    <Section padding="sm" size="none">
                      <ReflectSvg size="lg" />
                    </Section>
                  </FlexCol>
                  <FlexCol>
                    <Section padding="sm" size="none">
                      <Trans
                        t={t}
                        i18nKey="expectations.instructions.checkin"
                        components={{
                          title: (
                            <CenterHeading>
                              Check in with yourself
                            </CenterHeading>
                          ),
                          subtitle: (
                            <Content>
                              with questions customized for you.
                            </Content>
                          ),
                        }}
                      />
                    </Section>
                  </FlexCol>
                </FlexRow>
              </Section>
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </Breakpoint>
    </>
  );
};

export default ExpectationsInstructions;
