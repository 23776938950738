import { gql } from "@apollo/client";

const getMemberCohort = gql`
  query getMemberCohort($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        email
        is_global_t2
        intent_option
        cohort {
          id
          benefits_summary
          basic_visits_covered
          specialist_visits_covered
          sponsored_visits_covered
          unlimited_coaching_direct_visits
          unlimited_coaching_cn_visits
          coaching_visits_covered
          name
          dependents_allowed
          child_dependents_allowed
          eap_freephone_number
          eap_direct_dial_number
          eap_email
          contract_term {
            id
            scheduling_access {
              id
              name
            }
          }

          customer {
            id
            name
          }
        }
      }
    }
  }
`;

export default getMemberCohort;
