import { getSelectorsByUserAgent } from "react-device-detect";
import routes from "routes/PublicRoutes";

const isSSRRoute = (currentRoute, userAgent) => {
  let shouldSSR = false;
  const isSSRRoute = Object.entries(routes).find(([_key, definition]) => {
    return definition.to === currentRoute && definition.ssr;
  });

  if (isSSRRoute) {
    let userAgentSelectors;
    try {
      if (userAgent) {
        userAgentSelectors = getSelectorsByUserAgent(userAgent);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error getting user agent", e);
    }
    const isMobile = userAgentSelectors?.isMobileOnly;
    shouldSSR = typeof isMobile === "boolean" && !isMobile ? true : false;
  }

  return shouldSSR;
};

export default isSSRRoute;
