/**
 * Enums used to determine Assessment risk levels
 */

const AssessmentRisk = Object.freeze({
	Low       : 'Low',
	LowMedium : 'Low / Medium',
	Medium    : 'Medium',
	High      : 'High',
});

module.exports = AssessmentRisk;
