//

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import { Section, Modal, Subhead, Button, Content } from "@spring/smeargle";
import { modalIds } from "@spring/constants";
import { openModal, closeModal } from "@spring/smeargle/actions";
import { useTranslation, Trans } from "react-i18next";

import { Link } from "components/atoms";
import { FrownSvg } from "components/atoms/Assets/svgs";

import envUtils from "utils/environment";
import styles from "./styles.module.scss";

export const MemberTerminationModal = connect(null, { openModal, closeModal })((
  props,
) => {
  const { t } = useTranslation("memberTermination");
  const terminateAt = props.terminateAt;
  const terminateAtFormatted = terminateAt
    ? DateTime.fromISO(terminateAt).toLocaleString(DateTime.DATE_FULL)
    : null;
  if (!terminateAtFormatted) {
    return null;
  }

  const closeForComponentLifecycle = () => {
    localStorage.setItem("hasOpened", true);
    props.closeModal(modalIds.terminationWarning);
  };

  // Hack to add logic indirectly to the closeModal action
  // when modal is dismissed via clicking outisde, or via the x button
  const dismissableHack = () => localStorage.setItem("hasOpened", true);

  return (
    <Modal
      size="lg"
      id={modalIds.terminationWarning}
      closeRedirect={dismissableHack}
    >
      <div className={styles.memberTerminationModal}>
        <div className={styles.frownSvg}>
          <FrownSvg />
        </div>
        <Subhead center bold>
          {t("terminationWarningPopup.heading", { terminateAtFormatted })}
        </Subhead>
        <Section>
          <div className={styles.body}>
            <div className={styles.forHelpText}>
              <Content>{t("terminationWarningPopup.body.forHelp")}</Content>
            </div>
            <Content>
              <Trans
                ns="memberTermination"
                i18nKey="terminationWarningPopup.body.questions"
                components={[
                  <Link key={"0"} to={`mailto:${envUtils.careTeamEmail}`} />,
                ]}
              />
            </Content>
          </div>
          <Button
            full
            text={t("terminationWarningPopup.button")}
            onClick={closeForComponentLifecycle}
          />
        </Section>
      </div>
    </Modal>
  );
});

MemberTerminationModal.propTypes = {
  closeModal: PropTypes.func,
  terminateAt: PropTypes.any,
};

MemberTerminationModal.defaultProps = {
  terminateAt: null,
};

export default MemberTerminationModal;
