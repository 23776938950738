import { useTranslation } from "react-i18next";
import { handleBookAgainClick } from "components/templates/CareVisitsPage/components/Appointments/pastAppointments.util";
import { Button } from "@springcare/sh-component-library";

import {
  AppointmentAction,
  isCareConsultantAppointment,
  isCareNavigation,
} from "@spring/constants";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getFormLinkWPMHC } from "utils/employerServicesHelpers";
import type {
  Appointment,
  Member,
} from "modules/shared/graphql-codegen/graphql";

type BookAgainButtonProps = {
  appointmentRow: Appointment;
  onScheduleModalOpen: (args: any) => void;
  openModal: (args: any) => void;
  getLazyNextAppointmentSlot: (args: any) => void;
  getLazySlotRequestVars: (args: any) => void;
  memberInfoData: Member;
};

export const BookAgainButton = ({
  appointmentRow,
  onScheduleModalOpen,
  openModal,
  getLazyNextAppointmentSlot,
  getLazySlotRequestVars,
  memberInfoData,
}: BookAgainButtonProps) => {
  const { t } = useTranslation(["careVisits"]);
  const isCareConsultant = isCareConsultantAppointment(appointmentRow?.kind);
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const customerId = memberInfoData?.user?.member?.cohort?.customer?.id;
  const formLinkSDOH = getFormLinkWPMHC(customerId);

  const openScheduleModal = (appointmentRow) => {
    if (
      shouldShowNewInsuranceModal &&
      !isCareNavigation(appointmentRow?.kind)
    ) {
      onScheduleModalOpen({
        kind: appointmentRow.kind,
        provider: appointmentRow.provider,
        buttonText: "Confirm Appointment",
        action: AppointmentAction.Create,
        dataCy: "schedule-provider-modal",
        mpLocation: "Your Past Appointments",
        telemetryProps: {
          springDocId: appointmentRow.spring_doc_id,
          location: "Book again",
          appointment_medium: appointmentRow.medium,
        },
      });
    } else {
      handleBookAgainClick(
        appointmentRow,
        openModal,
        getLazyNextAppointmentSlot,
        getLazySlotRequestVars,
      );
    }
  };

  const openLCCForm = () =>
    window.open(formLinkSDOH, "_blank", "noopener noreferrer");

  return (
    <Button
      colorScheme="positive"
      variant="low-emphasis"
      size="sm"
      data-cy="book-again-button"
      onClick={() =>
        isCareConsultant ? openLCCForm() : openScheduleModal(appointmentRow)
      }
    >
      {t("appointmentsTable.bookAgain")}
    </Button>
  );
};
