import { ApolloError, useQuery } from "@apollo/client";
import { useMemberInfo } from "hooks";
import { getFeaturedWorkplaceVideos } from "modules/MemberDashboard/ManagerExperience/Workplace/grapqhql/queries/getFeaturedWorkplaceVideos";
import { useChannelPartnerReseller } from "modules/MemberDashboard/ManagerExperience/Workplace/hooks/useChannelPartnerReseller";
import { isGlobalUser } from "utils/global";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isMinor } from "utils/memberHelpers";
import { Member, WorkplaceVideo } from "modules/shared/graphql-codegen/graphql";
import { useWorkplaceCustomizationData } from "modules/MemberDashboard/ManagerExperience/Workplace/hooks/useWorkplaceCustomizationData";

type useWorkplaceContentExperimentReturnType = {
  shouldShowWorkplaceContentExperiment: boolean;
  featuredWorkplaceVideosData: Array<WorkplaceVideo>;
  isFeaturedWorkplaceVideosLoading: boolean;
  hasFeaturedWorkplaceVideosError: ApolloError;
};

// This hook is temporary code for Manager Experience Experiment #6: https://springhealth.atlassian.net/browse/MXE-830
// It will be removed once the experiment is complete
export const useWorkplaceContentExperiment =
  (): useWorkplaceContentExperimentReturnType => {
    const workplaceContentExperimentFlag: boolean = useFeatureFlag(
      FLAGS.MANAGER_X_EXPERIMENT_6,
    );
    const workplaceFeatureFlag: boolean = useFeatureFlag(
      FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
    );

    const globalWorkplaceFeatureFlag: boolean = useFeatureFlag(
      FLAGS.MANAGER_EXPERIENCE_FOR_GLOBAL_MEMBERS,
    );

    const { data: memberData } = useMemberInfo({
      skip: !workplaceContentExperimentFlag,
    });

    const { isReseller } = useChannelPartnerReseller();

    const customerId = memberData?.user?.customer?.id;

    const { customerHasWorkplaceEnabled } =
      useWorkplaceCustomizationData(customerId);

    const member: Member = memberData?.user?.member;
    const isAWorkplaceManager = member?.workplace_manager;
    const memberCountry = member?.postal_address?.country;
    const isAGlobalMember = isGlobalUser(memberCountry);
    const isAMinorMember = isMinor(member);
    const isADependent = member?.covered_life?.dependent;
    const memberIsPrivatePractice = member?.is_private_practice;

    const isWorkplaceAccessBlocked =
      !workplaceFeatureFlag ||
      (isAGlobalMember && !globalWorkplaceFeatureFlag) ||
      isReseller ||
      !customerHasWorkplaceEnabled ||
      !isAWorkplaceManager ||
      isAMinorMember ||
      isADependent ||
      memberIsPrivatePractice;

    const shouldShowWorkplaceContentExperiment =
      workplaceContentExperimentFlag && !isWorkplaceAccessBlocked;

    const {
      data: featuredWorkplaceVideosData,
      loading: isFeaturedWorkplaceVideosLoading,
      error: hasFeaturedWorkplaceVideosError,
    } = useQuery(getFeaturedWorkplaceVideos, {
      variables: {
        limit: 3,
      },
      skip: !shouldShowWorkplaceContentExperiment,
    });

    return {
      shouldShowWorkplaceContentExperiment,
      featuredWorkplaceVideosData:
        featuredWorkplaceVideosData?.featured_workplace_videos?.data,
      isFeaturedWorkplaceVideosLoading,
      hasFeaturedWorkplaceVideosError,
    };
  };
