import { gql } from "@apollo/client";

import paging from "operations/fragments/paging";

const getExercises = gql`
  query getExercises(
    $category: ExerciseCategoryEnum
    $emotion: Emotion
    $limit: Int = 10
    $offset: Int = 0
    $exerciseIds: [ID!]
    $teen_spotlighted_only: Boolean
    $t2_member_only: Boolean
    $daily_moments: Boolean
  ) {
    exercises(
      category: $category
      emotion: $emotion
      limit: $limit
      offset: $offset
      exercise_ids: $exerciseIds
      teen_spotlighted_only: $teen_spotlighted_only
      t2_member_only: $t2_member_only
      daily_moments: $daily_moments
    ) {
      data {
        id
        title
        completed
        duration
        activity_type
        total_steps
        emotions {
          emotion
          position
        }
        categories {
          category
        }
        content_formats
        images {
          url
          context
        }
        starting_step {
          id
          exercise_id
          position
          title
          content_format
          final_step
          video_url
          caption_url
          elements {
            type
            content
            media_uri
          }
        }
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default getExercises;
