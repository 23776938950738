import { TRACK_EVENT } from "utils/mixpanel";
import { getAppointmentKindForTracking } from "@spring/constants";

export const trackLearnMoreModalOpened = () => {
  TRACK_EVENT.MODAL_OPENED(window.location.pathname, "Generative AI Intro", {
    spring_doc_id: "recs21",
  });
};

export const trackLearnMoreModalClosed = () => {
  TRACK_EVENT.MODAL_CLOSED(window.location.pathname, "Generative AI Intro", {
    spring_doc_id: "recs22",
  });
};

export const trackFeedbackBtnClicked = (providerData) => {
  const { provider_id, provider_order } = providerData;
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "AI Summary Feedback", {
    provider_id,
    provider_order,
    spring_doc_id: "recs23",
  });
};

export const trackFeedbackModalOpened = () => {
  TRACK_EVENT.MODAL_OPENED(window.location.pathname, "AI Feedback Modal", {
    spring_doc_id: "recs24",
  });
};

export const trackFeedbackModalClosed = () => {
  TRACK_EVENT.MODAL_CLOSED(window.location.pathname, "AI Feedback Modal", {
    spring_doc_id: "recs26",
  });
};

export const trackFeedbackSubmitted = (feebackData) => {
  const { selected_feedback, feedback_sentiment, feedback_text } = feebackData;

  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Submit AI feedback", {
    location: "AI Feedback Modal",
    selected_feedback,
    feedback_sentiment,
    feedback_text: String(feedback_text),
    spring_doc_id: "recs25",
  });
};

export const trackGenAIProviderCardViewed = (cardViewedData) => {
  const {
    index,
    provider,
    gen_ai_batch_query_id,
    gen_ai_unique_query_id,
    gen_ai_summary_type,
    gen_ai_query_error,
  } = cardViewedData;

  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Recommended Provider Profile",
    {
      spring_doc_id: "directsched02",
      provider_id: provider.id,
      provider_order: index,
      is_company_exclusive: provider?.dedicated_customer?.name ? true : false,
      is_associate: provider?.associate_status === "active",
      gen_ai_batch_query_id,
      gen_ai_unique_query_id,
      gen_ai_summary_type,
      gen_ai_query_error: gen_ai_query_error
        ? String(gen_ai_query_error)
        : String(false),
      recommended_reason: "Recommended Provider Profile",
    },
  );
};

export const trackViewFullProfileBtnClicked = (
  providerData,
  queryRequestId,
) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "View Full Profile", {
    provider_id: providerData.providerId,
    provider_order: providerData.providerIndex,
    query_request_id: queryRequestId,
    spring_doc_id: "recs27",
    to: "Provider Profile Page",
    location: "GenAI Recs Card",
  });
};

export const trackGenAICardClicked = ({
  provider,
  providerIndex,
  queryRequestId,
  appointmentKind,
}) => {
  TRACK_EVENT.CARD_CLICKED(
    window.location.pathname,
    "Provider Profile Page",
    "Recommended Provider Profile Gen AI",
    {
      spring_doc_id: "recs28",
      appointment_type:
        getAppointmentKindForTracking(appointmentKind) || "Therapy",
      provider_id: provider.id,
      provider_order: providerIndex,
      query_request_id: queryRequestId,
    },
  );
};

export const trackBioClicked = ({
  providerId,
  pageNumber,
  providerIndex,
  isBioExpanded,
  profileTabFlag,
  queryRequestId,
  isBrecsFlag = false,
}) => {
  const trackName = isBioExpanded ? "Hide Bio" : "Show Bio";

  const location = isBrecsFlag
    ? "Recommended Provider Card"
    : profileTabFlag
      ? "Provider Card"
      : "Provider Profile";

  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, trackName, {
    spring_doc_id: "recs29",
    location,
    to: profileTabFlag ? "Provider Profile Page" : "",
    provider_id: providerId,
    browse_page_number: pageNumber,
    provider_order: providerIndex,
    query_request_id: queryRequestId,
  });
};
