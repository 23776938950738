import { DateTime } from "luxon";

// Used for displayed date in PreJoin component
export const formatPreSessionDateString = (date: string) => {
  const dateTime = DateTime.fromISO(date);
  const sessionDateString = dateTime.toFormat("EEEE, MMMM dd");
  const sessionTimeString = dateTime.toFormat("h:mm a");
  return { sessionDateString, sessionTimeString };
};

// Helper function to remove prefixes from device labels
export const cleanDeviceLabel = (label) => {
  // Remove specific patterns in parentheses
  label = label.replace(/\(\w{4}:\w{4}\)/, "");
  // Modify labels containing "default"
  label = label.replace(/^(Default|default)\s*-\s*/, "Same as system (");
  // Close the parenthesis if "Same as system (" was added
  if (label.startsWith("Same as system (")) {
    label += ")";
  }
  return label;
};
