import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash/fp";

import { getBenefits } from "operations/queries/benefits";

interface CoachingBenefit {
  unlimitedCoachingCN?: boolean;
  unlimitedCoachingDirectScheduling?: boolean;
  showCoachingPaidExperience?: boolean;
  allowDirectScheduling?: boolean;
  qualifiesForCoachingViaCN?: boolean;
  shouldShowCoaching?: boolean;
  isCoachingRecommended?: boolean;
  isCoachingEnabled?: boolean;
  hasContinuedCoaching?: boolean;
}

interface CareNavigationBenefit {
  specializedCNEnabled?: boolean;
}
interface SUDBenefit {
  sudEnabled?: boolean;
  isSUDRecommended?: boolean;
}

interface isLoading {
  isLoading?: boolean;
}

type MemberBenefits = CareNavigationBenefit &
  CoachingBenefit &
  SUDBenefit &
  isLoading;

export const useMemberBenefits = (memberId: string): MemberBenefits => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requiredMemberBenefits, setRequiredMemberBenefits] =
    useState<MemberBenefits>({});

  const { data, loading, error } = useQuery(getBenefits, {
    variables: { id: memberId },
    skip: !memberId,
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions
  });

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const memberBenefits = data?.benefits?.spring_benefit?.member_benefits;

      if (memberBenefits?.length > 0) {
        const benefits = {};

        if (memberBenefits.includes("coaching")) {
          const {
            unlimited_coaching_cn,
            unlimited_coaching_direct_scheduling,
            show_coaching_paid_experience,
            allow_direct_scheduling,
            qualifies_for_coaching_via_CN,
            recommended: coaching_recommended,
            enabled: coaching_enabled,
            continued_coaching,
          } = data.benefits.coaching_benefit;

          Object.assign(benefits, {
            unlimitedCoachingCN: unlimited_coaching_cn,
            unlimitedCoachingDirectScheduling:
              unlimited_coaching_direct_scheduling,
            showCoachingPaidExperience: show_coaching_paid_experience,
            allowDirectScheduling: allow_direct_scheduling,
            qualifiesForCoachingViaCN: qualifies_for_coaching_via_CN,
            shouldShowCoaching:
              unlimited_coaching_cn ||
              unlimited_coaching_direct_scheduling ||
              show_coaching_paid_experience,
            isCoachingRecommended: coaching_recommended,
            isCoachingEnabled: coaching_enabled,
            hasContinuedCoaching: continued_coaching,
          });
        }

        if (memberBenefits.includes("SUD")) {
          const {
            enabled: sud_enabled,
            recommended: sud_recommended,
            initial_sud_risk,
            latest_sud_risk,
            specialized_cn_enabled,
            peer_recovery_specialist_supported,
          } = data.benefits.sud_benefit;

          Object.assign(benefits, {
            sudEnabled: sud_enabled,
            isSUDRecommended: sud_recommended,
            initialSUDRisk: initial_sud_risk,
            latestSUDRisk: latest_sud_risk,
            specializedCNEnabled: specialized_cn_enabled,
            peerRecoverySpecialistSupported: peer_recovery_specialist_supported,
          });
        }

        setRequiredMemberBenefits(benefits);
      }
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    }
  }, [loading]);

  return { ...requiredMemberBenefits, isLoading };
};
