import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


import { Breakpoint, Card, FlexRow, Section } from '../../layout';
import { Col, Grid } from '../../layout/Grid';
import { Content, Stout, Subtitle } from '../../typography';
import { Button, LinkButton } from '../../buttons';
import PersonalizedSuggestionIcon from '../../atoms/Assets/svgs/PersonalizedSuggestionIcon';

import styles from './styles.module.scss';

import { Slider } from '../../../components';



class MomentsCarePlanStep extends Component {
	static defaultProps = {
		kind: '',
	};

	get kind() {
		if (this.props.kind.includes('Onward')) {
			return null;
		}

		if (this.props.index === this.props.firstActiveStep) {
			return (
				<div className={styles.activeKind}>
					<Stout inheritColor>Your Next Step</Stout>
				</div>
			);
		}

		return (
			<Stout>Coming Up</Stout>
		);
	}

	get actions() {
		return this.props.actions.map((action) => {
			return (
				<Section key={action.text}>
					<Button
						full
						text={action.text}
						icon={action.icon}
						onClick={() => {
							action.onClick();
						}}
						reverse={action.reverse}
					/>
				</Section>
			);
		});
	}

	get memberGoals() {
		return (this.props.memberGoals && this.props.memberGoals.length > 0) ?
			this.props.memberGoals :
			['Get recommendations based on how you’re feeling'];
	}

	render() {
		return (
			<Card>
				<Grid>
					<Col md={6}>
						<Section size="sm">
							{this.kind}
						</Section>
						<Section>
							<Subtitle noMargin bold>
								{this.props.title}
							</Subtitle>
						</Section>
						<Content>
							{this.props.description}
							{' '}
							<LinkButton
								text={this.props.learnMoreLink.text}
								onClick={this.props.learnMoreLink.onClick}
							/>
						</Content>
						<Section>
							<FlexRow>
								<PersonalizedSuggestionIcon/>
								<span className={styles.slider}>
									<Slider texts={this.memberGoals}/>
								</span>
							</FlexRow>
						</Section>
						{this.hasProvider}
						{this.actions}
					</Col>
					<Col md={6} alignment="center" justification="center">
						<Breakpoint md andUp>
							<div className={classnames(styles.svgContainer)}>
								<img src="/static/momentsIllustration.svg" alt="Moments Illustration" />
							</div>
						</Breakpoint>
					</Col>
				</Grid>
			</Card>
		);
	}
}

MomentsCarePlanStep.propTypes = {
	kind            : PropTypes.string.isRequired,
	title           : PropTypes.string.isRequired,
	description     : PropTypes.string.isRequired,
	index           : PropTypes.number.isRequired,
	firstActiveStep : PropTypes.number.isRequired,
	memberGoals     : PropTypes.arrayOf(PropTypes.string),
	actions         : PropTypes.arrayOf(PropTypes.shape({
		text    : PropTypes.string.isRequired,
		reverse : PropTypes.bool,
		onClick : PropTypes.func,
		icon    : PropTypes.string,
	})).isRequired,
	learnMoreLink: {
		text    : PropTypes.string.isRequired,
		onClick : PropTypes.func,
	},
};

export default MomentsCarePlanStep;
