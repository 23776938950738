import { Button } from "@springcare/sh-component-library";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  HStack,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "hooks/react-i18next";
import { trackCloseMoment } from "modules/MemberDashboard/Moments/components/analytics";
import { toTitleCase } from "utils/mixpanel";

export const ExitMomentModal = ({
  isOpen,
  onClose,
  onCloseMomentModal,
  exercise,
}) => {
  const { t } = useTranslation("moments");
  const handleExitMomentOnClick = () => {
    trackCloseMoment(
      exercise?.id,
      exercise?.title,
      exercise?.categories
        ?.map((item) => toTitleCase(item.category))
        .join(", "),
      exercise?.content_formats?.join(", "),
    );
    onCloseMomentModal();
  };
  const [isMobile] = useMediaQuery("(max-width:600px)");

  if (isMobile) {
    return (
      <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <VStack alignItems="center" justifyContent="center">
              <Text
                fontSize="27px"
                fontWeight={600}
                position="absolute"
                top="40%"
                w="85%"
                textAlign="center"
              >
                {t("exerciseModal.exitModal.message")}
              </Text>
              <VStack position="absolute" bottom="0" pb="20px" w="85%">
                <Button
                  size="lg"
                  w="100%"
                  colorScheme="base"
                  variant="medium-emphasis"
                  onClick={handleExitMomentOnClick}
                >
                  {t("exerciseModal.exitModal.declineText")}
                </Button>
                <Button size="lg" w="100%" colorScheme="base" onClick={onClose}>
                  {t("exerciseModal.exitModal.confirmationText")}
                </Button>
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w={["100%", 462]} h="fit-content" p="v-32">
        <ModalBody w="100%" p={0}>
          <VStack spacing="v-32" mb="v-20" w="100%" my="v-24">
            <Text fontSize="27px" fontWeight={600} alignSelf="start">
              {t("exerciseModal.exitModal.message")}
            </Text>
            <HStack spacing="v-16" w="100%" h="100%">
              <Button
                size="lg"
                w={191}
                colorScheme="base"
                variant="medium-emphasis"
                onClick={handleExitMomentOnClick}
              >
                {t("exerciseModal.exitModal.declineText")}
              </Button>
              <Button size="lg" w={191} colorScheme="base" onClick={onClose}>
                {t("exerciseModal.exitModal.confirmationText")}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
