/**
 * Enums for Care Provider Tag Kinds.
 */

const CareProviderTagKind = Object.freeze({
	Condition         : 'CONDITION',
	Specialty         : 'SPECIALTY',
	TreatmentModality : 'TREATMENT_MODALITY',
	Language          : 'LANGUAGE',
	Gender            : 'GENDER',
	Ethnicity         : 'ETHNICITY',
	SessionType	      : 'SESSION TYPE',
	TimeOfDay         : 'TIME OF DAY',
	DaysOfWeek        : 'DAYS OF WEEK',
});

module.exports = CareProviderTagKind;
