import React from 'react';

const SkipBackwardIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M12.3942 1.28092C12.7912 1.66473 12.8019 2.29784 12.4181 2.69501L12.3245 2.79182C16.6135 2.92869 20.4891 5.84048 21.657 10.201C23.0856 15.5353 19.9216 21.019 14.5888 22.4486C9.25603 23.8782 3.77532 20.7119 2.34666 15.3776C1.44952 12.0279 2.36438 8.6197 4.48584 6.19546C4.84959 5.77979 5.48132 5.73767 5.89686 6.10138C6.3124 6.46508 6.35438 7.09688 5.99064 7.51254C4.29006 9.45584 3.56111 12.1813 4.27846 14.8598C5.42155 19.1278 9.80626 21.6601 14.0714 20.5167C18.3365 19.3733 20.8683 14.987 19.7252 10.7189C18.7518 7.08445 15.4278 4.70871 11.8287 4.7894L12.4638 5.40369C12.8607 5.78765 12.8711 6.42076 12.4871 6.81779C12.1032 7.21482 11.4701 7.22542 11.0732 6.84147L8.97826 4.81488C8.58145 4.43103 8.57087 3.79811 8.95462 3.40104L10.9803 1.30511C11.3641 0.907939 11.9971 0.89711 12.3942 1.28092Z" fill="black"/>
		<path d="M8.89662 9.42403C8.89662 10.0177 8.62949 10.2212 8.12195 10.2212H7.59647C7.26705 10.2212 7 10.4882 7 10.8177V10.8177C7 11.1471 7.26705 11.4141 7.59647 11.4141H8.89662V16.168C8.89662 16.5619 9.21595 16.8813 9.60986 16.8813V16.8813C10.0038 16.8813 10.3231 16.5619 10.3231 16.168V9.71324C10.3231 9.31933 10.0038 9 9.60986 9H9.32065C9.08647 9 8.89662 9.18984 8.89662 9.42403V9.42403Z" fill="black"/>
		<path d="M14.7086 17C17.126 17 17.4062 14.6323 17.4062 12.9721C17.4062 11.4011 17.1535 9 14.7031 9C12.2417 9 12 11.4123 12 12.9666C12 14.5989 12.2582 17 14.7086 17ZM14.7086 15.5794C13.6647 15.5794 13.4944 14.3705 13.4944 13.0167C13.4944 11.6072 13.6647 10.4318 14.7031 10.4318C15.7195 10.4318 15.9118 11.5794 15.9118 13.0167C15.9118 14.4039 15.7195 15.5794 14.7086 15.5794Z" fill="black"/>
	</svg>
);

export default SkipBackwardIcon;
