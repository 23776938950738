import { useState, useRef, TouchEventHandler } from "react";
import { Tooltip } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
} from "@chakra-ui/react";
import { useVideoPlayer } from "modules/shared/components/VideoPlayer/VideoPlayer/context/videoPlayerContext";

// Utility function to format time in HH:MM:SS or MM:SS
const formatTime = (seconds: number): string => {
  const pad = (num: number): string => num.toString().padStart(2, "0");
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  return hours > 0
    ? `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
    : `${pad(minutes)}:${pad(secs)}`;
};

export const Timeline = () => {
  //eventually isTitlesOn will be used in UI
  const { duration, currentTime, updateTime, showControls, setShowControls } =
    useVideoPlayer();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipValue, setTooltipValue] = useState<string>("");
  const [tooltipPosition, setTooltipPosition] = useState<number>(0);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const defaultProgressStart = 0.1;
  const progress = (currentTime / duration) * 100 || defaultProgressStart;

  const isTitlesOn = false; //hardcoded for now

  const handleSliderChange = (value: number) => {
    const newTime = (value / 100) * duration;
    updateTime(newTime);
    setTooltipValue(formatTime(newTime));
    setTooltipPosition(value);
  };

  const handleMouseMove = (e: { clientX: number }) => {
    updateTooltip(e.clientX);
  };

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    if (e.touches && e.touches[0]) {
      updateTooltip(e.touches[0].clientX);
    }
    if (!showControls) {
      setShowControls(true);
    }
  };

  const updateTooltip = (clientX: number) => {
    if (!progressBarRef.current) return;
    const progressBarRect = progressBarRef.current.getBoundingClientRect();
    const position = (clientX - progressBarRect.left) / progressBarRect.width;
    const clampedPosition = Math.min(Math.max(0, position), 1);
    const positionPercentage = clampedPosition * 100;
    const timeSeconds = clampedPosition * duration;

    setTooltipPosition(positionPercentage);
    setTooltipValue(formatTime(timeSeconds));
  };

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);
  const handleOnFocus = () => setShowControls(true);
  const handleOnBlur = () => setShowControls(false);
  const handleTouchStart = () => setShowTooltip(true);
  const handleTouchEnd = () => setShowTooltip(false);

  return (
    <Box
      w="100%"
      height={["v-lg", "v-sm"]}
      position="relative"
      px="v-4"
      py="v-24"
      ref={progressBarRef}
      mb={["v-32", "v-4"]}
    >
      {isTitlesOn ? (
        <Flex justifyContent="space-between">
          <Text size="body-medium-strong">Title Placeholder</Text>
          <Text
            size="body-small-regular"
            color="white"
          >{`${formatTime(currentTime)} / ${formatTime(duration)}`}</Text>
        </Flex>
      ) : (
        <Flex justifyContent="space-between">
          <Text size="body-small-regular" color="white">
            {formatTime(currentTime)}
          </Text>
          <Text size="body-small-regular" color="white">
            {formatTime(duration)}
          </Text>
        </Flex>
      )}

      <Slider
        id="video-timeline"
        defaultValue={0}
        colorScheme="whiteAlpha"
        value={progress}
        min={0}
        max={100}
        step={0.1}
        focusThumbOnChange={false}
        onChange={handleSliderChange}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        aria-label="Video Progress"
      >
        <SliderTrack
          bg="border-media-subtle"
          background-blend-mode="color-dodge, normal"
        >
          <SliderFilledTrack bg="var(--focusVisible-outline-on-bold, #FFF)" />
        </SliderTrack>
        <SliderThumb boxSize="v-sm" />
      </Slider>

      {showTooltip && (
        <Tooltip
          placement="top"
          isOpen={showTooltip}
          label={tooltipValue}
          aria-label="Video time tooltip"
          mt="-40px"
          sx={{
            position: "absolute",
            borderRadius: "8px",
            background: "var(--Background-Media-overlay, rgba(0, 0, 0, 0.56))",
            backdropFilter: "blur(12px)",
          }}
        >
          <Box position="absolute" left={`${tooltipPosition}%`} />
        </Tooltip>
      )}
    </Box>
  );
};

Timeline.displayName = "Timeline";
