import { gql } from "@apollo/client";

const getMemberCarePlanProgress = gql`
  query getMemberCarePlanProgress($member_id: ID!) {
    care_plan_progress(member_id: $member_id) {
      id
      label
      progress_steps {
        call_to_action
        completed
        skipped
        cta_path
        description
        external_name
        id
        internal_name
        alt_copy
        timeline_name
      }
    }
  }
`;

export default getMemberCarePlanProgress;
