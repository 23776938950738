import { useMutation } from "@apollo/client";
import { startTeenAssessment } from "operations/mutations/assessment";

export const useStartTeenAssessmentMutation = (
  createdAssessmentID,
  setAssessmentData,
  setLoading,
) => {
  return useMutation(startTeenAssessment, {
    variables: {
      input: {
        id: createdAssessmentID,
      },
    },
    onCompleted: (data) => {
      setAssessmentData(data.startAssessment.assessment);
      setLoading(false);
    },
  });
};
