import { DateTime } from "luxon";

type coveredAndRemainingBasicValue = {
  coveredTherapy: number;
  remainingTherapy: number;
  usedTherapy: number;
};
type coveredAndRemainingCoachingValue = {
  coveredCoaching: number;
  remainingCoaching: number;
  usedCoaching;
};
type coveredAndRemainingMedManagementValue = {
  coveredMedManagement: number;
  remainingMedManagement: number;
  usedMedManagement;
};
/**
 *
 * @param memberInfoData member info data returned by getMemberInfo(i.e response.data.user.member)
 * @param stringFormat Luxon friendly stringFormat, will default to Luxon's default format if not provided
 */
export const stringifyVisitsEligibilityDate = (
  memberInfoData,
  stringFormat?: any,
): string => {
  const { visits_eligibility_limit_date } = memberInfoData || {};
  const eligibilityDateTime = DateTime.fromISO(visits_eligibility_limit_date);
  if (eligibilityDateTime.isValid) {
    return stringFormat
      ? eligibilityDateTime.toFormat(stringFormat)
      : eligibilityDateTime.toLocaleString();
  }
  return "";
};

/*
  NOTE: We'll need to translate appointment allocations
  to this flattened visit count view when we start Flex Session M1
*/
export const returnTherapyCoveredAndRemaining = (
  memberInfoData,
): coveredAndRemainingBasicValue => {
  const {
    visits_covered_total: coveredTotal,
    visits_remaining_total: remainingTotal,
    visits_used_basic: usedTherapy,
  } = memberInfoData || {};

  // Negative and decimal values are valid for the sake of not overengineering this
  return {
    coveredTherapy: isNaN(coveredTotal) ? 0 : coveredTotal,
    remainingTherapy: isNaN(remainingTotal) ? 0 : remainingTotal,
    usedTherapy: isNaN(usedTherapy) ? 0 : usedTherapy,
  };
};

export const returnCoachingCoveredAndRemaining = (
  memberInfoData,
): coveredAndRemainingCoachingValue => {
  const {
    visits_covered_coaching: coveredCoaching,
    visits_remaining_coaching: remainingCoaching,
    visits_used_coaching: usedCoaching,
  } = memberInfoData || {};
  return {
    coveredCoaching: isNaN(coveredCoaching) ? 0 : coveredCoaching,
    remainingCoaching: isNaN(remainingCoaching) ? 0 : remainingCoaching,
    usedCoaching: isNaN(usedCoaching) ? 0 : usedCoaching,
  };
};

export const returnMedManagementCoveredAndRemaining = (
  memberInfoData,
): coveredAndRemainingMedManagementValue => {
  const {
    visits_covered_specialist: coveredSpecialist,
    visits_remaining_specialist: remainingSpecialist,
    visits_used_specialist: usedSpecialist,
  } = memberInfoData || {};
  return {
    coveredMedManagement: isNaN(coveredSpecialist) ? 0 : coveredSpecialist,
    remainingMedManagement: isNaN(remainingSpecialist)
      ? 0
      : remainingSpecialist,
    usedMedManagement: isNaN(usedSpecialist) ? 0 : usedSpecialist,
  };
};

export const returnAllSessionCounts = (memberInfoData) => {
  return {
    ...returnCoachingCoveredAndRemaining(memberInfoData),
    ...returnMedManagementCoveredAndRemaining(memberInfoData),
    ...returnTherapyCoveredAndRemaining(memberInfoData),
  };
};

export const hasCoveredTherapyRemaining = (memberInfoData): boolean => {
  const { remainingTherapy } = returnTherapyCoveredAndRemaining(memberInfoData);
  return remainingTherapy > 0;
};

export const hasCoveredCoachingRemaining = (memberInfoData): boolean => {
  const { remainingCoaching } =
    returnCoachingCoveredAndRemaining(memberInfoData);
  return remainingCoaching > 0;
};

export const hasCoveredMedManagementRemaining = (memberInfoData): boolean => {
  const { remainingMedManagement } =
    returnMedManagementCoveredAndRemaining(memberInfoData);
  return remainingMedManagement > 0;
};

export const hasCoveredSessionsAllKinds = (memberInfo) => {
  return {
    hasCoveredTherapyRemaining: hasCoveredCoachingRemaining(memberInfo),
    hasCoveredCoachingRemaining: hasCoveredCoachingRemaining(memberInfo),
    hasCoveredMedManagementRemaining:
      hasCoveredMedManagementRemaining(memberInfo),
  };
};
