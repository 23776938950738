import React from "react";

export const SocialFactors = ({ height = "16", width = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6297 1.32909C12.9632 0.209489 14.7986 -0.142687 16.4648 0.401341L20.3162 1.65888C20.8402 1.82996 21.1233 2.38469 20.9487 2.8979C20.774 3.41112 20.2077 3.68849 19.6838 3.51742L15.8323 2.25987C14.8326 1.93346 13.7314 2.14476 12.9313 2.81652L8.4707 6.5616L8.86729 6.95009C9.57108 7.63947 10.6853 7.71703 11.4815 7.13207L13.4 5.72265C13.8418 5.39806 14.4686 5.48577 14.8 5.91856C15.1314 6.35134 15.0418 6.96532 14.6 7.2899L12.6815 8.69932C11.089 9.86924 8.86065 9.71411 7.45308 8.33535L7.05648 7.94687C6.23269 7.13994 6.28456 5.81684 7.16911 5.07417L11.6297 1.32909Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8861 6.22285C13.322 5.89072 13.9503 5.96765 14.2894 6.39467L16.7054 9.43744C17.6343 10.6073 17.5285 12.2715 16.4587 13.3195L16.2929 13.4818C15.7303 14.0329 14.9672 14.3425 14.1716 14.3425H14.1179C13.8844 14.7882 13.5385 15.1681 13.1128 15.4461L13.0204 15.5065C12.1247 16.0914 10.9776 16.1614 10.0147 15.6898L9.54767 15.461C8.4205 16.0015 7.03479 15.694 6.26217 14.6849L4.8 12.7753C4.61115 12.5286 4.31476 12.3835 4 12.3835C3.44772 12.3835 3 11.9449 3 11.4039C3 10.863 3.44772 10.4244 4 10.4244C4.94427 10.4244 5.83344 10.8599 6.4 11.5998L7.86216 13.5095C8.06989 13.7808 8.45779 13.8459 8.74584 13.6578C9.16777 13.3822 9.70807 13.3493 10.1616 13.5714L10.9091 13.9375C11.2301 14.0947 11.6124 14.0714 11.911 13.8764L12.0034 13.8161C12.1482 13.7215 12.2653 13.5916 12.3431 13.4391L12.6056 12.9249C12.775 12.5931 13.1212 12.3835 13.5 12.3835H14.1716C14.4368 12.3835 14.6911 12.2803 14.8787 12.0966L15.0445 11.9342C15.4011 11.5849 15.4363 11.0301 15.1267 10.6402L12.7106 7.59742C12.3716 7.17039 12.4501 6.55498 12.8861 6.22285Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.99266 0.517063C7.23622 -0.0919914 8.68252 -0.166349 9.98434 0.311841L12.3511 1.18122C12.8682 1.37117 13.1302 1.93578 12.9363 2.44232C12.7424 2.94886 12.166 3.2055 11.6489 3.01555L9.28209 2.14617C8.501 1.85926 7.63322 1.90387 6.88708 2.2693L4.44721 3.46427C3.95324 3.7062 3.35256 3.51008 3.10557 3.02621C2.85858 2.54234 3.05881 1.95396 3.55279 1.71203L5.99266 0.517063Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 11.4039C16 10.863 16.4477 10.4244 17 10.4244H20C20.5523 10.4244 21 10.863 21 11.4039C21 11.9449 20.5523 12.3835 20 12.3835H17C16.4477 12.3835 16 11.9449 16 11.4039Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 1.60862C23.5523 1.60862 24 2.04717 24 2.58815V13.363C24 13.904 23.5523 14.3425 23 14.3425C22.4477 14.3425 22 13.904 22 13.363V2.58815C22 2.04717 22.4477 1.60862 23 1.60862Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 1.60862C1.55228 1.60862 2 2.04717 2 2.58815V13.363C2 13.904 1.55228 14.3425 1 14.3425C0.447715 14.3425 0 13.904 0 13.363V2.58815C0 2.04717 0.447715 1.60862 1 1.60862Z"
        fill="#171E22"
      />
    </svg>
  );
};
