import COLORS from '../../../../../constants/colors';

import React from 'react';
import PropTypes from 'prop-types';

const CoachIcon = ({ color, width, height }) => (
	<svg role="presentation" aria-label="Coach Icon" width={ width || '32' } height={ height || '32' } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill={color || COLORS.PRIMARY_500} fillRule="evenodd" clipRule="evenodd" d="M4.15881 1.45908C4.44743 1.01011 5.03776 0.866944 5.49999 1.13381L10.6961 4.13381L16.7583 7.63381C17.1893 7.88264 17.3717 8.4105 17.1863 8.87234L17.0507 9.21032L18.7923 8.88435L18.5817 8.39587C18.3631 7.8887 18.597 7.30033 19.1041 7.08169C19.6113 6.86306 20.1997 7.09697 20.4183 7.60414L21.0532 9.07702C21.1197 9.17608 21.1694 9.28859 21.1973 9.41121L23.1048 13.8362C23.3235 14.3433 23.0896 14.9317 22.5824 15.1504C22.0752 15.369 21.4869 15.1351 21.2682 14.6279L21.003 14.0127L13.2595 18.6554L11.9184 21.9967C11.8104 22.2657 11.591 22.4746 11.317 22.5693C11.043 22.664 10.7414 22.6352 10.4904 22.4902L0.964088 16.9902C0.713035 16.8453 0.537235 16.5985 0.482245 16.3139C0.427254 16.0293 0.498484 15.7348 0.677477 15.5067L3.5143 11.8927C1.13628 10.4322 0.40881 7.29245 1.92763 4.92983L4.15881 1.45908ZM19.6039 10.7672L20.2042 12.1597L14.4922 15.5844L16.1676 11.4103L19.6039 10.7672ZM4.76056 10.305L8.6976 5.28936L5.32563 3.34255L3.60999 6.01134C2.68364 7.45233 3.14997 9.37515 4.63353 10.2317L4.76056 10.305ZM10.4472 6.29951L2.96262 15.8347L10.4918 20.1817L15.0072 8.93221L10.4472 6.29951ZM7.23181 16.9466C8.36775 17.6024 9.81402 17.224 10.4621 16.1015C11.6357 14.0688 10.5568 11.1875 10.5568 11.1875C10.5568 11.1875 7.52206 11.6938 6.34853 13.7265C5.7004 14.849 6.09586 16.2907 7.23181 16.9466Z" />
	</svg>
);

CoachIcon.propTypes = {
	color  : PropTypes.string,
	width  : PropTypes.string,
	height : PropTypes.string,
};

export default CoachIcon;
