//
import { gql } from "@apollo/client";

const updateMember = gql`
  mutation UpdateMember($input: MemberInput!) {
    updateMember(input: $input) {
      success
      member {
        id
        name
        first_name
        last_name
        email
        phone
        country_code
        date_of_birth
        informed_consent
        hipaa_privacy_policy
        sms_opt_in
        check_in_frequency_days
        optimism
        payment_preference
        opt_in_personal
        sms_opt_in
        workplace_manager
        intent_option
        postal_address {
          city
          country
          state
          street_address_1
          street_address_2
          zip_code
        }
      }
    }
  }
`;

export default updateMember;
