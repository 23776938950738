import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Box, Text } from "design-system/components";
import { formatAppointmentTime } from "components/templates/CareVisitDetails/utils";

export const InPersonCopy = ({ duration, time, provider, location }) => {
  const { t } = useTranslation("careVisits");

  return (
    <>
      <Box as="section">
        <Text as="h2" variant="bodyBold_v1" mb={16}>
          {t("appointmentDetails.details")}
        </Text>
        <Text variant="body_v1" mb={16}>
          <Trans
            ns={"careVisits"}
            i18nKey={"appointmentDetails.inPersonCopy.content1"}
            values={{
              providerName: provider.name,
              appointmentTime: formatAppointmentTime(time),
              location: location.replace(/(\r\n|\n|\r)/gm, " "), // regex replaces new lines and carriage returns with a space
              duration: duration,
            }}
          >
            <Text as="span" variant="bodyBold_v1" />
          </Trans>
        </Text>
        <Text variant="body_v1" mb={16}>
          <Trans
            ns={"careVisits"}
            i18nKey={"appointmentDetails.inPersonCopy.content2"}
          />
        </Text>
      </Box>
    </>
  );
};

InPersonCopy.propTypes = {
  duration: PropTypes.any,
  location: PropTypes.shape({
    replace: PropTypes.func,
  }),
  provider: PropTypes.shape({
    name: PropTypes.any,
  }),
  time: PropTypes.any,
};
