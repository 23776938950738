import React from "react";
import { Box } from "@chakra-ui/react";

const Ellipse = ({ color, opacity }) => {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          cx="12.501"
          cy="12.8906"
          r="12"
          fill={color || "#058261"}
          fillOpacity={opacity || "0.12"}
        />
      </svg>
    </Box>
  );
};

export default Ellipse;
