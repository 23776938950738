import Router from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";

export const trackMemberClickedCrisisSupportPhoneNumber = (
  crisisSupportNumber,
) => {
  return TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    crisisSupportNumber,
    "Crisis support phone number",

    {
      location: "Manager Experience",
      spring_doc_id: "Manager_Experience_082",
    },
  );
};

export const trackMemberClickedEmailButton = (emailAddress) => {
  return TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    `mailto:${emailAddress}`,
    "Email Contact Button",
    {
      location: "Manager Experience",
      spring_doc_id: "Manager_Experience_020",
    },
  );
};
