import { createIcon } from "@chakra-ui/react";

export const HelpingHand = createIcon({
  displayName: "HelpingHand",
  viewBox: "0 0 24 24 ",
  path: (
    <>
      <path
        d="M17.6119 18.3309L18.2412 19.1081L17.6119 18.3309ZM20.8906 15.6761L21.5199 16.4533V16.4533L20.8906 15.6761ZM20.7767 14.042L21.2922 13.1851L21.2922 13.1851L20.7767 14.042ZM20.6496 13.9655L20.1342 14.8224L20.1342 14.8225L20.6496 13.9655ZM17.4723 14.0184L16.9286 13.1791V13.1791L17.4723 14.0184ZM16.1459 14.8776L15.6022 14.0383L16.1459 14.8776ZM10.888 14.709C10.3591 14.55 9.80143 14.8498 9.64237 15.3787C9.48331 15.9075 9.78311 16.4652 10.312 16.6243L10.888 14.709ZM2.42557 20.1814C1.9735 20.4987 1.8642 21.1223 2.18144 21.5744C2.49869 22.0265 3.12235 22.1358 3.57443 21.8186L2.42557 20.1814ZM8.62821 20.2222H15.0947V18.2222H8.62821V20.2222ZM18.2412 19.1081L21.5199 16.4533L20.2613 14.8989L16.9826 17.5537L18.2412 19.1081ZM21.2922 13.1851L21.1651 13.1086L20.1342 14.8225L20.2613 14.8989L21.2922 13.1851ZM16.9286 13.1791L15.6022 14.0383L16.6896 15.7169L18.016 14.8577L16.9286 13.1791ZM10.312 16.6243C12.468 17.2727 14.8 16.9409 16.6896 15.7169L15.6022 14.0383C14.2055 14.9431 12.4817 15.1883 10.888 14.709L10.312 16.6243ZM21.1651 13.1086C19.8554 12.3208 18.2113 12.3482 16.9286 13.1791L18.016 14.8577C18.6573 14.4422 19.4794 14.4286 20.1342 14.8224L21.1651 13.1086ZM21.5199 16.4533C22.5985 15.5799 22.4814 13.9004 21.2922 13.1851L20.2613 14.8989V14.8989L21.5199 16.4533ZM15.0947 20.2222C16.2402 20.2222 17.3509 19.8289 18.2412 19.1081L16.9826 17.5537C16.4485 17.9862 15.782 18.2222 15.0947 18.2222V20.2222ZM3.57443 21.8186C5.05489 20.7796 6.81958 20.2222 8.62821 20.2222V18.2222C6.40843 18.2222 4.24259 18.9063 2.42557 20.1814L3.57443 21.8186Z"
        fill="currentColor"
      />
      <path
        d="M14.519 14.4481C14.2142 14.9087 14.3405 15.5291 14.8011 15.8339C15.2616 16.1387 15.8821 16.0124 16.1869 15.5519L14.519 14.4481ZM2.44246 13.7254C1.98398 14.0333 1.86193 14.6546 2.16985 15.1131C2.47776 15.5716 3.09905 15.6936 3.55754 15.3857L2.44246 13.7254ZM13.1653 12.0437L12.9542 11.0662L13.1653 12.0437ZM14.1418 11.8329L13.9308 10.8554L14.1418 11.8329ZM16.1869 15.5519L16.6557 14.8434L14.9878 13.7397L14.519 14.4481L16.1869 15.5519ZM13.9308 10.8554L12.9542 11.0662L13.3763 13.0212L14.3528 12.8104L13.9308 10.8554ZM5.74402 11.5081L2.44246 13.7254L3.55754 15.3857L6.85909 13.1684L5.74402 11.5081ZM10.8929 10.9128C9.18139 10.2662 7.26282 10.488 5.74402 11.5081L6.85909 13.1684C7.84048 12.5093 9.08018 12.3659 10.1861 12.7837L10.8929 10.9128ZM12.9542 11.0662C12.2667 11.2146 11.5509 11.1614 10.8929 10.9128L10.1861 12.7837C11.2044 13.1684 12.3122 13.2509 13.3763 13.0212L12.9542 11.0662ZM16.6557 14.8434C17.946 12.8936 16.2162 10.362 13.9308 10.8554L14.3528 12.8104C14.8854 12.6954 15.2885 13.2853 14.9878 13.7397L16.6557 14.8434Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 3C11.1119 3 11 3.11193 11 3.25V5L9.25 5C9.11193 5 9 5.11193 9 5.25V6.75C9 6.88807 9.11193 7 9.25 7H11V8.75C11 8.88807 11.1119 9 11.25 9H12.75C12.8881 9 13 8.88807 13 8.75V7H14.75C14.8881 7 15 6.88807 15 6.75V5.25C15 5.11193 14.8881 5 14.75 5L13 5V3.25C13 3.11193 12.8881 3 12.75 3H11.25Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    "aria-label": "Helping Hand icon",
    role: "img",
  },
});
