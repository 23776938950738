//

import PropTypes from "prop-types";
import React, { Component } from "react";
import Router from "next/router";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import classnames from "classnames";
import {
  Subtitle,
  Subhead,
  Button,
  Section,
  SpringMedication,
} from "@spring/smeargle";

import promptStyles from "components/assessments/Prompt/styles.module.scss";

import styles from "./styles.module.scss";

import routes from "routes";
import finishAssessment from "operations/mutations/assessment/finishAssessment";
import { updateQuestionnaire } from "operations/mutations/questionnaire";
import { track } from "utils/mixpanel";
import { getIterableCampaignInfo } from "utils/localStorage";

class SubmitSprass extends Component {
  static propTypes = {
    assessmentId: PropTypes.any,
    assessmentKind: PropTypes.any,
    direction: PropTypes.any,
    finishAssessment: PropTypes.func,
    stackPosition: PropTypes.any,
    subtitle: PropTypes.any,
    title: PropTypes.any,
  };

  get title() {
    if (this.props.title) {
      return (
        <Subhead center bold noMargin>
          {this.props.title}
        </Subhead>
      );
    }

    return null;
  }

  get subtitle() {
    if (this.props.subtitle) {
      return (
        <Section size="xlg">
          <Subtitle>{this.props.subtitle}</Subtitle>
        </Section>
      );
    }

    return null;
  }

  get actions() {
    return (
      <Button
        text="Request a Medication Consultation"
        onClick={this.submit}
        debounce={2000}
        icon="arrow-right"
      />
    );
  }

  submit = () => {
    track("Assessment -- Submitted", {
      "Assessment Kind": this.props.assessmentKind,
      "Assessment Id": this.props.assessmentId,
    });

    this.props.finishAssessment(this.props.assessmentId).then(() => {
      track("Assessment -- Finished", {
        "Assessment Kind": this.props.assessmentKind,
        "Assessment Id": this.props.assessmentId,
      });

      Router.replace(routes.MemberCareVisits.to, routes.MemberCareVisits.as);
    });
  };
  render() {
    return (
      <div
        className={classnames(
          promptStyles.prompt,
          promptStyles[this.props.stackPosition],
          promptStyles[this.props.direction],
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <SpringMedication />
          </div>
          {this.title}
          {this.subtitle}
          <Section size="xlg">{this.actions}</Section>
        </div>
      </div>
    );
  }
}

export { SubmitSprass };

export default compose(
  connect((state, ownProps) => ({
    formData: getOr({}, `${ownProps.formKey}.data`, state.form),
  })),
  graphql(updateQuestionnaire, {
    props: ({ mutate }) => ({
      updateQuestionnaire: (id, responses) => {
        let normalized = {};

        for (let res in responses) {
          let num = +responses[res];
          // If the value is a stringified numuber, turn it into a num
          // otherwise, keep it a string.
          normalized[res] = Number.isNaN(num) ? responses[res] : num;
        }

        const input = {
          id,
          patch: { responses: JSON.stringify(normalized) },
        };

        return mutate({
          variables: { input },
        });
      },
    }),
  }),
  graphql(finishAssessment, {
    props: ({ mutate }) => ({
      finishAssessment: (id) => {
        const input = { id };

        return mutate({
          variables: {
            input: { ...input, campaign: getIterableCampaignInfo() },
          },
          refetchQueries: ["getMemberInfo"],
        });
      },
    }),
  }),
)(SubmitSprass);
