import NextLink from "next/link";
import { Button } from "@springcare/sh-component-library";

type VButtonLinkProps = {
  href: string;
  variant?: string;
  i18nTranslatedText: string;
  dataCy?: string;
};

// NOTE: Ensure props that shouldn't be rendered in as HTML attributes
// are destructured to keep the console less noisy!
export const VButtonLink = ({
  href,
  variant,
  // Link text should be translated ahead of time
  i18nTranslatedText,
  // NOTE: Uncomment when tracking is to be implemented
  // mpTrackingProps,
  dataCy,
  ...props
}: VButtonLinkProps): JSX.Element => {
  return (
    <NextLink passHref href={href}>
      <Button as="a" variant={variant} data-cy={dataCy} {...props}>
        {i18nTranslatedText}
      </Button>
    </NextLink>
  );
};
