import { gql } from "@apollo/client";

import { careTeamMember } from "operations/fragments/careProvider";

const updateCareProvider = gql`
  mutation UpdateCareProvider($input: UpdateCareProviderInput!) {
    updateCareProvider(input: $input) {
      success
      care_provider {
        ...careTeamMember
      }
    }
  }
  ${careTeamMember}
`;

export default updateCareProvider;
