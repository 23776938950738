import { Box } from "@chakra-ui/react";
import { Spinner, useDisclosure } from "design-system/components";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { getCareTeam } from "operations/queries/careProvider";
import { getMemberCareItems } from "operations/queries/member";
import { formatMemberAddress } from "utils/displayHelpers";
import { getHomePageMemberInfo } from "components/templates/HomePage/queries/getHomePageMemberInfo";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import Meowth from "@spring/meowth";
import {
  AlsoAvailableSection,
  AvailabilityRequestModal,
  CareProviderScheduleModal,
  CareProviderScheduleModalV2,
  YourCareSection,
} from "components/index";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import { getAssessmentRiskLevels } from "operations/queries/assessment";
import { hasManagedDependents } from "utils/memberHelpers";
import { WorkplaceManagerBanners } from "modules/MemberDashboard/Home/components/ManagerExperience/Workplace/components";
import { useWorkplaceManagerBanner } from "components/templates/HomePage/hooks/useWorkplaceManagerBanner";
import { DateTime } from "luxon";
import { useScheduleModalWithProps } from "shared/hooks";
import { SpecializedCareNavigatorAvailabilityModal } from "components/modals/CareNavigatorScheduleModal/CareNavigatorAvailabilityModal";
import { MemberGreeting } from "shared/components";
import { useTranslation } from "react-i18next";
import { PreAssessmentBanner } from "modules/MemberDashboard/Teen/Home/components";

const PreAssessedHomePage = () => {
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();

  const peerRecoverySpecialistAvailable = useFeatureFlag(
    FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE,
  );
  const { data: memberData } = useQuery(
    getHomePageMemberInfo(peerRecoverySpecialistAvailable),
    {
      ...apolloOptionsUserId,
    },
  );

  const member = memberData?.user?.member;
  const memberId: string | undefined = member?.id;

  const address = formatMemberAddress(memberData);
  const getCareTeamVars = address
    ? { id: memberData.user.id, distance_from: { address: address } }
    : { id: apolloOptionsUserId.variables.id };

  const { data: careTeamData } = useQuery(getCareTeam, {
    variables: getCareTeamVars,
    skip: !memberData,
    fetchPolicy: "cache-and-network",
  });

  const connectedBenefitsEnabled = useFeatureFlag(
    FLAGS.SHOW_CONNECTED_BENEFITS,
  );
  const { data: careItemsData } = useQuery(getMemberCareItems, {
    variables: {
      id: apolloOptionsUserId.variables.id,
      connected_benefits_enabled: connectedBenefitsEnabled,
    },
    fetchPolicy: "network-only",
  });

  const momentsAreRecommended =
    careItemsData?.user?.member?.care_items?.recommended_care_items.find(
      (care_item) => care_item.care_item_name === "moments",
    );
  const HOMEPAGE_COLUMNS = [4, 4, 8, 12];
  //Any use of Grid or SimpleGrid should use this HOMEPAGE_COLUMNS variable
  const GRID_SPACING = 24;

  const coachingAvailableSectionFlag = useFeatureFlag(
    FLAGS.COACHING_AVAILABLE_SECTION,
  );
  const {
    shouldShowCoaching,
    allowDirectScheduling,
    showCoachingPaidExperience,
    isLoading: isBenefitsLoading,
  } = useMemberBenefits(memberId);
  const coach = careTeamData?.user?.member?.care_team?.coach;
  const isDirectScheduling = (component?: string): boolean => {
    let qualifiesForCoaching = shouldShowCoaching;
    const paidCoachingExperience =
      coachingAvailableSectionFlag && showCoachingPaidExperience;
    const isDirectSchedulingComponent =
      component === "carePlans"
        ? paidCoachingExperience
        : allowDirectScheduling || paidCoachingExperience;
    qualifiesForCoaching =
      component === "carePlans"
        ? qualifiesForCoaching
        : !!coach || qualifiesForCoaching;

    return isDirectSchedulingComponent && qualifiesForCoaching;
  };

  const memberCountry = member?.postal_address?.country;
  const showGlobalExperience =
    memberCountry && memberCountry.length > 0 && memberCountry !== "US";
  const coachVisitsRemaining =
    memberData?.user?.member?.visits_remaining_coaching;
  const coachVisitsCovered = memberData?.user?.member?.visits_covered_coaching;

  const cohort = member?.cohort;
  const memberHasInPersonSupport = cohort?.in_person_supported;

  const isGlobalWithNoCoachingSessions = () => {
    return (
      shouldShowCoaching &&
      showGlobalExperience &&
      coachVisitsRemaining === 0 &&
      coachVisitsCovered > 0
    );
  };

  const hasEverCompletedARecommendedCareItem =
    careItemsData &&
    JSON.parse(careItemsData?.user?.member?.care_items?.metadata)
      ?.ever_completed_a_recommended_care_item;

  const { data: memberAssessments } = useQuery(getAssessmentRiskLevels, {
    variables: { member_id: memberId },
  });
  const memberHasManagedDependents = hasManagedDependents(member);
  const isSpecialtyCareNavigatorSupported =
    memberData?.user?.member?.is_eligible_for_specialty_care;

  const {
    isOpen: isSpecializedCNScheduleModalOpen,
    onOpen: openSpecializedCNScheduleModal,
    onClose: closeSpecializedCNScheduleModal,
  } = useDisclosure();

  const {
    showWorkplaceManagerSelfIdCardOnTop,
    showExploreCard,
    removeWorkplaceManagerBanner,
  } = useWorkplaceManagerBanner(member);
  const selectedOption = member?.intent_option;

  const customerId = cohort?.customer?.id;
  const availableCareItems =
    careItemsData?.user?.member?.care_items?.available_care_items;

  const visitsEligibilityLimitDate = DateTime.fromISO(
    member?.visits_eligibility_limit_date || "",
  );
  const formattedVisitsEligibilityLimitDate = visitsEligibilityLimitDate.isValid
    ? visitsEligibilityLimitDate.toLocaleString(DateTime.DATE_FULL)
    : "";
  const sessionSnapshot = {
    expirationDate: formattedVisitsEligibilityLimitDate,
    totalVisitsCovered: member?.visits_covered_total || 0,
    remainingCovered: member?.visits_remaining_total || 0,
    specialtyVistsRemaining: member?.visits_remaining_specialist || 0,
    visits_covered_coaching: member?.visits_covered_coaching,
  };

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const isHealthPlan = member?.cohort?.customer?.is_health_plan;

  const connectedBenefitsMetadata = availableCareItems?.find(
    (item) => item.care_item_name === "connected_benefits",
  )?.metadata;
  const recommendedConnectedBenefits = connectedBenefitsMetadata
    ? JSON.parse(connectedBenefitsMetadata)
    : [];
  const recommendedCareItems =
    careItemsData?.user.member.care_items.recommended_care_items;

  const isSpecializedCNSupported =
    availableCareItems?.some(
      (item) => item.care_item_name === "specialized_care_navigator",
    ) ||
    recommendedCareItems?.some(
      (item) => item.care_item_name === "specialized_care_navigator",
    );
  const [isFromSUDSignalExperience, setIsFromSUDSignalExperience] =
    useState(false);

  const { t } = useTranslation("limitedLangHomepage");

  const isLoading = !memberData || !careTeamData || !careItemsData;

  return (
    <>
      {isLoading ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner speed="1s" size="xl" />
        </Box>
      ) : (
        <>
          <MemberGreeting
            memberFirstName={memberData?.user?.first_name}
            memberGreeting={t("homepage:greeting.welcome")}
            size="heading-small"
            as="p"
          />
          <PreAssessmentBanner />
          <YourCareSection
            careTeam={careTeamData}
            hasEverCompletedARecommendedCareItem={
              hasEverCompletedARecommendedCareItem
            }
            momentsAreRecommended={momentsAreRecommended}
            columns={HOMEPAGE_COLUMNS}
            member={member}
            isDirectScheduling={isDirectScheduling()}
            isGlobalWithNoCoachingSessions={isGlobalWithNoCoachingSessions()}
            spacing={GRID_SPACING}
            memberHasInPersonSupport={memberHasInPersonSupport}
            memberCountry={memberCountry}
            memberAssessments={memberAssessments}
            hasManagedDependents={memberHasManagedDependents}
            isSpecialtyCareNavigatorSupported={
              isSpecialtyCareNavigatorSupported
            }
            openSpecializedCNScheduleModal={openSpecializedCNScheduleModal}
            showNewPreAssessedExperience={true}
          />
          {showWorkplaceManagerSelfIdCardOnTop && (
            <WorkplaceManagerBanners
              removeWorkplaceManagerBanner={removeWorkplaceManagerBanner}
              memberId={memberId}
              showExploreCard={showExploreCard}
              showWorkplaceManagerSelfIdCardOnBottom={
                showWorkplaceManagerSelfIdCardOnTop
              }
              selectedOption={selectedOption}
            />
          )}
          <AlsoAvailableSection
            availableCareItems={availableCareItems}
            careTeam={careTeamData}
            customerId={customerId}
            memberId={memberId}
            sessionSnapshot={sessionSnapshot}
            isDirectScheduling={isDirectScheduling()}
            tobaccoCtaCallBack={() => {}}
            onScheduleModalOpen={onScheduleModalOpen}
            hasManagedDependents={memberHasManagedDependents}
            openSpecializedCNScheduleModal={openSpecializedCNScheduleModal}
            isHealthPlan={isHealthPlan}
            recommendedConnectedBenefits={recommendedConnectedBenefits}
            selectedOption={selectedOption}
            showPreAssessedExperience={true}
          />
          <CareProviderScheduleModal />
          <AvailabilityRequestModal />
          <CareProviderScheduleModalV2
            isOpen={isScheduleModalOpen}
            onClose={onScheduleModalClose}
            {...scheduleModalProps}
          />
          <SpecializedCareNavigatorAvailabilityModal
            isOpen={isSpecializedCNScheduleModalOpen}
            isSpecializedCNSupported={isSpecializedCNSupported}
            onClose={() => {
              setIsFromSUDSignalExperience(false);
              closeSpecializedCNScheduleModal();
            }}
            onOpen={openSpecializedCNScheduleModal}
            isFromSUDSignalExperience={isFromSUDSignalExperience}
          />
        </>
      )}
    </>
  );
};

export default PreAssessedHomePage;
