import React from "react";
import { Section, Modal, Content, Subhead, Bolded } from "@spring/smeargle";
import { modalIds, links } from "@spring/constants";

import styles from "./styles.module.scss";

import { Link } from "components";
import routes from "routes";

const NoChildEmailModal = () => (
  <Modal id={modalIds.noChildEmailModal} size="lg">
    <Subhead semibold noMargin>
      Don't have an email address for your child?
    </Subhead>

    <Section>
      <div className={styles.subheader}>
        Spring Health requires children accounts to have a separate email
        address. If you do not have an email address for your child, here are
        some suggestions:
      </div>
    </Section>

    <div className={styles.bodyText}>
      <Content inheritColor>
        <ul>
          <li className={styles.listItem}>
            <Bolded>Create an email address for them that you manage.</Bolded>{" "}
            Sign up for a new email address using your information that can be
            used for all child related communication. Because they are free and
            also offer ways to set up and manage family accounts, Spring
            recommends
            <Link
              to={links.GoogleAccount}
              mpTracking={{
                page: routes.FamilySignup.as,
                to: links.GoogleAccount,
                type: "Google",
                location: "No Child Email Modal",
              }}
            >
              <Bolded> Gmail</Bolded>
            </Link>
            ,
            <Link
              to={links.iCloudAccount}
              mpTracking={{
                page: routes.FamilySignup.as,
                to: links.iCloudAccount,
                type: "iCloud",
                location: "No Child Email Modal",
              }}
            >
              <Bolded> iCloud email</Bolded>
            </Link>
            , and
            <Link
              to={links.OutlookAccount}
              mpTracking={{
                page: routes.FamilySignup.as,
                to: links.OutlookAccount,
                type: "Outlook",
                location: "No Child Email Modal",
              }}
            >
              <Bolded> Outlook</Bolded>
            </Link>
            .
          </li>
          <li className={styles.listItem}>
            <Bolded>Use a spare email address.</Bolded> If you have an
            additional email address that isn't associated with your Spring
            Health account, you can use it here.
          </li>
          <li className={styles.listItem}>
            <Bolded>Create a Gmail alias.</Bolded> If you have a Gmail account,
            append a plus ("+") sign and any combination of words or numbers
            after your email address. For example, if your name was{" "}
            <span className={styles.italic}>greatguardian@gmail.com</span>, you
            could send mail to{" "}
            <span className={styles.italic}>
              greatguardian<Bolded>+child1</Bolded>@gmail.com
            </span>{" "}
            or{" "}
            <span className={styles.italic}>
              greatguardian<Bolded>+child2</Bolded>@gmail.com.
            </span>
          </li>
        </ul>
      </Content>
    </div>
  </Modal>
);

export default NoChildEmailModal;
