import { useDisclosure } from "@chakra-ui/react";

export const useTeenFAQFlyoutDisclosures = () => {
  const {
    isOpen: isTherapyAtSpringOpen,
    onOpen: onTherapyAtSpringOpen,
    onClose: onTherapyAtSpringOpenClose,
  } = useDisclosure();
  const {
    isOpen: isPrivateVirtualSessionOpen,
    onOpen: onPrivateVirtualSessionOpen,
    onClose: onPrivateVirtualSessionClose,
  } = useDisclosure();
  const {
    isOpen: isNeedForTherapyOpen,
    onOpen: onNeedForTherapyOpen,
    onClose: onNeedForTherapyClose,
  } = useDisclosure();
  const {
    isOpen: isTalkToParentsOpen,
    onOpen: onTalkToParentsOpen,
    onClose: onTalkToParentsClose,
  } = useDisclosure();

  return {
    therapyAtSpring: {
      isOpen: isTherapyAtSpringOpen,
      onOpen: onTherapyAtSpringOpen,
      onClose: onTherapyAtSpringOpenClose,
    },
    privateVirtualSession: {
      isOpen: isPrivateVirtualSessionOpen,
      onOpen: onPrivateVirtualSessionOpen,
      onClose: onPrivateVirtualSessionClose,
    },
    needForTherapy: {
      isOpen: isNeedForTherapyOpen,
      onOpen: onNeedForTherapyOpen,
      onClose: onNeedForTherapyClose,
    },
    talkToParents: {
      isOpen: isTalkToParentsOpen,
      onOpen: onTalkToParentsOpen,
      onClose: onTalkToParentsClose,
    },
  };
};
