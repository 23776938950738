import React from "react";
import PropTypes from "prop-types";
import {
  Subtitle,
  Bolded,
  Grid,
  Col,
  Content,
  FlexRow,
  Button,
  Icon,
  LinkButton,
} from "@spring/smeargle";
import { Trans, useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { track } from "utils/mixpanel";
import { formatPhoneForDisplay } from "utils/global";
import envUtils from "utils/environment";
import { compose } from "redux";
import { connect } from "react-redux";
import { openModal } from "@spring/smeargle/src/actions";
import { CareProviderScheduleModal } from "components/modals";
import { useHelpInfo } from "hooks/useHelpInfo";
import { useRouter } from "next/router";
import routes from "routes";
import { isBoeing } from "constants/customers";
import useChatBotHandler from "utils/chatbot/useChatBotHandler";

const EAPBenefit = ({ cohort, showGlobalExperience }) => {
  const {
    cohort_eap_link,
    eap_access_code_text,
    customer,
    eap_freephone_number,
    eap_direct_dial_number,
  } = cohort;

  const { helpInfo } = useHelpInfo();
  const router = useRouter();

  const defaultWorkLifeFreePhone = helpInfo?.region?.work_life_free_phone;
  const defaultWorkLifeDirectDial = helpInfo?.region?.work_life_direct_dial;

  const workLifeFreePhone =
    formatPhoneForDisplay(eap_freephone_number) || defaultWorkLifeFreePhone;
  const workLifeDirectDial =
    formatPhoneForDisplay(eap_direct_dial_number) || defaultWorkLifeDirectDial;

  const customerName = customer && customer.name;
  const customerId = customer && customer.id;

  const { handleOpenChat } = useChatBotHandler();

  const handleChatOnClick = () => {
    handleOpenChat();
  };

  const openResourceTab = () => {
    track("EAP - Clicked EAP_URL Site", { customer: customerName });
    window.open(cohort_eap_link);
  };

  const { t } = useTranslation(["benefits", "limitedLangBenefits"]);
  const careTeamEmail = envUtils.careTeamEmail;

  const onClick = () => {
    router.push(routes.CareNavigation.as);
  };
  return (
    <>
      <div className={styles.title}>
        <Subtitle>
          <Bolded>{t("limitedLangBenefits:EAPBenefit.label")}</Bolded>
        </Subtitle>
      </div>
      <Subtitle className={styles}>
        {showGlobalExperience ? (
          <Trans
            ns="benefits"
            i18nKey="EAPBenefit.blurbGlobal"
            values={{ careTeamEmail }}
            components={[
              <a
                key="0"
                className={styles.inlineTextLink}
                href={`mailto:${envUtils.careTeamEmail}`}
              >{` ${careTeamEmail}`}</a>,
              <LinkButton
                key="1"
                role="link"
                inlineTextLink={true}
                bold={false}
                small={false}
                letterSpacing={false}
                text={t("benefits:coveredCareContent.realTimeChat")}
                onClick={handleChatOnClick}
              />,
              <LinkButton
                key="2"
                role="link"
                inlineTextLink={true}
                bold={false}
                small={false}
                letterSpacing={false}
                text={t("benefits:coveredCareContent.findATime")}
                onClick={onClick}
              />,
            ]}
          />
        ) : (
          t("benefits:EAPBenefit.blurb")
        )}
      </Subtitle>
      <Grid>
        <Col lg={6} md={12}>
          <Subtitle>{t("benefits:EAPBenefit.includes")}</Subtitle>
          <Grid>
            <Col size={2}>
              <div className={styles.checklistIcon}>
                <Icon color="PRIMARY_500" type="scale-balance" />
              </div>
            </Col>
            <Col size={10}>
              <FlexRow>
                <Bolded>
                  {t("benefits:EAPBenefit.financialServices.label")}
                </Bolded>
              </FlexRow>
              <Content>
                {t("benefits:EAPBenefit.financialServices.content")}
              </Content>
            </Col>

            <Col size={2}>
              <div className={styles.checklistIcon}>
                <Icon color="PRIMARY_500" type="bank" />
              </div>
            </Col>
            <Col size={10}>
              <FlexRow>
                <Bolded>
                  {t("benefits:EAPBenefit.legalAssistance.label")}
                </Bolded>
              </FlexRow>
              <Content>
                {t("benefits:EAPBenefit.legalAssistance.content")}
              </Content>
            </Col>

            <Col size={2}>
              <div className={styles.checklistIcon}>
                <Icon color="PRIMARY_500" type="food-apple" />
              </div>
            </Col>
            <Col size={10}>
              <FlexRow>
                <Bolded>
                  {t("benefits:EAPBenefit.majorLifeEvents.label")}
                </Bolded>
              </FlexRow>
              <Content>
                {t("benefits:EAPBenefit.majorLifeEvents.content")}
              </Content>
            </Col>

            <Col size={2}>
              <div className={styles.checklistIcon}>
                <Icon color="PRIMARY_500" type="map-marker" />
              </div>
            </Col>
            <Col size={10}>
              <FlexRow>
                <Bolded>{t("benefits:EAPBenefit.localServices.label")}</Bolded>
              </FlexRow>
              <Content>
                {t("benefits:EAPBenefit.localServices.content")}
              </Content>
            </Col>
          </Grid>
        </Col>

        <Col lg={6} md={12}>
          <Subtitle>{t("benefits:EAPBenefit.accessSubtitle")}</Subtitle>
          <Grid>
            <Col size={5}>
              <span className={styles.bullet} />
              <Bolded>{t("limitedLangBenefits:EAPBenefit.freephone")}</Bolded>
            </Col>
            <Col size={7}>
              <div className={styles.checklistItem}>
                <Content>{workLifeFreePhone}</Content>
              </div>
            </Col>

            <Col size={5}>
              <span className={styles.bullet} />
              <Bolded>{t("limitedLangBenefits:EAPBenefit.directDial")}</Bolded>
            </Col>
            <Col size={7}>
              <div className={styles.checklistItem}>
                <Content>{workLifeDirectDial}</Content>
              </div>
            </Col>
          </Grid>

          <div className={styles.exploreButton}>
            <Button
              full
              text={t("benefits:EAPBenefit.buttonText")}
              onClick={openResourceTab}
            />
          </div>

          {!isBoeing(customerId) && (
            <Bolded>
              <Icon type="alert-circle" />{" "}
              {t("benefits:EAPBenefit.enterAccessCode", {
                eap_access_code_text: eap_access_code_text,
              })}
            </Bolded>
          )}
        </Col>
      </Grid>
      <CareProviderScheduleModal />
    </>
  );
};

EAPBenefit.propTypes = {
  cohort: PropTypes.shape({
    cohort_eap_link: PropTypes.string,
    customer: PropTypes.shape({
      name: PropTypes.string,
    }),
    eap_access_code_text: PropTypes.string,
    eap_direct_dial_number: PropTypes.string,
    eap_freephone_number: PropTypes.string,
  }),
  country: PropTypes.string,
  showGlobalExperience: PropTypes.bool,
};

EAPBenefit.defaultProps = {
  cohort: {},
};

export default compose(connect(null, { openModal }))(EAPBenefit);
