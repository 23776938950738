import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Trans, useTranslation } from "react-i18next";
import yup from "schemas/yup";
import {
  Bolded,
  Breakpoint,
  Card,
  Checkbox,
  Content,
  Col,
  DateSelect,
  Form,
  Input,
  Subhead,
  Grid,
  LoadingCircle,
} from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import { PotentialMemberType, links } from "@spring/constants";
import { parseSSOBirthday } from "utils/memberHelpers";
import styles from "./styles.module.scss";
import { AUTH_MESSAGE_CL_LOOKUP_FAILED } from "constants/pichu";
import { Link } from "components";
import {
  updateFindMyMembershipModel,
  updateVerificationModel,
} from "actions/signUp";
import routes from "routes";
import { getFirstError } from "utils/apollo/errorHandler";
import {
  track,
  TRACK_EVENT,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";
import { getLanguageFromCountry } from "utils/language";
import { normalizeToISO2 } from "utils/global";
import { localeCountry } from "actions/global/actions";
import { minAgeDOB } from "schemas/minAgeDOB";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { useIsAwaitingSsoRelayState } from "hooks/useIsAwaitingSsoRelayState";
import { useLazyFindCoveredLife } from "hooks/useLazyFindCoveredLife";

const mpPrefix = "Let's find your benefits (/verification)";

const memberVerificationSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  date_of_birth: minAgeDOB(13).required(),
  email: yup.string().email().nullable(),
  electronic_communication_agreement: yup.boolean().equals([true]).required(),
});

const MemberVerificationForm = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formData, setFormData] = useState(null);
  const isAwaitingSSORelayInformation = useIsAwaitingSsoRelayState(
    props.ssoRelayState,
  );

  const params = new URLSearchParams(window.location.search);

  const {
    getFindCoveredLife: getCoveredLife,
    loading,
    data,
  } = useLazyFindCoveredLife({
    onError: (err) => props.addNotification(getFirstError(err), "error"),
    fetchPolicy: "network-only",
  });

  const potentialMemberType = props.findMyMembershipModel.potential_member_type;

  // Removes error message when user updates form data.
  const handleErrorMessageReset = () => {
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (potentialMemberType === PotentialMemberType.Employee) {
      track(`${mpPrefix} -- Primary Viewed`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
          page: routes.Verification.as,
        }),
      });
    }

    if (potentialMemberType === PotentialMemberType.Dependent) {
      track(`${mpPrefix} -- Dependent Viewed`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
          page: routes.Verification.as,
        }),
      });
    }
  }, []);

  const getInitialFormData = () => {
    let dob = props.ssoRelayState?.attributes_transformed?.date_of_birth;

    return {
      first_name: props.ssoRelayState?.attributes_transformed?.first_name,
      last_name: props.ssoRelayState?.attributes_transformed?.last_name,
      date_of_birth: dob ? { ...parseSSOBirthday(dob) } : {},
      email: props.ssoRelayState?.attributes_transformed?.email,
    };
  };

  const getAgeInYears = (birthday) => {
    const today = new Date();
    const birthdate = new Date(birthday);

    let yearDiff = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdate.getDate())
    ) {
      yearDiff--;
    }

    return yearDiff;
  };

  const handleSubmit = async (formData) => {
    TRACK_EVENT.FORM_SUBMITTED(routes.Verification.as, "Verification");
    if (potentialMemberType === PotentialMemberType.Employee) {
      track(`${mpPrefix} -- Primary Submitted`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
          page: routes.Verification.as,
          type: "Verification",
        }),
      });
    }

    if (potentialMemberType === PotentialMemberType.Dependent) {
      track(`${mpPrefix} -- Dependent Submitted`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
          page: routes.Verification.as,
          type: "Verification",
        }),
      });
    }
    setFormData(formData);
    const { first_name, last_name, date_of_birth, email } = formData;

    const age =
      date_of_birth.actualDate && getAgeInYears(date_of_birth.actualDate);

    if (age < 18) {
      const { to, as } = routes.UnderageVerification;
      return props.router.push(to, as);
    }

    try {
      await getCoveredLife({
        variables: {
          first_name,
          last_name,
          date_of_birth: date_of_birth.actualDate,
          email: email || "",
        },
      });
      props.updateFindMyMembershipModel({
        fieldKey: "date_of_birth",
        value: date_of_birth.actualDate,
      });
    } catch (err) {
      props.addNotification(err, "error");
    }
  };

  const { i18n } = useTranslation();

  const changeLanguageFromCountry = (country) => {
    const iso2Lang = normalizeToISO2(country);
    const language = getLanguageFromCountry(iso2Lang);
    i18n.changeLanguage(language);
    props.localeCountry({ lang: language });
  };

  useEffect(() => {
    if (data && data.find_covered_life) {
      // If the covered life returned is 'claimed' we render an error message.
      // Instructing the member to login or reset their password.
      if (data.find_covered_life.claimed === true) {
        setShowErrorMessage(true);
        TRACK_EVENT.ERROR_MESSAGE_VIEWED(
          routes.Verification.as,
          "Member Exists",
          {
            spring_doc_id: "mverror001",
          },
        );
        return;
      }

      // If covered life is 'claimed' and the first name, last name and date of birth match - we show an error message.
      if (
        data.find_covered_life.claimed === true &&
        data.find_covered_life.first_name === formData.first_name &&
        data.find_covered_life.last_name === formData.last_name &&
        data.find_covered_life.date_of_birth === formData.date_of_birth
      ) {
        setShowErrorMessage(true);
        return;
      }

      // Send to manual verification if first name, last name and date of birth
      if (
        data.find_covered_life.first_name === formData.first_name &&
        data.find_covered_life.last_name === formData.last_name &&
        data.find_covered_life.date_of_birth === formData.date_of_birth
      ) {
        const { to, as } = routes.ManualVerification;
        props.router.push(to, as);
        return;
      }

      if (data.find_covered_life.country) {
        props.updateFindMyMembershipModel({
          fieldKey: "country",
          value: data.find_covered_life.country,
        });
        changeLanguageFromCountry(data.find_covered_life.country);
      }
      // If the query succeeds, check if the covered_life has dependents in their cohort, and go to SignUpOptions page
      if (
        data.find_covered_life.invite_token &&
        data.find_covered_life.cohort_id &&
        data.find_covered_life.child_dependents_allowed
      ) {
        const { invite_token, cohort_id } = data.find_covered_life;

        sessionStorage.setItem("inviteToken", invite_token);

        props.updateVerificationModel({
          fieldKey: "invite_token",
          value: invite_token,
        });
        props.updateVerificationModel({
          fieldKey: "cohort_id",
          value: cohort_id,
        });
        sessionStorage.setItem(
          "findMyMembershipModel",
          JSON.stringify(props.findMyMembershipModel),
        );
        // TODO: figure out if we need to include query params - think we do
        const { to, as } = routes.Register;
        props.router.push(
          {
            pathname: to,
            query: { invite_token, cohort_id },
          },
          as,
        );
        return;
      }

      // If the query succeeds, and if the covered_life does not have dependents in their cohort, go to CreateAccount page
      if (
        data.find_covered_life.invite_token &&
        data.find_covered_life.cohort_id
      ) {
        const { invite_token, cohort_id } = data.find_covered_life;
        const { to, as } = routes.Register;

        sessionStorage.setItem("inviteToken", invite_token);
        props.updateVerificationModel({
          fieldKey: "invite_token",
          value: invite_token,
        });
        props.updateVerificationModel({
          fieldKey: "cohort_id",
          value: cohort_id,
        });
        props.router.push(
          {
            pathname: to,
            query: { invite_token, cohort_id },
          },
          as,
        );
        return;
      }

      // If the query fails and we do *not* have an email address,
      // employees go to second email ask. Dependents go to lookup verification.
      if (!data.find_covered_life.success && !formData.email) {
        if (potentialMemberType === PotentialMemberType.Employee) {
          const { to, as } = routes.EmailVerification;
          props.router.push(to, as);
          return;
        }
      }

      // If the query fails and we *do* have an email address,
      // employees go to manual verification. Dependents go to lookup verification.
      if (!data.find_covered_life.success && formData.email) {
        if (potentialMemberType === PotentialMemberType.Employee) {
          const { to, as } = routes.ManualVerification;
          props.router.push(to, as);
          return;
        }
      }

      const { to, as } = routes.LookupVerification;
      props.router.push(to, as);
    }
  }, [data]);

  const hasSsoRequestError = () => {
    return (
      params.has("auth_message") &&
      params.get("auth_message") === AUTH_MESSAGE_CL_LOOKUP_FAILED
    );
  };

  const { t } = useTranslation("limitedLangAuth");

  const size = {
    xs: "xs",
    sm: "sm",
    md: "md",
    lg: "lg",
  };

  const searchForm = (ariaSize) => {
    return (
      <>
        <div className={styles.header}>
          <Subhead semibold center inheritColor noMargin>
            <h3>
              {potentialMemberType === PotentialMemberType.Dependent
                ? t("memberVerificationForm.subhead.dependent")
                : t("memberVerificationForm.subhead.employee")}
            </h3>
          </Subhead>

          <div className={styles.headerSubtext}>
            <Content inheritColor>
              {potentialMemberType === PotentialMemberType.Dependent
                ? t("memberVerificationForm.content.dependent")
                : t("memberVerificationForm.content.employee")}
            </Content>
          </div>

          {hasSsoRequestError() && (
            <div className={styles.headerSubtext}>
              <Content>
                <Alert status="warning" p="10" variant="left-accent">
                  <AlertIcon />
                  {t("memberVerificationForm.ssoVerificationMessage")}
                </Alert>
              </Content>
            </div>
          )}
        </div>

        <Form
          onChange={handleErrorMessageReset}
          onSubmit={(formData) => handleSubmit(formData)}
          submitText={t("memberVerificationForm.submitText")}
          disabled={loading || showErrorMessage}
          schema={memberVerificationSchema}
          formKey={"member_verification_form" + ariaSize}
          preserve
          fullSubmit
          submitIcon="arrow-right"
          flatSubmit
          dataCy="member-verification-form"
          initialData={getInitialFormData()}
        >
          <Grid gutter="0 16px">
            <Col sm={6}>
              <Input
                fieldKey="first_name"
                dataCy="member-verification-firstname"
                label={t("memberVerificationForm.firstName.label")}
                placeholder={t("memberVerificationForm.firstName.placeholder")}
                autoComplete="given-name"
                onInput={(e) => {
                  props.updateFindMyMembershipModel({
                    fieldKey: "first_name",
                    value: e.target.value,
                  });
                }}
                bolded
                autoFocus
              />
            </Col>

            <Col sm={6}>
              <Input
                fieldKey="last_name"
                dataCy="member-verification-lastname"
                label={t("memberVerificationForm.lastName.label")}
                placeholder={t("memberVerificationForm.lastName.placeholder")}
                autoComplete="family-name"
                onInput={(e) => {
                  props.updateFindMyMembershipModel({
                    fieldKey: "last_name",
                    value: e.target.value,
                  });
                }}
                bolded
              />
            </Col>

            <Col>
              <DateSelect
                fieldKey="date_of_birth"
                label={t("memberVerificationForm.dateOfBirth")}
                inputLabel={{
                  month: {
                    label: t("memberVerificationForm.dateOfSelect.month.label"),
                    placeholder: t(
                      "memberVerificationForm.dateOfSelect.month.placeholder",
                    ),
                  },
                  day: {
                    label: t("memberVerificationForm.dateOfSelect.day.label"),
                    placeholder: t(
                      "memberVerificationForm.dateOfSelect.day.placeholder",
                    ),
                  },
                  year: {
                    label: t("memberVerificationForm.dateOfSelect.year.label"),
                    placeholder: t(
                      "memberVerificationForm.dateOfSelect.year.placeholder",
                    ),
                  },
                }}
                autoComplete="bday"
                onInput={(e) => {
                  props.updateFindMyMembershipModel({
                    fieldKey: "date_of_birth",
                    value: e.target.value,
                  });
                }}
                dataCy="member-verification-dob"
                bolded
              />
            </Col>

            <Col>
              <Input
                fieldKey="email"
                label={
                  potentialMemberType === PotentialMemberType.Dependent
                    ? t("memberVerificationForm.email.label.dependent")
                    : t("memberVerificationForm.email.label.employee")
                }
                placeholder={
                  potentialMemberType === PotentialMemberType.Dependent
                    ? t("memberVerificationForm.email.placeholder.dependent")
                    : t("memberVerificationForm.email.placeholder.employee")
                }
                autoComplete="email"
                onInput={(e) => {
                  props.updateFindMyMembershipModel({
                    fieldKey: "email_address",
                    value: e.target.value,
                  });
                }}
                dataCy="member-verification-email"
                bolded
              />
            </Col>

            <Col>
              <div className={styles.info}>
                <Content>
                  {potentialMemberType === PotentialMemberType.Dependent
                    ? t("memberVerificationForm.email.content.dependent")
                    : t("memberVerificationForm.email.content.employee")}
                </Content>
              </div>

              {showErrorMessage && (
                <div role="alert" className={styles.standardErrorMessage}>
                  <p>
                    <Trans
                      ns="limitedLangAuth"
                      i18nKey="memberVerificationForm.error.doubleCheckYourInfo"
                      components={{
                        bolded: <Bolded key="doubleCheckYourInfo-bold" />,
                        loginLink: (
                          <Link
                            key="signin"
                            alias="SignIn"
                            className={styles.linkBoldErrorMessage}
                            mpTracking={{
                              page: routes.Verification.as,
                              to: routes.SignIn.to,
                              type: "Sign In",
                              location: "User Exists Error Message",
                            }}
                          />
                        ),
                        resetLink: (
                          <Link
                            key="forgotpassword"
                            alias="ForgotPassword"
                            className={styles.linkBoldErrorMessage}
                            mpTracking={{
                              page: routes.Verification.as,
                              to: routes.ForgotPassword.to,
                              type: "Forgot Password",
                              location: "User Exists Error Message",
                            }}
                          />
                        ),
                      }}
                      defaults="<bolded>The information entered may match an existing account.</bolded> Double check the information above and try <loginLink>logging in</loginLink> or <resetLink>resetting the password</resetLink>. For additional help contact support below."
                    />
                  </p>
                </div>
              )}

              <div className={styles.checkBoxContainer}>
                <Checkbox
                  fieldKey="electronic_communication_agreement"
                  dataCy="member-verification-agreement"
                  required={false}
                  label={
                    <span className={styles.info}>
                      {t("memberVerificationForm.ECAgreement.label")}{" "}
                      <Link
                        inlineTextLink
                        to={links.ElectronicCommunicationAgreement}
                        target="_blank"
                        mpTracking={{
                          page: routes.Verification.as,
                          to: routes.SignIn.as,
                          type: "Electronic Communication Agreement",
                        }}
                      >
                        {t("memberVerificationForm.ECAgreement.link")}
                      </Link>
                    </span>
                  }
                />
              </div>
            </Col>
          </Grid>
        </Form>
      </>
    );
  };

  const breakpointWrapper = () => {
    if (isAwaitingSSORelayInformation)
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingCircle />
        </div>
      );

    return (
      <>
        <Breakpoint xs>
          <Card color="GREY_100" padding="verification-sm" radius="md">
            {searchForm(size.xs)}
          </Card>
        </Breakpoint>

        <Breakpoint sm>
          <Card color="GREY_100" padding="verification-sm" radius="md">
            {searchForm(size.sm)}
          </Card>
        </Breakpoint>

        <Breakpoint md andUp>
          <Card color="GREY_100" padding="verification-lg" radius="md">
            {searchForm(size.md)}
          </Card>
        </Breakpoint>
      </>
    );
  };

  return (
    <>
      {breakpointWrapper()}
      <div className={styles.signInPrompt}>
        <p>{t("memberVerificationForm.accountExists")}</p>
        <Link
          inlineTextLink
          to={routes.SignIn.as}
          mpTracking={{
            page: routes.Verification.as,
            to: routes.SignIn.as,
            type: "Log In",
            location: "Page Bottom",
          }}
        >
          {t("memberVerificationForm.logIn")}
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  findMyMembershipModel: state.signUp.findMyMembershipModel,
  ssoRelayState: state.auth.ssoRelayState,
});

const mapDispatchToProps = {
  addNotification,
  updateFindMyMembershipModel,
  localeCountry,
  updateVerificationModel,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MemberVerificationForm);

MemberVerificationForm.propTypes = {
  addNotification: PropTypes.func,
  findMyMembershipModel: PropTypes.shape({
    country: PropTypes.string,
    date_of_birth: PropTypes.string,
    email_address: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    potential_member_type: PropTypes.string,
  }),
  localeCountry: PropTypes.func,
  router: PropTypes.object,
  updateFamilySignupModel: PropTypes.func,
  updateFindMyMembershipModel: PropTypes.func,
  updateVerificationModel: PropTypes.func,
};
