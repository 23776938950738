type WorkplaceCategories =
  | "burnout"
  | "focus"
  | "career"
  | "stress"
  | "selfEsteem"
  | "managingChange";
type WorkplaceCategoriesConfig = Record<WorkplaceCategories, string[]>;

export const MOMENTS_IDS_BY_WORKPLACE_CATEGORY: WorkplaceCategoriesConfig = {
  burnout: [
    "a0b70cd3-ed06-49a5-91f9-a96af3598b30", //Burnout 101
    "c4856785-af83-4bb8-9974-a55776ee2f9c", //Preventing Burnout Podcast
    "6c4f2c19-603e-4448-ad20-5ac8bd4678ee", //Creating Calm - Count Practice
  ],
  focus: [
    "9b801782-512d-4159-a657-6b517316b1ed", //SMART Goals
    "6c4f2c19-603e-4448-ad20-5ac8bd4678ee", //Creating Calm - Sit
    "3f285b80-8b41-4826-a052-c87061a9261f", //Grounding: 5-4-3-2-1
  ],
  career: [
    "1066e12d-e062-4d9c-bc1a-8bfdbd28f33e", //Change Can be Stressful
    "249d5285-d312-42af-8b9b-6a7fece90f4f", //Culture's Influence
    "0453ddd2-8c53-494a-8d8b-73be859c16e4", //When You Don't Feel Your Worth
  ],
  stress: [
    "4666713b-a93c-43dc-bdea-42842861652c", //Muscle Relaxation
    "32adf2fb-f955-412f-8bc9-79831d76ebf4", //Deep Breathing
    "2cf633f3-9f46-4e3e-91e0-f0aaf07e6db2", //Guided Productivity Meditation
    "46bd8b55-9f5c-4016-b695-3c43b22b441c", //Three Good Things
  ],
  selfEsteem: [
    "aad190fc-a3f3-4042-a098-30e96dd34240", //Dimensions of Your Self-Esteem
    "644378b7-cfb4-429c-8275-3ec03fa13bdd", //Understanding Self-Criticism
    "7355cf96-dc67-4ec9-8e8e-2ecefcc8514f", //Half Smile Technique
  ],
  managingChange: [
    "1066e12d-e062-4d9c-bc1a-8bfdbd28f33e", //Change Can be Stressful
  ],
};
