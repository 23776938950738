//

import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { openModal } from "@spring/smeargle/actions";
import { compose } from "redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "design-system/components";
import { Box, Text } from "@chakra-ui/react";
import { connect } from "react-redux";
import { getAppointmentSlots } from "operations/queries/appointment";
import AppointmentsTable from "./Appointment";
import { TRACK_EVENT } from "utils/mixpanel";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";

const PastAppointments = ({
  openModal,
  memberTherapyDetails,
  appointmentsData,
  appointmentsLoading,
  onScheduleModalOpen,
}) => {
  const { t } = useTranslation("careVisits");
  const { isGlobalMemberWithNoVisitsRemaining } = memberTherapyDetails;
  const { currentTab } = useContext(CareContextValues);
  const [trackedOnce, setTrackedOnce] = useState(false);

  // Splitting the request variables off to pass into handleBookAgain  as Apollo doesn't cache our initial vars
  const getLazySlotRequestVars = {
    slot_increment: 1,
    upcoming_slots_only: true,
    upcoming_slots_count: 1,
  };

  const [getLazyNextAppointmentSlot] = useLazyQuery(getAppointmentSlots, {
    variables: getLazySlotRequestVars,
  });

  useEffect(() => {
    if (!appointmentsLoading && currentTab === 0 && !trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Past Appointments",
        {
          total: appointmentsData?.length || "0",
        },
      );
      setTrackedOnce(true);
    }
  }, [appointmentsLoading, currentTab]);

  if (appointmentsLoading) {
    return <Spinner speed="1s" size="xl" />;
  }

  return (
    <>
      {!appointmentsData?.length && (
        <Box borderRadius={7} my={16} p={16} bg="background-subtle">
          <Text as="span" fontWeight={500}>
            {t("appointmentsTable.noAppointments", {
              past_or_upcoming: "past appointments",
            })}
          </Text>{" "}
        </Box>
      )}
      {appointmentsData?.length > 0 && (
        <AppointmentsTable
          openModal={openModal}
          appointmentsData={appointmentsData}
          actionType={"button"}
          isGlobalMemberWithNoVisitsRemaining={
            isGlobalMemberWithNoVisitsRemaining
          }
          getLazyNextAppointmentSlot={getLazyNextAppointmentSlot}
          onScheduleModalOpen={onScheduleModalOpen}
        />
      )}
    </>
  );
};

export default compose(connect(null, { openModal }))(PastAppointments);
