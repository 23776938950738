// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const Breakpoint = (props) => (
	<div
		className={classnames(styles.breakpoint, {
			[styles.xs]      : props.xs,
			[styles.sm]      : props.sm,
			[styles.md]      : props.md,
			[styles.lg]      : props.lg,
			[styles.xlg]     : props.xlg,
			[styles.andUp]   : props.andUp,
			[styles.andDown] : props.andDown,
		})}
	>
		{props.children}
	</div>
);

export default Breakpoint;
