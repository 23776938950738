import { useState } from "react";
import pichu from "constants/pichu";
import Pichu from "@spring/pichu";

/**
 * Hook to handle backup codes received from Diglet after a device has been registered and
 * verified, either via phone number (SMS and calls) or authentication app (QR code)
 */

// deepcode ignore HardcodedNonCryptoSecret: This is not actually a secret, but a key for storing data in sessionStorage
const BACKUP_CODE_STORAGE_KEY = "backupCodes";

export const useMfaSetup = () => {
  const [mfaDeviceId, setMfaDeviceId] = useState();
  const [otpAuthURI, setOtpAuthURI] = useState();

  const registerMFADevice = async (phoneNumber, isVoice = false) => {
    if (phoneNumber) {
      const { id } = await pichu.mfaRegisterDevice(Pichu.MFA_DEVICE_ID_SMS, {
        is_over_voice: isVoice,
        phone_number: phoneNumber,
      });

      setMfaDeviceId(id);
    } else {
      const {
        id,
        payload: { provisioning_uri },
      } = await pichu.mfaRegisterDevice(Pichu.MFA_DEVICE_ID_APP);

      setOtpAuthURI(provisioning_uri);
      setMfaDeviceId(id);
    }
  };

  const removeBackupCodesFromStorage = () =>
    sessionStorage.removeItem(BACKUP_CODE_STORAGE_KEY);

  const getBackupCodesFromStorage = () =>
    JSON.parse(sessionStorage.getItem(BACKUP_CODE_STORAGE_KEY));

  const setBackupCodesToStorage = async (formValues) => {
    const { code } = formValues;

    if (!mfaDeviceId) {
      throw new Error("Invalid MFA device ID");
    }

    const { backup_codes } = await pichu.mfaVerifyDevice(mfaDeviceId, { code });

    sessionStorage.setItem(
      BACKUP_CODE_STORAGE_KEY,
      JSON.stringify(backup_codes),
    );
  };

  return {
    otpAuthURI,
    registerMFADevice,
    setBackupCodesToStorage,
    getBackupCodesFromStorage,
    removeBackupCodesFromStorage,
  };
};

/**
 * Hook mainly used to handle rendering the MFA sign in form. It is exported for easier spying/mocking
 * in the relevant component tests.
 */
export const useMfaSignIn = () => {
  const [isMFAAttempt, setIsMFAAttempt] = useState(false);
  const [mfaLoginData, setMfaLoginData] = useState(undefined);

  const initiateMfaLogin = ({ email, password }, token, typeId) => {
    setIsMFAAttempt(true);
    setMfaLoginData({
      mfaToken: token,
      mfaType: typeId === 2 ? "phone" : "app",
      userEmail: email,
      userPassword: password,
    });
  };

  return {
    isMFAAttempt,
    mfaLoginData,
    initiateMfaLogin,
  };
};
