import { gql } from "@apollo/client";
import { postalAddress } from "operations/fragments/member";

const getMemberInfoForTeenAssessment = gql`
  query getMemberInfo($id: ID!) {
    user(id: $id) {
      id
      first_name

      member {
        id
        date_of_birth
        manager {
          id
        }
        postal_address {
          ...postalAddress
        }
      }
    }
  }
  ${postalAddress}
`;

export default getMemberInfoForTeenAssessment;
