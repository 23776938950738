import { Card } from "@springcare/sh-component-library";
import {
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import { VCoachingIcon } from "@springcare/verdant-icons-react";
import { InformationFlyout } from "components";

import { Carousel } from "components/molecules";
import Testimonial from "design-system/assets/icons/Testimonials";
import { Trans, useTranslation } from "react-i18next";

type CoachingFlyoutType = {
  callback?: () => void;
  isAlsoAvailableTile?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const NewCoachingFlyoutBody = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.newCoaching",
  });
  const [isMobile] = useMediaQuery("(max-width: 450px)");

  const CarouselHeading = () => (
    <Heading size={isMobile ? "heading-small" : "heading-medium"} mb="v-8">
      {t("testimonials.header")}
    </Heading>
  );

  const testimonials = [
    t("testimonials.item1"),
    t("testimonials.item2"),
    t("testimonials.item3"),
    t("testimonials.item4"),
  ];

  return (
    <>
      <Heading size={isMobile ? "heading-small" : "heading-medium"} mb="v-16">
        {t("introHeading")}
      </Heading>

      <Trans i18nKey={t("benefits.header")}>
        <Text mb={24}>{t("intro")}</Text>
      </Trans>

      <Heading
        size={isMobile ? "heading-small" : "heading-medium"}
        mt="v-40"
        mb="v-16"
      >
        {t("benefits.header")}
      </Heading>

      <UnorderedList mb={42} pl={12}>
        <ListItem>{t("benefits.item1")}</ListItem>
        <ListItem>{t("benefits.item2")}</ListItem>
        <ListItem>{t("benefits.item3")}</ListItem>
        <ListItem>{t("benefits.item4")}</ListItem>
        <ListItem>{t("benefits.item5")}</ListItem>
      </UnorderedList>
      <Flex
        flexDirection={"column"}
        width="100%"
        overflow={"hidden"}
        justifyContent={"center"}
        alignItems="flex-start"
      >
        <Carousel
          gap={0}
          CarouselHeading={CarouselHeading}
          sliderVariant="compact"
          itemVariant="wide"
          itemWidth="100%"
          sliderFullWidth
          isArrowSquare
        >
          {testimonials.map((testimonial) => (
            <Card
              bg={"background-subtle"}
              width="100%"
              minW={280}
              key={testimonial}
              variant="medium-emphasis"
              padding={24}
              mr={16}
              borderRadius={16}
              display="block"
            >
              <>
                <Testimonial />
                <Text marginTop={24}>{testimonial}</Text>
              </>
            </Card>
          ))}
        </Carousel>
      </Flex>
    </>
  );
};

export const CoachingFlyout = ({
  callback,
  isAlsoAvailableTile = false,
  isOpen,
  onClose,
}: CoachingFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.coaching",
  });

  const { t: newT } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.newCoaching",
  });

  const [isMobile] = useMediaQuery("(max-width: 450px)");
  return (
    <>
      <InformationFlyout
        headerText={newT("heading")}
        headerIcon={<VCoachingIcon />}
        isOpen={isOpen}
        padding={isMobile ? "24px 0" : "24px 40px"}
        onClose={onClose}
        ctaText={callback ? t("ctaTextForCN") : newT("ctaText")}
        ctaLink={callback ? "" : "/browse/coaches"}
        ctaType={callback && "button"}
        ctaCallback={callback}
      >
        <NewCoachingFlyoutBody />
      </InformationFlyout>
    </>
  );
};
