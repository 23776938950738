import { Flex, Text } from "@chakra-ui/react";

const ParticipantNameTag = ({ isSmall, text }) => (
  <Flex
    bg="background-media-overlay"
    borderRadius={isSmall ? "v-sm" : "v-lg"}
    px={"v-8"}
    py={isSmall ? "v-2" : "v-4"}
  >
    <Text
      size={isSmall ? "body-small-regular" : "body-medium-regular"}
      color="white"
    >
      {text}
    </Text>
  </Flex>
);

export default ParticipantNameTag;
