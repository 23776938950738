import { Alert } from "@springcare/sh-component-library";
import { Section } from "@spring/smeargle";

export const ComingSoonBanner = () => {
  return (
    <Section size="sm">
      <Alert
        colorScheme="positive"
        content="You can begin scheduling with this Team Member Care Advocate starting 4/7/24"
        showIcon="on"
        showTitle="on"
        size="medium"
        title="Coming April 7th"
        type="standard"
      />
    </Section>
  );
};

export default ComingSoonBanner;
