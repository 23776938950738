//

import { gql } from "@apollo/client";

const getMemberAddress = gql`
  query getMemberAddress($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        full_address_available
        postal_address {
          street_address_1
          street_address_2
          city
          state
          zip_code
          country
          country_display_name
        }
      }
    }
  }
`;

export default getMemberAddress;
