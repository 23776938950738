import React, { useContext } from "react";
import {
  PastAppointments,
  UpcomingAppointments,
  CareTeamList,
  CareProviderDetailModal,
  CareProviderScheduleModal,
} from "components";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import { useTranslation } from "react-i18next";
import { Heading, Box, Spacer } from "@chakra-ui/react";
import { ActiveCareNoProvidersCard } from "components/molecules/ActiveCareCard";
import { useScheduleModalWithProps } from "shared/hooks";
import { CareProviderScheduleModalV2 } from "components/modals";
import { SentimentRatingBanner } from "components/templates/CareVisitsPage/components/SentimentRatingBanner";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isCNScheduling } from "utils/global";

export const YourCareTab = () => {
  const { t } = useTranslation("careVisits");
  const quickSentimentLDFlag = useFeatureFlag(
    FLAGS.SENTIMENT_RATING_ENTRY_POINTS,
  );

  const {
    upcomingAppointmentsData,
    pastAppointmentsData,
    isAMinor,
    isMemberUnderSix,
    memberCoachingDetails,
    memberTherapyDetails,
    appointmentsLoading,
    careTeamInfo,
    memberInfo,
    isPrivatePractice,
    setCareTeamList,
  } = useContext(CareContextValues);

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const careTeam = careTeamInfo?.user?.member?.care_team;
  const hasCareTeam =
    (careTeam?.care_navigator && !isPrivatePractice) || // CNs are not available for private practice members if there is one, ignore it
    careTeam?.medication_manager ||
    careTeam?.therapist ||
    careTeam?.coach;

  const lastUnratedAppointment =
    memberInfo?.user?.member?.first_past_unrated_appointment;

  const isCnScheduling = isCNScheduling(
    memberInfo?.user?.member?.cohort?.contract_term?.scheduling_access?.name,
  );
  return (
    <>
      {quickSentimentLDFlag && lastUnratedAppointment && (
        <Box mb={32}>
          <SentimentRatingBanner appointment={lastUnratedAppointment} />
        </Box>
      )}
      {!isCnScheduling && (
        <Box mb={64} data-cy="upcoming-appointments-section">
          <Heading fontSize={20} fontWeight={600} my={16}>
            {t("upcomingAppointmentsTitle")}
          </Heading>
          <UpcomingAppointments
            memberTherapyDetails={memberTherapyDetails}
            appointmentsData={upcomingAppointmentsData}
            appointmentsLoading={appointmentsLoading}
            isAMinor={isAMinor}
          />
        </Box>
      )}

      {!isCnScheduling && (
        <Box mb={64} data-cy="past-appointments-section">
          <Heading fontSize={20} fontWeight={600} my={16}>
            {t("pastAppointmentsTitle")}
          </Heading>
          <PastAppointments
            memberTherapyDetails={memberTherapyDetails}
            appointmentsData={pastAppointmentsData}
            appointmentsLoading={appointmentsLoading}
            onScheduleModalOpen={onScheduleModalOpen}
          />
        </Box>
      )}

      <Box mb={64} data-cy="your-care-providers-section">
        <Heading fontSize={20} fontWeight={600} my={16}>
          {t("yourProviders")}
        </Heading>

        {hasCareTeam && (
          <CareTeamList
            sendCareTeamListCb={(list) => setCareTeamList(list)}
            isAMinor={isAMinor}
            memberTherapyDetails={memberTherapyDetails}
            isMemberUnderSix={isMemberUnderSix}
            memberCoachingDetails={memberCoachingDetails}
            onScheduleModalOpen={onScheduleModalOpen}
          />
        )}
        {!hasCareTeam && (
          <>
            <Spacer mb={32} />
            <ActiveCareNoProvidersCard />
          </>
        )}
      </Box>

      <CareProviderDetailModal />
      <CareProviderScheduleModal />
      <CareProviderScheduleModalV2
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
        {...scheduleModalProps}
      />
    </>
  );
};
