//
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Form, Input } from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import { useTranslation } from "react-i18next";

import { resendConfirmationEmail } from "actions/auth";
import forgotPasswordSchema from "schemas/forgotPassword";

const handleSubmit = async (data, addNotification) => {
  try {
    await resendConfirmationEmail(data);
  } catch (error) {
    addNotification(error, "error");
  }
};

const ResendConfirmationForm = (props) => {
  const { t } = useTranslation("limitedLangAuth");
  return (
    <Form
      theme="simple"
      formKey="forgot_password"
      submitText={t("common:form.submitText")}
      schema={forgotPasswordSchema}
      onSubmit={(data) => handleSubmit(data, props.addNotification)}
    >
      <Input label={t("resendConfirmation.label")} fieldKey="email" />
    </Form>
  );
};

ResendConfirmationForm.propTypes = {
  addNotification: PropTypes.any,
};

export default connect(null, { addNotification })(ResendConfirmationForm);
