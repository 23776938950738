import { links } from "@spring/constants";

export const WITHIN_AGING_OUT_VALUES = {
  seventeen_to_eighteen: "seventeen_to_eighteen",
  twelve_to_thirteen: "twelve_to_thirteen",
  five_to_six: "five_to_six",
};

export const ACCOUNT_RECENTLY_TRANSITIONED_VALUES = {
  recently_turned_thirteen: "recently_turned_thirteen",
  recently_turned_six: "recently_turned_six",
};

export const FAQ_LINK_FOR_AGING_OUT_RANGE = {
  seventeen_to_eighteen: links.AccountTransitionFAQlink17To18,
  twelve_to_thirteen: links.AccountTransitionFAQlink12To13,
  five_to_six: links.AccountTransitionFAQlink5To6,
  recently_turned_six: links.AccountTransitionFAQlink5To6,
  recently_turned_thirteen: links.AccountTransitionFAQlink12To13,
};
