const GlobalOneSupportedLanguages = [
  {
    nativeName: "Eesti",
    englishName: "Estonian",
    code: "et",
  },
  {
    nativeName: "Hrvatski",
    englishName: "Croatian",
    code: "hr",
  },
  {
    nativeName: "Slovenščina",
    englishName: "Slovenian",
    code: "sl",
  },
  {
    nativeName: "Slovenčina",
    englishName: "Slovak",
    code: "sk",
  },
  {
    nativeName: "Latviešu",
    englishName: "Latvian",
    code: "lv",
  },
  {
    nativeName: "සිංහල",
    englishName: "Sinhala",
    code: "si",
  },
  {
    nativeName: "Українська",
    englishName: "Ukrainian",
    code: "uk",
  },
  {
    nativeName: "Қазақ",
    englishName: "Kazakh",
    code: "kk",
  },
  {
    nativeName: "اردو",
    englishName: "Urdu",
    code: "ur",
  },
  {
    nativeName: "Հայերեն",
    englishName: "Armenian",
    code: "hy",
  },
  {
    nativeName: "繁體中文 (台灣)",
    englishName: "Traditional Chinese (Taiwan)",
    code: "zh-TW",
  },
  {
    nativeName: "繁體中文 (香港)",
    englishName: "Traditional Chinese (Hong Kong)",
    code: "zh-HK",
  },
  {
    nativeName: "Македонски",
    englishName: "Macedonian",
    code: "mk",
  },
  {
    nativeName: "Íslenska",
    englishName: "Icelandic",
    code: "is",
  },
  {
    nativeName: "Беларуская",
    englishName: "Belarusian",
    code: "be",
  },
  {
    nativeName: "ไทย",
    englishName: "Thai",
    code: "th",
  },
  {
    nativeName: "বাংলা",
    englishName: "Bangladeshi",
    code: "bn",
  },
  {
    nativeName: "Tiếng Việt",
    englishName: "Vietnamese",
    code: "vi",
  },
];

module.exports = GlobalOneSupportedLanguages;
