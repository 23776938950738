import { Badge, Link } from "@springcare/sh-component-library";
import { Box, Text, Spacer } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TRACK_EVENT } from "utils/mixpanel";

export const ImmediateSupport = () => {
  const { t } = useTranslation("scheduling", { keyPrefix: "bookingReview" });

  return (
    <>
      <Box
        width="100%"
        borderRadius={12}
        p={24}
        backgroundColor="background-subtle"
      >
        <Badge
          backgroundColor="accent-base"
          color="accent-on-base"
          fontSize={16}
        >
          {t("immediateSupportHeading")}
        </Badge>
        <Text my={12}>{t("immediateSupport")}</Text>
        <Link
          href="tel:+18553055533"
          colorScheme="neutral"
          size="v-sm"
          onClick={() => {
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Immediate support number clicked",
            );
          }}
        >
          {t("supportNumber")}
        </Link>
      </Box>
      <Spacer />
    </>
  );
};
