// 

import React from 'react';
import classnames from 'classnames';

import { LoadingCircle } from '../../atoms';

import styles from './styles.module.scss';


const LoadingOverlay = (props) => (
	<div
		className={classnames(
			styles.loading,
			{
				[styles.out] : props.out,
				[styles.in]  : props.in,
			}
		)}
	>
		<div className={styles.wrapper}>
			<LoadingCircle />
		</div>
	</div>
);

LoadingOverlay.defaultProps = {
	out: true,
};

export default LoadingOverlay;
