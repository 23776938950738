import { useMutation } from "@apollo/client";
/* eslint react/prop-types: 0 */
import React, { useState } from "react";
import { Section, Textarea, Form } from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";

import { requestCareNavigatorAssistance } from "operations/mutations/careProvider";
import { getFirstError } from "utils/apollo/errorHandler";
import { Heading } from "design-system/components";

const reason = "DELETE_USER_ACCOUNT";

const SubmitStatus = Object.freeze({
  NotSubmitted: "NOT_SUBMITTED",
  Submitted: "SUBMITTED",
});

const isSubmitted = (status) => status === SubmitStatus.Submitted;

const DeleteAccount = () => {
  const { t } = useTranslation("limitedLangSettings");
  const [userReason, setUserReason] = useState("");
  const [submitStatus, setSubmitStatus] = useState<string>(
    SubmitStatus.NotSubmitted,
  );
  const dispatch = useDispatch();
  const [useRequestCareNavigatorAssistance, { loading }] = useMutation(
    requestCareNavigatorAssistance,
    {
      variables: { reason, userReason },
      onError: (err) => dispatch(addNotification(getFirstError(err), "error")),
      onCompleted: () => {
        dispatch(addNotification(t("notifications.accountDeleted"), "success"));
        setSubmitStatus(SubmitStatus.Submitted);
      },
    },
  );

  return (
    <Section size="xlg">
      <Heading fontSize={20} fontWeight={"bold"} color="#4b4b4b">
        {t("deleteAccount.title")}
      </Heading>
      <div className={styles.spacer}></div>
      <label className={styles.deleteContent}>
        {t("deleteAccount.subTitle")}
      </label>
      <Form
        dataCy="deleteAccount"
        formKey="deleteAccount"
        onChange={(formData) => setUserReason(formData.deleteArea)}
        submitText={t("common:form.submitText")}
        submitAriaLabel={t("a11y:submit.deleteAccountReq")}
        onSubmit={useRequestCareNavigatorAssistance}
        loading={loading}
        disabled={isSubmitted(submitStatus) || loading}
      >
        <Textarea
          dataCy="deleteAccount"
          fieldKey="deleteArea"
          placeholder={t("deleteAccount.placeholder")}
        />
      </Form>
    </Section>
  );
};

export default DeleteAccount;
