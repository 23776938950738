const minorTags = {
	child: {
		label: 'Children (6-12 years old)',
	},
	adolescent: {
		label: 'Adolescents (13-17 years old)',
	},
};

module.exports = minorTags;
