import { Box, Flex, Text } from "@chakra-ui/react";
import styles from "./styles.module.scss";
import { footerLinks } from "components/organisms/MemberHelpCard/footerLinks";
import { useTranslation } from "hooks/react-i18next";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "components/atoms";
import { LINK_TYPE } from "utils/mixpanel";
import { useRouter } from "next/router";

const TermsAndPolicyFooter = ({ noBackground }) => {
  const { t } = useTranslation("limitedLangAuth");
  const router = useRouter();

  return (
    <footer
      className={classnames(styles.pageFooter, {
        [styles.noBackground]: noBackground,
        [styles.whiteBackground]: !noBackground,
      })}
    >
      <div className={styles.termsList}>
        <div className={styles.term}>
          {t("forms.dependentSignupForm.all_right_reserved")}
        </div>
        <Link
          className={classnames(styles.termsLink, styles.term)}
          target="_blank"
          rel="noopener noreferrer"
          inlineTextLink
          to={footerLinks.privacyPolicy}
          mpTracking={{
            page: router?.asPath,
            to: footerLinks.privacyPolicy,
            type: LINK_TYPE.PP,
            location: "Footer",
          }}
        >
          {t("forms.dependentSignupForm.privacyPolicy")}
        </Link>
        <Link
          className={classnames(styles.termsLink, styles.term)}
          target="_blank"
          rel="noopener noreferrer"
          inlineTextLink
          to={footerLinks.tos}
          mpTracking={{
            page: router?.asPath,
            to: footerLinks.privacyPolicy,
            type: LINK_TYPE.TOS,
            location: "Footer",
          }}
        >
          {t("forms.dependentSignupForm.tos")}
        </Link>
      </div>
    </footer>
  );
};

const ResponsiveTermsAndPolicyFooter = ({ noBackground }) => {
  const { t } = useTranslation("limitedLangAuth");

  return (
    <Box
      as="footer"
      position="absolute"
      bottom={0}
      zIndex={998}
      backgroundColor={noBackground ? "none" : "white"}
      w={["90%", "80%", "49%"]}
      mb={32}
    >
      <Box>
        <Flex flexDirection={["column", "column", "column", "row-reverse"]}>
          <Box w={[null, null, null, "50%"]}>
            <Flex flexDirection="row" justifyContent={["space-around"]}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={footerLinks.privacyPolicy}
              >
                <Text color="primary-base" size="body-medium-strong">
                  {t("forms.dependentSignupForm.privacyPolicy")}
                </Text>
              </Link>
              <span className={styles.interpunct}></span>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={footerLinks.tos}
              >
                <Text color="primary-base" size="body-medium-strong">
                  {t("forms.dependentSignupForm.tos")}
                </Text>
              </Link>
            </Flex>
          </Box>
          <Box w={[null, null, null, "50%"]} mt={[16, 16, 16, 0]}>
            <Flex justifyContent={["center", "center"]}>
              <Text color="content-primary" size="body-medium">
                {t("forms.dependentSignupForm.all_right_reserved")}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

TermsAndPolicyFooter.propTypes = {
  noBackground: PropTypes.bool,
};

export default TermsAndPolicyFooter;
export { ResponsiveTermsAndPolicyFooter };
