import { TRACK_EVENT } from "utils/mixpanel";
import { getTextBetweenAsterisks } from "components/templates/TeenAssessment/utils";
import Router from "next/router";
import { getAssessmentKindForTracking } from "@spring/constants";

export const handleTelemetryForQuestions = ({
  teenAssessmentData,
  type = null,
  prompt,
}) => {
  const assessmentId = teenAssessmentData?.id;
  const assessmentKind = getAssessmentKindForTracking(teenAssessmentData?.kind);
  const questionnaireKind =
    teenAssessmentData?.current_questionnaire?.questionnaire_kind?.label;
  const questionNumber = prompt?.questions[0]?.key;
  const questionnairesId = teenAssessmentData?.current_questionnaire?.id;
  const assessmentVersion = teenAssessmentData?.version_number;

  const pageVersion = getPageVersionForTelemetry({
    questionnaireKind,
    prompt,
  });
  // we use the calculated value for page_version_viewed and don't pass in a type
  const isPageViewEvent = !type;

  if (isPageViewEvent) {
    TRACK_EVENT.PAGE_VERSION_VIEWED(Router.asPath, `${pageVersion} question`, {
      page_version: pageVersion,
    });
  } else {
    TRACK_EVENT.ASSESSMENT_PROMPT_ANSWERED(
      Router.asPath,
      `${questionnaireKind} - ${questionNumber}`,

      {
        assessment_kind: assessmentKind,
        questionnaire_kind: questionnaireKind,
        questions: questionNumber,
        assessment_id: assessmentId,
        prompt_id: prompt.id,
        questionnaires_id: questionnairesId,
        assessment_version: assessmentVersion,
        page_version: pageVersion,
      },
    );
  }
};

const getPageVersionForTelemetry = ({ questionnaireKind, prompt }) => {
  let pageVersion = "";
  switch (questionnaireKind) {
    case "GAD2":
    case "GAD7":
      // get unique text from what is bolded
      pageVersion = getTextBetweenAsterisks(prompt.newSubtitle);
      break;
    case "PHQA":
      pageVersion = prompt.questions[0].newTitle;
      break;
    case "SIB":
      pageVersion = prompt.questions[0].key;
      break;
    default:
      break;
  }

  return pageVersion;
};
