import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FlexRow, CheckboxGroup, Icon } from "@spring/smeargle";
import { browseProviders } from "@spring/constants";
import { clickableDivProps } from "lib/accessibility_helpers";
import classnames from "classnames";

import styles from "./styles.module.scss";

const FilterAccordion = (props) => {
  const ref = useRef();
  const { t } = useTranslation("careProvider");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.open) setIsActive(true);
    else setIsActive(false);
  }, [props.open, props.timestamp]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  });

  const handleClick = (e) => {
    if (
      e.target.getBoundingClientRect().y === 0 &&
      e.target.getBoundingClientRect().right === window.innerWidth
    ) {
      return;
    }
    if (
      !ref.current ||
      ref.current.contains(e.target) ||
      e.target.innerHTML === t("providerFilterFlyout.title") ||
      e.target.innerHTML === t("providerFilterFlyout.updateButton")
    ) {
      return;
    }
    setIsActive(false);
  };

  const setFilterCount = () => {
    let filterCountText = `${props.selectedCount} filter`;

    if (props.selectedCount > 1) {
      filterCountText = `${props.selectedCount} filters`;
    }
    return <span className={styles.filterCountText}>{filterCountText}</span>;
  };

  return (
    <div className={styles.accordianContainer} ref={ref}>
      <fieldset aria-labelledby={`filter-accordian-${props.fieldKey}`}>
        <div
          onClick={() => setIsActive(!isActive)}
          {...clickableDivProps({
            expanded: isActive,
            onClick: () => setIsActive(!isActive),
          })}
          data-cy={props.dataCy}
        >
          <FlexRow justification="space-between" alignment="center">
            <legend
              className={styles.conditionHeader}
              id={`filter-accordian-${props.fieldKey}`}
            >
              {props.title} {props.selectedCount > 0 && setFilterCount()}
            </legend>
            <div className={styles.icon}>
              <Icon type={isActive ? "minus" : "plus"} />
            </div>
          </FlexRow>
        </div>

        <div
          className={classnames(styles.accordionContent, {
            [styles.isOpen]: isActive,
          })}
        >
          {props.data && (
            <CheckboxGroup
              formKey={browseProviders.formKeyTemp}
              fieldKey={props.fieldKey}
              options={props.data}
              columnCount={props.data.length}
              column
              useRedesign
              hoverHighlight
              dataCy={props.dataCy}
            />
          )}
        </div>
      </fieldset>
    </div>
  );
};

FilterAccordion.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.array,
  fieldKey: PropTypes.string,
  selectedCount: PropTypes.number,
  timestamp: PropTypes.string,
};

export { FilterAccordion };
export default FilterAccordion;
