import { gql } from "@apollo/client";

// See Member Payment Collection Tech Doc for spec
// https://springhealth.atlassian.net/wiki/spaces/RE/pages/1018429549/Member+Payment+Collections+-+Milestone+1+Technical+Document.#%F0%9F%92%B0-Billing-Tabs%3A
export const getMemberAppointmentInvoices = gql`
  query getMemberAppointmentInvoices(
    $member_id: ID!
    $status: StatusEnumType!
    $page: String
  ) {
    member_appointment_invoices(
      member_id: $member_id
      status: $status
      page: $page
    ) {
      next_page
      previous_page
      appointment_invoices {
        appointment_id
        care_provider_avatar
        care_provider
        appointment_kind
        appointment_date
        attended_status
        invoice_date
        invoice_id
        invoice_status
        amount_charged
        amount_paid
        balance
      }
    }
  }
`;
