import { Heading, Text } from "@chakra-ui/react";
import { compact } from "lodash";
import { useTranslation } from "react-i18next";
import { useRef, useEffect } from "react";

type NicelyDoneTextProps = {
  firstName: string;
};

const capitalize = (s: string | null): string | null => {
  if (!s) return null;

  return s.charAt(0).toUpperCase() + s.slice(1);
};

const NicelyDoneText = ({ firstName }: NicelyDoneTextProps) => {
  const { t } = useTranslation("assessments");
  const headingRef = useRef(null);
  const subheading = compact([
    capitalize(firstName),
    t("submitAssessment.complete.newSubtitle"),
  ]).join(", ");

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.focus();
    }
  }, []);

  return (
    <>
      <Heading
        as="h1"
        size="heading-medium"
        mt="1.5px"
        tabIndex={0}
        ref={headingRef}
      >
        {t("submitAssessment.complete.title")}
      </Heading>

      <Text size="body-medium-regular" mt="v-4">
        {subheading}
      </Text>
    </>
  );
};

export default NicelyDoneText;
