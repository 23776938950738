import { useEffect } from "react";
import {
  Flex,
  MenuItem,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  Text,
} from "@chakra-ui/react";
import {
  VMicOffIcon,
  VMicOnIcon,
  VVolumeHighIcon,
} from "@springcare/verdant-icons-react";
import { links } from "@spring/constants";
import { useSessionDevices } from "context/SessionRoomContext";
import useMicVolumeLevel from "components/templates/SHSessionRoom/hooks/useMicVolumeLevel";
import { useTranslation } from "hooks/react-i18next";

export const AudioOutputTesterMenuItem = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "audioTesterMenuItem",
  });
  const { sessionDevices } = useSessionDevices();
  const { selectedAudioOutputDevice } = sessionDevices;

  const playSound = async () => {
    const audio = new Audio(links.SpeakerTestURL);
    // @ts-ignore: Suppress TypeScript error for setSinkId
    if (audio.setSinkId && selectedAudioOutputDevice?.deviceId) {
      // @ts-ignore: Suppress TypeScript error for setSinkId
      await audio.setSinkId(selectedAudioOutputDevice.deviceId);
    }
    audio.play();
  };

  return (
    <MenuItem
      onClick={playSound}
      _focus={{ outline: "0px solid transparent !important" }}
      _focusVisible={{ outline: "2px solid black !important" }}
    >
      <Flex align="center" gap="v-8">
        <VVolumeHighIcon aria-hidden={true} />
        <Text>{t("testYourSpeakers")}</Text>
      </Flex>
    </MenuItem>
  );
};

export const AudioInputTesterMenuItem = () => {
  const { sessionDevices } = useSessionDevices();
  const [stopMicRecording, volume] = useMicVolumeLevel();

  useEffect(() => {
    return () => {
      stopMicRecording();
    };
  }, []);

  return (
    <MenuItem
      aria-label="Audio Input Level"
      pointerEvents="none"
      _focus={{ outline: "0px solid transparent !important" }}
      _focusVisible={{ outline: "2px solid black !important" }}
    >
      <Flex align="center" gap="v-8" w="100%">
        {!sessionDevices.isMicrophoneEnabled && (
          <VMicOffIcon aria-hidden={true} />
        )}
        {sessionDevices.isMicrophoneEnabled && (
          <VMicOnIcon aria-hidden={true} />
        )}
        <Slider
          colorScheme="whiteAlpha"
          defaultValue={10}
          value={volume}
          min={0}
          max={20}
          width="100%"
          me="v-16"
        >
          <SliderTrack bg="var(--Background-Media-overlay, rgba(0, 0, 0, 0.032))">
            <SliderFilledTrack bg="#8DFF9D" />
          </SliderTrack>
        </Slider>
      </Flex>
    </MenuItem>
  );
};
