import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useVideoPlayer } from "modules/shared/components/VideoPlayer/VideoPlayer/context/videoPlayerContext";

interface CaptionProps {
  captionUrl: string | null | undefined;
}

interface SubtitleProps {
  id: number;
  startTime: number;
  endTime: number;
  text: string;
}

const timeToSeconds = (time: string, fileType: "vtt" | "srt"): number => {
  const [hours, minutes, seconds] = time.split(":");
  const [sec, milli] =
    fileType === "srt" ? seconds.split(",") : seconds.split(".");
  return (
    Number(hours) * 3600 +
    Number(minutes) * 60 +
    Number(sec) +
    Number(milli) / 1000
  );
};

const parseCaptionFile = (
  data: string,
  fileType: "srt" | "vtt",
): SubtitleProps[] => {
  if (fileType === "vtt") {
    data = data.replace(/WEBVTT[\s\S]*?\n\n/, "");
  }

  //the regex is used to capture start time --> end time, and the proceeding subtitle text
  const regex =
    fileType === "vtt"
      ? /(\d{2}:\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}:\d{2}\.\d{3})\n([\s\S]*?)(?=\n\n|\n*$)/g
      : /(\d+)\r?\n(\d{2}:\d{2}:\d{2},\d{3}) --> (\d{2}:\d{2}:\d{2},\d{3})\r?\n([\s\S]*?)(?=\r?\n\r?\n|$)/g;

  const subtitles: SubtitleProps[] = [];
  let match;

  //the result of regex.exec is save to match variable and then used to create subtitle objects
  while ((match = regex.exec(data)) !== null) {
    const startTime = timeToSeconds(
      match[fileType === "vtt" ? 1 : 2],
      fileType,
    );
    const endTime = timeToSeconds(match[fileType === "vtt" ? 2 : 3], fileType);
    const text = match[fileType === "vtt" ? 3 : 4].trim();
    const id =
      fileType === "vtt" ? subtitles.length + 1 : parseInt(match[1], 10);
    subtitles.push({ id, startTime, endTime, text });
  }

  return subtitles;
};

export const PlayerCaption: React.FC<React.PropsWithChildren<CaptionProps>> = ({
  captionUrl,
}) => {
  const { isCaptionOn, currentTime } = useVideoPlayer();
  const [subtitles, setSubtitles] = useState<SubtitleProps[]>([]);

  useEffect(() => {
    if (captionUrl) {
      const parts = captionUrl.split(".");
      const fileType =
        parts.length > 0 ? parts[parts.length - 1].toLowerCase() : null;

      if (fileType !== "vtt" && fileType !== "srt") {
        console.error("Invalid filetype");
        return;
      }

      // Check if captionUrl is a valid URL
      try {
        new URL(captionUrl);
      } catch (_) {
        console.error("Invalid URL:", captionUrl);
        return;
      }

      fetch(captionUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((data) => {
          const parsedSubtitles = parseCaptionFile(data, fileType);
          setSubtitles(parsedSubtitles);
        })
        .catch((error) => console.error("Error fetching subtitles:", error));
    }
  }, [captionUrl]);
  const currentSubtitle =
    subtitles.find(
      (sub) => sub.startTime <= currentTime && sub.endTime >= currentTime,
    )?.text || "";

  const subtitleLines = currentSubtitle
    .split("\n")
    .filter((line) => line.trim());

  return isCaptionOn ? (
    <Box
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      maxWidth="90%"
      bottom="v-16"
      textAlign="center"
    >
      {subtitleLines.map((line, index) => (
        <Box
          key={index}
          background="#0000008F"
          color="white"
          borderRadius="8px"
          padding="v-4"
          marginBottom="v-4"
          backdropFilter="blur(12px)"
        >
          <Text fontSize="v-sm">{line}</Text>
        </Box>
      ))}
    </Box>
  ) : null;
};

PlayerCaption.displayName = "PlayerCaption";
