import { FilterChipDropdown } from "./FilterChipDropdown";
import { FilterChip } from "@springcare/sh-component-library";
import { Box, Flex } from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useInViewport } from "hooks";
import { useTranslation } from "react-i18next";
import { TRACK_EVENT } from "utils/mixpanel";

const MotionFlex = motion(Flex);

export const GoalsList = ({ trackedGoals, selectedGoal, setSelectedGoal }) => {
  const { i18n } = useTranslation();
  const goals_description_list = trackedGoals?.map((data) => data?.goal);
  // framer-motion dragConstraint has a bug
  // that allows items to get dragged out of the viewport.
  // These refs are a workaround to ensure items snap
  // back onto the screen if all are out of the viewport.
  const [notInViewPort, setNotInViewPort] = useState(false);
  const parentRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const isInViewportref1 = useInViewport(ref1);
  const isInViewportref2 = useInViewport(ref2);
  const isInViewportref3 = useInViewport(ref3);
  const refArray = [ref1, ref2, ref3];
  const buttonsWidth =
    ref1?.current?.clientWidth +
    ref2?.current?.clientWidth +
    ref3?.current?.clientWidth;

  const filterChipToggleState = {
    _hover: {
      background: "accent-base",
    },
    _focus: {
      background: "accent-base",
    },
  };

  useEffect(() => {
    if (!isInViewportref1 && !isInViewportref2 && !isInViewportref3) {
      setNotInViewPort(true);
    } else {
      setNotInViewPort(false);
    }
  }, [isInViewportref1, isInViewportref2, isInViewportref3]);

  const langMargin = () =>
    i18n.dir() == "rtl"
      ? {
          left: 0,
          right:
            ref1?.current?.clientWidth +
            ref2?.current?.clientWidth +
            (ref3?.current?.clientWidth - parentRef?.current?.clientWidth + 20),
        }
      : {
          left:
            -ref1?.current?.clientWidth -
            ref2?.current?.clientWidth -
            (ref3?.current?.clientWidth - parentRef?.current?.clientWidth + 20),
          right: 0,
        };

  if (goals_description_list?.length > 3) {
    return (
      <FilterChipDropdown
        selectedItem={goals_description_list[selectedGoal]}
        updateGoal={setSelectedGoal}
        list={goals_description_list}
      />
    );
  } else if (goals_description_list?.length > 0) {
    return (
      <Box ref={parentRef}>
        <MotionFlex
          drag={buttonsWidth > parentRef?.current?.clientWidth ? "x" : false}
          dragMomentum={true}
          dragSnapToOrigin={notInViewPort}
          dragElastic={0.5}
          maxWidth="max-content"
          dragConstraints={langMargin()}
        >
          <Flex>
            {goals_description_list.map((goal, index) => (
              <FilterChip
                ref={refArray[index]}
                key={index}
                onClick={() => {
                  setSelectedGoal(index);
                  TRACK_EVENT.BUTTON_CLICKED(
                    window.location.pathname,
                    "Progress Goal",
                    { to: goal?.description },
                  );
                }}
                selected={selectedGoal === index}
                variant="medium-emphasis"
                {...(selectedGoal === index && filterChipToggleState)}
                marginRight="8px"
              >
                {goal?.description}
              </FilterChip>
            ))}
          </Flex>
        </MotionFlex>
      </Box>
    );
  } else {
    return null;
  }
};
