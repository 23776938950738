import { AppointmentKind } from "@spring/constants";

export const hasHadMedManagerAppt = (provider, pastAppointments) => {
  return pastAppointments
    ? !!pastAppointments.find(
        (appointment) =>
          appointment.kind === AppointmentKind.InitialMedicationManagement &&
          appointment.provider_id == provider.id &&
          appointment.status == "Booked" &&
          (!appointment.attended ||
            appointment.attended?.toLowerCase() === "kept"),
      )
    : false;
};
