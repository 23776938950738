import { useEffect, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";

/*
 * Fixes a known NextJS issue where SSR and CSR values mismatch, causing a console warning
 * https://github.com/chakra-ui/chakra-ui/issues/4319#issuecomment-1435751805
 */
const useClientSideRenderValue = (value, ssrValue) => {
  // Needed to prevent a mismatch between the first render on the server and the client
  const [mountedValue, setMountedValue] = useState(ssrValue);
  useEffect(() => setMountedValue(value), [value]);

  return mountedValue;
};

/*
 * If we update to `@chakra-ui/media-query@3.1.0`, we could just use
 * ```
 * // return false on the server, and re-evaluate on the client side
 * useMediaQuery('foobar', { ssr: true, fallback: false });
 * ```
 * https://chakra-ui.com/docs/hooks/use-media-query#api-reference
 * https://github.com/chakra-ui/chakra-ui/pull/6125
 */
export const useSSRSafeMediaQuery = (query, ssrValue = false) => {
  const [value] = useMediaQuery(query);

  return [useClientSideRenderValue(value, ssrValue)];
};
