import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { useMfaSetup } from "hooks";
import { useTranslation } from "react-i18next";
import { Card, Reader } from "@spring/smeargle";
import { Download } from "design-system/assets";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from "design-system/components";
import { addNotification } from "@spring/smeargle/actions";
import routes from "routes";

const MfaBackupCodesPage = (props) => {
  const [backupCodes, setBackupCodes] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [userConfirmedCodes, setUserConfirmedCodes] = useState(false);

  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });
  const router = useRouter();

  const { removeBackupCodesFromStorage, getBackupCodesFromStorage } =
    useMfaSetup();

  // Display alternate text when codes are in clipboard
  useEffect(() => {
    let timer;

    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isCopied]);

  useEffect(() => {
    if (!backupCodes) {
      try {
        const codes = getBackupCodesFromStorage();

        setBackupCodes(codes);
      } catch (_err) {
        props.addNotification(t("backupCodesErrorReading"), "error");
      }
    }
  }, [backupCodes]);

  const handleOnClickButton = () => {
    removeBackupCodesFromStorage();
    router.replace(routes.MemberSettings.to, routes.MemberSettings.as);
  };

  const handleOnClickCopy = async () => {
    if (typeof navigator.clipboard?.writeText === "function") {
      await navigator.clipboard.writeText(backupCodes);
      setIsCopied(true);
      handleUserConfirmationOfCodes();
    } else {
      props.addNotification(t("backupCodesErrorCopying"), "error");
    }
  };

  /**
   * Ideally, we would want to use an anchor tag's href and download attribute to natively allow a
   * user to download on click. However, the designs call for a visually interactive button.
   * Because a11y specifications disallow nested interactive elements, i.e. button > link, we
   * instead use the HTMLElement interface to reproduce the effect of clicking and downloading.
   */
  const handleOnClickDownload = () => {
    if (backupCodes.length > 0) {
      const backupBlob = new Blob([backupCodes.join("\n")], {
        type: "text/plain",
      });
      const backupHref = URL.createObjectURL(backupBlob);
      const tempLink = document.createElement("a");

      tempLink.style.display = "none";
      tempLink.href = backupHref;
      tempLink.setAttribute("download", "backup_codes.txt");
      document.body.appendChild(tempLink);
      tempLink.click();
      tempLink.remove();

      handleUserConfirmationOfCodes();
    } else {
      props.addNotification(t("backupCodesErrorDownloading"), "success");
    }
  };

  const handleUserConfirmationOfCodes = () => {
    setUserConfirmedCodes(true);
    props.addNotification(t("backupCodesSucessFinishedSetup"), "success");
  };

  const renderBackupCodes = (codes) => {
    return codes.map((code) => (
      <GridItem key={code} justifySelf="center">
        <Text variant="subtitle1" color="platform.base">
          {code}
        </Text>
      </GridItem>
    ));
  };

  return (
    <div role="main">
      <Reader>
        <Flex flexDirection="column" alignItems="left">
          <Heading as="h1" variant="lg" color="primary.700">
            {t("backupCodesTitle")}
          </Heading>
          <Text color="platform">{t("backupCodesSubtitle")}</Text>
        </Flex>
        <div id="printContent">
          <Box py={24}>
            <Card>
              <Grid templateColumns="repeat(2, 1fr)">
                {backupCodes && renderBackupCodes(backupCodes)}
              </Grid>
            </Card>
          </Box>
        </div>
        <Grid templateColumns="repeat(2, 1fr)" gap={16} mb={24}>
          <GridItem>
            <Button
              data-testid="backup-codes-copy"
              variant="outline"
              colorScheme="primary"
              h="40px"
              w="100%"
              rightIcon={<Download />}
              onClick={handleOnClickCopy}
            >
              {isCopied
                ? t("backupCodesCopiedButton")
                : t("backupCodesCopyButton")}
            </Button>
          </GridItem>
          <GridItem>
            <Button
              data-testid="backup-codes-download"
              variant="outline"
              colorScheme="primary"
              h="40px"
              w="100%"
              rightIcon={<Download />}
              onClick={handleOnClickDownload}
            >
              {t("backupCodesDownloadButton")}
            </Button>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(2, 1fr)" gap={20}>
          <GridItem gridColumnStart={2}>
            <Button
              data-testid="backup-codes-confirm"
              variant="solid"
              colorScheme="primary"
              h="40px"
              w="100%"
              onClick={handleOnClickButton}
              isDisabled={!userConfirmedCodes}
            >
              {t("backupCodesFinishButton")}
            </Button>
          </GridItem>
        </Grid>
      </Reader>
    </div>
  );
};

MfaBackupCodesPage.propTypes = {
  addNotification: PropTypes.func,
};

export { MfaBackupCodesPage };
export default connect(null, { addNotification })(MfaBackupCodesPage);
