import { createIcon } from "@chakra-ui/react";

export const Virtual = createIcon({
  displayName: "Virtual",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4C3.13401 4 0 7.13401 0 11V17C0 18.6569 1.34315 20 3 20H11C14.0741 20 16.6854 18.0184 17.6262 15.2628L21.3675 16.5099C22.6626 16.9416 24 15.9777 24 14.6126V9.38743C24 8.02231 22.6626 7.05837 21.3675 7.49006L18 8.61257V7C18 5.34315 16.6569 4 15 4H7ZM18 10.7208V13.2792L22 14.6126V9.38743L18 10.7208ZM16 7C16 6.44772 15.5523 6 15 6H7C4.23858 6 2 8.23858 2 11V17C2 17.5523 2.44772 18 3 18H11C13.7614 18 16 15.7614 16 13V7Z"
    />
  ),
  defaultProps: {
    "aria-label": "Virtual meeting icon",
    role: "img",
    color: "tertiary.700",
  },
});
