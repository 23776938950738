import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { LeftArrow } from "design-system/assets";
import { IconButton, Heading, Text } from "design-system/components";
import { useTranslation } from "react-i18next";
import routes from "routes";

const i18nTextByMFAType = {
  sms: {
    title: "enableSmsTitle",
    description: "enableSmsDescription",
  },
  voice: {
    title: "enableVoiceTitle",
    description: "enableVoiceDescription",
  },
  app: {
    title: "enableAppTitle",
    description: "enableAppDescription",
  },
  default: {
    title: "chooseOptionSubtitle",
    description: "chooseOptionDescription",
  },
};

const EnableMfaHeading = ({ backButtonAlias, onClick, mfaOption }) => {
  const router = useRouter();
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });

  const backButtonOnClick = () => {
    backButtonAlias
      ? router.push(routes[backButtonAlias].to, routes[backButtonAlias].as)
      : onClick();
  };

  return (
    <>
      <IconButton
        data-testid="mfaBackButton"
        aria-label={
          backButtonAlias
            ? "Navigate back to settings"
            : "Navigate back to MFA options"
        }
        icon={<LeftArrow h="40px" w="40px" />}
        colorScheme="primary"
        variant="ghost"
        isRound={true}
        h="40px"
        w="40px !important"
        mb={32}
        onClick={backButtonOnClick}
      />
      <Text variant="subtitle1" color="primary.700">
        {t("chooseOptionTitle")}
      </Text>
      <Heading as="h1" variant="lg" color="primary.700">
        {t(i18nTextByMFAType[mfaOption].title)}
      </Heading>
      <Text variant="body1" color="platform.base" mb={24}>
        {t(i18nTextByMFAType[mfaOption].description)}
      </Text>
    </>
  );
};

EnableMfaHeading.propTypes = {
  onClick: PropTypes.func,
  mfaOption: PropTypes.oneOf(["default", "sms", "voice", "app"]).isRequired,
};

export default EnableMfaHeading;
