import ASRS, { ASRSSchema } from "./ASRS";
import AUDIT, { AUDITSchema } from "./AUDIT";
import EPDS, { EPDSSchema } from "./EPDS";
import GAD7, { GAD7Schema } from "./GAD7";
import Issues, { IssuesSchema } from "./Issues";
import MDQ, { MDQSchema } from "./MDQ";
import PCPTSD, { PCPTSDSchema } from "./PCPTSD";
import PHQ9, { PHQ9Schema } from "./PHQ9";
import QIDS, { QIDSSchema } from "./QIDS";
import SCOFF, { SCOFFSchema } from "./SCOFF";
import SDS, { SDSSchema } from "./SDS";
import SIB, { SIBSchema } from "./SIB";
import SPRASS, { SPRASSSchema } from "./SPRASS";
import complete from "./complete";
import goals from "./goals";
import introduction from "./introduction";
import sprassComplete from "./SPRASSComplete";
import confidence from "./confidence"; /* eslint-disable-line */

const questionnaires = {
  ASRS,
  AUDIT,
  EPDS,
  GAD7,
  Issues,
  MDQ,
  PCPTSD,
  PHQ9,
  QIDS,
  SCOFF,
  SDS,
  SIB,
  SPRASS,
  complete,
  goals,
  introduction,
  sprassComplete,
  confidence,
};

export const Schemas = {
  ASRS: ASRSSchema,
  AUDIT: AUDITSchema,
  EPDS: EPDSSchema,
  GAD7: GAD7Schema,
  Issues: IssuesSchema,
  MDQ: MDQSchema,
  PCPTSD: PCPTSDSchema,
  PHQ9: PHQ9Schema,
  QIDS: QIDSSchema,
  SCOFF: SCOFFSchema,
  SDS: SDSSchema,
  SIB: SIBSchema,
  SPRASS: SPRASSSchema,
};

export default questionnaires;
