import yup from "schemas/yup";

const requiredStateCountries = [
  "us",
  "usa",
  "united states",
  "ca",
  "can",
  "canada",
];
const isStateRequired = (country) => {
  return requiredStateCountries.includes(country.toLowerCase());
};

const schema = yup.object().shape({
  postal_address: yup.object().shape({
    city: yup.string().required(),
    state: yup.string().when("country", {
      is: (country) => country && isStateRequired(country),
      then: yup.string().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    street_address_1: yup.string().min(5).required(),
    street_address_2: yup.string().nullable(),
    zip_code: yup.string().min(2).required(),
    country: yup.string().nullable(),
  }),
});

export default schema;
