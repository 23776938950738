import { gql } from "@apollo/client";

const goal = gql`
  fragment goal on Goal {
    id
    goal_type
    description
    improvement_description
    neutral_description
    regression_description
    questionnaires
  }
`;

export default goal;
