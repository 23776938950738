// 

import { useState } from 'react';
import { connect } from 'react-redux';
import { getOr } from 'lodash/fp';

import { addOverlay } from '../../../actions/modal';


/**
 * This component can be used to conditionally add an overlay to a page.
 *
 * It is best used when the overlay logic should render AFTER the `PageBase` logic.
 * For example:
 *
 *     The MemberDashboard wants to ensure that a Member has taken an initial assessment before rendering
 *     The MemberDashboard uses `Snorlax` to make that check.
 *     Snorlax is checked in `PageBase` inside of the Member Dashboard
 *
 *     The MemberDashboard also wants to show an overlay if the member hasn't provided certain information
 *
 *     The InitialAssessment logic should take precedence over the overlay logic.
 *     To accomplish this, we can use the `AddAnOverlay` component inside Member Dashboard
 *
 *    Because `PageBase` does not render its children until `Allowed` is true, the `AddAnOverlay`
 *    logic will not fire until `Snorlax` does its magic. This way, we can ensure that we resolve
 *    the page logic before the overlay logic!
 */
const AddAnOverlay = ({ showIf, overlayId, overlays, addOverlay }) => {
	const [ overlayAdded, update ] = useState(false);

	if (
		showIf() &&
		!overlayAdded &&
		!overlays.includes(overlayId)
	) {
		addOverlay(overlayId);
		update(true);
	}

	return null;
};

AddAnOverlay.defaultProps = {
	showIf: () => false,
};

export { AddAnOverlay };
export default connect(
	(state) => ({
		overlays: getOr([], 'modal.overlays', state),
	}),
	{ addOverlay }
)(AddAnOverlay);