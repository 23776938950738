//

import React from "react";
import { Standard, FloatyPage } from "@spring/smeargle";
import { Overlay } from "design-system/components";

import { ReminderFrequencyForm } from "components/organisms";

const SetReminderFrequencyOverlay = () => (
  <Overlay>
    <Standard>
      <FloatyPage>
        <ReminderFrequencyForm />
      </FloatyPage>
    </Standard>
  </Overlay>
);

export default SetReminderFrequencyOverlay;
