import { Box, SliderMark } from "design-system/components";
import { range } from "lodash";

type SliderDotProps = {
  index: number;
  max: number;
  sliderValue: number;
  value: number | null;
};

type SliderDotsProps = {
  max: number;
  min: number;
  value: number | null;
};

const SliderDot = ({ index, max, sliderValue, value }: SliderDotProps) => {
  const bg = sliderValue <= value ? "primary-bold" : "background-disabled";

  return (
    <SliderMark
      key={sliderValue}
      ml={index === max ? ["-5px", "-7px"] : 0}
      mt={[-0.5, -1]}
      value={sliderValue}
      zIndex={-1}
    >
      <Box
        bg={bg}
        borderRadius="100%"
        data-bg={bg}
        data-testid="slider-dot"
        height={[1, 2]}
        overflow="visible"
        width={[1, 2]}
      />
    </SliderMark>
  );
};

const SliderDots = ({ max, min, value }: SliderDotsProps) => {
  return (
    <>
      {range(min, max + 1).map((sliderValue, index) => (
        <SliderDot
          index={index}
          key={`slider-dot-${index}`}
          max={max}
          sliderValue={sliderValue}
          value={value}
        />
      ))}
    </>
  );
};

export default SliderDots;
