//

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { getOr } from "lodash/fp";
import { Modal, Headline, Content, Button } from "@spring/smeargle";
import { addNotification, closeModal } from "@spring/smeargle/actions";
import {
  modalIds,
  RequestableAppointmentKind,
  AppointmentMedium,
} from "@spring/constants";
import { Trans, useTranslation } from "react-i18next";

import { SpringPhone, Link } from "components/atoms";

import styles from "./styles.module.scss";

import { getFirstError } from "utils/apollo/errorHandler";
import envUtils from "utils/environment";
import { requestAppointment } from "operations/mutations/appointment";

const RequestInPersonModal = (props) => {
  const { t } = useTranslation("careProvider");
  const submitRequest = async () => {
    try {
      const { days, times, other } = props.appointmentData.data;
      const payload = {
        availability_days_of_week: days,
        availability_time_of_day: times,
        medium: AppointmentMedium.InPerson,
        notes: other,
        kind: RequestableAppointmentKind.Therapy,
      };

      const {
        data: { requestAppointment },
        errors,
      } = await props.requestAppointment(payload);

      if (!requestAppointment.success) {
        return props.addNotification(getFirstError(errors), "error");
      }

      props.closeModal(modalIds.requestInPersonModal);
      return props.addNotification(
        t("requestInPersonModal.notificationMessage.success"),
        "success",
      );
    } catch (error) {
      return props.addNotification(getFirstError(error), "error");
    }
  };

  return (
    <Modal id={modalIds.requestInPersonModal} size="md">
      <Headline>{t("requestInPersonModal.headline")}</Headline>
      <Content>
        <Trans
          ns={"careProvider"}
          i18nKey={"requestInPersonModal.content"}
          components={[
            <Link key={"0"} to={`mailto:${envUtils.careTeamEmail}`}>
              send us a message
            </Link>,
            <SpringPhone key={"1"} />,
          ]}
        />
        <Link to={`mailto:${envUtils.careTeamEmail}`}>send us a message</Link>
      </Content>
      <div className={styles.buttonContainer}>
        <Button
          onClick={submitRequest}
          text={t("requestInPersonModal.submitText")}
          full
        />
      </div>
    </Modal>
  );
};

RequestInPersonModal.propTypes = {
  addNotification: PropTypes.func,
  appointmentData: PropTypes.shape({
    data: PropTypes.shape({
      days: PropTypes.any,
      times: PropTypes.any,
      other: PropTypes.any,
    }),
  }),
  closeModal: PropTypes.func,
  requestAppointment: PropTypes.func,
};

const mapStateToProps = (state) => ({
  appointmentData: getOr({}, "inPersonAppointmentDetail", state.form),
});

const mapDispatchToProps = { addNotification, closeModal };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (data) =>
        mutate({ variables: { input: { ...data } } }),
    }),
  }),
)(RequestInPersonModal);
