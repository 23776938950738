/**
 * NOTE: @param are props
 * @param {object}   notification    Notification object to populate content
 * @param {function} setNotification Set setNotification to null to update state
 */
import { useState } from "react";
import { Box, Flex, Text, IconButton } from "design-system/components";
import { Delete } from "design-system/assets";
// TODO: add a11y stuff
import PropTypes from "prop-types";

export const SHNotification = ({ notification, setNotification }) => {
  const [show, setShow] = useState(true);
  const { text, status } = notification;

  function closeNotification() {
    setNotification(null);
    setShow(false);
  }

  return (
    <>
      {show && (
        <Flex
          justifyContent="center"
          alignItems="center"
          py={8}
          px={12}
          bg={status === "success" ? "primary.500" : "error.500"}
          color="white"
          fontWeight={500}
          fontSize={[18, 18, 20, 20]}
          borderRadius={5}
          aria-hidden={!show}
          aria-live="polite"
          mb={10}
        >
          <Flex
            flex="1"
            justifyContent="center"
            transform="translateX(24px)"
            textAlign="center"
          >
            <Text data-cy="notification">{text}</Text>
          </Flex>

          <Box ms="left">
            <IconButton
              aria-label="Close Notification"
              data-cy="close-notification-button"
              tabIndex={show ? "0" : "-1"}
              onClick={() => closeNotification()}
              boxSize={24}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{
                height: 32,
                border: "3px solid #068464",
              }}
              icon={<Delete color="white" boxSize={24} />}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

SHNotification.propTypes = {
  notification: PropTypes.object.isRequired,
  setNotification: PropTypes.func.isRequired,
};

export default SHNotification;
