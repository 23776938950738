import { gql } from "@apollo/client";

const updateMemberWorkplaceManager = gql`
  mutation updateMemberWorkplaceManager(
    $member_id: ID!
    $workplace_manager: Boolean!
  ) {
    updateMemberWorkplaceManager(
      member_id: $member_id
      workplace_manager: $workplace_manager
    ) {
      success
      member {
        workplace_manager
      }
    }
  }
`;

export default updateMemberWorkplaceManager;
