//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { getOr } from "lodash/fp";
import { Grid, Col, Content } from "@spring/smeargle";

import { formatMemberPrevisitData } from "utils/displayHelpers";

class IntakeDisplay extends Component {
  static propTypes = {
    formData: PropTypes.any,
    status: PropTypes.any,
  };

  fieldData = (path, append = "") => {
    const data = getOr("", `${path}`, this.props.formData);
    const formattedDisplayValue = formatMemberPrevisitData(path, data, append);

    if (formattedDisplayValue) {
      return <Content>{formattedDisplayValue}</Content>;
    }

    if (!this.props.status) {
      return null;
    }

    return <Content italic> Not Provided{append}</Content>;
  };

  render() {
    return (
      <Grid>
        <Col md={5} lg={4}>
          <Content bold>Date of Birth:</Content>
        </Col>
        <Col md={7}>{this.fieldData("dateOfBirth")}</Col>

        <Col md={5} lg={4}>
          <Content bold>Gender:</Content>
        </Col>
        <Col md={7} dataCy="gender">
          {this.fieldData("gender")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>
            How do you identify in terms of race or ethnicity?
          </Content>
        </Col>
        <Col md={7} lg={8} dataCy="race_or_ethnicity">
          {this.fieldData("raceOrEthnicity")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>
            Have you ever had mental health diagnoses before?
          </Content>
        </Col>
        <Col md={7} lg={8} dataCy="previousDiagnoses">
          {this.fieldData("previousDiagnoses")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>
            Have you ever had any mental health treatments before?
          </Content>
        </Col>
        <Col md={7} lg={8} dataCy="previousTreatments">
          {this.fieldData("previousTreatments")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>
            Are you taking any medications for mental health currently?
          </Content>
        </Col>
        <Col md={7} lg={8} dataCy="MentalHealthMeds">
          {this.fieldData("currentMentalHealthMeds")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>
            Are you taking any other medications currently? If so, please list
            them.
          </Content>
        </Col>
        <Col md={7} lg={8} dataCy="otherCurrentMeds">
          {this.fieldData("otherCurrentMeds")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>Do you have any allergies to medication?</Content>
        </Col>
        <Col md={7} lg={8} dataCy="allergies">
          {this.fieldData("allergies")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>Name of your emergency contact:</Content>
        </Col>
        <Col md={7} lg={8} dataCy="emergencyName">
          {this.fieldData("emergencyContact.name")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>Phone number for your emergency contact:</Content>
        </Col>
        <Col md={7} lg={8} dataCy="emergencyPhoneNumber">
          {this.fieldData("emergencyContact.phoneNumber.number")}
        </Col>

        <Col md={5} lg={4}>
          <Content bold>
            During your time with Spring, you may try medications to help you
            feel better. If any of our doctors were to prescribe you a
            medication, do you have a preferred pharmacy where you would like us
            to send your medication?
          </Content>
        </Col>

        <Col md={7} lg={8} dataCy="pharmacy">
          {this.fieldData("preferredPharmacy.name", ": ")}
          {this.fieldData("preferredPharmacy.street", ", ")}
          {this.fieldData("preferredPharmacy.city", " ")}
          {this.fieldData("preferredPharmacy.state", ", ")}
          {this.fieldData("preferredPharmacy.zipCode")}
        </Col>
      </Grid>
    );
  }
}

export default IntakeDisplay;
