//

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { withRouter } from "next/router";
import { addNotification } from "@spring/smeargle/actions";
import Meowth from "@spring/meowth";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { SHInputWithLoadingState } from "design-system/components";

import {
  Heading,
  Text,
  Button,
  SimpleGrid,
  Box,
  SHStickyFooter,
  Flex,
} from "design-system/components";
import { emailValidation } from "schemas/hookFormSchema";

import { getMemberInfo } from "operations/queries/member";
import { updateUser } from "operations/mutations/user";
import routes from "routes";
import { getFirstError } from "utils/apollo/errorHandler";
import {
  TRACK_EVENT,
  track,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";

// The server is returning "#<StandardError: Could not update user>"
function removeStandardError(message) {
  return message.replace("#<StandardError: ", "").replace(">", "");
}

const UpdateEmailForm = (props) => {
  const { t } = useTranslation("emailContent");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const emailField = watch();

  const checkKeyDown = (e) => {
    // enter submits form and adds url params
    // this causes a bug in view rendering logic
    // below prevents bug & submits on 'return'
    if (e.code === "Enter") {
      e.preventDefault();
      onSubmit(emailField);
    }
  };

  useEffect(() => {
    track("Change sign-in email -- Viewed", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
        page: routes.UpdateEmail.as,
      }),
    });
  }, []);

  if (props.data?.loading) return null;

  const { addNotification, data, submit, router } = props;
  const currentEmail = data.user?.member.email || "";

  const onSubmit = async (formData) => {
    TRACK_EVENT.BUTTON_CLICKED(routes.UpdateEmail.as, "Change email");

    const payload = {
      id: data.user.id,
      patch: {
        email: formData.email,
      },
    };

    try {
      const {
        data: { updateUser },
      } = await submit(payload);

      if (!updateUser.success) {
        addNotification(`${t("updateEmail.notification")}`, "error");
      }
      // Every time a email is changed, a confirmation is automatically sent
      addNotification(
        t("notification.resendNotification", { email: formData.email }),
        "success",
      );

      track("User -- Change Email", {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
          page: routes.UpdateEmail.as,
          type: "Update Email",
        }),
      });

      TRACK_EVENT.FORM_SUBMITTED(routes.UpdateEmail.as, "Update Email");

      return router.push(
        routes.CodeConfirmationEmail.to,
        routes.CodeConfirmationEmail.as,
      );
    } catch (err) {
      addNotification(removeStandardError(getFirstError(err)), "error");
    }
  };

  const handleDiscardChanges = () => {
    track("User -- Disregard Change Email", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
        page: routes.UpdateEmail.as,
        to: routes.CodeConfirmationEmail.as,
        type: "Disregard Change Email",
      }),
    });
    TRACK_EVENT.LINK_CLICKED(
      routes.UpdateEmail.as,
      routes.CodeConfirmationEmail.as,
      "Disregard Change Email",
    );
    router.push(
      routes.CodeConfirmationEmail.to,
      routes.CodeConfirmationEmail.as,
    );
  };

  return (
    <>
      <Box
        m="0 auto"
        pb={[100, 100, 75, 75, 75]}
        w={[
          "calc(100% - 48px)",
          "calc(100% - 48px)",
          "calc(100% - 48px)",
          "80%",
        ]}
      >
        <Heading variant="md_v1" mb={8} lineHeight="37px">
          {t("updateEmail.title")}
        </Heading>
        <Text variant="body_v1" lineHeight="24px" mb="24px">
          {t("updateEmail.content")}
        </Text>
        <FormProvider>
          <form onKeyDown={(e) => checkKeyDown(e)}>
            <SimpleGrid w="100%">
              <Text fontWeight={700}>{t("updateEmail.currentMailLabel")}</Text>
              <Text variant="body_v1" mb="20px">
                {currentEmail}
              </Text>
              <SHInputWithLoadingState
                required
                name="email"
                label={t("updateEmail.label")}
                register={register}
                validation={emailValidation}
                errors={errors?.email}
                dataCy="email"
                t={t}
              />
            </SimpleGrid>
          </form>
        </FormProvider>
      </Box>
      <SHStickyFooter
        width={["100vw", "100vw", "50vw", "50vw", "50vw"]}
        hasShadow
      >
        <Flex
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          height={150}
          mb="8"
        >
          <Button
            colorScheme="primary"
            size="lg"
            variant="solid"
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            w={[
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "80%",
            ]}
            onClick={handleSubmit((formData) => onSubmit(formData))}
          >
            {t("updateEmail.submitText")}
          </Button>
          <Button
            bgColor="tertiary.50"
            color="platform"
            _hover={{
              bgColor: "tertiary.100",
              color: "platform",
            }}
            _focus={{
              bgColor: "tertiary.100",
              color: "platform",
            }}
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            size="lg"
            w={[
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "80%",
            ]}
            variant="solid"
            onClick={() => handleDiscardChanges()}
          >
            {t("updateEmail.discardChanges")}
          </Button>
        </Flex>
      </SHStickyFooter>
    </>
  );
};

UpdateEmailForm.propTypes = {
  addNotification: PropTypes.func,
  data: PropTypes.shape({
    loading: PropTypes.any,
    user: PropTypes.shape({
      id: PropTypes.any,
      member: PropTypes.shape({
        email: PropTypes.string,
      }),
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  submit: PropTypes.func,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  findMyMembershipModel: state.signUp.findMyMembershipModel,
});

const mapDispatchToProps = { addNotification };

export default compose(
  withRouter,
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(updateUser, {
    props: ({ mutate }) => ({
      submit: (input) =>
        mutate({
          variables: { input },
        }),
    }),
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(UpdateEmailForm);
