import styles from "./styles.module.scss";
import { SpringTextLogo } from "design-system/assets";

const SimpleFooter = () => (
  <footer className={styles.footer}>
    <div className={styles.footerImage} />
    <div className={styles.footerContent}>
      <div className={styles.footerText}>Provided By</div>
      <div className={styles.logo}>
        <SpringTextLogo />
      </div>
    </div>
  </footer>
);

export default SimpleFooter;
