// 

import React, { Component } from 'react';

import styles from './styles.module.scss';


class Timeline extends Component {
	handleStepClick = (index) => {
		if (this.props.onStepClick) {
			this.props.onStepClick(index);
		}
		this.props.setActiveStep(index);
		this.props.setTimeLineHeight();
	}

	// Encapsulate <Timeline/> component internal state and click handlers as props for <TimelineStep/> children
	renderChildrenWithProps = () => {
		return this.props.children.map((child, index) => {
			return React.cloneElement(child, {
				key        : index,
				onClick    : this.handleStepClick,
				active     : index === this.props.activeStep,
				activeStep : this.props.activeStep,
				index,
			});
		});
	}

	// Render current active step content
	renderActiveStepContent() {
		const { children, activeStep } = this.props;

		if (children[activeStep]) {
			return children[activeStep].props.children;
		}

		return null;
	}

	render() {
		return (
			<>
				<div className={styles.timeline}>
					<div className={styles.timelineWrapper}>
						{this.renderChildrenWithProps()}
					</div>
				</div>
				<div
					className={styles.stepContent}
				>
					{this.renderActiveStepContent()}
				</div>
			</>
		);
	}
}

export default Timeline;
