import { Box, HStack, Text } from "design-system/components";
import { QuestionMark } from "design-system/assets";
import { useTranslation } from "react-i18next";

export const DesktopHelpBtn = ({ onClick }) => {
  const { t } = useTranslation("common");

  return (
    <Box
      data-cy="help-button"
      as="button"
      onClick={onClick}
      w="fit-content"
      h="40px"
      px={16}
      bg="tertiary.50"
      borderRadius={24}
      _focus={{ border: "2px solid #068464" }}
      _active={{ bg: "tertiary.50", border: "2px solid #068464" }}
      _hover={{ bg: "#DCD9D6" }}
    >
      <HStack spacing={2}>
        <QuestionMark boxSize={20} />
        <Text fontWeight="bold">{t("navigation.helpButton")}</Text>
      </HStack>
    </Box>
  );
};
