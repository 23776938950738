export type ContentTypesUnion = "leader_course" | "webinar" | "moments";
export type ContentFormatsUnion =
  | "video"
  | "external"
  | "textAndAudio"
  | "text"
  | "audio";

export const CONTENT_TYPES: Record<ContentTypesUnion, ContentTypesUnion> = {
  leader_course: "leader_course",
  webinar: "webinar",
  moments: "moments",
};

export const CONTENT_FORMATS: Record<ContentFormatsUnion, ContentFormatsUnion> =
  {
    video: "video",
    external: "external",
    textAndAudio: "textAndAudio",
    text: "text",
    audio: "audio",
  };
