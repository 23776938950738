import { useTranslation } from "react-i18next";

import { Flex, Spinner } from "design-system/components";
import { Heading } from "design-system/components/base/Typography";

const Loading = () => {
  const { t } = useTranslation("loginless", { keyPrefix: "confirmation" });

  return (
    <Flex
      direction="column"
      align="center"
      style={{ width: "100%" }}
      data-testid="loading"
    >
      <Heading as="h1" align="center" variant="md_v1" mb={5}>
        {t("loading")}
      </Heading>
      <Spinner speed="1s" size="xl" />
    </Flex>
  );
};

export default Loading;
