export const getRecommendedMomentsSectionHeader = (
  momentsOnly,
  membersTrackedGoals,
  t,
) => {
  if (momentsOnly) {
    return "Recommended";
  }
  if (membersTrackedGoals.length > 0) {
    return t("basedOnYourGoals");
  }
  return t("forYou");
};
