import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import { SHHeader, SHProgressBar } from "design-system/components";
import { TeenInitialAssessment } from "./InitialAssessment";
import { TeenAssessmentSubmitted } from "./InitialAssessment/TeenAssessmentSubmitted";
import finishAssessment from "operations/mutations/assessment/finishAssessment";
import { useMutation } from "@apollo/client";
import RiskBasedSuicidePreventionModal, {
  ModalVariantType,
} from "components/modals/SuicidePreventionModal/RiskBasedSuicidePreventionModal";
import { handleTelemetryForQuestions } from "components/templates/TeenAssessment/utils";
import { getIterableCampaignInfo } from "utils/localStorage";

type OptionProps = {
  label: string;
  value: string;
};

type QuestionProps = {
  id: string;
  position: number;
  key: string;
  newTitle?: string;
  newSubtitle?: string;
  element: {
    id: string;
    kind: string;
    label?: string;
    options: OptionProps[];
  };
};

type CurrentPromptProps = {
  id: string;
  position: number;
  title: string;
  subtitle?: string;
  newTitle: string;
  questions: QuestionProps[];
};

type TeenAssessmentWrapperProps = {
  teenAssessmentData: {
    id: string;
    num_prompts_total: number;
    num_prompts_answered: number;
    current_questionnaire: {
      current_prompts: CurrentPromptProps[];
      kind: string;
    };
    display_modal?: ModalVariantType | null;
  };
  memberInfo: {
    first_name: string;
  };
  submitResponses: (formData: string[]) => void;
  closeTo?: string;
};

const TeenAssessmentWrapper = ({
  teenAssessmentData,
  memberInfo,
  submitResponses,
  closeTo = undefined,
}: TeenAssessmentWrapperProps) => {
  const { t } = useTranslation("assessments");
  const { current_questionnaire, display_modal } = teenAssessmentData;
  const { current_prompts, kind } = current_questionnaire || {
    current_prompts: [],
  };
  const totalPromptsInCurrenQuestionnaire = current_prompts.length;
  const [formData, setFormData] = useState<string[]>([]);
  const [currentPromptIndex, setCurrentPromptIndex] = useState<number>(0);
  const [currentPrompt, setCurrentPrompt] = useState<CurrentPromptProps>(
    current_prompts[currentPromptIndex],
  );
  const [isModalOpen, setIsModalOpen] = useState(!!display_modal);

  const [submitTeenAssessmentResponses] = useMutation(finishAssessment, {
    variables: {
      input: { id: teenAssessmentData.id, campaign: getIterableCampaignInfo() },
    },
  });

  useEffect(() => {
    if (!current_questionnaire) {
      submitTeenAssessmentResponses();
    }
  }, [current_questionnaire, submitTeenAssessmentResponses]);

  const handleAnswerSelection = (data) => {
    formData[currentPromptIndex] = data;
    const newPromptIndex = currentPromptIndex + 1;
    const noMorePrompts = newPromptIndex === totalPromptsInCurrenQuestionnaire;

    if (noMorePrompts) {
      handleTelemetryForQuestions({
        teenAssessmentData: teenAssessmentData,
        type: "Next",
        prompt: currentPrompt,
      });
      submitResponses(formData);
    } else {
      handleTelemetryForQuestions({
        teenAssessmentData: teenAssessmentData,
        type: "Next",
        prompt: currentPrompt,
      });
      setFormData(formData);
      // show the next prompt
      setCurrentPrompt(current_prompts[newPromptIndex]);
      setCurrentPromptIndex(newPromptIndex);
    }
  };

  const handleBackBtnClick = () => {
    if (currentPromptIndex !== 0) {
      handleTelemetryForQuestions({
        teenAssessmentData: teenAssessmentData,
        type: "Back",
        prompt: currentPrompt,
      });

      const newPromptIndex = currentPromptIndex - 1;
      setCurrentPrompt(current_prompts[newPromptIndex]);
      setCurrentPromptIndex(newPromptIndex);
    }
  };

  return (
    <Box>
      <Box
        style={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 1,
        }}
      >
        {/* @ts-ignore */}
        <SHHeader
          sliderDismissed
          isLoggedIn
          displayBackBtn={() => currentPromptIndex !== 0}
          doGoBack={handleBackBtnClick}
          showSplitHeaderDesktop={false}
          showLanguageSelect={false}
          closeTo={closeTo}
        />
        <Box>
          <SHProgressBar
            ariaLabel={t("assessmentProgress")}
            min={0}
            max={
              current_questionnaire ? teenAssessmentData.num_prompts_total : 1
            }
            current={
              current_questionnaire
                ? teenAssessmentData.num_prompts_answered + currentPromptIndex
                : 1
            }
          />
        </Box>
      </Box>
      {!current_questionnaire ? (
        <TeenAssessmentSubmitted memberInfo={memberInfo} />
      ) : (
        <TeenInitialAssessment
          memberInfo={memberInfo}
          handleAnswerSelection={handleAnswerSelection}
          formData={formData}
          currentPrompt={currentPrompt}
          questionnaireKind={kind}
          teenAssessmentData={teenAssessmentData}
        />
      )}
      <RiskBasedSuicidePreventionModal
        isOpen={isModalOpen}
        variant={display_modal as ModalVariantType}
        closeModalCallback={() => setIsModalOpen(false)}
      />
    </Box>
  );
};

export default TeenAssessmentWrapper;
