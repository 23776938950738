import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SimpleGrid, GridItem, Box, Text } from "@chakra-ui/react";
import { Subtitle, BackgroundCurve, LanguageSvg } from "@spring/smeargle";
import { openModal } from "@spring/smeargle/actions";
import Meowth from "@spring/meowth";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "redux";
import { useTranslation } from "hooks/react-i18next";

import { CheckinTodo } from "components/molecules";
import { lookupLanguage } from "utils/language";

import styles from "./styles.module.scss";

import { getMemberTodos } from "operations/queries/member";
import { MomentsStartExerciseModal } from "components";
import { Heading } from "design-system/components";

const MomentsHeader = (props) => {
  const { t } = useTranslation("moments");
  const language = lookupLanguage(props.lang).nativeName;
  const langIsEnglish = language === "English";
  const { momentsOnly } = props;

  return (
    <>
      <Box as="header">
        <SimpleGrid columns={12}>
          <GridItem colSpan={[12, 7, 9]}>
            <div className={styles.header}>
              <Heading
                as="h1"
                my={24}
                fontSize={48}
                fontWeight={50}
                data-cy="moments"
              >
                {t("momentsHeader.title")}
              </Heading>
              <Subtitle>
                <span>{t("momentsHeader.subtitle")}</span>
              </Subtitle>
              {momentsOnly && (
                <Text marginBottom={"2rem"} data-test="momentsOnlyContent">
                  {t("momentsOnlyHeader.content")}
                </Text>
              )}
              {!langIsEnglish && (
                <div className={styles.momentsTranslation}>
                  <LanguageSvg />
                  <div>
                    &nbsp;
                    {t("momentsHeader.languageNote", { language: language })}
                  </div>
                </div>
              )}
            </div>
          </GridItem>
          <GridItem colSpan={[0, 5, 3]}>
            <div className={styles.momentsIllustration}>
              <img
                src="/static/momentsIllustrationGrey.svg"
                alt="Moments Illustration"
                aria-hidden="true"
              />
            </div>
          </GridItem>
        </SimpleGrid>
      </Box>

      <BackgroundCurve marginTop="-530px" pageRoute="Moments" />
      {!momentsOnly && <CheckinTodo data={props.data} />}
      <MomentsStartExerciseModal />
    </>
  );
};

MomentsHeader.propTypes = {
  data: PropTypes.any,
  lang: PropTypes.any,
  openModal: PropTypes.func,
};
const mapStateToProps = (state) => ({
  lang: state.global.lang,
});

export default compose(
  graphql(getMemberTodos, {
    options: Meowth.apolloOptionsUserId,
    fetchPolicy: "network-only",
  }),
  connect(mapStateToProps, { openModal }),
)(MomentsHeader);
