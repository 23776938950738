import { ProviderRole, getProviderRoleForTracking } from "@spring/constants";
import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";

/*
reducing provider roles, by feeding it careteamdata and looking for
said providers in order to populate return array :)
*/
export const getCareTeamListRoles = (careTeamData) => {
  const providerRoleVals = Object.values(ProviderRole);
  return providerRoleVals.reduce((acc: Array<string>, currVal: string) => {
    const roleExists =
      careTeamData?.user.member.care_team[currVal] !== undefined;
    if (roleExists) {
      acc.push(getProviderRoleForTracking(currVal));
      return acc;
    }
    return acc;
  }, []);
};

export const fireProviderLinkClickedTelemetry = (
  routes,
  type,
  location,
  providerId = null,
) => {
  return () => {
    const { to, as } = routes;

    TRACK_EVENT.LINK_CLICKED(window.location.pathname, as, type, {
      location,
      provider_id: providerId,
    });

    Router.push(to, as);
  };
};
