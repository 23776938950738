import { SliderMark, Text } from "design-system/components";

type SliderRangeValuesProps = {
  min: number;
  max: number;
};

const SliderRangeValues = ({ max, min }: SliderRangeValuesProps) => {
  return (
    <>
      <SliderMark
        as={Text}
        color="platform.500"
        data-testid="slider-range-value-min"
        mt={20}
        value={min}
        variant="bodyBold_v1"
      >
        {min}
      </SliderMark>
      <SliderMark
        as={Text}
        color="platform.500"
        data-testid="slider-range-value-max"
        ml={-16}
        mt={20}
        value={max}
        variant="bodyBold_v1"
      >
        {max}
      </SliderMark>
    </>
  );
};

export default SliderRangeValues;
