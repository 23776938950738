import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Router from "next/router";
import routes from "routes";
import { useTranslation } from "react-i18next";
import { NicelyDoneScreen } from "components/assessments/SubmitAssessment/NicelyDoneScreen";
import { TRACK_EVENT } from "utils/mixpanel";
import { TEEN_ASSESSMENT_TELEMETRY_TYPES } from "components/templates/TeenAssessment/constants/telemetryValues";

type MemberInfoProps = {
  memberInfo: {
    first_name: string;
  };
};

export const TeenAssessmentSubmitted = ({
  memberInfo,
}: MemberInfoProps): React.ReactElement => {
  const { t } = useTranslation("assessments");
  const { first_name } = memberInfo;
  const submitButtonCopy = `${t(`submitAssessment.complete.visitHomePage`)}`;

  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      TEEN_ASSESSMENT_TELEMETRY_TYPES.COMPLETED_SURVEY,
      {
        page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.COMPLETED_SURVEY,
        spring_doc_id: "completedsurvey001",
      },
    );
  }, []);

  const handleSubmit = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      TEEN_ASSESSMENT_TELEMETRY_TYPES.RETURN_TO_HOME,
      {
        page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.COMPLETED_SURVEY,
        spring_doc_id: "completedsurvey002",
      },
    );
    Router.push(routes.TeenHome.as);
  };

  return (
    <Box
      role="main"
      maxW="606px"
      m="auto"
      data-testid="teen-assessment-submitted"
    >
      <NicelyDoneScreen
        firstName={first_name}
        submitButtonCopy={submitButtonCopy}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
