"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useOrderedArray;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function useOrderedArray() {
  var initial = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var _useState = (0, _react.useState)(initial),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    currentIndex = _useState2[0],
    updateIndex = _useState2[1];
  var prev = function prev() {
    updateIndex(currentIndex - 1);
  };
  var next = function next() {
    updateIndex(currentIndex + 1);
  };
  return [currentIndex, {
    prev: prev,
    next: next
  }];
}