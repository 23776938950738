import Router from "next/router";

import routes from "routes";

export function redirectToMemberHome() {
  Router.replace(routes.MemberHome.to, routes.MemberHome.as);
}

export function redirectToManualVerification() {
  Router.replace(routes.ManualVerification.to, routes.ManualVerification.as);
}

export function redirectToSignIn() {
  Router.replace(routes.SignIn.to, routes.SignIn.as);
}

export function redirectToRegister() {
  Router.push(routes.Register.to, routes.Register.as);
}
