import {
  VAvatarDuotoneSpot,
  VRelationshipsDuotoneSpot,
  VSetbackDuotoneSpot,
  VSelfAwarenessDuotoneSpot,
} from "@springcare/verdant-icons-react";

import { TEEN_RECOMMENDED_MOMENTS_IDS } from "modules/MemberDashboard/Teen/Home/constants/TeenHomePage.constants";

export const getTeenMomentIcon = (momentId) => {
  switch (momentId) {
    case TEEN_RECOMMENDED_MOMENTS_IDS.selfEsteem:
      return VAvatarDuotoneSpot;
    case TEEN_RECOMMENDED_MOMENTS_IDS.relationshipEnd:
      return VRelationshipsDuotoneSpot;
    case TEEN_RECOMMENDED_MOMENTS_IDS.difficultEmotions:
      return VSetbackDuotoneSpot;
    case TEEN_RECOMMENDED_MOMENTS_IDS.relaxingMuscles:
      return VSelfAwarenessDuotoneSpot;
    default:
      return VAvatarDuotoneSpot;
  }
};
