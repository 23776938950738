import { Avatar } from "@springcare/sh-component-library";
import {
  AvatarBadge,
  Box,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { VPersonalizedCareIcon } from "@springcare/verdant-icons-react";
import { useTranslation } from "react-i18next";
import type { CareProviderType } from "modules/MemberDashboard/Scheduling/types";

type CareNavigatorInfoPropType = {
  careProvider: CareProviderType;
  showSUDCN: boolean;
};

export const CareNavigatorInfo = ({
  careProvider,
  showSUDCN,
}: CareNavigatorInfoPropType) => {
  const { avatar, bio, name } = careProvider;
  const { t } = useTranslation("scheduling", { keyPrefix: "bookingReview" });

  return (
    <Box w="100%" mt="v-40" mr="v-64">
      <Heading size="heading-small" mb={24}>
        {showSUDCN
          ? t("specializedCareNavigatorHeading")
          : t("careNavigatorHeading")}
      </Heading>
      <HStack mb={32} gap={16}>
        <Avatar name={name} height={105} width={105} src={avatar}>
          <AvatarBadge
            boxSize="40px"
            bgColor="var(--chakra-colors-background-base)"
            border="1px solid"
            borderColor="var(--chakra-colors-border-subtle)"
            color="var(--chakra-colors-primary-base)"
            left={-10}
          >
            <VPersonalizedCareIcon height={18} width={18} />
          </AvatarBadge>
        </Avatar>
        <VStack alignItems="flex-start" justifyContent="center">
          <Heading size="heading-small">{name}</Heading>
          <Text>
            {showSUDCN ? t("specializedCareNavigator") : t("careNavigator")}
          </Text>
        </VStack>
      </HStack>
      <Text>{bio}</Text>
    </Box>
  );
};
