/**
 * NOTE: @param are props
 * @param {number}   [fcMb]          margin-bottom for <FormControl>
 * @param {string}   [label]         label for form
 * @param {object}   errors          Passed from RHF - will be passed if input has error
 * @param {string}   name            Important - this the key for the input value that gets Submitted
 * @param {function} register        Important - RHF function that registers the key (i.e 'name' prop)
 * @param {boolean}  [required]        If true makes field required - else - does not
 * @param {function} [setValue]      An RHF function that programatically sets the input value if it exists
 * @param {string}   [existingEmail] If an email is available externally, pass it here to then set the value
 * @param {string}   [emailBtnText]  Text for the btn which sets existingEmail
 * @param {string}   [helpText]      Text for box which can be used to add help text for the user
 * @param {string}   [fontWeight]    fontWeight for form label
 * @param {string}   [variant]       The design system variant of the input field

 * NOTE: This component must be nested in a react-hook-form form
   in order to fully work. It is meant for broad use and thus
   should be expanded very sparingly.

 * NOTE: Without the existingEmail, emailBtnText, & helpText, it
   simply renders a label and input with validation and RHF
   functionality.

 * NOTE: This input is currently required by default, to make it optional
   take a look at how the 'required' prop is used in <SHTextInput> and
   add it here.
*/

import PropTypes from "prop-types";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
} from "../../../index";
import { Trans } from "react-i18next";

export const SHEmailInput = ({
  fcMb,
  label,
  errors = undefined,
  name,
  register,
  required,
  helpText,
  dataCy,
  fontWeight,
  variant,
}) => {
  const emailValidation = (required) => {
    return {
      ...(required
        ? {
            required: (
              <Trans ns="limitedLangValidations" i18nKey="required">
                {{ fieldName: "Email" }}
              </Trans>
            ),
          }
        : {}),
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: (
          <Trans
            ns="limitedLangValidations"
            i18nKey="invalidFormat.email"
          ></Trans>
        ),
      },
    };
  };

  return (
    <FormControl mb={fcMb} isRequired isInvalid={errors}>
      <Flex justifyContent="space-between">
        <FormLabel
          htmlFor="email"
          fontWeight={fontWeight}
          requiredIndicator={
            required ? (
              <span style={{ marginLeft: "4px" }} title="required field">
                *
              </span>
            ) : null
          }
        >
          {label}
        </FormLabel>
      </Flex>

      <Input
        {...register(name, emailValidation(required))}
        id="email"
        type="email"
        variant={variant ?? "outline"}
        colorScheme={errors ? "error" : "platform"}
        autoComplete="email"
        data-cy={dataCy}
        required={required}
      />
      {errors && (
        <FormErrorMessage color="error.base" fontWeight="bold">
          {errors?.message}
        </FormErrorMessage>
      )}
      {helpText && (
        <Text color="platform.500" mt={8} fontSize={16} fontWeight={900}>
          {helpText}
        </Text>
      )}
    </FormControl>
  );
};

SHEmailInput.propTypes = {
  fcMb: PropTypes.number,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  setValue: PropTypes.func,
  existingEmail: PropTypes.string,
  emailBtnText: PropTypes.string,
  helpText: PropTypes.string,
  dataCy: PropTypes.string,
  fontWeight: PropTypes.string || PropTypes.number,
  variant: PropTypes.string,
};
