// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const Floaty = (props) => (
	<div
		className={classnames(styles.floaty, {
			[styles[`float-${props.float}`]] : props.float,
			[styles[`hover-${props.hover}`]] : props.hover,
		})}
		style={{ borderRadius: props.radius }}
	>
		{props.children}
	</div>
);

Floaty.defaultProps = {
	depth  : 3,
	radius : 0,
};

export default Floaty;
