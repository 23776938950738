import { gql } from "@apollo/client";

const resetDemoUser = gql`
  mutation resetDemoUser {
    resetDemoUser {
      success
      user {
        id
        member {
          id
        }
      }
    }
  }
`;

export default resetDemoUser;
