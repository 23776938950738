import { useMutation, useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { ActionChip, ModalSheet } from "@springcare/sh-component-library";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import {
  VHelpIcon,
  VPersonalizedCareDuotoneSpot,
} from "@springcare/verdant-icons-react";
import { HelpFlyout } from "components/flyouts/HelpFlyout";
import { SpecialtyCareModal, useDisclosure } from "design-system/components";
import { useRouter } from "next/router";
import { generateAssessment } from "operations/mutations/assessment";
import { getMemberInfo } from "operations/queries/member";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import routes from "routes";
import {
  SpecialtyCareClosing,
  SpecialtyCareClosingBody,
} from "./SpecialtyCareClosing";
import {
  SpecialtyCarePrimer,
  SpecialtyCarePrimerBody,
} from "./SpecialtyCarePrimer";
import SpecialtyCareSchedule from "./SpecialtyCareSchedule";

type SpecialtyCareGetStartedType = {
  closeModal: () => void;
  questionnaireKind?: string;
  isAssessmentCompleted?: boolean;
};

export const SpecialtyCareGetStarted = ({
  closeModal,
  questionnaireKind,
  isAssessmentCompleted,
}: SpecialtyCareGetStartedType) => {
  const {
    isOpen: isSpecialtyCareModalOpen,
    onOpen: onSpecialtyCareModalOpen,
    onClose: onSpecialtyCareModalClose,
  } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isHelpFlyoutOpen,
    onOpen: onHelpFlyoutOpen,
    onClose: onHelpFlyoutClose,
  } = useDisclosure();

  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [onBackClickAction, setOnBackClickAction] = useState(
    () => () => setIsScheduleOpen(false),
  );

  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.specialtyCare",
  });

  const { data: memberInfo, loading: memberLoading } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );

  const router = useRouter();

  const goToHomePage = () => {
    router.replace(routes.MemberHome.to, routes.MemberHome.as);
  };
  const [generateInitialSecondaryAssessmentMutation] =
    useMutation(generateAssessment);

  const handleInitialSecondary = (questionnaires_order) => {
    generateInitialSecondaryAssessmentMutation({
      variables: {
        member_id: memberInfo?.user?.member?.id,
        kind: "PROGRESS_UPDATE",
        questionnaires_order: questionnaires_order,
      },
      onError: () => {
        goToHomePage();
      },
      onCompleted: ({ generateAssessment }) => {
        mutationComplete(generateAssessment, router);
      },
    });
  };
  const mutationComplete = (generateAssessment, router) => {
    const { id } = generateAssessment.assessment;
    let { as } = routes.TakeMemberAssessment;

    as = as.replace(":id", id);
    router.replace(
      {
        pathname: routes.TakeMemberAssessment.to,
        query: { id },
      },
      as,
    );
  };

  function onClose() {
    !isAssessmentCompleted && onSpecialtyCareModalClose();
    closeModal();
  }

  const headerActionItems = [
    <ActionChip
      _active={{ textColor: "content-primary" }}
      _focus={{ textColor: "content-primary" }}
      _focusVisible={{
        textColor: "primary-base",
        boxShadow: "var(--chakra-shadows-focus-visible)",
      }}
      bg="#5F4B191A"
      color="content-primary"
      onClick={() => onHelpFlyoutOpen()}
      border="none"
      borderRadius={30}
      icon={VHelpIcon}
      data-cy={"help-button"}
      key="help-action-chip"
    >
      {t("headerActions.help")}
    </ActionChip>,
  ];

  const mobileHeaderItems = [
    {
      label: t("headerActions.help"),
      onClick: () => onHelpFlyoutOpen(),
    },
    {
      label: t("headerActions.close"),
      onClick: onClose,
    },
  ];

  const [isSm] = useMediaQuery("(max-width: 480px)");
  const SpecialtyCarePrimerMobile = () => {
    return (
      <ModalSheet
        placement={"bottom"}
        size={"lg"}
        isOpen={isSpecialtyCareModalOpen}
        onClose={onClose}
        scrim={true}
        showCloseButton={true}
        footerVariant="horizontal-group"
        footerActions={[
          {
            label: t("specialtyCarePrimer.actions.continueButton"),
            onClick: () => handleInitialSecondary([questionnaireKind]),
          },
          {
            label: t("specialtyCarePrimer.actions.cancelButton"),
            onClick: onClose,
          },
        ]}
      >
        <Flex direction={"column"} gap={16} pt={32}>
          <SpecialtyCarePrimerBody
            ModalImg={VPersonalizedCareDuotoneSpot}
            title={t("specialtyCarePrimer.headline.title")}
            subtitle={t("specialtyCarePrimer.headline.subtitle")}
            nextTitle={t("specialtyCarePrimer.next.title")}
            nextList={t(
              questionnaireKind === "PCL5"
                ? "specialtyCareTraumaPrimer.next.nextList"
                : "specialtyCarePrimer.next.nextList",
              {
                returnObjects: true,
              },
            )}
          />
        </Flex>
      </ModalSheet>
    );
  };

  const SpecialtyCareClosingMobile = () => {
    return (
      <ModalSheet
        placement={"bottom"}
        size={"lg"}
        isOpen={isSpecialtyCareModalOpen}
        onClose={onClose}
        scrim={true}
        showCloseButton={true}
        footerVariant={
          questionnaireKind === "PCL5" ? "high-emphasis" : "horizontal-group"
        }
        footerActions={[
          {
            label: t(
              questionnaireKind === "PCL5"
                ? "specialtyCareTraumaClosing.actions.buttonText"
                : "specialtyCareClosing.actions.scheduleButton",
            ),
            onClick: () => {
              questionnaireKind === "PCL5"
                ? onClose()
                : setIsScheduleOpen(true);
            },
          },
          {
            label:
              questionnaireKind !== "PCL5" &&
              t("specialtyCareClosing.actions.skipButton"),
            onClick: questionnaireKind !== "PCL5" && onClose,
          },
        ]}
      >
        <Flex direction={"column"} gap={16} pt={32}>
          <SpecialtyCareClosingBody
            ModalImg={VPersonalizedCareDuotoneSpot}
            title={t(
              questionnaireKind === "PCL5"
                ? "specialtyCareTraumaClosing.title"
                : "specialtyCareClosing.title",
            )}
            subtitle={t(
              questionnaireKind === "PCL5"
                ? "specialtyCareTraumaClosing.subtitle"
                : "specialtyCareClosing.subtitle",
            )}
          />
        </Flex>
      </ModalSheet>
    );
  };
  return (
    <>
      {isSm ? (
        <>
          {!isAssessmentCompleted ? (
            <SpecialtyCarePrimerMobile />
          ) : (
            <>{!isScheduleOpen && <SpecialtyCareClosingMobile />}</>
          )}
        </>
      ) : (
        <>
          <SpecialtyCareModal
            isOpen={isSpecialtyCareModalOpen}
            onClose={onClose}
            onBackClick={isScheduleOpen ? onBackClickAction : null}
            bodyDisplay="flex"
            headerItems={headerActionItems}
            styles={{
              "& > :nth-of-type(2)": {
                ...(!isScheduleOpen && { position: "absolute" }),
              },
            }}
          >
            {!isAssessmentCompleted ? (
              <SpecialtyCarePrimer
                ModalImg={VPersonalizedCareDuotoneSpot}
                title={t("specialtyCarePrimer.headline.title")}
                subtitle={t("specialtyCarePrimer.headline.subtitle")}
                nextTitle={t("specialtyCarePrimer.next.title")}
                nextList={t(
                  questionnaireKind === "PCL5"
                    ? "specialtyCareTraumaPrimer.next.nextList"
                    : "specialtyCarePrimer.next.nextList",
                  {
                    returnObjects: true,
                  },
                )}
                ctaButton={t("specialtyCarePrimer.actions.continueButton")}
                cancelButton={t("specialtyCarePrimer.actions.cancelButton")}
                ctaAction={() => handleInitialSecondary([questionnaireKind])}
                cancelAction={onClose}
              />
            ) : (
              <>
                {!isScheduleOpen && (
                  <SpecialtyCareClosing
                    ModalImg={VPersonalizedCareDuotoneSpot}
                    title={t(
                      questionnaireKind === "PCL5"
                        ? "specialtyCareTraumaClosing.title"
                        : "specialtyCareClosing.title",
                    )}
                    subtitle={t(
                      questionnaireKind === "PCL5"
                        ? "specialtyCareTraumaClosing.subtitle"
                        : "specialtyCareClosing.subtitle",
                    )}
                    ctaButton={t(
                      questionnaireKind === "PCL5"
                        ? "specialtyCareTraumaClosing.actions.buttonText"
                        : "specialtyCareClosing.actions.scheduleButton",
                    )}
                    ctaAction={() => {
                      questionnaireKind === "PCL5"
                        ? onClose()
                        : setIsScheduleOpen(true);
                    }}
                    skipButton={
                      questionnaireKind !== "PCL5" &&
                      t("specialtyCareClosing.actions.skipButton")
                    }
                    skipAction={questionnaireKind !== "PCL5" && onClose}
                  />
                )}
              </>
            )}
          </SpecialtyCareModal>
        </>
      )}

      {isAssessmentCompleted && isScheduleOpen && (
        <SpecialtyCareModal
          isOpen={isSpecialtyCareModalOpen}
          onClose={onClose}
          onBackClick={isScheduleOpen ? onBackClickAction : null}
          bodyDisplay="flex"
          headerItems={headerActionItems}
          mobileHeaderItems={mobileHeaderItems}
          styles={{
            "& > :nth-of-type(2)": {
              ...(!isScheduleOpen && { position: "absolute" }),
            },
          }}
        >
          <SpecialtyCareSchedule
            setOnBackClickAction={setOnBackClickAction}
            setIsScheduleOpen={setIsScheduleOpen}
          />
        </SpecialtyCareModal>
      )}
      <HelpFlyout
        isOpen={isHelpFlyoutOpen}
        onOpen={onHelpFlyoutOpen}
        onClose={onHelpFlyoutClose}
      />
    </>
  );
};
