//
import { ProviderRole } from "@spring/constants";

const includeTagsSubset = (
  tags,
  includedKinds = ["SPECIALTY", "CONDITION"],
) => {
  return tags.filter((tag) => includedKinds.includes(tag.kind));
};

const shouldShowTags = (providerData) =>
  !(
    providerData?.roles?.includes("Care Navigator") ||
    providerData?.careTeamRole === ProviderRole.CareNavigator
  );

export { includeTagsSubset, shouldShowTags };
