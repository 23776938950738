//
import { createSelector } from 'reselect';
import { get, getOr } from 'lodash/fp';

const dirtySelector = (state, props) => {
	return getOr(false, `${props.formKey}.meta.${props.fieldKey}.dirty`, state.form);
};

const requiredSelector = (state, props) => {
	return getOr(false, `${props.formKey}.meta.${props.fieldKey}.__required`, state.form);
};

const valueSelector = (state, props) => {
	if (props.type === 'number') {
		// Easier to use getOr here because ‘props.fieldKey’ can sometimes be an object (i.e., ….),
		// where nested keys cannot be easily and dynamically accessed with optional chaining and dot notation.
		const value = getOr(null, `${props.formKey}.data.${props.fieldKey}`, state.form);

		return Number.isNaN(parseInt(value, 10)) ? null : +value;
	}

	return getOr('', `${props.formKey}.data.${props.fieldKey}`, state.form) || '';
};

const nullValueSelector = (state, props) => {
	return getOr(null, `${props.formKey}.data.${props.fieldKey}`, state.form) || null;
};

const themeSelector = (state, props) => {
	return get(`${props.formKey}.theme`, state.form);
};

const clientValidationSelector = (state, props) => {
	return getOr({}, `${props.formKey}.validation.client.${props.fieldKey}`, state.form);
};

export const formFieldSelector = createSelector(
	[
		dirtySelector,
		valueSelector,
		themeSelector,
		clientValidationSelector,
		requiredSelector,
	],
	(dirty, value, theme, clientValidation, required) => ({
		dirty,
		value,
		theme,
		clientValidation,
		required,
	})
);

export const nullFormFieldSelector = createSelector(
	[
		dirtySelector,
		nullValueSelector,
		themeSelector,
		clientValidationSelector,
	],
	(dirty, value, theme, clientValidation) => ({
		dirty,
		value,
		theme,
		clientValidation,
	})
);
