// TODO: Find better place for this, maybe diff data structure
export const DIAL_CODES = [
  {
    name: "United States",
    label: "\ud83c\uddfa\ud83c\uddf8 +1",
    code: "US",
    value: "1",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "United Kingdom",
    label: "\ud83c\uddec\ud83c\udde7 +44",
    code: "GB",
    value: "44",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Hong Kong",
    label: "\ud83c\udded\ud83c\uddf0 +852",
    code: "HK",
    value: "852",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Japan",
    label: "\ud83c\uddef\ud83c\uddf5 +81",
    code: "JP",
    value: "81",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Korea, Republic of South Korea",
    label: "\ud83c\uddf0\ud83c\uddf7 +82",
    code: "KR",
    value: "82",
    min_length: 7,
    max_length: 8,
  },
  {
    name: "Switzerland",
    label: "\ud83c\udde8\ud83c\udded +41",
    code: "CH",
    value: "41",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Germany",
    label: "\ud83c\udde9\ud83c\uddea +49",
    code: "DE",
    value: "49",
    min_length: 10,
    max_length: 11,
  },
  {
    name: "China",
    label: "\ud83c\udde8\ud83c\uddf3 +86",
    code: "CN",
    value: "86",
    min_length: 11,
    max_length: 11,
  },
  {
    name: "Singapore",
    label: "\ud83c\uddf8\ud83c\uddec +65",
    code: "SG",
    value: "65",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Afghanistan",
    label: "\ud83c\udde6\ud83c\uddeb +93",
    code: "AF",
    value: "93",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "\u00c5land Islands",
    label: "\ud83c\udde6\ud83c\uddfd +358",
    code: "AX",
    value: "358",
    min_length: 6,
    max_length: 10,
  },
  {
    name: "Albania",
    label: "\ud83c\udde6\ud83c\uddf1 +355",
    code: "AL",
    value: "355",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Algeria",
    label: "\ud83c\udde9\ud83c\uddff +213",
    code: "DZ",
    value: "213",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "American Samoa",
    label: "\ud83c\udde6\ud83c\uddf8 +1684",
    code: "AS",
    value: "1684",
  },
  {
    name: "Andorra",
    label: "\ud83c\udde6\ud83c\udde9 +376",
    code: "AD",
    value: "376",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Angola",
    label: "\ud83c\udde6\ud83c\uddf4 +244",
    code: "AO",
    value: "244",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Anguilla",
    label: "\ud83c\udde6\ud83c\uddee +1264",
    code: "AI",
    value: "1264",
  },
  {
    name: "Antarctica",
    label: "\ud83c\udde6\ud83c\uddf6 +672",
    code: "AQ",
    value: "672",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Antigua and Barbuda",
    label: "\ud83c\udde6\ud83c\uddec +1268",
    code: "AG",
    value: "1268",
  },
  {
    name: "Argentina",
    label: "\ud83c\udde6\ud83c\uddf7 +54",
    code: "AR",
    value: "54",
    min_length: 6,
    max_length: 10,
  },
  {
    name: "Armenia",
    label: "\ud83c\udde6\ud83c\uddf2 +374",
    code: "AM",
    value: "374",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Aruba",
    label: "\ud83c\udde6\ud83c\uddfc +297",
    code: "AW",
    value: "297",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Australia",
    label: "\ud83c\udde6\ud83c\uddfa +61",
    code: "AU",
    value: "61",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Austria",
    label: "\ud83c\udde6\ud83c\uddf9 +43",
    code: "AT",
    value: "43",
    min_length: 10,
    max_length: 11,
  },
  {
    name: "Azerbaijan",
    label: "\ud83c\udde6\ud83c\uddff +994",
    code: "AZ",
    value: "994",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Bahamas",
    label: "\ud83c\udde7\ud83c\uddf8 +1242",
    code: "BS",
    value: "1242",
  },
  {
    name: "Bahrain",
    label: "\ud83c\udde7\ud83c\udded +973",
    code: "BH",
    value: "973",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Bangladesh",
    label: "\ud83c\udde7\ud83c\udde9 +880",
    code: "BD",
    value: "880",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Barbados",
    label: "\ud83c\udde7\ud83c\udde7 +1246",
    code: "BB",
    value: "1246",
  },
  {
    name: "Belarus",
    label: "\ud83c\udde7\ud83c\uddfe +375",
    code: "BY",
    value: "375",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Belgium",
    label: "\ud83c\udde7\ud83c\uddea +32",
    code: "BE",
    value: "32",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Belize",
    label: "\ud83c\udde7\ud83c\uddff +501",
    code: "BZ",
    value: "501",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Benin",
    label: "\ud83c\udde7\ud83c\uddef +229",
    code: "BJ",
    value: "229",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Bermuda",
    label: "\ud83c\udde7\ud83c\uddf2 +1441",
    code: "BM",
    value: "1441",
  },
  {
    name: "Bhutan",
    label: "\ud83c\udde7\ud83c\uddf9 +975",
    code: "BT",
    value: "975",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Bolivia, Plurinational State of bolivia",
    label: "\ud83c\udde7\ud83c\uddf4 +591",
    code: "BO",
    value: "591",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Bosnia and Herzegovina",
    label: "\ud83c\udde7\ud83c\udde6 +387",
    code: "BA",
    value: "387",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Botswana",
    label: "\ud83c\udde7\ud83c\uddfc +267",
    code: "BW",
    value: "267",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Bouvet Island",
    label: "\ud83c\udde7\ud83c\uddfb +47",
    code: "BV",
    value: "47",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Brazil",
    label: "\ud83c\udde7\ud83c\uddf7 +55",
    code: "BR",
    value: "55",
    min_length: 11,
    max_length: 11,
  },
  {
    name: "British Indian Ocean Territory",
    label: "\ud83c\uddee\ud83c\uddf4 +246",
    code: "IO",
    value: "246",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Brunei Darussalam",
    label: "\ud83c\udde7\ud83c\uddf3 +673",
    code: "BN",
    value: "673",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Bulgaria",
    label: "\ud83c\udde7\ud83c\uddec +359",
    code: "BG",
    value: "359",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Burkina Faso",
    label: "\ud83c\udde7\ud83c\uddeb +226",
    code: "BF",
    value: "226",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Burundi",
    label: "\ud83c\udde7\ud83c\uddee +257",
    code: "BI",
    value: "257",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Cambodia",
    label: "\ud83c\uddf0\ud83c\udded +855",
    code: "KH",
    value: "855",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Cameroon",
    label: "\ud83c\udde8\ud83c\uddf2 +237",
    code: "CM",
    value: "237",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Canada",
    label: "\ud83c\udde8\ud83c\udde6 +1",
    code: "CA",
    value: "1",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Cape Verde",
    label: "\ud83c\udde8\ud83c\uddfb +238",
    code: "CV",
    value: "238",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Cayman Islands",
    label: "\ud83c\uddf0\ud83c\uddfe +345",
    code: "KY",
    value: "345",
  },
  {
    name: "Central African Republic",
    label: "\ud83c\udde8\ud83c\uddeb +236",
    code: "CF",
    value: "236",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Chad",
    label: "\ud83c\uddf9\ud83c\udde9 +235",
    code: "TD",
    value: "235",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Chile",
    label: "\ud83c\udde8\ud83c\uddf1 +56",
    code: "CL",
    value: "56",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Christmas Island",
    label: "\ud83c\udde8\ud83c\uddfd +61",
    code: "CX",
    value: "61",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Cocos (Keeling) Islands",
    label: "\ud83c\udde8\ud83c\udde8 +61",
    code: "CC",
    value: "61",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Colombia",
    label: "\ud83c\udde8\ud83c\uddf4 +57",
    code: "CO",
    value: "57",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Comoros",
    label: "\ud83c\uddf0\ud83c\uddf2 +269",
    code: "KM",
    value: "269",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Congo",
    label: "\ud83c\udde8\ud83c\uddec +242",
    code: "CG",
    value: "242",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    label: "\ud83c\udde8\ud83c\udde9 +243",
    code: "CD",
    value: "243",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Cook Islands",
    label: "\ud83c\udde8\ud83c\uddf0 +682",
    code: "CK",
    value: "682",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Costa Rica",
    label: "\ud83c\udde8\ud83c\uddf7 +506",
    code: "CR",
    value: "506",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Cote d'Ivoire",
    label: "\ud83c\udde8\ud83c\uddee +225",
    code: "CI",
    value: "225",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Croatia",
    label: "\ud83c\udded\ud83c\uddf7 +385",
    code: "HR",
    value: "385",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Cuba",
    label: "\ud83c\udde8\ud83c\uddfa +53",
    code: "CU",
    value: "53",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Cyprus",
    label: "\ud83c\udde8\ud83c\uddfe +357",
    code: "CY",
    value: "357",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Czech Republic",
    label: "\ud83c\udde8\ud83c\uddff +420",
    code: "CZ",
    value: "420",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Denmark",
    label: "\ud83c\udde9\ud83c\uddf0 +45",
    code: "DK",
    value: "45",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Djibouti",
    label: "\ud83c\udde9\ud83c\uddef +253",
    code: "DJ",
    value: "253",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Dominica",
    label: "\ud83c\udde9\ud83c\uddf2 +1767",
    code: "DM",
    value: "1767",
  },
  {
    name: "Dominican Republic",
    label: "\ud83c\udde9\ud83c\uddf4 +1849",
    code: "DO",
    value: "1849",
  },
  {
    name: "Ecuador",
    label: "\ud83c\uddea\ud83c\udde8 +593",
    code: "EC",
    value: "593",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Egypt",
    label: "\ud83c\uddea\ud83c\uddec +20",
    code: "EG",
    value: "20",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "El Salvador",
    label: "\ud83c\uddf8\ud83c\uddfb +503",
    code: "SV",
    value: "503",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Equatorial Guinea",
    label: "\ud83c\uddec\ud83c\uddf6 +240",
    code: "GQ",
    value: "240",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Eritrea",
    label: "\ud83c\uddea\ud83c\uddf7 +291",
    code: "ER",
    value: "291",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Estonia",
    label: "\ud83c\uddea\ud83c\uddea +372",
    code: "EE",
    value: "372",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Ethiopia",
    label: "\ud83c\uddea\ud83c\uddf9 +251",
    code: "ET",
    value: "251",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Falkland Islands (Malvinas)",
    label: "\ud83c\uddeb\ud83c\uddf0 +500",
    code: "FK",
    value: "500",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Faroe Islands",
    label: "\ud83c\uddeb\ud83c\uddf4 +298",
    code: "FO",
    value: "298",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Fiji",
    label: "\ud83c\uddeb\ud83c\uddef +679",
    code: "FJ",
    value: "679",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Finland",
    label: "\ud83c\uddeb\ud83c\uddee +358",
    code: "FI",
    value: "358",
    min_length: 4,
    max_length: 12,
  },
  {
    name: "France",
    label: "\ud83c\uddeb\ud83c\uddf7 +33",
    code: "FR",
    value: "33",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "French Guiana",
    label: "\ud83c\uddec\ud83c\uddeb +594",
    code: "GF",
    value: "594",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "French Polynesia",
    label: "\ud83c\uddf5\ud83c\uddeb +689",
    code: "PF",
    value: "689",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "French Southern Territories",
    label: "\ud83c\uddf9\ud83c\uddeb +262",
    code: "TF",
    value: "262",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Gabon",
    label: "\ud83c\uddec\ud83c\udde6 +241",
    code: "GA",
    value: "241",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Gambia",
    label: "\ud83c\uddec\ud83c\uddf2 +220",
    code: "GM",
    value: "220",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Georgia",
    label: "\ud83c\uddec\ud83c\uddea +995",
    code: "GE",
    value: "995",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Ghana",
    label: "\ud83c\uddec\ud83c\udded +233",
    code: "GH",
    value: "233",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Gibraltar",
    label: "\ud83c\uddec\ud83c\uddee +350",
    code: "GI",
    value: "350",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Greece",
    label: "\ud83c\uddec\ud83c\uddf7 +30",
    code: "GR",
    value: "30",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Greenland",
    label: "\ud83c\uddec\ud83c\uddf1 +299",
    code: "GL",
    value: "299",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Grenada",
    label: "\ud83c\uddec\ud83c\udde9 +1473",
    code: "GD",
    value: "1473",
  },
  {
    name: "Guadeloupe",
    label: "\ud83c\uddec\ud83c\uddf5 +590",
    code: "GP",
    value: "590",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Guam",
    label: "\ud83c\uddec\ud83c\uddfa +1671",
    code: "GU",
    value: "1671",
  },
  {
    name: "Guatemala",
    label: "\ud83c\uddec\ud83c\uddf9 +502",
    code: "GT",
    value: "502",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Guernsey",
    label: "\ud83c\uddec\ud83c\uddec +44",
    code: "GG",
    value: "44",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Guinea",
    label: "\ud83c\uddec\ud83c\uddf3 +224",
    code: "GN",
    value: "224",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Guinea-Bissau",
    label: "\ud83c\uddec\ud83c\uddfc +245",
    code: "GW",
    value: "245",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Guyana",
    label: "\ud83c\uddec\ud83c\uddfe +592",
    code: "GY",
    value: "592",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Haiti",
    label: "\ud83c\udded\ud83c\uddf9 +509",
    code: "HT",
    value: "509",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Heard Island and Mcdonald Islands",
    label: "\ud83c\udded\ud83c\uddf2 +672",
    code: "HM",
    value: "672",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Holy See (Vatican City State)",
    label: "\ud83c\uddfb\ud83c\udde6 +379",
    code: "VA",
    value: "379",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Honduras",
    label: "\ud83c\udded\ud83c\uddf3 +504",
    code: "HN",
    value: "504",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Hungary",
    label: "\ud83c\udded\ud83c\uddfa +36",
    code: "HU",
    value: "36",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Iceland",
    label: "\ud83c\uddee\ud83c\uddf8 +354",
    code: "IS",
    value: "354",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "India",
    label: "\ud83c\uddee\ud83c\uddf3 +91",
    code: "IN",
    value: "91",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Indonesia",
    label: "\ud83c\uddee\ud83c\udde9 +62",
    code: "ID",
    value: "62",
    min_length: 11,
    max_length: 11,
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    label: "\ud83c\uddee\ud83c\uddf7 +98",
    code: "IR",
    value: "98",
    min_length: 11,
    max_length: 11,
  },
  {
    name: "Iraq",
    label: "\ud83c\uddee\ud83c\uddf6 +964",
    code: "IQ",
    value: "964",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Ireland",
    label: "\ud83c\uddee\ud83c\uddea +353",
    code: "IE",
    value: "353",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Isle of Man",
    label: "\ud83c\uddee\ud83c\uddf2 +44",
    code: "IM",
    value: "44",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Israel",
    label: "\ud83c\uddee\ud83c\uddf1 +972",
    code: "IL",
    value: "972",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Italy",
    label: "\ud83c\uddee\ud83c\uddf9 +39",
    code: "IT",
    value: "39",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Jamaica",
    label: "\ud83c\uddef\ud83c\uddf2 +1876",
    code: "JM",
    value: "1876",
  },
  {
    name: "Jersey",
    label: "\ud83c\uddef\ud83c\uddea +44",
    code: "JE",
    value: "44",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Jordan",
    label: "\ud83c\uddef\ud83c\uddf4 +962",
    code: "JO",
    value: "962",
    min_length: 8,
    max_length: 9,
  },
  {
    name: "Kazakhstan",
    label: "\ud83c\uddf0\ud83c\uddff +7",
    code: "KZ",
    value: "7",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Kenya",
    label: "\ud83c\uddf0\ud83c\uddea +254",
    code: "KE",
    value: "254",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Kiribati",
    label: "\ud83c\uddf0\ud83c\uddee +686",
    code: "KI",
    value: "686",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    label: "\ud83c\uddf0\ud83c\uddf5 +850",
    code: "KP",
    value: "850",
    min_length: 4,
    max_length: 13,
  },
  {
    name: "Kosovo",
    label: "\ud83c\uddfd\ud83c\uddf0 +383",
    code: "XK",
    value: "383",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Kuwait",
    label: "\ud83c\uddf0\ud83c\uddfc +965",
    code: "KW",
    value: "965",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Kyrgyzstan",
    label: "\ud83c\uddf0\ud83c\uddec +996",
    code: "KG",
    value: "996",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Laos",
    label: "\ud83c\uddf1\ud83c\udde6 +856",
    code: "LA",
    value: "856",
    min_length: 8,
    max_length: 9,
  },
  {
    name: "Latvia",
    label: "\ud83c\uddf1\ud83c\uddfb +371",
    code: "LV",
    value: "371",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Lebanon",
    label: "\ud83c\uddf1\ud83c\udde7 +961",
    code: "LB",
    value: "961",
    min_length: 7,
    max_length: 8,
  },
  {
    name: "Lesotho",
    label: "\ud83c\uddf1\ud83c\uddf8 +266",
    code: "LS",
    value: "266",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Liberia",
    label: "\ud83c\uddf1\ud83c\uddf7 +231",
    code: "LR",
    value: "231",
    min_length: 8,
    max_length: 9,
  },
  {
    name: "Libyan Arab Jamahiriya",
    label: "\ud83c\uddf1\ud83c\uddfe +218",
    code: "LY",
    value: "218",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Liechtenstein",
    label: "\ud83c\uddf1\ud83c\uddee +423",
    code: "LI",
    value: "423",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Lithuania",
    label: "\ud83c\uddf1\ud83c\uddf9 +370",
    code: "LT",
    value: "370",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Luxembourg",
    label: "\ud83c\uddf1\ud83c\uddfa +352",
    code: "LU",
    value: "352",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Macao",
    label: "\ud83c\uddf2\ud83c\uddf4 +853",
    code: "MO",
    value: "853",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Macedonia",
    label: "\ud83c\uddf2\ud83c\uddf0 +389",
    code: "MK",
    value: "389",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Madagascar",
    label: "\ud83c\uddf2\ud83c\uddec +261",
    code: "MG",
    value: "261",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Malawi",
    label: "\ud83c\uddf2\ud83c\uddfc +265",
    code: "MW",
    value: "265",
    min_length: 7,
    max_length: 9,
  },
  {
    name: "Malaysia",
    label: "\ud83c\uddf2\ud83c\uddfe +60",
    code: "MY",
    value: "60",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Maldives",
    label: "\ud83c\uddf2\ud83c\uddfb +960",
    code: "MV",
    value: "960",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Mali",
    label: "\ud83c\uddf2\ud83c\uddf1 +223",
    code: "ML",
    value: "223",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Malta",
    label: "\ud83c\uddf2\ud83c\uddf9 +356",
    code: "MT",
    value: "356",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Marshall Islands",
    label: "\ud83c\uddf2\ud83c\udded +692",
    code: "MH",
    value: "692",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Martinique",
    label: "\ud83c\uddf2\ud83c\uddf6 +596",
    code: "MQ",
    value: "596",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Mauritania",
    label: "\ud83c\uddf2\ud83c\uddf7 +222",
    code: "MR",
    value: "222",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Mauritius",
    label: "\ud83c\uddf2\ud83c\uddfa +230",
    code: "MU",
    value: "230",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Mayotte",
    label: "\ud83c\uddfe\ud83c\uddf9 +262",
    code: "YT",
    value: "262",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Mexico",
    label: "\ud83c\uddf2\ud83c\uddfd +52",
    code: "MX",
    value: "52",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    label: "\ud83c\uddeb\ud83c\uddf2 +691",
    code: "FM",
    value: "691",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Moldova",
    label: "\ud83c\uddf2\ud83c\udde9 +373",
    code: "MD",
    value: "373",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Monaco",
    label: "\ud83c\uddf2\ud83c\udde8 +377",
    code: "MC",
    value: "377",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Mongolia",
    label: "\ud83c\uddf2\ud83c\uddf3 +976",
    code: "MN",
    value: "976",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Montenegro",
    label: "\ud83c\uddf2\ud83c\uddea +382",
    code: "ME",
    value: "382",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Montserrat",
    label: "\ud83c\uddf2\ud83c\uddf8 +1664",
    code: "MS",
    value: "1664",
  },
  {
    name: "Morocco",
    label: "\ud83c\uddf2\ud83c\udde6 +212",
    code: "MA",
    value: "212",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Mozambique",
    label: "\ud83c\uddf2\ud83c\uddff +258",
    code: "MZ",
    value: "258",
    min_length: 12,
    max_length: 12,
  },
  {
    name: "Myanmar",
    label: "\ud83c\uddf2\ud83c\uddf2 +95",
    code: "MM",
    value: "95",
    min_length: 4,
    max_length: 11,
  },
  {
    name: "Namibia",
    label: "\ud83c\uddf3\ud83c\udde6 +264",
    code: "NA",
    value: "264",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Nauru",
    label: "\ud83c\uddf3\ud83c\uddf7 +674",
    code: "NR",
    value: "674",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Nepal",
    label: "\ud83c\uddf3\ud83c\uddf5 +977",
    code: "NP",
    value: "977",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Netherlands",
    label: "\ud83c\uddf3\ud83c\uddf1 +31",
    code: "NL",
    value: "31",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Netherlands Antilles",
    label: " +599",
    code: "AN",
    value: "599",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "New Caledonia",
    label: "\ud83c\uddf3\ud83c\udde8 +687",
    code: "NC",
    value: "687",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "New Zealand",
    label: "\ud83c\uddf3\ud83c\uddff +64",
    code: "NZ",
    value: "64",
    min_length: 8,
    max_length: 10,
  },
  {
    name: "Nicaragua",
    label: "\ud83c\uddf3\ud83c\uddee +505",
    code: "NI",
    value: "505",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Niger",
    label: "\ud83c\uddf3\ud83c\uddea +227",
    code: "NE",
    value: "227",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Nigeria",
    label: "\ud83c\uddf3\ud83c\uddec +234",
    code: "NG",
    value: "234",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Niue",
    label: "\ud83c\uddf3\ud83c\uddfa +683",
    code: "NU",
    value: "683",
    min_length: 4,
    max_length: 4,
  },
  {
    name: "Norfolk Island",
    label: "\ud83c\uddf3\ud83c\uddeb +672",
    code: "NF",
    value: "672",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Northern Mariana Islands",
    label: "\ud83c\uddf2\ud83c\uddf5 +1670",
    code: "MP",
    value: "1670",
  },
  {
    name: "Norway",
    label: "\ud83c\uddf3\ud83c\uddf4 +47",
    code: "NO",
    value: "47",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Oman",
    label: "\ud83c\uddf4\ud83c\uddf2 +968",
    code: "OM",
    value: "968",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Pakistan",
    label: "\ud83c\uddf5\ud83c\uddf0 +92",
    code: "PK",
    value: "92",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Palau",
    label: "\ud83c\uddf5\ud83c\uddfc +680",
    code: "PW",
    value: "680",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Palestinian Territory, Occupied",
    label: "\ud83c\uddf5\ud83c\uddf8 +970",
    code: "PS",
    value: "970",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Panama",
    label: "\ud83c\uddf5\ud83c\udde6 +507",
    code: "PA",
    value: "507",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Papua New Guinea",
    label: "\ud83c\uddf5\ud83c\uddec +675",
    code: "PG",
    value: "675",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Paraguay",
    label: "\ud83c\uddf5\ud83c\uddfe +595",
    code: "PY",
    value: "595",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Peru",
    label: "\ud83c\uddf5\ud83c\uddea +51",
    code: "PE",
    value: "51",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Philippines",
    label: "\ud83c\uddf5\ud83c\udded +63",
    code: "PH",
    value: "63",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Pitcairn",
    label: "\ud83c\uddf5\ud83c\uddf3 +64",
    code: "PN",
    value: "64",
    min_length: 8,
    max_length: 9,
  },
  {
    name: "Poland",
    label: "\ud83c\uddf5\ud83c\uddf1 +48",
    code: "PL",
    value: "48",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Portugal",
    label: "\ud83c\uddf5\ud83c\uddf9 +351",
    code: "PT",
    value: "351",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Puerto Rico",
    label: "\ud83c\uddf5\ud83c\uddf7 +1939",
    code: "PR",
    value: "1939",
  },
  {
    name: "Qatar",
    label: "\ud83c\uddf6\ud83c\udde6 +974",
    code: "QA",
    value: "974",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Romania",
    label: "\ud83c\uddf7\ud83c\uddf4 +40",
    code: "RO",
    value: "40",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Russia",
    label: "\ud83c\uddf7\ud83c\uddfa +7",
    code: "RU",
    value: "7",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Rwanda",
    label: "\ud83c\uddf7\ud83c\uddfc +250",
    code: "RW",
    value: "250",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Reunion",
    label: "\ud83c\uddf7\ud83c\uddea +262",
    code: "RE",
    value: "262",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Saint Barthelemy",
    label: "\ud83c\udde7\ud83c\uddf1 +590",
    code: "BL",
    value: "590",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    label: "\ud83c\uddf8\ud83c\udded +290",
    code: "SH",
    value: "290",
    min_length: 4,
    max_length: 4,
  },
  {
    name: "Saint Kitts and Nevis",
    label: "\ud83c\uddf0\ud83c\uddf3 +1869",
    code: "KN",
    value: "1869",
  },
  {
    name: "Saint Lucia",
    label: "\ud83c\uddf1\ud83c\udde8 +1758",
    code: "LC",
    value: "1758",
  },
  {
    name: "Saint Martin",
    label: "\ud83c\uddf2\ud83c\uddeb +590",
    code: "MF",
    value: "590",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Saint Pierre and Miquelon",
    label: "\ud83c\uddf5\ud83c\uddf2 +508",
    code: "PM",
    value: "508",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Saint Vincent and the Grenadines",
    label: "\ud83c\uddfb\ud83c\udde8 +1784",
    code: "VC",
    value: "1784",
  },
  {
    name: "Samoa",
    label: "\ud83c\uddfc\ud83c\uddf8 +685",
    code: "WS",
    value: "685",
    min_length: 5,
    max_length: 7,
  },
  {
    name: "San Marino",
    label: "\ud83c\uddf8\ud83c\uddf2 +378",
    code: "SM",
    value: "378",
    min_length: 10,
    max_length: 10,
  },
  {
    name: "Sao Tome and Principe",
    label: "\ud83c\uddf8\ud83c\uddf9 +239",
    code: "ST",
    value: "239",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Saudi Arabia",
    label: "\ud83c\uddf8\ud83c\udde6 +966",
    code: "SA",
    value: "966",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Senegal",
    label: "\ud83c\uddf8\ud83c\uddf3 +221",
    code: "SN",
    value: "221",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Serbia",
    label: "\ud83c\uddf7\ud83c\uddf8 +381",
    code: "RS",
    value: "381",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Seychelles",
    label: "\ud83c\uddf8\ud83c\udde8 +248",
    code: "SC",
    value: "248",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Sierra Leone",
    label: "\ud83c\uddf8\ud83c\uddf1 +232",
    code: "SL",
    value: "232",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Slovakia",
    label: "\ud83c\uddf8\ud83c\uddf0 +421",
    code: "SK",
    value: "421",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Slovenia",
    label: "\ud83c\uddf8\ud83c\uddee +386",
    code: "SI",
    value: "386",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Solomon Islands",
    label: "\ud83c\uddf8\ud83c\udde7 +677",
    code: "SB",
    value: "677",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Somalia",
    label: "\ud83c\uddf8\ud83c\uddf4 +252",
    code: "SO",
    value: "252",
    min_length: 8,
    max_length: 9,
  },
  {
    name: "South Africa",
    label: "\ud83c\uddff\ud83c\udde6 +27",
    code: "ZA",
    value: "27",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "South Sudan",
    label: "\ud83c\uddf8\ud83c\uddf8 +211",
    code: "SS",
    value: "211",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    label: "\ud83c\uddec\ud83c\uddf8 +500",
    code: "GS",
    value: "500",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Spain",
    label: "\ud83c\uddea\ud83c\uddf8 +34",
    code: "ES",
    value: "34",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Sri Lanka",
    label: "\ud83c\uddf1\ud83c\uddf0 +94",
    code: "LK",
    value: "94",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Sudan",
    label: "\ud83c\uddf8\ud83c\udde9 +249",
    code: "SD",
    value: "249",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Suriname",
    label: "\ud83c\uddf8\ud83c\uddf7 +597",
    code: "SR",
    value: "597",
    min_length: 6,
    max_length: 7,
  },
  {
    name: "Svalbard and Jan Mayen",
    label: "\ud83c\uddf8\ud83c\uddef +47",
    code: "SJ",
    value: "47",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Swaziland",
    label: "\ud83c\uddf8\ud83c\uddff +268",
    code: "SZ",
    value: "268",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Sweden",
    label: "\ud83c\uddf8\ud83c\uddea +46",
    code: "SE",
    value: "46",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Syrian Arab Republic",
    label: "\ud83c\uddf8\ud83c\uddfe +963",
    code: "SY",
    value: "963",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Taiwan",
    label: "\ud83c\uddf9\ud83c\uddfc +886",
    code: "TW",
    value: "886",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Tajikistan",
    label: "\ud83c\uddf9\ud83c\uddef +992",
    code: "TJ",
    value: "992",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    label: "\ud83c\uddf9\ud83c\uddff +255",
    code: "TZ",
    value: "255",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Thailand",
    label: "\ud83c\uddf9\ud83c\udded +66",
    code: "TH",
    value: "66",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Timor-Leste",
    label: "\ud83c\uddf9\ud83c\uddf1 +670",
    code: "TL",
    value: "670",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Togo",
    label: "\ud83c\uddf9\ud83c\uddec +228",
    code: "TG",
    value: "228",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Tokelau",
    label: "\ud83c\uddf9\ud83c\uddf0 +690",
    code: "TK",
    value: "690",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Tonga",
    label: "\ud83c\uddf9\ud83c\uddf4 +676",
    code: "TO",
    value: "676",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Trinidad and Tobago",
    label: "\ud83c\uddf9\ud83c\uddf9 +1868",
    code: "TT",
    value: "1868",
  },
  {
    name: "Tunisia",
    label: "\ud83c\uddf9\ud83c\uddf3 +216",
    code: "TN",
    value: "216",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Turkey",
    label: "\ud83c\uddf9\ud83c\uddf7 +90",
    code: "TR",
    value: "90",
    min_length: 11,
    max_length: 11,
  },
  {
    name: "Turkmenistan",
    label: "\ud83c\uddf9\ud83c\uddf2 +993",
    code: "TM",
    value: "993",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Turks and Caicos Islands",
    label: "\ud83c\uddf9\ud83c\udde8 +1649",
    code: "TC",
    value: "1649",
  },
  {
    name: "Tuvalu",
    label: "\ud83c\uddf9\ud83c\uddfb +688",
    code: "TV",
    value: "688",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Uganda",
    label: "\ud83c\uddfa\ud83c\uddec +256",
    code: "UG",
    value: "256",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Ukraine",
    label: "\ud83c\uddfa\ud83c\udde6 +380",
    code: "UA",
    value: "380",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "United Arab Emirates",
    label: "\ud83c\udde6\ud83c\uddea +971",
    code: "AE",
    value: "971",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Uruguay",
    label: "\ud83c\uddfa\ud83c\uddfe +598",
    code: "UY",
    value: "598",
    min_length: 8,
    max_length: 8,
  },
  {
    name: "Uzbekistan",
    label: "\ud83c\uddfa\ud83c\uddff +998",
    code: "UZ",
    value: "998",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Vanuatu",
    label: "\ud83c\uddfb\ud83c\uddfa +678",
    code: "VU",
    value: "678",
    min_length: 5,
    max_length: 5,
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    label: "\ud83c\uddfb\ud83c\uddea +58",
    code: "VE",
    value: "58",
    min_length: 7,
    max_length: 7,
  },
  {
    name: "Vietnam",
    label: "\ud83c\uddfb\ud83c\uddf3 +84",
    code: "VN",
    value: "84",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Virgin Islands, British",
    label: "\ud83c\uddfb\ud83c\uddec +1284",
    code: "VG",
    value: "1284",
  },
  {
    name: "Virgin Islands, U.S.",
    label: "\ud83c\uddfb\ud83c\uddee +1340",
    code: "VI",
    value: "1340",
  },
  {
    name: "Wallis and Futuna",
    label: "\ud83c\uddfc\ud83c\uddeb +681",
    code: "WF",
    value: "681",
    min_length: 6,
    max_length: 6,
  },
  {
    name: "Yemen",
    label: "\ud83c\uddfe\ud83c\uddea +967",
    code: "YE",
    value: "967",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Zambia",
    label: "\ud83c\uddff\ud83c\uddf2 +260",
    code: "ZM",
    value: "260",
    min_length: 9,
    max_length: 9,
  },
  {
    name: "Zimbabwe",
    label: "\ud83c\uddff\ud83c\uddfc +263",
    code: "ZW",
    value: "263",
    min_length: 9,
    max_length: 9,
  },
];
