import { useLazyQuery, useQuery } from "@apollo/client";
import {
  getAppointmentsWithProviderDetails,
  careProviderAvailabilities,
} from "operations/queries/appointment";
import Meowth from "@spring/meowth";
import { DateTime } from "luxon";

export const useMultiSessionBookingAppts = (memberId) => {
  const { data: appointmentData, loading: appointmentLoading } = useQuery(
    getAppointmentsWithProviderDetails,
    {
      variables: {
        limit: 1,
        booking_user_id: Meowth.getUserId(),
        kind: [
          "Therapy Appointment",
          "Minor Therapy Appointment",
          "Couples Therapy Appointment",
          "Initial Physician Appointment",
          "Follow Up Physician Appointment",
          "Coaching Appointment",
          "Initial Coaching Appointment",
          "Follow Up Coaching Appointment",
        ],
        sort_by: [
          {
            column: "appointments.created_at",
            direction: "desc",
          },
        ],
      },
      fetchPolicy: "network-only",
    },
  );
  const lastAppointment = appointmentData?.appointments?.data[0];

  const getFutureApptPayload = (apptStart, weekIncrement) => {
    const apptStartISO = DateTime.fromISO(apptStart);
    return {
      upcoming_slots_count: 4 - weekIncrement,
      user_ids: {
        member_id: memberId,
        care_provider_id: lastAppointment?.provider?.user_id,
      },
      kind: lastAppointment?.kind,
      upcoming_slots_only: true,
      range: {
        start_span: apptStartISO.plus({ days: weekIncrement * 7 }).toISO(),
        end_span: apptStartISO
          .plus({ days: 29 })
          .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
          .toISO(),
      },
    };
  };

  const [getAppointmentSlotsV2Lazy] = useLazyQuery(careProviderAvailabilities, {
    fetchPolicy: "network-only",
  });

  const lazyFetchAppointmentSlots = async (apptStartTime, weekIncrement) => {
    const payload = getFutureApptPayload(apptStartTime, weekIncrement);
    return getAppointmentSlotsV2Lazy({ variables: payload });
  };

  const sortByTime = (dateTimes) => {
    const uniqueDateTimes = dateTimes.filter(
      (value, index, self) => self.indexOf(value) === index,
    );
    return uniqueDateTimes.sort(
      (a, b) => DateTime.fromISO(a) - DateTime.fromISO(b),
    );
  };

  const sortFutureAppointments = (
    medium,
    oneWeekOutData,
    twoWeekOutData,
    threeWeekOutData,
  ) => {
    //Ideally use 1 slot from each week
    //Use 2 slots from week 2 if no week 1 slots
    //Use 3 slots from week 1 if no week 2 slots
    const loweredMedium = medium.toLowerCase();
    const oneWeekSlots =
      oneWeekOutData?.care_provider_availabilities?.[loweredMedium]?.available;
    const twoWeekSlots =
      twoWeekOutData?.care_provider_availabilities?.[loweredMedium]?.available;
    const threeWeekSlots =
      threeWeekOutData?.care_provider_availabilities?.[loweredMedium]
        ?.available;
    const nextSlots = [oneWeekSlots, twoWeekSlots, threeWeekSlots]
      .map((available) => available?.[0])
      .filter((firstSlot) => firstSlot);
    if (
      nextSlots.length === 2 &&
      (twoWeekSlots?.length > 1 || oneWeekSlots > 1)
    ) {
      const extraSlot =
        twoWeekSlots?.length > 1
          ? twoWeekSlots[twoWeekSlots.length - 1]
          : oneWeekSlots[oneWeekSlots.length - 1];
      return sortByTime([...nextSlots, extraSlot]);
    }
    if (nextSlots.length < 2 && oneWeekSlots) {
      return sortByTime([...oneWeekSlots].slice(0, 3));
    }
    return sortByTime(nextSlots);
  };

  const lazyFetchFutureAppointmentSlots = async (apptStartTime, medium) => {
    const [week1, week2, week3] = await Promise.all([
      lazyFetchAppointmentSlots(apptStartTime, 1),
      lazyFetchAppointmentSlots(apptStartTime, 2),
      lazyFetchAppointmentSlots(apptStartTime, 3),
    ]);
    return sortFutureAppointments(medium, week1.data, week2.data, week3.data);
  };

  return {
    lastAppointment,
    appointmentLoading,
    lazyFetchFutureAppointmentSlots,
  };
};
