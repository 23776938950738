import { useState, useEffect } from "react";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";

export function getTagName(careProviderTags, tagId) {
  let copy = careProviderTags;
  let filteredCopy = copy.filter((tag) => tag.id === tagId);
  if (filteredCopy[0]) return filteredCopy[0].name;
}

export const getCurrentRoute = (providerType) =>
  providerType === "coach" ? routes.CoachesBrowse.as : window.location.pathname;

export function useTrackFilterChange(
  filterName,
  isFlyoutOpen,
  tagData,
  filterTagData,
  providerType,
) {
  const [prevFilterTagData, setPrevFilterTagData] = useState([]);
  const currentRoute = getCurrentRoute(providerType);

  useEffect(() => {
    if (!isFlyoutOpen) return;
    const lastFilterEntry = filterTagData[filterTagData.length - 1];
    const hasRemovedAFilterEntry =
      prevFilterTagData.length > filterTagData.length;

    if (tagData && hasRemovedAFilterEntry) {
      const removedTag = prevFilterTagData.filter(
        (d) => !filterTagData.includes(d),
      );
      TRACK_EVENT.FILTER_CHANGE_APPLIED(currentRoute, filterName, {
        change: "Removed",
        value: getTagName(tagData, removedTag[0]),
      });
    } else if (tagData && getTagName(tagData, lastFilterEntry)) {
      TRACK_EVENT.FILTER_CHANGE_APPLIED(currentRoute, filterName, {
        change: "Added",
        value: getTagName(tagData, lastFilterEntry),
      });
    }

    setPrevFilterTagData(filterTagData);
  }, [filterTagData, isFlyoutOpen]);
}
