import React from 'react';
import PropTypes from 'prop-types';

const MomentsCarePlanIcon = ({ svgProps, title, iconId, ...props }) => {
	const suffix = iconId ? `-${iconId}` : '';
	const titleId = title && `moments-care-plan-icon${suffix}`;

	return (
		<svg
			aria-labelledby={titleId}
			viewBox="0 -1 24 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={props}
			role="presentation"
			alt=""
			{ ...svgProps }
		>
			{title && <title id={titleId}>{title}</title>}
			<path
				d="M20.557 14.255c-.134 1.14-1.029 2.236-2.702 2.997-1.657.753-3.93 1.095-6.414.782-2.484-.314-4.615-1.212-6.054-2.36-1.456-1.16-2.077-2.454-1.944-3.599.134-1.14 1.029-2.235 2.702-2.996 1.657-.753 3.93-1.096 6.414-.782 2.485.314 4.615 1.212 6.054 2.36 1.456 1.16 2.077 2.453 1.944 3.598z"
				stroke="#068464"
				strokeWidth="2"
			/>
			<path
				d="M23 22.543c.027 1.053-.827 2.299-2.867 3.367-1.978 1.037-4.802 1.74-7.995 1.809-3.193.068-6.052-.515-8.086-1.466-2.097-.982-3.023-2.194-3.051-3.25-.029-1.054.826-2.3 2.866-3.368 1.978-1.036 4.802-1.74 7.995-1.809 3.193-.068 6.052.515 8.086 1.466 2.097.982 3.023 2.194 3.051 3.251z"
				stroke="#068464"
				strokeWidth="2"
			/>
			<circle cx="13.197" cy="4.787" r="3.787" stroke="#068464" strokeWidth="2"/>
		</svg>
	);
};

MomentsCarePlanIcon.propTypes = {
	height   : PropTypes.string,
	width    : PropTypes.string,
	padding  : PropTypes.string,
	title    : PropTypes.string,
	iconId   : PropTypes.string,
	svgProps : PropTypes.shape({
		role : PropTypes.string,
		alt  : PropTypes.string,
	}),
};

MomentsCarePlanIcon.defaultProps = {
	height   : '24px',
	width    : '24px',
	padding  : '5px 0px 0px',
	svgProps : {},
};

export default MomentsCarePlanIcon;
