import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useMediaQuery, Center, Heading, Box } from "design-system/components";

import { MembershipItem } from "components/atoms";

const getHeading = (t, isWidthSmall) => {
  const heading = (
    <Heading mb={24} as="h3" fontSize={{ base: 21, md: 29 }} fontWeight={700}>
      {t("membershipTable.title")}
    </Heading>
  );
  if (isWidthSmall) {
    return heading;
  }
  return <Center textAlign="start">{heading}</Center>;
};

const MembershipTable = (props) => {
  const [isWidthSmall] = useMediaQuery("(max-width: 48em)");

  const { t } = useTranslation("membership");

  const specialtyVisitsCovered = props.specialtyVisitsCovered;
  const totalVisitsCovered = props.totalVisitsCovered;
  const coachingVisitsAmount = t(
    "membershipTable.coaching.coachingVisitsCovered",
    { visits_covered: props.coachingVisitsCovered },
  );

  const therapyDescription = (
    totalVisitsCovered,
    specialtyVisitsCovered,
    t,
  ) => {
    if (totalVisitsCovered > 0) {
      return t("membershipTable.therapy.descriptionWithSessions", {
        specialtyVisitsCovered: specialtyVisitsCovered,
      });
    }
    return specialtyVisitsCovered > 0
      ? t("membershipTable.therapy.descriptionNoSessions")
      : t("membershipTable.therapy.descriptionNoSessionsNoMedication");
  };

  return (
    <Box
      bg="#FAF9F6"
      borderRadius="lg"
      pt={24}
      pb={8}
      px={{ base: 20, md: 50 }}
    >
      {getHeading(t, isWidthSmall)}

      <MembershipItem
        title={
          specialtyVisitsCovered > 0
            ? t("membershipTable.therapy.title")
            : t("membershipTable.therapy.titleNoMedication")
        }
        amount={props.amount}
        description={therapyDescription(
          totalVisitsCovered,
          specialtyVisitsCovered,
          t,
        )}
      />
      {props.coachingVisitsCovered > 0 && (
        <MembershipItem
          title={t("membershipTable.coaching.title")}
          amount={coachingVisitsAmount}
          description={t("membershipTable.coaching.description")}
        />
      )}
      <MembershipItem
        title={t("membershipTable.dedicatedSupport.title")}
        amount={t("membershipTable.unlimited")}
        description={t("membershipTable.dedicatedSupport.description")}
      />

      <MembershipItem
        title={t("membershipTable.wellnessExercises.title")}
        amount={t("membershipTable.unlimited")}
        description={t("membershipTable.wellnessExercises.description")}
      />

      <MembershipItem
        title={t("membershipTable.progressTracking.title")}
        amount={t("membershipTable.unlimited")}
        description={t("membershipTable.progressTracking.description")}
      />
    </Box>
  );
};

MembershipTable.propTypes = {
  amount: PropTypes.string,
  specialtyVisitsCovered: PropTypes.any,
  totalVisitsCovered: PropTypes.number,
};

MembershipTable.defaultProps = {
  amount: "Price varies",
};

export default MembershipTable;
