import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { MomentsContentIcons } from '../../atoms';
import { Card, FlexCol, FlexRow } from '../../layout';
import { clickableDivProps } from '../../../lib/accessibility_helpers';

import styles from './styles.module.scss';

import { IconAriaLabelsPropType, ExercisesPropType } from '../../../components/molecules/Moments/propShapes';
const MomentsExerciseCard = (props) => {

	const [bookIconProps, headphonesIconProps] = ['book', 'headphones'].map(icon => {
		if (props.iconAriaLabels && props.iconAriaLabels[icon]) {
			return { 'aria-label': props.iconAriaLabels[icon] };
		}
	});

	return (
		<div
			data-testid="exerciseCard"
			className={classnames(styles.card, {
				[styles.clickable]: !!props.onClick,
			})}
			onClick={props.onClick}
			data-cy={props.dataCy ? props.dataCy + '-exercise' : ''}
			{...clickableDivProps({ onClick: props.onClick })}
			ref={props.focusRef}
		>
			<Card
				backgroundImage={props.exercise.images[0].url}
				radius="md"
				padding="none"
				bordered={false}
				full
			>
				<div className={styles.content}>
					<FlexCol>
						<span className={styles.title}>{props.exercise.title.substring(0,29) +
					(props.exercise.title.length > 29? '...' : '')}</span>
						<FlexRow justification="space-between" alignment="baseline">
							<span className={styles.subtitle}>
								{props.t('activityTypes.' + props.exercise.activity_type, { ns: 'moments' })}
								{' • '}
								{props.exercise.duration} {props.t('duration.minutes', { ns: 'moments' })}
							</span>
							<div className={styles.icons}>
								<MomentsContentIcons
									types={props.exercise.content_formats}
									bookIconProps={bookIconProps}
									headphonesIconProps={headphonesIconProps}
								/>
							</div>
						</FlexRow>
					</FlexCol>
				</div>
			</Card>
		</div>
	);
};

MomentsExerciseCard.propTypes = {
	exercise       : ExercisesPropType,
	iconAriaLabels : IconAriaLabelsPropType,
	dataCy         : PropTypes.string,
	onClick        : PropTypes.func,
	t              : PropTypes.func,
};

export default MomentsExerciseCard;
