import { DateTime } from "luxon";
import Router from "next/router";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  AppointmentAction,
  AppointmentKind,
  AppointmentMedium,
  getModalNameForTracking,
  modalIds,
} from "@spring/constants";
import { Button } from "@springcare/sh-component-library";
import { Box } from "@chakra-ui/react";

import { RightArrow } from "design-system/assets";
import { useOpenModal } from "hooks";
import routes from "routes";
import { CareProvider } from "shared/contexts";
import { ScheduleModalOpenOptions } from "shared/hooks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";

interface CallToActionProps {
  callToActionText: string;
  careNavigator: CareProvider;
  therapist: CareProvider;
  isCoach: boolean;
  medicationManager: CareProvider;
  medium: string;
  isTherapy: boolean;
  initialStartTime: DateTime;
  callToActionRoute: string;
  isMedicationManager: boolean;
  modalControls: { isOpen: boolean; onClose: () => void; onOpen: () => void };
  onScheduleModalOpen: (props: ScheduleModalOpenOptions) => void;
  hasSpecializedCareNavigator?: boolean;
  onClickCallToAction?: () => void;
}

const CallToAction: FC<React.PropsWithChildren<CallToActionProps>> = ({
  callToActionText,
  careNavigator,
  isCoach,
  isTherapy = false,
  therapist,
  medicationManager,
  medium,
  initialStartTime,
  callToActionRoute,
  isMedicationManager,
  onScheduleModalOpen,
  hasSpecializedCareNavigator = false,
  onClickCallToAction,
}) => {
  const { t } = useTranslation(["benefits"]);
  const { openCareProviderScheduleModal } = useOpenModal();

  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const handleRoute = (trackingName = "") => {
    const { to, as } = routes[callToActionRoute];
    Router.push(to, as);
    if (trackingName) {
      TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, trackingName, {
        to: routes[callToActionRoute].as,
      });
    }
  };

  const openMedicationManagerModal = () => {
    if (shouldShowNewInsuranceModal) {
      onScheduleModalOpen({
        provider: medicationManager,
        kind: AppointmentKind.FollowUpMedicationManagement,
        buttonText: t("medicationManagement.followUpcallToActionText"),
        action: AppointmentAction.Create,
      });
    } else {
      openCareProviderScheduleModal({
        provider: medicationManager,
        kind: AppointmentKind.FollowUpMedicationManagement,
        medium: AppointmentMedium.Video,
        providerRole: "Medication Manager",
        buttonText: t("medicationManagement.followUpcallToActionText"),
        action: AppointmentAction.Create,
        initialStartTime: initialStartTime,
      });
    }
  };

  const openTherapistModal = () => {
    if (shouldShowNewInsuranceModal) {
      onScheduleModalOpen({
        provider: therapist,
        kind: AppointmentKind.Therapy,
        buttonText: t("therapyVisits.modalText"),
        action: AppointmentAction.Create,
      });
    } else {
      openCareProviderScheduleModal({
        provider: therapist,
        kind: AppointmentKind.Therapy,
        medium: medium,
        providerRole: "Therapist",
        buttonText: t("therapyVisits.modalText"),
        action: AppointmentAction.Create,
        initialStartTime: initialStartTime,
      });
      TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Schedule Therapy", {
        spring_doc_id: "therapy002",
        to: "Open Modal",
      });
    }
  };

  const openModal = () => {
    onClickCallToAction?.();
    if (
      callToActionText === t("careNavigation.callToActionTextNew") ||
      callToActionText === t("careNavigation.callToActionText")
    ) {
      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        "Talk to a Care Navigator clicked",
        {
          to: getModalNameForTracking(modalIds.careProviderScheduleModal),
        },
      );
    }
    if (hasSpecializedCareNavigator) {
      handleRoute("Schedule Specialized Care Navigator");
    } else if (careNavigator && !isTherapy) {
      Router.push(routes.CareNavigation.as);
    } else if (therapist) {
      openTherapistModal();
    } else if (medicationManager) {
      openMedicationManagerModal();
    } else if (isCoach) {
      handleRoute("Schedule Coaching");
    } else if (isMedicationManager) {
      handleRoute("Schedule Medication Manager");
    } else {
      handleRoute();
    }
  };

  return (
    <>
      <Button
        data-cy="covered-care-content-call-to-action"
        borderRadius="4px"
        padding="16px 32px"
        onClick={openModal}
        mt={12}
      >
        {callToActionText}
        <Box as="span" ml={12}>
          <RightArrow />
        </Box>
      </Button>
    </>
  );
};

export default CallToAction;
