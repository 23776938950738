import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import Meowth from "@spring/meowth";
import { useTranslation } from "react-i18next";
import { ActionChip, IconButton } from "@springcare/sh-component-library";
import { Text, useBreakpointValue } from "@chakra-ui/react";
import { VAssessmentIcon } from "@springcare/verdant-icons-react";

import routes from "routes";
import { getMemberInfo } from "operations/queries/member";
import { useCreateCheckInMutation } from "hooks/useCreateCheckInMutation";
import { TRACK_EVENT } from "utils/mixpanel";
import { useCustomToast } from "@springcare/sh-component-library";

const TakeCheckinButton = ({ isATeen = false }) => {
  const { t } = useTranslation(["common", "error"]);
  const router = useRouter();
  const errorToast = useCustomToast({
    type: "error",
    message: t("message", { ns: "error" }),
    layout: "fit-content",
    duration: 5000,
  });

  const { data, loading } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
    onError: () => {
      errorToast();
    },
  });
  const memberId = data?.user?.member?.id;
  const [createCheckInMutation] = useCreateCheckInMutation(memberId, router);

  const handleTeenCheckInClick = () => {
    router.push(routes.TeenCheckIn.as);
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Progress Check-in", {
      spring_doc_id: "checkin002",
    });
  };

  const CircularNoTextButton = ({
    isATeen,
    handleTeenCheckInClick,
    createCheckInMutation,
  }) => {
    return (
      <IconButton
        variant="low-emphasis"
        size="sm"
        colorScheme="positive"
        borderRadius="full"
        borderWidth="1px"
        borderStyle="solid"
        onClick={isATeen ? handleTeenCheckInClick : createCheckInMutation}
        fontSize="lg"
        icon={VAssessmentIcon}
        aria-label={t("navigation.checkinBtn")}
      />
    );
  };

  const FullTextbutton = ({
    isATeen,
    handleTeenCheckInClick,
    createCheckInMutation,
  }) => {
    return (
      <ActionChip
        variant="low-emphasis"
        colorScheme="positive"
        icon={VAssessmentIcon}
        onClick={isATeen ? handleTeenCheckInClick : createCheckInMutation}
      >
        <Text size="body-medium-strong">{t("navigation.checkinBtn")}</Text>
      </ActionChip>
    );
  };

  const shouldHideText = useBreakpointValue({ base: true, xl: false });

  if (loading || !router) {
    return null;
  }

  return shouldHideText ? (
    <CircularNoTextButton
      isATeen={isATeen}
      handleTeenCheckInClick={handleTeenCheckInClick}
      createCheckInMutation={createCheckInMutation}
    />
  ) : (
    <FullTextbutton
      isATeen={isATeen}
      handleTeenCheckInClick={handleTeenCheckInClick}
      createCheckInMutation={createCheckInMutation}
    />
  );
};

TakeCheckinButton.propTypes = {
  addNotification: PropTypes.func,
};

export { TakeCheckinButton };
export default TakeCheckinButton;
