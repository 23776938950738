import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Heading, Text } from "design-system/components";
import { updateMember } from "operations/mutations/member";
import { useMutation } from "@apollo/client";
import { Badge } from "@springcare/sh-component-library";
import { VCircleCheckIcon } from "@springcare/verdant-icons-react";
import { trackMemberToggledWorkplaceAccess } from "./utils/MemberSettingsMixpanelAnalytics";
import { addNotification } from "@spring/smeargle/actions";
import { useDispatch } from "react-redux";

export const ConfigureWorkplaceAccess = ({ member }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "workplaceManager",
  });

  const initialWorkplaceManagerValue = member.workplace_manager;
  const [isWorkplaceEnabled, setIsWorkplaceEnabled] = useState(
    initialWorkplaceManagerValue,
  );

  const [updateMemberMutation] = useMutation(updateMember, {
    onCompleted: () =>
      dispatch(addNotification("Your choice was saved", "success")),
    onError: () =>
      dispatch(addNotification(t("notifications.genericError"), "error")),
  });

  const updateMemberWorkplaceManagerAccess = (value) => {
    updateMemberMutation({
      variables: {
        input: {
          id: member.id,
          patch: {
            workplace_manager: value,
          },
        },
      },
    });
  };

  const updateMemberWorkplaceAccess = (value) => {
    trackMemberToggledWorkplaceAccess(value);

    setIsWorkplaceEnabled(!isWorkplaceEnabled);
    updateMemberWorkplaceManagerAccess(value);
  };

  return (
    <section>
      <Box data-cy="configure" my={16}>
        <Flex alignItems="center">
          <Heading fontSize={20} fontWeight="bold" color="platform.900">
            {t("heading")}
          </Heading>
          <Badge ml={2} colorScheme="positive">
            {t("badge")}
          </Badge>
        </Flex>
        <Box
          px={24}
          py={12}
          mb={16}
          mt={16}
          borderWidth="1px"
          borderColor="platform.base"
          borderRadius="base"
        >
          <Flex justify="space-between">
            {isWorkplaceEnabled ? (
              <Text variant="body1" color="platform.700" fontWeight={700}>
                {t("textFieldPlaceholderCopy")}
              </Text>
            ) : (
              <Text
                variant="body1"
                color="content-secondary"
                fontWeight={700}
                aria-live="polite"
              >
                {t("textFieldPlaceholderCopy")}
              </Text>
            )}
            {isWorkplaceEnabled ? (
              <Flex align="center">
                <VCircleCheckIcon
                  aria-hidden={true}
                  height={18}
                  width={18}
                  color="#068262"
                />
                <Text
                  data-testid="workplaceAccessActive"
                  variant="body-medium-strong"
                  color="primary-base"
                  fontWeight={700}
                  marginLeft="10px"
                >
                  {t("workplaceEnabled")}
                </Text>
              </Flex>
            ) : (
              <Text
                data-testid="workplaceAccessActive"
                variant="body-medium-strong"
                color="content-secondary"
                fontWeight={700}
              >
                {t("workplaceDisabled")}
              </Text>
            )}
          </Flex>
        </Box>
        {isWorkplaceEnabled && (
          <Text variant="body1" color="platform.base" my={16}>
            {t("textFieldSubtext")}
          </Text>
        )}
        <Flex justify="flex-end">
          {
            <Button
              data-testid={
                isWorkplaceEnabled
                  ? "removeWorkplaceAccessButton"
                  : "addWorkplaceAccessButton"
              }
              aria-label={
                isWorkplaceEnabled
                  ? "Remove workplace access"
                  : "Enable workplace access"
              }
              aria-live="polite"
              variant="solid"
              colorScheme="primary"
              h="58"
              w="150px"
              onClick={
                isWorkplaceEnabled
                  ? () => updateMemberWorkplaceAccess(false)
                  : () => updateMemberWorkplaceAccess(true)
              }
            >
              {isWorkplaceEnabled
                ? t("removeAccessButton")
                : t("enableAccessButton")}
            </Button>
          }
        </Flex>
      </Box>
    </section>
  );
};
