import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import { assessmentToTake } from "operations/fragments/assessment";

const updateAssessment = gql`
  mutation UpdateAssessment($input: UpdateAssessmentInput!) {
    updateAssessment(input: $input) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default graphql(updateAssessment, { name: "updateAssessment" });
