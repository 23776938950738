import React from "react";

import { DedicatedCareIcon } from "@spring/smeargle";

import styles from "./styles.module.scss";

const OnsiteBadge = () => {
  return (
    <>
      <div className={styles.inPersonCare}>
        <span className={styles.inPersonIcon}>
          <DedicatedCareIcon ariaHidden />
        </span>
        <span className={styles.inPersonText}>Located at your workplace</span>
      </div>
    </>
  );
};

export default OnsiteBadge;
