import { gql } from "@apollo/client";

import paging from "operations/fragments/paging";

const getMembers = gql`
  query getMembers(
    $name_or_email: String
    $offset: Int
    $limit: Int
    $customer_id: String
    $created_after: Date
    $created_before: Date
  ) {
    members(
      name_or_email: $name_or_email
      offset: $offset
      limit: $limit
      customer_id: $customer_id
      created_after: $created_after
      created_before: $created_before
    ) {
      data {
        id
        created_at
        name
        phone
        country_code
        cohort {
          id
          name
          customer {
            id
            name
          }
        }
        user {
          id
          email
        }
        previsit {
          id
          status
        }
        proposed_grantor_email
        grantor {
          ... on Member {
            id
            first_name
            last_name
          }
          ... on CoveredLife {
            id
            first_name
            last_name
          }
        }
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default getMembers;
