import { gql } from "@apollo/client";

// See Member Payment Collection Tech Doc for spec
// https://springhealth.atlassian.net/wiki/spaces/RE/pages/1018429549/Member+Payment+Collections+-+Milestone+1+Technical+Document.#%F0%9F%92%B0-Billing-Tabs%3A
export const getMemberCoveredAppointments = gql`
  query getMemberCoveredAppointments(
    $member_id: ID!
    $limit: Int
    $offset: Int
    $end_timestamp: DateTime
  ) {
    member_covered_appointments(
      member_id: $member_id
      limit: $limit
      offset: $offset
      end_timestamp: $end_timestamp
    ) {
      paging {
        page
        pages
        limit
        total
      }
      data {
        appointment_id
        care_provider_avatar
        care_provider
        appointment_kind
        appointment_date
        attended_status
      }
    }
  }
`;
