//

import { graphql } from "@apollo/client/react/hoc";
import {
  assessments as assessmentConstants,
  getAssessmentKindForTracking,
} from "@spring/constants";
import Meowth from "@spring/meowth";
import { Fab, TextLoader } from "@spring/smeargle";
import { addNotification, removeFormField } from "@spring/smeargle/actions";
import { CompactPageHeader } from "@springcare/sh-component-library";
import { Box, FormControl } from "@chakra-ui/react";
import { Center, Spinner } from "design-system/components";
import {
  CloseBtn,
  LogoutBtnNew,
  NavHelpBtn,
} from "design-system/components/custom/headers/HeaderButtons";
import { get, getOr } from "lodash/fp";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Prompt from "components/assessments/Prompt";
import SubmitAssessment from "components/assessments/SubmitAssessment/SubmitAssessment";
import SubmitSprass from "components/assessments/SubmitSprass";
import styles from "./styles.module.scss";

import { elementKinds } from "@spring/constants/src/assessments";
import { logout, redirectToMemberHome } from "actions/auth";
import RiskBasedSuicidePreventionModal from "components/modals/SuicidePreventionModal/RiskBasedSuicidePreventionModal";
import { NewMemberGoalForm } from "components/organisms";
import {
  submitAssessmentResponses,
  updateAssessment,
} from "operations/mutations/assessment";
import { updateMember } from "operations/mutations/member";
import getSUDRisk from "operations/queries/benefits/getSUDRisk";
import { getMemberInfo } from "operations/queries/member";
import routes from "routes";
import { getFirstError } from "utils/apollo/errorHandler";
import AssessmentController from "utils/assessments";
import {
  formatPromptData,
  formatResponseData,
} from "utils/assessments/assessmentUtils";
import builtInPromptData from "utils/assessments/questionnaires";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getIterableCampaignInfo } from "utils/localStorage";
import { time_event, track, TRACK_EVENT } from "utils/mixpanel";

const { IssuesData, assessmentKinds, builtInPromptIds } = assessmentConstants;
const { goals, complete, confidence, sprassComplete } = builtInPromptData;

class Assessment extends Component {
  static propTypes = {
    addNotification: PropTypes.func,
    removeFormField: PropTypes.func,
    assessmentId: PropTypes.any,
    assessmentKind: PropTypes.string,
    assessmentVersion: PropTypes.number,
    currentQuestionnaire: PropTypes.shape({
      current_prompts: PropTypes.any,
      id: PropTypes.any,
      questionnaire_kind: PropTypes.any,
    }),
    data: PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.any,
      }),
    }),
    dispatch: PropTypes.any,
    formData: PropTypes.any,
    formKey: PropTypes.any,
    initialData: PropTypes.shape({
      Issues: PropTypes.any,
    }),
    isStatic: PropTypes.any,
    numPromptsAnswered: PropTypes.any,
    numPromptsTotal: PropTypes.any,
    order: PropTypes.shape({
      includes: PropTypes.func,
    }),
    questionnaires: PropTypes.array,
    showGoalsLast: PropTypes.bool,
    status: PropTypes.any,
    submitAssessmentResponses: PropTypes.func,
    sudRisk: PropTypes.shape({
      benefits: PropTypes.shape({
        sud_benefit: PropTypes.shape({
          initial_sud_risk: PropTypes.string,
          latest_sud_risk: PropTypes.string,
        }),
      }),
    }),
    t: PropTypes.func,
    updateAssessment: PropTypes.any,
    updateMember: PropTypes.func,
    helpClickBtnHandler: PropTypes.func,
    skipConfidence: PropTypes.bool,
  };

  assessment = new AssessmentController();
  prompts;

  static defaultProps = {
    questionnaires: [],
  };

  state = {
    isLoading: false,
    promptsAnswered: this.props.numPromptsAnswered,
    prevResponse: "",
    hasSeenInitialAnnouncement: false,
    isSuicideRiskModalOpen: false,
    suicideRisk: "",
    currentPrompts: [],
    questionnaireKind: {},
    skippedPromptIds: [],
    shouldSkipQuestionnaire: false,
    isAssessmentSubmitting: false,
  };

  componentDidMount() {
    window.onbeforeunload = () => {
      track("Assessment -- Dropoff", {
        "Assessment Kind": this.props.assessmentKind,
        "Assessment Id": this.props.assessmentId,
        assessment_version: this.props.assessmentVersion,
      });
    };

    time_event("Assessment -- Submitted", {
      assessment_version: this.props.assessmentVersion,
    });

    track("Assessment -- Started", {
      "Assessment Kind": this.props.assessmentKind,
      "Assessment Id": this.props.assessmentId,
      assessment_version: this.props.assessmentVersion,
    });

    // Scroll down on mobile devices that support it
    // makes sure questions are in view on mount.
    if (typeof window !== "undefined" && window.scrollBy) {
      if (typeof window.matchMedia === "undefined") {
        // eslint-disable-next-line no-console
        console.error("This browser does not support matchMedia");
      } else {
        const breakpoints = {
          sm: "(min-width : 540px)",
          md: "(min-width : 720px)",
          lg: "(min-width : 960px)",
          xlg: "(min-width : 1140px)",
        };

        const smallScreen = window.matchMedia(breakpoints.md);
        if (!smallScreen.matches) {
          // screen is not bigger than "smallScreen"
          // Screen won't scroll without timeout in some browsers
          setTimeout(() => {
            window.scrollBy({
              top: -30,
              behavior: "smooth",
            });
          }, 100);
        }
      }
    }

    const {
      questionnaires = [],
      assessmentId,
      updateAssessment,
      isStatic,
      assessmentKind,
      currentQuestionnaire,
      status,
      skipConfidence,
    } = this.props;

    this.assessment = new AssessmentController(
      questionnaires.length,
      currentQuestionnaire,
      assessmentId,
      isStatic,
      updateAssessment,
      assessmentKind,
      status,
      skipConfidence,
    );
    this.forceUpdate();
  }

  componentWillUnmount() {
    track("Assessment -- Closed", {
      "Assessment Kind": this.props.assessmentKind,
      "Assessment Id": this.props.assessmentId,
      assessment_version: this.props.assessmentVersion,
    });
  }

  handleError = (error) => {
    this.props.addNotification(this.props.t("notification.error"), "error");
    // eslint-disable-next-line no-console
    console.error(error);
  };

  submitConfidencePrompt = async () => {
    const memberId = get("data.user.member.id", this.props);

    try {
      const response = await this.props.updateMember({
        variables: {
          input: {
            id: memberId,
            patch: {
              optimism: +this.props.formData["q1-confidence"],
            },
          },
        },
        refetchQueries: [
          {
            query: getMemberInfo,
            variables: {
              id: this.props.data.user.id,
            },
          },
        ],
      });

      if (!response.data.updateMember.success) {
        const error = getFirstError(response.errors);
        return { error };
      }
      this.handleAdvance();
    } catch (err) {
      const error = getFirstError(err);
      return { error };
    }
  };

  currentQuestionnaireKind = () => {
    return Object.keys(this.state.questionnaireKind ?? {}).length > 0
      ? this.state.questionnaireKind
      : this.props.currentQuestionnaire?.questionnaire_kind;
  };

  submitPrompts = async (answeringPreviousPrompt) => {
    const {
      currentNode: { value },
    } = this.assessment;

    let formattedData;
    if (
      answeringPreviousPrompt ||
      this.currentQuestionnaireKind()?.label === "CSSRS7"
    ) {
      // Format data to submit a single prompt response to the backend
      formattedData = formatPromptData(this.props.formData, value);
    } else {
      const currentPrompts =
        this.state.currentPrompts.length > 0
          ? this.state.currentPrompts
          : this.props.currentQuestionnaire?.current_prompts;
      // Format data to submit prompt response(s) to the backend
      formattedData = formatResponseData(
        this.props.formData,
        currentPrompts,
        this.currentQuestionnaireKind(),
      );
    }

    // Submit the response(s) to the prompt(s) and get the next one(s) back
    try {
      this.showLoader();
      const submitResponse = await this.props.submitAssessmentResponses(
        value.questionnaire_id,
        formattedData,
        this.state.shouldSkipQuestionnaire,
      );
      const nextData =
        submitResponse.data && submitResponse.data.submitAssessmentResponses;
      const current_questionnaire =
        nextData.assessment && nextData.assessment.current_questionnaire;

      this.setState({
        suicideRisk:
          submitResponse?.data?.submitAssessmentResponses?.assessment
            ?.display_modal,
        currentPrompts: current_questionnaire?.current_prompts,
        questionnaireKind: current_questionnaire?.questionnaire_kind,
        shouldSkipQuestionnaire: false,
      });
      if (this.state.suicideRisk) {
        this.setState({
          isSuicideRiskModalOpen: true,
        });
      }

      const endingQuestionnaire = [...complete];
      if (!this.props.skipConfidence) {
        endingQuestionnaire.splice(0, 0, ...confidence);
      }

      if (!current_questionnaire) {
        // If no data on the current questionnaire, it means we're at the end of the assessment
        if (
          this.props.assessmentKind === assessmentKinds.INITIAL_ASSESSMENT &&
          this.props.showGoalsLast === true
        ) {
          endingQuestionnaire.splice(0, 0, ...goals);
          this.assessment.addPrompts(
            endingQuestionnaire,
            "ending_questionnaire",
          );
        } else if (
          this.props.assessmentKind === assessmentKinds.INITIAL_ASSESSMENT
        ) {
          this.assessment.addPrompts(
            endingQuestionnaire,
            "ending_questionnaire",
          );
        } else if (this.props.assessmentKind !== assessmentKinds.SPRASS) {
          this.assessment.addPrompts(complete, "ending_questionnaire");
        } else if (this.props.assessmentKind === assessmentKinds.SPRASS) {
          this.assessment.addPrompts(sprassComplete, "ending_questionnaire");
        }
      } else if (current_questionnaire.current_prompts.length > 0) {
        this.assessment.addPrompts(
          current_questionnaire.current_prompts,
          current_questionnaire.id,
          current_questionnaire.questionnaire_kind,
        );
      } else {
        const error =
          "Assessment has current_questionnaire but no current_prompts.";
        this.handleError(error);
        return { error };
      }
      return current_questionnaire;
    } catch (error) {
      return { error };
    }
  };

  handleAdvance = () => {
    this.forceUpdate(); // update first to populate the next prompt
    this.assessment.next();
    this.forceUpdate();
  };

  handleQuestionnaireSkip = () => {
    let skippedIds = [];
    this.setState({ shouldSkipQuestionnaire: true }, () => {
      while (this.assessment.currentNode.next) {
        if (
          this.assessment.currentNode.value.questionnaire_id !==
          this.props.currentQuestionnaire?.id
        ) {
          break;
        }
        this.handleAdvance();
        skippedIds.push(this.assessment.currentNode.value.id);
      }
      this.setState((prevState) => ({
        skippedPromptIds: [...prevState.skippedPromptIds, ...skippedIds],
      }));
      return this.advancePrompt(true);
    });
  };

  parseFormDataValue = (value) => {
    if (typeof value === "boolean") {
      return value ? 1 : 0;
    }

    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  getDependentElementResponse = (fieldKey, currentNodeValue, formData) => {
    if (!fieldKey || !currentNodeValue || !formData) {
      return null;
    }

    if (formData[fieldKey]) {
      return this.parseFormDataValue(formData[fieldKey]);
    }

    return null;
  };

  triggerConditionalNode = (conditions, elementResponses) => {
    if (!conditions || !elementResponses) {
      return false;
    }

    const { dependent_element_id, operator, score } = conditions;
    const dependentElementResponse = elementResponses[dependent_element_id];

    if (dependentElementResponse === undefined) {
      return false;
    }

    switch (operator) {
      case ">=":
        return dependentElementResponse >= score;
      case "<=":
        return dependentElementResponse <= score;
      case ">":
        return dependentElementResponse > score;
      case "<":
        return dependentElementResponse < score;
      case "==":
        return dependentElementResponse === score;
      case "!=":
        return dependentElementResponse !== score;
      default:
        return false;
    }
  };

  advancePrompt = async (isAnswering) => {
    const currentNode = this.assessment.currentNode;
    const currentNodeValue = currentNode.value;
    const nextNodeConditions =
      currentNode.next?.value?.questions?.[0]?.element?.show_conditions;
    this.setPreviousResponse(currentNodeValue);

    const fieldKey = getOr("", "questions[0].fieldKey", currentNodeValue);
    const { onAdvance, id } = currentNodeValue;

    this.trackAssessmentPromptAnswered(id, currentNodeValue);

    if (onAdvance) {
      onAdvance(this.props.formData, this.props.dispatch, fieldKey);
    }

    const answeringPreviousPrompt =
      isAnswering &&
      this.isPromptInPreviousQuestionnaire(this.assessment.currentNode);

    if (id === builtInPromptIds.CONFIDENCE) {
      return await this.handleConfidencePrompt();
    } else if (id === builtInPromptIds.GOALS) {
      this.handleAdvance();
    } else if (this.shouldSubmit(answeringPreviousPrompt)) {
      return await this.handlePromptSubmission(
        answeringPreviousPrompt,
        currentNodeValue,
      );
    } else if (nextNodeConditions) {
      return await this.handleNextNodeConditions();
    } else {
      this.handleAdvance();
      this.setState({ shouldSkipQuestionnaire: false });
    }
  };

  setPreviousResponse = (currentNodeValue) => {
    let didSetPrevResponse = false;

    if (
      currentNodeValue?.questions?.length === 1 &&
      currentNodeValue?.questions?.[0]?.element?.kind === elementKinds.RADIO
    ) {
      const question = currentNodeValue.questions[0];
      const key = question.fieldKey;
      const formData = this.props.formData || {};

      const options = question.element.options;
      if (formData[key] !== undefined && options) {
        const option = options.find((option) => option.value === formData[key]);
        const prevResponse = option ? option.label : "";
        didSetPrevResponse = true;

        this.setState({
          prevResponse: prevResponse,
          hasSeenInitialAnnouncement: true,
        });
      }
    }

    if (!didSetPrevResponse) {
      this.setState({
        prevResponse: "",
        hasSeenInitialAnnouncement: true,
      });
    }
  };

  trackAssessmentPromptAnswered = (id, currentNodeValue) => {
    if (id !== builtInPromptIds.CONFIDENCE) {
      const questionsString = this.getQuestionsString(currentNodeValue);
      TRACK_EVENT.ASSESSMENT_PROMPT_ANSWERED(
        routes.TakeInitialAssessment.as,
        "Member answered assessment prompt",
        {
          assessment_kind: getAssessmentKindForTracking(
            this.props.assessmentKind,
          ),
          questionnaire_kind: this.props.currentQuestionnaire?.kind,
          questions: questionsString,
          assessment_id: this.props.assessmentId,
          prompt_id: this.assessment?.currentNode?.value?.id,
          questionnaires_id: this.props.currentQuestionnaire?.id,
          assessment_version: this.props.assessmentVersion,
          promptTitle: this.assessment.currentNode?.value?.newSubtitle,
          promptSubtitle: this.assessment.currentNode?.value?.newTitle,
        },
      );
    }
  };

  handleConfidencePrompt = async () => {
    TRACK_EVENT.FORM_SUBMITTED(routes.TakeInitialAssessment.as, "Confidence", {
      assessment_version: this.props.assessmentVersion,
    });
    const response = await this.submitConfidencePrompt();
    if (response && response.error) {
      this.handleError(response.error);
      return response;
    }
  };

  handlePromptSubmission = async (
    answeringPreviousPrompt,
    currentNodeValue,
  ) => {
    const response = await this.submitPrompts(answeringPreviousPrompt);
    if (response && response.error) {
      this.handleError(response.error);
      return response;
    }

    this.trackQuestionnaireSubmissions(response, currentNodeValue);
    this.hideLoader();
    this.handleAdvance();
  };

  handleNextNodeConditions = async () => {
    let currentNode = this.assessment.currentNode;
    if (this.shouldTriggerConditionalNode()) {
      if (this.state.skippedPromptIds.includes(currentNode.next?.value?.id)) {
        this.setState({
          skippedPromptIds: this.state.skippedPromptIds.filter(
            (id) => id !== currentNode.next?.value?.id,
          ),
        });
      }
    } else {
      let skippedIds = [];

      while (
        this.assessment.currentNode.next?.value?.questions?.[0]?.element
          ?.show_conditions &&
        !this.shouldTriggerConditionalNode()
      ) {
        skippedIds.push(this.assessment.currentNode.next.value?.id);
        // handle the case where user decides to skip a conditional prompt that has multi-level dependency
        await Promise.all(
          this.deleteSkipQuestionFieldKey(
            this.assessment.currentNode.next?.value?.questions,
          ),
        );
        this.handleAdvance();
      }

      this.setState({
        skippedPromptIds: [...this.state.skippedPromptIds, ...skippedIds],
      });

      if (!this.assessment.currentNode.next) {
        return this.advancePrompt(false);
      }
    }
    this.handleAdvance();
  };

  deleteSkipQuestionFieldKey = (questions) => {
    return questions.map((question) =>
      this.props.dispatch(
        this.props.removeFormField(this.props.formKey, question.fieldKey),
      ),
    );
  };

  shouldTriggerConditionalNode = () => {
    const currentNode = this.assessment.currentNode;
    const nextPromptConditions =
      currentNode.next?.value?.questions?.[0]?.element?.show_conditions;

    if (nextPromptConditions) {
      const dependentElementId = nextPromptConditions?.dependent_element_id;
      const fieldKey = nextPromptConditions?.field_key;
      const dependentElementResponse = this.getDependentElementResponse(
        fieldKey,
        currentNode.value,
        this.props.formData,
      );

      return this.triggerConditionalNode(nextPromptConditions, {
        [dependentElementId]: dependentElementResponse,
      });
    }

    return false;
  };

  getQuestionsString = (currentNodeValue) => {
    const obj = currentNodeValue?.questions || {};
    return Object.keys(obj)
      .map((question) => obj[question].key)
      .toString()
      .replaceAll(",", ", ");
  };

  trackQuestionnaireSubmissions = (response, currentNodeValue) => {
    const currentQuestionnaireKind =
      this.props?.currentQuestionnaire?.questionnaire_kind;

    if (
      !["Issues", "Issues_v2", "AUDITC", "AUDIT", "DAST10"].includes(
        currentQuestionnaireKind?.label,
      )
    ) {
      return;
    }

    const hasQuestionnaireChanged =
      response?.id !== currentNodeValue?.questionnaire_id;

    const sudRisks = this.props?.sudRisk?.benefits?.sud_benefit;

    if (hasQuestionnaireChanged) {
      if (
        currentQuestionnaireKind?.label === "Issues_v2" ||
        currentQuestionnaireKind?.label === "Issues"
      ) {
        this.trackSUDIssues();
        return;
      }

      TRACK_EVENT.FORM_SUBMITTED(
        routes.TakeInitialAssessment.as,
        `${currentQuestionnaireKind?.label || ""} questionnaire submitted`,
        {
          assessment_version: this.props.assessmentVersion,
          initial_sud_risk: sudRisks.initial_sud_risk ?? "None",
          latest_sud_risk: sudRisks.latest_sud_risk ?? "None",
        },
      );
    }
  };

  trackSUDIssues() {
    const isSudEligible = Boolean(
      this.props.formData?.["Issues_alcohol"] ||
        this.props.formData?.["Issues_drugs"],
    );
    if (isSudEligible) {
      TRACK_EVENT.FORM_SUBMITTED(
        routes.TakeInitialAssessment.as,
        "Members issues have SUD eligibility",
      );
    }
  }

  shouldSubmit = (answeringPreviousPrompt) => {
    return (
      !this.assessment.currentNode.next ||
      answeringPreviousPrompt ||
      this.currentQuestionnaireKind()?.label === "CSSRS7"
    );
  };

  showLoader = () => {
    this.setState({ isLoading: true });
  };

  hideLoader = () => {
    this.setState({ isLoading: false });
  };

  hideModal = () => {
    this.setState({ isSuicideRiskModalOpen: false, suicideRisk: "" });
  };

  isPromptInPreviousQuestionnaire = (node) => {
    const { currentQuestionnaire } = this.props;
    if (!currentQuestionnaire) {
      return true;
    }
    return node.next && node.value.questionnaire_id !== currentQuestionnaire.id;
  };

  setIsAssessmentSubmitting = (isSubmitting) => {
    this.setState({ isAssessmentSubmitting: isSubmitting });
  };

  renderPrompt = (node, position, direction, active) => {
    if (node) {
      let kind = Prompt;

      if (node.next?.value?.id === "complete") {
        TRACK_EVENT.PAGE_VERSION_VIEWED(
          window.location.pathname,
          "Member viewed their last question",
          {
            assessmentKind: getAssessmentKindForTracking(
              this.props.assessmentKind,
            ),
            promptTitle: node.value?.newSubtitle,
            promptSubtitle: node.value?.newTitle,
            promptId: node.value?.id,
            questionnaireKind: this.props.currentQuestionnaire?.kind,
            questions: this.getQuestionsString(node.value),
          },
        );
      }

      if (node.value.id === "complete") {
        kind = SubmitAssessment;
      } else if (node.value.id === "goals") {
        kind = NewMemberGoalForm;
      } else if (node.value.id === "SPRASS--complete") {
        kind = SubmitSprass;
      }

      const activePromptLoading = this.state.isLoading && active;

      const promptElement = React.createElement(kind, {
        key: node.value.id,
        assessmentId: this.props.assessmentId,
        assessmentKind: this.props.assessmentKind,
        assessmentVersion: this.props.assessmentVersion,
        currentQuestionnaireKind: this.props.currentQuestionnaire?.kind,
        formKey: this.props.formKey,
        stackPosition: activePromptLoading ? "out" : position,
        direction: activePromptLoading ? "left" : direction,
        questionnaireData: this.props.questionnaires,
        questionnaireKind: this.props.currentQuestionnaire?.kind,
        questionsString: this.getQuestionsString(
          this.assessment?.currentNode?.value,
        ),
        advance: active ? this.advancePrompt : () => {},
        handleError: this.handleError,
        hasNext: !!node.next,
        active,
        activePromptLoading: activePromptLoading,
        showGoalsLast: this.props.showGoalsLast,
        handleQuestionnaireSkip: this.handleQuestionnaireSkip,
        isAssessmentSubmitting: this.state.isAssessmentSubmitting,
        setIsAssessmentSubmitting: this.setIsAssessmentSubmitting,
        ...node.value,
      });

      let firstAnnouncement = this.props.t("firstPromptAnnouncement");

      if (
        !this.state.hasSeenInitialAnnouncement &&
        firstAnnouncement === "firstPromptAnnouncement"
      ) {
        return (
          <Center>
            <Spinner speed="1s" size="xl" />
          </Center>
        );
      }

      let autoAdvanceMessage = "";
      if (
        !this.state.hasSeenInitialAnnouncement &&
        this.assessment.currentNode.prev === null
      ) {
        autoAdvanceMessage = firstAnnouncement;
      } else if (this.state.prevResponse !== "") {
        autoAdvanceMessage = this.props.t("afterRedirectAnnouncement", {
          response: this.state.prevResponse,
        });
      }

      return (
        <div>
          <div className={styles.hidden} role={"alert"}>
            {autoAdvanceMessage}
          </div>
          <div
            ref={(prompts) => {
              this.prompts = prompts;
            }}
          >
            {promptElement}
          </div>
        </div>
      );
    }

    return <div />;
  };

  get activePrompt() {
    if (this.assessment) {
      return this.renderPrompt(this.assessment.currentNode, "in", null, true);
    }

    return null;
  }

  handlePrev = () => {
    let skippedIdsToRemove = [];
    while (
      this.assessment.currentNode.prev &&
      this.state.skippedPromptIds.includes(
        this.assessment.currentNode.prev?.value?.id,
      )
    ) {
      this.assessment.prev();
      skippedIdsToRemove.push(this.assessment.currentNode.value.id);
    }

    const prevPrompt = this.assessment?.currentNode?.prev?.value;
    if (prevPrompt != null) {
      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        "Member clicked back button",
        {
          prompt_id: prevPrompt.id,
          questionnaire_id: prevPrompt.questionnaire_id,
          questionnaire_kind:
            this.props?.currentQuestionnaire?.questionnaire_kind?.label,
          questions: prevPrompt.questions.map((question) => question.key),
          promptTitle: prevPrompt.newSubtitle,
          promptSubtitle: prevPrompt.newTitle,
          assessmentKind: getAssessmentKindForTracking(
            this.props.assessmentKind,
          ),
        },
      );
    }

    this.assessment.prev();
    this.forceUpdate();
    this.setState((prevState) => ({
      prevResponse: "",
      skippedPromptIds: prevState.skippedPromptIds.filter(
        (id) => !skippedIdsToRemove.includes(id),
      ),
      shouldSkipQuestionnaire: false,
    }));
  };

  get prev() {
    return (
      <Fab
        alt={this.props.t("goToPreviousQuestion")}
        ariaLabel={this.props.t("goToPreviousQuestion")}
        secondary
        square
        disabled={!this.assessment.currentNode.prev}
        onClick={() => {
          this.handlePrev();
          track("Assessment -- Back Bottom Navigation", {
            assessment_version: this.props.assessmentVersion,
          });
        }}
        icon="arrow-left"
        size="lg"
        color="success"
      />
    );
  }

  get next() {
    const valid = getOr(
      true,
      `prompts.${this.assessment.currentNode.value.id}.valid`,
      this.props,
    );

    return (
      <Fab
        alt={this.props.t("goToNextQuestion")}
        ariaLabel={this.props.t("goToNextQuestion")}
        secondary
        square
        disabled={!valid || !this.assessment.currentNode.next}
        onClick={() => {
          this.advancePrompt(false);
          track("Assessment -- Next Bottom Navigation");
        }}
        icon="arrow-right"
        size="lg"
        color="success"
      />
    );
  }

  get initialData() {
    if (this.props.order && this.props.order.includes("Issues")) {
      const initialData = Object.assign(this.props.initialData); // clone obj
      const issues = Object.assign(IssuesData, this.props.initialData.Issues); // combine issues, preserving any data

      initialData.Issues = issues;

      return initialData;
    }

    return this.props.initialData;
  }

  get activePromptHeight() {
    if (!this.prompts || !this.prompts.children) {
      return { height: 500 };
    }

    const shadowOffset = 16; // Offset to make sure we can still see the shadow with `overflow: hidden`

    /**
     * Get the height of the MIDDLE prompt, since that is the one in view.
     *
     *                  get this height
     *                        vvv
     * [ off screen ] [ Current Prompt ] [ off screen ]
     */
    return { height: this.prompts.children[0].offsetHeight + shadowOffset };
  }

  get promptsAnswered() {
    if (this.state.promptsAnswered === null) {
      return this.props.numPromptsTotal;
    }
    return this.state.promptsAnswered + this.assessment.currentIndex;
  }

  get isAssessmentCheckIn() {
    return this.props.assessmentKind === "GOAL_CHECK_IN";
  }

  get headerActions() {
    const isCheckInAssessment = this.isAssessmentCheckIn;
    return [
      isCheckInAssessment ? (
        <Box
          w={40}
          h={40}
          borderRadius={10}
          backgroundColor={"tertiary.light"}
          _focus={{ border: "2px solid", borderColor: "primary.base" }}
        >
          <CloseBtn closeTo={"MemberHome"} />
        </Box>
      ) : (
        <LogoutBtnNew key="nav-logout-button" />
      ),
      <NavHelpBtn
        key="nav-help-button"
        onClick={this.props.helpClickBtnHandler}
      />,
    ];
  }

  get headerActionsMobile() {
    const isCheckInAssessment = this.isAssessmentCheckIn;

    const actions = {
      help: {
        label: `${this.props.t(["limitedLangMemberHelpCard:helpButtonText", "Help"])}`,
        onClick: this.props.helpClickBtnHandler,
      },
    };

    if (isCheckInAssessment) {
      actions.close = {
        label: `${this.props.t(["common:navigation.links.exit", "Exit"])}`,
        onClick: () => {
          redirectToMemberHome();
        },
      };
    } else {
      actions.logout = {
        label: `${this.props.t(["common:navigation.links.logout", "Logout"])}`,
        onClick: () => {
          logout();
        },
      };
    }

    return actions;
  }

  onBackClick = () => {
    this.handlePrev();
    track("Assessment -- Back Navigation", {
      assessment_version: this.props.assessmentVersion,
    });
  };

  shouldShowBackButton = () => {
    return this.assessment.currentNode.prev
      ? !this.state.isAssessmentSubmitting
      : false;
  };

  render() {
    if (this.assessment.currentNode) {
      return (
        <div style={{ position: "relative", width: "100%" }}>
          <Box zIndex={10} position="relative">
            <CompactPageHeader
              backstack={this.shouldShowBackButton()}
              backstackVariant="sm-med-emphasis"
              onBackClick={this.onBackClick}
              progressBar={true}
              actions={this.headerActions}
              actionsMobile={this.headerActionsMobile}
              backstackAriaLabel="back button"
              pageTitleAriaLabel="page title"
              progressBarAriaLabel="progress bar"
              progressBarMax={this.props.numPromptsTotal}
              progressBarCurrent={this.promptsAnswered}
              headerState="scrolled"
            />
          </Box>

          <div
            className={styles.assessmentWrapper}
            id="main-content"
            tabIndex={-1}
            data-cy="assessment"
            data-cy-assessment-version={this.props.assessmentVersion}
          >
            <RiskBasedSuicidePreventionModal
              isOpen={this.state.isSuicideRiskModalOpen}
              closeModalCallback={() => this.hideModal()}
              variant={this.state.suicideRisk}
            />

            <div className={styles.promptWrapper}>
              <FormControl>
                <div className={styles.prompts}>
                  <div>{this.activePrompt}</div>
                  {this.state.isLoading && (
                    <TextLoader
                      text={[this.props.t("loaderText")]}
                      writeTimeout={50}
                      whiteBg
                    />
                  )}
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  prompts: get("prompts", state.assessment),
  formData: getOr({}, `${ownProps.formKey}.data`, state.form),
});

const mapDispatchToProps = (dispatch) => ({
  addNotification,
  removeFormField,
  dispatch,
});

export { Assessment };

const AssessmentContainer = (props) => {
  const skipConfidence = useFeatureFlag(FLAGS.REMOVE_CONFIDENCE);
  return <Assessment skipConfidence={skipConfidence} {...props} />;
};

export default compose(
  graphql(submitAssessmentResponses, {
    props: ({ mutate }) => ({
      submitAssessmentResponses: (
        questionnaire_id,
        prompt_responses,
        skipped,
      ) => {
        return mutate({
          variables: {
            questionnaire_id,
            prompt_responses,
            skipped,
            campaign: getIterableCampaignInfo(),
          },
        });
      },
    }),
  }),
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(getSUDRisk, {
    options: (props) => ({ variables: { id: props?.data?.user?.member?.id } }),
    props: ({ data }) => ({
      sudRisk: data,
    }),
  }),
  graphql(updateMember, { name: "updateMember" }),
  updateAssessment,
  connect(mapStateToProps, mapDispatchToProps),
)(withTranslation("assessments")(AssessmentContainer));
