// 

import React from 'react';

import {
	Section, Backdrop, Grid, Col,
} from '../../layout';
import { Icon } from '../../atoms';

import styles from './styles.module.scss';


const Banner = (props) => (
	<Section>
		<Backdrop color={props.color}>
			<div className={styles.container}>
				<Grid gutter="2px 6px" alignment="center">
					{props.icon && <Col size={1}>
						<div className={styles.icon}>
							<Icon type={props.icon} />
						</div>
					</Col>}
					<Col size={ props.icon ? 11 : 12}>
						{props.text}
					</Col>
				</Grid>
			</div>
		</Backdrop>
	</Section>
);

export default Banner;
