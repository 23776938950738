import {
  MedicationManagerRole,
  ProviderRole,
  getProviderRoleForTracking,
} from "@spring/constants";

const MedicationManagerSchedulableProviderRole = getProviderRoleForTracking(
  ProviderRole.MedicationManager,
);
const TherapistSchedulableProviderRole = getProviderRoleForTracking(
  ProviderRole.Therapist,
);

export const getSchedulableProviderRole = (careProvider) => {
  const [role] = careProvider?.roles || "";
  const possibleMedicationManagerRoles = Object.values(MedicationManagerRole);

  if (possibleMedicationManagerRoles.includes(role)) {
    return MedicationManagerSchedulableProviderRole;
  }

  return role || TherapistSchedulableProviderRole;
};
