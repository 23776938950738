import React, { useEffect, useState } from "react";
import {
  Button,
  ModalSheet,
  ModalSheetProps,
} from "@springcare/sh-component-library";
import {
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
  VStack,
  ImageProps,
} from "@chakra-ui/react";
import { VCelebrationConfettiIcon } from "@springcare/verdant-icons-react";
import { useRouter } from "next/router";

import { useTranslation } from "hooks/react-i18next";
import {
  trackCoachingCarePopupLoaded,
  trackCoachingCarePopupCloseButtonClicked,
  trackCoachingCarePopupButtonClicked,
  trackTherapyCarePopupLoaded,
  trackTherapyCarePopupCloseButtonClicked,
  trackTherapyCarePopupButtonClicked,
} from "modules/MemberDashboard/Moments/components/analytics";

// Moments Experiment #4: https://springhealth.atlassian.net/browse/MXE-679
export const CarePopupExperimentModal = ({
  isCoachingTreatmentMember,
  isTherapyTreatmentMember,
  remainingSessions,
  setModalViewedThisSession,
  isOpen,
  onClose,
  totalCompletedExercises,
}) => {
  const { t } = useTranslation("moments");
  const router = useRouter();
  const [viewedOnce, setViewedOnce] = useState(false);

  useEffect(() => {
    if (!isOpen || viewedOnce) return;

    if (isCoachingTreatmentMember) {
      trackCoachingCarePopupLoaded(totalCompletedExercises);
      setViewedOnce(true);
    }

    if (isTherapyTreatmentMember) {
      trackTherapyCarePopupLoaded(totalCompletedExercises);
      setViewedOnce(true);
    }
  }, [isCoachingTreatmentMember, isTherapyTreatmentMember, isOpen]);

  const IMAGE_CONFIG: ImageConfig = isCoachingTreatmentMember
    ? {
        src: "/static/coaches.png",
        alt: "A man looking through a window",
        width: IMAGE_WIDTH,
        maxHeight: MAX_IMAGE_HEIGHT,
        minHeight: MIN_IMAGE_HEIGHT,
        objectFit: "cover",
      }
    : {
        src: "/static/care_banner_therapy.png",
        alt: "A man sitting at a table with a laptop",
        width: IMAGE_WIDTH,
        maxHeight: MAX_IMAGE_HEIGHT,
        minHeight: MIN_IMAGE_HEIGHT,
        objectFit: "cover",
      };

  const modalPlacement: ModalSheetProps["placement"] = useBreakpointValue([
    "bottom",
    null,
    "center",
  ]);

  const modalSize: ModalSheetProps["size"] = useBreakpointValue([
    "sm",
    null,
    "lg",
  ]);

  const headingSize: string = useBreakpointValue([
    "heading-small",
    null,
    "heading-medium",
  ]);

  const buttoni18nKey = isCoachingTreatmentMember
    ? "carePopup.findACoach"
    : "carePopup.findATherapist";

  const headingi18nKey = isCoachingTreatmentMember
    ? "carePopup.coachingHeading"
    : "carePopup.therapyHeading";

  const subheadingi18nKey = isCoachingTreatmentMember
    ? "carePopup.coachingSubheading"
    : "carePopup.therapySubheading";

  const remainingSessionsCountCopy =
    remainingSessions === "unlimited"
      ? t("carePopup.unlimited")
      : remainingSessions;

  const remainingSessionsi18nKey =
    remainingSessions === 1
      ? "carePopup.oneRemainingSessionSubheading"
      : "carePopup.remainingSessionsSubheading";

  const handleCloseClick = () => {
    setModalViewedThisSession();
    onClose();
    isCoachingTreatmentMember
      ? trackCoachingCarePopupCloseButtonClicked(totalCompletedExercises)
      : trackTherapyCarePopupCloseButtonClicked(totalCompletedExercises);
  };

  const handleBrowseClick = () => {
    setModalViewedThisSession();
    onClose();
    isCoachingTreatmentMember
      ? trackCoachingCarePopupButtonClicked(totalCompletedExercises)
      : trackTherapyCarePopupButtonClicked(totalCompletedExercises);
    isCoachingTreatmentMember
      ? router.push("/browse/coaches")
      : router.push("/members/recommendations");
  };

  return (
    <ModalSheet
      placement={modalPlacement}
      size={modalSize}
      isOpen={isOpen}
      onClose={handleCloseClick}
      footerChildren={
        <Button
          mb={["v-32", null, "v-20"]}
          _focus={{}}
          onClick={handleBrowseClick}
        >
          {t(buttoni18nKey)}
        </Button>
      }
    >
      <VStack spacing="v-16">
        <Flex>
          <Image
            borderRadius="16px"
            mb={["v-24", null, "v-40"]}
            {...IMAGE_CONFIG}
          />
        </Flex>
        <VStack
          alignItems={["baseline", "center"]}
          pb="v-12"
          sx={{ "&&": { m: "v-none" } }}
        >
          <Heading size={headingSize} sx={{ "&&": { m: "v-none" } }} pb="v-8">
            {t(headingi18nKey)}
          </Heading>
          <Text sx={{ "&&": { m: "v-none" } }} pb={["v-16", null, "v-8"]}>
            {t(subheadingi18nKey)}
          </Text>
          {remainingSessions && (
            <Flex sx={{ "&&": { m: "v-none" } }}>
              <VCelebrationConfettiIcon
                width={24}
                height={24}
                aria-label="Celebration confetti icon"
              />
              <Text
                justifyContent="center"
                pl="v-8"
                sx={{ "&&": { m: "v-none" } }}
              >
                {remainingSessionsCountCopy}
                {t(remainingSessionsi18nKey)}
              </Text>
            </Flex>
          )}
        </VStack>
      </VStack>
    </ModalSheet>
  );
};

const IMAGE_WIDTH = "440px";
const MAX_IMAGE_HEIGHT = "248px";
const MIN_IMAGE_HEIGHT = "184px";

interface ImageConfig {
  src: string;
  alt: string;
  maxHeight?: string;
  minHeight?: string;
  width?: string;
  objectFit?: ImageProps["objectFit"];
}
