import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const getAssessmentToTake = gql`
  query getAssessment($id: ID!) {
    assessment(id: $id) {
      ...assessmentToTake
    }
  }
  ${assessmentToTake}
`;

export default getAssessmentToTake;
