import { datadogRum } from "@datadog/browser-rum";
import { ErrorBody } from "pages/_error";
import React from "react";
import { TRACK_EVENT } from "utils/mixpanel";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Updated state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    TRACK_EVENT.ERROR(window.location.pathname, "Error Boundary");

    const renderingError = new Error(error.message);
    renderingError.name = "ReactRenderingError";
    renderingError.stack = info.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <ErrorBody heading={"Oops!"} status={"An error occurred"} />;
    }
    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
