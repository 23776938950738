import { graphql } from "@apollo/client/react/hoc";
import React from "react";
import PropTypes from "prop-types";
import { Badge } from "@spring/smeargle";
import { getOr } from "lodash/fp";
import Meowth from "@spring/meowth";

import { useTranslation } from "react-i18next";

import { Link } from "components/atoms";
import { Bell } from "components/templates/MemberDashboard/Icons";

import styles from "./styles.module.scss";

import { getMemberInfo } from "operations/queries/member";

const NotificationsBell = (props) => {
  const { t } = useTranslation("a11y");

  const getCount = () => {
    if (!props.data.loading) {
      const { family_todo_count } = getOr(
        0,
        "data.user.member.experience_state",
        props,
      );
      const todos = getOr([], "data.user.member.todos", props);

      if (Array.isArray(todos) && family_todo_count > 0) {
        return todos.length - 1;
      }

      if (Array.isArray(todos)) {
        return todos.length;
      }

      return 0;
    }

    return 0;
  };

  const count = getCount();
  const content = (
    <div className={props.link ? styles.wrapperWithLink : styles.wrapper}>
      <Bell componentFrom={props.componentFrom} count={count} />
      <div className={styles.badge}>
        <Badge small hideEmpty count={count} />
      </div>
    </div>
  );

  if (!props.link) {
    return content;
  }

  return (
    // This link navigates to the home page of the member dashboard (if not there already)
    // and automatically scrolls to the 'Things to do' section.
    <Link
      alias="MemberDashboard"
      queries={{ scrollTo: "todo" }}
      mpTracking={{
        pageName: "Top Navigation",
        linkName: "Notifications Bell Icon",
      }}
      ariaLabel={t("notificationBellLabel", { notifCount: count })}
    >
      {content}
    </Link>
  );
};

NotificationsBell.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool,
    user: PropTypes.shape({
      member: PropTypes.shape({
        todos: PropTypes.arrayOf(PropTypes.any),
        experience_state: PropTypes.shape({
          family_todo_count: PropTypes.number,
        }),
      }),
    }),
  }),
  link: PropTypes.bool,
  componentFrom: PropTypes.string,
};

export default graphql(getMemberInfo, {
  options: Meowth.apolloOptionsUserId,
})(NotificationsBell);
