import { gql } from "@apollo/client";

const getSUDRisk = gql`
  query getSUDRisk($id: ID!) {
    benefits(id: $id) {
      sud_benefit {
        initial_sud_risk
        latest_sud_risk
      }
    }
  }
`;

export default getSUDRisk;
