import { useQuery } from "@apollo/client";
import { useMemberInfo } from "hooks";
import { getMemberBalance } from "modules/MemberDashboard/Billing/graphql/queries";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export function useMemberBalance(
  memberId?: string,
  variables = {},
  fetchPolicy?: WatchQueryFetchPolicy,
) {
  const { data: memberData } = useMemberInfo({ skip: memberId != undefined });
  const memberPaymentCollectionLDFlag = useFeatureFlag(
    FLAGS.MEMBER_PAYMENT_COLLECTION_M1,
  );

  return useQuery(getMemberBalance, {
    variables: {
      member_id: memberId || memberData?.user?.member?.id,
      ...variables,
    },
    // skip if no apero record ide from member data
    skip:
      (!memberId &&
        !memberData?.user?.member?.id &&
        !memberData?.user?.member?.apero_record_id) ||
      memberPaymentCollectionLDFlag === false,
    fetchPolicy: fetchPolicy,
  });
}
