//

const confidence = [
  {
    id: "confidence_prompt",
    questions: [
      {
        key: "q1-confidence",
        element: { kind: "CONFIDENCE" },
        id: "confidence",
        fieldKey: "q1-confidence",
        required: true,
      },
    ],
  },
];

export default confidence;
