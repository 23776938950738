//

import { Bolded, Section, FlexRow } from "@spring/smeargle";

const introduction = [
  {
    id: "introduction--1",
    title: "The Assessment",
    subtitle: (
      <>
        <div>
          In order to create a personalized experience in Spring Health, we have
          put together a set of research-proven questions to measure your mental
          health today. This personalized assessment will take ~5 minutes to
          complete.
        </div>
        <Section size="md">
          <FlexRow>
            <Bolded>Time to Complete:</Bolded> 5 mins
          </FlexRow>
        </Section>
      </>
    ),
    nextText: "Let's begin!",
  },
];

export default introduction;
