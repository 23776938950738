import Head from "next/head";
import { Heading } from "@chakra-ui/react";
import { Avatar } from "@springcare/sh-component-library";
import {
  VTherapyDuotoneSpot,
  VWarningIcon,
} from "@springcare/verdant-icons-react";
import { useTranslation } from "react-i18next";

interface NoShowHeaderProps {
  showAutoCancelPolicy: boolean;
}

export const NoShowHeader: React.FC<
  React.PropsWithChildren<NoShowHeaderProps>
> = ({ showAutoCancelPolicy }) => {
  const { t } = useTranslation(["careVisits"]);
  return (
    <>
      <Head>
        <title>{t("noShowlandingPage.title")}</title>
      </Head>
      {showAutoCancelPolicy ? (
        <Avatar size="2xl" colorScheme="warning" icon={VWarningIcon} />
      ) : (
        <VTherapyDuotoneSpot />
      )}
      <Heading as="h1" size="heading-medium">
        {t(
          `noShowlandingPage.${showAutoCancelPolicy ? "autoCancelTitle" : "friendlyReminder"}`,
        )}
      </Heading>
    </>
  );
};
