export const ExerciseIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6162 3.34894C10.7537 3.13169 10.9929 3 11.25 3H15C15.8284 3 16.5 3.67157 16.5 4.5V5.25C16.5 5.66421 16.1642 6 15.75 6C15.3358 6 15 5.66421 15 5.25V4.5H12.4352L13.8938 7.57942C14.1293 7.52742 14.3739 7.5 14.625 7.5C16.489 7.5 18 9.01104 18 10.875C18 12.739 16.489 14.25 14.625 14.25C12.761 14.25 11.25 12.739 11.25 10.875C11.25 9.7987 11.7538 8.84007 12.5385 8.22208L12.1964 7.5H8.50994L10.0589 10.9422C10.2289 11.32 10.0605 11.764 9.68277 11.9339C9.30504 12.1039 8.86104 11.9355 8.69106 11.5578L6.95035 7.68952L6.03931 8.80301C6.4847 9.37491 6.75 10.094 6.75 10.875C6.75 12.739 5.23896 14.25 3.375 14.25C1.51104 14.25 0 12.739 0 10.875C0 9.01104 1.51104 7.5 3.375 7.5C3.91531 7.5 4.42596 7.62697 4.87875 7.85269L6.16953 6.27507C6.19963 6.23829 6.23287 6.20477 6.26871 6.17478L5.51506 4.5H3.75C3.33579 4.5 3 4.16421 3 3.75C3 3.33579 3.33579 3 3.75 3H6C6.29513 3 6.56283 3.17309 6.68394 3.44223L7.83494 6H11.4859L10.5722 4.07106C10.4621 3.83872 10.4788 3.56619 10.6162 3.34894ZM3.88306 9.06965C3.72148 9.02427 3.55108 9 3.375 9C2.33947 9 1.5 9.83947 1.5 10.875C1.5 11.9105 2.33947 12.75 3.375 12.75C4.41053 12.75 5.25 11.9105 5.25 10.875C5.25 10.5668 5.17566 10.2761 5.04394 10.0196L3.95547 11.3499C3.69317 11.6705 3.22066 11.7178 2.90007 11.4555C2.57949 11.1932 2.53224 10.7207 2.79453 10.4001L3.88306 9.06965ZM13.9472 11.1961L13.2115 9.643C12.9241 9.97249 12.75 10.4034 12.75 10.875C12.75 11.9105 13.5895 12.75 14.625 12.75C15.6605 12.75 16.5 11.9105 16.5 10.875C16.5 9.83947 15.6605 9 14.625 9C14.6056 9 14.5864 9.00029 14.5671 9.00088L15.3028 10.5539C15.4801 10.9283 15.3204 11.3755 14.9461 11.5528C14.5717 11.7301 14.1245 11.5704 13.9472 11.1961Z"
        fill="#171E22"
      />
    </svg>
  );
};
