import React from 'react';

const SkipForwardIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M11.6102 1.2811C11.2131 1.665 11.2025 2.29808 11.5864 2.69511L11.6799 2.79183C7.38915 2.92869 3.51171 5.84025 2.34322 10.2011C0.913806 15.5357 4.07963 21.0191 9.41429 22.4485C14.749 23.8779 20.2323 20.7121 21.6617 15.3775C22.5593 12.0275 21.644 8.61935 19.5216 6.19522C19.1578 5.77969 18.526 5.73775 18.1105 6.10155C17.695 6.46536 17.653 7.09713 18.0168 7.51266C19.7183 9.45607 20.4475 12.1816 19.7299 14.8598C18.5864 19.1276 14.1997 21.6602 9.93193 20.5167C5.6642 19.3731 3.13154 14.9864 4.27508 10.7187C5.24891 7.08432 8.57475 4.70824 12.1762 4.78929L11.5408 5.40362C11.1438 5.78752 11.1331 6.4206 11.517 6.81764C11.9009 7.21467 12.534 7.22532 12.931 6.84142L15.027 4.81484C15.424 4.43094 15.4347 3.79786 15.0508 3.40082L13.0242 1.30489C12.6403 0.90785 12.0072 0.8972 11.6102 1.2811Z" fill="black"/>
		<path d="M8.89662 9.42403C8.89662 10.0177 8.62949 10.2212 8.12195 10.2212H7.59647C7.26705 10.2212 7 10.4882 7 10.8177V10.8177C7 11.1471 7.26705 11.4141 7.59647 11.4141H8.89662V16.168C8.89662 16.5619 9.21595 16.8813 9.60986 16.8813V16.8813C10.0038 16.8813 10.3231 16.5619 10.3231 16.168V9.71324C10.3231 9.31933 10.0038 9 9.60986 9H9.32065C9.08647 9 8.89662 9.18984 8.89662 9.42403V9.42403Z" fill="black"/>
		<path d="M14.7086 17C17.126 17 17.4062 14.6323 17.4062 12.9721C17.4062 11.4011 17.1535 9 14.7031 9C12.2417 9 12 11.4123 12 12.9666C12 14.5989 12.2582 17 14.7086 17ZM14.7086 15.5794C13.6647 15.5794 13.4944 14.3705 13.4944 13.0167C13.4944 11.6072 13.6647 10.4318 14.7031 10.4318C15.7195 10.4318 15.9118 11.5794 15.9118 13.0167C15.9118 14.4039 15.7195 15.5794 14.7086 15.5794Z" fill="black"/>
	</svg>
);

export default SkipForwardIcon;
