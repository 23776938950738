import { Card, Avatar } from "@springcare/sh-component-library";
import { Flex, Box, Text } from "@chakra-ui/react";
import { VArrowRightIcon } from "@springcare/verdant-icons-react";

const CardPseudostateStyling = {
  _hover: { background: "accent-subtle", borderColor: "accent-sutble" },
  _active: { background: "accent-subtle", borderColor: "accent-sutble" },
  _focus: { background: "accent-subtle", borderColor: "accent-sutble" },
};

interface InteractableCardProps {
  cardText: string;
  cardSubtext: string;
  primaryIcon?: (props) => JSX.Element;
  handleClickCallback?: () => void;
}

export const InteractableCard = ({
  cardText,
  cardSubtext,
  primaryIcon: PrimaryIcon,
  handleClickCallback,
}: InteractableCardProps): JSX.Element => {
  return (
    <Card
      as="button"
      my={[24, 16]}
      padding="v-16"
      variant="medium-emphasis"
      borderColor="background-subtle"
      borderRadius="v-lg"
      background="background-subtle"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      {...CardPseudostateStyling}
      onClick={handleClickCallback}
    >
      <>
        <Flex>
          {PrimaryIcon && (
            <Box minWidth="27px">
              <PrimaryIcon
                height={48}
                width="27px"
                mx={16}
                aria-hidden="true"
              />
            </Box>
          )}
          <Flex flexDirection="column" mx={16} width={["100%", "auto", "auto"]}>
            <Text textAlign="left" size="body-medium-strong">
              {cardText}
            </Text>
            <Text textAlign="left" size="body-medium-regular">
              {cardSubtext}
            </Text>
          </Flex>
        </Flex>
        <Avatar
          display={["none", "flex"]}
          alignItems="center"
          icon={VArrowRightIcon}
          size="lg"
          color="content-primary"
          background="transparent"
        />
      </>
    </Card>
  );
};
