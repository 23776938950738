/**
 * NOTE: @param are props
 * @param {boolean}    isOpen         Important - captures the opened state of the flyout
 * @param {function}   onOpen         Important - function that controls opening flyout
 * @param {function}   onClose        Important - function that controls closing flyout
 * @param {string}     [size]         Controls the flyout size
 * @param {string}     headerText     Text value for <FlyoutHeader>

 * NOTE: Renders a chakra Drawer/ SH Flyout with a string prop for the Header and children for Body copy
*/

import {
  Flyout,
  FlyoutBody,
  FlyoutHeader,
  FlyoutOverlay,
  FlyoutContent,
  FlyoutCloseButton,
  useMediaQuery,
} from "design-system/components";
import NewMemberHelpCard from "components/organisms/NewMemberHelpCard/NewMemberHelpCard";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const SHFlyout = ({
  isOpen,
  onClose,
  onOpen,
  size,
  headerText,
  children,
  isMemberHelpFlyout = false,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { i18n } = useTranslation();
  const dir = i18n.dir(i18n.language);
  const newHelpFlyout =
    useFeatureFlag(FLAGS.NEW_HELP_FLYOUT_EXPERIENCE) && isMemberHelpFlyout;

  return (
    <Flyout
      size={isMobile ? "xl" : size}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement={dir === "ltr" ? "right" : "left"}
      trapFocus={
        typeof window !== "undefined" && window?.zESpringIsOpen !== true
      }
    >
      <FlyoutOverlay bg="rgba(232, 234, 228, 0.4)" />
      <FlyoutContent data-testid="sh-flyout">
        {newHelpFlyout ? (
          <NewMemberHelpCard closeFlyout={onClose} />
        ) : (
          <>
            <FlyoutCloseButton
              data-testid="sh-flyout-close-btn"
              data-cy="close-btn"
              variant="outline"
              mt={isMobile ? 12 : 24}
              onTouchEnd={onClose}
            />
            <FlyoutHeader
              color={"#6a6a6a"}
              fontSize={24}
              px={24}
              py={isMobile ? 20 : 30}
              mb={16}
              boxShadow="md"
              fontWeight={500}
              as={"h2"}
            >
              {headerText}
            </FlyoutHeader>
            <FlyoutBody p={0} mx={0}>
              {children}
            </FlyoutBody>
          </>
        )}
      </FlyoutContent>
    </Flyout>
  );
};

SHFlyout.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  headerText: PropTypes.string.isRequired,
};
