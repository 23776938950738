// 
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';

import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';
import Stout from '../../typography/Stout';

import styles from './styles.module.scss';

import { formFieldSelector } from '../../../selectors/form';
import { setField } from '../../../actions/form';



class Checkbox extends Component {
	handleChange = (e) => {
		this.props.setField(
			this.props.formKey,
			this.props.fieldKey,
			e.target.checked,
			true
		);
	};

	get sanitizeLabel() {
		if (typeof this.props.label === 'string') {
			return this.props.label ? this.props.label.replace(/ /g, '_').replace(/[^\w\d]/g, '') : '';
		}

		return 'label';
	}

	get label() {
		if (this.props.label) {
			return (
				<Label
					for={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel}`}
					disabled={this.props.disabled}
					formKey={this.props.formKey}
					fieldKey={this.props.fieldKey}
					theme="simple"
					bolded={this.props.bolded}
					required={this.props.required}
				>
					{this.props.label}
				</Label>
			);
		}

		return null;
	}

	get showValidation() {
		// show if the field has an error and is dirty
		return !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);
	}
	
	get errors() {
		const status = !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);
		if (status) return (
			<div
				role="alert"
				className={classnames(
					sharedStyles.errorMessage,
					{
						[sharedStyles.show]              : status,
						[sharedStyles.hide]              : !status,
						[sharedStyles[this.props.theme]] : this.props.theme,
					}
				)}
			>
				<Stout
					uppercase={false}
					status="error"
				>
					{this.props.errorMsg ? this.props.errorMsg : 'This field is required'}
				</Stout>
			</div>
		);
		return null;
	}

	render() {
		const checked = this.props.value ? this.props.value.toString() : undefined;

		return (
			<InputWrapper
				inline={this.props.inline}
				valid={this.showValidation}
				theme={this.props.theme}
				for="checkbox"
			>
				<span
					className={classnames(styles.checkboxFieldWrapper, {
						[sharedStyles.full]         : this.props.border,
						[sharedStyles.border]       : this.props.border,
						[sharedStyles.activeBorder] : checked,
					})}
				>
					<input
						id={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel}`}
						data-cy={this.props.dataCy ? this.props.dataCy + '-checkbox' : ''}
						className={classnames(sharedStyles.checkboxField, {
							[styles.invalid]           : this.showValidation,
							[styles[this.props.theme]] : this.props.theme,
							[sharedStyles.invalid]     : this.showValidation,
						})}
						type='checkbox'
						disabled={this.props.disabled}
						onChange={this.handleChange}
						checked={this.props.value}
						placeholder={this.props.placeholder}
						required={this.props.required}
					/>
					{this.label}
				</span>
				{this.errors}
			</InputWrapper>
		);
	}
}

export { Checkbox };
export default connect(
	(state, ownProps) => formFieldSelector(state, ownProps),
	{ setField }
)(Checkbox);
