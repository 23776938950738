import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Content,
  FlexRow,
  FlexCol,
  Section,
  Icon,
  LinkButton,
} from "@spring/smeargle";

import styles from "./styles.module.scss";
import { Heading } from "design-system/components";

const OfferingIcon = (props) => (
  <div className={classnames(styles.iconWrapper, styles.customIcon)}>
    {typeof props.icon === "string" ? (
      <Icon type={props.icon} color="PRIMARY_500" />
    ) : (
      props.icon
    )}
  </div>
);

OfferingIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const buttons = (buttons) =>
  buttons && (
    <div className={styles.buttonWrapper}>
      <FlexRow>{buttons.map((button) => button)}</FlexRow>
    </div>
  );

const action = (action) => {
  if (action) {
    return (
      <Section size="sm">
        <Content>
          <LinkButton
            inlineTextLink
            text={action.text}
            onClick={action.onClick}
            icon="arrow-right"
            tabIndex="0"
            onKeyDown={(e) => e.code === "Enter" && action.onClick()}
            role="link"
          />
        </Content>
      </Section>
    );
  }
  return null;
};

const CarePlanOffering = (props) => {
  return (
    <div className={styles.padding}>
      <FlexRow alignment="flex-start">
        <OfferingIcon icon={props.icon} />
        <FlexCol>
          <div className={styles.titleWrapper}>
            <Heading as="h3" fontSize={16}>
              {props.title}
            </Heading>
          </div>
          <Section size="sm">
            <Content>{props.description}</Content>
          </Section>
          {props?.subDescription && (
            <Section size="sm">
              <Content>{props?.subDescription}</Content>
            </Section>
          )}
          {buttons(props.buttons)}
          {action(props.action)}
        </FlexCol>
      </FlexRow>
    </div>
  );
};

CarePlanOffering.propTypes = {
  action: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(PropTypes.node),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  subDescription: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
};

export default CarePlanOffering;
