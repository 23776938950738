//
import yup from "schemas/yup";

const schema = yup.object().shape({
  dateOfBirth: yup.object().shape({
    month: yup.string().required(),
    day: yup.number().min(1).max(31).required(),
    year: yup.number().min(1901).max(2019).required(),
  }),
  postal_address: yup.object().shape({
    street_address_1: yup.string().required(),
    city: yup.string().required(),
    zip_code: yup.string().required().min(2),
  }),
});

export default schema;
