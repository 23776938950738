import React from "react";

export const SocialInclusion = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.78354 13.2499C7.44278 13.2499 6.15438 14.4714 5.99729 16.3136C5.96209 16.7263 5.59899 17.0323 5.18628 16.9972C4.77356 16.962 4.46752 16.5989 4.50271 16.1862C4.70869 13.7707 6.47547 11.7499 8.78354 11.7499C11.1574 11.7499 13.0397 13.6826 13.4876 16.114C13.5626 16.5213 13.2932 16.9124 12.8859 16.9875C12.4785 17.0625 12.0875 16.7931 12.0124 16.3858C11.663 14.4891 10.2678 13.2499 8.78354 13.2499Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 8.74994C8.17157 8.74994 7.5 9.42151 7.5 10.2499C7.5 11.0783 8.17157 11.7499 9 11.7499C9.82843 11.7499 10.5 11.0783 10.5 10.2499C10.5 9.42151 9.82843 8.74994 9 8.74994ZM6 10.2499C6 8.59309 7.34315 7.24995 9 7.24995C10.6569 7.24995 12 8.59309 12 10.2499C12 11.9068 10.6569 13.2499 9 13.2499C7.34315 13.2499 6 11.9068 6 10.2499Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 3.49999C2.58579 3.49999 2.25 3.83577 2.25 4.24998C2.25 4.66419 2.58579 4.99997 3 4.99997C3.41421 4.99997 3.75 4.66419 3.75 4.24998C3.75 3.83577 3.41421 3.49999 3 3.49999ZM0.75 4.24998C0.75 3.00735 1.75736 2 3 2C4.24264 2 5.25 3.00735 5.25 4.24998C5.25 5.49261 4.24264 6.49996 3 6.49996C1.75736 6.49996 0.75 5.49261 0.75 4.24998Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 6.49996C2.17157 6.49996 1.5 7.17153 1.5 7.99995C1.5 8.41416 1.16421 8.74994 0.75 8.74994C0.335786 8.74994 0 8.41416 0 7.99995C0 6.34311 1.34315 4.99997 3 4.99997C4.30739 4.99997 5.41762 5.83585 5.8291 7.00002C5.96714 7.39055 5.76244 7.81904 5.37191 7.95708C4.98137 8.09511 4.55288 7.89042 4.41484 7.49989C4.20857 6.91628 3.65194 6.49996 3 6.49996Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 3.49999C15.4142 3.49999 15.75 3.83577 15.75 4.24998C15.75 4.66419 15.4142 4.99997 15 4.99997C14.5858 4.99997 14.25 4.66419 14.25 4.24998C14.25 3.83577 14.5858 3.49999 15 3.49999ZM17.25 4.24998C17.25 3.00735 16.2426 2 15 2C13.7574 2 12.75 3.00735 12.75 4.24998C12.75 5.49261 13.7574 6.49996 15 6.49996C16.2426 6.49996 17.25 5.49261 17.25 4.24998Z"
        fill="#171E22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 6.49996C15.8284 6.49996 16.5 7.17153 16.5 7.99995C16.5 8.41416 16.8358 8.74994 17.25 8.74994C17.6642 8.74994 18 8.41416 18 7.99995C18 6.34311 16.6569 4.99997 15 4.99997C13.6926 4.99997 12.5824 5.83585 12.1709 7.00002C12.0329 7.39055 12.2376 7.81904 12.6281 7.95708C13.0186 8.09511 13.4471 7.89042 13.5852 7.49989C13.7914 6.91628 14.3481 6.49996 15 6.49996Z"
        fill="#171E22"
      />
    </svg>
  );
};
