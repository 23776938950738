import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const JoinSpringHealthHeader = ({ familyView }) => {
  const { t } = useTranslation("limitedLangAuth");

  return (
    <header data-cy="join-spring-health" className={styles.title}>
      <h1>{t("joinSpringHealthHeader.title")}</h1>
      <div className={styles.subtitle}>
        {!familyView ? (
          <h2>{t("joinSpringHealthHeader.subtitle")}</h2>
        ) : (
          <h2>{t("joinSpringHealthHeader.familySubtitle")}</h2>
        )}
      </div>
    </header>
  );
};

export default JoinSpringHealthHeader;

JoinSpringHealthHeader.propTypes = {
  familyView: PropTypes.bool,
};
