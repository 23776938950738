import { gql } from "@apollo/client";

import paging from "operations/fragments/paging";
import { goal } from "operations/fragments/goal";

const getGoals = gql`
  query getGoals(
    $limit: Int = 30
    $offset: Int = 0
    $version: String = "improved_sud"
  ) {
    goals(offset: $offset, limit: $limit, version: $version) {
      data {
        ...goal
      }
      paging {
        ...paging
      }
    }
  }
  ${goal}
  ${paging}
`;

export default getGoals;
