import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { AudioPlayer } from "../../../../components/atoms/AudioPlayer";
import { FlexCol } from "../../../../components";

const MomentsAudioStep = ({ exercise, ...props }) => {
  const imageUrl = exercise?.images[0]?.url;
  return (
    <FlexCol shortHeight>
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className={classnames(styles.imageWrapper, {
          [styles.hideOnSmallScreen]: props.hideOnSmallScreen,
        })}
      />
      <h2
        className={classnames(styles.title, {
          [styles.hideOnSmallScreen]: props.hideOnSmallScreen,
        })}
        aria-label={props.title}
      >
        {props.title}
      </h2>
      <div className={styles.audioPlayer}>
        <AudioPlayer
          exercise={exercise}
          title={props.title}
          url={props.mediaUrl}
          subtitles={props.subtitles}
          trackLang={props.trackLang}
          trackAudioExercisePlayButtonClicked={
            props.trackAudioExercisePlayButtonClicked
          }
          trackAudioExercisePauseButtonClicked={
            props.trackAudioExercisePauseButtonClicked
          }
          trackAudioExerciseRewindButtonClicked={
            props.trackAudioExerciseRewindButtonClicked
          }
          trackAudioExerciseFastForwardButtonClicked={
            props.trackAudioExerciseFastForwardButtonClicked
          }
          height="110px"
        />
      </div>
    </FlexCol>
  );
};

MomentsAudioStep.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  mediaUrl: PropTypes.string,
  subtitles: PropTypes.array,
  hideOnSmallScreen: PropTypes.string,
  trackLang: PropTypes.string,
};

MomentsAudioStep.defaultProps = {
  showCoverImage: true,
};

export default MomentsAudioStep;
