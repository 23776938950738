import { Button } from "@springcare/sh-component-library";
import { Box } from "@chakra-ui/react";
import { useRef, useState, useCallback } from "react";
import { UpcomingAppointmentCard } from "shared/components/UpcomingAppointmentCard";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CoverageCard } from "./CoverageCard";
import { UpNextCard } from "shared/components";
import { useTranslation } from "hooks/react-i18next";
import UpNextTherapyCard from "./UpNextTherapyCard";
import { TRACK_EVENT } from "utils/mixpanel";
import { SentimentRatingCard } from "shared/components/SentimentRatingCard/SentimentRatingCard";

const UpNextListV2 = ({
  recommendedCareItems,
  memberLastUnratedAppointment,
  cardConfig,
  hasEverCompletedARecommendedCareItem,
  headerText,
  upcomingAppointments,
  showNewCoachingCard,
  isSUDLoading,
  initialSUDRisk,
  latestSUDRisk,
  memberId,
  userId,
  balanceData,
}) => {
  // only show appts <= 72 hours
  const upcomingAppointmentsCardsFlag = useFeatureFlag(
    FLAGS.UPCOMING_APPOINTMENT_CARDS,
  );

  const showSpecializedCoach = useFeatureFlag(
    FLAGS.SPECIALIZED_COACHING_CARD_V2_EXPERIENCE,
  );

  const showPeerRecoverySpecialistExp = useFeatureFlag(
    FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE,
  );

  const showNewTherapyCard = ["clear_images", "blurred_images"].includes(
    useFeatureFlag(FLAGS.HOMEPAGE_TODO_COLLAPSE),
  );

  const quickSentimentLDFlag = useFeatureFlag(
    FLAGS.SENTIMENT_RATING_ENTRY_POINTS,
  );

  const { t } = useTranslation("homepage");

  const isFirstCard = (index) => index === 0;

  const [showFullList, setShowFullList] = useState(false);
  const firstCardRef = useRef(null);
  const containerRef = useRef(null);
  const upcomingApptsRef = useRef(null);
  const sentimentRatingRef = useRef(null);
  const balanceRef = useRef(null);

  const getViewMoreCopy = () => {
    if (!recommendedCareItems) {
      return null;
    }
    if (recommendedCareItems.length > 2) {
      return t("upNextSection.viewMultipleMoreRecommendations", {
        // @ts-ignore
        count: `${recommendedCareItems.length - 1}`,
      });
    }
    return t("upNextSection.viewOneMoreRecommendation");
  };

  const getTherapyCardTitle = () => {
    switch (useFeatureFlag(FLAGS.HOMEPAGE_TODO_COLLAPSE)) {
      case "clear_images":
        return t("upNextSection.therapistAssignment.assignmentCardTitle");
      case "blurred_images":
        return t("upNextSection.therapistAssignment.recommendedCardTitle2");
      default:
        return t("upNextSection.therapistAssignment.recommendedCardTitle");
    }
  };

  const handleExpandButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Up next cards expand button clicked",
      {
        action: showFullList ? "hide" : "show",
      },
    );
    setShowFullList(!showFullList);
  };

  const outstandingBalance = balanceData?.member_balance?.balance > 0;

  const getCollapsedHeight = useCallback(() => {
    const firstCardHeight = firstCardRef?.current?.scrollHeight;
    const upcomingApptsHeight = upcomingAppointmentsCardsFlag
      ? upcomingApptsRef?.current?.scrollHeight
      : 0;
    const sentimentRatingHeight =
      quickSentimentLDFlag && memberLastUnratedAppointment
        ? sentimentRatingRef?.current?.scrollHeight
        : 0;

    const balanceHeight = outstandingBalance
      ? balanceRef?.current?.scrollHeight
      : 0;

    return firstCardHeight
      ? firstCardHeight +
          upcomingApptsHeight +
          balanceHeight +
          sentimentRatingHeight
      : "auto";
  }, [
    firstCardRef.current,
    upcomingApptsRef.current,
    sentimentRatingRef.current,
    upcomingAppointmentsCardsFlag,
    quickSentimentLDFlag,
    balanceRef.current,
    outstandingBalance,
  ]);

  return (
    <>
      <Box
        ref={containerRef}
        maxHeight={
          showFullList
            ? containerRef?.current?.scrollHeight
            : getCollapsedHeight()
        }
        overflow="hidden"
        transition="max-height 0.5s"
      >
        {upcomingAppointmentsCardsFlag && (
          <Box ref={upcomingApptsRef}>
            {upcomingAppointments?.map((appointment, index) => (
              <Box
                key={index}
                pb="24px"
                data-testid="upcoming-appointment-card"
              >
                <UpcomingAppointmentCard
                  appointment={appointment}
                  memberId={memberId}
                  userId={userId}
                />
              </Box>
            ))}
          </Box>
        )}
        {quickSentimentLDFlag && memberLastUnratedAppointment && (
          <Box
            pb="24px"
            data-testid="sentiment-rating-card"
            ref={sentimentRatingRef}
          >
            <SentimentRatingCard appointment={memberLastUnratedAppointment} />
          </Box>
        )}
        {outstandingBalance && (
          <Box ref={balanceRef} pb="24px">
            <UpNextCard
              {...cardConfig.billing_and_payments}
              buttonVariant="solid"
              showBalanceFlag={outstandingBalance}
              headerText={headerText}
            />
          </Box>
        )}
        <CoverageCard cardConfig={cardConfig} />
        {recommendedCareItems &&
          recommendedCareItems.map((recommended_care_item, index) => (
            <Box
              ref={isFirstCard(index) ? firstCardRef : null}
              key={recommended_care_item.care_item_name}
              pb="24"
            >
              {recommended_care_item.care_item_name ===
              "peer_recovery_specialist_follow_up" ? (
                showPeerRecoverySpecialistExp && (
                  <UpNextCard
                    {...cardConfig[recommended_care_item.care_item_name]}
                    buttonVariant={isFirstCard(index) ? "solid" : "outline"}
                    showFlag={
                      isFirstCard(index) &&
                      !hasEverCompletedARecommendedCareItem
                    }
                    showNewCoachingExperience={
                      showSpecializedCoach &&
                      recommended_care_item.care_item_name ===
                        "coach_assignment" &&
                      showNewCoachingCard
                    }
                    showPeerRecoverySpecilaistExp={
                      showPeerRecoverySpecialistExp &&
                      recommended_care_item.care_item_name ===
                        "peer_recovery_specialist_follow_up"
                    }
                    cardOrder={index}
                    headerText={headerText}
                    initialSUDRisk={initialSUDRisk}
                    latestSUDRisk={latestSUDRisk}
                    isSUDLoading={isSUDLoading}
                  />
                )
              ) : recommended_care_item.care_item_name ===
                  "therapist_assignment" && showNewTherapyCard ? (
                <UpNextTherapyCard
                  {...cardConfig[recommended_care_item.care_item_name]}
                  cardOrder={index}
                  headerText={headerText}
                  cardTitle={getTherapyCardTitle()}
                  isVisible={isFirstCard(index) || showFullList}
                />
              ) : (
                <UpNextCard
                  {...cardConfig[recommended_care_item.care_item_name]}
                  buttonVariant={isFirstCard(index) ? "solid" : "outline"}
                  showFlag={
                    isFirstCard(index) && !hasEverCompletedARecommendedCareItem
                  }
                  isVisible={isFirstCard(index) || showFullList}
                  showNewCoachingExperience={
                    showSpecializedCoach &&
                    recommended_care_item.care_item_name ===
                      "coach_assignment" &&
                    showNewCoachingCard
                  }
                  cardOrder={index}
                  headerText={headerText}
                  initialSUDRisk={initialSUDRisk}
                  latestSUDRisk={latestSUDRisk}
                  isSUDLoading={isSUDLoading}
                />
              )}
            </Box>
          ))}
      </Box>
      {recommendedCareItems?.length > 1 && (
        <Button
          onClick={handleExpandButtonClick}
          size="lg"
          data-testid="up-next-view-more-button"
          colorScheme="primary"
          variant="outline"
          border="2px solid rgba(0, 19, 23, 0.22)"
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          w="100%"
        >
          {showFullList ? t("upNextSection.viewLess") : getViewMoreCopy()}
        </Button>
      )}
    </>
  );
};

export default UpNextListV2;
