import { useTranslation } from "react-i18next";

import { Flex } from "design-system/components";
import { Heading } from "design-system/components/base/Typography";

import AppointmentCard from "./AppointmentCard";
import CancellationPolicy from "./CancellationPolicy";

const Confirmed = ({ appointment }) => {
  const { t } = useTranslation("loginless", { keyPrefix: "confirmation" });
  const providerName = appointment?.provider.first_name || "your provider";

  return (
    <Flex
      direction="column"
      align="start"
      data-testid="confirmed"
      data-cy="appointment-confirmed-headline"
    >
      <Heading as="h1" variant="md_v1">
        {t("confirmed", {
          appointmentType: appointment.type,
          providerName,
        })}
      </Heading>
      <AppointmentCard appointment={appointment} />
      <CancellationPolicy appointment={appointment} />
    </Flex>
  );
};

export default Confirmed;
