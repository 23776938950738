import { TRACK_EVENT } from "utils/mixpanel";
import { TEEN_ASSESSMENT_TELEMETRY_TYPES } from "components/templates/TeenAssessment/constants/telemetryValues";
import Router from "next/router";

export const showBeforeWeStart = (setRenderBeforeWeStart) => {
  setRenderBeforeWeStart(true);
  TRACK_EVENT.BUTTON_CLICKED(
    Router.asPath,
    TEEN_ASSESSMENT_TELEMETRY_TYPES.TEEN_SURVEY_START,
    {
      page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_CHOOSE_USER,
      spring_doc_id: "surveychooseuser002",
    },
  );
};

export const showShareWithTeen = (setRenderShareWithYourTeen) => {
  setRenderShareWithYourTeen(true);
  TRACK_EVENT.BUTTON_CLICKED(
    Router.asPath,
    TEEN_ASSESSMENT_TELEMETRY_TYPES.ADULT_SURVEY_START,
    {
      page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_CHOOSE_USER,
      spring_doc_id: "surveychooseuser003",
    },
  );
};

export const mpTrackingForSurveyChooseUserPage = () => {
  TRACK_EVENT.PAGE_VERSION_VIEWED(
    Router.asPath,
    TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_CHOOSE_USER,
    {
      page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_CHOOSE_USER,
      spring_doc_id: "surveychooseuser001",
    },
  );
};
