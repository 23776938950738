//

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { Modal, Subtitle, Button, FlexRow } from "@spring/smeargle";
import {
  addNotification,
  closeModal,
  openModal,
} from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import Meowth from "@spring/meowth";
import { Trans, useTranslation } from "react-i18next";

import { Link } from "components/atoms";

import { updateMemberPrivacyPolicyAgreedAt } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";
import { getUserInfo } from "operations/queries/user";

const CCPAModal = ({ addNotification, closeModal, openModal }) => {
  const { t } = useTranslation("common");
  const { data: userData, loading: userLoading } = useQuery(getUserInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const [updateMemberMutation, { loading: updateMemberLoading }] = useMutation(
    updateMemberPrivacyPolicyAgreedAt,
    {
      variables: { input: { id: userData && userData.user?.member?.id } },
      onCompleted: () => closeModal(modalIds.ccpaModal),
      onError: (err) => addNotification(getFirstError(err), "error"),
      skip: Meowth.apolloSkipUserId(),
    },
  );

  useEffect(() => {
    if (
      userData &&
      !userData.user.roles.includes("MOMENTS_ONLY") &&
      !userData.user.member.valid_privacy_policy_agreement
    ) {
      openModal(modalIds.ccpaModal);
    }
  }, [userData]);

  if (userLoading) {
    return null;
  }

  return (
    <Modal size="md" id={modalIds.ccpaModal} dismissable={false}>
      <Subtitle>
        <Trans
          ns={"common"}
          i18nKey={"CCPAModal.subtitle"}
          components={[<Link key={0} alias="PrivacyPolicy"></Link>]}
        />
      </Subtitle>
      <FlexRow justification="flex-end">
        <Button
          text={t("accept")}
          disabled={updateMemberLoading}
          onClick={updateMemberMutation}
        />
      </FlexRow>
    </Modal>
  );
};

CCPAModal.propTypes = {
  addNotification: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

const mapDispatchToProps = { addNotification, closeModal, openModal };

export { CCPAModal };
export default connect(null, mapDispatchToProps)(CCPAModal);
