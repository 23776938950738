import { useQuery } from "@apollo/client";
import { getTemporaryPassword } from "operations/queries/temporaryPassword";

type TemporaryPasswordVariables = {
  id: string;
};

type TemporaryPasswordResult = {
  temporary_password: {
    temporary_password: string;
  };
};

export const useTemporaryPassword = (id: string, shouldSkip: boolean) => {
  return useQuery<TemporaryPasswordResult, TemporaryPasswordVariables>(
    getTemporaryPassword,
    {
      variables: { id },
      skip: shouldSkip,
    },
  );
};
