"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useDropdownToggle;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
// Functionality for a dropdown toggle that closes the dropdown when a user clicks outside the component.
// See smeargle/src/components/molecules/LanguageSelect/LanguageSelect.js for implementation
function useDropdownToggle(initialIsVisible) {
  var _useState = (0, _react.useState)(initialIsVisible),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    isComponentVisible = _useState2[0],
    setIsComponentVisible = _useState2[1];
  var ref = (0, _react.useRef)(null);
  var handleClickOutside = function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };
  (0, _react.useEffect)(function () {
    document.addEventListener('click', handleClickOutside, true);
    return function () {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return [ref, isComponentVisible, setIsComponentVisible];
}