import { ProviderRole } from "@spring/constants";
import { titleCase } from "components/templates/RegisterPage/analytics";
import Router from "next/router";
import routes from "routes";

import { SpecializedCoachingCardAnalyticsMapping } from "modules/MemberDashboard/SpecializeCoach/constants/analyticsMapping";

export const isACareNavigator = (careProvider) =>
  careProvider?.careTeamRole === ProviderRole.CareNavigator ||
  careProvider?.roles?.includes("Care Navigator") ||
  careProvider?.careTeamRole === ProviderRole.SpecialtyCareNavigator ||
  careProvider?.roles?.includes("Specialty Care Navigator");

export const isACareAdvocate = (careProvider) =>
  careProvider && careProvider?.roles?.includes("Care Advocate"); // TODO: these should really be an enum

export const isATherapist = (careProvider) =>
  careProvider && careProvider?.roles?.includes("Therapist"); // TODO: these should really be an enum

export const isPeerRecoverySpecialist = (careProvider) =>
  careProvider &&
  careProvider?.roles?.includes(titleCase(ProviderRole.PeerRecoverySpecialist));

export const routeToConfirmationPage = (
  routeTeenToInitialAssessment,
  id,
  isReschedule = false,
) => {
  const { to } = routeTeenToInitialAssessment
    ? routes.TeenAssessment
    : routes.MemberAppointmentDetail;
  let { as } = routeTeenToInitialAssessment
    ? routes.TeenAssessment
    : routes.MemberAppointmentDetail;

  if (!routeTeenToInitialAssessment && isReschedule) return;

  if (routeTeenToInitialAssessment) {
    return Router.replace(
      {
        pathname: to,
        query: { appointment_id: id },
      },
      as,
    );
  } else {
    as = as.replace(":id", id);
    return Router.replace(
      {
        pathname: to,
        query: { id },
      },
      as,
    );
  }
};

export const customerSiteAddress = (customerSite) => {
  if (!customerSite) return null;

  const { address, appointment_directions, city, postal_code, state } =
    customerSite;

  return [address, city, state, postal_code, appointment_directions]
    .filter(Boolean)
    .join(", ");
};

export const specializedTopics = (router) => {
  const personalDevelopmentParam =
    router.query["speciality-personalDevelopment"];
  const professionalGrowthParam = router.query["speciality-professionalGrowth"];
  const parentingParam = router.query["speciality-parenting"];
  const healthWellnessParam = router.query["speciality-healthWellness"];
  if (
    router.query.personalDevelopment === "true" ||
    personalDevelopmentParam === "true"
  ) {
    return SpecializedCoachingCardAnalyticsMapping.personalDevelopment;
  }
  if (
    router.query.professionalGrowth === "true" ||
    professionalGrowthParam === "true"
  ) {
    return SpecializedCoachingCardAnalyticsMapping.professionalGrowth;
  }
  if (router.query.parenting === "true" || parentingParam === "true") {
    return SpecializedCoachingCardAnalyticsMapping.parenting;
  }
  if (
    router.query.healthWellness === "true" ||
    healthWellnessParam === "true"
  ) {
    return SpecializedCoachingCardAnalyticsMapping.healthWellness;
  }
  return "";
};
