import { isNil } from "lodash";

type GetLabelValuesProps = {
  markers?: string[];
  max: number;
  min: number;
  value: number | null;
};

type GetMarkerLabelProps = {
  labelModifier?: (label: string) => string;
  markers?: string[];
  max: number;
  min: number;
  t: Function;
  value: number | null;
};

const getMarkerLabel = ({
  labelModifier,
  markers,
  max,
  min,
  t,
  value,
}: GetMarkerLabelProps) => {
  if (isNil(value)) {
    return t("slider.slideToSelect");
  } else if (markers && labelModifier) {
    return labelModifier(labelValues({ markers, max, min, value }));
  } else if (labelModifier) {
    return labelModifier(value.toString());
  } else {
    return "";
  }
};

/*
 * Function to create unique labels for markers if the markers exist.
 * Uses range between markers to custom create labels if there are more options than there are markers.
 * i.e. If a user chooses a marker between "Not at all" and "Mildly", the marker used will read "Not at all | Mildly"
 */
const labelValues = ({ markers, max, min, value }: GetLabelValuesProps) => {
  const total = max - min + 1;
  const range = total / markers.length;
  const exactValues: [number, string][] = markers.map((m, i) => {
    const exact = Math.floor(range * i + range / 2) + min;
    return [exact, m];
  });

  const margin = Math.ceil(range * 0.25); // 1

  let label;
  const lastMarker = exactValues[exactValues.length - 1];
  const firstMarker = exactValues[0];

  exactValues.forEach((arr, i) => {
    if (value === arr[0]) {
      label = [arr[1]];
    }

    if (!label) {
      const next = exactValues[i + 1] || [];

      if (
        value >= parseInt(arr[0]?.toString(), 10) + margin &&
        value <= parseInt(next[0]?.toString(), 10) - margin
      ) {
        label = [arr[1], next[1]];
      } else if (value <= parseInt(arr[0]?.toString(), 10) + margin) {
        label = [arr[1]];
      }
    }
  });

  if (!label) {
    if (value <= firstMarker[0]) {
      label = [exactValues[0][1]];
    } else if (value >= lastMarker[0]) {
      label = [lastMarker[1]];
    }
  }

  return label.join(" / ");
};

export { getMarkerLabel };
