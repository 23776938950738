import { useRef } from "react";
import { Flex, Box, Text, useDisclosure } from "design-system";
import { SHModal } from "design-system/components";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { UpdateAddressForm } from "./UpdateAddressForm";
import { useTranslation } from "hooks/react-i18next";
import { Trans } from "react-i18next";

const AddressDisclaimer = () => {
  const { t } = useTranslation(["careProvider", "common"]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeModalFocusRef = useRef(null);

  return (
    <Flex
      mb={12}
      w="100%"
      borderRadius={16}
      backgroundColor={"info-subtle"}
      p={16}
      justify="space-between"
      align="center"
    >
      <Flex align="center">
        <InfoOutlineIcon boxSize={20} mr={8} color="info-on-subtle" />
        <Trans mx={4}>
          <Text>{t("browserPageWrapper.addressDisclaimer")}</Text>
        </Trans>
      </Flex>

      <Box
        as="button"
        onClick={onOpen}
        mx={4}
        color="info-on-subtle"
        textDecoration="none"
        cursor="pointer"
        _hover={{ textDecoration: "underline", color: "info-bold" }}
      >
        <Text>{t("updateAddress.link")}</Text>
      </Box>

      <SHModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={t("updateAddress.title")}
        finalFocusRef={closeModalFocusRef}
      >
        <UpdateAddressForm onClose={onClose} />
      </SHModal>
    </Flex>
  );
};

export default AddressDisclaimer;
