import { Button } from "@springcare/sh-component-library";
import routes from "routes/index";
import { PhotoCard } from "shared/components/PhotoCard";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTranslation } from "react-i18next";
import Router from "next/router";

const Cta = ({ ctaText }) => {
  return (
    <Button
      alignSelf="flex-start"
      onClick={() => {
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "Take Initial Assessment",
          {
            location: "Pre-Assessed homepage",
            to: routes.TakeInitialAssessment.as,
            // spring_doc_id: "", // TODO: add a doc id
          },
        );
        Router.replace(
          routes.TakeInitialAssessment.to,
          routes.TakeInitialAssessment.as,
        );
      }}
    >
      {ctaText}
    </Button>
  );
};

/**
 * This component renders a PhotoCard banner. It will check the following details:
 * It renders a CTA button that will take users to the initial assessment page.
 */

export const PreAssessmentBanner = () => {
  const { t } = useTranslation("homepage");

  const imgURL = "/static/pre_assessment.png";
  const imageLinearGradient =
    "background: linear-gradient(270deg, rgba(71, 52, 47, 0.00) 0%, rgba(71, 52, 47, 0.01) 14.59%, rgba(71, 52, 47, 0.04) 26.58%, rgba(71, 52, 47, 0.08) 36.32%, rgba(71, 52, 47, 0.14) 44.14%, rgba(71, 52, 47, 0.21) 50.37%, rgba(71, 52, 47, 0.29) 55.36%, rgba(71, 52, 47, 0.38) 59.44%, rgba(71, 52, 47, 0.47) 62.96%, rgba(71, 52, 47, 0.56) 66.24%, rgba(71, 52, 47, 0.65) 69.63%, rgba(71, 52, 47, 0.74) 73.46%, rgba(71, 52, 47, 0.82) 78.08%, rgba(71, 52, 47, 0.89) 83.82%, rgba(71, 52, 47, 0.95) 91.01%, #47342F 100%)";

  return (
    <PhotoCard
      role="link"
      altText={t("preAssessment.banner.altText")}
      imgUrl={imgURL}
      heading={t("preAssessment.banner.heading")}
      description={t("preAssessment.banner.description")}
      photoGradient={imageLinearGradient}
      cta={<Cta ctaText={t("preAssessment.banner.cta.text")} />}
      onClickCallback={() => {
        Router.push(
          routes.TakeInitialAssessment.to,
          routes.TakeInitialAssessment.as,
        );
      }}
    />
  );
};
