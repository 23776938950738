import { TRACK_EVENT } from "utils/mixpanel";
import {
  modalIds,
  ProviderRole,
  getProviderRoleForTracking,
} from "@spring/constants";
import { camelCase } from "lodash";
import { DateTime } from "luxon";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly/flags";
import { returnAllSessionCounts } from "modules/shared/utils";

export const getModalId = (memberData, providerRole) => {
  const isHighMark = useFeatureFlag(FLAGS.HIGHMARK_COST_TRANSPARENCY_COPY);
  const {
    // These endpoints account for sponsored sessions
    remainingMedManagement: specialistSessionsRemaining,
    // basic + specialist + sponsored
    remainingTherapy: totalTherapySessionsRemaining,
  } = returnAllSessionCounts(memberData?.user?.member);
  const canSchedule = memberData?.user?.member?.previsit?.can_schedule;

  // displays insurance modal if the user has 1 or fewer sessions remaining and is not HighMark
  if (
    providerRole === ProviderRole.Therapist &&
    !canSchedule &&
    totalTherapySessionsRemaining <= 1 &&
    !isHighMark
  ) {
    TRACK_EVENT.MODAL_OPENED(
      window.location.pathname,
      "Profile Settings and Insurance",
      {
        provider_role: ProviderRole.Therapist,
      },
    );
    return modalIds.beforeYouScheduleModal;
  }

  if (providerRole === ProviderRole.MedicationManager) {
    // displays insurance modal if the user has 1 or fewer sessions remaining and is not HighMark
    if (!canSchedule && specialistSessionsRemaining >= 1 && !isHighMark) {
      TRACK_EVENT.MODAL_OPENED(
        window.location.pathname,
        "Profile Settings and Insurance",
        {
          provider_role: ProviderRole.MedicationManager,
        },
      );
      return modalIds.beforeYouScheduleModal;
    }
  }
  return modalIds.careProviderScheduleModal;
};

export const supportsInPerson = (
  provider,
  memberInfo,
  showGlobalExperience,
) => {
  return (
    memberInfo?.user?.member?.cohort?.in_person_supported &&
    provider.in_person_supported &&
    provider.roles.includes("Therapist") &&
    !showGlobalExperience
  );
};

export const getLicenses = (providerData) => {
  return providerData?.licenses?.length
    ? ` • ${providerData.licenses.join(", ")}`
    : null;
};

export const getCategories = (providerRole, providerData, t) => {
  const formattedRole = camelCase(providerRole);
  const subHeadCategories = providerRole
    ? t(`careProvider.${formattedRole}`, { ns: "careVisits" })
    : "";
  if (
    formattedRole === "coach" &&
    providerData.category &&
    providerData.category?.length > 0
  ) {
    return providerData.category
      .map(function (cat) {
        return t(`coachCategory.${cat}`);
      })
      .join(", ");
  }
  return subHeadCategories;
};

export const modalData = (
  provider,
  appointmentKind,
  memberData,
  providerRole,
  showGlobalExperience,
) => {
  return {
    ...provider,
    mpLocation: "Your Care Providers",
    providerRole: getProviderRoleForTracking(providerRole),
    supportsInPerson: supportsInPerson(
      provider,
      memberData,
      showGlobalExperience,
    ),
    kind: appointmentKind,
    medium: provider.supported_appointment_mediums[0],
  };
};

export const getTimeToAppointment = (appointmentStartTime) => {
  const appointmentTime = DateTime.fromISO(appointmentStartTime);
  const currentTime = DateTime.fromISO(new Date().toISOString());
  const appointmetTimeDifference = appointmentTime.diff(currentTime, "minutes");
  return parseFloat(appointmetTimeDifference.minutes).toFixed(0);
};
