import { gql } from "@apollo/client";

const sessionEndForAll = gql`
  query sessionEndForAll($appointmentId: ID!, $participantId: ID!) {
    session_end_for_all(
      appointment_id: $appointmentId
      participant_id: $participantId
    ) {
      message
    }
  }
`;

export default sessionEndForAll;
