import { Box } from "design-system/components";
import { Avatar } from "@spring/smeargle";

const CareTeamCardAvatar = ({
  providerData,
  styles,
  providerAttributes,
  infoAction,
  width = null,
  noExtraSpace = false,
}) => {
  if (providerData?.avatar) {
    const imgProps = { alt: `${providerData.name}`, "aria-hidden": "true" };
    return (
      <Box
        mt={noExtraSpace ? 0 : 10}
        pt={noExtraSpace ? 0 : 10}
        onClick={() => infoAction(providerData)}
        className={styles.avatar}
        w={width ?? [80, 90, 120, 130]}
      >
        <Avatar
          provider={`${providerData.name}`}
          imgProps={imgProps}
          src={providerData.avatar}
          round
        />
      </Box>
    );
  }
  return providerAttributes.emptyIcon;
};

export default CareTeamCardAvatar;
