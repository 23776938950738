//

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { Form, Checkbox } from "@spring/smeargle";
import { links } from "@spring/constants";
import Meowth from "@spring/meowth";

import { Link } from "components/atoms";

import envUtils from "utils/environment";
import { getMemberConsent } from "operations/queries/member";
import memberConsentSchema from "schemas/memberConsent";

/**
 * USAGE:
 *
 * This is meant to be used to conditional ask for the member's consent.
 * All this component does is add the consent form to the page
 *
 * IT IS YOUR RESPONSIBILITY TO SAVE THAT DATA!!!
 *
 * To do so, you will need to grab the data from this form using the redux store
 * and fire `updateMemberInfo` (graphql) when you are done checking their consent and want to save.
 *
 * see CareProviderScheduleModal for an example
 *
 */
class MemberConsent extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      user: PropTypes.shape({
        member: PropTypes.shape({
          hipaa_privacy_policy: PropTypes.any,
          informed_consent: PropTypes.any,
        }),
      }),
      loading: PropTypes.bool,
    }),
    showHipaaCheckbox: PropTypes.bool,
  };

  static defaultProps = {
    showHipaaCheckbox: true,
  };

  render() {
    if (this.props.data?.loading) {
      return null;
    }

    return (
      <Form
        formKey="member-consent"
        theme="simple"
        initialData={this.props.data?.user?.member}
        schema={memberConsentSchema}
      >
        {!this.props.data?.user?.member.informed_consent && (
          <Checkbox
            fieldKey="informed_consent"
            label={
              <span>
                I agree to Spring's{" "}
                <Link to={envUtils.informedConsentLink} target="_blank">
                  Informed Consent
                </Link>{" "}
                policy
              </span>
            }
          />
        )}
        {!this.props.data?.user?.member.hipaa_privacy_policy &&
          this.props.showHipaaCheckbox && (
            <Checkbox
              fieldKey="hipaa_privacy_policy"
              label={
                <span>
                  I agree to Spring's{" "}
                  <Link to={links.PrivacyNotice} target="_blank">
                    HIPAA Privacy
                  </Link>{" "}
                  policy
                </span>
              }
            />
          )}
      </Form>
    );
  }
}

export { MemberConsent };

export default compose(
  graphql(getMemberConsent, { options: Meowth.apolloOptionsUserId }),
)(MemberConsent);
