import yup from "schemas/yup";

const schema = yup.object().shape({
  payment_preference: yup.boolean(),
  carrier_name: yup.string().when("payment_preference", {
    is: false,
    then: yup.string().required(),
  }),
  plan_name: yup.string().when("payment_preference", {
    is: false,
    then: yup.string().required(),
  }),
  insurance_group_id: yup.string().when("payment_preference", {
    is: false,
    then: yup.string().required(),
  }),
  insurance_member_id: yup.string().when("payment_preference", {
    is: false,
    then: yup.string().required(),
  }),
});

export default schema;
