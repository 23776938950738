import React, { useState, useEffect } from "react";
import NextLink from "next/link";
import {
  Box,
  Button,
  Heading,
  Link,
  useBreakpointValue,
} from "design-system/components";
import routes from "routes";
import { useTranslation } from "react-i18next";
import { TRACK_EVENT } from "utils/mixpanel";

const UpNextEmpty = () => {
  const headingVariant = useBreakpointValue(["sm", "sm", "sm", "lg"]);
  const { t } = useTranslation("homepage");

  let [trackedOnce, setTrackedOnce] = useState(0);
  useEffect(() => {
    if (trackedOnce === 0) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Up next empty state",
        {
          spring_doc_id: "HPR_027",
          location: "Up Next section",
        },
      );
      setTrackedOnce(1);
    }
  }, []);
  return (
    <>
      <Box maxW={[360, 360, 360, 535, 614]}>
        <Heading variant={headingVariant}>
          {t("upNextSection.emptyState.heading")}
        </Heading>
      </Box>
      <Box pt={24}>
        <NextLink href={routes.MemberCareVisits.as} passHref>
          <Button
            as={Link}
            colorScheme="primary"
            size="md"
            onClick={() => {
              TRACK_EVENT.LINK_CLICKED(
                window.location.pathname,
                routes.MemberCareVisits.as,
                "Manage sessions",
                {
                  spring_doc_id: "HPR_011",
                  location: "Recommended care items empty",
                },
              );
            }}
          >
            {t("upNextSection.emptyState.buttonText")}
          </Button>
        </NextLink>
      </Box>
    </>
  );
};

export default UpNextEmpty;
