import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const startAssessment = gql`
  mutation StartAssessment($input: StartAssessmentInput!) {
    startAssessment(input: $input) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default startAssessment;
