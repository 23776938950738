import { gql } from "@apollo/client";

const getAppointment = gql`
  query getAppointment($id: ID!) {
    appointment(id: $id) {
      id
      duration
      attended
      cancelled_for_member
      start_at
      confirmed_at
      kind
      medium
      status
      location
      provider_id
      session_type
      reason_for_scheduling
      is_covered_for_member
      is_in_house_video
      sentiment_rating
      member_id
      is_eligible_for_recording
      bookings {
        id
        name
        role
        avatar
        calendar {
          id
        }
      }
    }
  }
`;

export default getAppointment;
