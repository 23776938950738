import { createIcon } from "@chakra-ui/react";

export const Mobile = createIcon({
  displayName: "Mobile",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07812 3.98344C7.52584 3.98344 7.07812 4.43116 7.07812 4.98345V7.90148V19.0162C7.07812 19.5685 7.52584 20.0162 8.07812 20.0162H15.9091C16.4614 20.0162 16.9091 19.5685 16.9091 19.0162V4.98344C16.9091 4.43116 16.4614 3.98344 15.9091 3.98344H15.0001L15.0003 4.00005C15.0003 4.55233 14.5526 5.00005 14.0003 5.00005H10.0003C9.44799 5.00005 9.00028 4.55233 9.00028 4.00005L9.00041 3.98344H8.07812ZM5.07812 4.98345C5.07812 3.32659 6.42127 1.98344 8.07812 1.98344H10.5148H15.9091C17.566 1.98344 18.9091 3.32659 18.9091 4.98344V19.0162C18.9091 20.6731 17.566 22.0162 15.9091 22.0162H8.07812C6.42127 22.0162 5.07812 20.6731 5.07812 19.0162V7.90148V4.98345ZM10.0003 17C9.44799 17 9.00028 17.4478 9.00028 18C9.00028 18.5523 9.44799 19 10.0003 19H14.0003C14.5526 19 15.0003 18.5523 15.0003 18C15.0003 17.4478 14.5526 17 14.0003 17H10.0003Z"
    />
  ),
  defaultProps: {
    "aria-label": "Mobile icon",
    role: "img",
  },
});
