import { useContext } from "react";
import PropTypes from "prop-types";

import { AssessmentResultsContext } from "components/templates/YourAssessmentResults/YourAssessmentResults";

import Card from "./Card";
import CardWrapper from "./CardWrapper";

const Focus = ({ type, displayInline = false }) => {
  const values = useContext(AssessmentResultsContext);
  const focusCards = values.focusCards;
  const { pageRefs, styles, t } = values;

  return (
    <CardWrapper
      header={t(`${type}.header`)}
      subheader={t(`${type}.subHeader`)}
      styles={styles}
      ref={(el) => (pageRefs.current = { ...pageRefs.current, focus: el })}
      displayInline={displayInline}
    >
      {focusCards.map((card) => (
        <Card data={card} type={type} key={card.label} />
      ))}
    </CardWrapper>
  );
};

Focus.propTypes = {
  type: PropTypes.string,
};

export default Focus;
