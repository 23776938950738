//
import PropTypes from "prop-types";

import React, { PureComponent } from "react";
import Router from "next/router";
import { compose } from "redux";
import { DateTime } from "luxon";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { get, getOr } from "lodash/fp";
import {
  Avatar,
  Button,
  Grid,
  Col,
  HorizontalRule,
  FlexCol,
  Section,
  Steps,
  Stout,
  Headline,
  Content,
  Subtitle,
  FlexRow,
  LinkButton,
  FloatyPage,
} from "@spring/smeargle";
import { openModal } from "@spring/smeargle/actions";
import {
  links,
  modalIds,
  AppointmentKind,
  AppointmentMedium,
  AppointmentAction,
} from "@spring/constants";
import Meowth from "@spring/meowth";

import Link from "components/atoms/Link";
import CareProviderScheduleModal from "components/modals/CareProviderScheduleModal";
import ReviewBenefits from "components/molecules/ReviewBenefits";

import styles from "./styles.module.scss";

import routes from "routes";
import { latestInitialAssessment } from "operations/selectors/member";
import { getMemberInfo } from "operations/queries/member";
import { getCareTeam } from "operations/queries/careProvider";
import { track } from "utils/mixpanel";

class PersonalizedPlan extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.any,
    }),
    openModal: PropTypes.func,
  };

  get cnRecommended() {
    const careNavigator = get(
      "careTeam.user.member.care_team.care_navigator",
      this.props,
    );
    const recommended = get(
      "data.user.member.chart.care_navigator_call_recommended",
      this.props,
    );

    return Boolean(careNavigator && recommended);
  }

  get stepArray() {
    const medicationRecommended = get(
      "data.user.member.chart.medication_manager_recommended",
      this.props,
    );
    const steps = [
      {
        headline: "Initial Therapy Visit",
        text: (
          <Content>
            An initial 55-minute video consultation with your therapist who will
            explain the process of treatment and partner with you to form goals.
            Not ready to schedule a visit?
            <br />
            <Link
              alias="TherapistsBrowse"
              mpTracking={{
                pageName: "Personalized Plan",
                linkName: "Browse Therapists",
              }}
            >
              Browse our network of world-class therapists.
            </Link>
          </Content>
        ),
      },
      {
        headline: "Begin Treatment",
        text: "Based on your assessment results, your goals, your therapist's therapeutic approach, you will set out on a plan for care.",
      },
      {
        headline: "Track Your Progress",
        text: "We'll send you regular self-report assessments that will help you visualize your progress over time.",
      },
    ];

    const medicationStep = {
      headline: "Medication Consultation",
      text: (
        <Content>
          According to your needs and preferences, Spring may connect you with a
          board-certified physician or psychiatrist for medication management.
          <br />
          <Link
            alias="Medication_ManagersBrowse"
            mpTracking={{
              pageName: "Personalized Plan",
              linkName: "Browse Physicians",
            }}
          >
            Browse our network of physicians.
          </Link>
        </Content>
      ),
    };

    const kickoffStep = {
      headline: "Schedule a Kick-off Call",
      text: "Ask questions about Spring, discover your options, and get connected with a care provider.",
    };

    if (medicationRecommended) {
      steps.splice(1, 0, medicationStep);
    }

    if (this.cnRecommended) {
      steps.unshift(kickoffStep);
    }

    return steps;
  }

  get steps() {
    return (
      <FlexCol alignment="center">
        <Steps steps={this.stepArray} striped />
      </FlexCol>
    );
  }

  get careNavigator() {
    if (this.cnRecommended) {
      const careNavigator = get(
        "careTeam.user.member.care_team.care_navigator",
        this.props,
      );

      return (
        <div>
          <FlexRow alignment="center">
            <Avatar
              round
              height="101px"
              width="101px"
              src={careNavigator.avatar}
            />

            <div className={styles.careNavigatorName}>
              <Subtitle noMargin>{careNavigator.name}</Subtitle>
              <Stout>
                {careNavigator.roles.join("")},{" "}
                {careNavigator.licenses.join(", ")}
              </Stout>
            </div>
          </FlexRow>

          <Section>
            <Content>
              Your Care Navigator is here to support you throughout your journey
              using Spring Health. Follow the link below to schedule a call.
            </Content>
          </Section>
        </div>
      );
    }

    return (
      <Section size="sm">
        <FlexRow alignment="center" justification="center">
          <Avatar
            src={links.SpringCDN + "/assets/images/members/Therapy+Visit.png"}
            height={"auto"}
            width={"50%"}
            square
          />
        </FlexRow>
        <Section size="sm">
          <Content>
            You have access to a network of therapists who are here to support
            you in realizing your mental health and wellness goals.
          </Content>
        </Section>
      </Section>
    );
  }

  get action() {
    if (this.cnRecommended) {
      const careNavigator = get(
        "careTeam.user.member.care_team.care_navigator",
        this.props,
      );

      return (
        <Section size="md">
          <Button
            full
            text={`Schedule Call with ${careNavigator.first_name}`}
            icon="arrow-right"
            onClick={() => {
              track("Personalized Plan -- Schedule Call (Care Navigator)");

              this.props.openModal(modalIds.careProviderScheduleModal, {
                ...careNavigator,
                kind: AppointmentKind.InitialCareNavigation,
                medium: AppointmentMedium.Phone,
                providerRole: "Care Navigator",
                buttonText: "Confirm appointment",
                action: AppointmentAction.Create,
                initialStartTime: DateTime.local()
                  .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                  .toISO(),
              });
            }}
          />
        </Section>
      );
    }

    return (
      <Section>
        <Button
          full
          icon="arrow-right"
          text="Schedule A Visit"
          onClick={() => {
            track("Personalized Plan -- Schedule a Visit (Therapist)");

            const { to, as } = routes.TherapistsBrowse;
            Router.replace(to, as);
          }}
        />
      </Section>
    );
  }

  render() {
    if (
      !this.props.data.loading &&
      !get("props.initialAssessment.loading", this) &&
      !get("props.careTeam.loading", this)
    ) {
      return (
        <FloatyPage float={1} radius={3}>
          <Headline noMargin>Your Personalized Plan</Headline>
          <Subtitle noMargin>
            Based on what you've shared, we've put together a personalized care
            plan for you.
          </Subtitle>

          <Section size="xlg">
            <HorizontalRule />
          </Section>

          <div className={styles.content}>
            <Grid gutter="16px 36px">
              <Col md={6}>{this.steps}</Col>
              <Col md={6}>
                <FlexCol>
                  {this.careNavigator}
                  <ReviewBenefits />
                  {this.action}

                  <div className={styles.resultsButton}>
                    <LinkButton
                      text="View Assessment Results"
                      onClick={() => {
                        track("Personalized Plan -- View Assessment Results");

                        if (typeof window !== "undefined" && window.scrollTo) {
                          window.scrollTo({
                            top: getOr(
                              1000,
                              "document.body.scrollHeight",
                              window,
                            ),
                            behavior: "smooth",
                          });
                        }
                      }}
                    />
                  </div>
                </FlexCol>
              </Col>
            </Grid>
          </div>
          <CareProviderScheduleModal />
        </FloatyPage>
      );
    }

    return null;
  }
}

export { PersonalizedPlan };

export default compose(
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(getCareTeam, {
    options: Meowth.apolloOptionsUserId,
    name: "careTeam",
  }),
  connect(null, { openModal }),
  latestInitialAssessment(),
)(PersonalizedPlan);
