"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toTagLanguageOptions = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var toTagLanguageOptions = exports.toTagLanguageOptions = function toTagLanguageOptions(careProviderTags, languagesTags) {
  var copy = (0, _toConsumableArray2["default"])(careProviderTags);
  var fieldKey = 'languagesTags';
  return copy.map(function (tag) {
    return {
      id: tag.englishName,
      name: tag.englishName,
      label: tag.englishName,
      value: tag.englishName,
      kind: 'LANGUAGE',
      metadata: {
        fieldKey: fieldKey,
        tagArray: languagesTags
      }
    };
  });
};