import { Flex, Box, useDisclosure } from "@chakra-ui/react";
import { NavHelpBtn } from "design-system/components/custom/headers/HeaderButtons";
import { MemberHelpCard } from "components";
import { SHFlyout } from "design-system/components";
import { SpringLogoIcon } from "design-system/assets";
import { useTranslation } from "react-i18next";

const MainHeader = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { t } = useTranslation(["memberHelpCard", "limitedLangMemberHelpCard"]);

  return (
    <Box>
      <Box as="nav" aria-label="Navigation">
        <Flex w="100vw" h="82px" justify="space-between" align="center">
          <Box pl={["v-16", "v-16", "39px", "39px"]}>
            <SpringLogoIcon />
          </Box>

          <Box mr={["v-16", "v-16", "45px", "45px"]}>
            <NavHelpBtn onClick={onToggle} />
          </Box>
        </Flex>
      </Box>

      <SHFlyout
        isOpen={isOpen}
        onClose={onToggle}
        onOpen={onOpen}
        size="md"
        headerText={t("limitedLangMemberHelpCard:footerHeader")}
        isMemberHelpFlyout={true}
      >
        <MemberHelpCard closeFlyout={onClose} />
      </SHFlyout>
    </Box>
  );
};

export default MainHeader;
