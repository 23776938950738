import { AppointmentKind } from "@spring/constants";

interface Provider {
  id: string;
}

interface Appointment {
  kind: AppointmentKind;
  provider_id: string;
  status: string;
  attended?: string;
}

export const hasHadSpecialtyCareNavigationAppointment = (
  provider: Provider,
  pastAppointments: Appointment[],
): boolean => {
  return !!pastAppointments?.find(
    (appointment) =>
      appointment.kind === AppointmentKind.InitialSpecialtyCareNavigation &&
      appointment.provider_id == provider.id &&
      appointment.status == "Booked" &&
      (!appointment.attended || appointment.attended?.toLowerCase() === "kept"),
  );
};
