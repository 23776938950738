import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Label from '../Label';
import Stout from '../../typography/Stout';

import { customStyles, customTheme } from './reactSelectStyles';
import { formatInitialValue } from './helpers';
import styles from './styles.module.scss';

const RedesignSelectInput = ({
	formKey,
	fieldKey,
	options = [],
	multi = true,
	customComponents = {},
	customStyleOpts = {},
	disabled = false,
	label,
	placeholder = 'Please select...',
	hideSelectedOptions = false,
	clearable = true,
	valueOverride,
	value,
	handleChange,
	setField,
	required = false,
	customLabelComponent = null,
	tracking,
	dataCy,
}) => {

	const initialValue = valueOverride || value;

	const handleSelect = (selected, actionMeta) => {
		if (tracking) {
			tracking(selected);
		}

		if (handleChange) {
			handleChange(selected, value);
		} else {

			let formattedSelection;
			if (multi) {
				formattedSelection = selected.map(
					(item) => item.value
				);
			} else {
				formattedSelection = selected?.value;
			}

			switch (actionMeta.action) {
				case 'remove-value':
				case 'pop-value':
					if (actionMeta.removedValue.isFixed && actionMeta.removedValue.isFixed) {
						return;
					}
					break;
				case 'deselect-option':
					if (actionMeta.option.isFixed && actionMeta.option.isFixed) {
						return;
					}
					break;
			}

			setField(
				formKey,
				fieldKey,
				formattedSelection,
				true,
			);


		}
	};
	const renderSelect = () => {

		return (
			<Select
				dataCy={dataCy}
				id={`${formKey}-${fieldKey}`}
				value={formatInitialValue(
					initialValue,
					options,
					multi
				)}
				components={customComponents}
				options={options}
				isMulti={multi}
				closeMenuOnSelect={!multi}
				isDisabled={disabled}
				placeholder={placeholder}
				hideSelectedOptions={hideSelectedOptions}
				onChange={handleSelect}
				isClearable={clearable}
				styles={customStyles(customStyleOpts)}
				theme={(provided) => customTheme(provided)}
			/>
		);
	};

	if (label) {
		return (
			<div className={styles.selectWrapper}>
				{renderSelect()}
				{customLabelComponent || (
					<Label
						theme={'simple'}
						disabled={disabled}
						formKey={formKey}
						fieldKey={fieldKey}
						required={required}
					>
						<Stout inline>{label}</Stout>
					</Label>
				)}
			</div>
		);
	}

	return renderSelect();
};

RedesignSelectInput.propTypes = {
	disabled : PropTypes.bool,
	fieldKey : PropTypes.string,
	formKey  : PropTypes.string,
	label    : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	setField : PropTypes.func,
	value    : PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.string,
		PropTypes.number,
	]),
	options       : PropTypes.arrayOf(PropTypes.shape),
	handleChange  : PropTypes.func,
	multi         : PropTypes.bool,
	valueOverride : PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.string,
		PropTypes.number,
	]),
	customStyleOpts      : PropTypes.object,
	hideSelectedOptions  : PropTypes.bool,
	clearable            : PropTypes.bool,
	customComponents     : PropTypes.objectOf(PropTypes.node),
	placeholder          : PropTypes.string,
	required             : PropTypes.bool,
	customLabelComponent : PropTypes.node,
	tracking             : PropTypes.func,
};

export default RedesignSelectInput;
