import { Button, Link } from "@springcare/sh-component-library";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import NextLink from "next/link";
import { useEffect } from "react";

import { useTranslation } from "hooks/react-i18next";
import {
  trackBrowseRecommendedTherapistsLinkOnMomentsCompletionModal,
  trackMaybeLaterOnMomentsCompletionModal,
  trackTherapyPromptLoaded,
} from "modules/MemberDashboard/Moments/components/analytics";
import routes from "routes/MemberDashboardRoutes";

type TherapyPromptProps = {
  closeModal: () => void;
  exerciseId: string;
  exerciseTitle: string;
  exerciseCategory: unknown;
  exerciseMediaType: unknown;
  feeling: string;
};

export const TherapyPrompt: React.FC<
  React.PropsWithChildren<TherapyPromptProps>
> = ({
  closeModal,
  exerciseId,
  exerciseTitle: exerciseName,
  exerciseCategory,
  exerciseMediaType,
  feeling,
}) => {
  const maybeLaterButtonVariant = useBreakpointValue([
    "no-emphasis",
    null,
    "low-emphasis",
  ]);
  const { t } = useTranslation("moments");

  const handleSeeTherapistsLinkClick = () => {
    trackBrowseRecommendedTherapistsLinkOnMomentsCompletionModal(
      feeling,
      exerciseId,
      exerciseName,
      exerciseCategory,
      exerciseMediaType,
    );

    closeModal();
  };

  const handleMaybeLaterButtonClick = () => {
    trackMaybeLaterOnMomentsCompletionModal(
      feeling,
      "therapy",
      exerciseId,
      exerciseName,
      exerciseCategory,
      exerciseMediaType,
    );

    closeModal();
  };

  useEffect(() => {
    trackTherapyPromptLoaded();
  }, []);

  return (
    <Box>
      <Flex
        flexDirection={["column-reverse", null, "row"]}
        justifyContent="flex-end"
      >
        <Button
          variant={maybeLaterButtonVariant}
          alignItems="center"
          mr={["v-none", null, "v-8"]}
          mt={["v-8", null, "v-none"]}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          onClick={handleMaybeLaterButtonClick}
        >
          {t("completeExerciseModal.maybeLater")}
        </Button>
        <NextLink href={{ pathname: routes.RecommendedTherapists.as }} passHref>
          <Button
            as={Link}
            alignItems="center"
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            sx={{
              _active: { color: "primary-on-base" },
              _focus: { color: "primary-on-base" },
            }}
            onClick={handleSeeTherapistsLinkClick}
          >
            {t("completeExerciseModal.seeRecommendedTherapists")}
          </Button>
        </NextLink>
      </Flex>
    </Box>
  );
};
