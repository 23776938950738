import { useState } from "react";
import { Alert } from "@springcare/sh-component-library";
import { Stack, Text, Link } from "@chakra-ui/react";
import { VideoChat } from "design-system/index";
import usePostTemporaryConsents from "components/templates/SHSessionRoom/hooks/usePostTemporaryConsents";
import { useTranslation } from "hooks/react-i18next";
import {
  useParticipantMetadata,
  useSessionAuthentication,
} from "context/SessionRoomContext";
import {
  ParticipantType,
  SessionViewStatus,
} from "components/templates/SHSessionRoom/types";
import { useCompassAuthRedirect } from "components/templates/SHSessionRoom/hooks/useCompassAuthRedirect";
import ProviderStatusBadge from "./ProviderStatusBadge";

interface SessionConsentJoinAlertProps {
  handleJoin: () => void;
  appointmentId: string;
  sessionData: any;
  sessionViewStatus: SessionViewStatus;
}

const SessionConsentJoinAlert = ({
  handleJoin,
  appointmentId,
  sessionData,
  sessionViewStatus,
}: SessionConsentJoinAlertProps) => {
  const { participantType, participantId } = useParticipantMetadata();
  const {
    authDisclosure,
    authenticated,
    setAfterAuthAction,
    setQuitAuthAction,
  } = useSessionAuthentication();
  const [loadingButton, setLoadingButton] = useState(null);
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionConsentJoin",
  });

  const postTemporaryConsents = usePostTemporaryConsents();
  const redirectForAuth = useCompassAuthRedirect();

  const handleClick = (consented, buttonType) => {
    setLoadingButton(buttonType);
    postTemporaryConsents({
      appointment_id: appointmentId,
      participant_id: participantId,
      consented,
    }).then(() => {});
    if (
      consented &&
      participantType === ParticipantType.Provider &&
      !authenticated
    ) {
      setAfterAuthAction(() => () => {
        handleJoin();
      });

      setQuitAuthAction(() => () => {
        handleJoin();
      });

      authDisclosure.onOpen();
      redirectForAuth();
    } else {
      handleJoin();
    }
  };

  return (
    <Stack>
      <Alert
        showIcon={"on"}
        icon={VideoChat}
        colorScheme="base"
        content={t("pleaseConfirmConsent")}
        showTitle="on"
        size="small"
        title={t("consentToAudioRecording")}
        primaryAction={{
          children: t("agreeJoin"),
          onClick: () => handleClick(true, "primary"),
          isLoading: loadingButton === "primary",
          disabled: loadingButton === "secondary",
          _active: { color: "primary-on-base" },
          _focus: { color: "primary-on-base" },
        }}
        secondaryAction={{
          children: t("declineJoin"),
          onClick: () => handleClick(false, "secondary"),
          disabled: loadingButton === "primary",
          isLoading: loadingButton === "secondary",
        }}
        type="standard"
      />
      <Text pl={3} pt={2}>
        Read our{" "}
        <Link href="https://www.springhealth.com/terms-of-service">Terms</Link>{" "}
        ,{" "}
        <Link href="https://www.springhealth.com/privacy-policy">
          Privacy Policy
        </Link>
        , or{" "}
        <Link href="https://careteam.springhealth.com/hc/en-us/categories/32233872553492-Session-Recordings-and-Privacy">
          Help Center
        </Link>{" "}
        for more
      </Text>
      {participantType === ParticipantType.Member &&
        sessionViewStatus === SessionViewStatus.MemberIsWaiting && (
          <ProviderStatusBadge sessionData={sessionData} />
        )}
    </Stack>
  );
};
export default SessionConsentJoinAlert;
