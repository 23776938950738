import { gql } from "@apollo/client";

const getMemberStatement = gql`
  query getMemberStatementPdf(
    $member_id: ID!
    $statement_type: StatementEnumType!
  ) {
    member_statement_pdf(
      member_id: $member_id
      statement_type: $statement_type
    ) {
      pdf_file
      file_name
    }
  }
`;

export default getMemberStatement;
