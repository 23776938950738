import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const TargetSvg = (props) => (
  <svg
    className={styles[`size-${props.size}`]}
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 21C5 29.8366 12.1634 37 21 37C29.8366 37 37 29.8366 37 21C37 12.1634 29.8366 5 21 5C12.1634 5 5 12.1634 5 21ZM42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21ZM15 21C15 24.3137 17.6863 27 21 27C24.3137 27 27 24.3137 27 21C27 17.6863 24.3137 15 21 15C17.6863 15 15 17.6863 15 21ZM32 21C32 27.0751 27.0751 32 21 32C14.9249 32 10 27.0751 10 21C10 14.9249 14.9249 10 21 10C27.0751 10 32 14.9249 32 21Z"
      fill="#068464"
    />
  </svg>
);

TargetSvg.propTypes = {
  size: PropTypes.string,
};

TargetSvg.defaultProps = {
  size: "lg",
};

export default TargetSvg;
