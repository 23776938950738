const formKey = 'browseProviderTags';
const formKeyTemp = 'browseProviderTagsTemp';
const conditionsFieldKey = 'conditionsTags';
const specialtiesFieldKey = 'specialtiesTags';
const languagesFieldKey = 'languagesTags';
const gendersFieldKey = 'gendersTags';
const ethnicitiesFieldKey = 'ethnicitiesTags';
const fixedFieldKey = 'fixedTag';
const sessionTypesFieldKey = 'sessionTypeTags';
const daysOfWeekFieldKey = 'daysOfWeekTags';
const timeOfDayFieldKey = 'timeOfDayTags';

module.exports = {
	formKey,
	formKeyTemp,
	conditionsFieldKey,
	specialtiesFieldKey,
	languagesFieldKey,
	gendersFieldKey,
	ethnicitiesFieldKey,
	fixedFieldKey,
	sessionTypesFieldKey,
	daysOfWeekFieldKey,
	timeOfDayFieldKey,
};