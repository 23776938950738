import { API_BASE, API_GRAPHQL } from "constants/api";

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import fetch from "isomorphic-fetch";
import { ddGlobalSessionId } from "utils/telemetry";

let apolloClient = null;
const baseUri = `${API_BASE}${API_GRAPHQL}`;

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

const authMiddleware = new ApolloLink((operation, forward) => {
  if (localStorage && typeof window !== "undefined") {
    const access_token = localStorage.getItem("access_token");
    const token_type = localStorage.getItem("token_type");

    operation.setContext({
      headers: {
        Authorization: `${token_type} ${access_token}`,
        "x-springhealth-client-path": window.location.pathname,
        "x-springhealth-magic-id": ddGlobalSessionId.getSessionId(),
      },
    });
  }

  return forward(operation);
});

const cacheMiddleware = new ApolloLink((operation, forward) => {
  try {
    const definitions = operation?.query?.definitions;
    const isMutation = definitions?.some(
      (request) => request.operation === "mutation",
    );

    if (isMutation) {
      const { cache } = operation.getContext();
      const cacheKeys = Object.keys(cache?.data?.data);
      const memberCacheKey = cacheKeys?.find((key) =>
        key.startsWith("Member:"),
      );

      if (memberCacheKey) {
        cache.evict({ id: memberCacheKey });
        cache.gc();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("error in cache middleware", e);
  }

  return forward(operation);
});

const http = (uri) => {
  return new HttpLink({
    uri, // Server URL (must be absolute)
    opts: {
      credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
    },
  });
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError && networkError.statusCode === 401) {
      // TODO: add datadog error handling
      // eslint-disable-next-line no-console
      console.error("Apollo Error: User un-authed", networkError);
    }

    if (!graphQLErrors && !networkError) {
      return forward(operation);
    }
  },
);

function create(initialState, uri = baseUri) {
  return new ApolloClient({
    connectToDevTools: process.browser,

    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: from([authMiddleware, errorLink, cacheMiddleware, http(uri)]),
    cache: new InMemoryCache().restore(initialState || {}),
  });
}

export function initApollo(initialState, api) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, api);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, api);
  }

  return apolloClient;
}
