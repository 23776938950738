//

export const UPDATE_FIND_MY_MEMBERSHIP_MODEL =
  "signUp/UPDATE_FIND_MY_MEMBERSHIP_MODEL";
export const RESET_FIND_MY_MEMBERSHIP_MODEL =
  "signUp/RESET_FIND_MY_MEMBERSHIP_MODEL";
export const UPDATE_FAMILY_SIGNUP_MODEL = "signUp/UPDATE_FAMILY_SIGNUP_MODEL";
export const RESET_FAMILY_SIGNUP_MODEL = "signUp/RESET_FAMILY_SIGNUP_MODEL";
export const UPDATE_VERIFICATION_MODEL = "signUp/UPDATE_VERIFICATION_MODEL";
export const RESET_VERIFICATION_MODEL = "signUp/RESET_VERIFICATION_MODEL";
