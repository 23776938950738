import PropTypes from "prop-types";
import { Heading, Box, Flex } from "design-system/components";
import { Col } from "@spring/smeargle";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { ProviderRole } from "@spring/constants";
import { getLicenses, getCategories } from "./utils";
import { colors } from "design-system/theme/base/colors";
import CareTeamCardAvatar from "./CareTeamCardAvatar";
import DifferentProvider from "./DifferentProvider";
import ShowSchedulingLinks from "./ShowSchedulingLinks";
import { formatMemberExactAge } from "utils/displayHelpers";
import AppointmentSection from "./AppointmentSection";

const CareTeamCard = ({
  providerRole,
  providerData,
  providerAttributes,
  scheduleAction,
  showChange,
  infoAction,
  changeAction,
  scheduleCoachWithCN,
  browseOthers,
  appointmentKind,
  memberData,
  showGlobalExperience,
  scheduleConfirmation,
  onSchedule = null,
  isGlobalMemberWithNoVisitsRemaining = false,
  isTherapistLicensed = true,
  isMedicationManagerLicensed = true,
  isCNAvailabilityModal = false,
  onScheduleModalOpen,
  isCoachLicensed = false,
  ...props
}) => {
  const { t } = useTranslation(["careVisits", "careProvider", "a11y"]);
  const isMinorMember = memberData?.user?.member?.minor;
  const isMemberUnderSix =
    formatMemberExactAge(memberData?.user?.member?.date_of_birth) < 6;

  if (scheduleCoachWithCN) {
    ({
      providerRole,
      providerData,
      scheduleAction,
      showChange,
      infoAction,
      changeAction,
    } = scheduleCoachWithCN);
  }

  const isProviderACareNavigator =
    providerRole === ProviderRole.CareNavigator ||
    providerRole === ProviderRole.SpecialtyCareNavigator;
  const isProviderASpecializedCareNavigator =
    providerRole === "specialized_care_navigator";
  const isProviderATherapist = providerRole === ProviderRole.Therapist;
  // not a care navigator, and not a minor then we show it; if you are a minor that is not under six, we will show the link
  const shouldDisplayBrowseOthersLink =
    !isProviderACareNavigator &&
    !isProviderASpecializedCareNavigator &&
    (!isMinorMember ||
      (isMinorMember && !isMemberUnderSix && isProviderATherapist));
  return (
    <>
      <Col sm={12} lg={8}>
        <Flex flex={1} flexDirection="column">
          <Flex data-cy={`${providerRole}-card`}>
            <Flex h="100%" pr={16}>
              <CareTeamCardAvatar
                providerData={providerData}
                styles={styles}
                providerAttributes={providerAttributes}
                infoAction={infoAction}
                width={props.avatarWidth}
                noExtraSpace={props.avatarNoExtraSpace}
              />
            </Flex>

            <Flex
              alignItems="flex-start"
              flexDirection="column"
              justifyContent="center"
            >
              <div
                className={styles.providerName}
                aria-label={t("a11y:providerName", {
                  providerName: providerData.name,
                })}
              >
                <Heading
                  as="h3"
                  data-cy={`${providerRole}-name`}
                  color={colors.tertiary["900"]}
                  fontSize={props.nameFontSize ?? { base: 16, md: 20 }}
                  fontWeight={600}
                >
                  {providerData.name}
                </Heading>
              </div>
              <Flex
                alignItems="center"
                className={styles.providerRoleContainer}
              >
                <Flex style={{ fontSize: 16 }} className={styles.providerIcon}>
                  {providerAttributes.smallIcon}
                </Flex>
                <h2 style={{ fontSize: 16 }} className={styles.providerRole}>
                  {getCategories(providerRole, providerData, t)}
                  {getLicenses(providerData)}
                </h2>
              </Flex>
              {/* For global, show description for all providers. Show only CN description for US */}
              {/* Show therapist or med manager description for all the members if they are not licensed in member country*/}
              {(showGlobalExperience ||
                providerRole === ProviderRole.CareNavigator ||
                (providerRole === ProviderRole.Therapist &&
                  !isTherapistLicensed) ||
                (providerRole === ProviderRole.MedicationManager &&
                  !isMedicationManagerLicensed)) && (
                <div className={styles.providerDescription}>
                  {providerAttributes.description}
                </div>
              )}
            </Flex>
          </Flex>
          {providerRole !== ProviderRole.CareNavigator &&
            !isGlobalMemberWithNoVisitsRemaining &&
            !isMemberUnderSix && (
              <Box
                w="100%"
                flexGrow={1}
                pt={30}
                display={{ base: "none", md: "block" }}
              >
                {shouldDisplayBrowseOthersLink && (
                  <DifferentProvider
                    isMobile={false}
                    providerData={providerData}
                    providerRole={providerRole}
                    browseOthers={browseOthers}
                    changeAction={changeAction}
                  />
                )}
              </Box>
            )}
        </Flex>
      </Col>
      {/* These three "Col" sections function like "Breakpoints" at different sizes.
        The Grid set up in CareTeamList.js makes it so that using Breakpoint elements will not display the Columns correctly. */}
      {providerRole === ProviderRole.CareNavigator &&
        !isCNAvailabilityModal && (
          <ShowSchedulingLinks
            {...{
              providerRole,
              providerData,
              scheduleAction,
              showChange,
              infoAction,
              changeAction,
              providerAttributes,
            }}
          />
        )}
      {providerRole &&
        (providerRole !== ProviderRole.CareNavigator ||
          isCNAvailabilityModal) &&
        (providerRole === ProviderRole.Therapist
          ? isTherapistLicensed
          : true) &&
        (providerRole === ProviderRole.MedicationManager
          ? isMedicationManagerLicensed
          : true) &&
        (providerRole === ProviderRole.Coach ? isCoachLicensed : true) &&
        !isGlobalMemberWithNoVisitsRemaining && (
          <>
            <Col
              alignment="center"
              md={12}
              lg={4}
              width={props.slotsWidth}
              maxWidth={props.slotsMaxWidth}
            >
              <AppointmentSection
                providerData={providerData}
                providerRole={providerRole}
                memberData={memberData}
                appointmentKind={appointmentKind}
                showGlobalExperience={showGlobalExperience}
                onSchedule={onSchedule}
                isCNAvailabilityModal={isCNAvailabilityModal}
                scheduleConfirmation={scheduleConfirmation}
                disableTracking={props.disableTracking}
                isAssignedCN={props.isAssignedCN}
                providerOrder={props.providerOrder}
                singleTabTitle={props.singleTabTitle}
                onNoAppointments={props.onNoAppointments}
                onScheduleModalOpen={onScheduleModalOpen}
                isProviderASpecializedCareNavigator={
                  isProviderASpecializedCareNavigator
                }
              />
              <Box
                w="100%"
                flexGrow={1}
                pt={shouldDisplayBrowseOthersLink ? 30 : 0}
                display={{ base: "block", md: "none" }}
              >
                {shouldDisplayBrowseOthersLink && (
                  <DifferentProvider
                    isMobile
                    providerData={providerData}
                    providerRole={providerRole}
                    browseOthers={browseOthers}
                    changeAction={changeAction}
                  />
                )}
              </Box>
            </Col>
          </>
        )}
    </>
  );
};

CareTeamCard.propTypes = {
  providerAttributes: PropTypes.object,
};

export default CareTeamCard;
