import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ModalSheet } from "@springcare/sh-component-library";
import { Flex, Text, HStack, CloseButton, useToast } from "@chakra-ui/react";
import { VCircleCheckIcon } from "@springcare/verdant-icons-react";
import GenAIFeedbackForm from "components/form/GenAIFeedbackForm/GenAIFeedbackForm";
import createPersonalizedProviderBioFeedbackMutation from "operations/mutations/general/createPersonalizedProviderBioFeedback";
import {
  trackFeedbackModalClosed,
  trackFeedbackSubmitted,
} from "components/organisms/ProviderCardGenAI/analytics";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  providerId: string;
  personalizedBioData: {
    id: string;
    explanation_specialties: string;
    explanation_modalities: string;
  } | null;
  feedback: {
    feedback_text: string | undefined;
    comments: string | undefined;
    feedback_sentiment: string | undefined;
    feedback_enum: string | undefined;
  } | null;
  setFeedback: React.Dispatch<
    React.SetStateAction<{
      feedback_text: string | undefined;
      comments: string | undefined;
      feedback_sentiment: string | undefined;
      feedback_enum: string | undefined;
    } | null>
  >;
  setHasNegFeedback: React.Dispatch<React.SetStateAction<boolean>>;
};

type Notification = {
  text: string;
  status: string;
};

const GenAIFeedbackModal = ({
  isOpen,
  onClose,
  feedback,
  setFeedback,
  setHasNegFeedback,
  providerId,
  personalizedBioData,
}: Props) => {
  const { t } = useTranslation("careProvider");
  const [notification, setNotification] = useState<Notification | null>(null);
  const [createPersonalizedProviderFeedback] = useMutation(
    createPersonalizedProviderBioFeedbackMutation,
  );
  const toast = useToast();

  useEffect(() => {
    if (notification?.text && notification?.status) {
      // TODO: Add this to component-library
      toast({
        duration: 5000,
        isClosable: true,
        status: "success",
        containerStyle: {
          maxWidth: "calc(100% - 32px)",
          width: "calc(100% - 32px)",
          background: "#068262",
          padding: "20px",
          color: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 12px 40px -8px rgba(72, 45, 40, 0.2)",
        },
        render: () => (
          <Flex justify="space-between">
            <HStack spacing={8}>
              <VCircleCheckIcon width={24} height={24} />
              <Text size="body-medium-strong">{notification.text}</Text>
            </HStack>
            <CloseButton onClick={() => toast.closeAll()} />
          </Flex>
        ),
      });
    }
  }, [notification]);

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    const input = {
      ...feedback,
      bio:
        personalizedBioData.explanation_specialties +
        " " +
        personalizedBioData.explanation_modalities,
      care_provider_id: providerId,
      personalized_provider_bio_id: personalizedBioData.id,
    };

    setNotification({
      text: t(
        "recommendedProviders.providerCardGenAI.feedbackModal.successPrompt",
      ),
      status: "success",
    });

    if (feedback.feedback_sentiment === "NEGATIVE") {
      setHasNegFeedback(true);
    }

    const feedbackData = {
      selected_feedback: feedback.feedback_enum,
      feedback_sentiment: feedback.feedback_sentiment,
      feedback_text: feedback.comments ? true : false,
    };
    trackFeedbackSubmitted(feedbackData);
    closeModal();

    await createPersonalizedProviderFeedback({
      variables: { input },
    });
  };

  const closeModal = () => {
    trackFeedbackModalClosed();
    onClose();
  };

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={() => closeModal()}
      placement="center"
      title={t("recommendedProviders.providerCardGenAI.feedbackModal.title")}
      caption={t(
        "recommendedProviders.providerCardGenAI.feedbackModal.caption",
      )}
      size="lg"
    >
      <GenAIFeedbackForm
        feedback={feedback}
        setFeedback={setFeedback}
        handleFeedbackSubmit={handleFeedbackSubmit}
      />
    </ModalSheet>
  );
};

export default GenAIFeedbackModal;
