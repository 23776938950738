import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

export const ResponsiveCardPadding = (props) => (
  <div className={styles.default}>{props.children}</div>
);

ResponsiveCardPadding.propTypes = {
  children: PropTypes.node.isRequired,
};
