/* eslint-disable react/jsx-key */
import { createIcon } from "@chakra-ui/react";

export const Flexible = createIcon({
  displayName: "Flexible",
  viewBox: "0 0 24 24",
  path: [
    <path
      fill="currentColor"
      d="M2 17C2 11.4772 6.47715 7 12 7C17.5228 7 22 11.4772 22 17C22 17.5523 22.4477 18 23 18C23.5523 18 24 17.5523 24 17C24 10.3726 18.6274 5 12 5C5.37258 5 0 10.3726 0 17C0 17.5523 0.447715 18 1 18C1.55228 18 2 17.5523 2 17Z"
    />,
    <path
      fill="currentColor"
      d="M12 11C8.68629 11 6 13.6863 6 17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17C4 12.5817 7.58172 9 12 9C16.4183 9 20 12.5817 20 17C20 17.5523 19.5523 18 19 18C18.4477 18 18 17.5523 18 17C18 13.6863 15.3137 11 12 11Z"
    />,
    <path
      fill="currentColor"
      d="M12 15C10.8954 15 10 15.8954 10 17C10 17.5523 9.55229 18 9 18C8.44771 18 8 17.5523 8 17C8 14.7909 9.79086 13 12 13C14.2091 13 16 14.7909 16 17C16 17.5523 15.5523 18 15 18C14.4477 18 14 17.5523 14 17C14 15.8954 13.1046 15 12 15Z"
    />,
  ],
  defaultProps: {
    color: "tertiary.700",
  },
});
