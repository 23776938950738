import { useQuery } from "@apollo/client";
import getCareProvider from "modules/MemberDashboard/GatedBooking/graphql/queries/getCareProvider";

export function useCareProviderDetails(providerId) {
  return useQuery(getCareProvider, {
    variables: {
      id: providerId,
    },
    skip: !providerId,
  });
}
