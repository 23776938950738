import { gql } from "@apollo/client";
export const getMemberPaymentMethod = gql`
  query fetchMemberPaymentMethod($member_id: ID!) {
    member_payment_method(member_id: $member_id) {
      id
      exp_month
      exp_year
      brand
      country
      funding
      last4
      auto_pay_enabled
    }
  }
`;
