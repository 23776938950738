"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 * Takes a path, a value, and the object to set into.
 * Sets the value while keeping the original object intact.
 *
 * @name setIn
 * @param {(string | Array<String | Number>)} path
 * @param {*} value
 * @param {Object} obj
 * @returns {Object} Returns a new object with updated value.
 *
 * @example
 * const x = {a: { b: 'overwrite me!'}};
 * const y = setIn('a.b', 'c', x);
 *
 * console.log(x.a.b, y.a.b) // 'overwrite me!', 'c'
 */

function curried(path, value, obj) {
  return (0, _fp.setWith)(_fp.clone, path, value, (0, _fp.clone)(obj));
}
var setIn = (0, _fp.curry)(curried);
var _default = exports["default"] = setIn;