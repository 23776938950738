import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Router, { useRouter } from "next/router";
import Meowth from "@spring/meowth";
import {
  generateTeenInitialAssessment,
  getMemberInfoForTeenAssessment,
} from "components/templates/TeenAssessment/queries";
import { submitTeenAssessmentResponses } from "operations/mutations/assessment";
import { useTeenAssessmentStatus } from "modules/MemberDashboard/Teen/Home/hooks/useTeenAssessmentStatus";
import routes from "routes";
import { assessments as assessmentConstants } from "@spring/constants";
import { TRACK_EVENT } from "utils/mixpanel";
import { TEEN_ASSESSMENT_TELEMETRY_TYPES } from "components/templates/TeenAssessment/constants/telemetryValues";
import MemberDashboardPageWrapper from "components/templates/MemberDashboard/PageWrapper";
import TeenAssessmentWrapper from "components/templates/TeenAssessment/components/TeenAssessmentWrapper";
import MinorAppointmentDetails from "./MinorAppointmentDetails/MinorAppointmentDetails";
import { BeforeWeStart } from "./components/BeforeWeStart";
import ShareWithYourTeen from "./components/ShareWithYourTeen";
import { LoadingState } from "./components/LoadingState";
import { ErrorScreen } from "./components/ErrorScreen";
import { useStartTeenAssessmentMutation } from "./hooks/useStartTeenAssessmentMutation";
import { getIterableCampaignInfo } from "utils/localStorage";

export const TeenAssessment = () => {
  const memberInfo = useQuery(getMemberInfoForTeenAssessment, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const {
    assessmentStatus: teenAssessmentStatus,
    loading: teenAssessmentStatusLoading,
  } = useTeenAssessmentStatus(!Meowth.apolloOptionsUserId());
  const { assessmentStatuses } = assessmentConstants;

  useEffect(() => {
    if (
      !teenAssessmentStatusLoading &&
      teenAssessmentStatus === assessmentStatuses.FINISHED
    ) {
      const { to, as } = routes.TeenHome;
      Router.replace(
        {
          pathname: to,
        },
        as,
      );
    }
  }, [
    assessmentStatuses.FINISHED,
    teenAssessmentStatus,
    teenAssessmentStatusLoading,
  ]);

  const router = useRouter();
  const appointmentId = router?.query?.appointment_id as string;

  const [renderAssessmentPrompt, setRenderAssessmentPrompt] = useState(true);
  const [renderBeforeWeStart, setRenderBeforeWeStart] =
    useState(!appointmentId);
  const [renderShareWithYourTeen, setRenderShareWithYourTeen] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [createTeenAssessmentMutation, { data: createdAssessmentData }] =
    useMutation(generateTeenInitialAssessment, {
      variables: {
        member_id: memberInfo?.data?.user?.member?.id,
        campaign: getIterableCampaignInfo(),
      },
      onCompleted: (data) => {
        const isRequested =
          data.generateTeenAssessment.assessment.status === "Requested";
        if (isRequested) {
          startTeenAssessmentMutation();
        } else {
          setAssessmentData(data.generateTeenAssessment.assessment);
        }
        setLoading(false);
      },
    });

  const [startTeenAssessmentMutation] = useStartTeenAssessmentMutation(
    createdAssessmentData?.generateTeenAssessment?.assessment?.id,
    setAssessmentData,
    setLoading,
  );

  const [submitResponsesMutation] = useMutation(submitTeenAssessmentResponses, {
    onCompleted: (data) => {
      setAssessmentData(data.submitAssessmentResponses.assessment);
      setLoading(false);
    },
  });

  const createTeenAssessment = () => {
    setLoading(true);
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Next", {
      page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_START,
      spring_doc_id: "teenstartinitialassessment001",
    });
    createTeenAssessmentMutation();
    setRenderAssessmentPrompt(false);
  };

  const submitResponses = (formData) => {
    setLoading(true);

    submitResponsesMutation({
      variables: {
        questionnaire_id: assessmentData.current_questionnaire.id,
        prompt_responses: formData,
        campaign: getIterableCampaignInfo(),
      },
    });
  };

  if (memberInfo.loading || loading) {
    return <LoadingState />;
  }

  if (memberInfo.data) {
    const {
      first_name,
      member: { date_of_birth, manager },
    } = memberInfo.data.user;

    const isUnmanagedTeen = manager === null;

    if (renderAssessmentPrompt) {
      return (
        <MemberDashboardPageWrapper
          // @ts-ignore
          float={false}
        >
          {renderBeforeWeStart && (
            <BeforeWeStart
              firstName={first_name}
              memberDateOfBirth={date_of_birth}
              createTeenAssessment={createTeenAssessment}
            />
          )}
          {renderShareWithYourTeen && (
            <ShareWithYourTeen memberFirstName={first_name} />
          )}
          {!renderBeforeWeStart && !renderShareWithYourTeen && (
            <MinorAppointmentDetails
              memberFirstName={first_name}
              isUnmanagedTeen={isUnmanagedTeen}
              appointmentId={appointmentId}
              setRenderBeforeWeStart={setRenderBeforeWeStart}
              setRenderShareWithYourTeen={setRenderShareWithYourTeen}
              customerId={memberInfo?.data?.user?.cohort?.customer?.id}
            />
          )}
        </MemberDashboardPageWrapper>
      );
    }

    // TODO better handle the assessment loading
    if (assessmentData) {
      return (
        <TeenAssessmentWrapper
          teenAssessmentData={assessmentData}
          memberInfo={memberInfo?.data?.user}
          submitResponses={submitResponses}
        />
      );
    }
    return <LoadingState />;
  }
  return <ErrorScreen />;
};
