import { ModalSheet } from "@springcare/sh-component-library";
import { Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpecialtyCareGetStarted } from "./SpecialtyCareGetStarted";

type SpecialtyCareFlyoutType = {
  isOpen: boolean;
  onClose: () => void;
  questionnaireKind?: string;
  isAssessmentCompleted?: boolean;
  showSpecialtyCareModal?: boolean;
  hideSpecialtyCareModal?: () => void;
};

const SpecialtyCareFlyoutBody = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.specialtyCare",
  });

  return (
    <>
      <Heading size="body-medium-strong" fontWeight={600} mb="v-8">
        {t("questions.first.heading")}
      </Heading>
      <Text size="body-medium" mb={24}>
        {t("questions.first.body")}
      </Text>

      <Heading size="body-medium-strong" fontWeight={600} mb="v-8">
        {t("questions.second.heading")}
      </Heading>
      <Text size="body-medium" mb={24}>
        {t("questions.second.body")}
      </Text>

      <Heading size="body-medium-strong" fontWeight={600} mb="v-8">
        {t("questions.third.heading")}
      </Heading>
      <Text size="body-medium" mb={24}>
        {t("questions.third.body")}
      </Text>
    </>
  );
};

export const SpecialtyCareFlyout = ({
  isOpen,
  onClose,
  questionnaireKind,
  isAssessmentCompleted,
  showSpecialtyCareModal = false,
  hideSpecialtyCareModal,
}: SpecialtyCareFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.specialtyCare",
  });
  const [isSm] = useMediaQuery("(max-width: 480px)");

  const [isSpecialtyCareModalOpen, setSpecialtyCareModalOpen] = useState(
    showSpecialtyCareModal,
  );

  const openSpecialtyCareModal = () => {
    onClose(); // Closes the ModalSheet
    setSpecialtyCareModalOpen(true);
  };

  const closeSpecialtyCareModal = () => {
    setSpecialtyCareModalOpen(false);
    hideSpecialtyCareModal?.();
  };

  useEffect(() => {
    if (showSpecialtyCareModal) setSpecialtyCareModalOpen(true);
  }, [showSpecialtyCareModal]);
  useEffect(() => {
    if (isAssessmentCompleted) openSpecialtyCareModal();
  }, []);

  return (
    <>
      <ModalSheet
        placement={isSm ? "bottom" : "right"}
        title={t("heading")}
        footerVariant="high-emphasis"
        footerActions={[
          {
            label: t("buttonText"),
            onClick: openSpecialtyCareModal,
          },
        ]}
        isOpen={isOpen}
        onClose={onClose}
        scrim={true}
        size={isSm ? "lg" : "sm"}
      >
        <SpecialtyCareFlyoutBody />
      </ModalSheet>
      {isSpecialtyCareModalOpen && (
        <SpecialtyCareGetStarted
          closeModal={closeSpecialtyCareModal}
          questionnaireKind={questionnaireKind}
          isAssessmentCompleted={isAssessmentCompleted}
        />
      )}
    </>
  );
};
