//

import { flow } from "lodash/fp";
import { setIn } from "@spring/immutability";

import {
  START_LOADING_PROGRESS,
  SET_LOADING_PROGRESS,
  RESET_LOADING_PROGRESS,
  END_LOADING_PROGRESS,
  START_ROUTING,
  END_ROUTING,
} from "actions/app/types";

export const initialState = {
  loading: {
    active: false,
    progress: 0,
  },
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_PROGRESS:
      return flow(
        setIn("loading.progress", 35),
        setIn("loading.active", true),
      )(state);

    case SET_LOADING_PROGRESS:
      if (
        action.progress >= 0 &&
        action.progress <= 100 &&
        state.loading.active
      ) {
        return setIn("loading.progress", action.progress, state);
      }

      return state;

    case END_LOADING_PROGRESS:
      return flow(
        setIn("loading.progress", 100),
        setIn("loading.active", false),
      )(state);

    case RESET_LOADING_PROGRESS:
      return flow(
        setIn("loading.progress", 0),
        setIn("loading.active", false),
      )(state);

    case START_ROUTING:
      return setIn("routing", true, state);

    case END_ROUTING:
      return setIn("routing", false, state);

    default:
      return state;
  }
};

export default app;
