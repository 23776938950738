import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import {
  Content,
  Subtitle,
  Section,
  Button,
  Grid,
  Col,
  Avatar,
  Stout,
  Modal,
} from "@spring/smeargle";
import { closeModal, addNotification } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import { useTranslation } from "react-i18next";

import { Link } from "components/atoms";

import styles from "./styles.module.scss";

import { track } from "utils/mixpanel";

const AvailabilityRequestModal = ({ modalData }) => {
  const { t } = useTranslation("careVisits");
  return (
    <Modal size="md" id={modalIds.availabilityRequestModal}>
      <div className={styles.headline} data-cy="availability-request-modal">
        We've received your request.
      </div>

      <Section size="la">
        <Content>{t("availabilityRequest.willGetBack")}</Content>
      </Section>

      <div className={styles.providerSection}>
        <div className={styles.img}>
          <Avatar
            imgProps={{ alt: modalData.name }}
            src={modalData.avatar}
            height={120}
            width={120}
            round
          />
        </div>
        <Section size="xsm">
          <div className={styles.providerName}>
            <Subtitle bold noMargin>
              {modalData.name}
            </Subtitle>
          </div>
          <div className={styles.providerRoles}>
            <Stout>
              {modalData.roles.join(", ")}, {modalData.licenses.join(", ")}
            </Stout>
          </div>
        </Section>
      </div>

      <Section>
        <Subtitle bold noMargin>
          {t("availabilityRequest.subtitle")}
        </Subtitle>
        <Section>
          <Content>{t("availabilityRequest.availability")}</Content>
        </Section>
        <Section>
          <Grid gutter="16px">
            <Col md={6}>
              {typeof window !== "undefined" && (
                <Button
                  text={t("availabilityRequest.liveChatButtonText")}
                  full
                  flat
                  onClick={() => {
                    track("Manual Request Modal - Live Chat clicked");

                    if (window.zE) {
                      window.open("/chat", "_parent");
                    }
                  }}
                  icon="chat"
                />
              )}
            </Col>
            <Col md={6}>
              {typeof window !== "undefined" && (
                <Link to="tel:+12405585796" aria-label="+1(240)558-5796">
                  <Button
                    text="+1 (240) 558-5796"
                    full
                    flat
                    onClick={() => {
                      track("Manual Request Modal - Phone number clicked");

                      if (window) {
                        window.open("tel:1 240 558 5796", "_parent");
                      }
                    }}
                    icon="phone"
                  />
                </Link>
              )}
            </Col>
          </Grid>
        </Section>
      </Section>
    </Modal>
  );
};

AvailabilityRequestModal.propTypes = {
  modalData: PropTypes.shape({
    avatar: PropTypes.string,
    bio: PropTypes.string,
    care_provider_tags: PropTypes.array,
    distance_miles: PropTypes.number,
    id: PropTypes.string,
    in_person_supported: PropTypes.bool,
    kind: PropTypes.string,
    licenses: PropTypes.array,
    member_id: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    providerRole: PropTypes.string,
    role: PropTypes.string,
    roles: PropTypes.array,
    states: PropTypes.array,
    status: PropTypes.string,
    supported_appointment_mediums: PropTypes.array,
    time_zone: PropTypes.string,
    user_id: PropTypes.string,
  }),
};

export { AvailabilityRequestModal };

export default compose(
  connect(
    (state) => ({
      modalData: getOr(
        {
          licenses: [],
          roles: [],
          states: [],
          care_provider_tags: [],
        },
        `modal.${modalIds.availabilityRequestModal}.data`,
        state,
      ),
    }),
    { addNotification, closeModal },
  ),
)(AvailabilityRequestModal);
