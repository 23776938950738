// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const Section = (props) => (
	<section
		tabIndex={props.tabIndex}
		onClick={props.onClick}
		className={classnames(styles.section, styles[props.size], styles[`padding-${props.padding}`])}
	>
		{props.children}
	</section>
);

Section.defaultProps = {
	size    : 'md',
	padding : 'none',
};

export default Section;
