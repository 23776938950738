import { useEffect, useRef, useCallback } from "react";
import { useVideoPlayer } from "./videoPlayerContext";

export const useVideoPlayerHotKeys = () => {
  const {
    setIsPlaying,
    isPlaying,
    forward10,
    backward10,
    fullScreenButtonTapped,
    isFullScreen,
    captionButtonTapped,
    isCaptionOn,
    setIsMuted,
    isMuted,
  } = useVideoPlayer();

  const stateRef = useRef({ isPlaying, isFullScreen, isCaptionOn, isMuted });
  stateRef.current = { isPlaying, isFullScreen, isCaptionOn, isMuted };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { isPlaying, isMuted } = stateRef.current;
      switch (event.key) {
        case " ":
        case "k":
          setIsPlaying(!isPlaying);
          break;
        case "l":
        case "ArrowRight":
          forward10();
          break;
        case "j":
        case "ArrowLeft":
          backward10();
          break;
        case "f":
          fullScreenButtonTapped();
          break;
        case "c":
          captionButtonTapped();
          break;
        case "m":
          setIsMuted(!isMuted);
          break;
        default:
          break;
      }
    },
    [
      setIsPlaying,
      forward10,
      backward10,
      fullScreenButtonTapped,
      captionButtonTapped,
      setIsMuted,
    ],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return;
};
