import { gql } from "@apollo/client";

import { careTeamMember } from "operations/fragments/careProvider";

// `care_providers` part copied from `src/operations/queries/careProvider/getCareProvider.js`
const getCustomerSite = gql`
  query getCustomerSite($id: ID!) {
    customer_site(id: $id) {
      id
      name
      address
      city
      state
      postal_code
      appointment_directions

      care_providers {
        id
        user_id
        ...careTeamMember
        status
        supported_appointment_kinds
        supported_appointment_mediums
        instructions
        customer_role_name
      }
    }
  }
  ${careTeamMember}
`;

export default getCustomerSite;
