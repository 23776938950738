import {
  Box,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Heading,
  useMediaQuery,
} from "design-system/components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  modalTitle?: string;
  finalFocusRef?: React.RefObject<HTMLElement>;
};

export const SHModal = ({ modalTitle, children, isOpen, onClose }: Props) => {
  const [isMobile] = useMediaQuery("(max-width: 450px)");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"inside"}
      isCentered
      size={isMobile ? "full" : "xl"}
    >
      <ModalOverlay />
      <ModalContent maxW={780}>
        <Box py={32}>
          <ModalCloseButton />
        </Box>
        {modalTitle && (
          <ModalHeader>
            <Heading variant="display" as="h1">
              {modalTitle}
            </Heading>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
