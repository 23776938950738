import { gql } from "@apollo/client";

import question from "./question";

const assessmentToTake = gql`
  fragment assessmentToTake on Assessment {
    id
    kind
    status
    risk
    num_prompts_total
    num_prompts_answered
    display_modal
    version_number
    current_questionnaire {
      id
      kind
      current_prompts {
        id
        position
        title
        subtitle
        newTitle
        newSubtitle
        questions {
          ...question
        }
      }
      questionnaire_kind {
        abbreviation
        label
        condition_name
      }
    }
    questionnaires(version: "improved_sud") {
      id
      kind
      information
      prompts {
        id
      }
    }
  }
  ${question}
`;

export default assessmentToTake;
