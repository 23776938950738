export const momentsExerciseCategories = [
  { name: "All", query: null, tagIndex: 0 },
  { name: "Anxiety", query: "ANXIETY", tagIndex: 1 },
  { name: "Body Image", query: "BODY_IMAGE", tagIndex: 2 },
  { name: "Breakup", query: "BREAKUP", tagIndex: 3 },
  { name: "Burnout", query: "BURNOUT", tagIndex: 4 },
  { name: "Depression", query: "DEPRESSION", tagIndex: 5 },
  { name: "Eating", query: "EATING", tagIndex: 6 },
  { name: "Emotions", query: "EMOTIONS", tagIndex: 7 },
  { name: "Exercise", query: "EXERCISE", tagIndex: 8 },
  { name: "Focus", query: "FOCUS", tagIndex: 9 },
  { name: "Job/Career", query: "JOB_CAREER", tagIndex: 10 },
  { name: "Loneliness", query: "LONELINESS", tagIndex: 11 },
  { name: "Mindfulness", query: "MINDFULNESS", tagIndex: 12 },
  { name: "Parenting", query: "PARENTING", tagIndex: 13 },
  { name: "Personal Growth", query: "PERSONAL_GROWTH", tagIndex: 14 },
  { name: "Relationships", query: "RELATIONSHIPS", tagIndex: 15 },
  { name: "Self-Esteem", query: "SELF_ESTEEM", tagIndex: 16 },
  { name: "Sleep", query: "SLEEP", tagIndex: 17 },
  { name: "Stress", query: "STRESS", tagIndex: 18 },
  { name: "Substance Use", query: "SUBSTANCE_USE", tagIndex: 19 },
];
