import { Link } from "@springcare/sh-component-library";
import {
  Medication,
  PlantInHand,
  PlantLeaves,
  WateringCan,
} from "design-system/assets";
import { Box, Flex, Heading, Image, Text } from "design-system/components";
import { DateTime } from "luxon";
import { TRACK_EVENT } from "utils/mixpanel";

const appointmentTypeIcons = {
  Therapy: PlantLeaves,
  "Medication Management": Medication,
  Coaching: WateringCan,
  "Care Navigation": PlantInHand,
  "Couples Therapy": PlantLeaves,
  "Minor Therapy": PlantLeaves,
};

const formatDateTime = (rawDateTime) =>
  DateTime.fromISO(rawDateTime).toLocaleString(DateTime.DATETIME_FULL);

const AppointmentCard = ({ appointment }) => {
  const { provider } = appointment;
  const AppointmentTypeIcon =
    appointmentTypeIcons[appointment.type] || PlantLeaves;
  const href = `/members/care_visits/${appointment.id}`;
  const providerName = `${provider.first_name} ${provider.last_name}`;

  return (
    <Box
      bg="white"
      borderColor="tertiary.light"
      borderRadius="12px"
      borderWidth="2px"
      h={160}
      my={5}
      overflow="hidden"
      w="100%"
      zIndex={1}
    >
      <Link
        colorScheme="neutral"
        style={{ textDecoration: "none" }}
        href={href}
        onClick={() =>
          TRACK_EVENT.LINK_CLICKED(
            window.location.pathname,
            href,
            "Manage appointment",
            {
              location: "AppointmentCard",
              spring_doc_id: "loginlessConfirmManage01",
            },
          )
        }
      >
        <Flex direction="row" justify="start" align="center">
          <Box height={160} width={140}>
            <Image
              alt={providerName}
              aria-hidden="true"
              borderBottomStartRadius="12px"
              borderTopStartRadius="12px"
              height="100%"
              objectFit="cover"
              src={provider.avatar_cdn_url}
              width="100%"
            />
          </Box>
          <Flex direction="column" justify="center" align="start" ms={5}>
            <Flex direction="row" justify="start" align="center">
              <Text me={2} variant="bodyBold_v1">
                {appointment.type}
              </Text>
              <AppointmentTypeIcon color="tertiary.700" />
            </Flex>
            <Heading mb={1} mt={3} variant="sm_v1">
              {providerName}
            </Heading>
            <Text variant="body_v1">
              {formatDateTime(appointment.start_at)}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};

export default AppointmentCard;
