import { useState, useEffect, useMemo } from "react";
import { isSafari } from "react-device-detect";
import { MenuOptionGroup, MenuItemOption } from "@chakra-ui/react";
import {
  useSessionViewStatus,
  useSessionDevices,
} from "context/SessionRoomContext";
import { trackDeviceMenuSelection } from "components/templates/SHSessionRoom/telemetry/analytics";
import {
  DeviceTypes,
  SafariAudioDevice,
} from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";
import {
  excludeVirtualDeviceRegex,
  cleanAlphanumericParentheticals,
} from "components/templates/SHSessionRoom/utils/cleanLabelsUtils";

import { logActiveDeviceChanged } from "components/templates/SHSessionRoom/telemetry/datadog";

export const DeviceMenuOptionGroup = ({ type, sourceView }) => {
  const [connectedDevices, setConnectedDevices] = useState({
    audioinput: [],
    videoinput: [],
    audiooutput: [],
  });
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.deviceMenuOptionGroup",
  });
  const { sessionViewStatus } = useSessionViewStatus();
  const { sessionDevices, setSessionDevices } = useSessionDevices();
  const {
    devices,
    selectedAudioOutputDevice,
    selectedAudioInputDevice,
    selectedVideoDevice,
  } = sessionDevices;

  useEffect(() => {
    if (isSafari && type == DeviceTypes.AudioOutput) {
      // Inject a virtual device into connectedDevices
      const simulatedDevice = {
        label: SafariAudioDevice.SystemDefaultSpeaker,
        deviceId: SafariAudioDevice.DeviceId,
      };
      setConnectedDevices({
        ...devices,
        audiooutput: [...devices.audiooutput, simulatedDevice],
      });
    } else {
      setConnectedDevices(devices);
    }
  }, [devices]);

  const selectedDevice = useMemo(() => {
    if (isSafari && type === DeviceTypes.AudioOutput) {
      return {
        deviceId: SafariAudioDevice.DeviceId,
        label: SafariAudioDevice.SystemDefaultSpeaker,
      };
    } else if (type === DeviceTypes.AudioOutput) {
      return {
        deviceId: selectedAudioOutputDevice.deviceId,
        label: selectedAudioOutputDevice.label,
      };
    } else if (type === DeviceTypes.AudioInput) {
      return {
        deviceId: selectedAudioInputDevice.deviceId,
        label: selectedAudioInputDevice.label,
      };
    } else if (type === DeviceTypes.VideoInput) {
      return {
        deviceId: selectedVideoDevice.deviceId,
        label: selectedVideoDevice.label,
      };
    }
  }, [
    selectedAudioOutputDevice,
    selectedAudioInputDevice,
    selectedVideoDevice,
  ]);

  const title = useMemo(() => {
    const titles = {
      [DeviceTypes.AudioOutput]: t("selectASpeaker"),
      [DeviceTypes.AudioInput]: t("selectAMicrophone"),
      [DeviceTypes.VideoInput]: t("selectACamera"),
    };
    return titles[type] || "";
  }, [type, t]);

  const selectActiveDevice = (device) => {
    logActiveDeviceChanged(
      type,
      sessionDevices,
      sessionViewStatus,
      "DeviceMenuOptionGroup - selectActiveDevice()",
    );

    if (type === DeviceTypes.AudioInput) {
      setSessionDevices((prevState) => ({
        ...prevState,
        selectedAudioInputDevice: device,
      }));
    } else if (type === DeviceTypes.VideoInput) {
      setSessionDevices((prevState) => ({
        ...prevState,
        selectedVideoDevice: device,
      }));
    } else if (type === DeviceTypes.AudioOutput) {
      if (!isSafari) {
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedAudioOutputDevice: device,
        }));
      }
    }
    trackDeviceMenuSelection(type, sourceView, device.label);
  };

  return (
    <>
      {devices && selectedDevice && (
        <MenuOptionGroup
          defaultValue={selectedDevice.deviceId}
          title={title}
          type="radio"
        >
          {connectedDevices[type].map((device) =>
            excludeVirtualDeviceRegex.test(device.label) ? null : (
              <MenuItemOption
                value={device.deviceId}
                key={device.deviceId}
                onClick={() => selectActiveDevice(device)}
                _focus={{ outline: "0px solid transparent !important" }}
                _focusVisible={{ outline: "2px solid black !important" }}
                _hover={{ bg: "hover-base-low-emphasis" }}
              >
                {cleanAlphanumericParentheticals(device.label)}
              </MenuItemOption>
            ),
          )}
        </MenuOptionGroup>
      )}
    </>
  );
};

export default DeviceMenuOptionGroup;
