import { useTranslation } from "react-i18next";
import { VFamiliesChildIcon } from "design-system/assets/verdant";
import { Box, Heading, Text, VButton } from "design-system/components";
import NextLink from "next/link";
import routes from "routes";

export const DidYouKnowCard = ({ totalVisitsCovered }) => {
  const { t } = useTranslation("homepage");
  const hasSponsoredSession = totalVisitsCovered > 0;
  const didYouKnowCardBodyText = hasSponsoredSession
    ? t("familySection.didYouKnowCard.hasSponsoredSessionText", {
        numberOfSponsoredSessions: totalVisitsCovered,
      })
    : t("familySection.didYouKnowCard.hasNoSponsordSessionText");

  return (
    <Box
      w="100%"
      p={24}
      borderWidth="2px"
      borderRadius="12px"
      borderColor="tertiary.light"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box pb={20}>
        {hasSponsoredSession && <VFamiliesChildIcon height={24} width={24} />}
      </Box>
      <Heading variant="sm_v1" pb={8}>
        {t("familySection.didYouKnowCard.heading")}
      </Heading>
      <Text
        pb={24}
        maxWidth={["100%", "100%", "347px", "100%"]}
        textAlign="center"
        size="body-medium-regular"
      >
        {didYouKnowCardBodyText}
      </Text>
      {!hasSponsoredSession && (
        <NextLink
          href={`${routes.TherapistsBrowse.as}/?Families=true`}
          as={routes.TherapistsBrowse.as}
          passHref
        >
          <VButton variant="low-emphasis">
            <Text size="body-medium-strong">
              {t("familySection.didYouKnowCard.browseTherapistCTAtext")}
            </Text>
          </VButton>
        </NextLink>
      )}
    </Box>
  );
};
