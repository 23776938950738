/**
 * Enums for care navigator contracts.
 */

const CareNavigationContractType = Object.freeze({
	Regular   : 'regular',
	Blended   : 'blended',
	Dedicated : 'dedicated',
});

module.exports = CareNavigationContractType;