import { useEffect, useRef, useState } from "react";
import {
  Card,
  Badge,
  IconButton,
  FilterChip,
} from "@springcare/sh-component-library";
import {
  Text,
  Flex,
  Box,
  Heading,
  useBreakpointValue,
  HStack,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { VXIcon, VWorkersDuotoneSpot } from "@springcare/verdant-icons-react";
import updateMemberWorkplaceManager from "modules/MemberDashboard/ManagerExperience/Workplace/grapqhql/mutations/updateMemberWorkplaceManager";
import { updateMemberExperienceState } from "operations/mutations/member";
import { useTranslation } from "react-i18next";
import { WorkplaceManagerSelfIdentificationCardProps } from "./types";
import { useMutation } from "@apollo/client";
import { getHomePageMemberInfo } from "components/templates/HomePage/queries/getHomePageMemberInfo";
import Meowth from "@spring/meowth";
import { debounce } from "lodash";
import {
  trackMemberViewedWorkplaceManagerSelfIdentificationCard,
  trackMemberAnsweredManagerSelfIdentificationQuestion,
  trackMemberClickedCloseButtonOnWorkplaceManagerSelfIdentificationCard,
} from "modules/MemberDashboard/Home/components/ManagerExperience/Workplace/utils/WorkplaceBannersMixpanelAnalytics";
import { SELF_ID_ANSWER_CHOICES } from "modules/MemberDashboard/Home/components/ManagerExperience/Workplace/constants/selfIDAnswerChoices";
import { useInViewportOnce } from "hooks";
import { useManagerExperienceSimplifyIdFlowExperiment } from "modules/MemberDashboard/Home/components/ManagerExperience/Workplace/hooks/useManagerExperienceSimplifyIdFlowExperiment";
import { VWorkplaceManager } from "design-system/assets";

export const WorkplaceManagerSelfIdentificationCard: React.FC<
  React.PropsWithChildren<WorkplaceManagerSelfIdentificationCardProps>
> = ({
  removeWorkplaceManagerBanner,
  memberId,
  setShowWorkplaceManagerExploreBanner,
  showWorkplaceManagerSelfIdCardOnBottom = false,
  showPreAssessedExperience,
}) => {
  const [answerSubmittedNo, setAnswerSubmittedNo] = useState(false);
  const { t } = useTranslation("homepage");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const headingSize = useBreakpointValue([
    "heading-small",
    "heading-small",
    "heading-medium",
  ]);
  const cardVariant = showWorkplaceManagerSelfIdCardOnBottom
    ? "medium-emphasis"
    : "low-emphasis";

  const ref = useRef();
  const inViewport = useInViewportOnce(ref);
  const [viewedOnce, setViewedOnce] = useState(false);

  useEffect(() => {
    if (inViewport && !viewedOnce) {
      trackMemberViewedWorkplaceManagerSelfIdentificationCard(
        showWorkplaceManagerSelfIdCardOnBottom,
      );
      setViewedOnce(true);
    }
  }, [inViewport, viewedOnce, showWorkplaceManagerSelfIdCardOnBottom]);

  const {
    managerExperienceSimplifyIdFlowExperimentFeatureFlag,
    handleExperiment1SelfIDAnswerClick,
  } = useManagerExperienceSimplifyIdFlowExperiment(memberId);

  const handleSelfIDAnswerClick = (answer) => {
    // Beginning of conditional block for Manager Experience Experiment #1: https://springhealth.atlassian.net/browse/MXE-734
    // Code will be removed in MXE-762, after the experiment is done.
    if (
      managerExperienceSimplifyIdFlowExperimentFeatureFlag &&
      answer === SELF_ID_ANSWER_CHOICES.isAManager
    ) {
      handleExperiment1SelfIDAnswerClick(
        showWorkplaceManagerSelfIdCardOnBottom,
      );
      return;
    }
    // End of conditional block for Manager Experience Experiment #1

    if (answer) {
      trackMemberAnsweredManagerSelfIdentificationQuestion(
        showWorkplaceManagerSelfIdCardOnBottom,
        answer,
      );
    }

    if (answer === SELF_ID_ANSWER_CHOICES.isAManager) {
      setShowWorkplaceManagerExploreBanner(true);
      updateWorkplaceManagerField(true);
      updateWorkplacePageViewedField(false);
    }

    if (answer === SELF_ID_ANSWER_CHOICES.notAManager) {
      setAnswerSubmittedNo(!answerSubmittedNo);
      updateWorkplaceManagerField(false);
    }
  };

  const [updateMembersWorkplaceManagerField] = useMutation(
    updateMemberWorkplaceManager,
    {
      refetchQueries: [
        {
          query: getHomePageMemberInfo(),
          variables: {
            id: Meowth.getUserId(),
          },
        },
      ],
    },
  );

  const updateWorkplaceManagerField = (value) => {
    updateMembersWorkplaceManagerField({
      variables: {
        member_id: memberId,
        workplace_manager: value,
      },
    });
  };

  const [updateWorkplacePageViewed] = useMutation(updateMemberExperienceState);

  const updateWorkplacePageViewedField = (value) => {
    updateWorkplacePageViewed({
      variables: {
        member_id: memberId,
        workplace_page_viewed: value,
      },
    });
  };

  const handleCloseButtonClick = () => {
    trackMemberClickedCloseButtonOnWorkplaceManagerSelfIdentificationCard(
      showWorkplaceManagerSelfIdCardOnBottom,
    );

    updateWorkplaceManagerField(false);
    removeWorkplaceManagerBanner();
  };

  const handleConfirmationCloseButtonClick = () => {
    removeWorkplaceManagerBanner();
  };

  const SelfIDCard = () => {
    return (
      <>
        <HStack justifyContent="space-between" width="100%" mb="v-8">
          <Badge colorScheme="positive" variant="medium-emphasis">
            {t("workplaceSelfIdentification.newBadge")}
          </Badge>
          <IconButton
            icon={VXIcon}
            variant="no-emphasis"
            colorScheme="neutral"
            aria-label="close-banner-button"
            onClick={debounce(handleCloseButtonClick, 100)}
          ></IconButton>
        </HStack>
        {isMobile && (
          <Flex alignSelf="center" marginBottom="v-24">
            <VWorkersDuotoneSpot
              width={100}
              height={100}
              aria-hidden="true"
              data-testid="workplace-icon"
            />
          </Flex>
        )}
        <Flex flexDir="row" justifyContent="space-between" width="95%">
          <Box>
            <Heading size={headingSize} mb="v-16">
              {t("workplaceSelfIdentification.initialHeading")}
            </Heading>

            <VStack alignItems="right" gap="v-8">
              <FilterChip
                variant="low-emphasis"
                colorScheme="base"
                width="fit-content"
                onClick={() =>
                  handleSelfIDAnswerClick(SELF_ID_ANSWER_CHOICES.isAManager)
                }
              >
                <Text>
                  {t("workplaceSelfIdentification.isManagerAnswerChoice")}
                </Text>
              </FilterChip>
              <FilterChip
                variant="low-emphasis"
                colorScheme="base"
                width="fit-content"
                onClick={() =>
                  handleSelfIDAnswerClick(SELF_ID_ANSWER_CHOICES.notAManager)
                }
              >
                <Text>
                  {t("workplaceSelfIdentification.isNotManagerAnswerChoice")}
                </Text>
              </FilterChip>
            </VStack>
          </Box>
          {!isMobile && (
            <Flex alignSelf="flex-end">
              <VWorkersDuotoneSpot
                width={120}
                height={120}
                aria-hidden="true"
                data-testid="workplace-icon"
              />
            </Flex>
          )}
        </Flex>
      </>
    );
  };

  const PreAssessedExperienceIDCard = () => {
    return (
      <>
        <HStack justifyContent="space-between" width="100%" mb="v-8">
          {isMobile && (
            <Flex alignSelf="center" marginBottom="v-24">
              <VWorkplaceManager
                width={100}
                height={100}
                aria-hidden="true"
                data-testid="workplace-icon"
              />
            </Flex>
          )}
          <Flex flexDir="row" justifyContent="space-between" width="95%">
            <Box>
              <Heading size={headingSize} mb="v-16">
                {t("workplaceSelfIdentification.initialHeading")}
              </Heading>

              <VStack alignItems="right" gap="v-8">
                <FilterChip
                  variant="low-emphasis"
                  colorScheme="base"
                  width="fit-content"
                  onClick={() =>
                    handleSelfIDAnswerClick(SELF_ID_ANSWER_CHOICES.isAManager)
                  }
                >
                  <Text>
                    {t("workplaceSelfIdentification.isManagerAnswerChoice")}
                  </Text>
                </FilterChip>
                <FilterChip
                  variant="low-emphasis"
                  colorScheme="base"
                  width="fit-content"
                  onClick={() =>
                    handleSelfIDAnswerClick(SELF_ID_ANSWER_CHOICES.notAManager)
                  }
                >
                  <Text>
                    {t("workplaceSelfIdentification.isNotManagerAnswerChoice")}
                  </Text>
                </FilterChip>
              </VStack>
            </Box>
            {!isMobile && (
              <Flex alignSelf="flex-end">
                <VWorkplaceManager
                  width={121}
                  height={120}
                  aria-hidden="true"
                  data-testid="workplace-icon"
                />
              </Flex>
            )}
          </Flex>
          <IconButton
            icon={VXIcon}
            variant="no-emphasis"
            colorScheme="neutral"
            alignSelf="self-start"
            aria-label="close-banner-button"
            padding="v-18"
            onClick={debounce(handleCloseButtonClick, 100)}
          ></IconButton>
        </HStack>
      </>
    );
  };

  return (
    <Card
      variant={cardVariant}
      size={isMobile ? "md" : "lg"}
      width="100%"
      mb="v-16"
      mt={["v-8", "v-8", "v-none"]}
      ref={ref}
    >
      <>
        {answerSubmittedNo ? (
          <HStack flexDir="row" justifyContent="space-between" width="100%">
            <Text size="body-medium-strong">
              {t("workplaceSelfIdentification.thankYouHeading")}
            </Text>
            <IconButton
              icon={VXIcon}
              variant="no-emphasis"
              colorScheme="neutral"
              aria-label="close-banner-button"
              onClick={debounce(handleConfirmationCloseButtonClick, 100)}
            ></IconButton>
          </HStack>
        ) : showPreAssessedExperience ? (
          <PreAssessedExperienceIDCard />
        ) : (
          <SelfIDCard />
        )}
      </>
    </Card>
  );
};
