import { gql } from "@apollo/client";

const getTeenAppointment = gql`
  query getTeenAppointment($id: ID!) {
    appointment(id: $id) {
      id
      start_at
      kind
      medium
      provider_id
    }
  }
`;

export default getTeenAppointment;
