// 
import React from 'react';
import classnames from 'classnames';

import Number from '../../atoms/Number';
import { Grid, Col } from '../../layout/Grid';
import { Section, FlexCol } from '../../layout';
import Content from '../../typography/Content';

import styles from './styles.module.scss';



const constructSteps = (props) => {
	return props.steps.map((step, i) => (
		<div key={i} className={styles.step}>
			<Grid>
				<Col size={1}>
					<Number number={i+1} color={step.color} />
				</Col>
				<Col size={11}>
					<FlexCol>
						<Section size="sm">
							<h3>{step.headline}</h3>
						</Section>
						<Content>{step.text}</Content>
					</FlexCol>
				</Col>
			</Grid>
		</div>
	));
};

const Steps = (props) => (
	<section
		className={classnames(styles.stepSection, {
			[styles.striped]: props.striped,
		})}
	>
		<div className={styles.steps}>{constructSteps(props)}</div>
	</section>
);

Steps.defaultProps = {
	steps: [],
};

export default Steps;
