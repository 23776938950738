import { FC } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { GradientLayersProps } from "./types";

const GRADIENT_BLANKET_STYLE = {
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
};

const TO_BOTTOM_GRADIENT =
  "linear-gradient(to bottom, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.01) 14.59%, rgba(0, 0, 0, 0.03) 26.58%, rgba(0, 0, 0, 0.05) 36.32%, rgba(0, 0, 0, 0.09) 44.14%, rgba(0, 0, 0, 0.14) 50.37%, rgba(0, 0, 0, 0.19) 55.36%, rgba(0, 0, 0, 0.24) 59.44%, rgba(0, 0, 0, 0.30) 62.96%, rgba(0, 0, 0, 0.36) 66.24%, rgba(0, 0, 0, 0.42) 69.63%, rgba(0, 0, 0, 0.47) 73.46%, rgba(0, 0, 0, 0.52) 78.08%, rgba(0, 0, 0, 0.57) 83.82%, rgba(0, 0, 0, 0.61) 91.01%, rgba(0, 0, 0, 0.64) 100%)";
const LEFT_TO_RIGHT_GRADIENT =
  "linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.01) 14.59%, rgba(0, 0, 0, 0.03) 26.58%, rgba(0, 0, 0, 0.05) 36.32%, rgba(0, 0, 0, 0.09) 44.14%, rgba(0, 0, 0, 0.14) 50.37%, rgba(0, 0, 0, 0.19) 55.36%, rgba(0, 0, 0, 0.24) 59.44%, rgba(0, 0, 0, 0.30) 62.96%, rgba(0, 0, 0, 0.36) 66.24%, rgba(0, 0, 0, 0.42) 69.63%, rgba(0, 0, 0, 0.47) 73.46%, rgba(0, 0, 0, 0.52) 78.08%, rgba(0, 0, 0, 0.57) 83.82%, rgba(0, 0, 0, 0.61) 91.01%, rgba(0, 0, 0, 0.64) 100%)";

export const GradientLayers: FC<
  React.PropsWithChildren<GradientLayersProps>
> = ({ photoGradient, variant }) => {
  const isTileVariant = variant === "tile";

  const photoGradientLayer = {
    ...GRADIENT_BLANKET_STYLE,
    backgroundImage: photoGradient,
  };

  const blanketLinearGradient = isTileVariant
    ? TO_BOTTOM_GRADIENT
    : useBreakpointValue([
        TO_BOTTOM_GRADIENT,
        TO_BOTTOM_GRADIENT,
        LEFT_TO_RIGHT_GRADIENT,
        LEFT_TO_RIGHT_GRADIENT,
      ]);

  const gradientBlanket = {
    ...GRADIENT_BLANKET_STYLE,
    backgroundImage: blanketLinearGradient,
  };
  return (
    <>
      <Box w="100%" h="100%" position={"absolute"} style={photoGradientLayer} />
      <Box w="100%" h="100%" position={"absolute"} style={gradientBlanket} />
    </>
  );
};
