//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";
import { formOptions } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "PCPTSD.q1": numberField(),
};
const prompt2Schema = {
  "PCPTSD.q2": numberField(),
};
const prompt3Schema = {
  "PCPTSD.q3": numberField(),
};
const prompt4Schema = {
  "PCPTSD.q4": numberField(),
};

export const PCPTSDSchema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
};

const { YesNoOptions } = formOptions;

const PCPTSD = [
  {
    id: "PCPTSD--1",
    title:
      "In your life, have you ever had any experience that was so frightening, horrible, or upsetting that in the past month, you have **had nightmares about it or thought about it when you did not want to**?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="PCPTSD.q1"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "PCPTSD--2",
    title:
      "In your life, have you ever had any experience that was so frightening, horrible, or upsetting that in the past month, you **tried hard not to think about it or went out of your way to avoid situations that reminded you of it**?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="PCPTSD.q2"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "PCPTSD--3",
    title:
      "In your life, have you ever had any experience that was so frightening, horrible, or upsetting that in the past month, you **were constantly on guard, watchful, or easily startled**?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="PCPTSD.q3"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "PCPTSD--4",
    title:
      "In your life, have you ever had any experience that was so frightening, horrible, or upsetting that in the past month, you **felt numb or detached from others, activities, or your surroundings**?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="PCPTSD.q4"
        options={YesNoOptions}
      />,
    ],
  },
];

export default PCPTSD;
