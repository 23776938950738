import { useTranslation } from "react-i18next";
import { SHFormNotification } from "./SHFormNotification";
import { ChildBird } from "./ChildBird";

export const CountryGlobalHint = () => {
  const { t } = useTranslation("limitedLangAuth");

  return (
    <SHFormNotification
      icon={<ChildBird />}
      text={t("forms.childSignUpForm.country.global")}
    />
  );
};
