import { useTranslation } from "hooks/react-i18next";
import { useSelector } from "react-redux";
import { getOr } from "lodash/fp";
import { DateTime } from "luxon";

import { Flex, Text } from "design-system/components";

type MemberPortalReduxState = {
  global: {
    lang?: string;
    [key: string]: any;
  };
  [key: string]: any;
};

const TimeZone = () => {
  const { t } = useTranslation(["careProvider", "common"]);
  const locale = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.lang,
  );
  const luxonDateTime = DateTime.local().setLocale(locale);
  const timeZoneName = getOr(
    "your local time zone",
    "offsetNameLong",
    luxonDateTime,
  );

  return (
    <Flex justify="flex-end">
      <Flex justify="inline-flex">
        <Text variant="body_v1">{t("timeZone")}</Text>
        <Text ml={4}>
          <b>{timeZoneName}</b>
        </Text>
      </Flex>
    </Flex>
  );
};

export default TimeZone;
