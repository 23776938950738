import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

// schema function is a function that returns a schema and takes 't' as an argument
// allows us to generate yup schemas that have properly translated labels and error messages
const useSchema = ({
  schemaFunction,
  translationNamespace = "limitedLangValidations",
}: {
  schemaFunction: (t: any) => any;
  translationNamespace?: string | string[];
}) => {
  const { t, i18n, ready } = useTranslation(translationNamespace);
  const [schema, setSchema] = useState(() => schemaFunction(t));

  useEffect(() => {
    setSchema(schemaFunction(t));
  }, [i18n.language, ready]);

  return schema;
};

export default useSchema;
