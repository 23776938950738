const complete = [
  {
    id: "complete",
    title: "submitAssessment.complete.title",
    subtitle: "submitAssessment.complete.subtitle",
    questions: [{ id: "complete-1", element: { kind: "COMPLETE" } }],
  },
];

export default complete;
