import PublicRoutes from "./PublicRoutes";
import BrowseRoutes from "./BrowseRoutes";
import MemberDashboardRoutes from "./MemberDashboardRoutes";
import SelfHelpRoutes from "./SelfHelpRoutes";

export default {
  ...PublicRoutes,
  ...BrowseRoutes,
  ...MemberDashboardRoutes,
  ...SelfHelpRoutes,
};
