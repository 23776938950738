//

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  Card,
  Subtitle,
  Content,
  Icon,
  FlexRow,
  Section,
} from "@spring/smeargle";
import { openModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import { useTranslation } from "react-i18next";

import ReviewBenefitsModal from "components/modals/ReviewBenefitsModal";

import styles from "./styles.module.scss";

import { track } from "utils/mixpanel";

const ReviewBenefits = (props) => {
  const { t } = useTranslation("benefitsSnapshot");
  return (
    <div>
      <div
        className={styles.click}
        onClick={() => {
          props.openModal(modalIds.reviewBenefitsModal);
          track("Opened Review Benefits Modal");
        }}
      >
        <Card>
          <Section>
            <FlexRow alignment="center" justification="space-between">
              <div>
                <Subtitle noMargin>{t("reviewBenefits.title")}</Subtitle>
                <Content>{t("reviewBenefits.subtitle")}</Content>
              </div>
              <Icon type="arrow-right" />
            </FlexRow>
          </Section>
        </Card>
      </div>
      <ReviewBenefitsModal />
    </div>
  );
};

ReviewBenefits.propTypes = {
  openModal: PropTypes.func,
};

export { ReviewBenefits };
export default connect(null, { openModal })(ReviewBenefits);
