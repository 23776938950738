import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CareProviderTagKind,
  ProviderTags,
  flyoutIds,
  browseProviders,
} from "@spring/constants";
import { toTagOptions } from "@spring/immutability";
import { Flyout, Form, FlexRow, Button } from "@spring/smeargle";
import { initializeForm, closeFlyout } from "@spring/smeargle/actions";

import styles from "./styles.module.scss";
import FilterAccordion from "./FilterAccordion";
import { TRACK_EVENT } from "utils/mixpanel";
import { Heading } from "design-system/components";
import { clickableDivProps } from "lib/accessibility_helpers";
import {
  SESSION_TYPE_TAG_DATA,
  DAY_OF_WEEK_TAG_DATA,
  TIME_OF_DAY_TAG_DATA,
} from "./providerFilterFlyout.constants";
import {
  useTrackFilterChange,
  getCurrentRoute,
  getTagName,
} from "./providerFilterFlyout.utils";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const ProviderFilterFlyout = ({
  closeFlyout,
  initializeForm,
  conditionTags,
  specialtyTags,
  genderTags,
  ethnicityTags,
  languageTags,
  sessionTypeTags,
  daysOfWeekTags,
  timeOfDayTags,
  fixedTag,
  initialData,
  tempData,
  providerType,
  inPersonSupported,
  flyoutData,
  flyoutOpen,
}) => {
  const { t } = useTranslation("careProvider");
  const ref = useRef();

  const [prevSessionTypes, setprevSessionTypes] = useState([]);
  const shouldShowSessionFilters =
    providerType === "therapist" && inPersonSupported;

  const showAvailFilters = useFeatureFlag(FLAGS.AVAILABILITY_FILTER_RELEASE);

  const browseProviderFormKeys = [
    browseProviders.formKey,
    browseProviders.formKeyTemp,
  ];

  const { tagData, filterType, timestamp } = flyoutData;
  const currentRoute = getCurrentRoute(providerType);
  const sessionTagLength =
    shouldShowSessionFilters && sessionTypeTags ? sessionTypeTags.length : 0;
  const daysOfWeekTagLength =
    daysOfWeekTags && showAvailFilters ? daysOfWeekTags.length : 0;
  const timeOfDayTagLength =
    timeOfDayTags && showAvailFilters ? timeOfDayTags.length : 0;

  let totalSelectedTagCount =
    conditionTags.length +
    specialtyTags.length +
    languageTags.length +
    genderTags.length +
    ethnicityTags.length +
    sessionTagLength +
    daysOfWeekTagLength +
    timeOfDayTagLength;

  // This checks if member is a managed minor with preselected tag
  // If TRUE, will decrement the total tag count
  if (fixedTag) totalSelectedTagCount -= 1;

  // Specific method to get tag name for Session Types since they're generated by the FE
  const getSessionTypeTagName = (tagId) => {
    const filteredCopy = SESSION_TYPE_TAG_DATA.filter(
      (tag) => tag.id === tagId,
    );

    return filteredCopy[0]?.name;
  };

  useEffect(() => {
    const closeListener = (e) => {
      if (e.key === "Escape") {
        closeFlyout(flyoutIds.providerFilterFlyout);
      }
    };
    window.addEventListener("keydown", closeListener);
    return () => window.removeEventListener("keydown", closeListener);
  }, []);

  // Mixpanel Tracking - Filter Changes
  useTrackFilterChange(
    "Condition",
    flyoutOpen,
    tagData,
    conditionTags,
    providerType,
  );
  useTrackFilterChange(
    "Specialty",
    flyoutOpen,
    tagData,
    specialtyTags,
    providerType,
  );
  useTrackFilterChange(
    "Language",
    flyoutOpen,
    tagData,
    languageTags,
    providerType,
  );
  useTrackFilterChange("Gender", flyoutOpen, tagData, genderTags, providerType);
  useTrackFilterChange(
    "Ethnicity",
    flyoutOpen,
    tagData,
    ethnicityTags,
    providerType,
  );

  // Mixpanel Tracking - Session Type filter changes
  /*
    NOTE: this is a copy of useTrackFilterChange but with session type
    specific code since it's behind a feature flag
  */
  useEffect(() => {
    if (!flyoutOpen) return;
    const lastSessionTypeTag = sessionTypeTags[sessionTypeTags.length - 1];
    const hasRemovedAFilterEntry =
      prevSessionTypes.length > sessionTypeTags.length;
    const shouldRunEffect = tagData && shouldShowSessionFilters;

    if (shouldRunEffect && hasRemovedAFilterEntry) {
      const removedTag = prevSessionTypes.filter(
        (d) => !sessionTypeTags.includes(d),
      );

      TRACK_EVENT.FILTER_CHANGE_APPLIED(currentRoute, "Session type", {
        spring_doc_id: "directsched09",
        change: "Removed",
        value: getSessionTypeTagName(removedTag[0]),
      });
    } else if (shouldRunEffect && getSessionTypeTagName(lastSessionTypeTag)) {
      TRACK_EVENT.FILTER_CHANGE_APPLIED(currentRoute, "Session type", {
        spring_doc_id: "directsched09",
        change: "Added",
        value: getSessionTypeTagName(lastSessionTypeTag),
      });
    }

    setprevSessionTypes(sessionTypeTags);
  }, [sessionTypeTags, flyoutOpen]);

  const updateFilters = (formData) => {
    let tags = [].concat(
      formData.conditionsTags,
      formData.specialtiesTags,
      formData.gendersTags,
      formData.ethnicitiesTags,
      formData.sessionTypeTags,
      formData.daysOfWeekTags,
      formData.timeOfDayTags,
    );
    let filtered = tags.filter(Boolean || []);
    let tagsWithLabels = filtered.reduce((acc, tag) => {
      const name = getTagName(tagData, tag);
      const sessionName = getSessionTypeTagName(tag);
      let newValue;

      if (name) {
        newValue = name;
      } else if (sessionName && shouldShowSessionFilters) {
        newValue = sessionName;
      }

      return newValue ? [...acc, newValue] : [...acc];
    }, []);
    if (formData.languagesTags) tagsWithLabels.push(...formData.languagesTags);

    TRACK_EVENT.BUTTON_CLICKED(currentRoute, "Update Filters", {
      spring_doc_id: "directsched010",
      count: tagsWithLabels.length,
      value: tagsWithLabels.join(" "),
    });

    closeFlyout(flyoutIds.providerFilterFlyout);
    initializeForm(browseProviders.formKey, null, formData);
  };

  const resetFilters = () => {
    browseProviderFormKeys.map((formKey) => {
      initializeForm(formKey, null, {
        conditionTags: [],
        specialtiesTags: fixedTag ? [fixedTag] : [],
        languagesTags: [],
        fixedTag: fixedTag,
        gendersTags: [],
        ethnicitiesTags: [],
        daysOfWeekTags: [],
        timeOfDayTags: [],
      });
    });
  };

  const languageData = tagData?.filter(
    (language) =>
      !["Chinese (Simplified)", "Chinese (Traditional)"].includes(
        language.name,
      ),
  );

  return (
    <Flyout
      id={flyoutIds.providerFilterFlyout}
      addExitAction={() =>
        initializeForm(browseProviders.formKeyTemp, null, initialData)
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.headerContainer}>
          <FlexRow justification="space-between" alignment="center">
            <Heading
              as={"h2"}
              fontSize={30}
              color="#182023"
              fontWeight={"normal"}
              margin={"24px 0"}
            >
              {t("providerFilterFlyout.title")}
            </Heading>
            {totalSelectedTagCount > 0 && (
              <div
                className={styles.reset}
                {...clickableDivProps({ onClick: resetFilters })}
                onClick={resetFilters}
              >
                {t("providerFilterFlyout.resetButton")}
              </div>
            )}
          </FlexRow>
        </div>

        <div className={styles.container} ref={ref}>
          <Form formKey={browseProviders.formKeyTemp}>
            {providerType !== "coach" ? (
              <>
                {shouldShowSessionFilters && (
                  <FilterAccordion
                    fieldKey={browseProviders.sessionTypesFieldKey}
                    title={ProviderTags.SessionTypes}
                    data={
                      tagData &&
                      toTagOptions(
                        SESSION_TYPE_TAG_DATA,
                        CareProviderTagKind.SessionType,
                      )
                    }
                    selectedCount={sessionTypeTags.length}
                    open={filterType === ProviderTags.SessionTypes}
                    timestamp={timestamp}
                  />
                )}
                {showAvailFilters && (
                  <FilterAccordion
                    fieldKey={browseProviders.daysOfWeekFieldKey}
                    title={t("multiSelectParent.daysOfWeek.label")}
                    data={
                      tagData &&
                      toTagOptions(
                        DAY_OF_WEEK_TAG_DATA,
                        CareProviderTagKind.DaysOfWeek,
                      )
                    }
                    selectedCount={daysOfWeekTags.length}
                    open={filterType === ProviderTags.DaysOfWeeks}
                    timestamp={timestamp}
                  />
                )}
                {showAvailFilters && (
                  <FilterAccordion
                    fieldKey={browseProviders.timeOfDayFieldKey}
                    title={t("multiSelectParent.timeOfDay.label")}
                    data={
                      tagData &&
                      toTagOptions(
                        TIME_OF_DAY_TAG_DATA,
                        CareProviderTagKind.timeOfDay,
                      )
                    }
                    selectedCount={timeOfDayTags.length}
                    open={filterType === ProviderTags.timeOfDay}
                    timestamp={timestamp}
                  />
                )}
                <FilterAccordion
                  fieldKey={browseProviders.conditionsFieldKey}
                  title={t("multiSelectParent.conditions.label")}
                  data={
                    tagData &&
                    toTagOptions(tagData, CareProviderTagKind.Condition)
                  }
                  selectedCount={conditionTags.length}
                  open={filterType === ProviderTags.Conditions}
                  timestamp={timestamp}
                />
                <FilterAccordion
                  fieldKey={browseProviders.specialtiesFieldKey}
                  title={t("multiSelectParent.specialties.label")}
                  data={
                    tagData &&
                    toTagOptions(
                      tagData,
                      CareProviderTagKind.Specialty,
                      fixedTag,
                    )
                  }
                  selectedCount={specialtyTags.length}
                  open={filterType === ProviderTags.Specialties}
                  timestamp={timestamp}
                />
              </>
            ) : (
              <FilterAccordion
                fieldKey={browseProviders.specialtiesFieldKey}
                title={t("multiSelectParent.specialties.label")}
                data={
                  tagData &&
                  toTagOptions(tagData, CareProviderTagKind.Specialty, fixedTag)
                }
                selectedCount={specialtyTags.length}
                open={filterType === ProviderTags.Specialties}
                timestamp={timestamp}
              />
            )}
            <FilterAccordion
              fieldKey={browseProviders.gendersFieldKey}
              title={t("multiSelectParent.genders.label")}
              selectedCount={genderTags.length}
              data={
                tagData &&
                toTagOptions(tagData, CareProviderTagKind.Gender, fixedTag)
              }
              open={filterType === ProviderTags.Genders}
              timestamp={timestamp}
              dataCy="genders"
            />
            <FilterAccordion
              fieldKey={browseProviders.ethnicitiesFieldKey}
              title={t("multiSelectParent.ethnicities.label")}
              selectedCount={ethnicityTags.length}
              data={
                tagData &&
                toTagOptions(tagData, CareProviderTagKind.Ethnicity, fixedTag)
              }
              open={filterType === ProviderTags.Ethnicities}
              timestamp={timestamp}
              dataCy="ethnicities"
            />
            <FilterAccordion
              fieldKey={browseProviders.languagesFieldKey}
              title={t("multiSelectParent.languages.label")}
              selectedCount={languageTags.length}
              open={filterType === ProviderTags.Languages}
              data={
                languageData &&
                toTagOptions(languageData, CareProviderTagKind.Language)
              }
              timestamp={timestamp}
            />
          </Form>
        </div>
      </div>

      {totalSelectedTagCount > 0 && (
        <div className={styles.submitButton}>
          <Button
            full
            text={t("providerFilterFlyout.updateButton")}
            onClick={() => updateFilters(tempData)}
            dataCy={"providerFilterSubmit"}
          />
        </div>
      )}
    </Flyout>
  );
};

ProviderFilterFlyout.propTypes = {
  closeFlyout: PropTypes.func,
  conditionTags: PropTypes.array,
  fixedTag: PropTypes.string,
  flyoutData: PropTypes.shape({
    filterType: PropTypes.string,
    tagData: PropTypes.shape({
      conditionsTags: PropTypes.array,
      specialtiesTags: PropTypes.array,
      gendersTags: PropTypes.array,
      ethnicitiesTags: PropTypes.array,
      sessionTypeTags: PropTypes.array,
      daysOfWeekTags: PropTypes.array,
      timeOfDayTags: PropTypes.array,
    }),
    timestamp: PropTypes.any,
  }),
  initialData: PropTypes.any,
  initializeForm: PropTypes.func,
  languageTags: PropTypes.array,
  specialtyTags: PropTypes.array,
  gendersTags: PropTypes.array,
  ethnicitiesTags: PropTypes.array,
  tempData: PropTypes.any,
  providerType: PropTypes.string,
};

export { ProviderFilterFlyout };
export default compose(
  connect(
    (state) => ({
      flyoutOpen:
        state?.flyout?.[flyoutIds.providerFilterFlyout]?.open ?? false,
      flyoutData: state?.flyout?.[flyoutIds.providerFilterFlyout]?.data ?? {},
      initialData: state?.form?.browseProviderTagsTemp?.data ?? {},
      tempData: state?.form?.browseProviderTagsTemp?.data ?? [],
      conditionTags:
        state?.form?.browseProviderTagsTemp?.data?.conditionsTags ?? [],
      specialtyTags:
        state?.form?.browseProviderTagsTemp?.data?.specialtiesTags ?? [],
      languageTags:
        state?.form?.browseProviderTagsTemp?.data?.languagesTags ?? [],
      genderTags: state?.form?.browseProviderTagsTemp?.data?.gendersTags ?? [],
      ethnicityTags:
        state?.form?.browseProviderTagsTemp?.data?.ethnicitiesTags ?? [],
      sessionTypeTags:
        state?.form?.browseProviderTagsTemp?.data?.sessionTypeTags ?? [],
      daysOfWeekTags:
        state?.form?.browseProviderTagsTemp?.data?.daysOfWeekTags ?? [],
      timeOfDayTags:
        state?.form?.browseProviderTagsTemp?.data?.timeOfDayTags ?? [],
      fixedTag: state?.form?.browseProviderTags?.data?.fixedTag ?? "",
    }),
    { initializeForm, closeFlyout },
  ),
)(ProviderFilterFlyout);
