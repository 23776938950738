import Router from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Avatar } from "@springcare/sh-component-library";
import {
  Center,
  Heading,
  Text,
  VStack,
  HStack,
  useBreakpointValue,
  Spinner,
} from "@chakra-ui/react";
import {
  VFocusFlatSpot,
  VCheckInDuotoneSpot,
} from "@springcare/verdant-icons-react";
import { isTeen } from "utils/memberHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import { TEEN_ASSESSMENT_TELEMETRY_TYPES } from "components/templates/TeenAssessment/constants/telemetryValues";
import { getHomeRoute } from "shared/utils/getHomeRoute";

interface BeforeWeStartProps {
  firstName: string;
  memberDateOfBirth: string;
  createTeenAssessment: () => void;
}

export const BeforeWeStart: React.FC<
  React.PropsWithChildren<BeforeWeStartProps>
> = ({
  firstName,
  memberDateOfBirth,
  createTeenAssessment,
}): React.ReactElement => {
  const { t } = useTranslation("assessments");
  const [isLoading, setIsLoading] = useState(true);
  const buttonMarginVal = useBreakpointValue([50, 180]);
  const isMinorATeen = isTeen(memberDateOfBirth);

  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      Router.asPath,
      TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_START,
      {
        spring_doc_id: "beforewestart001",
      },
    );
  }, []);

  useEffect(() => {
    if (!isMinorATeen && memberDateOfBirth) {
      const memberHomeRoute = getHomeRoute(memberDateOfBirth);
      Router.replace(memberHomeRoute.to, memberHomeRoute.as);
    } else {
      setIsLoading(false);
    }
  }, [isMinorATeen]);

  return (
    <>
      {isLoading ? (
        <Center marginTop={250}>
          <Spinner speed="1s" size="xl" thickness="4px" w="100px" h="100px" />
        </Center>
      ) : (
        <Center marginTop={[30, 132]}>
          <VStack w={[327, 607]} alignItems="flex-start">
            <VCheckInDuotoneSpot
              aria-label="check-in icon"
              style={{ marginBottom: 16 }}
            />
            <Heading variant="md_v1" as="h1" data-testid="heading">
              {t("teenAssessments.beforeWeStart.feelingText", {
                ns: "assessments",
                firstName: firstName,
              })}
            </Heading>
            <Badge padding={4} style={{ marginBottom: 24 }} aria-hidden={true}>
              <HStack>
                <VFocusFlatSpot width={24} height={24} />
                <Text variant="medium-emphasis">
                  {t("teenAssessments.beforeWeStart.assessmentDuration")}
                </Text>
              </HStack>
            </Badge>
            <HStack>
              <Avatar name="1" size="md" marginInlineEnd={16} />
              <Text variant="body-medium">
                <b>
                  {t("teenAssessments.beforeWeStart.firstCompleteQuestionText")}
                </b>{" "}
                {t("teenAssessments.beforeWeStart.secondCompleteQuestionText")}
              </Text>
            </HStack>
            <HStack style={{ marginBottom: buttonMarginVal }}>
              <Avatar name="2" size="md" marginInlineEnd={16} />
              <Text>
                <b>{t("teenAssessments.beforeWeStart.privacyText")}</b>{" "}
                {t("teenAssessments.beforeWeStart.shareWithProvider")}
              </Text>
            </HStack>
            <Center w="100%">
              <Button
                w={[340, 390]}
                onClick={createTeenAssessment}
                data-cy="continue-teen-assessment-button"
              >
                {t("teenAssessments.beforeWeStart.continue")}
              </Button>
            </Center>
          </VStack>
        </Center>
      )}
    </>
  );
};
