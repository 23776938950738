import { DateTime } from "luxon";

import routes from "routes";

import { upcomingAppointmentsThresholdHours } from "constants/appointments";

export const saveCareItemCountToLocalStorage = (
  memberId,
  currentCareItemCount,
  currentDate,
) => {
  if (!memberId || currentCareItemCount === undefined) {
    return;
  }

  const careItemCountAtBeginningOfDay = {
    date: currentDate,
    count: currentCareItemCount,
  };

  window.localStorage.setItem(
    `careItemCountAtBeginningOfDay_${memberId}`,
    JSON.stringify(careItemCountAtBeginningOfDay),
  );
};

export const getCheckInAssessmentUrl = (recommendedCareItems) => {
  if (!recommendedCareItems || recommendedCareItems.length === 0) {
    return;
  }

  const checkInCareItemMetadata = recommendedCareItems.find(
    (careItem) => careItem.care_item_name === "check_in",
  )?.metadata;

  if (!checkInCareItemMetadata) {
    return;
  }

  const checkInAssessmentId = JSON.parse(
    checkInCareItemMetadata,
  )?.check_in_assessment_id;

  return checkInAssessmentId
    ? routes.TakeMemberAssessment.as.replace(":id", checkInAssessmentId)
    : "";
};

// Even though the query theoretically pulled the appointments within 72 hours
// let's go ahead and add another safe-guard filter here just to be safe
export const includeUpcomingAppointment = (appt) => {
  const startAt = DateTime.fromISO(appt.start_at);
  const endAt = DateTime.fromISO(appt.end_at);
  const now = DateTime.local();

  const diffHours = startAt.diffNow("hours").toObject().hours;
  const inProgress = startAt <= now && endAt >= now;
  const isWithin72Hours =
    diffHours > 0 && diffHours <= upcomingAppointmentsThresholdHours;

  return inProgress || isWithin72Hours;
};
