// 

import React from 'react';

import styles from './styles.module.scss';


const FloatyPage = (props) => (
	<div className={styles.content}>
		{props.children}
	</div>
);

export default FloatyPage;
