import { gql } from "@apollo/client";

const sessionAdmitParticipant = gql`
  query sessionAdmitParticipant($appointmentId: ID!, $participantId: ID!) {
    session_admit_participant(
      appointment_id: $appointmentId
      participant_id: $participantId
    ) {
      message
    }
  }
`;

export default sessionAdmitParticipant;
