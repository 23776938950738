import { datadogIdentify } from "lib/datadog-setup";

import { identifyUser as mixpanelIdentify } from "utils/mixpanel";
import { launchdarklyIdentify } from "utils/launchdarkly/client";

export const integrationsIdentify = (userIdentifiers, options = {}) => {
  mixpanelIdentify(userIdentifiers);
  launchdarklyIdentify(userIdentifiers, options.ldClient);
  datadogIdentify(userIdentifiers);

  return;
};
