import {
  getAppointmentMediumForTracking,
  getAppointmentKindForTracking,
  isMedicationManagement,
  isTherapy,
  isCoaching,
} from "@spring/constants";

import { COST_ESTIMATE_DISPLAY_STATES } from "components/templates/CostEstimation/constants";
import { getOr } from "lodash/fp";
import { returnAllSessionCounts } from "modules/shared/utils";

export const getModalLocation = (routes, mpLocation, eventProps) => {
  const isBenefitsPage = window.location.pathname.includes(
    routes.MemberBenefits.as,
  );
  const isHomePage = window.location.pathname.includes(routes.MemberHome.as);
  const isCareVisitsPage = window.location.pathname.includes(
    routes.MemberCareVisits.as,
  );
  if (isBenefitsPage) {
    return "Benefit Cards";
  }
  if (isHomePage) {
    return mpLocation;
  }
  if (isCareVisitsPage && eventProps.appointment_type === "Care Navigation") {
    return "Your Care Navigator";
  }
  return mpLocation || "Appointment Confirmation";
};

export const getEventProps = (
  provider,
  kind,
  medium,
  therapyTopSpecialtiesFlag,
) => {
  return {
    provider_id: provider?.id,
    appointment_type: getAppointmentKindForTracking(kind),
    appointment_medium: getAppointmentMediumForTracking(medium),
    top_specialties: getTopTags(provider, therapyTopSpecialtiesFlag),
  };
};

const getTopTags = (provider, therapyTopSpecialtiesFlag) => {
  if (therapyTopSpecialtiesFlag && provider.top_tags?.length > 0) {
    return provider.top_tags?.sort()?.join(", ");
  }
  return "";
};

export const lookupModalTitle = (t, auto_cancel_policy_ack_required) => {
  const titles = {
    [COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY]: t(
      "modal.initialDisplayHeading",
    ),
    [COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY]: t("modal.selfPayHeading"),
    [COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM]: t(
      "modal.insuranceFormHeading",
    ),
    [COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY]: t(
      "modal.cancellationPolicyHeading",
    ),
    [COST_ESTIMATE_DISPLAY_STATES.MULTI_SESSION_BOOKING]: t(
      "modal.multiSessionBookingHeading",
    ),
  };

  if (auto_cancel_policy_ack_required) {
    titles[COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY] = t(
      "modal.cancellationPolicyAckRequiredHeading",
    );
  }

  return titles;
};

export const getMinWidth = (isMobile, displayState, isDesktop) => {
  if (
    isMobile ||
    displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY
  ) {
    return undefined;
  } else if (isDesktop) {
    return "728px";
  }
  return "calc(100vw - 50px)";
};

export const hasTwoOrFewerVisitsRemaining = (member, kind) => {
  const {
    remainingCoaching: visitsRemainingCoaching,
    remainingTherapy: visitsRemainingTotal,
    remainingMedManagement: visitsRemainingSpecialist,
  } = returnAllSessionCounts(member);

  const unlimitedVisitsCoachingcn = getOr(
    false,
    "cohort.unlimited_coaching_cn_visits",
    member,
  );
  const unlimitedDirectVisitsCoaching = getOr(
    false,
    "cohort.unlimited_coaching_direct_visits",
    member,
  );

  if (isMedicationManagement(kind)) {
    return visitsRemainingSpecialist <= 2;
  } else if (isTherapy(kind)) {
    return visitsRemainingTotal <= 2;
  } else if (isCoaching(kind)) {
    if (unlimitedVisitsCoachingcn || unlimitedDirectVisitsCoaching)
      return false;
    return visitsRemainingCoaching <= 2;
  } else {
    // This means session is care navigation (or some other type added later)
    return false;
  }
};

export const isCostEstimateDisplayState = (displayState) => {
  return [
    COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY,
    COST_ESTIMATE_DISPLAY_STATES.REQUEST_STATUS,
    COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM,
    COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY,
  ].includes(displayState);
};

export const getModalPlacement = (
  isMobile,
  displayState,
  showScheduleContent,
) => {
  if (isMobile) {
    return "bottom";
  } else if (
    (!showScheduleContent &&
      displayState === COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY) ||
    displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY
  ) {
    return "center";
  }
  return "center-scroll";
};

export const getModalSize = (displayState, isMobile) => {
  if (
    displayState === COST_ESTIMATE_DISPLAY_STATES.MULTI_SESSION_BOOKING &&
    !isMobile
  ) {
    return "sm";
  } else if (
    displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY &&
    !isMobile
  ) {
    return "md";
  }
  return "lg";
};
