import { createIcon } from "@chakra-ui/react";

export const Close = createIcon({
  displayName: "CloseV1.1",
  viewBox: "0 0 18 18",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L10.4142 9L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L9 10.4142L1.70711 17.7071C1.31658 18.0976 0.683417 18.0976 0.292893 17.7071C-0.097631 17.3166 -0.097631 16.6834 0.292893 16.2929L7.58579 9L0.292894 1.70711C-0.0976301 1.31658 -0.0976301 0.683417 0.292894 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L9 7.58579L16.2929 0.292893Z"
    />
  ),
});
