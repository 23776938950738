import { useTranslation } from "react-i18next";
import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "design-system/components";
import { useFamilySectionTracking } from "components/templates/HomePage/hooks/useFamilySectionTracking";
import { useMemberBenefits } from "hooks/useMemberBenefits";

import { DidYouKnowCard } from "./DidYouKnowCard";
import { MinorsList } from "./MinorsList";
import { ParentCoachingCard } from "./ParentCoachingCard";
import { ParentingMomentsCarousel } from "./ParentingMomentsCarousel";

export const FamilySection = ({
  coach,
  columns,
  spacing,
  minors,
  totalVisitsCovered,
  memberId,
  onScheduleModalOpen,
}) => {
  const { showCoachingPaidExperience } = useMemberBenefits(memberId);
  const { ref } = useFamilySectionTracking();
  const familySectionHeadingVariant = useBreakpointValue([
    "md_v1",
    "md_v1",
    "lg_v1",
    "lg_v1",
  ]);
  const { t } = useTranslation("homepage");

  return (
    <Box pt={[20, 20, 12, 40]} ref={ref}>
      <Heading variant={familySectionHeadingVariant}>
        {t("familySection.yourFamily.heading")}
      </Heading>
      <Text pt={[0, 8]} pb={[0, 24]} variant="body_v1">
        {t("familySection.yourFamily.accountsYouHelpManageAgesUnder17")}
      </Text>
      <SimpleGrid columns={columns} spacing={spacing}>
        <GridItem colSpan={[4, 4, 8, 8]}>
          <MinorsList minors={minors} />
        </GridItem>
        <GridItem colSpan={[4, 4, 8, 4]} w="100%">
          {showCoachingPaidExperience ? (
            <ParentCoachingCard
              coach={coach}
              onScheduleModalOpen={onScheduleModalOpen}
            />
          ) : (
            <DidYouKnowCard totalVisitsCovered={totalVisitsCovered} />
          )}
        </GridItem>
      </SimpleGrid>
      <ParentingMomentsCarousel />
    </Box>
  );
};
