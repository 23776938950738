import React, { useEffect, useRef } from "react";
import { isEmpty, get, getOr } from "lodash/fp";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { DateTime } from "luxon";
import { useQuery, NetworkStatus } from "@apollo/client";
import { connect } from "react-redux";
import { Section, LoadingCircle } from "@spring/smeargle";
import { addNotification, openModal } from "@spring/smeargle/actions";
import {
  modalIds,
  AppointmentKind,
  AppointmentAction,
  inPersonDistance,
  timeFormats,
  ProviderRole,
} from "@spring/constants";
import Meowth from "@spring/meowth";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  ProviderMatchingModal,
  AvailabilityRequestModal,
} from "components/modals";

import { NONE_SELECTED, SELECTED } from "./constants";
import ProviderResult from "./ProviderResult";
import Pagination from "./Pagination";
import FindProviderCard from "./FindProviderCard";
import styles from "./styles.module.scss";

import {
  getMemberConsent,
  getMemberAddress,
  getMemberInfo,
} from "operations/queries/member";
import { browseAllMyProviders } from "operations/queries/careProvider";
import { requestCareTeamChange } from "operations/mutations/member";
import { getAppointments } from "operations/queries/appointment";
import { requestAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import { isLegacyMinor, isMinor } from "utils/memberHelpers";
import envUtils from "utils/environment";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly/flags";
import { TRACK_EVENT } from "utils/mixpanel";
import FindProviderCardLegacy from "./FindProviderCardLegacy";
import { hasHadMedManagerAppt } from "utils/providers/";

import { SESSION_TYPE_TAG_DATA } from "components/flyouts/ProviderFilterFlyout/providerFilterFlyout.constants";
import { splitSessionTypeTag, buildBaseRefetchOptions } from "./listing.utils";

const Listing = ({ refetchProviders, onScheduleModalOpen, ...props }) => {
  const didComponentMount = useRef();
  const isTherapistListing = props.providerType === "therapist";
  const showNewRequestAvailabilityForm = useFeatureFlag(
    FLAGS.PROVIDER_REQUEST_AVAILABILITY_FORM,
  );
  const enableInPersonGlobal = useFeatureFlag(FLAGS.IN_PERSON_GLOBAL);
  const { data: memberInfoData } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );
  const member = memberInfoData?.user?.member;

  const { data: pastAppointmentData } = useQuery(getAppointments, {
    variables: {
      booking_user_id: props.user && props.user.id,
      starting_before: DateTime.local()
        .toUTC()
        .toFormat(timeFormats.datePickerFormat),
      sort_by: [
        {
          column: "appointments.start_at",
          direction: "desc",
        },
      ],
    },
  });

  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);
  const isHighMark = useFeatureFlag(FLAGS.HIGHMARK_COST_TRANSPARENCY_COPY);

  useEffect(() => {
    const {
      in_person_supported,
      virtual_supported,
      tagData: filteredTags,
    } = splitSessionTypeTag(props.tagData);
    const {
      conditionsTags,
      specialtiesTags,
      gendersTags,
      ethnicitiesTags,
      languagesTags,
    } = filteredTags;
    const careProviderTagIds = [
      ...conditionallyInsert(conditionsTags),
      ...conditionallyInsert(specialtiesTags),
      ...conditionallyInsert(gendersTags),
      ...conditionallyInsert(ethnicitiesTags),
    ];
    const languages = [...conditionallyInsert(languagesTags)];
    const shouldExcludeSessionTypeFilters =
      !isTherapistListing ||
      (in_person_supported && virtual_supported) ||
      (in_person_supported === false && virtual_supported === false);

    const options = buildBaseRefetchOptions(
      props.memberAddress?.user.member.postal_address,
      props.providerType,
      inPersonDistance.limit35,
      isAMinor,
      props?.memberInfo?.user?.member?.cohort?.in_person_supported,
      languages,
      careProviderTagIds,
    );

    options.dedicated_only = props.showDedicatedProviders;

    // On component "mount"
    if (!didComponentMount.current) {
      options.in_person_supported = in_person_supported;
      options.virtual_supported = virtual_supported;

      // Load all the providers (mounts without requesting so pagination will work)
      refetchProviders(options);
    } else {
      /*
        Apollo reuses initial fetch options when refetching
        so we need to manually reset some options
        https://www.apollographql.com/docs/react/data/queries/#refetching
      */
      options.in_person_supported = undefined;
      options.virtual_supported = undefined;
      options.offset = 0;

      if (!isEmpty(props.tagData) && !shouldExcludeSessionTypeFilters) {
        if (in_person_supported) {
          options.in_person_supported = in_person_supported;
        }
        if (virtual_supported) {
          options.virtual_supported = virtual_supported;
        }
      }
      refetchProviders(options);
    }
  }, [
    props.tagData,
    props.memberAddress?.user.member.postal_address.city,
    props.memberAddress?.user.member.postal_address.state,
    props.memberAddress?.user.member.postal_address.street_address_1,
    props.memberAddress?.user.member.postal_address.street_address_2,
    props.memberAddress?.user.member.postal_address.zip_code,
    props.showDedicatedProviders,
  ]);

  // Will conditionally add tags of different kinds if they exist.
  const conditionallyInsert = (tags = []) =>
    !Array.isArray(tags) || !tags.length ? [] : tags;

  const providerRole =
    props.providerType === "therapist" ? "Therapist" : "Medication Manager";

  const appointmentKind = (hasHadMedManagerApptVal) => {
    const therapyBasedOnAge = isAMinor
      ? AppointmentKind.MinorTherapy
      : AppointmentKind.Therapy;
    const medManagementType = hasHadMedManagerApptVal
      ? AppointmentKind.FollowUpMedicationManagement
      : AppointmentKind.InitialMedicationManagement;

    switch (props.providerType) {
      case ProviderRole.Therapist:
        return therapyBasedOnAge;
      case ProviderRole.MedicationManager:
        return medManagementType;
      case ProviderRole.Coach:
        return AppointmentKind.Coaching;
    }
  };

  const modalId = () => {
    // These endpoints account for sponsored sessions
    const specialistSessionsRemaining = getOr(
      0,
      "memberInfo.user.member.visits_remaining_specialist",
      props,
    );
    // basic + specialist + sponsored
    const totalTherapySessionsRemaining = getOr(
      0,
      "memberInfo.user.member.visits_remaining_total",
      props,
    );
    const canSchedule = getOr(
      false,
      "memberInfo.user.member.previsit.can_schedule",
      props,
    );

    // displays insurance modal if the user has 1 or fewer sessions remaining and is not from HMX
    if (providerRole === "Therapist") {
      if (!canSchedule && totalTherapySessionsRemaining <= 1 && !isHighMark) {
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          "Profile Settings and Insurance",
          {
            provider_role: ProviderRole.Therapist,
          },
        );

        return modalIds.beforeYouScheduleModal;
      }
    }

    if (providerRole === "Medication Manager") {
      if (!canSchedule && specialistSessionsRemaining <= 1 && !isHighMark) {
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          "Profile Settings and Insurance",
          {
            provider_role: ProviderRole.MedicationManager,
          },
        );

        return modalIds.beforeYouScheduleModal;
      }
    }

    return modalIds.careProviderScheduleModal;
  };

  const isAMinor = isMinor(member);

  const isALegacyMinor = isLegacyMinor(
    getOr({}, "memberInfo.user.member", props),
  );

  const modalData = (provider) => {
    let role;
    const pastAppointments = pastAppointmentData?.appointments?.data;

    if (props.providerType === "therapist") {
      role = "Therapist";
    } else if (props.providerType === "medication_manager") {
      role = "Medication Manager";
    }
    return {
      ...provider,
      providerRole: role,
      kind: appointmentKind(hasHadMedManagerAppt(provider, pastAppointments)),
      medium: appointmentMedium(provider),
      supportsInPerson: supportsInPerson(provider),
    };
  };

  const appointmentMedium = (provider) =>
    provider.supported_appointment_mediums[0]; // TODO: logic will change in the future

  const schedule = (provider, index) => {
    if (shouldShowNewInsuranceModal) {
      onScheduleModalOpen({
        supportsInPerson: supportsInPerson(provider),
        kind: appointmentKind(
          hasHadMedManagerAppt(
            provider,
            pastAppointmentData?.appointments?.data,
          ),
        ),
        provider,
        buttonText: props.t("listings.confirmAppointment"),
        action: AppointmentAction.Create,
        dataCy: "schedule-provider-modal",
        providerOrder: index,
      });
    } else {
      return props.openModal(modalId(), {
        ...modalData(provider),
        videoTherapy: true,
        buttonText: props.t("listings.confirmAppointment"),
        action: AppointmentAction.Create,
        dataCy: "schedule-provider-modal",
        providerOrder: index,
        isAMinor,
      });
    }
  };

  const requestAvailability = async (provider, medium, kind) => {
    try {
      const payload = {
        care_provider_id: provider.id,
        availability_days_of_week: ["Unknown"],
        availability_time_of_day: ["Unknown"],
        medium,
        kind,
      };

      const {
        data: { requestAppointment },
      } = await props.requestAppointment(payload);

      if (!requestAppointment.success) {
        const { careTeamEmail } = envUtils;
        const message = (
          <Trans
            ns={"careProvider"}
            i18nKey={"listings.errorNotificationMessage"}
          >
            Oh no! We were not able to put this request through. Please reach
            out to {{ careTeamEmail }} with the name of the {{ providerRole }}{" "}
            you would like to schedule with, and a Care Navigator will assist
            you.
          </Trans>
        );
        return props.addNotification(message, "error", 0);
      }

      return props.openModal(modalIds.availabilityRequestModal, {
        ...modalData(provider),
      });
    } catch (err) {
      return props.addNotification(getFirstError(err), "error");
    }
  };

  const supportsInPerson = (provider) =>
    get("memberInfo.user.member.cohort.in_person_supported", props) &&
    provider.in_person_supported &&
    provider.roles.includes("Therapist") &&
    (!props.showGlobalExperience || enableInPersonGlobal);

  const findProviderCard = ({ pageRefs }) => {
    if (!showNewRequestAvailabilityForm) {
      return (
        <div
          className={styles.findProviderCard}
          ref={(el) =>
            (pageRefs.current = { ...pageRefs.current, findProvider: el })
          }
        >
          <FindProviderCardLegacy
            appointmentKind={appointmentKind}
            country={getOr(
              "US",
              "memberAddress.user.member.postal_address.country",
              props,
            )}
          />
        </div>
      );
    }

    return (
      <div
        className={styles.findProviderCard}
        ref={(el) =>
          (pageRefs.current = { ...pageRefs.current, findProvider: el })
        }
      >
        <FindProviderCard
          filteredProviderTags={props.filteredProviderTags}
          providerType={props.providerType}
          isMinor={isMinor(member)}
          queryRequestId={props.queryRequestId}
        />
      </div>
    );
  };

  findProviderCard.propTypes = {
    pageRefs: PropTypes.any,
  };

  const providers = (member) => {
    const providersData = getOr([], `data.browse_care_providers.data`, props);
    const pagingData = getOr([], `data.browse_care_providers.paging`, props);
    const pastAppointments = pastAppointmentData?.appointments?.data;

    if (props.loading) {
      return <LoadingCircle />;
    }
    const renderProviders = providersData.map((provider, index) => (
      <Section key={provider.id} size="lg">
        <ProviderResult
          provider={provider}
          member={member}
          providerIndex={index}
          pageNumber={pagingData.page}
          inPersonFilterSelected={props.tagData?.sessionTypeTags?.includes(
            "In Person",
          )}
          appointmentMedium={appointmentMedium(provider)}
          appointmentKind={appointmentKind(
            hasHadMedManagerAppt(provider, pastAppointments),
          )}
          modalData={() => modalData(provider)}
          supportsInPerson={supportsInPerson(provider)}
          supportsVirtual={provider.virtual_supported}
          schedule={() => schedule(provider, index)}
          requestAvailability={requestAvailability}
          isALegacyMinor={isALegacyMinor}
          isAMinor={isAMinor}
          showGlobalExperience={props.showGlobalExperience}
          tagData={props.tagData}
          showLongBioSnippet
        />
      </Section>
    ));

    return <>{renderProviders}</>;
  };

  const pagination = () => {
    const providersData = getOr([], `data.browse_care_providers.data`, props);

    if (!providersData.length) {
      return null;
    }

    return (
      <Pagination
        refetch={refetchProviders}
        loading={props.loading}
        currentPage={get(`data.browse_care_providers.paging.page`, props)}
        totalPages={get(`data.browse_care_providers.paging.pages`, props)}
        limit={get(`data.browse_care_providers.paging.limit`, props)}
      />
    );
  };

  const listings = (member) => {
    return (
      <>
        {providers(member)}
        {pagination()}
        <div id="teleVisit">{findProviderCard(props)}</div>
      </>
    );
  };

  if (props.loading || props.networkStatus === NetworkStatus.refetch) {
    return <LoadingCircle />;
  }

  return (
    <div ref={didComponentMount}>
      {listings(member)}
      <ProviderMatchingModal />
      <AvailabilityRequestModal />
    </div>
  );
};

Listing.propTypes = {
  addNotification: PropTypes.func,
  loading: PropTypes.any,
  memberAddress: PropTypes.shape({
    user: PropTypes.shape({
      member: PropTypes.shape({
        postal_address: PropTypes.shape({
          city: PropTypes.any,
          country: PropTypes.any,
          state: PropTypes.any,
          street_address_1: PropTypes.any,
        }),
      }),
    }),
  }),
  openModal: PropTypes.func,
  providerType: PropTypes.string,
  refetchProviders: PropTypes.func,
  networkStatus: PropTypes.func,
  requestAppointment: PropTypes.func,
  showDedicatedProviders: PropTypes.bool,
  showGlobalExperience: PropTypes.any,
  t: PropTypes.func,
  tagData: PropTypes.shape({
    conditionsTags: PropTypes.any,
    languagesTags: PropTypes.any,
    specialtiesTags: PropTypes.any,
    gendersTags: PropTypes.any,
    ethnicitiesTags: PropTypes.any,
    sessionTypeTags: PropTypes.any,
    daysOfWeekTags: PropTypes.any,
    timeOfDayTags: PropTypes.any,
  }),
  pageRefs: PropTypes.any,
  filteredProviderTags: PropTypes.array,
};

export { Listing };

const mapStateToProps = (state) => ({
  showGlobalExperience: state.global.showGlobalExperience,
});

const WrappedListing = compose(
  graphql(requestCareTeamChange, { name: "requestCareTeamChange" }),
  graphql(getMemberConsent, {
    options: Meowth.apolloOptionsUserId,
    name: "memberConsent",
  }),
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (input) =>
        mutate({
          variables: { input },
        }),
    }),
  }),
  connect(mapStateToProps, { addNotification, openModal }),
)(withTranslation("careProvider")(Listing));

const FinalListing = (props) => {
  const { data: memberAddressData, loading: memberAddressLoading } = useQuery(
    getMemberAddress,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );
  const { data: memberInfoData, loading: memberInfoLoading } = useQuery(
    getMemberInfo,
    {
      ...Meowth.apolloOptionsUserId(),
      skip: Meowth.apolloSkipUserId(),
    },
  );
  const isTherapistListing = props.providerType === "therapist";

  const {
    loading: isBrowseProviderLoading,
    data: browseCareProviderData,
    refetch: refetchProviders,
    error,
    networkStatus,
  } = useQuery(browseAllMyProviders, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    skip: !memberAddressData,
  });

  const pagingData = getOr(
    {},
    "browse_care_providers.paging",
    browseCareProviderData,
  );
  const providerData = getOr(
    [],
    "browse_care_providers.data",
    browseCareProviderData,
  );

  useEffect(() => {
    if (isBrowseProviderLoading) return;

    const pageNumber = pagingData.page;
    const totalPages = pagingData.pages;
    const totalProviderCount = pagingData.total;
    const tagData = props.tagData;

    const getTagName = (providerData, tagIds) => {
      const tagIdsWithNameObj = {};
      const tagNamesArr = [];

      providerData.map((provider) => {
        return provider.care_provider_tags.map((array) => {
          const { id, name } = array;
          tagIdsWithNameObj[id] = name;
        });
      });

      if (tagIds) {
        tagIds.map((id) => {
          return tagNamesArr.push(tagIdsWithNameObj[id]);
        });
      }
      return tagNamesArr;
    };

    const getArrayOfSessionTypeNames = (tagIds) => {
      return tagIds?.reduce((acc, id) => {
        const match = SESSION_TYPE_TAG_DATA.find((tag) => tag.id === id);
        if (match) {
          acc.push(match.id);
          return acc;
        }
        return acc;
      }, []);
    };

    TRACK_EVENT.COMPONENTS_LOADED(window.location.pathname, "Provider Cards", {
      total_providers_displayed:
        pageNumber === totalPages ? totalProviderCount % 10 : 10,
      total_providers_available: totalProviderCount,
      total_num_pages: totalPages,
      condition_tags: tagData.conditionsTags
        ? getTagName(providerData, tagData.conditionsTags)
        : NONE_SELECTED,
      language_tags: tagData.languagesTags || NONE_SELECTED,
      specialtiesTags: tagData.specialtiesTags
        ? getTagName(providerData, tagData.specialtiesTags)
        : NONE_SELECTED,
      genderTags: tagData.gendersTags
        ? getTagName(providerData, tagData.gendersTags)
        : NONE_SELECTED,
      ethnicitiesTags: tagData.ethnicitiesTags
        ? getTagName(providerData, tagData.ethnicitiesTags)
        : NONE_SELECTED,
      ...(isTherapistListing && {
        sessionTypeTags:
          tagData.sessionTypeTags?.length > 0
            ? getArrayOfSessionTypeNames(tagData?.sessionTypeTags)
            : NONE_SELECTED,
      }),
      dedicatedProviderFilter: props.showDedicatedProviders
        ? SELECTED
        : NONE_SELECTED,
    });
  }, [isBrowseProviderLoading]);

  return (
    <WrappedListing
      {...props}
      tagData={props.tagData}
      loading={
        isBrowseProviderLoading || memberAddressLoading || memberInfoLoading
      }
      data={browseCareProviderData}
      refetchProviders={refetchProviders}
      networkStatus={networkStatus}
      error={error}
      memberAddress={memberAddressData}
      memberInfo={memberInfoData}
    />
  );
};

FinalListing.propTypes = {
  tagData: PropTypes.any,
  providerType: PropTypes.string,
  pageRefs: PropTypes.any,
  filteredProviderTags: PropTypes.array,
};

export default FinalListing;
