import React, { FC } from "react";

import { getFAQFlyoutContents } from "modules/MemberDashboard/Teen/Home/components/FAQSection/utils/FAQSection.utils";
import { useRouter } from "next/router";

import { HStack, Heading, SimpleGrid, Box } from "@chakra-ui/react";

import { InformationFlyout } from "components/molecules/InformationFlyout/InformationFlyout";
import { useTeenFAQFlyoutDisclosures } from "./hooks/useTeenFAQFlyoutDisclosures";
import { useTranslation } from "hooks/react-i18next";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";

import { PhotoCard } from "shared/components/PhotoCard";
import type { FAQSectionProps } from "./types";
import { useTeenFAQSection } from "modules/MemberDashboard/Teen/Home/hooks";
import { CareProviderScheduleModalV2 } from "components";

export const FAQSection: FC<
  React.PropsWithChildren<FAQSectionProps>
> = (): React.ReactElement => {
  const { t } = useTranslation("teenHomepage");
  const router = useRouter();

  const disclosures = useTeenFAQFlyoutDisclosures();
  const {
    openTherapistModal,
    therapist,
    isScheduleModalOpen,
    onScheduleModalClose,
    scheduleModalProps,
  } = useTeenFAQSection();

  const handleFlyoutCTAButton = (item) => {
    if (therapist) {
      item.onClose();
      return openTherapistModal();
    }
    return router.push(routes.TherapistsBrowse.as);
  };

  const FAQ_FLYOUT_CONTENTS = getFAQFlyoutContents(t, disclosures);

  const handleFaqClicked = (item) => {
    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      `${item.flyoutName} Flyout`,
      item.flyoutName,
      {
        spring_doc_id: "teenhp003",
        location: "Learn about your care",
        card_title: item.headerText,
      },
    );
    item.onOpen();
  };

  return (
    <Box mt={[40, 56]}>
      <Heading size="heading-medium" mb={16}>
        {t("FAQSection.header")}
      </Heading>
      <SimpleGrid overflowX="scroll" h="100%">
        <HStack gap={12} mb={16}>
          {FAQ_FLYOUT_CONTENTS.map((item) => {
            return (
              <PhotoCard
                key={item.headerText}
                variant="tile"
                heading={item.headerText}
                imgUrl={item.tileImageSrc}
                photoGradient={item.tileImageGradient}
                onClickCallback={() => handleFaqClicked(item)}
              />
            );
          })}
        </HStack>
      </SimpleGrid>
      {FAQ_FLYOUT_CONTENTS.map((item) => {
        return (
          <InformationFlyout
            key={item.headerText}
            headerText={item.headerText}
            heroImageSrc={item.heroImageSrc}
            ctaText={therapist ? item.ctaTextSchedule : item.ctaText}
            ctaLinkAs={item.ctaLinkAs}
            ctaType={item.ctaType}
            ctaCallback={() => handleFlyoutCTAButton(item)}
            isOpen={item.isOpen}
            onClose={item.onClose}
            isButtonDisabled={false}
          >
            {item.body}
          </InformationFlyout>
        );
      })}
      <CareProviderScheduleModalV2
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
        {...scheduleModalProps}
      />
    </Box>
  );
};
