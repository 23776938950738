//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const Avatar = (props) => {
	return (
		<img
			alt=""
			className={classnames(styles.avatar, {
				[styles.circle]  : props.circle,
				[styles.rounded] : props.rounded,
				[styles.square]  : props.square,
			})}
			style={{
				height   : props.height,
				maxWidth : props.width,
			}}
			src={props.src}
			{ ...(props.imgProps || {}) }
			data-testid={props['data-testid']}
		/>
	);
};

Avatar.defaultProps = {
	circle : true,
	width  : '100%',
};

export default Avatar;
