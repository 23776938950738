import NextLink from "next/link";
import { useTranslation } from "react-i18next";
import { VButton, HStack, Link } from "design-system/components";
import { TRACK_EVENT } from "utils/mixpanel";

export const WrapperDesktop = ({
  children,
  link,
  accountLinkAriaLabel,
  accountLinkDataCy,
}) => {
  const { t } = useTranslation("homepage");
  const trackClick = () =>
    TRACK_EVENT.LINK_CLICKED(window.location.pathname, link, "View Account", {
      spring_doc_id: "famhp001",
      location: "Your Family",
    });

  return (
    <HStack h={"10 0%"} pb={40} justifyContent="space-between">
      {children}
      <NextLink href={link} passHref>
        <VButton
          as={Link}
          variant="medium-emphasis"
          aria-label={accountLinkAriaLabel}
          onClick={trackClick}
          data-cy={accountLinkDataCy}
        >
          {t("familySection.yourFamily.viewAccount")}
        </VButton>
      </NextLink>
    </HStack>
  );
};
