//

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import yup from "schemas/yup";
import { Card, Content, Form, Input, Subhead } from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { updateFindMyMembershipModel } from "actions/signUp";
import routes from "routes";
import { getFirstError } from "utils/apollo/errorHandler";
import { track } from "utils/mixpanel";
import { ResponsiveCardPadding } from "components";
import { useLazyFindCoveredLife } from "hooks/useLazyFindCoveredLife";

const mpPrefix =
  "Do you have an organization email address? (/email_verification)";

const secondEmailAskSchema = yup.object().shape({
  email: yup.string().email().nullable(),
});

const SecondEmailAskForm = (props) => {
  const [formData, setFormData] = useState(null);
  const [secondEmailPresent, setSecondEmailPresent] = useState(false);

  const router = useRouter();
  const first_name = props.findMyMembershipModel.first_name || "";
  const last_name = props.findMyMembershipModel.last_name || "";
  const date_of_birth = props.findMyMembershipModel.date_of_birth || "";

  const {
    getFindCoveredLife: getCoveredLife,
    loading,
    data,
  } = useLazyFindCoveredLife({
    onError: (err) => props.addNotification(getFirstError(err), "error"),
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    track(`${mpPrefix} -- Viewed`);
  }, []);

  useEffect(() => {
    if (formData) {
      const { email } = formData;
      getCoveredLife({
        variables: {
          first_name,
          last_name,
          date_of_birth,
          email,
        },
      });
    }
  }, [formData]);

  useEffect(() => {
    if (data && data.find_covered_life) {
      if (!data.find_covered_life.success && !formData.email) {
        let { to, as } = routes.ManualVerification;
        router.push(to, as);
        return;
      }

      // If the query fails and we *do* have an email address in `formData`, push both employees and dependents to manual verification.
      if (!data.find_covered_life.success && formData.email) {
        let { to, as } = routes.ManualVerification;
        router.push(to, as);
        return;
      }

      // When the query succeeds, we store the invite_token and go to the success screen (CompleteSignupPage).
      if (
        data.find_covered_life.invite_token &&
        data.find_covered_life.cohort_id
      ) {
        const { invite_token, cohort_id } = data.find_covered_life;
        let { to, as } = routes.Register;

        router.push(
          {
            pathname: to,
            query: { invite_token: invite_token, cohort_id: cohort_id },
          },
          as,
        );
        return;
      }
    }
  }, [data]);

  const onInputChange = (e) => {
    props.updateFindMyMembershipModel({
      fieldKey: "email_address",
      value: e.target.value,
    });

    if (e.target.value.trim().length > 0) {
      setSecondEmailPresent(true);
      return;
    }
    setSecondEmailPresent(false);
  };

  const { t } = useTranslation("emailContent");

  const secondEmailAsk = () => (
    <>
      <div className={styles.header}>
        <Subhead semibold center inheritColor noMargin>
          {t("secondEmailAskForm.subhead")}
        </Subhead>

        <div className={styles.headerSubtext}>
          <Content inheritColor>{t("secondEmailAskForm.content")}</Content>
        </div>
      </div>

      <Form
        onSubmit={(secondAskData) => {
          if (secondAskData.email) {
            track(`${mpPrefix} -- Submitted With Email`);
            setFormData({ email: secondAskData.email });
          } else {
            track(`${mpPrefix} -- Submitted Without Email`);
            let { to, as } = routes.ManualVerification;
            router.push(to, as);
          }
        }}
        submitText={
          secondEmailPresent
            ? `${t("secondEmailAskForm.emailExists.message")}`
            : `${t("secondEmailAskForm.emailNotExists.message")}`
        }
        disabled={loading}
        schema={secondEmailAskSchema}
        preserve
        fullSubmit
        flatSubmit
      >
        <Input
          fieldKey="email"
          label={t("secondEmailAskForm.label")}
          placeholder="youremail@organization.com"
          autoComplete="email"
          bolded
          autoFocus
          onInput={(e) => onInputChange(e)}
        />
      </Form>
    </>
  );

  return (
    <Card color="GREY_100" padding="none" radius="md">
      <ResponsiveCardPadding>{secondEmailAsk()}</ResponsiveCardPadding>
    </Card>
  );
};

SecondEmailAskForm.propTypes = {
  findMyMembershipModel: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    email_address: PropTypes.string,
    country: PropTypes.string,
    potential_member_type: PropTypes.string,
  }),
  addNotification: PropTypes.func,
  updateFindMyMembershipModel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  findMyMembershipModel: state.signUp.findMyMembershipModel,
});

const mapDispatchToProps = { addNotification, updateFindMyMembershipModel };

export default connect(mapStateToProps, mapDispatchToProps)(SecondEmailAskForm);
