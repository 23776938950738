import { useQuery } from "@apollo/client";
import { getTeenAppointmentInfo } from "components/templates/TeenAssessment/queries";

const useGetTeenAppointmentInfo = (appointmentId) => {
  const { data, loading, error } = useQuery(getTeenAppointmentInfo, {
    variables: {
      id: appointmentId,
    },
  });

  return {
    teenAppointment: data,
    teenAppointmentLoading: loading,
    teenAppointmentError: error,
  };
};

export { useGetTeenAppointmentInfo };
