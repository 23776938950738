// 

import React from 'react';

import Tag from '../../atoms/Tag';
import Icon from '../../atoms/Icon';
import { Stout } from '../../typography';

import styles from './styles.module.scss';


const TagSection = (props) => {

	function tags() {
		return props.tags.map((tag) => (
			<Tag
				key={tag}
				text={tag}
				onClick={
					props.onClick ?
						() => { props.onClick(tag); } :
						null
				}
			/>
		));
	}

	function title() {
		if (props.title) {
			return (
				<Stout>
					<Icon type="tag" /> Tags
				</Stout>
			);
		}

		return null;
	}

	return (
		<div className={styles.tagSection}>
			{title()}
			{tags()}
		</div>
	);
};

TagSection.defaultProps = {
	tags  : [],
	title : true,
};

export default TagSection;
