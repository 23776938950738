import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";
import { isSameDay } from "date-fns/isSameDay";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { RequestableAppointmentKind } from "@spring/constants";
import Meowth from "@spring/meowth";
import { useCustomToast } from "@springcare/sh-component-library";
import { useMemberInfo } from "hooks/useMemberInfo";
import type {
  GetCNsByStartTimesQuery,
  GetCNsByStartTimesQueryVariables,
  AvailableCnAppointmentTimeOutputType,
} from "modules/shared/graphql-codegen/graphql";
import { getCNsByStartTimes } from "modules/MemberDashboard/Scheduling/graphql/getCNsByStartTimes";
import { getCareProvider } from "operations/queries/careProvider";
import {
  formatSelectedDay,
  getTimeSlotsForDay,
  sortAppointmentsByDay,
} from "modules/MemberDashboard/Scheduling/utils";
import type { TimeSlotType } from "modules/MemberDashboard/Scheduling/types";
import { createAppointment } from "operations/mutations/appointment";
import { TRACK_EVENT } from "utils/mixpanel";
import { getIterableCampaignInfo } from "utils/localStorage";

const isSpecialtyCN = (careProviderData) => {
  return careProviderData?.care_provider?.roles?.includes(
    "Specialty Care Navigator",
  );
};

export const useCNScheduling = (showSUDCN: boolean) => {
  const { t } = useTranslation("scheduling");
  const today = useMemo(() => new Date().toISOString(), []);
  const [availableAppointments, setAvailableAppointments] = useState<
    AvailableCnAppointmentTimeOutputType[]
  >([]);
  const [selectedDateTimeSlots, setSelectedDateTimeSlots] = useState([]);
  const [selectedDayString, setSelectedDayString] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotType>(null);
  const [bookedAppointment, setBookedAppointment] = useState(null);
  const [shouldShowReviewScreen, setShouldShowReviewScreen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const loadingErrorToast = useCustomToast({
    type: "error",
    message: t("errorLoading"),
    layout: "fit-content",
    action: "on",
  });

  const noAvailabilityToast = useCustomToast({
    type: "error",
    message: t("noAvailability"),
    layout: "fit-content",
    action: "on",
  });

  const bookingErrorToast = useCustomToast({
    type: "error",
    message: t("bookingReview.errorBooking"),
    layout: "fit-content",
    action: "on",
  });

  const { data: memberData, loading: isMemberDataLoading } = useMemberInfo();
  const member = memberData?.user?.member;
  const { loading: isAvailableAppointmentsLoading } = useQuery<
    GetCNsByStartTimesQuery,
    GetCNsByStartTimesQueryVariables
  >(getCNsByStartTimes, {
    variables: {
      member_id: member?.id,
      start_date: today,
      sud_only: showSUDCN,
    },
    skip: !memberData || !today,
    onError: loadingErrorToast,
    onCompleted: ({
      all_available_cn_appointment_times_v2: allAppointments,
    }: {
      all_available_cn_appointment_times_v2: AvailableCnAppointmentTimeOutputType[];
    }) => {
      if (allAppointments?.length > 0) {
        const sortedAppointments = sortAppointmentsByDay(allAppointments);

        setAvailableAppointments(sortedAppointments);
        setFirstAvailableAppointment(sortedAppointments);
      } else {
        noAvailabilityToast();
      }
    },
  });

  const [
    doGetCareProvider,
    { data: careProviderData, loading: careProviderLoading },
  ] = useLazyQuery(getCareProvider);

  const [
    bookAppointmentMutation,
    { loading: isBookingAppointment, reset: resetBookAppointment },
  ] = useMutation(createAppointment, {
    onError: () => {
      TRACK_EVENT.ERROR_MESSAGE_VIEWED(
        window.location.pathname,
        "CN appointment booking failed",
        {
          appointment_time_utc: selectedTimeSlot.timestamp,
          message: t("errorBooking"),
        },
      );
      bookingErrorToast();
    },
    onCompleted: ({ createAppointment }) => {
      const appointmentId = createAppointment?.appointment.id;

      if (createAppointment.success) {
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "CN appointment successfully scheduled",
          {
            appointment_time_utc: selectedTimeSlot.timestamp,
          },
        );
        setBookedAppointment(appointmentId);
      }
    },
  });

  const setSelectedDateAndTimeSlots = (
    selected: Date,
    allAppointments: AvailableCnAppointmentTimeOutputType[],
  ) => {
    const selectedDayAppts = allAppointments.filter((appt) =>
      isSameDay(appt.start_time, selected),
    );
    const timeSlots = getTimeSlotsForDay(selectedDayAppts);

    setSelectedDate(selected);
    setSelectedDateTimeSlots(timeSlots);
    setSelectedDayString(formatSelectedDay(selected));
  };

  const setFirstAvailableAppointment = (allAppointments) => {
    const firstAppointmentDate = new Date(allAppointments[0].start_time);

    setSelectedDateAndTimeSlots(firstAppointmentDate, allAppointments);
  };

  const handleSelectedDay = useCallback(
    (selectedDay: Date) => {
      setSelectedTimeSlot(null);
      setSelectedDateAndTimeSlots(selectedDay, availableAppointments);
    },
    [availableAppointments],
  );

  const handleNextClick = useCallback(() => {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Next button clicked");
    setShouldShowReviewScreen(true);
    doGetCareProvider({
      variables: {
        id: selectedTimeSlot?.payload?.careProviderId,
      },
    });
  }, [doGetCareProvider, selectedTimeSlot?.payload?.careProviderId]);

  const handleBackToCalendar = useCallback(() => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Back to availability clicked",
    );

    resetBookAppointment();
    setShouldShowReviewScreen(false);
  }, [resetBookAppointment]);

  const handleBookAppointment = useCallback(() => {
    resetBookAppointment();
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Book appointment button clicked",
      {
        provider_id: selectedTimeSlot?.payload?.careProviderId,
        start_at: selectedTimeSlot?.timestamp,
      },
    );
    const {
      timestamp: start_at,
      payload: { careProviderUserId },
    } = selectedTimeSlot;

    const provider = {
      user_id: careProviderUserId,
      role: isSpecialtyCN(careProviderData)
        ? "Specialty Care Navigator"
        : "Care Navigator",
    };
    const user = {
      user_id: Meowth.getUserId(),
      role: "Member",
    };
    const payload = {
      variables: {
        input: {
          put: {
            start_at,
            time_zone: DateTime.local().zoneName,
            kind: isSpecialtyCN(careProviderData)
              ? RequestableAppointmentKind.SpecialtyCareNavigation
              : RequestableAppointmentKind.CareNavigation,
            bookings: [user, provider],
            campaign: getIterableCampaignInfo(),
          },
        },
      },
    };
    bookAppointmentMutation(payload);
  }, [
    bookAppointmentMutation,
    resetBookAppointment,
    selectedTimeSlot,
    careProviderData,
  ]);

  return {
    availableAppointments,
    bookedAppointment,
    careProviderData,
    careProviderLoading,
    handleBackToCalendar,
    handleBookAppointment,
    handleNextClick,
    handleSelectedDay,
    handleSelectedTimeSlot: setSelectedTimeSlot,
    isAvailableAppointmentsLoading,
    isBookingAppointment,
    isMemberDataLoading,
    member,
    selectedDateTimeSlots,
    selectedDayString,
    selectedTimeSlot,
    shouldShowReviewScreen,
    selectedDate,
  };
};
