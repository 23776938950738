//
import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';



const InputWrapper = (props) => (
	<div
		className={classnames(styles.inputWrapper, styles[props.for], {
			[styles.full]         : props.full,
			[styles.large]        : props.large,
			[styles.inline]       : props.inline,
			[styles.invalid]      : props.valid,
			[styles[props.theme]] : props.theme,
			[styles.fixedSpacing] : props.fixedSpacing,
			[styles.noMargin]     : props.noMargin,
			[styles.normalFlow]   : props.normalFlow,
			[styles.renderLabelFirst]: props.renderLabelFirst
		})}
	>
		{props.children}
		<div className={styles.bar} />
	</div>
);

export default InputWrapper;
