import { gql } from "@apollo/client";

import paging from "operations/fragments/paging";

const careProviderTags = gql`
  query careProviderTags(
    $limit: Int
    $offset: Int
    $locale: String
    $sort_by: [SortType]
  ) {
    care_provider_tags(limit: $limit, offset: $offset, sort_by: $sort_by) {
      data {
        id
        kind
        name(locale: $locale)
        english_name
        roles
        created_at
        updated_at
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default careProviderTags;
