import React from "react";
import PropTypes from "prop-types";
import { Box, Heading, Text, Flex } from "design-system/components";

const MembershipItem = (props) => (
  <Box my={20}>
    <Flex justify="space-between">
      <Heading as="h4" fontSize={{ base: 16, md: 20 }} fontWeight={700}>
        {props.title}
      </Heading>
      <Text fontSize={{ base: 16, md: 20 }} fontWeight={700}>
        {props.amount}
      </Text>
    </Flex>

    <Text color="#424A4C" fontSize={16}>
      {props.description}
    </Text>
  </Box>
);

MembershipItem.propTypes = {
  amount: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
};

MembershipItem.defaultProps = {
  title: "",
  amount: "",
  description: "",
};

export default MembershipItem;
