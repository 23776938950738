import { gql } from "@apollo/client";

import { careTeamMember } from "operations/fragments/careProvider";

const getRecommendedTherapists = gql`
  query getRecommendedTherapists(
    $id: ID!
    $current_path: String
    $version: String
    $filters: RecommendedTherapistsFiltersInputType
  ) {
    user(id: $id) {
      id
      first_name
      last_name
      member {
        id
        minor
        manager {
          id
          first_name
          last_name
          email
        }
        recommended_therapists(
          current_path: $current_path
          version: $version
          filters: $filters
        ) {
          name
          group_name
          query_request_id
          therapist {
            ...careTeamMember
          }
        }
        possible_filters {
          mediums
          ethnicities
          specialties
          genders
        }
      }
    }
  }
  ${careTeamMember}
`;

export default getRecommendedTherapists;
