/**
 * Constants used to determine cost of sessions
 */

const SessionCost = {
	coaching             : '$90',
	coachingByCustomerId : (customerId) => {
		const costByCustomerId = {
			'c0754563-d1fa-4c38-aed0-4b53f4841a9c' : '$105',
			// Test customer in staging
			'edb60f54-a00d-4acb-b52a-7c301f78e6f1' : '$105',
		};
  
		return costByCustomerId[customerId] || '$90';
	},
};


module.exports = SessionCost;