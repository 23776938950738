import { useEffect, useRef } from "react";
import { Button } from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";
import { useInViewportOnce } from "hooks/useInViewport";
import type { TimeSlotType } from "modules/MemberDashboard/Scheduling/types";

type TimeSlotPropTypes = {
  handleTimeSlotClick: (slot: TimeSlotType) => void;
  selectedTimeSlot: TimeSlotType;
  slot: TimeSlotType;
};

export const TimeSlot = ({
  handleTimeSlotClick,
  selectedTimeSlot,
  slot,
}: TimeSlotPropTypes) => {
  const isSelected = selectedTimeSlot?.timestamp === slot.timestamp;
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");

  useEffect(() => {
    if (inViewport) {
      TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, "Time Slot", {
        appointment_time_utc: slot.timestamp,
      });
    }
  }, [inViewport, slot.timestamp]);

  const handleClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Time Slot Clicked", {
      appointment_time_utc: slot.timestamp,
    });
    handleTimeSlotClick(slot);
  };

  return (
    <Button
      onClick={handleClick}
      ref={ref}
      w={{ base: "fit-content", lg: "100%" }}
      mb={8}
      mr={4}
      variant="low-emphasis"
      selected={isSelected}
      sx={{
        _hover: { bg: "primary-base", color: "primary-on-base" },
        _focus: { bg: "primary-base", color: "primary-on-base" },
        bg: isSelected ? "primary-base" : null,
        color: isSelected ? "primary-on-base" : null,
      }}
    >
      {slot.formattedTime}
    </Button>
  );
};
