import { gql } from "@apollo/client";

export const getHelpInfo = gql`
  query getHelpInfo($country: String, $locale: String) {
    help_info(country: $country, locale: $locale) {
      available_countries {
        name
        iso2
        flag
      }
      selected_country
      region {
        name
        iso2
        chat_availability
        crisis_support_availability
        crisis_support_number
        email
        email_availability
        general_support_availability
        general_support_number
        work_life_direct_dial
        work_life_email
        work_life_free_phone
        languages
      }
    }
  }
`;

export default getHelpInfo;
