import { gql } from "@apollo/client";

const loginlessUpdateUnattendanceReason = gql`
  mutation LoginlessUpdateUnattendanceReason($input: UnattendanceReasonInput!) {
    loginlessUpdateUnattendanceReason(input: $input) {
      success
      id
    }
  }
`;

export default loginlessUpdateUnattendanceReason;
