import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { Bolded } from "@spring/smeargle";
import Meowth from "@spring/meowth";
import { Trans, withTranslation } from "react-i18next";
import { getMemberVisits } from "operations/queries/member";

const remainingSpecialist = (props) => {
  return props?.data?.user?.member?.visits_remaining_specialist ?? 0;
};

const contractEndDate = (props) => {
  return DateTime.fromISO(
    props?.data?.user?.member?.visits_eligibility_limit_date,
  ).toFormat("DDD");
};

const allVisitsCovered = (props) => {
  return props?.data?.user?.member?.visits_covered_total ?? null;
};

const remaining = (props) => {
  return props?.data?.user?.member?.visits_remaining_total ?? null;
};

const computeTranslationKey = (isAMinor) => {
  const baseKey = "remainingVisits";
  if (isAMinor) {
    return baseKey + "Minor";
  }
  return baseKey;
};

const RemainingVisits = ({ isAMinor, ...props }) => {
  const remainingSessions = remaining(props);
  const copyKey = computeTranslationKey(isAMinor);

  if (remainingSessions > 0) {
    const totalVisitsCovered = allVisitsCovered(props);
    return (
      <Trans
        ns={"careProvider"}
        i18nKey={"scheduleModal." + copyKey}
        values={{
          remaining: remainingSessions,
          totalVisitsCovered: totalVisitsCovered,
          contractEndDate: contractEndDate(props),
          remainingSpecialist: remainingSpecialist(props),
        }}
        components={[<Bolded key={"remaining-sessions"} />]}
      />
    );
  }

  return null;
};

RemainingVisits.propTypes = {
  isAMinor: PropTypes.bool,
  onlyBasic: PropTypes.bool,
  onlySpecialist: PropTypes.bool,
};

export { RemainingVisits };
export default compose(
  graphql(getMemberVisits, { options: Meowth.apolloOptionsUserId }),
)(withTranslation("careProvider")(RemainingVisits));
