// 

import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { Tooltip } from '../../atoms';
import { Stout } from '../../typography';
import { getFirstUpperCase } from '../../../utils/formatting';

import styles from './styles.module.scss';

import { tableDataSelector } from '../../../selectors/table';





const Headers = (props) => {
	if (!props.activeKeys) {
		return null;
	}

	const heads = props.activeKeys.map((key) => {
		let classes = [];

		for (let breakpoint in props.hideAt) {
			if (props.hideAt[breakpoint].includes(key)) {
				classes.push(styles[`hide-${breakpoint}`]);
			}
		}

		return (
			<th
				key={key}
				className={classnames(classes)}
			>
				<Stout>
					{getFirstUpperCase(props.keys[key])}
					{props.tooltips && props.tooltips[key] && (
						<Tooltip placement="top" text={props.tooltips[key]} />
					)}
				</Stout>
			</th>
		);

	});

	return (
		<thead>
			<tr>
				{heads}
			</tr>
		</thead>
	);
};

export { Headers };
export default connect(
	(state, ownProps ) => tableDataSelector(state, ownProps),
)(Headers);
