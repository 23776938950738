export const TEEN_ASSESSMENT_TELEMETRY_TYPES = {
  VIEW_APPT_DETAILS: "View Appointment Details",
  SURVEY_CHOOSE_USER: "Survey Choose User",
  COMPLETED_SURVEY: "Completed Survey",
  RETURN_TO_HOME: "Return to home page",
  SHARE_LINK: "Share link",
  SURVEY_LINK_COPIED: "Survey Link Copied",
  SURVEY_START: "Survey Start",
  ADULT_SURVEY_START: "Adult Starts Survey",
  TEEN_SURVEY_START: "Teen Starts Survey",
  IMPORTANT_NOTE: "Important Note",
};
