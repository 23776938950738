import { gql } from "@apollo/client";

const getSUDCareNavigators = gql`
  query getSUDCareNavigators {
    sud_care_navigators {
      id
      name
      licenses
      avatar_cdn_url
      roles
    }
  }
`;

export default getSUDCareNavigators;
