"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDetectScrollTop = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var useDetectScrollTop = exports.useDetectScrollTop = function useDetectScrollTop() {
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    scroll = _useState2[0],
    setScroll = _useState2[1];
  (0, _react.useEffect)(function () {
    var handleScroll = function handleScroll() {
      var scrollY = window.scrollY;
      if (scrollY !== 0 && !scroll) {
        setScroll(true);
      }
      if (scrollY === 0) {
        setScroll(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return function () {
      return document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scroll;
};