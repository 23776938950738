//
import * as actions from './types';


export function initializeForm(
	formKey,
	schema,
	initialData = {},
	meta,
	theme = 'simple'
) {
	return (dispatch) => {
		dispatch({
			type: actions.INITIALIZE_FORM,
			initialData,
			formKey,
			meta,
			theme,
			schema,
		});
	};
}

export function setField(formKey, fieldKey, value, fieldType = '') {
	return (dispatch) => {
		dispatch({
			type  : actions.SET_FORM_FIELD,
			dirty : true,
			formKey,
			fieldKey,
			fieldType,
			value,
		});
	};
}

export function setFormValidation(formKey, validation, isValid) {
	return {
		type: actions.SET_FORM_VALIDATION,
		formKey,
		validation,
		isValid,
	};
}

export function clearForm(formKey) {
	return {
		type: actions.CLEAR_FORM,
		formKey,
	};
}

export function removeFormField(formKey, fieldKey, index = null) {
	return (dispatch) => {
		dispatch({
			type: actions.REMOVE_FORM_FIELD,
			formKey,
			fieldKey,
			index,
		});
	};
}

export function setDatepickerDisplayDate(formKey, fieldKey, value) {
	return {
		type: actions.SET_DATEPICKER_DISPLAY_DATE,
		formKey,
		fieldKey,
		value,
	};
}

export function setTypeaheadSearchValue(formKey, fieldKey, value) {
	return {
		type: actions.SET_TYPEAHEAD_SEARCH_VALUE,
		formKey,
		fieldKey,
		value,
	};
}

export function setAddressValue(formKey, fieldKey, value) {
	return {
		type: actions.SET_ADDRESS_VALUE,
		formKey,
		fieldKey,
		value,
	};
}
