import { useState, useRef, useEffect } from "react";
import {
  Box,
  Circle,
  Flex,
  Heading,
  HStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import Router from "next/router";
import { useInViewportOnce } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes/PublicRoutes";

import { Ratings } from "./Ratings";

// TODO: move to type folder to use here and UpcomingAppointmentCard.tsx
type MemberPortalReduxState = {
  global: {
    lang?: string;
    [key: string]: any;
  };
  [key: string]: any;
};

export const SentimentRatingCard = ({ appointment }) => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);

  const { t } = useTranslation("homepage");
  const imageUrl = appointment?.provider?.avatar;
  const providerFirstName = appointment?.provider?.name.split(" ")[0];
  const locale = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.lang,
  );

  const cardTitleSize = useBreakpointValue([
    "heading-small",
    "heading-small",
    "heading-medium",
    "heading-medium",
  ]);

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(
        window.location.pathname,
        "Sentiment Rating Card",
        {
          appointment_id: appointment?.id,
          provider_id: appointment?.provider_id,
        },
      );
      setTrackedOnce(true);
    }
  }, [inViewport, trackedOnce]);

  const { to, as } = routes.Feedback;

  const onRatingClick = (rating: number) => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Card Rating Clicked",
      {
        sentiment_rating: rating,
        appointment_id: appointment?.id,
        provider_id: appointment?.provider_id,
      },
    );
    const asRoute = `${as}?appointment_id=${appointment?.id}&provider_id=${appointment?.provider_id}&sentiment_value=${rating}&appt_type=${appointment?.kind}`;
    Router.replace(
      {
        pathname: to,
        query: {
          appointment_id: appointment?.id,
          provider_id: appointment?.provider_id,
          sentiment_value: rating,
          appt_type: appointment?.kind,
        },
      },
      asRoute,
    );
  };

  const appointmentTime = DateTime.fromISO(appointment?.start_at).setLocale(
    locale,
  );

  return (
    <Box
      w="100%"
      borderWidth="3px"
      borderRadius="12px"
      borderColor="tertiary.light"
      backgroundColor="background-base"
    >
      <Box w="100%" h="100%" p="24px">
        <Flex
          direction={{ base: "column", lg: "row", md: "column", sm: "column" }}
          w="100%"
          justifyContent="space-between"
        >
          <HStack w="full" maxW="560px">
            <Flex>
              <Heading size={`${cardTitleSize}`}>
                {t("upNextSection.sentimentRating.cardTitle", {
                  appointmentDate: appointmentTime.toLocaleString({
                    month: "long",
                    day: "numeric",
                  }),
                  careProviderName: providerFirstName,
                })}
              </Heading>
            </Flex>
            {/* @ts-ignore */}
            <Circle w="40px" h="40px">
              {Boolean(imageUrl) && (
                <>
                  {typeof imageUrl !== "string" ? (
                    imageUrl
                  ) : (
                    <Image
                      object-fit="cover"
                      height="100%"
                      width="100%"
                      borderRadius="full"
                      src={imageUrl}
                      alt={t(
                        "upNextSection.upcomingAppointments.avatarCaption",
                      )}
                      aria-hidden="true"
                    />
                  )}
                </>
              )}
            </Circle>
          </HStack>
          <Flex
            w="346px"
            align="center"
            justifyContent={{ base: "flex-start", lg: "center" }}
          >
            <Ratings onSubmit={onRatingClick} />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
