import PropTypes from "prop-types";
import { Grid, Flex, Spacer, HStack, Box, useMediaQuery } from "../../index";
import { SpringLogoIcon } from "../../../index";
import { NavHelpBtn } from "./HeaderButtons";

export const SHHeaderMaintenance = ({ onOpen }) => {
  const [isMobileTrue] = useMediaQuery("(max-width: 767px)");

  const MobileHeader = () => (
    <Box as="nav" alignItems="center">
      <Box h={95} p={24}>
        <Flex alignItems="center">
          <SpringLogoIcon boxSize="28px" />
          <Spacer />
          <HStack spacing={8} justifyContent="flex-end">
            <NavHelpBtn onClick={onOpen} />
          </HStack>
        </Flex>
      </Box>
    </Box>
  );

  const DesktopHeader = () => {
    return (
      <Box as="nav">
        <Grid templateColumns="repeat(2, 1fr)" gap={0} h={95}>
          <Flex bg="tertiary.25" h={95} alignItems="center">
            <Box ps={48}>
              <SpringLogoIcon boxSize="28px" />
            </Box>
          </Flex>
          <Flex
            w={[null, null, "calc(100% - 48px)", "80%"]}
            alignItems="center"
            m="0 auto"
            bg="white"
          >
            <Spacer />
            <NavHelpBtn onClick={onOpen} />
          </Flex>
        </Grid>
      </Box>
    );
  };

  return <>{isMobileTrue ? <MobileHeader /> : <DesktopHeader />}</>;
};

SHHeaderMaintenance.propTypes = {
  onOpen: PropTypes.func.isRequired,
};
