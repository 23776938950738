import React, { ReactNode, useEffect, useRef } from "react";
import { Container, useMediaQuery } from "@chakra-ui/react";
import { useVideoPlayer } from "modules/shared/components/VideoPlayer/VideoPlayer/context/videoPlayerContext";
import { useVideoPlayerHotKeys } from "modules/shared/components/VideoPlayer/VideoPlayer/context/useVideoPlayerHotKeys";

interface PlayerContainerProps {
  children: ReactNode;
}

export const PlayerContainer: React.FC<
  React.PropsWithChildren<PlayerContainerProps>
> = ({ children }) => {
  const {
    isMobile,
    showControls,
    setShowControls,
    isFullScreen,
    setContainerRef,
  } = useVideoPlayer();

  useVideoPlayerHotKeys();

  const [isPortrait] = useMediaQuery("(orientation: portrait)");

  const containerRefLocal = useRef(null);

  useEffect(() => {
    setContainerRef(containerRefLocal);
  }, [containerRefLocal]);

  const onMouseEnter = () => {
    if (!isMobile) {
      setShowControls(true);
    }
  };

  const onMouseLeave = () => {
    if (!isMobile) {
      setShowControls(false);
    }
  };

  const handleTap = () => {
    if (isMobile && !showControls) {
      setShowControls(true);
    }
  };

  const onMouseMove = () => {
    if (isFullScreen && !showControls) {
      setShowControls(true);
    }
  };

  return (
    <Container
      ref={containerRefLocal}
      style={isPortrait ? { aspectRatio: "9/16" } : { aspectRatio: "16/9" }}
      position="relative"
      width="100%"
      height="100%"
      justifyContent="center"
      padding="0"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={handleTap}
      onMouseMove={onMouseMove}
      maxWidth="100%"
    >
      {children}
    </Container>
  );
};

PlayerContainer.displayName = "PlayerContainer";
