import {
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip,
  VStack,
} from "design-system/components";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

import SliderDots from "./SliderDots";
import SliderRangeLabels from "./SliderRangeLabels";
import SliderRangeValues from "./SliderRangeValues";
import Title from "./Title";
import styles from "./styles.module.scss";

import { getMarkerLabel } from "./utils";

interface SHSliderInputProps {
  CustomTooltip?: any | undefined;
  dataCy?: string;
  hideSliderMarkers?: boolean;
  labelModifier?: (label: string) => string;
  leftLabel: string;
  marginBottom?: string;
  markers?: string[];
  max?: number;
  midLabel?: string;
  min?: number;
  onChange: (val: string | number) => void;
  rightLabel: string;
  step?: number;
  subtitle?: string;
  title?: string;
  toolTipDisabled?: boolean;
  tooltipMarginTop?: string;
  toolTipRef: any;
  value: string | number | null;
}

const inactiveTooltipStyling = {
  bg: "background-disabled",
  boxShadow: "none",
  color: "content-secondary",
};

const activeTooltipStyling = {
  bg: "white",
  boxShadow: "boxShadow",
  color: "primary-base",
};

export const SHSliderInput = ({
  CustomTooltip = undefined,
  dataCy = "slider",
  hideSliderMarkers = false,
  labelModifier = (label) => label,
  leftLabel,
  marginBottom = "58px",
  markers = null,
  max = null,
  midLabel,
  min = null,
  onChange,
  rightLabel,
  step = null,
  subtitle,
  title,
  toolTipDisabled = false,
  tooltipMarginTop = "60px",
  toolTipRef = undefined,
  value: propValue,
}: SHSliderInputProps) => {
  const { t } = useTranslation("careProvider");

  const hasValue = !isNil(propValue);
  const value = hasValue ? parseInt(propValue.toString()) : null;

  const handleFocus = () => {
    if (hasValue) return;

    onChange(min);
  };

  const markerLabel = getMarkerLabel({
    labelModifier,
    markers,
    max,
    min,
    t,
    value,
  });

  const sliderCaption = t("slider.sliderCaption", {
    title: title,
    min: min,
    max: max,
    leftLabel: ", " + leftLabel || "",
    rightLabel: ", " + rightLabel || "",
  });

  const SliderTooltip = CustomTooltip || Tooltip;

  // adjust tooltip alignment so it doesn't go past the end of the slider bar
  const tooltipPosition =
    !value || value === min ? "top-start" : value === max ? "top-end" : "top";

  return (
    <VStack width="100%" mb={marginBottom}>
      <Title title={title} subtitle={subtitle} />

      <HStack width="100%" spacing={20}>
        <HStack
          mb={hideSliderMarkers ? "8px" : "25px"}
          mt={!toolTipRef ? "16px" : tooltipMarginTop}
          px="12px"
          width="100%"
        >
          <Slider
            getAriaValueText={() => markerLabel}
            max={max}
            min={min}
            onChange={onChange}
            step={step || 1}
            value={value}
          >
            <div className={styles.hiddenCaptionStyling} tabIndex={-1}>
              {sliderCaption}
            </div>

            <SliderDots max={max} min={min} value={value} />

            {!hideSliderMarkers && <SliderRangeValues max={max} min={min} />}

            <SliderTrack
              bg="background-overlay"
              borderRadius="100px"
              h={["4px", "8px"]}
              width="100%"
              zIndex={-2}
            >
              <SliderFilledTrack bg="primary-base" width="100%" />
            </SliderTrack>

            <SliderTooltip
              {...(hasValue ? activeTooltipStyling : inactiveTooltipStyling)}
              fontSize="16"
              fontWeight="bold"
              isOpen={!toolTipDisabled}
              label={markerLabel}
              mb="8px"
              minW="max-content"
              p={2}
              borderRadius="8px"
              placement={tooltipPosition}
              aria-label={sliderCaption}
            >
              <SliderThumb
                borderColor={hasValue ? "primary-base" : "background-overlay"}
                borderWidth={3}
                boxSize={6}
                data-cy={dataCy}
                onFocus={handleFocus}
                title={t("slider.sliderThumb", {
                  title: title,
                })}
                zIndex={0}
                _focus={{ boxShadow: "0 0 0 3px rgb(86,142,207)" }}
              />
            </SliderTooltip>
          </Slider>
        </HStack>
      </HStack>

      <SliderRangeLabels
        leftLabel={leftLabel}
        midLabel={midLabel}
        rightLabel={rightLabel}
      />
    </VStack>
  );
};
