import { useTracks } from "@livekit/components-react";
import { Track } from "livekit-client";

export const useSessionTracks = () => {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: true },
    ],
    { onlySubscribed: false },
  );
  const localTrack = tracks.find((track) => track.participant.isLocal);
  const remoteTracks = tracks.filter(
    (track) =>
      track.participant.permissions?.canPublish &&
      !track.participant.isLocal &&
      track.source === Track.Source.Camera,
  );

  const screenshareTracks = tracks.filter(
    (track) =>
      track.participant.permissions?.canPublish &&
      track.participant.isScreenShareEnabled &&
      track.source === Track.Source.ScreenShare,
  );

  return {
    localTrack,
    remoteTracks,
    screenshareTracks,
  };
};
