import { useTranslation } from "react-i18next";
import { smeargleMaxTabletSize } from "constants/smeargle";
import { Flex, Box, Text, Heading, useMediaQuery } from "@chakra-ui/react";
import {
  VInsuranceIcon,
  VFinancialHelpIcon,
} from "@springcare/verdant-icons-react";

import { InteractableCard } from "./shared";
import { TRACK_EVENT } from "utils/mixpanel";
import { useEffect, useRef, useState } from "react";
import { useInViewportOnce } from "hooks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const InitialDisplay = ({
  trackingProperties,
  changeToInsuranceFormDisplayCallback,
  changeToSelfPayDisplayCallback,
}) => {
  const isInsuranceValidationEnabled = useFeatureFlag(
    FLAGS.INSURANCE_VALIDATION,
  );
  const { t } = useTranslation("costEstimate");
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const [isUnderSmeargleTabletSize] = useMediaQuery(
    `(max-width: ${smeargleMaxTabletSize}px)`,
  );
  const widthValueToUse = isUnderSmeargleTabletSize ? "100%" : "724px";

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(
        window.location.pathname,
        "Request cost estimate",
        trackingProperties,
      );
      setTrackedOnce(true);
    }
  });

  return (
    <Flex
      ref={ref}
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Heading as="h1" width={widthValueToUse} size="heading-medium" mb={24}>
        {isInsuranceValidationEnabled
          ? t("requestCostEstimateHeading_InsuranceValidation")
          : t("requestCostEstimateHeading")}
      </Heading>
      <Box as="section" width={widthValueToUse}>
        <Text>
          {isInsuranceValidationEnabled
            ? t("requestForInfoDescription_InsuranceValidation")
            : t("requestForInfoDescription")}
        </Text>
        {!isInsuranceValidationEnabled && (
          <Text mb={16}>{t("requestForInfoPrompt")}</Text>
        )}
        <InteractableCard
          data-cy="request-cost-estimate-insurance-card"
          cardText={t("addInsuranceCtaText")}
          cardSubtext={
            isInsuranceValidationEnabled
              ? t("addInsuranceCtaSubtext_InsuranceValidation")
              : t("addInsuranceCtaSubtext")
          }
          primaryIcon={VInsuranceIcon}
          handleClickCallback={changeToInsuranceFormDisplayCallback}
        />
        <InteractableCard
          cardText={t("selfPayCtaText")}
          cardSubtext={
            isInsuranceValidationEnabled
              ? t("selfPayCtaSubtext_InsuranceValidation")
              : t("selfPayCtaSubtext")
          }
          primaryIcon={VFinancialHelpIcon}
          handleClickCallback={changeToSelfPayDisplayCallback}
        />
      </Box>
    </Flex>
  );
};
