import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useRouter, withRouter } from "next/router";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { useMutation, useApolloClient } from "@apollo/client";
import { addNotification } from "@spring/smeargle/actions";

import FamilySignupPageForms from "./FamilySignupPageForms";

import routes from "routes";
import { signupMember } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";
import {
  track,
  TRACK_EVENT,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";
import {
  updateFamilySignupModel,
  updateFindMyMembershipModel,
} from "actions/signUp";
import { verifyToken } from "actions/auth/actions";
import { createPotentialCoveredLife } from "operations/mutations/user";
import { getUserInfo } from "operations/queries/user";
import { isMinor } from "utils/memberHelpers";
import Router from "next/router";

const FamilySignupPage = (props) => {
  const router = useRouter();
  const [isCensusError, setCensusError] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const isAMinor = isMinor(userInfo?.member);

  useEffect(() => {
    if (isAMinor) {
      Router.replace(routes.MemberCareVisits.to, routes.MemberCareVisits.as);
    }
  }, [isAMinor]);

  const client = useApolloClient();
  const isUserCensusDependent =
    userInfo?.member?.cohort?.contract_term?.census_dependents_only;

  const [doCreatePotentialCL] = useMutation(createPotentialCoveredLife);

  useEffect(() => {
    verifyToken().then((userIdentifiers) => {
      if (!userIdentifiers) {
        router.replace(routes.Logout.as);
      }
    });
  }, []);

  useEffect(() => {
    //fixes refresh issue
    if (props.familySignupModel.family_signup_step === null) {
      props.updateFamilySignupModel({
        fieldKey: "family_signup_step",
        value: "CHILD",
      });
    }
    const userId = localStorage.getItem("uuid");
    const cachedUserData = client.readQuery({
      query: getUserInfo,
      variables: { id: userId },
    });
    setUserInfo(cachedUserData?.user);
  }, []);

  const childSignup = (data) => {
    if (data.tos_pp_hippa) {
      data.terms_of_service =
        data.privacy_policy =
        data.hipaa_privacy_policy =
          true;
      delete data.tos_pp_hippa;
    }
    data.coppa_notice = data.informed_consent;
    const manager_id = localStorage.getItem("member_id");
    let { cohort_id } = props.verificationModel;

    if (!cohort_id) {
      cohort_id = new URL(location.href).searchParams.get("cohort_id");
    }
    if (!cohort_id) {
      cohort_id = data.cohort_id;
    }

    const country = data.country;

    const payload = {
      put: {
        ...data,
        // this is the guardian's phone number
        phone: props.familySignupModel.guardian_phone_number,
        country_code: "us",
        date_of_birth: data.date_of_birth.actualDate,
        cohort_id,
        manager_id,
        country,
      },
    };

    return props
      .submit(payload)
      .then((res) => {
        if (res.data.signupMember.success) {
          TRACK_EVENT.FORM_SUBMITTED(
            routes.FamilySignup.as,
            "Family Signup Child",
          );
          track("Signup -- Account Created", {
            deprecated: true,
            replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
              page: routes.FamilySignup.as,
              type: "Family Signup Child",
            }),
          });
          let { to, as } = routes.ChooseUser;
          router.push(to, as);
        } else {
          TRACK_EVENT.ERROR_MESSAGE_VIEWED(
            routes.FamilySignup.as,
            "Family Signup Child",
          );
          track("Signup -- Account Failed", {
            deprecated: true,
            replaced_with: "N/A",
          });
          props.addNotification(
            "Something went wrong, please try again",
            "error",
          );
        }
      })
      .catch((err) => {
        if (isUserCensusDependent) {
          const censusErrors = [
            "We were unable to find the above dependent within our system. If you believe that your dependent should be eligible for benefits, please file a Support Ticket and we will assist you",
            "The dependent you are trying to add is not eligible for Spring Health, for further assistance reach out to Spring Health support",
          ];
          if (censusErrors.includes(err.message)) {
            doCreatePotentialCL({
              variables: {
                input: {
                  first_name: userInfo.first_name,
                  last_name: userInfo.last_name,
                  email: userInfo.email,
                  customer: userInfo.member?.cohort?.customer?.name,
                  city: userInfo.member?.postal_address?.city,
                  state: userInfo.member?.postal_address?.state,
                  country: userInfo.member?.postal_address?.country,
                  primary: false,
                  metadata: JSON.stringify({
                    phone: userInfo.phone,
                    dependent_first_name: payload?.put?.first_name,
                    dependent_last_name: payload?.put?.last_name,
                    dependent_email: payload?.put?.email,
                    dependent_dob: payload?.put?.date_of_birth,
                  }),
                },
              },
            });
            TRACK_EVENT.ERROR_MESSAGE_VIEWED(
              routes.FamilySignup.as,
              "Family Signup Child",
              {
                page_version: "Error Census Dependent",
                message: err,
              },
            );
            setCensusError(true);
            return;
          }
        }
        TRACK_EVENT.ERROR_MESSAGE_VIEWED(
          routes.FamilySignup.as,
          "Family Signup Child",
        );
        track("Signup -- Account Failed", {
          deprecated: true,
          replaced_with: "N/A",
        });
        props.addNotification(getFirstError(err), "error");
      });
  };

  return (
    <FamilySignupPageForms
      childSignup={childSignup}
      isCensusError={isCensusError}
    />
  );
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      findMyMembershipModel: state.signUp.findMyMembershipModel,
      familySignupModel: state.signUp.familySignupModel,
      verificationModel: state.signUp.verificationModel,
    }),
    { addNotification, updateFamilySignupModel, updateFindMyMembershipModel },
  ),
  graphql(signupMember, {
    props: ({ mutate }) => ({
      submit: (input) => {
        return mutate({
          variables: {
            input: {
              ...input,
            },
          },
        });
      },
    }),
  }),
)(FamilySignupPage);

FamilySignupPage.propTypes = {
  addNotification: PropTypes.func,
  familySignupModel: PropTypes.shape({
    family_signup_step: PropTypes.string,
    guardian_phone_number: PropTypes.string,
    is_family_signup: PropTypes.bool,
  }),
  findMyMembershipModel: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    email_address: PropTypes.string,
    is_dependents_allowed: PropTypes.bool,
    country: PropTypes.string,
    potential_member_type: PropTypes.string,
  }),
  submit: PropTypes.func,
  updateFamilySignupModel: PropTypes.func,
  updateFindMyMembershipModel: PropTypes.func,
  verificationModel: PropTypes.shape({
    cohort_id: PropTypes.string,
    invite_token: PropTypes.string,
  }),
};
