import React, { ReactElement } from "react";
import { useTranslation } from "hooks/react-i18next";

import {
  Flex,
  HStack,
  VStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import { Error } from "design-system/assets";
import { SHTextInput } from "design-system/components";

const requiredValidation = (text): { required: ReactElement } => {
  return {
    required: (
      <Flex alignItems="center">
        <Error />
        <Text ml={2} color="red.600">
          {text}
        </Text>
      </Flex>
    ),
  };
};

const InsuranceForm = ({ handleSubmit, register, errors }) => {
  const { t } = useTranslation(["limitedLangSettings"]);
  const Stack = useBreakpointValue({ base: VStack, md: HStack });

  return (
    <form onSubmit={handleSubmit}>
      <VStack
        spacing={[16, 3]}
        backgroundColor="secondary.light"
        py={24}
        borderRadius={8}
      >
        <Stack
          w="100%"
          px={24}
          display="flex"
          justifyContent="space-between"
          spacing={16}
          align="start"
        >
          <SHTextInput
            required
            errors={errors?.carrier_name}
            name="carrier_name"
            label={`${t("insurance.carrier")} *`}
            register={register}
            inputType="text"
            isSubLabel={false}
            validation={requiredValidation(t("insurance.requiredField"))}
            autoComplete="off"
            height={40}
            tabIndex={1}
          />

          <SHTextInput
            required
            errors={errors?.plan_name}
            name="plan_name"
            label={`${t("insurance.planName")} *`}
            register={register}
            inputType="text"
            isSubLabel={false}
            validation={requiredValidation(t("insurance.requiredField"))}
            autoComplete="off"
            height={40}
            tabIndex={1}
          />
        </Stack>

        <Stack
          w="100%"
          px={24}
          display="flex"
          justifyContent="space-between"
          spacing={16}
          align="start"
        >
          <SHTextInput
            required
            errors={errors?.insurance_group_id}
            name="insurance_group_id"
            label={`${t("insurance.groupId")} *`}
            register={register}
            inputType="text"
            isSubLabel={false}
            validation={requiredValidation(t("insurance.requiredField"))}
            autoComplete="off"
            height={40}
            tabIndex={1}
          />
          <SHTextInput
            required
            errors={errors?.insurance_member_id}
            name="insurance_member_id"
            label={`${t("insurance.memberId")} *`}
            register={register}
            inputType="text"
            isSubLabel={false}
            validation={requiredValidation(t("insurance.requiredField"))}
            autoComplete="off"
            height={40}
            tabIndex={1}
          />
        </Stack>
      </VStack>
    </form>
  );
};

export default InsuranceForm;
