import { useLazyQuery } from "@apollo/client";
import { getCareProvider } from "operations/queries/careProvider";

export const useFullCareProvider = (providerId: string) => {
  const [
    getCareProviderInfo,
    { data: fullCareProviderInfo, loading: isCareProviderInfoLoading },
  ] = useLazyQuery(getCareProvider, {
    fetchPolicy: "cache-first",
    variables: {
      id: providerId,
    },
  });

  const careProviderInfo = async () => {
    const providerInfo = await getCareProviderInfo();
    return providerInfo;
  };

  const careProviderInfoResult = {
    ...fullCareProviderInfo,
    careProviderInfo,
  };

  return [careProviderInfo, careProviderInfoResult, isCareProviderInfoLoading];
};
