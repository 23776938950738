import React from "react";
import { useTranslation } from "react-i18next";

type SpringTextLogoProps = {
  componentFrom?: String;
};

export const SpringTextLogo = ({ componentFrom }: SpringTextLogoProps) => {
  const { t } = useTranslation("a11y");
  const suffix = componentFrom ? `-${componentFrom}` : "";
  const titleId = `spring-health-text-logo${suffix}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 299 48"
      fill="none"
      role="img"
      aria-labelledby={titleId}
    >
      <title id={titleId}>{t("shLogoAlt")}</title>
      <path
        d="M15.2115 20.0672L11.0653 18.4152C7.53944 16.9858 6.29427 15.2483 6.29427 12.9716C6.29427 10.1599 8.16844 8.59783 11.4675 8.59783C15.0361 8.59783 17.0429 11.2769 16.5508 15.4666L21.5015 15.9587C22.751 10.1128 18.5148 5.78613 11.6429 5.78613C5.39998 5.78613 1.3393 9.00011 1.3393 13.9516C1.3393 17.6535 3.52582 20.555 9.28095 22.8746L13.3844 24.5265C16.6407 25.8189 17.98 27.6506 17.98 30.1499C17.98 33.3639 15.6608 35.371 11.9125 35.371C7.40679 35.371 4.99777 32.4694 4.99777 27.4281H0C0 34.1642 4.59555 38.1784 11.9553 38.1784C18.7373 38.1784 23.0633 34.7419 23.0633 29.3453C23.0633 25.3311 20.7441 22.2498 15.2115 20.0672V20.0672Z"
        fill="#01382E"
      />
      <path
        d="M38.3179 17.08C35.1344 17.08 32.3617 18.8774 30.9582 21.5564V17.662H26.0503V47.5935H30.9582V33.7533C32.3874 36.3895 35.1644 38.1784 38.3179 38.1784C43.4911 38.1784 47.3721 33.6292 47.3721 27.6934C47.3721 21.7576 43.4911 17.0757 38.3179 17.0757V17.08ZM36.6235 34.5236C33.8507 34.5236 31.6856 32.6021 30.9582 29.816V29.7048H30.9325C30.7785 29.0757 30.6929 28.4038 30.6929 27.6976C30.6929 26.9444 30.7828 26.2255 30.9539 25.5578H30.9625V25.5322C31.6899 22.6905 33.855 20.739 36.6278 20.739C39.9739 20.739 42.3358 23.6834 42.3358 27.6976C42.3358 31.7119 39.9739 34.5236 36.6278 34.5236H36.6235Z"
        fill="#01382E"
      />
      <path
        d="M61.381 17.0801C58.2103 17.0801 56.3319 19.4895 55.2707 22.635V17.6578H50.4526V37.6008H55.2707V30.9973C55.2707 24.304 57.8594 21.0943 59.7764 21.0943C60.5808 21.0943 61.0258 21.6764 60.8461 22.9688L65.7968 23.461C66.2418 20.3839 65.0822 17.0801 61.381 17.0801V17.0801Z"
        fill="#01382E"
      />
      <path
        d="M70.5252 9.80859C68.7409 9.80859 67.5813 11.0112 67.5813 12.7102C67.5813 14.4092 68.7409 15.6117 70.5252 15.6117C72.3095 15.6117 73.4691 14.4092 73.4691 12.7102C73.4691 11.0112 72.3095 9.80859 70.5252 9.80859Z"
        fill="#01382E"
      />
      <path
        d="M72.8866 17.6575H68.1584V37.6004H72.8866V17.6575Z"
        fill="#01382E"
      />
      <path
        d="M89.4804 17.0801C85.8861 17.0801 83.2931 19.3483 81.8083 23.7177V17.6578H76.9004V37.6008H81.8083V34.6564C81.8083 26.0886 84.1703 21.0045 88.1882 21.0045C90.242 21.0045 91.132 22.3868 91.132 25.5109V37.6008H96.0399V25.5109C96.0399 20.1571 93.6737 17.0801 89.4847 17.0801H89.4804Z"
        fill="#01382E"
      />
      <path
        d="M110.045 35.3709H108.261C105.137 35.3709 104.157 35.0157 104.157 34.2539C104.157 33.7618 104.602 32.8246 109.108 32.8246C114.726 32.8246 118.834 29.4779 118.834 24.9287C118.834 22.4679 117.589 20.311 115.342 18.903C118.132 18.6248 120.353 16.9772 120.353 15.0685L115.355 14.4009C115.355 16.5407 114.508 17.6149 112.813 17.6149C111.744 17.6149 111.162 17.2126 109.112 17.2126C103.537 17.2126 99.4332 20.4693 99.4332 24.933C99.4332 28.0143 101.32 30.5392 104.268 31.8531C101.055 32.5721 99.523 33.7832 99.523 35.3281C99.523 36.7618 100.802 37.7974 104.033 38.1313C100.456 38.9701 97.9185 40.8959 97.9185 43.2711C97.9185 46.2154 101.71 48 107.91 48C115.539 48 120.4 45.2782 120.4 40.9943C120.4 37.3353 116.741 35.3709 110.049 35.3709H110.045ZM109.108 19.9344C111.782 19.9344 113.926 22.0742 113.926 24.933C113.926 27.7917 111.786 30.107 109.108 30.107C106.429 30.107 104.337 27.7875 104.337 24.933C104.337 22.0785 106.433 19.9344 109.108 19.9344ZM108.44 45.2739C104.782 45.2739 102.818 44.2939 102.818 42.4622C102.818 40.6305 104.735 39.2953 108.393 39.2953H110.135C113.524 39.2953 115.488 40.2325 115.488 41.9273C115.488 43.9344 112.676 45.2739 108.44 45.2739V45.2739Z"
        fill="#01382E"
      />
      <path
        d="M150.639 20.3792H136.587V6.37207H131.589V37.6003H136.587V23.101H150.639V37.6003H155.632V6.37207H150.639V20.3792Z"
        fill="#01382E"
      />
      <path
        d="M168.837 35.461C165.628 35.461 164.152 32.6279 164.07 27.8304H178.426C178.738 22.2969 175.482 17.0801 168.88 17.0801C163.394 17.0801 159.154 21.2741 159.154 27.655C159.154 34.0359 162.945 38.1828 168.965 38.1828C173.514 38.1828 177.262 35.3711 178.469 30.5523H173.471C172.846 33.9417 171.152 35.461 168.833 35.461H168.837ZM168.927 19.8019C171.439 19.8019 173.056 21.7363 173.373 25.3354H164.186C164.631 21.5951 166.338 19.8019 168.927 19.8019V19.8019Z"
        fill="#01382E"
      />
      <path
        d="M190.206 17.08C185.88 17.08 182.042 20.0243 181.687 23.6363L186.637 24.1285C186.86 21.5393 187.84 19.8018 190.339 19.8018C192.838 19.8018 193.993 21.4024 194.138 24.7918C185.602 25.5664 180.792 28.1384 180.792 32.9188C180.792 36.2183 183.159 38.1827 186.813 38.1827C190.091 38.1827 192.585 36.5607 194.13 33.5093V37.6006H199.038V25.331C199.038 19.7975 196.137 17.0757 190.206 17.0757V17.08ZM188.422 35.3282C186.77 35.3282 185.7 34.2583 185.7 32.341C185.7 29.3325 188.597 27.4794 194.1 27.1713C193.753 31.8788 191.353 35.3282 188.422 35.3282Z"
        fill="#01382E"
      />
      <path
        d="M207.826 6.81738H202.918V37.6005H207.826V6.81738Z"
        fill="#01382E"
      />
      <path
        d="M219.555 35.4605C218.618 35.4605 218.215 34.5233 218.215 32.5162V20.2466H224.505V17.6574H218.215V10.9641H213.308V17.6574H210.051V20.2466H213.308V31.6217C213.308 35.9484 215.361 38.1781 219.285 38.1781C222.764 38.1781 225.04 36.3935 225.443 33.1795H220.984C220.714 34.8742 220.316 35.4563 219.555 35.4563V35.4605Z"
        fill="#01382E"
      />
      <path
        d="M240.252 17.0799C236.658 17.0799 234.065 19.348 232.58 23.7175V6.81738H227.672V37.6005H232.58V34.6562C232.58 26.0884 234.947 21.0043 238.96 21.0043C241.01 21.0043 241.904 22.3866 241.904 25.5107V37.6005H246.812V25.5107C246.812 20.1569 244.446 17.0799 240.252 17.0799V17.0799Z"
        fill="#01382E"
      />
      <path
        d="M277.5 11.1441C274.325 13.8317 271.677 16.7033 269.469 19.5792C274.509 16.0956 280.431 14.2981 286.336 14.7047C290.816 15.0171 294.907 16.6562 297.851 18.3852C297.851 18.3852 298.985 16.4765 298.33 7.77175C297.979 3.08559 298.287 0 298.287 0C293.957 0.265335 283.268 6.26105 277.5 11.1441V11.1441Z"
        fill="#01382E"
      />
      <path
        d="M286.131 17.6919C279.366 17.2211 272.545 20.0499 267.411 25.1683C270.573 23.3923 274.745 22.0185 279.841 22.4208C286.696 22.9643 291.471 26.5763 294.252 29.4779C295.818 27.1755 296.892 24.5135 297.316 21.6419C294.547 19.776 290.769 18.0128 286.131 17.6919V17.6919Z"
        fill="#01382E"
      />
      <path
        d="M279.605 25.4079C270.41 24.6804 264.655 30.6675 263.11 32.5163C261.985 33.8687 261.052 35.3922 260.333 37.0441C260.248 37.318 260.162 37.5834 260.085 37.8401C260.085 37.8401 267.41 39.4236 278.698 38.1782C289.986 36.9329 292.361 31.823 292.361 31.823C290.003 29.2681 285.776 25.8958 279.601 25.4079H279.605Z"
        fill="#01382E"
      />
      <path
        d="M275.562 8.85877C279.824 5.25107 284.625 2.26819 289.764 0L278.579 0.0727532C268.177 0.136947 259.798 8.62339 259.863 19.0271L259.931 29.7603C262.807 23.3795 267.62 15.5863 275.558 8.86305L275.562 8.85877Z"
        fill="#01382E"
      />
    </svg>
  );
};
