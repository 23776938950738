import {
  UPDATE_PRE_ASSESSED_EXPERIENCE_MODEL,
  RESET_PRE_ASSESSED_EXPERIENCE_MODEL,
} from "actions/preAssessedExperience/types";

const initialState = {
  selectedOption: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PRE_ASSESSED_EXPERIENCE_MODEL:
      return {
        ...state,
        selectedOption: payload.selectedOption,
      };

    case RESET_PRE_ASSESSED_EXPERIENCE_MODEL:
      return {
        ...state,
        selectedOption: null,
      };

    default:
      return state;
  }
};
