import COLORS from '../../../../../constants/colors';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

const CareNavigatorIcon = ({ svgProps = {}, color, paddingTopLeft, title, iconId }) => {
	const suffix = iconId ? `-${iconId}` : '';
	const titleId = title && `care-navigator-icon${suffix}`;

	return (
		<svg
			className={classnames({ [styles.addPaddingTopAndLeft]: paddingTopLeft || false })}
			aria-labelledby={titleId}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="presentation"
			alt=""
			{...svgProps}
		>
			{title && <title id={titleId}>{title}</title>}
			<path fill={color || COLORS.PRIMARY_500} clipRule="evenodd" d="M21.3334 7.99995C21.3334 10.4108 20.267 12.5725 18.5802 14.0392C22.0241 15.3611 24.6448 18.4245 25.2817 22.2463L26.6486 30.4473C26.7696 31.1736 26.2789 31.8606 25.5526 31.9816C24.8262 32.1027 24.1393 31.612 24.0182 30.8857L22.6514 22.6847C22.0084 18.8272 18.6709 15.9999 14.7603 15.9999H11.9066C7.99596 15.9999 4.65846 18.8272 4.01555 22.6847L2.64872 30.8857C2.52766 31.612 1.84069 32.1027 1.11434 31.9816C0.38798 31.8606 -0.10271 31.1736 0.0183494 30.4473L1.38518 22.2463C2.02215 18.4245 4.64281 15.3611 8.08672 14.0392C6.3999 12.5725 5.33351 10.4108 5.33351 7.99995C5.33351 3.5817 8.91521 0 13.3335 0C17.7517 0 21.3334 3.5817 21.3334 7.99995ZM13.3335 2.66665C10.388 2.66665 8.00016 5.05445 8.00016 7.99995C8.00016 10.9454 10.388 13.3332 13.3335 13.3332C16.279 13.3332 18.6668 10.9454 18.6668 7.99995C18.6668 5.05445 16.279 2.66665 13.3335 2.66665Z" />
			<path fill={color || COLORS.PRIMARY_500} d="M12.2489 20.6586C11.277 19.7803 9.70112 19.7803 8.72915 20.6586C7.75719 21.5369 7.75719 22.9609 8.72915 23.8392L13.3335 27.9998L17.9378 23.8392C18.9098 22.9609 18.9098 21.5369 17.9378 20.6586C16.9658 19.7803 15.39 19.7803 14.418 20.6586L13.3335 21.6386L12.2489 20.6586Z" />
		</svg>
	);
};

CareNavigatorIcon.propTypes = {
	color          : PropTypes.string,
	title          : PropTypes.string,
	iconId         : PropTypes.string,
	paddingTopLeft : PropTypes.bool,
	svgProps       : PropTypes.shape({
		width  : PropTypes.string,
		height : PropTypes.string,
		role   : PropTypes.string,
		alt    : PropTypes.string,
	}),
};

export default CareNavigatorIcon;
