import { useState } from "react";
import { useQuery } from "@apollo/client";
import { getTherapeuticAllianceSurvey } from "operations/queries/survey";
import pichu from "constants/pichu";

export const useTherapeuticAllianceSurvey = (appointmentId) => {
  const [refetchCalled, setRefetchCalled] = useState(false);
  const { data, refetch } = useQuery(getTherapeuticAllianceSurvey, {
    variables: { appointment_id: appointmentId },
    onError: () => {
      pichu.logout();
      if (!refetchCalled) {
        refetch();
        setRefetchCalled(true);
      }
    },
  });
  return { data };
};
