import { gql } from "@apollo/client";

const manageUserSubscription = gql`
  mutation ManageUserSubscription($input: ManageUserSubscriptionInput!) {
    manageUserSubscription(input: $input) {
      success
    }
  }
`;

export default manageUserSubscription;
