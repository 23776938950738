export const SmartPhone = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path fill="#068262" d="M12 21a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
      <path
        fill="#068262"
        fill-rule="evenodd"
        d="M7 0a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H7ZM6 3a1 1 0 0 1 1-1h2v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V2h2a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V3Z"
        clip-rule="evenodd"
      />
    </svg>
  );
};
