import { useEffect, useRef } from "react";

import {
  modalIds,
  getAppointmentMediumForTracking,
  getAppointmentKindForTracking,
  getModalNameForTracking,
} from "@spring/constants";
import { TRACK_EVENT } from "utils/mixpanel";

export const useModalTracking = (
  provider,
  kind,
  medium,
  telemetryProps,
  scheduleModalOpen,
  isLoading,
  showScheduleContent,
) => {
  const trackedLinkClicked = useRef(false);
  const trackPaymentModal = useRef(false);
  const trackScheduleModal = useRef(false);

  const trackLinkClicked = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      getModalNameForTracking(modalIds.careProviderScheduleModal),
      telemetryProps.headerText,
      {
        spring_doc_id: telemetryProps.springDocId,
        location: telemetryProps.location,
        appointment_type: getAppointmentKindForTracking(kind),
        appointment_medium: getAppointmentMediumForTracking(medium),
        provider_id: provider?.id,
      },
    );
  };

  useEffect(() => {
    if (
      !trackedLinkClicked.current &&
      scheduleModalOpen &&
      telemetryProps &&
      provider &&
      kind &&
      medium
    ) {
      trackLinkClicked();
      trackedLinkClicked.current = true;
    }
    if (!scheduleModalOpen) {
      trackPaymentModal.current = false;
      trackScheduleModal.current = false;
      trackedLinkClicked.current = false;
    }

    if (!isLoading && scheduleModalOpen && telemetryProps) {
      if (showScheduleContent && !trackScheduleModal.current) {
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          getModalNameForTracking(modalIds.careProviderScheduleModal),
          {
            spring_doc_id:
              "springDocId" in telemetryProps ? telemetryProps.springDocId : "",
            location: telemetryProps.location,
            appointment_type: getAppointmentKindForTracking(kind),
            appointment_medium: getAppointmentMediumForTracking(medium),
            provider_id: provider?.id,
          },
        );
        trackScheduleModal.current = true;
      }
      if (showScheduleContent === false && !trackPaymentModal.current) {
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          "Insurance Detail Prompt Modal",
          {
            location: telemetryProps?.mpLocation || telemetryProps.location,
            appointment_type: getAppointmentKindForTracking(kind),
          },
        );
        trackPaymentModal.current = true;
      }
    }
  }, [
    provider,
    kind,
    medium,
    telemetryProps,
    scheduleModalOpen,
    trackLinkClicked,
    isLoading,
    showScheduleContent,
  ]);
};
