//
/* eslint-disable react/display-name */

import PropTypes from "prop-types";
import React from "react";
import yup from "schemas/yup";
import {
  RadioButtons,
  Checkbox,
  Select,
  Input,
  Grid,
  Col,
  Underlined,
  Label,
  Section,
} from "@spring/smeargle";

const numberField = () => yup.number().required();

// Next 2 components are hacks to allow columns in prompts.  Prompts pass the
// `fieldKey` directly into the questions via the render method, so we need to
// grab that and pass it in to the field manually if they're getting wrapped
const AppetiteChange = (
  { formKey }, // eslint-disable-line
) => (
  <Grid gap="0 16px">
    <Col md={6}>
      <Section>
        <Label>Decreased Appetite</Label>
      </Section>
    </Col>
    <Col md={6}>
      <Section>
        <Label>Increased Appetite</Label>
      </Section>
    </Col>

    <Col size={12}>
      <RadioButtons
        border={true}
        formKey={formKey}
        fieldKey="SPRASS.sapchange"
        options={[
          { label: "There is no change from my usual appetite.", value: 0 },
        ]}
      />
    </Col>

    <Col md={6}>
      <RadioButtons
        border={true}
        formKey={formKey}
        fieldKey="SPRASS.sapchange"
        options={[
          {
            label:
              "I eat somewhat less often or lesser amounts of food than usual.",
            value: 1,
          },
          {
            label: "I eat much less than usual and only with personal effort.",
            value: 2,
          },
          {
            label:
              "I rarely eat within a 24-hour period, and only with extreme personal effort or when others persuade me to eat.",
            value: 3,
          },
        ]}
      />
    </Col>

    <Col md={6}>
      <RadioButtons
        border={true}
        formKey={formKey}
        fieldKey="SPRASS.sapchange"
        options={[
          {
            label: "I feel a need to eat more frequently than usual.",
            value: 5,
          },
          {
            label:
              "I regularly eat more often and/or greater amounts of food than usual.",
            value: 6,
          },
          {
            label:
              "I feel driven to overeat both at mealtime and between meals.",
            value: 7,
          },
        ]}
      />
    </Col>
  </Grid>
);

AppetiteChange.propTypes = {
  formKey: PropTypes.any,
};

const WeightChange = (
  { formKey }, // eslint-disable-line
) => (
  <Grid gap="0 16px">
    <Col md={6}>
      <Section>
        <Label>Decreased Appetite</Label>
      </Section>
    </Col>
    <Col md={6}>
      <Section>
        <Label>Increased Appetite</Label>
      </Section>
    </Col>

    <Col size={12}>
      <RadioButtons
        border={true}
        formKey={formKey}
        fieldKey="SPRASS.swtchange"
        options={[{ label: "I have not had a change in my weight.", value: 0 }]}
      />
    </Col>

    <Col md={6}>
      <RadioButtons
        border={true}
        formKey={formKey}
        fieldKey="SPRASS.swtchange"
        options={[
          { label: "I feel as if I have had a slight weight loss.", value: 1 },
          { label: "I have lost 2 pounds or more.", value: 2 },
          { label: "I have lost 5 pounds or more.", value: 3 },
        ]}
      />
    </Col>
    <Col md={6}>
      <RadioButtons
        border={true}
        formKey={formKey}
        fieldKey="SPRASS.swtchange"
        options={[
          { label: "I feel as if I have had a slight weight gain.", value: 5 },
          { label: "I have gained 2 pounds or more.", value: 6 },
          { label: "I have gained 5 pounds or more.", value: 7 },
        ]}
      />
    </Col>
  </Grid>
);

WeightChange.propTypes = {
  formKey: PropTypes.any,
};

// Checkbox schema
const prompt1Schema = {};
const prompt5Schema = {};
const prompt6Schema = {};

const promptSchoolSchema = {
  "SPRASS.school": numberField(),
};
const promptEmploymentSchema = {
  "SPRASS.employment": numberField(),
};
const promptRaceSchema = {
  "SPRASS.race": numberField(),
};
const promptEpinoSchema = {
  "SPRASS.epino": numberField().min(0).max(30),
};
const promptSsoinSchema = {
  "SPRASS.ssoin": numberField(),
};
const promptSmninSchema = {
  "SPRASS.smnin": numberField(),
};
const promptSeminSchema = {
  "SPRASS.semin": numberField(),
};
const promptShysmSchema = {
  "SPRASS.shysm": numberField(),
};
const promptSmdsdSchema = {
  "SPRASS.smdsd": numberField(),
};
const promptScntrSchema = {
  "SPRASS.scntr": numberField(),
};
const promptSvwsfSchema = {
  "SPRASS.svwsf": numberField(),
};
const promptSsuicSchema = {
  "SPRASS.ssuic": numberField(),
};
const promptSintrSchema = {
  "SPRASS.sintr": numberField(),
};
const promptSengySchema = {
  "SPRASS.sengy": numberField(),
};
const promptSslowSchema = {
  "SPRASS.sslow": numberField(),
};
const promptSagitSchema = {
  "SPRASS.sagit": numberField(),
};
const promptHeminSchema = {
  "SPRASS.hemin": numberField(),
};

const promptHinsgSchema = {
  "SPRASS.hinsg": numberField(),
};

const promptHsanxSchema = {
  "SPRASS.hsanx": numberField(),
};

const promptHsuicSchema = {
  "SPRASS.hsuic": numberField(),
};

const promptHengySchema = {
  "SPRASS.hengy": numberField(),
};

const promptHslowSchema = {
  "SPRASS.hslow": numberField(),
};

const promptSapchangeSchema = {
  "SPRASS.sapchange": numberField(),
};

const promptSwtchangeSchema = {
  "SPRASS.swtchange": numberField(),
};

export const SPRASSSchema = {
  ...prompt1Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...promptSchoolSchema,
  ...promptEmploymentSchema,
  ...promptRaceSchema,
  ...promptEpinoSchema,
  ...promptSsoinSchema,
  ...promptSmninSchema,
  ...promptSeminSchema,
  ...promptSmdsdSchema,
  ...promptScntrSchema,
  ...promptSvwsfSchema,
  ...promptSintrSchema,
  ...promptSengySchema,
  ...promptSslowSchema,
  ...promptSagitSchema,
  ...promptHeminSchema,
  ...promptHinsgSchema,
  ...promptHsanxSchema,
  ...promptHsuicSchema,
  ...promptHengySchema,
  ...promptHslowSchema,
  ...promptSapchangeSchema,
  ...promptSwtchangeSchema,
};

const SPRASS = [
  {
    id: "SPRASS--1",
    subtitle: "Please check all that apply. If none apply, click next.",
    schema: yup.object().shape(prompt1Schema),
    autoAdvance: false,
    questions: [
      <Checkbox
        key="ex1"
        fieldKey="SPRASS.exmedadm"
        label="I am currently taking antidepressants to treat my depression"
        border
      />,
      <Checkbox
        key="ex2"
        fieldKey="SPRASS.exhxbpd"
        label="I have a lifetime history of bipolar disorder, schizophrenia, schizoaffective disorder, or Major Depressive Disorder with psychotic features."
        border
      />,
      <Checkbox
        key="ex3"
        fieldKey="SPRASS.exhxocd"
        label="I currently suffer from anorexia nervosa, bulimia nervosa, or obsessive compulsive disorder (OCD)."
        border
      />,
      <Checkbox
        key="ex4"
        fieldKey="SPRASS.expreg"
        label="I am breastfeeding, pregnant, or trying to become pregnant within the next 6-9 months."
        border
      />,
      <Checkbox
        key="ex5"
        fieldKey="SPRASS.exepilepsy"
        label="I have been diagnosed with epilepsy in the past"
        border
      />,
      <Checkbox
        key="ex6"
        fieldKey="SPRASS.exdetox"
        label="I am in detox or inpatient hospital treatment for substance dependence"
        border
      />,
      <Checkbox
        key="ex7"
        fieldKey="SPRASS.exmedstab"
        label="I am currently taking mood stabilising medication(e.g. Lithium, anti-convulsants, or anti-psychotics)."
        border
      />,
    ],
  },
  {
    id: "SPRASS--2",
    title:
      "What is the highest level **of education** that you have **completed**?",
    schema: yup.object().shape(promptSchoolSchema),
    autoAdvance: false,
    questions: [
      <Select
        key={2}
        fieldKey="SPRASS.school"
        placeholder="Select One"
        options={[
          { label: "Elementary School - 1st grade", value: 1 },
          { label: "Elementary School - 2nd grade", value: 2 },
          { label: "Elementary School - 3rd grade", value: 3 },
          { label: "Elementary School - 4th grade", value: 4 },
          { label: "Elementary School - 5th grade", value: 5 },
          { label: "Middle School - 6th grade", value: 6 },
          { label: "Middle School - 7th grade", value: 7 },
          { label: "Middle School - 7th grade", value: 8 },
          { label: "High School - 9th grade", value: 9 },
          { label: "High School - 10th grade", value: 10 },
          { label: "High School - 11th grade", value: 11 },
          { label: "High School - 12th grade", value: 12 },
          { label: "College (Associates/Bachelors) - 1st year", value: 13 },
          { label: "College (Associates/Bachelors) - 2nd year", value: 14 },
          { label: "College (Associates/Bachelors) - 3rd year", value: 15 },
          { label: "College (Associates/Bachelors) - 4th year", value: 16 },
          { label: "Graduate school - 1st year", value: 17 },
          { label: "Graduate school - 2nd year", value: 18 },
          { label: "Graduate school - 3rd year", value: 19 },
          { label: "Graduate school - 4th year", value: 20 },
          { label: "Graduate school - 5th year", value: 21 },
          { label: "Graduate school - 6th year", value: 22 },
          { label: "Graduate school - 7th year", value: 23 },
          { label: "Graduate school - 8th year", value: 24 },
          { label: "Graduate school - 9th year", value: 25 },
          { label: "Graduate school - 10th year", value: 26 },
          { label: "Graduate school - More than 10 years", value: 27 },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--3",
    title: "What best describes your current employment status?",
    schema: yup.object().shape(promptEmploymentSchema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="SPRASS.employment"
        options={[
          { label: "Unemployed, not looking for employment", value: 1 },
          { label: "Unemployed,looking for employment", value: 2 },
          { label: "Full-time employed for pay", value: 3 },
          { label: "Part-time employed for pay", value: 4 },
          { label: "Self-employed for pay", value: 5 },
          { label: "Retired, not working", value: 6 },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--4",
    title:
      "What is your race? Please choose one race with which you primarily identify.",
    schema: yup.object().shape(promptRaceSchema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="SPRASS.race"
        options={[
          { label: "Asian", value: 0 },
          { label: "Black/African-American", value: 1 },
          { label: "White", value: 2 },
          { label: "American Indian or Alaskan Native", value: 3 },
          { label: "Native Hawaiian or Other Pacific Islander", value: 4 },
          { label: "Hispanic", value: 5 },
          { label: "Unknown", value: 6 },
          { label: "Other", value: 7 },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--5",
    title:
      "Did any of the following **make you feel** fearful, anxious, **or** nervous because you were afraid you'd have an anxiety attack in the situation?",
    subtitle: "Please check all that apply. If none apply, click next.",
    autoAdvance: false,
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <Checkbox
        key="t1"
        fieldKey="SPRASS.teshk"
        label="Reminders of a traumatic event made me shake, break out into a sweat, or have a racing heart."
        border
      />,
      <Checkbox
        key="t2"
        fieldKey="SPRASS.trwit"
        label="I have witnessed a traumatic event such as rape, assault, someone dying in an accident, or any other extremely upsetting event."
        border
      />,
      <Checkbox
        key="t3"
        fieldKey="SPRASS.termd"
        label="I have tried to avoid activities, places, or people that reminded me of a traumatic event."
        border
      />,
    ],
  },
  {
    id: "SPRASS--6",
    subtitle: "Please check all that apply. If none apply, click next.",
    autoAdvance: false,
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <Checkbox
        key={"phach"}
        fieldKey="SPRASS.phach"
        label="I have been bothered by aches and pains in many different parts of my body."
        border
      />,
      <Checkbox
        key={"dsmdm"}
        fieldKey="SPRASS.dsmdm"
        label="I experience a depressed mood most of the day, nearly every day."
        border
      />,
      <Checkbox
        key="emqst"
        fieldKey="SPRASS.emqst"
        label="I worry a lot about doing or saying something to embarrass myself when asking a question in a group of people."
        border
      />,
    ],
  },
  {
    id: "SPRASS--7",
    title:
      "How many other times in your life have you been clinically depressed?",
    schema: yup.object().shape(promptEpinoSchema),
    autoAdvance: false,
    questions: [
      <Input
        key={7}
        fieldKey="SPRASS.epino"
        type="number"
        min={0}
        max={30}
        placeholder="Enter a number (0-30)"
      />,
    ],
  },
  {
    id: "SPRASS--8",
    title: "Falling Asleep",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSsoinSchema),
    questions: [
      <RadioButtons
        key={8}
        border={true}
        fieldKey="SPRASS.ssoin"
        options={[
          {
            label: "I never take longer than 30 minutes to fall asleep.",
            value: 0,
          },
          {
            label:
              "I take at least 30 minutes to fall asleep, less than half the time.",
            value: 1,
          },
          {
            label:
              "I take at least 30 minutes to fall asleep, more than half the time.",
            value: 2,
          },
          {
            label:
              "I take more than 60 minutes to fall asleep, more than half the time.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--9",
    title: "Sleep During the Night",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSmninSchema),
    questions: [
      <RadioButtons
        key={9}
        border={true}
        fieldKey="SPRASS.smnin"
        options={[
          { label: "I do not wake up at night.", value: 0 },
          {
            label:
              "I have a restless, light sleep with a few brief awakenings each night.",
            value: 1,
          },
          {
            label:
              "I wake up at least once a night, but I go back to sleep easily.",
            value: 2,
          },
          {
            label:
              "I awaken more than once a night and stay awake for 20 minutes or more, more than half the time.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--10",
    title: "Waking Up Too Early",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSeminSchema),
    questions: [
      <RadioButtons
        key={10}
        border={true}
        fieldKey="SPRASS.semin"
        options={[
          {
            label:
              "Most of the time, I awaken no more than 30 minutes before I need to get up.",
            value: 0,
          },
          {
            label:
              "More than half the time, I awaken more than 30 minutes before I need to get up.",
            value: 1,
          },
          {
            label:
              "I almost always awaken at least one hour or so before I need to, but I go back to sleep eventually.",
            value: 2,
          },
          {
            label:
              "I awaken at least one hour before I need to, and can't go back to sleep.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--11",
    title: "Sleeping Too Much",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptShysmSchema),
    questions: [
      <RadioButtons
        key={11}
        border={true}
        fieldKey="SPRASS.shysm"
        options={[
          {
            label:
              "I sleep no longer than 7-8 hours/night, without napping during the day.",
            value: 0,
          },
          {
            label:
              "I sleep no longer than 10 hours in a 24-hour period including naps.",
            value: 1,
          },
          {
            label:
              "I sleep no longer than 12 hours in a 24-hour period including naps.",
            value: 2,
          },
          {
            label:
              "I sleep longer than 12 hours in a 24-hour period including naps.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--12",
    title: "Feeling Sad",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSmdsdSchema),
    questions: [
      <RadioButtons
        key={12}
        border={true}
        fieldKey="SPRASS.smdsd"
        options={[
          { label: "I do not feel sad.", value: 0 },
          { label: "I feel sad less than half the time.", value: 1 },
          { label: "I feel sad more than half the time.", value: 2 },
          { label: "I feel sad nearly all of the time.", value: 3 },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--13",
    title: "Concentration and Decision Making",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptScntrSchema),
    questions: [
      <RadioButtons
        key={13}
        border={true}
        fieldKey="SPRASS.scntr"
        options={[
          {
            label:
              "There is no change in my usual capacity to concentrate or make decisions.",
            value: 0,
          },
          {
            label:
              "I occasionally feel indecisive or find that my attention wanders.",
            value: 1,
          },
          {
            label:
              "Most of the time, I struggle to focus my attention or to make decisions.",
            value: 2,
          },
          {
            label:
              "I cannot concentrate well enough to read or cannot make even minor decisions.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--14",
    title: "View of Myself",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSvwsfSchema),
    questions: [
      <RadioButtons
        key={14}
        border={true}
        fieldKey="SPRASS.svwsf"
        options={[
          {
            label:
              "I see myself as equally worthwhile and deserving as other people.",
            value: 0,
          },
          { label: "I am more self-blaming than usual.", value: 1 },
          {
            label: "I largely believe that I cause problems for others.",
            value: 2,
          },
          {
            label:
              "I think almost constantly about major and minor defects in myself.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--15",
    title: "Thoughts About Suicide or Death",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSsuicSchema),
    questions: [
      <RadioButtons
        key={15}
        border={true}
        fieldKey="SPRASS.ssuic"
        options={[
          { label: "I do not think of suicide or death.", value: 0 },
          {
            label: "I feel that life is empty or wonder if it's worth living.",
            value: 1,
          },
          {
            label:
              "I think of suicide or death several times a week for several minutes.",
            value: 2,
          },
          {
            label:
              "I think of suicide or death several times a day in some detail, or I have made specific plans for suicide or have actually tried to take my life.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--16",
    title: "General Interest",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSintrSchema),
    questions: [
      <RadioButtons
        key={16}
        border={true}
        fieldKey="SPRASS.sintr"
        options={[
          {
            label:
              "There is no change from usual in how interested I am in other people or activities.",
            value: 0,
          },
          {
            label:
              "I notice that I am less interested in people or activities.",
            value: 1,
          },
          {
            label:
              "I find I have interest in only one or two of my formerly pursued activities.",
            value: 2,
          },
          {
            label:
              "I have virtually no interest in formerly pursued activities.",
            value: 3,
          },
        ]}
      />,
    ],
  },

  {
    id: "SPRASS--17",
    title: "Energy Levels",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSengySchema),
    questions: [
      <RadioButtons
        key={17}
        border={true}
        fieldKey="SPRASS.sengy"
        options={[
          {
            label: "There is no change in my usual level of energy.",
            value: 0,
          },
          { label: "I get tired more easily than usual.", value: 1 },
          {
            label:
              "I have to make a big effort to start or finish my usual daily activities (for example, shopping, homework, cooking, or going to work).",
            value: 2,
          },
          {
            label:
              "I really cannot carry out most of my usual daily activities because I just don't have the energy.",
            value: 3,
          },
        ]}
      />,
    ],
  },

  {
    id: "SPRASS--18",
    title: "Feeling Slow",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSslowSchema),
    questions: [
      <RadioButtons
        key={18}
        border={true}
        fieldKey="SPRASS.sslow"
        options={[
          {
            label: "I think, speak, and move at my usual rate of speed.",
            value: 0,
          },
          {
            label:
              "I find that my thinking is slowed down or my voice sounds dull or flat.",
            value: 1,
          },
          {
            label:
              "It takes me several seconds to respond to most questions and I'm sure my thinking is slowed.",
            value: 2,
          },
          {
            label:
              "I am often unable to respond to questions without extreme effort.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--19",
    title: "Restlessness",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSagitSchema),
    questions: [
      <RadioButtons
        key={19}
        border={true}
        fieldKey="SPRASS.sagit"
        options={[
          { label: "I do not feel restless.", value: 0 },
          {
            label:
              "I'm often fidgety, wringing my hands, or need to shift how I am sitting.",
            value: 1,
          },
          {
            label: "I have impulses to move about and am quite restless.",
            value: 2,
          },
          {
            label:
              "At times, I am unable to stay seated and need to pace around.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--20",
    title: "Waking Up Too Early (after 4:00 am)",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptHeminSchema),
    questions: [
      <RadioButtons
        key={20}
        border={true}
        fieldKey="SPRASS.hemin"
        options={[
          { label: "Absent (No difficulty)", value: 0 },
          {
            label:
              "Mild (e.g. I wake up earlier than usual but can go back to sleep)",
            value: 1,
          },
          {
            label:
              "Moderate to severe (e.g. I wake up 1-3 hours before usual, and I am unable to sleep again)",
            value: 2,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--21",
    title: "Feeling Ill",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptHinsgSchema),
    questions: [
      <RadioButtons
        key={21}
        border={true}
        fieldKey="SPRASS.hinsg"
        options={[
          {
            label: "I feel that I'm depressed and ill.",
            value: 0,
          },
          {
            label:
              "I feel that I'm ill, but I don't think it's due to depression. I think it's due to other factors.",
            value: 1,
          },
          {
            label: "I don't feel that I'm ill.",
            value: 2,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--22",
    title: "Symptoms Of Anxiety",
    subtitle:
      "I experience symptoms of anxiety such as fainting, headache, tremor, sweating, hyperventilation, indigestion, or needing the bathroom a lot...",
    schema: yup.object().shape(promptHsanxSchema),
    questions: [
      <RadioButtons
        key={22}
        border={true}
        fieldKey="SPRASS.hsanx"
        options={[
          { label: "Not at all", value: 0 },
          { label: "Mildly", value: 1 },
          { label: "Moderately", value: 2 },
          { label: "Moderately severely", value: 3 },
          { label: "Severely", value: 4 },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--23",
    title: "Thoughts About Suicide or Death",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptHsuicSchema),
    questions: [
      <RadioButtons
        key={23}
        border={true}
        fieldKey="SPRASS.hsuic"
        options={[
          { label: "I feel that life is empty and not worth living", value: 0 },
          {
            label: "I experience recurrent thoughts or wishes about dying.",
            value: 1,
          },
          {
            label: "I have active suicidal thoughts, threats, and gestures.",
            value: 2,
          },
          { label: "I have had a serious suicide attempt.", value: 3 },
          {
            label:
              "I feel none of the above. I do not think at all about suicide.",
            value: 4,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--24",
    title: "Fatigue and Aching",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptHengySchema),
    questions: [
      <RadioButtons
        key={24}
        border={true}
        fieldKey="SPRASS.hengy"
        options={[
          {
            label:
              "I experience occasional, mild fatigue, easy tiring, and aching.",
            value: 0,
          },
          {
            label:
              "I experience moderate to severe fatigue (obviously low in energy, tired all the time, frequent backaches, headaches, heavy feelings in limbs)",
            value: 1,
          },
          { label: "I experience none of the above.", value: 2 },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--25",
    title: "Delays (or Slowing) in Thinking, Speaking, or Moving",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptHslowSchema),
    questions: [
      <RadioButtons
        key={25}
        border={true}
        fieldKey="SPRASS.hslow"
        options={[
          { label: "Absent (No difficulty).", value: 0 },
          { label: "Mild (slightly flattened affect).", value: 1 },
          {
            label:
              "Moderate (monotonous voice, delayed answering, sitting motionless).",
            value: 2,
          },
          {
            label:
              "Moderately severe (conversations are difficult and prolonged).",
            value: 3,
          },
          {
            label: "Extremely severe (conversations are impossible).",
            value: 4,
          },
        ]}
      />,
    ],
  },
  {
    id: "SPRASS--26",
    title: "Within the last **two weeks** have you had:",
    schema: yup.object().shape(promptSapchangeSchema),
    autoAdvance: false,
    questions: [
      <AppetiteChange key="SPRASS.sapchange" fieldKey="SPRASS.sapchange" />,
    ],
  },
  {
    id: "SPRASS--27",
    title: "Within the last **two weeks** have you had:",
    schema: yup.object().shape(promptSwtchangeSchema),
    autoAdvance: false,
    questions: [
      <WeightChange key="SPRASS.swtchange" fieldKey="SPRASS.swtchange" />,
    ],
  },
  {
    id: "SPRASS--complete",
    title: "You're done!",
    subtitle: (
      <>
        We will now analyze your answers to determine what medication can be
        most helpful <Underlined>to you</Underlined>, and send our
        recommendation to your care team.
      </>
    ),
  },
];

export default SPRASS;
