import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import {
  Box,
  Text,
  HStack,
  Heading,
  VStack,
  Spinner,
  Flex,
  Link,
  Hide,
  Show,
} from "design-system/components";
import { Benefits } from "design-system/assets";
import NextLink from "next/link";
import { DateTime } from "luxon";
import getMemberVisits from "operations/queries/member/getMemberVisits";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";

const getBenefitSnapshotData = (memberVisitsData) => {
  const visits_eligibility_limit_date =
    memberVisitsData?.user?.member?.visits_eligibility_limit_date;
  const specialist_visits_remaining =
    memberVisitsData?.user?.member?.visits_remaining_specialist;
  const total_visits_covered =
    memberVisitsData?.user?.member?.visits_covered_total;
  const total_remaining_visits =
    memberVisitsData?.user?.member?.visits_remaining_total;

  if (total_remaining_visits > 0) {
    return {
      total_visits_covered: total_visits_covered,
      specialist_visits_remaining: specialist_visits_remaining,
      end_date: DateTime.fromISO(visits_eligibility_limit_date).toFormat("DDD"),
      total_remaining_visits: total_remaining_visits,
    };
  }
};

const BenefitSummarySnapshot = () => {
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  const { data: memberVisitsData, loading: memberVisitsDataLoading } = useQuery(
    getMemberVisits,
    { ...apolloOptionsUserId, fetchPolicy: "cache-and-network" },
  );

  const { t } = useTranslation("benefitSummarySnapshot");

  const sessionsData = getBenefitSnapshotData(memberVisitsData);

  return (
    <>
      <Box>
        <VStack spacing={0}>
          <Box w="100%" bg="tertiary.50" borderRadius="12px 12px 0 0" p={20}>
            {memberVisitsDataLoading ? (
              <Spinner speed="1s" size="xl" />
            ) : (
              <>
                <HStack>
                  <Box h={32} borderRadius="50%" bg="platform.25" p={4}>
                    <Benefits color="platform.900" boxSize={24} />
                  </Box>
                  <Hide below="480px">
                    <Heading variant="sm_v1" color="platform.900">
                      {t("therapyMedicationHeader")}
                    </Heading>
                  </Hide>
                  <Show below="480px">
                    <Text variant="bodyBold_v1" color="platform.900">
                      {t("therapyMedicationHeader")}
                    </Text>
                  </Show>
                </HStack>
                {sessionsData ? (
                  <>
                    <Text
                      variant="body_v1"
                      color="platform.900"
                      marginBottom={8}
                      marginTop={10}
                    >
                      {t("benefitSummarySnapshotData.totalSessionsText", {
                        total_remaining_visits:
                          sessionsData.total_remaining_visits,
                      })}
                    </Text>
                    <Text
                      variant="body_v1"
                      color="platform.900"
                      marginBottom={8}
                    >
                      {t("benefitSummarySnapshotData.useText")}
                    </Text>
                    <Text
                      variant="body_v1"
                      color="platform.900"
                      marginBottom={8}
                    >
                      {t("benefitSummarySnapshotData.specialistSessionsText", {
                        specialist_visits_remaining:
                          sessionsData.specialist_visits_remaining,
                      })}
                    </Text>
                    <Text
                      variant="body_v1"
                      color="platform.900"
                      marginBottom={4}
                    >
                      {t("benefitSummarySnapshotData.contractEndDateText", {
                        end_date: sessionsData.end_date,
                      })}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      variant="body_v1"
                      color="platform.900"
                      marginBottom={8}
                      marginTop={10}
                    >
                      {t("benefitSummarySnapshotData.noSessionRemainingText1")}
                    </Text>
                    <Text
                      variant="body_v1"
                      color="platform.900"
                      marginBottom={24}
                    >
                      {t("benefitSummarySnapshotData.noSessionRemainingText2")}
                    </Text>
                  </>
                )}
              </>
            )}
          </Box>
          <Flex
            alignItems="center"
            justifyContent={"left"}
            w="100%"
            h="61px"
            border="2px solid"
            borderColor="tertiary.50"
          >
            <NextLink href="/members/benefits" passHref>
              <Link
                variant="primary"
                fontWeight={600}
                p={20}
                onClick={() => {
                  TRACK_EVENT.LINK_CLICKED(
                    window.location.pathname,
                    routes.MemberBenefits.as,
                    "Review Full Benefits",
                    {
                      spring_doc_id: "HPR_019",
                      location: "Benefit Summary",
                    },
                  );
                }}
              >
                {t("links.reviewBenefits")}
              </Link>
            </NextLink>
          </Flex>
          <Flex
            justifyContent={"left"}
            w="100%"
            h="61px"
            border="2px solid"
            borderColor="tertiary.50"
            borderTop={0}
            borderRadius="0 0 12px 12px"
          >
            <NextLink
              href={{
                pathname: "/members/settings",
                query: {
                  updateInsuranceForm: true,
                },
              }}
              passHref
            >
              <Link
                variant="primary"
                fontWeight={600}
                px={20}
                py={18}
                onClick={() => {
                  TRACK_EVENT.LINK_CLICKED(
                    window.location.pathname,
                    `${routes.MemberSettings.as}?updateInsuranceForm`,
                    "Update Insurance",
                    {
                      spring_doc_id: "HPR_020",
                      location: "Benefit Summary",
                    },
                  );
                }}
              >
                {t("links.updateInsuranceInfo")}
              </Link>
            </NextLink>
          </Flex>
        </VStack>
      </Box>
    </>
  );
};

export default BenefitSummarySnapshot;
