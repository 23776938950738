import {
  loadZendesk,
  zendeskBehavior,
  zendeskIdentify,
  openChat,
  openSupport,
  determineWidgetType,
} from "utils/zendeskHelpers";

export const openZendeskChat = async () => {
  try {
    // 1. Load script
    await loadZendesk();
    // 2. load additional script that defines default behavior
    await zendeskBehavior();
    // 3. Call zendeskIdentify()
    await zendeskIdentify();
    // 4. call openChat()
    openChat();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[Zendesk error] - ", e);
    openSupport();
  }
};

export function zendeskLogout() {
  try {
    if (typeof window !== "undefined") {
      if (!window.zE) return;
      const widgetType = determineWidgetType();
      if (widgetType === "messenger") {
        window.zE("messenger", "logoutUser");
      } else if (widgetType === "classic") {
        window.zE("webWidget", "logout");
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("[ZENDESK] - error in zendeskLogout", e);
  }
}
