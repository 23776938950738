import { Button } from "@springcare/sh-component-library";
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import {
  Career,
  CarePlan,
  RelationshipSpring,
  SelfEsteem,
  Sleep,
  Time,
} from "design-system/assets";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TRACK_EVENT } from "utils/mixpanel";
import styles from "./styles.module.scss";

export const PopoverComponent = ({ children }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleFocus = () => {
    setIsPopoverOpen(true);
  };
  const { t } = useTranslation(["carePlan", "homepage"]);
  const [trackedOnce, setTrackedOnce] = useState(0);

  const handleHover = () => {
    setIsPopoverOpen(!isPopoverOpen);
    if (trackedOnce === 0) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "coach assignment popover",
        {
          location: "Up Next section",
        },
      );
      setTrackedOnce(1);
    }
  };

  const CoachAssignmentItem = ({ iconComponent, titleKey }) => (
    <Box width={{ base: "100%", md: "50%" }} display="flex" flexDirection="row">
      {iconComponent}
      <Box
        ms="12"
        fontSize="12"
        color="content-primary"
        fontWeight="400"
        alignSelf={"center"}
      >
        {t(`homepage:upNextSection.coachAssignmentPopover.${titleKey}`)}
      </Box>
    </Box>
  );

  const CoachAssignmentSection = ({
    leftIcon,
    leftTitle,
    rightIcon,
    rightTitle,
  }) => (
    <Box
      display="flex"
      gap={{ base: "8", md: "0px" }}
      alignItems="center"
      flexDirection={{ base: "column", md: "row" }}
      className={styles.coachAssignmentContainer}
    >
      <CoachAssignmentItem iconComponent={leftIcon} titleKey={leftTitle} />
      <CoachAssignmentItem iconComponent={rightIcon} titleKey={rightTitle} />
    </Box>
  );

  return (
    <Box zIndex={995} position="relative" fontStyle="HK Grotesk">
      <Popover
        returnFocusOnClose={false}
        placement="auto"
        trigger="hover"
        isOpen={isPopoverOpen}
        onClose={() => {
          setIsPopoverOpen(false);
        }}
      >
        <PopoverTrigger>
          <Button
            as="h2"
            variant="upNextVariant"
            textDecoration="underline dotted #068262"
            display="inline-flex"
            p={0}
            mt={-4}
            ms={-4}
            bg={"#F8F6F2"}
            borderRadius={0}
            _hover={{
              color: "platform.900",
              bg: "#E7E6E2",
              p: 0,
              mt: -4,
              ms: -4,
            }}
            _focus={{
              color: "platform.900",
              bg: "#E7E6E2",
              textDecoration: "none",
              p: 0,
              mt: -4,
              ms: -4,
            }}
            fontFamily={"HKGroteskBold"}
            fontSize={{ sm: "21px", md: "29px", lg: "29px" }}
            fontWeight={{ sm: 600, md: 600, lg: 700 }}
            color="platform.900"
            onMouseOver={handleHover}
            onFocus={handleFocus}
            tabIndex="0"
          >
            {children}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          height="auto"
          width={{ base: "358px", md: "410px" }}
          borderRadius={"6px"}
          border="none"
          boxShadow={
            "0px 0px 4px 0px rgba(72, 45, 40, 0.04),0px 8px 16px -6px rgba(72, 45, 40, 0.12)"
          }
        >
          <PopoverHeader
            fontSize="21px"
            fontWeight="600"
            mt="24"
            ms="24"
            me="24"
            mb="16"
            p="0"
            borderBottom={0}
          >
            {t("homepage:upNextSection.coachAssignmentPopover.title")}
          </PopoverHeader>
          <PopoverBody ms="24" me="24" mb="24" p="0">
            <Box
              fontSize={12}
              color="content-secondary"
              mb="8"
              fontWeight={400}
            >
              {t("homepage:upNextSection.coachAssignmentPopover.subtitle")}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={{ base: "12", md: "16" }}
            >
              <CoachAssignmentSection
                leftIcon={<Career />}
                leftTitle="popular1"
                rightIcon={<SelfEsteem />}
                rightTitle="popular2"
              />
              <CoachAssignmentSection
                leftIcon={<CarePlan />}
                leftTitle="popular3"
                rightIcon={<Time />}
                rightTitle="popular4"
              />
              <CoachAssignmentSection
                leftIcon={<RelationshipSpring />}
                leftTitle="popular5"
                rightIcon={<Sleep />}
                rightTitle="popular6"
              />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
