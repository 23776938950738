// 

import React, { PureComponent } from 'react';
import classnames from 'classnames';

import Icon from '../Icon';
import Small from '../../typography/Small';
import MomentsCarePlanIcon from '../Assets/svgs/MomentsCarePlanIcon';
import TherapistIcon from '../Assets/svgs/TherapistIcon';
import CareNavigatorIcon from '../Assets/svgs/CareNavigatorIcon';
import { clickableDivProps } from '../../../lib/accessibility_helpers';

import styles from './styles.module.scss';




class TimelineStep extends PureComponent {
	static defaultProps = {
		completed : false,
		ctaPath   : '',
		steps     : [],
	};

	/**
	 * Internal handleClick that calls onClick from props,
	 * which passes step index to parent to set the activestep.
	 */
	handleClick = () => {
		if (!this.props.completed) {
			this.props.onClick(this.props.index);
		}
	}

	get timelineIcon() {
		let iconType = '';
		if (this.props.type) {
			iconType = this.props.type;
		} else if (this.props.completed) {
			iconType = 'check';
		} else if (this.props.ctaPath) {
			if (this.props.ctaPath.includes('Therapy')) {
				return (
					<TherapistIcon 
						paddingTop={true}
						svgProps={{ role: 'img', width: '18', height: '24', alt: undefined }}
						title={this.timelineLabel}
						iconId={`${this.props.index || 0}`}
					/>
				);
			} else if (this.props.ctaPath.includes('Medication')) {
				iconType = 'hospital';
			} else if (this.props.ctaPath.includes('CareNavigation')) {
				return (
					<CareNavigatorIcon
						paddingTopLeft={true}
						svgProps={{ role: 'img', width: '18', height: '24', alt: undefined }}
						title={this.timelineLabel}
						iconId={`${this.props.index || 0}`}
					/>
				);
			} else if (this.props.ctaPath.includes('SPRASS')) {
				iconType = 'pill';
			} else if (this.props.ctaPath.includes('Assessment')) {
				iconType = 'check';
			} else if (this.props.ctaPath.includes('Previsit')) {
				iconType = 'clipboard-account';
			} else if (
				this.props.ctaPath.includes('Onward') ||
				this.props.ctaPath.includes('OffboardingPreferences')
			) {
				iconType = 'arrow-top-right';
			} else if (this.props.ctaPath.includes('Moments')) {
				return (
					<MomentsCarePlanIcon
						svgProps={{ role: 'img', alt: undefined }}
						title={this.timelineLabel}
						iconId={`${this.props.index || 0}`}
					/>
				);
			}
		}

		const iconProps = {
			'aria-label': iconType === 'check' ? this.props.checkIconAriaLabel : this.timelineLabel,
		};

		return <Icon type={iconType} iconProps={iconProps} />;
	}

	get timelineLabel() {
		if (this.props.label) {
			return this.props.label;
		} else if (this.props.timelineName) {
			return this.props.timelineName;
		}

		return '';
	}

	get disabled() {
		if (this.props.ctaPath.includes('Moments')) {
			return false;
		}
		const steps = this.props.steps;

		if (this.props.disabled) {
			return true;
		}

		if (this.props.ctaPath.includes('Onward')) {
			let totalCompleted = steps.length - 1;

			steps.forEach((step) => {
				if (step.completed) {
					totalCompleted--;
				}
			});

			// disable last onward step if all of previous steps haven't been completed yet
			if (totalCompleted > 0) {
				return true;
			}

			return false;
		}

		if (!this.props.completed) {
			// make all first unique incompleted steps active
			// regardless of where it is in timeline
			let firstActiveIndex;

			for (let i = 0; i < steps.length; i++) {
				if (this.props.ctaPath === steps[i].cta_path &&
					!steps[i].completed
				) {
					// set index of first unique step
					firstActiveIndex = i;
					break;
				}
			}

			// if the current step is not the first unique one, disable it
			if (firstActiveIndex && this.props.index !== firstActiveIndex) {
				return true;
			}

			return false;
		}

		return false;
	}

	render() {
		return (
			<div className={styles.timelineStep}>
				<div
					className={classnames(styles.step, {
						[styles.active]    : this.props.active,
						[styles.completed] : this.props.completed,
						[styles.depth]     : !this.disabled,
					})}
					onClick={this.handleClick}
					{...clickableDivProps({ 
						onClick  : this.handleClick, 
						disabled : this.disabled || this.props.completed, 
						pressed  : this.props.active,
					})}	
				>
					{this.timelineIcon}
				</div>
				<div className={classnames(styles.line, { [styles.notCompleted]: !this.props.completed })} />
				<div
					className={classnames(styles.textWrapper, {
						[styles.boldActive]   : this.props.active,
						[styles.disabledText] : this.disabled,
					})}
				>
					<Small inheritColor bold>{this.timelineLabel}</Small>
				</div>
				<div className={classnames({ [styles.disabled]: this.disabled })} />
			</div>
		);
	}
}

export default TimelineStep;
