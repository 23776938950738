import React from "react";
import styles from "./styles.module.scss";
import { CelebrateIcon } from "components/atoms";
import { useTranslation } from "hooks/react-i18next";

const AppointmentNoCostBadge = () => {
  const { t } = useTranslation("careProvider");

  return (
    <>
      <div className={styles.inPersonCare}>
        <div>
          <CelebrateIcon ariaHidden fillColor="#182023" />
          <span className={styles.inPersonText}>
            {t("scheduleModal.appointmentCost.free_alt")}
          </span>
        </div>
      </div>
    </>
  );
};

export default AppointmentNoCostBadge;
