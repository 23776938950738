import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const startTeenAssessment = gql`
  mutation startTeenAssessment($input: StartAssessmentInput!) {
    startAssessment(input: $input) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default startTeenAssessment;
