import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import Router, { useRouter } from "next/router";
import routes from "routes";
import { loginlessUpdateUnattendanceReason } from "operations/mutations/appointment/";
import { TRACK_EVENT } from "utils/mixpanel";
import { VStack } from "@chakra-ui/react";
import { AcknowledgeLateCancelContent } from "components/templates/NoShowReschedulePage/AcknowledgeLateCancelContent";
import { useNoShowPolicyConfig } from "components/templates/CareVisitDetails/components/ConfirmationCopy/hooks";
import { NoShowHeader } from "./NoShowHeader";

export const NoShowReschedulePage = () => {
  const [updateUnattendanceReason] = useMutation(
    loginlessUpdateUnattendanceReason,
  );
  const {
    config: { isLoggedIn, memberEligibleForAutoCancel },
  } = useNoShowPolicyConfig();
  const routerImp = useRouter();
  const {
    id: apptId,
    member_id: memberId,
    unattendance_reason: reason,
    autocancel_eligible: autocancelEligible,
  } = routerImp.query;
  // Only use autocancel_eligible param for unauthenticated view
  const showAutoCancelPolicy = isLoggedIn
    ? memberEligibleForAutoCancel
    : autocancelEligible === "true";

  useEffect(() => {
    if (apptId?.length && memberId?.length && reason?.length) {
      updateUnattendanceReason({
        variables: {
          input: {
            id: apptId,
            member_id: memberId,
            unattendance_reason: reason,
          },
        },
      }).catch((_err) => {
        // TODO: add datadog error handling
      });
    }
    TRACK_EVENT.COMPONENT_VIEWED(
      window.location.pathname,
      "No Show Reschedule Page",
      { source: "email" },
    );
  }, [apptId, memberId, reason]);

  return (
    <VStack
      maxW={600}
      spacing={16}
      mx={36}
      justifyContent="center"
      alignItems="flex-start"
      flex="1"
    >
      <NoShowHeader showAutoCancelPolicy={showAutoCancelPolicy} />

      <AcknowledgeLateCancelContent
        memberId={memberId}
        trackingType="Acknowledge policy button"
        trackingProps={{
          location: "No Show Reschedule Page",
          to: "Browse Care Provider Page",
        }}
        onComplete={() => {
          localStorage.setItem("initialTarget", routes.MemberCareVisits.as);
          Router.push(routes.MemberCareVisits.as);
        }}
      />
    </VStack>
  );
};
