import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  SimpleGrid,
  Box,
  SHHeader,
  useMediaQuery,
} from "design-system/components";
import PromoDesktop from "components/templates/RegisterPage/components/PromoDesktop";

const ChildLayout = (props) => {
  const [isMobile] = useMediaQuery(
    "(max-width: 767px) and (orientation: portrait",
  );

  return (
    <Box as="main" p={0} maxW="100vw">
      <SHHeader
        sliderDismissed
        isLoggedIn
        country={props.country || "US"}
        displayBackBtn={() => true}
        doGoBack={props.doGoBack}
        currentView={props.currentView}
        setCurrentView={props.setCurrentView}
        page="family_signup"
      />
      <SimpleGrid columns={[1, 1, 2, 2]} overflowY="hidden">
        {/* LEFT-PANE VIEW - DESKTOP ONLY */}
        {!isMobile && (
          <Box
            as="section"
            bg="tertiary.25"
            minH="calc(100vh - 95px)"
            overflowY="scroll"
          >
            <PromoDesktop />
          </Box>
        )}
        {props.children}
      </SimpleGrid>
    </Box>
  );
};

ChildLayout.propTypes = {
  children: PropTypes.node.isRequired,
  doGoBack: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  currentView: PropTypes.string,
};

export default connect((state) => ({
  country: state?.global.country,
}))(ChildLayout);
