import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Stack,
  Container,
  Heading,
} from "design-system/components";
import QRCode from "qrcode.react";
import { Text } from "@chakra-ui/react";
import { modalIds, links } from "@spring/constants";
import { UnevenBubble } from "design-system";
import { sanitize } from "dompurify";

export const MobileQrCode = ({
  isOpen,
  onClose,
  url,
  header,
  text,
  altText,
}) => {
  const paragraphWithAppStoreLinks = text
    .replace("{links.GooglePlayUrl}", links.GooglePlayUrl)
    .replace("{links.AppStoreUrl}", links.AppStoreUrl);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"3xl"}
        id={modalIds.downloadOurMobileAppModal}
      >
        <ModalOverlay />
        <ModalContent
          h="fit-content"
          w={["95%", "95%", "95%", "815px"]}
          py={"75px"}
        >
          <ModalHeader m="auto" pt={0}>
            <Heading variant="lg_v1" textAlign="center">
              {header}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody m={"auto"} textAlign={"center"}>
            <Text
              variant="body_v1"
              w={["95%", "95%", "95%", "590px"]}
              dangerouslySetInnerHTML={{
                __html: sanitize(paragraphWithAppStoreLinks),
              }}
            />
            <Container>
              <Stack spacing={-220} alignItems={"center"} pb={50}>
                <UnevenBubble w="296px" h="282px" m="auto" mt="24px" />
                <Box
                  h="175px"
                  w="175px"
                  borderRadius={"7px"}
                  bg={"white"}
                  pt={2.5}
                >
                  <QRCode
                    height="155px"
                    width="155px"
                    top="50%"
                    left="50%"
                    value={url}
                    size={"153"}
                    renderAs={"svg"}
                    aria-label={altText}
                    tabIndex={0}
                  />
                </Box>
              </Stack>
            </Container>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
