import { useMemo } from "react";
import { MessagingApiClient } from "@springcare/sh-messaging-library";
import { API_DOMAIN, API_WEBSOCKET } from "constants/api";
import { isUserLoggedIn } from "lib/isUserLoggedIn";

export const useApiClient = () => {
  const isLoggedIn = isUserLoggedIn();
  const rotomUrl = API_DOMAIN;

  const apiClient = useMemo(
    () =>
      ({
        get: (url) => {
          return fetch(`${rotomUrl}${url}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }).then((res) => res.json());
        },

        post: (url, variables) =>
          fetch(`${rotomUrl}${url}`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(variables),
          }).then((res) => res.json()),
      }) as MessagingApiClient,
    [isLoggedIn],
  );

  return [apiClient, isLoggedIn, API_WEBSOCKET];
};
