import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { Link } from "@springcare/sh-component-library";
import { Box, Flex, Text, Heading, useMediaQuery } from "@chakra-ui/react";
import { LoadingDisplay } from "./LoadingDisplay";
import { useTranslation, Trans } from "react-i18next";
import { smeargleMaxTabletSize } from "constants/smeargle";
import { useInViewportOnce } from "hooks";
import { RequestedDateText } from "./features";
import { VButtonLink as ButtonLink } from "./shared";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import { isEmpty } from "lodash";
import {
  isFutureAppointment,
  isPastAppointment,
} from "utils/schedulingHelpers";
import { getAppointmentsWithProviderDetails } from "operations/queries/appointment";

type EstimateRequestStatusProps = {
  memberInsuranceInfo: { [key: string]: any };
};

export const EstimateRequestStatus = (
  { memberInsuranceInfo }: EstimateRequestStatusProps = {
    memberInsuranceInfo: {},
  },
): JSX.Element => {
  const { t } = useTranslation("costEstimate");
  const [isUnderSmeargleTabletSize] = useMediaQuery(
    `(max-width: ${smeargleMaxTabletSize}px)`,
  );
  const [isOver480] = useMediaQuery("(min-width: 480px)");
  const widthValueToUse = isUnderSmeargleTabletSize ? "100%" : "724px";
  const isTabletSizedViewport = isUnderSmeargleTabletSize && isOver480;

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const [hasAnyAppointments, setHasAnyAppointments] = useState(true);

  const { cost_estimate_requested_date: requestDate } =
    memberInsuranceInfo?.user.member.insurance_policy || {};

  function trackLinkClicked(path) {
    const type =
      path === routes.MemberHome.as ? "Go to my homepage" : "View my schedule";
    TRACK_EVENT.LINK_CLICKED(window.location.pathname, path, type, {
      page_version: "Cost estimate requested",
    });
  }

  const { data: appointmentsData, loading: appointmentsLoading } = useQuery(
    getAppointmentsWithProviderDetails,
    {
      skip: Meowth.apolloSkipUserId(),
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        booking_user_id: Meowth.getUserId(),
        sort_by: [
          {
            column: "appointments.start_at",
            direction: "desc",
          },
        ],
      },
    },
  );

  useEffect(() => {
    let pastAppointments = [];
    let upcomingAppointments = [];
    if (!appointmentsLoading && appointmentsData) {
      pastAppointments = appointmentsData.appointments.data.filter(
        (appointment) => {
          return isPastAppointment(appointment);
        },
      );

      upcomingAppointments = appointmentsData.appointments.data.filter(
        (appointment) => {
          return isFutureAppointment(appointment);
        },
      );
      setHasAnyAppointments(
        pastAppointments?.length > 0 || upcomingAppointments?.length > 0,
      );
    }
  }, [appointmentsLoading, appointmentsData]);

  const buttonProps = [
    {
      href: hasAnyAppointments
        ? routes.MemberCareVisits.as
        : `${routes.MemberCareVisits.as}?findNewCare=true`,
      variant: "medium-emphasis",
      i18nTranslatedText: hasAnyAppointments
        ? t("goToScheduleCta")
        : t("findNewCareCta"),
      width: "100%",
      mb: 8,
      onClick: () => {
        trackLinkClicked(routes.MemberCareVisits.as);
      },
    },
    {
      href: routes.MemberHome.as,
      variant: "high-emphasis",
      i18nTranslatedText: t("goToHomepageCta"),
      width: "100%",
      onClick: () => {
        trackLinkClicked(routes.MemberHome.as);
      },
    },
  ];

  const renderButtons = () =>
    buttonProps.map((props) => <ButtonLink key={props.href} {...props} />);
  const boxProps = isUnderSmeargleTabletSize
    ? {
        background: "white",
        width: "100%",
        padding: "16px 24px 34px 24px",
        paddingLeft: isTabletSizedViewport && "108px",
        bottom: 0,
        left: 0,
      }
    : { mt: 32 };

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(
        window.location.pathname,
        "Cost estimate requested",
        {},
      );
      setTrackedOnce(true);
    }
  }, [inViewport, trackedOnce]);

  // Shouldn't need this, but this is a fallback just in case
  if (isEmpty(memberInsuranceInfo)) return null;
  return (
    <>
      {appointmentsLoading ? (
        <LoadingDisplay isMemberInsuranceLoading={true} />
      ) : (
        <>
          <Flex
            ref={ref}
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Heading
              as="h1"
              width={widthValueToUse}
              size="heading-medium"
              mb={40}
              data-cy="cost-estimate-requested-heading"
            >
              {t("costEstimateRequestedHeading")}
            </Heading>
            <Box maxWidth={widthValueToUse} height="100%">
              <RequestedDateText requestDateInISO={requestDate} />
              <Box as="section">
                <Text my={16}>{t("requestSentParagraph")}</Text>
                <Text my={16}>{t("requestETAParagraph")}</Text>
                <Text my={16}>
                  <Trans
                    t={t}
                    i18nKey={"updateInsuranceText"}
                    components={[
                      <Link
                        href="/members/settings"
                        key="updateInsuranceText"
                      />,
                    ]}
                  />
                </Text>
              </Box>
              {!isUnderSmeargleTabletSize && (
                <Box as="footer" mt={48}>
                  <Box {...boxProps}>{renderButtons()}</Box>
                </Box>
              )}
            </Box>
          </Flex>
          {isUnderSmeargleTabletSize && (
            <Box as="footer" position="fixed" {...boxProps}>
              {renderButtons()}
            </Box>
          )}
        </>
      )}
    </>
  );
};
