/**
 * Enums for passing in Appointment Mediums
 * where necessary.
 */

const AppointmentMedium = Object.freeze({
	Video    : 'VIDEO',
	Phone    : 'PHONE',
	InPerson : 'IN_PERSON',
});


/**
 * This is a layer of abstraction to ensure that the name of these appointment kinds
 * that is sent to immutable tracking systems is never changed.
 * The values below should not be modified without lots of communication and consideration.
 * @param {string} modalId 
 * @returns {string}
 */
const getAppointmentMediumForTracking = (appointmentMediumEnum) => ({
	[AppointmentMedium.Video]    : 'Video',
	[AppointmentMedium.Phone]    : 'Phone',
	[AppointmentMedium.InPerson] : 'In Person',
})[appointmentMediumEnum];

module.exports = {
	AppointmentMedium,
	getAppointmentMediumForTracking,
};