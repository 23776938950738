//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";
import { formOptions } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "ASRS.q1": numberField(),
};
const prompt2Schema = {
  "ASRS.q2": numberField(),
};
const prompt3Schema = {
  "ASRS.q3": numberField(),
};
const prompt4Schema = {
  "ASRS.q4": numberField(),
};
const prompt5Schema = {
  "ASRS.q5": numberField(),
};
const prompt6Schema = {
  "ASRS.q6": numberField(),
};
const prompt7Schema = {
  "ASRS.q7": numberField(),
};
const prompt8Schema = {
  "ASRS.q8": numberField(),
};
const prompt9Schema = {
  "ASRS.q9": numberField(),
};
const prompt10Schema = {
  "ASRS.q10": numberField(),
};

export const ASRSSchema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...prompt7Schema,
  ...prompt8Schema,
  ...prompt9Schema,
  ...prompt10Schema,
};

const { HowOften } = formOptions;

const ASRS = [
  {
    id: "ASRS--1",
    title:
      "How often do you have **trouble wrapping up the final details** of a project once the challenging parts have been done?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="ASRS.q1"
        options={HowOften}
      />,
    ],
  },
  {
    id: "ASRS--2",
    title:
      "How often do you have **difficulty getting things in order** when you have to do a task that requires organization?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="ASRS.q2"
        options={HowOften}
      />,
    ],
  },
  {
    id: "ASRS--3",
    title:
      "How often do you **fidget or squirm** with your hands or feet when you have to sit down for a long time?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="ASRS.q3"
        options={HowOften}
      />,
    ],
  },
  {
    id: "ASRS--4",
    title:
      "When you have a task that requires a lot of thought, how often do you **avoid or delay getting started**?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="ASRS.q4"
        options={HowOften}
      />,
    ],
  },
  {
    id: "ASRS--5",
    title:
      "How often do you have **problems remembering appointments or obligations**?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="ASRS.q5"
        options={HowOften}
      />,
    ],
  },
  {
    id: "ASRS--6",
    title:
      "How often do you feel **overly active** and compelled to do things, like you were driven by a motor?",
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <RadioButtons
        key={6}
        border={true}
        fieldKey="ASRS.q6"
        options={HowOften}
      />,
    ],
  },
];

export default ASRS;
