// 

import React, { Component } from 'react';
import classnames from 'classnames';
import { random } from 'lodash';

import { LoadingCircle } from '../../Assets/svgs/';

import styles from './styles.module.scss';



class TextLoader extends Component {
	state = {
		index              : 0,
		content            : '',
		currentStringIndex : 0,
	}

	unmounted = false;

	static defaultProps = {
		thick        : false,
		text         : ['Loading...'],
		writeTimeout : 200,
		eraseTimeout : 20,
		restartDelay : 1000,
	}

	componentDidMount() {
		this.write();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	write = () => {
		if (!this.unmounted) {
			const { index, currentStringIndex } = this.state;
			const text = this.props.text[currentStringIndex];

			const { writeTimeout } = this.props;

			if (index <= text.length) {
				this.setState({
					index   : index + 1,
					content : text.slice(0, index + 1),
				});

				// -50 so we can make it longer occassionally
				setTimeout(this.write, writeTimeout - random(-50, writeTimeout));
			} else {
				setTimeout(this.erase, this.props.restartDelay);
			}

		}
	}

	erase = () => {
		if (!this.unmounted) {
			const { index, currentStringIndex } = this.state;
			const text = this.props.text[currentStringIndex];

			const { eraseTimeout } = this.props;

			if (index > -1) {
				this.setState({
					index   : index - 1,
					content : text.slice(0, index),
				});

				setTimeout(this.erase, eraseTimeout);
			} else {
				const { currentStringIndex } = this.state;
				let newIndex;

				if (currentStringIndex < this.props.text.length - 1) { // -1 since this is an array comparison
					newIndex = currentStringIndex + 1;
				} else {
					newIndex = 0;
				}

				this.setState({
					index              : 0,
					currentStringIndex : newIndex,
				});

				setTimeout(this.write, this.props.restartDelay);
			}

		}
	}

	render() {
		return (
			<div
				className={classnames(styles.wrapper, {
					[styles.relative] : this.props.relative,
					[styles.whiteBg]  : this.props.whiteBg,
				})}
				align={'center'}
			>
				<div
					className={classnames(styles.loader, {
						[styles.relative]: this.props.relative,
					})}
				>
					<LoadingCircle thick={this.props.thick}/>
					<div className={styles.loadingText} aria-label={this.props.text} role="status">
						<span aria-hidden="true">{this.state.content}</span>
					</div>
				</div>
			</div>
		);
	}
}

export default TextLoader;
