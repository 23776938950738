import { Underlined } from "@spring/smeargle";

const sprassComplete = [
  {
    id: "SPRASS--complete",
    title: "You're done!",
    subtitle: (
      <>
        We will now analyze your answers to determine what medication can be
        most helpful <Underlined>to you</Underlined>, and send our
        recommendation to your care team.
      </>
    ),
  },
];

export default sprassComplete;
