import { useTranslation } from "react-i18next";
import { Avatar, Button } from "@springcare/sh-component-library";
import { Box, Flex, Text } from "@chakra-ui/react";
import { VPrivateSpringIcon } from "@springcare/verdant-icons-react";
import { SHStickyFooter } from "design-system/components";
import { IntentOptions } from "components/organisms/MemberIntentQuestion/IntentOptionsEnum";

interface ButtonSectionProps {
  handleButtonClick: () => void;
  handleSkipButtonClick: () => void;
  isMobile: boolean;
  showSpringPreview: boolean;
  updateUserLangLoading: boolean;
  selectedOption?: string;
}

const ButtonSection = ({
  handleButtonClick,
  handleSkipButtonClick,
  isMobile,
  showSpringPreview,
  updateUserLangLoading,
  selectedOption,
}: ButtonSectionProps) => {
  const { t } = useTranslation("membership");

  const buttonText = (): string => {
    return showSpringPreview
      ? t("enhancedExpectations.previewSpringOfferings.buttonText")
      : t("enhancedExpectations.buttonText");
  };

  const BeginButton = () => (
    <Button
      variant="high-emphasis"
      onClick={handleButtonClick}
      isDisabled={updateUserLangLoading}
      data-cy="expectations-begin"
      w={showSpringPreview ? "initial" : ["343px", "initial"]}
      maxW="90vw"
      _active={{ backgroundColor: "hover-base-high-emphasis" }}
      _focus={{ backgroundColor: "hover-base-high-emphasis" }}
    >
      {buttonText()}
    </Button>
  );

  const SkipButtonSection = () => (
    <Button
      variant="no-emphasis"
      colorScheme="neutral"
      onClick={handleSkipButtonClick}
      isDisabled={updateUserLangLoading}
      data-cy="expectations-begin"
      w={showSpringPreview ? "initial" : ["343px", "initial"]}
      maxW="90vw"
      alignContent="center"
      marginBlockStart={"v-8"}
    >
      {t("enhancedExpectations.skipButton")}
    </Button>
  );

  const SpringPreviewConfidentiality = () => (
    <Box color="content-secondary" w="100%" mt={isMobile ? 0 : "48px"}>
      <Flex alignItems="center">
        <Avatar colorScheme="neutral" icon={VPrivateSpringIcon} />
        <Text fontSize="v-12" lineHeight="v-16" pl="v-8" pr="v-24">
          {t("enhancedExpectations.previewSpringOfferings.confidentiality")}
        </Text>
      </Flex>
    </Box>
  );

  return (
    <Flex
      width="100%"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      p={["0", "32px 0"]}
      alignSelf="stretch"
      bottom="0"
      left="0"
    >
      {isMobile ? (
        <SHStickyFooter
          testId="sticky-footer"
          hasShadow={showSpringPreview}
          left="0"
        >
          <Flex
            flexDirection="row-reverse"
            justifyContent="space-evenly"
            alignItems="center"
            mb="v-24"
            mt="v-16"
            px="v-16"
          >
            <Box>
              <BeginButton />
            </Box>
            <Box>
              <SkipButtonSection />
            </Box>
            {selectedOption === IntentOptions.SelfGuidedActivity && (
              <Box>
                <SkipButtonSection />
              </Box>
            )}
            {showSpringPreview && (
              <Box>
                <SpringPreviewConfidentiality />
              </Box>
            )}
          </Flex>
        </SHStickyFooter>
      ) : (
        <>
          <Flex
            w="full"
            justifyContent={["center", "left"]}
            flexDir="column"
            backgroundColor="background-base"
            position={["fixed", "initial"]}
            bottom={[0, "initial"]}
          >
            <BeginButton />
            {selectedOption === IntentOptions.SelfGuidedActivity && (
              <SkipButtonSection />
            )}
          </Flex>
          {showSpringPreview && <SpringPreviewConfidentiality />}
        </>
      )}
    </Flex>
  );
};

export default ButtonSection;
