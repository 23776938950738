import { gql } from "@apollo/client";

const createPotentialCoveredLife = gql`
  mutation createPotentialCoveredLife(
    $input: PotentialCoveredLifeInput!
    $send_notification: Boolean
  ) {
    createPotentialCoveredLife(
      input: $input
      send_notification: $send_notification
    ) {
      success
      potential_covered_life_id
    }
  }
`;

export default createPotentialCoveredLife;
