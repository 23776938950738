// 

import React from 'react';

const OpenInNewTab = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H5C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2H3C2.44772 2 2 2.44772 2 3V21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21V19C22 18.4477 22.4477 18 23 18C23.5523 18 24 18.4477 24 19V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="black"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M10 1C10 0.447715 10.4477 0 11 0H22.75C23.4404 0 24 0.559645 24 1.25V13C24 13.5523 23.5523 14 23 14C22.4477 14 22 13.5523 22 13V3.41421L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L20.5858 2H11C10.4477 2 10 1.55228 10 1Z" fill="black"/>
	</svg>
);
export default OpenInNewTab;
