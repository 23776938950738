import { Button, Link } from "@springcare/sh-component-library";
import { Flex, Box, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { ChangeCaregiver } from "design-system/assets";
import { useTranslation } from "hooks/react-i18next";
import { camelCase } from "lodash";
import {
  AppointmentMedium,
  ProviderRole,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  getModalNameForTracking,
  modalIds,
} from "@spring/constants";
import ChangePeerRecoverySpecialistModal from "components/modals/ChangePeerRecoverySpecialistModal";
import TRACKING from "modules/MemberDashboard/LocalResources/constants/tracking";
import { TRACK_EVENT } from "utils/mixpanel";
import { AppointmentKind } from "@spring/constants/src/enums/appointmentKind";

const DifferentProvider = ({
  isMobile,
  providerRole,
  providerData,
  changeAction,
  browseOthers,
}) => {
  const {
    isOpen: isPeerRecoverySpecialistFormOpen,
    onOpen: openPeerRecoverySpecialistForm,
    onClose: closePeerRecoverySpecialistForm,
  } = useDisclosure();
  const { t } = useTranslation(["careVisits"]);
  const handlePeerRecoverySpecialistClick = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      getModalNameForTracking(modalIds.changePeerRecoverySpecialistModal),
      "CareVisitPage:CareProviders",
      {
        location: `${TRACKING.peerRecoverySpecialistFollowUp} card`,
        appointment_type: getAppointmentKindForTracking(
          AppointmentKind.FollowUpPeerRecoverySpecialist,
        ),
        appointment_medium: getAppointmentMediumForTracking(
          AppointmentMedium.Video,
        ),
        provider_id: providerData.id,
      },
    );
    openPeerRecoverySpecialistForm();
  };
  return (
    <div>
      <Flex direction={"row"} alignItems={{ base: "center", md: "flex-start" }}>
        <Box
          textAlign={{ base: "center", md: "left" }}
          ml={{ base: 0, md: 5 }}
          mr={{ base: 13, md: 8 }}
        >
          <ChangeCaregiver
            aria-hidden="true"
            fontSize={24}
            verticalAlign={"text-bottom"}
          />
        </Box>
        <Stack direction={{ base: "column", md: "row" }} alignItems="left">
          <Text>
            {t(`careTeamCard.${camelCase(providerRole)}BrowseOthers`)}
          </Text>
          {providerRole == ProviderRole.PeerRecoverySpecialist ? (
            <Link onClick={handlePeerRecoverySpecialistClick}>
              {
                t(
                  "homepage:upNextSection.peerRecoverySupport.secondaryCTAText",
                ) as string
              }
            </Link>
          ) : (
            <Box
              whiteSpace={{ base: "nowrap", md: "normal" }}
              mt="0 !important"
            >
              <Button
                borderWidth={0}
                size="sm_v1"
                variant="link"
                colorScheme="primary"
                data-testid={
                  isMobile
                    ? `change-${providerRole}-button-mobile`
                    : `change-${providerRole}-button`
                }
                onClick={() => changeAction(providerData.id, providerRole)}
              >
                {browseOthers}
              </Button>
            </Box>
          )}
        </Stack>
      </Flex>
      <ChangePeerRecoverySpecialistModal
        isOpen={isPeerRecoverySpecialistFormOpen}
        onClose={closePeerRecoverySpecialistForm}
      />
    </div>
  );
};

export default DifferentProvider;
