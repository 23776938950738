export const TEEN_HOME_BANNER_TYPES = {
  find_your_therapist: {
    imgUrl: "/static/someoneWhoGetsYou.png",
    photoGradient:
      "linear-gradient(270deg, rgba(71, 52, 47, 0.00) 0%, rgba(71, 52, 47, 0.01) 14.59%, rgba(71, 52, 47, 0.04) 26.58%, rgba(71, 52, 47, 0.08) 36.32%, rgba(71, 52, 47, 0.14) 44.14%, rgba(71, 52, 47, 0.21) 50.37%, rgba(71, 52, 47, 0.29) 55.36%, rgba(71, 52, 47, 0.38) 59.44%, rgba(71, 52, 47, 0.47) 62.96%, rgba(71, 52, 47, 0.56) 66.24%, rgba(71, 52, 47, 0.65) 69.63%, rgba(71, 52, 47, 0.74) 73.46%, rgba(71, 52, 47, 0.82) 78.08%, rgba(71, 52, 47, 0.89) 83.82%, rgba(71, 52, 47, 0.95) 91.01%, #47342F 100%)",
    photoGradientMobile:
      "linear-gradient(to bottom, rgba(71, 52, 47, 0.00) 0%, rgba(71, 52, 47, 0.01) 14.59%, rgba(71, 52, 47, 0.04) 26.58%, rgba(71, 52, 47, 0.08) 36.32%, rgba(71, 52, 47, 0.14) 44.14%, rgba(71, 52, 47, 0.21) 50.37%, rgba(71, 52, 47, 0.29) 55.36%, rgba(71, 52, 47, 0.38) 59.44%, rgba(71, 52, 47, 0.47) 62.96%, rgba(71, 52, 47, 0.56) 66.24%, rgba(71, 52, 47, 0.65) 69.63%, rgba(71, 52, 47, 0.74) 73.46%, rgba(71, 52, 47, 0.82) 78.08%, rgba(71, 52, 47, 0.89) 83.82%, rgba(71, 52, 47, 0.95) 91.01%, #47342F 100%)",
  },
  take_teen_assessment: {
    imgUrl: "/static/teenTakeAssessment.png",
    photoGradient:
      "linear-gradient(270deg, rgba(74, 77, 48, 0.00) 0%, rgba(74, 77, 48, 0.01) 14.59%, rgba(74, 77, 48, 0.04) 26.58%, rgba(74, 77, 48, 0.08) 36.32%, rgba(74, 77, 48, 0.14) 44.14%, rgba(74, 77, 48, 0.21) 50.37%, rgba(74, 77, 48, 0.29) 55.36%, rgba(74, 77, 48, 0.38) 59.44%, rgba(74, 77, 48, 0.47) 62.96%, rgba(74, 77, 48, 0.56) 66.24%, rgba(74, 77, 48, 0.65) 69.63%, rgba(74, 77, 48, 0.74) 73.46%, rgba(74, 77, 48, 0.82) 78.08%, rgba(74, 77, 48, 0.89) 83.82%, rgba(74, 77, 48, 0.95) 91.01%, #4A4D30 100%)",
    photoGradientMobile:
      "linear-gradient(to bottom, rgba(74, 77, 48, 0.00) 0%, rgba(74, 77, 48, 0.01) 14.59%, rgba(74, 77, 48, 0.04) 26.58%, rgba(74, 77, 48, 0.08) 36.32%, rgba(74, 77, 48, 0.14) 44.14%, rgba(74, 77, 48, 0.21) 50.37%, rgba(74, 77, 48, 0.29) 55.36%, rgba(74, 77, 48, 0.38) 59.44%, rgba(74, 77, 48, 0.47) 62.96%, rgba(74, 77, 48, 0.56) 66.24%, rgba(74, 77, 48, 0.65) 69.63%, rgba(74, 77, 48, 0.74) 73.46%, rgba(74, 77, 48, 0.82) 78.08%, rgba(74, 77, 48, 0.89) 83.82%, rgba(74, 77, 48, 0.95) 91.01%, #4A4D30 100%)",
  },
};
