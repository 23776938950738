import { gql } from "@apollo/client";

const skipCarePlanProgressStep = gql`
  mutation SkipCarePlanProgressStep($care_plan_progress_step_id: ID!) {
    skipCarePlanProgressStep(
      care_plan_progress_step_id: $care_plan_progress_step_id
    ) {
      success
    }
  }
`;

export default skipCarePlanProgressStep;
