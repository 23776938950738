import { useMutation } from "@apollo/client";
import Router, { useRouter } from "next/router";
import updateMemberWorkplaceManager from "modules/MemberDashboard/ManagerExperience/Workplace/grapqhql/mutations/updateMemberWorkplaceManager";
import { updateMemberExperienceState } from "operations/mutations/member";
import routes from "routes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";

// Manager Experience Experiment #1: https://springhealth.atlassian.net/browse/MXE-734
export const useManagerExperienceSimplifyIdFlowExperiment = (memberId) => {
  const managerExperienceSimplifyIdFlowExperimentFeatureFlag: boolean =
    useFeatureFlag(FLAGS.MANAGER_EXPERIENCE_SIMPLIFY_ID_FLOW_EXPERIMENT);

  const router = useRouter();

  const [updateMemberisWorkplaceManager] = useMutation(
    updateMemberWorkplaceManager,
    {
      variables: {
        member_id: memberId,
        workplace_manager: true,
      },
    },
  );

  const [updateWorkplaceBannerDismissedAndWorkplacePageNotViewed] = useMutation(
    updateMemberExperienceState,
    {
      variables: {
        member_id: memberId,
        workplace_banner_dismissed: true,
        workplace_page_viewed: false,
      },
    },
  );

  const handleExperiment1SelfIDAnswerClick = (
    showWorkplaceManagerSelfIdCardOnBottom: boolean,
  ) => {
    updateMemberisWorkplaceManager();
    updateWorkplaceBannerDismissedAndWorkplacePageNotViewed();
    router.push(routes.WorkplaceManager.as);
    trackSelfIdCardLinkClicked(showWorkplaceManagerSelfIdCardOnBottom);
  };

  return {
    managerExperienceSimplifyIdFlowExperimentFeatureFlag,
    handleExperiment1SelfIDAnswerClick,
  };
};

const trackSelfIdCardLinkClicked = (showWorkplaceManagerSelfIdCardOnBottom) => {
  TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    routes.WorkplaceManager.as,
    "Positive Manager Identification",
    showWorkplaceManagerSelfIdCardOnBottom
      ? {
          spring_doc_id: "Manager_Experience_102",
          location: "Below the fold",
        }
      : {
          spring_doc_id: "Manager_Experience_101",
          location: "Top of homepage",
        },
  );
};
