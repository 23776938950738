//

import PropTypes from "prop-types";
import React from "react";
import { Icon } from "@spring/smeargle";

import Link from "components/atoms/Link";

import styles from "./styles.module.scss";

const BackButton = (props) => {
  if (props.alias) {
    return (
      <Link alias={props.alias} queries={props.queries}>
        <div className={styles.container}>
          <Icon type="keyboard-backspace" className={styles.back} />
          {props.children && (
            <div className={styles.content}>{props.children}</div>
          )}
        </div>
      </Link>
    );
  }

  return (
    <Icon
      type="keyboard-backspace"
      className={styles.back}
      onClick={props.onClick}
    />
  );
};

BackButton.propTypes = {
  alias: PropTypes.any,
  children: PropTypes.any,
  onClick: PropTypes.any,
  queries: PropTypes.any,
};

export default BackButton;
