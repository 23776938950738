import React from "react";
import { Box, Heading } from "@chakra-ui/react";

export const MemberGreeting = ({
  memberFirstName,
  memberGreeting,
  size,
  as = "h2",
}) => (
  <Box w="100%" pb="24">
    <Heading
      as={as as any}
      data-testid="up-next-section-greeting"
      size={size}
      overflow="hidden"
      textOverflow="ellipsis"
      wordBreak="break-all"
      noOfLines={1}
    >
      {memberGreeting} {memberFirstName}
    </Heading>
  </Box>
);
