import { useState, useEffect } from "react";
import { Avatar, Divider } from "@springcare/sh-component-library";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import {
  useSessionViewStatus,
  useSessionDevices,
  useSessionConnectStatus,
} from "context/SessionRoomContext";
import { SessionViewStatus } from "components/templates/SHSessionRoom/types";
import { formatPreSessionDateString } from "components/templates/SHSessionRoom/utils/utils";
import { useAppointment } from "hooks";
import { getAppointmentKindForTracking } from "@spring/constants";
import { useTranslation } from "hooks/react-i18next";

const SessionDetailsBody = ({ sessionData, children, appointmentId }) => {
  const [appointmentKindCopy, setAppointmentKindCopy] = useState<string>("");
  const { sessionViewStatus } = useSessionViewStatus();
  const { devicePermissionsDenied } = useSessionDevices();
  const { likelyFirewallError } = useSessionConnectStatus();

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoin",
  });

  const { data: appointmentData } = useAppointment(appointmentId);

  useEffect(() => {
    if (appointmentData) {
      const appointmentKind = `${getAppointmentKindForTracking(appointmentData?.appointment.kind)} ${t("aSession")}`;
      setAppointmentKindCopy(appointmentKind);
    }
  }, [appointmentData]);

  const { participant_type, provider_name, member_name, start_at } =
    sessionData;
  const { sessionDateString, sessionTimeString } =
    formatPreSessionDateString(start_at);

  const showParticipantDetails =
    sessionViewStatus !== SessionViewStatus.ProviderNoShow &&
    !devicePermissionsDenied &&
    !likelyFirewallError;

  const showAppointmentKind = !devicePermissionsDenied && !likelyFirewallError;

  const showAppointmentTime =
    sessionViewStatus === SessionViewStatus.NotStarted ||
    sessionViewStatus === SessionViewStatus.MemberIsWaiting ||
    sessionViewStatus === SessionViewStatus.Connecting;

  const participantNameCopy =
    participant_type === "provider" ? member_name : provider_name;

  return (
    <>
      <Flex
        py={showParticipantDetails && "v-16"}
        w="100%"
        justify="flex-start"
        align="center"
      >
        {showParticipantDetails && (
          <Box>
            <Avatar size="2xl" name={participantNameCopy} />
          </Box>
        )}

        <Box>
          {showParticipantDetails && (
            <Heading
              size="heading-small"
              ml="v-8"
              wordBreak="break-all"
              overflow="hidden"
            >
              {participantNameCopy}
            </Heading>
          )}
        </Box>
      </Flex>

      {showParticipantDetails && <Divider w="100%" mb="v-16" />}

      <Box pb="v-8">
        {showAppointmentKind && (
          <Text color={"content-secondary"}>
            {appointmentKindCopy
              ? appointmentKindCopy
              : t("genericSessionCopy")}
          </Text>
        )}
        {showAppointmentTime && (
          <Flex justify="space-between">
            <Text size="body-medium-strong" color="content-primary">
              {sessionDateString}
            </Text>
            <Text size="body-medium-strong" color="content-primary">
              {sessionTimeString}
            </Text>
          </Flex>
        )}
      </Box>
      {/* children is an optional button */}
      {children}
    </>
  );
};

export default SessionDetailsBody;
