import pichu, { AUTH_MESSAGE_CL_AWAITING_SSO } from "constants/pichu";
import { useRegisterContext } from "context/RegisterContext";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { removeQueryParams } from "utils/displayHelpers";

export const useCheckSsoReEnablement = () => {
  const router = useRouter();
  const {
    setNotification,
    ssoData = { relay_token: null, auth_message: null },
    setSsoData,
  } = useRegisterContext();
  const [isSsoReEnablement, setIsSsoReEnablement] = useState(false);

  // Set SSO re-enablement if params are found (auth message is correct and we have a relay token)
  useEffect(() => {
    if (
      router.query?.auth_message === AUTH_MESSAGE_CL_AWAITING_SSO &&
      ssoData?.relay_token
    ) {
      setIsSsoReEnablement(true);
    }
  }, [ssoData?.relay_token]);

  // Set SSO data in context for use in other components
  useEffect(() => {
    // Dont set SSO state if no relay_token provided
    if (!router.query?.relay_token) {
      return;
    }

    setSsoData({
      provider: router.query?.provider ?? null,
      external_user_id: router.query?.external_user_id ?? null,
      relay_token: router.query?.relay_token ?? ssoData?.relay_token ?? null,
      auth_message: router.query?.auth_message ?? ssoData?.auth_message ?? null,
    });

    removeQueryParams(["relay_token", "external_user_id"]);
  }, []);

  // Preserve the SSO enablement info in local storage (mainly used in Password Reset flow to continue re-connection)
  const preserveSsoReEnablementState = () => {
    window.localStorage.setItem(
      "sso_reenablement_state",
      JSON.stringify(ssoData),
    );
  };

  const clearPreservedSsoReEnablementState = () => {
    window.localStorage.removeItem("sso_reenablement_state");
  };

  const getPreservedState = () => {
    return JSON.parse(window.localStorage.getItem("sso_reenablement_state"));
  };

  const getRelayToken = () => {
    return ssoData?.relay_token ?? getPreservedState()?.relay_token;
  };

  const getAuthMessage = () => {
    return ssoData?.auth_message ?? getPreservedState()?.auth_message;
  };

  const checkSsoReEnablement = (password) => {
    // Get data directly out of context, or if not there, get out of preserved state
    const relay_token = getRelayToken();
    const auth_message = getAuthMessage();

    // Check to see if we have all needed pieces:
    // - Password
    // - Relay Token
    if (!password || !relay_token) {
      return;
    }

    // Check to see if the correct auth message was sent, which lets the FE know to trigger this request
    if (auth_message != AUTH_MESSAGE_CL_AWAITING_SSO) {
      return;
    }

    // If all checks pass, send the request to the auth service to preserve the SSO relay state on the auth'd user
    pichu
      .addSsoRelayState(password, relay_token)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("SSO relay added successfully!", response);
      })
      .catch((response) => {
        // eslint-disable-next-line no-console
        console.log("SSO relay failed to add", response);
        if (response.status === 409) {
          try {
            setNotification({
              text: "Unable to link the SSO to your account - It may be linked to another account already, please contact support",
              status: "error",
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        }
      })
      .finally(() => {
        // Clear the preserved state if we ever make the call regardless to prevent multiple calls.
        clearPreservedSsoReEnablementState();
      });
  };

  return {
    checkSsoReEnablement,
    isSsoReEnablement,
    preserveSsoReEnablementState,
  };
};
