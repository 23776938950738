import { Button, IconButton } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
  useTheme,
  Text,
} from "@chakra-ui/react";
import { VArrowLeftIcon } from "@springcare/verdant-icons-react";
import { useTranslation } from "react-i18next";
import { Call } from "design-system/assets";
import { VisitsCoveredCard } from "modules/MemberDashboard/Home/components/BenefitsModalT2/components/VisitsCoveredCard";
import { Description } from "modules/MemberDashboard/Home/components/BenefitsModalT2/components/Description";
import { FlyoutCTA } from "modules/MemberDashboard/Home/components/BenefitsModalT2/components/FlyoutCTA";
import { getDirectDialForT2, isT2Member } from "utils/memberHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import { benefitsCardAnalyticsMapping } from "modules/MemberDashboard/Home/constants/analyticsMapping";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";

export const BenefitsModalT2 = ({
  item,
  setShowBenefitsModal,
  helpInfo,
  memberData,
  isWpoCoaching = false,
}) => {
  const itemMap = {
    Therapy: "therapyVisits",
    Coaching: "coachesVisit",
    WorkLifeSupport: "EAPBenefit",
    CrisisSupport: "crisisSupport",
    DependentsSection: "dependentAccess",
  };

  const { breakpoints } = useTheme();
  const [isMoreThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isBetweenBaseToSm] = useMediaQuery(
    `(min-width: ${breakpoints.base}) and (max-width: ${breakpoints.sm})`,
  );

  const { t } = useTranslation(["t2Benefits", "memberHelpCard"]);

  const isWpoCoachingT1Member =
    isWpoCoaching && !isT2Member(memberData?.user?.member);

  const T1WpoDirectDialNumber = helpInfo?.region?.work_life_direct_dial;

  const directDialNumber = isWpoCoachingT1Member
    ? T1WpoDirectDialNumber
    : getDirectDialForT2(memberData?.user?.member?.cohort);

  return (
    <Box>
      {!isWpoCoaching && (
        <IconButton
          icon={VArrowLeftIcon}
          variant="medium-emphasis"
          aria-label={"back-button"}
          onClick={() => {
            setShowBenefitsModal("");
            TRACK_EVENT.MODAL_CLOSED(
              window.location.pathname,
              `Explore Care Modal - ${benefitsCardAnalyticsMapping[item]}`,
            );
          }}
        />
      )}
      <Heading as="h1" fontWeight="700" fontSize="29px" mt="64px">
        {t(`${itemMap[item]}.callToActionText`)}
      </Heading>
      <Flex mt="24px" gap={isMoreThanLg ? "64px" : "28px"}>
        <Description
          itemMap={itemMap}
          item={item}
          memberData={memberData}
          isBetweenBaseToSm={isBetweenBaseToSm}
          isMoreThanLg={isMoreThanLg}
        />
        {!isBetweenBaseToSm && (
          <VisitsCoveredCard
            isBetweenBaseToSm={isBetweenBaseToSm}
            item={item}
            memberData={memberData}
            isWpoCoaching={isWpoCoaching}
          />
        )}
      </Flex>
      <Flex
        alignItems="center"
        gap="24"
        mt="48px"
        flexDirection={isBetweenBaseToSm ? "column" : "row"}
      >
        <Button
          icon={Call}
          alignSelf="stretch"
          gap="4px"
          width={isBetweenBaseToSm && "100%"}
          aria-label={t("memberHelpCard:generalSupportText", {
            number: directDialNumber,
          })}
          onClick={() => {
            if (!isWpoCoaching) {
              TRACK_EVENT.BUTTON_CLICKED(
                window.location.pathname,
                `Explore Care Modal Direct Dial - ${benefitsCardAnalyticsMapping[item]}`,
              );
            }
            window.location.href = `tel:${directDialNumber}`;
          }}
        >
          <Text style={getPhoneStyleForRTL()}>{directDialNumber}</Text>
        </Button>
        <FlyoutCTA
          item={item}
          memberData={memberData}
          isWpoCoachingT1Member={isWpoCoachingT1Member}
          T1WpoDirectDialNumber={T1WpoDirectDialNumber}
        />
      </Flex>
    </Box>
  );
};
