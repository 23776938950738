import { Box, Flex, Text } from "design-system/components";
import PropTypes from "prop-types";
import { Star, Envelope, Error } from "design-system/assets";

export const SHStickyBanner = (props) => {
  return (
    <Box
      py={16}
      px={32}
      w={props.width ? props.width : "100vw"}
      h={props.height}
      mb={props.mb}
      zIndex={props.zIndex || 0}
      bg={props.backgroundColor}
    >
      <Flex alignItems="center">
        {
          {
            envelope: <Envelope />,
            star: <Star />,
            error: <Error color={props.errorFill ? props.errorFill : null} />,
          }[props.icon]
        }
        <Text
          ms=".5em"
          color={props.textColor}
          variant="bodyBold_v1"
          lineHeight="30px"
        >
          {props.firstRowText}
        </Text>
      </Flex>
      <Text
        color={props.textColor}
        variant="body_v1"
        ml="1.5em"
        lineHeight="20px"
      >
        {props.secondRowText}
      </Text>
    </Box>
  );
};

SHStickyBanner.propTypes = {
  height: PropTypes.any.isRequired,
  width: PropTypes.any,
  mb: PropTypes.number,
  zIndex: PropTypes.number,
  children: PropTypes.any,
  firstRowText: PropTypes.string,
  secondRowText: PropTypes.string,
  backgroundColor: PropTypes.string,
};
