export const routes = {
  "SelfHelp--Adhd": {
    to: "/apps/SelfHelp/adhd",
    as: "/self_help/adhd",
  },
  "SelfHelp--Alcoholism": {
    to: "/apps/SelfHelp/alcoholism",
    as: "/self_help/alcoholism",
  },
  "SelfHelp--Anxiety": {
    to: "/apps/SelfHelp/anxiety",
    as: "/self_help/anxiety",
  },
  "SelfHelp--Bipolar_Disorder": {
    to: "/apps/SelfHelp/bipolar_disorder",
    as: "/self_help/bipolar_disorder",
  },
  "SelfHelp--Depression": {
    to: "/apps/SelfHelp/depression",
    as: "/self_help/depression",
  },
  "SelfHelp--Eating_Disorder": {
    to: "/apps/SelfHelp/eating_disorder",
    as: "/self_help/eating_disorder",
  },
  "SelfHelp--Postpartum_Depression": {
    to: "/apps/SelfHelp/postpartum_depression",
    as: "/self_help/postpartum_depression",
  },
  "SelfHelp--Ptsd": {
    to: "/apps/SelfHelp/ptsd",
    as: "/self_help/ptsd",
  },
};

export default routes;
