import { gql } from "@apollo/client";

import { goal } from "operations/fragments/goal";

const updateMemberGoals = gql`
  mutation UpdateMemberGoals($input: UpdateMemberGoalsInput!) {
    updateMemberGoals(input: $input) {
      success
      member_goals {
        id
        status
        tracked
        goal {
          ...goal
        }
        member {
          id
        }
      }
    }
  }
  ${goal}
`;

export default updateMemberGoals;
