import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
  Grid,
  Col,
  Form,
  Checkbox,
  Input,
  PhoneInput,
  Textarea,
  GenderSelect,
} from "@spring/smeargle";
import Meowth from "@spring/meowth";
import { links } from "@spring/constants";
import { addNotification } from "@spring/smeargle/actions";

import envUtils from "utils/environment";
import { Link } from "components";
import { getMemberInfo } from "operations/queries/member";
import { updateMemberPrevisit } from "operations/mutations/member";
import { track } from "utils/mixpanel";
import getSchema from "schemas/memberPrevisitForm";
import useSchema from "schemas/use_schema";

const PrevisitForm = (props) => {
  const schema = useSchema({
    schemaFunction: getSchema,
    translationNamespace: ["limitedLangValidations", "limitedLangAuth"],
  });
  const { t } = useTranslation(["a11y", "previsitPage", "common"]);

  // we do not show pharmacy for global experience
  if (props.showGlobalExperience) {
    delete schema.preferredPharmacy;
    schema["_nodes"] = schema["_nodes"].filter(
      (node) => !node.includes("Pharmacy"),
    );
  }

  return (
    <Form
      preserve
      formKey="previsit"
      initialData={props.formData}
      schema={schema}
      submitText={t("common:form.submitText")}
      onSubmit={(data) => {
        const payload = {
          member_id: props.data.user.member.id,
          patch: {
            status: "Submitted",
            data: JSON.stringify(data),
          },
        };

        // $FlowFixMe complains about intersection type.
        props.submit(payload).then(() => {
          props.addNotification(
            "Thank you, your responses have been submitted.",
            "success",
          );
          props.toggle();
          track("Previsit Form Submitted");
        });
      }}
    >
      <Input
        dataCy="dateOfBirth"
        fieldKey="dateOfBirth"
        ariaLabel={t("previsitForm.ariaLabel.dob")}
        label={t("previsitPage:label.dob")}
        placeholder={
          props.data?.user?.member?.date_of_birth
            ? props.data.user.member.date_of_birth
            : "mm/dd/yyyy"
        }
        disabled
      />

      <GenderSelect
        fieldKey="gender"
        dataCy="gender"
        ariaLabel="gender"
        label={t("previsitPage:label.gender")}
        placeholder={t("previsitPage:placeholder.gender")}
      />

      <Input
        dataCy="raceOrEthnicity"
        fieldKey="raceOrEthnicity"
        ariaLabel={t("previsitForm.ariaLabel.raceOrEthnicity")}
        label={t("previsitPage:label.raceOrEthnicity")}
      />

      <Textarea
        dataCy="previousDiagnoses"
        fieldKey="previousDiagnoses"
        label={t("previsitPage:label.previousDiagnoses")}
      />

      <Textarea
        dataCy="previousTreatments"
        fieldKey="previousTreatments"
        label={t("previsitPage:label.previousTreatments")}
      />

      <Textarea
        dataCy="currentMentalHealthMeds"
        fieldKey="currentMentalHealthMeds"
        label={t("previsitPage:label.currentMentalHealthMeds")}
      />

      <Input
        dataCy="otherCurrentMeds"
        fieldKey="otherCurrentMeds"
        ariaLabel={t("previsitForm.ariaLabel.otherCurrentMeds")}
        label={t("previsitPage:label.otherCurrentMeds")}
        placeholder={t("previsitPage:placeholder.otherCurrentMeds")}
      />

      <Input
        dataCy="allergies"
        fieldKey="allergies"
        ariaLabel={t("previsitForm.ariaLabel.allergies")}
        label={t("previsitPage:label.allergies")}
        placeholder={t("previsitPage:placeholder.allergies")}
      />

      <Input
        dataCy="emergencyContactName"
        fieldKey="emergencyContact.name"
        ariaLabel={t("previsitForm.ariaLabel.emergencyContactName")}
        label={t("previsitPage:label.emergencyContactName")}
        placeholder={t("previsitPage:placeholder.emergencyContactName")}
      />

      <PhoneInput
        dataCy="emergencyContactPhoneNumber"
        fieldKey="emergencyContact.phoneNumber"
        label={t("previsitPage:label.emergencyContactPhoneNumber")}
        placeholder={t("previsitPage:placeholder.emergencyContactPhoneNumber")}
        required={true}
      />
      {!props.showGlobalExperience && (
        <Grid>
          <Col size={12}>
            <Input
              dataCy="pharmacyName"
              label={t("previsitPage:label.pharmacyName")}
              ariaLabel={t("previsitForm.ariaLabel.pharmacyName")}
              fieldKey="preferredPharmacy.name"
              placeholder={t("previsitPage:placeholder.pharmacyName")}
            />
          </Col>

          <Col size={12}>
            <Input
              dataCy="pharmacyStreet"
              label={t("previsitPage:label.pharmacyStreet")}
              ariaLabel={t("previsitForm.ariaLabel.pharmacyStreet")}
              fieldKey="preferredPharmacy.street"
              placeholder={t("previsitPage:placeholder.pharmacyStreet")}
            />
          </Col>

          <Col sm={4}>
            <Input
              dataCy="pharmacyCity"
              label={t("previsitPage:label.pharmacyCity")}
              ariaLabel={t("previsitForm.ariaLabel.pharmacyCity")}
              fieldKey="preferredPharmacy.city"
              placeholder={t("previsitPage:placeholder.pharmacyCity")}
            />
          </Col>

          <Col sm={4}>
            <Input
              dataCy="pharmacyState"
              label={t("previsitPage:label.pharmacyState")}
              ariaLabel={t("previsitForm.ariaLabel.pharmacyState")}
              fieldKey="preferredPharmacy.state"
              placeholder={t("previsitPage:placeholder.pharmacyState")}
            />
          </Col>

          <Col sm={4}>
            <Input
              dataCy="pharmacyPostalCode"
              label={t("previsitPage:label.pharmacyPostalCode")}
              ariaLabel={t("previsitForm.ariaLabel.pharmacyPostalCode")}
              fieldKey="preferredPharmacy.zipCode"
              placeholder={t("previsitPage:placeholder.pharmacyPostalCode")}
            />
          </Col>
        </Grid>
      )}
      <Checkbox
        dataCy="informedConsent"
        fieldKey="consentAgreements.informedConsent"
        label={
          <span>
            <Trans
              ns={"previsitPage"}
              i18nKey={"informedConsentLink"}
              components={[
                <Link
                  key={0}
                  to={envUtils.informedConsentLink}
                  target="_blank"
                  inlineTextLink={true}
                ></Link>,
              ]}
            />
          </span>
        }
        required={true}
      />
      <Checkbox
        dataCy="privacy"
        fieldKey="consentAgreements.privacy"
        label={
          <span>
            <Trans
              ns={"previsitPage"}
              i18nKey={"hipaaPrivacyLink"}
              components={[
                <Link
                  key={0}
                  to={links.PrivacyNotice}
                  target="_blank"
                  inlineTextLink={true}
                ></Link>,
              ]}
            />
          </span>
        }
        required={true}
      />
    </Form>
  );
};

PrevisitForm.propTypes = {
  addNotification: PropTypes.func,
  data: PropTypes.shape({
    user: PropTypes.shape({
      member: PropTypes.shape({
        date_of_birth: PropTypes.any,
        id: PropTypes.any,
      }),
    }),
  }),
  formData: PropTypes.any,
  showGlobalExperience: PropTypes.any,
  submit: PropTypes.func,
  toggle: PropTypes.func,
};

const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});

export default compose(
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(updateMemberPrevisit, {
    props: ({ mutate, ownProps }) => ({
      submit: (input) => {
        let refetchQueries = ["getMemberInfo"]; // When submitting, we remove the todo

        if (!ownProps.id) {
          // if updating an existing form. use auto-cache update
          refetchQueries.push("getMemberPrevisit");
        }

        return mutate({
          variables: { input },
          refetchQueries,
        });
      },
    }),
  }),
  connect(mapStateToProps, { addNotification }),
)(PrevisitForm);
