import { gql } from "@apollo/client";

const createCountryChangeRequestZendeskTicket = gql`
  mutation createCountryChangeRequestZendeskTicket($selected_country: String!) {
    requestCountryChange(selected_country: $selected_country) {
      success
    }
  }
`;

export default createCountryChangeRequestZendeskTicket;
