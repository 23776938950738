import { createIcon } from "@chakra-ui/react";

export const CircleCheck = createIcon({
  displayName: "CircleCheck",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16.6839 10.2295C17.0869 9.85181 17.1073 9.21897 16.7295 8.81606C16.3518 8.41315 15.719 8.39273 15.3161 8.77046L10.6667 13.1293L8.68394 11.2705C8.28103 10.8927 7.64819 10.9131 7.27046 11.3161C6.89273 11.719 6.91315 12.3518 7.31606 12.7295L9.98273 15.2295C10.3674 15.5902 10.966 15.5902 11.3506 15.2295L16.6839 10.2295Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    "aria-label": "Circle check icon",
    role: "img",
  },
});
