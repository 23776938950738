import { Avatar, Button, Link, Input } from "@springcare/sh-component-library";
import {
  Center,
  Box,
  VStack,
  Heading,
  Text,
  useBreakpointValue,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { VAvatarDuotoneSpot } from "@springcare/verdant-icons-react";
import Head from "next/head";
import { MfaSignInForm } from "components";
import { AccountLockedBanner } from "components/templates/RegisterPage/views/AccountLockedBanner";
import { RenderChildAvatar } from "./utils/RenderChildAvatar";
import { useTranslation } from "react-i18next";

type mfaLoginDataProps = {
  mfaToken: string;
  mfaType: string;
  userEmail: string;
  userPassword: string;
};

type targetDependentProps = {};

type ChooseUserPasswordDisplayProps = {
  isMFAAttempt: boolean;
  mfaLoginData: mfaLoginDataProps;
  initiateMfaLogin: () => void;
  first_name: string;
  targetDependent: targetDependentProps;
  isAccountLocked: boolean;
  handleSubmit: (e: unknown) => void;
  handlePasswordChange: (e: unknown) => void;
  requestForgotPassword: (email: string) => void;
  email: string;
  password: string;
};

export const ChooseUserPasswordDisplay = ({
  isMFAAttempt,
  mfaLoginData,
  initiateMfaLogin,
  first_name,
  targetDependent,
  isAccountLocked,
  handleSubmit,
  handlePasswordChange,
  requestForgotPassword,
  email,
  password,
}: ChooseUserPasswordDisplayProps) => {
  const { t } = useTranslation("chooseUser");
  const avatarWidth = useBreakpointValue([56, 118]);
  const avatarHeight = useBreakpointValue([56, 118]);
  const avatarBackgroundSize = useBreakpointValue([104, 188]);

  const ChildAvatar = (dependent) => () =>
    RenderChildAvatar({ dependent, avatarWidth, avatarHeight });

  const ParentAvatar = () => (
    // eslint-disable-next-line react/display-name
    <VAvatarDuotoneSpot
      data-testid="guardian-avatar"
      aria-hidden={true}
      width={58}
      height={59}
    />
  );
  const memberType = targetDependent ? "child" : "guardian";

  return (
    <>
      <Head>
        <title>Spring Health - {t("welcome")}</title>
        <meta property="og:title" content={t("welcome")} key="title" />
      </Head>
      {isMFAAttempt && mfaLoginData ? (
        <Center mt={24} w={[330, 330, "100%"]} p={10}>
          <MfaSignInForm
            mfaLoginData={mfaLoginData}
            initiateMfaLogin={initiateMfaLogin}
            routeAlias="MemberHome"
          />
        </Center>
      ) : (
        <VStack>
          <Heading as="h1" fontSize={[29, 38]} fontWeight="bold" mb={[10, 50]}>
            {t("welcome")} {first_name}
          </Heading>
          <Center>
            <Text
              as="p"
              my={24}
              fontSize={[16, 27]}
              fontWeight={"medium"}
              mb={[15, 40]}
            >
              {t("signIn")}
            </Text>
          </Center>
          <Box alignItems="center" display="flex" flexDir="column">
            <Box data-testid="avatar">
              <Avatar
                aria-hidden="true"
                icon={
                  targetDependent ? ChildAvatar(targetDependent) : ParentAvatar
                }
                height={avatarBackgroundSize}
                width={avatarBackgroundSize}
              />
            </Box>
            <VStack
              mt="24px"
              alignItems="center"
              mb={isAccountLocked ? "20px" : "50px"}
            >
              <Text as="p" fontSize={[16, 27]} fontWeight={600}>
                {first_name}
              </Text>
              {/* might need flexibility if adult password */}
              <Text variant="body1" fontWeight={600} color="#556065" margin={0}>
                {t(`type.${memberType}`)}
              </Text>
            </VStack>
          </Box>
          <Box alignSelf="center" width={200} margin="auto">
            <Box>
              {!isAccountLocked ? (
                <form onSubmit={handleSubmit}>
                  <FormControl>
                    <FormLabel
                      marginBottom={10}
                      marginRight={0}
                      textAlign="center"
                      fontSize={16}
                      color="#6a6a6a"
                    >
                      {t("password")}
                    </FormLabel>
                    <Input
                      name="password"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      height={40}
                    />
                  </FormControl>
                  <Center>
                    <Button
                      as={Link}
                      tabIndex={password.length < 1 ? 1 : 0}
                      size="v-sm"
                      onClick={handleSubmit}
                      variant="no-emphasis"
                      sx={{
                        textDecoration: "underline",
                        _hover: {
                          bg: "transparent",
                        },
                      }}
                      disabled={password.length < 1}
                      colorScheme="primary"
                      marginY={10}
                      _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                      data-cy="sign-in-button"
                    >
                      {t("signIn")}
                    </Button>
                  </Center>
                </form>
              ) : (
                <Center>
                  <AccountLockedBanner />
                </Center>
              )}
            </Box>
            {isAccountLocked ? (
              <Center>
                <Button onClick={() => requestForgotPassword(email)}>
                  {t("accountLocked")}
                </Button>
              </Center>
            ) : (
              <Center>
                <Button
                  as={Link}
                  variant="no-emphasis"
                  tabIndex={0}
                  sx={{
                    textDecoration: "underline",
                    _hover: "none",
                  }}
                  size="v-sm"
                  fontWeight="normal"
                  onClick={() => requestForgotPassword(email)}
                  _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                >
                  {t("forgotPassword")}?
                </Button>
              </Center>
            )}
          </Box>
        </VStack>
      )}
    </>
  );
};
