export const AttendedStatusTooltip = Object.freeze({
  AppointmentKept: "Kept",
  MemberLateCancel: "Member late cancel",
  MemberNoShow: "Member did not attend",
  MemberCancel: "Member cancel",
  CaregiverCancel: "Caregiver cancel",
  CaregiverLateCancel: "Caregiver late cancel",
  CaregiverNoShow: "Caregiver did not attend",
  SpringCancel: "Spring Health cancel",
  SpringLateCancel: "Spring Health late cancel",
  SpringAutoCancel: "Auto-cancel due to non-confirmation",
});
