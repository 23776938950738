//

import PropTypes from "prop-types";
import React from "react";
import Router from "next/router";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Modal, Content, Section, Button, FlexRow } from "@spring/smeargle";
import { closeModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import { get } from "lodash/fp";
import Meowth from "@spring/meowth";
import { useTranslation } from "react-i18next";

import { getMemberCohort } from "operations/queries/member";
import { track } from "utils/mixpanel";
import { Heading } from "design-system/components";
import routes from "routes";

const ReviewBenefitsModal = (props) => {
  const { t } = useTranslation("benefitsSnapshot");
  const { to, as } = routes.MemberBenefits;
  return (
    <Modal size="md" id={modalIds.reviewBenefitsModal} title={t("modal.title")}>
      <Heading as="h1" fontSize={48} fontWeight={50}>
        {t("modal.title")}
      </Heading>
      <Section>
        <Content>
          {get("data.user.member.cohort.benefits_summary", props)}
        </Content>
      </Section>
      <FlexRow justification="flex-end">
        <Button
          secondary
          text={t("modal.additionalDetails")}
          icon="arrow-right"
          onClick={() => {
            track("Review Benefits Modal - Additional Benefits Clicked");
            Router.push(to, as);
            props.closeModal(modalIds.reviewBenefitsModal);
          }}
        />
      </FlexRow>
    </Modal>
  );
};

ReviewBenefitsModal.propTypes = {
  closeModal: PropTypes.func,
};

export { ReviewBenefitsModal };

export default compose(
  connect(null, { closeModal }),
  graphql(getMemberCohort, { options: Meowth.apolloOptionsUserId }),
)(ReviewBenefitsModal);
