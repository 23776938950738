export const LeftRays = () => (
  <svg
    width="9"
    height="22"
    viewBox="0 0 9 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="left-rays"
    aria-hidden="true"
    role="img"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.98506 1.18333L7.88982 2.75043C8.16596 3.22872 8.00209 3.84031 7.52379 4.11645C7.0455 4.39259 6.43391 4.22872 6.15777 3.75043L5.25301 2.18333C4.97687 1.70504 5.14074 1.09345 5.61903 0.817308C6.09733 0.541166 6.70892 0.705041 6.98506 1.18333ZM0.947862 5.48827C1.224 5.00997 1.8356 4.8461 2.31389 5.12224L3.88098 6.027C4.35927 6.30314 4.52315 6.91473 4.24701 7.39303C3.97086 7.87132 3.35927 8.03519 2.88098 7.75905L1.31389 6.85429C0.835595 6.57815 0.67172 5.96656 0.947862 5.48827ZM1 12.1074C0.447715 12.1074 0 11.6597 0 11.1074C0 10.5551 0.447715 10.1074 1 10.1074H2.80952C3.36181 10.1074 3.80952 10.5551 3.80952 11.1074C3.80952 11.6597 3.36181 12.1074 2.80952 12.1074H1ZM5.61903 21.1827C5.14074 20.9065 4.97687 20.2949 5.25301 19.8166L6.15777 18.2495C6.43391 17.7712 7.0455 17.6074 7.52379 17.8835C8.00209 18.1597 8.16596 18.7712 7.88982 19.2495L6.98506 20.8166C6.70892 21.2949 6.09733 21.4588 5.61903 21.1827ZM2.31389 16.8777C1.8356 17.1539 1.224 16.99 0.947862 16.5117C0.67172 16.0334 0.835595 15.4218 1.31389 15.1457L2.88098 14.2409C3.35927 13.9648 3.97086 14.1286 4.24701 14.6069C4.52315 15.0852 4.35927 15.6968 3.88098 15.973L2.31389 16.8777Z"
      fill="#068262"
    />
  </svg>
);
