// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const Badge = (props) => (
	<div
		className={classnames(styles.badge, styles[props.color], {
			[styles.hide]  : props.hideEmpty && props.count < 1,
			[styles.small] : props.small,
		})}
	>
		{props.count}
	</div>
);

Badge.defaultProps = {
	count     : 0,
	hideEmpty : false,
};

export default Badge;
