import { Button } from "@springcare/sh-component-library";
import { Box, Flex, Text, Heading, useMediaQuery } from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";
import { smeargleMaxTabletSize } from "constants/smeargle";
import { getMemberInsurance } from "operations/queries/member";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";
import { TRACK_EVENT } from "utils/mixpanel";
import { useUpdateMemberInsurance } from "components/templates/CostEstimation/hooks";

type SelfPayDisplayProps = {
  handleDisplayChange: () => void;
  memberId: string;
};

export const SelfPayDisplay = ({
  memberId,
  handleDisplayChange,
}: SelfPayDisplayProps): JSX.Element => {
  const { t } = useTranslation("costEstimate");
  const [isUnderSmeargleTabletSize] = useMediaQuery(
    `(max-width: ${smeargleMaxTabletSize}px)`,
  );
  const [isOver480] = useMediaQuery("(min-width: 480px)");
  const widthValueToUse = isUnderSmeargleTabletSize ? "100%" : "724px";
  const isTabletSizedViewport = isUnderSmeargleTabletSize && isOver480;
  const [updateInsurance, { data }] = useUpdateMemberInsurance({
    refetchQueries: [getMemberInsurance],
  });

  const handleCostEstimateRequestClick = async () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Submit cost estimate",
      {
        is_submitted: true,
        page_version: "Self pay",
      },
    );
    await updateInsurance({
      variables: {
        member_id: memberId,
        insurance_type: CostEstimateInsuranceTypeEnum.noInsurance,
        trigger_cost_estimate_zendesk_ticket: true,
      },
    });

    if (data?.updateMemberInsurancePolicy?.success) {
      handleDisplayChange();
    }
  };

  return (
    <>
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Heading as="h1" width={widthValueToUse} size="heading-medium" mb={40}>
          {t("requestCostEstimateHeading")}
        </Heading>
        <Box as="section" maxWidth={widthValueToUse} height="100%">
          <Text>{t("requestSelfPayDescriptionText")}</Text>
          <Box
            background="background-subtle"
            paddingX="v-24"
            paddingY="v-16"
            marginTop="v-16"
            marginBottom={48}
            borderRadius="v-lg"
          >
            <Text mb="v-16">
              <Trans
                t={t}
                i18nKey="requestSelfPayAcknowledgementIntro"
                components={[
                  <Text
                    as="span"
                    size="body-medium-strong"
                    key="requestSelfPayAcknowledgementIntro"
                  />,
                ]}
              />
            </Text>
            <Text>{t("requestSelfPayAcknowledgementText")}</Text>
          </Box>
          {!isUnderSmeargleTabletSize && (
            <>
              <Button
                variant="high-emphasis"
                size="md"
                width="100%"
                onClick={handleCostEstimateRequestClick}
                data-cy="request-cost-estimate-button"
              >
                {t("requestEstimateCtaText")}
              </Button>
            </>
          )}
        </Box>
      </Flex>
      {isUnderSmeargleTabletSize && (
        <Box
          background="white"
          width="100%"
          padding="16px 24px 34px 24px"
          paddingLeft={isTabletSizedViewport && "108px"}
          position="fixed"
          bottom={0}
          left={0}
        >
          <Button
            variant="high-emphasis"
            size="md"
            width="100%"
            onClick={handleCostEstimateRequestClick}
          >
            {t("requestEstimateCtaText")}
          </Button>
        </Box>
      )}
    </>
  );
};
