import {
  teenWebLeftLinks,
  adultWebLeftLinks,
  adultWebWorkplaceManagerLeftLinks,
  minorWebLeftLinks,
  t2MemberWebLeftLinks,
  t2MemberWebLeftLinksWithWorkplace,
  adultMobileSidebarLinks,
  privatePracticeMobileSidebarLinks,
  adultMobileWorkplaceManagerSidebarLinks,
  minorMobileSidebarLinks,
  t2MemberMobileSidebarLinks,
  teenMobileSidebarLinks,
  t2MemberMobileSidebarLinksWithWorkplace,
  preAssessedExperience,
  preAssessedExperienceWithWorkplace,
  mobilePreAssessedExperience,
  mobilePreAssessedExperienceWithWorkplace,
} from "components/templates/MemberDashboard/PageWrapper/config/navConfig";
import {
  MenuNavLink,
  navLinkTogglesTypes,
  MobileMenuNavLink,
} from "components/templates/MemberDashboard/PageWrapper/types/navLinkTypes";
import { hasPreAssessedExperience } from "utils/memberHelpers";

export const getNavLinks = ({
  type,
  isAT2Member,
  isATeenMember,
  isAMinorMember,
  isAGlobalMember,
  canSeeWorkplaceLink,
  viewWorkplaceLinkFeatureFlag,
  isAWorkplaceManager,
  isADependent,
  isPrivatePractice = false,
  resellerCanViewWorkplaceNavLink,
  intent,
  assessment,
  hasWorkplaceManagerExperience,
}: navLinkTogglesTypes): MenuNavLink[] | MobileMenuNavLink[] => {
  const canViewWorkplaceLink =
    canSeeWorkplaceLink &&
    isAWorkplaceManager &&
    !isADependent &&
    !isAMinorMember &&
    !isPrivatePractice &&
    !resellerCanViewWorkplaceNavLink;

  const canAccessWorkplace =
    canViewWorkplaceLink &&
    (isAGlobalMember ? viewWorkplaceLinkFeatureFlag : true);

  if (type === "web") {
    if (hasPreAssessedExperience(intent, assessment)) {
      if (hasWorkplaceManagerExperience) {
        return preAssessedExperienceWithWorkplace;
      }
      return preAssessedExperience;
    }

    if (isAT2Member) {
      if (canViewWorkplaceLink && viewWorkplaceLinkFeatureFlag) {
        return t2MemberWebLeftLinksWithWorkplace;
      }
      return t2MemberWebLeftLinks;
    }
    if (isATeenMember) return teenWebLeftLinks;
    if (isAMinorMember) return minorWebLeftLinks;
    if (canAccessWorkplace) return adultWebWorkplaceManagerLeftLinks;
    return adultWebLeftLinks;
  }

  if (type === "mobile") {
    if (hasPreAssessedExperience(intent, assessment)) {
      if (hasWorkplaceManagerExperience) {
        return mobilePreAssessedExperienceWithWorkplace;
      }
      return mobilePreAssessedExperienceWithWorkplace;
    }

    if (isAT2Member) {
      if (canViewWorkplaceLink && viewWorkplaceLinkFeatureFlag) {
        return t2MemberMobileSidebarLinksWithWorkplace;
      }
      return t2MemberMobileSidebarLinks;
    }
    if (isATeenMember) return teenMobileSidebarLinks;
    if (isAMinorMember) return minorMobileSidebarLinks;
    if (canAccessWorkplace) return adultMobileWorkplaceManagerSidebarLinks;
    if (isPrivatePractice) return privatePracticeMobileSidebarLinks;
    return adultMobileSidebarLinks;
  }
};
