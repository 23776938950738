import { useRef, useEffect, useState } from "react";
import {
  Button,
  Box,
  Text,
  VStack,
  useBreakpointValue,
} from "design-system/components";
import { Virtual, InPerson, Call } from "design-system";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { getAppointmentMediumForTracking } from "@spring/constants";

import { useInViewportOnce } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";

type MobileTimeSlotsProps = {
  providerId?: string;
  providerIndex?: number;
  isPhoneMedium?: boolean; // Optional with default value
  inPerson: boolean;
  kind: string;
  timeSlotText: string;
  openSchedulingModal: (event) => void;
  timeSlot: string;
  seeMoreTimesLinkText: string;
  openSeeMoreTimes: (event) => void;
  isVertical?: boolean; // Optional with default value
  queryRequestId: string;
  trackingCategory: string;
};

const MobileTimeSlots = ({
  providerId,
  providerIndex,
  isPhoneMedium = false,
  inPerson,
  kind,
  timeSlotText,
  openSchedulingModal,
  timeSlot,
  seeMoreTimesLinkText,
  openSeeMoreTimes,
  isVertical = false,
  queryRequestId,
  trackingCategory,
}: MobileTimeSlotsProps) => {
  const ref = useRef();
  const [trackedOnce, setTrackedOnce] = useState(0);
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible
  const isBrecsFlag = isBrecsVariant(
    useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT),
  );

  const medium = inPerson ? "In Person" : isPhoneMedium ? "Phone" : "Video";

  useEffect(() => {
    // BRECS-specific tracking
    if (isBrecsFlag && inViewport && trackedOnce === 0) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Available Appointment",
        {
          spring_doc_id: "directsched03",
          location: "Recommended Provider Card",
          appointment_type: kind,
          appointment_medium: medium,
          appointment_time_utc: timeSlot,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          offset: new Date().getTimezoneOffset(),
          ["Virtual tab"]: getAppointmentMediumForTracking(medium) === "Video",
          ["In Person tab"]:
            getAppointmentMediumForTracking(medium) === "In Person",
          provider_id: providerId,
          provider_order: providerIndex,
          query_request_id: queryRequestId,
          browse_page_number: null, // N/A when isBrecs
          order: 1, // only one button,
          category: trackingCategory,
        },
      );
      setTrackedOnce(1);
    }
  }, [inViewport]);

  const buttonTextVariant = useBreakpointValue(["stretch", "center"]);
  let mediumIcon: JSX.Element;
  if (inPerson) {
    mediumIcon = <InPerson pl={4} color="platform" w={20} h={"auto"} />;
  } else if (isPhoneMedium) {
    mediumIcon = <Call pl={4} color="platform" w={20} h={"auto"} />;
  } else {
    mediumIcon = <Virtual pl={4} color="platform" w={24} h={16} />;
  }
  return (
    <Box ref={ref} display="flex" gap="v-24" flexDirection="column">
      <Box>
        <Button
          variant="outline"
          colorScheme="platform"
          w={{ base: "100%", lg: isVertical ? "100%" : "346px" }}
          h={"60px"}
          onClick={openSchedulingModal}
          aria-label={timeSlot}
        >
          <Box w="100%">
            <VStack align={buttonTextVariant} spacing={"3px"}>
              <Text variant="body2" alignItems="center" display="flex" gap={1}>
                {mediumIcon}
                {timeSlotText}
              </Text>
              <Text variant="body2">{timeSlot}</Text>
            </VStack>
          </Box>
        </Button>
      </Box>
      {seeMoreTimesLinkText && (
        <Box>
          <VStack mt={8}>
            <Button
              variant="link"
              colorScheme="gray"
              onClick={(e) => {
                e.stopPropagation();
                openSeeMoreTimes(e);
              }}
              id="isit"
            >
              <Text textAlign="center" variant="body2">
                {seeMoreTimesLinkText}
              </Text>
            </Button>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default MobileTimeSlots;
