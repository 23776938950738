import React from "react";

import { Text } from "@chakra-ui/react";

// From WebAIM
// https://webaim.org/techniques/css/invisiblecontent/#techniques
export const ScreenReaderOnly = (props) => {
  const { children, ...rest } = props;

  return (
    <Text
      as="span"
      {...rest}
      sx={{
        position: "absolute",
        left: "-10000px",
        top: "auto",
        width: "1px",
        height: "1px",
        overflow: "hidden",
      }}
    >
      {children}
    </Text>
  );
};

export default ScreenReaderOnly;
