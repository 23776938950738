import { useEffect } from "react";
import { useLocalParticipantPermissions } from "@livekit/components-react";

import {
  useSessionViewStatus,
  useSessionUserActive,
} from "context/SessionRoomContext";
import { SessionViewStatus } from "components/templates/SHSessionRoom/types";

import { requestKeepActive } from "components/templates/SHSessionRoom/utils/keepMemberLoggedIn";
// NOTE: This component checks the local participant's permissions using
// Livekit's useLocalParticipantPermissions() hook and routes them accordingly.
// Providers have all permissions set to 'true' and can join a room immediately
// and admit Members. Members have permissions 'false' by default, which change
// upon Provider approval. The useEffect() detects this change and updates the
// session view status (see setSessionViewStatus(SessionViewStatus.InProgress).
// This component is necessary b/c Livekit hooks must be used within React
// components.

const ParticipantPermissionsChecker = ({ videoRef }) => {
  const localParticipantPermissions = useLocalParticipantPermissions();
  const { canPublish, canSubscribe, canPublishData } =
    localParticipantPermissions || {};
  const { sessionViewStatus, setSessionViewStatus } = useSessionViewStatus();
  const { setWakeLock, isLoggedIn } = useSessionUserActive();

  const removePrejoinStream = () => {
    const tracks = (videoRef?.current?.srcObject as MediaStream)?.getTracks();
    tracks?.forEach((track) => {
      track.stop();
    });
  };

  useEffect(() => {
    if (canPublish && canSubscribe && canPublishData) {
      if (videoRef) {
        removePrejoinStream();
      }
      // Keep logged-in Members from being logged out due to inactivity once _inside the room_
      if (isLoggedIn) {
        requestKeepActive(setWakeLock);
      }
      setSessionViewStatus(SessionViewStatus.InProgress);
    } else if (sessionViewStatus !== SessionViewStatus.NotStarted) {
      // NOTE: This means the member should wait
      setSessionViewStatus(SessionViewStatus.MemberIsWaiting);
    }
  }, [localParticipantPermissions]);

  return null;
};

export default ParticipantPermissionsChecker;
