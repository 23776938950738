import { gql } from "@apollo/client";

const getMemberGoalsAndIssues = gql`
  query getMemberGoalsAndIssues($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        list_goal_types
        list_issues
      }
    }
  }
`;

export default getMemberGoalsAndIssues;
