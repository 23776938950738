import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { getChannelPartner } from "operations/queries/customer";

export function useChannelPartner() {
  return useQuery(getChannelPartner, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });
}
