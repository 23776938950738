import { restorePassword } from "lib/rememberPassword";
import { signIn } from "actions/auth";

export async function retryLogin(email) {
  const password = restorePassword();
  if (!password) return false;
  try {
    // signIn redirects to initialTarget
    await signIn(email, password);
    return true;
  } catch (_err) {
    return false;
  }
}
