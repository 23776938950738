import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import yup from "schemas/yup";
import {
  Button,
  Card,
  Col,
  Content,
  DateSelect,
  FlexRow,
  Form,
  Grid,
  Input,
  Subhead,
} from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import { RequestCoveredLifeKind } from "@spring/constants";
import { useTranslation } from "react-i18next";
import routes from "routes";
import { createCoveredLife } from "operations/mutations/user";
import { getFirstError } from "utils/apollo/errorHandler";
import { updateVerificationModel } from "actions/signUp";
import {
  TRACK_EVENT,
  track,
  EVENT_TYPE,
  makeEventString,
} from "utils/mixpanel";
import { minAgeDOB } from "schemas/minAgeDOB";

import styles from "./styles.module.scss";
import { ResponsiveCardPadding } from "components";
import { getIterableCampaignInfo } from "utils/localStorage";
import { useLazyFindCoveredLife } from "hooks/useLazyFindCoveredLife";

const mpPrefix = "Who shares this benefit with you? (/lookup_verification)";

const lookupVerificationSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  date_of_birth: minAgeDOB(13).required(),
  email: yup.string().email().nullable(),
});

const LookupVerificationForm = (props) => {
  const [childrenAllowed, setChildrenAllowed] = useState(false);
  const { first_name, last_name, date_of_birth } = props.findMyMembershipModel;
  const [
    setCoveredLife,
    { loading: setCoveredLifeLoading, data: setCoveredLifeData },
  ] = useMutation(createCoveredLife, {
    onError: (err) => props.addNotification(getFirstError(err), "error"),
  });

  const {
    getFindCoveredLife: getCoveredLife,
    loading: getCoveredLifeLoading,
    data: getCoveredLifeData,
  } = useLazyFindCoveredLife({
    onError: (err) => props.addNotification(getFirstError(err), "error"),
    fetchPolicy: "network-only",
  });

  const { t } = useTranslation("limitedLangAuth");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    track(`${mpPrefix} -- Viewed`, {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
        page: routes.LookupVerification.as,
      }),
    });
  }, []);

  const handleSubmit = async (formData) => {
    track(`${mpPrefix} -- Submitted`, {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
        page: routes.LookupVerification.as,
        type: "Look Up Verification Form",
      }),
    });
    TRACK_EVENT.FORM_SUBMITTED(
      routes.LookupVerification.as,
      "Look Up Verification Form",
    );
    const { first_name, last_name, date_of_birth, email } = formData;
    try {
      await getCoveredLife({
        variables: {
          first_name,
          last_name,
          date_of_birth: date_of_birth.actualDate,
          email,
          request_kind: RequestCoveredLifeKind.Primary,
        },
      });
    } catch (err) {
      props.addNotification(getFirstError(err), "error");
    }
  };

  useEffect(() => {
    if (getCoveredLifeData && getCoveredLifeData.find_covered_life) {
      // If the query succeeds, we pass the covered_life_id and run the createCoveredLife mutation
      if (
        getCoveredLifeData.find_covered_life.success &&
        getCoveredLifeData.find_covered_life.covered_life_id
      ) {
        setCoveredLife({
          variables: {
            input: {
              first_name: first_name,
              last_name: last_name,
              date_of_birth: date_of_birth,
              primary_covered_life_id:
                getCoveredLifeData.find_covered_life.covered_life_id,
              dependent: true,
              campaign: getIterableCampaignInfo(),
            },
          },
        });
        // used to route new CL properly
        if (getCoveredLifeData.find_covered_life.child_dependents_allowed) {
          setChildrenAllowed(true);
        }
      }

      // // If the query fails, we go to manual verification.
      if (!getCoveredLifeData.find_covered_life.success) {
        const { to, as } = routes.ManualVerification;

        props.router.push(to, as);
      }
    }
  }, [getCoveredLifeData]);

  useEffect(() => {
    if (setCoveredLifeData && setCoveredLifeData.createCoveredLife) {
      // If the query succeeds, we store the invite_token and cohort_id
      if (
        setCoveredLifeData.createCoveredLife.success &&
        setCoveredLifeData.createCoveredLife.invite_token &&
        setCoveredLifeData.createCoveredLife.cohort_id
      ) {
        const { invite_token, cohort_id } =
          setCoveredLifeData.createCoveredLife;
        props.updateVerificationModel({
          fieldKey: "invite_token",
          value: invite_token,
        });
        props.updateVerificationModel({
          fieldKey: "cohort_id",
          value: cohort_id,
        });

        if (childrenAllowed) {
          const { to, as } = routes.Register;
          props.router.push(
            {
              pathname: to,
              query: { invite_token, cohort_id },
            },
            as,
          );
          return;
        }
        // If not, go to CreateAccount
        const { to, as } = routes.Register;
        props.router.push(
          {
            pathname: to,
            query: { invite_token, cohort_id },
          },
          as,
        );
      }

      // If the query fails, we go to manual verification.
      if (!setCoveredLifeData.createCoveredLife.success) {
        const { to, as } = routes.ManualVerification;

        props.router.push(to, as);
      }
    }
  }, [setCoveredLifeData]);

  const handleNoInfoButtonClick = () => {
    props.router.push(
      routes.ManualVerification.to,
      routes.ManualVerification.as,
    );

    TRACK_EVENT.BUTTON_CLICKED(
      routes.LookupVerification.as,
      "No Member Info Now",
      {
        to: routes.ManualVerification.as,
      },
    );
  };

  const searchForm = () => (
    <>
      <div className={styles.header}>
        <Subhead semibold center inheritColor noMargin>
          {t("lookupVerificationForm.title")}
        </Subhead>

        <div className={styles.headerSubtext}>
          <Content inheritColor>{t("lookupVerificationForm.subtitle")}</Content>
        </div>
      </div>

      <Form
        onSubmit={(formData) => handleSubmit(formData)}
        submitText={t("lookupVerificationForm.submitText")}
        disabled={getCoveredLifeLoading || setCoveredLifeLoading}
        schema={lookupVerificationSchema}
        formKey="lookup_verification_form"
        preserve
        fullSubmit
        flatSubmit
        mdSection
        submitIcon="arrow-right"
        dataCy="lookup-verification-form"
      >
        <Grid gutter="0 16px">
          <Col sm={6}>
            <Input
              fieldKey="first_name"
              dataCy="lookup-verification-firstname"
              label={t("lookupVerificationForm.firstName.label")}
              placeholder={t("lookupVerificationForm.firstName.placeholder")}
              autoComplete="given-name"
              bolded
              autoFocus
            />
          </Col>

          <Col sm={6}>
            <Input
              fieldKey="last_name"
              dataCy="lookup-verification-lastname"
              label={t("lookupVerificationForm.lastName.label")}
              placeholder={t("lookupVerificationForm.lastName.placeholder")}
              autoComplete="family-name"
              bolded
            />
          </Col>

          <Col>
            <DateSelect
              fieldKey="date_of_birth"
              label={t("lookupVerificationForm.dateOfBirth.label")}
              autoComplete="bday"
              dataCy="lookup-verification-dob"
              bolded
            />
          </Col>

          <Col>
            <Input
              fieldKey="email" // also used as autoComplete value
              label={t("lookupVerificationForm.email.label")}
              placeholder={t("lookupVerificationForm.email.placeholder")}
              dataCy="lookup-verification-email"
              bolded
            />
          </Col>

          <Col>
            <div className={styles.info}>
              <Content>{t("lookupVerificationForm.email.noEmailText")}</Content>
            </div>
          </Col>
        </Grid>
      </Form>

      <div className={styles.manualVerificationBtn}>
        <FlexRow justification="center">
          <Button
            full
            flat
            secondary
            dataCy="lookup-verification-no-info"
            text={t("lookupVerificationForm.buttonText")}
            onClick={handleNoInfoButtonClick}
          />
        </FlexRow>
      </div>
    </>
  );

  const breakpointWrapper = () => (
    <Card color="GREY_100" padding="none" radius="md">
      <ResponsiveCardPadding>{searchForm()}</ResponsiveCardPadding>
    </Card>
  );

  return breakpointWrapper();
};

const mapStateToProps = (state) => ({
  findMyMembershipModel: state.signUp.findMyMembershipModel,
});
const mapDispatchToProps = { addNotification, updateVerificationModel };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LookupVerificationForm);

LookupVerificationForm.propTypes = {
  addNotification: PropTypes.func,
  findMyMembershipModel: PropTypes.shape({
    date_of_birth: PropTypes.string,
    email_address: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  router: PropTypes.object,
  updateVerificationModel: PropTypes.func,
};
