import {
  SupportedLanguages,
  CountryLanguage,
  GlobalOneSupportedLanguages,
} from "@spring/constants";

export const PREFERRED_LANGUAGE = "sh_preferred_language";

const AllLanguages = GlobalOneSupportedLanguages.concat(SupportedLanguages);

export const getLanguageFromLocalStorage = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(PREFERRED_LANGUAGE);
  }
};

export const setLanguageInLocalStorage = (language) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(PREFERRED_LANGUAGE, language);
  }
};

const lookupByNativeName = (language) => {
  return AllLanguages.find(
    (languageObject) => languageObject.nativeName.toLowerCase() === language,
  );
};

const lookupByEnglishName = (language) => {
  return AllLanguages.find(
    (languageObject) => languageObject.englishName.toLowerCase() === language,
  );
};

const lookupByCode = (language) => {
  return AllLanguages.find(
    (languageObject) => languageObject.code.toLowerCase() === language,
  );
};

export const lookupLanguage = (language) => {
  const lang = language?.toLowerCase();
  const code = lookupByCode(lang);
  if (code) {
    return code;
  }
  const native = lookupByNativeName(lang);
  if (native) {
    return native;
  }
  const eng = lookupByEnglishName(lang);
  return eng || SupportedLanguages[0];
};

export const getLanguageFromCountry = (country) => {
  if (!country || country.length === 0) {
    return "en";
  }

  const formattedCountry = country.toUpperCase().trim();
  const langFromCountry = CountryLanguage[formattedCountry];
  if (!langFromCountry) {
    return "en";
  }

  return langFromCountry.primary || "en";
};
