import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "@springcare/sh-component-library";
import { Trans, useTranslation } from "react-i18next";
import { links } from "@spring/constants";

export const SessionRecordingCopy = () => {
  const { t } = useTranslation("careVisits");
  return (
    <Box my="v-16">
      <Text size="body-medium-regular" mb="v-16">
        <Trans
          t={t}
          i18nKey="sessionRecordings.sessionRecordingCopy"
          components={[
            <Link
              key={"0"}
              href={links.TermsOfService}
              isExternal
              aria-label="Terms of Service"
            />,
            <Link
              key={"1"}
              href={links.PrivacyPolicy}
              isExternal
              aria-label="Privacy Policy"
            />,
          ]}
        />
      </Text>
    </Box>
  );
};
