import { gql } from "@apollo/client";

export const getFeaturedWorkplaceVideos = gql`
  query getFeaturedWorkplaceVideos($limit: Int = 3, $offset: Int = 0) {
    featured_workplace_videos(limit: $limit, offset: $offset) {
      data {
        id
        title
        description
        content_url
        content_type
        featured
        image_url
        us_available
        globally_available
        workplace_video_categories {
          category
        }
        duration_in_minutes
      }
    }
  }
`;
