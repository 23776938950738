import { gql } from "@apollo/client";

const updateMemberExperienceState = gql`
  mutation UpdateMemberExperienceState(
    $member_id: ID
    $visited_your_journey: Boolean
    $modal_mobile_app_count: Int
    $banner_moments_count: Int
    $family_todo_count: Int
    $update_settings_shown_count: Int
    $should_update_settings: Boolean
    $workplace_banner_dismissed: Boolean
    $workplace_page_viewed: Boolean
  ) {
    updateMemberExperienceState(
      member_id: $member_id
      visited_your_journey: $visited_your_journey
      modal_mobile_app_count: $modal_mobile_app_count
      banner_moments_count: $banner_moments_count
      family_todo_count: $family_todo_count
      update_settings_shown_count: $update_settings_shown_count
      should_update_settings: $should_update_settings
      workplace_banner_dismissed: $workplace_banner_dismissed
      workplace_page_viewed: $workplace_page_viewed
    ) {
      member {
        id
        experience_state {
          visited_your_journey
          modal_mobile_app_count
          banner_moments_count
          family_todo_count
          update_settings_shown_count
          should_update_settings
          workplace_banner_dismissed
          workplace_page_viewed
        }
      }
      success
    }
  }
`;

export default updateMemberExperienceState;
