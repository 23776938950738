import { useMutation } from "@apollo/client";
import { Button } from "@springcare/sh-component-library";
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";
import { smeargleMaxTabletSize } from "constants/smeargle";
import { updateMemberPaymentPreference } from "operations/mutations/member";
import { getMemberInsurance } from "operations/queries/member";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";
import { TRACK_EVENT } from "utils/mixpanel";

type SelfPayDisplayProps = {
  handleDisplayChange: () => void;
  memberId: string;
};

export const ModalSelfPayDisplay = ({
  memberId,
  handleDisplayChange,
}: SelfPayDisplayProps): JSX.Element => {
  const { t } = useTranslation("costEstimate");
  const [isUnderSmeargleTabletSize] = useMediaQuery(
    `(max-width: ${smeargleMaxTabletSize}px)`,
  );
  const [isOver480] = useMediaQuery("(min-width: 480px)");
  const widthValueToUse = isUnderSmeargleTabletSize ? "100%" : "724px";
  const [updateInsurance, { loading: isMemberInsuranceLoading }] = useMutation(
    updateMemberPaymentPreference,
    {
      refetchQueries: [getMemberInsurance],
      onCompleted: (data) => {
        if (data?.updateMemberInsurancePolicy?.success) {
          handleDisplayChange();
        }
      },
    },
  );

  const handlePaymenDetailsRequestClick = async () => {
    await updateInsurance({
      variables: {
        member_id: memberId,
        insurance_type: CostEstimateInsuranceTypeEnum.noInsurance,
        trigger_cost_estimate_zendesk_ticket: false,
      },
    });
  };

  return (
    <>
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box as="section" maxWidth={widthValueToUse} height="100%" pb={16}>
          <Text>{t("requestSelfPayDescriptionText")}</Text>
          <Box
            background="background-subtle"
            paddingX="v-24"
            paddingY="v-16"
            marginTop="v-16"
            marginBottom={29}
            borderRadius="v-lg"
          >
            <Text mb="v-16">
              <Trans
                t={t}
                i18nKey="requestSelfPayAcknowledgementIntro"
                components={[
                  <Text
                    as="span"
                    size="body-medium-strong"
                    key="requestSelfPayAcknowledgementIntro"
                  />,
                ]}
              />
            </Text>
            <Text>{t("requestSelfPayAcknowledgementText")}</Text>
          </Box>
          {isOver480 && (
            <Button
              variant="high-emphasis"
              size="md"
              width="100%"
              onClick={() => {
                TRACK_EVENT.BUTTON_CLICKED(
                  window.location.pathname,
                  "Save Insurance Info",
                  {
                    location: "Insurance Details Prompt Modal (Self)",
                  },
                );
                handlePaymenDetailsRequestClick();
              }}
              disabled={isMemberInsuranceLoading}
            >
              {t("modal.saveDetailsCtaText")}
            </Button>
          )}
        </Box>
      </Flex>
      {!isOver480 && (
        <Box
          background="white"
          width="100%"
          padding="16px 24px 34px 24px"
          position="fixed"
          bottom={0}
          left={0}
        >
          <Button
            variant="high-emphasis"
            size="md"
            width="100%"
            disabled={isMemberInsuranceLoading}
            onClick={() => {
              TRACK_EVENT.BUTTON_CLICKED(
                window.location.pathname,
                "Save Insurance Info",
                {
                  location: "Insurance Details Prompt Modal (Self)",
                },
              );
              handlePaymenDetailsRequestClick();
            }}
          >
            {t("modal.saveDetailsCtaText")}
          </Button>
        </Box>
      )}
    </>
  );
};
