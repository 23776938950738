//

import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import get from "lodash/fp";
import { InputWrapper, Label, Stout, FlexRow, Bolded } from "@spring/smeargle";
import { setField, setAddressValue } from "@spring/smeargle/actions";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Script from "react-load-script";

import sharedStyles from "components/form/sharedStyles.module.scss";

import styles from "./styles.module.scss";

import { formFieldSelector } from "selectors/form";

class AddressTypeahead extends Component {
  static propTypes = {
    bolded: PropTypes.any,
    country: PropTypes.shape({
      toLowerCase: PropTypes.func,
    }),
    dirty: PropTypes.any,
    disabled: PropTypes.bool,
    disabledTextBox: PropTypes.any,
    fieldKey: PropTypes.string,
    formKey: PropTypes.string,
    full: PropTypes.any,
    inherit: PropTypes.any,
    inline: PropTypes.bool,
    label: PropTypes.shape({
      substr: PropTypes.func,
    }),
    locationBounds: PropTypes.object,
    placeholder: PropTypes.string,
    searchValue: PropTypes.any,
    setField: PropTypes.func,
    showErrors: PropTypes.bool,
    t: PropTypes.func,
    theme: PropTypes.any,
    value: PropTypes.any,
    zip: PropTypes.string,
    clientValidation: PropTypes.any,
  };

  static defaultProps = {
    disabled: false,
    inline: false,
    placeholder: "",
    showErrors: true,
    zip: "",
    locationBounds: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };

    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener("keydown", this.handleKeyPress);
    }
  }

  setBlur = () => {
    this.setState({ blurred: true });
  };

  /**
   * There is a bug when using the arrow keys on the typeahead dropdown and
   * pressing enter, the enter key will instead submit the form instead of
   * choosing the address from the dropdown. This method prevents the enter
   * key from submitting the form and will choose from the list instead.
   */
  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  initAutocomplete = () => {
    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      { fields: ["address_components", "geometry", "icon", "name"] },
    );
    if (this.props.country) {
      this.autocomplete.setOptions({
        componentRestrictions: { country: this.props.country.toLowerCase() },
      });
    }

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(["address_component"]);
    this.autocomplete.addListener("place_changed", this.handleFocus);
    const randomString = (Math.random() + 1).toString(36).substring(7);
    //this is used to disable chrome autofill
    window?.google?.maps?.event.addDomListener(
      this.autocompleteInput?.current,
      "focusin",
      (e) => {
        e.target?.setAttribute("autocomplete", `new-${randomString}`);
      },
    );
  };

  parsePlaceData = (data) => {
    const addressComponents = data?.address_components;
    let city = null;
    let state = null;
    let country = null;
    let street_number = null;
    let route = null;
    let postal_code = null;

    if (addressComponents?.length) {
      addressComponents.forEach((comp) => {
        const addressType = comp.types;

        if (
          addressType.includes("locality") ||
          addressType.includes("sublocality") ||
          (!city && addressType.includes("administrative_area_level_3"))
        ) {
          city = comp.long_name;
        }

        if (addressType.includes("administrative_area_level_1")) {
          state = comp.short_name;
        }

        if (addressType.includes("country")) {
          country = comp.short_name;
        }
        if (addressType.includes("street_number")) {
          street_number = comp.short_name;
        }
        if (addressType.includes("route")) {
          route = comp.short_name;
        }
        if (addressType.includes("postal_code")) {
          postal_code = comp.short_name;
        }
      });
    }

    return {
      city,
      state,
      country,
      street_number,
      route,
      postal_code,
    };
  };

  handleChange = (e) => {
    const target = e.target;

    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      target.value,
      true, // sets dirty
    );
  };

  handleFocus = () => {
    const place = this.autocomplete.getPlace();
    const parsedData = this.parsePlaceData(place);
    let { city, state, country, street_number, route, postal_code } =
      parsedData;

    // hack to not make experience feel so jarring when the autocomplete cuts off the 'A'
    // when you select USA from dropdown options
    if (country === "US") {
      country = "USA";
    }

    // need to concatenate string otherwise we will be showing [object Object] in the input field
    const value = [city, state, country].filter(Boolean).join(", ");
    const homeAddress = [street_number, route].filter(Boolean).join(", ");

    if (this.props.fieldKey === "postal_address.street_address_1") {
      //modified to set value for Home address
      this.props.setField(
        this.props.formKey,
        this.props.fieldKey,
        homeAddress,
        { searchValue: homeAddress, actualValue: parsedData },
        true,
      );
      this.props.setField(
        this.props.formKey,
        "postal_address.city",
        { searchValue: value, actualValue: parsedData },
        true,
      );
      this.props.setField(
        this.props.formKey,
        "postal_address.zip_code",
        postal_code,
        true,
      );
    } else if (this.props.fieldKey === "street_address_1") {
      this.props.setField(
        this.props.formKey,
        this.props.fieldKey,
        homeAddress,
        { searchValue: homeAddress, actualValue: parsedData },
        true,
      );
      this.props.setField(
        this.props.formKey,
        "city",
        { searchValue: value, actualValue: parsedData },
        true,
      );
      this.props.setField(this.props.formKey, "zip_code", postal_code, true);
    } else {
      // this is to set fields and value of state/city province  which was earlier code
      this.props.setField(
        this.props.formKey,
        this.props.fieldKey,
        { searchValue: value, actualValue: parsedData },
        true,
      );
      if (this.props.formKey === "member-address") {
        // to keep it just for member settings edit form. Can be used later for other forms if needed
        if (homeAddress) {
          this.props.setField(
            this.props.formKey,
            "postal_address.street_address_1",
            homeAddress,
            { searchValue: homeAddress, actualValue: parsedData },
            true,
          );
        }
        if (postal_code) {
          this.props.setField(
            this.props.formKey,
            "postal_address.zip_code",
            postal_code,
            true,
          );
        }
      }
    }
  };

  get label() {
    if (this.props.label) {
      // 11-23-2020 Kyle
      // I hate this.  BUT the Chrome team are not good stewards of internet standards
      // This code is required to disable `AutoFill` on chrome, which is the cause
      // of a lot of pain in this component's UX for our members.
      // It works by splitting the label in half, injecting some text, and hidding the
      // injected words.
      // The `visiblity: hidden` and `aria-hidden` attribute _should_ save this from
      // interferrig with Screen Readers

      // 1-14-2021 Added `Date.now` to hopefully have the string be unique every time
      // and throw off Chrome EVEN MORE.
      const l = this.props.label;
      const label = (
        <span>
          {l.substr(0, 2)}
          <span
            aria-hidden="true"
            style={{
              position: "absolute",
              insetInlineStart: -99999,
              visibility: "hidden",
            }}
          >
            {Date.now()} Hack to disable autofill
          </span>
          {l.substr(2)}
        </span>
      );

      return (
        <FlexRow>
          <Label
            disabled={this.props.disabled}
            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            theme={this.props.theme}
            bolded={this.props.bolded}
          >
            {this.props.bolded ? (
              <span className={styles.bolded}>
                <Bolded>{label}</Bolded>
              </span>
            ) : (
              <Stout inherit={this.props.inherit} inline>
                {label}
              </Stout>
            )}
          </Label>
        </FlexRow>
      );
    }

    return null;
  }

  get showValidation() {
    return (
      !!this.props?.clientValidation?.message &&
      (this.props.dirty || this.props.value)
    );
  }

  get errors() {
    if (!this.props.showErrors) {
      return null;
    }

    let message = "";
    const valid = this?.props?.clientValidation?.valid ?? true;

    if (!valid && this.props.dirty && this.state.blurred) {
      message = this.props.t("addressTypeahead.message");
    }

    return (
      <div
        className={classnames(sharedStyles.errorMessage, {
          [sharedStyles.show]: !valid && this.props.dirty,
          [sharedStyles.hide]: valid,

          [sharedStyles[this.props.theme]]: this.props.theme,
        })}
      >
        <Stout uppercase={false} status="error">
          {message}
        </Stout>
      </div>
    );
  }

  get searchValue() {
    if (typeof this.props.searchValue === "string") {
      // if there is a search, use the content of that
      return this.props.searchValue;
    } else if (this.props.value) {
      return this.props.value;
    }

    return "";
  }

  onFocus = () => {
    if (document) {
      document.addEventListener("keydown", this.handleKeyPress);
    }
  };

  render() {
    return (
      <>
        <InputWrapper
          full={this.props.full}
          inline={this.props.inline}
          valid={this.showValidation}
          theme={this.props.theme}
        >
          <input
            id={`${this.props.formKey}-${this.props.fieldKey}`}
            style={{
              opacity:
                this.props.disabledTextBox || this.props.disabled ? 0.3 : 1,
            }} // for 'disabled' opacity
            className={classnames(
              styles.autocomplete,
              sharedStyles.inputField,
              {
                [sharedStyles.full]: this.props.full,
                [sharedStyles.invalid]: this.showValidation,
                [sharedStyles[this.props.theme]]: this.props.theme,
              },
            )}
            placeholder=""
            onChange={this.handleChange}
            disabled={this.props.disabledTextBox}
            onBlur={this.setBlur}
            type="text" // hack to disable chrome autofill
            ref={this.autocompleteInput}
            value={this.searchValue}
            name="search"
            // hack to disable chrome autofill dropdown
            // https://stackoverflow.com/questions/50347574/how-to-disable-chrome-autocomplete-feature
            onFocus={this.onFocus}
            autoComplete="new-password"
          />
          {this.label}
          {this.errors}
        </InputWrapper>
        <Script
          url={`https://maps.google.com/maps/api/js?key=${process.env.GOOGLE_MAPS_KEY}&libraries=places`}
          onLoad={this.initAutocomplete}
        />
      </>
    );
  }
}

export { AddressTypeahead };
export default connect(
  (state, ownProps) => ({
    ...formFieldSelector(state, ownProps),
    searchValue: get(
      `${ownProps.formKey}.data.${ownProps.fieldKey}.searchValue`,
      state.form,
    ),
    actualValue: get(
      `${ownProps.formKey}.data.${ownProps.fieldKey}.actualValue`,
      state.form,
    ),
  }),
  { setField, setAddressValue },
)(withTranslation("limitedLangAuth")(AddressTypeahead));
