"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLoading = exports.isLoaded = exports.hasException = void 0;
var _constants = require("@spring/constants");
var isLoading = exports.isLoading = function isLoading(loadingState) {
  return loadingState === _constants.LoadingStatus.Loading;
};
var isLoaded = exports.isLoaded = function isLoaded(loadingState) {
  return loadingState === _constants.LoadingStatus.Success;
};
var hasException = exports.hasException = function hasException(loadingState) {
  return loadingState === _constants.LoadingStatus.Failed;
};