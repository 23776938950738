import React, { useCallback } from "react";

import { titleCase } from "components/templates/RegisterPage/analytics";
import { Link } from "design-system/components";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";

import { ConsentLinkType } from "./types";

interface ConsentLinkPropsType {
  consentLink: ConsentLinkType;
  currentView: string;
  informedConsentLink: string;
  t: (key: string) => string;
}

const ConsentLink = ({
  consentLink,
  currentView,
  informedConsentLink,
  t,
}: ConsentLinkPropsType) => {
  const { link } = consentLink;
  const { url, name, docId } = link;
  const href = url || informedConsentLink; // IC link URL is null in const, passed in as prop

  const handleClick = useCallback(() => {
    TRACK_EVENT.LINK_CLICKED(routes.Register.as, href, name, {
      location: currentView,
      page_version: titleCase(currentView),
      spring_doc_id: docId,
    });
  }, [currentView, docId, href, name]);

  return (
    <Link
      href={href}
      onClick={handleClick}
      rel="noopener, noreferrer"
      target="_blank"
      textDecoration="underline"
    >
      {t(consentLink.transKey)}
    </Link>
  );
};

export default ConsentLink;
