//

import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import {
  Modal,
  Headline,
  Subtitle,
  Section,
  Grid,
  Col,
  Form,
  Input,
  DateSelect,
  Button,
} from "@spring/smeargle";
import StateSelect from "components/form/StateSelect";
import {
  addNotification,
  closeModal,
  openModal,
} from "@spring/smeargle/actions";
import { modalIds, timeFormats } from "@spring/constants";
import { get, getOr } from "lodash/fp";
import Meowth from "@spring/meowth";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { InsuranceForm } from "components";
import {
  getMemberPrevisit,
  getMemberInfo,
  getMemberAddress,
} from "operations/queries/member";
import {
  updateMemberPrevisit,
  updateMemberInsurance,
  updateMember,
} from "operations/mutations/member";
import beforeYouScheduleSchema from "schemas/beforeYouSchedule";
import { track } from "utils/mixpanel";
import { parseBirthday, isDemoEnvironment } from "utils/memberHelpers";
import { getFirstError } from "utils/apollo/errorHandler";
import { shouldShowStateField, isStateMailingEnabled } from "utils/global";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";

const formKey = "before-you-visit";

const BeforeYouScheduleModal = (props) => {
  const [currentModalId, setCurrentModalId] = useState(
    modalIds.careProviderScheduleModal,
  );
  const [countryShortCode, setCountryShortCode] = useState("us");

  const isDemo = isDemoEnvironment();
  const { setMemberCanSchedule } = useProviderBrowseContext();

  useEffect(() => {
    if (
      props?.modalData?.requestInPersonProvider ||
      props?.modalData?.requestInPerson
    ) {
      setCurrentModalId(modalIds.inPersonAppointmentDetails);
    } else if (props?.modalData?.videoTherapy) {
      setCurrentModalId(modalIds.careProviderScheduleModal);
    }

    const address = getOr(
      {},
      "memberAddress.user.member.postal_address",
      props,
    );
    setCountryShortCode(address?.country ? address.country : "us");
  }, [
    props?.modalData?.requestInPersonProvider,
    props?.modalData?.requestInPerson,
    props?.modalData?.videoTherapy,
  ]);

  const previsit = () => {
    const address = getOr(
      {},
      "memberAddress.user.member.postal_address",
      props,
    );
    const payment_preference = getOr(
      false,
      "formData.data.payment_preference",
      props,
    );
    const dob = get("data.user.member.date_of_birth", props) || "";

    return {
      payment_preference,
      dateOfBirth: { ...parseBirthday(dob) },
      postal_address: {
        street_address_1: address.street_address_1,
        street_address_2: address.street_address_2,
        zip_code: address.zip_code ? address.zip_code : null,
        city: address.city,
        state: address.state,
        country: address.country,
      },
    };
  };

  const SubmitButton = () => {
    const { t, formData } = props;
    return (
      <Button
        dataCy={"scheduleApt"}
        text={t("beforeYouScheduleModal.submitText")}
        onClick={handleSubmit}
        disabled={!formData?.validation?.isValid || validateInsuranceFormData()}
        full
      />
    );
  };

  const validateInsuranceFormData = () => {
    const { showGlobal } = props;

    if (showGlobal || isDemo) {
      return false;
    }
    return !props?.insuranceFormData?.validation?.isValid;
  };

  const handleSubmit = async () => {
    const {
      submit,
      addNotification,
      submitPrevisit,
      closeModal,
      openModal,
      modalData,
      memberInfo,
      submitInsurance,
      formData: { data: formData },
      insuranceFormData: { data: insuranceFormData },
    } = props;
    const streetAddress1 = formData.postal_address.street_address_1;
    const zipCode = formData.postal_address.zip_code;
    const { year, month, day } = formData.dateOfBirth;

    const dateOfBirth = formData.dateOfBirth.actualDate
      ? formData.dateOfBirth.actualDate
      : DateTime.fromObject({ year, month, day }).toFormat(
          timeFormats.datePickerFormat,
        );

    // If edited, these will be populated
    const { city, state, country } = formData;
    const { showGlobal } = props;

    const paymentPreference =
      isDemo || showGlobal ? true : insuranceFormData.payment_preference;

    const { t } = props;

    try {
      let payload = {
        id: get("member.id", memberInfo),
        patch: {
          date_of_birth: dateOfBirth,
          street_address_1: streetAddress1
            ? streetAddress1.trim()
            : streetAddress1,
          zip_code: zipCode,
          payment_preference: paymentPreference ? "OUT_OF_POCKET" : "INSURANCE",
        },
      };

      const policyPayload = {
        member_id: memberInfo.member.id,
        carrier_name: getOr("", "carrier_name", insuranceFormData),
        insurance_member_id: getOr(
          "",
          "insurance_member_id",
          insuranceFormData,
        ),
        insurance_group_id: getOr("", "insurance_group_id", insuranceFormData),
        plan_name: getOr("", "plan_name", insuranceFormData),
      };

      const previsitPayload = {
        member_id: memberInfo.member.id,
        patch: {
          status: "Pending",
          data: JSON.stringify({ dateOfBirth }),
        },
      };

      if (city) {
        payload.patch.city = city;
      }
      if (state) {
        payload.patch.state = state;
      }
      if (country) {
        payload.patch.country = country === "USA" ? "US" : country;
      }

      const [
        updateMemberResponse,
        updateMemberInsurancePolicyResponse,
        updateMemberPrevisitResponse,
      ] = await Promise.all([
        submit(payload),
        !paymentPreference
          ? await submitInsurance(policyPayload)
          : { data: { updateMemberInsurancePolicy: { success: true } } },
        submitPrevisit(previsitPayload),
      ]);

      const {
        data: { updateMember },
      } = updateMemberResponse;
      const {
        data: { updateMemberInsurancePolicy },
      } = updateMemberInsurancePolicyResponse;
      const {
        data: { updateMemberPrevisit },
      } = updateMemberPrevisitResponse;

      if (
        !updateMember.success ||
        updateMemberInsurancePolicy.error ||
        !updateMemberPrevisit.success
      ) {
        return addNotification(
          t("beforeYouScheduleModal.errorNotificationMessage"),
          "error",
        );
      }

      setMemberCanSchedule(true);
      closeModal(modalIds.beforeYouScheduleModal);

      if (currentModalId === modalIds.confirmInPersonAppointment) {
        return openModal(modalIds.confirmInPersonAppointment, modalData);
      } else if (currentModalId === modalIds.inPersonAppointmentDetails) {
        return openModal(modalIds.inPersonAppointmentDetails, modalData);
      }

      track("Previsit, Before You Schedule Submitted");
      addNotification(
        t("beforeYouScheduleModal.successNotificationMessage"),
        "success",
      );

      // TODO: Monkey patch solution to stop this modal from opening the CareProviderScheduleModal
      // ...Should figure out a better way to do this
      if (props.modalData.shouldCloseOnSubmitSuccess === true) {
        return closeModal(modalIds.beforeYouScheduleModal);
      }
      return openModal(modalIds.careProviderScheduleModal, modalData);
    } catch (err) {
      const errorExplanation = get(
        "graphQLErrors[0].problems[0].explanation",
        err,
      );
      errorExplanation
        ? addNotification(errorExplanation, "error")
        : addNotification(getFirstError(err), "error");
    }
  };

  const insuranceSection = () => {
    if (isDemo) {
      return <Section size="lg" />; //Add a reasonable amount of space between the info form and button
    }

    return (
      <Section size="xlg">
        <InsuranceForm />
      </Section>
    );
  };

  const { t, showGlobal } = props;
  const showState = shouldShowStateField(countryShortCode);
  const stateMailingEnabled = isStateMailingEnabled(countryShortCode);

  if (
    get("data.loading", props) ||
    get("memberInfo.loading", props) ||
    get("memberAddress.loading", props)
  ) {
    return null;
  }

  return (
    <Modal
      size="xlg"
      id={modalIds.beforeYouScheduleModal}
      title={t("beforeYouScheduleModal.subtitle")}
    >
      <Headline>
        <h1>{t("beforeYouScheduleModal.headline")}</h1>
      </Headline>
      <Subtitle>
        <h2>{t("beforeYouScheduleModal.subtitle")}</h2>
      </Subtitle>
      <Form
        formKey={formKey}
        theme="simple"
        submitText={t("common:form.submitText")}
        initialData={previsit()}
        schema={beforeYouScheduleSchema}
        preserve
      >
        <Grid>
          <Col sm={12}>
            <DateSelect
              formKey={formKey}
              label={t("beforeYouScheduleModal.dateOfBirth.label")}
              fieldKey="dateOfBirth"
            />
          </Col>

          <Col sm={8}>
            <Input
              fieldKey="postal_address.street_address_1"
              placeholder={t(
                "beforeYouScheduleModal.streetAddress1.placeholder",
              )}
              label={t("beforeYouScheduleModal.streetAddress1.label")}
            />
          </Col>
          <Col sm={4}>
            <Input
              fieldKey="postal_address.street_address_2"
              label={t("beforeYouScheduleModal.streetAddress2.label")}
            />
          </Col>
        </Grid>

        <Grid>
          <Col sm={showState ? 5 : 7}>
            <Input
              fieldKey="postal_address.city"
              placeholder={t("beforeYouScheduleModal.city.placeholder")}
              label={t("beforeYouScheduleModal.city.label")}
            />
          </Col>

          {showState && stateMailingEnabled && (
            <Col sm={3}>
              <StateSelect
                fieldKey="postal_address.state"
                placeholder={t("beforeYouScheduleModal.state.placeholder")}
                label={t("beforeYouScheduleModal.state.label")}
                country={countryShortCode}
              />
            </Col>
          )}

          <Col sm={4}>
            <Input
              fieldKey="postal_address.zip_code"
              placeholder={t("beforeYouScheduleModal.zipCode.placeholder")}
              label={t("beforeYouScheduleModal.zipCode.label")}
            />
          </Col>

          <Col sm={12}>
            <Input
              fieldKey="postal_address.country"
              placeholder={t("beforeYouScheduleModal.country.placeholder")}
              label={t("beforeYouScheduleModal.country.label")}
              disabled
            />
            <p>{t("beforeYouScheduleModal.country.wrongCountry")}</p>
          </Col>
        </Grid>
      </Form>

      {!showGlobal && insuranceSection()}
      <SubmitButton />
    </Modal>
  );
};

BeforeYouScheduleModal.propTypes = {
  addNotification: PropTypes.func,
  closeModal: PropTypes.func,
  formData: PropTypes.any,
  insuranceFormData: PropTypes.any,
  memberInfo: PropTypes.shape({
    member: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  modalData: PropTypes.shape({
    shouldCloseOnSubmitSuccess: PropTypes.bool,
  }),
  openModal: PropTypes.func,
  showGlobal: PropTypes.any,
  submit: PropTypes.func,
  submitInsurance: PropTypes.func,
  submitPrevisit: PropTypes.func,
  t: PropTypes.func,
};

export { BeforeYouScheduleModal };
export default compose(
  connect(
    (state) => ({
      formData: getOr({}, `form.${formKey}`, state),
      insuranceFormData: getOr({}, "form.insurance-policy", state),
      modalData: getOr(
        {},
        `modal.${modalIds.beforeYouScheduleModal}.data`,
        state,
      ),
      showGlobal: getOr({}, "global.showGlobalExperience", state),
    }),
    { addNotification, closeModal, openModal },
  ),
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(getMemberAddress, {
    name: "memberAddress",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(getMemberPrevisit, {
    name: "memberInfo",
    options: ({ data }) => {
      const id = get("user.member.id", data);
      return { variables: { id } };
    },
    skip: (ownProps) => get("data.user.member.id", ownProps) === undefined,
  }),
  graphql(updateMemberInsurance, {
    props: ({ mutate }) => ({
      submitInsurance: (input) =>
        mutate({
          variables: { ...input },
        }),
    }),
  }),
  graphql(updateMember, {
    props: ({ mutate }) => ({
      submit: (input) =>
        mutate({
          variables: { input },
        }),
    }),
  }),
  graphql(updateMemberPrevisit, {
    props: ({ mutate, ownProps }) => ({
      submitPrevisit: (input) => {
        let refetchQueries = ["getMemberInfo"]; // When submitting, we remove the todo

        if (!ownProps.id) {
          // if updating an existing form. use auto-cache update
          refetchQueries.push("getMemberPrevisit");
        }

        return mutate({
          variables: { input },
          refetchQueries,
        });
      },
    }),
  }),
)(withTranslation("careProvider")(BeforeYouScheduleModal));
