import { gql } from "@apollo/client";

import { careTeamMember } from "operations/fragments/careProvider";

const getCareProvider = gql`
  query getCareProvider($id: ID!) {
    care_provider(id: $id) {
      ...careTeamMember
      status
      supported_appointment_kinds
      supported_appointment_mediums
      instructions
    }
  }
  ${careTeamMember}
`;

export default getCareProvider;
