"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _lodash = require("lodash");
var _universalCookie = _interopRequireDefault(require("universal-cookie"));
var _fp = require("lodash/fp");
var buildCookies = function buildCookies() {
  var originAccessToken = "".concat(window.location.hostname.replaceAll(".", "_"), "_access_token");
  var cookieExpiration = new Date();
  cookieExpiration.setFullYear(cookieExpiration.getFullYear() + 1);
  var cookies = new _universalCookie["default"](null, {
    path: "/",
    domain: window.location.host.includes("localhost") ? undefined : ".".concat(window.location.host.split(".").slice(-2).join(".")),
    expires: cookieExpiration
  });
  return {
    originAccessToken: originAccessToken,
    cookies: cookies
  };
};
var Meowth = {
  // Get the uuid from local storage
  getUserId: function getUserId() {
    return typeof window === "undefined" ? null : window.localStorage.getItem("uuid");
  },
  // Get the customer id from local storage
  getCustomerId: function getCustomerId() {
    return typeof window === "undefined" ? null : window.localStorage.getItem("customerId");
  },
  // Set the uuid into local storage
  setUserId: function setUserId(id) {
    window.localStorage.setItem("uuid", id);
  },
  getUserScopes: function getUserScopes() {
    var _window$localStorage$;
    if (typeof window === "undefined") {
      return [];
    }

    // Ensure we always return an array
    var scopes = (_window$localStorage$ = window.localStorage.getItem("scopes")) === null || _window$localStorage$ === void 0 ? void 0 : _window$localStorage$.split(",");
    if (Array.isArray(scopes)) {
      return scopes;
    }
    return [];
  },
  // Saves the needed headers for api requests
  saveHeaders: function saveHeaders(headers, uid) {
    var access_token = headers.access_token,
      refresh_token = headers.refresh_token,
      scopes = headers.scopes,
      token_type = headers.token_type;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("compass_access_token", "\"".concat(access_token, "\""));
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("scopes", scopes);
    localStorage.setItem("token_type", token_type);
    localStorage.setItem("uid", uid);
    var _buildCookies = buildCookies(),
      cookies = _buildCookies.cookies,
      originAccessToken = _buildCookies.originAccessToken;
    cookies.set(originAccessToken, access_token);
  },
  updateHeaders: function updateHeaders(headers) {
    for (var header in headers) {
      localStorage.setItem(header, headers[header]);
    }
  },
  updateTokenExpiration: function updateTokenExpiration(access) {
    localStorage.setItem("access_token_expires_at", access);
  },
  // Clears all headers from local storage
  clearHeaders: function clearHeaders() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token_expires_at");
    localStorage.removeItem("compass_access_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("scopes");
    localStorage.removeItem("uid");
    localStorage.removeItem("uuid");
    var _buildCookies2 = buildCookies(),
      cookies = _buildCookies2.cookies,
      originAccessToken = _buildCookies2.originAccessToken;
    cookies.remove(originAccessToken);
  },
  getAuthorizationHeader: function getAuthorizationHeader() {
    var access_token = localStorage.getItem("access_token");
    var token_type = localStorage.getItem("token_type");
    return {
      Authorization: "".concat(token_type, " ").concat(access_token)
    };
  },
  // Constructs Apollo Options of just the uuid
  // Allows overriding with props on the component
  apolloOptionsUserId: function apolloOptionsUserId() {
    var ownProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (ownProps.userId) {
      return {
        variables: {
          id: ownProps.userId
        }
      };
    }
    return {
      variables: {
        id: Meowth.getUserId() || ""
      }
    };
  },
  apolloMemberIdFromVar: function apolloMemberIdFromVar(_ref) {
    var data = _ref.data;
    var id = (0, _fp.get)("user.member.id", data);
    return {
      variables: {
        id: id
      }
    };
  },
  apolloSkipMemberId: function apolloSkipMemberId(_ref2) {
    var data = _ref2.data;
    return !(0, _fp.get)("user.member.id", data);
  },
  apolloSkipUserId: function apolloSkipUserId() {
    return !Meowth.getUserId();
  },
  // Constructs Apollo Options from keys in local storage.
  apolloOptionsFromLocalStorage: function apolloOptionsFromLocalStorage(keys) {
    if (typeof window === "undefined") {
      return {};
    }
    var options = {};
    keys.forEach(function (k) {
      var value = localStorage.getItem(k);
      if (!(0, _lodash.isNil)(value)) {
        options[k] = value;
      }
    });
    return options;
  }
};
var _default = exports["default"] = Meowth;