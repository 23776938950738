import React from "react";
import { Box } from "design-system";

const FlowerPot = ({ width, height }) => {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        fill="none"
        viewBox="0 0 80 80"
      >
        <g clipPath="url(#clip0_186_7846)">
          <path
            fill="#068262"
            d="M23 59h34v3l-34 2v-5zM40 11a6.003 6.003 0 00-5.659 4L40 19l5.659-4A6.003 6.003 0 0040 11zM44 4a4 4 0 11-8 0 4 4 0 018 0zM53.464 8.68a4 4 0 11-6.928-4 4 4 0 016.928 4zM26.536 8.68a4 4 0 106.928-4 4 4 0 00-6.928 4z"
          ></path>
          <path
            fill="#033C2E"
            fillRule="evenodd"
            d="M58.852 48H21.148l.714 10h36.276l.714-10zm-37.704-2a2 2 0 00-1.995 2.142l.714 10A2 2 0 0021.862 60h36.276a2 2 0 001.995-1.858l.714-10A2 2 0 0058.852 46H21.148z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#033C2E"
            fillRule="evenodd"
            d="M55.81 60H24.19l1.636 18h28.348l1.636-18zm-31.62-2a2 2 0 00-1.992 2.181l1.637 18A2 2 0 0025.826 80h28.348a2 2 0 001.991-1.819l1.637-18A2 2 0 0055.81 58H24.19z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#033C2E"
            fillRule="evenodd"
            d="M47.553 50.384a1 1 0 011.341.447l1.5 3a1 1 0 01-.02.933L48.11 58.84l2.314 5.553a1 1 0 01-.06.888l-1.329 2.28 1.117.957a1 1 0 11-1.302 1.518l-.84-.72-1.145 1.965a1 1 0 01-1.728-1.008l3.25-5.57-2.31-5.541a1 1 0 01.05-.87l2.244-4.04-1.264-2.528a1 1 0 01.447-1.341zM18 79a1 1 0 011-1h42a1 1 0 110 2H19a1 1 0 01-1-1zM40 30a1 1 0 011 1v16a1 1 0 11-2 0V31a1 1 0 011-1z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#033C2E"
            fillRule="evenodd"
            d="M10.159 30.46A1 1 0 0111 30h13.37a11 11 0 019.294 5.118l7.181 11.347A1 1 0 0140 48h-2.25a.997.997 0 01-.252-.032c-4.177-1.088-7.148-3.48-9.064-5.575a20.578 20.578 0 01-2.126-2.757c-.221-.346-.389-.634-.506-.843a139.922 139.922 0 01-1.929-.678 169.138 169.138 0 01-5.14-1.929c-1.887-.747-3.827-1.567-5.364-2.337-.765-.383-1.455-.766-1.995-1.133a5.933 5.933 0 01-.73-.571 2.401 2.401 0 01-.553-.73 1 1 0 01.068-.956zM27.4 37.538l.004.008a5.499 5.499 0 00.131.247c.099.178.25.44.457.763a18.593 18.593 0 001.918 2.486c1.72 1.883 4.336 3.98 7.97 4.957h.304l-6.21-9.813A9 9 0 0024.37 32H14.144l.121.061c1.458.73 3.335 1.526 5.205 2.266a166.742 166.742 0 006.73 2.487l.46.158.12.041.04.014-.32.947.32-.947a1 1 0 01.58.51v.002z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#033C2E"
            fillRule="evenodd"
            d="M69.841 30.46A1 1 0 0069 30H55.63a11 11 0 00-9.294 5.118l-7.181 11.347A1 1 0 0040 48h2.25c.085 0 .17-.01.252-.032 4.177-1.088 7.148-3.48 9.064-5.575a20.578 20.578 0 002.126-2.757c.221-.346.389-.634.506-.843a139.922 139.922 0 001.929-.678c1.386-.496 3.247-1.18 5.14-1.929 1.887-.747 3.827-1.567 5.363-2.337.766-.383 1.456-.766 1.996-1.133.27-.183.52-.374.73-.571.2-.188.417-.432.553-.73a1 1 0 00-.068-.956zM52.6 37.538l-.004.008a5.499 5.499 0 01-.131.247c-.099.178-.25.44-.457.763a18.593 18.593 0 01-1.918 2.486c-1.72 1.883-4.336 3.98-7.97 4.957h-.304l6.21-9.813A9 9 0 0155.63 32h10.225l-.121.061c-1.458.73-3.335 1.526-5.204 2.266a166.838 166.838 0 01-6.73 2.487l-.46.158-.121.041-.04.014.32.947-.32-.947a1 1 0 00-.58.51v.002zM39 19.511l-5.808-3.567a.88.88 0 00-.568-.137c-.121.018-.167.062-.2.113A9.002 9.002 0 0035 28.261a9 9 0 004 1.462V19.51zm2 10.212V19.51l5.808-3.567a.88.88 0 01.568-.137c.121.018.167.062.2.113A9 9 0 0141 29.723zm0 2.01a11 11 0 008.26-16.892c-.75-1.169-2.316-1.328-3.5-.602L40 17.78l-5.76-3.54c-1.184-.726-2.75-.567-3.5.602A11 11 0 0041 31.733z"
            clipRule="evenodd"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_186_7846">
            <path fill="#fff" d="M0 0H80V80H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};

export default FlowerPot;
