import React from "react";
import { useTranslation } from "react-i18next";

import { MemberHelpCard } from "components";
import { SHFlyout } from "design-system/components";

export const HelpFlyout = ({ isOpen, onOpen, onClose }) => {
  const { t } = useTranslation("limitedLangMemberHelpCard");

  return (
    <SHFlyout
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      size="md"
      headerText={t("footerHeader")}
      aria-label={t("footerHeader")}
      isMemberHelpFlyout={true}
    >
      <MemberHelpCard closeFlyout={onClose} />
    </SHFlyout>
  );
};
