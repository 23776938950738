// 

import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { get } from 'lodash/fp';

import { getFirstUpperCase } from '../../../utils/formatting';

import styles from './styles.module.scss';

import { tableDataSelector } from '../../../selectors/table';




const Body = (props) => {
	if (!props.activeKeys) {
		return null;
	}

	const rows = props.data.map((record, i) => {
		const cells = props.activeKeys.map((key) => {
			let value = get(key, record);
			let mutatedValue = null;

			if (props.mutators && props.mutators[key]) {
				// Allows you to mutate the data.
				// First argument is the single value for this cell.
				// The second is the data for the entire row.
				// The row should only be used if you need to reference data
				// in a different cell.
				mutatedValue = props.mutators[key](value, record);
			}

			if (Array.isArray(value)) {
				value = value.join(', ');
			}

			let classes = [];

			for (let breakpoint in props.hideAt) {
				if (props.hideAt[breakpoint].includes(key)) {
					classes.push(styles[`hide-${breakpoint}`]);
				}
			}

			return (
				<td
					key={`${key}-${value}-${i}`}
					className={classnames(classes)}
				>
					&lrm;{getFirstUpperCase(mutatedValue || value)}
				</td>
			);
		});

		return (
			<tr key={i}>
				{cells}
			</tr>
		);
	});

	return (
		<tbody>
			{rows}
		</tbody>
	);
};

Body.defaultProps = {
	hideAt: {},
};

export { Body };
export default connect(
	(state, ownProps ) => tableDataSelector(state, ownProps),
)(Body);
