import { Button } from "@springcare/sh-component-library";
import { Box, Flex } from "@chakra-ui/react";
import { useSSRSafeMediaQuery } from "hooks";

type SubmitAssessmentButtonProps = {
  handleSubmit: () => void;
  loading?: boolean;
  text: string;
};

const ButtonWrapper = ({ children, isMobile }) =>
  isMobile ? (
    <Flex
      justifyContent={["center", "left"]}
      w={"full"}
      backgroundColor={"background-base"}
      position={["fixed", "initial"]}
      bottom={[0, "initial"]}
      left={[0, "initial"]}
    >
      {children}
    </Flex>
  ) : (
    <Box width="50%" my={24}>
      {children}
    </Box>
  );

const SubmitAssessmentButton = ({
  handleSubmit,
  loading,
  text,
}: SubmitAssessmentButtonProps) => {
  const [isMobile] = useSSRSafeMediaQuery("(max-width: 500px)");

  return (
    <ButtonWrapper isMobile={isMobile}>
      <Button
        _focusVisible={{ boxShadow: "0 0 0 3px black" }}
        colorScheme="base"
        data-cy="submit-assessment-button"
        fontWeight={600}
        isLoading={loading}
        onClick={handleSubmit}
        size="md"
        variant="high-emphasis"
        w={["343px", "initial"]}
        m={["24px 0px", "initial"]}
      >
        {text}
      </Button>
    </ButtonWrapper>
  );
};

export default SubmitAssessmentButton;
