import { gql } from "@apollo/client";

const getCompletedInitialAssessment = gql`
  query getCompletedInitialAssessment {
    assessments(status: ["Finished"], kind: INITIAL_ASSESSMENT, limit: 1) {
      data {
        id
        kind
        status
        questionnaires_order
        started_at
        ended_at
        metadata
        triaged
        questionnaires(version: "improved_sud") {
          id
          kind
          information
          responses
          status
          results_sanitized
        }
      }
    }
  }
`;

export default getCompletedInitialAssessment;

// export graphql(getInitialAssessment, {
//  skip    : ({ data }): boolean => !get('user.member.id', data),
//  options : ({ data }): { variables: { id: string } } => {
//    const id = get('user.member.id', data);
//    return { variables: { id } };
//  },
// });
//
// [Started, Requested, Finished, Canceled]
