/**
 * Enums for passing in Appointment Kinds
 * where necessary.
 */
const { ProviderRole } = require('./providerRole');

const AppointmentKind = Object.freeze({
	Therapy                                : 'THERAPY',
	CouplesTherapy                         : 'COUPLES_THERAPY',
	MinorTherapy                           : 'MINOR_THERAPY',
	InitialCareNavigation                  : 'INITIAL_CARE_NAVIGATION',
	FollowUpCareNavigation                 : 'FOLLOW_UP_CARE_NAVIGATION',
	InitialMedicationManagement            : 'INITIAL_MEDICATION_MANAGEMENT',
	FollowUpMedicationManagement           : 'FOLLOW_UP_MEDICATION_MANAGEMENT',
	Coaching                               : 'COACHING',
	WellnessAndSocialSupportSession        : 'WELLNESS_AND_SOCIAL_SUPPORT_SESSION',
	ManagerConsultationAndSupport          : 'MANAGER_CONSULTATION_AND_SUPPORT',
	IndividualSolutionsFocusedConsultation : 'INDIVIDUAL_SOLUTIONS_FOCUSED_CONSULTATION',
	TrainingAndAwarenessEventConsultation  : 'TRAINING_AND_AWARENESS_EVENT_CONSULTATION',
	InitialCoaching                        : 'INITIAL_COACHING',
	FollowUpCoaching                       : 'FOLLOW_UP_COACHING',
	InitialPeerRecoverySpecialist          : 'INITIAL_PEER_RECOVERY',
	FollowUpPeerRecoverySpecialist         : 'FOLLOW_UP_PEER_RECOVERY',
	InitialSpecialtyCareNavigation         : 'INITIAL_SPECIALTY_CARE_NAVIGATION',
	FollowUpSpecialtyCareNavigation        : 'FOLLOW_UP_SPECIALTY_CARE_NAVIGATION',
});

/**
 * Enums which maps a provider role to an appointment kind.
 */
const AppointmentKindByProviderRole = Object.freeze({
	[ProviderRole.Therapist]           	  : AppointmentKind.Therapy,
	[ProviderRole.MedicationManager]   	  : AppointmentKind.InitialMedicationManagement,
	[ProviderRole.Coach]               	  : AppointmentKind.Coaching,
	[ProviderRole.CareAdvocate]        	  : AppointmentKind.WellnessAndSocialSupportSession,
	[ProviderRole.CareConsultant]      	  : AppointmentKind.ManagerConsultationAndSupport,
	[ProviderRole.PeerRecoverySpecialist] : AppointmentKind.FollowUpPeerRecoverySpecialist,
	[ProviderRole.SpecialtyCareNavigator] : AppointmentKind.InitialSpecialtyCareNavigation,
});

/**
 * This is a layer of abstraction to ensure that the name of these appointment kinds
 * that is sent to immutable tracking systems is never changed.
 * The values below should not be modified without lots of communication and consideration.
 * @param {string} modalId
 * @returns {string}
 */
const getAppointmentKindForTracking = (appointmentKindEnum) =>
	({
		[AppointmentKind.Therapy]                                : 'Therapy',
		[AppointmentKind.CouplesTherapy]                         : 'Couples Therapy',
		[AppointmentKind.MinorTherapy]                           : 'Minor Therapy',
		[AppointmentKind.InitialCareNavigation]                  : 'Care Navigation', // These are often used interchangeably
		[AppointmentKind.FollowUpCareNavigation]                 : 'Care Navigation', // and in tracking we can differentiate by event order.
		[AppointmentKind.InitialMedicationManagement]            : 'Medication Management',
		[AppointmentKind.FollowUpMedicationManagement]           : 'Medication Management',
		[AppointmentKind.Coaching]                               : 'Coaching',
		[AppointmentKind.WellnessAndSocialSupportSession]        : 'Wellness and Social Support Session',
		[AppointmentKind.ManagerConsultationAndSupport]          : 'Manager Consultation and Support',
		[AppointmentKind.IndividualSolutionsFocusedConsultation] : 'Individual Solutions Focused Consultation',
		[AppointmentKind.TrainingAndAwarenessEventConsultation]  : 'Training and Awareness Event Consultation',
		[AppointmentKind.InitialPeerRecoverySpecialist]          : 'INITIAL PEER RECOVERY',
		[AppointmentKind.FollowUpPeerRecoverySpecialist]         : 'FOLLOW UP PEER RECOVERY',
	}[appointmentKindEnum]);

/**
 *
 * @param {string} providerRole
 * @returns {string} AppointmentKind enum value
 */
const getAppointmentKindByProviderRole = (providerRole) =>
	AppointmentKindByProviderRole[providerRole];

/**
 *
 * @param {string} AppointmentKind enum value
 * @returns {boolean}
 */
const isTherapy = (kind) =>
	[
		AppointmentKind.Therapy,
		AppointmentKind.CouplesTherapy,
		AppointmentKind.MinorTherapy,
	].includes(kind);

/**
 *
 * @param {string} AppointmentKind enum value
 * @returns {boolean}
 */
const isCareNavigation = (kind) =>
	[
		AppointmentKind.InitialCareNavigation,
		AppointmentKind.FollowUpCareNavigation,
		AppointmentKind.InitialSpecialtyCareNavigation,
		AppointmentKind.FollowUpSpecialtyCareNavigation,
	].includes(kind);

/**
 *
 * @param {string} AppointmentKind enum value
 * @returns {boolean}
 */
const isMedicationManagement = (kind) =>
	[
		AppointmentKind.InitialMedicationManagement,
		AppointmentKind.FollowUpMedicationManagement,
	].includes(kind);

/**
 *
 * @param {string} AppointmentKind enum value
 * @returns {boolean}
 */
const isCoaching = (kind) =>
	[
		AppointmentKind.Coaching,
		AppointmentKind.InitialCoaching,
		AppointmentKind.FollowUpCoaching,
	].includes(kind);

const isCareAdvocateAppointment = (kind) =>
	kind === AppointmentKind.WellnessAndSocialSupportSession;

const isCareConsultantAppointment = (kind) =>
	[
		AppointmentKind.ManagerConsultationAndSupport,
		AppointmentKind.IndividualSolutionsFocusedConsultation,
		AppointmentKind.TrainingAndAwarenessEventConsultation,
	].includes(kind);

const AppointmentAttendedValues = Object.freeze({
	Kept: 'KEPT',
});

module.exports = {
	AppointmentKind,
	AppointmentAttendedValues,
	getAppointmentKindForTracking,
	getAppointmentKindByProviderRole,
	isTherapy,
	isCareNavigation,
	isMedicationManagement,
	isCoaching,
	isCareAdvocateAppointment,
	isCareConsultantAppointment,
};
