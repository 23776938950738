import { Box } from "design-system/components";
import { AddMinorButton } from "components/templates/HomePage/components/FamilySection/AddMinorButton";
import { MinorCard } from "components/templates/HomePage/components/FamilySection/MinorCard";

export const MinorsList = ({ minors }) => (
  <Box
    minW={["100%", "100%", "100%", "650px"]}
    h="100%"
    py={24}
    px={[0, 24]}
    borderWidth={[0, "2px"]}
    borderRadius="12px"
    borderColor="tertiary.light"
  >
    {minors.map((minor) => (
      <MinorCard key={minor.id} minor={minor} />
    ))}
    <AddMinorButton />
  </Box>
);
