import { Box } from "../../index";
import PropTypes from "prop-types";
import { useSSRSafeMediaQuery } from "hooks";

export const SHStickyFooter = (props) => {
  const [isMobileLandscape] = useSSRSafeMediaQuery("(max-height: 500px)");

  return (
    <Box
      pos={isMobileLandscape ? "static" : "fixed"}
      bottom={0}
      left={props.left ? props.left : null}
      w={props.width ? props.width : "100%"}
      h={props.height}
      zIndex="100"
      bg="white"
      boxShadow={props.hasShadow ? "topShadow" : null}
      data-testid={props.testId}
    >
      {props.children}
    </Box>
  );
};

SHStickyFooter.propTypes = {
  height: PropTypes.number || PropTypes.string,
  width: PropTypes.array,
  hasShadow: PropTypes.bool,
  children: PropTypes.any,
  left: PropTypes.string,
  testId: PropTypes.string,
};
