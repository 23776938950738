import { Text, HStack } from "design-system/components";

type SliderRangeLabelProps = {
  label: string;
  testId: string;
};

type SliderRangeLabelsProps = {
  leftLabel: string;
  midLabel?: string;
  rightLabel: string;
};

const SliderRangeLabel = ({ label, testId }: SliderRangeLabelProps) => (
  <Text variant="caption_v1" color="platform.500" data-testid={testId}>
    {label}
  </Text>
);

const SliderRangeLabels = ({
  leftLabel,
  midLabel,
  rightLabel,
}: SliderRangeLabelsProps) => {
  return (
    <HStack width="100%" justifyContent="space-between">
      <SliderRangeLabel label={leftLabel} testId="slider-left-label" />
      {midLabel && (
        <SliderRangeLabel label={midLabel} testId="slider-mid-label" />
      )}
      <SliderRangeLabel label={rightLabel} testId="slider-right-label" />
    </HStack>
  );
};

export default SliderRangeLabels;
