import { ConnectionCheck } from "livekit-client";

export const runConnectionCheck = async (livekit_url: string, jwt: string) => {
  const connectionCheck = new ConnectionCheck(livekit_url, jwt);
  try {
    await connectionCheck.checkWebsocket();
    await connectionCheck.checkWebRTC();
    await connectionCheck.checkTURN();
    await connectionCheck.checkReconnect();
    const allSuccess = connectionCheck.isSuccess();
    return { allSuccess, results: connectionCheck.getResults() };
  } catch (error) {
    return { error: error.message };
  }
};
