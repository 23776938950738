import React from 'react';
import PropTypes from 'prop-types';

const ChatHumanOnCallIcon = ({ width, height }) => (


	<svg width={ width || '24' } height={ height || '25' } viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M-8.8095e-05 10.0164V16C-8.8095e-05 17.7922 0.674732 19.4291 1.78256 20.6669C2.15089 21.0784 2.78309 21.1135 3.19461 20.7451C3.60614 20.3768 3.64116 19.7446 3.27283 19.3331C2.48051 18.4478 1.99991 17.2812 1.99991 16V10.8026C3.07306 10.5124 4.11742 10.0317 5.00969 9.53714C5.70638 9.15099 6.3353 8.74237 6.84366 8.37873C8.5859 10.2344 12.342 12.7116 17.9999 12.9767V16C17.9999 18.7614 15.7613 21 12.9999 21H12.7323C12.3865 20.4022 11.7402 20 10.9999 20H8.99991C7.89534 20 6.99991 20.8954 6.99991 22C6.99991 23.1046 7.89534 24 8.99991 24H10.9999C11.7402 24 12.3865 23.5978 12.7323 23H12.9999C16.8659 23 19.9999 19.866 19.9999 16V10C19.9999 4.47715 15.5228 0 9.99991 0C4.4837 0 0.010672 4.4664 -6.87113e-05 9.9801C-0.000295403 9.99218 -0.000302959 10.0043 -8.8095e-05 10.0164ZM17.9999 10.9743V10C17.9999 5.58172 14.4182 2 9.99991 2C6.03334 2 2.74101 4.8868 2.10929 8.67406C2.76274 8.44254 3.42125 8.13089 4.04013 7.78786C5.05976 7.22272 5.90777 6.60841 6.35973 6.23178C6.57468 6.05265 6.85484 5.97178 7.13219 6.00879C7.40953 6.0458 7.65869 6.19731 7.81914 6.42654C8.74652 7.75136 12.1402 10.6705 17.9999 10.9743Z" fill="#916010"/>
		<path d="M7.68271 15.2694C7.27921 14.8923 6.6464 14.9137 6.26931 15.3172C5.89221 15.7207 5.91361 16.3535 6.31711 16.7306C7.19407 17.5502 8.56646 18 9.99991 18C11.4334 18 12.8058 17.5502 13.6827 16.7306C14.0862 16.3535 14.1076 15.7207 13.7305 15.3172C13.3534 14.9137 12.7206 14.8923 12.3171 15.2694C11.9042 15.6553 11.0708 16 9.99991 16C8.929 16 8.09566 15.6553 7.68271 15.2694Z" fill="#916010"/>
	</svg>

);

ChatHumanOnCallIcon.propTypes = {
	color  : PropTypes.string,
	width  : PropTypes.string,
	height : PropTypes.string,
};

export default ChatHumanOnCallIcon;