import PropTypes from 'prop-types';

export const EmotionPropType = PropTypes.shape({
	emotion  : PropTypes.string,
	position : PropTypes.number,
});

export const CategoryPropType = PropTypes.shape({
	category: PropTypes.string,
});

export const ImagePropType = PropTypes.shape({
	url     : PropTypes.string,
	context : PropTypes.string,
});

export const ElementPropType = PropTypes.shape({
	type      : PropTypes.string,
	content   : PropTypes.string,
	media_uri : PropTypes.string,
});

export const ExerciseStepPropType = PropTypes.shape({
	id             : PropTypes.string,
	exercise_id    : PropTypes.string,
	position       : PropTypes.number,
	title          : PropTypes.string,
	content_format : PropTypes.string,
	final_step     : PropTypes.bool,
	elements       : PropTypes.arrayOf(ElementPropType),
});

export const ExercisesPropType = PropTypes.shape({
	id              : PropTypes.string,
	title           : PropTypes.string,
	completed       : PropTypes.bool,
	duration        : PropTypes.string,
	activity_type   : PropTypes.string,
	total_steps     : PropTypes.number,
	emotions        : PropTypes.arrayOf(EmotionPropType),
	categories      : PropTypes.arrayOf(CategoryPropType),
	content_formats : PropTypes.arrayOf(PropTypes.string),
	images          : PropTypes.arrayOf(ImagePropType),
	starting_step   : ExerciseStepPropType,
});

export const IconAriaLabelsPropType = PropTypes.shape({
	book       : PropTypes.string,
	headphones : PropTypes.string,
});
