import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Badge, Button } from "@springcare/sh-component-library";
import { VWorkplaceIcon } from "@springcare/verdant-icons-react";
import { useRouter } from "next/router";
import { useTranslation } from "hooks/react-i18next";
import {
  getContentFormat,
  getEyeBrowText,
} from "modules/MemberDashboard/ManagerExperience/Workplace/components/BuildNewSkills/utils/helper";
import { ContentCard } from "./ContentCard";
import { WorkplaceVideo } from "modules/shared/graphql-codegen/graphql";
import routes from "routes/MemberDashboardRoutes";
import { useEffect, useRef, useState } from "react";
import { useInViewportOnce } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";

type WorkplaceContentForExperiment6Props = {
  videoData: Array<WorkplaceVideo>;
  displayInRowAtMobileWidth?: boolean;
  paddingT?: string[];
  paddingB?: string[];
  headingSize?: string;
};

// This component is temporary code for Manager Experience Experiment #6: https://springhealth.atlassian.net/browse/MXE-830
// It will be removed once the experiment is complete
export const WorkplaceContentForExperiment6 = ({
  videoData,
  displayInRowAtMobileWidth = false,
  paddingT = ["v-none"],
  paddingB = ["v-52", null, "v-none"],
  headingSize,
}: WorkplaceContentForExperiment6Props) => {
  const { t } = useTranslation("managerExperience", {
    keyPrefix: "workplaceContentExperiment6",
  });

  const MANAGER_CONTENT_CARD_WIDTH = displayInRowAtMobileWidth
    ? ["305px", null, null, "100%"]
    : ["100%"];

  const exploreButtonPosition = useBreakpointValue(["bottom", null, "heading"]);
  const router = useRouter();

  const ref = useRef();
  const inViewport = useInViewportOnce(ref);
  const [viewedOnce, setViewedOnce] = useState(false);

  useEffect(() => {
    if (inViewport && !viewedOnce && videoData?.length) {
      const videosDisplayed = videoData.map((video) => ({
        id: video.id,
        title: video.title,
      }));

      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Workplace content section",
        {
          spring_doc_id: "MGXX6_001",
          video_cards_displayed: videosDisplayed,
        },
      );

      setViewedOnce(true);
    }
  }, [inViewport, viewedOnce, videoData]);

  // Heading size for Homepage
  const defaultHeadingSize = useBreakpointValue([
    "heading-medium",
    null,
    "heading-large",
  ]);

  const handleVideoClick = (videoId, videoTitle) => {
    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      routes.SageVideo.as.replace(":id", videoId),
      "Workplace Video Card",
      {
        content_type: "Leader Course",
        content_id: videoId,
        content_name: videoTitle,
        location: "Workplace content section",
        spring_doc_id: "MGXX6_002",
      },
    );

    goToWorkplaceVideoByID(router, videoId);
  };

  const handleExploreWorkplaceButtonClick = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      routes.WorkplaceManager.as,
      "Explore workplace button",
      {
        spring_doc_id: "MGXX6_004",
        location: "Workplace content section",
      },
    );

    router.push(routes.WorkplaceManager.as);
  };

  return (
    <Box pt={paddingT} pb={paddingB} ref={ref}>
      <VStack spacing="v-4" align="start" w="100%">
        <HStack w="100%" justifyContent="space-between">
          <VStack>
            <HStack spacing="v-8" w="100%">
              <VWorkplaceIcon
                role="img"
                aria-hidden="true"
                width="24px"
                height="24px"
              />
              <Heading size={headingSize || defaultHeadingSize}>
                {t("heading")}
              </Heading>
              <Badge>{t("featured")}</Badge>
            </HStack>
            <Box>
              <Text mb="v-20">{t("subheading")}</Text>
            </Box>
          </VStack>
          {exploreButtonPosition === "heading" && (
            <ExploreWorkplaceButton
              handleClick={handleExploreWorkplaceButtonClick}
            />
          )}
        </HStack>

        <Flex
          gap="v-24"
          w="100%"
          pb="v-12"
          direction={
            displayInRowAtMobileWidth ? ["row"] : ["column", "column", "row"]
          }
          overflowX="auto"
        >
          {videoData?.map((video) => (
            <VStack alignItems="start" w="100%" key={video.id}>
              <ContentCard
                imageSrc={video.image_url}
                imageAltText={video.title}
                contentType={getContentFormat(video.content_type)}
                eyebrowText={getEyeBrowText(video.content_type)}
                title={video.title}
                time={video.duration_in_minutes}
                ariaLabel={video.title}
                handleClick={() => handleVideoClick(video.id, video.title)}
                cardWidthSize={MANAGER_CONTENT_CARD_WIDTH}
              />
            </VStack>
          ))}
        </Flex>
        {exploreButtonPosition === "bottom" && (
          <ExploreWorkplaceButton
            isFullWidth
            handleClick={handleExploreWorkplaceButtonClick}
          />
        )}
      </VStack>
    </Box>
  );
};

const ExploreWorkplaceButton = ({ isFullWidth = false, handleClick }) => {
  const { t } = useTranslation("managerExperience", {
    keyPrefix: "workplaceContentExperiment6",
  });

  return (
    <Button
      variant="medium-emphasis"
      width={isFullWidth ? "100%" : "auto"}
      mt="v-16"
      onClick={handleClick}
      role="link"
    >
      {t("exploreWorkplaceButtonText")}
    </Button>
  );
};

const goToWorkplaceVideoByID = (router, id?: string) => {
  if (!id) {
    return null;
  }

  const BASE_URL = `/members/workplace/video/sage`;
  const URL = `${BASE_URL}/${id}`;
  const as = URL;

  router.push({ pathname: URL, query: { referrer: router.asPath } }, as);
};
