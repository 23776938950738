import React, { Component } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import {
  Form,
  FlexCol,
  CheckboxGroup,
  Subtitle,
  Section,
} from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import { get, getOr } from "lodash/fp";
import Meowth from "@spring/meowth";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

import { getGoals } from "operations/queries/goal";
import { getMemberGoals } from "operations/queries/member";
import { updateMemberGoals } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";
import { track } from "utils/mixpanel";
class MemberGoalForm extends Component {
  get options() {
    const goals = getOr([], "data.goals.data", this.props);

    return goals.map((g) => {
      return {
        label: g.description,
        value: g.id,
      };
    });
  }

  handleSubmit = (data) => {
    const userId = get("props.memberGoals.user.id", this);

    const payload = {
      variables: {
        input: {
          goal_ids: data.goals,
        },
      },
      refetchQueries: [
        {
          query: getMemberGoals,
          variables: {
            id: userId,
          },
        },
      ],
    };

    this.props
      .updateMemberGoals(payload)
      .then((res) => {
        if (!res.data.updateMemberGoals.success) {
          this.props.addNotification(getFirstError(res.errors), "error");
        } else {
          this.props.onSubmit();
        }
      })
      .catch((err) => {
        this.props.addNotification(getFirstError(err), "error");
      });
  };

  get initialData() {
    const memberGoals = getOr(
      [],
      "props.memberGoals.user.member.tracked_goals",
      this,
    );

    return memberGoals.map((memberGoal) => memberGoal.goal.id);
  }

  get actions() {
    let actions = [];
    const { t } = this.props;
    if (this.props.onSkip) {
      const onClick = () => {
        track("Goals -- Initial Assessment Skipped");
        this.props.onSkip();
      };

      actions.push({
        reverse: "true",
        color: "success",
        text: t("memberGoalForm.successText"),
        onClick,
        buttonType: "LinkButton",
      });
    }

    return actions;
  }

  render() {
    if (this.props.data.loading || this.props.memberGoals.loading) {
      return null;
    }

    const { t } = this.props;

    return (
      <Form
        preserve
        formKey="memberGoalForm"
        theme="simple"
        onSubmit={this.handleSubmit}
        submitIcon="arrow-right"
        submitText={t("memberGoalForm.submitText")}
        initialData={{ goals: this.initialData }}
        actions={this.actions}
        dataCy="initial-assessment-goals-form"
      >
        <fieldset>
          <Section size="lg">
            <FlexCol justification="center" alignment="center">
              <h1 className={styles.goalQuestionHeader}>
                {t("memberGoalForm.subhead")}
              </h1>
              <Section size="md">
                <legend>
                  <Subtitle noMargin>{t("memberGoalForm.subtitle")}</Subtitle>
                </legend>
              </Section>
            </FlexCol>
            <div className={styles.questions}>
              <CheckboxGroup
                fieldKey="goals"
                options={this.options}
                dataCy="initial-assessment-goals"
                border
                column
              />
            </div>
          </Section>
        </fieldset>
      </Form>
    );
  }
}

MemberGoalForm.propTypes = {
  addNotification: PropTypes.func,
  data: PropTypes.shape({
    loading: PropTypes.any,
  }),
  memberGoals: PropTypes.shape({
    loading: PropTypes.any,
  }),
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
  updateMemberGoals: PropTypes.func,
};

export { MemberGoalForm };

export default compose(
  graphql(getGoals),
  graphql(getMemberGoals, {
    name: "memberGoals",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(updateMemberGoals, { name: "updateMemberGoals" }),
  connect(null, { addNotification }),
)(withTranslation("goals")(MemberGoalForm));
