/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  Center,
  Heading,
  Box,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { LandingPageWrapper } from "components";
import routes from "routes";
import Snorlax from "utils/snorlax";

export const ChooseUserPageLayout = ({ subtitle, children, header }) => {
  const allowed = useSelector((state) =>
    Snorlax(state)
      .loggedIn.to(routes.SignIn)
      .isMember(routes.Logout)
      .isNestedMinor(),
  );
  const textAlignLeft = useBreakpointValue([true, false, false]);

  return (
    <LandingPageWrapper darkBg={false} full allowed={() => allowed}>
      <Box role="main" maxWidth="1400px" margin="auto">
        <Box aria-label={header} data-cy="choose-user-header">
          {textAlignLeft ? (
            <Box>
              <Heading as="h1" size="heading-medium" ml={20}>
                {header}
              </Heading>
            </Box>
          ) : (
            <Center>
              <Heading
                as="h1"
                size="heading-large"
                alignSelf="stretch"
                mt={100}
              >
                {header}
              </Heading>
            </Center>
          )}
        </Box>

        {textAlignLeft ? (
          <Box ml="20px" mt={[8, 8, 100]}>
            <Text size="body-medium-regular">{subtitle}</Text>
          </Box>
        ) : (
          <Center>
            <Text size="body-medium-regular">{subtitle}</Text>
          </Center>
        )}
        <Box mt={[24, 40]} />
        {children}
      </Box>
    </LandingPageWrapper>
  );
};

ChooseUserPageLayout.propTypes = {
  children: PropTypes.any,
  name: PropTypes.any,
  subtitle: PropTypes.any,
};
