//

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { SpringTextLogo } from "design-system";

import styles from "./styles.module.scss";

const LoadingPage = (props) => (
  <div
    className={classnames(styles.loading, {
      [styles.out]: props.out,
      [styles.hidden]: props.hidden,
    })}
    aria-busy="true"
    aria-live="polite"
    aria-label="loading"
  >
    <div className={styles.logoWrapper}>
      <SpringTextLogo
        componentFrom={
          props.componentFrom
            ? `LoadingPage-${props.componentFrom}`
            : "LoadingPage"
        }
      />
    </div>
  </div>
);

LoadingPage.propTypes = {
  hidden: PropTypes.any,
  out: PropTypes.any,
  componentFrom: PropTypes.string,
};

export default LoadingPage;
