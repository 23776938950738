import { gql } from "@apollo/client";

import paging from "operations/fragments/paging";

const getAppointmentsWithProviderDetails = gql`
  query getAppointmentsWithProviderDetails(
    $status: String
    $kind: [String]
    $paid: String
    $booking_name: String
    $booking_user_id: ID
    $starting_after: Date
    $starting_before: Date
    $limit: Int = 30
    $offset: Int
    $sort_by: [SortType] = [
      { column: "appointments.start_at", direction: "desc" }
    ]
    $member_has_customer: ID
    $attended: AppointmentAttendedEnumType
  ) {
    appointments(
      status: $status
      kind: $kind
      paid: $paid
      booking_name: $booking_name
      booking_user_id: $booking_user_id
      starting_after: $starting_after
      starting_before: $starting_before
      limit: $limit
      offset: $offset
      sort_by: $sort_by
      member_has_customer: $member_has_customer
      attended: $attended
    ) {
      data {
        id
        attended
        cancelled_for_member
        duration
        start_at
        end_at
        kind
        medium
        status
        location
        reason_for_scheduling
        provider {
          acuity_calendar_id
          avatar
          bio
          care_provider_tags {
            id
            kind
            name
          }
          city
          coordinates {
            latitude
            longitude
          }
          distance_kilometers
          distance_miles
          email
          first_name
          gender
          id
          in_person_supported
          instructions
          languages
          last_name
          licensed_countries
          licenses
          name
          on_site
          phone
          roles
          scheduling_url
          state
          states
          status
          street_address_1
          street_address_2
          supported_appointment_kinds
          supported_appointment_mediums
          tags
          user_id
          virtual_supported
          zip_code
        }
        cohort_name
        bookings {
          id
          name
          role
          avatar
        }
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default getAppointmentsWithProviderDetails;
