import { gql } from "@apollo/client";

const createPotentialCoveredLife = gql`
  mutation createPotentialCoveredLife($input: PotentialCoveredLifeInput!) {
    createPotentialCoveredLife(input: $input) {
      success
    }
  }
`;

export default createPotentialCoveredLife;
