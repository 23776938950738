"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toTagOptions = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _constants = require("@spring/constants");
/**
 * Gets tag options from an array of tags
 *
 * @name toTagOptions
 * @param {Array} careProviderTags
 * @param {Array} careProviderTagKindEnum

 * @returns {Array} Returns a new array with the tag options.
 *
 * @example
 * const arr = [1, 2, 3];
 * const newArr =  push(4, arr);
 * const options = [
 *   { id: '1', kind: 'CONDITION', name: 'foo' },
 *   { id: '2', kind: 'CONDITION', name: 'foobar' },
 *   { id: '3', kind: 'SPECIALTY', name: 'bar' },
 *   { id: '4', kind: 'SPECIALTY', name: 'barbar' },
 *   { id: '5', kind: 'TREATMENT_MODALITY', name: 'bazbaz' },
 *   { id: '6', kind: 'TREATMENT_MODALITY', name: 'baz' },
 * ];
 *
 * const opts = toTagOptions(options, CareProviderTagKind.Condition);
 * console.log(opts); // [ { label: 'foo', value: '1' }, { label: 'foobar', value: '2' }]
 */

var toTagOptions = exports.toTagOptions = function toTagOptions(careProviderTags, careProviderTagKindEnum) {
  var isFixedTagId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var copy = (0, _toConsumableArray2["default"])(careProviderTags);
  var filteredTags = copy.filter(function (tag) {
    return tag.kind === careProviderTagKindEnum && !tag.name.match(/^unknown$/i);
  });
  if (careProviderTagKindEnum === _constants.CareProviderTagKind.Language) {
    return filteredTags.map(function (tag) {
      return {
        label: tag.name,
        value: tag.english_name
      };
    });
  }
  if (isFixedTagId.length === 0) {
    return filteredTags.map(function (tag) {
      return {
        label: tag.name,
        value: tag.id
      };
    });
  }
  return filteredTags.map(function (tag) {
    return {
      label: tag.name,
      value: tag.id,
      isFixed: tag.id === isFixedTagId
    };
  });
};