import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { DateTime } from "luxon";
import { graphql } from "@apollo/client/react/hoc";
import { get } from "lodash/fp";
import {
  modalIds,
  AppointmentKind,
  AppointmentMedium,
  getModalNameForTracking,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  timeFormats,
} from "@spring/constants";
import Meowth from "@spring/meowth";
import { Button, Card } from "@springcare/sh-component-library";
import { Box, Text } from "@chakra-ui/react";
import { VArrowRightIcon } from "@springcare/verdant-icons-react";
import { withTranslation } from "react-i18next";
import Router from "next/router";

import {
  CareProviderScheduleModal,
  ChangeCareProviderModal,
} from "components/modals";

import styles from "./styles.module.scss";

import { getAppointments } from "operations/queries/appointment";
import { getMemberGoalProgress } from "operations/queries/member";
import { getCareTeam } from "operations/queries/careProvider";
import {
  EVENT_TYPE,
  makeEventString,
  track,
  TRACK_EVENT,
} from "utils/mixpanel";
import { BaseLineChart, ScreenReaderOnly } from "modules/shared";

const TREND_DIRECTION = {
  IMPROVEMENT: "Improvement",
  REGRESSION: "Regression",
  NEUTRAL: "Neutral",
};

class ProgressTrackingGraph extends Component {
  static propTypes = {
    memberGoalProgress: PropTypes.shape({
      loading: PropTypes.any,
    }),
    t: PropTypes.func,
  };

  get graph() {
    const series = get(
      "memberGoalProgress.member_goal_progress.data.series",
      this.props,
    );
    const questionnaires = get(
      "memberGoalProgress.member_goal_progress.goal.questionnaires",
      this.props,
    );
    const goalDescription = get(
      "memberGoalProgress.member_goal_progress.goal.description",
      this.props,
    );
    const { t } = this.props;

    if (!series) {
      return null;
    }

    // Nivo draws the graph in reverse order, so we need to reverse the series to ensure correct taborder
    let data = [
      {
        id: "journey",
        data:
          series.length === 0
            ? [{ y: 0, x: 0 }]
            : series
                .map((d, index) => ({ y: d.value, x: index + 1 }))
                .reverse(),
      },
    ];

    let maxY = 40;

    if (questionnaires.includes("GAD7") || questionnaires.includes("GAD2")) {
      maxY = 21;
    }

    if (questionnaires.includes("PHQ9") || questionnaires.includes("PHQ2")) {
      maxY = 27;
    }

    if (questionnaires.includes("AUDIT") || questionnaires.includes("AUDITC")) {
      maxY = 40;
    }

    return (
      <Box height={440} my={32}>
        <BaseLineChart
          ariaLabel={t("a11y:progressGraphLabel", { goal: goalDescription })}
          ariaDescribedby="your-journey-leadin"
          curve="monotoneX"
          data={data}
          margin={{ top: 16, right: 16, bottom: 56, left: 56 }}
          xScale={{
            type: "linear",
            min: "auto",
            max: Math.max(series.length, 9),
          }}
          yScale={{
            type: "linear",
            min: "auto",
            max: maxY,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t("progressTracking.xAxisLabel"),
            legendOffset: 48,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t("progressTracking.yourScore"),
            legendOffset: -48,
            legendPosition: "middle",
          }}
          lineWidth={5}
          pointSize={16}
          pointColor={"#fff"}
          pointBorderWidth={5}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          tooltip={(props) => (
            <Card size="sm" variant="low-emphasis">
              <Text>
                {t("progressTracking.tooltipText")}: <b>{props.point.data.y}</b>
              </Text>
            </Card>
          )}
        />
      </Box>
    );
  }

  get leadinText() {
    const { t } = this.props;
    const progressTrendDirection = get(
      "memberGoalProgress.member_goal_progress.trend.direction",
      this.props,
    );
    const series = get(
      "memberGoalProgress.member_goal_progress.data.series",
      this.props,
    );

    if (series.length < 2) return t("progressTracking.startLeadin");

    switch (progressTrendDirection) {
      case TREND_DIRECTION.IMPROVEMENT:
        return t("progressTracking.improvementLeadin");
      case TREND_DIRECTION.REGRESSION:
        return t("progressTracking.regressionLeadin");
      case TREND_DIRECTION.NEUTRAL:
        return t("progressTracking.neutralLeadin");
      default:
        return "";
    }
  }

  get showDeltaText() {
    const { t } = this.props;
    const series = get(
      "memberGoalProgress.member_goal_progress.data.series",
      this.props,
    );
    const appointments = get("appointments.appointments.data", this.props);

    const description = t("progressTracking.description");

    if (!series) {
      return null;
    }

    return (
      <>
        <Text fontSize={20} mb={16} id={"your-journey-leadin"}>
          <ScreenReaderOnly>
            {t("a11y:progressGraphValues", {
              values: series.map((d) => d.value).join(", "),
            })}
          </ScreenReaderOnly>
          {this.leadinText} {t("progressTracking.scoreExplanation")}
        </Text>
        <Box>
          <Text mb={24} id={"your-journey-description"}>
            {description}
          </Text>
          <Button
            isFullWidth
            rightIcon={<VArrowRightIcon />}
            onClick={() => {
              track("Your Journey -- Schedule Appointment Clicked", {
                deprecated: true,
                replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
                  page: window.location.pathname,
                  type: "Schedule Care Navigation",
                  to: getModalNameForTracking(
                    modalIds.careProviderScheduleModal,
                  ),
                  appointment_type: getAppointmentKindForTracking(
                    AppointmentKind.InitialCareNavigation,
                  ),
                  appointment_medium: getAppointmentMediumForTracking(
                    AppointmentMedium.Phone,
                  ),
                }),
              });
              track("Your Journey -- Schedule Care Navigation Clicked", {
                deprecated: true,
                spring_doc_id: "cnsched008",
                page: window.location.pathname,
                to: getModalNameForTracking(modalIds.careProviderScheduleModal),
                appointment_type: getAppointmentKindForTracking(
                  AppointmentKind.InitialCareNavigation,
                ),
                appointment_medium: getAppointmentMediumForTracking(
                  AppointmentMedium.Phone,
                ),
              });
              if (appointments.length === 0) {
                TRACK_EVENT.BUTTON_CLICKED(
                  window.location.pathname,
                  "Browse Therapists",
                  {
                    to: "/browse/therapists",
                  },
                );
                Router.push("/browse/therapists");
              } else {
                TRACK_EVENT.BUTTON_CLICKED(
                  window.location.pathname,
                  "Book appointment with provider",
                  {
                    to: "/members/care_visits",
                  },
                );
                Router.push("/members/care_visits");
              }
            }}
          >
            {t("progressTracking.scheduleAppointment")}
          </Button>
          <CareProviderScheduleModal />
          <ChangeCareProviderModal />
        </Box>
      </>
    );
  }

  get trackerSection() {
    return (
      <>
        <div>{this.graph}</div>
        <div>{this.showDeltaText}</div>
      </>
    );
  }

  render() {
    if (this.props.memberGoalProgress.loading) {
      return null;
    }

    return (
      <section className={styles.graphWrapper}>{this.trackerSection}</section>
    );
  }
}

export default compose(
  graphql(getMemberGoalProgress, {
    name: "memberGoalProgress",
    options: (props) => {
      return { variables: { id: props.memberGoalId } };
    },
  }),
  graphql(getCareTeam, {
    options: Meowth.apolloOptionsUserId,
    name: "careTeam",
  }),
  graphql(getAppointments, {
    name: "appointments",
    options: {
      booking_user_id: Meowth.getUserId(),
      starting_after: DateTime.local().toFormat(timeFormats.datePickerFormat),
    },
  }),
)(withTranslation(["journey", "a11y"])(ProgressTrackingGraph));
