import routes from "routes/index";

export const getT2MembersSupportedRoutes = (
  hasAccessToManagerExperience,
  routerQueryObj,
) => {
  if (hasAccessToManagerExperience) {
    const { id: sageVideoId } = routerQueryObj;
    return [
      routes.MemberHome.as,
      routes.MemberBenefits.as,
      routes.MemberSettings.as,
      routes.MemberMoments.as,
      routes.SignIn.as,
      routes.Register.as,
      routes.WorkplaceManager.as,
      routes.WorkplaceGeneralConsultations.as,
      routes.WorkplaceCriticalIncidents.as,
      routes.WorkplaceCrisisSupport.as,
      routes.AccidentsInTheWorkplace.as,
      routes.ManageTraumaticEvents.as,
      routes.SupportingTeamWithChange.as,
      routes.ManagingViolenceInWorkplace.as,
      routes.ManagingTeamsThroughLoss.as,
      `/members/workplace/video/sage/${sageVideoId}`,
    ];
  } else {
    return [
      routes.MemberHome.as,
      routes.MemberBenefits.as,
      routes.MemberSettings.as,
      routes.MemberMoments.as,
      routes.SignIn.as,
      routes.Register.as,
    ];
  }
};
