import { isMobileOnly } from "react-device-detect";

import { useTrackToggle } from "@livekit/components-react";
import { Track } from "livekit-client";

import { Start, Center, End } from "./VideoCallControls/Group";
import { ToggleButton } from "./VideoCallControls/ToggleButton";
import { DeviceMenuChevron } from "./VideoCallControls/DeviceMenuChevron";
import { ExitButton } from "./VideoCallControls/ExitButton";
import { AdmitParticipantPopover } from "./VideoCallControls/AdmitParticipantPopover";
import {
  ToggleButtonTypes,
  DeviceTypes,
  ParticipantType,
} from "components/templates/SHSessionRoom/types";
import { Flex } from "@chakra-ui/react";
import {
  useParticipantMetadata,
  useSessionRoomDrawer,
  useSessionDevices,
  useSessionRecording,
} from "context/SessionRoomContext";
import { RecordingBadge } from "./RecordingBadge";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const DesktopControls = () => {
  const { participantType } = useParticipantMetadata();
  const { sessionDevices } = useSessionDevices();
  const { recordingEligible } = useSessionRecording();
  const enableRecordingsBadge =
    useFeatureFlag(FLAGS.ENABLE_CONSENTS_WAITING_ROOM_LIVEKIT) &&
    sessionDevices?.stream;
  const enableFaqButton =
    enableRecordingsBadge &&
    recordingEligible &&
    participantType === ParticipantType.Provider;

  const { buttonProps: microphoneButtonProps } = useTrackToggle({
    source: Track.Source.Microphone,
  });
  const { buttonProps: cameraButtonProps } = useTrackToggle({
    source: Track.Source.Camera,
  });

  return (
    <>
      <Start>
        <ToggleButton
          type={ToggleButtonTypes.Microphone}
          trackToggleButtonProps={microphoneButtonProps}
        />
        <DeviceMenuChevron
          types={[DeviceTypes.AudioInput, DeviceTypes.AudioOutput]}
        />
        <ToggleButton
          type={ToggleButtonTypes.Camera}
          trackToggleButtonProps={cameraButtonProps}
        />
        <DeviceMenuChevron types={[DeviceTypes.VideoInput]} />
        {enableRecordingsBadge && recordingEligible && <RecordingBadge />}
      </Start>
      <Center>
        <ToggleButton type={ToggleButtonTypes.Participants} />
        <ToggleButton type={ToggleButtonTypes.Chat} />
        <ToggleButton type={ToggleButtonTypes.Screensharing} />
        {enableFaqButton && <ToggleButton type={ToggleButtonTypes.Faq} />}
      </Center>
      <End>
        <ExitButton />
      </End>
    </>
  );
};

const MobileControls = () => {
  const { buttonProps: microphoneButtonProps } = useTrackToggle({
    source: Track.Source.Microphone,
  });
  const { buttonProps: cameraButtonProps } = useTrackToggle({
    source: Track.Source.Camera,
  });

  return (
    <Center>
      <ExitButton />
      <ToggleButton
        type={ToggleButtonTypes.Microphone}
        trackToggleButtonProps={microphoneButtonProps}
      />
      <ToggleButton
        type={ToggleButtonTypes.Camera}
        trackToggleButtonProps={cameraButtonProps}
      />
      <ToggleButton type={ToggleButtonTypes.Participants} />
      <ToggleButton type={ToggleButtonTypes.Chat} />
    </Center>
  );
};

export const VideoCallControls = () => {
  const { participantType } = useParticipantMetadata();
  const { isSessionRoomDrawerOpen, drawerWidth } = useSessionRoomDrawer();
  const DEFAULT_MAX_WIDTH = `calc(100vw - ${
    isSessionRoomDrawerOpen ? 30 : 48
  }px)`;
  const DEFAULT_MAX_HEIGHT = "90vh";
  const DEFAULT_MIN_WIDTH = `calc(100vw - 48px)`;

  const aspectRatio = 16 / 9;
  const width = `calc(${DEFAULT_MAX_HEIGHT} * ${aspectRatio})`;
  const maxWidth = `calc(${DEFAULT_MAX_WIDTH} - ${
    isSessionRoomDrawerOpen ? drawerWidth : 0
  }px)`;

  return (
    <Flex
      justifyContent="center"
      width={width}
      maxW={maxWidth}
      minW={{ base: DEFAULT_MIN_WIDTH, md: "auto" }}
      direction="column"
      display={isMobileOnly && isSessionRoomDrawerOpen && "none"}
      position={isMobileOnly ? "fixed" : undefined}
      bottom={isMobileOnly && 8}
      left={isMobileOnly && 0}
      right={isMobileOnly && 0}
    >
      {participantType === ParticipantType.Provider && (
        <AdmitParticipantPopover />
      )}
      <Flex
        position="relative"
        justifyContent={isMobileOnly ? "center" : "space-between"}
        width={isMobileOnly ? "100vw" : "100%"}
      >
        {isMobileOnly ? <MobileControls /> : <DesktopControls />}
      </Flex>
    </Flex>
  );
};

export default VideoCallControls;
