import { FilterChip } from "@springcare/sh-component-library";
import { Flex, List, ListItem, Text } from "@chakra-ui/react";
import { VChevronDownIcon } from "@springcare/verdant-icons-react";
import { useSelect } from "downshift";
import styles from "./styles.module.scss";
import { useTranslation } from "hooks/react-i18next";

type MobileDropdownMenuProps = {
  memberGoals: { [key: string]: any };
  handleClick: (data: any) => void;
};

export const MobileDropdownMenu = ({
  memberGoals,
  handleClick,
}: MobileDropdownMenuProps) => {
  const { t } = useTranslation("a11y");
  const goalNamesAndIds = memberGoals?.map((memberGoal) => {
    return {
      name: memberGoal?.goal?.description,
      id: memberGoal?.id,
    };
  });

  const {
    isOpen,
    highlightedIndex,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: goalNamesAndIds,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      handleClick(newSelectedItem);
    },
  });

  function selected_or_default(goal) {
    return selectedItem
      ? JSON.stringify(goal) == JSON.stringify(selectedItem)
      : goal;
  }

  return (
    <Flex flexDirection="column" position="relative">
      <FilterChip
        className={styles.filterChip}
        colorScheme="nuetral"
        {...getToggleButtonProps()}
        aria-label={t("a11y:selectGoal")}
      >
        <Flex>
          <Text whiteSpace="normal" noOfLines={1}>
            {goalNamesAndIds?.find(selected_or_default)?.name}
          </Text>
        </Flex>
        <VChevronDownIcon className={styles.downIcon} />
      </FilterChip>
      <List
        className={styles.goalsList}
        border={isOpen ? "1px solid #a9a9a9" : ""}
        padding={isOpen ? "2" : ""}
        {...getMenuProps()}
      >
        {isOpen
          ? goalNamesAndIds?.map(
              (item, index) =>
                item?.name && (
                  <ListItem
                    padding="4px"
                    borderRadius="3px"
                    key={`goal_${index}`}
                    bgColor={
                      index === highlightedIndex ||
                      goalNamesAndIds?.find(selected_or_default)?.name ===
                        item?.name
                        ? "hover-base-low-emphasis"
                        : undefined
                    }
                    {...getItemProps({ item, index })}
                  >
                    {item?.name}
                  </ListItem>
                ),
            )
          : null}
      </List>
    </Flex>
  );
};
