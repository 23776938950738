//

import { SET_PROMPT_VALIDATION, SET_FORM_FIELD } from "./types";

/**
 *
 * Actions.
 *
 */

export function setPromptValidation(id, valid) {
  return {
    type: SET_PROMPT_VALIDATION,
    id,
    valid,
  };
}

export function setField(formKey, fieldKey, value, fieldType = "") {
  return (dispatch) => {
    dispatch({
      type: SET_FORM_FIELD,
      dirty: true,
      formKey,
      fieldKey,
      fieldType,
      value,
    });
  };
}
