import React from "react";

const FiltersIcon = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.816 1.969a1 1 0 0 0-2 0v4.83a3.85 3.85 0 0 0 0 7.434v8.628a1 1 0 1 0 2 0v-8.627a3.85 3.85 0 0 0 0-7.436v-4.83Zm-.989 10.396a1.849 1.849 0 1 0-.02 0h.02Zm8.588-10.396a1 1 0 1 0-2 0v11.476a3.85 3.85 0 0 0 0 7.436v1.98a1 1 0 0 0 2 0v-1.98a3.85 3.85 0 0 0 0-7.436V1.97Zm-2.85 15.194c0-1.017.822-1.842 1.837-1.849h.026a1.849 1.849 0 1 1-1.862 1.85ZM20.016.97a1 1 0 0 1 1 1v1.98a3.85 3.85 0 0 1 0 7.435v11.477a1 1 0 1 1-2 0V11.385a3.85 3.85 0 0 1 0-7.437v-1.98a1 1 0 0 1 1-1Zm-.01 4.849a1.85 1.85 0 1 0 .019 3.698 1.85 1.85 0 0 0-.02-3.698Z"
      fill="#4B4B4B"
    />
  </svg>
);

export default FiltersIcon;
