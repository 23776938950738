import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  VisuallyHidden,
} from "design-system";
import { DateTime } from "luxon";
import { Link } from "components/atoms";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { TRACK_EVENT } from "utils/mixpanel";
import { getIsUniqueCCACopy } from "utils/employerServicesHelpers";
import { Hide, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import { useMemberInfo } from "hooks";
import camelCase from "lodash/camelCase";

import { AppointmentKind } from "@spring/constants";
import { PastAppointmentStatus } from "./PastAppointmentStatus";

const getProvider = (bookings) => {
  const users = bookings
    .filter((person) => person.role !== "Member")
    .map((provider) => provider.name)
    .join(", ");

  return users || "N/A";
};

const getKindMedium = (row, t, customerId) => {
  const isUniqueCCACopy = getIsUniqueCCACopy(customerId);

  // Target wants overrides to the wording of CCA appointments
  if (
    isUniqueCCACopy &&
    row.kind === AppointmentKind.WellnessAndSocialSupportSession
  ) {
    return t(`appointmentsTable.wellnessAndSocialSupport.${customerId}`);
  } else if (row.kind === AppointmentKind.WellnessAndSocialSupportSession) {
    return t(`appointmentsTable.wellnessAndSocialSupport.default`);
  }

  if (row.kind === AppointmentKind.FollowUpPeerRecoverySpecialist) {
    return t("appointmentsTable.peerRecoverySupport");
  }

  if (row.kind === AppointmentKind.InitialPeerRecoverySpecialist) {
    return t("appointmentsTable.initialPeerRecoverySupport");
  }

  if (!row.kind.toLowerCase().includes("therapy")) {
    return t(`appointmentsTable.${camelCase(row.kind)}`);
  }

  const medium = row.medium.toLowerCase().includes("person")
    ? t("appointmentsTable.inPerson")
    : t("appointmentsTable.virtual");
  return `${t(`appointmentsTable.${camelCase(row.kind)}`)} - ${medium}`;
};

const AppointmentsTable = ({
  appointmentsData,
  openModal,
  actionType,
  isGlobalMemberWithNoVisitsRemaining,
  getLazyNextAppointmentSlot,
  getLazySlotRequestVars,
  onScheduleModalOpen,
}) => {
  const { t } = useTranslation(["careVisits"]);
  const DEFAULT_TABLE_LENGTH = 3;
  const [showFullTable, setShowFullTable] = useState(false);

  const isPastApptTable = actionType === "button";
  const isUpcomingApptTable = actionType === "link";

  const parsedTableData = showFullTable
    ? appointmentsData
    : appointmentsData.slice(0, DEFAULT_TABLE_LENGTH);
  const shouldRenderMoreLessButton =
    isPastApptTable && appointmentsData?.length > DEFAULT_TABLE_LENGTH;

  const viewMoreLink = useBreakpointValue([
    t("appointmentsTable.view"),
    t("appointmentsTable.view"),
    t("appointmentsTable.viewSession"),
  ]);
  const formattedAppointmentTime = useBreakpointValue([
    "LLL dd ",
    "LLL dd, yyyy",
  ]);

  const anchorTag = !isPastApptTable ? "PastAppointmentsTableAnchorTag" : "";
  const { data: memberInfoData } = useMemberInfo();
  const customerId = memberInfoData?.user?.member?.cohort?.customer?.id;

  const [isSmallerScreen] = useMediaQuery("(max-width: 650px)");

  function handleExpandCollapseTable() {
    if (!showFullTable) {
      TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "View More");
    }
    if (document.getElementById(anchorTag)) {
      document.getElementById(anchorTag).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setShowFullTable(!showFullTable);
  }

  return (
    <>
      <Table size={isSmallerScreen ? "sm" : "md"} variant="unstyled" my={5}>
        <Thead fontWeight={500} color="platform.900">
          <Tr>
            <Td pl={0} pr={2} minW={{ base: "0px", sm: "150px" }}>
              {t("appointmentsTable.date")}
            </Td>
            <Hide below="lg">
              <Td pl={0} pr={2} minW={120} w={150}>
                {t("appointmentsTable.time")}
              </Td>
              <Td pl={0} pr={2}>
                {t("appointmentsTable.kind")}
              </Td>
            </Hide>
            <Td pl={0} pr={2} minW={170}>
              {t("appointmentsTable.provider")}
            </Td>
            <Td pl={0} pr={2}>
              <VisuallyHidden>{t("apptTableStatusColHead")}</VisuallyHidden>
            </Td>
          </Tr>
        </Thead>

        <Tbody id={anchorTag}>
          {parsedTableData.map((appointmentDetails) => {
            const appointmentId = appointmentDetails.id;
            const apptDateTime = DateTime.fromISO(appointmentDetails.start_at);
            return (
              <Tr
                color="platform.800"
                borderBottom="1px solid #EFEDE8"
                height={50}
                key={appointmentId}
                data-cy={`appointment-row-${appointmentId}`}
              >
                <Td pl={0} pr={[0, 2, 2, 2]} minW={[75, ""]}>
                  {apptDateTime.toLocal().toFormat(formattedAppointmentTime)}
                </Td>
                <Hide below="lg">
                  <Td pl={0} pr={2}>
                    {apptDateTime.toLocal().toFormat("h:mm a ZZZZ")}
                  </Td>
                  <Td pl={0} pr={2}>
                    {getKindMedium(appointmentDetails, t, customerId)}
                  </Td>
                </Hide>
                <Td pl={0} pr={2}>
                  <Text maxWidth={isSmallerScreen ? "162px" : ""}>
                    {getProvider(appointmentDetails.bookings)}
                  </Text>
                </Td>
                {isUpcomingApptTable && (
                  <Td pl={0} pr={2} fontWeight={500}>
                    {appointmentDetails.status === "Cancelled" ? (
                      <Text color={"platform.600"}>
                        {t("appointmentsTable.cancelled")}
                      </Text>
                    ) : (
                      <Link
                        dataCy="view-appointment"
                        ariaLabel={t("a11y:viewAppointment", {
                          provider_name: getProvider(
                            appointmentDetails.bookings,
                          ),
                          appt_time: apptDateTime.toLocaleString(
                            DateTime.DATETIME_SHORT,
                          ),
                        })}
                        alias="MemberAppointmentDetail"
                        queries={{ id: appointmentId }}
                        mpTracking={{
                          page: window.location.pathname,
                          type: "View Appointment Details Link",
                          props: { appointment_id: appointmentId },
                        }}
                      >
                        {viewMoreLink}
                      </Link>
                    )}
                  </Td>
                )}

                {isPastApptTable && (
                  <Td pl={0} pr={2}>
                    <PastAppointmentStatus
                      appointmentRow={appointmentDetails}
                      isGlobalMemberWithNoVisitsRemaining={
                        isGlobalMemberWithNoVisitsRemaining
                      }
                      onScheduleModalOpen={onScheduleModalOpen}
                      openModal={openModal}
                      getLazyNextAppointmentSlot={getLazyNextAppointmentSlot}
                      getLazySlotRequestVars={getLazySlotRequestVars}
                    />
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {shouldRenderMoreLessButton && (
        <Box color="primary.500" position="absolute" right={0}>
          <Button
            variant="button"
            border={0}
            onClick={handleExpandCollapseTable}
          >
            {showFullTable
              ? t("pastAppointments.viewLess")
              : t("pastAppointments.viewMore")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default AppointmentsTable;
