// 
import { findIndex } from 'lodash/fp';
import { setIn, push, reject } from '@spring/immutability';

import {
	ADD_NOTIFICATION, DISMISS_NOTIFICATION, MOUNT_NOTIFICATION, REMOVE_NOTIFICATION,
} from '../../actions/notifications/types';


export const initialState = [];

const notifications = (state = [], action) => {
	switch (action.type) {
		case ADD_NOTIFICATION:
			return push({
				text      : action.text,
				color     : action.color,
				timestamp : action.timestamp,
			}, state);

		case DISMISS_NOTIFICATION:
			return setIn(
				[findIndex({ timestamp: action.timestamp }, state), 'out'],
				true,
				state
			);

		case MOUNT_NOTIFICATION:
			return setIn(
				[findIndex({ timestamp: action.timestamp }, state), 'mounted'],
				true,
				state
			);

		case REMOVE_NOTIFICATION:
			return reject({ timestamp: action.timestamp }, state);

		default:
			return state;
	}
};

export default notifications;

