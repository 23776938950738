//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";
import { formOptions } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "GAD7.q1": numberField(),
};
const prompt2Schema = {
  "GAD7.q2": numberField(),
};
const prompt3Schema = {
  "GAD7.q3": numberField(),
};
const prompt4Schema = {
  "GAD7.q4": numberField(),
};
const prompt5Schema = {
  "GAD7.q5": numberField(),
};
const prompt6Schema = {
  "GAD7.q6": numberField(),
};
const prompt7Schema = {
  "GAD7.q7": numberField(),
};

export const GAD7Schema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...prompt7Schema,
};

const { HowManyDays } = formOptions;

const GAD7 = [
  {
    id: "GAD7--1",
    title:
      "Over the last two weeks, how often have you been bothered by feeling **nervous, anxious, or on edge**?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="GAD7.q1"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "GAD7--2",
    title:
      "Over the last two weeks, how often have you been bothered by **not being able to stop or control worrying**?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="GAD7.q2"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "GAD7--3",
    title:
      "Over the last two weeks, how often have you been bothered by **worrying too much** about different things?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="GAD7.q3"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "GAD7--4",
    title:
      "Over the last two weeks, how often have you been bothered by **having trouble relaxing**?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="GAD7.q4"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "GAD7--5",
    title:
      "Over the last two weeks, how often have you been bothered by being **so restless that it is hard to sit still**?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="GAD7.q5"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "GAD7--6",
    title:
      "Over the last two weeks, how often have you been bothered by becoming **easily annoyed or irritable**?",
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <RadioButtons
        key={6}
        border={true}
        fieldKey="GAD7.q6"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "GAD7--7",
    title:
      "Over the last two weeks, how often have you been bothered by feeling **afraid as if something awful might happen**?",
    schema: yup.object().shape(prompt7Schema),
    questions: [
      <RadioButtons
        key={7}
        border={true}
        fieldKey="GAD7.q7"
        options={HowManyDays}
      />,
    ],
  },
];

export default GAD7;
