import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, SHInputWithLoadingState } from "design-system/components";
import { SHPasswordInput } from "design-system/components/custom/forms/inputs/SHPasswordInput";
import { passwordValidation, emailValidation } from "schemas/hookFormSchema";

type ResetPasswordProps = {
  email: string;
  onSubmit: (data: any) => void;
};

const ResetPasswordForm = (props: ResetPasswordProps) => {
  const { t } = useTranslation("limitedLangAuth");
  const email = props.email;

  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      email,
      password: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form>
        <SHInputWithLoadingState
          fcMb={16}
          required
          pl={14}
          fontWeight={400}
          register={register}
          ariaLabel={t("emailLabel")}
          label={t("emailLabel")}
          name="email"
          disabled={email ? true : false}
          placeholder={email ? email : ""}
          errors={errors?.email}
          validation={emailValidation}
        />
        <SHPasswordInput
          fcMb={16}
          required
          pl={14}
          fontWeight={400}
          register={register}
          ariaLabel={t("resetPassword.newPasswordLabel")}
          label={t("resetPassword.newPasswordLabel")}
          name="password"
          type="password"
          t={t}
          validation={passwordValidation}
          errors={errors?.password}
        />
        <Button
          mt={16}
          colorScheme="primary"
          type="submit"
          data-cy="find-my-benefit-button"
          h={56}
          onClick={handleSubmit((data) => props.onSubmit(data))}
          variant="solid"
          w="100%"
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          isDisabled={!isValid || !isDirty}
        >
          {t("common:form.submitText")}
        </Button>
      </form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
