import React, { FC } from "react";
import { useTranslation } from "hooks/react-i18next";
import { Button } from "@springcare/sh-component-library";
import { useBreakpointValue } from "@chakra-ui/react";
import routes from "routes";
import type { TakeAssessmentHeroBannerProps } from "./types";
import { PhotoCard } from "shared/components/PhotoCard";
import { TEEN_HOME_BANNER_TYPES } from "modules/MemberDashboard/Teen/Home/components/FindTherapistHeroBanner/constants";
import { TRACK_EVENT } from "utils/mixpanel";
import { routeToConfirmationPage } from "components/molecules/CareProviderScheduleContent/CareProviderScheduleContent.utils";

const Cta = ({ ctaText, appointmentId }) => {
  return (
    <Button
      alignSelf="flex-start"
      onClick={() => {
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "Take Assessment",
          {
            location: "Teen home spotlight",
            to: routes.TeenAssessment.as,
            spring_doc_id: "teenhp012",
          },
        );
        const routeTeenToInitialAssessment = true;
        routeToConfirmationPage(routeTeenToInitialAssessment, appointmentId);
      }}
    >
      {ctaText}
    </Button>
  );
};

/**
 * This component renders a PhotoCard banner. It will check the following details:
 * It renders a CTA button that will take users to the teen assessment page.
 */

export const TakeAssessmentHeroBanner: FC<
  React.PropsWithChildren<TakeAssessmentHeroBannerProps>
> = ({ appointmentId }) => {
  const { t } = useTranslation("teenHomepage");
  const BANNER = TEEN_HOME_BANNER_TYPES.take_teen_assessment;
  const photoGradient = useBreakpointValue([
    BANNER.photoGradientMobile,
    BANNER.photoGradientMobile,
    BANNER.photoGradient,
    BANNER.photoGradient,
  ]);

  return (
    <PhotoCard
      altText={t("takeAssessmentBanner.altText")}
      imgUrl={BANNER.imgUrl}
      heading={t("takeAssessmentBanner.heading")}
      description={t("takeAssessmentBanner.description")}
      photoGradient={photoGradient}
      cta={
        <Cta
          ctaText={t("takeAssessmentBanner.cta")}
          appointmentId={appointmentId}
        />
      }
    />
  );
};
