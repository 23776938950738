import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Content } from '../../typography';

import styles from './styles.module.scss';

const Tab = (props) => {
	/**
	 * Internal handleClick that calls onClick from props,
	 * which passes tab index to parent to set the activeTab.
	 */
	const handleClick = (event) => {
		const { tracking, onClick, index } = props;
		if (tracking) {
			tracking();
		}
		onClick(index, event);
	};

	const getLabel = () => {
		if (props.icon) {

			return (
				<div className={styles.labelWithIcon}>
					<div className={styles.icon}>{props.icon}</div>
					<div className={styles.label}>{props.label}</div>
				</div>
			);

		}

		return props.label;
	};

	return (
		<button
			style={{ width: props.width }}
			className={classnames(styles.tabListItem, {
				[styles.active]      : props.active,
				[styles.transparent] : props.transparent,
			})}
			onClick={(event) => handleClick(event)}
			data-cy={props.dataCy ? props.dataCy + '-tab' : ''}
			type="button"
			role="tab"
			tabIndex={!props.active ? '-1' : '0'}
			id={`tab-${props.panelId || ''}${props.index+1}`}
			aria-controls={`tabpanel-${props.panelId || ''}${props.index+1}`}
			aria-selected={props.active}
			onKeyDown={(e) => {
				if (e.code === 'Enter') { handleClick(e); }
				if (e.code === 'ArrowLeft' || e.code === 'ArrowRight' || e.code === 'Home' || e.code === 'End') { 
					props.handleTabSelected(props.index, e);
				}
			}}
		>
			<Content center>
				{getLabel()}
			</Content>
		</button>
	);
};

Tab.propTypes = {
	active      : PropTypes.bool,
	index       : PropTypes.number,
	label       : PropTypes.string,
	onClick     : PropTypes.func,
	width       : PropTypes.string,
	tracking    : PropTypes.func,
	transparent : PropTypes.bool,
	icon        : PropTypes.any,
	dataCy      : PropTypes.string,
	panelId     : PropTypes.string,

};

export default Tab;
