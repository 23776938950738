import { withTranslation } from "react-i18next";

import { formatMiles, formatKilometers } from "utils/displayHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import routes from "routes";

import { Box, Text } from "design-system/components";
import { Link } from "components/atoms";
import { OpenInNewTab } from "design-system/assets";
import { useTranslation } from "react-i18next";

type ProviderOfficeLocationProps = {
  provider: {
    city: string;
    distance_kilometers: number;
    distance_miles: number;
    state: string;
    street_address_1: string;
    street_address_2: string;
    zip_code: number;
  };
  showGlobalExperience: boolean;
};

const ProviderOfficeLocation = ({
  provider,
  showGlobalExperience,
}: ProviderOfficeLocationProps) => {
  const { t } = useTranslation(["displayHelper"]);

  const { street_address_1, street_address_2, city, state, zip_code } =
    provider;
  const address = [street_address_1, street_address_2, city, state]
    .filter(Boolean)
    .join(", ");
  const full_street_address = street_address_2
    ? `${street_address_1} ${street_address_2}`
    : street_address_1;
  const enableInPersonGlobal = useFeatureFlag(FLAGS.IN_PERSON_GLOBAL);
  const distanceResult =
    enableInPersonGlobal && showGlobalExperience
      ? formatKilometers(provider.distance_kilometers, false)
      : formatMiles(provider.distance_miles, false);

  const AddressLink = () => (
    <div onClick={(event) => event.stopPropagation()}>
      <Link
        to={"https://www.google.com/maps/search/"}
        mpTracking={{
          page: routes.TherapistsBrowse.as,
          type: "Provider Address",
          location: "Provider Profile",
          spring_doc_id: "directsched002",
        }}
        queries={{
          api: 1,
          query: `${full_street_address},${state},${city},${zip_code}`,
        }}
      >
        <Text color="platform.600" as="u">
          {address} {zip_code}
        </Text>
      </Link>
      &nbsp;
    </div>
  );

  return (
    <Box pt={0} pb={10}>
      <Text variant="bodyBold_v1">
        {distanceResult.distance +
          " " +
          t(`displayHelper:${distanceResult.distanceUnit}`) +
          " " +
          t("away")}
      </Text>
      <AddressLink />
      <OpenInNewTab boxSize={16} />
    </Box>
  );
};

export { ProviderOfficeLocation };
export default withTranslation("careProvider")(ProviderOfficeLocation);
