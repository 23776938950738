import React from 'react';

const BrowseProvidersCurveSVG = () => (
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1553 229" aria-hidden="true">
		<path d="M1552.27 106.226V.194H.273v228H41.72c41.264-2.496 82.841-7.057 124.126-12.313 55.448-7.12 110.585-15.782 165.035-24.337 9.134-1.434 18.248-2.866 27.342-4.287 47.967-7.505 95.711-13.348 142.783-18.568 47.079-5.194 93.336-9.865 138.737-14.43 32.535-3.292 65.048-4.638 96.386-2.678 1.166.088 2.328.172 3.488.255l.007.001c2.728.196 5.447.393 8.199.635l11.731 1.166 23.408 2.431c12.052 1.245 24.022 2.718 35.978 4.189l.021.003c3.517.432 7.032.865 10.548 1.291 22.434 2.794 44.735 5.799 66.978 8.795 39.066 5.264 77.952 10.503 117.054 14.532 65.35 6.711 133.21 10.129 202.97 6.52 69.82-3.879 141.05-15.982 210.75-34.288 1.31-.32 2.62-.65 3.92-.979l.02-.003c1.3-.328 2.61-.656 3.9-.975 1.67-.429 3.34-.938 5.02-1.451.97-.295 1.94-.592 2.92-.876 4.93-1.482 9.9-2.974 14.87-4.466l1.16-.348c3.48-1.177 6.96-2.428 10.46-3.684l.1-.034 2.58-.926c.94-.336 1.88-.671 2.82-1.004a672.47 672.47 0 0 0 4.01-1.432c1.34-.481 2.68-.961 4.02-1.435l7.88-3.252c1.78-.75 3.58-1.491 5.37-2.234 3.5-1.446 7-2.895 10.47-4.408 2.92-1.394 5.84-2.779 8.77-4.167l.18-.086c1.34-.637 2.68-1.275 4.03-1.914.85-.402 1.69-.805 2.54-1.208 4.46-2.019 8.71-4.323 12.95-6.628l1.01-.547 1.26-.684 7.53-4.064c2.47-1.296 4.88-2.632 7.22-4.08Z" fill="#FAF9F6" />
	</svg>
);

export default BrowseProvidersCurveSVG;

