import { useEffect } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";

export function withLazyQuery(query, { options }, cleanup) {
  return function hoc(Component) {
    return function LazyQueryWrappedComponent({ ...props }) {
      const client = useApolloClient();
      // useLazyQuery gives us a handle to call manually.
      // it also seems to avoid the cache bug in graphql().
      const [getData, { data, loading }] = useLazyQuery(query);

      let fetchOptions = {};
      if (typeof options === "function") {
        fetchOptions = { ...options(props) };
      }

      // do an initial fetch
      useEffect(() => {
        getData({ ...fetchOptions });

        if (typeof cleanup === "function") {
          return () => cleanup(client); // allow for clearing of cache keys on wrapper unmount.
        }
      }, []);

      return (
        <Component
          {...props}
          data={{
            ...data,
            loading, // this is on data in graphql(), next to it for useLazyQuery
            fetchMore: ({ variables }) => {
              // this is just to match the signature of graphql()
              getData({
                ...fetchOptions,
                variables: {
                  ...fetchOptions.variables,
                  ...variables,
                },
              });
            },
          }}
        />
      );
    };
  };
}
