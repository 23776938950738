import { gql } from "@apollo/client";

const postalAddress = gql`
  fragment postalAddress on PostalAddress {
    street_address_1
    street_address_2
    city
    state
    zip_code
    country
    country_display_name
  }
`;

export default postalAddress;
