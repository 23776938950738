import React from 'react';

import Icon from '../Icon';

const MomentsContentIcons = ({ types = [], bookIconProps, headphonesIconProps }) => {
	const icons = types.map((type) => {
		if (type === 'TEXT') {
			return <Icon key="book" type="book-open-variant" iconProps={bookIconProps} />;
		}

		if (type === 'AUDIO') {
			return <Icon key="headphones" type="headphones" iconProps={headphonesIconProps} />;
		}

		return null;
	});

	return icons.length ? icons : null;
};

export default MomentsContentIcons;
