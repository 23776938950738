import { useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import SparkMD5 from "spark-md5";
import { datadogAddAction } from "lib/datadog-setup";

import {
  createDocumentUploadPresignedUrl,
  createDocumentUploadPresignedStatus,
} from "operations/mutations/insurance";

const addMemberDocUploadDataDogAction = (
  upload_document_start_time: number,
  file: File,
) => {
  datadogAddAction("member_document_uploaded", {
    time: Date.now() - upload_document_start_time,
    fileType: file?.type,
  });
};

export const useDocumentUpload = (userId, setDocumentUrlObjectKey) => {
  const [createPresignedUrlMutation] = useMutation(
    createDocumentUploadPresignedUrl,
  );
  const [createDocumentUploadPresignedStatusMutation] = useMutation(
    createDocumentUploadPresignedStatus,
  );
  const [insuranceCardIsLoading, setInsuranceCardIsLoading] = useState(false);
  const [uploadStatus, setFileStatus] = useState(null);
  const isMounted = useRef(true);
  const timeoutId = useRef(null);

  useEffect(() => {
    // Cleanup function to be called when the component unmounts
    return () => {
      isMounted.current = false;
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, []);

  const reader = new FileReader();

  const setUploadFailed = () => {
    setFileStatus("failed");
    setInsuranceCardIsLoading(false);
  };

  const getS3UploadResponse = async ({
    presignedDocumentUploadUrl,
    file,
    presignedDocumentTaggingHeader,
    base64Md5Hash,
  }) => {
    try {
      if (
        !presignedDocumentUploadUrl ||
        !file ||
        !presignedDocumentTaggingHeader ||
        !base64Md5Hash
      )
        throw new Error("Missing required params");
      return await fetch(presignedDocumentUploadUrl, {
        method: "PUT",
        body: file,
        headers: {
          "x-amz-tagging": presignedDocumentTaggingHeader,
          "Content-MD5": base64Md5Hash,
        },
      });
    } catch (_err) {
      setUploadFailed();
    }
  };

  const getBase64Md5Hash = () => {
    const spark = new SparkMD5.ArrayBuffer();
    const fileReaderResult = reader.result as ArrayBuffer;
    spark.append(fileReaderResult);
    const fileHexHash = spark.end();
    const base64Md5 = Buffer.from(fileHexHash, "hex").toString("base64");
    return base64Md5;
  };

  const getPresignedUploadUrl = async (fileName, base64Md5) => {
    try {
      if (!fileName || !base64Md5) throw new Error("Missing required params");
      return await createPresignedUrlMutation({
        variables: {
          input: {
            id: userId,
            file_name: fileName,
            md5: base64Md5,
          },
        },
      });
    } catch (_err) {
      setUploadFailed();
    }
  };

  const uploadFileToS3 = async (file, presignedUrlResponse, base64Md5) => {
    try {
      if (!file || !presignedUrlResponse || !base64Md5)
        throw new Error("Missing required params");
      const uploadResponse = await getS3UploadResponse({
        presignedDocumentUploadUrl:
          presignedUrlResponse.data.create_document_upload_presigned_url.url,
        file: file,
        presignedDocumentTaggingHeader:
          presignedUrlResponse.data.create_document_upload_presigned_url
            .tagging_header,
        base64Md5Hash: base64Md5,
      });
      if (uploadResponse.status !== 200) throw new Error("Upload failed");
      return uploadResponse;
    } catch (_error) {
      setUploadFailed();
    }
  };

  const getStatusPresignedUrl = async (objectKey) => {
    try {
      if (!objectKey) throw new Error("Missing required params");
      return await createDocumentUploadPresignedStatusMutation({
        variables: {
          input: {
            id: userId,
            object_key: objectKey,
          },
        },
      });
    } catch (_error) {
      setUploadFailed();
    }
  };

  const getRawFileStatus = async (presignedStatusResponse) => {
    try {
      if (!presignedStatusResponse) throw new Error("Missing required params");
      const s3DocumentStatus = await fetch(
        presignedStatusResponse.data.create_document_upload_presigned_status
          .tagging_url,
      );
      return s3DocumentStatus.body.getReader().read();
    } catch (_error) {
      setUploadFailed();
    }
  };

  const verifyCleanStatus = async (
    presignedStatusResponse,
    upload_document_start_time,
    file,
  ) => {
    try {
      if (!presignedStatusResponse) throw new Error("Missing required params");
      const maxTries = 30;
      const textDecoded = new TextDecoder("utf-8");
      for (let tries = 0; tries < maxTries; tries++) {
        const fileStatus = await getRawFileStatus(presignedStatusResponse);
        const decodedStatus = textDecoded.decode(fileStatus.value);

        if (decodedStatus.includes("CLEAN")) {
          setInsuranceCardIsLoading(false);
          addMemberDocUploadDataDogAction(upload_document_start_time, file);
          setFileStatus("success");
          return true;
        } else if (decodedStatus.includes("ERROR")) {
          throw new Error("Scan ERRROR");
        }
        if (isMounted.current) {
          // Wait 2 seconds before trying again
          await new Promise((resolve) => {
            timeoutId.current = setTimeout(resolve, 2000);
          });
        }
      }

      if (isMounted.current) {
        addMemberDocUploadDataDogAction(upload_document_start_time, file);
        setUploadFailed();
      }
      return false;
    } catch {
      if (isMounted.current) {
        setUploadFailed();
      }
    }
  };

  const processFile = async (file) => {
    if (!file) {
      setInsuranceCardIsLoading(false);
      setFileStatus(null);
      setDocumentUrlObjectKey(null);
      return;
    }
    setInsuranceCardIsLoading(true);
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      //MD5 hash is required for S3 upload
      const base64Md5 = getBase64Md5Hash();
      const presignedUrlResponse = await getPresignedUploadUrl(
        file.name,
        base64Md5,
      );
      const objectKey =
        presignedUrlResponse?.data?.create_document_upload_presigned_url
          ?.object_key;
      //Set object key in parent component
      if (objectKey) setDocumentUrlObjectKey(objectKey);
      //upload_document_start_time is used to report the time it takes to upload a document to DD
      const upload_document_start_time = Date.now();
      await uploadFileToS3(file, presignedUrlResponse, base64Md5);
      const getStatusPresignedUrlResponse =
        await getStatusPresignedUrl(objectKey);
      await verifyCleanStatus(
        getStatusPresignedUrlResponse,
        upload_document_start_time,
        file,
      );
    };
  };

  return { processFile, uploadStatus, insuranceCardIsLoading };
};
