import { gql } from "@apollo/client";

const getBenefits = gql`
  query getBenefits($id: ID!) {
    benefits(id: $id) {
      spring_benefit {
        member_benefits
      }
      coaching_benefit {
        enabled
        coaching_tier
        continued_coaching
        unlimited_coaching_cn
        unlimited_coaching_direct_scheduling
        show_coaching_paid_experience
        allow_direct_scheduling
        qualifies_for_coaching_via_CN
        sessions_remaining
        sessions_used
        reschedule
        recommended
      }
      sud_benefit {
        enabled
        recommended
        initial_sud_risk
        latest_sud_risk
        specialized_cn_enabled
      }
    }
  }
`;

export default getBenefits;
