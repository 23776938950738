import { graphql } from "modules/shared/graphql-codegen";

export const getCoveredLifeInfo = graphql(`
  query getCoveredLifeInfo($id: ID!) {
    get_covered_life_info(covered_life_id: $id) {
      is_care_enabled
      is_chip
    }
  }
`);
