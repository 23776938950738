import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";

interface DownloadMobileAppParams {
  eventType: string;
  onDownloadAppOpen: () => void;
  springDocIdMobile: string;
  springDocIdWeb: string;
  location?: string;
}

export const openDownloadMobileAppModal = ({
  eventType,
  onDownloadAppOpen,
  springDocIdMobile,
  springDocIdWeb,
  location = "Also available to you section",
}: DownloadMobileAppParams) => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ) && !window?.chrome;

  if (isMobile) {
    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      "Device app store",
      eventType,
      {
        spring_doc_id: springDocIdMobile,
        location,
      },
    );
    Router.push(DOWNLOAD_APP_URL());
  } else {
    TRACK_EVENT.MODAL_OPENED(window.location.pathname, eventType, {
      spring_doc_id: springDocIdWeb,
      location,
    });
    onDownloadAppOpen();
  }
};

export const DOWNLOAD_APP_URL = () => {
  return (
    (window &&
      window.location &&
      window.location.protocol +
        "//" +
        window.location.host +
        "/download_spring_app") ||
    "https://care.springhealth.com/download_spring_app"
  );
};
