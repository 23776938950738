//

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { getOr } from "lodash/fp";
import {
  Modal,
  Headline,
  Avatar,
  FlexRow,
  Subtitle,
  Stout,
  TagSection,
  Grid,
  Col,
  Content,
  FlexCol,
  Button,
  Section,
} from "@spring/smeargle";
import {
  modalIds,
  RequestableAppointmentKind,
  AppointmentMedium,
} from "@spring/constants";
import { addNotification, closeModal } from "@spring/smeargle/actions";
import { useTranslation } from "react-i18next";

import { requestAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import { formatMiles, formatKilometers } from "utils/displayHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const renderAppointmentDetails = (details) =>
  details && details.map((detail) => <Content key={detail}>{detail}</Content>);

const ConfirmInPersonAppointmentModal = ({
  providerData,
  appointmentData,
  requestAppointment,
  addNotification,
  closeModal,
  showGlobalExperience,
}) => {
  const { t } = useTranslation(["careProvider", "displayHelper"]);
  const enableInPersonGlobal = useFeatureFlag(FLAGS.IN_PERSON_GLOBAL);
  if (!providerData) {
    return null;
  }

  const providerAddress = () =>
    `${providerData.street_address_1}, ${providerData.city}, ${providerData.state}`;

  const createAppointment = async () => {
    try {
      const { days, times, other } = appointmentData.data;
      const payload = {
        availability_days_of_week: days,
        availability_time_of_day: times,
        medium: AppointmentMedium.InPerson,
        notes: other,
        care_provider_id: providerData.id,
        kind: RequestableAppointmentKind.Therapy,
      };

      const {
        data: { requestAppointment: requestAppointmentResponse },
      } = await requestAppointment(payload);

      if (!requestAppointmentResponse.success) {
        return addNotification(
          t(
            "careProvider:confirmInPersonAppointmentModal.notificationMessage.error",
          ),
          "error",
        );
      }

      closeModal(modalIds.confirmInPersonAppointment);
      return addNotification(
        t(
          "careProvider:confirmInPersonAppointmentModal.notificationMessage.success",
        ),
        "success",
      );
    } catch (err) {
      return addNotification(getFirstError(err), "error");
    }
  };
  const distanceResult =
    enableInPersonGlobal && showGlobalExperience
      ? formatKilometers(providerData.distance_kilometers, true)
      : formatMiles(providerData.distance_miles, true);

  return (
    <Modal size="lg" id={modalIds.confirmInPersonAppointment}>
      <Headline>
        {t("careProvider:confirmInPersonAppointmentModal.headline")}
      </Headline>
      <Grid>
        <Col sm={4}>
          <Avatar
            src={providerData.avatar}
            imgProps={{ alt: providerData.name }}
            height={120}
            width={120}
            round
          />
        </Col>
        <Col sm={8}>
          <Section size="md">
            <Subtitle noMargin>{providerData.name}</Subtitle>
            <Stout>
              {providerData.roles.join(", ")},{" "}
              {providerData.licenses.join(", ")}
            </Stout>
            <Section size="md">
              <TagSection title={false} tags={providerData.tags} />
            </Section>
          </Section>
        </Col>

        <Col sm={12}>
          <Section size="xlg">
            <Stout>About</Stout>
            <Section size="md">
              <Content>{providerData.bio}</Content>
            </Section>
          </Section>
        </Col>

        <Col sm={12}>
          <FlexRow>
            <Grid>
              <Col sm={3}>
                <Stout>Provider address</Stout>
                <Section size="md">
                  <Content>{providerAddress()}</Content>
                </Section>
              </Col>
              <Col sm={3}>
                <Stout>Distance from me</Stout>
                <Section size="md">
                  <Content>
                    {distanceResult.distance +
                      " " +
                      t(`displayHelper:${distanceResult.distanceUnit}`)}
                  </Content>
                </Section>
              </Col>
              <Col sm={3}>
                <Stout>Preferred times</Stout>
                <Section size="md">
                  <FlexCol>
                    {renderAppointmentDetails(appointmentData.data.times)}
                  </FlexCol>
                </Section>
              </Col>
              <Col sm={3}>
                <Stout>Preferred Days</Stout>
                <Section size="md">
                  <FlexCol>
                    {renderAppointmentDetails(appointmentData.data.days)}
                  </FlexCol>
                </Section>
              </Col>
            </Grid>
          </FlexRow>
        </Col>

        <Col sm={12}>
          <Section size="md">
            <Button
              onClick={createAppointment}
              text={t(
                "careProvider:confirmInPersonAppointmentModal.submitText",
              )}
              full
            />
          </Section>
        </Col>
      </Grid>
    </Modal>
  );
};

ConfirmInPersonAppointmentModal.propTypes = {
  addNotification: PropTypes.func,
  appointmentData: PropTypes.shape({
    data: PropTypes.shape({
      days: PropTypes.any,
      other: PropTypes.any,
      times: PropTypes.any,
    }),
  }),
  closeModal: PropTypes.func,
  providerData: PropTypes.shape({
    avatar: PropTypes.any,
    bio: PropTypes.any,
    city: PropTypes.any,
    distance_kilometers: PropTypes.any,
    distance_miles: PropTypes.any,
    id: PropTypes.any,
    licenses: PropTypes.shape({
      join: PropTypes.func,
    }),
    name: PropTypes.any,
    roles: PropTypes.shape({
      join: PropTypes.func,
    }),
    state: PropTypes.any,
    street_address_1: PropTypes.any,
    tags: PropTypes.any,
  }),
  requestAppointment: PropTypes.func,
};

const mapStateToProps = (state) => ({
  providerData: getOr({}, modalIds.confirmInPersonAppointment, state.modal)
    .data,
  appointmentData: getOr({}, "inPersonAppointmentDetail", state.form),
  showGlobalExperience: state.global?.showGlobalExperience,
});

const mapDispatchToProps = { addNotification, closeModal };

export default compose(
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (data) =>
        mutate({ variables: { input: { ...data } } }),
    }),
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ConfirmInPersonAppointmentModal);
