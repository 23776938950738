import { Box } from "design-system";

export const VTherapy = ({ width, height }) => (
  <Box>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 108 108"
      fill="none"
    >
      <path
        d="M71.55 56.7L66.825 65.475L64.125 62.775L53.7546 41.85L56.7 36.45L71.55 56.7Z"
        fill="#068464"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.4 70.2H48.6V78.3H40.5V89.1H48.6V97.2H59.4V89.1H67.5V78.3H59.4V70.2Z"
        fill="#068464"
      />
      <path
        d="M53.5209 41.175L72.6685 2.46327C73.0057 1.78146 73.7005 1.34998 74.4612 1.34998H95.3295C96.8125 1.34998 97.7797 2.90739 97.1222 4.23667L67.1664 64.8"
        stroke="#17453A"
        stroke-width="2"
      />
      <path
        d="M40.4298 65.475L10.8365 4.22C10.1949 2.89196 11.1625 1.34998 12.6374 1.34998H32.9368C33.7042 1.34998 34.4039 1.78901 34.7377 2.47995L63.8672 62.775"
        stroke="#17453A"
        stroke-width="2"
      />
      <path
        d="M77.3 83.7C77.3 96.5683 66.8682 107 54 107C41.1317 107 30.7 96.5683 30.7 83.7C30.7 70.8318 41.1317 60.4 54 60.4C66.8682 60.4 77.3 70.8318 77.3 83.7Z"
        stroke="#17453A"
        stroke-width="2"
      />
    </svg>
  </Box>
);
