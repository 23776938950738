import React from 'react';

const PauseIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M21.072 1.9972H14.892V22.0028H21.072V1.9972ZM9 2H3V22H9V2Z" fill="white"/>
		<path d="M14.892 1.9972V0.9972C14.3397 0.9972 13.892 1.44492 13.892 1.9972L14.892 1.9972ZM21.072 1.9972H22.072C22.072 1.44492 21.6243 0.9972 21.072 0.9972V1.9972ZM14.892 22.0028H13.892C13.892 22.5551 14.3397 23.0028 14.892 23.0028V22.0028ZM21.072 22.0028V23.0028C21.6243 23.0028 22.072 22.5551 22.072 22.0028H21.072ZM3 2V1C2.44772 1 2 1.44772 2 2H3ZM9 2H10C10 1.44772 9.55228 1 9 1V2ZM3 22H2C2 22.5523 2.44772 23 3 23V22ZM9 22V23C9.55228 23 10 22.5523 10 22H9ZM14.892 2.9972H21.072V0.9972H14.892V2.9972ZM15.892 22.0028V1.9972H13.892V22.0028H15.892ZM21.072 21.0028H14.892V23.0028H21.072V21.0028ZM20.072 1.9972V22.0028H22.072V1.9972H20.072ZM3 3H9V1H3V3ZM4 22V2H2V22H4ZM9 21H3V23H9V21ZM8 2V22H10V2H8Z" fill="white"/>
	</svg>
);

export default PauseIcon;
