import {
  UPDATE_FIND_MY_MEMBERSHIP_MODEL,
  RESET_FIND_MY_MEMBERSHIP_MODEL,
  UPDATE_FAMILY_SIGNUP_MODEL,
  RESET_FAMILY_SIGNUP_MODEL,
  UPDATE_VERIFICATION_MODEL,
  RESET_VERIFICATION_MODEL,
} from "./types";

export function updateFindMyMembershipModel(payload) {
  return {
    type: UPDATE_FIND_MY_MEMBERSHIP_MODEL,
    payload,
  };
}

export function resetFindMyMembershipModel(payload) {
  return {
    type: RESET_FIND_MY_MEMBERSHIP_MODEL,
    payload,
  };
}

export function updateFamilySignupModel(payload) {
  return {
    type: UPDATE_FAMILY_SIGNUP_MODEL,
    payload,
  };
}

export function resetFamilySignupModel(payload) {
  return {
    type: RESET_FAMILY_SIGNUP_MODEL,
    payload,
  };
}

export function updateVerificationModel(payload) {
  return {
    type: UPDATE_VERIFICATION_MODEL,
    payload,
  };
}

export function resetVerificationModel(payload) {
  return {
    type: RESET_VERIFICATION_MODEL,
    payload,
  };
}
