//

import React from "react";
import NextLink from "next/link";
import {
  Box,
  Text,
  Heading,
  Link as SHLink,
  Image,
  VStack,
  HStack,
  Show,
  Center,
  useBreakpointValue,
  Flex,
  Hide,
} from "design-system/components";
import { Button, TextButton } from "@springcare/sh-component-library";
import { colors } from "design-system/theme/base/colors";
import PropTypes from "prop-types";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";

const ActiveCareCard = ({
  icon,
  imageSvg: ImageSvg, // NOTE: Moments ImageSVG is flat so we add the flat prop in
  imageUrl,
  overline,
  cardTitle,
  buttonText,
  subtitle,
  subtitleLinkText,
  subtitleLinkURL,
  subtitleLinkQueryParams,
  buttonCallback,
  memberCanBookAppointments = true,
}) => {
  const defaultProviderImageUrl = "/static/DefaultAvatar.png";
  const overlineVariant = useBreakpointValue([
    "captionBold_v1",
    "captionBold_v1",
    "bodyBold_v1",
    "bodyBold_v1",
  ]);
  const cardTitleVariant = useBreakpointValue([
    "bodyBold_v1",
    "bodyBold_v1",
    "bodyBold_v1",
    "sm_v1",
  ]);
  const subtitleVariant = useBreakpointValue([
    "caption_v1",
    "caption_v1",
    "body_v1",
    "body_v1",
  ]);
  const svgWidth = useBreakpointValue([33, 33, 48]);
  const svgHeight = useBreakpointValue([40, 40, 58]);
  const shouldShowMobileCTAs =
    subtitleLinkQueryParams?.id || memberCanBookAppointments;
  const hasBothMobileCTAs =
    subtitleLinkQueryParams?.id && memberCanBookAppointments;
  const mobileButtonWidth = hasBothMobileCTAs ? "50%" : "100%";

  return (
    <div>
      <Show above={"992px"}>
        <Box
          data-cy={`${overline}-active-care-card`}
          h="100%"
          p={4}
          borderWidth="3px"
          borderRadius="12px"
          borderColor={colors.tertiary.light}
          padding
        >
          <HStack h={"100%"}>
            {imageUrl && (
              <Image
                w={140}
                h={160}
                objectFit="cover"
                fallbackSrc={defaultProviderImageUrl}
                src={imageUrl}
                alt="A photograph of your care provider"
                borderTopStartRadius="9px"
                borderBottomStartRadius="9px"
                aria-hidden="true"
              />
            )}

            {ImageSvg && (
              <Box>
                <Center w={140} h={160} bg={colors.tertiary.light}>
                  <ImageSvg w="58px" h="58px" flat={true} />
                </Center>
              </Box>
            )}

            <Box w="100%" ps={"24px"} pe={"24px"} m={0}>
              <HStack>
                <Box w="100%">
                  <VStack align="stretch">
                    <Flex alignItems="center">
                      <Text
                        variant={overlineVariant}
                        pt={1}
                        data-cy={`${overline}-active-care-card-title`}
                      >
                        {overline}
                      </Text>
                      <Box as="span" px={2} color={colors.platform["900"]}>
                        {icon || null}
                      </Box>
                    </Flex>

                    {cardTitle && (
                      <Box h="fit-content">
                        <Box>
                          <Heading
                            as="h4"
                            variant={cardTitleVariant}
                            color={colors.platform["900"]}
                            noOfLines={[1, 2]}
                            overflowWrap="anywhere"
                            data-cy={`${overline}-active-care-card-content`}
                          >
                            {cardTitle}
                          </Heading>
                        </Box>
                      </Box>
                    )}
                    {subtitle && (
                      <Box>
                        <Text
                          data-cy={`${overline}-active-care-card-subtitle`}
                          variant={subtitleVariant}
                          color={colors.platform["900"]}
                          float="left"
                          noOfLines={[1, 2]}
                        >
                          {subtitle}
                          {subtitleLinkQueryParams?.id && (
                            <Box as="span" p={3}>
                              {/* Desktop width "View" link */}
                              <NextLink
                                href={routes[subtitleLinkURL].as.replace(
                                  ":id",
                                  subtitleLinkQueryParams.id,
                                )}
                                passHref
                              >
                                <SHLink
                                  data-cy={`${overline}-view-button`}
                                  textStyle={subtitleVariant}
                                  color="primary.base"
                                  _focusVisible={{
                                    boxShadow: "0 0 0 3px black",
                                  }}
                                  onClick={() => {
                                    TRACK_EVENT.LINK_CLICKED(
                                      window.location.pathname,
                                      routes[subtitleLinkURL].as.replace(
                                        ":id",
                                        subtitleLinkQueryParams.id,
                                      ),
                                      "View Upcoming Appointment",
                                      {
                                        spring_doc_id: "HPR_016",
                                        location: "Active Care",
                                        appointment_type: overline,
                                      },
                                    );
                                  }}
                                >
                                  {subtitleLinkText}
                                </SHLink>
                              </NextLink>
                            </Box>
                          )}
                        </Text>
                      </Box>
                    )}
                  </VStack>
                </Box>

                {memberCanBookAppointments && (
                  <Box pt={"20px"} pb={"35px"}>
                    <Button
                      colorScheme="base"
                      variant="low-emphasis"
                      size="sm"
                      _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                      onClick={buttonCallback}
                    >
                      <Text size="body-medium-strong">{buttonText}</Text>
                    </Button>
                  </Box>
                )}
              </HStack>
            </Box>
          </HStack>
        </Box>
      </Show>
      <Hide above={"992px"}>
        <Box
          borderWidth="1px"
          borderRadius="8px"
          borderColor={colors.tertiary.light}
        >
          <VStack w={"100%"} spacing={0}>
            <Box w="100%" minH="120px">
              <HStack w="100%" h="100%">
                {imageUrl && (
                  <Image
                    fallbackSrc={defaultProviderImageUrl}
                    src={imageUrl}
                    borderTopStartRadius="7px"
                    borderBottomStartRadius={
                      !shouldShowMobileCTAs ? "7px" : "0px"
                    }
                    alt="A photograph of your care provider"
                    aria-hidden="true"
                    w={[80, 80, 140, 140]}
                    h={[130, 120, 160, 160]}
                    objectFit="cover"
                  />
                )}

                {ImageSvg && (
                  <Box h={[130, 120, 160, 160]}>
                    <Center
                      w={[80, 80, 140, 140]}
                      h="100%"
                      bg={colors.tertiary.light}
                    >
                      <ImageSvg w={svgWidth} h={svgHeight} flat={true} />
                    </Center>
                  </Box>
                )}
                <Box w="100%" ps={"8px"} pe={"8px"}>
                  <VStack w="100%" pt={"17px"}>
                    <Box w="100%">
                      <Text
                        variant={overlineVariant}
                        color={colors.platform["900"]}
                      >
                        <Box as="span">{overline}</Box>
                        <Box as="span" p={2}>
                          {icon || null}
                        </Box>
                      </Text>
                    </Box>

                    <Box w="100%">
                      <Text
                        variant={cardTitleVariant}
                        color={colors.platform["900"]}
                        noOfLines={[1, 2]}
                        overflowWrap="anywhere"
                      >
                        {cardTitle}
                      </Text>
                    </Box>

                    {subtitle && (
                      <Box w="100%" pb={"12px"}>
                        <Text
                          variant={subtitleVariant}
                          color={colors.platform["900"]}
                          float="left"
                        >
                          {subtitle}
                        </Text>
                      </Box>
                    )}
                  </VStack>
                </Box>
              </HStack>
            </Box>
            {shouldShowMobileCTAs && (
              <HStack
                w="100%"
                h="45px"
                mt={0}
                borderTop="1px"
                borderColor={colors.tertiary.light}
              >
                {subtitleLinkQueryParams?.id && (
                  <Box
                    h="100%"
                    w={mobileButtonWidth}
                    borderInlineEnd={hasBothMobileCTAs ? "1px" : "0px"}
                    borderColor={colors.tertiary.light}
                  >
                    {/* Mobile width "View session" button */}
                    <NextLink
                      href={routes[subtitleLinkURL].as.replace(
                        ":id",
                        subtitleLinkQueryParams.id,
                      )}
                      passHref
                    >
                      <TextButton
                        h="100%"
                        w="100%"
                        _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                      >
                        {subtitleLinkText} session
                      </TextButton>
                    </NextLink>
                  </Box>
                )}
                {memberCanBookAppointments && (
                  <Box
                    w={mobileButtonWidth}
                    h="100%"
                    style={{ marginInlineStart: "0px" }}
                  >
                    <TextButton
                      h="100%"
                      w="100%"
                      onClick={buttonCallback}
                      _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                    >
                      {buttonText}
                    </TextButton>
                  </Box>
                )}
              </HStack>
            )}
          </VStack>
        </Box>
      </Hide>
    </div>
  );
};
ActiveCareCard.propTypes = {
  icon: PropTypes.object,
  imageUrl: PropTypes.any,
  imageSvg: PropTypes.func, // Is a function that returns an element
  overline: PropTypes.any,
  cardTitle: PropTypes.any,
  buttonText: PropTypes.any,
  subtitle: PropTypes.any,
  subtitleLinkText: PropTypes.any,
  subtitleLinkURL: PropTypes.any,
  subtitleLinkQueryParams: PropTypes.any,
  buttonCallback: PropTypes.any,
};

export default ActiveCareCard;
