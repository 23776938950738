import React, { FC } from "react";
import Router from "next/router";
import routes from "routes";
import { useTranslation } from "react-i18next";
import type { FindTherapistHeroBannerProps } from "./types";
import { PhotoCard } from "shared/components/PhotoCard";
import { Button } from "@springcare/sh-component-library";
import { HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import { VTherapyIcon } from "@springcare/verdant-icons-react";
import { TEEN_HOME_BANNER_TYPES } from "./constants";
import { useTeenTherapyVisitsCovered } from "modules/MemberDashboard/Teen/Home/hooks";
import { TRACK_EVENT } from "utils/mixpanel";

/**
 * This component renders a PhotoCard banner. It will check the following details:
 * If a member has sponsored sessions, it will render a tag listing the number of sessions covered.
 * It renders a CTA button that will take users to the browse therapists page.
 */

const Tag = ({ sessionCount }) => {
  if (!sessionCount) {
    return null;
  }

  const { t } = useTranslation("teenHomepage");
  return (
    <HStack alignSelf="flex-start">
      <VTherapyIcon aria-hidden="true" color="white" />
      <Text size="body-medium-regular" color="background-base">
        {t("findYourTherapistBanner.tagText", { count: sessionCount })}
      </Text>
    </HStack>
  );
};

const Cta = ({ ctaText }) => {
  return (
    <Button
      alignSelf="flex-start"
      data-cy="teen-hero-banner-cta-button"
      onClick={() => {
        TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Find Therapist", {
          location: "Teen home spotlight",
          to: routes.TherapistsBrowse.as,
          spring_doc_id: "teenhp002",
        });
        Router.push(routes.TherapistsBrowse.to, routes.TherapistsBrowse.as);
      }}
    >
      {ctaText}
    </Button>
  );
};

export const FindTherapistHeroBanner: FC<
  React.PropsWithChildren<FindTherapistHeroBannerProps>
> = () => {
  const { t } = useTranslation("teenHomepage");
  const BANNER = TEEN_HOME_BANNER_TYPES.find_your_therapist;

  const { therapySessionsCovered, loading } = useTeenTherapyVisitsCovered();
  const photoGradient = useBreakpointValue([
    BANNER.photoGradientMobile,
    BANNER.photoGradientMobile,
    BANNER.photoGradient,
    BANNER.photoGradient,
  ]);

  return (
    <PhotoCard
      altText={t("findYourTherapistBanner.altText")}
      imgUrl={BANNER.imgUrl}
      heading={t("findYourTherapistBanner.heading")}
      description={t("findYourTherapistBanner.description")}
      photoGradient={photoGradient}
      tag={<Tag sessionCount={therapySessionsCovered} />}
      cta={<Cta ctaText={t("findYourTherapistBanner.cta")} />}
      loading={loading}
    />
  );
};
