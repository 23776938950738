import { gql } from "@apollo/client";

const createDocumentUploadPresignedUrl = gql`
  mutation CreateDocumentUploadPresignedUrl(
    $input: CreateDocumentPresignedUrlInputType!
  ) {
    create_document_upload_presigned_url(input: $input) {
      url
      tagging_header
      object_key
    }
  }
`;

export default createDocumentUploadPresignedUrl;
