import { CONTENT_EYEBROW_TEXT } from "modules/MemberDashboard/ManagerExperience/shared/constants";
import {
  CONTENT_FORMATS,
  CONTENT_TYPES,
} from "../constants/contentTypes.constants";
import { MOMENTS_IDS_BY_WORKPLACE_CATEGORY } from "../constants/momentsIdsByWorkplaceCategory";
export const topPicksCategory = "TOP_PICKS";

export const categorySelectedStateStyle = {
  _hover: {
    color: "primary-on-subtle",
    background: "primary-subtle",
  },
  _focus: {
    color: "primary-on-subtle",
    background: "primary-subtle",
  },
};

export const getNewSkillsCategories = () => {
  return [
    {
      name: "contentCategories.topPicks",
      query: "TOP_PICKS",
    },
    {
      name: "contentCategories.burnOut",
      query: "BURNOUT",
    },
    {
      name: "contentCategories.career",
      query: "CAREER",
    },
    {
      name: "contentCategories.crisisReadiness",
      query: "CRISIS_READINESS",
    },
    {
      name: "contentCategories.communicateEffectively",
      query: "COMMUNICATE_EFFECTIVELY",
    },
    {
      name: "contentCategories.focus",
      query: "FOCUS",
    },
    {
      name: "contentCategories.mentalHealth",
      query: "MENTAL_HEALTH",
    },
    {
      name: "contentCategories.psychologicalSafety",
      query: "PSYCHOLOGICAL_SAFETY",
    },
    {
      name: "contentCategories.selfEsteem",
      query: "SELF_ESTEEM",
    },
    {
      name: "contentCategories.stress",
      query: "STRESS",
    },
    {
      name: "contentCategories.healthyBoundaries",
      query: "HEALTHY_BOUNDARIES",
    },
    {
      name: "contentCategories.managingChange",
      query: "MANAGING_CHANGE",
    },
  ];
};

export const determineCategoriesForFilterChipsAndMenu = (
  numOfChipsToDisplayPerScreenSize: number,
) => {
  if (numOfChipsToDisplayPerScreenSize < 0) {
    return [];
  }

  const BuildNewSkillsCategories = getNewSkillsCategories();
  //render the number of category from 0 index to numOfChipsToDisplayPerScreenSize
  const categoriesForFilterChips = BuildNewSkillsCategories.slice(
    0,
    numOfChipsToDisplayPerScreenSize,
  );

  // any remaining categories should be rendered in the more option menu
  // from remainingCategoriesToDisplay to the last index
  const remainingCategoriesToDisplay = numOfChipsToDisplayPerScreenSize;

  const categoriesForMoreOptionsMenu = BuildNewSkillsCategories.slice(
    remainingCategoriesToDisplay,
  );
  return [categoriesForFilterChips, categoriesForMoreOptionsMenu];
};

export const getMomentIdsByCategory = (categoryName) => {
  switch (categoryName) {
    case "BURNOUT":
      return MOMENTS_IDS_BY_WORKPLACE_CATEGORY.burnout;
    case "FOCUS":
      return MOMENTS_IDS_BY_WORKPLACE_CATEGORY.focus;
    case "CAREER":
      return MOMENTS_IDS_BY_WORKPLACE_CATEGORY.career;
    case "STRESS":
      return MOMENTS_IDS_BY_WORKPLACE_CATEGORY.stress;
    case "SELF_ESTEEM":
      return MOMENTS_IDS_BY_WORKPLACE_CATEGORY.selfEsteem;
    case "MANAGING_CHANGE":
      return MOMENTS_IDS_BY_WORKPLACE_CATEGORY.managingChange;
    default:
      return [];
  }
};

export const getContentFormat = (contentType: string) => {
  if (contentType === CONTENT_TYPES.leader_course) {
    return "video";
  }
  if (contentType === CONTENT_TYPES.webinar) {
    return "external";
  }
};

export const getMomentsContentFormat = (contentTypes) => {
  const audioContentType = CONTENT_FORMATS.audio.toUpperCase();
  const textContentType = CONTENT_FORMATS.text.toUpperCase();
  if (contentTypes?.length < 2 && contentTypes?.includes(textContentType)) {
    return "text";
  }
  if (contentTypes?.length < 2 && contentTypes?.includes(audioContentType)) {
    return "audio";
  }
  if (
    contentTypes?.length > 1 &&
    contentTypes?.includes(textContentType, audioContentType)
  ) {
    return "textAndAudio";
  }
};

export const getEyeBrowText = (contentType) => {
  if (contentType === CONTENT_TYPES.leader_course) {
    return CONTENT_EYEBROW_TEXT.leaderCourses;
  }
  if (contentType === CONTENT_TYPES.webinar) {
    return CONTENT_EYEBROW_TEXT.webinar;
  }
  return CONTENT_EYEBROW_TEXT.moments;
};
