import { API_DOMAIN } from "constants/api";

export type StopRecordingPayload = {
  appointment_id: string;
};

export const usePostStopRecording = () => {
  const rotomUrl = API_DOMAIN;

  return async (payload: StopRecordingPayload) => {
    const res = await fetch(
      `${rotomUrl}/api/ehr/session_recording_audio_files/stop_audio_recordings`,
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("compass_redirect_access_token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      },
    );
    return await res.json();
  };
};
export default usePostStopRecording;
