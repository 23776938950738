// 

import React from 'react';
import DOMPurify from 'dompurify';

import styles from './styles.module.scss';


const sanitize = (children) => DOMPurify.sanitize(children);

const html = (children) => ({
	__html: sanitize(children),
});

const DangerousHtml = ({ children, ariaLabel }) => {
	return <div aria-label={ariaLabel} className={styles.html} dangerouslySetInnerHTML={html(children)} />;
};

DangerousHtml.defaultProps = {
	replaceMe: 'sailor',
};

export default DangerousHtml;

