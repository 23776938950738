// 
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';



const activeKeysSelector = (state, props) => {
	return get(`${props.tableKey}.activeKeys`, state.table);
};

export const tableDataSelector = createSelector(
	[
		activeKeysSelector,
	],
	(activeKeys) => ({
		activeKeys,
	})
);

