import { gql } from "@apollo/client";

const updateAppointmentSentimentRating = gql`
  mutation UpdateAppointmentSentimentRating(
    $appointment_id: ID!
    $sentiment_rating: Int!
  ) {
    updateAppointmentSentimentRating(
      appointment_id: $appointment_id
      sentiment_rating: $sentiment_rating
    ) {
      success
    }
  }
`;
export default updateAppointmentSentimentRating;
