export const SpringLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      aria-hidden="true"
    >
      <title>Spring Health Logo</title>
      <path
        d="M18.9455 14.9199C16.974 16.5873 15.3316 18.37 13.9619 20.1555C17.0893 17.9932 20.7652 16.8769 24.4297 17.13C27.2084 17.324 29.748 18.3419 31.5761 19.4132C31.5761 19.4132 32.2792 18.2294 31.8742 12.8251C31.6576 9.91766 31.8489 8 31.8489 8C29.163 8.1659 22.5285 11.8859 18.9512 14.9171L18.9455 14.9199Z"
        fill="#01382E"
      />
      <path
        d="M24.2988 18.983C20.0999 18.6906 15.87 20.4479 12.6836 23.6225C14.6467 22.5202 17.2341 21.6683 20.398 21.9157C24.6504 22.2531 27.6147 24.4941 29.3415 26.2965C30.3146 24.8681 30.9811 23.2148 31.2426 21.4321C29.5243 20.2736 27.1815 19.1798 24.3016 18.9802L24.2988 18.983Z"
        fill="#01382E"
      />
      <path
        d="M20.2515 23.7743C14.5451 23.3216 10.9733 27.0388 10.0171 28.186C9.31964 29.0268 8.74028 29.9716 8.29311 30.9979C8.23967 31.1666 8.18624 31.3325 8.13843 31.4927C8.13843 31.4927 12.6833 32.4769 19.689 31.7036C26.6919 30.9304 28.1656 27.7587 28.1656 27.7587C26.7031 26.1728 24.0791 24.0808 20.2486 23.7771L20.2515 23.7743Z"
        fill="#01382E"
      />
      <path
        d="M17.7424 13.5027C20.3861 11.2645 23.3672 9.41153 26.5537 8.00281L19.6127 8.0478C13.1582 8.08716 7.95807 13.3565 8.00025 19.8124L8.04243 26.4736C9.8255 22.5118 12.8151 17.6754 17.7396 13.5027H17.7424Z"
        fill="#01382E"
      />
    </svg>
  );
};
