import { createIcon } from "@chakra-ui/react";

// NOTE: this is the monotone version of the icon, the duotone version might need some work
export const PlantLeaves = createIcon({
  displayName: "PlantLeavesV1.1",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3457 0C22.8204 0 23.2342 0.312297 23.3493 0.757464L23.8116 2.54479C24.791 6.33167 21.8279 10 17.7896 10H13.0346V23C13.0346 23.5523 12.5714 24 12 24C11.4286 24 10.9654 23.5523 10.9654 23L10.9654 20H6.21045C2.1721 20 -0.791049 16.3317 0.188395 12.5448L0.650671 10.7575C0.765809 10.3123 1.17962 10 1.65435 10H6.82717C8.417 10 9.86723 10.5777 10.9654 11.5278V6C10.9654 2.68629 13.7446 0 17.1728 0H22.3457ZM10.9654 15.9972C10.9639 13.7894 9.11171 12 6.82717 12H2.46211L2.19575 13.0299C1.54278 15.5544 3.51822 18 6.21045 18H10.9654L10.9654 15.9972ZM13.0346 6V8H17.7896C20.4818 8 22.4572 5.55445 21.8043 3.02986L21.5379 2H17.1728C14.8873 2 13.0346 3.79086 13.0346 6Z"
    />
  ),
  defaultProps: {
    "aria-hidden": true,
    "aria-label": "Plant leaves icon",
    role: "img",
    color: "tertiary.700",
    "aria-hidden": "true",
  },
});
