import { useCallback } from "react";
import { useTranslation as _useTranslation } from "react-i18next";
import { get } from "lodash";
import * as common from "public/locales/en/common.json";
import * as limitedLangAuth from "public/locales/en/limitedLangAuth.json";
import * as limitedLangRegister from "public/locales/en/limitedLangRegister.json";

// TODO: https://springhealth.atlassian.net/browse/MXE-540

// static translations
const NAMESPACES = { limitedLangRegister, common, limitedLangAuth };

/**
 * This hook wraps react-i18next useTranslation hook to return the default English translation
 * while the correct translation is downloading.
 */
export function useTranslation(
  namespace: string | string[],
  options?: { keyPrefix: string | undefined },
) {
  const { t: _t } = _useTranslation(namespace, options);
  const ns = Array.isArray(namespace) ? namespace[0] : namespace;

  function enFallback(key: string, opts = { ns }) {
    const value = _t(key, opts);
    return value !== key ? value : get(NAMESPACES[opts.ns ?? ns], key);
  }

  // cache function definition between re-renders
  const t = useCallback(enFallback, [_t]);

  return { t };
}
