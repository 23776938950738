import { Button } from "@springcare/sh-component-library";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  useDisclosure,
  AlertDialogBody,
} from "@chakra-ui/react";
import {
  useSessionVerification,
  useParticipantMetadata,
  useSessionRecording,
  useSessionAuthentication,
} from "context/SessionRoomContext";
import { useLocalParticipant } from "@livekit/components-react";
import { useEffect, useRef, useState } from "react";
import useVerifySession from "components/templates/SHSessionRoom/hooks/useVerifySession";
import { useStartAudioRecording } from "components/templates/SHSessionRoom/hooks/useStartAudioRecording";
import { useEndSessionForAll } from "components/templates/SHSessionRoom/hooks/useEndSessionForAll";
import { useSessionTracks } from "components/templates/SHSessionRoom/hooks/useSessionTracks";
import { ParticipantType } from "components/templates/SHSessionRoom/types";

export const SessionVerificationModal = () => {
  const confirmRef = useRef(null);
  const verificationDisclosure = useDisclosure();
  const { appointmentId, participantType, remoteParticipantName } =
    useParticipantMetadata();
  const { sessionVerified, setSessionVerified } = useSessionVerification();
  const { authenticated } = useSessionAuthentication();
  const { localParticipant } = useLocalParticipant();
  const { setSessionConsents, recordingEligible } = useSessionRecording();
  const { endSessionForAll, isLoading: isLoadingEndSession } =
    useEndSessionForAll();
  const verifySession = useVerifySession();
  const [loading, setLoading] = useState(false);
  const { localTrack, remoteTracks } = useSessionTracks();

  useEffect(() => {
    if (
      !verificationDisclosure.isOpen &&
      localTrack &&
      remoteTracks?.length === 1 &&
      !sessionVerified &&
      participantType === ParticipantType.Provider &&
      authenticated &&
      recordingEligible
    ) {
      verificationDisclosure.onOpen();
    }
  }, [localTrack, remoteTracks, authenticated]);

  useStartAudioRecording();

  const onVerify = () => {
    setLoading(true);
    verifySession({ appointment_id: appointmentId })
      .then((response) => {
        setSessionVerified(true);
        setSessionConsents(response?.consents);
      })
      .finally(() => {
        verificationDisclosure.onClose();
        setLoading(false);
      });
  };

  return (
    <AlertDialog
      onClose={verificationDisclosure.onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      leastDestructiveRef={confirmRef}
      isOpen={verificationDisclosure.isOpen}
    >
      <AlertDialogContent>
        <AlertDialogHeader
          textStyle="heading-small"
          px="v-24"
          pt="v-24"
          pb="v-16"
        >
          Confirm client's identity
        </AlertDialogHeader>

        <AlertDialogBody textStyle="body-medium-regular">
          Please confirm you are speaking to <b>{remoteParticipantName}</b>{" "}
          before proceeding. If this is not {remoteParticipantName}, please end
          the session.
        </AlertDialogBody>

        <AlertDialogFooter px="v-24" pb="v-24" pt="v-16">
          <Button
            variant="medium-emphasis"
            onClick={() => endSessionForAll(localParticipant?.identity)}
            disabled={loading}
            isLoading={isLoadingEndSession}
          >
            End session
          </Button>
          <Button
            variant="high-emphasis"
            onClick={onVerify}
            ml={3}
            ref={confirmRef}
            isLoading={loading}
            _focus={{
              color: "white",
            }}
          >
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
