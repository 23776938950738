import React, { useEffect } from 'react';
import classnames from 'classnames';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const TransitionStack = ({ animation, currentIndex, stack }) => {

	const currentPath = path => path.substring(path.lastIndexOf('/') + 1);

	useEffect(() => {
		if (currentPath(window.location.href) === 'initial_assessment') {
			document.body.classList.add('protect-overflow-height');
		} else {
			document.body.classList.add('protect-overflow-width');
		}
	
		return () => {
			document.body.classList.remove('protect-overflow-height');
			document.body.classList.remove('protect-overflow-width');
		};
	}, [window.location.href]);
	
	return (
		<div
			className={classnames(styles.wrapper, [styles[animation]])}
		>
			<div
				key={get(`[${currentIndex - 1}].key`, stack)}
				className={classnames(styles.node, styles.past, styles.out, styles.left)}
			>
				{stack[currentIndex - 1] && (
					<>
						{React.cloneElement(stack[currentIndex - 1], { stack: stack })}
					</>
				)}
			</div>

			<div
				key={get(`[${currentIndex}].key`, stack)}
				className={classnames(styles.node, styles.active, styles.in)}
			>
				{stack[currentIndex] && (
					<>
						{React.cloneElement(stack[currentIndex], { stack: stack })}
					</>
				)}
			</div>

			<div
				key={get(`[${currentIndex + 1}].key`, stack)}
				className={classnames(styles.node, styles.past, styles.out, styles.right)}
			>
				{stack[currentIndex + 1] && (
					<>
						{React.cloneElement(stack[currentIndex + 1], { stack: stack })}
					</>
				)}
			</div>
		</div>
	);

};

TransitionStack.defaultProps = {
	animation    : 'slideshow',
	currentIndex : 0,
	stack        : [],
};

TransitionStack.propTypes = {
	animation    : PropTypes.string,
	currentIndex : PropTypes.number,
	stack        : PropTypes.array,
};

export default TransitionStack;
