import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
} from "design-system/components";
import { PhoneIcon, ChatHumanOnCallIcon } from "@spring/smeargle";
import { useRegisterContext } from "context/RegisterContext";
import { chatHelpNumbers } from "@spring/constants";
import { track } from "utils/mixpanel";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";

const MinorHelpSection = ({ headerText, bodyText, showHelpMethod }) => {
  const { isDependentOrNoEmail }: any = useRegisterContext();
  const { t } = useTranslation("limitedLangRegister");
  return (
    <Box
      bg="warning.light"
      p={33}
      borderRadius={8}
      mb={144}
      data-testid="minor-help-section"
    >
      <Heading variant="sm_v1" color="warning.dark" lineHeight="27px">
        {headerText}
      </Heading>
      <Text variant="body_v1" mt={16} lineHeight="21px" color="warning.dark">
        {bodyText}
      </Text>
      {showHelpMethod && !isDependentOrNoEmail && (
        <Box mt={24}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX={8}>
            <Button
              type="button"
              h={56}
              onClick={() => {
                track("Register Page - Live chat clicked");
                if (window?.zE as Window) {
                  window?.zE?.activate();
                }
              }}
              data-cy={t("createAccount.support.liveChat")}
              color="warning.dark"
              border="2px"
              mb="8"
              borderRadius="8px"
              _hover={{
                background: "info.100",
              }}
            >
              <ChatHumanOnCallIcon />{" "}
              <Text ms={8}>{t("createAccount.support.liveChat")}</Text>
            </Button>
            <Button
              type="button"
              h={56}
              color="warning.dark"
              border="2px"
              borderRadius="8px"
              _hover={{
                background: "info.100",
              }}
            >
              <PhoneIcon color="#916010" />{" "}
              <a href={`tel:${chatHelpNumbers.minorHelpNumber.value}`}>
                <Text ml={8} style={getPhoneStyleForRTL()}>
                  {chatHelpNumbers.minorHelpNumber.label}
                </Text>
              </a>
            </Button>
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

export default MinorHelpSection;
