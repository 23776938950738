import { Box } from "design-system";

export const Checkmark = ({ width, height }) => {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 40 35"
        aria-label="checkmark"
        aria-hidden="true"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.715 13.3009C30.1011 13.6958 30.094 14.3289 29.6991 14.715L17.4264 26.715C17.2353 26.9019 16.9773 27.0045 16.71 26.9999C16.4428 26.9952 16.1885 26.8838 16.0039 26.6905L10.2766 20.6905C9.89531 20.291 9.91003 19.658 10.3095 19.2766C10.709 18.8953 11.342 18.91 11.7234 19.3095L16.7518 24.5774L28.3009 13.285C28.6958 12.8989 29.3289 12.906 29.715 13.3009Z"
          fill="#068464"
        />
      </svg>
    </Box>
  );
};
