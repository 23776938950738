import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import Router from "next/router";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Col,
  Subhead,
  AppStore,
  GooglePlay,
  Content,
  MomentsIcon,
  EAPIcon,
  CoachIcon,
  MedicationManagerIcon,
  TherapistIcon,
  CareNavigatorIcon,
  ProgressTrackingIcon,
  MobileIcon,
  SubstanceUseIcon,
} from "@spring/smeargle";
import classnames from "classnames";
import { openModal, addNotification } from "@spring/smeargle/actions";

import {
  modalIds,
  AppointmentKind,
  AppointmentMedium,
  AppointmentAction,
  links,
  getModalNameForTracking,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  RequestableAppointmentKind,
} from "@spring/constants";
import {
  useUpcomingAppointmentSlots,
  useAllUpcomingAppointmentSlots,
} from "hooks";
import { getOr } from "lodash/fp";
import { useTranslation, Trans } from "react-i18next";
import { isMinor, isMediumOrHighRisk } from "utils/memberHelpers";
import { createSUDZendeskTicket } from "operations/mutations/member";

import styles from "./styles.module.scss";

import { CarePlanOffering } from "components";
import routes from "routes";
import {
  EVENT_TYPE,
  makeEventString,
  track,
  TRACK_EVENT,
} from "utils/mixpanel";
import { useMemberBenefits } from "hooks/useMemberBenefits";

const CareNavigatorDescription = connect(null, { openModal })(({
  openModal,
  careNavigator,
  showGlobalExperience,
}) => {
  const handleClick = () =>
    openModal(modalIds.changeCareProviderModal, {
      careProviderId: careNavigator.id,
      role: careNavigator.roles[0],
    });

  return (
    <Content>
      {showGlobalExperience ? (
        <Trans
          ns="carePlan"
          i18nKey="whatsInYourPlan.careNavigation.globalDescription"
          components={[
            <a
              tabIndex="0"
              href="#"
              key="0"
              className={styles.inlineTextLink}
              onClick={handleClick}
            ></a>,
          ]}
        />
      ) : (
        <Trans
          ns="carePlan"
          i18nKey="whatsInYourPlan.careNavigation.description"
          components={[
            <a
              tabIndex="0"
              href="#"
              key="0"
              className={styles.inlineTextLink}
              onClick={handleClick}
            ></a>,
          ]}
        />
      )}
    </Content>
  );
});

CareNavigatorDescription.propTypes = {
  careNavigator: PropTypes.shape({
    id: PropTypes.any,
    roles: PropTypes.any,
  }),
  openModal: PropTypes.func,
  addNotification: PropTypes.func,
  showGlobalExperience: PropTypes.bool,
};

const EAPOffering = ({ customerName, showGlobal, isMinor, showCoaching }) => {
  const { t } = useTranslation("carePlan");
  const showMedication = !showGlobal && !isMinor;
  return (
    <Col lg={6}>
      <CarePlanOffering
        icon={<EAPIcon />}
        title={t("whatsInYourPlan.eap.title")}
        description={t("whatsInYourPlan.eap.description")}
        action={{
          text: t("whatsInYourPlan.eap.actionText"),
          onClick: () => {
            track("EAP - What's in my plan - Learn More", {
              customer: customerName,
            });
            let tabId = 0;
            if (showMedication && showCoaching) {
              tabId = 6;
            }
            if (
              (showMedication && !showCoaching) ||
              (!showMedication && showCoaching)
            ) {
              tabId = 5;
            }
            if (!showMedication && !showCoaching) {
              tabId = 4;
            }
            const { to, as } = routes.MemberBenefits;

            Router.replace(
              {
                pathname: to,
                query: { tabId },
              },
              as,
            );
          },
        }}
      />
    </Col>
  );
};

const MedicationOffering = () => {
  const { t } = useTranslation("carePlan");
  return (
    <Col lg={6}>
      <CarePlanOffering
        icon={<MedicationManagerIcon />}
        title={t("whatsInYourPlan.medication.title")}
        description={t("whatsInYourPlan.medication.description")}
        action={{
          text: t("whatsInYourPlan.medication.actionText"),
          onClick: () => {
            track("What's in your plan -- Medical Consultation");
            const { to, as } = routes.Medication_ManagersBrowse;
            Router.replace(to, as);
          },
        }}
      />
    </Col>
  );
};

const CoachingOffering = ({ openCareNavigatorModal, member }) => {
  const { t } = useTranslation("carePlan");
  const { allowDirectScheduling } = useMemberBenefits(member?.id);
  const description = allowDirectScheduling
    ? t("whatsInYourPlan.coachingWithDirectScheduling.description")
    : t("whatsInYourPlan.coaching.description");
  const actionText = allowDirectScheduling
    ? t("whatsInYourPlan.coachingWithDirectScheduling.actionText")
    : t("whatsInYourPlan.coaching.actionText");
  return (
    <Col lg={6}>
      <CarePlanOffering
        icon={<CoachIcon />}
        title={t("whatsInYourPlan.coaching.title")}
        description={description}
        action={{
          text: actionText,
          onClick: () => {
            track("Coaching - What's in my plan section clicked");
            if (allowDirectScheduling) {
              const { to, as } = routes.CoachesBrowse;
              Router.replace(to, as);
            } else {
              openCareNavigatorModal(true);
            }
          },
        }}
      />
    </Col>
  );
};

const WhatsInYourPlan = (props) => {
  const sudRequested = window.localStorage.getItem("sud_requested");
  const [isSUDRequested, setIsSUDRequested] = useState(sudRequested);
  const [isSUDLoading, setIsSUDLoading] = useState(false);
  const { showGlobalExperience, customerName, sudSupported } = props;
  const { t } = useTranslation("carePlan");
  const { data: upcomingCNAppointmentSlot } = useUpcomingAppointmentSlots(
    props.careNavigator,
    AppointmentKind.FollowUpCareNavigation,
    AppointmentMedium.Phone,
  );
  const { data: upcomingTherapyAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      props.therapist,
      RequestableAppointmentKind.Therapy,
    );
  let startTime = DateTime.local()
    .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    .toISO();
  const assessments = getOr([], "riskLevels.assessments.data", props);
  const { shouldShowCoaching } = useMemberBenefits(props?.member?.id);

  if (shouldShowCoaching) {
    track(`Member qualifies for coaching -- Customer: ${customerName}`);
  }

  if (
    upcomingCNAppointmentSlot &&
    upcomingCNAppointmentSlot.appointment_slots?.available.length > 0
  ) {
    startTime = upcomingCNAppointmentSlot.appointment_slots?.available[0];
  }

  let initialStartTime;
  let medium;
  const therapyVideoStartTime =
    upcomingTherapyAppointmentSlots &&
    upcomingTherapyAppointmentSlots?.firstVirtualSlot?.appointment_slots
      ?.available[0];
  const therapyInPersonStartTime =
    upcomingTherapyAppointmentSlots &&
    upcomingTherapyAppointmentSlots?.firstInPersonSlot?.appointment_slots
      ?.available[0];

  if (therapyInPersonStartTime === null && therapyVideoStartTime) {
    initialStartTime = therapyVideoStartTime;
    medium = AppointmentMedium.Video;
  } else if (therapyVideoStartTime === null && therapyInPersonStartTime) {
    initialStartTime = therapyInPersonStartTime;
    medium = AppointmentMedium.InPerson;
  } else {
    initialStartTime =
      therapyVideoStartTime > therapyInPersonStartTime
        ? therapyInPersonStartTime
        : therapyVideoStartTime;
    medium =
      therapyVideoStartTime > therapyInPersonStartTime
        ? AppointmentMedium.InPerson
        : AppointmentMedium.Video;
  }

  const openCareNavigatorModal = (isCoachingConsultation = false) => {
    if (props.careNavigator) {
      props.openModal(modalIds.careProviderScheduleModal, {
        ...props.careNavigator,
        kind: AppointmentKind.InitialCareNavigation,
        medium: AppointmentMedium.Phone,
        providerRole: "Care Navigator",
        buttonText: t("carePlanSteps.scheduleCareCallButtonText"),
        action: AppointmentAction.Create,
        initialStartTime: startTime,
        isCoachingConsultation,
      });
    } else {
      const { to, as } = routes.TherapistsBrowse;
      Router.replace(to, as);
    }
  };

  const openTherapistModal = (isCoachingConsultation = false) => {
    if (props.therapist) {
      props.openModal(modalIds.careProviderScheduleModal, {
        ...props.therapist,
        kind: AppointmentKind.Therapy,
        medium: medium,
        providerRole: "Therapist",
        buttonText: t("carePlanSteps.confirmAppointment"),
        action: AppointmentAction.Create,
        initialStartTime: initialStartTime,
        isCoachingConsultation,
      });
    } else {
      const { to, as } = routes.TherapistsBrowse;
      Router.replace(to, as);
    }
  };

  const therapyLinkText = () => {
    return props.therapist
      ? "whatsInYourPlan.therapy.postActionText"
      : "whatsInYourPlan.therapy.actionText";
  };

  const [sudZendeskMutation] = useMutation(createSUDZendeskTicket, {
    onCompleted: () => {
      window.localStorage.setItem("sud_requested", true);
      setIsSUDRequested(true);
      props.addNotification(
        t("whatsInYourPlan.substanceUse.successNotification"),
        "success",
      );
    },
    onError: () => {
      setIsSUDRequested(false);
      setIsSUDLoading(false);
      props.addNotification(
        t("whatsInYourPlan.substanceUse.errorNotification"),
        "error",
      );
    },
  });

  return (
    <div className={classnames(styles.planWrapper, styles.momentsIcon)}>
      <Subhead center>
        <h2>{t("whatsInYourPlan.title")}</h2>
      </Subhead>
      <Grid gutter="0 36px">
        <Col lg={6}>
          <CarePlanOffering
            icon={<CareNavigatorIcon />}
            title={t("whatsInYourPlan.careNavigation.title")}
            description={
              <CareNavigatorDescription
                careNavigator={props.careNavigator}
                showGlobalExperience={props.showGlobalExperience}
              />
            }
            action={{
              text: t("whatsInYourPlan.careNavigation.actionText"),
              onClick: () => {
                track("What's In Your Plan -- Care Expert", {
                  deprecated: true,
                  replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
                    path: window.location.pathname,
                    type: "Schedule Care Navigation",
                    location: "Plan Details",
                    to: getModalNameForTracking(
                      modalIds.careProviderScheduleModal,
                    ),
                    appointment_type: getAppointmentKindForTracking(
                      AppointmentKind.InitialCareNavigation,
                    ),
                    appointment_medium: getAppointmentMediumForTracking(
                      AppointmentMedium.Phone,
                    ),
                  }),
                });
                TRACK_EVENT.BUTTON_CLICKED(
                  window.location.pathname,
                  "Schedule Care Navigation",
                  {
                    spring_doc_id: "cnsched004",
                    location: "Plan Details",
                    to: getModalNameForTracking(
                      modalIds.careProviderScheduleModal,
                    ),
                    appointment_type: getAppointmentKindForTracking(
                      AppointmentKind.InitialCareNavigation,
                    ),
                    appointment_medium: getAppointmentMediumForTracking(
                      AppointmentMedium.Phone,
                    ),
                  },
                );
                openCareNavigatorModal(false);
              },
            }}
          />
        </Col>

        <Col lg={6}>
          <CarePlanOffering
            icon={<TherapistIcon />}
            title={t("whatsInYourPlan.therapy.title")}
            description={t("whatsInYourPlan.therapy.description")}
            action={{
              text: t(therapyLinkText()),
              onClick: () => {
                const { as } = routes.TherapistsBrowse;
                track("What's In Your Plan -- Therapy", {
                  deprecated: true,
                  replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
                    page: window.location.pathname,
                    to: as,
                    type: "Schedule Therapy",
                    location: "Plan Details",
                  }),
                });
                if (props.therapist) {
                  TRACK_EVENT.LINK_CLICKED(
                    window.location.pathname,
                    "Open Modal",
                    "Schedule Therapy",
                    {
                      location: "Plan Details",
                      spring_doc_id: "therapy006",
                    },
                  );
                } else {
                  TRACK_EVENT.LINK_CLICKED(
                    window.location.pathname,
                    as,
                    "Schedule Therapy",
                    {
                      location: "Plan Details",
                      spring_doc_id: "therapy005",
                    },
                  );
                }

                openTherapistModal(false);
              },
            }}
          />
        </Col>

        <Col lg={6}>
          <CarePlanOffering
            icon={<MomentsIcon height={32} width={32} />}
            title={t("whatsInYourPlan.moments.title")}
            description={t("whatsInYourPlan.moments.description")}
            action={{
              text: t("whatsInYourPlan.moments.actionText"),
              onClick: () => {
                track("Moments Pathway - What's in my plan - Learn More");
                const tabId = 1;
                const { to, as } = routes.MemberBenefits;

                Router.replace(
                  {
                    pathname: to,
                    query: { tabId },
                  },
                  as,
                );
              },
            }}
          />
        </Col>

        {props.showMedication && isMediumOrHighRisk(assessments) && (
          <MedicationOffering />
        )}

        {shouldShowCoaching && (
          <CoachingOffering
            openCareNavigatorModal={openCareNavigatorModal}
            member={props.member}
          />
        )}

        {props.eapUrl && (
          <EAPOffering
            showGlobal={props.showGlobalExperience}
            isMinor={isMinor(props.member)}
            customerName={props.customerName}
            showCoaching={shouldShowCoaching}
          />
        )}

        <Col lg={6}>
          <CarePlanOffering
            icon={<ProgressTrackingIcon />}
            title={t("whatsInYourPlan.progress.title")}
            description={t("whatsInYourPlan.progress.description")}
          />
        </Col>
        {!showGlobalExperience && (
          <Col lg={6}>
            <CarePlanOffering
              icon={<MobileIcon />}
              title={t("whatsInYourPlan.mobileApp.title")}
              description={t("whatsInYourPlan.mobileApp.description")}
              buttons={[
                <div key={links.GooglePlayUrl} className={styles.appLinks}>
                  <GooglePlay
                    link={links.GooglePlayUrl}
                    onClick={() => track("GooglePlay Clicked -- Your Plan")}
                    ariaLabel={t(
                      "whatsInYourPlan.mobileApp.downloadAndroidApp",
                    )}
                  />
                </div>,
                <div key={links.AppStoreUrl} className={styles.appLinks}>
                  <AppStore
                    link={links.AppStoreUrl}
                    onClick={() => track("AppStore Clicked -- Your Plan")}
                    ariaLabel={t("whatsInYourPlan.mobileApp.downloadIosApp")}
                  />
                </div>,
              ]}
            />
          </Col>
        )}

        {sudSupported && (
          <Col lg={6}>
            <CarePlanOffering
              icon={<SubstanceUseIcon />}
              title={t("whatsInYourPlan.substanceUse.title")}
              description={t("whatsInYourPlan.substanceUse.description")}
              subDescription={
                isSUDRequested
                  ? t("whatsInYourPlan.substanceUse.subDescription")
                  : ""
              }
              action={
                !isSUDRequested
                  ? {
                      text: t("whatsInYourPlan.substanceUse.actionText"),
                      onClick: () => {
                        setIsSUDLoading(true);
                        if (!isSUDLoading) {
                          sudZendeskMutation();
                        }
                      },
                    }
                  : null
              }
            />
          </Col>
        )}
      </Grid>
    </div>
  );
};

EAPOffering.propTypes = {
  customerName: PropTypes.string.isRequired,
  isMinor: PropTypes.bool,
  showCoaching: PropTypes.bool,
  showGlobal: PropTypes.bool,
  showMedication: PropTypes.bool.isRequired,
};

CoachingOffering.propTypes = {
  openCareNavigatorModal: PropTypes.func.isRequired,
  member: PropTypes.object,
};

WhatsInYourPlan.propTypes = {
  careNavigator: PropTypes.object.isRequired,
  therapist: PropTypes.object.isRequired,
  coachingSupported: PropTypes.bool,
  sudSupported: PropTypes.bool,
  customerName: PropTypes.string,
  eapUrl: PropTypes.string,
  member: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  optimism: PropTypes.number,
  riskLevels: PropTypes.object,
  showGlobalExperience: PropTypes.bool,
  showMedication: PropTypes.bool,
};

WhatsInYourPlan.defaultProps = {
  showMedication: false,
  eapUrl: "",
  customerName: "",
  coachingSupported: false,
  optimism: 0,
  riskLevels: {},
};

export { WhatsInYourPlan };

const mapStateToProps = (state) => ({
  showGlobalExperience: state.global.showGlobalExperience,
});

export default connect(mapStateToProps, { openModal, addNotification })(
  WhatsInYourPlan,
);
