import { useEffect, useState } from "react";
import { checkAndSetSSORelayState } from "actions/auth/actions";

// Hook for getting and waiting for SSO Relay state information
const useIsAwaitingSsoRelayState = (ssoRelayState) => {
  const [isAwaitingSSORelayInformation, setIsAwaitingSSORelayInformation] =
    useState(true);

  // If we have an SSO relay token, call the auth service to help pre-fill form with data
  useEffect(() => {
    checkAndSetSSORelayState(ssoRelayState).finally(() => {
      setIsAwaitingSSORelayInformation(false);
    });
  }, []);

  return isAwaitingSSORelayInformation;
};

export { useIsAwaitingSsoRelayState };
