//

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { getOr, get } from "lodash/fp";
import {
  Form,
  Icon,
  Subhead,
  Select,
  Subtitle,
  Section,
  FlexCol,
} from "@spring/smeargle";
import { addNotification, removeOverlayById } from "@spring/smeargle/actions";
import Meowth from "@spring/meowth";

import styles from "./styles.module.scss";

import { getMemberCheckInFrequency } from "operations/queries/member";
import { updateMember } from "operations/mutations/member";
import reminderFrequencySchema from "schemas/reminderFrequencyForm";
import { getFirstError } from "utils/apollo/errorHandler";
import { track } from "utils/mixpanel";

class ReminderFrequencyForm extends PureComponent {
  static propTypes = {
    addNotification: PropTypes.func,
    assessmentNext: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.any,
      user: PropTypes.shape({
        id: PropTypes.any,
        member: PropTypes.shape({
          id: PropTypes.any,
        }),
      }),
    }),
    overlays: PropTypes.any,
    removeOverlayById: PropTypes.func,
    updateMember: PropTypes.func,
  };

  get frequencyTitle() {
    if (this.props.assessmentNext) {
      return "How often should we remind you to check in on progress?";
    }

    return "You can now set and realize mental health and wellness goals with Spring Health! How often should we remind you to check in on progress?";
  }

  submitEmailNotifications = ({ check_in_frequency_days }) => {
    track("Check-inFrequency Set", {
      "Form Location": this.props.assessmentNext
        ? "Initial Assessment"
        : "Set Reminder Frequency Overlay",
      Frequency: +check_in_frequency_days,
    });

    const memberId = this.props.data.user.member.id;

    this.props
      .updateMember({
        variables: {
          input: {
            id: memberId,
            patch: {
              check_in_frequency_days: +check_in_frequency_days,
            },
          },
        },
        refetchQueries: [
          {
            query: getMemberCheckInFrequency,
            variables: {
              id: this.props.data.user.id,
            },
          },
        ],
      })
      .then((res) => {
        if (!res.data.updateMember.success) {
          this.props.addNotification(
            "Something went wrong, please check your form and try again.",
            "error",
          );
        } else {
          if (this.props.overlays) {
            this.props.removeOverlayById("SetReminderFrequencyOverlay");
          }

          if (this.props.assessmentNext) {
            this.props.assessmentNext();
          }
        }
      })
      .catch((err) => {
        this.props.addNotification(getFirstError(err), "error");
      });
  };

  render() {
    const checkInFrequencyDays = get(
      "props.data.user.member.check_in_frequency_days",
      this,
    );

    if (this.props.data.loading) {
      return null;
    }

    return (
      <div>
        <Section size="lg">
          <FlexCol>
            <Subhead center bold noMargin>
              {this.frequencyTitle}
            </Subhead>
            <Section size="md">
              <Subtitle noMargin>
                For reminders, we send one email and you can always turn this
                off if it gets to be too much.
              </Subtitle>
            </Section>
          </FlexCol>
        </Section>
        <Form
          formKey="reminderFrequencyForm"
          theme="simple"
          schema={reminderFrequencySchema}
          onSubmit={this.submitEmailNotifications}
          submitIcon="arrow-right"
          submitText="Next"
          initialData={{
            check_in_frequency_days:
              typeof checkInFrequencyDays === "number"
                ? checkInFrequencyDays.toString()
                : 14,
          }}
          dataCy={"frequency-form"}
        >
          <Select
            fieldKey="check_in_frequency_days"
            placeholderPadding="130px"
            inlineLabel={
              <div className={styles.iconWrapper}>
                <Icon type="progress-check" className={styles.icon} />
                <span className={styles.labelText}>Remind me:</span>
              </div>
            }
            options={[
              { label: "Every 4 weeks", value: 28 },
              { label: "Every 2 weeks (recommended)", value: 14 },
              { label: "I'd rather not track my progress", value: 0 },
            ]}
            displayKey="label"
            searchKeys={["label", "value"]}
          />
        </Form>
      </div>
    );
  }
}

export default compose(
  graphql(getMemberCheckInFrequency, { options: Meowth.apolloOptionsUserId }),
  graphql(updateMember, { name: "updateMember" }),
  connect(
    (state) => ({
      overlays: getOr([], "modal.overlays", state),
    }),
    { addNotification, removeOverlayById },
  ),
)(ReminderFrequencyForm);
