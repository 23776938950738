//
import { connect } from 'react-redux';

import { addNotification } from '../../../actions/notifications';
import { useEffect } from 'react';


const UrlParamNotification = ({ param, value, notification, addNotification, status = 'info' }) => {

	const { search } = window.location;
	const params = Object.fromEntries( (new URLSearchParams(search)).entries() );

	useEffect(() => {
		if (checkHasParam()) {
			if (value) {
				if (params[param] === value) {
					notify();
				}
			} else {
				notify();
			}
		}
	}, []);

	const checkHasParam = () => {
		if( !search )
			return false;

		return params[param];
	};

	const notify = () => {
		addNotification(notification, status);
	};

	return null;
};

export { UrlParamNotification };
export default connect(null, { addNotification })(UrlParamNotification);