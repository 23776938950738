import { TEEN_HOMEPAGE_SECTIONS } from "modules/MemberDashboard/Teen/Home/constants";
import { ValuesOf } from "utils/typescriptHelpers";
import { AppointmentAction, modalIds } from "@spring/constants";
import { getAppointmentSlotStartTimeAndMedium } from "utils/schedulingHelpers";
import { openModal } from "@spring/smeargle/actions";

export const determineTeenHomepageSectionsToDisplay = (
  hasCompletedAssessment,
  hasUpcomingAppointments,
  hasAProvider,
): Array<ValuesOf<typeof TEEN_HOMEPAGE_SECTIONS>> => {
  if (!hasAProvider) {
    // regardless of assessment status or upcoming appointments
    return [
      TEEN_HOMEPAGE_SECTIONS.greeting,
      TEEN_HOMEPAGE_SECTIONS.findTherapistHero,
      TEEN_HOMEPAGE_SECTIONS.faq,
      TEEN_HOMEPAGE_SECTIONS.moments,
      TEEN_HOMEPAGE_SECTIONS.alsoAvailable,
    ];
  }

  if (hasUpcomingAppointments && !hasCompletedAssessment) {
    return [
      TEEN_HOMEPAGE_SECTIONS.greeting,
      TEEN_HOMEPAGE_SECTIONS.takeAssessmentHero,
      TEEN_HOMEPAGE_SECTIONS.yourCare,
      TEEN_HOMEPAGE_SECTIONS.moments,
      TEEN_HOMEPAGE_SECTIONS.faq,
      TEEN_HOMEPAGE_SECTIONS.alsoAvailable,
    ];
  }

  if (hasUpcomingAppointments && hasCompletedAssessment) {
    return [
      TEEN_HOMEPAGE_SECTIONS.greeting,
      TEEN_HOMEPAGE_SECTIONS.yourCare,
      TEEN_HOMEPAGE_SECTIONS.moments,
      TEEN_HOMEPAGE_SECTIONS.faq,
      TEEN_HOMEPAGE_SECTIONS.alsoAvailable,
    ];
  }

  if (!hasUpcomingAppointments) {
    // && hasATherapistAssigned || hasACoachAssigned, regardless of assessment status
    return [
      TEEN_HOMEPAGE_SECTIONS.greeting,
      TEEN_HOMEPAGE_SECTIONS.upNext,
      TEEN_HOMEPAGE_SECTIONS.yourCare,
      TEEN_HOMEPAGE_SECTIONS.moments,
      TEEN_HOMEPAGE_SECTIONS.faq,
      TEEN_HOMEPAGE_SECTIONS.alsoAvailable,
    ];
  }

  return [];
};

export const openUpNextSchedulingModal = (
  provider,
  kind,
  providerRole,
  shouldShowNewInsuranceModal = false,
  onScheduleModalOpen,
  buttonText,
  location,
  springDocId,
  upcomingAppointmentSlots,
  memberHasInPersonSupport,
  memberCountry,
  dispatch,
) => {
  if (!provider) return;

  if (shouldShowNewInsuranceModal) {
    onScheduleModalOpen({
      kind,
      provider,
      action: AppointmentAction.Create,
      buttonText: buttonText,
      telemetryProps: {
        location: location,
        springDocId: springDocId,
      },
    });
  } else {
    const { initialStartTime, medium } = getAppointmentSlotStartTimeAndMedium(
      upcomingAppointmentSlots,
      provider,
      memberHasInPersonSupport,
      memberCountry,
    );

    dispatch(
      openModal(modalIds.careProviderScheduleModal, {
        ...provider,
        kind,
        medium,
        mpLocation: location,
        providerRole: providerRole,
        buttonText: buttonText,
        action: AppointmentAction.Create,
        initialStartTime: initialStartTime,
      }),
    );
  }
};
