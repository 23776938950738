import React from "react";
import { Box, Text } from "design-system/components";
import { Link } from "@springcare/sh-component-library";
import { Trans, useTranslation } from "react-i18next";

const INTERACT_PSEUDOCLASSES_COLOR = "#045642";
const INTERACT_STYLING = {
  _hover: {
    color: INTERACT_PSEUDOCLASSES_COLOR,
  },
  _active: {
    color: INTERACT_PSEUDOCLASSES_COLOR,
  },
  _focus: {
    color: INTERACT_PSEUDOCLASSES_COLOR,
  },
};

export const RebookCopy = ({
  bookAnotherAppointmentCb,
  isChip = false,
}: {
  bookAnotherAppointmentCb: () => {};
  isChip?: boolean;
}) => {
  const { t } = useTranslation("careVisits");
  return (
    <Box my={16}>
      <Text variant="body_v1" mb={16}>
        <Trans
          ns="careVisits"
          i18nKey="appointmentDetails.rebookAppointmentsCopy"
          components={[<Text key={0} as="span" variant="bodyBold_v1" />]}
        />{" "}
        {isChip && <Box mb={8} />}
        <Link
          as="button"
          {...INTERACT_STYLING}
          /*
          Not entirely sure why link variants aren't automagically computing
          the hover/ focus/ active values
        */
          onClick={() => bookAnotherAppointmentCb()}
        >
          {t("appointmentDetails.scheduleNow")}
        </Link>
      </Text>
    </Box>
  );
};
