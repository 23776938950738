//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";
import { formOptions } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "SCOFF.q1": numberField(),
};
const prompt2Schema = {
  "SCOFF.q2": numberField(),
};
const prompt3Schema = {
  "SCOFF.q3": numberField(),
};
const prompt4Schema = {
  "SCOFF.q4": numberField(),
};
const prompt5Schema = {
  "SCOFF.q5": numberField(),
};
const prompt6Schema = {
  "SCOFF.q6": numberField(),
};
const prompt7Schema = {
  "SCOFF.q7": numberField(),
};

export const SCOFFSchema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...prompt7Schema,
};

const { YesNoOptions } = formOptions;

const SCOFF = [
  {
    id: "SCOFF--1",
    title: "Do you make yourself sick because you feel uncomfortably full?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="SCOFF.q1"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SCOFF--2",
    title: "Do you worry you have lost control over how much you eat?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="SCOFF.q2"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SCOFF--3",
    title: "Have you recently lost more than 14 lbs (6kg) in a 3 month period?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="SCOFF.q3"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SCOFF--4",
    title:
      "Do you believe yourself to be fat when others say you are too thin?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="SCOFF.q4"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SCOFF--5",
    title: "Would you say that food dominates your life?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="SCOFF.q5"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SCOFF--6",
    title: "Are you satisfied with your eating patterns?",
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <RadioButtons
        key={6}
        border={true}
        fieldKey="SCOFF.q6"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "SCOFF--7",
    title: "Do you ever eat in secret?",
    schema: yup.object().shape(prompt7Schema),
    questions: [
      <RadioButtons
        key={7}
        border={true}
        fieldKey="SCOFF.q7"
        options={YesNoOptions}
      />,
    ],
  },
];

export default SCOFF;
