
// Cherrim
const beforeYouScheduleModal = 'Member Before You Schedule Previsit Modal';
const suicidePreventionModal = 'Suicide Prevention Modal';
const reviewBenefitsModal = 'Review Benefits Modal';
const assessmentHelpModal = 'Assessment Help Modal';
const previewDemoDisclaimerModal = 'Preview Demo Disclaimer Modal';
const providerMatchingModal = 'Provider Matching Modal';
const beforeRequestMedicationModal = 'Before Request Medication Modal';
const addressModal = 'Address Modal';
const inPersonAppointmentDetails = 'In Person Appointment Details';
const confirmInPersonAppointment = 'Confirm In Person Appointment';
const requestInPersonModal = 'Request In Person Modal';
const checkInDetailsModal = 'Check In Details Modal';
const cancelAppointmentModal = 'Late Cancellation/Reschedule Modal';
const mobileAdvertisementModal = 'Mobile Advertisement Modal';
const ccpaModal = 'CCPA Modal';
const availabilityRequestModal = 'Availability Request Modal';
const cancellationDetailsModal = 'Cancellation Details Modal';
const informedConsentModal = 'Informed Consent Modal';
const momentsStartExerciseModal = 'Moments Start Exercise Modal';
const momentsExerciseModal = 'Moments Exercise Modal';
const momentsCompleteExerciseModal = 'Moments Complete Exercise Modal';
const exitModal = 'Exit Modal';
const noChildEmailModal = 'No Child Email';
const terminationWarning = 'Member Termination Modal';
const downloadOurMobileAppModal = 'Download Our Mobile App Modal';
const supportForParentsModal = 'Support for Parents';
const careNavigatorScheduleModal = 'Care Navigator Schedule Modal';
const memberPaymentModal = 'Member Payment Modal';
const changePeerRecoverySpecialistModal = 'Change Peer Recovery Specialist Modal'; 

// Shaymin
const assignCareProviderModal = 'Assign Care Provider Modal';
const destroyResourceModal = 'Destroy Resource Modal';
const previewCohortPlatformServicesModal = 'Preview Cohort Platform Services Modal';
const addCohortPlatformServiceModal = 'Add Cohort Platform Service Modal';
const platformServicesModal = 'Platform Services Modal';
const updateContractTermsModal = 'Update Contract Terms Modal';
const cancelAssessmentModal = 'Cancel Assessment Modal';
const confirmationModal = 'Confirmation Modal';
const censusErrorsModal = 'Census Errors Modal';
const memberTerminationModal = 'Member Termination Modal';
const planConfirmDeleteModal = 'Plan Confirm Delete Modal';
const planDiscardConfirmationModal = 'Plan Discard Confirmation Modal';
const medManagementExcessWarningModal = 'Med Management Excess Warning Modal';


// Shared
const changeCareProviderModal = 'Request Change Care Provider Modal';
const careProviderDetailModal = 'Care Provider Detail Modal';
const careProviderScheduleModal = 'Care Provider Schedule Modal';
const medManagementScheduleModal = 'Medication Manager Schedule Modal';
const backupCodesModal = 'Backup Codes Modal';
const disableTwoFactorAuthenticationModal = 'Disable Two Factor Authentication Modal';
const enterMFACodeModal = 'Enter MFA Code Modal';
const tryMomentsModal = 'Try Moments Modal';

/**
 * This is a layer of abstraction to ensure that the name of these modals
 * that is sent to immutable tracking systems is never changed.
 * The values below should not be modified without lots of communication and consideration.
 * @param {string} modalId
 * @returns {string}
 */
const getModalNameForTracking = (modalId) => ({
	[careProviderScheduleModal]         : 'Care Provider Schedule Modal',
	[cancelAppointmentModal]            : 'Cancel Appointment Modal',
	[medManagementScheduleModal]        : 'Medication Manager Schedule Modal',
	[changePeerRecoverySpecialistModal] : 'Change Peer Recovery Specialist Modal',
	'NonImmediateSuicidePrevention'     : 'Non Immediate Suicide Prevention',
	'ImmediateSuicidePrevention'        : 'Immediate Suicide Prevention',
	'Standard Suicide Prevention'       : 'Standard Suicide Prevention',
})[modalId];

module.exports = {
	beforeYouScheduleModal,
	suicidePreventionModal,
	reviewBenefitsModal,
	assessmentHelpModal,
	previewDemoDisclaimerModal,
	providerMatchingModal,
	beforeRequestMedicationModal,
	addressModal,
	inPersonAppointmentDetails,
	confirmInPersonAppointment,
	requestInPersonModal,
	checkInDetailsModal,
	cancelAppointmentModal,
	mobileAdvertisementModal,
	ccpaModal,
	availabilityRequestModal,
	cancellationDetailsModal,
	informedConsentModal,
	momentsStartExerciseModal,
	momentsExerciseModal,
	momentsCompleteExerciseModal,
	exitModal,
	noChildEmailModal,
	terminationWarning,
	downloadOurMobileAppModal,
	supportForParentsModal,
	careNavigatorScheduleModal,
	memberPaymentModal,
	changePeerRecoverySpecialistModal,

	assignCareProviderModal,
	destroyResourceModal,
	previewCohortPlatformServicesModal,
	addCohortPlatformServiceModal,
	platformServicesModal,
	updateContractTermsModal,
	cancelAssessmentModal,
	confirmationModal,
	censusErrorsModal,
	memberTerminationModal,
	planConfirmDeleteModal,
	planDiscardConfirmationModal,
	medManagementExcessWarningModal,

	changeCareProviderModal,
	careProviderDetailModal,
	careProviderScheduleModal,
	medManagementScheduleModal,
	backupCodesModal,
	disableTwoFactorAuthenticationModal,
	enterMFACodeModal,
	tryMomentsModal,

	getModalNameForTracking,
};
