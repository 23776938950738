//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";
import { formOptions } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "MDQ.q1a": numberField(),
};
const prompt2Schema = {
  "MDQ.q1b": numberField(),
};
const prompt3Schema = {
  "MDQ.q1c": numberField(),
};
const prompt4Schema = {
  "MDQ.q1d": numberField(),
};
const prompt5Schema = {
  "MDQ.q1e": numberField(),
};
const prompt6Schema = {
  "MDQ.q1f": numberField(),
};
const prompt7Schema = {
  "MDQ.q1g": numberField(),
};
const prompt8Schema = {
  "MDQ.q1h": numberField(),
};
const prompt9Schema = {
  "MDQ.q1i": numberField(),
};
const prompt10Schema = {
  "MDQ.q1j": numberField(),
};
const prompt11Schema = {
  "MDQ.q1k": numberField(),
};
const prompt12Schema = {
  "MDQ.q1l": numberField(),
};
const prompt13Schema = {
  "MDQ.q1m": numberField(),
};
const prompt14Schema = {
  "MDQ.q2": numberField(),
};
const prompt15Schema = {
  "MDQ.q3": numberField(),
};

export const MDQSchema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...prompt7Schema,
  ...prompt8Schema,
  ...prompt9Schema,
  ...prompt10Schema,
  ...prompt11Schema,
  ...prompt12Schema,
  ...prompt13Schema,
  ...prompt14Schema,
  ...prompt15Schema,
};

const { YesNoOptions } = formOptions;

const MDQ = [
  {
    id: "MDQ--1",
    title:
      "Has there ever been a period of time when you were not your usual self and you felt **so good or so hyper** that other people thought you were not your normal self or you were so hyper that you got into trouble?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key="1a"
        border={true}
        fieldKey="MDQ.q1a"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--2",
    title:
      "Has there ever been a period of time when you were not your usual self and you were **so irritable** that you shouted at people or started fights or arguments?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key="1b"
        border={true}
        fieldKey="MDQ.q1b"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--3",
    title:
      "Has there ever been a period of time when you were not your usual self and you felt **much more self-confident than usual**?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key="1c"
        border={true}
        fieldKey="MDQ.q1c"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--4",
    title:
      "Has there ever been a period of time when you were not your usual self and you got **much less sleep** than usual and found that you didn't really miss it?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key="1d"
        border={true}
        fieldKey="MDQ.q1d"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--5",
    title:
      "Has there ever been a period of time when you were not your usual self and you were **more talkative or spoke much faster** than usual?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key="1e"
        border={true}
        fieldKey="MDQ.q1e"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--6",
    title:
      "Has there ever been a period of time when you were not your usual self and **thoughts raced** through your head or you **couldn't slow your mind down**?",
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <RadioButtons
        key="1f"
        border={true}
        fieldKey="MDQ.q1f"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--7",
    title:
      "Has there ever been a period of time when you were not your usual self and you were so easily distracted by things around you that you had **trouble concentrating or staying on track**?",
    schema: yup.object().shape(prompt7Schema),
    questions: [
      <RadioButtons
        key="1g"
        border={true}
        fieldKey="MDQ.q1g"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--8",
    title:
      "Has there ever been a period of time when you were not your usual self and you had **more energy than usual**?",
    schema: yup.object().shape(prompt8Schema),
    questions: [
      <RadioButtons
        key="1h"
        border={true}
        fieldKey="MDQ.q1h"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--9",
    title:
      "Has there ever been a period of time when you were not your usual self and you were **much more active** or did many more things than usual?",
    schema: yup.object().shape(prompt9Schema),
    questions: [
      <RadioButtons
        key="1i"
        border={true}
        fieldKey="MDQ.q1i"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--10",
    title:
      "Has there ever been a period of time when you were not your usual self and you were much **more social or outgoing** than usual, for example, you telephoned friends in the middle of the night?",
    schema: yup.object().shape(prompt10Schema),
    questions: [
      <RadioButtons
        key="1j"
        border={true}
        fieldKey="MDQ.q1j"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--11",
    title:
      "Has there ever been a period of time when you were not your usual self and you were **much more interested in sex** than usual?",
    schema: yup.object().shape(prompt11Schema),
    questions: [
      <RadioButtons
        key="1k"
        border={true}
        fieldKey="MDQ.q1k"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--12",
    title:
      "Has there ever been a period of time when you were not your usual self and you did things that were unusual for you or that other people might have thought were **excessive, foolish, or risky**?",
    schema: yup.object().shape(prompt12Schema),
    questions: [
      <RadioButtons
        key="1l"
        border={true}
        fieldKey="MDQ.q1l"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--13",
    title:
      "Has there ever been a period of time when you were not your usual self and **spending money got you or your family in trouble**?",
    schema: yup.object().shape(prompt13Schema),
    questions: [
      <RadioButtons
        key="1m"
        border={true}
        fieldKey="MDQ.q1m"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--14",
    title:
      "If you selected 'yes' to more than one of the previous questions, have several of these ever happened during the **same period of time**?",
    schema: yup.object().shape(prompt14Schema),
    questions: [
      <RadioButtons
        key="2"
        border={true}
        fieldKey="MDQ.q2"
        options={YesNoOptions}
      />,
    ],
  },
  {
    id: "MDQ--15",
    title:
      "How much of a problem did any of these cause you - like being unable to work; having family, money, or legal troubles; getting into arguments or fights?",
    schema: yup.object().shape(prompt15Schema),
    questions: [
      <RadioButtons
        key="3"
        border={true}
        fieldKey="MDQ.q3"
        options={[
          { label: "No problems", value: 0 },
          { label: "Minor problem", value: 1 },
          { label: "Moderate problem", value: 2 },
          { label: "Serious problem", value: 3 },
        ]}
      />,
    ],
  },
];

export default MDQ;
