import React from "react";
import { useForm } from "react-hook-form";
import Router from "next/router";
import { useMutation, useQuery } from "@apollo/client";
import { getFirstError } from "utils/apollo/errorHandler";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOr } from "lodash/fp";

import { Button, Heading, Text, SHSelectInput } from "design-system/components";
import { addNotification } from "@spring/smeargle/actions";
import { TextButton } from "@springcare/sh-component-library";
import { Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { TRACK_EVENT } from "utils/mixpanel";
import { getAllCountries } from "operations/queries/setting";
import { createCountryChangeRequestZendeskTicket } from "operations/mutations/careProvider";
import { LanguageType } from "components/templates/MemberSettingsForm/types";
import { HelpFlyout } from "components/flyouts";

const ChangeCountry = ({ data }) => {
  const countryChangeRequest =
    window.sessionStorage.getItem("countryChangeRequested") === "true";
  const memberCountry = getOr("us", "user.member.postal_address.country", data);

  const { t, i18n } = useTranslation("limitedLangSettings");
  const dispatch = useDispatch();
  const { lang: locale } = useSelector((state: LanguageType) => state.global);
  // Control the help flyout state
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      change_country: memberCountry,
    },
  });
  const watchChangeCountry = watch("change_country");

  const {
    data: { all_countries: allCountries = [] } = {},
    loading: loadingAllCountries,
  } = useQuery(getAllCountries, { variables: { locale: locale } });
  const [useChangeCountryRequest, { loading }] = useMutation(
    createCountryChangeRequestZendeskTicket,
  );

  async function onSubmit(values) {
    const { change_country: selected_country } = values;
    try {
      const res = await useChangeCountryRequest({
        variables: { selected_country },
      });
      if (res?.data?.requestCountryChange?.success) {
        dispatch(
          addNotification(
            t("notifications.countryRequestSubmitted"),
            "success",
          ),
        );
        window.sessionStorage.setItem("countryChangeRequested", "true");
      } else {
        dispatch(addNotification(t("notifications.genericError"), "error"));
      }
    } catch (e) {
      dispatch(addNotification(getFirstError(e), "error"));
    }
  }

  const isDisable = (watchChangeCountry) => {
    return watchChangeCountry.toLowerCase() === memberCountry.toLowerCase();
  };

  const openHelpFlyout = () => {
    onOpen();
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Help Button Clicked", {});
  };

  const countryChangeRequestedVerbiage = () => (
    <Flex direction="column" py="16">
      <Text>
        {t("limitedLangSettings:changeCountry.countryChangeRequested")}
      </Text>
      <Text>
        <Trans
          ns={"limitedLangSettings"}
          i18nKey="changeCountry.countryChangeAdditionalSupport"
          components={[
            <TextButton
              fontWeight="normal"
              pt="0.3rem" // custom css added to keep previous smeargle designs
              _hover={{ textDecoration: "underline" }}
              _active={{ textDecoration: "underline" }}
              _focus={{ textDecoration: "underline" }}
              key="0-open-help-flyout"
              onClick={openHelpFlyout}
            />,
          ]}
        />
      </Text>
      <HelpFlyout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Flex>
  );

  if (loadingAllCountries) {
    return <Spinner speed="1s" size="xl" />;
  }
  return (
    <Flex
      id="change-country-section"
      direction="column"
      alignItems={"flex-start"}
    >
      <Heading fontSize={20} fontWeight={"bold"} color="#4b4b4b">
        {t("limitedLangSettings:changeCountry.title")}
      </Heading>
      {countryChangeRequest ? (
        countryChangeRequestedVerbiage()
      ) : (
        <Flex direction="column">
          <Text py="15">{t("limitedLangSettings:changeCountry.subTitle")}</Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex data-testid="change-country-dropdown" maxWidth={"450px"}>
              <SHSelectInput
                errors={errors?.change_country || null}
                required
                name="change_country"
                label="Change Country"
                hideLabel
                register={register}
                options={allCountries.map((country) => ({
                  ...country,
                  label: country.flag + " " + country.name,
                  value: country.iso2,
                }))}
                hasOptionLabel
                textAlign={i18n.dir(i18n.language) === "rtl" ? "right" : "left"}
                dataCy="change-country-select"
                autoComplete="off"
                placeholder={t("address.countryPlaceholder")}
              />
            </Flex>
            <Flex my={32}>
              <Button
                type="submit"
                variant="solid"
                colorScheme="primary"
                h={56}
                p="16px 32px"
                isLoading={loading}
                isDisabled={isDisable(watchChangeCountry)}
              >
                {t("common:form.submitText")}
              </Button>
            </Flex>
          </form>
        </Flex>
      )}
    </Flex>
  );
};

export default ChangeCountry;
