"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 * Removes a value from an array.
 *
 * @name without
 * @param {*} value
 * @param {Array} array
 * @returns {Array} Returns a new array without the value that was removed.
 *
 * @example
 * const arr = [1, 2, 3];
 * const newArr =  without(2, arr);
 *
 * console.log(arr, newArr); // [1, 2, 3], [1, 3]
 */

function curried(value, array) {
  return array.filter(function (v) {
    return v !== value;
  });
}
var without = (0, _fp.curry)(curried);
var _default = exports["default"] = without;