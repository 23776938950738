// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const LoadingCircle = (props) => {
	// Note: the `cx`, `cy` and `r` presentation attributes of the <circle> were moved from the stylesheet
	// to the jsx in order to resolve a bug where the <circle> would not appear in Safari or Firefox.
	// See https://stackoverflow.com/a/51552461/12253596 for additional details.
	return (
		<svg
			data-testid="loading-circle"
			data-cy="loading-circle"
			className={classnames(styles.default,
				styles[`color-${props.color || 'default'}`],
				{
					[styles.thick] : props.thick,
					[styles.small] : props.small,
				})}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
			aria-label="loading"
			aria-busy="true"
			aria-live="polite"
		>
			<circle cx="50" cy="50" r="35"/>
		</svg>
	);
};

export default LoadingCircle;
