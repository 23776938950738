import React from "react";

import ConsentLink from "./ConsentLink";
import { ConsentLinkType } from "./types";

interface CombinedConsentLinksPropsType {
  consentLinks: ConsentLinkType[];
  currentView: string;
  informedConsentLink: string;
  t: (key: string) => string;
}

const CombinedConsentLinks = ({
  currentView,
  informedConsentLink,
  consentLinks,
  t,
}: CombinedConsentLinksPropsType) => {
  const andWithSpaces = " " + t("common:grammar.and") + " ";
  const commaWithSpace = ", ";

  return (
    <>
      {consentLinks.map((consentLink, index) => {
        const showAnd =
          index === consentLinks.length - 1 && consentLinks.length > 1;
        const showComma = index !== 0 && consentLinks.length > 2;

        return (
          <span key={consentLink.transKey}>
            {showComma && commaWithSpace}
            {showAnd && andWithSpaces}
            <ConsentLink
              consentLink={consentLink}
              currentView={currentView}
              informedConsentLink={informedConsentLink}
              t={t}
            />
          </span>
        );
      })}
    </>
  );
};

export default CombinedConsentLinks;
