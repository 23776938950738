//

import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';

import Content from '../../typography/Content';
import Stout from '../../typography/Stout';
import Section from '../../layout/Section';
import FlexRow from '../../layout/FlexRow';
import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

import { setField } from '../../../actions/form';
import { nullFormFieldSelector } from '../../../selectors/form';

class Range extends Component {
	state = {
		value               : 0,
		triggered           : false,
		showActiveIndicator : true,
		showExact           : true,
	};

	static defaultProps = {
		disabled     : false,
		type         : 'text',
		min          : 1,
		max          : 10,
		step         : 1,
		showAsterisk : true,
	};

	handleChange = (e) => {
		const target = e.target;
		if (this.props.showActiveIndicator) {
			this.setState({ value: +target.value, triggered: true });
		}

		this.props.setField(
			this.props.formKey,
			this.props.fieldKey,
			target.value,
			true // sets dirty
		);
	};

	get label() {
		if (this.props.label) {
			return (
				<Label
					disabled={this.props.disabled}
					formKey={this.props.formKey}
					fieldKey={this.props.fieldKey}
					theme={this.props.theme}
					required={this.props.showAsterisk || this.props.required}
					hidden={this.props.hideLabel}
					for={this.props.label}
				>
					{this.props.label}
				</Label>
			);
		}

		return null;
	}

	get subtext() {
		if (this.props.subtext) {
			return (
				<Content italic>
					{this.props.subtext}
				</Content>
			);
		}

		return null;
	}

	get showValidation() {
		// show if the field has an error and is dirty
		return !!get('clientValidation.message', this.props) && (this.props.dirty || this.props.value);
	}

	get markerValues() {
		if (this.props.markers && this.props.markers.length) {
			return this.props.markers;
		}

		const { min, max } = this.props;
		const markers = [];

		for (let i = min; i <= max; i++) {
			markers.push(i);
		}

		return markers;
	}

	get markers() {
		if (this.props.showMarkers) {
			let markers = [];
			const { min, step, value } = this.props;
			const markerValues = this.markerValues;

			markers = markerValues.map((m, i) => {
				// Get the active markers, and compare to the current marker.
				const active = this.activeValues.includes(m);

				const passed = value + min > i + step;

				return (
					<span
						key={i}
						style={{ width: `${100 / markerValues.length}%` }}
						className={classnames(styles.marker, {
							[styles.active] : active,
							[styles.passed] : passed,
						})}
					>
						{m}
					</span>
				);
			});

			return (
				<div className={styles.markers}>
					{markers}
				</div>
			);
		}

		return null;
	}

	get halfway() {
		return Math.floor(((this.props.max - this.props.min) / 2) + this.props.min);
	}

	/**
	 * Calculate a range between each marker.
	 * For example, if total = 100, and there are 5 markers, range would be 20, since there's
	 * 20 values between each marker.
	 */
	get valueRange() {
		const { min, max } = this.props;
		const markers = this.markerValues;

		// Get the total number of possible values a user can select.
		const total = max - min + 1; // Add one because the min and the max are both selectable

		return total / markers.length;
	}

	/**
	 * Generates an array of exact value, label combinations.
	 * We need to know the exact value that is associated with a marker if we
	 * want to be able to calculate when to apply multiple markers, or just one!
	 *
	 * For example:
	 * min = 1, max = 100
	 * markers = [a, b, c, d, e]
	 *
	 * returns: [[10, a], [30, b], [50, c], [70, d], [90, e]]
	 * BECAUSE:
	 * ranges:
	 *    "undefined, a" : 0 - 4,   // only prints out "a" since we skip undefined values
	 *    "a"            : 5 - 15,  // exact is 10
	 *    "a, b"         : 16 - 24,
	 *    "b"            : 25 - 35, // exact is 30
	 *    ....
	 */
	get exactValues() {
		const { min } = this.props;
		const markers = this.markerValues;
		const range = this.valueRange;

		return markers.map((m, i) => {
			// We add the `min` value so that the exact value matches.
			// For example, if the values were 100 - 110, we need to make sure range (11)
			// takes into account that it's not starting at 0 or 1, but 100.
			const exact = Math.floor((range * i) + range / 2) + min;

			return [exact, m]; // exact value to match, the label for that match;
		}); // [[10, 'Not at All'], [30, 'Mildly'], ...]
	}

	get canShowActiveValues() {
		const { max, min } = this.props;
		const markers = this.markerValues;

		return this.props.showActiveIndicator && // this functionality is enabled.
			this.state.triggered &&              // the user triggered the range slider.
			markers &&                           // there are markers to show.
			markers.length <= (max - min + 1);   // there are more possible values than there are markes.
	}

	// Get the values we should show as active.
	// We return an array so we can compare single markers against possible
	// multiple active markers when the value falls between multiple options.
	get activeValues() {
		if (this.canShowActiveValues) {

			const exactValues = this.exactValues;
			const range = this.valueRange;


			/**
			 * Calculates the margin above and below a marker's value that we still consider
			 * being ONLY that value when deciding which marker to show.
			 * We wanted to capture that if 20/100 is mildly, 21/100 is probably still mildly,
			 * and not "mildly, moderately"
			 *
			 * For example, total = 100, markers = [a, b, c, d, e];
			 * range = 20, margin = 5;
			 * the exact value for `a` would be `10` (see markers.map below)
			 * therefore, we consider any value from 10 +- margin to be exact
			 * therefore, 5 - 15 all print _only_ `a`
			 */
			const margin = Math.ceil(range * 0.25); // 1

			// Get the current selected value
			const { value } = this.state;

			let label;
			const lastMarker = exactValues[exactValues.length - 1]; // the last marker pair in the array

			/**
			 * Loop through the exact values we calculated, and figure out the best label
			 * match for the selected value.
			 */
			exactValues.forEach((arr, i) => {
				// If value is an exact match, only use the exact marker, every time.
				if (value === arr[0]) {
					label = [arr[1]];
				}

				if (!label) {
					// the next value in the array, for when displaying multiple markers.
					const next = exactValues[i + 1] || [];

					// IF current value >= the current markers' exact value + the margin
					//    2 >= 1 + 1
					// AND IF current value <= the next marker's exact value - the margin
					//    2 <= 3 - 1
					// THEN show both markers.
					// This is to capture when a selected value is directly in between 2 values
					if (
						value >= arr[0] + margin &&
						value <= next[0] - margin // the next exact value, minus margin
					) {
						label = [arr[1], next[1]];

						// Otherwise, if the value is ONLY inside one margin for a marker, show that marker.
						//    14 <= 10 + 5
						// THEN show only this marker
						// This is to allow breathing room for values before we combine multiple values.
					} else if (value <= arr[0] + margin) {
						label = [arr[1]];
					}
				}
			});

			// FINALLY.
			// If the value is at either extreme (beginning or end of the number line) show the first or last value.
			//   VALUE is 3, EXACT for first marker is 10, show only marker for 10, instead of "undefined, marker"
			if (!label) {
				if (value <= exactValues[0][0]) { // if it's the first one, don't combine
					label = [exactValues[0][1]];
				} else if (value >= lastMarker[0]) { // if it's the last one, don't combine.
					label = [lastMarker[1]];
				}
			}

			return label;
		}

		return [];
	}

	get activeIndicator() {
		return (
			<div className={styles.activeIndicatorWrapper}>
				<Section size="lg">
					<FlexRow justification="center">
						<Content bold>{this.activeValues.join(', ')}</Content>
					</FlexRow>
				</Section>
			</div>
		);
	}

	get labelIndicators() {
		if (this.props.leftLabel && this.props.rightLabel) {
			return (
				<FlexRow justification="space-between">
					<Stout>{this.props.leftLabel}</Stout>
					<Stout>{this.props.rightLabel}</Stout>
				</FlexRow>
			);
		}

		return null;
	}

	getAriaValueText() {
		let activeValues = this.activeValues;

		if (Array.isArray(activeValues) && activeValues.length === 0) return null;

		if (!activeValues) return null;

		if (typeof activeValues === 'string') activeValues = [activeValues];

		return activeValues.join(' to ');
	}

	render() {
		return (
			<InputWrapper
				valid={this.showValidation}
				theme={this.props.theme}
				for="range"
				normalFlow
			>
				{this.label}
				{this.subtext}
				{this.activeIndicator}
				<input
					id={this.props.label}
					className={classnames(styles.rangeField, {
						[styles.invalid]       : this.showValidation,
						[sharedStyles.invalid] : this.showValidation,
					})}
					required={this.props.required}
					min={this.props.min}
					max={this.props.max}
					step={this.props.step}
					onChange={this.handleChange}
					value={this.props.value || (this.halfway)}
					type="range"
					data-cy={this.props.dataCy ? `${this.props.dataCy}-range-input` : ''}
					aria-valuetext={this.getAriaValueText()}
				/>
				{this.markers}
				{this.labelIndicators}
			</InputWrapper>
		);
	}
}

export { Range };
export default connect(
	(state, ownProps) => nullFormFieldSelector(state, ownProps),
	{ setField }
)(Range);
