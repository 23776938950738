/**
 * Enums for passing in Visit Type
 * where necessary.
 */

const VisitType = Object.freeze({
	BasicOnly          : 'BASIC_ONLY',
	BasicAndSpecialist : 'BASIC_AND_SPECIALIST',
});

module.exports = VisitType;