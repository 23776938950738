//

import { flow } from "lodash/fp";
import { setIn } from "@spring/immutability";

import { SET_PROMPT_VALIDATION } from "actions/assessment/types";

export const initialState = {
  prompts: {},
};

const assessment = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMPT_VALIDATION:
      return flow(setIn(`prompts.${action.id}.valid`, action.valid))(state);

    default:
      return state;
  }
};

export default assessment;
