/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { useQuery } from "@apollo/client";
import Router, { useRouter } from "next/router";
import { DateTime } from "luxon";
import { InfoIcon } from "design-system/assets";
import { Tooltip, SHRecommendationsCard, Flex } from "design-system/components";
import { mxTheme } from "design-system";

import {
  Section,
  ContentPage,
  Bolded,
  LoadingCircle,
  Stout,
  Content,
  Grid,
  Col,
  FlexRow,
  HugIcon,
} from "@spring/smeargle";
import { EditIcon } from "components/atoms";
import Meowth from "@spring/meowth";
import { modalIds, ProviderRole } from "@spring/constants";
import {
  addOverlay,
  openModal,
  openFlyout,
  setField,
  initializeForm,
} from "@spring/smeargle/actions";
import { truncateString } from "@spring/immutability";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import Listing from "components/templates/Browse/Listing";
import NoProvidersFound from "components/templates/Browse/Listing/NoProvidersFound";
import BrowseHeader from "components/templates/Browse/Listing/BrowseHeader";
import MultiSelectParent from "components/templates/Browse/Listing/MultiSelectParent";
import MemberDashboardPageWrapper from "components/templates/MemberDashboard/PageWrapper";
import {
  CareProviderDetailModal,
  CareProviderScheduleModal,
  BeforeYouScheduleModal,
  BeforeRequestMedicationModal,
  AddressModal,
  InPersonAppointmentDetailsModal,
  ConfirmInPersonAppointmentModal,
  RequestInPersonModal,
  CareProviderScheduleModalV2,
} from "components/modals";
import { ProviderFilterFlyout } from "components/flyouts";

import styles from "./styles.module.scss";

import routes from "routes";
import Snorlax from "utils/snorlax";
import {
  EVENT_TYPE,
  makeEventString,
  track,
  TRACK_EVENT,
} from "utils/mixpanel";
import { filterTagsByProviderType } from "utils/displayHelpers";
import { isMinor } from "utils/memberHelpers";
import { getMemberInfo, getMemberAddress } from "operations/queries/member";
import {
  getCareProviderTags,
  getCareTeam,
} from "operations/queries/careProvider";
import { clickableDivProps } from "lib/accessibility_helpers";

import { isCNSchedulingOrNoScheduling } from "utils/global";
import { DedicatedProviderSwitch } from "components/templates/Browse/Listing/DedicatedProviderSwitch";
import { formatMemberExactAge } from "utils/displayHelpers";
import NoSponsoredSessions from "components/templates/Browse/ProviderBrowsePage/components/HeaderSection/NoSponsoredSessions";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";
import { useScheduleModalWithProps } from "shared/hooks";

const useMemberData = () => {
  const {
    data: memberInfoData,
    loading: memberInfoLoading,
    refetch: memberInfoRefetch,
  } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const { data: memberAddressData, loading: memberAddressLoading } = useQuery(
    getMemberAddress,
    Meowth.apolloOptionsUserId(),
  );

  const getCareTeamVars = { id: memberInfoData && memberInfoData.user.id };

  const { data: careTeamData, loading: careTeamLoading } = useQuery(
    getCareTeam,
    {
      variables: getCareTeamVars,
      skip: !memberInfoData,
    },
  );

  // Pipe the data to avoid repetition
  const memberInfo = memberInfoData && memberInfoData?.user;
  const memberAddress = memberAddressData && memberAddressData?.user;

  return {
    data: { memberInfo, memberAddress, careTeamData },
    loading: memberInfoLoading || memberAddressLoading || careTeamLoading,
    refetch: memberInfoRefetch,
  };
};

const TimeZoneSection = (props) => {
  const { t } = useTranslation("careProvider");
  const luxonDateTime = DateTime.local().setLocale(props.locale);
  const timeZoneOffsetName = getOr(
    "your local time zone",
    "offsetNameLong",
    luxonDateTime,
  );

  if (props.isAMinor) {
    return (
      <Grid gap="0 16px" alignment="end" justification="space-between">
        <Col md={6}>
          <div className={styles.childInitialVisitSection}>
            <FlexRow alignment="center" justification="start">
              <span className={styles.hugIcon}>
                <HugIcon />
              </span>
              <span>
                {t("minorFixedTag.title", { filterText: props.tagData })}
              </span>
            </FlexRow>
          </div>
        </Col>
        <Col md={6}>
          <div
            className={classnames(styles.timeZoneOffsetName, styles.withBanner)}
          >
            <Trans
              ns={"careProvider"}
              i18nKey={"timeZoneSection.timeZone"}
              values={{ timeZoneOffsetName: timeZoneOffsetName }}
            >
              <b>{timeZoneOffsetName}</b>
            </Trans>
          </div>
        </Col>
      </Grid>
    );
  }

  return (
    <div>
      <div className={styles.timeZoneOffsetName}>
        <Trans
          ns={"careProvider"}
          i18nKey={"timeZoneSection.timeZone"}
          values={{ timeZoneOffsetName: timeZoneOffsetName }}
        >
          <b>{timeZoneOffsetName}</b>
        </Trans>
      </div>
    </div>
  );
};

TimeZoneSection.propTypes = {
  isAMinor: PropTypes.bool,
};

const Address = ({ memberAddress, openModal, shouldDisableField }) => {
  const { t } = useTranslation("careProvider");

  if (!memberAddress.member.full_address_available) {
    return null;
  }

  const { street_address_1, city, state, zip_code, country } = getOr(
    {},
    "postal_address",
    memberAddress.member,
  );
  const address = [
    truncateString(street_address_1, 15),
    truncateString(city, 15),
    state,
    zip_code,
    country,
  ]
    .filter(Boolean)
    .join(", ");
  const disableCursor = shouldDisableField ? styles.disabled : "";
  const disableButtonStyles = shouldDisableField
    ? mxTheme.semanticTokens.colors["content-disabled"].default
    : "";

  // TODO: 3 tracking events for clicking an edit address button seems a little much
  // - nick
  const onClick = () => {
    if (shouldDisableField) return;

    openModal(modalIds.addressModal, {
      noAddress: false,
    });

    track('Browse Provider Page - Address "Edit" Clicked', {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: "Edit Address",
        to: "Edit Address Modal",
      }),
    });

    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Edit Address", {
      spring_doc_id: "directsched011",
      to: "Edit Address Modal",
    });

    TRACK_EVENT.MODAL_OPENED(window.location.pathname, "Edit Address", {
      spring_doc_id: "directsched012",
    });
  };

  return (
    <div className={styles.addressContainer}>
      <Section size="sm">
        <Stout>
          {t("browserPageWrapper.yourLocation")}
          <span className={styles.tooltip}>
            <Tooltip
              hasArrow
              label={t("browserPageWrapper.tooltipLocation")}
              p="24px"
              placement="top"
            >
              <InfoIcon color="platform.900" width="14px" height="14px" />
            </Tooltip>
          </span>
        </Stout>
      </Section>
      <div
        aria-label={"Edit your location, current address is: " + address}
        className={`${styles.addressEditContainer} ${disableCursor}`}
        onClick={onClick}
        {...clickableDivProps({ onClick: onClick })}
      >
        <Content>
          <Bolded>
            <FlexRow justification="space-between" alignment="center">
              {/* need to set height to avoid the address shifting when EditIcon is not rendered */}
              <span style={{ color: disableButtonStyles, height: "24px" }}>
                {address}
              </span>
              {!shouldDisableField && (
                <div className={styles.editAddressPencil}>
                  <EditIcon />
                </div>
              )}
            </FlexRow>
          </Bolded>
        </Content>
      </div>
    </div>
  );
};

Address.propTypes = {
  memberAddress: PropTypes.shape({
    member: PropTypes.shape({
      full_address_available: PropTypes.any,
    }),
  }),
  openModal: PropTypes.func,
  shouldDisableField: PropTypes.bool,
};

const TravelDisclaimer = ({ scrollToSection }) => {
  const { t } = useTranslation("careProvider");

  return (
    <div className={styles.travelDisclaimerWrapper}>
      <div className={styles.travelDisclaimer}>
        {t("browserPageWrapper.travelDisclaimer")}
        <button
          type="button"
          alias="TherapistsBrowse"
          onClick={() => scrollToSection()}
          className={styles.travelDisclaimerLink}
        >
          {
            // mpTracking={{ pageName: '', linkName: '' }}>} NOTE: Add mixpanel tracking on this
          }
          {t("browserPageWrapper.travelDisclaimerLink")}
        </button>
      </div>
    </div>
  );
};

TravelDisclaimer.propTypes = {
  scrollToSection: PropTypes.func,
};

const CareProviderBrowsePageWrapper = (props) => {
  const { t } = useTranslation("careProvider");
  const [isDedicatedProviderToggleOn, setIsDedicatedProviderToggleOn] =
    useState(false);

  const { data, loading: memberDataLoading } = useMemberData();
  const pageRefs = useRef({});

  const { data: careProviderTagData, loading: careProviderTagLoading } =
    useQuery(getCareProviderTags, {
      skip: Meowth.apolloSkipUserId(),
      fetchPolicy: "no-cache",
    });

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const needsTravelDisclaimer = useFeatureFlag(FLAGS.TRAVEL_DISCLAIMER);
  const isDependent = data.memberInfo?.member?.covered_life?.dependent ?? false;
  const showDedicatedProviderToggle =
    useFeatureFlag(FLAGS.SHOW_DEDICATED_PROVIDER_FILTER) &&
    props.providerType === ProviderRole.Therapist &&
    data.memberInfo?.member?.cohort?.has_dedicated_providers &&
    !isDependent;

  const router = useRouter();
  const shouldFilterByFamiliesSpecialty = Object.keys(router.query).includes(
    "Families",
  );

  const filteredProviderTags = filterTagsByProviderType(
    careProviderTagData,
    props.providerType,
  );
  const fixedTag = filteredProviderTags?.find(
    (tag) => tag.id === props.tagData?.fixedTag,
  )?.name;

  const shouldUseBrecsRoute = isBrecsVariant(
    useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT),
  );
  const showInPersonMedMan = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );

  useEffect(() => {
    if (
      props.isFormInitialized &&
      shouldFilterByFamiliesSpecialty &&
      careProviderTagData
    ) {
      const careProviderFamiliesTag =
        careProviderTagData?.care_provider_tags?.data.find(
          (tag) => tag.name === "Families",
        ).id || [];
      props.setField(
        "browseProviderTagsTemp",
        "specialtiesTags",
        [careProviderFamiliesTag],
        true,
      );
      props.initializeForm("browseProviderTags", null, {
        specialtiesTags: [careProviderFamiliesTag],
      });
    }
  }, [
    props.isFormInitialized,
    shouldFilterByFamiliesSpecialty,
    careProviderTagData,
  ]);

  const schedulingAccessName =
    data.memberInfo?.member.cohort.contract_term.scheduling_access.name;
  const validSchedulingAccess = !isCNSchedulingOrNoScheduling(
    schedulingAccessName,
    props.providerType,
  );

  const loading = memberDataLoading || careProviderTagLoading;

  const isAMinor = isMinor(getOr({}, "memberInfo.member", data));
  const isMemberUnderSix =
    formatMemberExactAge(data.memberInfo?.member?.date_of_birth) < 6;
  const country = data.memberInfo?.member?.postal_address?.country;
  const isGlobal = country && country.length > 0 && country !== "US";

  const canShowRecommendationsCard =
    props.providerType === ProviderRole.Therapist &&
    !isAMinor &&
    !isGlobal &&
    !shouldUseBrecsRoute;

  const {
    visits_covered_total: coveredTherapyCount,
    visits_remaining_total: remainingTherapyCount,
  } = data?.memberInfo?.member ?? {};

  const isGlobalWithNoVisits =
    props.showGlobalExperience &&
    remainingTherapyCount === 0 &&
    coveredTherapyCount >= 0;

  function scrollToSection() {
    pageRefs.current["findProvider"]?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (data.memberInfo) {
      if (!data.memberInfo.member.full_address_available) {
        props.openModal(modalIds.addressModal),
          {
            noAddress: true,
          };
      }
    }
  }, [data.memberInfo]);

  useEffect(() => {
    if (
      isMemberUnderSix &&
      [routes.TherapistsBrowse.as].includes(Router.asPath)
    ) {
      Router.replace(routes.MemberCareVisits.as, routes.MemberCareVisits.as);
    }
  }, [isMemberUnderSix, Router.asPath]);

  return (
    <MemberDashboardPageWrapper
      wide
      allowed={props.allowed}
      float={false}
      darkBg={false}
      bordered={false}
      headerBackground="transparent"
      showCheckIn={false}
      shadedBackground
    >
      <div role="main">
        <ContentPage>
          {loading && <LoadingCircle />}
          {!loading && (
            <>
              <div
                role="heading"
                aria-level="1"
                aria-label={t("browseHeader.label")}
              >
                <BrowseHeader
                  memberInfo={data.memberInfo}
                  providerType={props.providerType}
                  tagData={filteredProviderTags}
                  validSchedulingAccess={validSchedulingAccess}
                />
              </div>
              {isGlobalWithNoVisits ? (
                <NoSponsoredSessions
                  showHeader={false}
                  providerType={props.providerType}
                  memberInfoData={data.memberInfo.member}
                />
              ) : (
                <>
                  {canShowRecommendationsCard && (
                    <SHRecommendationsCard
                      marginTop={0}
                      marginBottom={40}
                      onClick={() => {
                        const { to, as } = routes.RecommendedTherapists;
                        Router.push(
                          {
                            pathname: to,
                            query: { viaBrowseTherapistPage: true },
                          },
                          as,
                        );

                        TRACK_EVENT.BUTTON_CLICKED(
                          window.location.pathname,
                          "See Your Recommendations",
                          {
                            location: "Browse Therapists",
                            to: routes.RecommendedTherapists.as,
                          },
                        );
                      }}
                      title={t(
                        "recommendedProviders.recommendationEntryCard.title",
                      )}
                      subTitle={t(
                        "recommendedProviders.recommendationEntryCard.subtitle",
                      )}
                      submitBtnText={t(
                        "recommendedProviders.recommendationEntryCard.submitBtn",
                      )}
                      dataCy="recommendation-button"
                      ariaLabel="recommendation-button"
                      trackingText="Recommendations Button"
                      TRACK_EVENT={TRACK_EVENT}
                    />
                  )}

                  {validSchedulingAccess && (
                    <>
                      <div className={styles.filterAndAddressContainer}>
                        <Flex direction="column">
                          <MultiSelectParent
                            providerType={props.providerType}
                            tagData={filteredProviderTags}
                            memberInfo={data.memberInfo}
                            shouldDisableFilters={isDedicatedProviderToggleOn}
                          />
                          {showDedicatedProviderToggle && (
                            <DedicatedProviderSwitch
                              companyName={data.memberInfo.member.cohort.customer.name.trim()}
                              isDedicatedProviderToggleOn={
                                isDedicatedProviderToggleOn
                              }
                              setIsDedicatedProviderToggleOn={
                                setIsDedicatedProviderToggleOn
                              }
                            />
                          )}
                        </Flex>
                        <div>
                          <Address
                            openModal={props.openModal}
                            memberAddress={data.memberAddress}
                            shouldDisableField={isDedicatedProviderToggleOn}
                          />
                          {needsTravelDisclaimer && (
                            <TravelDisclaimer
                              scrollToSection={scrollToSection}
                              pageRefs={pageRefs}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {props.children}

                  {validSchedulingAccess &&
                    (isAMinor ? props.tagData?.fixedTag : true) && (
                      <>
                        <TimeZoneSection
                          openModal={props.openModal}
                          locale={props.locale}
                          memberInfo={data.memberInfo}
                          tagData={fixedTag}
                          isAMinor={isAMinor}
                        />
                        <Listing
                          providerType={props.providerType}
                          tagData={props.tagData}
                          pageRefs={pageRefs}
                          filteredProviderTags={filteredProviderTags}
                          showDedicatedProviders={isDedicatedProviderToggleOn}
                          onScheduleModalOpen={onScheduleModalOpen}
                        />
                      </>
                    )}

                  {!validSchedulingAccess && <NoProvidersFound />}
                </>
              )}
            </>
          )}
        </ContentPage>
        <CareProviderDetailModal />
        <CareProviderScheduleModal />
        <BeforeYouScheduleModal />
        <BeforeRequestMedicationModal />
        <AddressModal showSubmit />
        <InPersonAppointmentDetailsModal />
        <ConfirmInPersonAppointmentModal />
        <RequestInPersonModal />
        <ProviderFilterFlyout
          providerType={props.providerType}
          inPersonSupported={
            data.memberInfo?.member?.cohort?.in_person_supported ||
            (showInPersonMedMan &&
              data.memberInfo?.member?.cohort
                ?.in_person_med_managment_supported)
          }
        />
        <CareProviderScheduleModalV2
          isOpen={isScheduleModalOpen}
          onClose={onScheduleModalClose}
          {...scheduleModalProps}
        />
      </div>
    </MemberDashboardPageWrapper>
  );
};

CareProviderBrowsePageWrapper.propTypes = {
  addOverlay: PropTypes.func,
  allowed: PropTypes.bool,
  children: PropTypes.any,
  openModal: PropTypes.func,
  overlays: PropTypes.shape({
    includes: PropTypes.func,
  }),
  providerType: PropTypes.any,
  tagData: PropTypes.any,
};

CareProviderBrowsePageWrapper.defaultProps = { allowed: true };

const mapStateToProps = (state) => ({
  // TODO: change this to a formal selector
  // TODO: Remove the Temp portion once the Rotom work in MXENG-2985 is merged

  showGlobalExperience: state.global.showGlobalExperience,
  locale: state.global.lang,
  tagData:
    state.form.browseProviderTagsTemp && state.form.browseProviderTagsTemp.data,
  isFormInitialized: Object.keys(state.form).length > 0,
  overlays: getOr([], "modal.overlays", state),
  allowed: () =>
    Snorlax(state)
      .loggedIn.to(routes.SignIn)
      .isMember(routes["Logout"])
      .hasCompletedInitialAssessment(routes.MemberExpectations),
});

const mapDispatchToProps = {
  addOverlay,
  openModal,
  openFlyout,
  setField,
  initializeForm,
};

export { CareProviderBrowsePageWrapper };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CareProviderBrowsePageWrapper);
