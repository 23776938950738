import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FALSY_CHILD_ERR_STRING = 'Tabs component received a falsy child, skipping rendering of falsy child';
const defaultActiveTab = 0;

const Tabs = ({ children, fixedTabWidth, stopPropagation, ...props }) => {
	const [activeTab, setActiveTab] = useState(props.activeTab || defaultActiveTab);
	
	useEffect(() => {
		const newActiveTab = props.activeTab > children.length - 1 ? defaultActiveTab : props.activeTab || defaultActiveTab;
		setActiveTab(newActiveTab);
	}, [children.length]);

	const falsyChildrenCount = children.filter(child => !child).length;
	if (falsyChildrenCount > 0) {
		console.warn(FALSY_CHILD_ERR_STRING);
	}
	const adjustedLength = children.length - falsyChildrenCount;

	const handleTabClick = (index, event) => {
		if (stopPropagation){
			event.stopPropagation();
		}

		setActiveTab(index);
	};
	
	const tabsListRef = useRef(null);

	const moveFocusToTab = (tab) => tabsListRef.current.querySelectorAll('button')[tab].focus(); 
	
	const handleTabSelected = (currentIndex, event) => {
		event.preventDefault();
		const keyCode = event.code;
		
		const indexOfFirstTab = 0;
		const indexOfLastTab = adjustedLength - 1;
		const indexOfPreviousTab = currentIndex > 0 ? currentIndex - 1 : adjustedLength - 1;
		const indexOfNextTab = currentIndex < adjustedLength - 1 ? currentIndex + 1 : 0;

		const getSelectedTabIndex = () => {
			switch (keyCode) {
				case 'ArrowLeft':
					return indexOfPreviousTab;
					
				case 'ArrowRight':
					return indexOfNextTab;
		
				case 'Home':
					return indexOfFirstTab; 
		
				case 'End':
					return indexOfLastTab;	
		
				default:
					break;
			}
		};
		
		const nextTab = getSelectedTabIndex(keyCode);

		return moveFocusToTab(nextTab);
	};

	const HoCChildren = () => {
		const width = fixedTabWidth ? '100px' : `${100 / adjustedLength}%`;
		return children
			.filter((child) => child)
			.map((child, index) => {
				return React.cloneElement(child, {
					key     : child.key || index,
					panelId : props.panelId,
					onClick : handleTabClick,
					active  : index === +activeTab,
					handleTabSelected,
					index,
					width,
				});
			});
	};

	const ActiveTabContent = () => {
		const truthyChildren = children.filter((child) => child);
		if (truthyChildren[activeTab]) {
			return truthyChildren[activeTab].props.children;
		}

		return null;
	};

	const underlineStyles = () => {
		const width = fixedTabWidth ? '100px' : `${100 / adjustedLength}%`;
		const underlineStyles = {
			width            : width,
			insetInlineStart : `calc(${width} * ${activeTab})`,
		};

		return underlineStyles;
	};

	if (props.showSingleTab) {
		return (
			<div>
				<div>
					{props.singleTabTitle}
				</div>
				<ActiveTabContent />
			</div>
		);
	}

	return (
		<div className="tabs">
			<div className={styles.tabList}>
				<div className={styles.tabListWrapper} role="tablist" ref={tabsListRef}>
					{ HoCChildren() }
					<li
						style={underlineStyles()}
						className={styles.underline}
						role="presentation"
					>
						<div className={styles.line} />
					</li>
				</div>
			</div>
			<div
				id={`tabpanel-${props.panelId || ''}${activeTab+1}`}
				className="tabContent"
				role="tabpanel"
				aria-labelledby={`tab-${props.panelId || ''}${activeTab+1}`}
			>
				<ActiveTabContent />
			</div>
		</div>
	);
};

Tabs.propTypes = {
	children        : PropTypes.node,
	activeTab       : PropTypes.number,
	fixedTabWidth   : PropTypes.bool,
	stopPropagation : PropTypes.bool,
	panelId         : PropTypes.string, // Only required when a single page is rendering multiple tabs
};

Tabs.defaultProps = {
	activeTab       : 0,
	stopPropagation : false,
};

export default Tabs;
export { FALSY_CHILD_ERR_STRING };
