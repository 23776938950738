export const Sleep = ({ color, height = "24", width = "24" }) => {
  const fill = color || "#045642";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {color ?? (
        <rect
          width="24"
          height="24"
          rx="12"
          fill="#058261"
          fill-opacity="0.12"
        />
      )}
      <g clip-path="url(#clip0_654_12627)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.9068 3.97945C17.689 3.39079 17.1277 3 16.5 3C15.8723 3 15.311 3.39079 15.0932 3.97945L14.5223 5.52231L12.9794 6.09322C12.3908 6.31104 12 6.87234 12 7.5C12 8.12766 12.3908 8.68896 12.9794 8.90678L14.5223 9.47769L15.0932 11.0206C15.311 11.6092 15.8723 12 16.5 12C17.1277 12 17.689 11.6092 17.9068 11.0206L18.4777 9.47769L20.0206 8.90678C20.6092 8.68896 21 8.12766 21 7.5C21 6.87234 20.6092 6.31104 20.0206 6.09322L18.4777 5.52231L17.9068 3.97945ZM15.6897 6.68973L16.5 4.5L17.3103 6.68973L19.5 7.5L17.3103 8.31027L16.5 10.5L15.6897 8.31027L13.5 7.5L15.6897 6.68973Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.9651 4.56661C10.1184 4.29529 10.0879 3.95744 9.88857 3.71791C9.68921 3.47838 9.36251 3.38709 9.06786 3.48858C5.53788 4.70443 3 8.05483 3 11.9999C3 16.9705 7.02944 20.9999 12 20.9999C15.3649 20.9999 18.2975 19.153 19.8409 16.4211C19.9942 16.1498 19.9637 15.8119 19.7644 15.5724C19.565 15.3329 19.2383 15.2416 18.9436 15.3431C18.1787 15.6065 17.3569 15.7499 16.5 15.7499C12.3579 15.7499 9 12.3921 9 8.24994C9 6.90981 9.35082 5.65388 9.9651 4.56661ZM4.5 11.9999C4.5 9.39232 5.83104 7.09437 7.85187 5.75029C7.62274 6.5443 7.5 7.38311 7.5 8.24994C7.5 13.2205 11.5294 17.2499 16.5 17.2499C16.8034 17.2499 17.1035 17.2349 17.3994 17.2055C16.0347 18.6207 14.1198 19.4999 12 19.4999C7.85786 19.4999 4.5 16.1421 4.5 11.9999Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_654_12627">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
