import { useEffect } from "react";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import routes from "routes";
import Snorlax from "utils/snorlax";
import Meowth from "@spring/meowth";

import { PageBase } from "components/layout";
import { updateUser } from "operations/mutations/user";

import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import Header from "components/organisms/MemberExpectations/components/Header";
import styles from "components/organisms/MemberExpectations/styles.module.scss";
import { TRACK_EVENT } from "utils/mixpanel/events";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useMemberBenefits } from "hooks";

import SpringPreview from "./SpringPreview";
import ContentSection from "./ContentSection";

const EnhancedMemberExpectations = ({
  allowed,
  agedOutConsentRecord,
  lang,
  memberId,
  memberName,
  selectedOption,
}) => {
  const router = useRouter();

  const { isCoachingEnabled } = useMemberBenefits(memberId);
  const isAgeOutEnabled = useFeatureFlag(FLAGS.ENABLE_AGING_OUT_P1);
  // If a member has an agedOutRecord and not taken an adult IA, then we display different prompt text
  const hasAgedOutRecord =
    isAgeOutEnabled && !!agedOutConsentRecord?.created_at;

  const springPreviewEnabled = useFeatureFlag(
    FLAGS.EXPECTATIONS_SPRING_PREVIEW,
  );
  const showSpringPreview = springPreviewEnabled && !hasAgedOutRecord;

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const [updateUserLang, { loading: updateUserLangLoading = true }] =
    useMutation(updateUser);

  const handleButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      routes.MemberExpectations.as,
      "Enhanced Member Expectations Begin",
      {
        is_aged_out: hasAgedOutRecord,
      },
    );
    router.push(
      routes.TakeInitialAssessment.to,
      routes.TakeInitialAssessment.as,
    );
  };

  const handleSkipButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      routes.MemberHome.as,
      "Enhanced Member Expectations Skip",
      {
        is_aged_out: hasAgedOutRecord,
      },
    );
    router.push(routes.MemberHome.to, routes.MemberHome.as);
  };

  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      routes.MemberExpectations.as,
      "Enhanced Member Expectations",
      {
        page_version: "Enhanced Member Expectations",
        is_aged_out: hasAgedOutRecord,
      },
    );
  }, [hasAgedOutRecord]);

  return (
    <PageBase
      className={styles.pageBase}
      darkBg={false}
      allowed={allowed}
      redirect={routes.SignIn}
    >
      <Box marginRight={["24", "initial"]}>
        <Header
          alwaysWhite={true}
          isLoggedIn={true}
          updateUserLang={updateUserLang}
        />
      </Box>

      <Flex
        as="main"
        direction={["column", "column", "row"]}
        justifyContent="center"
        align="center"
        justify="space-between"
        gap={["v-24", "v-24", "v-12", "v-24"]}
        maxW={
          showSpringPreview ? "1200px" : ["100%", "85%", "70%", "55%", "40%"]
        }
        p="0"
        pt={isMobile ? 0 : "initial"}
        m="0 auto"
      >
        {showSpringPreview && <SpringPreview isMobile={isMobile} />}

        <ContentSection
          handleButtonClick={handleButtonClick}
          handleSkipButtonClick={handleSkipButtonClick}
          hasAgedOutRecord={hasAgedOutRecord}
          isCoachingEnabled={isCoachingEnabled}
          isMobile={isMobile}
          lang={lang}
          memberName={memberName}
          showSpringPreview={showSpringPreview}
          updateUserLangLoading={updateUserLangLoading}
          selectedOption={selectedOption}
        />
      </Flex>
    </PageBase>
  );
};

EnhancedMemberExpectations.propTypes = {
  allowed: PropTypes.func,
  lang: PropTypes.string,
  memberName: PropTypes.string,
};

EnhancedMemberExpectations.defaultProps = {
  allowed: true,
  lang: "en",
  memberName: null,
};

const mapStateToProps = (state) => ({
  allowed: () =>
    Snorlax(state)
      .loggedIn.to(routes.SignIn)
      .isMember(routes["Logout"])
      .customerIsLaunched(Meowth.getCustomerId(), routes.ComingSoon)
      .hasNotCompletedInitialAssessment(routes.MemberHome),
  lang: state?.global?.lang,
  memberId: state?.auth?.userIdentifiers?.user?.member?.id,
  memberName: state?.auth?.userIdentifiers?.user?.first_name,
  agedOutConsentRecord:
    state?.auth?.userIdentifiers?.user?.member?.aged_out_consent_fields,
  selectedOption: state?.auth?.userIdentifiers?.user?.member?.intent_option,
});

export { EnhancedMemberExpectations };
export default connect(mapStateToProps)(EnhancedMemberExpectations);
