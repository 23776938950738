import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";

export const trackMemberToggledWorkplaceAccess = (canMemberAccessWorkplace) => {
  TRACK_EVENT.BUTTON_CLICKED(
    Router.asPath,
    canMemberAccessWorkplace ? "Workplace Enabled" : "Workplace Disabled",
    {
      spring_doc_id: canMemberAccessWorkplace
        ? "Manager_Experience_013"
        : "Manager_Experience_014",
      location: "Account Settings",
    },
  );
};
