export const IS_ALLOWED_URI =
  /^(https*:\/\/)[\w]+[-\w]*(\.[-\w]+)+(\/[-\w]*)*/i; // eslint-disable-line no-useless-escape

export const IS_SCRIPT_OR_DATA = /(script|data|src)/i;

// remove fragments per https://developers.google.com/safe-browsing/reference
export const getUrlWithoutFragments = (url) => {
  const fragmentIndex = url.indexOf("#");
  return fragmentIndex === -1 ? url : url.slice(0, fragmentIndex);
};

export const escapeHTML = (chatMessage) => {
  return chatMessage.replace(/[&<>"']/g, (individualCharacter) => {
    switch (individualCharacter) {
      case "&":
        return "&amp;";
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case '"':
        return "&quot;";
      case "'":
        return "&#x27;";
      // remove whitespace characters per https://developers.google.com/safe-browsing/reference
      case "0x09": // tab
        return "";
      case "0x0d": // carriage return
        return "";
      case "0x0a": // line feed
        return "";
      default:
        return individualCharacter;
    }
  });
};

export const convertMessageToHaveClickableLinks = (chatMessage) => {
  const escapedChatMessage = escapeHTML(chatMessage);
  const escapedChatMessageArray = escapedChatMessage.split(" ");
  const checkedEscapedChatMessageArray = escapedChatMessageArray.map(
    (escapedChatMessage) => {
      if (
        IS_ALLOWED_URI.test(escapedChatMessage) &&
        !IS_SCRIPT_OR_DATA.test(escapedChatMessage)
      ) {
        const urlWithoutFragments = getUrlWithoutFragments(escapedChatMessage);
        return `<a href="${urlWithoutFragments}" target="_blank">${urlWithoutFragments}</a>`;
      }
      return escapedChatMessage;
    },
  );
  return checkedEscapedChatMessageArray.join(" ");
};
