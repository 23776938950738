import { useTranslation } from "hooks/react-i18next";
import {
  Box,
  HStack,
  Flex,
  Text,
  useMediaQuery,
  useDisclosure,
} from "design-system/components";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { Plus, Filters } from "design-system/assets";
import ProviderFilterFlyout from "components/templates/Browse/ProviderBrowsePage/components/Modals/ProviderFilterFlyout";
import { DedicatedProviderSwitch } from "components/templates/Browse/Listing/DedicatedProviderSwitch";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { trackFilterButtonClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { ProviderRole } from "@spring/constants";

type FilterButtonProps = {
  index: number;
  filterCategory: object;
  t: any;
};

const ALL_FILTER_CATEGORIES = [
  { mediums: "mediums" },
  { days_of_week: "days_of_week" },
  { time_of_day: "time_of_day" },
  { conditions: "conditions" },
  { specialties: "specialties" },
  { genders: "genders" },
  { ethnicities: "ethnicities" },
  { languages: "languages" },
  { moreFilters: "" },
];

const DEFAULT_FILTER_CATEGORIES = [
  { mediums: "mediums" },
  { conditions: "conditions" },
  { specialties: "specialties" },
  { moreFilters: "" },
];

const DEFAULT_FILTER_CATEGORIES_WITH_AVAIL = [
  { mediums: "mediums" },
  { days_of_week: "days_of_week" },
  { time_of_day: "time_of_day" },
  { conditions: "conditions" },
  { specialties: "specialties" },
  { moreFilters: "" },
];

const THERAPIST_FILTER_CATEGORIES = [
  { mediums: "mediums" },
  { days_of_week: "days_of_week" },
  { time_of_day: "time_of_day" },
  { conditions: "conditions" },
  { specialties: "specialties" },
  { moreFilters: "" },
];

const MED_MANAGER_FILTER_CATEGORIES = [
  { conditions: "conditions" },
  { specialties: "specialties" },
  { moreFilters: "" },
];

const MED_MANAGER_FILTER_CATEGORIES_WITH_AVAIL = [
  { days_of_week: "days_of_week" },
  { time_of_day: "time_of_day" },
  { conditions: "conditions" },
  { specialties: "specialties" },
  { moreFilters: "" },
];

const COACH_FILTER_CATEGORIES = [
  { specialties: "specialties" },
  { genders: "genders" },
  { ethnicities: "ethnicities" },
  { languages: "languages" },
];

const COACH_FILTER_CATEGORIES_WITH_AVAIL = [
  { days_of_week: "days_of_week" },
  { time_of_day: "time_of_day" },
  { specialties: "specialties" },
  { genders: "genders" },
  { ethnicities: "ethnicities" },
  { languages: "languages" },
];

const FilterSection = () => {
  const { t } = useTranslation(["careProvider", "common"]);
  const [isLessThan600px] = useMediaQuery("(max-width: 600px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    memberInfo,
    possibleFilters,
    queriedFilters,
    setActiveAccordionIndex,
    isCompanyExclusive,
    setIsCompanyExclusive,
    providerType,
  } = useProviderBrowseContext();

  const showAvailFilters = useFeatureFlag(FLAGS.AVAILABILITY_FILTER_RELEASE);
  const showInPersonMedMan = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );
  const inPersonMedManagementSupported =
    memberInfo?.user?.member?.cohort?.in_person_med_management_supported;
  const showDedicatedProviderToggle =
    possibleFilters?.allow_company_exclusive &&
    ![ProviderRole.MedicationManager, ProviderRole.Coach].includes(
      providerType,
    );

  let filterCategories = ALL_FILTER_CATEGORIES;

  if (
    providerType == ProviderRole.MedicationManager &&
    showInPersonMedMan &&
    inPersonMedManagementSupported &&
    showAvailFilters
  ) {
    filterCategories = DEFAULT_FILTER_CATEGORIES_WITH_AVAIL;
  } else if (
    providerType == ProviderRole.MedicationManager &&
    showInPersonMedMan &&
    inPersonMedManagementSupported
  ) {
    filterCategories = DEFAULT_FILTER_CATEGORIES;
  } else if (
    providerType == ProviderRole.MedicationManager &&
    showAvailFilters
  ) {
    filterCategories = MED_MANAGER_FILTER_CATEGORIES_WITH_AVAIL;
  } else if (providerType == ProviderRole.MedicationManager) {
    filterCategories = MED_MANAGER_FILTER_CATEGORIES;
  } else if (providerType == ProviderRole.Coach && showAvailFilters) {
    filterCategories = COACH_FILTER_CATEGORIES_WITH_AVAIL;
  } else if (providerType == ProviderRole.Coach) {
    filterCategories = COACH_FILTER_CATEGORIES;
  } else if (providerType == ProviderRole.Therapist && showAvailFilters) {
    filterCategories = THERAPIST_FILTER_CATEGORIES;
  } else {
    filterCategories = DEFAULT_FILTER_CATEGORIES;
  }

  function sumOfSelectedFilters(filterArrays, excluded, excludedKeys) {
    let sum = 0;
    if (excluded) {
      for (const key in filterArrays) {
        if (!excludedKeys.includes(key)) {
          sum += filterArrays[key].length;
        }
      }
    } else {
      for (const key in filterArrays) {
        sum += filterArrays[key].length;
      }
    }
    return sum;
  }

  const FilterButton = ({ index, filterCategory, t }: FilterButtonProps) => {
    const { isMobile } = useProviderBrowseContext();
    const filterLabel = Object.keys(filterCategory)[0];
    const filterKey = Object.values(filterCategory)[0];

    const displayBtn = isMobile
      ? filterLabel === "moreFilters" || filterLabel === "sessionType"
        ? "block"
        : "none"
      : "flex";

    return (
      <Box
        display={displayBtn}
        as="button"
        px={16}
        py={18}
        border="2px solid #B4B9BB"
        borderRadius={8}
        whiteSpace="nowrap"
        overflow="auto"
        cursor={isCompanyExclusive ? "not-allowed" : "pointer"}
        onClick={() => {
          if (isCompanyExclusive) return;
          trackFilterButtonClicked(t(`multiSelectParent.${filterLabel}.label`));

          if (filterLabel === "moreFilters") {
            setActiveAccordionIndex(-1);
          } else {
            setActiveAccordionIndex(index);
          }
          onOpen();
        }}
        data-cy={filterLabel}
      >
        <HStack
          spacing={8}
          align="center"
          color={isCompanyExclusive ? "tertiary.400" : ""}
        >
          <Text variant="bodyBold_v1">
            {t(`multiSelectParent.${filterLabel}.label`)}
          </Text>

          {filterKey !== "" && queriedFilters.filters[filterKey].length > 0 && (
            <Text variant="body_v1">
              {queriedFilters.filters[filterKey].length}
            </Text>
          )}

          {/* "More Filters" tally count for desktop views */}
          {filterLabel === "moreFilters" &&
            !isMobile &&
            sumOfSelectedFilters(queriedFilters.filters, true, [
              "days_of_week",
              "time_of_day",
              "conditions",
              "mediums",
              "specialties",
            ]) > 0 && (
              <Text variant="body_v1">
                {sumOfSelectedFilters(queriedFilters.filters, true, [
                  "days_of_week",
                  "time_of_day",
                  "conditions",
                  "mediums",
                  "specialties",
                ])}
              </Text>
            )}

          {/* "More Filters" tally count for tablet views */}
          {filterLabel === "moreFilters" &&
            isMobile &&
            sumOfSelectedFilters(queriedFilters.filters, true, ["mediums"]) >
              0 && (
              <Text variant="body_v1">
                {sumOfSelectedFilters(queriedFilters.filters, true, [
                  "mediums",
                ])}
              </Text>
            )}

          {filterLabel === "moreFilters" && (
            <Plus
              boxSize={24}
              color={isCompanyExclusive ? "tertiary.400" : ""}
            />
          )}
        </HStack>
      </Box>
    );
  };

  const MobileFilterButton = ({ t }) => {
    const selectedFiltersSum = sumOfSelectedFilters(
      queriedFilters.filters,
      false,
      [],
    );

    return (
      <Box
        as="button"
        w="100%"
        px={16}
        py={18}
        border="2px solid #B4B9BB"
        borderRadius={8}
        whiteSpace="nowrap"
        overflow="auto"
        cursor={isCompanyExclusive ? "not-allowed" : "default"}
        onClick={() => {
          if (isCompanyExclusive) return;
          setActiveAccordionIndex(0);
          onOpen();
        }}
      >
        <Flex
          justify="space-between"
          color={isCompanyExclusive ? "tertiary.400" : ""}
        >
          {selectedFiltersSum > 0 ? (
            <Text variant="bodyBold_v1">{selectedFiltersSum} selected</Text>
          ) : (
            <Text variant="bodyBold_v1">
              {t("multiSelectParent.noneSelected.label")}
            </Text>
          )}
          {!isCompanyExclusive && <Filters boxSize={24} ml={8} />}
        </Flex>
      </Box>
    );
  };

  return (
    <Box mb={16}>
      <Text variant="bodyBold_v1" color="platform.base" mb={4}>
        {t("multiSelectParent.title")}
      </Text>
      {memberInfo && possibleFilters && !isLessThan600px && (
        <>
          <HStack spacing={8} mb={12}>
            {filterCategories.map((filterCategory, index) => (
              <FilterButton
                key={index}
                index={index}
                filterCategory={filterCategory}
                t={t}
              />
            ))}
          </HStack>
          {showDedicatedProviderToggle && (
            <Box data-testid="dedicated-provider-switch">
              <DedicatedProviderSwitch
                isDedicatedProviderToggleOn={isCompanyExclusive}
                setIsDedicatedProviderToggleOn={setIsCompanyExclusive}
                isNewBrowse={true}
              />
            </Box>
          )}
        </>
      )}

      {memberInfo && possibleFilters && isLessThan600px && (
        <>
          <MobileFilterButton t={t} />
          {showDedicatedProviderToggle && (
            <Box mt={12} data-testId="dedicated-provider-switch">
              <DedicatedProviderSwitch
                isDedicatedProviderToggleOn={isCompanyExclusive}
                setIsDedicatedProviderToggleOn={setIsCompanyExclusive}
                isNewBrowse={true}
              />
            </Box>
          )}
        </>
      )}

      <ProviderFilterFlyout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Box>
  );
};

export default FilterSection;
