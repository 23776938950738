import COLORS from '../../../../../constants/colors';

import React from 'react';

const EAPIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill={COLORS.PRIMARY_500} fillRule="evenodd" clipRule="evenodd" d="M1.33355 6.66657C1.33355 2.98473 4.31827 0 8.00012 0C11.682 0 14.6667 2.98473 14.6667 6.66657C14.6667 9.80657 12.4958 12.4395 9.57307 13.1465L24.5237 10.4282C22.6698 9.81052 21.3333 8.06131 21.3333 5.99991C21.3333 3.42262 23.4226 1.33331 25.9999 1.33331C28.5771 1.33331 30.6665 3.42262 30.6665 5.99991C30.6665 7.46366 29.9925 8.77 28.9381 9.6256L30.4279 9.35471C31.1524 9.22298 31.8465 9.70351 31.9783 10.428C32.11 11.1525 31.6295 11.8466 30.905 11.9783L16.0997 14.6702C16.5293 14.7023 16.9195 14.9409 17.1433 15.3138L21.1432 21.9804C21.3904 22.3923 21.3969 22.9053 21.1602 23.3233C20.9235 23.7413 20.4803 23.9997 19.9999 23.9997H12.0001C11.5197 23.9997 11.0765 23.7413 10.8398 23.3233C10.6031 22.9053 10.6096 22.3923 10.8568 21.9804L14.8567 15.3138C14.9962 15.0812 15.2005 14.9009 15.4387 14.7904L1.57206 17.3116C0.847564 17.4433 0.153463 16.9628 0.0217376 16.2383C-0.109988 15.5138 0.370543 14.8197 1.09503 14.688L8.82335 13.2828C8.5536 13.316 8.27885 13.3331 8.00012 13.3331C4.31827 13.3331 1.33355 10.3484 1.33355 6.66657ZM8.00012 2.66663C5.79101 2.66663 4.00017 4.45746 4.00017 6.66657C4.00017 8.87568 5.79101 10.6665 8.00012 10.6665C10.2092 10.6665 12.0001 8.87568 12.0001 6.66657C12.0001 4.45746 10.2092 2.66663 8.00012 2.66663ZM16 18.5913L17.6451 21.333H14.3549L16 18.5913ZM23.9999 5.99991C23.9999 4.89536 24.8953 3.99994 25.9999 3.99994C27.1044 3.99994 27.9998 4.89536 27.9998 5.99991C27.9998 7.10447 27.1044 7.99989 25.9999 7.99989C24.8953 7.99989 23.9999 7.10447 23.9999 5.99991Z"/>
	</svg>
);

export default EAPIcon;
