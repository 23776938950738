const ETHNICITIES = [
	{ label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native' },
	{ label: 'Asian', value: 'Asian' },
	{ label: 'Black or African American', value: 'Black or African American' },
	{ label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
	{ label: 'White', value: 'White' },
	{ label: 'Other', value: 'Other' },
	{ label: 'Unknown', value: 'Unknown' },
];

module.exports = ETHNICITIES;
