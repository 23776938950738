import { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { Container, Flex } from "design-system/components";
import { loginlessConfirmAppointment } from "operations/mutations/appointment/";

import {
  AlreadyConfirmed,
  Cancelled,
  Confirmed,
  Error,
  Loading,
  Passed,
} from "./components";
import { TRACK_EVENT } from "utils/mixpanel";

/**
 * Helper method to track page views in mix panel.
 * @param {string} apptId appointment uuid pulled from url.
 * @param {object} data response data object with some appointment details
 * @param {string} urlPath path from router
 */
const triggerTrackPageView = (apptId, data, urlPath) => {
  const apptData = data.appointment;
  let pageVersion = "error";
  if (apptData.derived_status === "Confirmed") {
    pageVersion = data.success ? "success" : "error - already confirmed";
  } else if (apptData.derived_status === "Cancelled") {
    pageVersion = "error - appointment cancelled";
  } else if (apptData.derived_status === "Passed") {
    pageVersion = "error - appointment passed";
  }

  TRACK_EVENT.PAGE_VERSION_VIEWED(urlPath, "Appointment Confirmation", {
    page_version: pageVersion,
    appointment_id: apptId ?? "",
    appointment_type: apptData.type ?? "",
    appointment_time_utc: apptData.start_at ?? "",
  });
};

const renderComponent = ({ data, loading }) => {
  const { appointment, success } = data?.loginlessConfirmAppointment || {};
  const { derived_status: status } = appointment || {};

  if (status === "Confirmed" && success)
    return <Confirmed appointment={appointment} />;
  else if (status === "Confirmed" && !success)
    return <AlreadyConfirmed appointment={appointment} />;
  else if (status === "Cancelled")
    return <Cancelled appointment={appointment} />;
  else if (status === "Passed") return <Passed appointment={appointment} />;
  else if (loading) return <Loading />;

  return <Error />;
};

const LoginlessConfirmAppointment = ({ appointmentId, memberId }) => {
  const [confirmAppointmentMutation, { data, loading }] = useMutation(
    loginlessConfirmAppointment,
  );

  if (!loading && data) {
    triggerTrackPageView(
      appointmentId,
      data.loginlessConfirmAppointment,
      window.location.pathname,
    );
  }

  useEffect(() => {
    confirmAppointmentMutation({
      variables: { input: { id: appointmentId, memberId } },
    }).catch(() => {});
  }, []);

  return (
    <Container maxW="613px">
      <main>
        <Flex direction="row" align="start">
          {renderComponent({ data, loading })}
        </Flex>
      </main>
    </Container>
  );
};

export default LoginlessConfirmAppointment;
