import { Button, Card } from "@springcare/sh-component-library";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useCreateCheckInMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import routes from "routes";
import { GoalsGraph } from "./GoalsGraph";
import { TRACK_EVENT } from "utils/mixpanel";

interface GoalProgress {
  member_goal_progress: {
    trend: {
      description: string;
    };
  };
}

interface JourneySectionContentProps {
  hasUpcomingAppointments: boolean;
  memberId: string;
  goalProgress: GoalProgress;
}

export const JourneySectionContent: React.FC<
  React.PropsWithChildren<JourneySectionContentProps>
> = ({ hasUpcomingAppointments, memberId, goalProgress }) => {
  const { t } = useTranslation("homepage");
  const router = useRouter();
  const [createCheckInMutation] = useCreateCheckInMutation(memberId, router);
  const takeCheckIn = () => {
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Take Check In", {
      to: "Assessments",
      location: "Your Progress",
      primary_cta: hasUpcomingAppointments,
    });
    createCheckInMutation();
  };
  const redirectToSchedule = () => {
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Schedule Session", {
      to: "Schedule Page",
      location: "Your Progress",
      primary_cta: !hasUpcomingAppointments,
    });
    router.push(routes.MemberCareVisits.as);
  };

  return (
    <Card
      variant="high-emphasis"
      size="lg"
      width={"100%"}
      borderColor={"border-subtle"}
    >
      <Flex
        direction={["column", "column", "column", "row"]}
        width={"100%"}
        marginTop={[0, 0, 0, 36]}
      >
        <Box width={{ base: "100%", lg: "40%" }}>
          <Box pb={[16, 16, 16, 24]}>
            <Heading variant="sm_v1">
              {goalProgress?.member_goal_progress?.trend?.description ||
                t("journeySection.ctaSection.heading")}
            </Heading>
          </Box>
          <Box pb={[16, 16, 16, 24]}>
            <Text>{t("journeySection.ctaSection.description")}</Text>
          </Box>
          <Flex direction={["column", "column", "column", "row"]}>
            <Box order={hasUpcomingAppointments ? 2 : 1} pb={[16, 16, 16, 24]}>
              <Button
                colorScheme={hasUpcomingAppointments ? "primary.base" : "base"}
                variant={
                  hasUpcomingAppointments ? "no-emphasis" : "low-emphasis"
                }
                size="md"
                w={["100%", "100%", "100%", "auto"]}
                onClick={redirectToSchedule}
              >
                {t("journeySection.ctaSection.scheduleButton")}
              </Button>
            </Box>
            <Box order={hasUpcomingAppointments ? 1 : 2} pb={[16, 16, 16, 24]}>
              <Button
                colorScheme={hasUpcomingAppointments ? "base" : "primary.base"}
                variant={
                  hasUpcomingAppointments ? "low-emphasis" : "no-emphasis"
                }
                size="md"
                w={["100%", "100%", "100%", "auto"]}
                onClick={takeCheckIn}
              >
                {t("journeySection.ctaSection.checkInButton")}
              </Button>
            </Box>
          </Flex>
        </Box>
        <Box
          width={{ base: "100%", lg: "60%" }}
          marginLeft={[0, 0, 0, 24]}
          marginTop={[24, 24, 0, 0]}
        >
          <GoalsGraph
            memberGoalProgress={goalProgress?.member_goal_progress}
          ></GoalsGraph>
        </Box>
      </Flex>
    </Card>
  );
};
