/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React from "react";
import { Headline, Subtitle, Bolded } from "@spring/smeargle";
import { Trans } from "react-i18next";

import { LotusSvg } from "components/atoms";

import Slide from "./Slide";

const Success = ({ who }) => {
  const getSuccessText = () => {
    switch (who) {
      case "dependent_adult":
        return (
          <Trans
            ns={"limitedLangAuth"}
            i18nKey={"forms.dependentSignupForm.success.dependent_adult"}
          >
            <Bolded />
          </Trans>
        );
      case "dependent_child":
        return (
          <Trans
            ns={"limitedLangAuth"}
            i18nKey={"forms.dependentSignupForm.success.dependent_child"}
          >
            <Bolded />
          </Trans>
        );
      default:
        return (
          <Trans
            ns={"limitedLangAuth"}
            i18nKey={"forms.dependentSignupForm.success.default"}
          >
            <Bolded />
          </Trans>
        );
    }
  };

  return (
    <Slide empty>
      <Headline center>
        <LotusSvg />
      </Headline>

      <Headline center>Thank you!</Headline>

      <Subtitle center>{getSuccessText()}</Subtitle>
    </Slide>
  );
};

Success.propTypes = {
  who: PropTypes.any,
};

export default Success;
