import { useEffect } from "react";
import { Alert } from "@springcare/sh-component-library";
import { useMediaQuery } from "@chakra-ui/react";
import { VCelebrationConfettiIcon } from "@springcare/verdant-icons-react";
import { useTranslation } from "react-i18next";
import Router from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";
import { AccountTransitionBannerProps } from "./types";
import { FAQ_LINK_FOR_AGING_OUT_RANGE } from "modules/shared/components/Notifications/constants/AccountTransitions.constants";

export const AccountTransitionBanner: React.FC<
  React.PropsWithChildren<AccountTransitionBannerProps>
> = ({ accountTransitionRange }) => {
  const { t } = useTranslation("accountTransition");
  const [isDesktop] = useMediaQuery("(min-width: 993px)");
  const translatedText = t(`alert.${accountTransitionRange}.content`);

  useEffect(() => {
    TRACK_EVENT.COMPONENT_VIEWED(Router.asPath, "Account transition banner", {
      spring_doc_id: "agingOut010",
    });
  }, []);

  const handleButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Account transition banner", {
      spring_doc_id: "agingOut011",
      to: FAQ_LINK_FOR_AGING_OUT_RANGE[accountTransitionRange],
    });

    window.open(FAQ_LINK_FOR_AGING_OUT_RANGE[accountTransitionRange], "_blank");
  };

  return (
    <Alert
      colorScheme="base"
      title={t("accountTransitioned", { ns: "accountTransition" })}
      icon={VCelebrationConfettiIcon}
      content={translatedText}
      primaryAction={{
        children: t(`alert.${accountTransitionRange}.button`),
        onClick: handleButtonClick,
      }}
      showIcon="on"
      showTitle="on"
      size="medium"
      type={isDesktop ? "one-line" : "standard"}
    />
  );
};
