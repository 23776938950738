import { gql } from "@apollo/client";

const updateCarePreferences = gql`
  mutation updateCarePreference(
    $member_id: ID!
    $input: UpdateMemberCarePreferenceInput!
  ) {
    updateCarePreference(member_id: $member_id, input: $input) {
      success
      care_preference {
        genders
        specialties
        ethnicities
        mediums
        therapy_styles
        preference_type
        status
        # time_of_day
        # days_of_week
      }
    }
  }
`;

export default updateCarePreferences;
