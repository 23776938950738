import { useTranslation } from "react-i18next";
import { Button } from "@springcare/sh-component-library";
import {
  Flex,
  FormLabel,
  FormControl,
  SHTextArea,
  SimpleGrid,
  SHOutlinedCircleCheckBox,
} from "design-system/components";

import { Text } from "@chakra-ui/react";

type Props = {
  feedback_text: string | undefined;
  comments: string | undefined;
  feedback_sentiment: string | undefined;
  feedback_enum: string | undefined;
};

const GenAIFeedbackForm = ({
  feedback,
  setFeedback,
  handleFeedbackSubmit,
}: {
  feedback: Props;
  setFeedback: React.Dispatch<React.SetStateAction<Props>>;
  handleFeedbackSubmit: (e) => void;
}) => {
  const { t } = useTranslation(["careProvider"]);
  const FEEDBACK_CATEGORIES = [
    {
      feedback_sentiment: "POSITIVE",
      feedback_text: t(
        "recommendedProviders.providerCardGenAI.feedbackForm.infoHelpfulBtn",
      ),
      feedback_enum: "HELPFUL",
    },
    {
      feedback_sentiment: "NEGATIVE",
      feedback_text: t(
        "recommendedProviders.providerCardGenAI.feedbackForm.infoIncorrectBtn",
      ),
      feedback_enum: "INCORRECT",
    },
    {
      feedback_sentiment: "NEGATIVE",
      feedback_text: t(
        "recommendedProviders.providerCardGenAI.feedbackForm.infoNotHelpfulBtn",
      ),
      feedback_enum: "NOT_HELPFUL",
    },
  ];

  const setFeedbackData = (category) => {
    const { feedback_text, feedback_sentiment, feedback_enum } = category;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      feedback_text,
      feedback_sentiment,
      feedback_enum,
    }));
  };
  const setFeedbackComment = (comment) => {
    setFeedback((prevFeedback) => ({ ...prevFeedback, comments: comment }));
  };

  const handleFeedbackCommentChange = (text) => {
    setFeedbackComment(text);
  };

  const showError = () => {
    if (!feedback?.feedback_text && feedback?.comments) {
      return true;
    }
  };

  const FeedbackOption = ({ setFeedbackData, feedback, category }) => {
    const handleFeedbackClick = () => {
      if (feedback?.feedback_enum === category.feedback_enum) {
        // If clicked feedback is same as selected, clear it
        setFeedback(null);
      } else {
        // Otherwise, set new feedback
        setFeedbackData(category);
      }
    };

    const isSelected = feedback?.feedback_enum === category.feedback_enum;

    return (
      <SHOutlinedCircleCheckBox
        label={category.feedback_text}
        fieldKey={category.feedback_enum}
        key={category.feedback_enum}
        onChange={handleFeedbackClick}
        checked={isSelected}
        isVerdant
      />
    );
  };

  return (
    <form>
      <SimpleGrid columns={1}>
        {showError() && (
          <FormControl>
            <FormLabel mb={8}>
              <Text size="body-medium-strong" color="negative-base">
                {t(
                  "recommendedProviders.providerCardGenAI.feedbackForm.errorText",
                )}
              </Text>
            </FormLabel>
          </FormControl>
        )}
        <FormControl as="fieldset">
          <SimpleGrid columns={1}>
            {FEEDBACK_CATEGORIES?.map((category) => (
              <FeedbackOption
                setFeedbackData={setFeedbackData}
                feedback={feedback}
                category={category}
                key={category.feedback_enum}
              />
            ))}
          </SimpleGrid>
        </FormControl>

        <FormControl>
          <FormLabel color="content-primary" mb={12}>
            {t(
              "recommendedProviders.providerCardGenAI.feedbackForm.commentPrompt",
            )}
          </FormLabel>
          <SHTextArea
            value={feedback?.comments}
            onChange={handleFeedbackCommentChange}
            placeholder={t(
              "recommendedProviders.providerCardGenAI.feedbackForm.commentPlaceholder",
            )}
            isVerdant
          />
        </FormControl>
      </SimpleGrid>

      <Flex justify="end" pt={24} pb={16}>
        <Button
          type="submit"
          onClick={(e) => handleFeedbackSubmit(e)}
          disabled={!feedback?.feedback_text}
        >
          {t("recommendedProviders.providerCardGenAI.feedbackForm.submitBtn")}
        </Button>
      </Flex>
    </form>
  );
};

export default GenAIFeedbackForm;
