import { graphql } from "modules/shared/graphql-codegen";

export const getMemberCareItems = graphql(`
  query getMemberCareItems($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        care_items {
          recommended_care_items {
            care_item_name
            metadata
          }
          available_care_items {
            care_item_name
            metadata
          }
          metadata
        }
      }
    }
  }
`);
