import { gql } from "@apollo/client";

const getAppointmentWithUserId = gql`
  query getAppointmentWithUserId($id: ID!) {
    appointment(id: $id) {
      id
      duration
      start_at
      kind
      medium
      status
      location
      paid
      attended
      bookings {
        id
        name
        role
        avatar
        calendar {
          id
          user {
            id
          }
        }
      }
    }
  }
`;

export default getAppointmentWithUserId;
