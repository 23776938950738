import { gql } from "@apollo/client";

const getMemberConnectedBenefits = gql(`
  query GetMemberConnectedBenefits($member_id: ID!, $recommended_only: Boolean) {
    member_connected_benefits(member_id: $member_id, recommended_only: $recommended_only) {
      benefits {
        id
        name
        benefits_type
        logo_url
        benefits_url
        dependent_enabled
        description
        recommended
      }
    }
  }
`);

export default getMemberConnectedBenefits;
