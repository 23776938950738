// 
/**
 * Pass in a promise to receive a wrapped version that is cancelable in flight.
 *
 * A typical use case to cancel a promise is in your component's
 * componentWillUnmount or useEffect equivalent.
 *
 * Taken mostly from https://stackoverflow.com/a/37492399/17186166
 * See the link for how-to-use details.
 */
export const makeCancelable = (promise) => {
	let hasCanceled_ = false;

	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then((val) =>
			// This differs from the link, because we don't want red error
			// text in the console upon a successfully canceled promise
			!hasCanceled_ && resolve(val)
		);
		promise.catch((error) =>
			hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
		);
	});

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true;
		},
	};
};
