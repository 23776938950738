import { useCallback, useRef } from "react";

type UseLimitCallsReturnType = [(fn: () => void) => void, () => void];

export const useLimitCalls = (
  maxCallCount = 1,
  check = true,
): UseLimitCallsReturnType => {
  const callCountRef = useRef(0);

  const call = useCallback(
    (fn: () => void) => {
      if (callCountRef.current < maxCallCount && check) {
        callCountRef.current += 1;
        fn();
      }
    },
    [check, maxCallCount],
  );

  const reset = useCallback(() => {
    callCountRef.current = 0;
  }, []);

  return [call, reset];
};
