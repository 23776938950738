import React from "react";
import { IconButton } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Heading,
  ModalHeaderProps,
  Text,
  useBreakpointValue,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
} from "@chakra-ui/react";
import {
  VXIcon,
  VInternationalIcon,
  VArrowLeftIcon,
} from "@springcare/verdant-icons-react";
import { HelpLanguageMenu } from "components/organisms/MemberHelpCard/HelpLanguageMenu";

const NewSHFlyoutHeader = ({
  title = "",
  caption,
  variant = "base",
  closeButton = "on",
  backButton = "off",
  layout = "",
  onBack,
  onClose,
  helpInfo,
  handleSetCountry,
}) => {
  const showCloseButton = closeButton === "on";
  const isBaseVariant = variant === "base";
  const showBackButton = backButton === "on";
  let [titleWidth, titlePaddingTop, titleOrder] = !showBackButton
    ? ["", "", 0]
    : ["100%", "8px", 4];

  const onMobile = useBreakpointValue({
    base: true,
    sm: false,
  });
  let titleHeight = "auto";
  let titleTextSize = "heading-small";

  if (onMobile && layout === "compact") {
    titleWidth = "";
    titlePaddingTop = "";
    titleOrder = 2;
    titleHeight = "40px";
    titleTextSize = "body-medium-strong";
  }
  return (
    <Flex
      direction="column"
      padding="v-24"
      pb="v-16"
      gap="v-4"
      borderTopLeftRadius="v-xl"
      borderTopRightRadius="v-xl"
      backgroundColor={isBaseVariant ? "initial" : "background-subtle"}
      borderBottom={isBaseVariant ? "none" : "1px"}
      borderColor="border-subtle"
    >
      <Flex
        justify="space-between"
        gap="v-2"
        flexWrap={showBackButton && showCloseButton ? "wrap" : "nowrap"}
      >
        <Flex
          height={titleHeight}
          textAlign={onMobile && layout === "compact" ? "center" : "start"}
          paddingTop={titlePaddingTop}
          order={titleOrder}
          width={titleWidth}
          minHeight="v-40"
        >
          <Flex direction="column" gap="v-4" justifyContent="center">
            <Flex alignItems="center">
              <Heading size={titleTextSize}>{title}</Heading>
            </Flex>
            {Boolean(caption?.length) && !showBackButton && (
              <Box w="fit-content">
                <Text size="body-small-regular">{caption}</Text>
              </Box>
            )}
          </Flex>
        </Flex>

        <Flex
          justifyContent={showBackButton && "space-between"}
          width={showBackButton && "100%"}
        >
          {showBackButton && (
            <Box order={1} flex="initial">
              <IconButton
                icon={VArrowLeftIcon}
                variant="medium-emphasis"
                size="sm"
                aria-label="Back button"
                onClick={onBack}
              />
            </Box>
          )}

          {!showBackButton && (
            <Menu>
              <MenuButton
                w={40}
                h={40}
                me={2}
                bg={"tertiary.50"}
                color={"accent-on-subtle"}
                borderRadius={8}
              >
                <Box mt={1}>
                  <VInternationalIcon width={"18px"} height={"18px"} />
                </Box>
              </MenuButton>
              <MenuList maxHeight={"60vh"} overflowY="scroll">
                <MenuGroup>
                  {helpInfo?.available_countries?.map((country, index) => (
                    <MenuItem
                      key={index}
                      color={"black"}
                      cursor={"pointer"}
                      onClick={() => {
                        handleSetCountry(country);
                      }}
                    >
                      <Flex>
                        <Text mr=".75rem" fontSize="md" aria-hidden={true}>
                          {country.flag}
                        </Text>
                        <Text fontSize="md">{country.name}</Text>
                      </Flex>
                    </MenuItem>
                  ))}
                </MenuGroup>
              </MenuList>
            </Menu>
          )}

          {showCloseButton && (
            <Box order={2}>
              <IconButton
                icon={VXIcon}
                variant="medium-emphasis"
                size="sm"
                aria-label="Close ModalSheet button"
                onClick={onClose}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NewSHFlyoutHeader;
