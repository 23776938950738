import { gql } from "@apollo/client";
import { assessmentToTake } from "operations/fragments/assessment";

const createTeenCheckIn = gql`
  mutation createTeenCheckIn($member_id: ID!, $campaign: CampaignInput) {
    createTeenCheckIn(member_id: $member_id, campaign: $campaign) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default createTeenCheckIn;
