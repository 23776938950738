// This package exports a interface to temporary remember the user password between
// the sign_in page and code confirmation. It can be replaced with sessionStorage.
let memoryStorage = null;

export function rememberPassword(password) {
  memoryStorage = password;
}

export function destroyPassword() {
  memoryStorage = null;
}

export function restorePassword() {
  const password = memoryStorage;
  destroyPassword();
  return password;
}
