import { Box, useTheme } from "../../index";

// TODO: ADD DYNAMIC A11Y PROPS
export const ForkRoadSign = ({
  width,
  height,
  colorScheme = "primary",
  asSpan,
}) => {
  const { colors } = useTheme();

  return (
    <Box width={width} height={height} as={asSpan && "span"}>
      <svg
        viewBox="0 0 108 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="fork-in-road-sign"
        aria-hidden="true"
      >
        <title id="fork-in-road-sign">Fork in road sign</title>
        <path
          fill={colors[colorScheme].base}
          d="M96.2633 20.7031L14.5945 46.078L12.4274 23.2305L89.0336 15.9646L96.2633 20.7031Z"
        />
        <path
          fill={colors[colorScheme].base}
          d="M60.7499 48.5999H49.9499V52.6499L60.7499 51.2999V48.5999Z"
        />
        <path
          fill={colors[colorScheme].base}
          d="M60.7499 91.7999H49.9499V95.8499L60.7499 94.4999V91.7999Z"
        />
        <path
          fill={colors[colorScheme].base}
          d="M92.7463 65.7401L9.7959 86.547L16.7517 91.6791L93.643 88.6726L92.7463 65.7401Z"
        />
        <path
          fill={colors[colorScheme].dark}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.5998 4.05C48.5998 1.81325 50.413 0 52.6498 0H58.0498C60.2865 0 62.0998 1.81325 62.0998 4.05V15.8071L87.7573 13.3736C89.2642 13.2306 90.7619 13.7267 91.8855 14.741L102.761 24.5579C104.84 26.4345 105.139 29.5891 103.45 31.8231L94.6141 43.5097C93.7013 44.7171 92.3235 45.4859 90.8166 45.6288L62.0998 48.3525V60.1833L89.7842 59.1008C92.7642 58.9843 95.2745 61.3057 95.391 64.2857L96.235 85.8692C96.3515 88.8493 94.0301 91.3596 91.0501 91.4761L62.0998 92.6081V106.65C62.0998 107.396 61.4954 108 60.7498 108C60.0042 108 59.3998 107.396 59.3998 106.65V92.7136L51.2998 93.0304V106.65C51.2998 107.396 50.6954 108 49.9498 108C49.2042 108 48.5998 107.396 48.5998 106.65V93.1359L17.8815 94.3371C16.369 94.3962 14.9011 93.8178 13.8355 92.7428L3.52104 82.3379C1.54934 80.3489 1.42553 77.1825 3.23592 75.0456L12.7064 63.8671C13.6848 62.7122 15.1031 62.0209 16.6156 61.9618L48.5998 60.7112V49.633L17.9193 52.543C14.9503 52.8246 12.3151 50.646 12.0335 47.677L9.99395 26.1735C9.71235 23.2045 11.8909 20.5693 14.8599 20.2877L48.5998 17.0876V4.05ZM51.2998 60.6056L59.3998 60.2889V48.6086L51.2998 49.3769V60.6056ZM17.776 91.6391C17.0197 91.6687 16.2858 91.3795 15.753 90.842L5.43856 80.4371C4.45271 79.4426 4.39081 77.8594 5.29599 76.7909L14.7665 65.6124C15.2557 65.0349 15.9648 64.6893 16.721 64.6597L89.8897 61.7988C91.3797 61.7405 92.6348 62.9012 92.6931 64.3912L93.537 85.9747C93.5953 87.4647 92.4346 88.7199 90.9446 88.7781C39.0811 90.8061 72.8291 89.4865 17.776 91.6391ZM15.1149 22.9757C13.6304 23.1165 12.5411 24.434 12.6819 25.9185L14.7214 47.422C14.8622 48.9065 16.1798 49.9958 17.6643 49.855L90.5617 42.9409C91.3151 42.8694 92.004 42.485 92.4604 41.8813L101.296 30.1948C102.141 29.0778 101.991 27.5005 100.952 26.5622L90.0763 16.7452C89.5145 16.2381 88.7657 15.99 88.0123 16.0615C38.0962 20.7959 69.1969 17.8461 15.1149 22.9757ZM59.3998 4.05V16.0632L51.2998 16.8315V4.05C51.2998 3.30442 51.9042 2.7 52.6498 2.7H58.0498C58.7954 2.7 59.3998 3.30442 59.3998 4.05Z"
        />
      </svg>
    </Box>
  );
};
