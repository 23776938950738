import React, { useEffect, useState } from "react";
import { Button } from "@springcare/sh-component-library";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { VWarningIcon } from "@springcare/verdant-icons-react";
import { isMedicationManagement } from "@spring/constants";
import NextLink from "next/link";
import { useTranslation, Trans } from "react-i18next";
import routes from "routes/index";
import { TRACK_EVENT } from "utils/mixpanel";

const ConfirmationBanner = ({ appointmentId, memberId, kind }) => {
  const { t } = useTranslation("careVisits");
  const [eventTracked, setEventTracked] = useState(false);

  useEffect(() => {
    if (!eventTracked) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Confirmation Banner",
        {
          appointmentId,
          memberId,
        },
      );

      setEventTracked(true);
    }
  }, [eventTracked]);

  return (
    <Flex
      background="warning-subtle"
      gap="16px"
      p="24px"
      borderRadius="16px"
      alignItems="flex-start"
      mb={isMedicationManagement(kind) ? "0px" : "40px"}
    >
      <Icon fontSize="24px" as={VWarningIcon} />
      <Flex direction="column" gap="16px">
        <Trans>
          <Text>{t("appointmentDetails.confirmationBanner.body")}</Text>
        </Trans>
        <NextLink
          href={{
            pathname: routes.ConfirmAppointment.as,
            query: {
              id: appointmentId,
              memberId: memberId,
            },
          }}
        >
          <Button
            variant="high-emphasis"
            colorScheme="warning"
            py="12px"
            px="16px"
            maxW="200px"
            onClick={() =>
              TRACK_EVENT.LINK_CLICKED(
                window.location.pathname,
                routes.ConfirmAppointment.as,
                "Confirm Appointment",
                {
                  appointmentId,
                  memberId,
                },
              )
            }
          >
            {t("appointmentDetails.confirmationBanner.cta")}
          </Button>
        </NextLink>
      </Flex>
    </Flex>
  );
};

export default ConfirmationBanner;
