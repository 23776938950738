import COLORS from '../../../../../constants/colors';

import React from 'react';

const LanguageSvg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_338:441)">
			<path fill={COLORS.PRIMARY_500} fillRule="evenodd" clipRule="evenodd" d="M10 10C9.44771 10 9 10.4477 9 11C9 11.5523 9.44771 12 10 12H10.6584C10.9208 13.0805 11.4481 14.0507 12.1649 14.8426C11.7849 14.9453 11.3858 15 10.9751 15H10.5C9.94771 15 9.5 15.4477 9.5 16C9.5 16.5523 9.94771 17 10.5 17H10.9751C12.0622 17 13.0923 16.7339 14 16.2609C14.9077 16.7339 15.9378 17 17.0249 17H17.5C18.0523 17 18.5 16.5523 18.5 16C18.5 15.4477 18.0523 15 17.5 15H17.0249C16.6142 15 16.2151 14.9453 15.8351 14.8426C16.5519 14.0507 17.0792 13.0805 17.3416 12H18C18.5523 12 19 11.5523 19 11C19 10.4477 18.5523 10 18 10H15V8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8V10H10ZM14 13.8497C13.4419 13.3535 13.0056 12.7207 12.7445 12H15.2555C14.9944 12.7207 14.5581 13.3535 14 13.8497Z"/>
			<path fill={COLORS.PRIMARY_500} fillRule="evenodd" clipRule="evenodd" d="M2 7C2 4.23858 4.23858 2 7 2H18C18.5523 2 19 1.55228 19 1C19 0.447715 18.5523 0 18 0H7C3.13401 0 0 3.13401 0 7V16.5858C0 18.3676 2.15428 19.2599 3.41421 18L4.84058 16.5736C6.15309 19.198 8.8661 21 12 21H17.3333L20.8 23.6C21.406 24.0545 22.2169 24.1276 22.8944 23.7889C23.572 23.4501 24 22.7575 24 22V12C24 7.58172 20.4183 4 16 4H8C5.79086 4 4 5.79086 4 8V13C4 13.4965 4.04523 13.9825 4.13181 14.454L2 16.5858V7ZM22 12V22L18 19H12C8.68629 19 6 16.3137 6 13V8C6 6.89543 6.89543 6 8 6H16C19.3137 6 22 8.68629 22 12Z"/>
		</g>
		<defs>
			<clipPath id="clip0_338:441">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
);

export default LanguageSvg;
