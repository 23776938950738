import React from "react";
// NOTE: NOT MEANT FOR WIDESPREAD USE. THIS IS A STOPGAP UNTIL
// THE VERDANT NOTIFICATION SVG IS APPROVED AND RELEASED
const NotificationSvgIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1Z"
      fill="#C44848"
    />
    <path
      d="M6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default NotificationSvgIcon;
