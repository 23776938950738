import { useTranslation } from "react-i18next";

export const getRelationships = () => {
  const { t } = useTranslation("limitedLangRegister");
  return [
    { label: t("relationships.mother"), value: "Mother" },
    { label: t("relationships.father"), value: "Father" },
    { label: t("relationships.parent"), value: "Parent" },
    { label: t("relationships.brother"), value: "Brother" },
    { label: t("relationships.sister"), value: "Sister" },
    { label: t("relationships.familyMember"), value: "Family member" },
    { label: t("relationships.guardian"), value: "Guardian" },
    { label: t("relationships.friend"), value: "Friend" },
    { label: t("relationships.partner"), value: "Partner" },
    { label: t("relationships.roommate"), value: "Roommate" },
    { label: t("relationships.socailWorker"), value: "Social worker" },
    { label: t("relationships.other"), value: "Other" },
  ];
};
