import COLORS from '../../../constants/colors';
import React from 'react';
import classnames from 'classnames';

import { clickableDivProps } from '../../../lib/accessibility_helpers';

import styles from './styles.module.scss';

const Card = (props) => {
	const bg = COLORS[props.color];
	let style = {};

	if (props.color && !bg) {
		console.error(`${props.color} is not a valid color.`);
		console.error('Valid colors', Object.keys(COLORS));
	} else {
		style.backgroundColor = bg;
	}

	if (props.backgroundImage) {
		style.backgroundImage = `url(${props.backgroundImage})`;
		style.backgroundSize = props.backgroundSize;
		style.backgroundPosition = 'center';
	}

	if(props.height) {
		style.height = props.height + 'px';
	}

	return (
		<div
			style={style}
			data-cy={props.dataCy ? props.dataCy : ''}
			onClick={props.onClick}
			{...clickableDivProps({ onClick: props.onClick })}
			className={classnames(
				styles.card, styles[`padding-${props.padding}`], styles[`radius-${props.radius}`], {
					[styles.bordered]      : props.bordered,
					[styles.center]        : props.center,
					[styles.dashed]        : props.dashed,
					[styles.full]          : props.full,
					[styles.highlight]     : props.highlight,
					[styles.white]         : props.white,
					[styles.gray]          : props.gray,
					[styles.allowOverflow] : props.allowOverflow,
				}
			)}
			tabIndex={typeof props.onClick === 'function' ? '0' : '-1'}
			onKeyDown={(e) => e.code === 'Enter' && typeof props.onClick === 'function' && props.onClick()}
			role={typeof props.onClick === 'function' ? 'button' : null }
		>
			{props.children}
		</div>
	);
};

Card.defaultProps = {
	bordered       : true,
	radius         : 'md',
	white          : true,
	gray           : false,
	padding        : 'md',
	backgroundSize : 'cover',
};

export default Card;
