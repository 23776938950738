import yup from "schemas/yup";
import { assessments as assessmentConstants } from "@spring/constants";

const { elementKinds, builtInPromptIds } = assessmentConstants;

// Define which element kinds don't need to have their responses converted to Number
const elementStringKinds = [elementKinds.TEXTAREA, elementKinds.TEXT];

// Prompts supplied by cherrim, these don't need special question formatting
const builtInPrompts = Object.values(builtInPromptIds);

const getFieldKey = (questionnaireKind, questionKey) => {
  if (!questionnaireKind) {
    return questionKey;
  }
  return `${questionnaireKind.abbreviation}_${questionKey}`;
};

// This is used to establish a validation schema for every prompt that comes from the backend.
export const getPromptSchema = (prompt, questionnaire_kind) => {
  if (!prompt.questions) return null;
  const schema = {};
  prompt.questions.forEach((question) => {
    const fieldKey = getFieldKey(questionnaire_kind, question.key);
    switch (question.element.kind) {
      case elementKinds.CHECKBOX:
        break;
      case elementKinds.TEXTAREA:
        schema[fieldKey] = yup.string().required();
        break;
      case elementKinds.TEXT:
        schema[fieldKey] = yup.string().trim().max(40);
        break;
      case elementKinds.NUMBER:
        schema[fieldKey] = yup
          .number()
          .min(question.element.min)
          .max(question.element.max)
          .required();
        break;
      default:
        schema[fieldKey] = yup.number().required();
        break;
    }
  });
  return yup.object().shape(schema);
};

// Used to format data correctly for the submitAssessmentResponses mutation.
// Loops over each prompt and gets the prompt id, then loops over each question and matches it to its response in the formData.
export const formatResponseData = (
  responses,
  current_prompts,
  questionnaire_kind,
) => {
  const formattedPrompts = formatPrompts(current_prompts, questionnaire_kind);
  const responseData = formattedPrompts.map((prompt) =>
    formatPromptData(responses, prompt),
  );
  return responseData;
};

export const formatPromptData = (responses, current_prompt) => {
  const questionsWithResponses = {};
  current_prompt.questions.forEach((question) => {
    if (elementStringKinds.includes(question.element.kind)) {
      questionsWithResponses[question.key] = responses[question.fieldKey];
    } else if (
      question.element.kind === elementKinds.CHECKBOX &&
      !responses[question.fieldKey]
    ) {
      questionsWithResponses[question.key] = 0; // If a checkbox question is unanswered, add it to the data as `key: 0`
    } else if (responses[question.fieldKey] === true) {
      questionsWithResponses[question.key] = 1; // Change true answers to binary values for the backend
    } else {
      questionsWithResponses[question.key] = Number(
        responses[question.fieldKey],
      ); // Most of the element types need to have the responses as Numbers
    }
  });
  return {
    prompt_id: current_prompt.id,
    question_responses: JSON.stringify(questionsWithResponses),
  };
};

// Used to format prompts in the AssessmentController before passing them on to the Assessment/Prompt components
export const formatPrompts = (
  prompts,
  questionnaire_kind,
  questionnaire_id,
) => {
  const newPrompts = prompts.map((prompt) => {
    const newQuestions =
      !builtInPrompts.includes(prompt.id) &&
      prompt.questions.map((question) => {
        const fieldKey = getFieldKey(questionnaire_kind, question.key);
        return { ...question, fieldKey }; // Add a consistent fieldKey
      });
    return {
      ...prompt,
      questions: builtInPrompts.includes(prompt.id)
        ? prompt.questions
        : newQuestions,
      schema: prompt.schema
        ? prompt.schema
        : getPromptSchema(prompt, questionnaire_kind), // If schema already provided, no need to make another one
      questionnaire_id: questionnaire_id ? questionnaire_id : null, // if confidence/complete prompt, no questionnaire id needed
    };
  });
  return newPrompts;
};

export const getInitialAndRecentAssessments = (assessments) => {
  const initialAssessment = assessments?.find(
    (a) => a.kind == "INITIAL_ASSESSMENT",
  );
  const mostRecentAssessment = [...assessments]?.sort(function (a, b) {
    return new Date(b.ended_at) - new Date(a.ended_at);
  })?.[0];

  return [initialAssessment, mostRecentAssessment];
};
