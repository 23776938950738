import { useEffect, useState, useRef } from "react";

import { useInViewportOnce } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";

export const useFamilySectionTracking = () => {
  const ref = useRef();
  const [hasTracked, setHasTracked] = useState(false);
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible

  useEffect(() => {
    if (!hasTracked && inViewport) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Your Family Section",
        {
          location: "Your Family Section on Home page",
          spring_doc_id: "famhp003",
        },
      );
      setHasTracked(true);
    }
  }, [inViewport, hasTracked]);

  return { ref };
};
