export const Setback = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M35.6999 5.1L33.1499 0L30.5999 5.1H35.6999Z" fill="#068262" />
      <path
        d="M51.4249 25.925L56.5249 23.375L51.4249 20.825V25.925Z"
        fill="#068262"
      />
      <path d="M51.4249 35.7L56.5249 33.15L51.4249 30.6V35.7Z" fill="#068262" />
      <path
        d="M51.4249 45.475L56.5249 42.925L51.4249 40.375V45.475Z"
        fill="#068262"
      />
      <path
        d="M50.2515 16.4459L53.3932 11.6875L47.7015 12.0291L50.2515 16.4459Z"
        fill="#068262"
      />
      <path
        d="M16.0483 16.4459L12.9065 11.6875L18.5983 12.0291L16.0483 16.4459Z"
        fill="#068262"
      />
      <path
        d="M14.8749 25.925L9.7749 23.375L14.8749 20.825V25.925Z"
        fill="#068262"
      />
      <path d="M14.8749 35.7L9.7749 33.15L14.8749 30.6V35.7Z" fill="#068262" />
      <path
        d="M14.8749 45.475L9.7749 42.925L14.8749 40.375V45.475Z"
        fill="#068262"
      />
      <path
        d="M44.4958 8.82341L44.8374 3.13168L40.079 6.27341L44.4958 8.82341Z"
        fill="#068262"
      />
      <path
        d="M21.804 8.82341L21.4624 3.13168L26.2207 6.27341L21.804 8.82341Z"
        fill="#068262"
      />
      <path
        d="M53.4659 51.307L55.2499 49.3H59.4999L55.2499 62.9L50.1499 67.15H16.9999L13.5999 65.875L11.8999 62.9L40.9979 58.1631C45.8183 57.3784 50.2213 54.9572 53.4659 51.307Z"
        fill="#068262"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4501 22.95C14.4501 12.6223 22.8224 4.25 33.1501 4.25C43.4778 4.25 51.8501 12.6223 51.8501 22.95V48.45H59.5001C59.7726 48.45 60.0286 48.5807 60.1885 48.8014C60.3483 49.022 60.3927 49.306 60.3078 49.5649L56.3714 61.5644C55.727 63.5288 54.4742 65.1677 52.8631 66.3H62.0501C62.5195 66.3 62.9001 66.6806 62.9001 67.15C62.9001 67.6194 62.5195 68 62.0501 68H5.9501C5.48066 68 5.1001 67.6194 5.1001 67.15C5.1001 66.6806 5.48066 66.3 5.9501 66.3H13.4371C11.826 65.1677 10.5732 63.5288 9.92877 61.5644L5.99244 49.5649C5.9075 49.306 5.95186 49.022 6.11173 48.8014C6.2716 48.5807 6.52759 48.45 6.8001 48.45H14.4501V22.95ZM16.1501 48.45H21.2501V16.15C21.2501 13.3339 22.2283 10.7463 23.8634 8.70835C19.2191 11.743 16.1501 16.9881 16.1501 22.95V48.45ZM28.6175 7.00991C25.2585 8.67884 22.9501 12.1448 22.9501 16.15V48.45H28.0501V9.35C28.0501 8.50647 28.2549 7.71077 28.6175 7.00991ZM29.7501 48.45H36.5501V9.35C36.5501 7.47223 35.0279 5.95 33.1501 5.95C31.2723 5.95 29.7501 7.47223 29.7501 9.35V48.45ZM37.6827 7.00991C38.0453 7.71077 38.2501 8.50647 38.2501 9.35V48.45H43.3501V16.15C43.3501 12.1448 41.0417 8.67884 37.6827 7.00991ZM45.0501 48.45V16.15C45.0501 13.3339 44.0719 10.7463 42.4368 8.70835C47.0811 11.743 50.1501 16.9881 50.1501 22.95V48.45H45.0501ZM7.9735 50.15L11.5441 61.0345C12.5746 64.176 15.5067 66.3 18.813 66.3H47.4872C50.7935 66.3 53.7256 64.176 54.7561 61.0345L58.3267 50.15H7.9735Z"
        fill="#033C2E"
      />
    </svg>
  );
};
