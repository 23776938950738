import { Box, Text, Stack } from "design-system/components";

const SHLineBreak = ({ color = "accent-base", word }) => {
  return (
    <Stack align="center" direction="row" my={24} spacing={2}>
      <Box flex="1" borderColor={color} borderWidth={1} />
      {word && (
        <>
          <Text color={color} fontWeight={600} fontSize={16}>
            {word}
          </Text>
          <Box flex="1" borderColor={color} borderWidth={1} />
        </>
      )}
    </Stack>
  );
};

export default SHLineBreak;
