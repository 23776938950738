// NOTE: This is only for Admin use to assign existing languages known/offered by CNs (MXENG-2214)
// You should otherwise build an englishName prop into LanguageSelect.

const LANGUAGES_EXTENDED = [
	{ nativeName: 'Afrikaans', englishName: 'Afrikaans', label: 'Afrikaans', value: 'Afrikaans', code: 'af' },
	{ nativeName: 'Albanian', englishName: 'Albanian', label: 'Albanian', value: 'Albanian', code: 'sq' },
	{ nativeName: 'Amharic', englishName: 'Amharic', label: 'Amharic', value: 'Amharic', code: '' },
	{ nativeName: 'Arabic', englishName: 'Arabic', label: 'Arabic', value: 'Arabic', code: 'ar' },
	{ nativeName: 'Armenian', englishName: 'Armenian', label: 'Armenian', value: 'Armenian', code: 'hy' },
	{ nativeName: 'ASL', englishName: 'ASL', label: 'ASL', value: 'ASL', code: '' },
	{ nativeName: 'Basque', englishName: 'Basque', label: 'Basque', value: 'Basque', code: 'eu' },
	{ nativeName: 'Belarusian', englishName: 'Belarusian', label: 'Belarusian', value: 'Belarusian', code: '' },
	{ nativeName: 'Bengali', englishName: 'Bengali', label: 'Bengali', value: 'Bengali', code: 'bn' },
	{ nativeName: 'Bosnian', englishName: 'Bosnian', label: 'Bosnian', value: 'Bosnian', code: 'bs' },
	{ nativeName: 'Breton', englishName: 'Breton', label: 'Breton', value: 'Breton', code: 'br' },
	{ nativeName: 'Bulgarian', englishName: 'Bulgarian', label: 'Bulgarian', value: 'Bulgarian', code: 'bg' },
	{ nativeName: 'Burmese', englishName: 'Burmese', label: 'Burmese', value: 'Burmese', code: '' },
	{ nativeName: 'Catalan', englishName: 'Catalan', label: 'Catalan', value: 'Catalan', code: 'ca' },
	{ nativeName: 'Cebuano', englishName: 'Cebuano', label: 'Cebuano', value: 'Cebuano', code: '' },
	{ nativeName: 'Chechen', englishName: 'Chechen', label: 'Chechen', value: 'Chechen', code: '' },
	{ nativeName: 'Chinese (Cantonese)', englishName: 'Chinese (Cantonese)', label: 'Chinese (Cantonese)', value: 'Chinese (Cantonese)', code: '' },
	{ nativeName: 'Chinese (Mandarin)', englishName: 'Chinese (Mandarin)', label: 'Chinese (Mandarin)', value: 'Chinese (Mandarin)', code: 'cmn' },
	{ nativeName: 'Creole', englishName: 'Creole', label: 'Creole', value: 'Creole', code: '' },
	{ nativeName: 'Croatian', englishName: 'Croatian', label: 'Croatian', value: 'Croatian', code: 'hr' },
	{ nativeName: 'Czech', englishName: 'Czech', label: 'Czech', value: 'Czech', code: 'cs' },
	{ nativeName: 'Danish', englishName: 'Danish', label: 'Danish', value: 'Danish', code: 'da' },
	{ nativeName: 'Dutch', englishName: 'Dutch', label: 'Dutch', value: 'Dutch', code: 'nl' },
	{ nativeName: 'English', englishName: 'English', label: 'English', value: 'English', code: 'en' },
	{ nativeName: 'Esperanto', englishName: 'Esperanto', label: 'Esperanto', value: 'Esperanto', code: 'eo' },
	{ nativeName: 'Estonian', englishName: 'Estonian', label: 'Estonian', value: 'Estonian', code: 'et' },
	{ nativeName: 'Farsi', englishName: 'Farsi', label: 'Farsi', value: 'Farsi', code: 'fa' },
	{ nativeName: 'Finnish', englishName: 'Finnish', label: 'Finnish', value: 'Finnish', code: 'fi' },
	{ nativeName: 'French', englishName: 'French', label: 'French', value: 'French', code: 'fr' },
	{ nativeName: 'Frisian', englishName: 'Frisian', label: 'Frisian', value: 'Frisian', code: 'fy' },
	{ nativeName: 'Georgian', englishName: 'Georgian', label: 'Georgian', value: 'Georgian', code: 'ka' },
	{ nativeName: 'German', englishName: 'German', label: 'German', value: 'German', code: 'de' },
	{ nativeName: 'Greek', englishName: 'Greek', label: 'Greek', value: 'Greek', code: 'el' },
	{ nativeName: 'Gujarati', englishName: 'Gujarati', label: 'Gujarati', value: 'Gujarati', code: 'gu' },
	{ nativeName: 'Haitian-Creole', englishName: 'Haitian-Creole', label: 'Haitian-Creole', value: 'Haitian-Creole', code: '' },
	{ nativeName: 'Hawaiian', englishName: 'Hawaiian', label: 'Hawaiian', value: 'Hawaiian', code: '' },
	{ nativeName: 'Hebrew', englishName: 'Hebrew', label: 'Hebrew', value: 'Hebrew', code: 'he' },
	{ nativeName: 'Hindi', englishName: 'Hindi', label: 'Hindi', value: 'Hindi', code: 'hi' },
	{ nativeName: 'Hmong', englishName: 'Hmong', label: 'Hmong', value: 'Hmong', code: '' },
	{ nativeName: 'Hungarian', englishName: 'Hungarian', label: 'Hungarian', value: 'Hungarian', code: 'hu' },
	{ nativeName: 'Icelandic', englishName: 'Icelandic', label: 'Icelandic', value: 'Icelandic', code: 'is' },
	{ nativeName: 'Ilongo', englishName: 'Ilongo', label: 'Ilongo', value: 'Ilongo', code: '' },
	{ nativeName: 'Indonesian', englishName: 'Indonesian', label: 'Indonesian', value: 'Indonesian', code: 'id' },
	{ nativeName: 'Irish', englishName: 'Irish', label: 'Irish', value: 'Irish', code: 'ga' },
	{ nativeName: 'Italian', englishName: 'Italian', label: 'Italian', value: 'Italian', code: 'it' },
	{ nativeName: 'Japanese', englishName: 'Japanese', label: 'Japanese', value: 'Japanese', code: 'ja' },
	{ nativeName: 'Khmer', englishName: 'Khmer', label: 'Khmer', value: 'Khmer', code: '' },
	{ nativeName: 'Konkani', englishName: 'Konkani', label: 'Konkani', value: 'Konkani', code: '' },
	{ nativeName: 'Korean', englishName: 'Korean', label: 'Korean', value: 'Korean', code: 'ko' },
	{ nativeName: 'Latin', englishName: 'Latin', label: 'Latin', value: 'Latin', code: 'la' },
	{ nativeName: 'Latvian', englishName: 'Latvian', label: 'Latvian', value: 'Latvian', code: 'lv' },
	{ nativeName: 'Lithuanian', englishName: 'Lithuanian', label: 'Lithuanian', value: 'Lithuanian', code: 'lt' },
	{ nativeName: 'Malay', englishName: 'Malay', label: 'Malay', value: 'Malay', code: 'ms' },
	{ nativeName: 'Maori', englishName: 'Maori', label: 'Maori', value: 'Maori', code: 'mi' },
	{ nativeName: 'Mongolian', englishName: 'Mongolian', label: 'Mongolian', value: 'Mongolian', code: 'mn' },
	{ nativeName: 'Norwegian', englishName: 'Norwegian', label: 'Norwegian', value: 'Norwegian', code: 'no' },
	{ nativeName: 'Occitan', englishName: 'Occitan', label: 'Occitan', value: 'Occitan', code: 'oc' },
	{ nativeName: 'Other', englishName: 'Other', label: 'Other', value: 'Other', code: '' },
	{ nativeName: 'Persian', englishName: 'Persian', label: 'Persian', value: 'Persian', code: '' },
	{ nativeName: 'Polish', englishName: 'Polish', label: 'Polish', value: 'Polish', code: 'pl' },
	{ nativeName: 'Portuguese', englishName: 'Portuguese', label: 'Portuguese', value: 'Portuguese', code: 'pt' },
	{ nativeName: 'Punjabi', englishName: 'Punjabi', label: 'Punjabi', value: 'Punjabi', code: 'pa' },
	{ nativeName: 'Romanian', englishName: 'Romanian', label: 'Romanian', value: 'Romanian', code: 'ro' },
	{ nativeName: 'Rotuman', englishName: 'Rotuman', label: 'Rotuman', value: 'Rotuman', code: '' },
	{ nativeName: 'Russian', englishName: 'Russian', label: 'Russian', value: 'Russian', code: 'ru' },
	{ nativeName: 'Sanskrit', englishName: 'Sanskrit', label: 'Sanskrit', value: 'Sanskrit', code: 'sa' },
	{ nativeName: 'Sardinian', englishName: 'Sardinian', label: 'Sardinian', value: 'Sardinian', code: '' },
	{ nativeName: 'Serbian', englishName: 'Serbian', label: 'Serbian', value: 'Serbian', code: 'sr' },
	{ nativeName: 'Slovak', englishName: 'Slovak', label: 'Slovak', value: 'Slovak', code: 'sk' },
	{ nativeName: 'Slovenian', englishName: 'Slovenian', label: 'Slovenian', value: 'Slovenian', code: 'sl' },
	{ nativeName: 'Spanish', englishName: 'Spanish', label: 'Spanish', value: 'Spanish', code: 'es' },
	{ nativeName: 'Somali', englishName: 'Somali', label: 'Somali', value: 'Somali', code: '' },
	{ nativeName: 'Swahili', englishName: 'Swahili', label: 'Swahili', value: 'Swahili', code: 'sw' },
	{ nativeName: 'Swedish', englishName: 'Swedish', label: 'Swedish', value: 'Swedish', code: 'sv' },
	{ nativeName: 'Tagalog', englishName: 'Tagalog', label: 'Tagalog', value: 'Tagalog', code: 'tl' },
	{ nativeName: 'Tamil', englishName: 'Tamil', label: 'Tamil', value: 'Tamil', code: 'ta' },
	{ nativeName: 'Telugu', englishName: 'Telugu', label: 'Telugu', value: 'Telugu', code: '' },
	{ nativeName: 'Thai', englishName: 'Thai', label: 'Thai', value: 'Thai', code: 'th' },
	{ nativeName: 'Tibetan', englishName: 'Tibetan', label: 'Tibetan', value: 'Tibetan', code: 'bo' },
	{ nativeName: 'Turkish', englishName: 'Turkish', label: 'Turkish', value: 'Turkish', code: 'tr' },
	{ nativeName: 'Ukrainian', englishName: 'Ukrainian', label: 'Ukrainian', value: 'Ukrainian', code: 'uk' },
	{ nativeName: 'Urdu', englishName: 'Urdu', label: 'Urdu', value: 'Urdu', code: 'ur' },
	{ nativeName: 'Vietnamese', englishName: 'Vietnamese', label: 'Vietnamese', value: 'Vietnamese', code: 'vi' },
	{ nativeName: 'Welsh', englishName: 'Welsh', label: 'Welsh', value: 'Welsh', code: 'cy' },
	{ nativeName: 'Yiddish', englishName: 'Yiddish', label: 'Yiddish', value: 'Yiddish', code: 'yi' },
];

module.exports = LANGUAGES_EXTENDED;