//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import {
  Subhead,
  Checklist,
  FlexCol,
  Button,
  FlexRow,
  Subtitle,
  Section,
} from "@spring/smeargle";
import { getOr } from "lodash/fp";
import Meowth from "@spring/meowth";
import { withTranslation } from "react-i18next";

import { getMemberGoals } from "operations/queries/member";
import { track } from "utils/mixpanel";

class JourneyIntroCard extends Component {
  static propTypes = {
    onNext: PropTypes.any,
    t: PropTypes.func,
  };

  componentDidMount() {
    track("Your Journey -- Intro Shown");
  }

  get selectedGoals() {
    const goals = getOr([], "props.data.user.member.tracked_goals", this);

    return goals.map((g) => g.goal.description);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Section size="lg">
          <FlexCol alignment="center">
            <Subhead center bold noMargin dataCy="journey-intro-message">
              {t("stack.title")}
            </Subhead>
            <Section size="md">
              <Subtitle noMargin>{t("stack.introText")}</Subtitle>
            </Section>
            <div>
              <Checklist listItems={this.selectedGoals} />
            </div>
          </FlexCol>
        </Section>
        <FlexRow justification="flex-end">
          <Button
            text={t("stack.btnTitle")}
            icon="arrow-right"
            onClick={this.props.onNext}
          />
        </FlexRow>
      </div>
    );
  }
}

export default compose(
  graphql(getMemberGoals, { options: Meowth.apolloOptionsUserId }),
)(withTranslation("journey")(JourneyIntroCard));
