import { Card } from "@springcare/sh-component-library";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

import { useTranslation } from "hooks/react-i18next";
import { MomentContentFormatIcons } from "modules/MemberDashboard/Teen/Home/components/MomentsSection/MomentContentFormatIcons";
import { toTitleCase } from "utils/mixpanel";

import type { TeenMomentsCardProps } from "./types";
import { getTeenMomentIcon } from "./utils/teenMomentsCard.utils";
import { trackTeenExerciseClicked } from "modules/MemberDashboard/Moments/components/analytics";

export const TeenMomentsCard = ({
  title,
  contentFormatTypes,
  duration,
  openModal,
  exerciseId,
  exerciseCategory,
}: TeenMomentsCardProps): React.ReactElement => {
  const { t } = useTranslation("teenHomepage");

  const MomentIcon = getTeenMomentIcon(exerciseId);

  const handleMomentClicked = () => {
    trackTeenExerciseClicked(
      exerciseId,
      title,
      exerciseCategory
        ?.map((item: any) => toTitleCase(item.category))
        .join(", "),
      contentFormatTypes?.join(", "),
    );

    openModal();
  };

  return (
    <Card
      as="button"
      variant="medium-emphasis"
      size="md"
      _hover={{ hover: "none" }}
      onClick={handleMomentClicked}
      data-testid="teen-moments-card"
    >
      <Box w={[271, 271, 225]} h={74}>
        <HStack spacing={8}>
          <VStack alignItems="start">
            <Text
              w={[187, 187, 143]}
              h={48}
              size="body-medium-strong"
              textAlign="start"
            >
              {title}
            </Text>
            <HStack>
              {contentFormatTypes.map((formatType) => (
                <MomentContentFormatIcons
                  key={formatType}
                  formatType={formatType}
                />
              ))}
              <Text size="body-small-regular" pb={2}>
                {duration} {t("teenMomentsCards.minutes")}
              </Text>
            </HStack>
          </VStack>
          <Box>
            <MomentIcon width={68} height={68} aria-hidden={true} />
          </Box>
        </HStack>
      </Box>
    </Card>
  );
};
