/**
 * Enums for passing in Care Preferences Questionnaire Status
 * where necessary.
 */

const CarePreferenceStatus = Object.freeze({
	Started    : 'STARTED',
	InProgress : 'IN_PROGRESS',
	Finished   : 'FINISHED',
});

module.exports = {
	CarePreferenceStatus,
};