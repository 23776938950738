import { gql } from "@apollo/client";

const getMemberPaymentHistory = gql`
  query getMemberPaymentHistory($member_id: ID!, $page: String) {
    member_payment_history(member_id: $member_id, page: $page) {
      next_page
      previous_page
      payments {
        id
        amount
        date_paid
        date_posted
        receipt
      }
    }
  }
`;

export default getMemberPaymentHistory;
