import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  VTherapyDuotoneSpot,
  VTherapyIcon,
} from "@springcare/verdant-icons-react";
import { InformationFlyout } from "components";
import { Call } from "design-system/index";
import { Trans, useTranslation } from "react-i18next";

type TherapyFlyoutType = {
  callback?: () => void;
  isOpen: boolean;
  onClose: () => void;
  ctaText: string;
};

const TherapyFlyoutBody = () => {
  const { t } = useTranslation("t2Homepage", {
    keyPrefix: "therapyFlyout",
  });

  return (
    <>
      <Heading size="heading-small" mb="v-8">
        {" "}
        {t("benefitsHeader")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>{t("benefitsBody.benefit1")}</ListItem>
        <ListItem>{t("benefitsBody.benefit2")}</ListItem>
      </UnorderedList>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpectHeader")}
      </Heading>
      <Text display="inline" size="body">
        {t("whatToExpectBody")}
      </Text>

      <Heading size="heading-small" mb="v-8" mt="v-24">
        {" "}
        {t("commonConcernsHeader")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Trans
            t={t}
            i18nKey="commonConcernsBody.concern1"
            components={[<Text key="commonConcernsBody.concern1" />]}
          />
        </ListItem>
        <ListItem>
          <Trans
            t={t}
            i18nKey="commonConcernsBody.concern2"
            components={[<Text key="commonConcernsBody.concern2" />]}
          />
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const TherapyBenefitFlyout = ({
  callback,
  isOpen,
  onClose,
  ctaText,
}: TherapyFlyoutType) => {
  const { t } = useTranslation("t2Homepage", {
    keyPrefix: "therapyFlyout",
  });
  const [isMobile] = useMediaQuery("(max-width: 450px)");

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VTherapyIcon />}
      heroIllustration={VTherapyDuotoneSpot}
      isOpen={isOpen}
      padding={isMobile ? "24px 0" : "24px 40px"}
      onClose={onClose}
      ctaIcon={<Call />}
      ctaText={ctaText}
      ctaLink={`tel:${ctaText}`}
      ctaType={callback && "link"}
      ctaCallback={callback}
    >
      <TherapyFlyoutBody />
    </InformationFlyout>
  );
};
