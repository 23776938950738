import { gql } from "@apollo/client";

const getMemberTodos = gql`
  query getMemberTodos($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        todos {
          id
          data
          detail
          kind
          title
        }
      }
    }
  }
`;

export default getMemberTodos;
