"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 *
 * @param {string} enumerable
 * @returns {string} A sanitized version of the enum
 *
 * This function will take an enum that's returned from the backend
 * that looks like this: 'INITIAL_MEDICATION_MANAGEMENT', will
 * sanitize it to make it remove underscores and capitalize into
 * a string: 'Initial Medication Management'
 */

var sanitizeEnum = function sanitizeEnum(enumerable) {
  if (!enumerable || typeof enumerable !== 'string') {
    return enumerable;
  }
  return enumerable.toLowerCase().split('_').reduce(function (acc, val) {
    var sanitized = "".concat(val.charAt(0).toUpperCase() + val.substring(1));

    // We only want to concat if there's a value.
    if (acc.length) {
      return "".concat(acc, " ").concat(sanitized);
    }
    return "".concat(sanitized);
  }, '');
};
var _default = exports["default"] = (0, _fp.curry)(sanitizeEnum);