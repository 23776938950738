import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
  desc?: string;
  descId?: string;
}
export const TemporaryStopIcon = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    {desc ? <desc id={descId}>{desc}</desc> : null}
    {title ? <title id={titleId}>{title}</title> : null}
    <g clip-path="url(#clip0_1589_3260)">
      <path
        d="M0.753723 3.75C0.753723 2.09315 2.09687 0.75 3.75372 0.75H14.2537C15.9106 0.75 17.2537 2.09315 17.2537 3.75V14.25C17.2537 15.9069 15.9106 17.25 14.2537 17.25H3.75372C2.09687 17.25 0.753723 15.9069 0.753723 14.25V3.75Z"
        fill="#171E22"
      />
    </g>
    <defs>
      <clipPath id="clip0_1589_3260">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.00372314)"
        />
      </clipPath>
    </defs>
  </svg>
);
