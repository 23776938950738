import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { useRouter, withRouter } from "next/router";

import ChildLayout from "./FamilySignupChild/ChildLayout";
import ChildForm from "./FamilySignupChild/ChildForm";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import { updateFamilySignupModel } from "actions/signUp";
import ErrorCensusDependent from "components/templates/InviteDependentsPage/views/ErrorCensusDependent";
import { Box, SHHeader, useMediaQuery } from "design-system/components";

const FamilySignupPageForms = (props) => {
  const router = useRouter();
  const { childSignup, isCensusError } = props;

  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const isMobileTrue = useMemo(() => isMobile, [isMobile]);

  function backToGuardian() {
    const { to, as } = routes.InviteDependents;
    router.replace(to, as);
  }

  function handleAddLaterClick() {
    // mp event here
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Add Child Dependent Later",
    );
    router.push(routes.InviteDependents.to, routes.InviteDependents.as);
  }

  const [loading, setLoading] = useState(false);

  async function submitChildSignup(e) {
    setLoading(true);
    try {
      await childSignup(e);
    } finally {
      setLoading(false);
    }
  }

  if (isCensusError) {
    return (
      <Box as="main" p={0} maxW="100vw">
        <SHHeader
          showSplitHeaderDesktop={false}
          sliderDismissed
          isLoggedIn
          displayBackBtn={() => true}
          doGoBack={backToGuardian}
          desktopBg="tertiary.25"
        />
        <ErrorCensusDependent
          handleAddLaterClick={handleAddLaterClick}
          isMobileTrue={isMobileTrue}
        />
      </Box>
    );
  }

  return (
    <>
      {props.familySignupModel.family_signup_step === "CHILD" && (
        <ChildLayout
          doGoBack={backToGuardian}
          setCurrentView={backToGuardian}
          currentView={props.familySignupModel.family_signup_step}
        >
          <ChildForm submit={submitChildSignup} isLoading={loading} />
        </ChildLayout>
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      findMyMembershipModel: state.signUp.findMyMembershipModel,
      familySignupModel: state.signUp.familySignupModel,
    }),
    { updateFamilySignupModel },
  ),
)(FamilySignupPageForms);

FamilySignupPageForms.propTypes = {
  childSignup: PropTypes.any,
  familySignupModel: PropTypes.shape({
    family_signup_step: PropTypes.string,
    guardian_phone_number: PropTypes.string,
    is_family_signup: PropTypes.bool,
  }),
  findMyMembershipModel: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    email_address: PropTypes.string,
    country: PropTypes.string,
    potential_member_type: PropTypes.string,
  }),
};
