import { gql } from "@apollo/client";

const getMemberDOBAndType = gql`
  query getMemberDOBAndType($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        date_of_birth
        is_private_practice
      }
    }
  }
`;

export default getMemberDOBAndType;
