import { Box, Flex, Text } from "@chakra-ui/react";
import { VPrivateSpringIcon } from "@springcare/verdant-icons-react";
import { useTranslation } from "hooks/react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const ConfidentialityBanner = () => {
  const { t } = useTranslation("goals");
  const showNewFooter = useFeatureFlag(FLAGS.FOOTER_REPOSITION);

  return (
    <Box color="content-secondary">
      <Flex alignItems="center" mb={showNewFooter ? 0 : 50}>
        <VPrivateSpringIcon />
        <Text fontSize="12px" pl="8px">
          {t("memberGoalForm.confidentiality")}
        </Text>
      </Flex>
    </Box>
  );
};

export { ConfidentialityBanner };
