import { Flex } from "@chakra-ui/react";
import { useLayoutEffect } from "react";

import { LeftArrow, RightArrow } from "design-system/assets";
import { Box, HStack, IconButton } from "design-system/components";

import { useBoundingRect } from "./hooks";
import { Button } from "@springcare/sh-component-library";
import { useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const CarouselSlider = ({
  initSliderWidth,
  setActiveItem,
  activeItem,
  constraint,
  positions,
  children,
  gap,
  CarouselHeading,
  CarouselSubHeading,
  sliderVariant,
  sliderFullWidth,
  isArrowSquare,
  hideSliderButtons,
  mpSliderBtnEvent,
  isT2Member,
}) => {
  const [ref, { width }] = useBoundingRect();
  const isLeftButtonDisabled = activeItem === 0;
  const isRightButtonDisabled = activeItem === positions.length - constraint;
  const [isMobile] = useMediaQuery("(min-width: 510px)");
  const { t } = useTranslation("t2Homepage");

  useLayoutEffect(
    () => initSliderWidth(Math.round(width)),
    [width, initSliderWidth],
  );

  const handleDecrementClick = () => {
    if (mpSliderBtnEvent) {
      mpSliderBtnEvent("Left");
    }
    if (activeItem !== 0) setActiveItem((prev) => prev - 1);
  };

  const handleIncrementClick = () => {
    if (mpSliderBtnEvent) {
      mpSliderBtnEvent("Right");
    }
    if (!isRightButtonDisabled) setActiveItem((prev) => prev + 1);
  };

  return (
    <>
      <Flex
        w={sliderFullWidth ? "100%" : "auto"}
        align="baseline"
        justifyContent={"space-between"}
      >
        <HStack
          mt={sliderVariant === "default" ? 64 : 0}
          mb={!CarouselSubHeading ? [16, 24] : undefined}
          width={sliderFullWidth ? "100%" : "auto"}
          justifyContent={sliderFullWidth && "space-between"}
        >
          {CarouselHeading && <CarouselHeading />}
          {!hideSliderButtons && !isT2Member && (
            <HStack>
              <IconButton
                aria-label={"carousel-left-arrow"}
                icon={<LeftArrow />}
                color={"black"}
                colorScheme={"neutral"}
                variant={"medium-emphasis"}
                background={
                  isArrowSquare
                    ? !isLeftButtonDisabled && "tertiary.light"
                    : "tertiary.light"
                }
                _hover={{
                  color: isLeftButtonDisabled ? "initial" : "primary.base",
                }}
                _active={{
                  color: isLeftButtonDisabled ? "initial" : "primary.base",
                }}
                disabled={isLeftButtonDisabled}
                ml={sliderVariant === "default" ? 25 : 0}
                h={40}
                w={40}
                pb={4}
                fontSize={20}
                isRound={isArrowSquare ? false : true}
                onClick={handleDecrementClick}
              />
              <IconButton
                aria-label={"carousel-right-arrow"}
                icon={<RightArrow />}
                color={"black"}
                colorScheme={"neutral"}
                variant={"medium-emphasis"}
                background={
                  isArrowSquare
                    ? !isRightButtonDisabled && "tertiary.light"
                    : "tertiary.light"
                }
                _hover={{
                  color: isRightButtonDisabled ? "initial" : "primary.base",
                }}
                _active={{
                  color: isRightButtonDisabled ? "initial" : "primary.base",
                }}
                disabled={isRightButtonDisabled}
                ml={1}
                h={40}
                w={40}
                pb={4}
                fontSize={20}
                isRound={isArrowSquare ? false : true}
                onClick={handleIncrementClick}
              />
            </HStack>
          )}
        </HStack>
        {isT2Member && (
          <Flex>
            <HStack>
              <IconButton
                aria-label={"carousel-left-arrow"}
                icon={<LeftArrow />}
                color={"black"}
                colorScheme={"neutral"}
                variant={"medium-emphasis"}
                background={
                  isArrowSquare
                    ? !isLeftButtonDisabled && "tertiary.light"
                    : "tertiary.light"
                }
                _hover={{
                  color: isLeftButtonDisabled ? "initial" : "primary.base",
                }}
                _active={{
                  color: isLeftButtonDisabled ? "initial" : "primary.base",
                }}
                disabled={isLeftButtonDisabled}
                ml={sliderVariant === "default" ? 25 : 0}
                h={40}
                w={40}
                pb={4}
                fontSize={20}
                onClick={handleDecrementClick}
              />
              <IconButton
                aria-label={"carousel-right-arrow"}
                icon={<RightArrow />}
                color={"black"}
                colorScheme={"neutral"}
                variant={"medium-emphasis"}
                background={
                  isArrowSquare
                    ? !isRightButtonDisabled && "tertiary.light"
                    : "tertiary.light"
                }
                _hover={{
                  color: isRightButtonDisabled ? "initial" : "primary.base",
                }}
                _active={{
                  color: isRightButtonDisabled ? "initial" : "primary.base",
                }}
                disabled={isRightButtonDisabled}
                ml={1}
                h={40}
                w={40}
                pb={4}
                fontSize={20}
                onClick={handleIncrementClick}
              />
            </HStack>
            <Box ms={16}>
              {isMobile && (
                <Button
                  style={{
                    height: "48px",
                    width: "fit-content",
                    padding: "12px 16px 12px 16px",
                    colorScheme: "base",
                    backgroundColor: "rgba(95, 75, 25, 0.1)",
                    color: "rgba(72, 45, 40, 1)",
                  }}
                  _focusVisible={{
                    border: "2px solid #4b4b4b !important",
                  }}
                >
                  {t("ExploreCareSection.benefitsButton")}
                </Button>
              )}
            </Box>
          </Flex>
        )}
      </Flex>
      {CarouselSubHeading && <CarouselSubHeading />}
      <Box
        ref={ref}
        w={{ base: "100%", md: `calc(100% + ${gap}px)` }}
        ms={{ base: 0, md: -(gap / 2) }}
        px={gap / 2}
        position="relative"
        overflow="visible"
      >
        {children}
      </Box>
    </>
  );
};

export default CarouselSlider;
