import React from "react";
import PropTypes from "prop-types";
import { Select } from "@spring/smeargle";
import { useCountryStates } from "hooks";

const StateSelect = (props) => {
  const { data: countryStates } = useCountryStates();

  return (
    <Select
      ariaLabel={props.label}
      label={props.label}
      options={countryStates?.states}
      {...props}
      dataCy={props.dataCy ? props.dataCy + "-state" : ""}
      forceRequired={props.forceRequired}
    />
  );
};

const stateOptionShape = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

StateSelect.propTypes = {
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldKey: PropTypes.string,
  formKey: PropTypes.string,
  label: PropTypes.string,
  setField: PropTypes.func,
  value: PropTypes.string,
  valid: PropTypes.bool,
  theme: PropTypes.string,
  country: PropTypes.string,
  bolded: PropTypes.bool,
  dataCy: PropTypes.string,
  stateOptions: PropTypes.arrayOf(stateOptionShape),
};

StateSelect.defaultProps = {
  disabled: false,
  label: "State",
  type: "text",
  country: "US",
};

export default StateSelect;
