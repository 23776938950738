"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _fp = require("lodash/fp");
/**
 * Takes a value and returns a boolen representing
 * if the object is empty or not, including all children.
 *
 * Empty strings are considered empty.
 *
 * All other non-object values are always considered 
 * not empty (except null, undefined, and NaN)
 *
 *
 * @name hasNonEmptyValue 
 * @param {*} value
 * @returns {Boolean} True if empty, true if not
 *
 * @example
 * const empty = { a: { b: { c: { d: undefined, e: { f: null, g: {} }}}}};
 * const notEmpty = { a: { b: 'yay' }};
 *
 * console.log(hasNonEmptyValue(empty)); // false 
 * console.log(hasNonEmptyValue(notEmpty)); // true 
 * console.log(hasNonEmptyValue(0)); // true 
 * console.log(hasNonEmptyValue(1)); // true 
 * console.log(hasNonEmptyValue('hello world'); // true 
 * console.log(hasNonEmptyValue(''); // false 
 */

function hasNonEmptyValue(val) {
  var has = false;
  if (val === undefined || val === null) {
    return false;
  }
  if (typeof val === 'string') {
    return val.length > 1;
  }
  if (typeof val === 'number') {
    return !Number.isNaN(val);
  }
  if ((0, _typeof2["default"])(val) !== 'object') {
    if (typeof val !== 'undefined' && val !== null) {
      return true;
    }
  }
  if ((0, _typeof2["default"])(val) === 'object' && Array.isArray(val)) {
    val.forEach(function (element) {
      if (hasNonEmptyValue(element)) {
        has = true;
      }
    });
  } else if ((0, _typeof2["default"])(val) === 'object') {
    for (var _i = 0, _Object$values = Object.values(val); _i < _Object$values.length; _i++) {
      var value = _Object$values[_i];
      if (typeof value !== 'undefined' && value !== null) {
        if (hasNonEmptyValue(value)) {
          has = true;
        }
      }
    }
  }
  return has;
}
var curried = (0, _fp.curry)(hasNonEmptyValue);
var _default = exports["default"] = curried;