import {
  TRACK_EVENT,
  EVENT_TYPE,
  track,
  makeEventString,
} from "utils/mixpanel";
import {
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  getModalNameForTracking,
  modalIds,
} from "@spring/constants";
import { DateTime } from "luxon";
import { capitalize } from "lodash/fp";

type SlotSelectedType = {
  index: number;
  dateTime: string;
  appointmentMedium: string;
  provider: any;
  pageNumber: number;
  providerIndex: number;
  queryRequestId: string;
  profileTabFlag?: boolean;
  appointmentKind?: string;
  badges?: Array<string>;
};

type MoreTimesType = {
  index: number;
  appointmentMedium: string;
  provider: any;
  pageNumber: number;
  providerIndex: number;
  queryRequestId: string;
  profileTabFlag?: boolean;
  appointmentKind?: string;
  isBrecsFlag?: boolean;
  badges?: Array<string>;
};

type SlotViewedProps = {
  index: number;
  time: string;
  provider: any;
  providerIndex: number;
  pageNumber: number;
  queryRequestId: string;
  appointmentMedium: string;
  appointmentKind?: string;
  trackingCategory?: string;
};

type BioType = {
  provider: any;
  pageNumber: number;
  providerIndex: number;
  isExpanded: boolean;
  profileTabFlag?: boolean;
  queryRequestId?: string;
};

type RequestSupportType = {
  appointmentMedium: string;
  appointmentKind: string;
  provider: any;
  providerIndex?: number;
  pageNumber?: number;
  queryRequestId?: string;
  category?: string;
};

type ModalSlotType = {
  appointmentKind: string;
  appointmentMedium: string;
  provider: any;
  startTime: any;
  modalId: string;
  queryRequestId: string;
  isProfilePage?: boolean;
};

type ConfirmAppointmentType = {
  appointmentKind: string;
  appointmentMedium: string;
  provider: any;
  pageNumber: number;
  providerIndex: number;
  queryRequestId: string;
  startTime: any;
  modalId: string;
  isSUDcn: boolean;
  visits_remaining_total: number;
  inNetwork: boolean;
  index: number;
  topTags: string;
  profileTabFlag?: boolean;
  category: string;
  isFromSUDSignalExperience: string;
  sudSignalExperimentValue: string;
};

type PhoneNumberType = {
  phoneNumber: string;
  isGeneralSupport: boolean;
  isT2Member: boolean;
};

// directsched01
export const trackProviderCardsLoaded = (
  selectedFilters,
  paging,
  showDedicatedProviderToggle,
  isCompanyExclusive,
  queryRequestId,
) => {
  const { conditions, specialties, languages, genders, ethnicities, mediums } =
    selectedFilters?.filters ?? {};

  TRACK_EVENT.COMPONENTS_LOADED(window.location.pathname, "Provider Cards", {
    spring_doc_id: "directsched01",
    total_providers_displayed:
      paging?.page === paging?.pages ? paging?.total % 10 : 10,
    total_providers_available: paging?.total,
    total_num_pages: paging?.pages,
    condition_tags: conditions.length ? conditions.join(", ") : "None Selected",
    specialties_tags: specialties.length
      ? specialties.join(", ")
      : "None Selected",
    language_tags: languages.length ? languages.join(", ") : "None Selected",
    gender_tags: genders.length ? genders.join(", ") : "None Selected",
    ethnicities_tags: ethnicities.length
      ? ethnicities.join(", ")
      : "None Selected",
    session_tags: mediums.length ? mediums.join(", ") : "None Selected",
    has_company_exclusive_option: showDedicatedProviderToggle,
    dedicated_provider_filter: isCompanyExclusive
      ? "Selected"
      : "None Selected",
    query_request_id: queryRequestId,
  });
};

// directsched02
export const trackProviderCardViewed = ({
  provider,
  index,
  pageNumber,
  queryRequestId,
  topTags,
  badges,
}) => {
  const isRecommendationPage =
    window.location.pathname === "/members/recommendations";

  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    isRecommendationPage ? "Recommended Provider Profile" : "Provider Profile",
    {
      spring_doc_id: "directsched02",
      provider_id: provider.id,
      is_company_exclusive: provider?.dedicated_customer?.name ? true : false,
      top_specialties: topTags,
      browse_page_number: pageNumber,
      provider_order: index + 1,
      query_request_id: queryRequestId,
      is_associate: provider?.associate_status === "active",
      badges: badges,
    },
  );
};

// directsched03
export const trackAppointmentSlotViewed = ({
  index,
  time,
  provider,
  providerIndex,
  pageNumber,
  queryRequestId,
  appointmentMedium,
  appointmentKind,
  trackingCategory,
}: SlotViewedProps) => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Available Appointment",
    {
      spring_doc_id: "directsched03",
      location: "Provider Profile",
      provider_id: provider.id,
      appointment_type:
        getAppointmentKindForTracking(appointmentKind) || "Therapy",
      appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
      appointment_time_utc: time,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: new Date().getTimezoneOffset(),
      ["Virtual tab"]:
        getAppointmentMediumForTracking(appointmentMedium) === "Video",
      ["In Person tab"]:
        getAppointmentMediumForTracking(appointmentMedium) === "In Person",
      query_request_id: queryRequestId,
      browse_page_number: pageNumber,
      provider_order: providerIndex + 1,
      order: index + 1,
      category: trackingCategory,
    },
  );
};

// directsched04
export const trackMediumTabClicked = ({
  provider,
  appointmentMedium,
  appointmentKind,
}) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Appointment Medium Tab",
    {
      spring_doc_id: "directsched04",
      location: "Provider Profile",
      provider_id: provider.id,
      appointment_type:
        getAppointmentKindForTracking(appointmentKind) || "Therapy",
      appointment_medium: appointmentMedium,
    },
  );
};

// directsched08
export const trackFilterButtonClicked = (buttonTitle) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, buttonTitle, {
    spring_doc_id: "directsched08",
    to: "Filter Flyout",
  });
};

// directsched09
export const trackFilterChangeApplied = (
  filterTag,
  filterCategory,
  isAddedFilter,
  eventLocation,
) => {
  TRACK_EVENT.FILTER_CHANGE_APPLIED(
    window.location.pathname,
    capitalize(filterCategory),
    {
      spring_doc_id: "directsched09",
      location: eventLocation,
      change: isAddedFilter ? "Added" : "Removed",
      value: filterTag,
    },
  );
};

// directsched010
export const trackUpdateFiltersClicked = (tags: any) => {
  const formattedTags = [];

  Object.values(tags).forEach((value: any) => {
    formattedTags.push(value);
  });

  const finalTags = [].concat(...formattedTags);

  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Update Filters", {
    spring_doc_id: "directsched010",
    count: finalTags.length,
    value: finalTags.join(" "),
  });
};

// directsched011 + directsched012
export const trackEditAddressClicked = () => {
  track('Browse Provider Page - Address "Edit" Clicked', {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
      page: window.location.pathname,
      type: "Edit Address",
      to: "Edit Address Modal",
    }),
  });

  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Edit Address", {
    spring_doc_id: "directsched011",
    to: "Edit Address Modal",
  });

  TRACK_EVENT.MODAL_OPENED(window.location.pathname, "Edit Address", {
    spring_doc_id: "directsched012",
  });
};

// directsched013 + directsched014
export const trackEditAddressSubmit = () => {
  TRACK_EVENT.FORM_SUBMITTED(window.location.pathname, "Edit Address", {
    spring_doc_id: "directsched013",
  });
  TRACK_EVENT.MODAL_CLOSED(window.location.pathname, "Edit Address Modal", {
    spring_doc_id: "directsched014",
  });
};

// directsched015
export const trackMoreTimesClicked = ({
  appointmentMedium,
  provider,
  providerIndex,
  pageNumber,
  queryRequestId,
  appointmentKind,
  isBrecsFlag,
  badges,
}: MoreTimesType) => {
  track('Browse Provider Page - "See All Virtual Options" Clicked', {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
      page: window.location.pathname,
      type: "See All Appointments",
    }),
  });

  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "See All Appointments", {
    spring_doc_id: "directsched015",
    location: isBrecsFlag ? "Recommended Provider Card" : "Provider Card",
    to: "Care Provider Schedule Modal",
    provider_id: provider.id,
    appointment_type:
      getAppointmentKindForTracking(appointmentKind) || "Therapy",
    appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
    browse_page_number: pageNumber,
    provider_order: ++providerIndex,
    query_request_id: queryRequestId,
    badges: badges,
  });
};

// directsched016
export const trackProviderCardClicked = ({
  provider,
  providerIndex,
  pageNumber,
  queryRequestId,
  appointmentKind,
}) => {
  track('Browse Provider Page - "See All Virtual Options" Clicked', {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
      page: window.location.pathname,
      type: "See All Appointments",
    }),
  });
  TRACK_EVENT.CARD_CLICKED(
    window.location.pathname,
    "Provider Profile Page",
    "Provider Card",
    {
      spring_doc_id: "directsched016",
      appointment_type:
        getAppointmentKindForTracking(appointmentKind) || "Therapy",
      provider_id: provider.id,
      browse_page_number: pageNumber,
      provider_order: ++providerIndex,
      query_request_id: queryRequestId,
      is_company_exclusive: provider?.dedicated_customer?.name ? true : false,
    },
  );
};

// directsched017
export const trackBioClicked = ({
  provider,
  pageNumber,
  providerIndex,
  isExpanded,
  profileTabFlag,
  queryRequestId,
}: BioType) => {
  const trackName = isExpanded ? "Hide Bio" : "Show Bio";
  track(`Browse Provider Page - ${trackName}`, {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
      page: window.location.pathname,
      type: trackName,
    }),
  });
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, trackName, {
    spring_doc_id: "directsched017",
    location: profileTabFlag ? "Provider Card" : "Provider Profile",
    to: profileTabFlag ? "Provider Profile Page" : "",
    provider_id: provider.id,
    browse_page_number: pageNumber,
    provider_order: providerIndex + 1,
    query_request_id: queryRequestId,
  });
};

// directsched018
export const trackAppointmentSlotSelected = ({
  index,
  dateTime,
  appointmentMedium,
  provider,
  pageNumber,
  providerIndex,
  queryRequestId,
  appointmentKind,
  badges,
}: SlotSelectedType) => {
  track("Browse Provider Page - Virtual Appointment Slot Clicked", {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
      page: window.location.pathname,
      type: "Appointment Selected",
    }),
  });
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Appointment Selected", {
    spring_doc_id: "directsched018",
    location: "Provider Card",
    to: "Care Provider Schedule Modal",
    provider_id: provider.id,
    appointment_type:
      getAppointmentKindForTracking(appointmentKind) || "Therapy",
    appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
    appointment_time: dateTime,
    browse_page_number: pageNumber,
    provider_order: providerIndex + 1,
    order: index + 1,
    query_request_id: queryRequestId,
    badges: badges,
  });
};

// directsched019
export const trackProviderProfileModalOpened = ({
  modalData,
  modalUuid,
  eventProps,
}) => {
  TRACK_EVENT.MODAL_OPENED(
    window.location.pathname,
    window.location.pathname.includes("medication_managers")
      ? getModalNameForTracking(modalIds.medManagementScheduleModal)
      : getModalNameForTracking(modalIds.careProviderScheduleModal),
    {
      spring_doc_id: "directsched019",
      ...eventProps,
      modal_id: modalUuid,
      location: modalData?.mpLocation || "Appointment Confirmation",
      query_request_id: modalData?.queryRequestId,
      browse_page_number: modalData?.pageNumber,
      provider_order: modalData?.providerOrder + 1,
    },
  );
};

// directsched020
export const trackProviderProfileModalClosed = ({
  type,
  modalUuid,
  eventProps,
}) => {
  let modalTypeName = "";
  if (type) {
    modalTypeName = type;
  } else if (window.location.pathname.includes("medication_managers")) {
    modalTypeName = getModalNameForTracking(
      modalIds.medManagementScheduleModal,
    );
  } else {
    modalTypeName = getModalNameForTracking(modalIds.careProviderScheduleModal);
  }

  TRACK_EVENT.MODAL_CLOSED(window.location.pathname, modalTypeName, {
    spring_doc_id: "directsched020",
    ...eventProps,
    modal_id: modalUuid,
  });
};

// directsched021
export const trackAvailabileAppointmentsViewed = ({
  index,
  providerId,
  kind,
  medium,
  appointment,
  modalId,
  timeDifference,
  queryRequestId,
  isProfilePage,
}) => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Available Appointment Care Provider Schedule Modal",
    {
      spring_doc_id: "directsched021",
      provider_id: providerId ?? "",
      appointment_type: getAppointmentKindForTracking(kind),
      appointment_medium: getAppointmentMediumForTracking(medium),
      appointment_time_utc: appointment,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: new Date().getTimezoneOffset(),
      modal_id: modalId,
      time_to_available_appointment: Math.round(timeDifference), // In minutes
      location: isProfilePage
        ? "Provider Profile"
        : getModalNameForTracking(modalIds.careProviderScheduleModal),
      order: index + 1,
      query_request_id: queryRequestId,
    },
  );
};

// directsched022
export const trackSlotPaginationButtonClicked = ({
  directionName,
  providerId,
  appointmentKind,
  appointmentMedium,
  modalId,
}) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, directionName, {
    spring_doc_id: "directsched022",
    location: getModalNameForTracking(modalIds.careProviderScheduleModal),
    provider_id: providerId,
    appointment_type: getAppointmentKindForTracking(appointmentKind),
    appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
    modal_id: modalId,
  });
};

// directsched024
export const trackPaginationNextButton = ({
  currentPage,
  destinationPage,
  totalPages,
}) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Pagination Next", {
    spring_doc_id: "directsched024",
    page_number_from: currentPage,
    page_number_to: destinationPage,
    total_pages: totalPages,
  });
};

// directsched025
export const trackPaginationBackButton = ({
  currentPage,
  destinationPage,
  totalPages,
}) => {
  TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Pagination Back", {
    spring_doc_id: "directsched025",
    page_number_from: currentPage,
    page_number_to: destinationPage,
    total_pages: totalPages,
  });
};

// directsched026
export const trackRequestProviderFormSubmitted = (
  formValues,
  queryRequestId,
) => {
  TRACK_EVENT.FORM_SUBMITTED(window.location.pathname, "Requested Provider", {
    spring_doc_id: "directsched026",
    location: "Request Provider Availability Zendesk Form",
    query_request_id: queryRequestId,
    ...formValues,
  });
};

// directsched027
export const trackRequestSupportedClicked = ({
  appointmentMedium,
  appointmentKind,
  provider,
  queryRequestId,
}: RequestSupportType) => {
  track("Provider Profile", {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
      page: window.location.pathname,
      type: "Request Support",
    }),
  });
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Request Provider Profile",
    {
      spring_doc_id: "directsched027",
      to: "Request Provider Availability Chat",
      provider_id: provider.id,
      appointment_type: getAppointmentKindForTracking(appointmentKind),
      appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
      query_request_id: queryRequestId,
    },
  );
};

// directsched028
export const trackRequestSupportComponentType = ({
  provider,
  appointmentMedium,
  appointmentKind,
  providerIndex,
  pageNumber,
  queryRequestId,
  category = "",
}: RequestSupportType) => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Request Availability",
    {
      spring_doc_id: "directsched028",
      location: "Provider Profile",
      provider_id: provider.id,
      appointment_type: getAppointmentKindForTracking(appointmentKind),
      appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
      browse_page_number: pageNumber,
      provider_order: ++providerIndex,
      query_request_id: queryRequestId,
      category: category,
    },
  );
};

// directsched038
export const trackProviderProfileNoAvailability = ({
  providerId,
  appointmentKind,
  queryRequestId,
}) => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Provider Profile No Availability",
    {
      spring_doc_id: "directsched038",
      location: "Provider Profile",
      provider_id: providerId,
      appointment_type: getAppointmentKindForTracking(appointmentKind),
      query_request_id: queryRequestId,
    },
  );
};

// directsched029
export const trackTimeSlotInModalClicked = ({
  appointmentKind,
  appointmentMedium,
  provider,
  startTime,
  modalId,
  queryRequestId,
  isProfilePage,
}: ModalSlotType) => {
  track("Care Provider Schedule Modal - Select Time Slot", {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
      page: window.location.pathname,
      type: "Appointment Time",
    }),
  });
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Schedule Appointment Time",
    {
      spring_doc_id: "directsched029",
      location: isProfilePage
        ? "Provider Profile"
        : "Care Provider Schedule Modal",
      provider_id: provider?.id,
      appointment_type: getAppointmentKindForTracking(appointmentKind),
      appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
      appointment_time_utc: DateTime.fromISO(startTime).toUTC().toISO(),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: new Date().getTimezoneOffset(),
      modal_id: modalId,
      query_request_id: queryRequestId,
    },
  );
};

// directsched030
export const trackConfirmAppointmentoInModalClicked = ({
  appointmentKind,
  appointmentMedium,
  provider,
  providerIndex,
  pageNumber,
  queryRequestId,
  startTime,
  modalId,
  isSUDcn,
  visits_remaining_total,
  inNetwork,
  // index,
  topTags,
  profileTabFlag,
  category,
  isFromSUDSignalExperience,
  sudSignalExperimentValue,
}: ConfirmAppointmentType) => {
  const trackingName = isSUDcn
    ? "Schedule SUD CN Appointment"
    : "Schedule Appointment";

  track("Care Provider Schedule Modal - Confirm Appointment", {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
      page: window.location.pathname,
      type: trackingName,
    }),
    ...(isFromSUDSignalExperience === "true" && {
      is_from_sud_signal_experiment: isFromSUDSignalExperience,
      experience_type: sudSignalExperimentValue,
    }),
  });

  TRACK_EVENT.FORM_SUBMITTED(window.location.pathname, trackingName, {
    spring_doc_id: "directsched030",
    location: profileTabFlag
      ? "Provider Profile"
      : "Care Provider Schedule Modal",
    provider_id: provider.id,
    appointment_type: getAppointmentKindForTracking(appointmentKind),
    appointment_medium: getAppointmentMediumForTracking(appointmentMedium),
    appointment_time_utc: DateTime.fromISO(startTime).toUTC().toISO(),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    offset: new Date().getTimezoneOffset(),
    modal_id: modalId,
    browse_page_number: pageNumber,
    provider_order: providerIndex,
    query_request_id: queryRequestId,
    is_company_exclusive: provider?.dedicated_customer?.name ? true : false,
    available_session: visits_remaining_total,
    in_network: inNetwork,
    top_specialties: topTags,
    is_associate: provider?.associate_status === "active",
    category: category,
  });
};

// directsched031
export const trackSupportPhoneNumberClicked = ({
  phoneNumber,
  isGeneralSupport,
  isT2Member,
}: PhoneNumberType) => {
  const trackingType = isT2Member
    ? isGeneralSupport
      ? "Freephone"
      : "Direct Dial"
    : isGeneralSupport
      ? "Care and Technical Issues"
      : "Crisis Support";
  const to = `tel:${phoneNumber}`;

  track(`Link Clicked - ${trackingType} Phone Number`, {
    deprecated: true,
    replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
      page: window.location.pathname,
      to: to,
      type: trackingType,
      location: "Request Support Fly-out",
    }),
  });
  TRACK_EVENT.LINK_CLICKED(window.location.pathname, to, trackingType, {
    location: "Request Support Fly-out",
    spring_doc_id: "directsched031",
  });
};

// directsched034
export const track24HourWarning = ({ providerId }) => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Cancellation Charge Warning",
    {
      spring_doc_id: "directsched034",
      provider_id: providerId,
      location: "Available Appointment Care Provider Schedule Modal",
    },
  );
};

// directsched035
export const trackCompanyExclusiveViewed = () => {
  TRACK_EVENT.COMPONENT_VIEWED(
    window.location.pathname,
    "Company Exclusive Toggle",
    { spring_doc_id: "directsched035" },
  );
};

// directsched036
export const trackCompanyExclusiveClicked = (isCompanyExclusive) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Company Exclusive Toggle",
    {
      spring_doc_id: "directsched036",
      change: isCompanyExclusive ? "Removed" : "Added",
      value: "Company Exclusive Toggle",
    },
  );
};

// directsched037
export const trackCarouselBtnClicked = (direction) => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    `Carousel ${direction} Arrow`,
    { spring_doc_id: "directsched037" },
  );
};

export const trackBrecsSeeMoreTherapistsClicked = () => {
  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Brecs See More Therapists",
  );
};
