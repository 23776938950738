import { useQuery } from "@apollo/client";
import { showGatedBooking } from "operations/queries/benefits";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const useCanAccessGatedBooking = (
  appointmentKind,
  memberInfo = undefined,
) => {
  const isMemberPaymentFlagEnabled = useFeatureFlag(
    FLAGS.MEMBER_PAYMENT_COLLECTIONS_M3,
  );

  return useQuery(showGatedBooking, {
    variables: {
      member_id: memberInfo?.id,
      appointment_kind: appointmentKind,
    },
    skip: !memberInfo || !isMemberPaymentFlagEnabled,
  });
};
