import { AppointmentKind } from "@spring/constants";
import { useDispatch } from "react-redux";
import { useAllUpcomingAppointmentSlots } from "hooks";
import { useTranslation } from "hooks/react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useScheduleModalWithProps } from "shared/hooks";
import { openUpNextSchedulingModal } from "modules/MemberDashboard/Teen/Home/utils/teenHomePage.utils";
import { useTeenHomePage } from "./useTeenHomePage";

export const useTeenFAQSection = () => {
  const { therapist, memberData } = useTeenHomePage();

  const { data: upcomingTherapyAppointmentSlots } =
    useAllUpcomingAppointmentSlots(therapist, AppointmentKind.MinorTherapy, 1);

  const dispatch = useDispatch();
  const { t } = useTranslation(["carePlan"]);
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const memberHasInPersonSupport = memberData?.cohort?.in_person_supported;
  const memberCountry = memberData?.postal_address?.country;

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const buttonText = t("carePlanSteps.confirmAppointment");
  const location = "Teen FAQ Section";
  const springDocId = "Teen_FAQ_001";

  const openTherapistModal = () =>
    openUpNextSchedulingModal(
      therapist,
      AppointmentKind.MinorTherapy,
      "Therapist",
      shouldShowNewInsuranceModal,
      onScheduleModalOpen,
      buttonText,
      location,
      springDocId,
      upcomingTherapyAppointmentSlots,
      memberHasInPersonSupport,
      memberCountry,
      dispatch,
    );

  return {
    openTherapistModal,
    therapist,
    isScheduleModalOpen,
    onScheduleModalClose,
    scheduleModalProps,
  };
};
