import { TRACK_EVENT } from "utils/mixpanel";

export const trackBadgeViewedFactory =
  (badgeType, memberId, managedMemberId) => () => {
    const chooseUserCtaTagsForTracking = {
      finish_assessment: "Finish Assessment",
      schedule_initial_appointment: "Schedule Appointment",
      schedule_follow_up_appointment: "Schedule Follow Up",
      upcoming_changes: "Upcoming Changes",
    };

    TRACK_EVENT.COMPONENT_VIEWED(
      window.location.pathname,
      "Choose User Badge",
      {
        spring_doc_id: "choose_user_001",
        member_id: memberId, // manager
        managed_member_id: managedMemberId, // managed dependent
        badge_type: chooseUserCtaTagsForTracking[badgeType],
      },
    );
  };
