/**
 * Enums for passing in User Roles.
 */

const RequestableAppointmentKind = Object.freeze({
	Admin         : 'ADMIN',
	Employer      : 'EMPLOYER',
	Member        : 'MEMBER',
	CareProvider  : 'CARE_PROVIDER',
	Staff         : 'STAFF',
	MedicalBiller : 'MEDICAL_BILLER',
});

module.exports = RequestableAppointmentKind;