const CountryLanguage = Object.freeze({
  AU: { primary: "en", secondary: "en" },
  BR: { primary: "pt", secondary: "en" },
  CA: { primary: "en", secondary: "fr-CA" },
  FR: { primary: "fr", secondary: "en" },
  DE: { primary: "de", secondary: "en" },
  IN: { primary: "en", secondary: "hi" },
  IE: { primary: "en", secondary: "en" },
  SE: { primary: "en", secondary: "en" },
  UK: { primary: "en", secondary: "en" },
  US: { primary: "en", secondary: "es" },
  AR: { primary: "es", secondary: "en" },
  CO: { primary: "es", secondary: "en" },
  CR: { primary: "es", secondary: "en" },
  CL: { primary: "es", secondary: "en" },
  MX: { primary: "es", secondary: "en" },
  ES: { primary: "es", secondary: "en" },
  DO: { primary: "es", secondary: "en" },
  GT: { primary: "es", secondary: "en" },
  SV: { primary: "es", secondary: "en" },
  NZ: { primary: "en", secondary: "en" },
  PA: { primary: "es", secondary: "en" },
  PE: { primary: "es", secondary: "en" },
  EC: { primary: "es", secondary: "en" },
  CH: { primary: "fr", secondary: "en" },
  ZA: { primary: "en", secondary: "en" },
  PL: { primary: "pl", secondary: "en" },
  NL: { primary: "nl", secondary: "en" },
  JP: { primary: "ja", secondary: "en" },
  AT: { primary: "de", secondary: "en" },
  BE: { primary: "fr", secondary: "en" },
  CZ: { primary: "cs", secondary: "en" },
  EG: { primary: "ar", secondary: "en" },
  FI: { primary: "en", secondary: "en" },
  HU: { primary: "hu", secondary: "en" },
  ID: { primary: "id", secondary: "en" },
  IL: { primary: "he", secondary: "en" },
  IT: { primary: "it", secondary: "en" },
  MY: { primary: "ms", secondary: "en" },
  NO: { primary: "en", secondary: "en" },
  PH: { primary: "fil", secondary: "en" },
  PT: { primary: "pt-PT", secondary: "en" },
  RO: { primary: "ro", secondary: "en" },
  SG: { primary: "en", secondary: "zh" },
  KR: { primary: "ko", secondary: "en" },
  AE: { primary: "ar", secondary: "en" },
  DK: { primary: "en", secondary: "en" },
  CY: { primary: "el", secondary: "en" },
  GE: { primary: "en", secondary: "en" },
  GR: { primary: "el", secondary: "en" },
  JO: { primary: "ar", secondary: "en" },
  RS: { primary: "en", secondary: "en" },
  TR: { primary: "tr", secondary: "en" },
  BG: { primary: "en", secondary: "en" },
  EE: { primary: "en", secondary: "en" },
  HR: { primary: "en", secondary: "en" },
  KE: { primary: "en", secondary: "en" },
  KW: { primary: "ar", secondary: "en" },
  KZ: { primary: "en", secondary: "en" },
  LK: { primary: "en", secondary: "en" },
  LT: { primary: "en", secondary: "en" },
  LU: { primary: "fr", secondary: "en" },
  LV: { primary: "en", secondary: "en" },
  MA: { primary: "ar", secondary: "en" },
  NG: { primary: "en", secondary: "en" },
  SI: { primary: "en", secondary: "en" },
  SK: { primary: "en", secondary: "en" },
  UA: { primary: "en", secondary: "en" },
  LB: { primary: "ar", secondary: "en" },
  OM: { primary: "ar", secondary: "en" },
  PK: { primary: "en", secondary: "en" },
  TT: { primary: "en", secondary: "en" },
  UY: { primary: "es", secondary: "en" },
  BH: { primary: "ar", secondary: "en" },
  AM: { primary: "en", secondary: "en" },
  MT: { primary: "en", secondary: "en" },
  CI: { primary: "fr", secondary: "en" },
  BN: { primary: "ms", secondary: "en" },
  BD: { primary: "en", secondary: "en" },
  MO: { primary: "en", secondary: "en" },
  MK: { primary: "en", secondary: "en" },
  TN: { primary: "ar", secondary: "en" },
  BA: { primary: "en", secondary: "en" },
  IS: { primary: "en", secondary: "en" },
  JM: { primary: "en", secondary: "en" },
  MU: { primary: "en", secondary: "en" },
  SN: { primary: "fr", secondary: "en" },
  AO: { primary: "pt", secondary: "en" },
  BO: { primary: "es", secondary: "en" },
  GH: { primary: "en", secondary: "en" },
  NA: { primary: "en", secondary: "en" },
  DZ: { primary: "ar", secondary: "en" },
  BY: { primary: "en", secondary: "en" },
  HN: { primary: "es", secondary: "en" },
  HK: { primary: "zh", secondary: "en" },
});

module.exports = CountryLanguage;
