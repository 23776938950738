/**
 * Enums for provider tags.
 */

const ProviderTags = Object.freeze({
	Conditions   : 'Condition',
	Specialties  : 'Specialty',
	Genders      : 'Gender',
	Ethnicities  : 'Ethnicity',
	Languages    : 'Language',
	SessionTypes : 'Session type',
	DaysOfWeeks  : 'Days of week',
	TimeOfDays   : 'Time of day',
});

module.exports = ProviderTags;
