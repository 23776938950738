const { SpringCDN } = require('./links');

const CareAdvocateCard = 'https://spring-cdn.s3.amazonaws.com/assets/images/members/care-visits/team-member-care-advocate.png';
const CareConsultantCard = 'https://spring-cdn.s3.amazonaws.com/assets/images/members/care-visits/leader-care-consultant.png';
const CoachingCareCard = 'https://spring-cdn.s3.amazonaws.com/assets/images/members/care-visits/coaches.png';
const MedicationManagerCareCard = 'https://spring-cdn.s3.amazonaws.com/assets/images/members/care-visits/medication-managers.png';
const TherapyCareCard = 'https://spring-cdn.s3.amazonaws.com/assets/images/members/care-visits/therapists.png';
const SUDCNCard = SpringCDN + '/assets/images/members/sud-cn.png';
const WorkplaceConsultationCard = 'https://spring-cdn.s3.amazonaws.com/assets/images/members/care-visits/workplace-consultations.jpg';

const MomentsBannerSmall = SpringCDN + '/assets/images/members/moments-preview-banner-small.png';
const MomentsBannerFull = SpringCDN + '/assets/images/members/moments-preview-banner.png';

module.exports = {
	CareAdvocateCard,
	CareConsultantCard,
	CoachingCareCard,
	MedicationManagerCareCard,
	MomentsBannerSmall,
	MomentsBannerFull,
	TherapyCareCard,
	SUDCNCard,
	WorkplaceConsultationCard,
};
