import { SHOW_GLOBAL_EXPERIENCE, LOCALE_COUNTRY } from "actions/global/types";

const initialState = {
  showGlobalExperience: false,
  country: "US",
  lang: "en",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_GLOBAL_EXPERIENCE:
      return {
        ...state,
        showGlobalExperience: payload.showGlobalExperience,
      };

    case LOCALE_COUNTRY:
      return {
        ...state,
        lang: payload?.lang || state.lang,
        country: payload?.country || state.country,
      };

    default:
      return state;
  }
};
