import { useRouter } from "next/router";
import { useMutation, useQuery } from "@apollo/client";
import { getMemberInfo } from "operations/queries/member";
import { createCheckIn } from "operations/mutations/assessment";
import routes from "routes/index";
import { useEffect } from "react";
import { isTeen, isMinorNonTeen } from "utils/memberHelpers";
import { Flex, Spinner } from "design-system/components";
import Meowth from "@spring/meowth";
import { getIterableCampaignInfo } from "utils/localStorage";

const TriggerCheckIn = () => {
  const router = useRouter();

  const { data, loading } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const [createCheckInMutation] = useMutation(createCheckIn, {
    variables: {
      member_id: data && data.user.member?.id,
      campaign: getIterableCampaignInfo(),
    },
    onError: () => {
      goToHomePage();
    },
    onCompleted: ({ createCheckIn }) => {
      mutationComplete(createCheckIn, router);
    },
  });

  const mutationComplete = (createCheckIn, router) => {
    const { id } = createCheckIn.assessment;
    let { as } = routes.TakeMemberAssessment;

    as = as.replace(":id", id);
    router.replace(
      {
        pathname: routes.TakeMemberAssessment.to,
        query: { id },
      },
      as,
    );
  };

  const goToHomePage = () => {
    router.replace(routes.MemberHome.to, routes.MemberHome.as);
  };

  useEffect(() => {
    const isATeen = isTeen(data?.user?.member?.date_of_birth);
    const isMinor = isMinorNonTeen(data?.user?.member?.date_of_birth);
    if (isATeen) {
      router.replace(routes.TeenHome.as);
    } else if (isMinor) {
      goToHomePage();
    } else if (!loading && data?.user?.member?.id) {
      createCheckInMutation();
    }
  }, [loading]);

  return (
    <Flex align="center" justify="center" h="100vh" w="100vw">
      <Spinner speed="1s" size="xl" />
    </Flex>
  );
};

export default TriggerCheckIn;
