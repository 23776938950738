import React from "react";

const OutlookSvgIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.351V19.6485L11 21.5366V2.46288L2 4.351ZM0.794679 2.56032L11.7947 0.252631C12.416 0.122276 13 0.596441 13 1.23133V22.7682C13 23.4031 12.416 23.8773 11.7947 23.7469L0.794681 21.4392C0.331609 21.3421 0 20.9337 0 20.4605V3.53902C0 3.06587 0.331608 2.65747 0.794679 2.56032Z"
      fill="#110A09"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.52132 13.6282C6.78054 13.2826 7 12.7134 7 11.9998C7 11.2861 6.78054 10.717 6.52132 10.3713C6.26256 10.0263 6.05777 9.99977 6 9.99977C5.94223 9.99977 5.73744 10.0263 5.47868 10.3713C5.21946 10.717 5 11.2861 5 11.9998C5 12.7134 5.21946 13.2826 5.47868 13.6282C5.73744 13.9732 5.94223 13.9998 6 13.9998C6.05777 13.9998 6.26256 13.9732 6.52132 13.6282ZM6 15.9998C7.65685 15.9998 9 14.2089 9 11.9998C9 9.79063 7.65685 7.99977 6 7.99977C4.34315 7.99977 3 9.79063 3 11.9998C3 14.2089 4.34315 15.9998 6 15.9998Z"
      fill="#110A09"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6.99977C14 6.44748 14.4477 5.99977 15 5.99977H23C23.5523 5.99977 24 6.44748 24 6.99977V16.9998C24 18.1043 23.1046 18.9998 22 18.9998H15C14.4477 18.9998 14 18.5521 14 17.9998C14 17.4475 14.4477 16.9998 15 16.9998H22V8.9008L15.5665 13.3238C15.1114 13.6367 14.4888 13.5214 14.176 13.0663C13.8631 12.6112 13.9784 11.9886 14.4335 11.6757L19.7803 7.99977H15C14.4477 7.99977 14 7.55205 14 6.99977Z"
      fill="#110A09"
    />
  </svg>
);

export default OutlookSvgIcon;
