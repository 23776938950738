import { Card, Badge, Avatar, Link } from "@springcare/sh-component-library";
import {
  VStack,
  Text,
  Box,
  HStack,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AddSvg } from "./icons";
import { useEffect, useRef } from "react";
import { useInViewportOnce } from "hooks";
import { TRACK_EVENT, CARD_TYPE } from "utils/mixpanel";
import routes from "routes";
import { UpcomingChangesBadge } from "modules/shared/components/Notifications/components";

type DependentProps = {
  id: string;
  within_aging_out?: string;
  date_of_birth: string;
};

type ChooseUserCardType = {
  badge?: string;
  heading?: string;
  subheading?: string;
  to?: string;
  dataCy?: string;
  Icon?: React.ElementType;
  handleCardClickTracking?: (heading: string, to?: string) => void;
  handleBadgeViewedTracking?: () => void;
  dependent?: DependentProps;
};

export const ChooseUserCard = ({
  badge,
  heading,
  subheading,
  to,
  dataCy,
  Icon,
  handleBadgeViewedTracking,
  dependent,
}: ChooseUserCardType): JSX.Element => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");

  useEffect(() => {
    if (badge && inViewport) {
      handleBadgeViewedTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport, badge]);

  const handleCardClickTracking = (subheading = "Add") => {
    const cardType = {
      Guardian: CARD_TYPE.GUARDIAN,
      Child: CARD_TYPE.CHILD,
      Add: CARD_TYPE.ADD_CHILD,
    };

    TRACK_EVENT.CARD_CLICKED(routes.ChooseUser.as, to, cardType[subheading]);
  };

  const UserCardView = useBreakpointValue([
    ChooseUserMobileView,
    ChooseUserDesktopView,
    ChooseUserDesktopView,
  ]);

  return (
    <div ref={ref}>
      <UserCardView
        heading={heading}
        subheading={subheading}
        to={to}
        badge={badge}
        dependent={dependent}
        handleCardClickTracking={handleCardClickTracking}
        Icon={Icon}
        dataCy={dataCy}
      />
    </div>
  );
};

export const ChooseUserDesktopView = ({
  badge,
  heading,
  subheading,
  to,
  Icon,
  dataCy,
  dependent,
  handleCardClickTracking,
}: ChooseUserCardType) => {
  return (
    <Link
      display="inline-block"
      borderRadius="16px"
      href={to}
      style={{ textDecoration: "none" }}
      onClick={() => {
        handleCardClickTracking(heading);
      }}
    >
      <Card data-cy={dataCy} data-testid="user-view">
        <HStack width={371} gap={8}>
          <Box data-testid="avatar">
            {Icon ? (
              <Avatar aria-hidden="true" icon={Icon} height={104} width={104} />
            ) : (
              <AddSvg aria-hidden="true" width={104} height={104} />
            )}
          </Box>
          <VStack h="100%" alignItems="start">
            {badge && badge !== "seventeen_to_eighteen" && (
              <Badge variant="medium-emphasis" colorScheme="neutral" mb="v-4">
                {badge}
              </Badge>
            )}
            {badge && badge === "seventeen_to_eighteen" && (
              <Box mb="v-4">
                <UpcomingChangesBadge
                  dateOfBirth={dependent.date_of_birth}
                  agingOutCategory={dependent.within_aging_out}
                  minorId={dependent.id}
                />
              </Box>
            )}
            <Text
              as="h2"
              size="body-medium-strong"
              color="content-primary"
              mb="v-4"
            >
              {heading}
            </Text>
            <Text size="body-medium" color="content-secondary">
              {subheading}
            </Text>
          </VStack>
        </HStack>
      </Card>
    </Link>
  );
};

const ChooseUserMobileView = ({
  Icon,
  badge,
  heading,
  subheading,
  to,
  dataCy,
  dependent,
  handleCardClickTracking,
}: ChooseUserCardType) => {
  return (
    <Box
      as="a"
      w="100%"
      href={to}
      onClick={() => {
        handleCardClickTracking(subheading);
      }}
      data-testid="user-view"
      data-cy={dataCy}
    >
      <Flex alignItems="center" display="flex" flexDir="row">
        <Box data-testid="avatar">
          {Icon ? (
            <Avatar aria-hidden="true" icon={Icon} height={104} width={104} />
          ) : (
            <AddSvg aria-hidden="true" width={104} height={104} />
          )}
        </Box>
        <VStack ml={16} alignItems="flex-start">
          <Box h="100%">
            {badge && badge !== "seventeen_to_eighteen" && (
              <Badge variant="medium-emphasis" colorScheme="neutral" mb="v-4">
                {badge}
              </Badge>
            )}
            {badge && badge === "seventeen_to_eighteen" && (
              <Box mb="v-4">
                <UpcomingChangesBadge
                  dateOfBirth={dependent.date_of_birth}
                  agingOutCategory={dependent.within_aging_out}
                  minorId={dependent.id}
                />
              </Box>
            )}
            <Box>
              <Text size="body-medium-strong" color="content-primary" mb="v-4">
                {heading}
              </Text>
            </Box>
            <Box>
              <Text size="body-medium" color="content-secondary">
                {subheading}
              </Text>
            </Box>
          </Box>
        </VStack>
      </Flex>
    </Box>
  );
};
