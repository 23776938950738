import { gql } from "@apollo/client";

const findCoveredLife = gql`
  query findCoveredLife(
    $first_name: TrimWhitespace!
    $last_name: TrimWhitespace!
    $date_of_birth: Date!
    $email: TrimWhitespace
    $invite_token: String
    $request_kind: RequestKindEnum
    $covered_life_id: ID
    $secure: Boolean
    $form_name: String
    $event_id: String
  ) {
    find_covered_life(
      first_name: $first_name
      last_name: $last_name
      date_of_birth: $date_of_birth
      email: $email
      invite_token: $invite_token
      request_kind: $request_kind
      covered_life_id: $covered_life_id
      secure: $secure
      form_name: $form_name
      event_id: $event_id
    ) {
      first_name
      last_name
      date_of_birth
      email
      covered_life_id
      customer_id
      invite_token
      success
      city
      state
      country
      total_visits_covered
      is_dependent
      email_sent
      primary_industry
      sub_industry
    }
  }
`;

export default findCoveredLife;
