import { isUnitedStatesOrCanada } from "utils/global";

interface ITagData {
  sessionTypeTags?: [string];
  daysOfWeekTags?: [string];
  conditionsTags?: [string];
  ethnicitiesTags?: [string];
  languagesTags?: [string];
  specialtiesTags?: [string];
}
/**
 * splitSessionTypeTag function returns an object of values:
 * - tagData: an array of CareProviderTags(see getCareProviderTags)
 * ...without the sesssionTagData as that's not a real CP tag in the database
 * - sessionTypeTags: an object of shape:
 * { in_person_supported: Boolean, virtual_supported: Boolean }
 */
export const splitSessionTypeTag = (tagData: ITagData = {}) => {
  // TOOD: make this actually indicate if it's in person or virtual
  const { sessionTypeTags, ...theRest } = tagData;
  const supportedSessionFilters = sessionTypeTags?.length > 0 && {
    in_person_supported: sessionTypeTags?.includes("In Person"),
    virtual_supported: sessionTypeTags?.includes("Virtual"),
  };
  return { ...supportedSessionFilters, tagData: theRest };
};

/**
 * Base refetch options that should be computed at the start
 * - address
 * - state
 * - country
 * - provider role
 * - radius
 * - member supports in person/ virtual?
 * - languages
 *
 * Beyond the inperson/ virtual supported and languages options,
 * everything else more or less stays the same
 */
// TOOD: make sure there's a global version of these interfaces
interface IPostalAddress {
  street_address_1: string;
  street_address_2?: string;
  city: string;
  state: string;
  country: string;
}

interface IMemberCohort {
  in_person_supported: Boolean;
  virtual_supported: Boolean;
  [key: string]: any;
}

interface IBrowseCareProviderArgs {
  role: string;
  distance_from: { address: string };
  radius_search: { address: string; radius_mile: number };
  licensed_countries: [string];
  languages: [string];
  care_provider_tag_ids_exact_match: Boolean;
  care_provider_tag_ids: [string];
  appointment_kind_mediums: { kind?: string; mediums?: [string] };
}

export const buildBaseRefetchOptions = (
  postal_address: IPostalAddress,
  role: string,
  radius_mile: number,
  isAMinor: Boolean,
  memberCohort: IMemberCohort,
  languages: [string],
  care_provider_tag_ids: [string],
): IBrowseCareProviderArgs => {
  const { street_address_1, state, city, country } = postal_address ?? {};
  const address = [street_address_1, city, state, country]
    .filter(Boolean)
    .join(", ");
  let appointment_kind_mediums = {};

  if (role === "therapist") {
    const in_person_supported = memberCohort?.in_person_supported;
    const mediums = in_person_supported
      ? ["Virtual", "In_Person"]
      : ["Virtual"];
    const kind = isAMinor ? "Minor Therapy" : "Therapy";
    appointment_kind_mediums = {
      kind,
      mediums,
    };
  }

  return {
    role,
    distance_from: { address },
    radius_search: { address, radius_mile },
    licensed_countries: [country],
    care_provider_tag_ids_exact_match: isAMinor,
    appointment_kind_mediums: appointment_kind_mediums,
    languages,
    care_provider_tag_ids,
    // Conditionally add a state property in
    ...(isUnitedStatesOrCanada(country) && { states: [state] }),
  };
};
