//

import { gql } from "@apollo/client";

const getUserSMSInfo = gql`
  query getUserSMSInfo($id: ID!) {
    user(id: $id) {
      id
      phone
      member {
        id
        sms_opt_in
      }
    }
  }
`;

export default getUserSMSInfo;
