/**
 * NOTE: this is not actual tag data returned by the backend but modeled like it
 * - not ideal but there's a lot happening when providers are filtered and this just works
 * - TODO: find a nicer way to do this that doesn't involve a constant
 */
export const SESSION_TYPE_TAG_DATA = [
  {
    kind: "SESSION TYPE",
    name: "Virtual",
    id: "Virtual",
    roles: [],
  },
  {
    kind: "SESSION TYPE",
    name: "In Person",
    id: "In Person",
    roles: [],
  },
];

export const DAY_OF_WEEK_TAG_DATA = [
  {
    kind: "DAY OF WEEK",
    name: "Monday",
    id: "Monday",
    roles: [],
  },
  {
    kind: "DAY OF WEEK",
    name: "Tuesday",
    id: "Tuesday",
    roles: [],
  },
  {
    kind: "DAY OF WEEK",
    name: "Wednesday",
    id: "Wednesday",
    roles: [],
  },
  {
    kind: "DAY OF WEEK",
    name: "Thursday",
    id: "Thursday",
    roles: [],
  },
  {
    kind: "DAY OF WEEK",
    name: "Friday",
    id: "Friday",
    roles: [],
  },
  {
    kind: "DAY OF WEEK",
    name: "Saturday",
    id: "Saturday",
    roles: [],
  },
  {
    kind: "DAY OF WEEK",
    name: "Sunday",
    id: "Sunday",
    roles: [],
  },
];

export const TIME_OF_DAY_TAG_DATA = [
  {
    kind: "TIME OF DAY",
    name: "Morning (before 12pm)",
    id: "Morning",
    roles: [],
  },
  {
    kind: "TIME OF DAY",
    name: "Afternoon (before 5pm)",
    id: "Afternoon",
    roles: [],
  },
  {
    kind: "TIME OF DAY",
    name: "Evening (after 5pm)",
    id: "Evening",
    roles: [],
  },
];
