import PropTypes from "prop-types";
import { format } from "date-fns/format";

import { Box, Heading, Text } from "@chakra-ui/react";

const SeeResults = ({ selectedAssessment, t }) => {
  return (
    <Box p={24} pb={0}>
      <Heading size="heading-medium" as="h1">
        {t("seeResults.headerText")}
      </Heading>
      <Text size="body-medium">
        {format(selectedAssessment.ended_at, "MM/dd/yyyy")}
      </Text>
    </Box>
  );
};

SeeResults.propTypes = {
  selectedAssessment: PropTypes.object,
  t: PropTypes.func,
};

export default SeeResults;
