import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const generateTeenInitialAssessment = gql`
  mutation generateTeenAssessment($member_id: ID!, $campaign: CampaignInput) {
    generateTeenAssessment(member_id: $member_id, campaign: $campaign) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default generateTeenInitialAssessment;
