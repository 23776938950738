import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const useShouldShowSUDCNCalendar = (
  sudSupported: boolean,
  latestSUDRisk: string | null = null,
) => {
  const isSUDCNCalendarFlagEnabled = useFeatureFlag(
    FLAGS.SHOW_SUD_CN_CALENDAR_SCHEDULING,
  );

  const shouldShowSUDCNCalendar =
    isSUDCNCalendarFlagEnabled && sudSupported && latestSUDRisk != null;

  return {
    shouldShowSUDCNCalendar,
  };
};
