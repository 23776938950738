import * as yup from "yup";
import _ from "lodash";
import i18n from "i18n";

// { path: "first_name" } => First Name
// { path: "dob.year" } => Year
// If in limitedLangValidations.json...
// { path: "postal_address.street_address_1" } => Home Address
// { path: "preferredPharmacy.name" } => Pharmacy Name
const getFieldName = (field) => {
  if (!field) return "This field";
  if (field.label) return field.label;

  const fullPath = field?.path;
  const pathNameArr = fullPath ? fullPath.split(".") : [];
  const pathName = pathNameArr.length && pathNameArr[pathNameArr.length - 1];

  if (!pathName || !pathName.length) return "This field";

  let translatedLabel;
  if (i18nInitialized) {
    translatedLabel = [fullPath, pathName].reduce((currLabel, path) => {
      if (realTranslation(currLabel)) return currLabel;
      return i18n.t(`labels.${path}`, { ns: "limitedLangValidations" });
    }, translatedLabel);
  }

  return realTranslation(translatedLabel)
    ? translatedLabel
    : _.startCase(pathName);
};

const realTranslation = (translatedLabel) => {
  return (
    translatedLabel &&
    translatedLabel.length &&
    !translatedLabel.includes("labels")
  );
};

// This allows us to have generalized translated error messages
let i18nInitialized = false;

i18n.on("initialized", function () {
  i18n
    .loadNamespaces("limitedLangValidations")
    .then(() => {
      i18nInitialized = true;
      setLocale();
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
});

const getErrorMessage = (translationKey, field, name) => {
  if (!i18nInitialized) return null;
  const fieldName = name || getFieldName(field);
  return i18n.t(translationKey, {
    fieldName: fieldName,
    ns: "limitedLangValidations",
    ...field,
  });
};

const setLocale = () =>
  yup.setLocale({
    mixed: {
      required: (field) => getErrorMessage("required", field),
      length: (field) => getErrorMessage("length", field),
      min: (field) => getErrorMessage("string_min", field),
      max: (field) => getErrorMessage("string_max", field),
    },
    string: {
      email: (field) => getErrorMessage("email", field),
      length: (field) => getErrorMessage("length", field),
      min: (field) => getErrorMessage("string_min", field),
      max: (field) => getErrorMessage("string_max", field),
    },
    number: {
      length: (field) => getErrorMessage("length", field),
      min: (field) => getErrorMessage("number_min", field),
      max: (field) => getErrorMessage("number_max", field),
      positive: (field) => getErrorMessage("positive", field),
    },
  });

setLocale();

export default yup;
