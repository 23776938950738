import { useQuery } from "@apollo/client";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { flow } from "lodash/fp";
import {
  Bolded,
  Breakpoint,
  Button,
  Content,
  FlexRow,
  Grid,
  LoadingCircle,
  MomentsExerciseCard,
} from "@spring/smeargle";
import { openModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import { setIn } from "@spring/immutability";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

import styles from "./styles.module.scss";
import getExercises from "operations/queries/exercise/getExercises";
import { getFirstError } from "utils/apollo/errorHandler";

import { toTitleCase } from "utils/mixpanel";
import { Heading } from "design-system/components";
import {
  MomentsExerciseModal,
  MomentsCompleteExerciseModal,
  CareProviderScheduleModal,
  AvailabilityRequestModal,
  CareProviderScheduleModalV2,
} from "components";
import { MomentsMenu } from "./MomentsMenu";
import { useScheduleModalWithProps } from "shared/hooks";
import { VideoMomentCard } from "modules/MemberDashboard/Moments/components/VideoMomentCard";
import getExercise from "operations/queries/exercise/getExercise";
import { useDispatch } from "react-redux";
import { Flex, useDisclosure } from "@chakra-ui/react";
import {
  VideoMomentModalOverlay,
  MomentsStartModal,
  ExitMomentModal,
} from "modules/MemberDashboard/Moments/components";
import { trackMomentExerciseClicked } from "modules/MemberDashboard/Moments/components/analytics";
import { useCarePopupExperiment } from "modules/MemberDashboard/Moments/hooks/useCarePopupExperiment";
import { CarePopupExperimentModal } from "modules/MemberDashboard/Moments/components/CarePopupExperimentModal/CarePopupExperimentModal";
import { addExerciseIdToQueryParams } from "utils/routes";

const MemberMoments = (props) => {
  const router = useRouter();
  const categoryQuery = router.query.category;
  const momentId = router.query.id;
  const dispatch = useDispatch();

  const {
    isOpen: isVideoModalOpen,
    onOpen: onOpenVideoModal,
    onClose: onCloseVideoModal,
  } = useDisclosure();
  const {
    isOpen: isStartModalOpen,
    onOpen: openStartModal,
    onClose: closeStartModal,
  } = useDisclosure();
  const {
    isOpen: isExitModalOpen,
    onOpen: onOpenExitModal,
    onClose: onCloseExitModal,
  } = useDisclosure();

  const { data: momentByIdData, loading: momentByIdloading } = useQuery(
    getExercise,
    {
      variables: {
        id: momentId,
      },
      skip: !momentId,
    },
  );

  useEffect(() => {
    if (momentByIdData && !momentByIdloading && momentId) {
      if (momentByIdData.exercise?.content_formats?.includes("VIDEO")) {
        openStartModal();
      } else {
        dispatch(
          openModal(
            modalIds.momentsStartExerciseModal,
            momentByIdData.exercise,
          ),
        );
      }
    }
  }, [momentByIdData, momentByIdloading, openModal, momentId]);

  const { data, loading, error, fetchMore, refetch } = useQuery(getExercises, {
    variables: {
      category: categoryQuery ? categoryQuery : null,
      limit: 15,
    },
  });

  const {
    isLoading: isLoadingCarePopupExperiment,
    isControlGroupMember,
    isCoachingTreatmentMember,
    isTherapyTreatmentMember,
    remainingSessions,
    setModalViewedThisSession,
    isCarePopupExperimentModalOpen,
    onCloseCarePopupExperimentModal,
    carePopupExperimentFeatureFlag,
    totalCompletedExercises,
  } = useCarePopupExperiment();

  const handleStartExercise = () => {
    closeStartModal();
    onOpenVideoModal();
  };

  const handleVideoClose = () => {
    onOpenExitModal();
  };
  const handleExitMoment = () => {
    onCloseExitModal();
    onCloseVideoModal();
  };

  const CARDS_RENDERED = 15;
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadingRef = useRef();
  const { t } = useTranslation(["moments", "a11y"]);
  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const getExerciseCards = () => {
    const iconAriaLabels = {
      book: t("icons.bookAlt", { ns: "a11y" }),
      headphones: t("icons.headphonesAlt", { ns: "a11y" }),
    };

    return data?.exercises.data.map((exercise, i) => {
      return (
        <div key={exercise.id} id={i}>
          {exercise?.content_formats?.includes("VIDEO") ? (
            <VideoMomentCard
              exercise={exercise}
              locationForTracking="Member moments section"
            />
          ) : (
            <MomentsExerciseCard
              focusRef={i % CARDS_RENDERED === 0 ? loadingRef : null}
              exercise={exercise}
              iconAriaLabels={iconAriaLabels}
              dataCy="browse-all-moments"
              t={t}
              onClick={(e) => {
                trackMomentExerciseClicked(
                  exercise.id,
                  exercise.title,
                  exercise.categories
                    ?.map((item) => toTitleCase(item.category))
                    .join(", "),
                  exercise.content_formats?.join(", "),
                );
                props.openModal(modalIds.momentsStartExerciseModal, {
                  ...exercise,
                  setReturnFocus: e.target,
                });
                addExerciseIdToQueryParams(router, exercise);
              }}
            />
          )}
        </div>
      );
    });
  };

  const loadMoreExercises = async () => {
    setIsLoadingMore(true);

    await fetchMore({
      variables: {
        offset: data.exercises.paging.page * data.exercises.paging.limit,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const dataPath = "exercises";

        if (!fetchMoreResult) {
          return prev;
        }

        const data = [
          ...prev[dataPath].data,
          ...fetchMoreResult[dataPath].data,
        ];
        const paging = fetchMoreResult[dataPath].paging;

        return flow(
          setIn(`${dataPath}.data`, data),
          setIn(`${dataPath}.paging`, paging),
        )(prev);
      },
    });
    loadingRef.current?.focus();
    setIsLoadingMore(false);
  };

  const breakpointWrapper = () => {
    const exerciseCards = getExerciseCards();

    return (
      <div role="tabpanel" className={styles.breakpointWrapper}>
        {/* "sm andDown" is not properly rendering the content, so we need to separately declare xs and sm breakpoints */}
        <Breakpoint xs>
          <Grid oneCol>{exerciseCards}</Grid>
        </Breakpoint>

        <Breakpoint sm>
          <Grid oneCol>{exerciseCards}</Grid>
        </Breakpoint>

        <Breakpoint md>
          <Grid twoCol>{exerciseCards}</Grid>
        </Breakpoint>

        <Breakpoint lg andUp>
          <Grid threeCol gutter="24px">
            {exerciseCards}
          </Grid>
        </Breakpoint>
      </div>
    );
  };

  return (
    <>
      <div className={styles.subtitle}>
        <Heading fontSize={20}>{t("browseAll")}</Heading>
      </div>
      <div className={styles.categoryTags}>
        <MomentsMenu refetch={refetch} />
      </div>
      {
        // Loading state
        loading && <LoadingCircle />
      }
      {
        // Error state
        !loading && error && (
          <div className={styles.error}>
            <Bolded>{t("error")}</Bolded>
            <Content inheritColor>{getFirstError(error)}</Content>
          </div>
        )
      }
      {
        // Exercise cards
        !loading && data && breakpointWrapper()
      }
      {
        // Load more
        data?.exercises &&
          data.exercises.paging.page < data.exercises.paging.pages &&
          (isLoadingMore ? (
            <Flex>
              <LoadingCircle />
            </Flex>
          ) : (
            <FlexRow justification="center">
              <div className={styles.loadMoreButton}>
                <Button
                  text={t("loadMoreMoments")}
                  onClick={loadMoreExercises}
                  dataCy="load-more-moments"
                  disabled={loading || isLoadingMore}
                  flat
                  full
                  reverse
                />
              </div>
            </FlexRow>
          ))
      }
      <MomentsExerciseModal
        refetchMemberAppointmentAndAssessmentData={
          props.refetchMemberAppointmentAndAssessmentData
        }
      />
      {/* 
      TODO: https://springhealth.atlassian.net/browse/MXE-506
       */}
      <MomentsCompleteExerciseModal
        onScheduleModalOpen={onScheduleModalOpen}
        isChakraModalOpen={isCarePopupExperimentModalOpen}
      />
      <CareProviderScheduleModal />
      <AvailabilityRequestModal />
      <CareProviderScheduleModalV2
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
        {...scheduleModalProps}
      />
      <VideoMomentModalOverlay
        isOpen={isVideoModalOpen}
        onClose={handleVideoClose}
        exercise={momentByIdData?.exercise}
        onOpenExitModal={onOpenExitModal}
        onCloseVideoModal={onCloseVideoModal}
        refetchMemberAppointmentAndAssessmentData={
          props.refetchMemberAppointmentAndAssessmentData
        }
      />
      <MomentsStartModal
        isOpen={isStartModalOpen}
        onClose={closeStartModal}
        onStartExercise={handleStartExercise}
        exercise={momentByIdData?.exercise}
      />
      <ExitMomentModal
        isOpen={isExitModalOpen}
        onClose={onCloseExitModal}
        onCloseMomentModal={handleExitMoment}
        exercise={momentByIdData?.exercise}
      />

      {/* Moments Experiment #4: https://springhealth.atlassian.net/browse/MXE-679 */}
      {carePopupExperimentFeatureFlag &&
        !isLoadingCarePopupExperiment &&
        !isControlGroupMember && (
          <CarePopupExperimentModal
            isCoachingTreatmentMember={isCoachingTreatmentMember}
            isTherapyTreatmentMember={isTherapyTreatmentMember}
            remainingSessions={remainingSessions}
            setModalViewedThisSession={setModalViewedThisSession}
            isOpen={isCarePopupExperimentModalOpen}
            onClose={onCloseCarePopupExperimentModal}
            totalCompletedExercises={totalCompletedExercises}
          />
        )}
    </>
  );
};

MemberMoments.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default connect(null, { openModal })(MemberMoments);
