//

import React, { Component } from "react";
import {
  Subhead,
  Section,
  FlexRow,
  Button,
  FlexCol,
  Subtitle,
} from "@spring/smeargle";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { track } from "utils/mixpanel";

class GoalProgressCheckinCard extends Component {
  componentDidMount() {
    track("Your Journey -- Goal Progress Check In Card Shown");
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Section size="lg">
          <FlexCol alignment="center">
            <Subhead center bold noMargin>
              {t("goalProgressCheckinCard.subhead")}
            </Subhead>
            <Section size="lg">
              <Subtitle noMargin>
                {t("goalProgressCheckinCard.subtitle")}
              </Subtitle>
            </Section>
          </FlexCol>
        </Section>
        <FlexRow justification="flex-end">
          <Button
            text={t("goalProgressCheckinCard.buttonText")}
            icon="arrow-right"
            onClick={this.props.onNext}
          />
        </FlexRow>
      </div>
    );
  }
}

GoalProgressCheckinCard.propTypes = {
  onNext: PropTypes.any,
  t: PropTypes.func,
};

export default withTranslation("goals")(GoalProgressCheckinCard);
