import { Card } from "@springcare/sh-component-library";
import { Box } from "@chakra-ui/react";
import Ellipse from "design-system/assets/icons/Ellipse";
import { Flex, Text } from "design-system/components";
import routes from "routes";
import Router from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";
import { ReactNode } from "react";
import { useMutation } from "@apollo/client";
import { updateMember } from "operations/mutations/member";
import { useDispatch } from "react-redux";
import { updatePreAssessedExperienceModel } from "actions/preAssessedExperience";
import { IntentOptions } from "components/organisms/MemberIntentQuestion/IntentOptionsEnum";

interface MemberIntentCardProps {
  icon: ReactNode;
  title: string;
  titleKey: string;
  description: string;
  ellipseColor?: string;
  ellipseOpacity?: number;
  [key: string]: any;
}

const MemberIntentCard = ({
  icon,
  title,
  titleKey,
  description,
  ellipseColor,
  ellipseOpacity,
  id,
  ...props
}: MemberIntentCardProps) => {
  const intentCardAnalyticsMapping = {
    SelfCare: "I need care for myself",
    HouseholdCare: "I want care for someone else in my household",
    SelfGuidedActivity: "I want self guided activities and resources",
    ManagerTeamCare: "I want support as a manager of a team",
    NotSure: "I’m not sure yet",
  };

  const [doUpdateMember] = useMutation(updateMember);

  const dispatch = useDispatch();

  function handlePreAssessedExperienceRouting(intent: string): {
    to: string;
    as: string;
  } {
    let to, as;

    if (!intent) {
      return {
        to: routes.MemberExpectations.to,
        as: routes.MemberExpectations.as,
      };
    }

    switch (intent) {
      case IntentOptions.SelfCare:
        to = routes.TakeInitialAssessment.to;
        as = routes.TakeInitialAssessment.as;
        break;
      case IntentOptions.HouseholdCare:
        to = routes.MemberHome.to;
        as = routes.MemberHome.as;
        break;
      case IntentOptions.SelfGuidedActivity:
        to = routes.MemberExpectations.to;
        as = routes.MemberExpectations.as;
        break;
      case IntentOptions.NotSure:
        to = routes.MemberHome.to;
        as = routes.MemberHome.as;
        break;
      case IntentOptions.ManagerTeamCare:
      default:
        to = routes.MemberExpectations.to;
        as = routes.MemberExpectations.as;
    }
    return { to, as };
  }

  const handleCardClicked = async () => {
    const memberData = await doUpdateMember({
      variables: {
        input: {
          id,
          patch: {
            intent_option: titleKey,
          },
        },
      },
    });

    const { to, as } = handlePreAssessedExperienceRouting(
      memberData?.data?.updateMember?.member.intent_option,
    );

    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      as,
      intentCardAnalyticsMapping[titleKey],
    );

    dispatch(updatePreAssessedExperienceModel({ selectedOption: titleKey }));

    Router.push(to, as);
  };
  return (
    <Card
      size="sm"
      variant="low-emphasis"
      h={124}
      p={0}
      {...props}
      onClick={handleCardClicked}
    >
      <Flex direction={"row"} p={16}>
        <Box w="40px" h="40px" position="relative" paddingEnd={16}>
          <Box
            as={() => <Ellipse color={ellipseColor} opacity={ellipseOpacity} />}
            position="absolute"
            top="0"
            insetStart="0"
            width="100%"
            height="100%"
          />
          <Box
            position="absolute"
            top="60%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            {icon}
          </Box>
        </Box>
        <Box
          maxWidth={239}
          maxHeight={48}
          paddingStart={16}
          alignContent={"center"}
        >
          <Box>
            <Text fontWeight={600} fontSize={16}>
              {title}
            </Text>
            <Text fontWeight={400} fontSize={12} color={"content-secondary"}>
              {description}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Card>
  );
};

export default MemberIntentCard;
