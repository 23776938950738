export const parseAndBoldBetweenAsterisks = (text) => {
  if (!text) return;

  // this finds text in between two asterisks
  // e.g. it would find **nervous, anxious, or on edge**
  const regex = /\*\*([\s\S]+?)\*\*/g;

  text = text.replace(regex, "<strong>$1</strong>");

  return text;
};
