import { gql } from "@apollo/client";

export const getAllowDirectScheduling = gql`
  query getMemberCoachingTier($id: ID!) {
    member_coaching_tier(id: $id) {
      allow_direct_scheduling
      unlimited_coaching_direct_scheduling
    }
  }
`;
