import React from 'react';
import { modalIds } from '@spring/constants';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

import { Button, Modal, FlexCol, Grid, Col,
	LinkButton,
} from '../../../components';






const ExitModal = (props) => (
	<Modal id={modalIds.exitModal} size="md" title={props.message} hideModalCloseButton>
		<FlexCol center>
			<div className={classNames(styles.title, { [styles.withSubtitleText]: props.subtitleText })}>
				{props.message}
			</div>
			{props.subtitleText &&
				<div className={styles.subtitle}>
					{props.subtitleText}
				</div>}
			{props.emphasizeDecline ?
				<Grid>
					<Col md={12} verticalPadding>
						<Button
							full
							flat
							text={props.declineText}
							onClick={props.declineAction}
						/>
					</Col>
					<Col md={12} verticalPadding>
						<div className={styles.centerLinkButton}>
							<LinkButton
								text={props.confirmationText}
								onClick={props.confirmationAction}
							/>
						</div>
					</Col>
				</Grid> :
				<Grid>
					<Col md={6} verticalPadding>
						<Button
							secondary
							full
							flat
							text={props.declineText}
							onClick={props.declineAction}
						/>
					</Col>
					<Col md={6} verticalPadding>
						<Button
							full
							flat
							text={props.confirmationText}
							onClick={props.confirmationAction}
						/>
					</Col>
				</Grid>}

		</FlexCol>
	</Modal>
);

ExitModal.propTypes = {
	message            : PropTypes.string.isRequired,
	confirmationText   : PropTypes.string.isRequired,
	declineText        : PropTypes.string.isRequired,
	confirmationAction : PropTypes.func.isRequired,
	declineAction      : PropTypes.func.isRequired,
	subtitleText       : PropTypes.string,
	emphasizeDecline   : PropTypes.bool,
};

export { ExitModal };

export default ExitModal;
