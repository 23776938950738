import { gql } from "@apollo/client";

const requestCareNavigatorAssistance = gql`
  mutation RequestCareNavigatorAssistance(
    $reason: AssistanceReasonEnumType!
    $userReason: String
  ) {
    requestCareNavigatorAssistance(reason: $reason, user_reason: $userReason) {
      success
    }
  }
`;

export default requestCareNavigatorAssistance;
