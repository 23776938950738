import React from "react";
import { modalIds } from "@spring/constants";
import { Trans } from "react-i18next";
import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
} from "@chakra-ui/react";
import { useTranslation } from "hooks/react-i18next";
import { useNoShowPolicyConfig } from "components/templates/CareVisitDetails/components/ConfirmationCopy/hooks";

const CustomerPayForNoShowCopy = ({ customerName, customerCostForNoShow }) => {
  return (
    <Trans
      ns="careVisits"
      i18nKey="cancelAppointment.cancelPolicyModal.customerSpecificPolicy"
      values={{
        customerName: customerName,
        costOfMissedAppointment: customerCostForNoShow,
      }}
    />
  );
};

const MemberPayForNoShowCopy = ({
  hasGracePeriodMissedAppointments,
  memberCostForNoShow,
}) =>
  memberCostForNoShow ? (
    <Trans
      ns="careVisits"
      i18nKey={
        hasGracePeriodMissedAppointments
          ? "cancelAppointment.cancelPolicyModal.memberMayPayForNoShow"
          : "cancelAppointment.cancelPolicyModal.memberWillPayForNoShow"
      }
      values={{
        costOfMissedAppointment: memberCostForNoShow,
      }}
    />
  ) : (
    <Trans
      ns="careVisits"
      i18nKey={
        hasGracePeriodMissedAppointments
          ? "cancelAppointment.cancelPolicyModal.memberMayPayForNoShowNoCost"
          : "cancelAppointment.cancelPolicyModal.memberWillPayForNoShowNoCost"
      }
      values={{
        costOfMissedAppointment: memberCostForNoShow,
      }}
    />
  );

const PolicyPreface = ({ memberEligibleForAutoCancel }) => {
  return (
    <Trans
      ns="careVisits"
      i18nKey={`cancelAppointment.cancelPolicyModal.${memberEligibleForAutoCancel ? "autoCancelPolicyPreface" : "policyPreface"}`}
    />
  );
};

const NoShowPolicyCopy = () => {
  const {
    config: {
      customerName,
      customerPayForNoShow,
      customerCostForNoShow,
      memberPayForNoShow,
      memberCostForNoShow,
      showDefaultCopy,
      hasGracePeriodMissedAppointments,
      isPayer,
      memberEligibleForAutoCancel,
    },
  } = useNoShowPolicyConfig();

  if (showDefaultCopy) {
    return (
      <>
        <PolicyPreface
          memberEligibleForAutoCancel={memberEligibleForAutoCancel}
        />{" "}
        <Trans
          ns="careVisits"
          i18nKey={
            isPayer
              ? "cancelAppointment.cancelPolicyModal.policyPayer"
              : "cancelAppointment.cancelPolicyModal.policyEmployer"
          }
        />
      </>
    );
  }

  if (memberPayForNoShow) {
    return (
      <>
        <PolicyPreface
          memberEligibleForAutoCancel={memberEligibleForAutoCancel}
        />{" "}
        <MemberPayForNoShowCopy
          hasGracePeriodMissedAppointments={hasGracePeriodMissedAppointments}
          memberCostForNoShow={memberCostForNoShow}
        />
      </>
    );
  }

  if (customerPayForNoShow) {
    return (
      <>
        <PolicyPreface
          memberEligibleForAutoCancel={memberEligibleForAutoCancel}
        />{" "}
        <CustomerPayForNoShowCopy
          customerName={customerName}
          customerCostForNoShow={customerCostForNoShow}
        />
      </>
    );
  }
};

const ConfirmOrCancelCopy = () => {
  const {
    config: { requireConfirmOrCancel, memberEligibleForAutoCancel },
  } = useNoShowPolicyConfig();

  if (memberEligibleForAutoCancel) {
    return (
      <Box>
        <Trans
          ns="careVisits"
          i18nKey={`cancelAppointment.cancelPolicyModal.eligibleForAutoCancel`}
        />
      </Box>
    );
  } else if (requireConfirmOrCancel) {
    return (
      <Box>
        <Trans
          ns="careVisits"
          i18nKey={`cancelAppointment.cancelPolicyModal.autoCancelPolicy`}
        />
      </Box>
    );
  } else {
    return null;
  }
};

const CancellationDetailsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation("careVisits");

  return (
    <Modal
      size="xl"
      id={modalIds.cancellationDetailsModal}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={16}>
          {t("cancelAppointment.cancelPolicyModal.header")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={16} pb={32}>
          <Box mb={16}>
            <NoShowPolicyCopy />
          </Box>
          <ConfirmOrCancelCopy />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancellationDetailsModal;
