import { useQuery } from "@apollo/client";
import { assessments as assessmentConstants } from "@spring/constants";
import Meowth from "@spring/meowth";
import Router from "next/router";
import { useEffect } from "react";

import { determineTeenHomepageSectionsToDisplay } from "modules/MemberDashboard/Teen/Home/utils";
import { getCareTeam } from "operations/queries/careProvider";
import routes from "routes";
import { formatMemberAddress } from "utils/displayHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isTeen } from "utils/memberHelpers";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import { useTeenAssessmentStatus, useTeenMemberInfo } from "./";
import { useMemberBenefits } from "hooks/useMemberBenefits";

export const useTeenHomePage = () => {
  const canVisitTeenHomePage = useFeatureFlag(FLAGS.TEEN_HOMEPAGE);
  const { data: memberData, loading: memberDataLoading } = useTeenMemberInfo();

  const memberDOB = memberData?.user?.member?.date_of_birth;
  const isATeen = isTeen(memberDOB);

  /**
   * Redirect members who shouldn't be able to access the teen home page
   */
  useEffect(() => {
    if (memberDataLoading) {
      return;
    }

    // temp condition until we remove flag https://springhealth.atlassian.net/browse/FAM-465
    if (isATeen && !canVisitTeenHomePage) {
      Router.replace(routes.MemberCareVisits.as);
      return;
    }

    if (!isATeen || (!canVisitTeenHomePage && memberDOB)) {
      const memberHomeRoute = getHomeRoute(memberDOB);
      Router.replace(memberHomeRoute.as);
    }
  }, [canVisitTeenHomePage, isATeen, memberDataLoading]);

  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  const address = formatMemberAddress(memberData);
  const getCareTeamVars = address
    ? { id: memberData.user.id, distance_from: { address: address } }
    : { id: apolloOptionsUserId.variables.id };

  const { data: careTeamData, loading: careTeamDataLoading } = useQuery(
    getCareTeam,
    {
      variables: getCareTeamVars,
      skip: !memberData,
      fetchPolicy: "network-only",
    },
  );

  const therapist = careTeamData?.user?.member?.care_team?.therapist;
  const coach = careTeamData?.user?.member?.care_team?.coach;

  const { assessmentStatus, loading: assessmentStatusLoading } =
    useTeenAssessmentStatus(!memberData);

  const hasATherapistAssigned = Boolean(
    careTeamData?.user?.member?.care_team?.therapist,
  );
  const hasACoachAssigned = Boolean(
    careTeamData?.user?.member?.care_team?.coach,
  );
  const upcomingTherapyScheduled = Boolean(
    memberData?.user?.member?.first_upcoming_appointments?.therapy,
  );
  const upcomingCoachingScheduled = Boolean(
    memberData?.user?.member?.first_upcoming_appointments?.coaching,
  );

  const hasCompletedAssessment =
    assessmentStatus === assessmentConstants.assessmentStatuses.FINISHED;

  const {
    shouldShowCoaching,
    allowDirectScheduling,
    showCoachingPaidExperience,
    isLoading: isBenefitsLoading,
  } = useMemberBenefits(careTeamData?.user?.member?.id);

  const isDirectScheduling =
    (allowDirectScheduling || showCoachingPaidExperience) &&
    (hasACoachAssigned || shouldShowCoaching);

  const hasTherapy = hasATherapistAssigned && upcomingTherapyScheduled;
  const hasCoaching =
    hasACoachAssigned && upcomingCoachingScheduled && isDirectScheduling;
  const hasUpcomingAppointments = hasTherapy || hasCoaching;
  const hasAProvider =
    hasATherapistAssigned || (hasACoachAssigned && isDirectScheduling);
  const displayedSections = determineTeenHomepageSectionsToDisplay(
    hasCompletedAssessment,
    hasUpcomingAppointments,
    hasAProvider,
  );

  const isLoading =
    memberDataLoading ||
    assessmentStatusLoading ||
    careTeamDataLoading ||
    isBenefitsLoading;

  return {
    therapist,
    memberData,
    isLoading,
    displayedSections,
    hasATherapistAssigned,
    hasACoachAssigned,
    coach,
  };
};
