import { Link } from "@springcare/sh-component-library";
import { useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TherapyBenefitFlyout } from "components/flyouts/TherapyBenefitFlyout";
import { CoachingFlyout } from "components/flyouts/CoachingFlyout";
import { WorkLifeSupportFlyout } from "components/flyouts/WorkLifeSupportFlyout";
import { DependentsFlyout } from "components/flyouts/DependentsFlyout";
import { getDirectDialForT2 } from "utils/memberHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import { benefitsCardAnalyticsMapping } from "modules/MemberDashboard/Home/constants/analyticsMapping";

export const FlyoutCTA = ({
  item,
  memberData,
  isWpoCoachingT1Member = false,
  T1WpoDirectDialNumber = null,
}) => {
  const { t } = useTranslation("t2Benefits");
  const {
    isOpen: isFlyoutOpen,
    onOpen: onOpenFlyout,
    onClose: closeFlyout,
  } = useDisclosure();

  const directDialNumber = isWpoCoachingT1Member
    ? T1WpoDirectDialNumber
    : getDirectDialForT2(memberData?.user?.member?.cohort);

  const RenderFlyout = () => {
    switch (item) {
      case "Therapy":
        return (
          <TherapyBenefitFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            callback={onOpenFlyout}
            ctaText={directDialNumber}
          />
        );
      case "Coaching":
        return (
          <CoachingFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            ctaText={directDialNumber}
            callback={onOpenFlyout}
          />
        );
      case "WorkLifeSupport":
        return (
          <WorkLifeSupportFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            ctaText={directDialNumber}
            callback={onOpenFlyout}
          />
        );
      case "DependentsSection":
        return (
          <DependentsFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            ctaText={directDialNumber}
            callback={onOpenFlyout}
          />
        );

      default:
        return null;
    }
  };

  const ctaText = () => {
    if (item === "Therapy") {
      return t("therapyVisits.whatIsTherapy");
    }
    if (item === "Coaching") {
      return t("coachesVisit.whatIsCoaching");
    }
    if (item !== "CrisisSupport") {
      return t("rightPanel.learnMore");
    }
    return null;
  };

  return (
    <>
      <RenderFlyout />
      <Link
        as="button"
        onClick={() => {
          onOpenFlyout();
          if (!isWpoCoachingT1Member) {
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              `Explore Care Modal Direct Dial - ${benefitsCardAnalyticsMapping[item]}`,
            );
          }
        }}
      >
        {ctaText()}
      </Link>
    </>
  );
};
