import routes from "routes";
import { PageBase } from "components/layout";
import { Box, useTheme, useMediaQuery } from "@chakra-ui/react";
import {
  VWorkplaceIcon,
  VCircleQuestionIcon,
  VRelationshipsSpringIcon,
  VPersonalizedCareIcon,
  VSelfGuidedExcercisesIcon,
} from "@springcare/verdant-icons-react";
import { Flex, Heading, SHHeader, Text } from "design-system/components";
import styles from "components/organisms/MemberExpectations/styles.module.scss";
import MemberIntentCard from "./MemberIntentCard";
import { useTranslation } from "react-i18next";
import { useMemberInfo } from "hooks";
import { IntentOptions } from "components/organisms/MemberIntentQuestion/IntentOptionsEnum";

interface MemberIntentQuestionProps {
  allowed: boolean;
}

const MemberIntentQuestion = ({ allowed }: MemberIntentQuestionProps) => {
  const { t } = useTranslation("memberIntent");
  const { breakpoints } = useTheme();
  const breakpointQuery = (min, max) => {
    return useMediaQuery(`(min-width: ${min}) and (max-width: ${max})`);
  };
  const [isBetweenBaseAndSm] = breakpointQuery(
    breakpoints.base,
    breakpoints.sm,
  );
  const [isBetweenSmAndMd] = breakpointQuery(breakpoints.sm, breakpoints.md);
  const [isBetweenBaseAndMd] = breakpointQuery(
    breakpoints.base,
    breakpoints.md,
  );
  const [isBetweenMdAndLg] = breakpointQuery(breakpoints.md, breakpoints.lg);

  const getMarginTop = () => {
    if (isBetweenMdAndLg) return 92;
    if (isBetweenSmAndMd) return 84;
    if (isBetweenBaseAndSm) return 24;
    return 104;
  };

  const getContentWidth = () => {
    if (isBetweenMdAndLg) return 678;
    if (isBetweenSmAndMd) return 561;
    if (isBetweenBaseAndSm) return 327;
    return 1024;
  };
  const renderCard = ({
    icon,
    title,
    titleKey,
    description,
    ellipseColor,
    ellipseOpacity,
    justifyContent = "flex-start",
  }) => (
    <MemberIntentCard
      icon={icon}
      title={title}
      titleKey={titleKey}
      description={description}
      ellipseColor={ellipseColor}
      ellipseOpacity={ellipseOpacity}
      justifyContent={justifyContent}
      width={isBetweenBaseAndSm ? "100%" : 327}
      id={memberInfoData?.user?.member?.id}
    />
  );

  const cardsData = [
    {
      icon: <VPersonalizedCareIcon width={24} height={24} />,
      title: t("memberIntent.cards.selfCare.title"),
      titleKey: IntentOptions.SelfCare,
      description: t("memberIntent.cards.selfCare.description"),
      ellipseColor: "#058261",
      ellipseOpacity: 0.12,
    },
    {
      icon: <VRelationshipsSpringIcon width={24} height={24} />,
      title: t("memberIntent.cards.householdCare.title"),
      titleKey: IntentOptions.HouseholdCare,
      description: t("memberIntent.cards.householdCare.description"),
      ellipseColor: "#EFB707",
      ellipseOpacity: 0.25,
    },
    {
      icon: <VSelfGuidedExcercisesIcon width={24} height={24} />,
      title: t("memberIntent.cards.selfGuidedActivity.title"),
      titleKey: IntentOptions.SelfGuidedActivity,
      description: t("memberIntent.cards.selfGuidedActivity.description"),
      ellipseColor: "#EB1919",
      ellipseOpacity: 0.1,
    },
    {
      icon: <VCircleQuestionIcon width={24} height={24} />,
      title: t("memberIntent.cards.notSure.title"),
      titleKey: IntentOptions.NotSure,
      description: "",
      ellipseColor: "#121E1E",
      ellipseOpacity: 0.08,
      justifyContent: "center",
    },
  ];

  const { data: memberInfoData } = useMemberInfo();
  const memberName = memberInfoData?.user?.member?.name;

  return (
    <PageBase
      // @ts-ignore
      className={styles.pageBase}
      darkBg={false}
      allowed={allowed}
      redirect={routes.SignIn}
    >
      <SHHeader
        showSplitHeaderDesktop={true}
        sliderDismissed
        isLoggedIn
        country="US"
        global={false}
        setGlobalCtx={() => {}}
        currentView="intent_question"
        isDependentObj={false}
        setCurrentView={() => {}}
        displayBackBtn={() => false}
        doGoBack={() => {}}
        desktopBg="white"
        page="intent_question"
        mobileBg="white"
        currLang={undefined}
      />

      <Box display="flex" width="100%" justifyContent="center" mb={40}>
        <Flex
          mt={getMarginTop()}
          alignContent="center"
          flexDirection="column"
          alignItems="center"
          width={getContentWidth()}
        >
          <Heading
            data-testid="member-intent-question-heading"
            variant={isBetweenBaseAndSm ? "md_v1" : "lg_v1"}
            as="h1"
            textAlign="center"
            px={isBetweenBaseAndMd ? 24 : 0}
          >
            {memberName
              ? t("memberIntent.title", { memberName })
              : t("memberIntent.defaultTitle")}
          </Heading>

          <Text
            color="content-secondary"
            mt={8}
            fontSize={isBetweenBaseAndMd ? 16 : 21}
            fontWeight={isBetweenBaseAndSm ? 400 : 600}
            textAlign="center"
            px={isBetweenBaseAndMd ? 24 : 0}
          >
            {t("memberIntent.subtitle")}
          </Text>

          <Box
            display="flex"
            alignItems="center"
            gap="24px"
            alignSelf="stretch"
            flexWrap="wrap"
            flexDirection="column"
            mt={40}
            px={isBetweenBaseAndSm ? 24 : 0}
          >
            <Flex
              gap={24}
              flexDirection={isBetweenBaseAndMd ? "column" : "row"}
            >
              {cardsData.slice(0, 2).map((card, index) => (
                <div key={`card-${index}`}>{renderCard(card)}</div>
              ))}
            </Flex>

            <Flex
              gap={24}
              flexDirection={isBetweenBaseAndMd ? "column" : "row"}
            >
              {cardsData.slice(2).map((card, index) => (
                <div key={`card-${index + 2}`}>{renderCard(card)}</div>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </PageBase>
  );
};

export default MemberIntentQuestion;
