import { gql } from "@apollo/client";

const requestAssessment = gql`
  mutation RequestAssessment($input: RequestAssessmentInput!) {
    requestAssessment(input: $input) {
      success
    }
  }
`;

export default requestAssessment;
