import { gql } from "@apollo/client";

import { assessment } from "operations/fragments/assessment";

const getDetailedAssessment = gql`
  query getDetailedAssessment($id: ID!) {
    assessment(id: $id) {
      ...assessment
      risk
      member {
        id
        name
        care_team {
          id
          care_navigator {
            id
            name
          }
        }
      }
      questionnaires(version: "improved_sud") {
        id
        risk
        results_sanitized
      }
    }
  }
  ${assessment}
`;

export default getDetailedAssessment;
