import { Box, HStack, Grid, Heading, Text } from "@chakra-ui/react";

const CardWrapper = ({
  children,
  header,
  subheader,
  styles,
  displayInline = false,
  height = 200,
}) => (
  <Box pb={60}>
    <Heading size="heading-small" pb={8}>
      {header}
    </Heading>
    <Text size="body-medium">{subheader}</Text>
    {displayInline ? (
      <Box overflowX="auto" w="100%" mt={16}>
        <HStack spacing={24} align="start">
          {children.map((child, index) => (
            <Box
              key={index}
              maxW="320px"
              minW="200px"
              flexShrink={0}
              h={height}
              display="flex"
            >
              {child}
            </Box>
          ))}
        </HStack>
      </Box>
    ) : (
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        columnGap={20}
        rowGap={{ base: 24, lg: 20 }}
        mt={16}
        className={styles.cardSectionContainer}
      >
        {children}
      </Grid>
    )}
  </Box>
);

export default CardWrapper;
