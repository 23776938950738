/**
 * NOTE: @param are props
 * NOTE: RHF refers to the react-hook-form library ()
 * @param {number}   [fcMb]       margin-bottom for <FormControl>
 * @param {required} [required]   Used w/ 'errors' to display <FormErrorMessage> if true, and 'errors' true
 * @param {object}   errors       Passed from RHF - will be passed if input has error
 * @param {string}   name         Important - this the key for the input value that gets Submitted
 * @param {string}   label        Text value for <FormLabel> - required to be a11y-safe
 * @param {boolean}  hideLabel    Hide label or not - used for a11y compliance
 * @param {function} register     Important - RHF function that registers the key (i.e 'name' prop)
 * @param {object}   validation   Important - this object sets validation used by RHF, pass false if not needed
 * @param {string}   placeholder  Placeholder text
 * @param {string}   [dataCy]     Used for Cypress E2E testing
 * @param {string}   [textAlign]  Align <options>
 * @param {string}   autoComplete String to set HTML autocomplete tag, use standard HTML autocomplete values
 *  - see MDN doc for values https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
 * @param {array}    options      This array is used to populate the <options> elements
 * @param {boolean} [isSubLabel]  Set label styles to those of a sub-label
 * @param {number}  [fontWeight]  Control fontWeight; otherwise will be set by isSubLabel
 * //TODO - remove the below params once new component is built
 * @param {string}  [backgroundColor]  Control backgroundColor
 * @param {string}  [height]  Control height
 * @param {string}  [borderColor]  Control borderColor
 * @param {string}  [borderWidth]  Control borderWidth
 * @param {boolean} [isOnboarding]  Control isOnboarding styles

 * NOTE: This component must be nested in a react-hook-form form
   in order to fully work. It is meant for broad use and thus
   should be expanded very sparingly.

 * NOTE: Renders a label and select with validation and RHF functionality.

 * NOTE: This can be expanded in the future to support Multi-Select forms. The 'options'
   prop can also be an object, with predictable keys (i.e 'name', 'value') to support
   displaying a 'name', but recording the 'value' when selected (i.e 'January', '01')
*/

import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  VisuallyHidden,
} from "../../../index";

export const SHSelectInput = ({
  fcMb = 0,
  required,
  errors,
  name,
  label,
  hideLabel,
  register,
  validation,
  placeholder,
  autoComplete,
  options,
  autoFocus,
  hasOptionLabel,
  textAlign,
  testId,
  dataCy,
  onBlur,
  isSubLabel,
  fontWeight,
  backgroundColor = null,
  height = null,
  borderColor = null,
  borderWidth = null,
  isOnboarding = false,
  variant = "outline",
}) => {
  return (
    <FormControl
      mb={fcMb}
      isRequired={required ? true : false}
      isInvalid={required ? errors : false}
    >
      {label && hideLabel ? (
        <VisuallyHidden>
          <FormLabel
            htmlFor={name}
            requiredIndicator={
              required ? (
                <span style={{ marginLeft: "4px" }} title="required field">
                  *
                </span>
              ) : null
            }
          >
            {label}
          </FormLabel>
        </VisuallyHidden>
      ) : (
        <FormLabel
          htmlFor={name}
          fontSize={isSubLabel ? "14px" : "16px"}
          fontWeight={fontWeight ? fontWeight : isSubLabel ? "400" : "600"}
          requiredIndicator={
            required ? (
              <span style={{ marginLeft: "4px" }} title="required field">
                *
              </span>
            ) : null
          }
        >
          {label}
        </FormLabel>
      )}
      <Select
        autoFocus={autoFocus}
        id={name}
        {...register(name, { ...validation })}
        aria-label={`${name.replace("_", "-")}-select`}
        type="text"
        variant={variant}
        colorScheme={errors ? "error" : "platform"}
        color="platform.900"
        placeholder={placeholder}
        autoComplete={autoComplete}
        backgroundColor={backgroundColor}
        height={height}
        borderWidth={borderWidth}
        borderColor={borderColor}
        data-cy={dataCy}
        data-testid={testId}
        onBlur={onBlur}
        textAlign={textAlign || "left"}
        required={required}
        fontWeight={fontWeight}
        sx={
          isOnboarding
            ? {
                _hover: {
                  borderColor: "border-interactive",
                },
                _focus: {
                  //these colors are hardcoded because they are hardcoded in the design-system. Looking into why we don't have tokens.
                  border: "1px solid #068262",
                  boxShadow: "0px 0px 0px 4px rgba(6, 130, 98, 0.24)",
                  _invalid: {
                    boxShadow: "0px 0px 0px 4px rgba(196, 72, 72, 0.24)",
                  },
                },
                _invalid: {
                  borderColor: "negative-on-subtle",
                  border: "1px solid",
                  background: "negative-subtle",
                },
              }
            : null
        }
      >
        {!hasOptionLabel ? (
          <>
            {options?.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </>
        ) : (
          <>
            {options?.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </>
        )}
      </Select>
      {errors && (
        <FormErrorMessage mt={16} color="error.base" fontWeight="bold">
          {errors?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

SHSelectInput.propTypes = {
  required: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  register: PropTypes.func.isRequired,
  validation: PropTypes.object,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  textAlign: PropTypes.string,
  dataCy: PropTypes.string,
  onBlur: PropTypes.func,
  isSubLabel: PropTypes.bool,
  fontWeight: PropTypes.number,
  testId: PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  isOnboarding: PropTypes.bool,
  autoFocus: PropTypes.bool,
};
