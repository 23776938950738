import { gql } from "@apollo/client";

const rescheduleAppointment = gql`
  mutation RescheduleAppointment($input: RescheduleAppointmentInput!) {
    rescheduleAppointment(input: $input) {
      success
      appointment {
        id
        duration
        start_at
        kind
        medium
        status
        location
        reason_for_scheduling
      }
    }
  }
`;

export default rescheduleAppointment;
