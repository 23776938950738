import { createIcon } from "@chakra-ui/react";

export const EmptyState = createIcon({
  displayName: "EmptyStateV1.1",
  viewBox: "0 0 80 80",
  path: (
    <>
      <path fill="currentColor" d="M23 47h34v3l-34 2v-5Z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M20.215 40.1a1 1 0 0 1 .995-1.1h37.58a1 1 0 0 1 .995 1.1l-.6 6a1 1 0 0 1-.995.9H21.81a1 1 0 0 1-.995-.9l-.6-6Z"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M23.194 48.09A1 1 0 0 1 24.19 47h31.62a1 1 0 0 1 .996 1.09l-1.636 18a1 1 0 0 1-.996.91H25.826a1 1 0 0 1-.995-.91l-1.637-18Z"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 67h42"
      />
      <path fill="currentColor" d="M23 39a7 7 0 0 1 7-7h20a7 7 0 0 1 7 7H23Z" />
      <path fill="currentColor" d="M29 35a7 7 0 0 1 7-7h8a7 7 0 0 1 7 7H29Z" />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M40 29v-1.958a17 17 0 0 0-4.98-12.021L33 13"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m51 13-8.491.42A4.814 4.814 0 0 0 38 17.423c4.002 3.32 10.065 1.944 12.242-2.778L51 13Z"
      />
    </>
  ),
  defaultProps: {
    "aria-label": "EmptyState icon",
    role: "img",
    color: "blue",
  },
});
