import { gql } from "@apollo/client";

const getMemberPrevisit = gql`
  query getMemberPrevisit($id: ID!) {
    member(id: $id) {
      id
      date_of_birth
      previsit {
        id
        data
        status
      }
    }
  }
`;

export default getMemberPrevisit;
