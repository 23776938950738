// 

import { OPEN_FLYOUT, CLOSE_FLYOUT } from './types';

export function openFlyout(id, data) {
	return {
		type: OPEN_FLYOUT,
		id,
		data,
	};
}

export function closeFlyout(id) {
	return {
		type: CLOSE_FLYOUT,
		id,
	};
}
