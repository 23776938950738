import { gql } from "@apollo/client";

const createCoveredLife = gql`
  mutation createCoveredLife($input: CoveredLifeInput!) {
    createCoveredLife(input: $input) {
      cohort_id
      customer_id
      invite_token
      invite_url
      country
      success
    }
  }
`;

export default createCoveredLife;
