import { SHOutlinedCircleCheckBox } from "design-system/components";
import { AnswerProps, PromptProps, QuestionProps, OptionProps } from "./types";

type RadioOptionsProps = {
  question: QuestionProps;
  answers: Array<AnswerProps>;
  prompt: PromptProps;
  submitQuestionResponse: (option: OptionProps) => void;
};

export const RadioOptions = ({
  question,
  answers,
  prompt,
  submitQuestionResponse,
}: RadioOptionsProps): React.ReactElement => {
  return (
    <>
      {question.element.options.map((option) => (
        <Option
          submitQuestionResponse={submitQuestionResponse}
          answers={answers}
          option={option}
          prompt={prompt}
          key={option.label}
        />
      ))}
    </>
  );
};

const Option = ({ submitQuestionResponse, answers, option, prompt }) => {
  const handleAnswerClick = (el) => {
    submitQuestionResponse(option);
    el.target.blur();
  };

  const prevAnswer: AnswerProps = answers.find(
    (data) => data.prompt_id === prompt.id,
  );

  let isSelected = false;
  if (prevAnswer) {
    const answerValue: AnswerProps[] = getValueFromKeyValuePair(
      prevAnswer.question_responses,
    );
    isSelected = answerValue.toString() === option.value;
  }
  return (
    <SHOutlinedCircleCheckBox
      label={option.label}
      fieldKey={option.value}
      key={option.value}
      onChange={(el) => handleAnswerClick(el)}
      checked={isSelected}
    />
  );
};

function getValueFromKeyValuePair(jsonStr): AnswerProps[] {
  try {
    const obj = JSON.parse(jsonStr);
    return Object.values(obj)[0] as AnswerProps[];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error parsing JSON:", error);
    return null;
  }
}
