import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  VGoalsDuotoneSpot,
  VGoalsFlatSpot,
} from "@springcare/verdant-icons-react";
import { InformationFlyout } from "components";
import LeftIcon from "design-system/assets/icons/LeftIcon";
import { useTranslation } from "react-i18next";

type TobaccoCessationFlyoutType = {
  callback: () => void;
  isOpen: boolean;
  onClose: () => void;
};

const TobaccoCessationBenefits = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.tobaccoCessation",
  });
  const benefits = [];

  for (let i = 1; i <= 5; i++) {
    benefits.push(
      <ListItem key={`tobacco_cessation_benefits_${i}`}>
        <Text display="inline">{t(`benifits.item${i}`)}</Text>{" "}
      </ListItem>,
    );
  }
  return (
    <>
      <Text mb={24}>{t("benifitsBody")}</Text>
      <UnorderedList mb={24}>{benefits}</UnorderedList>
    </>
  );
};

const TobaccoCessationFlyoutBody = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.tobaccoCessation",
  });

  return (
    <>
      <Heading size="heading-medium" mb="v-8">
        {t("benifitsHeader")}
      </Heading>
      <TobaccoCessationBenefits />
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpect.header")}
      </Heading>
      <Text mb={24}>{t("whatToExpect.body1")}</Text>
      <Text mb={24}>{t("whatToExpect.body2")}</Text>

      <Text mb={24}>{t("yourProgramIncludes.header")}</Text>
      <UnorderedList mb={24}>
        <ListItem>
          <Text display="inline"> {t("yourProgramIncludes.item1")}</Text>{" "}
        </ListItem>
        <ListItem>
          <Text display="inline"> {t("yourProgramIncludes.item2")}</Text>{" "}
        </ListItem>
        <ListItem>
          <Text display="inline"> {t("yourProgramIncludes.item3")}</Text>{" "}
        </ListItem>
      </UnorderedList>
      <Text mb={24}>{t("programSupport")}</Text>
    </>
  );
};

export const TobaccoCessationFlyout = ({
  callback,
  isOpen,
  onClose,
}: TobaccoCessationFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.tobaccoCessation",
  });

  return (
    <InformationFlyout
      headerText={t("quitSmoking")}
      headerIcon={<VGoalsFlatSpot />}
      heroIllustration={VGoalsDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaType="button"
      ctaCallback={callback}
      ctaIcon={<LeftIcon />}
      reEnableButton
    >
      <TobaccoCessationFlyoutBody />
    </InformationFlyout>
  );
};
