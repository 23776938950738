import { isSafari, fullBrowserVersion } from "react-device-detect";
import { Track, LocalAudioTrack } from "livekit-client";
import {
  logNoiseFilterDeactivationError,
  logNoiseFilterInitializationError,
} from "components/templates/SHSessionRoom/telemetry/datadog";

const isSafariKrispCompatible = (() => {
  const SAFARI_COMPATIBLE = 170401; // 17.4.1
  const [major, minor = 0, patch = 0] = fullBrowserVersion
    .split(".")
    .map(Number);
  const localSafariVersion = major * 10000 + minor * 100 + patch;

  return localSafariVersion >= SAFARI_COMPATIBLE;
})();

const isMicrophoneTrackKrispCompatible = (trackPublication) =>
  trackPublication.source === Track.Source.Microphone &&
  trackPublication instanceof LocalAudioTrack;

const initKrispNoiseFilter = async (trackPublication) => {
  const { KrispNoiseFilter } = await import("@livekit/krisp-noise-filter");
  const krispProcessor = KrispNoiseFilter();
  await trackPublication.setProcessor(krispProcessor);
};

export const toggleKrispNoiseFiltering = async (
  isNoiseCancelingPreferenceEnabled,
  trackPublication,
  participantId,
  appointmentId,
) => {
  if (isNoiseCancelingPreferenceEnabled) {
    const isKrispCompatible =
      !isSafari || (isSafari && isSafariKrispCompatible);

    if (
      isKrispCompatible &&
      isMicrophoneTrackKrispCompatible(trackPublication)
    ) {
      try {
        await initKrispNoiseFilter(trackPublication);
      } catch (error) {
        logNoiseFilterInitializationError(appointmentId, participantId, error);
      }
    }
  } else {
    try {
      await trackPublication.stopProcessor();
    } catch (error) {
      logNoiseFilterDeactivationError(appointmentId, participantId, error);
    }
  }
};
