import { gql } from "@apollo/client";

const createDocumentUploadPresignedStatus = gql`
  mutation CreateDocumentUploadPresignedStatus(
    $input: CreateDocumentPresignedStatusInputType!
  ) {
    create_document_upload_presigned_status(input: $input) {
      tagging_url
    }
  }
`;

export default createDocumentUploadPresignedStatus;
