import React, { useState } from "react";
import { Box, Menu, MenuButton, MenuList, MenuDivider } from "@chakra-ui/react";
import { VChevronUpIcon } from "@springcare/verdant-icons-react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { DeviceTypes } from "components/templates/SHSessionRoom/types";
import { LivekitDeviceMenuOptionGroup } from "components/templates/SHSessionRoom/components/VideoCallControls/LivekitDeviceMenuOptionGroup";
import {
  AudioOutputTesterMenuItem,
  AudioInputTesterMenuItem,
} from "components/templates/SHSessionRoom/components/AudioTesterMenuItem";
import { NoiseFilterMenuItem } from "components/templates/SHSessionRoom/components/NoiseFilterMenuItem";
import { trackDeviceMenuOpened } from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";

interface DeviceMenuChevronProps {
  types: DeviceTypes[];
}

export const DeviceMenuChevron = ({ types }: DeviceMenuChevronProps) => {
  const isAudioInputTesterEnabled = useFeatureFlag(
    FLAGS.ENABLE_AUDIO_INPUT_TESTER,
  );
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.deviceMenuChevron",
  });
  const handleMenuClick = () => {
    trackDeviceMenuOpened(types, "meeting_room");
    setIsOpen(!isOpen);
  };

  return (
    <Menu isLazy>
      <MenuButton
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls={`${types}-device-menu`}
        aria-label={`${t("deviceMenuOptionsAriaLabel")} ${types}`}
        onClick={handleMenuClick}
      >
        <Box pe="v-12">
          <VChevronUpIcon color={"white"} />
        </Box>
      </MenuButton>
      <MenuList>
        {types.map((type, index) => (
          <>
            <LivekitDeviceMenuOptionGroup type={type} />
            {index < types.length - 1 && <MenuDivider />}
            {type === DeviceTypes.AudioOutput && <AudioOutputTesterMenuItem />}
            {type === DeviceTypes.AudioInput && isAudioInputTesterEnabled && (
              <AudioInputTesterMenuItem />
            )}
            {type === DeviceTypes.AudioOutput && (
              <>
                <MenuDivider />
                <NoiseFilterMenuItem />
              </>
            )}
          </>
        ))}
      </MenuList>
    </Menu>
  );
};

DeviceMenuChevron.displayName = "DeviceMenuChevron";
