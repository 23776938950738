import { useDispatch, useSelector } from "react-redux";

import { SHSliderInput } from "design-system/components";
import { setField } from "actions/assessment/actions";
import { SpringFormState } from "components/assessments/types";

type SliderProps = {
  fieldKey: string;
  formKey: string;
  title: string;
  subtitle: string;
  min: number;
  max: number;
  step: number;
  markers: any[];
  toolTipDisabled: boolean;
  toolTipRef: React.RefObject<any>; //TODO: Refactor ElementCurator.js to .tsx. Prop should be defined in the base component ElementCurator.js
};

const Slider = (props: SliderProps) => {
  const {
    formKey,
    fieldKey,
    title,
    subtitle,
    min,
    max,
    step,
    markers,
    toolTipDisabled,
    toolTipRef,
  } = props;

  const answer = useSelector((state: SpringFormState) => {
    return state.form[props.formKey]?.data[props.fieldKey];
  });

  const dispatch = useDispatch();

  const handleChange = (val: string | number) => {
    dispatch(setField(formKey, fieldKey, val, "string"));
  };

  const getMarkerTitle = () => {
    if (markers && markers[0].includes("Days") && title == null) {
      return "Days";
    }
    return null;
  };

  return (
    <>
      <SHSliderInput
        title={title || getMarkerTitle()}
        subtitle={subtitle}
        min={min}
        max={max}
        markers={markers}
        step={step}
        onChange={handleChange}
        value={answer}
        leftLabel={markers[0]}
        rightLabel={markers[markers.length - 1]}
        dataCy={"assessment-slider-" + fieldKey}
        toolTipDisabled={toolTipDisabled}
        toolTipRef={toolTipRef}
      />
    </>
  );
};

export { Slider };
