export const space = {
  4: ".25rem",
  8: ".5rem",
  10: ".625rem",
  12: ".75rem",
  14: ".875rem",
  16: "1rem",
  20: "1.25rem",
  22: "22px",
  24: "1.5rem",
  28: "1.75rem",
  32: "2rem",
  36: "2.25rem",
  40: "2.5rem",
  48: "3rem",
  52: "3.25rem",
  56: "3.5rem",
  60: "3.75rem",
  64: "4rem",
  72: "4.5rem",
  80: "5rem",
  96: "6rem",
  104: "6.5rem",
  112: "7rem",
  120: "7.5rem",
  168: "10.5rem",
  272: "17rem",
};
