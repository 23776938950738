import React, { useEffect, useRef, useContext } from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import Router from "next/router";
import { compose } from "redux";
import { DateTime } from "luxon";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { get, getOr } from "lodash/fp";
import { Divider, Spinner } from "design-system/components";
import {
  Grid,
  Col,
  EmptyCareNavigatorIcon,
  EmptyTherapistIcon,
  EmptyMedicationManagerIcon,
  EmptyCoachIcon,
} from "@spring/smeargle";
import {
  // Icons
  PlantLeaves,
  Medication,
  WateringCan,
  SocialFactors,
} from "design-system/assets";
import { addNotification, openModal } from "@spring/smeargle/actions";
import {
  modalIds,
  AppointmentKind,
  RequestableAppointmentKind,
  AppointmentMedium,
  AppointmentAction,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  isCareNavigation,
  timeFormats,
  ProviderRole,
} from "@spring/constants";
import { Link } from "@springcare/sh-component-library";
import { useDisclosure } from "@chakra-ui/react";
import Meowth from "@spring/meowth";
import { getSupportsInPersonTherapy } from "utils/schedulingHelpers";
import { getAssessmentRiskLevels } from "operations/queries/assessment";
import { useTranslation, Trans } from "react-i18next";

import envUtils from "utils/environment";
import {
  CareProviderScheduleModal,
  CareProviderDetailModal,
  AvailabilityRequestModal,
  BeforeYouScheduleModal,
  EmptyCareTeamCard,
  CareTeamCard,
  HelpFlyout,
} from "components";

import {
  useInViewportOnce,
  useAllUpcomingAppointmentSlots,
  useUpcomingAppointmentSlots,
} from "hooks";
import { getCareTeam } from "operations/queries/careProvider";
import { getMemberInfo } from "operations/queries/member";
import { requestAppointment } from "operations/mutations/appointment";
import { getAppointments } from "operations/queries/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import browseRoutes from "routes/BrowseRoutes";
import { isCNSchedulingOrNoScheduling, normalizeToName } from "utils/global";
import styles from "./styles.module.scss";
import {
  getCareTeamListRoles,
  fireProviderLinkClickedTelemetry,
} from "./CareTeamList.utils";

import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import { hasManagedDependents, isMinor } from "utils/memberHelpers";
import { returnAllSessionCounts } from "modules/shared/utils";

const getAddress = (data) => {
  const { street_address_1, city, state, zip_code, country } =
    data.user.member.postal_address;
  const address = [street_address_1, city, state, zip_code, country]
    .filter(Boolean)
    .join(", ");

  return {
    address,
  };
};

const getInitialStartTime = (locale, available) => {
  if (Array.isArray(available) && !!available.length) {
    const dateTime = DateTime.fromISO(available[0]).setLocale(locale);
    const localTime = dateTime.toLocal();
    return localTime.toISO();
  }
  return null;
};

const getTherapyKind = (memberData) => {
  const member = memberData && memberData.user?.member;
  return (
    member &&
    (member.minor ? AppointmentKind.MinorTherapy : AppointmentKind.Therapy)
  );
};

const useMemberInfo = (careTeamInView) => {
  const getMemberInfoOptions = {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId() || !careTeamInView,
  };

  const { data: memberData, loading: memberLoading } = useQuery(
    getMemberInfo,
    getMemberInfoOptions,
  );

  // TODO: refactor how care navigation appointment kind is determined MXENG-2171
  const InitialCareNavigation = "Initial Care Navigator Call";

  const getAppointmentsVars = {
    starting_before: DateTime.local()
      .toUTC()
      .toFormat(timeFormats.datePickerFormat),
    booking_user_id: memberData && memberData.user.id,
    limit: 1,
    kind: InitialCareNavigation,
  };

  const { data: cnAppointmentData, loading: cnAppointmentLoading } = useQuery(
    getAppointments,
    {
      variables: getAppointmentsVars,
      skip: !memberData || !careTeamInView,
    },
  );

  const getCareTeamVars = {
    id: memberData && memberData.user.id,
    distance_from: memberData && getAddress(memberData),
  };

  const { data: careTeamData, loading: careTeamLoading } = useQuery(
    getCareTeam,
    {
      variables: getCareTeamVars,
      skip: !memberData || !careTeamInView,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  );

  const cn_appointments =
    cnAppointmentData && cnAppointmentData.appointments?.data;
  const careNavigationKind =
    cn_appointments &&
    (cn_appointments.length > 0
      ? AppointmentKind.FollowUpCareNavigation
      : AppointmentKind.InitialCareNavigation);
  const careNavProvider = careTeamData?.user.member.care_team.care_navigator;
  const { data: careNavPhoneFirstSlot, loading: careNavPhoneFirstSlotLoading } =
    useAllUpcomingAppointmentSlots(
      careNavProvider,
      careNavigationKind,
      1,
      true,
      false,
    );

  const therapyKind = getTherapyKind(memberData);
  const therapist = careTeamData?.user.member.care_team.therapist;
  const { data: therapyFirstSlots, loading: therapyFirstSlotsLoading } =
    useAllUpcomingAppointmentSlots(therapist, therapyKind, 1);

  const medicationKind = AppointmentKind.FollowUpMedicationManagement;
  const medication_manager =
    careTeamData?.user.member.care_team.medication_manager;
  const { data: medFirstSlot, loading: medFirstSlotLoading } =
    useUpcomingAppointmentSlots(
      medication_manager,
      medicationKind,
      AppointmentMedium.Video,
      1,
    );

  const coach = careTeamData?.user.member.care_team.coach;
  const { data: coachVideoFirstSlot, loading: coachVideoLoading } =
    useAllUpcomingAppointmentSlots(
      coach,
      AppointmentKind.Coaching,
      1,
      false,
      true,
    );
  const data = {
    memberData,
    ...careTeamData,
    ...cnAppointmentData,
    carenav_phone_appointment_slots: { ...careNavPhoneFirstSlot },
    therapy_appointment_slots: { ...therapyFirstSlots },
    medication_appointment_slots: { ...medFirstSlot },
    coach_video_appointment_slots: { ...coachVideoFirstSlot },
    mpCareTeamList: getCareTeamListRoles(careTeamData),
  };

  const loading =
    memberLoading ||
    careTeamLoading ||
    careNavPhoneFirstSlotLoading ||
    therapyFirstSlotsLoading ||
    cnAppointmentLoading ||
    medFirstSlotLoading ||
    coachVideoLoading;

  return { data, loading };
};

const CardDivider = () => (
  <Col size={12}>
    <Divider
      aria-hidden="true"
      borderColor="gray.300"
      pt={{ base: 20, md: 0 }}
      pb={{ base: 10, md: 0 }}
      w="100%"
    />
  </Col>
);

const CareTeamList = ({ onScheduleModalOpen, ...props }) => {
  const {
    setCareTeamList,
    isCNScheduleModalOpen,
    openCNScheduleModal,
    allowDirectScheduling,
    specializedCNEnabled,
    isPrivatePractice,
  } = useContext(CareContextValues);
  const careTeamSection = useRef();
  const careTeamInView = useInViewportOnce(careTeamSection, "0px");
  const { data, loading: isMemberInfoLoading } = useMemberInfo(careTeamInView);
  const isHighMark = useFeatureFlag(FLAGS.HIGHMARK_COST_TRANSPARENCY_COPY);
  const showPeerRecoverySpecialistExp = useFeatureFlag(
    FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE,
  );

  const { t } = useTranslation(["careVisits", "coachesProvider"]);
  const { member } = getOr({}, "memberInfo.user", props);
  const isCnOrNoScheduling = isCNSchedulingOrNoScheduling(
    member?.cohort?.contract_term?.scheduling_access?.name,
  );
  const cohortEAPLink =
    member?.cohort?.cohort_eap_link || browseRoutes.TherapistsBrowse.as;
  const { memberTherapyDetails } = props;
  const { memberCoachingDetails } = props;
  const {
    therapyCoveredCount,
    therapyUsedCount,
    therapistEmail,
    isGlobalMemberWithNoVisitsRemaining,
  } = memberTherapyDetails;
  const {
    coachingCoveredCount,
    coachingUsedCount,
    coachEmail,
    isGlobalMemberWithNoCoachingVisitsRemaining,
  } = memberCoachingDetails;
  const {
    isOpen: isHelpFlyoutOpen,
    onOpen: onOpenHelpFlyout,
    onClose: closeHelpFlyout,
  } = useDisclosure();
  const {
    therapist: therapistDetails,
    medication_manager: medicationManagerDetails,
    coach: coachingManagerDetails,
  } = getOr("", "user.member.care_team", data);
  const therapistLicensedCountries = therapistDetails?.licensed_countries
    ?.map((country) => normalizeToName(country))
    .join(", ");
  const medicationManagerLicensedCountries =
    medicationManagerDetails?.licensed_countries
      ?.map((country) => normalizeToName(country))
      .join(", ");
  const coachLicensedCountries = coachingManagerDetails?.licensed_countries
    ?.map((country) => normalizeToName(country))
    .join(", ");
  const memberCountry = normalizeToName(member?.postal_address?.country);
  const navigatorLink = routes.ScheduleAppointmentProviderDetail.as.replace(
    ":id",
    data?.user?.member?.care_team?.care_navigator?.id,
  );
  // The null case occurs when the member doesn't have a therapist or med manager assigned. In that case, we display the default verbiage
  const isTherapistLicensed =
    therapistDetails === null
      ? true
      : therapistLicensedCountries?.includes(memberCountry);
  const isMedicationManagerLicensed =
    medicationManagerDetails === null
      ? true
      : medicationManagerLicensedCountries?.includes(memberCountry);
  const isCoachLicensed =
    coachingManagerDetails === null
      ? true
      : coachLicensedCountries?.includes(memberCountry);

  const memberIsMinor = isMinor(member);

  useEffect(() => {
    if (!isMemberInfoLoading) {
      const careArray = careTeam();
      const careTeamList = [];
      const providerTypes = ["therapist", "medication_manager", "coach"];

      careArray.filter((element) => {
        if (providerTypes.includes(element.key)) careTeamList.push(element.key);
      });

      setCareTeamList(careTeamList);
    }
  }, [isMemberInfoLoading]);

  const internationalCnOrNoSchedulingVerbiage = (
    <Trans
      ns={"careVisits"}
      i18nKey={"careTeamCard.therapistDescriptionInternationalCN"}
      components={[
        <br key={"0"} />,
        <br key={"1"} />,
        <Link key={"2"} to={cohortEAPLink} />,
      ]}
    />
  );

  const openHelpFlyout = () => {
    onOpenHelpFlyout();
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Help Button Clicked", {});
  };

  const noGlobalMemberWithVisitsRemainingCareVerbiage = (
    zeroVisitsLeftSessionUsed,
    continueSesstionKey,
    therapistOrCoachEmailDetails,
    therapyOrCoachingData,
  ) => (
    <>
      <p>
        {t(zeroVisitsLeftSessionUsed, {
          [`${therapyOrCoachingData[0]}Count`]: therapyOrCoachingData[1],
          [`${therapyOrCoachingData[2]}Count`]: therapyOrCoachingData[3],
        })}
      </p>
      <p className={styles.therapistorCoachingVerbiageMargin}>
        {t(continueSesstionKey)}
      </p>
      <p className={styles.therapistorCoachingVerbiageMargin}>
        <Trans
          ns="careVisits"
          i18nKey={therapistOrCoachEmailDetails[0]}
          components={[
            <Link
              key={"0"}
              to={`mailto: ${therapistOrCoachEmailDetails[1]}`}
            />,
          ]}
          values={{ email: therapistOrCoachEmailDetails[1] }}
        />
      </p>
    </>
  );

  const therapyData = [
    "therapyUsed",
    therapyUsedCount,
    "therapyCovered",
    therapyCoveredCount,
  ];
  const therapyEmailDetails = [
    "careTeamCard.zeroVisitsLeft.therapistEmail",
    therapistEmail,
  ];

  const globalMemberWithNoVisitsRemainingVerbiage =
    noGlobalMemberWithVisitsRemainingCareVerbiage(
      "careTeamCard.zeroVisitsLeft.sessionsUsed",
      "careTeamCard.zeroVisitsLeft.continueTherapy",
      therapyEmailDetails,
      therapyData,
    );

  const coachingData = [
    "coachingUsed",
    coachingUsedCount,
    "coachingCovered",
    coachingCoveredCount,
  ];
  const coachEmailDetails = [
    "careTeamCard.zeroCoachingVisitsLeft.coachEmail",
    coachEmail,
  ];

  const globalMemberWithCoachingNoVisitsRemainingVerbiage =
    noGlobalMemberWithVisitsRemainingCareVerbiage(
      "careTeamCard.zeroCoachingVisitsLeft.sessionsUsed",
      "careTeamCard.zeroCoachingVisitsLeft.continueCoaching",
      coachEmailDetails,
      coachingData,
    );

  const providerNotLicensedVerbiage = (providerCountries) => (
    <div>
      <Trans
        ns="careProvider"
        i18nKey="scheduleModal.unsupportedCountry"
        values={{
          providerLicensedCountries: providerCountries,
          memberCountry: memberCountry,
        }}
        components={[
          <br key={"0"} />,
          <br key={"1"} />,
          <Link key={"2"} to={navigatorLink} />,
        ]}
      />
    </div>
  );

  const getTherapistCardVerbiage = () => {
    if (!isTherapistLicensed) {
      return providerNotLicensedVerbiage(therapistLicensedCountries);
    } else if (isGlobalMemberWithNoVisitsRemaining) {
      return globalMemberWithNoVisitsRemainingVerbiage;
    } else if (isCnOrNoScheduling) {
      return internationalCnOrNoSchedulingVerbiage;
    }
    return t("careTeamCard.therapistDescription");
  };

  const getCoachingCardVerbiage = () => {
    if (!isCoachLicensed) {
      return providerNotLicensedVerbiage(coachLicensedCountries);
    } else if (isGlobalMemberWithNoCoachingVisitsRemaining) {
      return globalMemberWithCoachingNoVisitsRemainingVerbiage;
    }
    return t("careTeamCard.coachDescription");
  };

  const getCareNavigatorCardVerbiage = () => (
    <Trans
      ns={"careVisits"}
      i18nKey={
        specializedCNEnabled
          ? "findNewCareTab.footer.careNavigation.sudDescription"
          : "careTeamCard.careNavigatorDescriptionNew"
      }
      components={[<Link key={"0"} onClick={openHelpFlyout} />]}
    />
  );

  const careNavigatorId = get("user.member.care_team.care_navigator.id", data);
  const hasCareNavigator = Boolean(careNavigatorId);

  const providerAttributes = {
    care_navigator: {
      role: specializedCNEnabled
        ? t("careProvider.specializedCareNavigator")
        : t("careProvider.careNavigator"),
      scheduleText: t("careTeamCard.scheduleCareNavigator"),
      changeText: t("careTeamCard.changeCareNavigator"),
      findText: t("careTeamCard.findCareNavigatorNew"),
      description: getCareNavigatorCardVerbiage(),
      emptyIcon: <EmptyCareNavigatorIcon aria-hidden="true" />,
      emptyAction: () => {
        if (!hasCareNavigator) {
          Router.push(routes.CareNavigation.as);
          return;
        }
        openCNScheduleModal();
      },
      overrideTitle:
        hasManagedDependents(member) || memberIsMinor
          ? t("careProvider.familyCareNavigator")
          : "",
    },
    therapist: {
      role: t("careProvider.therapist"),
      scheduleText: t("careTeamCard.scheduleTherapist"),
      changeText: t("careTeamCard.changeTherapist"),
      findText: t("careTeamCard.findTherapist"),
      description: getTherapistCardVerbiage(),
      emptyIcon: <EmptyTherapistIcon aria-hidden="true" />,
      smallIcon: (
        <PlantLeaves
          aria-hidden="true"
          color="#424A4C"
          width="24"
          height="24"
        />
      ),
      emptyAction: fireProviderLinkClickedTelemetry(
        routes.TherapistsBrowse,
        "Find Therapist",
        "Therapy",
      ),
    },
    medication_manager: {
      role: t("careProvider.medicationManager"),
      scheduleText: t("careTeamCard.scheduleMedicationManager"),
      changeText: t("careTeamCard.changeMedicationManager"),
      findText: t("careTeamCard.findMedicationManager"),
      description: isMedicationManagerLicensed
        ? t("careTeamCard.medicationManagerDescription")
        : providerNotLicensedVerbiage(medicationManagerLicensedCountries),
      emptyIcon: <EmptyMedicationManagerIcon aria-hidden="true" />,
      smallIcon: (
        <Medication aria-hidden="true" color="#424A4C" width="24" height="24" />
      ),
      emptyAction: fireProviderLinkClickedTelemetry(
        routes.Medication_ManagersBrowse,
        "Find Medication Manager",
        "Medicaton Management",
      ),
    },
    coach: {
      role: t("careProvider.coach"),
      scheduleText: t("careTeamCard.scheduleCoach"),
      changeText: t("careTeamCard.changeCoach"),
      findText: t("careTeamCard.findCoach"),
      description: getCoachingCardVerbiage(),
      emptyIcon: <EmptyCoachIcon aria-hidden="true" />,
      smallIcon: (
        <WateringCan
          aria-hidden="true"
          color="#424A4C"
          width="24"
          height="24"
        />
      ),
      emptyAction: fireProviderLinkClickedTelemetry(
        routes.CoachesBrowse,
        "Find Coach",
        "Coaching",
      ),
    },
    peer_recovery_specialist: {
      role: t("careProvider.peerRecoverySpecialist"),
      scheduleText: t("careTeamCard.schedulePeerRecoverySpecialist"),
      emptyIcon: <EmptyCoachIcon aria-hidden="true" />,
      smallIcon: (
        <SocialFactors
          aria-hidden="true"
          color="#424A4C"
          width="24"
          height="24"
        />
      ),
    },
  };

  const careTeamData = data && data.user?.member?.care_team;
  const locale = getOr("en", "locale", props);

  let medication_manager, therapist, coach, peer_recovery_specialist;

  if (careTeamData) {
    medication_manager = careTeamData.medication_manager;
    therapist = careTeamData.therapist;
    coach = careTeamData.coach;
    peer_recovery_specialist = careTeamData.peer_recovery_specialist;
  }
  const peerRecoverySpecialistSupported =
    data?.user?.member?.is_peer_recovery_specialist_supported;

  const modalId = (providerRole) => {
    const {
      // These endpoints account for sponsored sessions
      remainingMedManagement: specialistSessionsRemaining,
      // basic + specialist + sponsored
      remainingTherapy: totalTherapySessionsRemaining,
    } = returnAllSessionCounts(props?.memberInfo?.user?.member);
    const canSchedule = getOr(
      false,
      "memberInfo.user.member.previsit.can_schedule",
      props,
    );

    // displays insurance modal if the user has 1 or fewer sessions remaining and is not HighMark
    if (providerRole === "Therapist") {
      if (!canSchedule && totalTherapySessionsRemaining <= 1 && !isHighMark) {
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          "Profile Settings and Insurance",
          {
            provider_role: ProviderRole.Therapist,
          },
        );

        return modalIds.beforeYouScheduleModal;
      }
    }

    if (providerRole === "Medication Manager") {
      if (!canSchedule && specialistSessionsRemaining <= 1 && !isHighMark) {
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          "Profile Settings and Insurance",
          {
            provider_role: ProviderRole.MedicationManager,
          },
        );
        return modalIds.beforeYouScheduleModal;
      }
    }

    return modalIds.careProviderScheduleModal;
  };

  const action = (
    kind,
    providerRole,
    medium = AppointmentMedium.Video,
    params = {},
  ) => {
    if (providerRole !== "Care Navigator" && memberIsMinor) {
      const { Therapy, MedicationManagement } = RequestableAppointmentKind;
      const kind =
        providerRole === "Medication Manager" ? MedicationManagement : Therapy;

      return {
        text: t("careProvider.requestAvailability"),
        onClick: async (providerData) => {
          try {
            const payload = {
              care_provider_id: providerData.id,
              availability_days_of_week: ["Unknown"],
              availability_time_of_day: ["Unknown"],
              kind,
              medium,
            };

            const {
              data: { requestAppointment },
            } = await props.requestAppointment(payload);

            if (!requestAppointment.success) {
              const careTeamEmail = envUtils.careTeamEmail;
              const notification = (
                <Trans ns="careVisits" i18nKey="careProvider.errorNotification">
                  Oh no! We were not able to put this request through. Please
                  reach out to {{ careTeamEmail }} with the name of the
                  Medication Manager you would like to schedule with, and a Care
                  Navigator will assist you.
                </Trans>
              );
              return props.addNotification(notification, "error", 0);
            }

            return props.openModal(modalIds.availabilityRequestModal, {
              ...providerData,
            });
          } catch (err) {
            return props.addNotification(getFirstError(err), "error");
          }
        },
      };
    }

    return {
      onClick: (providerData) => {
        const appointmentType = getAppointmentKindForTracking(kind);
        const extractCareType = (rawType) => {
          if (rawType.includes("Therapy")) {
            return "Therapy";
          }
          return rawType;
        };
        const careType = extractCareType(appointmentType);

        if (isCareNavigation(kind)) {
          TRACK_EVENT.BUTTON_CLICKED(
            window.location.pathname,
            "Schedule Care Navigation",
            {
              spring_doc_id: "cnsched003",
              location: careType,
              to: modalId(providerRole),
              appointment_type: appointmentType,
              appointment_medium: getAppointmentMediumForTracking(medium),
            },
          );
        } else {
          TRACK_EVENT.BUTTON_CLICKED(
            window.location.pathname,
            `Schedule ${careType}`,
            {
              location: careType,
              to: modalId(providerRole),
              appointment_type: appointmentType,
              appointment_medium: getAppointmentMediumForTracking(medium),
            },
          );
        }

        props.openModal(modalId(providerRole), {
          ...providerData,
          kind,
          providerRole,
          medium,
          buttonText: t("careProvider.confirmAppointment"),
          action: AppointmentAction.Create,
          dataCy: "confirm-appointment",
          ...params,
        });
      },
    };
  };

  const getDetailsAction = () => {
    return (providerData) => {
      props.openModal(modalIds.careProviderDetailModal, {
        ...providerData,
      });
    };
  };

  const getCoachingAction = () => {
    if (allowDirectScheduling) {
      const coachVideoStartTime = getInitialStartTime(
        locale,
        data &&
          data.coach_video_appointment_slots?.appointment_slots?.available,
      );
      return action(
        AppointmentKind.Coaching,
        "Coach",
        AppointmentMedium.Video,
        { initialStartTime: coachVideoStartTime },
      );
    }
    const careNavPhoneStartTime = getInitialStartTime(
      locale,
      data &&
        data.carenav_phone_appointment_slots?.appointment_slots?.available,
    );
    return action(
      AppointmentKind.FollowUpCareNavigation,
      "Care Navigator",
      AppointmentMedium.Phone,
      { initialStartTime: careNavPhoneStartTime },
    );
  };

  const careTeam = () => {
    const team = [];
    let currLength = team.length;
    // TODO: there's probably a DRY-er way to do the below
    // For scheduling access either International CN or International No Scheduling, we empty care team card
    if (therapist) {
      const memberCohortSupportsInPerson = member.cohort?.in_person_supported;
      const memberCountry = member.postal_address?.country;
      const hasInPersonSupport = getSupportsInPersonTherapy(
        therapist,
        memberCohortSupportsInPerson,
        memberCountry,
      );

      let initialStartTime;
      let medium;
      const therapyVideoStartTime = getInitialStartTime(
        locale,
        data &&
          data.therapy_appointment_slots?.firstVirtualSlot?.appointment_slots
            ?.available,
      );
      const therapyInPersonStartTime = getInitialStartTime(
        locale,
        data &&
          data.therapy_appointment_slots?.firstInPersonSlot?.appointment_slots
            ?.available,
      );

      if (therapyInPersonStartTime === null && therapyVideoStartTime) {
        initialStartTime = therapyVideoStartTime;
        medium = AppointmentMedium.Video;
      } else if (therapyInPersonStartTime && therapyVideoStartTime === null) {
        initialStartTime = therapyInPersonStartTime;
        medium = AppointmentMedium.InPerson;
      } else {
        initialStartTime =
          therapyVideoStartTime > therapyInPersonStartTime && hasInPersonSupport
            ? therapyInPersonStartTime
            : therapyVideoStartTime;
        medium =
          therapyVideoStartTime > therapyInPersonStartTime && hasInPersonSupport
            ? AppointmentMedium.InPerson
            : AppointmentMedium.Video;
      }
      team.push(
        <CareTeamCard
          key={therapist.id}
          providerRole="therapist"
          providerAttributes={providerAttributes["therapist"]}
          browseOthers={t("careTeamCard.browseOthers")}
          showChange
          scheduleAction={action(AppointmentKind.Therapy, "Therapist", medium, {
            initialStartTime,
          })}
          infoAction={getDetailsAction()}
          changeAction={fireProviderLinkClickedTelemetry(
            routes.TherapistsBrowse,
            "Browse Others",
            "Your Care Providers",
            therapist.id,
          )}
          providerData={therapist}
          memberData={data.memberData}
          appointmentKind={getTherapyKind(data.memberData)}
          showGlobalExperience={props.showGlobalExperience}
          scheduleConfirmation={t("careProvider.confirmSession")}
          isGlobalMemberWithNoVisitsRemaining={
            isGlobalMemberWithNoVisitsRemaining
          }
          isTherapistLicensed={isTherapistLicensed}
          isMedicationManagerLicensed={isMedicationManagerLicensed}
          first_appointment_time_utc={initialStartTime}
          disableTracking={isCNScheduleModalOpen}
          onScheduleModalOpen={onScheduleModalOpen}
        />,
        <CardDivider key={`rule-${currLength}`} />,
      );
      currLength++;
    }
    if (
      peer_recovery_specialist &&
      showPeerRecoverySpecialistExp &&
      peerRecoverySpecialistSupported
    ) {
      team.push(
        <CareTeamCard
          key={peer_recovery_specialist.id}
          providerRole="peer_recovery_specialist"
          providerAttributes={providerAttributes["peer_recovery_specialist"]}
          infoAction={getDetailsAction()}
          providerData={peer_recovery_specialist}
          memberData={data.memberData}
          appointmentKind={AppointmentKind.FollowUpPeerRecoverySpecialist}
          scheduleConfirmation={t("careProvider.confirmSession")}
          onScheduleModalOpen={onScheduleModalOpen}
        />,
        <CardDivider key={`rule-${currLength}`} />,
      );
      currLength++;
    }
    if (allowDirectScheduling) {
      if (coach) {
        team.push(
          <CareTeamCard
            key={coach.id}
            providerRole="coach"
            providerAttributes={providerAttributes["coach"]}
            showChange
            scheduleAction={getCoachingAction()}
            infoAction={getDetailsAction()}
            changeAction={fireProviderLinkClickedTelemetry(
              routes.CoachesBrowse,
              "Browse Others",
              "Your Care Providers",
              coach.id,
            )}
            providerData={coach}
            browseOthers={t("careTeamCard.browseOthers")}
            memberData={data.memberData}
            appointmentKind={AppointmentKind.Coaching}
            isGlobalMemberWithNoVisitsRemaining={
              isGlobalMemberWithNoCoachingVisitsRemaining
            }
            showGlobalExperience={props.showGlobalExperience}
            scheduleConfirmation={t("careProvider.confirmSession")}
            isTherapistLicensed={isTherapistLicensed}
            isMedicationManagerLicensed={isMedicationManagerLicensed}
            isCoachLicensed={isCoachLicensed}
            disableTracking={isCNScheduleModalOpen}
            onScheduleModalOpen={onScheduleModalOpen}
          />,
          <CardDivider key={`rule-${currLength}`} />,
        );
        currLength++;
      }
    }

    if (medication_manager) {
      const medVideoStartTime = getInitialStartTime(
        locale,
        data && data.medication_appointment_slots?.appointment_slots?.available,
      );
      team.push(
        <CareTeamCard
          key={medication_manager.id}
          providerRole="medication_manager"
          providerAttributes={providerAttributes["medication_manager"]}
          showChange
          scheduleAction={action(
            AppointmentKind.FollowUpMedicationManagement,
            "Medication Manager",
            AppointmentMedium.Video,
            { initialStartTime: medVideoStartTime },
          )}
          infoAction={getDetailsAction()}
          changeAction={fireProviderLinkClickedTelemetry(
            routes.Medication_ManagersBrowse,
            "Browse Others",
            "Your Care Providers",
            medication_manager.id,
          )}
          providerData={medication_manager}
          browseOthers={t("careTeamCard.browseOthers")}
          memberData={data.memberData}
          appointmentKind={AppointmentKind.FollowUpMedicationManagement}
          showGlobalExperience={props.showGlobalExperience}
          scheduleConfirmation={t("careProvider.confirmSession")}
          isMedicationManagerLicensed={isMedicationManagerLicensed}
          isTherapistLicensed={isTherapistLicensed}
          disableTracking={isCNScheduleModalOpen}
          onScheduleModalOpen={onScheduleModalOpen}
        />,
        <CardDivider key={`rule-${currLength}`} />,
      );
    }
    return team;
  };

  const CareNavigatorSection = () => {
    const upcomingCNAppointmentID = get(
      "memberData.user.member.first_upcoming_appointments.care_navigation.id",
      data,
    );
    const getCareNavigatorCard = () => {
      if (!data?.user?.member?.care_team?.care_navigator) {
        return <></>;
      }
      return (
        <EmptyCareTeamCard
          key="empty-care_navigator"
          providerAttributes={providerAttributes["care_navigator"]}
          upcomingCNAppointmentID={upcomingCNAppointmentID}
        />
      );
    };
    if (isPrivatePractice) return <></>;
    return (
      <>
        <div style={{ marginTop: "50px" }}>
          <Grid gutter="16px">{getCareNavigatorCard()}</Grid>
        </div>
      </>
    );
  };

  return (
    <div ref={careTeamSection}>
      {isMemberInfoLoading && <Spinner speed="1s" size="xl" />}
      {!isMemberInfoLoading && (
        <>
          <Grid gutter="16px">{careTeam()}</Grid>
          <CareNavigatorSection />
          <CareProviderScheduleModal />
          <CareProviderDetailModal />
          <AvailabilityRequestModal />
          <HelpFlyout
            isOpen={isHelpFlyoutOpen}
            onOpen={openHelpFlyout}
            onClose={closeHelpFlyout}
          />
          <BeforeYouScheduleModal />
        </>
      )}
    </div>
  );
};

CareTeamList.propTypes = {
  addNotification: PropTypes.func.isRequired,
  isAMinor: PropTypes.bool,
  locale: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  requestAppointment: PropTypes.func.isRequired,
  showGlobalExperience: PropTypes.bool,
};

const mapStateToProps = ({ global: { showGlobalExperience, lang } }) => ({
  showGlobalExperience,
  locale: lang,
});

export default compose(
  connect(mapStateToProps, { openModal, addNotification }),
  graphql(getMemberInfo, {
    name: "memberInfo",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(getAssessmentRiskLevels, {
    options: (ownProps) => ({
      variables: {
        member_id: get("memberInfo.user.member.id", ownProps),
      },
    }),
    name: "assessmentRiskLevels",
  }),
  graphql(requestAppointment, {
    props: ({ mutate }) => ({
      requestAppointment: (data) =>
        mutate({ variables: { input: { ...data } } }),
    }),
  }),
)(CareTeamList);
