import { useGetWorkplaceCustomization } from "modules/MemberDashboard/ManagerExperience/Workplace/hooks/useGetWorkplaceCustomization";
import {
  GLOBAL_SUPPORT_EMAIL,
  GLOBAL_SUPPORT_EMAIL_T1_T2,
} from "modules/MemberDashboard/ManagerExperience/shared/components/ContactUs/utils/ContactUsSection.utils";
import { links } from "@spring/constants";
import { useTranslation } from "hooks/react-i18next";
import { WorkplaceCustomizationInput } from "modules/shared/graphql-codegen/graphql";
import {
  CriticalIncidentInfo,
  ManagerConsultInfo,
} from "modules/MemberDashboard/ManagerExperience/Workplace/types/types";

export const useWorkplaceCustomizationData = (
  customer_id: string,
  crisisSupportNumber?: string,
  isAGlobalMember = false,
) => {
  const { t } = useTranslation("managerExperience");

  const {
    workplaceCustomizationData,
    isWorkplaceCustomizationLoading,
    isManagerExperienceMilestone2Enabled,
  } = useGetWorkplaceCustomization(customer_id);

  //TODO: The code from L24-L56 is temp and will be removed https://springhealth.atlassian.net/browse/MXE-784
  const defaultWorkplaceCustomization = {
    isWorkplaceCustomizationLoading: false,
    customerHasWorkplaceEnabled: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    customerHasEmployerServicesEnabled: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    customerHasManagerConsultEnabled: false,
    customerHasCriticalIncidentsEnabled: false,
    shouldShowManagerConsult: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    shouldShowCriticalIncidents: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    shouldShowReferralContents: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    shouldShowSingleHelpCard: false, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    generalConsultationsReferralInfo: {
      //Setting default values for generalConsultationsReferrals to true to match prod
      shouldShowFitnessForDutyReferral: true,
      shouldShowFormalReferral: true,
      shouldShowInformalReferral: true,
      shouldShowMandatoryReferral: true,
      showShowSubstanceAbuseReferral: true,
    },
    criticalIncidentInfo: {
      criticalIncidentEmailAddress: GLOBAL_SUPPORT_EMAIL_T1_T2,
      criticalIncidentFormLink: links.WorkplaceCriticalIncidentResponse,
      criticalIncidentPhoneNumber: crisisSupportNumber,
      criticalIncidentDialOption: t("contactUsCard.press2Extension"),
    },
    managerConsultationInfo: {
      managerConsultEmailAddress: GLOBAL_SUPPORT_EMAIL,
      managerConsultFormLink: links.WorkplaceManagementConsult,
      managerConsultPhoneNumber: crisisSupportNumber,
      managerConsultDialOption: t("contactUsCard.press4Then2Extension"),
      managerConsultSubstituteTerm: t("defaultManagerConsultantTerm"),
    },
    numOfCustomManagerConsultOptions: 0,
    hasManagerConsultPhoneNumber: false,
    hasManagerConsultDialOption: false,
    hasManagerConsultEmail: false,
    hasManagerConsultFormLink: false,
    hasManagerConsultSubstituteTerm: false,
    numOfCustomCriticalIncidentOptions: 0,
    hasCriticalIncidentFormLink: false,
    hasCriticalIncidentPhoneNumber: false,
    hasCriticalIncidentDialOption: false,
    hasCriticalIncidentEmail: false,
    employeeSubstituteTerm: t("defaultEmployeeUppercaseTerm"),
    managerSubstituteTerm: t("defaultManagerUppercaseTerm"),
    leaderCourseInfo: {
      leaderCourseURL: "",
      leaderCourseTitle: t("leaderCourse.heading"),
      leaderCourseDescription: t("leaderCourse.description"),
    },
  };

  if (!isManagerExperienceMilestone2Enabled) {
    return defaultWorkplaceCustomization;
  }

  const workplaceData: WorkplaceCustomizationInput =
    workplaceCustomizationData?.customer_workplace_customization;

  const customerHasWorkplaceEnabled =
    isWorkplaceCustomizationLoading ||
    workplaceData?.workplace_enabled === undefined
      ? true
      : workplaceData?.workplace_enabled;

  const customerHasEmployerServicesEnabled =
    workplaceData?.employer_services_enabled;

  const customerHasManagerConsultEnabled =
    workplaceData?.manager_consult_enabled;

  const customerHasCriticalIncidentsEnabled =
    workplaceData?.critical_incident_response_enabled;

  const shouldShowManagerConsult =
    customerHasEmployerServicesEnabled && customerHasManagerConsultEnabled;

  const shouldShowCriticalIncidents =
    customerHasEmployerServicesEnabled && customerHasCriticalIncidentsEnabled;

  const generalConsultationsReferralInfo = {
    shouldShowFitnessForDutyReferral:
      workplaceData?.fitness_for_duty_referral_type_enabled,
    shouldShowFormalReferral: workplaceData?.formal_referral_type_enabled,
    shouldShowInformalReferral: workplaceData?.informal_referral_type_enabled,
    shouldShowMandatoryReferral: workplaceData?.mandatory_referral_type_enabled,
    showShowSubstanceAbuseReferral:
      workplaceData?.substance_abuse_referral_type_enabled,
  };

  const hasCriticalIncidentPhoneNumber =
    workplaceData?.critical_incident_phone_number;
  const hasCriticalIncidentDialOption =
    workplaceData?.critical_incident_phone_extension;
  const hasCriticalIncidentEmail = workplaceData?.critical_incident_email;
  const hasCriticalIncidentFormLink =
    workplaceData?.critical_incident_form_link;

  const criticalIncidentInfo: CriticalIncidentInfo =
    shouldShowCriticalIncidents && !isAGlobalMember
      ? {
          criticalIncidentEmailAddress: hasCriticalIncidentEmail
            ? workplaceData?.critical_incident_email
            : defaultWorkplaceCustomization.criticalIncidentInfo
                .criticalIncidentEmailAddress,
          criticalIncidentFormLink: hasCriticalIncidentFormLink
            ? workplaceData?.critical_incident_form_link
            : defaultWorkplaceCustomization.criticalIncidentInfo
                .criticalIncidentFormLink,
          criticalIncidentPhoneNumber: hasCriticalIncidentPhoneNumber
            ? workplaceData?.critical_incident_phone_number
            : defaultWorkplaceCustomization.criticalIncidentInfo
                .criticalIncidentPhoneNumber,
          criticalIncidentDialOption: hasCriticalIncidentDialOption
            ? workplaceData?.critical_incident_phone_extension
            : defaultWorkplaceCustomization.criticalIncidentInfo
                .criticalIncidentDialOption,
        }
      : {
          criticalIncidentEmailAddress:
            defaultWorkplaceCustomization.criticalIncidentInfo
              .criticalIncidentEmailAddress,
          criticalIncidentFormLink:
            defaultWorkplaceCustomization.criticalIncidentInfo
              .criticalIncidentFormLink,
          criticalIncidentPhoneNumber:
            defaultWorkplaceCustomization.criticalIncidentInfo
              .criticalIncidentPhoneNumber,
          criticalIncidentDialOption:
            defaultWorkplaceCustomization.criticalIncidentInfo
              .criticalIncidentDialOption,
        };

  const hasManagerConsultPhoneNumber =
    workplaceData?.manager_consult_phone_number;
  const hasManagerConsultDialOption =
    workplaceData?.manager_consult_phone_extension;
  const hasManagerConsultEmail = workplaceData?.manager_consult_email;
  const hasManagerConsultFormLink = workplaceData?.manager_consult_form_link;
  const hasManagerConsultSubstituteTerm = workplaceData?.manager_consult_term;

  const managerConsultationInfo: ManagerConsultInfo =
    shouldShowManagerConsult && !isAGlobalMember
      ? {
          managerConsultEmailAddress: hasManagerConsultEmail
            ? workplaceData?.manager_consult_email
            : defaultWorkplaceCustomization.managerConsultationInfo
                .managerConsultEmailAddress,
          managerConsultFormLink: hasManagerConsultFormLink
            ? workplaceData?.manager_consult_form_link
            : defaultWorkplaceCustomization.managerConsultationInfo
                .managerConsultFormLink,
          managerConsultPhoneNumber: hasManagerConsultPhoneNumber
            ? workplaceData?.manager_consult_phone_number
            : defaultWorkplaceCustomization.managerConsultationInfo
                .managerConsultPhoneNumber,
          managerConsultDialOption: hasManagerConsultDialOption
            ? workplaceData?.manager_consult_phone_extension
            : defaultWorkplaceCustomization.managerConsultationInfo
                .managerConsultDialOption,
          managerConsultSubstituteTerm: hasManagerConsultSubstituteTerm
            ? workplaceData?.manager_consult_term
            : defaultWorkplaceCustomization.managerConsultationInfo
                .managerConsultSubstituteTerm,
        }
      : {
          managerConsultEmailAddress:
            defaultWorkplaceCustomization.managerConsultationInfo
              .managerConsultEmailAddress,
          managerConsultFormLink:
            defaultWorkplaceCustomization.managerConsultationInfo
              .managerConsultFormLink,
          managerConsultPhoneNumber:
            defaultWorkplaceCustomization.managerConsultationInfo
              .managerConsultPhoneNumber,
          managerConsultDialOption:
            defaultWorkplaceCustomization.managerConsultationInfo
              .managerConsultDialOption,
          managerConsultSubstituteTerm:
            defaultWorkplaceCustomization.managerConsultationInfo
              .managerConsultSubstituteTerm,
        };

  const leaderCourseInfo = {
    leaderCourseURL:
      workplaceData?.leader_course_url ??
      defaultWorkplaceCustomization.leaderCourseInfo.leaderCourseURL,
    leaderCourseTitle:
      workplaceData?.leader_course_title ??
      defaultWorkplaceCustomization.leaderCourseInfo.leaderCourseTitle,
    leaderCourseDescription:
      workplaceData?.leader_course_description ??
      defaultWorkplaceCustomization.leaderCourseInfo.leaderCourseDescription,
  };

  const numOfCustomManagerConsultOptions = [
    hasManagerConsultFormLink,
    hasManagerConsultPhoneNumber,
    hasManagerConsultEmail,
  ].filter(Boolean).length;

  const numOfCustomCriticalIncidentOptions = [
    hasCriticalIncidentFormLink,
    hasCriticalIncidentPhoneNumber,
    hasCriticalIncidentEmail,
  ].filter(Boolean).length;

  const employeeSubstituteTerm = workplaceData?.term_employee_known_as;

  const managerSubstituteTerm = workplaceData?.term_manager_known_as;

  const shouldShowReferralContents =
    generalConsultationsReferralInfo.shouldShowFitnessForDutyReferral ||
    generalConsultationsReferralInfo.shouldShowFormalReferral ||
    generalConsultationsReferralInfo.shouldShowInformalReferral ||
    generalConsultationsReferralInfo.shouldShowMandatoryReferral ||
    generalConsultationsReferralInfo.showShowSubstanceAbuseReferral;

  const shouldShowSingleHelpCard =
    !shouldShowManagerConsult || !shouldShowCriticalIncidents;

  return {
    isWorkplaceCustomizationLoading,
    customerHasWorkplaceEnabled,
    customerHasEmployerServicesEnabled,
    customerHasManagerConsultEnabled,
    customerHasCriticalIncidentsEnabled,
    shouldShowManagerConsult,
    shouldShowCriticalIncidents,
    generalConsultationsReferralInfo,
    criticalIncidentInfo,
    managerConsultationInfo,
    employeeSubstituteTerm,
    managerSubstituteTerm,
    leaderCourseInfo,
    hasManagerConsultFormLink,
    hasManagerConsultPhoneNumber,
    hasManagerConsultDialOption,
    hasManagerConsultEmail,
    hasCriticalIncidentFormLink,
    hasCriticalIncidentPhoneNumber,
    hasCriticalIncidentDialOption,
    hasCriticalIncidentEmail,
    numOfCustomManagerConsultOptions,
    numOfCustomCriticalIncidentOptions,
    shouldShowReferralContents,
    shouldShowSingleHelpCard,
  };
};
