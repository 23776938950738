//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "next/router";
import { get } from "lodash/fp";

// Allows automatic scrolling to a given location on a page.
// Add this component as a parent of an item that you want to automatically
// scroll to. To create a link that scrolls to this location, pass
// queries={{ scrollTo: 'your ScrollTo id here' }} as a prop in the Link
// component.
class ScrollTo extends Component {
  static propTypes = {
    children: PropTypes.any,
    delay: PropTypes.number,
    id: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  scroll() {
    const scrollDestination = get("router.query.scrollTo", this.props);
    if (scrollDestination === this.props.id) {
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        window.scrollTo({
          top: this.ref.current.offsetTop,
          behavior: "smooth",
        });
      }, this.props.delay);
    }
  }

  componentDidMount() {
    this.scroll();
  }

  componentDidUpdate() {
    this.scroll();
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  render() {
    return <span ref={this.ref}>{this.props.children}</span>;
  }
}

ScrollTo.defaultProps = {
  delay: 0,
};

export default withRouter(ScrollTo);
