import { gql } from "@apollo/client";

const showGatedBooking = gql`
  query showGatedBookingFlow($member_id: ID!, $appointment_kind: String!) {
    show_gated_booking_flow(
      member_id: $member_id
      appointment_kind: $appointment_kind
    ) {
      success
      show_gated_booking
    }
  }
`;

export default showGatedBooking;
