import React from "react";

const CalendarSvgIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M6 14V16H4V14H6Z" fill="#110A09" />
    <path d="M10 10H8V12H10V10Z" fill="#110A09" />
    <path d="M8 14H10V16H8V14Z" fill="#110A09" />
    <path d="M14 10H12V12H14V10Z" fill="#110A09" />
    <path d="M12 14H14V16H12V14Z" fill="#110A09" />
    <path d="M18 12V10H16V12H18Z" fill="#110A09" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C6.55228 0 7 0.447715 7 1V2H15V1C15 0.447715 15.4477 0 16 0C16.5523 0 17 0.447715 17 1V2H19C20.6569 2 22 3.34315 22 5V17C22 18.6569 20.6569 20 19 20H3C1.34315 20 0 18.6569 0 17V5C0 3.34315 1.34315 2 3 2H5V1C5 0.447715 5.44772 0 6 0ZM15 4V5C15 5.55228 15.4477 6 16 6C16.5523 6 17 5.55228 17 5V4H19C19.5523 4 20 4.44772 20 5V17C20 17.5523 19.5523 18 19 18H3C2.44772 18 2 17.5523 2 17V5C2 4.44772 2.44772 4 3 4H5V5C5 5.55228 5.44772 6 6 6C6.55229 6 7 5.55228 7 5V4H15Z"
      fill="#110A09"
    />
  </svg>
);

export default CalendarSvgIcon;
