import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";

import routes from "routes";

export const getFAQFlyoutContents = (t, disclosures) => {
  return [
    {
      flyoutName: "therapy_at_spring",
      headerText: t("FAQFlyOutContents.therapyAtSpring.headerQuestion"),
      heroImageSrc: "/static/therapy_at_spring.png",
      tileImageSrc: "/static/spring_for_teens.png",
      tileImageGradient:
        "linear-gradient(180deg, rgba(57, 48, 59, 0.00) 0%, rgba(57, 48, 59, 0.01) 14.59%, rgba(57, 48, 59, 0.04) 26.58%, rgba(57, 48, 59, 0.08) 36.32%, rgba(57, 48, 59, 0.14) 44.14%, rgba(57, 48, 59, 0.21) 50.37%, rgba(57, 48, 59, 0.29) 55.36%, rgba(57, 48, 59, 0.38) 59.44%, rgba(57, 48, 59, 0.47) 62.96%, rgba(57, 48, 59, 0.56) 66.24%, rgba(57, 48, 59, 0.65) 69.63%, rgba(57, 48, 59, 0.74) 73.46%, rgba(57, 48, 59, 0.82) 78.08%, rgba(57, 48, 59, 0.89) 83.82%, rgba(57, 48, 59, 0.95) 91.01%, #39303B 100%)",
      body: (
        <>
          <Heading mb={8} size="heading-small">
            {t("FAQFlyOutContents.therapyAtSpring.subQuestion1")}
          </Heading>
          <Text mb={24} size="body-medium-regular">
            {t("FAQFlyOutContents.therapyAtSpring.bodyAnswer1")}
          </Text>
          <Text mb={24} size="body-medium-regular">
            {t("FAQFlyOutContents.therapyAtSpring.bodyAnswer2")}
          </Text>
          <Heading mb={8} size="heading-small">
            {t("FAQFlyOutContents.therapyAtSpring.subQuestion2")}
          </Heading>
          <Text mb={24} size="body-medium-regular">
            {t("FAQFlyOutContents.therapyAtSpring.bodyAnswer3")}
          </Text>
          <UnorderedList mb={24}>
            <ListItem>
              <Text size="body-medium-regular">
                {t("FAQFlyOutContents.therapyAtSpring.listItem1")}
              </Text>
            </ListItem>
            <ListItem>
              <Text size="body-medium-regular">
                {t("FAQFlyOutContents.therapyAtSpring.listItem2")}
              </Text>
            </ListItem>
            <ListItem>
              <Text size="body-medium-regular">
                {t("FAQFlyOutContents.therapyAtSpring.listItem3")}
              </Text>
            </ListItem>
          </UnorderedList>
          <Text mb={24} size="body-medium-regular">
            {t("FAQFlyOutContents.therapyAtSpring.bodyAnswer4")}
          </Text>
        </>
      ),
      ctaText: t("FAQFlyOutContents.ctaText"),
      ctaTextSchedule: t("FAQFlyOutContents.schedule"),
      ctaLinkAs: routes.TherapistsBrowse.as,
      ctaType: "button",
      isOpen: disclosures.therapyAtSpring.isOpen,
      onClose: disclosures.therapyAtSpring.onClose,
      onOpen: disclosures.therapyAtSpring.onOpen,
    },
    {
      flyoutName: "private_virtual_session",
      headerText: t("FAQFlyOutContents.privateVirtualSession.headerQuestion"),
      heroImageSrc: "/static/private_virtual_session.png",
      heroImageSrcAltTxt: t(
        "FAQFlyOutContents.imageAltText.privateVirtualSession",
      ),
      tileImageSrc: "/static/private_sessions_for_teens.png",
      tileImageGradient:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.01) 14.59%, rgba(0, 0, 0, 0.03) 26.58%, rgba(0, 0, 0, 0.05) 36.32%, rgba(0, 0, 0, 0.09) 44.14%, rgba(0, 0, 0, 0.14) 50.37%, rgba(0, 0, 0, 0.19) 55.36%, rgba(0, 0, 0, 0.24) 59.44%, rgba(0, 0, 0, 0.30) 62.96%, rgba(0, 0, 0, 0.36) 66.24%, rgba(0, 0, 0, 0.42) 69.63%, rgba(0, 0, 0, 0.47) 73.46%, rgba(0, 0, 0, 0.52) 78.08%, rgba(0, 0, 0, 0.57) 83.82%, rgba(0, 0, 0, 0.61) 91.01%, rgba(0, 0, 0, 0.64) 100%)",
      body: (
        <>
          <Text mb={24} size="body-medium-regular">
            {t("FAQFlyOutContents.privateVirtualSession.bodyAnswer")}
          </Text>
          <UnorderedList mb={24}>
            <ListItem>
              <Text size="body-medium-regular">
                {t("FAQFlyOutContents.privateVirtualSession.listItem1")}
              </Text>
            </ListItem>
            <ListItem>
              <Text size="body-medium-regular">
                {t("FAQFlyOutContents.privateVirtualSession.listItem2")}
              </Text>
            </ListItem>
            <ListItem>
              <Text size="body-medium-regular">
                {t("FAQFlyOutContents.privateVirtualSession.listItem3")}
              </Text>
            </ListItem>
            <ListItem>
              <Text size="body-medium-regular">
                {t("FAQFlyOutContents.privateVirtualSession.listItem4")}
              </Text>
            </ListItem>
          </UnorderedList>
        </>
      ),
      ctaText: t("FAQFlyOutContents.ctaText"),
      ctaTextSchedule: t("FAQFlyOutContents.schedule"),
      ctaLinkAs: routes.TherapistsBrowse.as,
      ctaType: "button",
      isOpen: disclosures.privateVirtualSession.isOpen,
      onClose: disclosures.privateVirtualSession.onClose,
      onOpen: disclosures.privateVirtualSession.onOpen,
    },
    {
      flyoutName: "need_for_therapy",
      headerText: t("FAQFlyOutContents.needForTherapy.headerQuestion"),
      heroImageSrc: "/static/need_for_therapy.png",
      tileImageSrc: "/static/teen_need_for_therapy.png",
      tileImageGradient:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.01) 14.59%, rgba(0, 0, 0, 0.03) 26.58%, rgba(0, 0, 0, 0.05) 36.32%, rgba(0, 0, 0, 0.09) 44.14%, rgba(0, 0, 0, 0.14) 50.37%, rgba(0, 0, 0, 0.19) 55.36%, rgba(0, 0, 0, 0.24) 59.44%, rgba(0, 0, 0, 0.30) 62.96%, rgba(0, 0, 0, 0.36) 66.24%, rgba(0, 0, 0, 0.42) 69.63%, rgba(0, 0, 0, 0.47) 73.46%, rgba(0, 0, 0, 0.52) 78.08%, rgba(0, 0, 0, 0.57) 83.82%, rgba(0, 0, 0, 0.61) 91.01%, rgba(0, 0, 0, 0.64) 100%)",
      body: (
        <Text mb={24} size="body-medium-regular">
          {t("FAQFlyOutContents.needForTherapy.bodyAnswer")}
        </Text>
      ),
      ctaText: t("FAQFlyOutContents.ctaText"),
      ctaTextSchedule: t("FAQFlyOutContents.schedule"),
      ctaLinkAs: routes.TherapistsBrowse.as,
      ctaType: "button",
      isOpen: disclosures.needForTherapy.isOpen,
      onClose: disclosures.needForTherapy.onClose,
      onOpen: disclosures.needForTherapy.onOpen,
    },
    {
      flyoutName: "talk_to_parents",
      headerText: t("FAQFlyOutContents.talkToParents.headerQuestion"),
      heroImageSrc: "/static/talk_to_parents.png",
      heroImageSrcAltTxt: t("FAQFlyOutContents.imageAltText.talkToParents"),
      tileImageSrc: "/static/teen_talk_to_parents.png",
      tileImageGradient:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.01) 14.59%, rgba(0, 0, 0, 0.03) 26.58%, rgba(0, 0, 0, 0.05) 36.32%, rgba(0, 0, 0, 0.09) 44.14%, rgba(0, 0, 0, 0.14) 50.37%, rgba(0, 0, 0, 0.19) 55.36%, rgba(0, 0, 0, 0.24) 59.44%, rgba(0, 0, 0, 0.30) 62.96%, rgba(0, 0, 0, 0.36) 66.24%, rgba(0, 0, 0, 0.42) 69.63%, rgba(0, 0, 0, 0.47) 73.46%, rgba(0, 0, 0, 0.52) 78.08%, rgba(0, 0, 0, 0.57) 83.82%, rgba(0, 0, 0, 0.61) 91.01%, rgba(0, 0, 0, 0.64) 100%)",
      body: (
        <Text mb={24} size="body-medium-regular">
          {t("FAQFlyOutContents.talkToParents.bodyAnswer")}
        </Text>
      ),
      ctaText: t("FAQFlyOutContents.ctaText"),
      ctaTextSchedule: t("FAQFlyOutContents.schedule"),
      ctaLinkAs: routes.TherapistsBrowse.as,
      ctaType: "button",
      isOpen: disclosures.talkToParents.isOpen,
      onClose: disclosures.talkToParents.onClose,
      onOpen: disclosures.talkToParents.onOpen,
    },
  ];
};
