import { createIcon } from "@chakra-ui/react";

export const WateringCan = createIcon({
  displayName: "WateringCanV1.1",
  viewBox: "0 0 23 22",
  path: (
    <>
      <path
        fill="currentColor"
        d="M6.85032 16.2238C8.00012 16.891 9.46405 16.5061 10.1201 15.364C11.3079 13.2961 10.2159 10.3647 10.2159 10.3647C10.2159 10.3647 7.14411 10.8798 5.95626 12.9478C5.30022 14.0898 5.70051 15.5566 6.85032 16.2238Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.73981 0.467347C4.03196 0.0105851 4.62949 -0.135072 5.09736 0.136429L16.4931 6.7493C16.9293 7.00245 17.114 7.53948 16.9263 8.00934L16.789 8.35319L18.5519 8.02156L18.3387 7.5246C18.1174 7.00862 18.3542 6.41003 18.8675 6.1876C19.3809 5.96517 19.9764 6.20314 20.1977 6.71911L20.8404 8.21757C20.9077 8.31836 20.958 8.43282 20.9862 8.55757L22.9171 13.0594C23.1384 13.5754 22.9016 14.1739 22.3882 14.3964C21.8749 14.6188 21.2793 14.3808 21.058 13.8649L20.7896 13.239L12.9516 17.9623L11.5941 21.3616C11.4848 21.6353 11.2627 21.8479 10.9854 21.9442C10.708 22.0405 10.4028 22.0112 10.1486 21.8637L0.506109 16.2682C0.251992 16.1207 0.0740471 15.8697 0.0183854 15.5801C-0.0372761 15.2905 0.0348231 14.9909 0.216001 14.759L3.08744 11.0822C0.680406 9.5963 -0.0559455 6.40201 1.4814 3.99837L3.73981 0.467347ZM19.3734 9.93707L19.981 11.3538L14.1993 14.838L15.8952 10.5914L19.3734 9.93707ZM4.34891 9.4669L8.334 4.36414L4.92087 2.38352L3.1843 5.09866C2.24664 6.56467 2.71867 8.52088 4.22033 9.39229L4.34891 9.4669ZM10.105 5.39183L2.52903 15.0926L10.1501 19.5151L14.7206 8.07025L10.105 5.39183Z"
      />
    </>
  ),
  defaultProps: {
    "aria-hidden": true,
    "aria-label": "Watering can icon",
    role: "img",
    color: "tertiary.700",
  },
});
