//

import React from "react";

import styles from "./styles.module.scss";

const BorderPage = (props) => (
  <div
    className={
      props.borderless
        ? styles.borderlessContent
        : props.isMemberT2
          ? styles.contentT2
          : styles.content
    }
  >
    {props.children}
  </div>
);

export default BorderPage;
