import { useDispatch, useSelector } from "react-redux";
import { SHTextArea } from "design-system/components";
import { setField } from "actions/assessment/actions";
import { SpringFormState } from "components/assessments/types";

type FillInAreaProps = {
  fieldKey: string;
  formKey: string;
  placeholder?: string;
  dataCy?: string;
};

const FillInArea = (props: FillInAreaProps) => {
  const { formKey, fieldKey, placeholder, dataCy } = props;
  const answer = useSelector((state: SpringFormState) => {
    return state.form[formKey]?.data[fieldKey];
  });

  const dispatch = useDispatch();

  const handleChange = (val) => {
    dispatch(setField(formKey, fieldKey, val, "string"));
  };

  return (
    <>
      <SHTextArea
        value={answer}
        onChange={handleChange}
        placeholder={placeholder}
        dataCy={dataCy}
      />
    </>
  );
};

export { FillInArea };
