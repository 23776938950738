//
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';

import styles from './styles.module.scss';



const Label = (props) => {
	let required = props.forceRequired || props.required;
	return (
		<label
			htmlFor={props.for ? `${props.for}` : `${props.formKey}-${props.fieldKey}`}
			className={classnames(styles.label, props.className, {
				[styles[props.theme]]            : props.theme,
				[styles.disabled]                : props.disabled,
				[styles.bolded]                  : props.bolded,
				[styles.descriptionAbovePresent] : props.descriptionAbovePresent,
				[styles.raiseLabel]              : props.raiseLabel,
				[styles.centerAligned]           : props.centerAligned,
			})}
			hidden={props.hidden}
			aria-label={props.ariaLabel}
		>
			{props.children}
			{required && props.bolded && (
				<span className={styles.requiredStarBlack} aria-hidden="true">*</span>
			)}
			{required && !props.bolded && (
				<span className={styles.requiredStar} aria-hidden="true">*</span>
			)}
		</label>
	);
};

export { Label };
export default connect(
	(state, ownProps) => ({
		required: typeof ownProps.required === 'boolean' ?
			ownProps.required :
			get(`${ownProps.formKey}.meta.${ownProps.fieldKey}.__required`, state.form),
	})
)(Label);
