import { lazy, Suspense } from "react";
import { Box, Flex, Text } from "design-system/components/index";

type Props = {
  variant: string;
  text: string;
  mb: string;
  iconName?: string;
};

export const SHBadge = ({ variant, text, mb, iconName }: Props) => {
  function loadIconImg(name) {
    if (typeof name !== "undefined") {
      const Icon = lazy(() => import("../../../assets/icons/" + name));

      return (
        <Suspense fallback={<span />}>
          <Box pr={8}>
            <Icon color="platform.900" boxSize={24} />
          </Box>
        </Suspense>
      );
    }
  }

  return (
    <Flex
      w="fit-content"
      p={8}
      bg={`${variant}.light`}
      borderRadius={8}
      mb={mb}
      display="inline-flex"
      align="center"
    >
      {iconName && loadIconImg(iconName)}
      <Text variant="bodyBold_v1" color={`${variant}.dark`}>
        {text}
      </Text>
    </Flex>
  );
};
