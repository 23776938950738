import { useDispatch, useSelector } from "react-redux";
import { SHOutlinedCircleCheckBox } from "design-system/components";
import { setField } from "actions/assessment/actions";
import { SpringFormState } from "components/assessments/types";

type CheckboxProps = {
  formKey: string;
  fieldKey: string;
  label: string;
  dataCy: string;
};

const Checkbox = (props: CheckboxProps) => {
  const { formKey, fieldKey, label, dataCy } = props;

  const answer = useSelector((state: SpringFormState) => {
    return state.form[formKey]?.data[fieldKey];
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(setField(formKey, fieldKey, e.target.checked, "boolean"));
  };

  return (
    <>
      <SHOutlinedCircleCheckBox
        checked={answer || false}
        onChange={handleChange}
        key={fieldKey}
        fieldKey={fieldKey}
        label={label}
        inputDataCy={dataCy}
      />
    </>
  );
};

export { Checkbox };
