import PropTypes from "prop-types";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  BackgroundImage,
  Breakpoint,
  FullWidth,
  NavItem,
  Standard,
  Stout,
} from "@spring/smeargle";
import { EnvDetails } from "modules/shared/components/EnvDetails/EnvDetails";
import { Navigation, useDisclosure } from "design-system/components";
import { useTranslation } from "hooks/react-i18next";
import { localeCountry } from "actions/global/actions";
import { lookupLanguage, getLanguageFromCountry } from "utils/language";
import { useDetectScrollTop } from "@spring/hooks";

import Link from "components/atoms/Link";
import { PageBase } from "components/layout/Page";

import styles from "./styles.module.scss";
import { HelpFlyout } from "components/flyouts/HelpFlyout";
import { HelpButton } from "components/molecules";
import routes from "routes";
import { useMemberInfo } from "hooks/useMemberInfo";
import { isTeen } from "utils/memberHelpers";

const rightLinksData = [
  {
    alias: "SignIn",
    linkName: "Sign In",
    dataCy: "sign-in",
    i18nKey: "rightLinks.signIn",
    path: "/sign_in",
    hideOnMobile: true,
  },
  {
    alias: "Register",
    linkName: "Create An Account",
    dataCy: "register",
    i18nKey: "rightLinks.createAccount",
    path: "/register",
  },
];

const RightLinks = connect((state) => ({
  isLoggedIn: state.auth.isLoggedIn,
}))((props) => {
  const router = useRouter();

  if (!router) {
    return null;
  }

  const { t } = useTranslation("limitedLangAuth");

  return (
    <>
      <div className={styles.helpIconWrapper}>
        <HelpLink />
      </div>
      {props.isLoggedIn && (
        <NavItem key="logout">
          <Link alias="Logout">
            <Stout>
              <div className={styles.link}>{t("signUp.logout")}</div>
            </Stout>
          </Link>
        </NavItem>
      )}
      {!props.isLoggedIn &&
        rightLinksData.map((data) => {
          /**
           * Optionally render links. In this case, the 'Sign In' link
           * will not render in /sign_in, but will render on /sign_up,
           * and vice versa for /sign_up.
           */
          if (
            router.asPath.includes(data.path) ||
            router.asPath.includes("family_signup")
          ) {
            return;
          }

          return (
            <NavItem key={data.linkName}>
              <Link alias={data.alias} dataCy={data.dataCy}>
                <Stout>
                  <div className={styles.link}>{t(data.i18nKey)}</div>
                </Stout>
              </Link>
            </NavItem>
          );
        })}
    </>
  );
});

RightLinks.propTypes = {
  isLoggedIn: PropTypes.any,
};

// For the new design, we only want sign_in to appear on the right
const RedesignLinks = connect((state) => ({
  isLoggedIn: state.auth.isLoggedIn,
}))((props) => {
  const router = useRouter();

  if (!router) {
    return null;
  }

  const { t } = useTranslation("limitedLangAuth");

  return (
    <>
      <div className={styles.helpIconWrapper}>
        <HelpLink />
      </div>
      {props.isLoggedIn && (
        <NavItem key="logout">
          <Link alias="Logout">
            <Stout>
              <div className={styles.link}>{t("signUp.logout")}</div>
            </Stout>
          </Link>
        </NavItem>
      )}
      {!props.isLoggedIn && (
        <NavItem key={rightLinksData[0].linkName}>
          <Link
            alias={rightLinksData[0].alias}
            dataCy={rightLinksData[0].dataCy}
          >
            <Stout>
              <div className={styles.link}>{t(rightLinksData[0].i18nKey)}</div>
            </Stout>
          </Link>
        </NavItem>
      )}
    </>
  );
});

const HelpLink = () => {
  // Control the help flyout state
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div className={styles.helpIconWrapper}>
        <HelpButton setOnOpen={onOpen} />
      </div>
      <HelpFlyout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

RedesignLinks.propTypes = {
  isLoggedIn: PropTypes.any,
};

const LogoWrapper = connect((state) => ({
  isLoggedIn: state.auth.isLoggedIn,
}))(({ children, isLoggedIn }) => {
  const { data: memberData } = useMemberInfo();
  const isATeen = isTeen(memberData?.user?.member?.date_of_birth);
  const alias = !isLoggedIn ? "SignIn" : isATeen ? "TeenHome" : "MemberHome";

  const { t } = useTranslation("a11y");
  const label = isLoggedIn
    ? `${t("action.linkTo", { ns: "a11y", linkName: `${t("a11y:shLogoAlt")}` })}`
    : `${t("action.linkTo", { ns: "a11y", linkName: `${t("a11y:shLogoAlt")}` })}`;
  return (
    <div className={styles.logo}>
      <Link prefetch ariaLabel={label} alias={alias}>
        {children}
      </Link>
    </div>
  );
});

LogoWrapper.propTypes = {
  children: PropTypes.any,
  isLoggedIn: PropTypes.any,
};

const Wrapper = (props) => {
  const router = useRouter();

  if (!router) {
    return null;
  }

  if (props.full) {
    return <FullWidth>{props.children}</FullWidth>;
  }

  return (
    <Standard
      addPaddingHeader={router.asPath.indexOf(routes.MemberHome.as) <= -1}
    >
      {props.children}
    </Standard>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
  full: PropTypes.any,
};

const BackgroundWrapper = (props) => {
  const isNotSignUpRoute = !props.pathname.includes("sign_up");
  const background = isNotSignUpRoute
    ? "url('/static/DeepBreathing.svg')"
    : "url('/static/signup.svg')";

  return (
    <div className={styles.backgroundWrapper}>
      <Breakpoint md andUp>
        <BackgroundImage
          backgroundImage={background}
          backgroundSize="cover"
          height="100vh"
        />
      </Breakpoint>
    </div>
  );
};

BackgroundWrapper.propTypes = {
  pathname: PropTypes.shape({
    includes: PropTypes.func,
  }),
};

const Landing = (props) => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const { t } = useTranslation("a11y");

  if (!router) {
    return null;
  }

  useEffect(() => {
    storeAndRemoveQueryParams({
      opt_in_personal: "opt_in_personal",
      relay_token: "relay_token",
      external_user_id: "external_user_id",
      provider: "provider",
    });
  }, []);

  // Stores query params when they hit the landing page then removes them from url
  // This function is an entry point to store param data until AFTER login, then use the data
  const storeAndRemoveQueryParams = (paramsToStore) => {
    for (const [param, local_key] of Object.entries(paramsToStore)) {
      if (router.query[param] === undefined) {
        continue;
      }

      localStorage.setItem(local_key, router.query[param] ?? null);
    }

    removeQueryParams(paramsToStore);
  };

  // Removes query param from url
  const removeQueryParams = (paramsToStore) => {
    const { query } = router;
    const params = new URLSearchParams(query);
    const pathname = window.location.pathname;

    let currentParams = [...params.keys()];
    let removedParams = Object.keys(paramsToStore);

    // if there are no query params to remove, don't bother reloading the page.
    // Also, this prevents a redirect loop under certain conditions
    if (!currentParams.some((p) => removedParams.includes(p))) return;

    if (Object.keys(query).length === 0) return;

    for (const [param] of Object.entries(paramsToStore)) {
      params.delete(param);
    }

    router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  };

  const setLanguage = (language = {}) => {
    if (!window) return;
    i18n.changeLanguage(language.code);
    props.localeCountry({ lang: language.code });
  };

  const getCurrentLanguage = () => {
    let lang = "en";
    if (props.lang) {
      lang = props.lang;
    } else if (props.country) {
      lang = getLanguageFromCountry(props.country);
    }
    return lookupLanguage(lang);
  };

  const navUrl = router?.asPath;
  const staticHeader =
    navUrl === "/sign_in" || navUrl === "/members/invite_dependents";
  const extraHeaderSpacing = navUrl === "/members/choose_user";

  const scroll = useDetectScrollTop();

  const links = props.hideLinks ? (
    <HelpLink />
  ) : props.useRedesign ? (
    <RedesignLinks />
  ) : (
    <RightLinks />
  );
  const enableLanguageToggle = !router.asPath.includes("sign_in");
  return (
    <PageBase allowed={props.allowed} darkBg={props.darkBg}>
      <header>
        <Navigation
          logoWrapper={LogoWrapper}
          right={links}
          enableLanguageToggle={enableLanguageToggle}
          handleLanguageChange={setLanguage}
          defaultLanguage={getCurrentLanguage()}
          languageSelectAriaLabel={t("select.language")}
          languageIconAriaLabel={t("icons.languageAlt")}
          onScroll={staticHeader ? true : scroll} // log in page always has white header on
        />
      </header>
      {process.env.HIDE_ENV_DETAILS ? null : (
        <EnvDetails
          environment={process.env.APP_ENV}
          apiUrl={process.env.APP_API}
          authApiUrl={process.env.AUTH_API_DOMAIN}
          compassUrl={process.env.COMPASS_URL}
        />
      )}

      <div className={extraHeaderSpacing ? styles.pageContent : ""}>
        {props.showFoliage && (
          <BackgroundWrapper query={router.query} pathname={router.pathname} />
        )}
        <Wrapper full={props.full}>
          <div
            id="main-content"
            tabIndex="-1"
            className={styles.landingPageMain}
          >
            {props.children}
          </div>
        </Wrapper>
      </div>
    </PageBase>
  );
};

Landing.propTypes = {
  allowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.any,
  country: PropTypes.any,
  darkBg: PropTypes.any,
  full: PropTypes.bool,
  hideLinks: PropTypes.any,
  lang: PropTypes.any,
  localeCountry: PropTypes.func,
  showFoliage: PropTypes.any,
  useRedesign: PropTypes.any,
};

Landing.defaultProps = {
  allowed: true,
  full: false,
};

export { Landing };
export default compose(
  connect(
    (state) => ({
      isLoggedIn: state.auth.isLoggedIn,
      lang: state.global?.lang,
      country: state.global?.country,
    }),
    { localeCountry },
  ),
)(Landing);
