import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const createCheckIn = gql`
  mutation CreateCheckIn($member_id: ID!, $campaign: CampaignInput) {
    createCheckIn(member_id: $member_id, campaign: $campaign) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default createCheckIn;
