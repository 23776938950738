export const tabTypes = {
  THERAPY: "therapyVisits",
  COACH: "coachesVisit",
  MOMENTS: "selfGuidedExercises",
  CRISIS: "crisisSupport",
  WORK_LIFE_SUPPORT: "EAPBenefit",
  DEPENDENT_ACCESS: "dependentAccess",
};

export const benefitsPageAnalyticsMapping = {
  [tabTypes.THERAPY]: "Therapy",
  [tabTypes.COACH]: "Coaching",
  [tabTypes.MOMENTS]: "Moments",
  [tabTypes.CRISIS]: "Crisis Support",
  [tabTypes.WORK_LIFE_SUPPORT]: "Work Life Support",
  [tabTypes.DEPENDENT_ACCESS]: "Dependent Access",
};
