import { gql } from "@apollo/client";

const getAssessmentRiskLevels = gql`
  query getAssessments($member_id: ID, $limit: Int = 30) {
    assessments(member_id: $member_id, limit: $limit) {
      data {
        id
        risk
      }
    }
  }
`;

export default getAssessmentRiskLevels;
