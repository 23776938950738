import { gql } from "@apollo/client";

const getSettings = gql`
  query getSettings($limit: Int = 30, $offset: Int = 0) {
    settings(limit: $limit, offset: $offset) {
      data {
        id
        name
        value
      }
    }
  }
`;

export default getSettings;
