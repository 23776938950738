//List of customer IDs for use throughout cherrim
export const BoeingCustomerID = "f1384746-ff63-4ab6-86b3-a592c79ff8c5";
export const BoeingTestID = "1f71c63a-aa91-4c36-8f98-13dd1ffe47bf";
export const BoeingTestStagingID = "7be9f873-c6a1-492a-968c-f75d251950c2";
export const TargetCustomerID = "b4df5012-d96b-4d92-8d42-1fcdc79692ed";
export const DeltaCustomerID = "8bf5c6a8-c78f-4c11-8634-be35259d00da";
export const DeltaTestID = "330a62e9-2f7d-438b-b352-51133dd953dc";
export const TargetTestID = "5709ccf0-8943-494d-b992-a7702a99d798";
export const PfizerCustomerID = "b3243336-1793-466a-ac31-818f54e15c9d";
export const PfizerTestID = "fe7f91b6-a738-40b0-be19-ef1f71737b2d";

export const isBoeing = (customerId) =>
  customerId === BoeingCustomerID ||
  customerId === BoeingTestID ||
  customerId === BoeingTestStagingID;

export const isPfizer = (customerId) =>
  customerId === PfizerCustomerID || customerId === PfizerTestID;
