import Router from "next/router";
import React, { useEffect, useRef, useState } from "react";

import { Badge, Card } from "@springcare/sh-component-library";
import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { VExternalLinkIcon } from "@springcare/verdant-icons-react";
import { useInViewportOnce } from "hooks/useInViewport";
import { TRACK_EVENT } from "utils/mixpanel";

type SHCareCardProps = {
  badgeText?: string;
  buttonTrackingOptions?: object;
  buttonTrackingText: string;
  cardSubtitle: string;
  cardTitle: string;
  componentTrackingOptions?: object;
  componentTrackingText: string;
  dataCy?: string;
  externalUrl?: string;
  imageAlt?: string;
  imageUrl: string;
  onClick?: () => void;
  onClickTrackingText?: string;
  route?: {
    to: string;
    as: string;
  };
  tabIndex?: number;
  testId?: string;
  width?: string[];
};

export const SHCareCard = ({
  badgeText,
  buttonTrackingOptions,
  buttonTrackingText,
  cardSubtitle,
  cardTitle,
  componentTrackingOptions,
  componentTrackingText,
  dataCy,
  externalUrl,
  imageAlt,
  imageUrl,
  onClick,
  onClickTrackingText,
  route,
  tabIndex,
  testId,
  width = ["336px"],
}: SHCareCardProps) => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [viewedOnce, setViewedOnce] = useState(false);

  const showBadge = Boolean(badgeText);
  const showExternalLinkIcon = Boolean(externalUrl);

  useEffect(() => {
    if (inViewport && !viewedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        componentTrackingText,
        componentTrackingOptions,
      );
      setViewedOnce(true);
    }
  }, [inViewport, viewedOnce]);

  const handleCardClick = () => {
    const linkTrackingText =
      route?.as || externalUrl || onClickTrackingText || "Unknown";

    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      linkTrackingText,
      buttonTrackingText,
      buttonTrackingOptions,
    );

    if (externalUrl) {
      window.open(externalUrl, "_blank", "noopener noreferrer");
    } else if (route) {
      Router.push(route.to, route.as);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <Card
      bg="background-subtle"
      borderColor="border-subtle"
      borderRadius={16}
      boxShadow="none"
      cursor="pointer"
      data-cy={dataCy}
      data-testid={testId}
      h="100%"
      onClick={handleCardClick}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          handleCardClick();
        }
      }}
      p={8}
      pb={12}
      ref={ref}
      w={width}
      tabIndex={tabIndex}
    >
      <Flex flexDirection="column" h="100%" w="100%">
        <Image
          alt={imageAlt}
          borderRadius={12}
          h={168}
          objectFit="cover"
          src={imageUrl}
        />
        {showBadge && (
          <Box h={40} py={8}>
            <Badge colorScheme="positive" data-testid="badge">
              {badgeText}
            </Badge>
          </Box>
        )}
        <Flex
          align="center"
          direction="row"
          justify="space-between"
          my={8}
          px={4}
          width="100%"
        >
          <Text color="content-primary" size="body-medium-strong">
            {cardTitle}
          </Text>
          {showExternalLinkIcon && (
            <Icon
              as={VExternalLinkIcon}
              color="content-secondary"
              data-testid="external-link-icon"
            />
          )}
        </Flex>
        <Text ml={4} color="content-secondary" textAlign="start">
          {cardSubtitle}
        </Text>
      </Flex>
    </Card>
  );
};
