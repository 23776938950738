import { useTranslation, Trans } from "react-i18next";
import { VanillaCelebration } from "design-system/assets";
import { Avatar, Badge } from "@springcare/sh-component-library";
import {
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { VCheckIcon } from "@springcare/verdant-icons-react";
import ButtonSection from "./ButtonSection";

interface ContentSectionProps {
  handleButtonClick: () => void;
  handleSkipButtonClick: () => void;
  hasAgedOutRecord: boolean;
  isCoachingEnabled: boolean;
  isMobile: boolean;
  lang: string;
  memberName: string;
  showSpringPreview: boolean;
  updateUserLangLoading: boolean;
  selectedOption?: string;
}

interface BulletPointProps {
  bulletKey: string;
  index: number;
}

const ContentSection = ({
  handleButtonClick,
  handleSkipButtonClick,
  hasAgedOutRecord,
  isCoachingEnabled,
  isMobile,
  lang,
  memberName,
  showSpringPreview,
  updateUserLangLoading,
  selectedOption,
}: ContentSectionProps) => {
  const { t } = useTranslation("membership");
  const showPersonalizedTitle = !!memberName && lang === "en";

  const headingText = (): string => {
    if (hasAgedOutRecord) {
      return t("enhancedExpectations.agingOut.heading");
    }
    if (showSpringPreview) {
      return t("enhancedExpectations.previewSpringOfferings.heading");
    }
    if (showPersonalizedTitle) {
      return t("enhancedExpectations.personalizedTitle", {
        name: memberName,
      });
    }
    return t("enhancedExpectations.title");
  };

  const bulletText = (bulletKey: string): string => {
    const upcasedKey = bulletKey.charAt(0).toUpperCase() + bulletKey.slice(1);
    const suffix =
      showSpringPreview || hasAgedOutRecord
        ? `bulletPoint${upcasedKey}`
        : `subtitle.${bulletKey}`;

    if (hasAgedOutRecord) {
      return t(`enhancedExpectations.agingOut.${suffix}`);
    }
    if (showSpringPreview) {
      return bulletKey === "two" && !isCoachingEnabled
        ? t(`enhancedExpectations.previewSpringOfferings.${suffix}NoCoaching`)
        : t(`enhancedExpectations.previewSpringOfferings.${suffix}`);
    }
    return t(`enhancedExpectations.${suffix}`);
  };

  const BulletPoint = ({ bulletKey, index }: BulletPointProps) => {
    const avatarProps =
      showSpringPreview && !hasAgedOutRecord
        ? { icon: VCheckIcon }
        : { name: String(index + 1) };

    const padY = showSpringPreview ? 2 : 8;

    return (
      <ListItem key={index}>
        <Flex pb={padY} pt={index === 0 ? 0 : padY} alignItems="center">
          <Avatar {...avatarProps} />
          <Trans i18nKey={`enhancedExpectations.subtitle.${bulletKey}`}>
            <Text
              ml="v-16"
              lineHeight={["v-24", "initial"]}
              fontSize={["v-16", "initial"]}
            >
              {bulletText(bulletKey)}
            </Text>
          </Trans>
        </Flex>
      </ListItem>
    );
  };

  return (
    <VStack
      w={["100%", "80%", "75%", "50%"]}
      flex="2"
      align="flex-start"
      ml={showSpringPreview ? [0, 0, "v-12", "v-24"] : undefined}
      mb={showSpringPreview && isMobile ? 100 : 0}
      pl={["v-24", "initial"]}
      pr="v-24"
    >
      {!showSpringPreview && <VanillaCelebration height={80} width={80} />}

      <Heading
        as="h1"
        variant="md_v1"
        mb={showSpringPreview ? 0 : 8}
        data-cy="begin-assessment-heading"
        data-testid="enhanced-member-expectations-heading"
      >
        {headingText()}
      </Heading>

      {hasAgedOutRecord && (
        <Text size="body-medium-regular" mb={8}>
          {t("enhancedExpectations.agingOut.subHeading")}
        </Text>
      )}

      {showSpringPreview && (
        <Text size="body-medium-regular" color="content-secondary" pb="v-16">
          {t("enhancedExpectations.previewSpringOfferings.subHeading")}
        </Text>
      )}

      {!showSpringPreview && (
        <Badge pl="v-8" pr="v-8" w={85} mb="v-16" bg="platform.light">
          {t("enhancedExpectations.completionEstimate")}
        </Badge>
      )}

      <OrderedList
        styleType="none"
        ml={[0, "initial"]}
        pt={showSpringPreview ? 0 : 16}
      >
        {["one", "two", "three"].map((bulletKey, index) => (
          <BulletPoint key={index} bulletKey={bulletKey} index={index} />
        ))}
      </OrderedList>

      <ButtonSection
        handleButtonClick={handleButtonClick}
        handleSkipButtonClick={handleSkipButtonClick}
        isMobile={isMobile}
        updateUserLangLoading={updateUserLangLoading}
        showSpringPreview={showSpringPreview}
        selectedOption={selectedOption}
      />
    </VStack>
  );
};

export default ContentSection;
