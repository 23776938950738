/**
 * Enums used to determine Assessment Kinds
 */

const AssessmentKind = Object.freeze({
	InitialAssessment    	: 'INITIAL_ASSESSMENT',
	ConditionsCheckIn     : 'CONDITIONS_CHECK_IN',
	ConditionsScreening   : 'CONDITIONS_SCREENING',
	ProgressUpdate        : 'PROGRESS_UPDATE',
	SPRASS                : 'SPRASS',
	GoalCheckIn  	        : 'GOAL_CHECK_IN',
	TeenCheckIn           : 'TEEN_CHECK_IN',
	TeenInitialAssessment : 'TEEN_INITIAL_ASSESSMENT',
});

const getAssessmentKindForTracking = (assessmentKindEnum) => ({
	[AssessmentKind.InitialAssessment]     : 'Initial Assessment',
	[AssessmentKind.ConditionsCheckIn]     : 'Conditions Check In',
	[AssessmentKind.ConditionsScreening]   : 'Conditions Screening',
	[AssessmentKind.ProgressUpdate]        : 'Progress Update',
	[AssessmentKind.SPRASS]                : 'SPRASS',
	[AssessmentKind.GoalCheckIn]           : 'Goal Check In',
	[AssessmentKind.TeenCheckIn]           : 'Teen Check In',
	[AssessmentKind.TeenInitialAssessment] : 'Teen Initial Assessment',
})[assessmentKindEnum];

module.exports = { 
	AssessmentKind, 
	getAssessmentKindForTracking, 
};