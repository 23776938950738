// 

import React from 'react';
import classnames from 'classnames';
import { useToggle } from '@spring/hooks';

import Icon from '../../atoms/Icon';
import HorizontalRule from '../../atoms/HorizontalRule';
import Accordion from '../../layout/Accordion';
import Section from '../../layout/Section';

import styles from './styles.module.scss';


const AccordionPanel = (props) => {
	const [ open, toggle ] = useToggle(props.defaultOpen);
	const iconStyle = props.useRedesign ? styles.iconRedesign : styles.icon;
	const toggleStyle = props.useRedesign ? styles.toggleRedesign : styles.toggle;

	return (
		<Section>
			<div
				data-cy={props.dataCy ? `${props.dataCy}-filter` : ''}
				className={classnames(toggleStyle, {
					[styles.openToggle]: open && props.useRedesign,
				})}
				onClick={toggle}
			>
				<div
					className={styles.title}
					data-cy={props.dataCy ? props.dataCy + '-' + props.title : ''}
				>
					{props.title}
				</div>
				<Icon
					type={props.icon}
					className={classnames(iconStyle, {
						[styles.open]: open,
					})}
				/>
			</div>

			{!props.useRedesign && <HorizontalRule />}

			<Section size="sm">
				<Accordion open={open} allowOverflow={props.allowOverflow}>
					{props.children}
				</Accordion>
			</Section>
		</Section>
	);
};

AccordionPanel.defaultProps = {
	icon  : 'chevron-down',
	title : 'Open',
};

export default AccordionPanel;
