import { useTranslation } from "react-i18next";
import { Flex, Box, Text, useMediaQuery } from "@chakra-ui/react";
import {
  VDayIcon,
  VSelfAwarenessIcon,
  VSleepIcon,
} from "@springcare/verdant-icons-react";
import { TimeSlot } from "modules/MemberDashboard/Scheduling/components";
import type {
  TimeSlotType,
  AppointmentTimeSlotsType,
} from "modules/MemberDashboard/Scheduling/types";

type TimeSlotGroupPropType = {
  groupedAppointments: AppointmentTimeSlotsType;
  handleTimeSlotClick: (slot: TimeSlotType) => void;
  type: keyof typeof ICON_MAP;
  selectedTimeSlot: TimeSlotType;
};
const ICON_MAP = {
  morning: VSelfAwarenessIcon,
  afternoon: VDayIcon,
  evening: VSleepIcon,
} as const;

export const TimeSlotGroup = ({
  groupedAppointments,
  handleTimeSlotClick,
  selectedTimeSlot,
  type,
}: TimeSlotGroupPropType) => {
  const { t } = useTranslation("scheduling", { keyPrefix: "timePicker" });
  const TimeSlotIcon = ICON_MAP[type];

  if (groupedAppointments.length === 0) {
    return null;
  }

  return (
    <Box mb={{ base: 16, lg: 40 }}>
      <Flex align="center" mb={8}>
        <TimeSlotIcon />
        <Text size="body-medium-strong" ml={8}>
          {t(type)}
        </Text>
      </Flex>
      <Flex
        direction={{ base: "row", lg: "column" }}
        w="100%"
        flexWrap={{ base: "wrap", lg: "nowrap" }}
      >
        {groupedAppointments.map((slot) => (
          <TimeSlot
            key={slot.timestamp}
            slot={slot}
            handleTimeSlotClick={handleTimeSlotClick}
            selectedTimeSlot={selectedTimeSlot}
          />
        ))}
      </Flex>
    </Box>
  );
};
