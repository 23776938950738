import yup from "schemas/yup";

const getSchema = (t) => {
  return yup.object().shape({
    first_name: yup.string().min(1).required(),
    last_name: yup.string().min(1).required(),
    email: yup.string().email().required(),
    phone: yup
      .object()
      .shape({
        code: yup.string(),
        country: yup.string(),
        number: yup
          .string()
          .when("code", {
            is: "us",
            then: yup
              .string()
              .min(
                10,
                t(
                  "limitedLangAuth:manualVerificationForm.phoneNumberVerify.exactly10Digits",
                ),
              )
              .max(
                10,
                t(
                  "limitedLangAuth:manualVerificationForm.phoneNumberVerify.exactly10Digits",
                ),
              )
              .matches(
                /^[0-9]+$/,
                t(
                  "limitedLangAuth:manualVerificationForm.phoneNumberVerify.numericOnly",
                ),
              ),
          })
          .matches(
            /^[0-9]+$/,
            t(
              "limitedLangAuth:manualVerificationForm.phoneNumberVerify.numericOnly",
            ),
          )
          .min(
            4,
            t(
              "limitedLangAuth:manualVerificationForm.phoneNumberVerify.atLeast4Digits",
            ),
          )
          .required(),
      })
      .required(),
  });
};

export default getSchema;
