import { useMemberInfo } from "hooks";
import { returnTherapyCoveredAndRemaining } from "modules/shared/utils";

export const useTeenTherapyVisitsCovered = () => {
  const { data: memberData, loading } = useMemberInfo();
  const { coveredTherapy: therapySessionsCovered } =
    returnTherapyCoveredAndRemaining(memberData?.user?.member);

  return { therapySessionsCovered, loading };
};
