//
import PropTypes from "prop-types";
import growlithe from "constants/growlithe";

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { DateTime } from "luxon";
import { saveAs } from "file-saver";
import {
  Backdrop,
  Section,
  Table,
  LinkButton,
  Subtitle,
} from "@spring/smeargle";
import { timeFormats } from "@spring/constants";
import Meowth from "@spring/meowth";

import { getAssessments } from "operations/queries/assessment";

const DownloadButton = ({ id, row, download }) => {
  return (
    <LinkButton
      text="Show Report"
      onClick={() => {
        const taken = DateTime.fromISO(row.ended_at).toFormat(
          timeFormats.dateLongform,
        );

        download("reports/assessment", {
          window: false,
          download: true,
          filename: `Spring ${row.kind} -- ${taken}`,
          key: `${id}.pdf`,
          headers: Meowth.getAuthorizationHeader(),
          onDownload: (blob) => saveAs(blob, `${id}.pdf`),
        });
      }}
    />
  );
};

DownloadButton.propTypes = {
  download: PropTypes.func,
  id: PropTypes.any,
  row: PropTypes.shape({
    ended_at: PropTypes.any,
    kind: PropTypes.any,
  }),
};
const AssessmentHistoryTable = (props) => {
  if (props.data.loading || props.data.error) {
    return null;
  }

  return (
    <Section>
      <Backdrop color="grey">
        <Subtitle noMargin>Assessments</Subtitle>
      </Backdrop>

      <Table
        tableKey="AssessmentHistory"
        data={props.data.assessments.data}
        keys={{
          ended_at: "taken",
          status: "status",
          questionnaires_order: "questionnaires",
          kind: "kind",
          id: "download",
        }}
        hideAt={{
          md: ["status", "kind"],
        }}
        mutators={{
          ended_at: (dt) =>
            dt
              ? DateTime.fromISO(dt).toFormat(timeFormats.dateLongform)
              : "In Progress",
          questionnaires_order: (q) => q.length.toString(),
          id: (id, row) => {
            // eslint-disable-line react/display-name
            if (row.status === "Finished") {
              return (
                <DownloadButton id={id} row={row} download={props.download} />
              );
            }

            return <div />;
          },
        }}
      />
    </Section>
  );
};

AssessmentHistoryTable.propTypes = {
  data: PropTypes.shape({
    assessments: PropTypes.shape({
      data: PropTypes.any,
    }),
    error: PropTypes.any,
    loading: PropTypes.any,
  }),
  download: PropTypes.any,
};

export { AssessmentHistoryTable };
export default compose(
  connect(null, { download: growlithe.download }),
  graphql(getAssessments, {
    options: (ownProps) => ({
      variables: {
        member_id: ownProps.memberId,
        status: undefined,
        limit: undefined,
      },
    }),
  }),
)(AssessmentHistoryTable);
