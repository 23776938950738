import { DateTime } from "luxon";

export const getAccountTransitionDate = (dateOfBirth: string) => {
  const currentYear = new Date().getFullYear();
  const dateValues = dateOfBirth.split("/");
  const transitionDate = `${dateValues[0]}/${dateValues[1]}/${currentYear}`;
  const date = DateTime.fromFormat(transitionDate, "MM/dd/yyyy");
  const formattedDate = date.toLocaleString(DateTime.DATE_FULL);

  return formattedDate;
};
