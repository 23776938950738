import {
  UPDATE_PRE_ASSESSED_EXPERIENCE_MODEL,
  RESET_PRE_ASSESSED_EXPERIENCE_MODEL,
} from "./types";

export function updatePreAssessedExperienceModel(payload) {
  return {
    type: UPDATE_PRE_ASSESSED_EXPERIENCE_MODEL,
    payload,
  };
}

export function resetPreAssessedExperienceModel() {
  return {
    type: RESET_PRE_ASSESSED_EXPERIENCE_MODEL,
  };
}
