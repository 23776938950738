import { ActionChip } from "@springcare/sh-component-library";
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { VPlayIcon } from "@springcare/verdant-icons-react";

import { useTranslation } from "hooks/react-i18next";

import dynamic from "next/dynamic";

const DynamicVideoPlayer = dynamic(
  () => import("./VideoPlayer").then((m) => m.default),
  {
    ssr: false,
    loading: () => (
      <Flex justifyContent="center" alignItems="center" p="v-32">
        <Spinner speed="1s" size="xl" />
      </Flex>
    ),
  },
);

interface ProfileVideoPlayButtonProps {
  name: string;
  videoUrl: string;
}

const ProfileVideoPlayButton = ({
  name,
  videoUrl,
}: ProfileVideoPlayButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation("careProvider");

  const handleVideoOpen = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      <ActionChip
        icon={VPlayIcon}
        variant="medium-emphasis"
        colorScheme="positive"
        position="absolute"
        top="100%"
        left="50%"
        backgroundColor="border-subtle"
        fontSize="v-14"
        transform="translate(-50%, -50%)"
        onClick={handleVideoOpen}
        aria-label={t("profileVideoPlayer.actionChipAriaLabel", {
          // @ts-ignore
          providerName: name,
        })}
        data-testid="profile-video-play-button"
      >
        {t("profileVideoPlayer.actionChipText")}
      </ActionChip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        isCentered
        aria-label="Video player"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            sx={{
              _focus: {
                boxShadow: "none", // Removes the focus box shadow
              },
            }}
          />
          <Box p={16} pt="48px">
            <Heading
              as="h2"
              fontSize={21}
              fontWeight={600}
              variant="md_v1"
              mb="v-12"
            >
              {
                // @ts-ignore
                t("profileVideoPlayer.modalHeading", { providerName: name })
              }
            </Heading>
            <DynamicVideoPlayer videoUrl={videoUrl} />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileVideoPlayButton;
