import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Reader } from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";

import {
  EnableMfaHeading,
  EnableMfaStepCard,
  MfaPhoneNumberForm,
  VerifyPinForm,
  MfaContext,
} from "components";
import { useMfaSetup } from "hooks";

const stepTwoDescription = (t) => ({
  sms: `${t("byPhoneStepTwoSubtitle")} ${t("byPhoneStepTwoSubtitleSMS")}.`,
  voice: `${t("byPhoneStepTwoSubtitle")} ${t("byPhoneStepTwoSubtitleVoice")}.`,
});

const MfaByPhone = (props) => {
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });
  const { setPage, routeToBackupCodes } = useContext(MfaContext);
  const { registerMFADevice, setBackupCodesToStorage } = useMfaSetup();

  const { type } = props;

  const onSubmitPhoneNumber = async (values) => {
    const { phone } = values;

    try {
      await registerMFADevice(phone, type === "voice");
    } catch (_err) {
      // TODO: Log the error to a service
      const errorMessage = t("errorRegisterMfaDevice");
      props.addNotification(errorMessage, "error");
    }
  };

  const onSubmitVerificationCode = async (values) => {
    try {
      await setBackupCodesToStorage(values);
      routeToBackupCodes();
    } catch (e) {
      props.addNotification(e.message, "error");
    }
  };

  return (
    <Reader>
      <EnableMfaHeading onClick={() => setPage("options")} mfaOption={type} />
      <EnableMfaStepCard
        stepNumber={1}
        stepTitle={t("byPhoneStepOneTitle")}
        stepDescription={t("byPhoneStepOneSubtitle")}
        mb={24}
      >
        <MfaPhoneNumberForm onSubmit={onSubmitPhoneNumber} />
      </EnableMfaStepCard>
      <EnableMfaStepCard
        stepNumber={2}
        stepTitle={t("byPhoneStepTwoTitle")}
        stepDescription={stepTwoDescription(t)[type]}
      >
        <VerifyPinForm
          onSubmit={onSubmitVerificationCode}
          formLabel={t("submitPinLabel")}
          formSubmit={t("submitPinButton")}
          pinInputWidth={"44px"}
          pinInputHeight={{ base: "43px", md: "48px" }}
        />
      </EnableMfaStepCard>
    </Reader>
  );
};

MfaByPhone.propTypes = {
  addNotification: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["sms", "voice"]).isRequired,
};

export default connect(null, { addNotification })(MfaByPhone);
