import { gql } from "@apollo/client";
import { money } from "modules/MemberDashboard/Billing/graphql/fragments";

export const getEncounterCostEstimate = gql`
  query getEncounterCostEstimate(
    $member_id: ID!
    $cpt_code: String!
    $cpt_code_modifier: String
    $date_of_service: Date!
  ) {
    encounter_cost_estimate(
      member_id: $member_id
      cpt_code: $cpt_code
      cpt_code_modifier: $cpt_code_modifier
      date_of_service: $date_of_service
    ) {
      insurance {
        coverage_active
        coverage_start
        coverage_end
        payer_id
        payer_name
        insurance_member_id
        insurance_group_id
        plan_description
      }
      estimate {
        billed_amount {
          ...money
        }
        coinsurance_estimate_percentage
        copay_amount {
          ...money
        }
        individual_deductible_amount {
          ...money
        }
        individual_oop_amount {
          ...money
        }
        patient_responsibility_amount {
          ...money
        }
        covered_by_insurance_amount {
          ...money
        }
        remaining_individual_deductible_amount {
          ...money
        }
        remaining_individual_oop_amount {
          ...money
        }
        spent_individual_deductible_amount {
          ...money
        }
        spent_individual_oop_amount {
          ...money
        }
      }
      updated_at
    }
  }
  ${money}
`;
