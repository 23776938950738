import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { getMemberInsurance } from "operations/queries/member";

export function useMemberInsurance() {
  return useQuery(getMemberInsurance, {
    ...Meowth.apolloOptionsUserId(),
  });
}
