// 

import { OPEN_MODAL, CLOSE_MODAL, ADD_OVERLAY, REMOVE_OVERLAY_BY_ID, REMOVE_FIRST_OVERLAY } from './types';

export function openModal(id, data) {
	return {
		type: OPEN_MODAL,
		id,
		data,
	};
}

export function closeModal(id) {
	return {
		type: CLOSE_MODAL,
		id,
	};
}

export function addOverlay(id) {
	return {
		type: ADD_OVERLAY,
		id,
	};
}

export function removeOverlayById(id) {
	return {
		type: REMOVE_OVERLAY_BY_ID,
		id,
	};
}

export function removeFirstOverlay() {
	return {
		type: REMOVE_FIRST_OVERLAY,
	};
}