// 

import React from 'react';

import Select from '../Select';



const options = [
	{ value: 'Woman', label: 'Woman' },
	{ value: 'Man', label: 'Man' },
	{ value: 'Non-Binary', label: 'Non-Binary' },
	{ value: 'Unknown', label: 'I prefer not to answer' },
];

const GenderSelect = (props) => (
	<Select
		{...props}
		options={options}
	/>
);

GenderSelect.defaultProps = {
	disabled    : false,
	placeholder : 'Please Select Your Gender',
	label       : 'Gender',
	type        : 'text',
};

export default GenderSelect;
