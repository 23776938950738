export const TEEN_HOMEPAGE_SECTIONS = {
  greeting: "MemberGreeting",
  findTherapistHero: "FindTherapistHeroBanner",
  takeAssessmentHero: "TakeAssessmentHeroBanner",
  upNext: "TeenUpNextSection",
  yourCare: "TeenYourCareSection",
  faq: "FAQSection",
  moments: "MomentsSection",
  alsoAvailable: "TeenAlsoAvailableSection",
};

// These moment IDs are listed here for the sole purpose of matching the correct icon to Moment
// The BE is the source of truth for which Moments will be shown
export const TEEN_RECOMMENDED_MOMENTS_IDS = {
  selfEsteem: "b8d0e65f-f476-4e4e-9e92-6d7df3afb4e9",
  relationshipEnd: "5e0bcada-506a-45a4-a32e-e71c6c753c0a",
  difficultEmotions: "d815362c-bff5-41df-a5cb-4be23bfe66fb",
  relaxingMuscles: "760913d0-e3bc-4ded-92a7-6984973a3d97",
};
