import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { DateTime } from "luxon";
import { logout } from "actions/auth";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import useSyncPreviousValue from "hooks/useSyncPreviousValue";

export const maintenanceViewPath = "/maintenance";
export const maintenanceCheckPath = "/api/maintenance";

/**
 * The scheduled outage is set for Saturday, April 1st from 12:00am-1:00am PDT /
 * 3:00am-4:00am EDT. Start polling an hour before and an hour after in order to toggle
 * maintenance mode on or off. We also leverage a flag for added manual controls
 */
const SCHEDULED_MAINTENANCE_DATETIME_START = "2023-04-01T07:00:00.000Z";
const SCHEDULED_MAINTENANCE_DATETIME_END = "2023-04-01T08:00:00.000Z";

const shouldStartPolling = (now) =>
  now >=
    DateTime.fromISO(SCHEDULED_MAINTENANCE_DATETIME_START, {
      setZone: true,
    }).minus({ hours: 1 }) &&
  now <=
    DateTime.fromISO(SCHEDULED_MAINTENANCE_DATETIME_END, {
      setZone: true,
    }).plus({ hours: 1 });

/**
 * Poll our custom NextJS server for the MAINTENANCE_MODE env and handle redirections as needed.
 * This is necessary since there are user actions that don't interact with the server at all
 * (e.g., leaving browsers open, using client-side routing, making GQL requests, etc.), otherwise
 * the routing to in and out of /maintenance could be handled by the server itself.
 */
export default function useMaintenanceMode() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const router = useRouter();
  const shouldPollViaFlag = useFeatureFlag(FLAGS.MAINTENANCE_MODE);

  const checkForMaintenanceMode = (shouldPollViaFlag) => {
    const now = DateTime.local();

    if (shouldStartPolling(now) || shouldPollViaFlag) {
      fetch(maintenanceCheckPath)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setIsMaintenanceMode(!!data?.maintenanceMode);
        })
        .catch((e) => {
          if (e.message === "Failed to fetch") {
            // Assume that the NextJS server is unavailable because it's restarting. Either way,
            // we clear the Diglet session. This will redirect to /sign_in before redirecting to
            // /maintenance
            logout();
          } else {
            // Otherwise, log the error for the time being
            // eslint-disable-next-line no-console
            console.error("Error checking for maintenance mode: ", e);
          }
        });
    }
  };

  useEffect(() => {
    const id = setInterval(
      () => checkForMaintenanceMode(shouldPollViaFlag),
      5000,
    );

    return () => clearInterval(id);
  }, [shouldPollViaFlag]);

  const prevMaintenanceMode = useSyncPreviousValue(isMaintenanceMode);

  useEffect(() => {
    if (
      isMaintenanceMode &&
      (!prevMaintenanceMode || router.asPath !== maintenanceViewPath)
    ) {
      // If we're transitioning into maintenance mode or we're already in it and the current
      // path isn't /maintenance, send users there.
      router.push(maintenanceViewPath);
    }

    // Sometimes, the maintenance page gets stuck on the "Oops" page, presumably due to bad timing
    // between polling for the maintenance command and redirecting.
    if (
      isMaintenanceMode &&
      prevMaintenanceMode &&
      router.asPath === maintenanceViewPath
    ) {
      const errorPage = document.querySelector('[data-cy="error-page"]');

      if (errorPage) {
        router.reload();
      }
    }

    // If we're transitioning out of maintenance mode, clear session in Diglet again just in case
    // and redirect to /sign_in
    if (!isMaintenanceMode && prevMaintenanceMode) {
      logout();
    }
  }, [isMaintenanceMode, router.asPath]);
}
