import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "./LoadingSpinner";
import { useRouter } from "next/router";
import { useMfaSignIn } from "hooks";
import { isTeen } from "utils/memberHelpers";
import { ChooseUserPasswordDisplay } from "./ChooseUserPasswordDisplay";
import { LandingPageWrapper } from "components";
import {
  requestForgotPassword,
  getTargetMember,
  loginWithDependentKeyInLocalStorage,
  signInWithTempPassword,
  chooseUserSignInSubmit,
  managerIsAbleToAutoSignInAsDependent,
  getTemporaryPasswordForDependent,
} from "./utils/ChooseUserPassword.utils";

const ChooseUserPassword = () => {
  const router = useRouter();
  const targetId = router?.query?.id;
  const [password, setPassword] = useState("");
  const [checkedTargetMemberAuth, updateCheckedTargetMemberAuth] =
    useState(false);
  const [isAccountLocked, setIsAccountLocked] = useState(false);

  const { targetDependent, loading, targetManager } = getTargetMember(targetId);
  const { data: tempPassData, loading: tempPassLoading } =
    getTemporaryPasswordForDependent(targetId, targetDependent);
  const { isMFAAttempt, mfaLoginData, initiateMfaLogin } = useMfaSignIn();

  const memberDateOfBirth = targetDependent?.date_of_birth;
  const managersInitialLoginAsDependent =
    tempPassData && targetDependent && !isMFAAttempt && !mfaLoginData;
  const managersSubsequentLoginAsDependent =
    managerIsAbleToAutoSignInAsDependent(targetId);

  useEffect(() => {
    if (!loading) {
      loginWithDependentKeyInLocalStorage(
        updateCheckedTargetMemberAuth,
        targetId,
        memberDateOfBirth,
      );
    }
  }, [loading]);

  const canRenderChooseUserPasswordDisplay =
    !loading &&
    checkedTargetMemberAuth &&
    (targetDependent || targetManager) &&
    !tempPassLoading;

  if (!canRenderChooseUserPasswordDisplay) return <LoadingSpinner />;

  const { first_name, email } = targetDependent
    ? targetDependent
    : targetManager;

  if (managersSubsequentLoginAsDependent) return <LoadingSpinner />;

  if (managersInitialLoginAsDependent) {
    const tempPassword = tempPassData?.temporary_password.temporary_password;
    const memberHomeRouteAlias = isTeen(memberDateOfBirth)
      ? "TeenHome"
      : "MemberCareVisits";
    signInWithTempPassword(
      tempPassword,
      email,
      targetId,
      initiateMfaLogin,
      setIsAccountLocked,
      memberHomeRouteAlias,
    );
    if (!isAccountLocked) return <LoadingSpinner />;
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (e) => {
    chooseUserSignInSubmit(
      e,
      email,
      password,
      targetId,
      memberDateOfBirth,
      initiateMfaLogin,
      setIsAccountLocked,
    );
  };

  return (
    <LandingPageWrapper darkBg={false}>
      <ChooseUserPasswordDisplay
        isMFAAttempt={isMFAAttempt}
        mfaLoginData={mfaLoginData}
        initiateMfaLogin={initiateMfaLogin}
        first_name={first_name}
        targetDependent={targetDependent}
        isAccountLocked={isAccountLocked}
        handleSubmit={handleSubmit}
        handlePasswordChange={handlePasswordChange}
        requestForgotPassword={requestForgotPassword}
        email={email}
        password={password}
      />
    </LandingPageWrapper>
  );
};

export default ChooseUserPassword;
