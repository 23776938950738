import { Tooltip, useMediaQuery } from "design-system/components";

export const SHTooltip = (props) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  return (
    <Tooltip {...props} closeOnClick={!isMobile}>
      {props.children}
    </Tooltip>
  );
};
