//
import yup from "schemas/yup";

import { equalTo } from "./utils";

yup.addMethod(yup.string, "equalTo", equalTo);

const getSchema = (t) =>
  yup.object().shape({
    current_password: yup
      .string()
      .label(t ? t("labels.currentPassword") : "Current password")
      .min(8)
      .required(),
    password: yup
      .string()
      .label(t ? t("labels.newPassword") : "New password")
      .min(8)
      .required(),
    password_confirmation: yup
      .string()
      .label(t ? t("labels.passwordConfirmation") : "Password confirmation")
      .min(8)
      .equalTo(yup.ref("password"))
      .required(),
  });

const schema = getSchema;

export { getSchema };

export default schema;
