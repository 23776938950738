import { useFlags } from "launchdarkly-react-client-sdk";
export { useListenToFeatureFlag } from "./otherHooks";
// BEFORE CREATING A FLAG, READ FOR BEST PRACTICES:
// https://springhealth.atlassian.net/wiki/spaces/ENG/pages/476285436/Launch+Darkly+In+Arceus
// https://www.flagship.io/feature-flags-naming-conventions/
// https://docs.launchdarkly.com/guides/best-practices/creating-flags

// Flag naming convention:
// feature_{release/op/experiment/permission}_team_month_year{_temp, if temp}

// Do not use dots in the name, while allowed in LD,
// you cannot access flag with dots in the name in react/js
// We turned off auto camel casing on the provider, more here
// https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys

// Launch Darkly Up Time - Plan your default and failure states well:
// We cannot expect 100% uptime from launch darkly. Plan default values and error handling in implementation
// carefully to plan for cases when we may not be able to reach launch darkly to retrieve the flag value.

// WARNING: Launch Darkly will not have 100% uptime. We cannot guarantee a flag value is returned every time
// or that we will not receive the default flag value. Plan you default and failure states for flags and
// implementation very carefully. Think about, what happens if this flag value returns null, or the default
// specified? How will users be impacted? Manage and design for those cases.

// Type of flags:
// RELEASE: a flag used to roll out a release (enable or disable), will be temporary
// OP: operations flag used to turn on and off operations tooling, may or may not be temporary
// EXPERIMENT: A/B and other testing flags, will likely be temporary
// PERMISSION: (DO NOT USE) flag to control feature permissions, e.g. Only enterprise users have feature XYZ, will not be temporary
//         Warning: Permission flags carry risk. LD will not have 100% up time. We highly
//         discourage the use of permission flags. If one is used, precise planning and implementation will be needed
//         to manage user experience if a flag value cannot be resolved or a default flag value is returned.

// Usage eg:
// import { useFeatureFlag, FLAGS } from 'utils/launchdarkly/flags'
// const internationalization = useFeatureFlag(FLAGS.INTERNATIONALIZATION);

// For temporary flags, like release flags, it is considered best practice to post a branch with
// the flag removed so that flag cleanup is as easy as possible.

export type FlagDefinition = {
  name: string;
  description: string;
  default: boolean | string;
  values: string[];
  track: boolean;
  trackingName: string;
  showInMixpanel?: boolean;
  useFlag?: () => any;
};

type FlagMap = {
  [key: string]: FlagDefinition;
};

// usage:
// const flagValue = useFeatureFlag(FLAGS.TRAVEL_DISCLAIMER)
// WARNING: Like all hooks, useFeatureFlag can only be called in the root of a component. Do not call it inside conditionals or nested functions. https://reactjs.org/docs/hooks-rules.html
export function useFeatureFlag(flag: FlagDefinition) {
  try {
    const flagValue = useFlags()[flag.name];
    return typeof flagValue === "undefined" ? flag.default : flagValue;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error from Launch Darkly", err);
    return flag.default;
  }
}

export const FLAGS: FlagMap = {
  // TEMPLATE: {
  //  name         : 'feature_{release/op/experiment/permission}_team_month_year{_temp, if temp}',
  //  description  : 'Feature flag to allow enterprise customers access to enterprise features',
  //  default      : false,
  //  values       : ['true', 'false'],
  //  track        : false,
  //  trackingName : '(first letter each word of feature)_(team)(month)(year))',
  //  useFlag      : () => useFlags().<TEMPLATE.name>,
  // },
  // TEST: {
  //  name         : 'test_feature_release_mxo_12_21_temp',
  //  description  : 'Feature flag to test flagging wrapper',
  //  default      : false,
  //  values       : ['true', 'false'],
  //  track        : true,
  //  trackingName : 'tfr_mxo1221',
  //  useFlag      : () => useFlags().test_mxo_12_21_temp,
  // },
  TRAVEL_DISCLAIMER: {
    name: "provider_search_travel_permission_mxe_03_22",
    description:
      "Permissions flag allowing only JB Hunt and Jetblue members to see a notification under their address when browsing possible providers",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "pstp_mxe0322",
    useFlag: () => useFlags().provider_search_travel_permission_mxe_03_22,
  },
  MX_EXPERIMENT_FEATURES: {
    name: "experiment_features_mxe_06_2022",
    description: "Roll our features to 20% of users for beta customers",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "ef_mxe0622",
    useFlag: () => useFlags().mxExperiment,
  },
  COACHING_AVAILABLE_SECTION: {
    name: "new_homepage_coaching_available_section_release_11_7_temp",
    description:
      "Ability to turn off coaching order logic in whats available to me.",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "nhcasr_mx1122",
    useFlag: () =>
      useFlags().new_homepage_coaching_available_section_release_11_7_temp,
  },
  PROVIDER_REQUEST_AVAILABILITY_FORM: {
    name: "provider_availability_request_form_11_22_temp",
    description:
      "Flag allowing members to see the new request availability form in the browse providers page",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "parf_mxa1122",
  },
  SHOW_SUD_PROGRAM_PAGE: {
    name: "sud_program_page_01_23_temp",
    description:
      "Flag allowing members to see the new standalone sud experience page",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "spp_sud0123",
  },
  SHOW_DEDICATED_PROVIDER_FILTER: {
    name: "show_dedicated_provider_filter_cx_04_23_temp",
    description: "shows the dedicated provider filter",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "sdpf_cx0423",
    useFlag: () => useFlags().show_dedicated_provider_filter_cx_04_23_temp,
  },
  MAINTENANCE_MODE: {
    name: "maintenance_mode_release_pfrm_03_2023_temp",
    description: "Manually control maintenance polling",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "mmr_pfrm0323",
    useFlag: () => useFlags().maintenance_mode_release_pfrm_03_2023_temp,
  },
  THERAPY_TOP_SPECIALTIES: {
    name: "therapy_top_specialties_mxa_06_23_temp",
    description: "Show Top Specialties on Provider Card",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "tts_mxe0423",
    useFlag: () => useFlags().therapy_top_specialties_mxa_06_23_temp,
  },
  ZERO_TO_FIVE_ACCOUNTS: {
    name: "zero_to_5_accounts_release_04_24_temp",
    description: "Flag for rollout of 0-5 accounts",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "ztfa_fam0424",
    useFlag: () => useFlags().zero_to_5_accounts_release_04_24_temp,
  },
  PROVIDER_PROFILE_PAGE: {
    name: "provider_profile_page_mxa_07_27_temp",
    description: "Open new provider profile page in new tab",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "tts_mxe0423",
    useFlag: () => useFlags().provider_profile_page_mxa_07_27_temp,
  },
  MEMBER_PREFERENCES: {
    name: "member_preferences_mxa_08_23_temp",
    description: "Show member preference options on various pages/flows",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "tts_mxe0423",
    useFlag: () => useFlags().member_preferences_mxa_08_23_temp,
  },
  GEN_AI_RECO_EXPLANATIONS: {
    name: "gen_ai_reco_explanation_08_15_temp",
    description: "Show Generative AI explanations for recommendations",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "bbot_mxa0224",
    useFlag: () => useFlags().gen_ai_reco_explanation_08_15_temp,
  },
  SUD_MX_V2: {
    name: "sud_mx_p1_LOB_08_23_temp",
    description:
      "Flag for SUD work for MX and SUD conversion v2. True means new experience is shown.",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().sud_mx_p1_LOB_08_23_temp,
  },
  MP_QUESTIONNAIRE: {
    name: "member_preferences_questionnaire_mxa_09_06_temp",
    description: "Show member preferences questionnaire on recs page",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mpq_mxa0906",
    useFlag: () => useFlags().member_preferences_questionnaire_mxa_09_06_temp,
  },
  INFER_KINDS_CARE_PROVIDER_SCHEDULE: {
    name: "use_infer_kinds_cp_schedule_release_pfrm_09_12_temp",
    description:
      "Flag for initial and followup code simplification work. Will infer kind instead of passing in initial/followup kind.",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () =>
      useFlags().use_infer_kinds_cp_schedule_release_pfrm_09_12_temp,
  },
  LOB_COACHING_PATHWAYS_CTA: {
    name: "lob_coaching_pathways_CTA_09_2023_temp",
    description: "Flag for lob coaching pathways CTA",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().lob_coaching_pathways_CTA_09_2023_temp,
  },
  LOB_COACHING_ASSESSMENT_RESULTS_CTA: {
    name: "lob_coaching_assessmentresultsCTA_09_2023_temp",
    description: "Flag for lob coaching pathways CTA",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "carc_lob1023",
    useFlag: () => useFlags().lob_coaching_assessmentresultsCTA_09_2023_temp,
  },
  LOB_SUD_ASSESSMENT_RESULTS_CTA: {
    name: "lob_coaching_sud_assessment_resultsCTA_09_2023_temp",
    description: "Flag for lob SUD Assessment Primary CTA",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "sarc_lob1023",
    useFlag: () =>
      useFlags().lob_coaching_sud_assessment_resultsCTA_09_2023_temp,
  },
  TEEN_HOMEPAGE: {
    name: "teen_homepage_feature_flag_2023_10_2",
    description: "enables new teen homepage",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().teen_homepage_feature_flag_2023_10_2,
  },
  NEW_INSURANCE_MODAL: {
    name: "new_insurance_modal_release_mxre_05_10_temp",
    description: "Feature flag for new insurance modal",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "nim_mxre0510",
    useFlag: () => useFlags().new_insurance_modal_release_mxre_05_10_temp,
  },
  RECS_M2: {
    name: "recommendations_m2_mxa_11_06_temp",
    description:
      "Use new recs m2 algorithm to generate therapist recommendations",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "rm2a_mxa1106",
    useFlag: () => useFlags().recommendations_m2_mxa_11_06_temp,
  },
  ENABLE_ZOOM_FALLBACK_URL: {
    name: "enable_zoom_fallback_url_01_24_temp",
    description:
      "Feature flag to conditionally show fallback url for in house video feature",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().enable_zoom_fallback_url_01_24_temp,
  },
  ENABLE_ZOOM_FEEDBACK_URL: {
    name: "enable_zoom_feedback_url_11_23_temp",
    description:
      "Feature flag to conditionally show feedback url for in house video feature",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().enable_zoom_feedback_url_11_23_temp,
  },
  MEMBER_PAYMENT_COLLECTION_M1: {
    name: "member_payment_collection_m1_mxre_11_23_temp",
    description: "Feature flag for member payment collection",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mpcm1_mxre1123",
    useFlag: () => useFlags().member_payment_collection_m1_mxre_11_23_temp,
  },
  EVIDENCE_OF_COVERAGE: {
    name: "evidence_of_coverage_mxre_12_23_temp",
    description: "Evidence of coverage for California-based customers",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "eoc_mxre1223",
    useFlag: () => useFlags().evidence_of_coverage_mxre_12_23_temp,
  },
  IN_PERSON_GLOBAL: {
    name: "in_person_global_release_lob_12_23_temp",
    description: "Allow global members to book in person therapy",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().in_person_global_release_lob_12_23_temp,
  },
  COACH_NEW_BROWSE_RELEASE: {
    name: "coach_browse_v2_lob_12_28_temp",
    description: "Controls Browse v2 Visibility on Member Portal",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().coach_browse_v2_lob_12_28_temp,
  },
  ENABLE_ACCOUNT_LOCKED_BANNER: {
    name: "account_locked_banner_2023_12_14_temp",
    description: "Lock account after 10 failed sign in attempts (MOBILE-2423)",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().account_locked_banner_2023_12_14_temp,
  },
  BROWSE_RECS_V1: {
    name: "browse_recs_v1_mxa_01_24_temp",
    description: "Show recommendations in /browse therapist route v1",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "brecs_mxa0124",
    useFlag: () => useFlags().browse_recs_v1_mxa_01_24_temp,
  },
  IN_PERSON_MED_MANAGER_RELEASE: {
    name: "in_person_mm_mxa_02_01_temp",
    description: "Allow medication managers to book in person therapy",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().in_person_mm_mxa_02_01_temp,
  },
  ENABLE_AGING_OUT_P1: {
    name: "enable_aging_out_p1_mxfamily_02_24_temp",
    description:
      "Enable notifications prior to member aging out and adding prompts for updated consent, initial assessment, update settings for aged out member",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().enable_aging_out_p1_mxfamily_02_24_temp,
  },
  MEMBER_PAYMENT_FLOWS_RELEASE: {
    name: "member_payment_flows_release_mxr_02_24_temp",
    description:
      "Feature flag to control visibility of an upcoming payment method modal and wallet tab in /member/billing-and-payments. This will NOT control visibility/ access to auto-pay",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().member_payment_flows_release_mxr_02_24_temp,
  },
  MEMBER_AUTO_PAY_RELEASE: {
    name: "member_auto_pay_release_mxr_02_24_temp",
    description:
      "Feature flag to control visibility/ access to auto-payment controls for MPC M2",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().member_auto_pay_release_mxr_02_24_temp,
  },
  MEMBER_JOURNEY_HOMEPAGE_RELEASE: {
    name: "member_journey_homepage_release_mxr_02_2024_temp",
    description:
      "Feature flag to control visibility of journey graph on homepage",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mjhr_mfopx0224",
    useFlag: () => useFlags().member_journey_homepage_release_mxr_02_2024_temp,
  },
  AVAILABILITY_FILTER_RELEASE: {
    name: "availability_filter_mxa_0305_temp",
    description: "Shows/Hides new availability filters",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "av_mxa0305",
    useFlag: () => useFlags().availability_filter_mxa_0305_temp,
  },
  HIGHMARK_COST_TRANSPARENCY_COPY: {
    name: "highmark_cost_transparency_copy_mxr_03_24",
    description:
      "Feature flag to control visibility of cost transparency copy for Highmark members",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().highmark_cost_transparency_copy_mxr_03_24,
  },
  ENABLE_CONSENTS_WAITING_ROOM_LIVEKIT: {
    name: "livekit_waiting_room_w_consents_cgxp5524_temp",
    description: "New waiting room UI for LiveKit w/ consent functionality",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().livekit_waiting_room_w_consents_cgxp5524_temp,
  },
  LD_EXPERIMENTATION_POC: {
    name: "ld_experimentation_poc_mxo_04_24_temp",
    description: "LD Experimentation POC",
    default: false,
    values: ["holdout", "control", "treatment_1", "treatment_2"],
    track: true,
    trackingName: "ldep_mxo0424",
    useFlag: () => useFlags().ld_experimentation_poc_mx0_04_24_temp,
  },
  BRECS_ABC_EXPERIMENT: {
    name: "browse_recs_variants_v2_mxa_07_24_temp",
    description: "Brecs ABC variants",
    default: false,
    values: ["control", "combinedList", "moreTherapistsButton"],
    track: true,
    trackingName: "brecs_abc_mxa0724",
    useFlag: () => useFlags().ld_experimentation_poc_mx0_04_24_temp,
  },
  BRECS_FILTERS_UPDATE: {
    name: "browse_recs_filters_v3_mxa_10_24",
    description: "Brecs Variant C Filters Update",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "brecs_c_filters_mxa1024",
    useFlag: () => useFlags().browse_recs_filters_v3_mxa_10_24,
  },
  FAKE_PROVIDER_BADGES: {
    name: "fake_provider_badges_mxa_09_24",
    description: "Fake therapists badges",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "fake_badges_mxa0924",
    useFlag: () => useFlags().fake_provider_badges_mxa_09_24,
  },
  ENABLE_WORKPLACE_MANAGER_NAV_LINK: {
    name: "enable_workplace_manager_nav_link_mxe_05_01_24_temp",
    description: "Will enable rendering of the manager exp link on the top nav",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () =>
      useFlags().enable_workplace_manager_nav_link_mxe_05_01_24_temp,
  },
  UPCOMING_APPOINTMENT_CARDS: {
    name: "upcoming_appointment_card_experiment_mfopx_05_24_temp",
    description: "Shows/hides upcoming appointments cards on home page",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "uac_mfopx0524",
    useFlag: () =>
      useFlags().upcoming_appointment_card_experiment_mfopx_05_24_temp,
  },
  MEMBER_PAYMENT_COLLECTIONS_M3: {
    name: "gated_booking_flow_mpc_m3_release_mxb_05_24_temp",
    description: "Will enable the new member payment collections m3 flow",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "gbfmmr_mxb0524",
    useFlag: () => useFlags().gated_booking_flow_mpc_m3_release_mxb_05_24_temp,
  },
  SDOH_LOCAL_RESOURCES: {
    name: "sdoh_local_resources_release_mxo_05_24_temp",
    description: "Shows/Hides the new SDOH Local Resources page",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().sdoh_local_resources_release_mxo_05_24_temp,
  },
  ENABLE_COST_TRANSPARENCY: {
    name: "cost_transperency_cgx_rev_5_24_temp",
    description: "Will enable cost transparency page",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "ct_cgx_rev_5_24_temp",
    useFlag: () => useFlags().cost_transperency_cgx_rev_5_24_temp,
  },
  SUD_SIGNAL_EXPERIMENT: {
    name: "sud_signal_AB_test_lob_05_2024_temp",
    description:
      "SUD Signal AB test showing 3 different cards to sud supported users on homepage",
    default: false,
    values: ["off", "substance_use", "drinking", "coping"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().sud_signal_AB_test_lob_05_2024_temp,
  },
  SHOW_GLOBAL_ONE_EXPERIENCE: {
    name: "lob_global_one_release_05_24",
    description: "Enables the GlobalOne experience for T2 members",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().lob_global_one_release_05_24,
  },
  PROVIDER_MEMBER_MESSAGING: {
    name: "provider_member_messaging_release_mfopx_06_2024_temp",
    description: "Enables the member to message a provider",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "pmmr_mfopx0624",
    useFlag: () =>
      useFlags().provider_member_messaging_release_mfopx_06_2024_temp,
  },
  ACKNOWLEDGE_LATE_CANCEL_ON_CREATE: {
    name: "acknowledge_late_cancel_on_create_experiment_mfopx_5_24_temp",
    description: "Acknowledges late cancel on create appointment",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "alcoc_mfopx0524",
    useFlag: () =>
      useFlags().acknowledge_late_cancel_on_create_experiment_mfopx_5_24_temp,
  },
  MEMBER_AUTOPAY_M3: {
    name: "autopay_mpc_m3_release_mxb_06_24_temp",
    description:
      "Will enable the autopay in the new member payment collections m3 flow",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().autopay_mpc_m3_release_mxb_06_24_temp,
  },
  SPECIALIZED_COACHING_CARD_V2_EXPERIENCE: {
    name: "specialized_coaching_experience_lob_05_2024_temp",
    description:
      "Specialized coaching experience card will show up for v2 coaching and low and low/medium acuity users on homepage",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().specialized_coaching_experience_lob_05_2024_temp,
  },
  HELP_CENTER_REDIRECT: {
    name: "help_center_redirect_07_2024",
    description: "attemps authentication into ZD help center when redirecting",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().help_center_redirect_07_2024,
  },
  SEASONALITY_APPOINTMENT_TIME_WARNING: {
    name: "seasonality_appointment_time_warning_experiment_mfopx_7_8_temp",
    description:
      "Displays a warning if appointment time is outside of normal hours or on weekends",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "satw_mfopx0724",
    useFlag: () =>
      useFlags().seasonality_appointment_time_warning_experiment_mfopx_7_8_temp,
  },
  HIGHMARK_COST_BANNERS: {
    name: "highmark_cost_banners_mxa_7_5",
    description:
      "Toggles the new Highmark-specific cost estimate request banners and cards",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "",
    useFlag: () => useFlags().highmark_cost_banners_mxa_7_5,
  },
  SHOW_CONNECTED_BENEFITS: {
    name: "connected_benefits_mxb_07_24_temp",
    description: "Shows/hides Connected Benefits feature for AB testing",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "cb_mxb0724",
    useFlag: () => useFlags().connected_benefits_mxb_07_24_temp,
  },
  MULTI_SESSION_BOOKING: {
    name: "multi_session_booking_experiment_mfopx_07_24_temp",
    description: "Enable multi session booking feature",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "msb_mfopx0724",
    useFlag: () => useFlags().multi_session_booking_release_mxa_07_24_temp,
  },
  HIGHMARK_INSURANCE_FORM: {
    name: "highmark_insurance_form_mxa_7_15",
    description: "Toggles the new Highmark-specific insurance form",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "",
    useFlag: () => useFlags().highmark_insurance_form_mxa_7_15,
  },
  SHOW_SUD_CN_CALENDAR_SCHEDULING: {
    name: "sud_cn_calendar_release_sudbhb_07_2024_temp",
    description: "Shows/Hides new SUD CN scheduling calendar page",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().sud_cn_calendar_release_mxo_07_2024_temp,
  },
  SENTIMENT_RATING_ENTRY_POINTS: {
    name: "quick_sentiment_ratings_entry_points_experiment_mfopx_07_24_temp",
    description:
      "Shows/hides sentiment rating card on homepage and banner on care visits tab",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "srepe_mfopx0724",
    useFlag: () =>
      useFlags()
        .quick_sentiment_ratings_entry_points_experiment_mfopx_07_24_temp,
  },
  HOMEPAGE_SEASONALITY_APPOINTMENT_TIME_WARNING: {
    name: "homepage_seasonality_appointment_time_warning_experiment_mfopx_7_19_temp",
    description:
      "Shows/Hides new homepage seasonality appointment time warning",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "hsatw_mfopx0719",
    useFlag: () =>
      useFlags()
        .homepage_seasonality_appointment_time_warning_experiment_mfopx_7_19_temp,
  },
  UPCOMING_APPT_CARD_CONFIRMATION_CTA: {
    name: "confirmation_cta_upcoming_appt_card_release_mfopx_07_31_temp",
    description: "",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () =>
      useFlags().confirmation_cta_upcoming_appt_card_release_mfopx_07_31_temp,
  },
  DELTA_UPCOMING_APPT_CARD_CONFIRMATION_COPY: {
    name: "delta_confirmation_copy_upcoming_appt_card_release_mfopx_07_31_temp",
    description: "",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () =>
      useFlags()
        .delta_confirmation_copy_upcoming_appt_card_release_mfopx_07_31_temp,
  },
  INTERACTIVE_JOURNALING: {
    name: "moments_interactive_journal_mxe_08_11_temp",
    description: "Toggles the interactive journaling feature",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mij_mxe0811",
    useFlag: () => useFlags().moments_interactive_journal_mxe_08_11_temp,
  },
  SHOW_POST_MOMENTS_PHASE_2_AB_TEST: {
    name: "post_moments_exercise_question_ab_test_phase_2_mxe_08_13_temp",
    description:
      "Onramp to care experiment - nudges user to seek out care (coaching or therapy)",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "pmeqabtp2_mxe0813",
    useFlag: () =>
      useFlags().post_moments_exercise_question_ab_test_phase_2_mxe_08_13_temp,
  },
  DELTA_CANCELATION_POLICY_COPY: {
    name: "delta_cancelation_policy_copy_mfopx_08_14",
    description: "Used to show Delta specific cancelation policy copy.",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "dcpc_mfopx0814",
    useFlag: () => useFlags().delta_cancelation_policy_copy_mfopx_08_14,
  },
  SHOW_ONSITE_CLINICIAN_FLOW: {
    name: "onsite_clinician_multi_role_ce_08_24_temp",
    description:
      "Feature Flag for enabling onsite clinician browse flow w/ entry points",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().onsite_clinician_multi_role_ce_08_24_temp,
  },
  PEER_RECOVERY_SPECIALIST_EXPERIENCE: {
    name: "peer_recovery_release_sudbhb_07_2024_temp",
    description: "Shows/Hides new peer recovery specialist experience",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().peer_recovery_release_sudbhb_07_2024_temp,
  },
  SKIP_WARM_MOBILE_WELCOME_SCREEN: {
    name: "skip_warm_mobile_welcome_screen_mfocl_09_03_temp",
    description: "Skip mobile welcome screen during warm sign up",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "swmws_mfocl0903",
    useFlag: () => useFlags().skip_warm_mobile_welcome_screen_mfocl_09_03_temp,
  },
  PROVIDER_CONSULTATION: {
    name: "provider_consultation_mxa_09_24",
    description: "Provider consultations",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "provcons_mxa0924",
    useFlag: () => useFlags().provider_consultation_mxa_09_24,
  },
  NEURODIVERSITY_PO_DEMO_NOT_PROD: {
    name: "neurodiversity_p0_experiment_demo_not_prod_mxe_09_24",
    description:
      "Controls access to neurodiversity pages and their entry points. These pages are vaporware intended for demo env. DO NOT USE in prod env.",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () =>
      useFlags().neurodiversity_p0_experiment_demo_not_prod_mxe_09_24,
  },
  EXPECTATIONS_SPRING_PREVIEW: {
    name: "expectations_spring_preview_mfom_09_2024_temp",
    description: "Show a preview of Sping offerings on expecatations page",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "esp_mfom0924",
    useFlag: () => useFlags().expectations_spring_preview_mfom_09_2024_temp,
  },
  REMOVE_CONFIDENCE: {
    name: "mfom_remove_confidence_9_11",
    description: "Removes the confidence question from the initial assesment",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mfom_rc_0911",
    useFlag: () => useFlags().mfom_remove_confidence_9_11,
  },
  PROVIDER_PROFILE_VIDEOS: {
    name: "profile_video_p0_demo_not_prod_mfom_09_24",
    description: "DO NOT USE IN PROD. Enables provider profile videos.",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mfom_ppv_0924",
    useFlag: () => useFlags().profile_video_p0_demo_not_prod_mfom_09_24,
  },
  MESSAGING_WEBSOCKETS_ENABLED: {
    name: "messaging_websocket_enabled_mfopx_09_24",
    description:
      "Flag controlling whether messaging uses websocket event streams for updates",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "mwe_mfopx0924",
    useFlag: () => useFlags().messaging_websocket_enabled_mfopx_09_24,
  },
  MESSAGING_THROTTLED_POLLING: {
    name: "messaging_throttled_polling_mfopx_09_24",
    description:
      "Flag controlling whether polling should be throttled, preventing background updates",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "mtp_mfopx0924",
    useFlag: () => useFlags().messaging_throttled_polling_mfopx_09_24,
  },
  ADA_CHATBOT: {
    name: "ada_chatbot_09_24_temp",
    description: "Feature flag for ADA Chatbot",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().ada_chatbot_09_24_temp,
  },
  MANAGER_EXPERIENCE_MILESTONE_3: {
    name: "managerX_milestone_3_mxe_10_17_temp",
    description:
      "This flag is to enable/disable Manager x for resellers and global members",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().managerX_milestone_3_mxe_10_17_temp,
  },
  MXE_500_CARE_BANNER_EXPERIMENT: {
    name: "care_banner_ad_experiment_mxe_09_2024_temp",
    description:
      "Care Banner Ad Experiment - Flag controlling whether the care banner is shown to Moments users",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "cbae_mxe_09_24",
    useFlag: () => useFlags().care_banner_ad_experiment_mxe_09_2024_temp,
  },
  SKIP_COLD_MOBILE_WELCOME_SCREEN: {
    name: "skip_cold_mobile_welcome_screen_two_mfocl_10_24_temp",
    description: "Skip mobile welcome screen during warm sign up",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "scmw_mfocl1124",
    useFlag: () =>
      useFlags().skip_cold_mobile_welcome_screen_two_mfocl_10_24_temp,
  },
  NEW_HELP_FLYOUT_EXPERIENCE: {
    name: "new_help_flyout_mxco_09_2024_temp",
    description: "Shows/Hides new help flyout experience",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().new_help_flyout_mxco_09_2024_temp,
  },
  COST_ESTIMATE_RANGE: {
    name: "cost_estimate_range_prcm_10_24_temp",
    description: "Shows cost estimate amount as a range on coverage page.",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().cost_estimate_range_prcm_10_24_temp,
  },
  INSURANCE_VALIDATION: {
    name: "insurance_validation_prcm_9_24_temp",
    description:
      "Insurance Validation - Adds validation for primary insurance carrier and member id in insurance information form(s)",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "iv_prcm_9_24_temp",
    useFlag: () => useFlags().insurance_validation_prcm_9_24_temp,
  },
  DESKTOP_COLD_SIGN_UP_PERSON_IMAGERY: {
    name: "desktop_cold_signup_person_imagery_mfocl_10_24",
    description: "Signup Experiment: Cold Sign Up Person Imagery. MFOCL-149",
    default: "control",
    values: ["control", "variant_b", "variant_c"],
    track: false,
    trackingName: "dcspi_mfocl1024",
    useFlag: () => useFlags().desktop_cold_signup_person_imagery_mfocl_10_24,
  },
  MANAGER_EXPERIENCE_FOR_GLOBAL_MEMBERS: {
    name: "manager_experience_for_global_members_mxe_10_2024_temp",
    description:
      "This flag will be utilize to rollout manager experience to global members",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () =>
      useFlags().manager_experience_for_global_members_mxe_10_2024_temp,
  },
  FLEXIBLE_SESSIONS: {
    name: "flexible_sessions_mxb_10_09_temp",
    description:
      "Flag for hiding the features for Flexible Sessions.  Allows for user to have more flexibility with their sessions. Feature is currently being built by MX-Benefits.",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().flexible_sessions_mxb_10_09_temp,
  },
  SPECIALTY_CARE_NAVIGATOR_EXPERIENCE: {
    name: "specialty_cn_release_sudbhb_10_2024_temp",
    description: "Shows/Hides the experience of specialty care navigator",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().specialty_cn_release_sudbhb_10_2024_temp,
  },
  MOMENTS_CARE_POPUP_EXPERIMENT: {
    name: "care_popup_moments_experiment_mxe_10_24_temp",
    description:
      "Moments Experiment #4: Care Pop-up on Moments completion. MXE-679",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mcpe_mxe1024",
    useFlag: () => useFlags().care_popup_moments_experiment_mxe_10_24_temp,
  },
  HIDE_COACHING_SESSIONS_COUNT: {
    name: "hide_coaching_sessions_count_mxco_10_2024_temp",
    description: "hides the coaching sessions count",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().hide_coaching_sessions_count_mxco_10_2024_temp,
  },
  ENABLE_AUDIO_INPUT_TESTER: {
    name: "livekit_enable_mic_volume_mei_17_12_24_temp",
    description: "Enabled the Mic Volume meter for audio input testing",
    default: true,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().livekit_enable_mic_volume_mei_17_12_24_temp,
  },
  INTENT_QUESTION_PAGE: {
    name: "intent_question_page_mxco_10_2024_temp",
    description: "Shows/Hides new intent question page",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "iqp_mxco1024",
    useFlag: () => useFlags().intent_question_page_mxco_10_2024_temp,
  },
  FOOTER_REPOSITION: {
    name: "footer_reposition_mfom_9_26",
    description: "Footer repositioning for assessments",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "fr_mfom1024",
    useFlag: () => useFlags().footer_reposition_mfom_9_26,
  },
  PROVIDER_PROFILE_BOOK_NOW_BUTTON: {
    name: "mfom_provider_profile_p2_10_24_temp",
    description: "Toggle Book Now button on Provider Profiles",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "ppbn_mfom1024",
    useFlag: () => useFlags().mfom_provider_profile_p2_10_24_temp,
  },
  CHIP_GUARDIAN_RELEASE: {
    name: "chip_guardian_release_mxce_11_2024_temp",
    description: "",
    default: undefined,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().chip_guardian_release_mxce_11_2024_temp,
  },
  MANAGER_EXPERIENCE_MILESTONE_2: {
    name: "manager_experience_milestone_2_mxe_11_24_temp",
    description: "This temp flag will be used to release milestone 2 work",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().manager_experience_milestone_2_mxe_11_24_temp,
  },
  MANAGER_EXPERIENCE_SIMPLIFY_ID_FLOW_EXPERIMENT: {
    name: "simplify_id_flow_manager_experience_experiment_mxe_11_24_temp",
    description:
      "Manager Experience Experiment #1: Simplify manager self-identification banner flow. MXE-734",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mgxx1_mxe1024",
    useFlag: () =>
      useFlags().simplify_id_flow_manager_experience_experiment_mxe_11_24_temp,
  },
  ENABLE_AUDIO_LIVEKIT_LOG_EXTENSION: {
    name: "livekit_enable_log_extension_mei_11_04_24_temp",
    description: "Enabled the LiveKit log extension for debug messages",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().livekit_enable_log_extension_mei_11_04_24_temp,
  },
  INVITE_DEPENDENTS_DESKTOP_WEB: {
    name: "invite_dependents_experiment_dw_mfocl_11_24_temp",
    description:
      "Enables the Invite Dependents screen revamp experiement on desktop web",
    default: "control",
    values: ["control", "variant_b", "variant_c"],
    track: false,
    trackingName: "idw_mfclo1124",
    useFlag: () => useFlags().invite_dependents_experiment_dw_mfocl_11_24_temp,
  },
  INVITE_DEPENDENTS_MOBILE_WEB: {
    name: "invite_dependents_experiment_mw_mfocl_11_24_temp",
    description:
      "Enables the Invite Dependents screen revamp experiement on mobile web",
    default: "control",
    values: ["control", "variant_b", "variant_c"],
    track: false,
    trackingName: "idm_mfclo1124",
    useFlag: () => useFlags().invite_dependents_experiment_mw_mfocl_11_24_temp,
  },
  LIGHT_WEIGHT_REFERRALS: {
    name: "referral_release_mfocl_11_2024_temp",
    description: "Release for light weight referral",
    // undefined is used to create a loading state until the flag is loaded with the env value
    default: undefined,
    values: ["true", "false"],
    track: false,
    trackingName: "lwrf_mfocl1124",
    useFlag: () => useFlags().referral_release_mfocl_11_2024_temp,
  },
  NEW_RIGHT_CTA: {
    name: "new_right_cta_6_20_24",
    description:
      "Toggles the main CTA on the right links of the navbar between 'check in' and 'book appointment'.",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mfom_nrcta_62024",
    useFlag: () => useFlags().new_right_cta_6_20_24,
  },
  ENABLE_NEW_NO_PROVIDER_INTERFACE: {
    name: "mfom_your_care_recs_11_24",
    description: "Shows/Hides new no provider interface on the home page",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mfom_ycr_1124",
    useFlag: () => useFlags().mfom_your_care_recs_11_24,
  },
  SHOW_PERSONALIZED_RESULT_SCREEN: {
    name: "mfom_remove_nicely_done_11_24",
    description:
      "shows personalized result screen instead of nicely done screen",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mfom_nd_1124",
    useFlag: () => useFlags().mfom_remove_nicely_done_11_24,
  },
  NEW_ASSESSMENT_RESULTS_CTA: {
    name: "mfom_assessment_ctas_11_24",
    description: "New assessment results page CTA updates",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mfom_actas_1124",
    useFlag: () => useFlags().mfom_assessment_ctas_11_24,
  },
  CN_ON_DEMAND_EXPERIMENT: {
    name: "cn_on_demand_experiment_mxce_11_24_temp",
    description: "Experiment to increase volume of on demand calls to CNs",
    default: "control",
    values: ["control", "treatment"],
    track: true,
    trackingName: "cnode_mxce1124",
    useFlag: () => useFlags().cn_on_demand_experiment_mxce_11_24_temp,
  },
  SESSION_RECORDING_COPY: {
    name: "session_recording_copy_cgxp_5576_temp",
    description: "Adds session recording copy to appoitnment details",
    default: false,
    values: ["true", "false"],
    track: false,
    trackingName: "",
    useFlag: () => useFlags().session_recording_copy_cgxp_5576_temp,
    // removal ticket: https://springhealth.atlassian.net/browse/CGXP-6509
  },
  LIGHTWEIGHT_CONTENT_UNIFICATION: {
    name: "lightweight_content_unification_release_mxe_11_24_temp",
    description:
      "Enables lightweight content unification: webinars, Wellsprings access. MXE-724",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "lcu_mxe1124",
    useFlag: () =>
      useFlags().lightweight_content_unification_release_mxe_11_24_temp,
  },
  MANAGER_SELF_ID_BANNER_EXPERIMENT_3: {
    name: "manager_experience_experiment_3_banner_on_care_moments_mxe_11_24_temp",
    description:
      "Allows manager self id banner to be shown on moments andcare pages",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mxexp3_mxe1124",
    useFlag: () =>
      useFlags()
        .manager_experience_experiment_3_banner_on_care_moments_mxe_11_24_temp,
  },
  HOMEPAGE_TODO_COLLAPSE: {
    name: "homepage_todo_collapse_mfom_11_5_temp",
    description: "Show collapsed variants of the todos on the homepage",
    default: "control",
    values: ["control", "hidden_cards", "clear_images", "blurred_images"],
    track: true,
    trackingName: "htc_mfom115",
    useFlag: () => useFlags().homepage_todo_collapse_mfom_11_5_temp,
  },
  RECS_SAGEMAKER_RANK: {
    name: "recs_sagemaker_rank_mfom_1122_temp",
    description: "Send sagemaker version to backend for ranking",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "sage_mfom1122",
    useFlag: () => useFlags().recs_sagemaker_rank_mfom_1122_temp,
  },
  MANGER_X_EXPERIMENT_2: {
    name: "manager_experience_experiment_2_mxe_11_24_temp",
    description:
      "This temp flag will be used manager experience experiment #2 to keep self ID at top of homepage",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mgxexp2_mxe1124",
    useFlag: () => useFlags().manager_experience_experiment_2_mxe_11_24_temp,
  },
  ENABLE_STOP_RECORDINGS_ON_EXCEEDING_PARTICIPANTS: {
    name: "enable_stop_recordings_on_exceeding_participants_CGXP_6693",
    description: "Enable stop recordings on exceeding participants",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "esroep_CGXP6693",
    useFlag: () =>
      useFlags().enable_stop_recordings_on_exceeding_participants_CGXP_6693,
  },
  UPSELL_T2_T1: {
    name: "upsell_t2_t1_mxco_11_24_temp",
    description: "Upsell T2 to T1",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "ut2t1_mxco1124",
    useFlag: () => useFlags().upsell_t2_t1_mfom_11_24_temp,
  },
  MANAGER_X_EXPERIMENT_6: {
    name: "manager_experience_experiment_6_mxe_11_24_temp",
    description:
      "Manager Experience Experiment #6: Show workplace content on homepage and Moments page. MXE-830",
    default: false,
    values: ["true", "false"],
    track: true,
    trackingName: "mgxx6_mxe1124",
    useFlag: () => useFlags().manager_experience_experiment_6_mxe_11_24_temp,
  },
};
