import { gql } from "@apollo/client";

const getCountryStates = gql`
  query getCountryStates($country: String!, $locale: String) {
    country_states(country: $country, locale: $locale) {
      code
      name
    }
  }
`;

export default getCountryStates;
