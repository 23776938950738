//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import {
  Floaty,
  Card,
  FlexRow,
  FlexCol,
  Content,
  VerticalRule,
} from "@spring/smeargle";
import { getOr } from "lodash/fp";
import Meowth from "@spring/meowth";
import { withTranslation, Trans } from "react-i18next";

import styles from "./styles.module.scss";

import { getMemberVisits } from "operations/queries/member";

class SupportInfoCard extends Component {
  static propTypes = {
    supportType: PropTypes.string,
    t: PropTypes.func,
    visits: PropTypes.shape({
      user: PropTypes.shape({
        member: PropTypes.shape({
          cohort: PropTypes.shape({
            contract_term: PropTypes.shape({
              visit_type: PropTypes.any,
            }),
          }),
        }),
      }),
    }),
  };

  // sponsored + basic + specialist
  get basicCovered() {
    return getOr(0, "visits.user.member.visits_covered_total", this.props);
  }
  get remainingBasic() {
    return getOr(0, "visits.user.member.visits_remaining_total", this.props);
  }

  // just specialist + sponsored
  get specialistCovered() {
    return getOr(0, "visits.user.member.visits_covered_specialist", this.props);
  }
  get remainingSpecialist() {
    return getOr(
      0,
      "visits.user.member.visits_remaining_specialist",
      this.props,
    );
  }

  get annualSupport() {
    const { t } = this.props;
    let numVisits;
    if (this.props.supportType === "Care Navigation") {
      return t("supportInfo.unlimited");
    } else if (this.props.supportType === "Basic") {
      numVisits = this.basicCovered;
    } else if (this.props.supportType === "Specialist") {
      numVisits = this.specialistCovered;
    } else return "";

    return (
      <Trans ns="benefits" i18nKey="supportInfo.numberOfVisits">
        {{ numVisits }} Visits
      </Trans>
    );
  }

  get remainingSupport() {
    const { t } = this.props;
    let numVisits;
    if (this.props.supportType === "Care Navigation") {
      return t("supportInfo.unlimited");
    } else if (this.props.supportType === "Basic") {
      numVisits = this.remainingBasic;
    } else if (this.props.supportType === "Specialist") {
      numVisits = this.remainingSpecialist;
    } else return "";

    return numVisits >= 0 ? (
      <Trans ns="benefits" i18nKey="supportInfo.numberOfVisits">
        {{ numVisits }} Visits
      </Trans>
    ) : (
      t("supportInfo.zeroVisits")
    );
  }

  render() {
    if (
      (this.props.supportType === "Basic" && this.basicCovered > 0) ||
      (this.props.supportType === "Specialist" && this.specialistCovered > 0) ||
      this.props.supportType === "Care Navigation"
    ) {
      const supportType =
        this.props.supportType === "Specialist" ? this.props.supportType : "";
      return (
        <Floaty float={1} radius="3px">
          <Card>
            <FlexRow justification="space-around" alignment="center">
              <FlexCol>
                <Content>
                  <Trans ns="benefits" i18nKey="supportInfo.annualSupport">
                    Annual {{ supportType }} Support
                  </Trans>
                </Content>
                <Content bold>{this.annualSupport}</Content>
              </FlexCol>
              <div className={styles.verticalRuleWrapper}>
                <VerticalRule margin={true} />
              </div>
              <FlexCol>
                <Content>
                  <Trans ns="benefits" i18nKey="supportInfo.remainingSupport">
                    Remaining {{ supportType }} Support
                  </Trans>
                </Content>
                <Content bold>{this.remainingSupport}</Content>
              </FlexCol>
            </FlexRow>
          </Card>
        </Floaty>
      );
    }

    return null;
  }
}

export default graphql(getMemberVisits, {
  options: Meowth.apolloOptionsUserId,
  name: "visits",
})(withTranslation("benefits")(SupportInfoCard));
