import {
  Checkbox,
  Box,
  InputRightElement,
  InputGroup,
  Editable,
  EditableInput,
  EditablePreview,
  FormLabel,
} from "design-system";
import { colors } from "design-system/theme/base/colors";

import PropTypes from "prop-types";

const COLORS_OUTLINED = {
  focusColor: colors.primary.base,
  background: colors.tertiary[25],
};

const SHCheckboxInput = ({
  onBlur,
  dataCy,
  checked,
  isFocused,
  setIsFocused,
  onChange,
  label,
  placeholder,
  fontWeight = 600,
}) => {
  return (
    <Box mb="80">
      {(checked || isFocused) && (
        <FormLabel fontSize={"16"} color="tertiary.base" fontWeight="regular">
          {label}
        </FormLabel>
      )}

      <Editable
        w="100%"
        h={56}
        bg={COLORS_OUTLINED.background}
        sx={{
          border: checked ? `solid ${COLORS_OUTLINED.focusColor}` : "none",
          borderWidth: checked ? 2 : "none",
          borderRadius: 8,
          _hover: checked
            ? {
                border: `solid ${COLORS_OUTLINED.focusColor}`,
                borderWidth: 3,
              }
            : { border: `solid ${COLORS_OUTLINED.focusColor}`, borderWidth: 1 },
        }}
        placeholder={isFocused ? placeholder : label}
        onClick={() => setIsFocused(true)}
      >
        <InputGroup>
          <EditablePreview
            border="none"
            color={isFocused ? "#0114195E" : "tertiary.base"}
            letterSpacing="0.0275em"
            cursor="pointer"
            w={"110%"}
            pl={28}
            pt={14}
            fontWeight={fontWeight}
          />
          <EditableInput
            border="none"
            letterSpacing="0.0275em"
            w={"110%"}
            h={52}
            pl={28}
            onBlur={(e) => onBlur(e)}
            onChange={(e) => {
              // Prevent only whitespace from being entered
              if (e.target.value.trim() === "") {
                e.target.value = "";
              }
              onChange(e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onBlur(e);
              }
            }}
            color="tertiary.base"
            maxLength={40}
          />
          <InputRightElement>
            <Checkbox
              variant="circle"
              isChecked={checked}
              isReadOnly={true}
              pointerEvents="none"
              data-cy={dataCy}
              aria-label="checkbox icon"
            />
          </InputRightElement>
        </InputGroup>
      </Editable>
    </Box>
  );
};

SHCheckboxInput.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
};

export { SHCheckboxInput };
