"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 * Takes a value and prepends it to a new array.
 * The old array is not updated.
 *
 * @name truncateWords
 * @param {Number} amount
 * @param {String} value
 * @returns {Array} Returns a new string of `amount` of words with '...' if the original string exceeded `amount` of words.
 *
 * @example
 * const string = 'hello world';
 * const newString =  truncate(1, string);
 *
 * console.log(string, newString); // 'hello world', 'hello...'
 */

function truncateWords(amount, value) {
  var whiteSpace = value.split(' ');
  if (whiteSpace.length > amount) {
    // Return only first `amount` of words
    var truncated = whiteSpace.splice(0, amount);
    return truncated.join(' ') + '...';
  }
  return value;
}
var _default = exports["default"] = (0, _fp.curry)(truncateWords);