import { useState, useCallback, useLayoutEffect } from "react";
import { debounce } from "lodash";

const getDimensions = (node) => {
  const rect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  };
};

const useBoundingRect = () => {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if ("undefined" !== typeof window && node) {
      const updateDimensions = () =>
        window.requestAnimationFrame(() => setDimensions(getDimensions(node)));

      updateDimensions();

      const updateDimensionsListener = debounce(updateDimensions, 100);

      window.addEventListener("resize", updateDimensionsListener);
      window.addEventListener("scroll", updateDimensionsListener);
      return () => {
        window.removeEventListener("resize", updateDimensionsListener);
        window.removeEventListener("scroll", updateDimensionsListener);
      };
    }
  }, [node]);

  return [ref, dimensions, node];
};

export { useBoundingRect };
