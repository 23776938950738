import { useState, useEffect } from "react";
import dynamic from "next/dynamic";

type LottieAnimationProps = {
  height: number;
  width: number;
  options?: {
    loop: boolean;
    autoplay: boolean;
    rendererSettings: {
      preserveAspectRatio: string;
    };
  };
  animationName: string;
};

const DynamicLottie = dynamic(() => import("react-lottie"), {
  ssr: false,
});

export const LottieAnimation = ({
  height,
  width,
  options = null,
  animationName,
}: LottieAnimationProps) => {
  const [animationData, setAnimationData] = useState(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    import(`./${animationName}/${animationName}.json`).then((data) => {
      setAnimationData(data.default);
    });
  }, [animationName]);

  return (
    <>
      {animationData && (
        <DynamicLottie
          // @ts-ignore
          height={height}
          width={width}
          options={options ? { ...options, animationData } : defaultOptions}
        />
      )}
    </>
  );
};
