export const requestKeepActive = async (setWakeLock) => {
  // Keep screen/device from turning off due to inactivity
  try {
    const lock = await navigator.wakeLock.request("screen");
    setWakeLock(lock);
  } catch {
    // Do nothing
  }
  // Keep logged-in users from being logged out due to inactivity
  localStorage.setItem("active_care_session", Date.now().toString());
  // '...vendor' used for logging
  localStorage.setItem("active_care_session_vendor", "livekit");
};

export const releaseKeepActive = async (wakeLock, setWakeLock) => {
  // Restore inactivity screen locking
  if (wakeLock) {
    await wakeLock.release();
    setWakeLock(null);
  }
  // Restore inactivity log-out mechanism
  localStorage.removeItem("active_care_session");
  localStorage.removeItem("active_care_session_vendor");
};
