import { useEffect, useRef } from "react";
import { Badge } from "@springcare/sh-component-library";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { VExternalLinkIcon } from "@springcare/verdant-icons-react";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTrackOnceInViewport } from "hooks/useTrackOnceInViewport";

interface ResourceCardProps {
  style?: React.CSSProperties;
  dataCy: string;
  description: string;
  heading: string;
  icon: React.ElementType;
  isExternal?: boolean;
  onClick: () => void;
  trackingAction: string;
  badgeText?: string;
  trackingProps?: object;
}

export function ResourceCard({
  style,
  dataCy,
  description,
  heading,
  icon: Icon,
  badgeText,
  isExternal = false,
  onClick,
  trackingAction,
  trackingProps,
}: ResourceCardProps) {
  const ref = useRef();
  const trackOnce = useTrackOnceInViewport(ref);

  useEffect(() => {
    trackOnce(() =>
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        `${dataCy.replace(/_/g, " ")} card`,
        { location: trackingAction, ...trackingProps },
      ),
    );
  }, [trackingAction, dataCy, trackOnce]);

  const handleClick = () => {
    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      isExternal ? "External link" : "",
      `${dataCy.replace(/_/g, " ")} card`,
      { location: trackingAction, ...trackingProps },
    );
    onClick();
  };

  return (
    <Box
      style={style}
      as={"button"}
      data-cy={dataCy}
      display="flex"
      flexDirection="column"
      textAlign="start"
      p={16}
      gap={8}
      w={"325px"}
      minH="196"
      borderRadius={8}
      boxShadow="0px 2px 8px rgba(106, 84, 77, 0.16)"
      _hover={{
        transform: "scale(1.025)",
        boxShadow: "0px 4px 16px rgba(106, 84, 77, 0.2)",
      }}
      onClick={handleClick}
      ref={ref}
    >
      <Box display="flex" w="100%" justifyContent="space-between">
        {typeof Icon !== "string" ? (
          <Icon width={40} height={40} aria-hidden="100%" />
        ) : (
          <Flex h={50} mr={2} justifyContent="start">
            <Image
              objectFit="contain"
              src={Icon}
              aria-hidden="true"
              alt="Benefit logo"
            />
          </Flex>
        )}
        {isExternal && (
          <VExternalLinkIcon width={18} height={18} aria-hidden="true" />
        )}
      </Box>
      <Flex gap={8}>
        <Text as="h3" size="body-medium-strong">
          {heading}
        </Text>
        {badgeText && <Badge colorScheme="positive">{badgeText}</Badge>}
      </Flex>
      <Text size="body-medium-regular">{description}</Text>
    </Box>
  );
}
