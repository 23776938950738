//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";

const numberField = () => yup.number().required();

const promptFallingAsleepSchema = {
  "QIDS.falling_asleep": numberField(),
};
const promptSleepDuringNightSchema = {
  "QIDS.sleep_during_night": numberField(),
};
const promptWakeEarlySchema = {
  "QIDS.wake_early": numberField(),
};
const promptSleepTooMuchSchema = {
  "QIDS.sleep_too_much": numberField(),
};
const promptSadSchema = {
  "QIDS.sad": numberField(),
};
const promptConcentrationSchema = {
  "QIDS.concentration": numberField(),
};
const promptSelfPerceptionSchema = {
  "QIDS.self_perception": numberField(),
};
const promptSuicideSchema = {
  "QIDS.suicide": numberField(),
};
const promptInterestSchema = {
  "QIDS.interest": numberField(),
};
const promptEnergySchema = {
  "QIDS.energy": numberField(),
};
const promptSlowSchema = {
  "QIDS.slow": numberField(),
};
const promptRestlessSchema = {
  "QIDS.restless": numberField(),
};

const promptAppetiteChangeSchema = {
  "QIDS.appetite_change": numberField(),
};

const promptWeightChangeSchema = {
  "QIDS.weight_change": numberField(),
};

export const QIDSSchema = {
  ...promptFallingAsleepSchema,
  ...promptSleepDuringNightSchema,
  ...promptWakeEarlySchema,
  ...promptSadSchema,
  ...promptConcentrationSchema,
  ...promptSelfPerceptionSchema,
  ...promptInterestSchema,
  ...promptEnergySchema,
  ...promptSlowSchema,
  ...promptRestlessSchema,
  ...promptAppetiteChangeSchema,
  ...promptWeightChangeSchema,
};

const QIDS = [
  {
    id: "QIDS--1",
    title: "Falling Asleep",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptFallingAsleepSchema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="QIDS.falling_asleep"
        options={[
          {
            label: "I never take longer than 30 minutes to fall asleep.",
            value: 0,
          },
          {
            label:
              "I take at least 30 minutes to fall asleep, less than half the time.",
            value: 1,
          },
          {
            label:
              "I take at least 30 minutes to fall asleep, more than half the time.",
            value: 2,
          },
          {
            label:
              "I take more than 60 minutes to fall asleep, more than half the time.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--2",
    title: "Sleep During the Night",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSleepDuringNightSchema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="QIDS.sleep_during_night"
        options={[
          { label: "I do not wake up at night.", value: 0 },
          {
            label:
              "I have a restless, light sleep with a few brief awakenings each night.",
            value: 1,
          },
          {
            label:
              "I wake up at least once a night, but I go back to sleep easily.",
            value: 2,
          },
          {
            label:
              "I awaken more than once a night and stay awake for 20 minutes or more, more than half the time.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--3",
    title: "Waking Up Too Early",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptWakeEarlySchema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="QIDS.wake_early"
        options={[
          {
            label:
              "Most of the time, I awaken no more than 30 minutes before I need to get up.",
            value: 0,
          },
          {
            label:
              "More than half the time, I awaken more than 30 minutes before I need to get up.",
            value: 1,
          },
          {
            label:
              "I almost always awaken at least one hour or so before I need to, but I go back to sleep eventually.",
            value: 2,
          },
          {
            label:
              "I awaken at least one hour before I need to, and can't go back to sleep.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--4",
    title: "Sleeping Too Much",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSleepTooMuchSchema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="QIDS.sleep_too_much"
        options={[
          {
            label:
              "I sleep no longer than 7-8 hours/night, without napping during the day.",
            value: 0,
          },
          {
            label:
              "I sleep no longer than 10 hours in a 24-hour period including naps.",
            value: 1,
          },
          {
            label:
              "I sleep no longer than 12 hours in a 24-hour period including naps.",
            value: 2,
          },
          {
            label:
              "I sleep longer than 12 hours in a 24-hour period including naps.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--5",
    title: "Feeling Sad",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSadSchema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="QIDS.sad"
        options={[
          { label: "I do not feel sad.", value: 0 },
          { label: "I feel sad less than half the time.", value: 1 },
          { label: "I feel sad more than half the time.", value: 2 },
          { label: "I feel sad nearly all of the time.", value: 3 },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--6",
    title: "Concentration and Decision Making",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptConcentrationSchema),
    questions: [
      <RadioButtons
        key={6}
        border={true}
        fieldKey="QIDS.concentration"
        options={[
          {
            label:
              "There is no change in my usual capacity to concentrate or make decisions.",
            value: 0,
          },
          {
            label:
              "I occasionally feel indecisive or find that my attention wanders.",
            value: 1,
          },
          {
            label:
              "Most of the time, I struggle to focus my attention or to make decisions.",
            value: 2,
          },
          {
            label:
              "I cannot concentrate well enough to read or cannot make even minor decisions.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--7",
    title: "View of Myself",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSelfPerceptionSchema),
    questions: [
      <RadioButtons
        key={7}
        border={true}
        fieldKey="QIDS.self_perception"
        options={[
          {
            label:
              "I see myself as equally worthwhile and deserving as other people.",
            value: 0,
          },
          { label: "I am more self-blaming than usual.", value: 1 },
          {
            label: "I largely believe that I cause problems for others.",
            value: 2,
          },
          {
            label:
              "I think almost constantly about major and minor defects in myself.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--8",
    title: "Thoughts About Suicide or Death",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSuicideSchema),
    questions: [
      <RadioButtons
        key={8}
        border={true}
        fieldKey="QIDS.suicide"
        options={[
          { label: "I do not think of suicide or death.", value: 0 },
          {
            label: "I feel that life is empty or wonder if it's worth living.",
            value: 1,
          },
          {
            label:
              "I think of suicide or death several times a week for several minutes.",
            value: 2,
          },
          {
            label:
              "I think of suicide or death several times a day in some detail, or I have made specific plans for suicide or have actually tried to take my life.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--9",
    title: "General Interest",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptInterestSchema),
    questions: [
      <RadioButtons
        key={9}
        border={true}
        fieldKey="QIDS.interest"
        options={[
          {
            label:
              "There is no change from usual in how interested I am in other people or activities.",
            value: 0,
          },
          {
            label:
              "I notice that I am less interested in people or activities.",
            value: 1,
          },
          {
            label:
              "I find I have interest in only one or two of my formerly pursued activities.",
            value: 2,
          },
          {
            label:
              "I have virtually no interest in formerly pursued activities.",
            value: 3,
          },
        ]}
      />,
    ],
  },

  {
    id: "QIDS--10",
    title: "Energy Levels",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptEnergySchema),
    questions: [
      <RadioButtons
        key={10}
        border={true}
        fieldKey="QIDS.energy"
        options={[
          {
            label: "There is no change in my usual level of energy.",
            value: 0,
          },
          { label: "I get tired more easily than usual.", value: 1 },
          {
            label:
              "I have to make a big effort to start or finish my usual daily activities (for example, shopping, homework, cooking, or going to work).",
            value: 2,
          },
          {
            label:
              "I really cannot carry out most of my usual daily activities because I just don't have the energy.",
            value: 3,
          },
        ]}
      />,
    ],
  },

  {
    id: "QIDS--11",
    title: "Feeling Slow",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptSlowSchema),
    questions: [
      <RadioButtons
        key={11}
        border={true}
        fieldKey="QIDS.slow"
        options={[
          {
            label: "I think, speak, and move at my usual rate of speed.",
            value: 0,
          },
          {
            label:
              "I find that my thinking is slowed down or my voice sounds dull or flat.",
            value: 1,
          },
          {
            label:
              "It takes me several seconds to respond to most questions and I'm sure my thinking is slowed.",
            value: 2,
          },
          {
            label:
              "I am often unable to respond to questions without extreme effort.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--12",
    title: "Restlessness",
    subtitle: "During the past seven days...",
    schema: yup.object().shape(promptRestlessSchema),
    questions: [
      <RadioButtons
        key={12}
        border={true}
        fieldKey="QIDS.restless"
        options={[
          { label: "I do not feel restless.", value: 0 },
          {
            label:
              "I'm often fidgety, wringing my hands, or need to shift how I am sitting.",
            value: 1,
          },
          {
            label: "I have impulses to move about and am quite restless.",
            value: 2,
          },
          {
            label:
              "At times, I am unable to stay seated and need to pace around.",
            value: 3,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--13",
    title: "Appetite Change",
    subtitle: "Complete either A or B (not both)",
    schema: yup.object().shape(promptAppetiteChangeSchema),
    questions: [
      <RadioButtons
        key="QIDS.appetite_change-a"
        border={true}
        fieldKey="QIDS.appetite_change"
        label="A. Decreased Appetite (Within the Last Two Weeks)"
        options={[
          { label: "There is no change from my usual appetite.", value: 0 },
          {
            label:
              "I eat somewhat less often or lesser amounts of food than usual.",
            value: 1,
          },
          {
            label: "I eat much less than usual and only with personal effort.",
            value: 2,
          },
          {
            label:
              "I rarely eat within a 24-hour period, and only with extreme personal effort or when others persuade me to eat.",
            value: 3,
          },
        ]}
      />,
      <RadioButtons
        key="QIDS.appetite_change-b"
        border={true}
        fieldKey="QIDS.appetite_change"
        label="B. Increased Appetite (Within the Last Two Weeks)"
        options={[
          { label: "There is no change from my usual appetite.", value: 4 },
          {
            label: "I feel a need to eat more frequently than usual.",
            value: 5,
          },
          {
            label:
              "I regularly eat more often and/or greater amounts of food than usual.",
            value: 6,
          },
          {
            label:
              "I feel driven to overeat both at mealtime and between meals.",
            value: 7,
          },
        ]}
      />,
    ],
  },
  {
    id: "QIDS--14",
    title: "Weight Change",
    subtitle: "Complete either A or B (not both)",
    schema: yup.object().shape(promptWeightChangeSchema),
    questions: [
      <RadioButtons
        key="QIDS.weight_change-a"
        border={true}
        fieldKey="QIDS.weight_change"
        label="A. Decreased Weight (Within the Last Two Weeks)"
        options={[
          { label: "I have not had a change in my weight.", value: 0 },
          { label: "I feel as if I have had a slight weight loss.", value: 1 },
          { label: "I have lost 2 pounds or more.", value: 2 },
          { label: "I have lost 5 pounds or more.", value: 3 },
        ]}
      />,
      <RadioButtons
        key="QIDS.weight_change-b"
        border={true}
        fieldKey="QIDS.weight_change"
        label="B. Increased Weight (Within the Last Two Weeks)"
        options={[
          { label: "I have not had a change in my weight.", value: 4 },
          { label: "I feel as if I have had a slight weight gain.", value: 5 },
          { label: "I have gained 2 pounds or more.", value: 6 },
          { label: "I have gained 5 pounds or more.", value: 7 },
        ]}
      />,
    ],
  },
];

export default QIDS;
