import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInViewportOnce } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";
import { Heading, VStack } from "@chakra-ui/react";
import { Card } from "@springcare/sh-component-library";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { Ratings } from "shared/components/SentimentRatingCard/Ratings";
import routes from "routes/PublicRoutes";

type MemberPortalReduxState = {
  global: {
    lang?: string;
    [key: string]: any;
  };
  [key: string]: any;
};

export const SentimentRatingBanner = ({ appointment }) => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const router = useRouter();

  const locale = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.lang,
  );

  const appointmentDate = DateTime.fromISO(appointment?.start_at).setLocale(
    locale,
  );

  const { t } = useTranslation("careVisits");

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Sentiment landing display",
        {
          provider_id: appointment?.provider_id,
          appointment_id: appointment?.id,
        },
      );
      setTrackedOnce(true);
    }
  }, [inViewport, trackedOnce]);

  const { to, as } = routes.Feedback;

  const onRatingClick = (rating: number) => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Card Rating Clicked",
      {
        sentiment_rating: rating,
        appointment_id: appointment?.id,
        provider_id: appointment?.provider_id,
      },
    );
    const asRoute = `${as}?appointment_id=${appointment?.id}&provider_id=${appointment?.provider_id}&sentiment_value=${rating}&appt_type=${appointment?.kind}`;

    router.push(
      {
        pathname: to,
        query: {
          appointment_id: appointment?.id,
          provider_id: appointment?.provider.id,
          sentiment_value: rating,
          appt_type: appointment?.kind,
        },
      },
      asRoute,
    );
  };

  return (
    <Card variant="high-emphasis" w="full" background="info-on-base">
      <VStack align="flex-start" gap="8px">
        <Heading pl="8px" size="heading-small">
          {t("yourCareTab.sentimentRatingBanner.title", {
            providerName: appointment?.provider?.name,
            appointmentDate: appointmentDate.toLocaleString({
              month: "long",
              day: "numeric",
            }),
          })}
        </Heading>
        <Ratings onSubmit={onRatingClick} />
      </VStack>
    </Card>
  );
};
