//
import PropTypes from "prop-types";
import { Card, Col } from "@spring/smeargle";

import styles from "./style.module.scss";

import envUtils from "utils/environment";

const NonAcuityContent = (props) => {
  const { providerData, isModal = false } = props;
  if (!envUtils.isInternalEnv()) {
    return null;
  }
  return (
    <Col md={5}>
      <div
        className={
          isModal ? styles.nonAcuityContainerModal : styles.nonAcuityContainer
        }
      >
        <Card highlight={false} full={false} dashed={false}>
          <div>
            <p>
              Please reach out directly to inquire about this Provider's
              availability.
            </p>
            <p>
              {providerData.email && (
                <div>
                  <div>
                    <b>Email:</b>
                  </div>
                  <div>{providerData.email}</div>
                </div>
              )}
              <br />
              {providerData.phone && (
                <div>
                  <div>
                    <b>Phone:</b>
                  </div>
                  <div>{providerData.phone}</div>
                </div>
              )}
            </p>
          </div>
        </Card>
      </div>
    </Col>
  );
};

NonAcuityContent.propTypes = {
  isModal: PropTypes.bool,
  providerData: PropTypes.shape({
    email: PropTypes.any,
    phone: PropTypes.any,
  }),
};

export default NonAcuityContent;
