//

import PropTypes from "prop-types";
import { assessments as assessmentConstants } from "@spring/constants";
import React, { useState, Component } from "react";
import Router from "next/router";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { createCheckIn } from "operations/mutations/assessment";
import { getUserInfo } from "operations/queries/user";
import { Assessment } from "components/assessments/";
import routes from "routes";
import { getAssessmentToTake } from "operations/queries/assessment";
import { startAssessment } from "operations/mutations/assessment";
import { datadogAddAction } from "lib/datadog-setup";
import Meowth from "@spring/meowth";
import { getIterableCampaignInfo } from "utils/localStorage";

const { assessmentStatuses } = assessmentConstants;

class AssessmentWrapper extends Component {
  static propTypes = {
    createCheckIn: PropTypes.func,
    data: PropTypes.shape({
      assessment: PropTypes.shape({
        current_questionnaire: PropTypes.any,
        id: PropTypes.any,
        kind: PropTypes.any,
        num_prompts_answered: PropTypes.any,
        num_prompts_total: PropTypes.any,
        questionnaires: PropTypes.any,
        status: PropTypes.any,
        version_number: PropTypes.number,
      }),
      refetch: PropTypes.func,
    }),
    initialData: PropTypes.any,
    startAssessment: PropTypes.func,
    static: PropTypes.any,
    helpClickBtnHandler: PropTypes.func,
  };

  state = {
    started: false,
  };

  componentDidMount() {
    const status = this.props?.data?.assessment?.status ?? null;
    if (status === assessmentStatuses.STARTED) {
      this.setState({ started: true });
    }
    if (status === assessmentStatuses.FINISHED) {
      if (this.shouldCreateGoalCheckIn()) {
        return this.createGoalCheckIn();
      } else {
        return Router.replace(routes.MemberHome.to, routes.MemberHome.as);
      }
    }
    if (status !== assessmentStatuses.STARTED) {
      this.setStarted();
    }
  }

  // Set the assessment to the started status
  setStarted = () => {
    this.props.startAssessment(this.props.data.assessment.id).then(async () => {
      await this.props.data.refetch();
      this.setState({ started: true });
    });
  };

  shouldCreateGoalCheckIn = () => {
    return [
      new URLSearchParams(window.location.search).get("utm_medium"),
      new URLSearchParams(
        sessionStorage.getItem("initialTargetSearchParams"),
      ).get("utm_medium"),
    ].includes("email");
  };

  createGoalCheckIn = () => {
    const member_id = this.props.getUserInfo?.user?.member?.id;

    let { to, as } = routes.TakeMemberAssessment;
    this.props
      .createCheckIn(member_id)
      .then(async (res) => {
        const id = res.data.createCheckIn.assessment.id;

        if (res.data.createCheckIn.success) {
          as = as.replace(":id", id);
          return Router.replace(
            {
              pathname: to,
              query: { id },
            },
            as,
          );
        }
      })
      .catch((error) => {
        datadogAddAction("take_assessment_page_create_check_in_error", {
          error: error,
        });
        return Router.replace(routes.MemberHome.to, routes.MemberHome.as);
      });
  };

  render() {
    if (!this.state.started) {
      return null; // Current implementation can't handle a lack of 'current_questionnaire' at the start of the assessment
    }
    return (
      <Assessment
        static={this.props.static}
        formKey={`assessment-${this.props.data.assessment.id}`}
        assessmentId={this.props.data.assessment.id}
        assessmentKind={this.props.data.assessment.kind}
        assessmentVersion={this.props.data.assessment.version_number}
        initialData={this.props.initialData}
        questionnaires={this.props.data.assessment.questionnaires}
        currentQuestionnaire={this.props.data.assessment.current_questionnaire}
        status={this.props?.data?.assessment?.status ?? null}
        numPromptsTotal={this.props.data.assessment.num_prompts_total}
        numPromptsAnswered={this.props.data.assessment.num_prompts_answered}
        showGoalsLast={this.props.showGoalsLast}
        helpClickBtnHandler={this.props.helpClickBtnHandler}
        t={this.props.t}
        setFileName={this.props.setFileName}
      />
    );
  }
}

const TakeAssessment = (props) => {
  const [redirecting, setRedirecting] = useState(false);

  const redirect = () => {
    // It's possible that a user who is NOT signed in, gets to this page via the email CTA,
    // but is ultimately redirected since they are not signed in. We need the query params
    // from their search to navigate them to a new or existing check-in once they have logged in.
    sessionStorage.setItem("initialTargetSearchParams", window.location.search);

    Router.replace(routes.MemberHome.to, routes.MemberHome.as);
    setRedirecting(true);
  };

  if (
    !props?.data?.loading &&
    props?.data?.assessment // there's a real assessment
  ) {
    return (
      <AssessmentWrapper
        helpClickBtnHandler={props.helpClickBtnHandler}
        t={props.t}
        setFileName={props.setFileName}
        {...props}
      />
    );
  } else if (
    !redirecting &&
    !props?.data?.loading &&
    !props?.data?.assessment // there's no assessment!!!
  ) {
    // redirect
    redirect();
    return null;
  }
  return null;
};

export { TakeAssessment };

export default compose(
  graphql(startAssessment, {
    props: ({ mutate }) => ({
      startAssessment: (id) => {
        const input = { id };

        return mutate({
          variables: { input },
        });
      },
    }),
  }),
  graphql(getAssessmentToTake, {
    skip: Meowth.apolloSkipUserId,
    options: (props) => {
      return {
        fetchPolicy: "network-only",
        variables: { id: props.id },
      };
    },
    props: ({ ownProps, data }) => {
      let initialData = {};
      return { ownProps, data, initialData };
    },
  }),
  graphql(getUserInfo, {
    name: "getUserInfo",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(createCheckIn, {
    props: ({ mutate }) => ({
      createCheckIn: (member_id) =>
        mutate({
          variables: {
            member_id: member_id,
            campaign: getIterableCampaignInfo(),
          },
        }),
    }),
  }),
)(TakeAssessment);
