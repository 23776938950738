export const VanillaCelebration = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="vanilla-celebration-icon"
  >
    <circle cx="40" cy="5" r="5" fill="#068464" />
    <path
      d="M62.3639 58C58.8727 53.9026 50.1759 50 40 50C37.9282 50 37 51 37 51L31.5 61L32.5 63H45.5L62.3639 58Z"
      fill="#068464"
    />
    <path
      d="M64.6646 31.8272L69 20L40 51L51.1568 46.1511C57.4291 43.4251 62.3108 38.2484 64.6646 31.8272Z"
      fill="#068464"
    />
    <path
      d="M15.3354 31.8272L11 20L34.8549 45.5L32 47.5231L28.8432 46.1511C22.5709 43.4251 17.6892 38.2484 15.3354 31.8272Z"
      fill="#068464"
    />
    <path
      d="M40 51L40 23"
      stroke="#11383B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 79H62"
      stroke="#11383B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="path-7-inside-1_920_21683" fill="white">
      <path d="M49 12C50.6569 12 52.0369 13.3635 51.6269 14.9689C51.486 15.5209 51.3056 16.0635 51.0866 16.5922C50.4835 18.0481 49.5996 19.371 48.4853 20.4853C47.371 21.5996 46.0481 22.4835 44.5922 23.0866C43.1363 23.6896 41.5759 24 40 24C38.4241 24 36.8637 23.6896 35.4078 23.0866C33.9519 22.4835 32.629 21.5996 31.5147 20.4853C30.4004 19.371 29.5165 18.0481 28.9134 16.5922C28.6944 16.0635 28.514 15.5209 28.3731 14.9689C27.9631 13.3635 29.3431 12 31 12L40 12H49Z" />
    </mask>
    <path
      d="M49 12C50.6569 12 52.0369 13.3635 51.6269 14.9689C51.486 15.5209 51.3056 16.0635 51.0866 16.5922C50.4835 18.0481 49.5996 19.371 48.4853 20.4853C47.371 21.5996 46.0481 22.4835 44.5922 23.0866C43.1363 23.6896 41.5759 24 40 24C38.4241 24 36.8637 23.6896 35.4078 23.0866C33.9519 22.4835 32.629 21.5996 31.5147 20.4853C30.4004 19.371 29.5165 18.0481 28.9134 16.5922C28.6944 16.0635 28.514 15.5209 28.3731 14.9689C27.9631 13.3635 29.3431 12 31 12L40 12H49Z"
      stroke="#11383B"
      strokeWidth="4"
      mask="url(#path-7-inside-1_920_21683)"
    />
    <mask id="path-8-inside-2_920_21683" fill="white">
      <path d="M43 12C44.6569 12 46.022 13.3487 45.8105 14.992C45.7405 15.5361 45.6513 16.0708 45.5433 16.5922C45.2417 18.0481 44.7998 19.371 44.2426 20.4853C43.6855 21.5996 43.0241 22.4835 42.2961 23.0866C41.5681 23.6896 40.7879 24 40 24C39.2121 24 38.4319 23.6896 37.7039 23.0866C36.9759 22.4835 36.3145 21.5996 35.7574 20.4853C35.2002 19.371 34.7583 18.0481 34.4567 16.5922C34.3487 16.0708 34.2595 15.5361 34.1895 14.992C33.9779 13.3487 35.3431 12 37 12L40 12H43Z" />
    </mask>
    <path
      d="M43 12C44.6569 12 46.022 13.3487 45.8105 14.992C45.7405 15.5361 45.6513 16.0708 45.5433 16.5922C45.2417 18.0481 44.7998 19.371 44.2426 20.4853C43.6855 21.5996 43.0241 22.4835 42.2961 23.0866C41.5681 23.6896 40.7879 24 40 24C39.2121 24 38.4319 23.6896 37.7039 23.0866C36.9759 22.4835 36.3145 21.5996 35.7574 20.4853C35.2002 19.371 34.7583 18.0481 34.4567 16.5922C34.3487 16.0708 34.2595 15.5361 34.1895 14.992C33.9779 13.3487 35.3431 12 37 12L40 12H43Z"
      stroke="#11383B"
      strokeWidth="4"
      mask="url(#path-8-inside-2_920_21683)"
    />
    <path
      d="M44.582 39.5418L40 51L51.1568 46.1511C57.4291 43.4251 62.3108 38.2484 64.6646 31.8272L69 20L56.2342 26.6578C50.9349 29.4216 46.8011 33.9924 44.582 39.5418Z"
      stroke="#11383B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40 51L35.418 39.5418C33.1989 33.9924 29.0651 29.4216 23.7658 26.6578L11 20L15.3354 31.8272C17.6892 38.2484 22.5709 43.4251 28.8432 46.1511L31.9787 47.5138"
      stroke="#11383B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4483 64.638L8 79H26.6522L31.2 71.8133L43.2893 70.5085C46.3998 70.1727 49.4145 69.2321 52.164 67.7395L63.4588 61.6081C65.1078 60.7129 64.5874 58.2218 62.718 58.0615C62.2436 58.0209 61.7658 58.0702 61.3097 58.2068L49.6996 61.6842C47.4654 62.3534 45.1456 62.6933 42.8134 62.6933H33.3015C32.3682 62.6933 31.7855 61.6824 32.2533 60.8749L38.3301 50.3831C39.2353 48.8202 38.6354 46.8177 37.02 46.01C35.8137 45.4068 34.3567 45.6433 33.4031 46.5969L20.1641 59.8359C18.7272 61.2728 17.4786 62.8864 16.4483 64.638Z"
      stroke="#11383B"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
