import { Volume } from "./controls/Volume";
import { Timeline } from "./controls/Timeline";
import { ToggleButton, ToggleButtonProps } from "./controls/ToggleButton";
import { Group, GroupProps } from "./Group";
import { Row, RowProps } from "./Row";
import { Footer, FooterProps } from "./Footer";
import { Header, HeaderProps } from "./Header";
import { Flex, Box } from "@chakra-ui/react";
import { useVideoPlayer } from "../../context/videoPlayerContext";
import { FC, ReactNode, Children, isValidElement, cloneElement } from "react";

interface PlayerControlsProps {
  children: ReactNode;
}

interface PlayerControlsComponent extends FC<PlayerControlsProps> {
  Header: FC<React.PropsWithChildren<HeaderProps>>;
  Footer: FC<React.PropsWithChildren<FooterProps>>;
  Row: FC<React.PropsWithChildren<RowProps>>;
  Group: FC<React.PropsWithChildren<GroupProps>>;
  Volume: FC<React.PropsWithChildren<unknown>>;
  Timeline: FC<React.PropsWithChildren<unknown>>;
  ToggleButton: FC<React.PropsWithChildren<ToggleButtonProps>>;
}

const PlayerControls: PlayerControlsComponent = (({ children }) => {
  const { showControls, setShowControls, isMobile, isPlaying, setIsPlaying } =
    useVideoPlayer();

  let header, footer, mainContent;

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === PlayerControls.Header) {
        header = cloneElement(child);
      } else if (child.type === PlayerControls.Footer) {
        footer = cloneElement(child);
      } else {
        mainContent = child;
      }
    }
  });

  const handleTap = () => {
    if (isMobile && showControls) {
      setShowControls(false);
    }
  };

  const handleClick = () => {
    if (!isMobile) {
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Flex
      position="relative"
      opacity={showControls || (!isPlaying && !isMobile) ? 1 : 0}
      pointerEvents={
        showControls || (!isPlaying && !isMobile) ? "auto" : "none"
      }
      transition={["opacity 0.3s", "opacity 0.0s"]}
      width="100%"
      height="100%"
      background="rgba(0, 0, 0, 0.56)"
      flexDirection="column"
      justifyContent="center"
      borderRadius="16px"
    >
      <Box
        position={"absolute"}
        width="100%"
        height="100%"
        onClick={handleClick}
        onTouchStart={handleTap}
      ></Box>
      {header}
      {mainContent}
      {footer}
    </Flex>
  );
}) as PlayerControlsComponent;

PlayerControls.Header = Header;
PlayerControls.Footer = Footer;
PlayerControls.Row = Row;
PlayerControls.Group = Group;
PlayerControls.Volume = Volume;
PlayerControls.Timeline = Timeline;
PlayerControls.ToggleButton = ToggleButton;

export { PlayerControls };
