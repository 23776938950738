import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

export interface RowProps {
  children: ReactNode;
}

export const Row: React.FC<React.PropsWithChildren<RowProps>> = ({
  children,
}) => {
  return (
    <Flex width="100%" justifyContent="space-between">
      {children}
    </Flex>
  );
};

Row.displayName = "Row";
