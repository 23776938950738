/**
 * NOTE: @param are props
 * NOTE: RHF refers to the react-hook-form library
 * @param {number}   [fcMb]       margin-bottom for <FormControl>
 * @param {required} [required]   Used w/ 'errors' to display <FormErrorMessage> if true, and 'errors' true
 * @param {object}   errors       Passed from RHF - will be passed if input has error
 * @param {string}   name         Important - this the key for the input value that gets Submitted
 * @param {string}   label        Text value for <FormLabel>
 * @param {boolean}  hideLabel    Hide label or not - used for a11y compliance
 * @param {function} register     Important - RHF function that registers the key (i.e 'name' prop)
 * @param {object}   validation   Important - this object sets validation used by RHF, pass false if not needed
 * @param {string}   inputType    String to set HTML input type, use standard HTML input values (i.e text, number etc)
 * @param {string}   autoComplete String to set HTML autocomplete tag, use standard HTML autocomplete values
 * @param {boolean}  [disabled]   Set input to disabled
 * @param {boolean}  [isSubLabel] Set label styles to those of a sub-label
 * @param {string}  [placeholder] Set placeholder to the input
 * @param {string}  textTransform Set textTransform to the input
 * @param {(number|string)}  [height]      Set height to the input
 * @param {number}   [tabIndex]   Set the tabIndex of the input
 * @param {string}   [variant]    The design system variant of the input field

 * NOTE: This component must be nested in a react-hook-form form
   in order to fully work. It is meant for broad use and thus
   should be expanded very sparingly.

 * NOTE: Renders a label and input with validation and RHF functionality.
*/

import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  VisuallyHidden,
} from "../../../index";

export const SHTextInput = ({
  fcMb,
  required,
  errors,
  name,
  label,
  hideLabel,
  register,
  validation,
  inputType,
  autoComplete,
  disabled,
  placeholder,
  dataCy,
  onBlur,
  isSubLabel,
  textTransform,
  height,
  tabIndex,
  variant,
}) => {
  return (
    <FormControl
      mb={fcMb}
      isRequired={required ? true : false}
      isInvalid={required ? errors : false}
    >
      {label && hideLabel ? (
        <VisuallyHidden>
          <FormLabel htmlFor={name} requiredIndicator={required ? true : false}>
            {label}
          </FormLabel>
        </VisuallyHidden>
      ) : (
        <FormLabel
          fontSize={isSubLabel ? "14px" : "16px"}
          fontWeight={isSubLabel ? "400" : "600"}
          htmlFor={name}
          requiredIndicator={
            required ? (
              <span style={{ marginLeft: "4px" }} title="required field">
                *
              </span>
            ) : null
          }
        >
          {label}
        </FormLabel>
      )}

      <Input
        {...register(name, { ...validation })}
        id={name}
        type={inputType}
        variant={variant ?? "outline"}
        placeholder={placeholder}
        colorScheme={errors ? "error" : "platform"}
        color="platform.900"
        autoComplete={autoComplete}
        isDisabled={disabled}
        disabled={disabled}
        data-cy={dataCy}
        onBlur={onBlur}
        aria-label={`${name.replace("_", "-")}-input`}
        textTransform={textTransform}
        {...(tabIndex && { tabIndex })}
        {...(height && { height })}
        required={required}
      />
      {errors && (
        <FormErrorMessage color="error.base" fontWeight="bold">
          {errors?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

SHTextInput.propTypes = {
  fcMb: PropTypes.number,
  required: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  register: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  inputType: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  isSubLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  textTransform: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabIndex: PropTypes.number,
  variant: PropTypes.string,
};
