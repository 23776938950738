import { graphql } from "modules/shared/graphql-codegen";

export const getCNsByStartTimes = graphql(`
  query getCNsByStartTimes(
    $member_id: ID!
    $start_date: DateTime!
    $sud_only: Boolean
  ) {
    all_available_cn_appointment_times_v2(
      member_id: $member_id
      start_date: $start_date
      sud_only: $sud_only
    ) {
      start_time
      care_provider {
        id
        user_id
      }
    }
  }
`);
