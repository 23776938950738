import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "design-system/components";
import { useInViewportOnce } from "hooks";
import { DateTime } from "luxon";
import { trackAppointmentSlotViewed } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { getAppointmentKindByProviderRole } from "@spring/constants";
import { formatDateWithPascalCaseMonth } from "modules/MemberDashboard/CareVisits/AppointmentDetails/utils/appointmentDetails.util";

type Props = {
  index: number;
  time: string;
  provider: any;
  providerIndex: number;
  pageNumber: number;
  queryRequestId: string;
  appointmentMedium: string;
  trackingCategory: string;
  onClick: (time: string, index: number, appointmentMedium: string) => {};
};

type MemberPortalReduxState = {
  global: {
    lang?: string;
    [key: string]: any;
  };
  [key: string]: any;
};

export const AppointmentSlot = ({
  index,
  time,
  provider,
  providerIndex,
  pageNumber,
  queryRequestId,
  appointmentMedium,
  trackingCategory,
  onClick,
}: Props) => {
  const { providerType } = useProviderBrowseContext();
  const appointmentKind = getAppointmentKindByProviderRole(providerType);
  const locale = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.lang,
  );

  const localDateTime = DateTime.fromISO(time).setLocale(locale);
  const formattedTime = formatDateWithPascalCaseMonth(
    localDateTime.toFormat("EEEE, LLLL dd - h:mm a"),
  );

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible

  const [trackedOnce, setTrackedOnce] = useState(0);

  const trackingData = {
    index,
    time,
    provider,
    providerIndex,
    pageNumber,
    queryRequestId,
    appointmentMedium,
    appointmentKind,
    trackingCategory,
  };

  useEffect(() => {
    if (inViewport && trackedOnce === 0) {
      // directsched03
      trackAppointmentSlotViewed(trackingData);
      setTrackedOnce(1);
    }
  }, [inViewport]);

  return (
    <Box
      borderRadius="lg"
      border="2px solid"
      borderColor="platform.400"
      mb={16}
      py={0}
      _hover={{
        background: "tertiary.light",
      }}
      ref={ref}
    >
      <Button
        w="100%"
        py={27}
        variant="ghost"
        borderColor="transparent"
        color="tertiary.dark"
        _hover={{
          color: "tertiary.dark",
        }}
        onClick={() => onClick(time, index, appointmentMedium)}
        data-cy="new-appointments-slot"
        aria-label={formattedTime}
      >
        {formattedTime}
      </Button>
    </Box>
  );
};

export default AppointmentSlot;
