import yup from "schemas/yup";

export function minAgeDOB(allowedAge) {
  // A11y TODO: this needs to be translated
  const errorMessage = `Date invalid (must be at least ${allowedAge})`;

  function isDateComplete(date) {
    return !!(date.month && date.day && date.year);
  }

  function isMaxYear(year) {
    const maxYear = new Date().getFullYear() - allowedAge;
    return year === maxYear;
  }

  function isMaxMonth(month) {
    return month === new Date().getMonth() + 1;
  }

  return yup.object().shape({
    month: yup.lazy((_value, { parent }) => {
      if (isDateComplete(parent) && isMaxYear(parent.year)) {
        return yup
          .number()
          .min(1, errorMessage)
          .max(new Date().getMonth() + 1, errorMessage)
          .required(errorMessage);
      }
      return yup.number().min(1).max(12).required();
    }),
    day: yup.lazy((_value, { parent }) => {
      if (
        isDateComplete(parent) &&
        isMaxYear(parent.year) &&
        isMaxMonth(parent.month)
      ) {
        return yup
          .number()
          .min(1, errorMessage)
          .max(new Date().getDate(), errorMessage)
          .required();
      }
      return yup.number().min(1, errorMessage).max(31, errorMessage).required();
    }),
    year: yup
      .number()
      .min(1900)
      .max(new Date().getFullYear() - allowedAge, errorMessage)
      .required(),
  });
}
