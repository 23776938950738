// 

import React from 'react';
import classnames from 'classnames';

import Base from '../Base';

import styles from './styles.module.scss';


const CallToAction = (props) => (
	<Base
		flat
		color=""
		className={classnames(styles.default, {
			[styles.secondary]: props.secondary,
		})}
		onClick={props.onClick}
		text={props.text}
		icon={props.icon}
		debounce={props.debounce}
		full={props.full}
		mini={props.mini}
	/>
);

export default CallToAction;
