export const BoeingSDOHZendeskForm =
  "https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=25427958444692";
export const TargetSDOHZendeskForm =
  "https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=23929859966484";
export const SupportPageSubmitRequest =
  "https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=14448838263956";
export const GettingStartedInTherapyArticles =
  "https://careteam.springhealth.com/hc/en-us/categories/24231941591188-Getting-Started-in-Therapy";
export const BillingArticles =
  "https://careteam.springhealth.com/hc/en-us/categories/15545046525972-Spring-Health-Billing";
export const InsuranceArticles =
  "https://careteam.springhealth.com/hc/en-us/categories/15545044898836-Insurance-Questions";
