import { useTranslation } from "react-i18next";

export const getPhoneStyleForRTL = () => {
  const { i18n } = useTranslation();

  const rtlLayoutPhoneNumberStyle = {
    direction: "rtl",
    unicodeBidi: "bidi-override",
  };
  const isLayoutRTL = i18n.dir() === "rtl";

  if (isLayoutRTL) {
    return rtlLayoutPhoneNumberStyle;
  }
  return {};
};
