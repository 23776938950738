//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import {
  Card,
  Stout,
  Button,
  FlexCol,
  Section,
  Grid,
  Col,
  Checklist,
  Subtitle,
} from "@spring/smeargle";
import { addNotification } from "@spring/smeargle/actions";
import { withTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { Link } from "components";
import { updateOffboardingPreferences } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";
import envUtils from "utils/environment";

const questions = {
  1: "carePlanSteps.offboarding.questions.1",
  2: "carePlanSteps.offboarding.questions.2",
  "3a": "carePlanSteps.offboarding.questions.3a",
  "3b": "carePlanSteps.offboarding.questions.3b",
  "4a": "carePlanSteps.offboarding.questions.4a",
  "4b": "carePlanSteps.offboarding.questions.4b",
};

const continueWithCare = "continue_with_care";
const continueWithSpring = "continue_with_spring";
const findProviderHelp = "find_provider_help";
const preferOutOfPocketPayments = "prefer_out_of_pocket_payments";

class OffboardingStep extends Component {
  static propTypes = {
    addNotification: PropTypes.func,
    memberId: PropTypes.any,
    t: PropTypes.func,
    updateOffboardingPreferences: PropTypes.func,
  };

  state = {
    question: questions["1"],
  };

  updateOffboarding = (field, data) => {
    this.props
      .updateOffboardingPreferences({
        variables: {
          member_id: this.props.memberId,
          offboarding_preferences: {
            [field]: data,
          },
        },
      })
      .then((res) => {
        if (!res.data.updateOffboardingPreferences.success) {
          this.props.addNotification(
            this.props.t("carePlanSteps.offboarding.notification.error"),
            "error",
          );
        }

        // show message in these cases:
        // if member doesn't want to continue with care
        // if member doesn't want help to find provider
        // if member prefers out of pocket payments
        if (
          (field === continueWithCare && !data) ||
          (field === findProviderHelp && !data) ||
          (field === preferOutOfPocketPayments && data)
        ) {
          this.props.addNotification(
            this.props.t("carePlanSteps.offboarding.notification.success"),
            "success",
          );
        }
      })
      .catch((err) => {
        this.props.addNotification(getFirstError(err), "error");
      });
  };

  handleNo = () => {
    if (this.state.question === questions["1"]) {
      this.updateOffboarding(continueWithCare, false);
    } else if (this.state.question === questions["3b"]) {
      this.updateOffboarding(findProviderHelp, false);
    } else if (this.state.question === questions["3a"]) {
      this.updateOffboarding(preferOutOfPocketPayments, true);
    } else {
      this.setState({
        question: questions["3b"],
      });

      this.updateOffboarding(continueWithSpring, false);
    }
  };

  handleYes = () => {
    if (this.state.question === questions["1"]) {
      this.setState({
        question: questions["2"],
      });

      this.updateOffboarding(continueWithCare, true);
    } else if (this.state.question === questions["3b"]) {
      this.setState({
        question: questions["4b"],
      });

      this.updateOffboarding(findProviderHelp, true);
    } else if (this.state.question === questions["3a"]) {
      this.setState({
        question: questions["4a"],
      });

      this.updateOffboarding(preferOutOfPocketPayments, false);
    } else {
      this.setState({
        question: questions["3a"],
      });

      this.updateOffboarding(continueWithSpring, true);
    }
  };

  get inputs() {
    const { t } = this.props;
    if (
      this.state.question === questions["4a"] ||
      this.state.question === questions["4b"]
    ) {
      return (
        <div className={styles.listWrapper}>
          <FlexCol alignment="center" justification="center">
            <Checklist
              listItems={t("carePlanSteps.offboarding.insuranceItems", {
                returnObjects: true,
              })}
            />
            <Section size="lg">
              <Link to={`mailto:${envUtils.careTeamEmail}`}>
                <Button
                  text={t("carePlanSteps.offboarding.inputs.email", {
                    careTeamEmail: envUtils.careTeamEmail,
                  })}
                  icon="arrow-right"
                />
              </Link>
            </Section>
          </FlexCol>
        </div>
      );
    } else if (this.state.question === questions["3a"]) {
      return (
        <div className={styles.inputsWrapper}>
          <Grid>
            <Col md={6}>
              <Button
                text={t("carePlanSteps.offboarding.inputs.outOfPocket")}
                full
                icon="arrow-right"
                onClick={this.handleNo}
              />
            </Col>

            <Col md={6}>
              <Button
                text={t("carePlanSteps.offboarding.inputs.checkInsurance")}
                full
                icon="arrow-right"
                onClick={this.handleYes}
              />
            </Col>
          </Grid>
        </div>
      );
    }

    return (
      <div className={styles.inputsWrapper}>
        <Button
          text={t("carePlanSteps.offboarding.inputs.no")}
          secondary
          full
          icon="arrow-right"
          onClick={this.handleNo}
        />
        <Button
          text={t("carePlanSteps.offboarding.inputs.yes")}
          full
          icon="arrow-right"
          onClick={this.handleYes}
        />
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Card>
        <FlexCol alignment="center">
          <Section size="lg">
            <div className={styles.stoutWrapper}>
              <Stout inheritColor>{t("carePlanSteps.offboarding.title")}</Stout>
            </div>
          </Section>
          <Subtitle center bold noMargin>
            {t(this.state.question)}
          </Subtitle>
        </FlexCol>
        <Section size="xlg">{this.inputs}</Section>
      </Card>
    );
  }
}

export { OffboardingStep };

export default compose(
  graphql(updateOffboardingPreferences, {
    name: "updateOffboardingPreferences",
  }),
  connect(null, { addNotification }),
)(withTranslation("carePlan")(OffboardingStep));
