import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import {
  Box,
  FormControl,
  FormLabel,
  Spacer,
  useMediaQuery,
} from "design-system/components";
import { Switch } from "@chakra-ui/react";
import { TRACK_EVENT } from "utils/mixpanel";
import { trackCompanyExclusiveClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";

import styles from "./styles.module.scss";

const DedicatedProviderSwitch = ({
  isDedicatedProviderToggleOn,
  setIsDedicatedProviderToggleOn,
  isNewBrowse,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 960px)");
  const companyExclusive = "Company-exclusive";
  const dedicatedText = "Only sees individuals from your company";
  const brecsVariantFlag = useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT);
  const isBrecsFlag = isBrecsVariant(brecsVariantFlag);
  const brecsOldFilters =
    useFeatureFlag(FLAGS.BRECS_FILTERS_UPDATE) && isBrecsFlag;

  const handleOnChange = () => {
    if (brecsOldFilters) {
      trackCompanyExclusiveClicked(isDedicatedProviderToggleOn);
    } else {
      TRACK_EVENT.FILTER_CHANGE_APPLIED(
        window.location.pathname,
        "Dedicated provider",
        {
          change: isDedicatedProviderToggleOn ? "Removed" : "Added",
          value: "Dedicated provider",
        },
      );
    }

    setIsDedicatedProviderToggleOn(!isDedicatedProviderToggleOn);
  };

  return (
    <div
      className={classnames(styles.filterButton, {
        [styles.newBrowseDedicatedSwitch]: isNewBrowse,
      })}
    >
      <FormControl alignItems="center" display="flex">
        <FormLabel cursor="pointer" htmlFor="dedicated-providers" mb={"0px"}>
          {companyExclusive}
          {!isMobile && (
            <Box display="inline" fontWeight="normal" ml={"12px"}>
              {dedicatedText}
            </Box>
          )}
        </FormLabel>
        <Spacer />
        <Switch
          colorScheme="primary"
          id="dedicated-providers"
          isChecked={isDedicatedProviderToggleOn}
          ml={"12px"}
          onChange={handleOnChange}
          sx={{
            ".chakra-switch__track": {
              _focus: { boxShadow: "0 0 0 3px black" },
            },
          }}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
        />
      </FormControl>
    </div>
  );
};

DedicatedProviderSwitch.propTypes = {
  isDedicatedProviderToggleOn: PropTypes.bool,
  setIsDedicatedProviderToggleOn: PropTypes.func,
  isNewBrowse: PropTypes.bool,
};

export { DedicatedProviderSwitch };
