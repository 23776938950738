import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

export interface GroupProps {
  children?: ReactNode;
  justifyContent?: "start" | "center" | "end";
}

export const Group: React.FC<React.PropsWithChildren<GroupProps>> = ({
  children,
  justifyContent = "start",
}) => {
  return <Flex justifyContent={justifyContent}>{children}</Flex>;
};

Group.displayName = "Group";
