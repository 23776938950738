// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const ProgressBar = (props) => {
	const getWidth = () => {
		const { current, min, max } = props;

		return (current - min) / (max - min) * 100;
	};

	return (
		<div
			className={
				classnames(
					styles.progressBackground,
					styles[props.size],
					styles[props.status],
				)
			}
		>
			<span
				className={classnames(
					styles.progressBar,
					styles[props.status],
					{ [styles.hideProgressRadius]: props.hideProgressRadius },
				)}
				style={{ width: `${getWidth()}%` }}
			/>
			<span
				className={styles.overlay}
			>
				{props.overlay}
			</span>
		</div>
	);

};

ProgressBar.defaultProps = {
	min     : 0,
	max     : 100,
	overlay : '',
	size    : 'lg',
	status  : 'success',
};

export default ProgressBar;
