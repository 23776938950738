/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-indent-props */
import { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import Meowth from "@spring/meowth";
import { SHStickyFooter } from "design-system/components";
import { Button } from "@springcare/sh-component-library";
import {
  HStack,
  VStack,
  Text,
  Box,
  Flex,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";
import { AssessmentResultsContext } from "./YourAssessmentResults";
import {
  addCustomFlag,
  get_property,
  register,
  TRACK_EVENT,
} from "utils/mixpanel";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getMemberInfo } from "operations/queries/member";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";

const FooterButtons = ({
  primaryButtonText,
  secondaryButtonText,
  primaryOnClick,
  secondaryOnClick,
  primaryDataCy,
  secondaryDataCy,
  secondaryButtonVariant = "medium-emphasis",
  verticalView = false,
}) => {
  const PrimaryButton = () => (
    <Button
      variant="high-emphasis"
      me={verticalView ? 0 : 4}
      w={verticalView ? "100%" : "fit-content"}
      colorScheme="base"
      onClick={primaryOnClick}
      data-cy={primaryDataCy}
    >
      {primaryButtonText}
    </Button>
  );

  const SecondaryButton = () => (
    <Button
      variant={secondaryButtonVariant}
      colorScheme="base"
      w={verticalView ? "100%" : "fit-content"}
      ms={4}
      onClick={secondaryOnClick}
      data-cy={secondaryDataCy}
    >
      {secondaryButtonText}
    </Button>
  );

  return verticalView ? (
    <>
      <PrimaryButton />
      <SecondaryButton />
    </>
  ) : (
    <>
      <SecondaryButton />
      <PrimaryButton />
    </>
  );
};

const SUDFooter = ({
  t,
  trackSubstanceUsageDisorderClick,
  handleRoute,
  trackMemberResultsLinkClick,
  sudRoute,
  memberHomeRoute,
  openCNFlyout,
  isMobile,
}) => (
  <Flex
    w="100%"
    h="100%"
    alignItems="center"
    direction={{ base: "column", lg: "row" }}
  >
    <Box mb={{ base: 16, lg: 0 }}>
      <Text
        size="body-medium-strong"
        color="content-primary"
        display={{ base: "inline", lg: "block" }}
        mb={!isMobile && 8}
      >
        {t("footerCTA.substanceUseSupport.goalsRecommended")}
      </Text>
      {!isMobile && (
        <Text
          ms={{ lg: 0, base: 4 }}
          size="body-medium-strong"
          color="primary-base"
          display={{ base: "inline", lg: "block" }}
          cursor="pointer"
          role="button"
          onClick={() => {
            TRACK_EVENT.LINK_CLICKED(
              window.location.pathname,
              "Informational fly-out - Specialized care navigation",
              "Why specialized care navigation?",
              {
                spring_doc_id: "HPR_004",
              },
            );
            openCNFlyout();
          }}
        >
          {t("footerCTA.substanceUseSupport.whySpecializedCN")}
        </Text>
      )}
    </Box>
    <Spacer />
    <Flex
      w={{ base: "100%", lg: "auto" }}
      alignItems="center"
      gap={isMobile ? 8 : 16}
      justifyContent="center"
      direction={isMobile ? "column-reverse" : "row"}
    >
      <Button
        variant={isMobile ? "no-emphasis" : "medium-emphasis"}
        colorScheme="base"
        onClick={() => {
          const button_order = 1;
          trackMemberResultsLinkClick(button_order);
          handleRoute(memberHomeRoute);
        }}
      >
        {t("footerCTA.substanceUseSupport.personalizedCarePlan")}
      </Button>
      <Button
        variant="high-emphasis"
        colorScheme="base"
        onClick={() => {
          const button_order = 2;
          trackSubstanceUsageDisorderClick(button_order);
          handleRoute(sudRoute);
        }}
      >
        {t("footerCTA.substanceUseSupport.scheduleCall")}
      </Button>
    </Flex>
  </Flex>
);

const FooterCTA = ({
  t,
  Router,
  assessmentRiskScore,
  assignRiskScore,
  setIsSpecializedCNSupported,
  openCNFlyout,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 600px)");
  const [isMedium] = useMediaQuery("(max-width: 1150px)");
  const { data: memberInfoData, loading: memberInfoLoading } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );
  const country = memberInfoData?.user?.member?.postal_address?.country;
  const sudSupported =
    memberInfoData?.user?.member?.cohort?.contract_term?.sud_supported;

  const {
    isCoachingRecommended,
    isSUDRecommended,
    initialSUDRisk,
    specializedCNEnabled,
  } = useMemberBenefits(memberInfoData?.user?.member?.id);

  const hasSUDRisk = assignRiskScore(initialSUDRisk) < 3;
  const isAMinor = memberInfoData?.user?.member?.minor;
  const isGlobal = country && country.length > 0 && country !== "US";
  const shouldShowMPQuestionnaire = useFeatureFlag(FLAGS.MP_QUESTIONNAIRE);
  const showNewCoachingChanges = useFeatureFlag(
    FLAGS.LOB_COACHING_ASSESSMENT_RESULTS_CTA,
  );
  const showNewSUDChanges = useFeatureFlag(
    FLAGS.LOB_SUD_ASSESSMENT_RESULTS_CTA,
  );
  const showSUDPrimary = sudSupported && hasSUDRisk;
  const shouldUseBrecsRoute = isBrecsVariant(
    useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT),
  );
  const isNewHighAcuityCTA = useFeatureFlag(FLAGS.NEW_ASSESSMENT_RESULTS_CTA);

  const values = useContext(AssessmentResultsContext);
  let riskScore = assessmentRiskScore;
  let routes;
  if (values) {
    routes = values.routes;
  }

  const showNewHighAcuityCTA =
    isNewHighAcuityCTA && riskScore && riskScore <= 2;
  const showNewCTAMobileView = isMobile && showNewHighAcuityCTA;
  const showVerticalButtons = showNewCTAMobileView;
  const showCoachingPrimary = isCoachingRecommended && showNewCoachingChanges;

  const therapyRecsEligible = !isAMinor && !isGlobal;

  useEffect(() => {
    if (isCoachingRecommended || isSUDRecommended) {
      const feature_flags = get_property("feature_flags") || [];
      if (isCoachingRecommended) {
        localStorage.setItem(
          FLAGS.LOB_COACHING_ASSESSMENT_RESULTS_CTA.trackingName,
          showNewCoachingChanges.toString(),
        );
        addCustomFlag(
          FLAGS.LOB_COACHING_ASSESSMENT_RESULTS_CTA.trackingName,
          feature_flags,
          showNewCoachingChanges,
        );
      }
      if (isSUDRecommended) {
        localStorage.setItem(
          FLAGS.LOB_SUD_ASSESSMENT_RESULTS_CTA.trackingName,
          showNewSUDChanges.toString(),
        );
        addCustomFlag(
          FLAGS.LOB_SUD_ASSESSMENT_RESULTS_CTA.trackingName,
          feature_flags,
          showNewSUDChanges,
        );
      }
      register({ feature_flags });
    }
  }, [isCoachingRecommended, isSUDRecommended]);

  useEffect(() => {
    setIsSpecializedCNSupported(specializedCNEnabled);
  }, [specializedCNEnabled]);

  // route options
  const BROWSE_THERAPIST = "TherapistsBrowse";
  const MEMBER_HOME = "MemberHome";
  const RECOMMENDED_THERAPISTS = "RecommendedTherapists";
  const SUBSTANCE_USE_SUPPORT = "SUDCareNavigationScheduling";
  const BROWSE_COACHES = "CoachesBrowse";
  const carePlanRoute = shouldShowMPQuestionnaire
    ? RECOMMENDED_THERAPISTS
    : MEMBER_HOME;

  const trackBrowseTherapistButtonClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "Browse Therapists", {
      to: routes[BROWSE_THERAPIST].as,
      order: button_order,
    });
  };

  const trackTherapistRecsButtonClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "Recommended Therapists", {
      to: routes[RECOMMENDED_THERAPISTS].as,
      order: button_order,
    });
  };

  const trackMemberResultsLinkClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "See My Plan", {
      to: routes[MEMBER_HOME].as,
      order: button_order,
    });
  };

  const trackSubstanceUsageDisorderClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "Schedule call", {
      to: routes[SUBSTANCE_USE_SUPPORT].as,
      order: button_order,
    });
  };

  const trackBrowseCoachesClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "Browse Coaches", {
      to: routes[BROWSE_COACHES].as,
      order: button_order,
    });
  };

  const onClickAction = (buttonOrder) => {
    if (therapyRecsEligible && !shouldUseBrecsRoute) {
      trackTherapistRecsButtonClick(buttonOrder);
      handleRoute(RECOMMENDED_THERAPISTS);
      return;
    }

    trackBrowseTherapistButtonClick(buttonOrder);
    handleRoute(BROWSE_THERAPIST);
  };

  function handleRoute(route) {
    const { to, as, query } = routes[route];
    if (shouldShowMPQuestionnaire && query?.intro) {
      Router.push({ pathname: to, query }, as);
    } else {
      Router.push(to, as);
    }
  }

  if (memberInfoLoading) {
    return null;
  }

  const showSUDFooter = showSUDPrimary && !showCoachingPrimary;
  const getPadding = () => {
    if ((isMobile || isMedium) && showSUDFooter) {
      return "16px 24px 16px 24px";
    } else if (showSUDFooter) {
      return "32px 106px 32px 106px";
    } else {
      return "24px 28px 30px 28px";
    }
  };

  const getFooterContent = () => {
    if (showCoachingPrimary && !showSUDPrimary) {
      return (
        <FooterButtons
          primaryButtonText={t("footerCTA.browseCoachesButtonText")}
          secondaryButtonText={t("footerCTA.carePlanButtonText")}
          primaryOnClick={() => {
            const button_order = 1;
            trackBrowseCoachesClick(button_order);
            handleRoute(BROWSE_COACHES);
          }}
          secondaryOnClick={() => {
            const button_order = 2;
            trackMemberResultsLinkClick(button_order);
            handleRoute(MEMBER_HOME);
          }}
          primaryDataCy={"browse-coaches-button"}
          secondaryDataCy={"see-my-care-plan-button"}
        />
      );
    }
    if (!showCoachingPrimary && !showSUDPrimary && riskScore && riskScore > 2) {
      return (
        <FooterButtons
          primaryButtonText={t("footerCTA.carePlanButtonText")}
          secondaryButtonText={t("footerCTA.browseTherapistButtonText")}
          primaryOnClick={() => {
            const button_order = 1;
            trackMemberResultsLinkClick(button_order);
            handleRoute(carePlanRoute);
          }}
          secondaryOnClick={() => {
            const button_order = 2;
            onClickAction(button_order);
          }}
          primaryDataCy={"see-my-care-plan-button"}
          secondaryDataCy={"browse-therapists-button"}
        />
      );
    }
    if (showSUDFooter) {
      return (
        <SUDFooter
          t={t}
          trackSubstanceUsageDisorderClick={trackSubstanceUsageDisorderClick}
          handleRoute={handleRoute}
          trackMemberResultsLinkClick={trackMemberResultsLinkClick}
          sudRoute={SUBSTANCE_USE_SUPPORT}
          memberHomeRoute={MEMBER_HOME}
          openCNFlyout={openCNFlyout}
          isMobile={isMobile}
        />
      );
    }
    if (showNewHighAcuityCTA) {
      return (
        <FooterButtons
          primaryButtonText={t("footerCTA.viewTherapistButtonText")}
          secondaryButtonText={t("footerCTA.browseHomePageButtonText")}
          primaryOnClick={() => {
            const buttonOrder = 1;
            trackBrowseTherapistButtonClick(buttonOrder);
            handleRoute(BROWSE_THERAPIST);
          }}
          secondaryOnClick={() => {
            const button_order = 2;
            trackMemberResultsLinkClick(button_order);
            handleRoute(MEMBER_HOME);
          }}
          secondaryButtonVariant="low-emphasis"
          verticalView={showNewCTAMobileView}
        />
      );
    }
    return (
      <FooterButtons
        primaryButtonText={t("footerCTA.browseTherapistButtonText")}
        secondaryButtonText={t("footerCTA.carePlanButtonText")}
        primaryOnClick={() => {
          const button_order = 1;
          onClickAction(button_order);
        }}
        secondaryOnClick={() => {
          const button_order = 2;
          trackMemberResultsLinkClick(button_order);
          handleRoute(carePlanRoute);
        }}
      />
    );
  };

  return (
    <div role="navigation" aria-label="Footer Links">
      <SHStickyFooter hasShadow>
        {showVerticalButtons ? (
          <VStack
            w="100%"
            h={
              showSUDFooter || showNewCTAMobileView ? "fit-content" : [102, 120]
            }
            alignItems="center"
            justifyContent={["center", "flex-end"]}
            padding={getPadding()}
            gap={8}
          >
            {getFooterContent()}
          </VStack>
        ) : (
          <HStack
            w="100%"
            h={showSUDFooter ? "fit-content" : [102, 120]}
            alignItems="center"
            justifyContent={["center", "flex-end"]}
            padding={getPadding()}
            gap={8}
          >
            {getFooterContent()}
          </HStack>
        )}
      </SHStickyFooter>
    </div>
  );
};

FooterCTA.propTypes = {
  Router: PropTypes.object,
  styles: PropTypes.object,
  t: PropTypes.func,
};

export default FooterCTA;
