import React from "react";
import { Section, Subhead, Button, Content, Reader } from "@spring/smeargle";
import { SpringTextLogo } from "design-system";
import styles from "./styles.module.scss";
import { Link } from "components/atoms";
import { FrownSvg } from "components/atoms/Assets/svgs";
import { useTranslation, Trans } from "react-i18next";

import envUtils from "utils/environment";

const MainContent = () => {
  const { t } = useTranslation("memberTermination");
  return (
    <div className={styles.mainContent}>
      <Reader>
        <div className={styles.frownSvg}>
          <FrownSvg />
        </div>
        <Subhead center bold>
          <span className={styles.title}>{t("deactivatedPage.heading")}</span>
        </Subhead>
        <Section>
          <div className={styles.body}>
            <Content>
              <Trans
                ns="memberTermination"
                i18nKey="deactivatedPage.body.questions"
                components={[
                  <Link key={"0"} to={`mailto:${envUtils.careTeamEmail}`} />,
                ]}
              />
            </Content>
          </div>
          <Link alias="Logout" className={styles.button}>
            <Button full text={t("deactivatedPage.button")} key="logout" />
          </Link>
        </Section>
      </Reader>
    </div>
  );
};

const DeactivatedPage = () => {
  return (
    <div>
      <div className={styles.logo}>
        <SpringTextLogo componentFrom="DeactivatedPage" />
      </div>
      <MainContent />
    </div>
  );
};

export default DeactivatedPage;
