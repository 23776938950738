import { memberPrevisitDisplayValues as displayValues } from "constants/displayValues";

import { countries } from "@spring/constants";

import router from "next/router";

export const formatMemberPrevisitData = (path, data, append = "") => {
  let formattedData = null;
  if (typeof data === "number" || typeof data === "boolean") {
    formattedData = String(data);
  } else if (typeof data === "string") {
    formattedData = data;
  }

  if (!formattedData) {
    return null;
  }

  if (
    formattedData &&
    displayValues[path] &&
    displayValues[path][formattedData]
  ) {
    formattedData = displayValues[path][formattedData];
  }

  return formattedData + append;
};

export const formatMiles = (miles, isShort) => {
  let mileText = miles <= 1 ? "mile" : "miles";
  if (isShort) {
    mileText = mileText.charAt(0).toUpperCase() + mileText.slice(1);
  }
  return { distance: miles, distanceUnit: mileText };
};

export const formatKilometers = (kilometers, isShort) => {
  const formattedKilometers = Math.floor(kilometers);
  let kilometerText = formattedKilometers <= 1 ? "kilometer" : "kilometers";
  if (isShort) {
    kilometerText =
      kilometerText.charAt(0).toUpperCase() + kilometerText.slice(1);
  }
  return { distance: formattedKilometers, distanceUnit: kilometerText };
};

export const formatMemberExactAge = (dateOfBirth) => {
  const today = new Date();
  const birthday = new Date(dateOfBirth);
  const month = today.getMonth() - birthday.getMonth();
  let age = today.getFullYear() - birthday.getFullYear();

  if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }

  return age;
};

export const formatMemberAddress = (memberData) => {
  if (!memberData?.user?.member?.postal_address) return null;

  const { street_address_1, city, state, zip_code, country } =
    memberData.user.member.postal_address;
  return [street_address_1, city, state, zip_code, country]
    .filter(Boolean)
    .join(", ");
};

export function hasICModalClosed() {
  return sessionStorage.getItem("icModalClosed") === "true";
}

export function closeICModal() {
  sessionStorage.setItem("icModalClosed", "true");
}

export const filterTagsByProviderType = (careProviderTagData, providerType) =>
  careProviderTagData?.care_provider_tags?.data?.filter((tag) => {
    return tag.roles.includes(providerType) || !tag.roles.length;
  });

export const removeCountryCodeFromPhone = (phone, countryCode) => {
  const countryEntry =
    countries.find((entry) => entry.iso2 === countryCode.toLowerCase()) || {};
  const dialCode = countryEntry.dialCode;
  if (phone.startsWith(`+${dialCode}`)) {
    return phone.replace(`+${dialCode}`, "");
  }
  return phone;
};

export function formatPageTitle(route) {
  // takes last string in URL and returns a string for a page title
  // i.e /members/care_providers => Spring Health - Care Providers Page
  // replaces underscores and dashes to create separate, capitalized, words
  const title = route.substring(route.lastIndexOf("/") + 1);
  if (title !== "[id]") {
    return `Spring Health - ${title.replace(/[_-]/g, " ").replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}`;
  } else if (title === "[id]") {
    return "Spring Health";
  }
}

// Removes query param from url
export const removeQueryParams = (paramArray) => {
  const { query } = router;
  const params = new URLSearchParams(query);
  paramArray.forEach((entry) => {
    params.delete(entry);
  });
  const pathname = window.location.pathname;
  router.replace({ pathname, query: params.toString() }, undefined, {
    shallow: true,
  });
};

export const mobileAndTabletCheck = function () {
  let check = false;
  if (typeof navigator === "undefined") {
    return false;
  }
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      )
    )
      check = true;
  })(navigator.userAgent);
  return check;
};
