import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";

import { getMemberInfo } from "operations/queries/member";

export function useTeenMemberInfo() {
  return useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    fetchPolicy: "network-only",
  });
}
