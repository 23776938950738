import { Box } from "@chakra-ui/react";

export const PuzzlePiece = ({ width, height, fill }) => (
  <Box>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4730_33728)">
        <path
          d="M12.5 5.50002V4.88302C12.5 4.70751 12.4053 4.54805 12.2663 4.44095C11.7939 4.07712 11.5 3.56602 11.5 3C11.5 1.89543 12.6193 1 14 1C15.3807 1 16.5 1.89543 16.5 3C16.5 3.56602 16.2061 4.07712 15.7337 4.44095C15.5947 4.54805 15.5 4.70751 15.5 4.88302V5.50002C15.5 5.77616 15.7239 6.00002 16 6.00002H20C21.6569 6.00002 23 7.34316 23 9.00002V12C23 12.2761 22.7761 12.5 22.5 12.5L21.883 12.5C21.7075 12.5 21.548 12.4053 21.4409 12.2663C21.0771 11.7939 20.566 11.5 20 11.5C18.8954 11.5 18 12.6193 18 14C18 15.3807 18.8954 16.5 20 16.5C20.566 16.5 21.0771 16.2061 21.441 15.7337C21.5481 15.5946 21.7075 15.5 21.883 15.5H22.5C22.7761 15.5 23 15.7238 23 16V20C23 21.6569 21.6569 23 20 23H16C15.7239 23 15.5 22.7762 15.5 22.5V21.883C15.5 21.7075 15.5947 21.548 15.7337 21.4409C16.2061 21.0771 16.5 20.566 16.5 20C16.5 18.8954 15.3807 18 14 18C12.6193 18 11.5 18.8954 11.5 20C11.5 20.566 11.7939 21.0771 12.2663 21.4409C12.4053 21.548 12.5 21.7075 12.5 21.883V22.5C12.5 22.7762 12.2761 23 12 23H9C7.34315 23 6 21.6569 6 20V16C6 15.7239 5.77614 15.5 5.5 15.5H4.88302C4.70751 15.5 4.54805 15.5947 4.44095 15.7337C4.07712 16.2061 3.56602 16.5 3 16.5C1.89543 16.5 1 15.3807 1 14C1 13.1822 1.31414 12.4561 1.79981 12"
          stroke="#482D28"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 1L6.41421 4.58579L10 6L6.41421 7.41421L5 11L3.58579 7.41421L0 6L3.58579 4.58579L5 1ZM11 10.5C11 11.3284 10.3284 12 9.5 12C8.67157 12 8 11.3284 8 10.5C8 9.67157 8.67157 9 9.5 9C10.3284 9 11 9.67157 11 10.5ZM1 3C1.55228 3 2 2.55228 2 2C2 1.44772 1.55228 1 1 1C0.447715 1 0 1.44772 0 2C0 2.55228 0.447715 3 1 3Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4730_33728">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
