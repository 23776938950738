import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { getOr } from "lodash/fp";
import { compose } from "redux";
import { Modal, Headline, Subtitle } from "@spring/smeargle";
import { modalIds } from "@spring/constants";
import { useTranslation } from "react-i18next";

import { MemberAddressForm } from "components/organisms";
import { TRACK_EVENT } from "utils/mixpanel";

const MemberAddressHeader = (props) => {
  const { t } = useTranslation("careProvider");

  if (!props.noAddress) {
    return <Headline>{t("addressModal.updateHeadline")}</Headline>;
  }

  return (
    <>
      <Headline>{t("addressModal.headline")}</Headline>
      <Subtitle>{t("addressModal.subtitle")}</Subtitle>
    </>
  );
};

MemberAddressHeader.propTypes = {
  noAddress: PropTypes.any,
};

const AddressModal = (props) => {
  const router = useRouter();
  const { noAddress, showSubmit } = getOr(false, "addressData.data", props);

  return (
    <Modal
      size="lg"
      id={modalIds.addressModal}
      closeRedirect={() => {
        TRACK_EVENT.MODAL_CLOSED(router.asPath, "Edit Address Modal");
      }}
    >
      <MemberAddressHeader noAddress={noAddress} />
      <MemberAddressForm
        showSubmit={showSubmit}
        mpTracking={() => {
          TRACK_EVENT.FORM_SUBMITTED(router.asPath, "Edit Address", {
            spring_doc_id: "directsched013",
          });
          TRACK_EVENT.MODAL_CLOSED(router.asPath, "Edit Address Modal", {
            spring_doc_id: "directsched014",
          });
        }}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  addressData: getOr({}, `${modalIds.addressModal}`, state.modal),
});

export default compose(connect(mapStateToProps, null))(AddressModal);
