import COLORS from '../../../../../constants/colors';

import React from 'react';
import PropTypes from 'prop-types';

const ProgressTrackingIcon = ({ color }) => (
	<svg width="32" height="32" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill={color || COLORS.PRIMARY_500} d="M15.3345 2.71152C14.7204 2.07642 14.7374 1.06374 15.3725 0.449652C16.0076 -0.16444 17.0203 -0.147404 17.6344 0.487702L20.8761 3.84039C21.4902 4.47549 21.4732 5.48817 20.8381 6.10226L17.4854 9.34401C16.8503 9.9581 15.8376 9.94106 15.2235 9.30596C14.6094 8.67085 14.6265 7.65818 15.2616 7.04409L16.2779 6.0614C10.517 5.93174 5.19699 9.73255 3.63924 15.5462C1.81003 22.3729 5.8613 29.3899 12.688 31.2191C19.5147 33.0483 26.5317 28.997 28.3609 22.1703C29.5089 17.8861 28.3424 13.5264 25.6207 10.4177C25.0388 9.75304 25.1059 8.74245 25.7706 8.1605C26.4352 7.57856 27.4458 7.64564 28.0278 8.31033C31.4227 12.188 32.887 17.6398 31.4511 22.9984C29.1646 31.5317 20.3934 36.5958 11.86 34.3093C3.3266 32.0228 -1.73749 23.2515 0.549025 14.7182C2.41814 7.74251 8.62055 3.08516 15.4841 2.86623L15.3345 2.71152Z" />
		<path fill={color || COLORS.PRIMARY_500} d="M21.7596 24.9359C22.466 24.4053 22.6085 23.4025 22.0778 22.6962C21.5472 21.9898 20.5445 21.8474 19.8381 22.378C18.7686 23.1814 17.4421 23.6569 16.0001 23.6569C14.5575 23.6569 13.2306 23.1815 12.1613 22.378C11.455 21.8474 10.4522 21.9898 9.92156 22.6961C9.3909 23.4024 9.53329 24.4051 10.2396 24.9358C11.844 26.1412 13.8408 26.8561 16.0001 26.8561C18.1593 26.8561 20.1556 26.1408 21.7596 24.9359Z"/>
		<path fill={color || COLORS.PRIMARY_500} d="M14.4004 15.6589C14.4004 17.4257 13.3262 18.8581 12.001 18.8581C10.6759 18.8581 9.60161 17.4257 9.60161 15.6589C9.60161 13.892 10.6759 12.4596 12.001 12.4596C13.3262 12.4596 14.4004 13.892 14.4004 15.6589Z"/>
		<path fill={color || COLORS.PRIMARY_500} d="M19.9991 18.8581C21.3242 18.8581 22.3985 17.4257 22.3985 15.6589C22.3985 13.892 21.3242 12.4596 19.9991 12.4596C18.6739 12.4596 17.5997 13.892 17.5997 15.6589C17.5997 17.4257 18.6739 18.8581 19.9991 18.8581Z"/>
	</svg>
);

ProgressTrackingIcon.propTypes = {
	color: PropTypes.string,
};

export default ProgressTrackingIcon;
