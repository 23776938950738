import { gql } from "@apollo/client";

const createPersonalizedProviderBioFeedback = gql`
  mutation CreatePersonalizedProviderBioFeedback(
    $input: CreatePersonalizedProviderBioFeedbackInput!
  ) {
    createPersonalizedProviderBioFeedback(input: $input) {
      success
    }
  }
`;

export default createPersonalizedProviderBioFeedback;
