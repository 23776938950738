const COUNTRIES = [
	{
		'displayName'    : 'Afghanistan',
		'iso2'           : 'af',
		'dialCode'       : '93',
		'name'           : 'afghanistan',
		'iso3'           : 'afg',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Albania',
		'iso2'           : 'al',
		'dialCode'       : '355',
		'name'           : 'albania',
		'iso3'           : 'alb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Algeria',
		'iso2'           : 'dz',
		'dialCode'       : '213',
		'name'           : 'algeria',
		'iso3'           : 'dza',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'American Samoa',
		'iso2'           : 'as',
		'dialCode'       : '1684',
		'name'           : 'american samoa',
		'iso3'           : 'asm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Andorra',
		'iso2'           : 'ad',
		'dialCode'       : '376',
		'name'           : 'andorra',
		'iso3'           : 'and',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Angola',
		'iso2'           : 'ao',
		'dialCode'       : '244',
		'name'           : 'angola',
		'iso3'           : 'ago',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Anguilla',
		'iso2'           : 'ai',
		'dialCode'       : '1264',
		'name'           : 'anguilla',
		'iso3'           : 'aia',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Antigua and Barbuda',
		'iso2'           : 'ag',
		'dialCode'       : '1268',
		'name'           : 'antigua and barbuda',
		'iso3'           : 'atg',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Argentina',
		'iso2'           : 'ar',
		'dialCode'       : '54',
		'name'           : 'argentina',
		'iso3'           : 'arg',
		'stateLicensing' : true,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Armenia',
		'iso2'           : 'am',
		'dialCode'       : '374',
		'name'           : 'armenia',
		'iso3'           : 'arm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Aruba',
		'iso2'           : 'aw',
		'dialCode'       : '297',
		'name'           : 'aruba',
		'iso3'           : 'abw',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Australia',
		'iso2'           : 'au',
		'dialCode'       : '61',
		'name'           : 'australia',
		'iso3'           : 'aus',
		'stateLicensing' : false,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Austria',
		'iso2'           : 'at',
		'dialCode'       : '43',
		'name'           : 'austria',
		'iso3'           : 'aut',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Azerbaijan',
		'iso2'           : 'az',
		'dialCode'       : '994',
		'name'           : 'azerbaijan',
		'iso3'           : 'aze',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bahamas',
		'iso2'           : 'bs',
		'dialCode'       : '1242',
		'name'           : 'bahamas',
		'iso3'           : 'bhs',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bahrain',
		'iso2'           : 'bh',
		'dialCode'       : '973',
		'name'           : 'bahrain',
		'iso3'           : 'bhr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bangladesh',
		'iso2'           : 'bd',
		'dialCode'       : '880',
		'name'           : 'bangladesh',
		'iso3'           : 'bgd',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Barbados',
		'iso2'           : 'bb',
		'dialCode'       : '1246',
		'name'           : 'barbados',
		'iso3'           : 'brb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Belarus',
		'iso2'           : 'by',
		'dialCode'       : '375',
		'name'           : 'belarus',
		'iso3'           : 'blr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Belgium',
		'iso2'           : 'be',
		'dialCode'       : '32',
		'name'           : 'belgium',
		'iso3'           : 'bel',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Belize',
		'iso2'           : 'bz',
		'dialCode'       : '501',
		'name'           : 'belize',
		'iso3'           : 'blz',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Benin',
		'iso2'           : 'bj',
		'dialCode'       : '229',
		'name'           : 'benin',
		'iso3'           : 'ben',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bermuda',
		'iso2'           : 'bm',
		'dialCode'       : '1441',
		'name'           : 'bermuda',
		'iso3'           : 'bmu',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bhutan',
		'iso2'           : 'bt',
		'dialCode'       : '975',
		'name'           : 'bhutan',
		'iso3'           : 'btn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bolivia',
		'iso2'           : 'bo',
		'dialCode'       : '591',
		'name'           : 'bolivia',
		'iso3'           : 'bol',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bosnia and Herzegovina',
		'iso2'           : 'ba',
		'dialCode'       : '387',
		'name'           : 'bosnia and herzegovina',
		'iso3'           : 'bih',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Botswana',
		'iso2'           : 'bw',
		'dialCode'       : '267',
		'name'           : 'botswana',
		'iso3'           : 'bwa',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Brazil',
		'iso2'           : 'br',
		'dialCode'       : '55',
		'name'           : 'brazil',
		'iso3'           : 'bra',
		'stateLicensing' : false,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'British Indian Ocean Territory',
		'iso2'           : 'io',
		'dialCode'       : '246',
		'name'           : 'british indian ocean territory',
		'iso3'           : 'iot',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'British Virgin Islands',
		'iso2'           : 'vg',
		'dialCode'       : '1284',
		'name'           : 'british virgin islands',
		'iso3'           : 'vgb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Brunei',
		'iso2'           : 'bn',
		'dialCode'       : '673',
		'name'           : 'brunei',
		'iso3'           : 'brn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bulgaria',
		'iso2'           : 'bg',
		'dialCode'       : '359',
		'name'           : 'bulgaria',
		'iso3'           : 'bgr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Burkina Faso',
		'iso2'           : 'bf',
		'dialCode'       : '226',
		'name'           : 'burkina faso',
		'iso3'           : 'bfa',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Burundi',
		'iso2'           : 'bi',
		'dialCode'       : '257',
		'name'           : 'burundi',
		'iso3'           : 'bdi',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cambodia',
		'iso2'           : 'kh',
		'dialCode'       : '855',
		'name'           : 'cambodia',
		'iso3'           : 'khm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cameroon',
		'iso2'           : 'cm',
		'dialCode'       : '237',
		'name'           : 'cameroon',
		'iso3'           : 'cmr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Canada',
		'iso2'           : 'ca',
		'dialCode'       : '1',
		'name'           : 'canada',
		'iso3'           : 'can',
		'stateLicensing' : true,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Cabo Verde',
		'iso2'           : 'cv',
		'dialCode'       : '238',
		'name'           : 'cabo verde',
		'iso3'           : 'cpv',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Bonaire, Sint Eustacius, and Saba',
		'iso2'           : 'bq',
		'dialCode'       : '599',
		'name'           : 'bonaire, sint eustacius, and saba',
		'iso3'           : 'bes',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cayman Islands',
		'iso2'           : 'ky',
		'dialCode'       : '1345',
		'name'           : 'cayman islands',
		'iso3'           : 'cym',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Central African Republic',
		'iso2'           : 'cf',
		'dialCode'       : '236',
		'name'           : 'central african republic',
		'iso3'           : 'caf',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Chad',
		'iso2'           : 'td',
		'dialCode'       : '235',
		'name'           : 'chad',
		'iso3'           : 'tcd',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Chile',
		'iso2'           : 'cl',
		'dialCode'       : '56',
		'name'           : 'chile',
		'iso3'           : 'chl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'China',
		'iso2'           : 'cn',
		'dialCode'       : '86',
		'name'           : 'china',
		'iso3'           : 'chn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Christmas Island',
		'iso2'           : 'cx',
		'dialCode'       : '61',
		'name'           : 'christmas island',
		'iso3'           : 'cxr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cocos Islands',
		'iso2'           : 'cc',
		'dialCode'       : '61',
		'name'           : 'cocos islands',
		'iso3'           : 'cck',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Colombia',
		'iso2'           : 'co',
		'dialCode'       : '57',
		'name'           : 'colombia',
		'iso3'           : 'col',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Comoros',
		'iso2'           : 'km',
		'dialCode'       : '269',
		'name'           : 'comoros',
		'iso3'           : 'com',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Congo',
		'iso2'           : 'cd',
		'dialCode'       : '243',
		'name'           : 'democratic republic of the congo',
		'iso3'           : 'com',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Congo',
		'iso2'           : 'cg',
		'dialCode'       : '242',
		'name'           : 'the congo',
		'iso3'           : 'cog',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cook Islands',
		'iso2'           : 'ck',
		'dialCode'       : '682',
		'name'           : 'cook islands',
		'iso3'           : 'cok',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Costa Rica',
		'iso2'           : 'cr',
		'dialCode'       : '506',
		'name'           : 'costa rica',
		'iso3'           : 'cri',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'C\u00f4te d\u2019Ivoire',
		'iso2'           : 'ci',
		'dialCode'       : '225',
		'name'           : 'cote d\'ivoire',
		'iso3'           : 'civ',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Croatia',
		'iso2'           : 'hr',
		'dialCode'       : '385',
		'name'           : 'croatia',
		'iso3'           : 'hrv',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cuba',
		'iso2'           : 'cu',
		'dialCode'       : '53',
		'name'           : 'cuba',
		'iso3'           : 'cub',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cura\u00e7ao',
		'iso2'           : 'cw',
		'dialCode'       : '599',
		'name'           : 'curacao',
		'iso3'           : 'cuw',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Cyprus',
		'iso2'           : 'cy',
		'dialCode'       : '357',
		'name'           : 'cyprus',
		'iso3'           : 'cyp',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Czech Republic',
		'iso2'           : 'cz',
		'dialCode'       : '420',
		'name'           : 'czech republic',
		'iso3'           : 'cze',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Denmark',
		'iso2'           : 'dk',
		'dialCode'       : '45',
		'name'           : 'denmark',
		'iso3'           : 'dnk',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Djibouti',
		'iso2'           : 'dj',
		'dialCode'       : '253',
		'name'           : 'djibouti',
		'iso3'           : 'dji',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Dominica',
		'iso2'           : 'dm',
		'dialCode'       : '1767',
		'name'           : 'dominica',
		'iso3'           : 'dma',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Dominican Republic',
		'iso2'           : 'do',
		'dialCode'       : '1',
		'name'           : 'dominican republic',
		'iso3'           : 'dom',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Ecuador',
		'iso2'           : 'ec',
		'dialCode'       : '593',
		'name'           : 'ecuador',
		'iso3'           : 'ecu',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Egypt',
		'iso2'           : 'eg',
		'dialCode'       : '20',
		'name'           : 'egypt',
		'iso3'           : 'egy',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'El Salvador',
		'iso2'           : 'sv',
		'dialCode'       : '503',
		'name'           : 'el salvador',
		'iso3'           : 'slv',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Equatorial Guinea',
		'iso2'           : 'gq',
		'dialCode'       : '240',
		'name'           : 'equatorial guinea',
		'iso3'           : 'gnq',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Eritrea',
		'iso2'           : 'er',
		'dialCode'       : '291',
		'name'           : 'eritrea',
		'iso3'           : 'eri',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Estonia',
		'iso2'           : 'ee',
		'dialCode'       : '372',
		'name'           : 'estonia',
		'iso3'           : 'est',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Eswatini',
		'iso2'           : 'sz',
		'dialCode'       : '268',
		'name'           : 'eswatini',
		'iso3'           : 'swz',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Ethiopia',
		'iso2'           : 'et',
		'dialCode'       : '251',
		'name'           : 'ethiopia',
		'iso3'           : 'eth',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Falkland Islands',
		'iso2'           : 'fk',
		'dialCode'       : '500',
		'name'           : 'falkland islands',
		'iso3'           : 'flk',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Faroe Islands',
		'iso2'           : 'fo',
		'dialCode'       : '298',
		'name'           : 'faroe islands',
		'iso3'           : 'fro',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Fiji',
		'iso2'           : 'fj',
		'dialCode'       : '679',
		'name'           : 'fiji',
		'iso3'           : 'fji',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Finland',
		'iso2'           : 'fi',
		'dialCode'       : '358',
		'name'           : 'finland',
		'iso3'           : 'fin',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'France',
		'iso2'           : 'fr',
		'dialCode'       : '33',
		'name'           : 'france',
		'iso3'           : 'fra',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'French Guiana',
		'iso2'           : 'gf',
		'dialCode'       : '594',
		'name'           : 'french guiana',
		'iso3'           : 'guf',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'French Polynesia',
		'iso2'           : 'pf',
		'dialCode'       : '689',
		'name'           : 'french polynesia',
		'iso3'           : 'pyf',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'French Southern and Antarctic Lands',
		'iso2'           : 'tf',
		'dialCode'       : '262',
		'name'           : 'french southern and antarctic lands',
		'iso3'           : 'atf',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Gabon',
		'iso2'           : 'ga',
		'dialCode'       : '241',
		'name'           : 'gabon',
		'iso3'           : 'gab',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Gambia',
		'iso2'           : 'gm',
		'dialCode'       : '220',
		'name'           : 'gambia',
		'iso3'           : 'gmb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Georgia',
		'iso2'           : 'ge',
		'dialCode'       : '995',
		'name'           : 'georgia',
		'iso3'           : 'geo',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Germany',
		'iso2'           : 'de',
		'dialCode'       : '49',
		'name'           : 'germany',
		'iso3'           : 'deu',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Ghana',
		'iso2'           : 'gh',
		'dialCode'       : '233',
		'name'           : 'ghana',
		'iso3'           : 'gha',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Gibraltar',
		'iso2'           : 'gi',
		'dialCode'       : '350',
		'name'           : 'gibraltar',
		'iso3'           : 'gib',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Greece',
		'iso2'           : 'gr',
		'dialCode'       : '30',
		'name'           : 'greece',
		'iso3'           : 'grc',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Greenland',
		'iso2'           : 'gl',
		'dialCode'       : '299',
		'name'           : 'greenland',
		'iso3'           : 'grl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Grenada',
		'iso2'           : 'gd',
		'dialCode'       : '1473',
		'name'           : 'grenada',
		'iso3'           : 'grd',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Guadeloupe',
		'iso2'           : 'gp',
		'dialCode'       : '590',
		'name'           : 'guadeloupe',
		'iso3'           : 'glp',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Guam',
		'iso2'           : 'gu',
		'dialCode'       : '1671',
		'name'           : 'guam',
		'iso3'           : 'gum',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Guatemala',
		'iso2'           : 'gt',
		'dialCode'       : '502',
		'name'           : 'guatemala',
		'iso3'           : 'gtm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Guernsey',
		'iso2'           : 'gg',
		'dialCode'       : '44',
		'name'           : 'guernsey',
		'iso3'           : 'ggy',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Guinea',
		'iso2'           : 'gn',
		'dialCode'       : '224',
		'name'           : 'guinea',
		'iso3'           : 'gin',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Guinea-Bissau',
		'iso2'           : 'gw',
		'dialCode'       : '245',
		'name'           : 'guinea-bissau',
		'iso3'           : 'gnb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Guyana',
		'iso2'           : 'gy',
		'dialCode'       : '592',
		'name'           : 'guyana',
		'iso3'           : 'guy',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Haiti',
		'iso2'           : 'ht',
		'dialCode'       : '509',
		'name'           : 'haiti',
		'iso3'           : 'hti',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Honduras',
		'iso2'           : 'hn',
		'dialCode'       : '504',
		'name'           : 'honduras',
		'iso3'           : 'hnd',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Hong Kong',
		'iso2'           : 'hk',
		'dialCode'       : '852',
		'name'           : 'hong kong',
		'iso3'           : 'hkg',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Hungary',
		'iso2'           : 'hu',
		'dialCode'       : '36',
		'name'           : 'hungary',
		'iso3'           : 'hun',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Iceland',
		'iso2'           : 'is',
		'dialCode'       : '354',
		'name'           : 'iceland',
		'iso3'           : 'isl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'India',
		'iso2'           : 'in',
		'dialCode'       : '91',
		'name'           : 'india',
		'iso3'           : 'ind',
		'stateLicensing' : false,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Indonesia',
		'iso2'           : 'id',
		'dialCode'       : '62',
		'name'           : 'indonesia',
		'iso3'           : 'idn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Iran',
		'iso2'           : 'ir',
		'dialCode'       : '98',
		'name'           : 'iran',
		'iso3'           : 'irn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Iraq',
		'iso2'           : 'iq',
		'dialCode'       : '964',
		'name'           : 'iraq',
		'iso3'           : 'irq',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Ireland',
		'iso2'           : 'ie',
		'dialCode'       : '353',
		'name'           : 'ireland',
		'iso3'           : 'irl',
		'stateLicensing' : false,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Isle of Man',
		'iso2'           : 'im',
		'dialCode'       : '44',
		'name'           : 'isle of man',
		'iso3'           : 'imn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Israel',
		'iso2'           : 'il',
		'dialCode'       : '972',
		'name'           : 'israel',
		'iso3'           : 'isr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Italy',
		'iso2'           : 'it',
		'dialCode'       : '39',
		'name'           : 'italy',
		'iso3'           : 'ita',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Jamaica',
		'iso2'           : 'jm',
		'dialCode'       : '1876',
		'name'           : 'jamaica',
		'iso3'           : 'jam',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Japan',
		'iso2'           : 'jp',
		'dialCode'       : '81',
		'name'           : 'japan',
		'iso3'           : 'jpn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Jersey',
		'iso2'           : 'je',
		'dialCode'       : '44',
		'name'           : 'jersey',
		'iso3'           : 'jey',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Jordan',
		'iso2'           : 'jo',
		'dialCode'       : '962',
		'name'           : 'jordan',
		'iso3'           : 'jor',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Kazakhstan',
		'iso2'           : 'kz',
		'dialCode'       : '7',
		'name'           : 'kazakhstan',
		'iso3'           : 'kaz',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Kenya',
		'iso2'           : 'ke',
		'dialCode'       : '254',
		'name'           : 'kenya',
		'iso3'           : 'ken',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Kiribati',
		'iso2'           : 'ki',
		'dialCode'       : '686',
		'name'           : 'kiribati',
		'iso3'           : 'kir',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Democratic People\'s Republic of Korea',
		'iso2'           : 'kp',
		'dialCode'       : '850',
		'name'           : 'democratic peoples republic of korea',
		'iso3'           : 'prk',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Republic of Korea',
		'iso2'           : 'kr',
		'dialCode'       : '82',
		'name'           : 'republic of korea',
		'iso3'           : 'kor',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Kosovo',
		'iso2'           : 'xk',
		'dialCode'       : '383',
		'name'           : 'kosovo',
		'iso3'           : 'kos',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Kuwait',
		'iso2'           : 'kw',
		'dialCode'       : '965',
		'name'           : 'kuwait',
		'iso3'           : 'kwt',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Kyrgyzstan',
		'iso2'           : 'kg',
		'dialCode'       : '996',
		'name'           : 'kyrgyzstan',
		'iso3'           : 'kgz',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Laos',
		'iso2'           : 'la',
		'dialCode'       : '856',
		'name'           : 'laos',
		'iso3'           : 'lao',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Latvia',
		'iso2'           : 'lv',
		'dialCode'       : '371',
		'name'           : 'latvia',
		'iso3'           : 'lva',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Lebanon',
		'iso2'           : 'lb',
		'dialCode'       : '961',
		'name'           : 'lebanon',
		'iso3'           : 'lbn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Lesotho',
		'iso2'           : 'ls',
		'dialCode'       : '266',
		'name'           : 'lesotho',
		'iso3'           : 'lso',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Liberia',
		'iso2'           : 'lr',
		'dialCode'       : '231',
		'name'           : 'liberia',
		'iso3'           : 'lbr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Libya',
		'iso2'           : 'ly',
		'dialCode'       : '218',
		'name'           : 'libya',
		'iso3'           : 'lby',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Liechtenstein',
		'iso2'           : 'li',
		'dialCode'       : '423',
		'name'           : 'liechtenstein',
		'iso3'           : 'lie',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Lithuania',
		'iso2'           : 'lt',
		'dialCode'       : '370',
		'name'           : 'lithuania',
		'iso3'           : 'ltu',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Luxembourg',
		'iso2'           : 'lu',
		'dialCode'       : '352',
		'name'           : 'luxembourg',
		'iso3'           : 'lux',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Macao',
		'iso2'           : 'mo',
		'dialCode'       : '853',
		'name'           : 'macau',
		'iso3'           : 'mac',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'North Macedonia',
		'iso2'           : 'mk',
		'dialCode'       : '389',
		'name'           : 'north macedonia',
		'iso3'           : 'mkd',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Madagascar',
		'iso2'           : 'mg',
		'dialCode'       : '261',
		'name'           : 'madagascar',
		'iso3'           : 'mdg',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Malawi',
		'iso2'           : 'mw',
		'dialCode'       : '265',
		'name'           : 'malawi',
		'iso3'           : 'mwi',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Malaysia',
		'iso2'           : 'my',
		'dialCode'       : '60',
		'name'           : 'malaysia',
		'iso3'           : 'mys',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Maldives',
		'iso2'           : 'mv',
		'dialCode'       : '960',
		'name'           : 'maldives',
		'iso3'           : 'mdv',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Mali',
		'iso2'           : 'ml',
		'dialCode'       : '223',
		'name'           : 'mali',
		'iso3'           : 'mli',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Malta',
		'iso2'           : 'mt',
		'dialCode'       : '356',
		'name'           : 'malta',
		'iso3'           : 'mlt',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Marshall Islands',
		'iso2'           : 'mh',
		'dialCode'       : '692',
		'name'           : 'marshall islands',
		'iso3'           : 'mhl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Martinique',
		'iso2'           : 'mq',
		'dialCode'       : '596',
		'name'           : 'martinique',
		'iso3'           : 'mtq',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Mauritania',
		'iso2'           : 'mr',
		'dialCode'       : '222',
		'name'           : 'mauritania',
		'iso3'           : 'mrt',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Mauritius',
		'iso2'           : 'mu',
		'dialCode'       : '230',
		'name'           : 'mauritius',
		'iso3'           : 'mus',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Mayotte',
		'iso2'           : 'yt',
		'dialCode'       : '262',
		'name'           : 'mayotte',
		'iso3'           : 'myt',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Mexico',
		'iso2'           : 'mx',
		'dialCode'       : '52',
		'name'           : 'mexico',
		'iso3'           : 'mex',
		'stateLicensing' : true,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Micronesia',
		'iso2'           : 'fm',
		'dialCode'       : '691',
		'name'           : 'micronesia',
		'iso3'           : 'fsm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Moldova',
		'iso2'           : 'md',
		'dialCode'       : '373',
		'name'           : 'moldova',
		'iso3'           : 'mda',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Monaco',
		'iso2'           : 'mc',
		'dialCode'       : '377',
		'name'           : 'monaco',
		'iso3'           : 'mco',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Mongolia',
		'iso2'           : 'mn',
		'dialCode'       : '976',
		'name'           : 'mongolia',
		'iso3'           : 'mng',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Montenegro',
		'iso2'           : 'me',
		'dialCode'       : '382',
		'name'           : 'montenegro',
		'iso3'           : 'mne',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Montserrat',
		'iso2'           : 'ms',
		'dialCode'       : '1664',
		'name'           : 'montserrat',
		'iso3'           : 'msr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Morocco',
		'iso2'           : 'ma',
		'dialCode'       : '212',
		'name'           : 'morocco',
		'iso3'           : 'mar',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Mozambique',
		'iso2'           : 'mz',
		'dialCode'       : '258',
		'name'           : 'mozambique',
		'iso3'           : 'moz',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Myanmar',
		'iso2'           : 'mm',
		'dialCode'       : '95',
		'name'           : 'myanmar',
		'iso3'           : 'mmr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Namibia',
		'iso2'           : 'na',
		'dialCode'       : '264',
		'name'           : 'namibia',
		'iso3'           : 'nam',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Nauru',
		'iso2'           : 'nr',
		'dialCode'       : '674',
		'name'           : 'nauru',
		'iso3'           : 'nru',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Nepal',
		'iso2'           : 'np',
		'dialCode'       : '977',
		'name'           : 'nepal',
		'iso3'           : 'npl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'The Netherlands',
		'iso2'           : 'nl',
		'dialCode'       : '31',
		'name'           : 'the netherlands',
		'iso3'           : 'nld',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'New Caledonia',
		'iso2'           : 'nc',
		'dialCode'       : '687',
		'name'           : 'new caledonia',
		'iso3'           : 'ncl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'New Zealand',
		'iso2'           : 'nz',
		'dialCode'       : '64',
		'name'           : 'new zealand',
		'iso3'           : 'nzl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Nicaragua',
		'iso2'           : 'ni',
		'dialCode'       : '505',
		'name'           : 'nicaragua',
		'iso3'           : 'nic',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Niger',
		'iso2'           : 'ne',
		'dialCode'       : '227',
		'name'           : 'niger',
		'iso3'           : 'ner',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Nigeria',
		'iso2'           : 'ng',
		'dialCode'       : '234',
		'name'           : 'nigeria',
		'iso3'           : 'nga',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Niue',
		'iso2'           : 'nu',
		'dialCode'       : '683',
		'name'           : 'niue',
		'iso3'           : 'niu',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Norfolk Island',
		'iso2'           : 'nf',
		'dialCode'       : '672',
		'name'           : 'norfolk island',
		'iso3'           : 'nfk',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Northern Mariana Islands',
		'iso2'           : 'mp',
		'dialCode'       : '1670',
		'name'           : 'northern mariana islands',
		'iso3'           : 'mnp',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Norway',
		'iso2'           : 'no',
		'dialCode'       : '47',
		'name'           : 'norway',
		'iso3'           : 'nor',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Oman',
		'iso2'           : 'om',
		'dialCode'       : '968',
		'name'           : 'oman',
		'iso3'           : 'omn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Pakistan',
		'iso2'           : 'pk',
		'dialCode'       : '92',
		'name'           : 'pakistan',
		'iso3'           : 'pak',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Palau',
		'iso2'           : 'pw',
		'dialCode'       : '680',
		'name'           : 'palau',
		'iso3'           : 'plw',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Palestine',
		'iso2'           : 'ps',
		'dialCode'       : '970',
		'name'           : 'palestine',
		'iso3'           : 'pse',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Panama',
		'iso2'           : 'pa',
		'dialCode'       : '507',
		'name'           : 'panama',
		'iso3'           : 'pan',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Papua New Guinea',
		'iso2'           : 'pg',
		'dialCode'       : '675',
		'name'           : 'papua new guinea',
		'iso3'           : 'png',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Paraguay',
		'iso2'           : 'py',
		'dialCode'       : '595',
		'name'           : 'paraguay',
		'iso3'           : 'pry',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Peru',
		'iso2'           : 'pe',
		'dialCode'       : '51',
		'name'           : 'peru',
		'iso3'           : 'per',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Philippines',
		'iso2'           : 'ph',
		'dialCode'       : '63',
		'name'           : 'philippines',
		'iso3'           : 'phl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Poland',
		'iso2'           : 'pl',
		'dialCode'       : '48',
		'name'           : 'poland',
		'iso3'           : 'pol',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Pitcairn',
		'iso2'           : 'pn',
		'dialCode'       : '64',
		'name'           : 'pitcairn',
		'iso3'           : 'pcn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Portugal',
		'iso2'           : 'pt',
		'dialCode'       : '351',
		'name'           : 'portugal',
		'iso3'           : 'prt',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Puerto Rico',
		'iso2'           : 'pr',
		'dialCode'       : '1',
		'name'           : 'puerto rico',
		'iso3'           : 'pri',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Qatar',
		'iso2'           : 'qa',
		'dialCode'       : '974',
		'name'           : 'qatar',
		'iso3'           : 'qat',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'R\u00e9union',
		'iso2'           : 're',
		'dialCode'       : '262',
		'name'           : 'reunion',
		'iso3'           : 'reu',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Romania',
		'iso2'           : 'ro',
		'dialCode'       : '40',
		'name'           : 'romania',
		'iso3'           : 'rou',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Russia',
		'iso2'           : 'ru',
		'dialCode'       : '7',
		'name'           : 'russia',
		'iso3'           : 'rus',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Rwanda',
		'iso2'           : 'rw',
		'dialCode'       : '250',
		'name'           : 'rwanda',
		'iso3'           : 'rwa',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saint Barth\u00e9lemy',
		'iso2'           : 'bl',
		'dialCode'       : '590',
		'name'           : 'saint barthelemy',
		'iso3'           : 'blm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saint Helena, Ascension, and Tristan da Cunha',
		'iso2'           : 'sh',
		'dialCode'       : '290',
		'name'           : 'saint helena, ascension and tristan da cunha',
		'iso3'           : 'shn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saint Kitts and Nevis',
		'iso2'           : 'kn',
		'dialCode'       : '1869',
		'name'           : 'saint kitts and nevis',
		'iso3'           : 'kna',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saint Lucia',
		'iso2'           : 'lc',
		'dialCode'       : '1758',
		'name'           : 'saint lucia',
		'iso3'           : 'lca',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saint Martin',
		'iso2'           : 'mf',
		'dialCode'       : '590',
		'name'           : 'saint martin',
		'iso3'           : 'maf',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saint Pierre and Miquelon',
		'iso2'           : 'pm',
		'dialCode'       : '508',
		'name'           : 'saint pierre and miquelon',
		'iso3'           : 'spm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saint Vincent and the Grenadines',
		'iso2'           : 'vc',
		'dialCode'       : '1784',
		'name'           : 'saint vincent and the grenadines',
		'iso3'           : 'vct',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Samoa',
		'iso2'           : 'ws',
		'dialCode'       : '685',
		'name'           : 'samoa',
		'iso3'           : 'wsm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'San Marino',
		'iso2'           : 'sm',
		'dialCode'       : '378',
		'name'           : 'san marino',
		'iso3'           : 'smr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
		'iso2'           : 'st',
		'dialCode'       : '239',
		'name'           : 'sao tome and principe',
		'iso3'           : 'stp',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Saudi Arabia',
		'iso2'           : 'sa',
		'dialCode'       : '966',
		'name'           : 'saudi arabia',
		'iso3'           : 'sau',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Senegal',
		'iso2'           : 'sn',
		'dialCode'       : '221',
		'name'           : 'senegal',
		'iso3'           : 'sen',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Serbia',
		'iso2'           : 'rs',
		'dialCode'       : '381',
		'name'           : 'serbia',
		'iso3'           : 'srb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Seychelles',
		'iso2'           : 'sc',
		'dialCode'       : '248',
		'name'           : 'seychelles',
		'iso3'           : 'syc',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Sierra Leone',
		'iso2'           : 'sl',
		'dialCode'       : '232',
		'name'           : 'sierra leone',
		'iso3'           : 'sle',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Singapore',
		'iso2'           : 'sg',
		'dialCode'       : '65',
		'name'           : 'singapore',
		'iso3'           : 'sgp',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Sint Maarten',
		'iso2'           : 'sx',
		'dialCode'       : '1721',
		'name'           : 'sint maarten',
		'iso3'           : 'sxm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Slovakia',
		'iso2'           : 'sk',
		'dialCode'       : '421',
		'name'           : 'slovakia',
		'iso3'           : 'svk',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Slovenia',
		'iso2'           : 'si',
		'dialCode'       : '386',
		'name'           : 'slovenia',
		'iso3'           : 'svn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Solomon Islands',
		'iso2'           : 'sb',
		'dialCode'       : '677',
		'name'           : 'solomon islands',
		'iso3'           : 'slb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Somalia',
		'iso2'           : 'so',
		'dialCode'       : '252',
		'name'           : 'somalia',
		'iso3'           : 'som',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'South Africa',
		'iso2'           : 'za',
		'dialCode'       : '27',
		'name'           : 'south africa',
		'iso3'           : 'zaf',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'South Sudan',
		'iso2'           : 'ss',
		'dialCode'       : '211',
		'name'           : 'south sudan',
		'iso3'           : 'ssd',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Spain',
		'iso2'           : 'es',
		'dialCode'       : '34',
		'name'           : 'spain',
		'iso3'           : 'esp',
		'stateLicensing' : true,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Sri Lanka',
		'iso2'           : 'lk',
		'dialCode'       : '94',
		'name'           : 'sri lanka',
		'iso3'           : 'lka',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Sudan',
		'iso2'           : 'sd',
		'dialCode'       : '249',
		'name'           : 'sudan',
		'iso3'           : 'sdn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Suriname',
		'iso2'           : 'sr',
		'dialCode'       : '597',
		'name'           : 'suriname',
		'iso3'           : 'sur',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Svalbard and Jan Mayen',
		'iso2'           : 'sj',
		'dialCode'       : '47',
		'name'           : 'svalbard and jan mayen',
		'iso3'           : 'sjm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Sweden',
		'iso2'           : 'se',
		'dialCode'       : '46',
		'name'           : 'sweden',
		'iso3'           : 'swe',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Switzerland',
		'iso2'           : 'ch',
		'dialCode'       : '41',
		'name'           : 'switzerland',
		'iso3'           : 'che',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Syria',
		'iso2'           : 'sy',
		'dialCode'       : '963',
		'name'           : 'syria',
		'iso3'           : 'syr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Taiwan',
		'iso2'           : 'tw',
		'dialCode'       : '886',
		'name'           : 'taiwan',
		'iso3'           : 'twn',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Tajikistan',
		'iso2'           : 'tj',
		'dialCode'       : '992',
		'name'           : 'tajikistan',
		'iso3'           : 'tjk',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Tanzania',
		'iso2'           : 'tz',
		'dialCode'       : '255',
		'name'           : 'tanzania',
		'iso3'           : 'tza',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Thailand',
		'iso2'           : 'th',
		'dialCode'       : '66',
		'name'           : 'thailand',
		'iso3'           : 'tha',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Timor-Leste',
		'iso2'           : 'tl',
		'dialCode'       : '670',
		'name'           : 'timor-leste',
		'iso3'           : 'tls',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Togo',
		'iso2'           : 'tg',
		'dialCode'       : '228',
		'name'           : 'togo',
		'iso3'           : 'tgo',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Tokelau',
		'iso2'           : 'tk',
		'dialCode'       : '690',
		'name'           : 'tokelau',
		'iso3'           : 'tkl',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Tonga',
		'iso2'           : 'to',
		'dialCode'       : '676',
		'name'           : 'tonga',
		'iso3'           : 'ton',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Trinidad and Tobago',
		'iso2'           : 'tt',
		'dialCode'       : '1868',
		'name'           : 'trinidad and tobago',
		'iso3'           : 'tto',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Tunisia',
		'iso2'           : 'tn',
		'dialCode'       : '216',
		'name'           : 'tunisia',
		'iso3'           : 'tun',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Turkey',
		'iso2'           : 'tr',
		'dialCode'       : '90',
		'name'           : 'turkey',
		'iso3'           : 'tur',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Turkmenistan',
		'iso2'           : 'tm',
		'dialCode'       : '993',
		'name'           : 'turkmenistan',
		'iso3'           : 'tkm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Turks and Caicos Islands',
		'iso2'           : 'tc',
		'dialCode'       : '1649',
		'name'           : 'turks and caicos islands',
		'iso3'           : 'tca',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Tuvalu',
		'iso2'           : 'tv',
		'dialCode'       : '688',
		'name'           : 'tuvalu',
		'iso3'           : 'tuv',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'U.S. Virgin Islands',
		'iso2'           : 'vi',
		'dialCode'       : '1340',
		'name'           : 'u.s. virgin islands',
		'iso3'           : 'vir',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Uganda',
		'iso2'           : 'ug',
		'dialCode'       : '256',
		'name'           : 'uganda',
		'iso3'           : 'uga',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Ukraine',
		'iso2'           : 'ua',
		'dialCode'       : '380',
		'name'           : 'ukraine',
		'iso3'           : 'ukr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'United Arab Emirates',
		'iso2'           : 'ae',
		'dialCode'       : '971',
		'name'           : 'united arab emirates',
		'iso3'           : 'are',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'United Kingdom of Great Britain and Northern Ireland',
		'iso2'           : 'gb',
		'dialCode'       : '44',
		'name'           : 'united kingdom of great britain and northern ireland',
		'iso3'           : 'gbr',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'United States',
		'iso2'           : 'us',
		'dialCode'       : '1',
		'name'           : 'united states of america',
		'iso3'           : 'usa',
		'stateLicensing' : true,
		'stateMailing'   : true,
	},
	{
		'displayName'    : 'Uruguay',
		'iso2'           : 'uy',
		'dialCode'       : '598',
		'name'           : 'uruguay',
		'iso3'           : 'ury',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Uzbekistan',
		'iso2'           : 'uz',
		'dialCode'       : '998',
		'name'           : 'uzbekistasn',
		'iso3'           : 'uzb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Vanuatu',
		'iso2'           : 'vu',
		'dialCode'       : '678',
		'name'           : 'vanuatu',
		'iso3'           : 'vut',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Vatican City',
		'iso2'           : 'va',
		'dialCode'       : '39',
		'name'           : 'vatican city',
		'iso3'           : 'vat',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Venezuela',
		'iso2'           : 've',
		'dialCode'       : '58',
		'name'           : 'venezuela',
		'iso3'           : 'ven',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Vietnam',
		'iso2'           : 'vn',
		'dialCode'       : '84',
		'name'           : 'vietnam',
		'iso3'           : 'vnm',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Wallis and Futuna',
		'iso2'           : 'wf',
		'dialCode'       : '681',
		'name'           : 'wallis and futuna',
		'iso3'           : 'wlf',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Western Sahara',
		'iso2'           : 'eh',
		'dialCode'       : '212',
		'name'           : 'western sahara',
		'iso3'           : 'esh',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Yemen',
		'iso2'           : 'ye',
		'dialCode'       : '967',
		'name'           : 'yemen',
		'iso3'           : 'yem',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Zambia',
		'iso2'           : 'zm',
		'dialCode'       : '260',
		'name'           : 'zambia',
		'iso3'           : 'zmb',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : 'Zimbabwe',
		'iso2'           : 'zw',
		'dialCode'       : '263',
		'name'           : 'zimbabwe',
		'iso3'           : 'zwe',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
	{
		'displayName'    : '\u00c5land Islands',
		'iso2'           : 'ax',
		'dialCode'       : '358',
		'name'           : 'aland islands',
		'iso3'           : 'ala',
		'stateLicensing' : false,
		'stateMailing'   : false,
	},
];

module.exports = COUNTRIES;
