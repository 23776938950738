import { LOCALE_COUNTRY, SHOW_GLOBAL_EXPERIENCE } from "./types";

export const showGlobalExperience = (payload) => ({
  type: SHOW_GLOBAL_EXPERIENCE,
  payload,
});

export const localeCountry = (payload) => ({
  type: LOCALE_COUNTRY,
  payload,
});
