import {
  rememberParentLocalStorage,
  rememberDependentLocalStorage,
  autoSignInAsDependent,
  DEPENDENT_KEY,
} from "lib/rememberUser";
import { reduxStore } from "constants/reduxStore";
import {
  forgotPassword,
  setUserIdentifiers,
  verifyToken,
} from "actions/auth/actions";
import Router from "next/router";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import routes from "routes";
import { addNotification } from "@spring/smeargle/actions";
import { TRACK_EVENT } from "utils/mixpanel";
import { useChooseUserMemberInfo } from "components/templates/ChooseUserPage/hooks/useChooseUserMemberInfo";
import { signIn, mfaCreateAccessToken } from "actions/auth";
import pichu from "constants/pichu";
import { useTemporaryPassword } from "components/templates/ChooseUserPage/hooks/useTemporaryPassword";

export async function handleSignInOnChooseUserPage(
  id,
  memberDateOfBirth,
  isManager = false,
) {
  if (!isManager) {
    rememberDependentLocalStorage(id);
  }

  await verifyToken().then((userIdentifiers) => {
    if (userIdentifiers) {
      reduxStore.dispatch(setUserIdentifiers(userIdentifiers));
    }
  });

  if (isManager) {
    Router.push(routes.ChooseUser.to, routes.ChooseUser.as);
  } else if (memberDateOfBirth) {
    const memberHomeRoute = getHomeRoute(memberDateOfBirth);
    Router.push(memberHomeRoute.as);
  }
}

export async function requestForgotPassword(email) {
  // redirect if the reset_password url that is sent to the backend put in the email link
  // it returns error if is a localhost domain
  const redirect =
    process.env.APP_ENV === "production"
      ? window.location.protocol +
        "//" +
        window.location.host +
        "/reset_password"
      : "https://staging.springhealth.us/reset_password";

  try {
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Forgot Password");
    await forgotPassword(email, redirect);
    const message = `If ${email} is registered with us, you will receive instructions on how to reset your password.`;
    reduxStore.dispatch(addNotification(message, "success"));
  } catch ({ error }) {
    reduxStore.dispatch(addNotification(error, "error"));
  }
}

export function getTargetMember(id) {
  const { data, loading } = useChooseUserMemberInfo();
  if (loading) {
    return { loading, dependents: null };
  }
  const currentUser = data?.user;

  //currrent user is the parent signing into dependent
  const isParentSigningIntoDependentAccount = !currentUser?.member.minor;

  if (isParentSigningIntoDependentAccount) {
    const dependents = data?.user?.member?.managed_dependents || [];
    const targetDependent = dependents.find((dep) => dep.id === id);
    return { targetDependent, currentUser };
  }

  //current user is now the dependent signing back into parent account
  const targetManager = currentUser?.member?.manager;
  return { targetManager };
}

/* 
  if dependent key is stored in local storage, guardian will be auto logged in to dependent
  account without being prompted to enter password
*/
export const loginWithDependentKeyInLocalStorage = async function (
  updateCheckedTargetMemberAuth,
  targetId,
  memberDateOfBirth,
) {
  try {
    await autoSignInAsDependent(targetId, memberDateOfBirth);
    updateCheckedTargetMemberAuth(true);
  } catch (_err) {
    updateCheckedTargetMemberAuth(true);
  }
};

/* 
  guardian will be assigned a temp password and auto logged in to dependent
  account without being prompted to enter password in the initial login
*/
export const signInWithTempPassword = async (
  tempPassword,
  memberEmail,
  targetId,
  initiateMfaLogin,
  setIsAccountLocked,
  memberHomeRouteAlias,
) => {
  TRACK_EVENT.FORM_SUBMITTED(routes.ChooseUserPassword.as, "Sign In");

  rememberParentLocalStorage();
  try {
    await signIn(memberEmail, tempPassword, memberHomeRouteAlias, null, false, {
      skipConfirmationEmail: true,
    });
    rememberDependentLocalStorage(targetId);
  } catch (err) {
    if (err.error === "mfa_authentication_required") {
      const mfaToken = err.error_data.mfa_token;
      const mfaTypeId = err.error_data.mfa_type_id;
      try {
        return mfaCreateAccessToken(mfaToken, () =>
          initiateMfaLogin(
            { email: memberEmail, password: tempPassword },
            mfaToken,
            mfaTypeId,
          ),
        );
      } catch (err) {
        reduxStore.dispatch(addNotification(err.error_description, "error"));
      }
    }
    if (
      err.status === 401 &&
      err.error === "Unable to login, please contact support to resolve issue."
    ) {
      setIsAccountLocked(true);
    } else {
      setIsAccountLocked(false);
      reduxStore.dispatch(addNotification(err.error, "error"));
    }
  }
};

//guardian who signed in as dependent and now trying to sign is as guardian
export const chooseUserSignInSubmit = async (
  e,
  email,
  password,
  targetId,
  memberDateOfBirth,
  initiateMfaLogin,
  setIsAccountLocked,
) => {
  e.preventDefault();

  TRACK_EVENT.FORM_SUBMITTED(routes.ChooseUserPassword.as, "Sign In");
  rememberParentLocalStorage();
  localStorage.removeItem("initialTarget");
  try {
    await pichu.signIn(
      email,
      password,
      ["group:member", "group:moments_only"],
      { skipConfirmationEmail: true },
    );
    handleSignInOnChooseUserPage(targetId, memberDateOfBirth, true);
  } catch (err) {
    if (err.error === "mfa_authentication_required") {
      const mfaToken = err.error_data.mfa_token;
      const mfaTypeId = err.error_data.mfa_type_id;
      try {
        return mfaCreateAccessToken(mfaToken, () =>
          initiateMfaLogin(
            { email: email, password: password },
            mfaToken,
            mfaTypeId,
          ),
        );
      } catch (_err) {
        reduxStore.dispatch(
          addNotification("We had a problem signing in", "error"),
        );
      }
    }
    if (
      err.status === 401 &&
      err.error === "Unable to login, please contact support to resolve issue."
    ) {
      setIsAccountLocked(true);
      return;
    } else {
      setIsAccountLocked(false);
    }
    reduxStore.dispatch(
      addNotification("There was a problem with your password", "error"),
    );
  }
};

export const managerIsAbleToAutoSignInAsDependent = (targetId) => {
  if (localStorage.getItem(`${DEPENDENT_KEY}-${targetId}`)) return true;
  return false;
};

export const getTemporaryPasswordForDependent = (targetId, targetDependent) => {
  const skipUseTempPassword =
    !targetDependent || managerIsAbleToAutoSignInAsDependent(targetId);
  const { data, loading } = useTemporaryPassword(targetId, skipUseTempPassword);

  if (loading) {
    return { loading };
  }
  return { data };
};
