import { createIcon } from "@chakra-ui/react";

export const Union = createIcon({
  displayName: "Union",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C7.23858 0 5 2.23858 5 5V7.58152C2.06817 8.76829 0 11.6426 0 15V21C0 21.5523 0.447715 22 1 22H12C16.4183 22 20 18.4183 20 14V8C20 7.44772 19.5523 7 19 7H15V5C15 2.23858 12.7614 0 10 0ZM13 7V5C13 3.34315 11.6569 2 10 2C8.34315 2 7 3.34315 7 5V7H8H13ZM8 9C4.68629 9 2 11.6863 2 15V20H12C15.3137 20 18 17.3137 18 14V9H14H8ZM12 13C12 13.7403 11.5978 14.3866 11 14.7324V17C11 17.5523 10.5523 18 10 18C9.44771 18 9 17.5523 9 17V14.7324C8.4022 14.3866 8 13.7403 8 13C8 11.8954 8.89543 11 10 11C11.1046 11 12 11.8954 12 13Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    "aria-label": "Union icon",
    role: "img",
  },
});
