import { useEffect, useRef } from "react";
import { Button, Divider, Switch } from "@springcare/sh-component-library";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

import { SHTooltip as Tooltip } from "design-system/components";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";

import { useInViewportOnce } from "hooks/useInViewport";

import {
  trackCompanyExclusiveViewed,
  trackCompanyExclusiveClicked,
} from "components/templates/Browse/ProviderBrowsePage/analytics";

const CompanyExclusiveSection = ({
  showDedicatedProviderToggle,
  isSm,
  isMd,
  isLg,
  isXlg,
  t,
}) => {
  // used for component-viewed tracking
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const { isCompanyExclusive, setIsCompanyExclusive } =
    useProviderBrowseContext();

  useEffect(() => {
    if (inViewport) {
      trackCompanyExclusiveViewed();
    }
  }, [inViewport]);

  const handleOnChange = () => {
    trackCompanyExclusiveClicked(isCompanyExclusive);
    setIsCompanyExclusive(!isCompanyExclusive);
  };

  return (
    <>
      {showDedicatedProviderToggle && (isLg || isXlg) && (
        <HStack gap={4} ref={ref}>
          <Text size="body-medium" minW={140}>
            {t("newBrowseProviderPage.companyExclusive")}
          </Text>

          <Tooltip
            mt={24}
            label={t("newBrowseProviderPage.companyExclusiveTooltip")}
            placement="bottom"
            hasArrow
            id="companyExclusiveTooltip"
            role="tooltip"
            bg="#FFF"
            color="content-primary"
            borderRadius={8}
          >
            <Button
              p={0}
              ml={0}
              variant="no-emphasis"
              aria-labelledby="companyExclusiveTooltip"
              marginInlineStart="0px !important"
            >
              <InfoOutlineIcon boxSize={20} />
            </Button>
          </Tooltip>

          <Box marginInlineEnd="4px !important">
            <Switch
              isChecked={isCompanyExclusive}
              // @ts-ignore
              onChange={handleOnChange}
            />
          </Box>

          <Box marginInlineStart="0px !important">
            <Divider orientation="vertical" h={40} />
          </Box>
        </HStack>
      )}
      {showDedicatedProviderToggle && (isSm || isMd) && (
        <Flex
          align="center"
          justifyContent="center"
          bg="accent-on-base"
          py={20}
          ml={isMd && 83}
          borderTop="1px solid #EFEDE8"
          borderBottom="1px solid #EFEDE8"
          ref={ref}
        >
          <Text size="body-medium">
            {t("newBrowseProviderPage.companyExclusive")}
          </Text>

          <Box mr={16}>
            <Tooltip
              mt={16}
              label={t("newBrowseProviderPage.companyExclusiveTooltip")}
              placement="bottom"
              hasArrow
              id="companyExclusiveTooltip"
              role="tooltip"
              bg="#FFF"
              color="content-primary"
              borderRadius={8}
            >
              <Button
                p={0}
                ml={0}
                variant="no-emphasis"
                aria-labelledby="companyExclusiveTooltip"
              >
                <InfoOutlineIcon boxSize={18} />
              </Button>
            </Tooltip>
          </Box>

          <Box>
            <Switch
              isChecked={isCompanyExclusive}
              // @ts-ignore
              onChange={handleOnChange}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default CompanyExclusiveSection;
