import { useSessionAuthentication } from "context/SessionRoomContext";
import { useEffect } from "react";
import { COMPASS_DOMAIN } from "constants/api";
import Cookies from "universal-cookie";

type CompassAuthenticatedWindow = Window & {
  hasCompassAuthentication: boolean | undefined;
  compassAuthToken: string | undefined;
};

const buildCookies = () => {
  const optOutCookie = "compass_auth_opt_out";
  const accessTokenCookie = "compass_auth_access_token";
  const cookieExpiration = new Date();
  cookieExpiration.setFullYear(cookieExpiration.getFullYear() + 1);

  const cookies = new Cookies(null, {
    path: "/",
    domain: window.location.host.includes("localhost")
      ? undefined
      : `.${window.location.host.split(".").slice(-2).join(".")}`,
    expires: cookieExpiration,
  });

  return { optOutCookie, accessTokenCookie, cookies };
};

export const useCompassAuthRedirect = () => {
  const compassUrl = `${COMPASS_DOMAIN}/sign_in?session`;
  const { authenticate, quitAuthentication } = useSessionAuthentication();

  useEffect(() => {
    const { cookies, optOutCookie, accessTokenCookie } = buildCookies();

    const changeListener = ({ name, value }) => {
      if (value && (name === optOutCookie || name === accessTokenCookie)) {
        if (name === optOutCookie) {
          quitAuthentication();
        }

        if (name === accessTokenCookie) {
          localStorage.setItem("compass_redirect_access_token", value);
          authenticate();
        }
        cookies.remove(optOutCookie);
        cookies.remove(accessTokenCookie);
      }
    };

    cookies.addChangeListener(changeListener);

    return () => {
      cookies.removeChangeListener(changeListener);
    };
  }, [authenticate, quitAuthentication]);

  const startRedirect = () => {
    const { cookies, optOutCookie, accessTokenCookie } = buildCookies();
    window.open(
      compassUrl,
      "compassAuth",
      "popup,width=772,height=630",
    ) as CompassAuthenticatedWindow;
    cookies.remove(optOutCookie);
    cookies.remove(accessTokenCookie);
  };

  return startRedirect;
};
