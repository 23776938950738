import { API_DOMAIN } from "constants/api";

export type StartRecordingVariables = {
  appointment_id: string;
  member_livekit_audio_track_id: string;
  care_provider_livekit_audio_track_id: string;
};

export const usePostStartRecording = () => {
  const rotomUrl = API_DOMAIN;

  return async (variables: StartRecordingVariables) => {
    const res = await fetch(
      `${rotomUrl}/api/ehr/session_recording_audio_files/start_audio_recordings`,
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("compass_redirect_access_token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(variables),
      },
    );
    return await res.json();
  };
};
export default usePostStartRecording;
