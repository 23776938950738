import { useRef, useEffect } from "react";
import {
  isChrome,
  isSafari,
  isEdge,
  isFirefox,
  isMacOs,
  isWindows,
  isIOS,
  isAndroid,
} from "react-device-detect";
import { links } from "@spring/constants";
import { Button } from "@springcare/sh-component-library";
import { Flex, Box } from "@chakra-ui/react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import SessionDetailsContainer from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsContainer";
import SessionDetailsHeader from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsHeader";
import SessionDetailsBody from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsBody";
import VideoPreviewSection from "components/templates/SHSessionRoom/components/VideoPreviewSection";
import SessionConsentJoinAlert from "components/templates/SHSessionRoom/components/SessionConsentJoin";
import ProviderStatusBadge from "components/templates/SHSessionRoom/components/ProviderStatusBadge";

import {
  useSessionViewStatus,
  useSessionUserActive,
  useSessionConnectStatus,
  useSessionDevices,
  useParticipantMetadata,
} from "context/SessionRoomContext";
import {
  trackPageVersionViewed,
  trackStartSessionClick,
  trackContactSupportClicked,
  trackHelpInstructionsClicked,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import {
  SessionViewStatus,
  ParticipantType,
} from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";

import {
  requestKeepActive,
  releaseKeepActive,
} from "components/templates/SHSessionRoom/utils/keepMemberLoggedIn";

const PreJoin = ({ sessionData, videoRef, appointmentId, isMobile }) => {
  const timerRef = useRef(null);
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoin",
  });
  const { sessionViewStatus, setSessionViewStatus } = useSessionViewStatus();
  const { wakeLock, setWakeLock, isLoggedIn } = useSessionUserActive();
  const { setShouldConnect, setSkipSetStatus, likelyFirewallError } =
    useSessionConnectStatus();
  const { sessionDevices, devicePermissionsDenied } = useSessionDevices();

  const enableConsentsWaitingRoom =
    useFeatureFlag(FLAGS.ENABLE_CONSENTS_WAITING_ROOM_LIVEKIT) &&
    sessionDevices?.stream;
  const { participantType } = useParticipantMetadata();

  // Grace period is 10 minutes (600K milliseconds)
  const PROVIDER_GRACE_PERIOD = 600_000;

  useEffect(() => {
    trackPageVersionViewed("pre_join");
  }, []);

  useEffect(() => {
    if (sessionViewStatus === SessionViewStatus.MemberIsWaiting) {
      // sets a Grace Period timer for Provider, when a Member requests to join room
      timerRef.current = setTimeout(() => {
        setSessionViewStatus(SessionViewStatus.ProviderNoShow);
      }, PROVIDER_GRACE_PERIOD);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [sessionViewStatus]);

  const handleJoinMeeting = () => {
    // Keep logged-in Members from being logged out due to inactivity
    // This prevents _Members who are waiting_ from experiencing a timeout
    if (isLoggedIn) {
      requestKeepActive(setWakeLock);
    }
    setShouldConnect(true);
    setSessionViewStatus(SessionViewStatus.Connecting);
    trackStartSessionClick();
  };

  const handleCancelJoinMeeting = () => {
    // Restore inactivity log-out mechanism
    if (isLoggedIn) {
      releaseKeepActive(wakeLock, setWakeLock);
    }
    // NOTE: We need to reload here to disconnect from livekit
    // doing this reconnects, and resets the Member's permissions
    // which allows them to ask permission again, if they want
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  };

  const handleContactSupport = (action: string) => {
    setSkipSetStatus(true);
    trackContactSupportClicked(action);
    window.open(links.Support, "_blank");
  };

  const getSupportUrl = () => {
    if (sessionViewStatus === SessionViewStatus.LikelyFirewallError) {
      return links.FirewallSupportPage;
    }

    if (sessionViewStatus === SessionViewStatus.CameraInUsePermissionsDenied) {
      return links.GeneralPermissionsSupportPage;
    }

    if (sessionViewStatus === SessionViewStatus.AmbiguousPermissionsDenied) {
      return isIOS
        ? links.SystemPermissionsSupportPageIOS
        : links.GeneralPermissionsSupportPage;
    }

    if (sessionViewStatus === SessionViewStatus.BrowserPermissionsDenied) {
      if (isChrome) {
        return links.BrowserPermissionsSupportPageChrome;
      } else if (isSafari) {
        return links.BrowserPermissionsSupportPageSafari;
      } else if (isEdge) {
        return links.BrowserPermissionsSupportPageEdge;
      } else if (isFirefox) {
        return links.BrowserPermissionsSupportPageFirefox;
      } else {
        return links.GeneralPermissionsSupportPage;
      }
    }

    if (sessionViewStatus === SessionViewStatus.SystemPermissionsDenied) {
      if (isMacOs) {
        return links.SystemPermissionsSupportPageMacOS;
      } else if (isWindows) {
        return links.SystemPermissionsSupportPageWindows;
      } else if (isIOS) {
        return links.SystemPermissionsSupportPageIOS;
      } else if (isAndroid) {
        return links.SystemPermissionsSupportPageAndroid;
      } else {
        return links.GeneralPermissionsSupportPage;
      }
    }
  };

  const HelpButtons = ({ instructionType }) => {
    const handleInstructionsClick = () => {
      const url = getSupportUrl();
      trackHelpInstructionsClicked(`${instructionType} Instructions`);
      window.open(url, "_blank");
    };

    return (
      <>
        <Button
          w="100%"
          variant="high-emphasis"
          onClick={handleInstructionsClick}
          mb="v-16"
          _focus={{ outline: "0px solid transparent !important" }}
          _focusVisible={{ outline: "2px solid black !important" }}
        >
          {t("helpButtons.instructions")}
        </Button>

        <Button
          w="100%"
          variant="low-emphasis"
          onClick={() => handleContactSupport(instructionType)}
          _focus={{ outline: "0px solid transparent !important" }}
          _focusVisible={{ outline: "2px solid black !important" }}
        >
          {t("helpButtons.needHelp")}
        </Button>
      </>
    );
  };

  return (
    <SessionDetailsContainer isMobile={isMobile}>
      <VideoPreviewSection videoRef={videoRef} isMobile={isMobile} />

      <Flex
        w={["100%", "100%", "v-4xl", "v-4xl"]}
        flexDir="column"
        mb="v-8"
        alignSelf="center"
      >
        <SessionDetailsHeader isMobile={isMobile} />

        <SessionDetailsBody
          sessionData={sessionData}
          appointmentId={appointmentId}
        >
          <Box>
            {enableConsentsWaitingRoom &&
            !devicePermissionsDenied &&
            !likelyFirewallError &&
            sessionData?.is_eligible_for_recording &&
            sessionViewStatus !== SessionViewStatus.ProviderNoShow ? (
              <SessionConsentJoinAlert
                handleJoin={handleJoinMeeting}
                appointmentId={appointmentId}
                sessionData={sessionData}
                sessionViewStatus={sessionViewStatus}
              />
            ) : (
              <>
                {sessionViewStatus !== SessionViewStatus.ProviderNoShow &&
                  !devicePermissionsDenied &&
                  !likelyFirewallError && (
                    <Box>
                      {participantType === ParticipantType.Member &&
                        sessionViewStatus ===
                          SessionViewStatus.MemberIsWaiting && (
                          <ProviderStatusBadge sessionData={sessionData} />
                        )}

                      <Button
                        w="100%"
                        variant={
                          participantType === ParticipantType.Member &&
                          sessionViewStatus ===
                            SessionViewStatus.MemberIsWaiting
                            ? "medium-emphasis"
                            : "high-emphasis"
                        }
                        onClick={handleJoinMeeting}
                        isLoading={
                          sessionViewStatus === SessionViewStatus.Connecting ||
                          sessionViewStatus ===
                            SessionViewStatus.MemberIsWaiting
                        }
                        disabled={
                          !sessionDevices.stream ||
                          sessionViewStatus === SessionViewStatus.Connecting ||
                          sessionViewStatus ===
                            SessionViewStatus.MemberIsWaiting
                        }
                        _focus={{ outline: "0px solid transparent !important" }}
                        _focusVisible={{
                          outline: "2px solid black !important",
                        }}
                      >
                        {participantType === ParticipantType.Provider
                          ? t("startSession")
                          : t("joinSession")}
                      </Button>
                    </Box>
                  )}
              </>
            )}
            {sessionViewStatus === SessionViewStatus.MemberIsWaiting && (
              <Button
                w="100%"
                mt="v-8"
                variant="low-emphasis"
                onClick={handleCancelJoinMeeting}
                _focus={{ outline: "0px solid transparent !important" }}
                _focusVisible={{ outline: "2px solid black !important" }}
              >
                {t("cancel")}
              </Button>
            )}

            {sessionViewStatus === SessionViewStatus.ProviderNoShow && (
              <Button
                w="100%"
                variant="high-emphasis"
                onClick={() => handleContactSupport("Provider No Show")}
                _focus={{ outline: "0px solid transparent !important" }}
                _focusVisible={{ outline: "2px solid black !important" }}
              >
                {t("contactSupport")}
              </Button>
            )}
            {(devicePermissionsDenied || likelyFirewallError) && (
              <HelpButtons
                instructionType={
                  devicePermissionsDenied
                    ? "Browser Permissions"
                    : "Likely Firewall Error"
                }
              />
            )}
          </Box>
        </SessionDetailsBody>
      </Flex>
    </SessionDetailsContainer>
  );
};

export default PreJoin;
