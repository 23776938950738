import { gql } from "@apollo/client";

const signupMember = gql`
  mutation SignupMember($input: SignupMemberInput!) {
    signupMember(input: $input) {
      success
    }
  }
`;

export default signupMember;
