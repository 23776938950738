import growlithe from "constants/growlithe";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { saveAs } from "file-saver";
import Meowth from "@spring/meowth";
import { timeFormats } from "@spring/constants";
import { TRACK_EVENT } from "utils/mixpanel";
import { TextButton } from "@springcare/sh-component-library";
import { Box } from "@chakra-ui/react";
import { VDownloadIcon } from "@springcare/verdant-icons-react";
import { useState } from "react";

const DownloadCTA = (props) => {
  const { selectedAssessment, t, download, Router } = props;

  const [pdfDownloadClicked, setPdfDownloadClicked] = useState(false);

  function downloadResults() {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.router.asPath,
      "Download Assessment Results",
    );
    const taken = DateTime.fromISO(selectedAssessment.ended_at).toFormat(
      timeFormats.dateLongform,
    );

    download("reports/assessment", {
      download: true,
      filename: `Spring ${selectedAssessment.kind} -- ${taken}`,
      key: `${selectedAssessment.id}.pdf`,
      headers: Meowth.getAuthorizationHeader(),
      onDownload: (blob) => {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
        saveAs(blob, `${selectedAssessment.id}.pdf`);
        setPdfDownloadClicked(false);
      },
    });
  }

  return (
    <Box pb={60} aria-live="polite">
      <TextButton
        isLoading={pdfDownloadClicked}
        loadingText={t("downloadCTA.loadingText")}
        spinnerPlacement="start"
        _focusVisible={{ boxShadow: "0 0 0 3px black" }}
        onClick={() => {
          setPdfDownloadClicked(true);
          downloadResults();
        }}
        icon={VDownloadIcon}
      >
        {t("downloadCTA.buttonText")}
      </TextButton>
    </Box>
  );
};

DownloadCTA.propTypes = {
  Router: PropTypes.object,
  download: PropTypes.func,
  selectedAssessment: PropTypes.object,
  t: PropTypes.func,
};

export default compose(connect(null, { download: growlithe.download }))(
  DownloadCTA,
);
