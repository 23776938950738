import { Checkbox, Flex, Stack, Text, Box } from "design-system";
import { Text as VText } from "@chakra-ui/react";

import { colors } from "design-system/theme/base/colors";

import PropTypes from "prop-types";
import { InputHTMLAttributes, useState } from "react";

const COLORS_OUTLINED = {
  focusColor: colors.primary.base,
  background: colors.tertiary[25],
};

const outlinedClasses = {
  h: "auto",
  px: "12",
  py: "16",
  w: "100%",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  borderRadius: "8",
  bg: COLORS_OUTLINED.background,
  transition: "box-shadow 100ms",
  _checked: {
    boxShadow: `0 0 0 2px ${COLORS_OUTLINED.focusColor}`,
  },
  "@media(hover: hover)": {
    _hover: {
      boxShadow: `0 0 0 1px ${COLORS_OUTLINED.focusColor}`,
      transition: "box-shadow 150ms",
      _checked: {
        boxShadow: `0 0 0 3px ${COLORS_OUTLINED.focusColor}`,
      },
    },
  },
};

const SHOutlinedCircleCheckBox = ({
  fieldKey,
  label,
  subheader,
  iconRight,
  iconLeft,
  onChange,
  checked,
  dataCy,
  inputDataCy,
  isVerdant = false,
  subheaderSpacing = true,
  checkPositionTop = false,
  isDisabled = false,
}) => {
  const [isSelected, setIsSelected] = useState(checked);

  if (checkPositionTop) {
    outlinedClasses["span"] = {
      alignSelf: "flex-start",
    };
  }
  const handleCheckboxOnChange = (val) => {
    setIsSelected(!isSelected);
    onChange(val);
    val.target.blur();
  };

  return (
    <Box mb="18">
      <Checkbox
        sx={outlinedClasses}
        spacing="16"
        key={fieldKey}
        defaultChecked={false}
        variant="circle"
        onChange={handleCheckboxOnChange}
        isChecked={isSelected}
        inputProps={
          { "data-cy": inputDataCy } as InputHTMLAttributes<HTMLInputElement>
        }
        data-cy={dataCy}
        isDisabled={isDisabled}
      >
        <Flex alignItems="center">
          <Stack spacing={0}>
            {isVerdant ? (
              <VText size="body-medium-regular" color="content-primary">
                {label}
              </VText>
            ) : (
              <>
                <Flex direction="row">
                  {iconLeft && <Box mr={"12px"}>{iconLeft}</Box>}
                  <Text
                    letterSpacing="0.0275em"
                    fontWeight={"400"}
                    fontSize={"16"}
                    fontStyle="HK Grotesk"
                    color="tertiary.base"
                  >
                    {label}
                  </Text>
                  {iconRight && <Box ml={"6px"}>{iconRight}</Box>}
                </Flex>
                {subheader && (
                  <Text
                    letterSpacing={subheaderSpacing ? "0.0275em" : ""}
                    fontWeight={"regular"}
                    fontSize={"16"}
                    fontStyle="HK Grotesk"
                    color="tertiary.base"
                  >
                    {subheader}
                  </Text>
                )}
              </>
            )}
          </Stack>
        </Flex>
      </Checkbox>
    </Box>
  );
};

SHOutlinedCircleCheckBox.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  iconRight: PropTypes.node,
  iconLeft: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  dataCy: PropTypes.string,
  inputDataCy: PropTypes.string,
  isVerdant: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export { SHOutlinedCircleCheckBox };
