import { gql } from "@apollo/client";

export const getMemberBalance = gql`
  query getMemberBalance($member_id: ID!, $use_cache: Boolean) {
    member_balance(member_id: $member_id, use_cache: $use_cache) {
      balance
      lookup_code
      apero_payment_url
      latest_statement_url
    }
  }
`;
