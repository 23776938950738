//
import { useState, useEffect } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { TransitionStack } from "@spring/smeargle";
import Meowth from "@spring/meowth";

import { TakeAssessment } from "components";
import { Box, Spinner } from "@chakra-ui/react";
import { createInitialEvaluation } from "operations/mutations/assessment";
import { getMemberInfo } from "operations/queries/member";
import { connect } from "react-redux";
import { getIterableCampaignInfo } from "utils/localStorage";

const InitialAssessment = (props) => {
  const [assessmentId, setAssessmentId] = useState(null);

  const memberId = props?.data?.user?.member?.id;
  const version_number = props?.assessmentVersion;

  useEffect(() => {
    if (memberId) {
      props.createInitialEvaluation(memberId, version_number).then((res) => {
        const id = res.data.createInitialEvaluation.assessment.id;
        setAssessmentId(id);
      });
    }
  }, [memberId]);

  const LoadingSpinner = () => {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary.base"
          size="xl"
        />
      </Box>
    );
  };

  return (
    <TransitionStack
      animation="fadeWipeLeft"
      currentIndex={0}
      stack={[
        // renderComponent,
        <div key="initialAssessment">
          {assessmentId && (
            <TakeAssessment id={assessmentId} showGoalsLast={true} />
          )}
          {!assessmentId && <LoadingSpinner />}
        </div>,
      ]}
    />
  );
};

export { InitialAssessment };

export default compose(
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(createInitialEvaluation, {
    props: ({ mutate }) => ({
      createInitialEvaluation: (id, version_number) =>
        mutate({
          variables: {
            member_id: id,
            version_number: version_number,
            campaign: getIterableCampaignInfo(),
          },
        }),
    }),
  }),
  connect((state) => ({
    isLoggedIn: state.auth?.isLoggedIn || false,
  })),
)(InitialAssessment);
