import {
  Box,
  Heading,
  Image,
  Skeleton,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import { Card } from "@springcare/sh-component-library";
import { FC } from "react";
import { GradientLayers } from "./GradientLayers";
import { PhotoCardProps } from "./types";

/**
 * This component renders a PhotoCard component, which may render either a banner or a tile based on variant.
 * The PhotoCard renders the passed in image, and adds two gradient layers on top of the image:
 * One is a gradient based on the image colors, which is passed in. The second is a blanket gradient
 * with fixed value that helps darken the image for accessibility.
 *
 * The PhotoCard will always render a heading.
 * A banner variant will render a banner that may optionally render a description, tag, or cta.
 * A tile variant will render only the heading.
 *
 * The PhotoCard is wrapped in a Skeleton to match size of the component, which shows
 * when the loading value is true.
 */

export const PhotoCard: FC<React.PropsWithChildren<PhotoCardProps>> = (
  props,
) => {
  const {
    altText = "",
    imgUrl,
    heading,
    description,
    tag,
    cta,
    photoGradient,
    variant = "banner",
    loading = false,
    onClickCallback,
    role = "button",
  } = props;

  const isTileVariant = variant === "tile";

  const renderCardAs = isTileVariant ? "button" : "div";
  const headingTextSize = isTileVariant
    ? "heading-medium"
    : useBreakpointValue([
        "heading-medium",
        "heading-large",
        "heading-display",
      ]);
  const photoHeight = isTileVariant
    ? ["440px", "440px", "400px"]
    : ["498px", "512px", "512px"];
  const photoWidth = isTileVariant
    ? ["305px", "305px", "259px", "100%"]
    : "100%";
  const stackMaxWidth = isTileVariant ? "100%" : ["100%", "100%", "50%", "50%"];
  const stackMargin = isTileVariant ? "24px" : ["24px", "40px", "40px"];
  const objectPosition = isTileVariant
    ? "center"
    : [
        "right -13em bottom 0",
        "right -10em bottom 0",
        "right -8em bottom 0",
        "right",
      ];

  return (
    <Skeleton isLoaded={!loading} borderRadius="v-xl">
      <Card
        role={role}
        width={isTileVariant ? "fit-content" : "100%"}
        size="none"
        position="relative"
        onClick={onClickCallback}
        as={renderCardAs}
      >
        <>
          <Box w={photoWidth} h={photoHeight} position="relative">
            <Image
              src={imgUrl}
              objectFit="cover"
              objectPosition={objectPosition}
              alt={altText}
              height={photoHeight}
              width={photoWidth}
            />
            <GradientLayers photoGradient={photoGradient} variant={variant} />
          </Box>

          <VStack
            maxW={stackMaxWidth}
            margin={stackMargin}
            alignItems="flex-start"
            position="absolute"
            bottom={0}
          >
            <Heading
              size={headingTextSize}
              color="background-base"
              textAlign={"start"}
            >
              {heading}
            </Heading>
            {description && (
              <Text size="body-medium-regular" color="background-base">
                {description}
              </Text>
            )}
            {tag && tag}
            {cta && cta}
          </VStack>
        </>
      </Card>
    </Skeleton>
  );
};
