"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _fp = require("lodash/fp");
/**
 * Takes a predicate, and removes any value that returns
 * truthy from the array.
 *
 * The old array is not updated.
 *
 * @name reject
 * @param {*} predicate
 * @param {Array} array
 * @returns {Array} Returns a new array without the rejected value.
 *
 * @example
 * const arr = [{name: 'kyle', name: 'charlie'}];
 * const newArr =  reject({name: 'kyle'}, arr);
 *
 * console.log(arr, newArr); // [{name: 'kyle', name: 'charlie'}], [{ name: 'charlie' }]
 *
 * @example
 * const arr = [1, 2, 3];
 * const newArr =  reject(n => n === 2, arr);
 *
 * console.log(arr, newArr); // [1,2,3], [1,3]
 */

function curried(predicate, array) {
  return (0, _fp.reject)(predicate, array);
}
var reject = (0, _fp.curry)(curried);
var _default = exports["default"] = reject;