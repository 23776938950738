//

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

const AuthWrapper = (props) => (
  <div
    className={classnames(styles.container, {
      [styles.compact]: props.compact,
      [styles.noMargin]: props.noMargin,
    })}
  >
    {props.children}
  </div>
);

AuthWrapper.propTypes = {
  children: PropTypes.any,
  compact: PropTypes.any,
  noMargin: PropTypes.any,
};

export default AuthWrapper;
