//

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import { compose } from "redux";

import { AuthWrapper } from "components/layout";

import {
  LandingPageWrapper,
  MemberVerificationMenu,
  JoinSpringHealthHeader,
} from "components";
import Snorlax from "utils/snorlax";
import { track, makeEventString, EVENT_TYPE } from "utils/mixpanel";
import routes from "routes";

const mpPrefix = "Verify Your Benefit";

const ViewState = Object.freeze({
  Initial: "INITIAL",
  CompleteSignup: "COMPLETE_SIGNUP",
});

class SignupPage extends Component {
  static propTypes = {
    allowed: PropTypes.any,
    router: PropTypes.shape({
      query: PropTypes.shape({
        dependent: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      view: ViewState.Initial,
      signingUpViaInvitationEmail: this.setInviteToken() ? true : false,
    };
  }

  componentDidMount() {
    const { query } = this.props.router;

    if (this.state.signingUpViaInvitationEmail) {
      // TODO: Follow up on old email referals to make sure they are being redirected and that we dont have any current emails referrals going to /sign_up
      // Email invites should all currently be going to /create_account, but this event is being triggered occationally
      // possibly from old email invites.
      track(`${mpPrefix} -- Viewed`);
      this.setState({ view: ViewState.CompleteSignup });
    } else {
      track(`${mpPrefix} NOT Via Invitation Email -- Viewed`, {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.PAGE_VIEWED, {
          page: routes.SignUp.as,
        }),
      });
    }

    if (query.dependent === "true") {
      // TODO: Follow up on old email referals to make sure they are being redirected and that we dont have any current emails referrals going to /sign_up
      // Email invites should all currently be going to /create_account, but this event is being triggered occationally
      // possibly from old email invites.
      track(`${mpPrefix} -- Dependent Viewed`);
    }
  }

  get form() {
    return <MemberVerificationMenu />;
  }

  get renderScreen() {
    return (
      <>
        <JoinSpringHealthHeader />
        {this.form}
      </>
    );
  }

  setInviteToken = () => {
    const isBrowser = typeof window !== "undefined";
    const params = isBrowser && new URLSearchParams(window.location.search);
    const inviteToken = params && params.get("invite_token");

    return inviteToken ? inviteToken : "";
  };

  render() {
    if (!this.props.router) {
      return null;
    }

    return (
      <LandingPageWrapper allowed={this.props.allowed} darkBg={false}>
        <AuthWrapper noMargin>{this.renderScreen}</AuthWrapper>
      </LandingPageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  allowed: () => Snorlax(state).loggedOut.to(routes.MemberHome),
});

export default compose(withRouter, connect(mapStateToProps))(SignupPage);
