//

import React from "react";
import yup from "schemas/yup";
import { RadioButtons } from "@spring/smeargle";
import { openModal } from "@spring/smeargle/actions";
import { formOptions, modalIds } from "@spring/constants";

const numberField = () => yup.number().required();

const prompt1Schema = {
  "PHQ9.q1": numberField(),
};
const prompt2Schema = {
  "PHQ9.q2": numberField(),
};
const prompt3Schema = {
  "PHQ9.q3": numberField(),
};
const prompt4Schema = {
  "PHQ9.q4": numberField(),
};
const prompt5Schema = {
  "PHQ9.q5": numberField(),
};
const prompt6Schema = {
  "PHQ9.q6": numberField(),
};
const prompt7Schema = {
  "PHQ9.q7": numberField(),
};
const prompt8Schema = {
  "PHQ9.q8": numberField(),
};
const prompt9Schema = {
  "PHQ9.q9": numberField(),
};
const prompt10Schema = {
  "PHQ9.q10": numberField(),
};

export const PHQ9Schema = {
  ...prompt1Schema,
  ...prompt2Schema,
  ...prompt3Schema,
  ...prompt4Schema,
  ...prompt5Schema,
  ...prompt6Schema,
  ...prompt7Schema,
  ...prompt8Schema,
  ...prompt9Schema,
  ...prompt10Schema,
};

const { HowDifficult, HowManyDays } = formOptions;

const PHQ9 = [
  {
    id: "PHQ9--1",
    title:
      "Over the last two weeks, how often have you been bothered by **having little interest in doing things**?",
    schema: yup.object().shape(prompt1Schema),
    questions: [
      <RadioButtons
        key={1}
        border={true}
        fieldKey="PHQ9.q1"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--2",
    title:
      "Over the last two weeks, how often have you been bothered by **feeling down, depressed, or hopeless**?",
    schema: yup.object().shape(prompt2Schema),
    questions: [
      <RadioButtons
        key={2}
        border={true}
        fieldKey="PHQ9.q2"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--3",
    title:
      "Over the last two weeks, how often have you been bothered by having **trouble falling asleep, staying asleep, or sleeping too much**?",
    schema: yup.object().shape(prompt3Schema),
    questions: [
      <RadioButtons
        key={3}
        border={true}
        fieldKey="PHQ9.q3"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--4",
    title:
      "Over the last two weeks, how often have you been bothered by **feeling tired or having little energy**?",
    schema: yup.object().shape(prompt4Schema),
    questions: [
      <RadioButtons
        key={4}
        border={true}
        fieldKey="PHQ9.q4"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--5",
    title:
      "Over the last two weeks, how often have you been bothered by having **poor appetite or overeating**?",
    schema: yup.object().shape(prompt5Schema),
    questions: [
      <RadioButtons
        key={5}
        border={true}
        fieldKey="PHQ9.q5"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--6",
    title:
      "Over the last two weeks, how often have you been bothered by **feeling bad about yourself** - or that **you're a failure or have let yourself or your family down**?",
    schema: yup.object().shape(prompt6Schema),
    questions: [
      <RadioButtons
        key={6}
        border={true}
        fieldKey="PHQ9.q6"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--7",
    title:
      "Over the last two weeks, how often have you been bothered by having **trouble concentrating on things**, such as reading the newspaper or watching television?",
    schema: yup.object().shape(prompt7Schema),
    questions: [
      <RadioButtons
        key={7}
        border={true}
        fieldKey="PHQ9.q7"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--8",
    title:
      "Over the last two weeks, how often have you been bothered by **moving or speaking so slowly** that other people could have noticed. Or, the opposite were **so fidgety or restless** that you moved around a lot more than usual?",
    schema: yup.object().shape(prompt8Schema),
    questions: [
      <RadioButtons
        key={8}
        border={true}
        fieldKey="PHQ9.q8"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--9",
    title:
      "Over the last two weeks, how often have you been bothered by having **thoughts that you would be better off dead or of hurting yourself** in some way?",
    schema: yup.object().shape(prompt9Schema),
    onAdvance: (data, assessment, dispatch) => {
      let q9 = Number(data.q9);

      if (q9 > 1) {
        dispatch(
          openModal(modalIds.suicidePreventionModal, { immediateRisk: true }),
        );
      }

      if (q9 === 1) {
        dispatch(
          openModal(modalIds.suicidePreventionModal, { immediateRisk: false }),
        );
      }

      if (q9 >= 2) {
        assessment.injectQuestionnaire("SIB");
      }
    },
    questions: [
      <RadioButtons
        key={9}
        border={true}
        fieldKey="PHQ9.q9"
        options={HowManyDays}
      />,
    ],
  },
  {
    id: "PHQ9--10",
    title:
      "If you checked off any problems, how difficult have those problems made it for you to do your work, take care of things at home, or get along with other people?",
    schema: yup.object().shape(prompt10Schema),
    questions: [
      <RadioButtons
        key={10}
        border={true}
        fieldKey="PHQ9.q10"
        options={HowDifficult}
      />,
    ],
  },
];

export default PHQ9;
