import { gql } from "@apollo/client";

const cohortDetails = gql`
  fragment cohortDetails on Cohort {
    id
    dependents_allowed
    default
    name
    benefits_summary
    basic_visits_covered
    specialist_visits_covered
    sponsored_visits_covered

    members {
      id
    }
  }
`;

export default cohortDetails;
