import React from "react";

function NoProvidersChat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#1C6B57"
        fillRule="evenodd"
        d="M11 26.5V19a7 7 0 017-7h9a2 2 0 012 2v5a7 7 0 01-7 7h-7l-2 1.5-2 1.5v-2.5zm4.667 1.5H22a9 9 0 009-9v-5a4 4 0 00-4-4h-9a9 9 0 00-9 9v10a2 2 0 003.2 1.6l3.467-2.6zM15 17a1 1 0 011-1h8a1 1 0 110 2h-8a1 1 0 01-1-1zm1 3a1 1 0 100 2h5a1 1 0 100-2h-5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default NoProvidersChat;
