import { Box, Text, Link, useMediaQuery } from "design-system";
import { NoProvidersChat, NoProviderEmail } from "components/atoms";
import { useTranslation } from "react-i18next";
import { Button, Divider } from "@springcare/sh-component-library";
import { useDisclosure } from "@chakra-ui/react";
import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";
import routes from "routes/index";
import CareNavigatorAvailabilityModal from "components/modals/CareNavigatorScheduleModal";

const ScheduleWithCN = ({ t }) => {
  const {
    isOpen: isCNScheduleModalOpen,
    onOpen: openCNScheduleModal,
    onClose: closeCNScheduleModal,
  } = useDisclosure();

  const handleCNScheduleClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Care Navigator Schedule Button Clicked",
      {},
    );
    Router.push(routes.CareNavigation.as);
  };

  return (
    <>
      <Box>
        <CareNavigatorAvailabilityModal
          onOpen={openCNScheduleModal}
          isOpen={isCNScheduleModalOpen}
          onClose={closeCNScheduleModal}
        />
        <Text mb={24} variant="body1">
          {t("careProvider:noProviderFound.scheduleCoachViaCN")}
        </Text>
        <Button
          h={48}
          py={12}
          px={16}
          mb={16}
          bg="accent-subtle"
          color="accent-on"
          onClick={handleCNScheduleClick}
          _hover={{
            bg: "hover-base-medium-emphasis",
            color: "primary-content",
          }}
          tabIndex={0}
        >
          <Text size="body-medium-strong">
            {t("careVisits:findNewCareTab.footer.careNavigation.buttonText")}
          </Text>
        </Button>
      </Box>
      <Divider aria-hidden="true" borderColor="gray.300" mb={6} w="100%" />
    </>
  );
};

const NoProvidersFound = ({ isCoach }) => {
  const { t } = useTranslation(["careProvider", "careVisits"]);
  const [isMobile] = useMediaQuery("(max-width: 967px)");

  return (
    <Box
      border="1px"
      borderRadius="8px"
      borderColor="#a9a9a9"
      px={isMobile ? 18 : 58}
      py={9}
      backgroundColor="#faf9f6"
    >
      {isCoach && <ScheduleWithCN t={t} />}
      <Text color="#4b4b4b" my={24} fontSize={20}>
        {t("noProviderFound.contact")}
      </Text>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box
          w={isMobile ? "" : "425px"}
          h={isMobile ? "125px" : ""}
          mb={isMobile ? "10px" : ""}
          bg="white"
          px={isMobile ? 10 : 24}
          py={16}
          border="1px"
          borderColor="#a9a9a9"
          borderRadius="8px"
          display="flex"
          flexDirection="row"
        >
          <Box display="flex" alignItems="center" pr={10}>
            <NoProviderEmail />
          </Box>
          <Box>
            <Text color="#6a6a6a" fontSize={16}>
              {t("noProviderFound.email")}
            </Text>
            <Link
              fontSize={isMobile ? 15 : 20}
              fontWeight={500}
              textDecoration="none"
              color="inherit"
              backgroundColor="transparent"
              _hover={{
                textDecoration: "none",
              }}
              href="mailto:support@resourcesforyourlife.com"
              aria-label="support@resourcesforyourlife.com"
            >
              support@resourcesforyourlife.com
            </Link>
            <Text color="#6a6a6a" fontSize={16}>
              {t("noProviderFound.available")}
            </Text>
          </Box>
        </Box>
        <Box
          w={isMobile ? "inherit" : "425px"}
          h={isMobile ? "125px" : ""}
          bg="white"
          px={isMobile ? 10 : 24}
          py={16}
          border="1px"
          borderColor="#a9a9a9"
          borderRadius="8px"
          display="flex"
          flexDirection="row"
        >
          <Box display="flex" alignItems="center" pr={10}>
            <NoProvidersChat />
          </Box>
          <Box>
            <Text color="#6a6a6a" fontSize={16}>
              {t("noProviderFound.chat")}
            </Text>
            <Link
              fontSize={isMobile ? 15 : 20}
              fontWeight={500}
              textDecoration="none"
              color="inherit"
              backgroundColor="transparent"
              _hover={{
                textDecoration: "none",
              }}
              onClick={() => {
                if (window?.zE) {
                  window?.zE?.activate();
                }
              }}
            >
              {t("noProviderFound.message")}
            </Link>
            <Text color="#6a6a6a" fontSize={16}>
              Available: 8am - 9pm ET Monday - Friday
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NoProvidersFound;
