import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Col, Button } from "@spring/smeargle";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import { Text } from "@chakra-ui/react";
import { useCNApptToast } from "hooks/useCNApptToast";

import styles from "./styles.module.scss";
import { useMediaQuery } from "@chakra-ui/react";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import { useInViewport } from "hooks/useInViewport";

const EmptyCareTeamCard = ({ providerAttributes, upcomingCNAppointmentID }) => {
  const {
    currentTab,
    determinedInitialTab,
    trackedEmptyCareCardOnce,
    setTrackedEmptyCareCardOnce,
  } = useContext(CareContextValues);

  const ref = useRef(null);
  const isInViewport = useInViewport(ref);

  useEffect(() => {
    if (
      !trackedEmptyCareCardOnce &&
      determinedInitialTab &&
      currentTab === 0 &&
      isInViewport
    ) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Empty Care Team Card",
        {
          role: providerAttributes.role,
        },
      );
      setTrackedEmptyCareCardOnce(true);
    }
  }, [isInViewport]);

  const hasCNApptToast = useCNApptToast({ upcomingCNAppointmentID });

  const [isTablet] = useMediaQuery("(max-width: 960px)");
  return (
    <>
      <Col sm={12} md={3} lg={2} alignment={"center"}>
        <div ref={ref} className={styles.avatar}>
          {providerAttributes.emptyIcon}
        </div>
      </Col>

      <Col alignment={isTablet ? "end" : "center"} sm={12} md={9} lg={6}>
        <h3 data-cy="empty-card-heading" className={styles.providerRole}>
          {providerAttributes?.overrideTitle
            ? providerAttributes.overrideTitle
            : providerAttributes.role}
        </h3>
        <Text pr={16}>{providerAttributes.description}</Text>
      </Col>

      <Col
        alignment={"center"}
        justification={isTablet ? "center" : "end"}
        sm={12}
        md={12}
        lg={4}
        className={styles.buttonContainer}
      >
        <Button
          data-testid={`find-${providerAttributes.role.replace(" ", "").toLowerCase()}-button`}
          className={styles.button}
          text={providerAttributes.findText}
          onClick={() => {
            if (providerAttributes.role === "Care Navigator") {
              TRACK_EVENT.BUTTON_CLICKED(
                routes.MemberCareVisits.as,
                "Schedule Care Navigation",
                {
                  spring_doc_id: "empty_care_card001",
                  location: "Care Navigation Schedule a call",
                  to: "Care Navigator Availability Modal",
                },
              );

              return upcomingCNAppointmentID
                ? hasCNApptToast()
                : providerAttributes.emptyAction();
            } else {
              //TODO: see if this can be removed; this should never hit this case
              // Remind me August 2024
              providerAttributes.emptyAction();

              TRACK_EVENT.BUTTON_CLICKED(
                routes.MemberCareVisits.as,
                "Schedule Care Navigation",
                {
                  spring_doc_id: "empty_care_card002",
                  location: "Care Navigation Schedule a call",
                  to: "Care Navigator Availability Modal",
                },
              );
            }
          }}
          dataCy={providerAttributes.role}
        />
      </Col>
    </>
  );
};

EmptyCareTeamCard.propTypes = {
  providerAttributes: PropTypes.object,
};

export default EmptyCareTeamCard;
