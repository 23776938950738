import { Textarea } from "design-system/components";

type Props = {
  value: string;
  onChange: Function;
  placeholder?: string;
  dataCy?: string;
  isVerdant?: boolean;
};

export const SHTextArea = ({
  dataCy,
  placeholder,
  value,
  onChange,
  isVerdant,
}: Props) => {
  return (
    <Textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      p={16}
      h={168}
      border="none"
      backgroundColor={isVerdant ? "#FBFAF9" : "tertiary.25"}
      _placeholder={{ color: "#6A7378" }}
      placeholder={placeholder}
      data-cy={dataCy}
    />
  );
};
