import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
  VStack,
} from "design-system/components";
import { VCoachingDuotoneSpot } from "design-system/assets/verdant";
import { TRACK_EVENT } from "utils/mixpanel";
import React from "react";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { useTranslation } from "react-i18next";

const CoachingBanner = () => {
  const { isMobile } = useProviderBrowseContext();
  const { t } = useTranslation("coachesProvider");

  return (
    <Box
      width="100%"
      border="2px"
      borderRadius="16px"
      borderColor="gray.200"
      background="#E5F2F0"
      mb={[16, 28, 32]}
      padding={24}
    >
      <Stack direction={["column", "column", "row"]}>
        <HStack>
          <Box width={{ base: "80px", sm: "80px", md: "50px" }}>
            <VCoachingDuotoneSpot width={isMobile ? "80px" : "50px"} />
          </Box>
          <VStack align="start" justify="start" spacing={0}>
            <Text
              paddingLeft={0}
              fontSize="16px"
              fontWeight={700}
              color="#1A5856"
              lineHeight="24px"
            >
              {t("banner.title")}
            </Text>
            <Text
              color="#1A5856"
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
              letterSpacing={0}
            >
              {t("banner.subTitle")}
              <Link
                href="https://benefits.springhealth.com/coaching-pathways"
                isExternal
              >
                {t("banner.learnMore")}
              </Link>
            </Text>
          </VStack>
        </HStack>
        <Spacer />
        <Flex alignItems="center" justifyContent={isMobile ? "center" : ""}>
          <Link
            href="https://careteam.springhealth.com/hc/en-us/requests/new?ticket_form_id=18582323684756"
            isExternal
          >
            <Button
              borderRadius="8px"
              borderWidth="2px"
              colorScheme="primary"
              size="md"
              variant="solid"
              onClick={() => {
                TRACK_EVENT.BUTTON_CLICKED(
                  window.location.pathname,
                  "Submit request to enroll",
                );
              }}
            >
              {t("banner.submitButton")}
            </Button>
          </Link>
        </Flex>
      </Stack>
    </Box>
  );
};

export default CoachingBanner;
