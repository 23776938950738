import { gql } from "@apollo/client";

const getExercise = gql`
  query getExercise($id: ID!) {
    exercise(id: $id) {
      id
      title
      completed
      duration
      total_steps
      activity_type
      emotions {
        emotion
        position
      }
      categories {
        category
      }
      content_formats
      images {
        url
        context
      }
      starting_step {
        id
        exercise_id
        position
        title
        content_format
        final_step
        video_url
        caption_url
        elements {
          type
          content
          media_uri
        }
      }
    }
  }
`;

export default getExercise;
